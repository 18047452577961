define("ember-rs/templates/components/reports/account-efficiency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7oO0wthl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"personal_info\"],[14,0,\"report-section\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"reports.components.account-efficiency.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"description\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"reports.components.account-efficiency.description\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"chart-legend efficiency-legend\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"reports.components.account-document-volume.legend\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[10,\"i\"],[14,0,\"fa fa-circle-o\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"reports.components.account-efficiency.legend-pending\"],null]],[13],[2,\"\\n      \"],[10,\"li\"],[12],[10,\"i\"],[14,0,\"fa fa-check-circle\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"reports.components.account-efficiency.legend-signed\"],null]],[13],[2,\"\\n      \"],[10,\"li\"],[12],[10,\"i\"],[14,0,\"fa fa-times-circle\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"reports.components.account-efficiency.legend-expired\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \\n  \"],[10,\"div\"],[14,0,\"account-efficiency-charts\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"type\",\"width\",\"height\",\"options\",\"data\",\"class\"],[\"pie\",250,250,[35,2],[35,1,[\"pie\"]],\"pie\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"account-efficiency-charts\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"type\",\"width\",\"height\",\"options\",\"data\",\"class\"],[\"bar\",350,250,[35,2],[35,1,[\"bar\"]],\"bar\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"chartData\",\"chartOptions\",\"ember-chart\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/reports/account-efficiency.hbs"
    }
  });

  _exports.default = _default;
});