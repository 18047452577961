define("ember-rs/locales/ja/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareNextRoute = void 0;
  var prepareNextRoute = {
    "mergeFieldStepNext": "次へ: マージ フィールド",
    "documentStepNext": "次のドキュメント",
    "reviewStepNext": "次へ: 確認",
    "placeFieldNext": "次へ: フィールドの配置"
  };
  _exports.prepareNextRoute = prepareNextRoute;
});