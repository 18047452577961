define("ember-rs/locales/fr/templates/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = void 0;
  var verify = {
    "advisory": "L'expéditeur de ce document a activé la fonctionnalité d'authentification basée sur les connaissances de RightSignature pour vérifier votre identité. RightSignature utilise les services tiers de BlockScore pour valider et vérifier les informations personnelles fournies. L'utilisation des informations personnelles fournies est limitée à l'exécution de la validation et de la vérification de l'identité avec les enregistrements de données propriétaires de BlockScore. L'exécution de la validation et de la vérification de l'identité n'aura aucun effet sur votre rapport de solvabilité, votre cote de solvabilité ou vos rapports aux prêteurs. Ni le fisc, ni Blockscore, ni RightSignature ne pourront afficher ou accéder à votre rapport de solvabilité ou à vos informations fiscales dans le cadre de ce processus.",
    "step2": "Vérification d'identité - Étape 2 sur 2",
    "answer": "Répondez aux questions de vérification d'identité ci-dessous en cochant la case située en regard de la réponse appropriée.",
    "noMatch": "Désolé, les réponses que vous avez fournies ne correspondent pas aux informations dont nous disposons pour cette identité.",
    "youHave": "Il vous reste ",
    "remaining": "tentatives d'accès au document.",
    "complete": "Vérification terminée",
    "return": "Retourner au formulaire",
    "advisory2": "Avis sur la vérification d'identité",
    "unableToAuthenticate": "Impossible de s'authentifier avec succès. Contactez <b>{{{sender_email}}}</b> pour accéder au document via un autre mécanisme d'authentification."
  };
  _exports.verify = verify;
});