define("ember-rs/locales/es/mixins/integration-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocuments = void 0;
  var integrationDocuments = {
    "enable_sending": "Envío habilitado correctamente para {{{provider}}}",
    "enable_archiving": "Archivado habilitado correctamente para {{{provider}}}",
    "enable_contacts": "Contactos habilitados correctamente para {{{provider}}}",
    "enable_payments": "Pagos habilitados correctamente para {{{provider}}}",
    "disable_sending": "Envío inhabilitado correctamente para {{{provider}}}",
    "disable_archiving": "Archivado inhabilitado correctamente para {{{provider}}}",
    "disable_contacts": "Contactos inhabilitados correctamente para {{{provider}}}",
    "disable_payments": "Pagos inhabilitados correctamente para {{{provider}}}",
    "errorToggle": "Error. No se pudo modificar {{{service}}} para {{{provider}}}.",
    "errorRetrieve": "Error. No se pudo obtener el documento.",
    "errorProviderRetrieve": "Error. No se pudieron obtener {{{provider}}} documentos."
  };
  _exports.integrationDocuments = integrationDocuments;
});