define("ember-rs/locales/fr/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "notAllowed": "Action non autorisée",
    "noPro": "Vous n'avez pas d'accès PRO à ce compte. Vous pouvez vous connecter et consulter vos documents mais les fonctionnalités PRO ne sont pas disponibles. Contactez un administrateur de ce compte pour qu'il vous donne accès à PRO de manière à pouvoir :",
    "send": "Envoyer des documents pour signature",
    "create": "Créer et utiliser des modèles",
    "customize": "Personnaliser la marque",
    "enable": "Activer des intégrations de tiers",
    "reports": "Afficher rapports",
    "login": "Se connecter à RightSignature"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});