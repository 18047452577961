define("ember-rs/locales/es/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserForm = void 0;
  var newUserForm = {
    "email": "Correo electrónico",
    "name": "Nombre",
    "password": "Contraseña",
    "hidePassword": "Ocultar contraseña",
    "create": "Crear cuenta",
    "haveAccount": "¿Tiene una cuenta?"
  };
  _exports.newUserForm = newUserForm;
});