define("ember-rs/locales/zh-cn/templates/onboarding/prepare-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding3 = void 0;
  var prepareOnboarding3 = {
    "seeSigners": "<span>查看此文档的签名者</span>。每个签名者都有自己的颜色，这些颜色与其需要填写的字段相对应"
  };
  _exports.prepareOnboarding3 = prepareOnboarding3;
});