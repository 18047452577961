define("ember-rs/locales/zh-cn/templates/onboarding/post-sign-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding1 = void 0;
  var postSignOnboarding1 = {
    "tapGear": "<span>轻按齿轮</span>可下载文档及其他信息。",
    "viewDoc": "您可以在此处看到文档的<span>状态</span>"
  };
  _exports.postSignOnboarding1 = postSignOnboarding1;
});