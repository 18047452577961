define("ember-rs/locales/fr/templates/client-application/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTranslations = void 0;
  var newTranslations = {
    "request_key": "Demander une nouvelle clé API",
    "name_tool_tip": "Présenté aux utilisateurs si vous utilisez OAuth pour demander l'accès à leur compte. Par exemple Industry Automative Estimates Generator ou WordStar Plugin",
    "name": "Nom :",
    "org_name_tip": "  Nom de votre organisation",
    "org_name": "Nom de l'organisation :",
    "description_tip": "Présenté aux utilisateurs si vous utilisez OAuth pour demander l'accès à leur compte. Par exemple « Cette application est exclusivement utilisée par l'industrie automobile pour envoyer des estimations dans notre workflow d'entreprise » ou « WordStar Plugin permet aux utilisateurs de WordStar d'envoyer des documents pour signature via leur compte RightSignature&trade; ».",
    "description": "Description :",
    "scopes_tip": "  Niveau d'accès souhaité",
    "scopes": "Étendue(s) :",
    "app_url_tip": "Présenté aux utilisateurs si vous utilisez OAuth pour demander l'accès à leur compte. Par exemple http://industryauto.com/login ou http://wordstarsupportgroup.com/theplugin",
    "app_url": "URL de l'application :",
    "redirect_url_tip": "Réponse par défaut pour les demandes d'autorisation OAuth. Elle peut être spécifiée ou remplacée dans les appels de l'API. Par exemple, http://industryauto.com/oauth_callback",
    "redirect_url": "URL de redirection :",
    "org_url_tip": "  (facultatif) Site Web de votre organisation",
    "org_url": "URL de l'organisation :",
    "Request": "Demander",
    "readOnlyAccess": "Accès en lecture seule",
    "readWriteAccess": "Accès en lecture et en écriture"
  };
  _exports.newTranslations = newTranslations;
});