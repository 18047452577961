define("ember-rs/locales/zh-cn/templates/components/plan-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planInfo = void 0;
  var planInfo = {
    "perMonth": "/月",
    "teamMember": "团队成员",
    "teamMembers": "团队成员",
    "call": "致电 1-855-895-4862",
    "selectedPlan": "选定的计划",
    "selectPlan": "选择计划"
  };
  _exports.planInfo = planInfo;
});