define("ember-rs/templates/document/search-header-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xx6CYCE3",
    "block": "{\"symbols\":[],\"statements\":[[11,\"i\"],[24,0,\"fa fa-search\"],[4,[38,2],[[32,0],\"toggleSearchInput\"],null],[12],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"search-field\",[],[[\"@query\",\"@searchUpdate\",\"@placeholder\"],[[34,0],\"searchUpdate\",[30,[36,1],[\"document.search_header_mobile.search_docs\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"loading-spinner\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"search\",\"t\",\"action\",\"showSearchInput\",\"if\",\"isLoading\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/document/search-header-mobile.hbs"
    }
  });

  _exports.default = _default;
});