define("ember-rs/components/next-required-field", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['next-required-field'],
    classNameBindings: ['direction'],
    attributeBindings: ['style'],
    documentViewerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    paymentComponentData: Ember.inject.service(),
    bumpStyle: 0,
    scrollTop: 0,
    direction: '',
    initScrollEvent: Ember.on('didInsertElement', function () {
      var _this = this;

      this.$().parents('#sign_document').on('scroll', function (evnt) {
        _this.set('scrollTop', evnt.target.scrollTop);
      });
    }),
    style: Ember.computed('nextRequiredField.y', 'scrollTop', 'bumpStyle', 'layoutSizeData.windowHeight', 'layoutSizeData.windowWidth', 'layoutSizeData.signingFooterHeight', function () {
      var componentEle = (0, _jquery.default)(".signing-component[data-id='".concat(this.get('nextRequiredField.id'), "']"));
      var offset = componentEle.offset();

      if (Ember.isBlank(offset)) {
        return "display: none;".htmlSafe();
      }

      var top = 0;
      var left = (0, _jquery.default)('#document').offset().left;
      var bottomOfPage = this.get('layoutSizeData.windowHeight') - this.get('layoutSizeData.signingFooterHeight'); // To force recalculation

      this.scrollTop;

      if (offset.top < 0) {
        this.set('direction', 'up');
        top = 15;
      } else if (offset.top > bottomOfPage - this.$().height()) {
        this.set('direction', 'down');
        top = bottomOfPage - this.$().height();
      } else {
        this.set('direction', 'right');
        top = offset.top + componentEle.outerHeight() / 2 - this.$().height() / 2;
      }

      return "top: ".concat(top, "px; left: ").concat(left, "px").htmlSafe();
    }),
    click: function click() {
      this.scrollToElement();
    },
    bindTab: Ember.on('didInsertElement', function () {
      (0, _jquery.default)(window).on('keydown.nextComponent', Ember.run.bind(this, this.onKeyDown));
    }),
    unbindTab: Ember.on('willDestroyElement', function () {
      (0, _jquery.default)(window).off('keydown.nextComponent');
    }),
    onKeyDown: function onKeyDown(e) {
      var dontHijack = this.get('paymentComponentData.showCollectCardModal') || this.showTemplateSignerInfoSplash; // tab key pressed, don't hijack if signer name and email modal or the payment modal is showing

      if (!dontHijack) {
        if (e.keyCode === 9) {
          e.preventDefault();
          this.scrollToElement();
        }
      }
    },
    scrollToElement: function scrollToElement() {
      if (!this.get('nextRequiredField.id')) {
        return;
      }

      this.set('documentViewerData.componentToFocus', this.nextRequiredField);
      var componentEle = (0, _jquery.default)(".signing-component[data-id='".concat(this.get('nextRequiredField.id'), "']"));
      this.documentViewerData.scrollToElement(componentEle, true);
    },
    initStyle: Ember.on('didInsertElement', function () {
      Ember.run.next(this, function () {
        this.set('bumpStyle', this.bumpStyle + 1);
      });
    })
  });

  _exports.default = _default;
});