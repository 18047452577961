define("ember-rs/components/initial-onboarding", ["exports", "ember-rs/mixins/entitle-sharefile-user", "ember-rs/mixins/settings", "ember-rs/mixins/invalidate-and-sign-out", "ember-rs/config/environment"], function (_exports, _entitleSharefileUser, _settings, _invalidateAndSignOut, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_entitleSharefileUser.default, _settings.default, _invalidateAndSignOut.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    showInitialOnboarding: Ember.computed.not('currentUser.model.hasSeenWelcomeOnboarding'),
    marketingHost: _environment.default.marketingHost,
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      entitleMe: function entitleMe() {
        return this.entitleSharefileUser(Ember.get(this, 'currentUser.model.sharefileUserId'));
      },
      notNow: function notNow() {
        return this.updateSetting('hasSeenEntitlementOptions', 'has_seen_entitlement_options', true, Ember.get(this, 'currentUser.model'));
      },
      disagreeToTerms: function disagreeToTerms() {
        return this.invalidateAndSignOut();
      },
      agreeToTerms: function agreeToTerms() {
        return this.updateSetting('hasAgreedToTerms', 'has_agreed_to_terms', true, Ember.get(this, 'currentUser.model'));
      }
    }
  });

  _exports.default = _default;
});