define("ember-rs/locales/es/routes/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    "embeddedTemplate": "Su cuenta no tiene acceso a esta funcionalidad. Por favor, actualice a un plan Pro para comenzar a usar plantillas incrustadas.",
    "inPerson": "Su cuenta no tiene acceso a esta función. Amplíe su plan a Pro para empezar a usar la firma en persona."
  };
  _exports.startDocument = startDocument;
});