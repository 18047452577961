define("ember-rs/locales/pt-br/routes/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "saved": "salvo!"
  };
  _exports.documents = documents;
});