define("ember-rs/locales/fr/templates/email-unverified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailUnverified = void 0;
  var emailUnverified = {
    "verify": "Votre adresse e-mail n'a pas été vérifiée. Vérifiez votre e-mail et cliquez sur le lien de vérification pour confirmer votre compte."
  };
  _exports.emailUnverified = emailUnverified;
});