define("ember-rs/locales/fr/templates/components/hybris-trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialBanner = void 0;
  var hybrisTrialBanner = {
    "billingInfo": "Vos informations de facturation et vos tarifs ne sont pas à jour.",
    "update": "Cliquez ici pour mettre à jour",
    "hybrisTrialMsg": "Votre période d'évaluation expire dans {{{numberOfDaysLeft}}} jour(s). Veuillez <a href='/upgrade-worldpay'>mettre à niveau</a> vers la formule payante pour continuer à utiliser nos services.",
    "premiumTrialMsg": "You are on your Premium Trial. Please contact 1-800-441-3453 or Email us at <a href='mailto:{{{email}}}'>{{{email}}}</a> to upgrade and go completely paperless."
  };
  _exports.hybrisTrialBanner = hybrisTrialBanner;
});