define("ember-rs/locales/nl/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "linkExpired": "Documentkoppeling verlopen",
    "toSign": "Vraag een nieuwe koppeling aan om dit document te ondertekenen.",
    "sendLink": "Nieuwe ondertekenaarskoppeling verzenden",
    "contactSender": "Neem contact op met de afzender van het document om een ​​bijgewerkte koppeling aan te vragen."
  };
  _exports.identityExpired = identityExpired;
});