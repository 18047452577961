define("ember-rs/locales/zh-cn/templates/components/account-user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "basic": "基本信息",
    "firstName": "名字:",
    "lastName": "姓氏:",
    "required": "*必填",
    "email": "电子邮件:",
    "emailTip": "*必须提供有效的电子邮件。",
    "password": "密码:",
    "hiddenForSecurity": "隐藏以提高安全性",
    "reset": "重置",
    "emailInfo": "通过电子邮件发送登录信息",
    "oldPass": "旧密码:",
    "newPass": "新密码:",
    "passReq": "*密码必须至少包含 8 个字符，其中至少包含 1 个数字、1 个大写字母和 1 个小写字母。",
    "permissions": "权限",
    "manageUsers": "管理用户",
    "manageDelegateUsers": "可以委派用户",
    "manageViewBills": "可以查看账单",
    "customizeBranding": "自定义帐户外观方案",
    "manageTemplates": "管理模板",
    "viewDocuments": "查看所有文档",
    "removeUser": "删除用户",
    "reset2fa": "重置双重验证"
  };
  _exports.edit = edit;
});