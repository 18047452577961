define("ember-rs/locales/fr/templates/document-transaction/review-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_desktop = void 0;
  var review_desktop = {
    "send_document": "envoyer le document",
    "save_document": "Enregistrer document",
    "sendDocument": "Envoyer document",
    "bulkSendConfirmation": "Vous êtes sur le point de partager ce document avec plusieurs signataires",
    "confirm": "Confirmer",
    "cancel": "Annuler"
  };
  _exports.review_desktop = review_desktop;
});