define("ember-rs/locales/pt-br/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbarMenuitem = void 0;
  var componentToolbarMenuitem = {
    "signatureComponent": "Campo de assinatura",
    "textComponent": "Campo de texto",
    "dateComponent": "Campo de data",
    "checkComponent": "Caixa de seleção",
    "checkGroupComponent": "Grupo de caixas de seleção",
    "initialsComponent": "Campo de iniciais",
    "selectComponent": "Caixa de seleção",
    "signatureAnnotationComponent": "Adicionar assinatura",
    "signatureAnnotationName": "Anotação de assinatura",
    "textAnnotationComponent": "Adicionar texto",
    "textAnnotationName": "Anotação de texto",
    "checkmarkAnnotationComponent": "Adicionar marca de seleção",
    "checkmarkAnnotationName": "Anotação de marca de seleção",
    "attachmentComponent": "Anexo",
    "attachmentComponentName": "Anexo solicitado",
    "paymentComponent": "Receber pagamento",
    "signatureComponenttip": "Coloque este campo onde um signatário deva apor sua assinatura.",
    "textComponenttip": "Coloque este campo onde um signatário precisar inserir informações de texto (por exemplo, digitar seu nome ou endereço).",
    "dateComponenttip": "Use este campo onde for necessário inserir datas. Ao assinar o documento, será fornecido um seletor de datas ao signatário, que facilitará a seleção de uma data. A data será exibida como 'MM-DD-AAA'.",
    "checkComponenttip": "Use este campo onde o signatário precisar assinalar uma opção (por exemplo, uma cláusula 'Concordo com...'). Se você tiver um documento com uma série de opções, em que um signatário caixa de seleção está limitado a selecionar apenas uma ou pelo menos uma das opções, use a opção Grupo de caixas de seleção.",
    "checkGroupComponenttip": "Use um grupo de caixas de seleção se você tiver uma série de opções em que um signatário está limitado a selecionar apenas uma ou pelo menos uma das opções.",
    "initialsComponenttip": "Coloque este campo onde um signatário deva apor suas iniciais.",
    "selectComponenttip": "Coloque uma caixa de seleção onde os signatários precisam escolher uma opção de uma lista.",
    "signatureAnnotationComponenttip": "Adicione sua própria assinatura ao documento.",
    "textAnnotationComponenttip": "Adicione seu próprio texto ao documento para preencher ou anotar.",
    "checkmarkAnnotationComponenttip": "Assinalar uma caixa no documento.",
    "attachmentComponenttip": "Coloque esta caixa onde você deseja que um signatário anexe uma imagem ou arquivo ao documento.",
    "paymentComponenttip": "Use esta opção para adicionar um botão ao documento para que um signatário possa inserir um número de cartão de crédito para efetuar um pagamento. Nota: Antes de usar esse recurso, você deve ativar uma integração de pagamento em sua conta. (Visite Conta e selecione Integrações).",
    "unknownField": "Campo desconhecido"
  };
  _exports.componentToolbarMenuitem = componentToolbarMenuitem;
});