define("ember-rs/locales/zh-cn/templates/components/document-token-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTokenExpirationSelect = void 0;
  var documentTokenExpirationSelect = {
    "Do_not_expire_links": "请勿使链接过期",
    "1_hour": "1 小时",
    "3_hours": "3 小时",
    "8_hours": "8 小时",
    "24_hours": "24 小时",
    "1_week": "1 周"
  };
  _exports.documentTokenExpirationSelect = documentTokenExpirationSelect;
});