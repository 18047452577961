define("ember-rs/locales/de/templates/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateRow = void 0;
  var reusableTemplateRow = {
    "send": "Senden",
    "details": "Details",
    "trialmessageComp": "Mit Ihrem aktuellen Testabonnement können Sie nicht mehr als {{{documentLimit}}} Dokumente senden. ",
    "planmessageComp": "Mit Ihrem aktuellen Abonnement können Sie nicht mehr als {{{documentLimit}}} Dokumente senden. ",
    "upgrade": "Kontaktieren Sie uns, um ein Upgrade für Ihr Konto anzufordern."
  };
  _exports.reusableTemplateRow = reusableTemplateRow;
});