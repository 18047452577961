define("ember-rs/templates/components/loading-with-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Et5Y6utl",
    "block": "{\"symbols\":[],\"statements\":[[8,\"loading-spinner\",[],[[],[]],null],[2,\"\\n\"],[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"message\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/loading-with-message.hbs"
    }
  });

  _exports.default = _default;
});