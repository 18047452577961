define("ember-rs/locales/fr/templates/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detailsPanel = void 0;
  var detailsPanel = {
    "clientId": "ID CLIENT :",
    "clientSecret": "CLÉ SECRÈTE CLIENT :",
    "lastViewed": "Dernière consultation : ",
    "fromIp": "depuis l'IP",
    "hidden": "Masqué pour des raisons de sécurité",
    "reveal": "Révéler secret",
    "privateToken": "JETON D’API PRIVÉ :",
    "revealToken": "Révéler jeton",
    "regenerate": "Régénérer le jeton. ATTENTION : cette action est irréversible",
    "description": "DESCRIPTION :",
    "scope": "ÉTENDUE(S) :",
    "redirectUri": "URI DE REDIRECTION :",
    "applicationUrl": "URL DE L’APPLICATION :",
    "orgUrl": "URL DE L’ORGANISATION :",
    "createdAt": "CRÉÉ À :",
    "updatedAt": "MIS À JOUR À :",
    "edit": "modifier"
  };
  _exports.detailsPanel = detailsPanel;
});