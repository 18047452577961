define("ember-rs/controllers/documents", ["exports", "ember-rs/mixins/pagination", "ember-rs/mixins/trial-methods"], function (_exports, _pagination, _trialMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_trialMethods.default, _pagination.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    ////////////////////////  BINDINGS  ////////////////////////
    queryParams: ['search', 'state', 'page', 'perPage', 'account_documents', 'template_id'],
    search: '',
    state: '',
    account_documents: '',
    page: 1,
    perPage: 15,
    totalPages: Ember.computed.alias('model.meta.total_pages'),
    isLoading: false,
    showDocumentFilters: false,
    showSearchInput: false,
    showHelpPointer: false,
    _searchProxy: '',
    ////////////////////////  PROPERTIES  ////////////////////////
    searchProxy: Ember.computed('search', {
      get: function get() {
        return this.search || this._searchProxy;
      },
      set: function set(key, value) {
        return this._searchProxy = value;
      }
    }),
    documentSearchStyle: Ember.computed('layoutSizeData.windowWidth', 'layoutSizeData.sidebarWidth', 'layoutSizeData.sidebar', function () {
      var width = this.get('layoutSizeData.windowWidth') - this.get('layoutSizeData.dashboardSidebarWidth');
      return "width: ".concat(width, "px; max-width: 1300px;").htmlSafe();
    }),
    ////////////////////////  METHODS  ////////////////////////
    scrollTo: function scrollTo(page) {
      this.set('scrollToPage', page);
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleSearchInput: function toggleSearchInput() {
        this.toggleProperty('showSearchInput');
      },
      closeHelpPointerPopup: function closeHelpPointerPopup() {
        this.set('showHelpPointer', false);
      },
      toggleFilters: function toggleFilters() {
        this.set('showDocumentFilters', !this.showDocumentFilters);
      },
      showDocument: function showDocument(documentId) {
        this.transitionToRoute('document.details', documentId);
      },
      showArchivedDocument: function showArchivedDocument(documentId) {
        this.transitionToRoute('archived-document.details', documentId);
      },
      openMigrationModal: function openMigrationModal() {
        this.set('currentUser.model.openMigrationPopup', !this.get('currentUser.model.openMigrationPopup'));
      },
      setCurrentPage: function setCurrentPage(page) {
        this.set('page', page);
      }
    }
  });

  _exports.default = _default;
});