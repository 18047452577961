define("ember-rs/models/component-value", ["exports", "@ember-data/model", "ember-rs/utils/font-scaling"], function (_exports, _model, _fontScaling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    value: (0, _model.attr)('string'),
    fontSize: (0, _model.attr)('number', {
      defaultValue: _fontScaling.DEFAULT_FONT_SIZE
    }),
    // Because components is polymorphic and loaded from documentTransaction rather than with the component
    // in document, we need to just do the relationship manually as it is much more robust.
    componentId: (0, _model.attr)('string'),
    component: Ember.computed('document', 'componentId', function () {
      if (Ember.isBlank(this.get('document.components'))) {
        return undefined;
      }

      return this.get('document.components').findBy('id', this.componentId);
    }),
    appliedSignature: Ember.computed('value', 'component.type', function () {
      var signatureId = this.value;

      if (this.get('component.type') === 'signatureComponent' && Ember.isPresent(signatureId)) {
        return this.store.findRecord('signature', signatureId);
      } else {
        return undefined;
      }
    }),
    hasAppliedSignature: Ember.computed.notEmpty('appliedSignature'),
    // This is a virtual attribute that we set when the group has been fulfilled by another component in the group
    groupIsValid: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isValid: Ember.computed('value', 'component', 'hasAppliedSignature', 'groupIsValid', function () {
      if (this.groupIsValid) {
        return true;
      }

      if (this.get('component.isCheckBox')) {
        return this.value;
      } else if (this.get('component.isSignature')) {
        return this.hasAppliedSignature;
      } else {
        return Ember.isPresent(this.value);
      }
    }),
    document: (0, _model.belongsTo)('document', {
      inverse: 'componentValues',
      async: false
    }),
    response: (0, _model.belongsTo)('response', {
      async: false
    })
  });

  _exports.default = _default;
});