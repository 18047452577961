define("ember-rs/models/dashboard-reusable-template", ["exports", "@ember-data/model", "ember-rs/mixins/taggable"], function (_exports, _model, _taggable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // hasTags is included from Taggable
  var _default = _model.default.extend(_taggable.default, {
    ajax: Ember.inject.service(),
    ////////////////////////  ATTRIBUTES  ////////////////////////
    filename: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    model: (0, _model.attr)('type'),
    creator: (0, _model.attr)('object'),
    type: (0, _model.attr)('string'),
    accountId: (0, _model.attr)('string'),
    userId: (0, _model.attr)('string'),
    tags: (0, _model.attr)('object'),
    thumbnailUrl: (0, _model.attr)('string'),
    ////////////////////////  METHODS  ////////////////////////
    cloneDocument: function cloneDocument() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.ajax.post("reusable_templates/".concat(_this.id, "/clone_document.json")).then(function (response) {
          _this.store.pushPayload(response); // Need to reload doc since doc.get('roles') is blank. The records
          // are actually loaded into the store, but the relationships are not
          // correct linked up in ED. TODO: Look into getting rid of the
          // embedded record stuff wrt roles so this reload isn't necessary.


          _this.store.findRecord('document', response.document.id, {
            reload: true
          }).then(resolve);
        }).catch(reject);
      });
    }
  });

  _exports.default = _default;
});