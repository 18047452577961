define("ember-rs/controllers/document/prepare-document", ["exports", "ember-rs/mixins/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_settings.default, {
    overlayerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    missingMergeFields: function missingMergeFields(templateComponents) {
      return templateComponents.any(function (mergefield) {
        return mergefield.get('isMergeField') && mergefield.get('isRequired') && !mergefield.get('mergefieldValue.isValid');
      });
    },
    showPrepareTutorial: Ember.computed('currentUser.model.hasSeenPrepareOnboarding', function () {
      return !this.get('currentUser.model.hasSeenPrepareOnboarding');
    }),
    scrollContainerStyle: Ember.computed('layoutSizeData.windowHeight', 'layoutSizeData.tmHeaderHeight', function () {
      var height = this.get('layoutSizeData.windowHeight') - this.get('layoutSizeData.tmHeaderHeight');
      return "height: ".concat(height, "px;").htmlSafe();
    }),
    mergefieldsToFill: Ember.computed.filter('model.components', function (component) {
      return component.get('isMergeField') && component.get('isRequired');
    }),
    mergefieldValues: Ember.computed.mapBy('mergefieldsToFill', 'mergefieldValue'),
    mergefieldsPending: Ember.computed('mergefieldValues.@each.isValid', function () {
      return this.get('mergefieldValues').any(function (mergefieldValue) {
        return !mergefieldValue.get('isValid');
      });
    }),
    actions: {
      nextReview: function nextReview() {
        this.get('model').reload();

        if (this.missingMergeFields(this.get('model.components'))) {
          this.get('flashMessages').warning(this.get('i18n').t('document.prepare_document.missing_merge_fields'));
          return;
        }

        this.send('nextStep');
      }
    }
  });

  _exports.default = _default;
});