define("ember-rs/locales/fr/controllers/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "standardInfo": "Le plan que vous avez sélectionné ne prend en charge qu'un utilisateur. Si vous avez besoin de plus d'utilisateurs sur votre compte, choisissez le plan Advanced.",
    "migratedInfo": "Choisissez au moins {{{minimumSeats}}} utilisateurs car votre compte d'évaluation utilise déjà {{{minimumSeats}}} postes. Sinon, contactez le support pour obtenir de l'aide lors de la mise à niveau",
    "planInfo": "Le plan que vous avez sélectionné inclut 3 utilisateurs. Choisissez-en plus ci-dessous si nécessaire.",
    "validUsers": "Entrez un nombre d'utilisateurs valide"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});