define("ember-rs/locales/fr/templates/components/document-download-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDownloadOptions = void 0;
  var documentDownloadOptions = {
    "originalPDF": "PDF original",
    "completedPDF": "PDF complété",
    "overlay": "Superposition de PDF imprimable",
    "failed": "PDF complété (échec)",
    "support": "Un problème est survenu lors du traitement du document. Contactez le support.",
    "cert": "Certificat de signature",
    "mergedPDF": "PDF combiné"
  };
  _exports.documentDownloadOptions = documentDownloadOptions;
});