define("ember-rs/locales/nl/templates/onboarding/new-user-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboarding = void 0;
  var newUserOnboarding = {
    "title": "Welkom bij RightSignature™",
    "letsPersonalize": "Voordat we de functies verkennen, kunt u eerst uw account personaliseren.",
    "photoText": "Uw foto wordt op de ondertekeningspagina van het document gebruikt. Het voegt een niveau van personalisatie en authenticiteit toe.",
    "photoSubtext": "U kunt dit op elk moment bijwerken door naar uw accountinstellingen te gaan.",
    "sendSign": "Documenten verzenden en ondertekenen",
    "sendDoc": "Verzend documenten die door anderen moeten worden ondertekend of onderteken alle documenten die uw handtekening nodig hebben.",
    "knowMore": "Wilt u meer weten?",
    "viewSupport": "Ondersteuningsartikelen en video's weergeven",
    "createTemplates": "Sjablonen maken voor vaak ondertekende documenten.",
    "saveTime": "Bespaar tijd en maak herbruikbare sjablonen voor uw vaak gebruikte documenten, waaronder vrijstellingen, geheimhoudingsverklaringen, belastingformulieren, aanvraagformulieren voor medewerkers en meer.",
    "learnMore": "Wilt u meer weten?",
    "accessSupport": "Ga naar onze <a href='#''>ondersteuningssite</a> met zelfstudievideo's en handige tips die u helpen om RightSignature™ optimaal te benutten."
  };
  _exports.newUserOnboarding = newUserOnboarding;
});