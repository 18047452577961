define("ember-rs/locales/es/routes/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "tryAgain": "Se ha producido un error. Inténtelo de nuevo."
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});