define("ember-rs/components/sortable-roles", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortedRolesKey: ['sequence:asc'],
    sortedRoles: Ember.computed.sort('documentTemplate.roles', 'sortedRolesKey'),
    setupSortable: Ember.on('didInsertElement', function () {
      var self = this;
      this.$('ul.signers-list').sortable({
        start: function start(e, ui) {
          (0, _jquery.default)(this).attr('data-previndex', ui.item.index());
        },
        update: function update(e, ui) {
          var newIndex = ui.item.index();
          var oldIndex = (0, _jquery.default)(this).attr('data-previndex');
          (0, _jquery.default)(this).sortable('cancel');
          self.onSignerSequenceUpdate(oldIndex, newIndex);
          self.rerender();
        }
      });
      this.toggleSortable();
    }),
    destroySortable: Ember.on('willDestroyElement', function () {
      this.$('ul.signers-list').sortable('destroy');
    }),
    onSignerSequenceUpdate: function onSignerSequenceUpdate(fromIndex, toIndex) {
      var sortedRoles = this.sortedRoles;
      var role = sortedRoles.objectAt(fromIndex);

      if (fromIndex > toIndex) {
        // sequence all toIndex on +1
        sortedRoles.filter(function (r) {
          return r.get('sequence') >= toIndex;
        }).forEach(function (r) {
          if (r !== role) {
            r.set('sequence', r.get('sequence') + 1);
          }
        });
      } else {
        // sequence all toIndex less -1
        sortedRoles.filter(function (r) {
          return r.get('sequence') <= toIndex;
        }).forEach(function (r) {
          if (r !== role) {
            r.set('sequence', r.get('sequence') - 1);
          }
        });
      }

      role.set('sequence', toIndex);
      this.resequence();
    },
    resequence: function resequence() {
      this.sortedRoles.map(function (i, idx) {
        i.set('sequence', idx);
      });
    },
    toggleSortable: Ember.observer('documentTemplate.signerSequencing', function () {
      if (this.get('documentTemplate.signerSequencing')) {
        this.$('ul').sortable('enable');
      } else {
        this.$('ul').sortable('disable');
      }
    }),
    actions: {
      addRole: function addRole() {
        this.documentTemplate.newRole();
      },
      removeRole: function removeRole(role) {
        if (this.get('documentTemplate.roles.length') === 1) {
          // Clear out role instead
          if (this.get('documentTemplate.isReusable')) {
            role.setProperties({
              isSender: false,
              name: ''
            });
          } else {
            role.setProperties({
              isSender: false,
              signerName: '',
              signerEmail: ''
            });
          }
        } else {
          role.deleteRecord();
          role.unloadRecord();
          this.resequence();
        }
      }
    }
  });

  _exports.default = _default;
});