define("ember-rs/locales/es/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noUser = void 0;
  var noUser = {
    "unableLogin": "No se puede iniciar la sesión",
    "unableLoginTip": "No puede iniciar sesión en esta cuenta de RightSignature.<br> Póngase en contacto con un administrador de la cuenta para obtener acceso."
  };
  _exports.noUser = noUser;
});