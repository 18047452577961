define("ember-rs/locales/it/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialModal = void 0;
  var trialModal = {
    "ohNo": "Oh no!",
    "reminder": "Solo un promemoria:",
    "trialExpired": "La prova gratuita è scaduta.",
    "trialEnds_singular": "La prova gratuita termina in {{trialDays}} giorno.",
    "trialEnds_plural": "La prova gratuita termina in {{trialDays}} giorni.",
    "day": "giorno",
    "upgradeMessage": "È ancora necessario far firmare documenti? I prezzi dei piani aziendali di RightSignature sono pensati per far risparmiare tempo e denaro all'azienda.",
    "accountSetup": "Configurazione semplice, nessun contratto e la possibilità di annullare in qualsiasi momento.",
    "trialSetup": "Conservare i documenti e la configurazione dell'account dalla prova.",
    "paperWork": "Risparmiare un'ora o più di scartoffie ogni settimana!",
    "moreTime": "Occorre più tempo per la valutazione?",
    "call": "<a href='https://www.citrix.com/contact/technical-support.html'>Chiamaci</a>",
    "logout": "Scollega",
    "notSure": "Si è ancora indecisi? Ecco cosa può fare RightSignature per le aziende."
  };
  _exports.trialModal = trialModal;
});