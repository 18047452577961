define("ember-rs/components/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  BINDINGS  ////////////////////////
    classNameBindings: [':tool-tip', 'showTip', 'position'],
    attributeBindings: ['style'],
    layoutSizeData: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    position: 'left',
    width: 'default',
    fontSize: 'default',
    style: ''.htmlSafe(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().parent().on({
        mouseenter: function mouseenter() {
          _this.set('showTip', true);

          _this.set('layoutSizeData.showTip', true);

          if (_this.$().parents("#sidebar").length) {
            _this.set('layoutSizeData.sidebarScrollPosition', _this.$().parents("#sidebar").scrollTop());
          }

          _this.setPositionAndHeight();
        },
        mouseleave: function mouseleave() {
          _this.set('showTip', false);

          _this.set('layoutSizeData.showTip', false);

          _this.set('layoutSizeData.sidebarScrollPosition', 0);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$().parent().off('mouseenter', 'mouseleave');
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    hasMessage: Ember.isPresent('message'),
    ////////////////////////  METHODS  ////////////////////////
    setPositionAndHeight: function setPositionAndHeight() {
      this.set('height', this.$().height());
      this.set('topPosition', (this.height / 2 + 12) * -1);
      var widthStyle = '';

      if (this.width !== "default") {
        widthStyle = "width: ".concat(this.width, "px;");
      }

      var fontStyle = '';

      if (this.fontSize !== "default") {
        fontStyle = "font-size: ".concat(this.fontSize, "px;");
      }

      var optionsStyle = "".concat(widthStyle).concat(fontStyle);

      if (Ember.isEqual(this.position, 'left')) {
        this.set('style', "top: ".concat(this.topPosition, "px; height: ").concat(this.height, "px;").concat(optionsStyle).htmlSafe());
      } else {
        this.set('style', optionsStyle.htmlSafe());
      }
    }
  });

  _exports.default = _default;
});