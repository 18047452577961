define("ember-rs/locales/it/templates/components/component-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbar = void 0;
  var componentToolbar = {
    "useTools": "Utilizzare questi strumenti per i campi che i firmatari devono completare.",
    "showLess": "Mostra meno",
    "showMore": "Mostra altro",
    "annotate": "annotare:",
    "annotateTip": "Utilizzare questi strumenti per annotare il documento con la propria firma, testo o segni di spunta.",
    "request": "Richiesta:",
    "dataFields": "Campi dati",
    "sourceFetchFailed": "Impossibile recuperare le informazioni sull'origine.",
    "schemaFetchFailed": "Impossibile recuperare lo schema dell'entità di origine."
  };
  _exports.componentToolbar = componentToolbar;
});