define("ember-rs/locales/en/templates/components/documents", ["exports", "ember-rs/locales/en/templates/components/documents/document-row"], function (_exports, _documentRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    documentRow: _documentRow.documentRow
  };
  _exports.documents = documents;
});