define("ember-rs/templates/components/integration-setting-service-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6MueGMR3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[12],[1,[34,0]],[2,\":\"],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"description\"],[12],[1,[34,1]],[13],[2,\"\\n\"],[8,\"limited-input-text-field\",[],[[\"@type\",\"@value\",\"@required\"],[\"text\",[34,2],true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"name\",\"description\",\"paramValue\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/integration-setting-service-field.hbs"
    }
  });

  _exports.default = _default;
});