define("ember-rs/locales/zh-cn/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newClientApplication = void 0;
  var newClientApplication = {
    "appReview": "您的申请正在审查中。我们将在 24 小时内通知或审批您的请求。",
    "secretsSuccess": "检索到机密，这些机密仅应由您或您信任的人使用。",
    "secretsError": "无法检索机密: {{{error}}}",
    "tokenSuccess": "已成功生成新的专用 API 令牌",
    "tokenError": "无法生成令牌: {{{error}}}",
    "singleSecretSuccess": "检索到 {{{clientAppName}}} 的机密，此机密仅应由您或您信任的人使用。",
    "singleSecretError": "无法检索机密: {{{error}}}",
    "tokenRetrieveSuccess": "检索到 {{{clientAppName}}} 的专用 API 令牌，此令牌仅应由您或您信任的人使用。",
    "tokenRetrieveError": "无法检索专用 API 令牌: {{{error}}}",
    "tokenRegenerateError": "无法重新生成令牌: {{{error}}}",
    "confirmInfo": "您确定吗? 另一种选择是申请一个新 API 密钥，因为一旦重新生成，就无法恢复被覆盖的专用 API 令牌。如果选择继续，请务必删除并替换您的所有集成中的所有过时的凭据。"
  };
  _exports.newClientApplication = newClientApplication;
});