define("ember-rs/locales/en/templates/components/delete-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteSignature = void 0;
  var deleteSignature = {
    "deleteSuccess": "Successfully deleted the signature.",
    "deleteFailed": "Error deleting the signature."
  };
  _exports.deleteSignature = deleteSignature;
});