define("ember-rs/templates/onboarding/signer-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LH28VWzl",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1,[\"useMobileLayout\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"onboarding-target-image mobile-signature-field\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"arrow-right\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"signature-field-wrapper\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"signature-field\"],[12],[1,[30,[36,0],[\"components.document.signerRow.sign\"],null]],[13],[2,\"\\n      \"],[10,\"span\"],[12],[1,[30,[36,0],[\"components.overlayComponent.signature\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/mobile-pinch.png\"],[14,0,\"mobile-pinch\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"img\"],[14,\"src\",\"/assets/v3/onboarding/signer-flag-new.png\"],[14,0,\"onboarding-target-image\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding-new/overlay-arrow.svg\"],[14,0,\"onboarding-arrow\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/overlay-arrow.png\"],[15,\"alt\",[30,[36,0],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"onboarding.signerOnboarding1.redFlag\"],null]],[2,\"\\n\"],[6,[37,2],[[35,1,[\"useMobileLayout\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"onboarding.signerOnboarding1.zoom\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"layoutSizeData\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/signer-onboarding-1.hbs"
    }
  });

  _exports.default = _default;
});