define("ember-rs/locales/de/templates/components/code-generated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.codeGenerated = void 0;
  var codeGenerated = {
    "copyToClipboard": "In Zwischenablage kopieren",
    "copySuccess": "Kopiert!",
    "copyError": "Fehler beim Kopieren in die Zwischenablage"
  };
  _exports.codeGenerated = codeGenerated;
});