define("ember-rs/components/import-overlay-role-map-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['map-role-row'],
    didInsertElement: function didInsertElement() {
      this.send('selectMapping', this.defaultRoleIfMatches);
    },
    importableRoleObject: Ember.computed('importableDocument.roles.@each.name', 'importableRole', function () {
      return this.get('importableDocument.roles').findBy('name', this.importableRole);
    }),
    defaultRoleIfMatches: Ember.computed('defaultRole', 'documentTemplate.roles.@each.name', function () {
      var matchingRole = this.get('documentTemplate.roles').findBy('name', this.importableRole);
      return matchingRole ? matchingRole.get('name') : this.defaultRole;
    }),
    roleMappingOptions: Ember.computed('roleMappings.[]', function () {
      var _this = this;

      var options = Ember.A();
      this.roleMappings.forEach(function (role) {
        var signerName = _this.get('documentTemplate.roles').findBy('name', role).get('signerName');

        options.pushObject({
          role: role,
          label: signerName ? "".concat(signerName, " (").concat(role, ")") : role
        });
      });
      return options;
    }),
    actions: {
      selectMapping: function selectMapping(roleMapping) {
        this.sendAction('action', this.importableRole, roleMapping);
      }
    }
  });

  _exports.default = _default;
});