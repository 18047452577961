define("ember-rs/locales/pt-br/templates/components/rs-left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsLeftNav = void 0;
  var rsLeftNav = {
    "Documents": "Documentos",
    "Templates": "Modelos",
    "Account": "Conta",
    "Settings": "Configurações",
    "Integrations": "Integrações",
    "Branding": "Identidade visual",
    "Users": "Usuários",
    "Reports": "Relatórios",
    "Api": "API",
    "Billing": "Faturamento",
    "Workflows": "Comentários e aprovação"
  };
  _exports.rsLeftNav = rsLeftNav;
});