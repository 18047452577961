define("ember-rs/templates/templates/desktop-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4jn94r3I",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[14,0,\"fa fa-search\"],[12],[13],[2,\"\\n\"],[8,\"search-field\",[],[[\"@query\",\"@searchUpdate\",\"@placeholder\"],[[34,0],\"searchUpdate\",[30,[36,1],[\"templates.desktopHeader.searchTemplates\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"search\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/templates/desktop-header.hbs"
    }
  });

  _exports.default = _default;
});