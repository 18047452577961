define("ember-rs/templates/components/add-role-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ITMkn3jJ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"limited-input-text-field\",[],[[\"@class\",\"@type\",\"@value\",\"@placeholder\",\"@autofocus\",\"@disabled\",\"@autocomplete\",\"@spellcheck\",\"@input\",\"@focus-in\",\"@focus-out\"],[\"theme__field_textbox\",\"text\",[34,0],[30,[36,1],[\"components.addRoleField.name\"],null],[34,2],[34,3],\"false\",\"off\",[30,[36,4],[[32,0],\"input\"],null],[30,[36,4],[[32,0],\"focused\"],null],[30,[36,4],[[32,0],\"blur\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"limited-input-text-field\",[],[[\"@class\",\"@type\",\"@value\",\"@placeholder\",\"@autofocus\",\"@disabled\",\"@autocomplete\",\"@spellcheck\",\"@input\",\"@focus-in\",\"@focus-out\"],[\"theme__field_textbox\",\"email\",[34,0],[34,5],[34,2],[34,3],\"false\",\"off\",[30,[36,4],[[32,0],\"input\"],null],[30,[36,4],[[32,0],\"focused\"],null],[30,[36,4],[[32,0],\"blur\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"limited-input-text-field\",[],[[\"@class\",\"@type\",\"@value\",\"@placeholder\",\"@autofocus\",\"@disabled\",\"@autocomplete\",\"@spellcheck\",\"@input\",\"@focus-in\",\"@focus-out\"],[\"theme__field_textbox\",\"text\",[34,0],[30,[36,1],[\"components.addRoleField.name\"],null],[34,2],[34,3],\"false\",\"off\",[30,[36,4],[[32,0],\"input\"],null],[30,[36,4],[[32,0],\"focused\"],null],[30,[36,4],[[32,0],\"blur\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"hint\"],[12],[2,\"\\n  \"],[1,[34,10]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"t\",\"autofocus\",\"isDisabled\",\"action\",\"emailPlaceholder\",\"isNameField\",\"if\",\"isEmailField\",\"isRoleNameField\",\"hintText\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/add-role-field.hbs"
    }
  });

  _exports.default = _default;
});