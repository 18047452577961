define("ember-rs/locales/de/templates/components/agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "welcome": "Willkommen",
    "toRS": "bei RightSignature,",
    "bestOnDesktop": "Das Benutzererlebnis mit RightSignature ist auf einem Desktopcomputer am besten.",
    "pleaseRead": "Lesen Sie sich zuerst Folgendes durch:",
    "terms": "Durch Verwendung von RightSignature&trade; bestätigen Sie, dass Sie Folgendes gelesen haben und akzeptieren: ",
    "and": "und",
    "policy": "Datenschutzrichtlinie",
    "no": "Nein, danke",
    "yes": "Zustimmen"
  };
  _exports.agreeToTerms = agreeToTerms;
});