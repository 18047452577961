define("ember-rs/locales/ja/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "notAllowed": "許可されていない操作",
    "noPro": "現在、このアカウントへの PRO アクセスがありません。ドキュメントに署名して表示することはできますが、PRO 機能は利用できません。このアカウントの管理者に連絡して PRO アクセスを取得すると、次の操作ができます:",
    "send": "署名用にドキュメントを送信",
    "create": "テンプレートの作成と使用",
    "customize": "ブランドのカスタマイズ",
    "enable": "サードパーティとの統合を有効化",
    "reports": "レポートの表示",
    "login": "RightSignature にログイン"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});