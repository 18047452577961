define("ember-rs/locales/zh-cn/templates/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detailsPanel = void 0;
  var detailsPanel = {
    "clientId": "客户端 ID:",
    "clientSecret": "客户端密钥:",
    "lastViewed": "上次查看时间: ",
    "fromIp": "源 IP",
    "hidden": "隐藏以提高安全性",
    "reveal": "显示密钥",
    "privateToken": "专用 API 令牌:",
    "revealToken": "显示令牌",
    "regenerate": "重新生成令牌。警告: 此操作无法撤消",
    "description": "说明:",
    "scope": "范围:",
    "redirectUri": "重定向 URI:",
    "applicationUrl": "应用程序 URL:",
    "orgUrl": "组织 URL:",
    "createdAt": "创建时间:",
    "updatedAt": "更新时间:",
    "edit": "编辑"
  };
  _exports.detailsPanel = detailsPanel;
});