define("ember-rs/locales/it/templates/document-transaction/upload-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload_signers = void 0;
  var upload_signers = {
    "instructions_header": "Seguire la procedura seguente per caricare il file CSV",
    "step_1": "PASSAGGIO 1",
    "step_2": "PASSAGGIO 2",
    "step_3": "PASSAGGIO 3",
    "step_1_text": "Scarica l'elenco di esempio del firmatario per l'invio in blocco",
    "step_2_text": "Compilare i campi nome ed e-mail (obbligatori).",
    "step_3_text": "Caricare il csv completato.",
    "instructions_note": "Nota: il numero di firmatari non può superare 300",
    "upload_failed": "Caricamento non riuscito",
    "upload_failure_message": "Caricamento non riuscito a causa di un file danneggiato o di un errore di elaborazione. Verificare che il file abbia contenuto valido e riprovare a caricarlo.",
    "view_all": "Visualizza tutti",
    "ok": "OK",
    "validate_and_reupload": "Il file contiene altri errori, controllare le convalide e provare a ricaricare."
  };
  _exports.upload_signers = upload_signers;
});