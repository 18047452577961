define("ember-rs/templates/unauthorized/document-editing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yk7+Z+sj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.documentEditing.heading\"],null]],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"unauthorized.documentEditing.line1\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/document-editing.hbs"
    }
  });

  _exports.default = _default;
});