define("ember-rs/components/mobile-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    showDocumentSearch: false,
    showTemplateSearch: false,
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleSearchInput: function toggleSearchInput() {
        this.sendAction("toggleSearchInput");
      },
      searchUpdate: function searchUpdate(query) {
        this.sendAction("searchUpdate", query);
      },
      toggleMobileNavMenu: function toggleMobileNavMenu() {
        this.toggleProperty('layoutSizeData.showMobileNavMenu');
      }
    }
  });

  _exports.default = _default;
});