define("ember-rs/locales/ja/routes/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTransaction = void 0;
  var documentTransaction = {
    "disabledPaymentIntegration": "次のドキュメントを送信する前に、支払いの統合を有効にしてください: {{names}}",
    "disabledPasscode": "次のドキュメントを送信するには、パスコード/PIN を設定する必要があります: {{names}}",
    "successfulDocCreation": "ドキュメントが作成されました。"
  };
  _exports.documentTransaction = documentTransaction;
});