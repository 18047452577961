define("ember-rs/locales/de/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationEditor = void 0;
  var documentExpirationEditor = {
    "theDocExpired": "Das Dokument ist abgelaufen am:",
    "reactivated": "Wählen Sie aus, wann das Dokument ablaufen soll. Dann wird es wieder aktiviert.",
    "theDocExpires": "Derzeitiges Ablaufdatum des Dokuments:",
    "newExpiration": "Wählen Sie, wann das Dokument ablaufen soll.",
    "updateSuccess": "Das Ablaufdatum wurde aktualisiert."
  };
  _exports.documentExpirationEditor = documentExpirationEditor;
});