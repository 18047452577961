define("ember-rs/locales/it/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceCompleteDocumentModal = void 0;
  var forceCompleteDocumentModal = {
    "header": "Forza il completamento del documento",
    "forceCompleted": "In questo modo verranno rimossi tutti i campi assegnati ai firmatari che non hanno ancora firmato e il documento verrà reso esecutivo (stato finale). Non è possibile annullare questa operazione una volta completata.",
    "briefMoment": "Potrebbe essere necessario attendere un momento prima che il nuovo stato venga visualizzato sulla dashboard.",
    "forceComplete": "Forza esecuzione",
    "cancel": "Annulla",
    "success": "Il completamento del documento forzato è stato eseguito correttamente.",
    "failed": "Il completamento del documento forzato non è riuscito"
  };
  _exports.forceCompleteDocumentModal = forceCompleteDocumentModal;
});