define("ember-rs/locales/it/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteDocumentModal = void 0;
  var deleteDocumentModal = {
    "permanentlyDeleted": "Questo eliminerà definitivamente il documento da RightSignature. Questa operazione è irreversibile.",
    "email": "I firmatari e le persone in CC riceveranno un'e-mail con il documento completato e il certificato della firma, in modo da poterli conservare.",
    "briefMoment": "Potrebbe essere necessario qualche istante perché il documento scompaia dalla dashboard.",
    "delete": "Elimina",
    "cancel": "Annulla",
    "deleteDocument": "Elimina documento",
    "deleteComplete": "Documento eliminato",
    "deleteFailed": "Impossibile distruggere il documento"
  };
  _exports.deleteDocumentModal = deleteDocumentModal;
});