define("ember-rs/components/non-production", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var overrideableFeatures = ['api', 'attachments', 'paymentsIntegration', 'branding', 'externallyBilled', 'manualDistribution', 'onlineForms', 'inPerson', 'enterpriseReporting', 'kba'];

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    updating: false,
    classNames: 'non-production',
    currentPlan: Ember.computed.readOnly('currentUser.account.plan'),
    isFree: Ember.computed.equal('currentPlan', 'Free'),
    isUpgraded: Ember.computed.equal('currentPlan', 'Fake'),
    isDevelopment: Ember.computed(function () {
      return _environment.default.environment === 'development';
    }),
    updateRemote: function updateRemote(action, message, data) {
      var _this = this;

      if (!this.isDevelopment) {
        return;
      }

      data = data || {};
      Ember.set(this, 'updating', true);
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/non_production/").concat(action),
          type: 'PUT',
          success: resolve,
          error: reject,
          data: data
        });
      }, message);
      return promise.then(function () {
        return Ember.get(_this, 'currentUser.model').reload();
      }).then(function () {
        return _this.currentUser.reloadAccount();
      }).finally(function () {
        return Ember.set(_this, 'updating', false);
      });
    },
    featuresCollection: Ember.computed('currentUser.account.featureSet', function () {
      var _this2 = this;

      return _jquery.default.map(overrideableFeatures, function (featureName) {
        return {
          name: featureName,
          value: Ember.get(_this2, "currentUser.account.".concat(featureName))
        };
      });
    }),
    actions: {
      upgrade: function upgrade() {
        return this.updateRemote('upgrade', 'upgrade plan to fake (non-production operation)');
      },
      downgrade: function downgrade() {
        return this.updateRemote('downgrade', 'downgrade plan to free (non-production)');
      },
      clearOverride: function clearOverride() {
        return this.updateRemote('clear_override', 'clear plan override (non-production operation)');
      },
      updateFeature: function updateFeature(value, component) {
        var features = {};
        var name = Ember.get(component, 'attr').underscore();
        features[name] = value;
        return this.updateRemote('features', "edited feature ".concat(name), {
          features: features
        });
      }
    }
  });

  _exports.default = _default;
});