define("ember-rs/locales/zh-cn/mixins/workflow-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.workflowType = void 0;
  var workflowType = {
    "signatureRequest": "由您发送",
    "selfSign": "自签名",
    "templateSignerLink": "基于模板",
    "inPerson": "本人签名",
    "apiEmbedded": "API 嵌入式签名",
    "bulkSend": "批量发送"
  };
  _exports.workflowType = workflowType;
});