define("ember-rs/components/rs-date-picker", ["exports", "ember-rs/components/ember-flatpickr"], function (_exports, _emberFlatpickr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberFlatpickr.default.extend({
    classNames: 'rs-date-picker'
  });

  _exports.default = _default;
});