define("ember-rs/helpers/format-provider-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatProviderName = formatProviderName;
  _exports.default = void 0;
  var PROVIDER_NAME_MAPPING = {
    sharefile: "ShareFile",
    freshbooks: "FreshBooks",
    onedrive: "OneDrive"
  };

  function formatProviderName(provider) {
    var value = Ember.isArray(provider) ? provider[0] : provider;
    var formattedName = '';
    value.split('_').forEach(function (string) {
      formattedName += "".concat(string.capitalize(), " ");
    });
    return PROVIDER_NAME_MAPPING[provider] || formattedName.slice(0, -1);
  }

  var _default = Ember.Helper.helper(formatProviderName);

  _exports.default = _default;
});