define("ember-rs/templates/toolbar-icons/check-group-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+tOzxG6o",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"13.973px\"],[14,\"height\",\"14.013px\"],[14,\"viewBox\",\"0 0 13.973 14.013\"],[14,\"enable-background\",\"new 0 0 13.973 14.013\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[2,\"\\n\"],[10,\"g\"],[12],[2,\"\\n\\t\"],[10,\"g\"],[12],[2,\"\\n\\t\\t\"],[10,\"path\"],[14,\"d\",\"M1,8V4c0-1.654,1.346-3,3-3h4c1.218,0,2.269,0.73,2.738,1.775c0.168,0.374,1.219,0.557,1.139,0.242\\n\\t\\t\\tC11.436,1.288,9.861,0,8,0H4C1.8,0,0,1.8,0,4v4c0,1.74,1.126,3.228,2.685,3.774c0.405,0.142,0.39-0.889,0-1.08\\n\\t\\t\\tC1.688,10.207,1,9.183,1,8z\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"path\"],[14,\"d\",\"M9.998,2.016c-0.009,0-0.017-0.003-0.025-0.003H8.998H5.973c-2.2,0-4,1.8-4,4v2.982v1v0.018\\n\\t\\t\\tc0,2.2,1.8,4,4,4h4c2.2,0,4-1.8,4-4v-4C13.973,3.822,12.187,2.03,9.998,2.016z M12.973,10.013c0,1.654-1.346,3-3,3h-4\\n\\t\\t\\tc-1.654,0-3-1.346-3-3V9.998v-1V6.013c0-1.654,1.346-3,3-3h3.025h0.975c0.009,0,0.017,0.003,0.025,0.003\\n\\t\\t\\tc1.642,0.014,2.975,1.352,2.975,2.997V10.013z\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"g\"],[12],[2,\"\\n\\n\\t\\t\\t\"],[10,\"rect\"],[14,\"x\",\"8.433\"],[14,\"y\",\"5.168\"],[14,\"transform\",\"matrix(0.7071 0.7071 -0.7071 0.7071 8.0281 -4.0748)\"],[14,\"width\",\"1\"],[14,\"height\",\"4.97\"],[12],[13],[2,\"\\n\\n\\t\\t\\t\"],[10,\"rect\"],[14,\"x\",\"5.349\"],[14,\"y\",\"7.861\"],[14,\"transform\",\"matrix(-0.7071 -0.7071 0.7071 -0.7071 5.753 19.1042)\"],[14,\"width\",\"2.969\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/toolbar-icons/check-group-component.hbs"
    }
  });

  _exports.default = _default;
});