define("ember-rs/locales/ja/templates/document/prepare-signers-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_desktop = void 0;
  var prepare_signers_desktop = {
    "assign_roles": "次の役割を割り当てる: ",
    "who_sign": "必要な署名者 ",
    "sign_order": "署名者の順番を設定",
    "add_another": "別の署名者を追加",
    "add_yourself": "自分自身を追加",
    "contact_integtration": "連絡先の統合",
    "select_integration": "統合を選択",
    "who_sign_package": "このドキュメント パッケージへの署名が必要なユーザー。"
  };
  _exports.prepare_signers_desktop = prepare_signers_desktop;
});