define("ember-rs/locales/ja/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentityExpired = void 0;
  var confirmIdentityExpired = {
    "linkExpired": "リンクの有効期限が切れました",
    "resendIdentityConfirmation": "ID の確認メールを再送信します。",
    "resendIdentityVerification": "ID 確認を再送信する",
    "contactSender": "更新されたリンクを要求するには、ドキュメントの送信者に連絡してください。"
  };
  _exports.confirmIdentityExpired = confirmIdentityExpired;
});