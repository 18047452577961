define("ember-rs/locales/de/templates/onboarding/send-onboarding-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding4 = void 0;
  var sendOnboarding4 = {
    "close": "Wählen Sie den <span>Pfeil</span>, um diese Details zu schließen und zum Dokumentdashboard zu navigieren."
  };
  _exports.sendOnboarding4 = sendOnboarding4;
});