define("ember-rs/locales/es/templates/unauthorized/unconfirmed-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedEmail = void 0;
  var unconfirmedEmail = {
    "loginFailed": "Error de inicio de sesión",
    "loginFailedTip": "Primero hay que confirmar el correo electrónico de su cuenta de ShareFile. Consulte su correo electrónico para encontrar un enlace de confirmación de ShareFile."
  };
  _exports.unconfirmedEmail = unconfirmedEmail;
});