define("ember-rs/locales/it/templates/components/user-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userFields = void 0;
  var userFields = {
    "name": "nome",
    "email": "e-mail",
    "editInfo": "Modifica informazioni"
  };
  _exports.userFields = userFields;
});