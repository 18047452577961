define("ember-rs/locales/nl/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploader = void 0;
  var documentUploader = {
    "multiFile": "Het uploaden van meerdere bestanden wordt niet ondersteund. Alleen het eerste geselecteerde bestand wordt geüpload.",
    "alreadyUploaded": "U hebt dit bestand al geüpload.",
    "fileSize": "Het bestand {{{fileName}}} is groter dan de limiet van 20 MB.",
    "uploadFailed": "Fout bij het uploaden"
  };
  _exports.documentUploader = documentUploader;
});