define("ember-rs/components/hybris-plan-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'hybris-plan-info',
    isSelected: Ember.computed('selectedPlanId', 'plan.id', function () {
      return Ember.isEqual(this.selectedPlanId, Ember.get(this, 'plan.id'));
    }),
    price: Ember.computed('isSelected', 'selectedPrice', function () {
      if (this.isSelected) {
        return this.selectedPrice;
      } else {
        return Ember.get(this, 'plan.pricePerMonth');
      }
    }),
    isDisabled: Ember.computed('minimumSeats', 'plan.id', 'selectedPlanId', function () {
      return Ember.get(this, 'plan.id') === 'standard' && this.minimumSeats > 1;
    }),
    actions: {
      selectPlan: function selectPlan(planId) {
        this.sendAction('selectPlan', planId);
      }
    }
  });

  _exports.default = _default;
});