define("ember-rs/locales/ja/templates/onboarding/signer-onboarding-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboardingOld = void 0;
  var signerOnboardingOld = {
    "title": "RightSignature&trade; では、ドキュメントに簡単に署名できます。",
    "completefields": "フラグが付いているフィールドを入力します。",
    "submitSignature": "フィールドを入力したら、署名を送信します。",
    "progress": "進行状況バーで進行状況を確認できます。すべての必須フィールドの入力後、[署名の送信] ボタンをクリックして署名を完了します。",
    "help": "ヘルプが必要な場合",
    "questions": "ご不明の点がある場合または詳細の確認が必要な場合は、ここからヘルプを参照してください。"
  };
  _exports.signerOnboardingOld = signerOnboardingOld;
});