define("ember-rs/locales/fr/templates/template/search-header-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchHeaderMobile = void 0;
  var searchHeaderMobile = {
    "searchTemplates": "Rechercher modèles..."
  };
  _exports.searchHeaderMobile = searchHeaderMobile;
});