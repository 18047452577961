define("ember-rs/locales/de/templates/components/integration-document-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocumentSelector = void 0;
  var integrationDocumentSelector = {
    "chooseDocFrom": "Wählen Sie ein Dokument aus:",
    "loading": "Laden...",
    "back": "Zurück",
    "search": "Dokumente suchen...",
    "use": "Verwenden",
    "empty": "Dieser Ordner ist leer",
    "success": "Das Senden wurde für {{{provider}}} deaktiviert",
    "error": "Fehler. Das Senden konnte für {{{provider}}} nicht geändert werden"
  };
  _exports.integrationDocumentSelector = integrationDocumentSelector;
});