define("ember-rs/templates/components/csv-upload-error-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/RHiv3j0",
    "block": "{\"symbols\":[\"error\",\"index\"],\"statements\":[[6,[37,4],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"li\"],[14,0,\"upload-error upload-error-center-align\"],[12],[2,\"\\n    \"],[1,[30,[36,8],[\"document_transaction.upload_signers.upload_failure_message\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"upload-error\"],[15,5,[30,[36,3],[[30,[36,2],[[35,1],\"display: flex; justify-content: center;\"],null]],null]],[12],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[1,[30,[36,0],[[32,2]],null]],[2,\".\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[10,\"span\"],[12],[1,[32,1]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"list-item\",\"showList\",\"unless\",\"safe\",\"if\",\"uploadErrors\",\"-track-array\",\"each\",\"t\",\"showGenericMessage\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/csv-upload-error-list.hbs"
    }
  });

  _exports.default = _default;
});