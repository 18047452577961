define("ember-rs/locales/fr/templates/components/component-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentEditor = void 0;
  var componentEditor = {
    "charge": "Facturer après exécution ?",
    "selectOne": "Effectuer une sélection...",
    "amount": "Montant du paiement (USD) :",
    "enterAmount": "Entrer le montant du paiement...",
    "required": "OBLIGATOIRE : entrez le texte d'aide du signataire ci-dessous pour indiquer au destinataire ce qu'il doit charger.",
    "signerHelp": "Texte d'aide du signataire :",
    "helpText": "Entrer texte d'aide...",
    "selectBox": "La case Sélectionner nécessite au moins 2 options.",
    "selectOptions": "Sélectionnez les options :",
    "addOption": "Ajouter option...",
    "addToGroup": "Ajouter au groupe",
    "assignedTo": "Attribué à :",
    "fieldRequired": "Champ obligatoire ?",
    "autoFillDate": "Auto-fill date signed",
    "autoFillDateHelpText": "A recipient will not be able to edit the field. It will be filled in automatically with the date on which the recipient signs.",
    "mergeField": "Fusionner champ ?",
    "mergeFieldName": "le champ de fusion a besoin d'un nom",
    "nameMergeField": "Nom du champ de fusion :",
    "setFontSize": "Définir taille de la police...",
    "muiltiline": "Multiligne ?",
    "clearBackground": "Arrière-plan clair ?",
    "addDefaultValue": "Ajouter valeur par défaut ?",
    "setDefaultValue": "Définir valeur par défaut...",
    "defaultValue": "Valeur par défaut :",
    "nameThisField": "Nommer ce champ ?",
    "name": "Nom :",
    "setName": "Définir nom du composant...",
    "textAlign": "Aligner le texte",
    "showLess": "Afficher moins",
    "showMore": "Afficher plus",
    "delete": "Supprimer",
    "saved": "Enregistré !",
    "save": "Enregistrer",
    "dateFormat": "Format de la date",
    "left": "gauche",
    "center": "centre",
    "right": "droite",
    "groupRequirement": "Exigences de groupe",
    "only_one": "Une seule",
    "one_or_more": "Un ou plus",
    "options_warning": "Vous avez déjà ajouté cette option"
  };
  _exports.componentEditor = componentEditor;
});