define("ember-rs/locales/ja/templates/components/integration-document-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocumentSelector = void 0;
  var integrationDocumentSelector = {
    "chooseDocFrom": "ドキュメントの選択先:",
    "loading": "読み込み中...",
    "back": "戻る",
    "search": "ドキュメントの検索...",
    "use": "使用",
    "empty": "このフォルダーは空です",
    "success": "{{{provider}}} からの送信が無効になりました",
    "error": "エラー。{{{provider}}} からの送信を変更できませんでした"
  };
  _exports.integrationDocumentSelector = integrationDocumentSelector;
});