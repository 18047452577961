define("ember-rs/locales/es/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanDetails = void 0;
  var hybrisPlanDetails = {
    "document-sending": "Envío de documentos",
    "document-sending-info": "Cargue y envíe documentos en formatos comunes, como PDF, DOC y más.",
    "signature": "Firmas manuscritas",
    "signature-info": "Firme con el mouse o la pantalla táctil, como si lo hiciera en papel.",
    "customer-support": "Asistencia al cliente disponible las 24 horas",
    "customer-support-info": "Nuestro galardonado equipo de asistencia técnica le ayudará cuando lo necesite.",
    "email-alert": "Alertas por correo",
    "email-alert-info": "Recordatorios para los firmantes y actualizaciones instantáneas del estado de sus documentos.",
    "integration": "Complementos de integración",
    "integration-info": "Conecte RightSignature a más de una docena de las aplicaciones avanzadas más populares.",
    "type-sign": "Firma desde el teclado",
    "type-sign-info": "Permita a los firmantes firmar documentos con un teclado.",
    "mobile": "Inicio de sesión en dispositivos móviles",
    "mobile-info": "Firme documentos desde cualquier lugar con su smartphone o tableta.",
    "guided-signing": "Firma guiada",
    "guided-signing-info": "Reduzca los errores y las omisiones con instrucciones detalladas y sencillas.",
    "archive": "Archivo seguro de documentos",
    "archive-info": "Proteja sus documentos importantes con nuestro archivo seguro que puede consultar.",
    "encrypt": "Cifrado de datos de 256 bits",
    "encrypt-info": "Protección de documentos confidenciales contra filtraciones y robos gracias a una seguridad de nivel bancario.",
    "biometric": "Autenticación biométrica",
    "biometric-info": "Ayude a verificar la autenticidad de los firmantes con datos biométricos clave.",
    "fingerprint": "Huellas digitales",
    "fingerprint-info": "Las sumas de comprobación digitales SHA-1 impiden la modificación de documentos.",
    "audit": "Certificado y registro de auditoría",
    "audit-info": "Para cumplir la legalidad vigente, todas las acciones realizadas en los documentos se registran y se protegen.",
    "template": "Plantillas reutilizables",
    "template-info": "Comparta documentos de uso común con todo su equipo.",
    "branding": "Personalización de marca",
    "branding-info": "Agregue su logotipo, sus colores y una foto suya a sus documentos de RightSignature.",
    "attachments": "Solicitud de archivos adjuntos",
    "attachments-info": "Permita a los firmantes adjuntar archivos como parte de la firma de documentos.",
    "payments": "Solicitud de pagos",
    "payments-info": "Procese pagos o reúna información de tarjetas de crédito en sus documentos.",
    "api": "API para desarrolladores",
    "api-info": "Integre firmas electrónicas de RightSignature en sus propias aplicaciones o sitios web.",
    "kba": "Autenticación basada en conocimientos",
    "kba-info": "En algunas industrias, se puede mejorar el cumplimiento de las normativas con regulaciones pertinentes.",
    "standard": "Estándar",
    "advanced": "<span>Avanzado<span>",
    "advancedKba": "<span>KBA avanzado<span>",
    "sharefilePremium": "<span><p>ShareFile Premium</p> <p> con firma electrónica</p></span>",
    "annually": "Anual",
    "quarterly": "Trimestral",
    "monthly": "Mensualmente",
    "month": "mes",
    "year": "año",
    "quarter": "trimestre",
    "document-sends-per-user": "100 envíos de documentos por usuario",
    "hand-written-text-to-sign": "Firma manuscrita, texto en firma, clic para aceptar",
    "bulk-send-for-signature": "Envío en bloque para firma",
    "sequenced-signing": "Firma secuenciada",
    "knowledge-based-authentication": "Autenticación basada en conocimientos",
    "unlimited-document-sends": "Envíe documentos ilimitados para su firma",
    "store-sync-share-files": "Almacene, sincronice y comparta archivos fácilmente",
    "hipaa-compliant": "Configuración del cumplimiento de la normativa HIPAA",
    "advanced-caption": "Incluye funciones populares como:",
    "advanced-kba-caption": "Incluye funciones populares como:",
    "sharefile-caption": "Incluye TODAS LAS FUNCIONES DE FIRMA ELECTRÓNICA Y MÁS CON CITRIX SHAREFILE:",
    "minimum-of": "Mínimo de usuarios: {{{noUsers}}}",
    "users": "usuarios",
    "user": "usuario",
    "user-per-month": "por usuario/mes",
    "compare-plans-in-detail": "<a href='https://citrix.sharefile.com/share/view/s1493194af0a44866ae95a6a667e7ffcd' style='color:#2071C5' target='_blank'>Comparar planes en detalle</a>",
    "selected-plan-requires-3-users": "<span style='color:#536E7B'>El plan seleccionado requiere al menos 3 usuarios</span>",
    "more-than-1000-users-contact-sales": "<span style='color:#2071C5'>Si necesita más de 1000 usuarios, <a href={{driftLinkId}} style='color: #2071C5; text-decoration: underline; text-transform: lowercase'>chatee con Ventas. </a></span>",
    "trail-upgrade-msg": "Le quedan {{{numberOfDaysLeft}}} días de prueba. Para disfrutar de un uso sin preocupaciones y sin interrupciones, considere ampliar su plan.",
    "savings": "Ahorro",
    "contact-sales": "Chatear con Ventas",
    "select-plan": "Seleccionar un plan",
    "selected-plan": "Plan seleccionado",
    "best-for-team-collaboration": "La mejor oferta"
  };
  _exports.hybrisPlanDetails = hybrisPlanDetails;
});