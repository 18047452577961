define("ember-rs/templates/document-transaction/redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kTWq5/eB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"loading\"],[12],[8,\"loading-spinner\",[],[[\"@darkBg\"],[true]],null],[1,[30,[36,0],[\"document_transaction.redirect.please_wait\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/document-transaction/redirect.hbs"
    }
  });

  _exports.default = _default;
});