define("ember-rs/routes/reports/cycle-time", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Cycle Time',
    ////////////////////////  HOOKS  ////////////////////////
    model: function model() {
      return _jquery.default.getJSON("".concat(_environment.default.api.endpoint, "/account/reports/cycle_time"));
    },
    setupController: function setupController(controller, model) {
      controller.set('report', model);
    }
  });

  _exports.default = _default;
});