define("ember-rs/locales/nl/templates/unauthorized/signing-link-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingLinkDisabled = void 0;
  var signingLinkDisabled = {
    "signingLinkDisabled": "Ondertekeningskoppeling uitgeschakeld",
    "signingLinkDisabledTip": "Deze ondertekeningskoppeling is uitgeschakeld."
  };
  _exports.signingLinkDisabled = signingLinkDisabled;
});