define("ember-rs/locales/zh-cn/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noUser = void 0;
  var noUser = {
    "unableLogin": "无法登录",
    "unableLoginTip": "您无法登录此 RightSignature 帐户。<br> 请联系帐户管理员以获取访问权限。"
  };
  _exports.noUser = noUser;
});