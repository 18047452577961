define("ember-rs/locales/es/templates/components/signature-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signatureField = void 0;
  var signatureField = {
    "rotateToSign": "Gire para firmar.",
    "rotateTip": "Puede que tenga que desbloquear el giro de pantalla de su dispositivo.",
    "clear": "Borrar",
    "signInstn": "Haga clic y arrastre para dibujar su firma",
    "typeUrSign": "Introduzca su firma",
    "typeSign": "Introducir firma",
    "drawSign": "Dibujar firma",
    "or": "o",
    "savedSign": "Usar firma guardada",
    "apply": "Aplicar"
  };
  _exports.signatureField = signatureField;
});