define("ember-rs/locales/ja/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "unlock_message": "1 人または複数の署名者による ID 確認の最大試行回数 (3) を超えました。署名者に対してドキュメントがロックされました。ドキュメントのロックを解除し、保留中の署名者がナレッジベース認証でそれぞれの ID を確認するためには、[ロック解除] をクリックします。ドキュメントのロックを解除すると <a target='_blank' href='https://www.irs.gov/uac/electronic-signature-guidance-for-forms-8878-and-8879'>IRS Electronic Signature Guidance for Forms 8878 and 8879</a> と競合することがありますので注意してください。"
  };
  _exports.unlock = unlock;
});