define("ember-rs/services/fingerprinter", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // Browser fingerprint data
    browserScreen: {},
    plugins: Ember.A,
    fonts: '',
    storage: '',
    fingerprinter: Ember.computed(function () {
      return new Fingerprint2();
    }),
    // try to get as much data as possible now
    initFingerprint: function initFingerprint() {
      this.detectBrowserData();
      this.detectPlugins();
      this.detectFonts();
      this.detectStorage();
    },
    serialize: function serialize() {
      return {
        'screen': this.browserScreen,
        plugins: this.plugins,
        timezone: this.timezone,
        fonts: this.fonts,
        storage: this.storage
      };
    },
    detectBrowserData: function detectBrowserData() {
      var screenRes = this.fingerprinter.getScreenResolution();
      this.set('browserScreen', "".concat(screenRes[0], "x").concat(screenRes[1], "x").concat(screen.colorDepth));

      try {
        this.set('timezone', new Date().getTimezoneOffset());
      } catch (ignore) {// ignore errors
      }
    },
    detectPlugins: function detectPlugins() {
      var pluginsList = [];

      if (navigator.plugins) {
        _lodash.default.forEach(navigator.plugins, function (plugin) {
          pluginsList.push({
            name: plugin.name,
            description: plugin.description,
            filename: plugin.filename
          });
        });
      }

      pluginsList.sort();
      this.set('plugins', pluginsList);
    },
    pluginsString: function pluginsString() {
      var pluginsString = '';

      _lodash.default.forEach(this.plugins, function (plugin, idx) {
        pluginsString += "Plugin ".concat(idx, ": ").concat(plugin.name);
      });

      return pluginsString;
    },
    detectFonts: function detectFonts() {
      var self = this;
      this.fingerprinter.fontsKey([], function (newFontList) {
        self.set('fonts', newFontList.join(','));
      });
    },
    detectStorage: function detectStorage() {
      var supported = this.fingerprinter.hasLocalStorage() ? 'DOM localStorage: Yes' : 'DOM localStorage: No';
      supported += this.fingerprinter.hasSessionStorage() ? ', DOM sessionStorage: Yes' : ', DOM sessionStorage: No';
      this.set('storage', supported);
    },
    detectGeoLocation: function detectGeoLocation() {
      var self = this;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          self.set('geoLocation', position.coords);
        });
      }
    }
  });

  _exports.default = _default;
});