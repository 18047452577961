define("ember-rs/locales/nl/controllers/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.branding = void 0;
  var branding = {
    "emailUpdateSuccess": "E-mailinstellingen van gebruiker zijn bijgewerkt.",
    "emailUpdateFail": "Fout bij het bijwerken van e-mailinstellingen van de gebruiker.",
    "updateSuccess": "Huisstijlinstellingen voor gebruikersaccounts zijn bijgewerkt.",
    "updateFail": "Fout bij het bijwerken van de huisstijlinstellingen voor gebruikersaccounts."
  };
  _exports.branding = branding;
});