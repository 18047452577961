define("ember-rs/templates/toolbar-icons/checkmark-annotation-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i2ZcyOv6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"13px\"],[14,\"height\",\"9.775px\"],[14,\"viewBox\",\"0 0 13 9.775\"],[14,\"enable-background\",\"new 0 0 13 9.775\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[2,\"\\n\"],[10,\"g\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"rect\"],[14,\"x\",\"6.954\"],[14,\"y\",\"-0.867\"],[14,\"transform\",\"matrix(0.7071 0.7071 -0.7071 0.7071 5.8322 -4.3047)\"],[14,\"width\",\"2.316\"],[14,\"height\",\"11.509\"],[12],[13],[2,\"\\n\\n\\t\\t\"],[10,\"rect\"],[14,\"x\",\"-0.188\"],[14,\"y\",\"5.368\"],[14,\"transform\",\"matrix(-0.7071 -0.7071 0.7071 -0.7071 0.9335 13.4379)\"],[14,\"width\",\"6.876\"],[14,\"height\",\"2.316\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/toolbar-icons/checkmark-annotation-component.hbs"
    }
  });

  _exports.default = _default;
});