define("ember-rs/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h8KTAnex",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.identityExpired.linkExpired\"],null]],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3],[35,2]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"form\"],[4,[38,1],[[32,0],\"extendIdentityToken\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.identityExpired.toSign\"],null]],[13],[2,\"\\n\\n    \"],[10,\"button\"],[14,4,\"submit\"],[12],[1,[30,[36,0],[\"unauthorized.identityExpired.sendLink\"],null]],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.identityExpired.contactSender\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"identity_token\",\"signer_id\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/identity-expired.hbs"
    }
  });

  _exports.default = _default;
});