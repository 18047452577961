define("ember-rs/locales/it/templates/components/reminder-frequency-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reminderFrequency = void 0;
  var reminderFrequency = {
    "noAuto": "Nessun promemoria automatico",
    "daily": "Ogni giorno",
    "weekly": "Ogni settimana"
  };
  _exports.reminderFrequency = reminderFrequency;
});