define("ember-rs/locales/es/mixins/redirect-token-generation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectTokenGeneration = void 0;
  var redirectTokenGeneration = {
    "linkGenerated": "Se generó un nuevo enlace seguro y se envió a su dirección de correo electrónico."
  };
  _exports.redirectTokenGeneration = redirectTokenGeneration;
});