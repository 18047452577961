define("ember-rs/locales/it/templates/components/rs-left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsLeftNav = void 0;
  var rsLeftNav = {
    "Documents": "Documenti",
    "Templates": "Modelli",
    "Account": "Account",
    "Settings": "Impostazioni",
    "Integrations": "Integrazioni",
    "Branding": "Branding",
    "Users": "Utenti",
    "Reports": "Report",
    "Api": "API",
    "Billing": "Fatturazione",
    "Workflows": "Commenti e approvazione"
  };
  _exports.rsLeftNav = rsLeftNav;
});