define("ember-rs/templates/signer/sign/locked-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vK/6MtTL",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\"],[\"applicationModals\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"persistent-modal\",[],[[\"@actionsHidden\",\"@classNames\"],[true,\"template-signer-kba-locked\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"locked-title\"],[12],[1,[30,[36,0],[\"signer.sign.lockedModal.title\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"locked-image\"],[14,\"src\",\"/assets/images/document-locked-empty-state.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"p\"],[14,0,\"locked-subtext\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"signer.sign.lockedModal.maxAttempts\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"locked-subtext\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"signer.sign.lockedModal.locked\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/signer/sign/locked-modal.hbs"
    }
  });

  _exports.default = _default;
});