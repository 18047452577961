define("ember-rs/locales/es/templates/components/component-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentEditor = void 0;
  var componentEditor = {
    "charge": "¿Facturar después de la ejecución?",
    "selectOne": "Seleccione uno...",
    "amount": "Monto del pago (USD):",
    "enterAmount": "Introduzca el monto del pago...",
    "required": "REQUERIDO: Introduzca el texto de ayuda del firmante a continuación, para que el destinatario sepa qué debe cargar.",
    "signerHelp": "Texto de ayuda del firmante:",
    "helpText": "Introduzca el texto de ayuda...",
    "selectBox": "El cuadro de selección necesita al menos 2 opciones.",
    "selectOptions": "Seleccione opciones:",
    "addOption": "Agregar opción...",
    "addToGroup": "Agregar a un grupo",
    "assignedTo": "Asignado a:",
    "fieldRequired": "¿Campo obligatorio?",
    "autoFillDate": "Auto-fill date signed",
    "autoFillDateHelpText": "A recipient will not be able to edit the field. It will be filled in automatically with the date on which the recipient signs.",
    "mergeField": "¿Campo de combinación?",
    "mergeFieldName": "el campo de combinación necesita un nombre",
    "nameMergeField": "Nombre del campo de combinación:",
    "setFontSize": "Establecer tamaño de fuente...",
    "muiltiline": "¿Multilínea?",
    "clearBackground": "¿Sin fondo?",
    "addDefaultValue": "¿Agregar valor predeterminado?",
    "setDefaultValue": "Establecer valor predeterminado...",
    "defaultValue": "Valor predeterminado:",
    "nameThisField": "¿Nombrar este campo?",
    "name": "Nombre:",
    "setName": "Establecer nombre del componente...",
    "textAlign": "Alinear texto",
    "showLess": "Mostrar menos",
    "showMore": "Mostrar más",
    "delete": "Eliminar",
    "saved": "Guardado",
    "save": "Guardar",
    "dateFormat": "Formato de fecha",
    "left": "a la izquierda",
    "center": "centro",
    "right": "derecha",
    "groupRequirement": "Requisito de grupo",
    "only_one": "Solo una",
    "one_or_more": "Una o más",
    "options_warning": "Opción ya agregada"
  };
  _exports.componentEditor = componentEditor;
});