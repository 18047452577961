define("ember-rs/locales/pt-br/templates/components/payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentForm = void 0;
  var paymentForm = {
    "name": "Nome",
    "firstName": "Primeiro nome...",
    "lastName": "Sobrenome...",
    "address1": "Endereço linha 1...",
    "address2": "Endereço linha 2...",
    "city": "Cidade",
    "state": "Estado/Província",
    "zip": "Código postal",
    "country": "País",
    "address": "Endereço",
    "vatId": "ID de IVA",
    "redirect": "Redirecionando...",
    "upgrade": "Atualização",
    "payUpgrade": "Pagar e fazer upgrade",
    "oops": "Algo saiu errado",
    "ok": "OK"
  };
  _exports.paymentForm = paymentForm;
});