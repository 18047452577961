define("ember-rs/locales/zh-cn/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    "choose": "3. 选择要导出的数据",
    "columnNames": "列名称基于文档中的组件的名称:",
    "export": "4. 导出到 CSV",
    "selectColumns": "选择列",
    "view": "可以在 Excel 中打开 CSV 以查看与您已发送的文档有关的数据。"
  };
  _exports.template = template;
});