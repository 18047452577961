define("ember-rs/locales/it/templates/client-application/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTranslations = void 0;
  var newTranslations = {
    "request_key": "Richiedi nuova chiave API",
    "name_tool_tip": "Questo messaggio viene visualizzato per gli utenti che utilizzano OAuth per richiedere l'accesso al proprio account.  Ad esempio, Industry Automative Estimates Generator o WordStar Plugin",
    "name": "Nome:",
    "org_name_tip": "  Nome organizzazione",
    "org_name": "Nome organizzazione:",
    "description_tip": "Questo messaggio viene visualizzato per gli utenti che utilizzano OAuth per richiedere l'accesso al proprio account. Ad esempio, \"Questa applicazione viene utilizzata esclusivamente da Industry Automotive per inviare stime all'interno del nostro flusso di lavoro aziendale\" o \"WordStar Plugin consente agli utenti WordStar di inviare documenti per la firma tramite il proprio account RightSignature&trade;\".",
    "description": "Descrizione:",
    "scopes_tip": "  Livello di accesso desiderato",
    "scopes": "Ambito/i:",
    "app_url_tip": "Questo messaggio viene visualizzato per gli utenti che utilizzano OAuth per richiedere l'accesso al proprio account. Ad esempio, http://industryauto.com/login o http://wordstarsupportgroup.com/theplugin",
    "app_url": "URL applicazione:",
    "redirect_url_tip": "Questo è il callback predefinito per le richieste di autorizzazione OAuth. Può essere specificato o sostituito nelle chiamate API. Ad esempio, http://industryauto.com/oauth_callback",
    "redirect_url": "URL di reindirizzamento:",
    "org_url_tip": "  (facoltativo) Sito Web dell'organizzazione",
    "org_url": "URL organizzazione:",
    "Request": "Richiesta",
    "readOnlyAccess": "Accesso in sola lettura",
    "readWriteAccess": "Accesso in lettura e scrittura"
  };
  _exports.newTranslations = newTranslations;
});