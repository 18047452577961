define("ember-rs/components/rs-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'rs-avatar',
    classNameBindings: ['sizeModifier'],
    sizeModifier: Ember.computed('size', function () {
      var size = this.size || 'medium';
      return "is-".concat(size);
    })
  });

  _exports.default = _default;
});