define("ember-rs/locales/en/templates/components/code-generated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.codeGenerated = void 0;
  var codeGenerated = {
    "copyToClipboard": "Copy to Clipboard",
    "copySuccess": "Copied!",
    "copyError": "Failed to copy to clipboard"
  };
  _exports.codeGenerated = codeGenerated;
});