define("ember-rs/helpers/first-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.firstName = firstName;
  _exports.default = void 0;

  function firstName(input) {
    var value = (Ember.isArray(input) ? input[0] : input) || '';
    return value.split(' ')[0];
  }

  var _default = Ember.Helper.helper(firstName);

  _exports.default = _default;
});