define("ember-rs/components/document-tags-editor", ["exports", "ember-rs/mixins/tags-collection"], function (_exports, _tagsCollection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tagsCollection.default, {
    actions: {
      addNewTagAndCloseModal: function addNewTagAndCloseModal(newTagName, newTagValue) {
        this.send('addNewTag', newTagName, newTagValue);
        this.sendAction('closeModal');
      }
    }
  });

  _exports.default = _default;
});