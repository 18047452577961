define("ember-rs/locales/es/templates/components/integration-document-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocumentSelector = void 0;
  var integrationDocumentSelector = {
    "chooseDocFrom": "Elija un documento de:",
    "loading": "Cargando...",
    "back": "Atrás",
    "search": "Buscar documentos...",
    "use": "Usar",
    "empty": "Esta carpeta está vacía",
    "success": "Envío inhabilitado correctamente para {{{provider}}}",
    "error": "Error: No se pudo modificar el envío para {{{provider}}}"
  };
  _exports.integrationDocumentSelector = integrationDocumentSelector;
});