define("ember-rs/locales/en/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserForm = void 0;
  var newUserForm = {
    "email": "Email",
    "name": "Name",
    "password": "Password",
    "hidePassword": "Hide Password",
    "create": "Create Account",
    "haveAccount": "Have an account?"
  };
  _exports.newUserForm = newUserForm;
});