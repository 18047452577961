define("ember-rs/locales/fr/templates/onboarding/welcome-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding1 = void 0;
  var welcomeOnboarding1 = {
    "welcome": "Bienvenue à RightSignature,",
    "tips": "Nous vous montrerons comment vous orienter dans l'interface, comment utiliser RightSignature et vous offrirons des conseils pour profiter au maximum de notre service"
  };
  _exports.welcomeOnboarding1 = welcomeOnboarding1;
});