define("ember-rs/routes/account/users/reset-mfa", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('accountUser', params.userId);
    },
    actions: {
      reset_mfa: function reset_mfa(model) {
        var _this = this;

        this.controller.set('loading', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/account/users/").concat(model.get('id'), "/reset_mfa"),
          type: 'PUT'
        }).then(function () {
          _this.flashMessages.success(_this.i18n.t('components.account-users.reset2fa.reset-2fa-success-message', {
            userName: model.get('name')
          }));

          _this.controller.set('loading', false);

          _this.send('saveAndCloseModal');
        }, function () {
          _this.flashMessages.warning(_this.i18n.t('components.account-users.reset2fa.reset-2fa-warning-message', {
            userName: model.get('name')
          }));

          _this.controller.set('loading', false);

          _this.send('closeModal');
        });
      },
      saveAndCloseModal: function saveAndCloseModal() {
        this.transitionTo('account.users');
      },
      closeModal: function closeModal(model) {
        this.transitionTo('account.users.edit', model.get('id'));
      }
    }
  });

  _exports.default = _default;
});