define("ember-rs/locales/zh-cn/templates/components/template/template-signer-link-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.templateSignerLinkItem = void 0;
  var templateSignerLinkItem = {
    "view": "查看",
    "details": "详细信息",
    "reenable": "重新启用",
    "disable": "禁用",
    "signed": "已签名",
    "identityMethod": "身份识别方法: ",
    "expires": "过期时间: ",
    "passcode": "受通行码保护: ",
    "maxSigners": "签名者数量上限: ",
    "doesNotExpire": "未过期"
  };
  _exports.templateSignerLinkItem = templateSignerLinkItem;
});