define("ember-rs/locales/es/templates/terms-of-use-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.termsOfUseLink = void 0;
  var termsOfUseLink = {
    "terms": "Términos de uso",
    "url": "https://www.cloud.com/content/dam/citrix/en_us/documents/buy/enterprise-saas-eusa-es.pdf"
  };
  _exports.termsOfUseLink = termsOfUseLink;
});