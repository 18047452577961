define("ember-rs/components/reports/generate-download", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isIeExplorer: Ember.computed('window.navigator.userAgent', function () {
      return navigator.userAgent.indexOf("MSIE") > 0 || navigator.userAgent.indexOf("Trident/") > 0;
    }),
    actions: {
      generateCSV: function generateCSV() {
        var _this = this;

        // http://stackoverflow.com/questions/6410810/rails-not-decoding-json-from-jquery-correctly-array-becoming-a-hash-with-intege
        _jquery.default.ajax({
          type: 'POST',
          url: this.generateURL,
          contentType: 'application/json',
          data: JSON.stringify(this.generateData),
          success: function success(response) {
            if (response.message == 'via_email') {
              _this.set('downloadLater', true);
            } else {
              _this.set('downloadURL', response.url);
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});