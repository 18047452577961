define("ember-rs/components/delete-email-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    //DEFAULTS
    loading: false,
    //ACTIONS
    actions: {
      deleteEmail: function deleteEmail() {
        var _this = this;

        this.set('loading', true);
        this.emailVerification.destroyRecord().then(function () {
          _this.flashMessages.success(_this.i18n.t('components.delete-email-verification.flash-messages.delete-success'));

          _this.set('loading', false);

          _this.closeAction();
        }, function () {
          _this.flashMessages.warning(_this.i18n.t('components.delete-email-verification.flash-messages.delete-warning'));

          _this.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});