define("ember-rs/locales/zh-cn/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationSettingsService = void 0;
  var integrationSettingsService = {
    "requiresInfo": "此集成需要一些信息才能继续:",
    "authorize": "授权",
    "sending": "发送您的 {{{formattedProviderName}}} 文档以从 RightSignature 内部进行电子签名",
    "archiving": "将已完成文档的 PDF 发送到您的 {{{formattedProviderName}}} 帐户中的特定文件夹",
    "contacts": "使您的所有 {{{formattedProviderName}}} 联系人都可以对文档进行签名",
    "payments": "将 Payment Components 放在您的 RightSignature 文档中，以便安全地收集和存储信用卡信息并通过您的 {{{formattedProviderName}}} 帐户授权扣费",
    "fieldsInfo": "请填写所有字段"
  };
  _exports.integrationSettingsService = integrationSettingsService;
});