define("ember-rs/locales/zh-cn/templates/onboarding/send-onboarding-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding4 = void 0;
  var sendOnboarding4 = {
    "close": "选择<span>后退箭头</span>可关闭这些详细信息并转至“文档”控制板"
  };
  _exports.sendOnboarding4 = sendOnboarding4;
});