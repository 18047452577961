define("ember-rs/locales/es/templates/reusable-templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTrans = void 0;
  var newTrans = {
    "upload": "Cargar",
    "dragDrop": "Arrastrar y soltar archivos aquí o:",
    "uploading": "Cargando",
    "uploadFile": "Cargar un archivo",
    "chooseCloud": "elegirlos desde la nube",
    "prepare": "preparar el documento"
  };
  _exports.newTrans = newTrans;
});