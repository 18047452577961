define("ember-rs/locales/de/templates/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "share": "Freigeben",
    "status": "Status",
    "overview": "Übersicht",
    "sent": "gesendet",
    "people_involved": "Beteiligte Personen:",
    "reference_id": "Referenz-ID: ",
    "manage": "Verwalten",
    "tags": "Tags: "
  };
  _exports.details = details;
});