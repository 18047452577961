define("ember-rs/locales/zh-cn/routes/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "tryAgain": "出现错误! 请重试。"
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});