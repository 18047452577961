define("ember-rs/locales/nl/templates/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "share": "Delen",
    "status": "Status",
    "overview": "Overzicht",
    "sent": "verzonden",
    "people_involved": "Betrokken personen:",
    "reference_id": "Referentie-id: ",
    "manage": "Beheren",
    "tags": "Tags: "
  };
  _exports.details = details;
});