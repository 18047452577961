define("ember-rs/locales/it/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rs3LoginRedirect = void 0;
  var rs3LoginRedirect = {
    "looking": "Si sta cercando RightSignature&trade; - ShareFile Edition?",
    "sharefileEdition": "Questa pagina è stata raggiunta perché si è cercato di accedere a una versione precedente di RightSignature&trade;. In base alle informazioni di cui disponiamo, l'account è per RightSignature&trade; - ShareFile Edition. Accedere qui sotto.",
    "support": "In caso di domande, <a href='https://support.citrix.com/cscase'>contattare il supporto</a> o chiamare il numero 1-800-921-4250"
  };
  _exports.rs3LoginRedirect = rs3LoginRedirect;
});