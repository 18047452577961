define("ember-rs/routes/application", ["exports", "jquery", "lodash", "ember-rs/mixins/invalidate-and-sign-out", "ember-rs/config/environment"], function (_exports, _jquery, _lodash, _invalidateAndSignOut, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_invalidateAndSignOut.default, {
    ////////////////////////  BINDINGS  ////////////////////////
    queryParams: {
      token: {
        refreshModel: true
      }
    },
    ////////////////////////  DEPENDENCIES  ////////////////////////
    redirectToken: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    session: Ember.inject.service(),
    ////////////////////////  HOOKS  ////////////////////////
    moment: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      (0, _jquery.default)('body').addClass("".concat(transition.to.queryParams.theme || 'default', "-theme"));
    },
    model: function model(params, transition) {
      if (params.token) {
        this.redirectToken.setValue(params.token);
        return this.transitionTo({
          queryParams: _lodash.default.omit(transition.to.queryParams, ['token'])
        });
      }

      return this._super.apply(this, arguments);
    },
    afterModel: function afterModel() {
      var _this = this;

      this._super.apply(this, arguments);

      var locale = "en";

      if (/MSIE|Trident/.test(navigator.userAgent)) {
        //check for IE
        _jquery.default.ajax({
          url: "".concat(_environment.default.api.host, "/utils/locale"),
          success: function success(data) {
            locale = data;

            _this.setLocale(_this.getEmberLocale(locale));
          },
          error: function error() {
            _this.setLocale(locale);
          }
        });
      } else {
        locale = (navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage).toLowerCase();
        this.setLocale(this.getEmberLocale(locale));
      }
    },
    title: function title(tokens) {
      var titleTokens = Ember.A(['RightSignature', tokens]);
      return _lodash.default.flatten(titleTokens).join(' | ');
    },
    setLocale: function setLocale(locale) {
      this.set("i18n.locale", locale);
      this.moment.setLocale(locale);
    },
    getEmberLocale: function getEmberLocale(locale) {
      if (_environment.default.EmberENV.available_locales.includes(locale)) {
        if (locale.substring(0, 2) == 'zh') {
          locale = 'zh-CN';
        } else if (locale.substring(0, 2) == 'pt') {
          locale = 'pt-BR';
        } else {
          locale = locale.substring(0, 2);
        }
      } else {
        locale = 'en';
      }

      return locale;
    },
    ////////////////////////  METHODS  ////////////////////////
    // Invalidate session and display email verification page
    displayEmailVerificationInstructions: function displayEmailVerificationInstructions() {
      var _this2 = this;

      this.clearUserSession();

      if (this.get('session.isAuthenticated')) {
        this.session.invalidate().then(function () {
          _this2.replaceWith('email-unverified');
        });
      }

      this.replaceWith('email-unverified');
    },
    // Actions =================================
    actions: {
      invalidateSession: function invalidateSession() {
        this.invalidateAndSignOut();
      },
      signOut: function signOut() {
        this.invalidateAndSignOut();
      },
      toggleMobileNavMenu: function toggleMobileNavMenu() {
        this.toggleProperty('layoutSizeData.showMobileNavMenu');
      },
      transitionToRoot: function transitionToRoot() {
        this.transitionTo('documents');
      }
    }
  });

  _exports.default = _default;
});