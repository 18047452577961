define("ember-rs/templates/components/mobile-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LjwrG1nR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"mobile-header \",[30,[36,2],[[35,1,[\"model\",\"account\",\"isTrial\"]],\"user-in-trial\"],null],\" \",[30,[36,2],[[35,1,[\"model\",\"account\",\"isHybrisTrial\"]],\"user-in-trial\"],null]]]],[12],[2,\"\\n  \"],[11,\"i\"],[24,0,\"fa fa-bars\"],[4,[38,0],[[32,0],\"toggleMobileNavMenu\"],null],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mobile-header-title\"],[12],[2,\"\\n    \"],[1,[34,3]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[19,\"document/search-header-mobile\",[]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[19,\"template/search-header-mobile\",[]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"info-banner\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,2],[[35,6,[\"showMobileNavMenu\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"close-nav-mask\"],[4,[38,0],[[32,0],\"toggleMobileNavMenu\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"action\",\"currentUser\",\"if\",\"title\",\"showDocumentSearch\",\"showTemplateSearch\",\"layoutSizeData\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/mobile-header.hbs"
    }
  });

  _exports.default = _default;
});