define("ember-rs/locales/pt-br/templates/components/template/template-signer-link-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.templateSignerLinkItem = void 0;
  var templateSignerLinkItem = {
    "view": "Exibir",
    "details": "Detalhes",
    "reenable": "Reativar",
    "disable": "Desativar",
    "signed": "assinado",
    "identityMethod": "Método de identidade: ",
    "expires": "Expira: ",
    "passcode": "Protegido por código secreto: ",
    "maxSigners": "Quantidade máx. de signatários: ",
    "doesNotExpire": "Não expira"
  };
  _exports.templateSignerLinkItem = templateSignerLinkItem;
});