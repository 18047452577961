define("ember-rs/controllers/reports/user", ["exports", "ember-rs/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    generateURL: Ember.computed('model.id', function () {
      return "".concat(_environment.default.api.endpoint, "/account/reports/user");
    })
  });

  _exports.default = _default;
});