define("ember-rs/locales/de/templates/onboarding/post-sign-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding1 = void 0;
  var postSignOnboarding1 = {
    "tapGear": "<span>Tippen Sie auf das Zahnrad</span>, um das Dokument und weitere Informationen herunterzuladen.",
    "viewDoc": "Hier sehen Sie den <span>Status</span> des Dokuments"
  };
  _exports.postSignOnboarding1 = postSignOnboarding1;
});