define("ember-rs/components/paywall-button", ["exports", "ember-rs/components/paywall-action"], function (_exports, _paywallAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paywallAction.default.extend({
    showModal: false,
    allowUnentitled: false,
    showToggles: false,
    actions: {
      buttonPressed: function buttonPressed() {
        var _Ember$getProperties = Ember.getProperties(this, 'accessible', 'entitled', 'allowUnentitled'),
            accessible = _Ember$getProperties.accessible,
            entitled = _Ember$getProperties.entitled,
            allowUnentitled = _Ember$getProperties.allowUnentitled;

        if (accessible && (entitled || allowUnentitled)) {
          this.sendAction();
        } else {
          Ember.set(this, 'showModal', true);
        }
      }
    }
  });

  _exports.default = _default;
});