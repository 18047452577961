define("ember-rs/locales/it/templates/components/support-when-externally-billed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportWhenExternallyBilled = void 0;
  var supportWhenExternallyBilled = {
    "partnerSale": "L'account è stato creato tramite una vendita di partner. Contattare il supporto tecnico se si desidera aggiornare l'account."
  };
  _exports.supportWhenExternallyBilled = supportWhenExternallyBilled;
});