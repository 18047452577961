define("ember-rs/locales/it/templates/components/integration-document-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocumentSelector = void 0;
  var integrationDocumentSelector = {
    "chooseDocFrom": "Scegli un documento da:",
    "loading": "Caricamento...",
    "back": "Indietro",
    "search": "Cerca documenti...",
    "use": "Utilizza",
    "empty": "La cartella è vuota",
    "success": "Invio disabilitato correttamente per {{{provider}}}",
    "error": "Errore. Impossibile modificare l'invio per {{{provider}}}"
  };
  _exports.integrationDocumentSelector = integrationDocumentSelector;
});