define("ember-rs/locales/es/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding1 = void 0;
  var accountOnboarding1 = {
    "body": "Cambie <span>sus parámetros</span>, <span>marca personalizada</span> su cuenta, <span>intégrelo </span> con otras herramientas y mucho más."
  };
  _exports.accountOnboarding1 = accountOnboarding1;
});