define("ember-rs/components/rs-modal", ["exports", "jquery", "ember-rs/utils/media"], function (_exports, _jquery, _media) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layoutSizeData: Ember.inject.service(),
    classNameBindings: [':rs-modal', 'class', 'show:show', 'centered'],
    centered: true,
    invisibleShade: false,
    dismissable: true,
    innerHeight: null,
    orientationchangeModalTimeout: null,
    mobileMaxHeight: Ember.computed('show', 'innerHeight', function () {
      return "max-height: ".concat(this.innerHeight, "px;");
    }),
    onRotationChange: function onRotationChange() {
      this.set('innerHeight', window.innerHeight);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var context = (0, _jquery.default)(window);
      var width = context.width();
      var height = context.height();

      if ((0, _media.hasMobileUserAgent)() && Math.max(width, height) < _media.PHONE_SIZE && this.setMaxHeight) {
        this.onRotationChange();
        context.on('orientationchange.rsmodal', function () {
          _this.set('orientationchangeModalTimeout', Ember.run.later(function () {
            _this.onRotationChange();
          }, 500));
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.setMaxHeight) {
        Ember.run.cancel(this.orientationchangeModalTimeout);
        (0, _jquery.default)(window).off('orientationchange.rsmodal');
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this2 = this;

      Ember.run.next(function () {
        return _this2.set('layoutSizeData.showModal', _this2.attrs.show);
      });
    },
    shadeShowing: Ember.computed('show', 'layoutSizeData.useMobileLayout', function () {
      return this.show && !this.get('layoutSizeData.useMobileLayout');
    }),
    actions: {
      closeIfDismissable: function closeIfDismissable() {
        if (this.dismissable) {
          this.sendAction();
        }
      }
    }
  });

  _exports.default = _default;
});