define("ember-rs/mixins/invalidate-and-sign-out", ["exports", "ember-rs/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    //////////////////      DEPENDENCIES    ////////////////////////
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    //////////////////      METHODS    ////////////////////////
    invalidateAndSignOut: function invalidateAndSignOut() {
      var _this = this;

      if (Ember.get(this, 'session.isAuthenticated')) {
        return this.store.findRecord("sessionCredential", "current").then(function (sessionCredential) {
          return sessionCredential.destroyRecord().then(function () {
            return _this.session.invalidate().then(function () {
              window.location = _environment.default.marketingHost;
            });
          });
        });
      } else {
        window.location = _environment.default.marketingHost;
      }
    }
  });

  _exports.default = _default;
});