define("ember-rs/components/reports/top-users-by-efficiency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var gcd = function gcd(a, b) {
    if (!b) {
      return a;
    }

    return gcd(b, a % b);
  };

  var _default = Ember.Component.extend({
    topUsers: Ember.computed('report.top_users', function () {
      var output = [];
      var topUsers = this.get('report.top_users');

      if (topUsers) {
        for (var i = 0, iMax = topUsers.length, user, gcdRatio; i < iMax; i++) {
          user = topUsers[i];
          gcdRatio = gcd(user.signed, user.sent);
          output.push({
            name: user.name,
            percentage: user.ratio * 100,
            signedOverGCD: user.signed / gcdRatio,
            sentOverGCD: user.sent / gcdRatio
          });
        }
      }

      return output;
    })
  });

  _exports.default = _default;
});