define("ember-rs/locales/zh-cn/templates/unauthorized/unconfirmed-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedEmail = void 0;
  var unconfirmedEmail = {
    "loginFailed": "登录失败",
    "loginFailedTip": "必须先确认您的 ShareFile 帐户电子邮件。请查看您的电子邮件中来自 ShareFile 的确认链接。"
  };
  _exports.unconfirmedEmail = unconfirmedEmail;
});