define("ember-rs/locales/en/templates/unauthorized", ["exports", "ember-rs/locales/en/templates/unauthorized/already-signed", "ember-rs/locales/en/templates/unauthorized/confirm-identity-expired", "ember-rs/locales/en/templates/unauthorized/document-executed", "ember-rs/locales/en/templates/unauthorized/document-expired", "ember-rs/locales/en/templates/unauthorized/document-voided", "ember-rs/locales/en/templates/unauthorized/document-declined", "ember-rs/locales/en/templates/unauthorized/document-editing", "ember-rs/locales/en/templates/unauthorized/expired", "ember-rs/locales/en/templates/unauthorized/identity-expired", "ember-rs/locales/en/templates/unauthorized/no-integrations", "ember-rs/locales/en/templates/unauthorized/no-user", "ember-rs/locales/en/templates/unauthorized/pending-identity-verification", "ember-rs/locales/en/templates/unauthorized/not-your-turn", "ember-rs/locales/en/templates/unauthorized/redirect-to-rs3", "ember-rs/locales/en/templates/unauthorized/rs3-login-redirect", "ember-rs/locales/en/templates/unauthorized/signing-link-disabled", "ember-rs/locales/en/templates/unauthorized/unauthorized", "ember-rs/locales/en/templates/unauthorized/unconfirmed-email", "ember-rs/locales/en/templates/unauthorized/unsubscribed"], function (_exports, _alreadySigned, _confirmIdentityExpired, _documentExecuted, _documentExpired, _documentVoided, _documentDeclined, _documentEditing, _expired, _identityExpired, _noIntegrations, _noUser, _pendingIdentityVerification, _notYourTurn, _redirectToRs, _rs3LoginRedirect, _signingLinkDisabled, _unauthorized, _unconfirmedEmail, _unsubscribed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unauthorized = void 0;
  var unauthorized = {
    alreadySigned: _alreadySigned.alreadySigned,
    confirmIdentityExpired: _confirmIdentityExpired.confirmIdentityExpired,
    documentExecuted: _documentExecuted.documentExecuted,
    documentExpired: _documentExpired.documentExpired,
    documentVoided: _documentVoided.documentVoided,
    documentDeclined: _documentDeclined.documentDeclined,
    documentEditing: _documentEditing.documentEditing,
    expired: _expired.expired,
    identityExpired: _identityExpired.identityExpired,
    noIntegrations: _noIntegrations.noIntegrations,
    noUser: _noUser.noUser,
    pendingIdentityVerification: _pendingIdentityVerification.pendingIdentityVerification,
    notYourTurn: _notYourTurn.notYourTurn,
    redirectToRs3: _redirectToRs.redirectToRs3,
    rs3LoginRedirect: _rs3LoginRedirect.rs3LoginRedirect,
    signingLinkDisabled: _signingLinkDisabled.signingLinkDisabled,
    unauthorizedFile: _unauthorized.unauthorizedFile,
    unconfirmedEmail: _unconfirmedEmail.unconfirmedEmail,
    unsubscribed: _unsubscribed.unsubscribed,
    "return": "Return to RightSignature"
  };
  _exports.unauthorized = unauthorized;
});