define("ember-rs/locales/ja/templates/document/prepare-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_document = void 0;
  var prepare_document = {
    "prepare": "準備 ",
    "prepare_package": "ドキュメント パッケージの準備",
    "merge_field_message": "このドキュメントに含まれるマージ フィールドは送信前に入力する必要があります。",
    "merge_field_title": "マージ フィールドに記入",
    "missing_merge_fields": "すべての必須マージ フィールドに入力する必要があります。"
  };
  _exports.prepare_document = prepare_document;
});