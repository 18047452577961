define("ember-rs/locales/es/templates/components/documents/document-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentRow = void 0;
  var documentRow = {
    "signed": "firmados",
    "signer": "Firmante(s)",
    "legacy": " - Documento antiguo",
    "awaitingSignature": "En espera de su firma",
    "sign": "firmar",
    "completed": "Completados ",
    "sent": "Enviado",
    "voided": "Anulados",
    "expired": "Caducados ",
    "auth_failure": "Error al autenticar al firmante a través de la sesión: {{{authError}}}"
  };
  _exports.documentRow = documentRow;
});