define("ember-rs/locales/nl/templates/upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgrade = void 0;
  var upgrade = {
    "noUpgrade": "Kan account niet upgraden",
    "noPermission": "Het lijkt erop dat u niet gemachtigd bent om uw account te upgraden. Neem contact op met uw accountbeheerder over het upgraden naar een nieuw RightSignature-abonnement.",
    "upgrade": "Uw account upgraden",
    "upgradeSubtitle": "Tijdens uw proefperiode hebt u een Bedrijfsabonnement gehad. U kunt op dit abonnement blijven of een ander abonnement kiezen dat beter op uw behoeften is afgestemd.",
    "choosePlan": "1. Selecteer een abonnement",
    "allInOne": "De alles-in-één digitale handtekeningoplossing",
    "standard": "Standaardfuncties ",
    "usersNeeded": "2. Hoeveel gebruikers hebt u nodig?",
    "checkout": "Betaling",
    "showLess": "Minder weergeven",
    "showMore": "Meer weergeven",
    "users": "Gebruikers",
    "confirm": "Druk op Enter om te bevestigen",
    "billing": "Facturering",
    "billingDetails": "Factureringsgegevens",
    "noOfUser": "Aantal gebruikers",
    "billAmount": "Factureerbaar bedrag",
    "creditAmount": "Kredietbedrag uit oude RightSignature",
    "total": "Totaal",
    "taxCharges": "er kunnen extra belastingkosten van toepassing zijn",
    "oops": "Er is iets fout gegaan",
    "dataUnavailable": "Er zijn op dit moment geen gegevens beschikbaar. Kom later terug naar deze pagina."
  };
  _exports.upgrade = upgrade;
});