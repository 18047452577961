define("ember-rs/controllers/reusable-template/prepare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    layoutSizeData: Ember.inject.service(),
    session: Ember.inject.service(),
    wcsWorkflow: Ember.inject.service(),
    showPrepareTutorial: Ember.computed.not('currentUser.model.hasSeenPrepareOnboarding'),
    isSaving: false,
    nextStepText: Ember.computed(function () {
      if (this.wcsWorkflow.getValue()) {
        //iframed for WCS Workflow
        return this.i18n.t('template.review.create-button');
      } else {
        return this.i18n.t('reusableTemplate.prepare.next');
      }
    })
  });

  _exports.default = _default;
});