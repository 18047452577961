define("ember-rs/locales/nl/templates/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "send": "Verz. voor ondertekening",
    "notAllowed": " Met uw huidige abonnement kunt u geen documenten verzenden. Upgrade uw abonnement om toegang tot deze functie te krijgen.",
    "embed": "In website insluiten",
    "share": "Koppeling voor het delen genereren",
    "manageLinks": "Koppelingen en insluitingen beheren",
    "viewResponses": "Antwoorden weergeven",
    "edit": "Bewerken",
    "delete": "Verwijderen",
    "roles": "Rollen",
    "security": "Beveiligingsopties:",
    "expires": "Verloopt over:",
    "days": "dagen",
    "passcodeQuestion": "Wachtwoordcodevraag:",
    "passcode": "Wachtwoordcode:",
    "tags": "Tags:",
    "linkHistory": "Geschiedenis koppelingen en insluitcodes",
    "linkHistoryTip": "Gebruiksinformatie voor alle koppelingen en insluitingen van deze sjabloon beheren en weergeven.",
    "generate": "Koppeling voor het delen genereren",
    "nameLink": "Geef deze deelkoppeling een naam.",
    "nameLinkTip": "Hierdoor kunt u koppelingen gemakkelijker beheren.",
    "whichSigner": "Welke ondertekenaarsrol?",
    "whichSignerTip": "Ingesloten sjablonen en koppelingen kunnen slechts één ondertekenaar hebben.",
    "limitUses": "Aantal keer gebruiken beperken?",
    "limitUsesTip": "Stel een limiet in voor het aantal keer dat via deze koppeling kan worden ondertekend.",
    "expireDays": "Verlopen na aantal dagen?",
    "expireDaysTip": "Na de verlooptijd werkt deze koppeling niet meer.",
    "identityMethod": "Identiteitsmethode:",
    "identityMethodTip": "Hoe moet de identiteit van de ondertekenaars worden geverifieerd?",
    "generateLink": "Koppeling genereren",
    "generateEmbed": "Insluitcode genereren",
    "nameCode": "Geef een naam voor deze code op.",
    "nameCodeTip": "Hierdoor kunt u de gegenereerde codes gemakkelijker beheren.",
    "displayType": "Weergavetype",
    "displayTypeTip": "Dit geeft aan hoe de insluiting moet worden weergegeven",
    "pixel": "px",
    "codeGenerated": "Code gegenereerd",
    "linkGenerated": "Koppeling gegenereerd",
    "clickingManage": "door op de sjabloonpagina op \"Koppelingen en insluitingen beheren\" te klikken.",
    "youCanAccessCode": "U hebt toegang tot deze code of kunt de code uitschakelen en bewerken door op de sjabloonpagina op \"Koppelingen en insluitingen beheren\" te klikken.",
    "youCanAccessLink": "U hebt toegang tot deze koppeling of kunt de koppeling uitschakelen en bewerken door op de sjabloonpagina op \"Koppelingen en insluitingen beheren\" te klikken.",
    "newPin": "Nieuwe pincode",
    "done": "Gereed",
    "email": "E-mail",
    "sms": "SMS",
    "none": "Geen",
    "height": "Hoogte",
    "Width": "Breedte",
    "button": "knop",
    "image": "Beeld",
    "inline": "inline",
    "createdBy": "Gemaakt door",
    "actions": "Acties",
    "visibility-toggle-text": "Sjabloon instellen op privé",
    "visibility-toogle-info": "Door sjablonen op privé in te stellen zijn ze alleen zichtbaar voor de auteur",
    "template-signer-link": {
      "passcode-message": "De wachtwoordcodefunctie is ingeschakeld voor dit account, maar wordt niet afgedwongen voor gedeelde koppelingen of ingesloten documenten. Met deze koppeling/insluitcode hebt u zonder wachtwoordcode toegang tot dit document."
    },
    "embed-in-website": {
      "disabled-merge-fields": "Er zijn momenteel geen insluitcodes beschikbaar voor deze sjabloon. Als u een insluitcode voor deze sjabloon wilt genereren, verwijdert u eerst alle vereiste samenvoegvelden.",
      "disabled-no-online-forms": "Met uw huidige abonnement kunt u geen sjablonen insluiten. Upgrade uw abonnement om toegang tot deze functie te krijgen."
    },
    "generate-share-link": {
      "disabled-merge-fields": "Er zijn momenteel geen insluitcodes beschikbaar voor deze sjabloon. Als u een insluitcode voor deze sjabloon wilt genereren, verwijdert u eerst alle vereiste samenvoegvelden.",
      "disabled-no-manual-distribution": "Met uw huidige abonnement kunt u geen koppelingen voor het delen genereren. Upgrade uw abonnement om toegang tot deze functie te krijgen."
    }
  };
  _exports.details = details;
});