define("ember-rs/locales/fr/templates/onboarding/new-user-onboarding-from-sharefile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFromSharefile = void 0;
  var newUserOnboardingFromSharefile = {
    "title": "Envoyer &amp; signer vos documents",
    "startText": "Envoyez vos documents à signer ou signez les documents qui requièrent votre signature.",
    "clickLearn": "Cliquez sur suivant pour savoir comment procéder.",
    "addSigner": "Ajouter des signataires",
    "addAnyone": "Ajoutez toute personne qui doit remplir ou signer ce document.",
    "prepare": "Préparer le document",
    "placeFields": "Placez les champs là où vous voulez que les signataires fournissent des informations ou signent le document. Vous pouvez attribuer des champs à chaque signataire.",
    "sendTrack": "Envoyer et suivre la progression",
    "onceSent": "Une fois que vous avez envoyé le document, vous pouvez suivre sa progression sur la page de détails du document. Pour accéder à la page de détails, cliquez simplement sur le document à partir du tableau de bord de votre document."
  };
  _exports.newUserOnboardingFromSharefile = newUserOnboardingFromSharefile;
});