define("ember-rs/locales/it/templates/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlay = void 0;
  var importOverlay = {
    "importFrom": "Importa campi da:",
    "toImport": "componente/i da importare da",
    "page": "pagina/e",
    "cancel": "Annulla",
    "import": "Importa",
    "noComponents": "Nessun componente da importare",
    "pastDocs": "Importa campi da documenti precedenti",
    "noMatch": "Nessun documento trovato corrispondente a questo nome",
    "importSuccess": "{{{components}}} componenti importati correttamente."
  };
  _exports.importOverlay = importOverlay;
});