define("ember-rs/locales/es/templates/components/agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "welcome": "Bienvenido",
    "toRS": "a RightSignature,",
    "bestOnDesktop": "RightSignature funciona mejor en un equipo de escritorio.",
    "pleaseRead": "Antes de empezar la visita, lea lo siguiente:",
    "terms": "Mediante el uso de RightSignature&trade; usted certifica que ha leído y está de acuerdo con nuestros ",
    "and": "y",
    "policy": "Directiva de privacidad",
    "no": "No, gracias",
    "yes": "Acepto"
  };
  _exports.agreeToTerms = agreeToTerms;
});