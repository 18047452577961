define("ember-rs/models/attachment-component", ["exports", "ember-rs/models/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    overlayerData: Ember.inject.service(),
    hasHelpText: Ember.computed.notEmpty('helpText'),
    isValid: Ember.computed('helpText', function () {
      return this._super() && this.isValidHelpText;
    }),
    isValidHelpText: Ember.computed('helpText', 'isAttachment', function () {
      return this.hasHelpText;
    }),
    isInvalidHelpText: Ember.computed.not('isValidHelpText')
  });

  _exports.default = _default;
});