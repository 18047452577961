define("ember-rs/instance-initializers/ajax-session-header", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ajax-session-header',
    initialize: function initialize(instance) {
      _jquery.default.ajaxSetup({
        beforeSend: function beforeSend(xhr, settings) {
          if (settings.url.match(_environment.default.api.endpoint)) {
            if (instance.lookup('service:session') && instance.lookup('service:session').get("isAuthenticated")) {
              var accessToken = instance.lookup('service:session').get("data.authenticated.access_token");
              xhr.setRequestHeader("Authorization", "Bearer ".concat(accessToken));
            }
          }
        }
      });
    }
  };
  _exports.default = _default;
});