define("ember-rs/controllers/user/settings", ["exports", "jquery", "ember-rs/mixins/settings", "ember-rs/helpers/is-valid-email", "ember-rs/config/environment", "ember-rs/utils/mfa-reasons"], function (_exports, _jquery, _settings, _isValidEmail, _environment, _mfaReasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_settings.default, {
    flashMessages: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    loading: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    showAddVerifiedEmail: false,
    newVerifiedEmail: '',
    showMfaWaiverModal: false,
    showAttachedCompletedPdfConfirmationModal: false,
    showOptOutModal: false,
    mfaReasons: _mfaReasons.mfaReasons,
    hasNoSavedSignatures: Ember.computed.empty('model.signatures'),
    verifiedEmailVerifications: Ember.computed.filterBy('model.emailVerifications', 'verified', true),
    hasSelectedMfaReason: Ember.computed('mfaReasons.@each.selected', function () {
      return this.mfaReasons.isAny("selected", true);
    }),
    invalidNewEmail: Ember.computed('model.emailVerifications', 'newVerifiedEmail', function () {
      var _this = this;

      if (Ember.isBlank(this.newVerifiedEmail) || Ember.get(this, 'model.emailVerifications').filter(function (emailVerification) {
        var _Ember$getProperties = Ember.getProperties(emailVerification, 'verified', 'email'),
            verified = _Ember$getProperties.verified,
            email = _Ember$getProperties.email;

        return verified === true && email === _this.newVerifiedEmail;
      }).get('length') > 0) {
        return true;
      }

      return !(0, _isValidEmail.isValidEmail)(this.newVerifiedEmail);
    }),
    // logic for signature type toggles
    onlyOneSignatureTypeRemaining: Ember.computed('model.allowDrawnSignature', 'model.allowTypedSignature', function () {
      var values = [this.get('model.allowDrawnSignature'), this.get('model.allowTypedSignature')];
      var remainingAllowedTypes = values.filter(function (setting) {
        return setting === true;
      });

      if (remainingAllowedTypes.length === 1) {
        return true;
      }

      return false;
    }),
    allowDrawnSignatureToggleEnabled: Ember.computed('onlyOneSignatureTypeRemaining', 'model.allowDrawnSignature', function () {
      var lastToggleEnabled = this.onlyOneSignatureTypeRemaining;
      var toggleEnabled = this.get('model.allowDrawnSignature');

      if (lastToggleEnabled && toggleEnabled) {
        return false;
      } else {
        return true;
      }
    }),
    allowTypedSignatureToggleEnabled: Ember.computed('onlyOneSignatureTypeRemaining', 'model.allowTypedSignature', function () {
      var lastToggleEnabled = this.onlyOneSignatureTypeRemaining;
      var toggleEnabled = this.get('model.allowTypedSignature');

      if (lastToggleEnabled && toggleEnabled) {
        return false;
      } else {
        return true;
      }
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      makeSignatureDefault: function makeSignatureDefault(signature) {
        var _this2 = this;

        if (signature.get('isDefault')) {
          return;
        }

        var i18n = this.i18n;
        signature.makeDefault().then(function () {
          _this2.flashMessages.success(i18n.t('account.settings.saved-signature-settings.success'));
        }, function () {
          _this2.flashMessages.warning(i18n.t('account.settings.saved-signature-settings.error'));
        });
      },
      toggleAddVerifiedEmail: function toggleAddVerifiedEmail() {
        this.toggleProperty('showAddVerifiedEmail');
      },
      sendVerificationEmail: function sendVerificationEmail() {
        var _this3 = this;

        this.set('showAddVerifiedEmail', false);
        this.store.createRecord('email-verification', {
          email: this.newVerifiedEmail
        }).save().then(function (emailVerification) {
          Ember.get(_this3, 'model.emailVerifications').pushObject(emailVerification);

          _this3.flashMessages.success(_this3.i18n.t('components.unconfirmedUserButton.verificationMail', {
            email: _this3.newVerifiedEmail
          }));

          _this3.set('newVerifiedEmail', '');
        });
      },
      toggleMfaWaiver: function toggleMfaWaiver() {
        this.toggleProperty('showMfaWaiverModal');
      },
      toggleShowAttachedCompletedPdfConfirmationModal: function toggleShowAttachedCompletedPdfConfirmationModal() {
        this.toggleProperty('showAttachedCompletedPdfConfirmationModal');
      },
      showConsentForm: function showConsentForm() {
        this.toggleProperty('showMfaWaiverModal');
        this.toggleProperty('showOptOutModal');
      },
      toggleOptOutAndResetOptions: function toggleOptOutAndResetOptions() {
        if (this.showOptOutModal) {
          this.toggleProperty('showOptOutModal');
        }

        this.mfaReasons.forEach(function (mfaReason) {
          Ember.set(mfaReason, "selected", false);
        });
      },
      declineMfa: function declineMfa() {
        var _this4 = this;

        Ember.set(this, 'loading', true);
        var optOutReason = this.mfaReasons.filterBy("selected", true).mapBy("id");
        return _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/account/settings"),
          type: 'PUT',
          data: {
            "settings": {
              "opt_out_reason": optOutReason
            }
          }
        }).then(function () {
          _this4.currentUser.reloadAccount().then(function () {
            _this4.send('toggleOptOutAndResetOptions');

            _this4.flashMessages.warning(_this4.i18n.t('account.settings.mfa-settings.opted-out-toast'), {
              sticky: true
            });

            Ember.set(_this4, 'loading', false);
          });
        }).catch(function () {
          Ember.set(_this4, 'loading', false);

          _this4.flashMessages.warning('Error updating setting');
        });
      },
      mfaReasonChecked: function mfaReasonChecked(mfaReasonId) {
        var mfaReason = this.mfaReasons.findBy("id", mfaReasonId);
        Ember.set(mfaReason, "selected", !mfaReason.selected);
      }
    }
  });

  _exports.default = _default;
});