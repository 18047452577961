define("ember-rs/locales/zh-cn/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "notAllowed": "不允许的操作",
    "noPro": "您当前没有此帐户的 PRO 访问权限。可以签名和查看您的文档，但 PRO 功能不可用。请与此帐户的管理员联系以授予您 PRO 访问权限，以便您能够执行以下操作:",
    "send": "发送文档以获取签名",
    "create": "创建和使用模板",
    "customize": "自定义外观方案",
    "enable": "启用第三方集成",
    "reports": "查看报告",
    "login": "登录 RightSignature"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});