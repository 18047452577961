define("ember-rs/routes/cc-gateway-redirect", ["exports", "ember-rs/mixins/upgrade-worldpay"], function (_exports, _upgradeWorldpay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_upgradeWorldpay.default, {
    //SuccessParams: orderid=RS_TBuoLe1EH&stateid=&status=SUCCESS
    //FailureParams: orderid=RS_Pl7L1rsYx&status=failed&errorRefNumber=D190925-T131624-M671-84
    //&errorMessage=XML%20failed%20validation%3A%20Invalid%20payment%20details%20%3A%20Card%20number%20not%20recognised%3A%20466714******2847
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var cardStatus = transition.to.queryParams.status;
      var orderId = transition.to.queryParams.orderid;
      var errorMessage = transition.to.queryParams.errorMessage;
      var billingDetails = this.fetchBillingDetails();

      if (billingDetails && cardStatus.toLowerCase() === 'success') {
        billingDetails.order_id = orderId;
        this.upgradeToHybris(billingDetails).catch(function (errorMessage) {
          _this.replaceWith('upgrade-worldpay', {
            queryParams: {
              orderId: billingDetails.order_id,
              errorMessage: errorMessage
            }
          });
        });
      } else if (errorMessage) {
        this.replaceWith('upgrade-worldpay', {
          queryParams: {
            errorMessage: decodeURIComponent(errorMessage)
          }
        });
      } else {
        this.replaceWith('upgrade-worldpay', {
          queryParams: {
            errorMessage: this.i18n.t('routes.ccGatewayRedirect.tryAgain')
          }
        });
      }
    }
  });

  _exports.default = _default;
});