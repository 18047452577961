define("ember-rs/locales/it/mixins/redirect-token-generation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectTokenGeneration = void 0;
  var redirectTokenGeneration = {
    "linkGenerated": "Un nuovo collegamento sicuro è stato generato e inviato all'indirizzo e-mail dell'utente."
  };
  _exports.redirectTokenGeneration = redirectTokenGeneration;
});