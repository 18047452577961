define("ember-rs/mixins/redirect-token-generation", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    flashMessages: Ember.inject.service(),
    redirectToken: Ember.inject.service(),
    ////////////////////////  PARAMS  ////////////////////////
    queryParams: ['email', 'document_id', 'signer_id', {
      token: {
        as: 'previousRedirectToken'
      }
    }],
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      requestNewToken: function requestNewToken() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.post("".concat(_environment.default.api.endpoint, "/redirect_tokens"), {
            email: _this.email,
            document_id: _this.document_id,
            signer_id: _this.signer_id,
            token: _this.redirectToken.getValue() || _this.previousRedirectToken
          }).then(function (response) {
            _this.flashMessages.success(_this.i18n.t("mixins.redirectTokenGeneration.linkGenerated"));

            resolve(response);
          }, function (response) {
            reject(response);
          });
        }, 'request a token to view the document');
      }
    }
  });

  _exports.default = _default;
});