define("ember-rs/locales/zh-cn/routes/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    "embeddedTemplate": "您的帐户无权访问此功能。请升级到专业计划以开始使用嵌入的模板。",
    "inPerson": "您的帐户无权访问此功能。请升级到 Pro 计划以开始使用本人签名功能。"
  };
  _exports.startDocument = startDocument;
});