define("ember-rs/locales/en/templates/onboarding/send-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding = void 0;
  var sendOnboarding = {
    "continue": "Continue",
    "gotIt": "Got it!"
  };
  _exports.sendOnboarding = sendOnboarding;
});