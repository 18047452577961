define("ember-rs/locales/es/templates/onboarding/signer-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding = void 0;
  var signerOnboarding = {
    "gotIt": "De acuerdo"
  };
  _exports.signerOnboarding = signerOnboarding;
});