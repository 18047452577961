define("ember-rs/locales/fr/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationEditor = void 0;
  var documentExpirationEditor = {
    "theDocExpired": "Le document a expiré le",
    "reactivated": "Sélectionnez la date d'expiration et de réactivation du document.",
    "theDocExpires": "Le document expire le",
    "newExpiration": "Sélectionnez la date à laquelle vous voulez que le document expire.",
    "updateSuccess": "Date d'expiration mise à jour avec succès."
  };
  _exports.documentExpirationEditor = documentExpirationEditor;
});