define("ember-rs/templates/components/signer-legend-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KS4iMWtS",
    "block": "{\"symbols\":[],\"statements\":[[8,\"signer-color-code\",[],[[\"@sequence\"],[[34,0,[\"sequence\"]]]],null],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[35,0,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[35,0,[\"signerName\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"role\",\"isReusable\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/signer-legend-item.hbs"
    }
  });

  _exports.default = _default;
});