define("ember-rs/locales/pt-br/templates/document/search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header = void 0;
  var search_header = {
    "from": "De: ",
    "state": "Estado: ",
    "search_docs": "Pesquisar documentos...",
    "all": " Todos",
    "pending": " Pendente",
    "declined": " Recusada",
    "executed": " Concluído",
    "expired": " Expirado",
    "voided": " Anulado",
    "admin_view": "Modo de exibição de administador",
    "tool_tip": "Ver todos os documentos enviados desta conta.",
    "filter": "Filtrar"
  };
  _exports.search_header = search_header;
});