define("ember-rs/components/integration-setting", ["exports", "ember-rs/mixins/integration"], function (_exports, _integration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_integration.default, {
    classNameBindings: [':integration-setting', 'provider'],
    paymentsUnavailable: Ember.computed.not('currentUser.account.featureSet.payments_integration'),
    shouldRenderService: Ember.computed('integrationFilter', function () {
      var availableService = this.availableService;
      var integrationFilter = this.integrationFilter;

      if (integrationFilter) {
        return integrationFilter(availableService);
      } else {
        return true;
      }
    }),
    actions: {
      toggleService: function toggleService(availableService, serviceEnabled, params) {
        this.sendAction('toggleService', availableService, serviceEnabled, this.isAuthorized, this.provider, this.integrationMetadata, params);
      }
    }
  });

  _exports.default = _default;
});