define("ember-rs/locales/it/templates/onboarding/send-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding3 = void 0;
  var sendOnboarding3 = {
    "remind": "Selezionare <span>Invia promemoria</span> per inviare un nuovo avviso ai firmatari.",
    "void": "Selezionare <span>Nullo</span> per annullare il documento."
  };
  _exports.sendOnboarding3 = sendOnboarding3;
});