define("ember-rs/locales/de/templates/components/account-user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "basic": "Allgemeine Informationen",
    "firstName": "Vorname:",
    "lastName": "Nachname:",
    "required": "* erforderlich",
    "email": "E-Mail:",
    "emailTip": "* Sie müssen eine gültige E-Mail-Adresse angeben.",
    "password": "Kennwort:",
    "hiddenForSecurity": "zur Sicherheit ausgeblendet",
    "reset": "Zurücksetzen",
    "emailInfo": "E-Mail-Anmeldeinformationen",
    "oldPass": "Altes Kennwort:",
    "newPass": "Neues Kennwort:",
    "passReq": "* Kennwörter müssen mindestens 8 Zeichen umfassen und mindestens 1 Ziffer, 1 Großbuchstaben und 1 Kleinbuchstaben enthalten.",
    "permissions": "Berechtigungen",
    "manageUsers": "Benutzer verwalten",
    "manageDelegateUsers": "Benutzer delegieren",
    "manageViewBills": "Rechnungen einsehen",
    "customizeBranding": "Kontobranding anpassen",
    "manageTemplates": "Vorlagen verwalten",
    "viewDocuments": "Alle Dokumente anzeigen",
    "removeUser": "Benutzer entfernen",
    "reset2fa": "Zweistufige Verifizierung zurücksetzen"
  };
  _exports.edit = edit;
});