define("ember-rs/locales/en/templates/components/documents/document-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentRow = void 0;
  var documentRow = {
    "signed": "signed",
    "signer": "Signer(s)",
    "legacy": " - Legacy Doc",
    "awaitingSignature": "Awaiting your signature",
    "sign": "sign",
    "completed": "Completed ",
    "sent": "Sent",
    "voided": "Voided",
    "expired": "Expired ",
    "auth_failure": "Failed to authenticate signer via session: {{{authError}}}"
  };
  _exports.documentRow = documentRow;
});