define("ember-rs/components/view-document-page", ["exports", "ember-rs/mixins/document-page"], function (_exports, _documentPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_documentPage.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    documentViewerData: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    components: Ember.computed.filter('doc.components', function (component) {
      return component.get('page') === this.pageNumber;
    }),
    // Components that belong to the specific signer
    componentsToFill: Ember.computed.filter('components', function (component) {
      if (this.get('doc.type') === 'reusableTemplate') {
        return !component.get('isAnnotation');
      }

      return !component.get('isAnnotation') && !component.get('isMergeField');
    }),
    // Components that should just be rendered (other users components + annotations and mergefields)
    flattenedComponents: Ember.computed.filter('components', function (component) {
      return component.get('isAnnotation') || this.get('doc.type') === 'document' && component.get('isMergeField');
    }),
    ////////////////////////  STYLES  ////////////////////////
    pageCssStyle: Ember.computed('documentViewerData.pageHeight', 'documentViewerData.pageWidth', function () {
      return "width: ".concat(this.get('documentViewerData.pageWidth'), "px; height: ").concat(this.get('documentViewerData.pageHeight'), "px; margin-bottom: ").concat(this.get('documentViewerData.pageMargin'), "px;").htmlSafe();
    })
  });

  _exports.default = _default;
});