define("ember-rs/locales/pt-br/controllers/account/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUser = void 0;
  var newUser = {
    "success": "Usuário {{{searchValue}}} adicionado com sucesso.",
    "error": "Não foi possível adicionar o usuário {{{searchValue}}}."
  };
  _exports.newUser = newUser;
});