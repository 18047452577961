define("ember-rs/locales/fr/templates/onboarding/send-onboarding-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding4 = void 0;
  var sendOnboarding4 = {
    "close": "Sélectionnez la <span>flèche de page précédente</span> pour fermer ces détails et accéder à votre tableau de bord Documents"
  };
  _exports.sendOnboarding4 = sendOnboarding4;
});