define("ember-rs/locales/it/templates/unauthorized/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expired = void 0;
  var expired = {
    "linkExpired": "Collegamento al documento scaduto",
    "toDownload": "Per scaricare questo documento, richiedere un nuovo collegamento per il download.",
    "sendLink": "Invia nuovo collegamento per il download"
  };
  _exports.expired = expired;
});