define("ember-rs/components/reports/account-efficiency", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultPie = {
    labels: ['Signed', 'Pending', 'Expired'],
    datasets: [{
      backgroundColor: ['#47A459', '#FBB03B', '#E04B2E'],
      hoverBackgroundColor: ['#47A459', '#FBB03B', '#E04B2E']
    }]
  };
  var defaultBar = {
    datasets: [{
      label: 'Signed',
      backgroundColor: '#47A459',
      borderColor: '#47A459'
    }, {
      label: 'Pending',
      backgroundColor: '#FBB03B',
      borderColor: '#FBB03B'
    }, {
      label: 'Expired',
      backgroundColor: '#E04B2E',
      borderColor: '#E04B2E'
    }]
  };
  var setNames = ['executed', 'pending', 'expired'];

  var _default = Ember.Component.extend({
    chartOptions: {
      datasetFill: false,
      responsive: false,
      legend: {
        display: false
      }
    },
    chartData: Ember.computed('report', function () {
      var pie = defaultPie;
      pie.datasets[0].data = [0, 0, 0];
      var bar = defaultBar;
      var today = this.get('report.date');
      bar.labels = [(0, _moment.default)(today).subtract(3, 'months').format('MMMM'), (0, _moment.default)(today).subtract(2, 'months').format('MMMM'), (0, _moment.default)(today).subtract(1, 'months').format('MMMM'), (0, _moment.default)(today).format('MMMM')];
      var currentMonth = bar.labels.length - 1;

      for (var i = 0, iMax = setNames.length, setName, data, j, jMax, datum, index; i < iMax; i++) {
        setName = setNames[i];
        data = this.get("report.".concat(setName));
        pie.datasets[0].data[i] = 1;
        bar.datasets[i].data = [0, 0, 0, 0];

        if (data) {
          pie.datasets[0].data[i] = 0;

          for (j = 0, jMax = data.length; j < jMax; j++) {
            datum = data[j];
            index = bar.labels.indexOf((0, _moment.default)(datum.date).format('MMMM'));
            bar.datasets[i].data[index] = datum.count;

            if (index === currentMonth) {
              pie.datasets[0].data[i] = datum.count;
            }
          }
        }
      }

      return {
        pie: pie,
        bar: bar
      };
    })
  });

  _exports.default = _default;
});