define("ember-rs/locales/fr/mixins/workflow-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.workflowType = void 0;
  var workflowType = {
    "signatureRequest": "Envoyé par vous",
    "selfSign": "Auto-signé",
    "templateSignerLink": "À partir du modèle",
    "inPerson": "Signature en personne",
    "apiEmbedded": "API de signature intégrée",
    "bulkSend": "Envoi en bloc"
  };
  _exports.workflowType = workflowType;
});