define("ember-rs/locales/ja/templates/components/client-application/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "editKey": "API キーの編集 ",
    "name_tool_tip": "OAuth を使用してアカウントへのアクセスを要求すると、この画面が表示されます。例: Industry Automative Estimates Generator や WordStar Plugin",
    "name": "名前:",
    "org_name_tip": "  所属する組織名",
    "org_name": "組織名:",
    "description_tip": "OAuth を使用してアカウントへのアクセスを要求すると、この画面が表示されます。例: 「このアプリケーションは Industry Automotive がエンタープライズ ワークフロー内で見積もりを送信するためにのみ使用されます」や「WordStar Plugin により、WordStar ユーザーはRightSignature&trade; アカウントで署名用のドキュメントを送信できます」。",
    "description": "説明:",
    "app_url_tip": "OAuth を使用してアカウントへのアクセスを要求すると、この画面が表示されます。例: http://industryauto.com/login またはhttp://wordstarsupportgroup.com/theplugin",
    "app_url": "アプリケーション URL:",
    "redirect_url_tip": "これは OAuth 認証要求のデフォルトのコールバックで、API 呼び出しで指定されるか上書きされることがあります。例: http://industryauto.com/oauth_callback",
    "redirect_url": "リダイレクト URL:",
    "org_url_tip": "  (オプション) 所属する組織の Web サイト",
    "org_url": "組織 URL:",
    "update_success": "{{{applicationName}}} が更新されました。"
  };
  _exports.edit = edit;
});