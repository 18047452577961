define("ember-rs/mixins/country-details", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    changeCountry: function changeCountry(val) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/utility/states/").concat(val),
          type: 'GET',
          dataType: 'json',
          global: false
        }).then(function (response) {
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      }, 'Fetching states of a country');
    }
  });

  _exports.default = _default;
});