define("ember-rs/locales/fr/templates/signer/sign/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identify = void 0;
  var identify = {
    "advisory": "L'expéditeur de ce document a activé la fonctionnalité d'authentification basée sur les connaissances de RightSignature pour vérifier votre identité. RightSignature utilise les services tiers de BlockScore pour valider et vérifier les informations personnelles fournies. L'utilisation des informations personnelles fournies est limitée à l'exécution de la validation et de la vérification de l'identité avec les enregistrements de données propriétaires de BlockScore. L'exécution de la validation et de la vérification de l'identité n'aura aucun effet sur votre rapport de solvabilité, votre cote de solvabilité ou vos rapports aux prêteurs. Ni le fisc, ni Blockscore, ni RightSignature ne pourront afficher ou accéder à votre rapport de solvabilité ou à vos informations fiscales dans le cadre de ce processus.",
    "step1": "Vérification d'identité - Étape 1 sur 2",
    "beforeSigning": "Avant de signer le document, entrez vos informations personnelles pour nous aider à vérifier votre identité.",
    "notValid": "Désolé, nous n'avons pas trouvé d'identité valide correspondant aux informations fournies. Assurez-vous que les informations ci-dessous sont correctes et cliquez sur Suivant pour réessayer.",
    "youHave": "Il vous reste ",
    "remaining": " tentatives d'accès au document.",
    "name": "Nom :",
    "dob": "Date de naissance :",
    "ssn": "4 derniers chiffres du n° de sécurité sociale :",
    "passport": "Numéro de passeport :",
    "address": "Adresse :",
    "address2": "Adresse 2 :",
    "city": "Ville :",
    "state": "État :",
    "zip": "Code postal :",
    "country": "Pays :",
    "return": "Retourner au formulaire",
    "advisory2": "Avis sur la vérification d'identité",
    "sampleAddress1": "120 S. West St.",
    "sampleAddress2": "Suite 100",
    "sampleAddress3": "Raleigh",
    "submit": "Envoyer"
  };
  _exports.identify = identify;
});