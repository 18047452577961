define("ember-rs/locales/it/mixins/integration-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationAuth = void 0;
  var integrationAuth = {
    "unableAuthorize": "Impossibile autorizzare l'integrazione.",
    "notComplete": "Impossibile completare l'autorizzazione"
  };
  _exports.integrationAuth = integrationAuth;
});