define("ember-rs/locales/de/templates/components/show-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showComponent = void 0;
  var showComponent = {
    "completed": "Fertig",
    "pending": "Ausstehend",
    "mergefield": "ist ein Seriendruckfeld",
    "requiredAssignedTo": "Das erforderliche Feld '{{{fieldName}}}' ist <b>{{{assignedToName}}}</b> zugewiesen",
    "optionalAssignedTo": "Das optionale Feld '{{{fieldName}}}' ist <b>{{{assignedToName}}}</b> zugewiesen"
  };
  _exports.showComponent = showComponent;
});