define("ember-rs/locales/es/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceCompleteDocumentModal = void 0;
  var forceCompleteDocumentModal = {
    "header": "Forzar ejecución del documento",
    "forceCompleted": "Esto eliminará todos los campos asignados a los firmantes que aún no han firmado y hará que el documento se ejecute (estado final). Una vez hecho esto, no se puede deshacer.",
    "briefMoment": "El estado del documento puede tardar unos momentos en actualizarse en el panel.",
    "forceComplete": "Forzar ejecución",
    "cancel": "Cancelar",
    "success": "Documento completado correctamente a la fuerza.",
    "failed": "Error al completar el documento a la fuerza"
  };
  _exports.forceCompleteDocumentModal = forceCompleteDocumentModal;
});