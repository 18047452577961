define("ember-rs/locales/fr/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding2 = void 0;
  var accountOnboarding2 = {
    "body": "Personnalisez et donnez une apparence professionnelle à votre compte en <span>ajoutant une photo.</span>",
    "mobileBody": "Votre photo apparaît dans la page de signature du document et l'e-mail du destinataire afin que vos signataires aient toute confiance dans le processus de signature."
  };
  _exports.accountOnboarding2 = accountOnboarding2;
});