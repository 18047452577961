define("ember-rs/components/code-generated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COPY_SUCCESS_TRANSLATION = 'components.codeGenerated.copySuccess';
  var COPY_ERROR_TRANSLATION = 'components.codeGenerated.copyError';

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    actions: {
      copyToClipboardSuccess: function copyToClipboardSuccess() {
        var message = this.i18n.t(COPY_SUCCESS_TRANSLATION);
        this.flashMessages.success(message);
      },
      copyToClipboardError: function copyToClipboardError(error) {
        console.error(error);
        var message = this.i18n.t(COPY_ERROR_TRANSLATION);
        this.flashMessages.danger(message);
      }
    }
  });

  _exports.default = _default;
});