define("ember-rs/locales/fr/templates/components/signer/signer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerInfo = void 0;
  var signerInfo = {
    "toSign": "Pour signer ce document, entrez votre nom et votre e-mail :",
    "name": "Nom...",
    "proceed": "Aller au document"
  };
  _exports.signerInfo = signerInfo;
});