define("ember-rs/locales/en/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alreadySigned = void 0;
  var alreadySigned = {
    "thankYou": "Thank You For Signing",
    "youHave": "You have successfully signed this document. You can view the document at any time by requesting a secure link.",
    "sendNewLink": "Send New Download Link",
    "successfully": "You have successfully signed this document."
  };
  _exports.alreadySigned = alreadySigned;
});