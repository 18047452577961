define("ember-rs/locales/es/templates/document/search-header-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header_mobile = void 0;
  var search_header_mobile = {
    "search_docs": "Buscar documentos..."
  };
  _exports.search_header_mobile = search_header_mobile;
});