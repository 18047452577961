define("ember-rs/adapters/signer-payment", ["exports", "ember-rs/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    retryCharge: function retryCharge(documentId, signerPaymentId) {
      return this.ajax(this.urlForRetryCharge(documentId, signerPaymentId), 'POST');
    },
    urlForRetryCharge: function urlForRetryCharge(documentId, signerPaymentId) {
      return "".concat(this.buildURL('document', documentId), "/signer_payments/").concat(signerPaymentId, "/retry");
    }
  });

  _exports.default = _default;
});