define("ember-rs/locales/it/templates/components/component-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentEditor = void 0;
  var componentEditor = {
    "charge": "Addebitare dopo che il documento viene reso esecutivo?",
    "selectOne": "Selezionare uno...",
    "amount": "Importo del pagamento (USD):",
    "enterAmount": "Inserire l'importo del pagamento...",
    "required": "OBBLIGATORIO: inserire il testo di istruzioni per il firmatario di seguito, per comunicare al destinatario cosa caricare.",
    "signerHelp": "Testo di istruzioni per il firmatario:",
    "helpText": "Inserire il testo di istruzioni...",
    "selectBox": "La casella di selezione richiede almeno 2 opzioni.",
    "selectOptions": "Selezionare opzioni:",
    "addOption": "Aggiungi opzione...",
    "addToGroup": "Aggiungi al gruppo",
    "assignedTo": "Assegnato a:",
    "fieldRequired": "Campo obbligatorio?",
    "autoFillDate": "Auto-fill date signed",
    "autoFillDateHelpText": "A recipient will not be able to edit the field. It will be filled in automatically with the date on which the recipient signs.",
    "mergeField": "Campo di unione?",
    "mergeFieldName": "il campo di unione richiede un nome",
    "nameMergeField": "Nome campo di unione:",
    "setFontSize": "Imposta dimensione carattere...",
    "muiltiline": "Multilinea?",
    "clearBackground": "Nessuno sfondo?",
    "addDefaultValue": "Aggiungere valore predefinito?",
    "setDefaultValue": "Imposta valore predefinito...",
    "defaultValue": "Valore predefinito:",
    "nameThisField": "Assegnare un nome a questo campo?",
    "name": "Nome:",
    "setName": "Imposta il nome del componente...",
    "textAlign": "Allineamento testo",
    "showLess": "Mostra meno",
    "showMore": "Mostra altro",
    "delete": "Elimina",
    "saved": "Salvato",
    "save": "Salva",
    "dateFormat": "Formato data",
    "left": "a sinistra",
    "center": "centro",
    "right": "destra",
    "groupRequirement": "Requisito di gruppo",
    "only_one": "Solo uno",
    "one_or_more": "Uno o più",
    "options_warning": "Questa opzione è già stata aggiunta"
  };
  _exports.componentEditor = componentEditor;
});