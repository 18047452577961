define("ember-rs/components/signature-field", ["exports", "jquery", "ember-rs/utils/media"], function (_exports, _jquery, _media) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['signature-field'],
    attributeBindings: ['tabindex'],
    showSignaturePad: false,
    showSavedSignatures: false,
    currentSignatureData: null,
    signType: 'drawn',
    mobileOrientationIsPortrait: false,
    orientationchangeTimeout: null,
    componentValue: null,
    // passed in
    component: null,
    // passed in
    fieldLabel: null,
    // passed in
    tabIndex: null,
    // passed in
    canDraw: true,
    // passed in
    canType: true,
    // passed in
    clicked: false,
    // passed in
    color: 'black',
    // passed in
    previousSignature: null,
    // passed in
    savedSignatures: [],
    // passed in
    init: function init() {
      this._super.apply(this, arguments);

      this.signType = this.canDraw ? 'drawn' : 'typed';
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.get('clicked', true)) {
        this.set('clicked', false);
        this.send('clickSignatureField');
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var context = (0, _jquery.default)(window);
      var width = context.width();
      var height = context.height();

      if ((0, _media.hasMobileUserAgent)() && Math.max(width, height) < _media.PHONE_SIZE) {
        this._setMobileOrientation(context);

        context.on('orientationchange.signatureField', function () {
          _this.set('orientationchangeTimeout', Ember.run.later(function () {
            _this._setMobileOrientation(context);
          }, 500));
        });
      }
    },
    willRemoveElement: function willRemoveElement() {
      Ember.run.cancel(this.orientationchangeTimeout);
      (0, _jquery.default)(window).off('orientationchange.signatureField');
    },
    actions: {
      clickSignatureField: function clickSignatureField() {
        var _this2 = this;

        // only fetch signatures for logged in users
        if (this.get('session.isAuthenticated')) {
          var signatureStore = this.store.peekAll('signature');
          var documentStore = this.store.peekAll('document');
          var currentRoute = this.router.currentRouteName; // show only the signatures belonging to the logged in user in the modal

          if (signatureStore.length > 0 && !(currentRoute === 'signer.sign.index' && documentStore.length > 0 && documentStore.objectAt(0).workflow === 'in_person')) {
            this.set('savedSignatures', signatureStore.filter(function (s) {
              return s.userId === _this2.get('session.data.authenticated.user_id');
            }));
          }
        }

        if (this.previousSignature && !this.currentSignatureData) {
          this.set('currentSignatureData', this.previousSignature);
          this.set('signType', this.get('previousSignature.signatureType'));
          this.sendAction('signatureCreated', this.previousSignature, this._componentToSend);
        } else {
          this.set('showSignaturePad', true);
        }
      },
      saveSignature: function saveSignature(currentSignatureData) {
        this.sendAction('signatureCreated', currentSignatureData, this._componentToSend);
        this.set('showSignaturePad', false);
      },
      deleteSignatureComponent: function deleteSignatureComponent() {
        this.set('showSignaturePad', false);
        this.sendAction('deleteSignatureComponent', this.component);
      },
      selectSavedSignature: function selectSavedSignature(signature) {
        this.set('showSavedSignatures', false);
        this.set('currentSignatureData', signature);
        this.set('signType', signature.get('signatureType'));
        this.sendAction('signatureCreated', signature, this.component);

        if (this.component) {
          this.set('showSignaturePad', false);
        }
      },
      switchSignType: function switchSignType(signType) {
        this.set('signType', signType);
        this.set('currentSignatureData', null);
      }
    },
    _componentToSend: Ember.computed.or('componentValue', 'component'),
    _setMobileOrientation: function _setMobileOrientation(context) {
      context = context || (0, _jquery.default)(window);
      var width = context.width();
      var height = context.height();
      this.set('mobileOrientationIsPortrait', width < height);

      if (width < height) {
        (0, _jquery.default)(window).scrollTop(100);
      }
    }
  });

  _exports.default = _default;
});