define("ember-rs/locales/it/templates/support-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportContent = void 0;
  var supportContent = {
    "needHelp": "È necessaria assistenza per RightSignature&trade;?",
    "articlesBelow": "Di seguito sono riportati articoli e video di supporto che rispondono a molte delle domande che si potrebbero avere riguardo a RightSignature&trade; Se gli articoli e i video non rispondono alla propria domanda, contattare i nostri rappresentanti dell'assistenza.",
    "supportArticles": "Articoli di supporto",
    "overview": "Panoramica di RightSignature&trade; per ShareFile",
    "activate": "Come si attiva RightSignature&trade; per la funzione ShareFile nell'account ShareFile?",
    "send": "Come si invia un documento da ShareFile utilizzando RS per SF?",
    "sign": "Come si firma un documento da ShareFile utilizzando RS per SF?",
    "branding": "Come è possibile configurare il branding aziendale?",
    "changeEmail": "Come è possibile cambiare l'indirizzo e-mail associato al proprio account RightSignature&trade;?",
    "addUser": "Come è possibile aggiungere utenti al proprio account RightSignature&trade;?",
    "signerOrder": "Come si assegna un ordine per i firmatari nello stesso documento?",
    "annotate": "Come si utilizza RightSignature&trade; per annotare documenti per i firmatari?",
    "mergeFields": "Come si utilizza la funzione Campi di unione per immettere testo in un documento prima dell'invio?",
    "passcode": "Come si aggiunge un passcode al documento?",
    "importOverlay": "Come si utilizza la funzione Importa sovrapposizione?",
    "docLocation": "Dove vengono archiviati i documenti utilizzando RightSignature&trade; per ShareFile?",
    "share": "Come si condividono i documenti?",
    "multiple": "Come si inviano più documenti per la firma?",
    "oneOff": "Come si invia un documento? (una sola volta)",
    "template": "Come si crea un modello riutilizzabile?",
    "editTemplate": "Come si modifica un modello?",
    "sendTemplate": "Come si invia un documento basato su un modello?",
    "embed": "Come si incorpora un documento nel proprio sito Web per la firma?",
    "generate": "Come si genera un collegamento di condivisione per i documenti basati su modelli?",
    "delete": "Come si elimina un documento?",
    "tag": "Come si contrassegna un documento?",
    "selfSign": "Come si firma il proprio documento?",
    "reports": "Quali report e statistiche sono disponibili con RightSignature&trade;?",
    "complete": "Come completare il documento che si è ricevuto",
    "remind": "Come si invia un promemoria per firmare un documento?",
    "inPerson": "Come è possibile far firmare un documento di persona?",
    "showLess": "Mostra meno",
    "showMore": "Mostra altro",
    "sendQuestion": "Invia la domanda",
    "submitRequest": "Invia la richiesta qui",
    "call": "Contattaci subito",
    "free": "Numero verde"
  };
  _exports.supportContent = supportContent;
});