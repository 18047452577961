define("ember-rs/templates/components/rs-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KkwsGYVQ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[14,0,\"rs-checkbox-label\"],[15,\"for\",[31,[[34,0]]]],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"rs-checkbox-input\"],[12],[2,\"\\n  \"],[8,\"input\",[],[[\"@type\",\"@id\",\"@checked\",\"@disabled\",\"@changed\"],[\"checkbox\",[34,0],[34,3],[34,4],\"toggleCheck\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inputId\",\"label\",\"if\",\"isChecked\",\"isDisabled\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/rs-checkbox.hbs"
    }
  });

  _exports.default = _default;
});