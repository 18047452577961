define("ember-rs/locales/ja/templates/onboarding/send-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding3 = void 0;
  var sendOnboarding3 = {
    "remind": "<span>[通知]</span> を選択すると、再度署名者にメッセージを表示します。",
    "void": "<span>[取り消し]</span> を選択すると、ドキュメントをキャンセルします。"
  };
  _exports.sendOnboarding3 = sendOnboarding3;
});