define("ember-rs/models/base-file", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: add extended attributes
  var _default = _model.default.extend({
    filename: (0, _model.attr)('string'),
    extension: (0, _model.attr)('string'),
    contentType: (0, _model.attr)('string'),
    originalUrl: (0, _model.attr)('string'),
    originalFileSize: (0, _model.attr)('number'),
    processedFileSize: (0, _model.attr)('number'),
    pageCount: (0, _model.attr)('number'),
    createdAt: (0, _model.attr)('moment-date'),
    processingState: (0, _model.attr)('string'),
    processingError: (0, _model.attr)('string'),
    processingMessage: (0, _model.attr)('string'),
    originalChecksum: (0, _model.attr)('string'),
    processedChecksum: (0, _model.attr)('string'),
    annotationsChecksum: (0, _model.attr)('string'),
    fileComparator: (0, _model.attr)('string'),
    metadata: (0, _model.attr)('object'),
    imageWidth: (0, _model.attr)('number'),
    imageHeight: (0, _model.attr)('number'),
    downloadedFileUrl: (0, _model.attr)('string'),
    processedPdfUrl: (0, _model.attr)('string'),
    pageImages: (0, _model.attr)('array'),
    certificateImages: (0, _model.attr)('array'),
    annotations: (0, _model.attr)('array'),
    createImages: (0, _model.attr)('boolean'),
    flattenPdf: (0, _model.attr)('boolean'),
    reuseCount: (0, _model.attr)('number'),
    upload: (0, _model.belongsTo)('upload', {
      async: true
    })
  });

  _exports.default = _default;
});