define("ember-rs/locales/pt-br/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding2 = void 0;
  var accountOnboarding2 = {
    "body": "Personalize e dê um aspecto profissional à sua conta <span>adicionando uma foto.</span>",
    "mobileBody": "Sua foto aparece no email do destinatário e na página de assinatura para que seus signatários possam assinar com toda a confiança."
  };
  _exports.accountOnboarding2 = accountOnboarding2;
});