define("ember-rs/locales/nl/templates/components/restyle-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.restyleOnboarding = void 0;
  var restyleOnboarding = {
    "welcome": "Welkom!",
    "introduceNewLook": "Met groot genoegen presenteren wij het nieuwe ontwerp van RightSignature. Kijk zelf eens!",
    "gotIt": "OK!"
  };
  _exports.restyleOnboarding = restyleOnboarding;
});