define("ember-rs/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ////////////////////////  BINDINGS  ////////////////////////
    tagName: 'li',
    classNames: ['template', 'scrollable-item'],
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      sendDocument: function sendDocument() {
        var _this = this;

        this.set('sending', true);
        return this.store.createRecord('document-transaction', {
          workflow: 'signature_request',
          account: this.get('currentUser.account')
        }).save().then(function (documentTransaction) {
          _this.reusableTemplate.cloneDocument().then(function (doc) {
            _this.sendAction('routeToPrepareSigners', documentTransaction, doc);
          }).finally(function () {
            return _this.set('sending', false);
          });
        });
      }
    }
  });

  _exports.default = _default;
});