define("ember-rs/components/overlay-component", ["exports", "ember-rs/mixins/component-style", "ember-rs/mixins/overlay-component", "ember-rs/mixins/component-dimensions"], function (_exports, _componentStyle, _overlayComponent, _componentDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentStyle.default, _overlayComponent.default, _componentDimensions.default, {
    overlayerData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    i18n: Ember.inject.service(),
    isSelected: Ember.computed('overlayerData.selectedComponents.[]', 'component', function () {
      return this.get('overlayerData.selectedComponents').includes(this.component);
    }),
    selectedFieldTitle: Ember.computed('overlayerData.selectedComponentTitle', 'component', function () {
      if (this.get('component.isTextField') && this.get('component.isSourceMapped')) {
        return this.get('component.source.display_name');
      } else {
        return this.i18n.t("components.overlayComponent.text");
      }
    }),
    classNames: ['component'],
    classNameBindings: ['componentType', 'isRequired', 'component.transparent:is-transparent'],
    attributeBindings: ['style', 'data-id'],
    'data-id': Ember.computed.oneWay('component.id'),
    isRequired: Ember.computed('component.isRequired', function () {
      return this.get('component.isRequired');
    }),
    initResizable: Ember.on('didInsertElement', function () {
      var resizableOptions = {
        containment: 'parent',
        handles: 'n, e, s, w, ne, se, nw, sw',
        resize: Ember.run.bind(this, this.onResize),
        stop: Ember.run.bind(this, this.onResizeStop),
        start: Ember.run.bind(this, this.onResizeStart)
      }; // if it is a check or signature type component, constrain the aspect ratio according to the default ratios for that component type

      if (['check-component', 'check-group-component', 'checkmark-annotation-component', 'signature-component', 'signature-annotation-component'].includes(this.componentType)) {
        resizableOptions.aspectRatio = this.defaultComponentRatios[Ember.String.camelize(this.componentType)];
        resizableOptions.handles = 'ne, se, nw, sw';
      }

      this.$().resizable(resizableOptions);
    }),
    onResizeStart: function onResizeStart(evt, ui) {
      this.set('overlayerData.isResizing', true);
      this.set('component.width', (parseFloat(ui.size.width) + this.get('overlayerData.componentBorderWidth') * 2) / this.get('documentViewerData.pageWidth'));
      this.set('component.height', (parseFloat(ui.size.height) + this.get('overlayerData.componentBorderWidth') * 2) / this.get('documentViewerData.pageHeight'));
    },
    // calculating on resize instead of resizeStop so that the signature image syncs across resize correctly
    onResize: function onResize(evt, ui) {
      this.set('component.x', parseFloat(ui.position.left) / this.get('documentViewerData.pageWidth'));
      this.set('component.y', parseFloat(ui.position.top) / this.get('documentViewerData.pageHeight')); // need to adjust the size by 2 to account for a 1px border

      this.set('component.width', (parseFloat(ui.size.width) + this.get('overlayerData.componentBorderWidth') * 2) / this.get('documentViewerData.pageWidth'));
      this.set('component.height', (parseFloat(ui.size.height) + this.get('overlayerData.componentBorderWidth') * 2) / this.get('documentViewerData.pageHeight'));
    },
    onResizeStop: function onResizeStop(evt, ui) {
      this.set('overlayerData.isResizing', false); // need to skip selection for signatureAnnotation, works for all other component types

      if (this.componentType === 'signature-annotation-component') {
        this.set('skipComponentSelection', true);
      } // set new size for this component to use for next component dropped


      this.setLastComponentDimensions(this.componentType.camelize(), parseFloat(ui.size.width) + this.get('overlayerData.componentBorderWidth') * 2, parseFloat(ui.size.height) + this.get('overlayerData.componentBorderWidth') * 2);
    },
    destroyResizable: Ember.on('willDestroyElement', function () {
      this.$().resizable('destroy');
    }),
    tooSmallForLabel: Ember.computed.lt('component.width', 0.07),
    tooSmallForMergeLabel: Ember.computed.lt('component.width', 0.12),
    checkmarkAnnotationStyle: Ember.computed('component.height', 'documentViewerData.pageHeight', function () {
      var height = this.get('component.height') * this.get('documentViewerData.pageHeight');
      return "font-size: ".concat(height, "px;").htmlSafe();
    }),
    actions: {
      signatureCreated: function signatureCreated() {
        this.sendAction.apply(this, ['signatureCreated'].concat(Array.prototype.slice.call(arguments)));
      },
      deleteSignatureComponent: function deleteSignatureComponent() {
        this.sendAction.apply(this, ['deleteSignatureComponent'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});