define("ember-rs/locales/es/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "linkExpired": "El enlace del documento ha expirado",
    "toSign": "Para firmar este documento solicite un nuevo enlace.",
    "sendLink": "Enviar nuevo enlace de firmante",
    "contactSender": "Por favor, póngase en contacto con el remitente del documento para solicitar un enlace actualizado."
  };
  _exports.identityExpired = identityExpired;
});