define("ember-rs/locales/de/templates/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentAccessSettings = void 0;
  var documentAccessSettings = {
    "addViewer": "Leseberechtigten hinzufügen",
    "email": "E-Mail",
    "name": "Name",
    "add": "Hinzufügen",
    "cancel": "Abbrechen",
    "editSigner": "Unterzeichner bearbeiten",
    "save": "Speichern",
    "manageAccess": "Zugriff verwalten",
    "accessTip": "Diese Personen haben Zugriff auf dieses Dokument und können es jederzeit herunterladen oder anzeigen.",
    "signers": "Unterzeichner",
    "edit": "Bearbeiten",
    "viewers": "Leseberechtigte",
    "remove": "Entfernen",
    "updateEmail": "Die E-Mail-Adresse des Unterzeichners wurde aktualisiert.",
    "updateSigner": "Successfully updated signer details."
  };
  _exports.documentAccessSettings = documentAccessSettings;
});