define("ember-rs/locales/en/mixins/integration-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocuments = void 0;
  var integrationDocuments = {
    "enable_sending": "Successfully enabled sending for {{{provider}}}",
    "enable_archiving": "Successfully enabled archiving for {{{provider}}}",
    "enable_contacts": "Successfully enabled contacts for {{{provider}}}",
    "enable_payments": "Successfully enabled payments for {{{provider}}}",
    "disable_sending": "Successfully disabled sending for {{{provider}}}",
    "disable_archiving": "Successfully disabled archiving for {{{provider}}}",
    "disable_contacts": "Successfully disabled contacts for {{{provider}}}",
    "disable_payments": "Successfully disabled payments for {{{provider}}}",
    "errorToggle": "Error. Could not modify {{{service}}} for {{{provider}}}!",
    "errorRetrieve": "Error. Could not retrieve document.",
    "errorProviderRetrieve": "Error. Could not retrieve {{{provider}}} documents."
  };
  _exports.integrationDocuments = integrationDocuments;
});