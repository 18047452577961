define("ember-rs/locales/ja/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newClientApplication = void 0;
  var newClientApplication = {
    "appReview": "アプリケーションを確認中です。24 時間以内にリクエストを通知または承認します。",
    "secretsSuccess": "シークレットが取得されました。シークレットは、本人または信頼できるユーザーのみが使用することができます。",
    "secretsError": "シークレットを取得できませんでした: {{{error}}}",
    "tokenSuccess": "新しいプライベート API トークンが生成されました",
    "tokenError": "トークンを生成できませんでした: {{{error}}}",
    "singleSecretSuccess": "{{{clientAppName}}} のシークレットが取得されました。これは、本人または信頼できるユーザーのみが使用することができます。",
    "singleSecretError": "シークレットを取得できませんでした: {{{error}}}",
    "tokenRetrieveSuccess": "{{{clientAppName}}} のプライベート API トークンが取得されました。これは、本人または信頼できるユーザーのみが使用することができます。",
    "tokenRetrieveError": "プライベート API トークンを取得できませんでした: {{{error}}}",
    "tokenRegenerateError": "トークンを再生成できませんでした: {{{error}}}",
    "confirmInfo": "続行してもよろしいですか? 一度再作成され、上書きされたプライベート API トークンは取得できなくなります。そのため、代わりに新しい API キーを要求することもできます。続行する場合は、すべての統合で古い認証情報を削除して置き換えてください。"
  };
  _exports.newClientApplication = newClientApplication;
});