define("ember-rs/components/worldpay-payment-form", ["exports", "ember-cp-validations", "ember-countries", "ember-rs/utils/vat-eligible-countries", "ember-rs/mixins/country-details"], function (_exports, _emberCpValidations, _emberCountries, _vatEligibleCountries, _countryDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /// Validations ///
  var Validations = (0, _emberCpValidations.buildValidations)({
    'billing.first_name': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'First Name'
    }),
    'billing.last_name': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Last Name'
    }),
    'billing.address_1': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Address'
    }),
    'billing.city': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'City'
    }),
    'billing.country': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Country'
    }),
    'billing.zip': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      description: 'Zip'
    }),
    'billing.vat_id': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isVatEligible'),
      description: 'VAT ID'
    })
  });

  var _default = Ember.Component.extend(Validations, _countryDetails.default, {
    countries: _emberCountries.COUNTRIES_LIST,
    ccTypes: Ember.A(['VISA', 'MASTERCARD', 'AMEX']),
    showValidationErrors: false,
    tagName: 'form',
    submit: function submit(event) {
      event.preventDefault();
      this.send('processBillingForm');
    },
    isVatEligible: Ember.computed('billing.country', function () {
      return _vatEligibleCountries.vatEligibleCountries.includes(this.get('billing.country'));
    }),
    mergePlanParams: function mergePlanParams() {
      this.billing.plan = this.plan;
      this.billing.users = this.users;
      this.billing.cycle = this.cycle;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.send('changeCountryDetails', Ember.get(this, 'billing.country'));
    },
    /// Actions ///
    actions: {
      processBillingForm: function processBillingForm() {
        Ember.set(this, 'showValidationErrors', true);
        Ember.set(this, 'upgradeApiError', '');

        if (!Ember.get(this, 'validations.isValid')) {
          return;
        }

        this.mergePlanParams();
        Ember.set(this, 'isLoading', true);
        this.processSubmit();
      },
      changeCountryDetails: function changeCountryDetails(val) {
        var _this = this;

        this.changeCountry(val).then(function (response) {
          var newStates = response.states;
          Ember.set(_this, 'states', newStates);
          Ember.set(_this, 'billing.country', val);
        }).catch(function (err) {
          return err;
        });
      },
      closeModal: function closeModal() {
        Ember.set(this, 'upgradeApiError', false);
      }
    }
  });

  _exports.default = _default;
});