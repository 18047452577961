define("ember-rs/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.maxUserValue = _exports.billingOptions = _exports.plans = _exports.features = _exports.detailedFeatures = void 0;
  var detailedFeatures = Ember.A([{
    id: 'documentSending',
    description: 'utils.hybrisPlanDetails.document-sending',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.document-sending-info',
    proFeature: true
  }, {
    id: 'handDrawnSignatures',
    description: 'utils.hybrisPlanDetails.signature',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.signature-info',
    proFeature: true
  }, {
    id: '247CustomerSupport',
    description: 'utils.hybrisPlanDetails.customer-support',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.customer-support-info'
  }, {
    id: 'emailAlerts',
    description: 'utils.hybrisPlanDetails.email-alert',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.email-alert-info'
  }, {
    id: 'integrationAddOns',
    description: 'utils.hybrisPlanDetails.integration',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.integration-info'
  }, {
    id: 'typeToSign',
    description: 'utils.hybrisPlanDetails.type-sign',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.type-sign-info'
  }, {
    id: 'signOnMobileDevices',
    description: 'utils.hybrisPlanDetails.mobile',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.mobile-info'
  }, {
    id: 'guidedSigning',
    description: 'utils.hybrisPlanDetails.guided-signing',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.guided-signing-info'
  }, {
    id: 'secureDocumentArchive',
    description: 'utils.hybrisPlanDetails.archive',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.archive-info'
  }, {
    id: '256BitDataEncryption',
    description: 'utils.hybrisPlanDetails.encrypt',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.encrypt-info'
  }, {
    id: 'biometricAuthentication',
    description: 'utils.hybrisPlanDetails.biometric',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.biometric-info'
  }, {
    id: 'digitalFingerprints',
    description: 'utils.hybrisPlanDetails.fingerprint',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.fingerprint-info'
  }, {
    id: 'auditLogAndCertificate',
    description: 'utils.hybrisPlanDetails.audit',
    associatedPlans: ['standard', 'advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.audit-info'
  }, {
    id: 'reusableTemplates',
    description: 'utils.hybrisPlanDetails.template',
    associatedPlans: ['advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.template-info',
    proFeature: true
  }, {
    id: 'customBranding',
    description: 'utils.hybrisPlanDetails.branding',
    associatedPlans: ['advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.branding-info',
    proFeature: true
  }, {
    id: 'requestAttachments',
    description: 'utils.hybrisPlanDetails.attachments',
    associatedPlans: ['advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.attachments-info'
  }, {
    id: 'requestPayments',
    description: 'utils.hybrisPlanDetails.payments',
    associatedPlans: ['advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.payments-info',
    proFeature: true
  }, {
    id: 'developerApi',
    description: 'utils.hybrisPlanDetails.api',
    associatedPlans: ['advanced', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.api-info',
    proFeature: true
  }, {
    id: 'knowledgeBasedAuthentication',
    description: 'utils.hybrisPlanDetails.kba',
    associatedPlans: ['advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.kba-info',
    proFeature: true
  }]);
  _exports.detailedFeatures = detailedFeatures;
  var features = Ember.A([{
    id: 'handDrawnTextToSign',
    description: 'utils.hybrisPlanDetails.hand-written-text-to-sign',
    associatedPlans: ['sharefile premium', 'advanced kba', 'advanced'],
    moreInfo: 'utils.hybrisPlanDetails.document-sending-info'
  }, {
    id: 'reusableTemplates',
    description: 'utils.hybrisPlanDetails.template',
    associatedPlans: ['sharefile premium', 'advanced kba', 'advanced'],
    moreInfo: 'utils.hybrisPlanDetails.signature-info'
  }, {
    id: 'bulkSendForSignature',
    description: 'utils.hybrisPlanDetails.bulk-send-for-signature',
    associatedPlans: ['sharefile premium', 'advanced kba', 'advanced'],
    moreInfo: 'utils.hybrisPlanDetails.customer-support-info'
  }, {
    id: 'sequencedSigning',
    description: 'utils.hybrisPlanDetails.sequenced-signing',
    associatedPlans: ['sharefile premium', 'advanced kba', 'advanced'],
    moreInfo: 'utils.hybrisPlanDetails.email-alert-info'
  }, {
    id: 'customBranding',
    description: 'utils.hybrisPlanDetails.branding',
    associatedPlans: ['sharefile premium', 'advanced kba', 'advanced'],
    moreInfo: 'utils.hybrisPlanDetails.integration-info'
  }, {
    id: 'requestPayments',
    description: 'utils.hybrisPlanDetails.payments',
    associatedPlans: ['sharefile premium', 'advanced kba', 'advanced'],
    moreInfo: 'utils.hybrisPlanDetails.type-sign-info'
  }, {
    id: 'developerApi',
    description: 'utils.hybrisPlanDetails.api',
    associatedPlans: ['sharefile premium', 'advanced kba', 'advanced'],
    moreInfo: 'utils.hybrisPlanDetails.mobile-info'
  }, {
    id: 'knowledgeBasedAuthentication',
    description: 'utils.hybrisPlanDetails.knowledge-based-authentication',
    associatedPlans: ['sharefile premium', 'advanced kba'],
    moreInfo: 'utils.hybrisPlanDetails.guided-signing-info'
  }, {
    id: 'unlimitedDocumentSends',
    description: 'utils.hybrisPlanDetails.unlimited-document-sends',
    associatedPlans: ['sharefile premium'],
    moreInfo: 'utils.hybrisPlanDetails.archive-info'
  }, {
    id: 'storeSyncShareFiles',
    description: 'utils.hybrisPlanDetails.store-sync-share-files',
    associatedPlans: ['sharefile premium'],
    moreInfo: 'utils.hybrisPlanDetails.encrypt-info'
  }, {
    id: 'hipaaCompliant',
    description: 'utils.hybrisPlanDetails.hipaa-compliant',
    associatedPlans: ['sharefile premium'],
    moreInfo: 'utils.hybrisPlanDetails.biometric-info'
  }]);
  _exports.features = features;
  var plans = Ember.A([{
    id: 'advanced',
    description: 'utils.hybrisPlanDetails.advanced',
    defaultTeamMemberCount: 3,
    pricePerMonth: 25,
    caption: "utils.hybrisPlanDetails.advanced-caption"
  }, {
    id: 'advanced kba',
    description: 'utils.hybrisPlanDetails.advancedKba',
    defaultTeamMemberCount: 3,
    pricePerMonth: 25,
    caption: "utils.hybrisPlanDetails.advanced-kba-caption"
  }, {
    id: 'sharefile premium',
    description: 'utils.hybrisPlanDetails.sharefilePremium',
    defaultTeamMemberCount: 5,
    pricePerMonth: 27,
    caption: "utils.hybrisPlanDetails.sharefile-caption"
  }]);
  _exports.plans = plans;
  var billingOptions = Ember.A([{
    name: 'year',
    value: '0',
    description: 'utils.hybrisPlanDetails.annually',
    frequency: 'utils.hybrisPlanDetails.year'
  }, {
    name: 'quarter',
    value: '0',
    description: 'utils.hybrisPlanDetails.quarterly',
    frequency: 'utils.hybrisPlanDetails.quarter'
  }, {
    name: 'month',
    value: '0',
    description: 'utils.hybrisPlanDetails.monthly',
    frequency: 'utils.hybrisPlanDetails.month'
  }]);
  _exports.billingOptions = billingOptions;
  var maxUserValue = 1000;
  _exports.maxUserValue = maxUserValue;
});