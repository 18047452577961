define("ember-rs/locales/zh-cn/templates/components/integration-document-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocumentSelector = void 0;
  var integrationDocumentSelector = {
    "chooseDocFrom": "从此位置选择文档:",
    "loading": "正在加载...",
    "back": "上一步",
    "search": "搜索文档...",
    "use": "使用",
    "empty": "此文件夹为空",
    "success": "已成功禁止 {{{provider}}} 的发送",
    "error": "错误。无法修改 {{{provider}}} 的发送"
  };
  _exports.integrationDocumentSelector = integrationDocumentSelector;
});