define("ember-rs/locales/nl/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noUser = void 0;
  var noUser = {
    "unableLogin": "Kan niet aanmelden",
    "unableLoginTip": "U kunt niet aanmelden bij dit RightSignature-account.<br> Neem contact op met een accountbeheerder om toegang te krijgen."
  };
  _exports.noUser = noUser;
});