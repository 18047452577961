define("ember-rs/locales/de/templates/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.index = void 0;
  var index = {
    "templates": "Vorlagen",
    "reusableTemplates": "Wiederverwendbare Vorlagen",
    "templateExplain": "Mit Vorlagen können Sie Dokumente in Ihrer Site einbetten, schnell an viele Empfänger verteilen oder häufig verwendete Dokumente mit nur einem Schritt senden.",
    "newTemplate": "Neue Vorlage",
    "noResults": "Keine Suchergebnisse",
    "searchAgain": "Ihre Suche ergab keine Ergebnisse. Probieren Sie eine andere Suche, um Ihre Vorlage zu finden.",
    "create": "Erstellen Sie wiederverwendbare Dokumentvorlagen, die Sie in Ihre Site einbetten und über einen Link freigeben können.",
    "createTemplate": "Vorlage erstellen",
    "notAllowed": "Mit Ihrem aktuellen Abonnement können Sie keine Vorlagen erstellen. Wenn Sie Vorlagen erstellen möchten, bitten Sie den Support um ein Upgrade Ihres Kontos."
  };
  _exports.index = index;
});