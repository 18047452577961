define("ember-rs/locales/es/templates/document-transaction/redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirect = void 0;
  var redirect = {
    "please_wait": " Espere mientras se procesa su documento..."
  };
  _exports.redirect = redirect;
});