define("ember-rs/locales/de/templates/components/hybris-plan-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanInfo = void 0;
  var hybrisPlanInfo = {
    "perMonth": "pro Monat"
  };
  _exports.hybrisPlanInfo = hybrisPlanInfo;
});