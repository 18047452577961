define("ember-rs/locales/nl/templates/email-unverified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailUnverified = void 0;
  var emailUnverified = {
    "verify": "Uw e-mailadres is niet geverifieerd. Controleer uw e-mail en klik op de verificatiekoppeling om uw account te bevestigen."
  };
  _exports.emailUnverified = emailUnverified;
});