define("ember-rs/locales/ja/templates/components/add-role-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoleField = void 0;
  var addRoleField = {
    "name": "名前...",
    "emailOptional": "メールアドレス (オプション) ...",
    "email": "メールアドレス...",
    "nameTip": "フルネームが必要です。",
    "emailTip": "有効なメールアドレスが必要です。",
    "roleTip": "役割名が必要です。"
  };
  _exports.addRoleField = addRoleField;
});