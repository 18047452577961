define("ember-rs/locales/pt-br/templates/unauthorized/redirect-to-rs3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectToRs3 = void 0;
  var redirectToRs3 = {
    "wrongVersion": "Versão incorreta do RightSignature",
    "classic": "Você tentou fazer login no RightSignature ShareFile Edition. No entanto, sua conta dá acesso ao Classic RightSignature.",
    "go": "Ir para o RightSignature"
  };
  _exports.redirectToRs3 = redirectToRs3;
});