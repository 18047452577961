define("ember-rs/locales/de/templates/onboarding/welcome-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding2 = void 0;
  var welcomeOnboarding2 = {
    "tips": "Zugreifen auf Ihre <span>Dokumente</span> und <span>Vorlagen</span>, <span>Verwalten von Einstellungen</span> sowie Videoanleitungen <span>und hilfreiche Artikel</span>."
  };
  _exports.welcomeOnboarding2 = welcomeOnboarding2;
});