define("ember-rs/locales/ja/templates/document/search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header = void 0;
  var search_header = {
    "from": "対象: ",
    "state": "状態: ",
    "search_docs": "ドキュメントの検索...",
    "all": " すべて",
    "pending": " 保留",
    "declined": " 辞退しました",
    "executed": " 完了",
    "expired": " 期限切れ",
    "voided": " 取り消し済み",
    "admin_view": "管理表示",
    "tool_tip": "このアカウントから送信されたすべてのドキュメントを表示します。",
    "filter": "フィルター"
  };
  _exports.search_header = search_header;
});