define("ember-rs/components/rs-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Bindings
    tagName: 'button',
    classNameBindings: [':rs-button', 'isPrimary:is-primary:is-secondary', 'isSmall:is-small', 'isLink:is-link', 'isLoading:is-loading'],
    attributeBindings: ['_isDisabled:disabled'],
    // Defaults
    isPrimary: true,
    isLoading: false,
    isDisabled: false,
    _isDisabled: Ember.computed('isLoading', 'isDisabled', function () {
      // automatically set disabled if isLoading
      return this.isLoading ? true : this.isDisabled;
    }),
    // Events
    click: function click() {
      this.action();
    }
  });

  _exports.default = _default;
});