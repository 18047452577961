define("ember-rs/locales/de/templates/onboarding/migration-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.migrationOnboarding = void 0;
  var migrationOnboarding = {
    "migrationHeading": "Entdecken Sie die neueste Version von RightSignature!",
    "slide1Q": "Was gibt's Neues?",
    "slide1a": "Verbesserte Sicherheitsfeatures:",
    "slide1b": "Wissensbasierte Authentifizierung, erzwingbarer Passcodezugriff und vieles mehr.",
    "slide1c": "Verbesserte Benutzerfreundlichkeit:",
    "slide1d": "Ein neues optimiertes Layout, verbesserte Anpassbarkeit, Produktivitätsbenachrichtigungen und verbesserte mobile Funktionen.",
    "slide2": "Wo sind die Dokumente aus der alten Version von RightSignature?",
    "slide3": "Wo sind die Vorlagen aus der alten Version von RightSignature?",
    "slide4": "Wie kann ich die neueste Version von RightSignature in ein bezahltes Abonnement umwandeln?",
    "continue": "Weiter",
    "dismiss": "Schließen"
  };
  _exports.migrationOnboarding = migrationOnboarding;
});