define("ember-rs/components/certificate-loading-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    documentViewerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    ////////////////////////  STYLES  ////////////////////////
    pageCssStyle: Ember.computed('documentViewerData.pageHeight', 'documentViewerData.pageMargin', 'documentViewerData.scaledPageWidth', 'layoutSizeData.scrollbarWidth', function () {
      return "width: ".concat(this.get('documentViewerData.scaledPageWidth') - this.get('layoutSizeData.scrollbarWidth'), "px;\n      height:").concat(this.get('documentViewerData.pageHeight'), "px; margin-bottom: ").concat(this.get('documentViewerData.pageMargin'), "px;").htmlSafe();
    })
  });

  _exports.default = _default;
});