define("ember-rs/locales/ja/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "documents": "ドキュメント",
    "noResults": "検索結果がありません",
    "tryAgain": "検索結果がありませんでした。ドキュメントを見つけるには、さらに検索してください。",
    "anotherFilter": "選択したフィルターの結果がありません。別のフィルターを実行してください。",
    "documentsTip": "ここは署名済みのドキュメントおよび保留中のドキュメントの場所です。最初のドキュメントを作成して、使用を開始します。",
    "questions": "ご不明の点があれば、こちらのヘルプをご覧ください。",
    "gotIt": "OK!",
    "startDocument": "ドキュメント作成を開始",
    "userGuide": "ユーザーガイド"
  };
  _exports.documents = documents;
});