define("ember-rs/locales/nl/templates/components/document/signer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerRow = void 0;
  var signerRow = {
    "pendingIdentityAuthentication": "Identiteitverificatie in behandeling",
    "sign": "Ondertekenen",
    "disabledReminder": "Deze ondertekenaar heeft verdere herinneringen voor dit document uitgeschakeld",
    "threshold": "U hebt de drempel voor verzendherinneringen overschreden (3 per document)",
    "signerFailed": "Deze ondertekenaar heeft verzuimd ",
    "currentlyLocked": "en heeft momenteel geen toegang meer tot het document.",
    "signerLocked": "Bij deze ondertekenaar is op kennis gebaseerde verificatie mislukt en het document is momenteel vergrendeld.",
    "remind": "Herinneren",
    "resend": "Opnieuw verzenden",
    "createLink": "Koppeling maken",
    "copyLink": "Koppeling kopiëren",
    "copyLinkSuccess": "Koppeling naar klembord gekopieerd",
    "copyLinkError": "Kan koppeling niet naar klembord kopiëren",
    "signerAuthFailed": "Kan ondertekenaar niet verifiëren via sessie: {{{error}}}",
    "reminderSuccess": "{{{name}}} heeft een herinnering ontvangen",
    "reminderFailed": "Kan geen herinnering genereren voor {{{name}}}",
    "generateLinkFailed": "Kan geen ondertekenaarskoppeling genereren: {{{error}}}",
    "resendIdentityVerification": "Identiteitverificatie is opnieuw verzonden",
    "resendingFailed": "Kan identiteitsverificatie niet opnieuw verzenden: {{{error}}}"
  };
  _exports.signerRow = signerRow;
});