define("ember-rs/services/partial-session", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    setValue: function setValue(token) {
      this.set('value', token);
    },
    getValue: function getValue() {
      return this.value;
    },
    hasValue: function hasValue() {
      return this.checkSessionStorage() ? Object.prototype.hasOwnProperty.call(sessionStorage, 'partialSessionToken') : false;
    },
    setSession: function setSession() {
      sessionStorage.setItem('partialSessionToken', this.getValue());
    },
    clearSession: function clearSession() {
      sessionStorage.removeItem('partialSessionToken');
    },
    updateStore: function updateStore(payLoad) {
      this.store.pushPayload(payLoad);
      this.store.pushPayload(payLoad.redirect_token);
    },
    getCurrentUser: function getCurrentUser() {
      var _this = this;

      var router = Ember.getOwner(this).lookup('router:main');
      return new Ember.RSVP.Promise(function (resolve) {
        if (Ember.isPresent(_this.getValue())) {
          resolve(_this.currentUser);
        } else if (sessionStorage.getItem('partialSessionToken')) {
          var ajaxHash = {
            type: 'GET',
            url: "".concat(_environment.default.api.endpoint, "/redirect_tokens/current")
          };

          ajaxHash['beforeSend'] = function (xhr) {
            xhr.setRequestHeader('Rightsignature-Document-Grant-Token', sessionStorage.getItem('partialSessionToken'));
          };

          _jquery.default.ajax(ajaxHash).then(function (response) {
            if (response.redirect_token) {
              _this.setValue(response.redirect_token.token);

              _this.updateStore(response);

              _this.currentUser.set('model', _this.store.peekRecord('user', response.redirect_token.user.id));

              resolve(_this.currentUser);
            } else {
              _this.clearSession();

              router.transitionTo('login');
            }
          }).fail(function () {
            _this.clearSession();

            router.transitionTo('login');
          });
        } else {
          router.transitionTo('login');
        }
      });
    },
    checkSessionStorage: function checkSessionStorage() {
      try {
        return !!sessionStorage;
      } catch (error) {
        if (error.code === DOMException.SECURITY_ERR) {
          return false;
        } else {
          throw error;
        }
      }
    }
  });

  _exports.default = _default;
});