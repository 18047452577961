define("ember-rs/locales/ja/templates/client-application/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTranslations = void 0;
  var newTranslations = {
    "request_key": "新しい API キーを要求する",
    "name_tool_tip": "OAuth を使用してアカウントへのアクセスを要求すると、この画面が表示されます。例: Industry Automative Estimates Generator や WordStar Plugin",
    "name": "名前：",
    "org_name_tip": "  所属する組織名",
    "org_name": "組織名:",
    "description_tip": "OAuth を使用してアカウントへのアクセスを要求すると、この画面が表示されます。例: 「このアプリケーションは Industry Automotive がエンタープライズ ワークフロー内で見積もりを送信するためにのみ使用されます」や「WordStar Plugin により、WordStar ユーザーはRightSignature&trade; アカウントで署名用のドキュメントを送信できます」。",
    "description": "説明：",
    "scopes_tip": "  必要なアクセス レベル",
    "scopes": "スコープ:",
    "app_url_tip": "OAuth を使用してアカウントへのアクセスを要求すると、この画面が表示されます。例: http://industryauto.com/login またはhttp://wordstarsupportgroup.com/theplugin",
    "app_url": "アプリケーション URL:",
    "redirect_url_tip": "これは OAuth 認証要求のデフォルトのコールバックで、API 呼び出しで指定されるか上書きされることがあります。例: http://industryauto.com/oauth_callback",
    "redirect_url": "リダイレクト URL:",
    "org_url_tip": "  (オプション) 所属する組織の Web サイト",
    "org_url": "組織 URL:",
    "Request": "要求",
    "readOnlyAccess": "読み取り専用アクセス",
    "readWriteAccess": "読み取り/書き込みアクセス"
  };
  _exports.newTranslations = newTranslations;
});