define("ember-rs/routes/account/users", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    titleToken: 'Users',
    currentUser: Ember.inject.service(),
    ////////////////////////  PARAMS  ////////////////////////
    queryParams: {
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: false
      }
    },
    beforeModel: function beforeModel() {
      if (!this.get('currentUser.isConfirmed')) {
        this.transitionTo('account');
      }
    },
    actions: {
      didTransition: function didTransition() {
        if (!this.get('currentUser.account.isCar') || !this.get('currentUser.canManageUsers')) {
          this.transitionTo('unauthorized.unauthorized');
        }
      }
    }
  });

  _exports.default = _default;
});