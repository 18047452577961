define("ember-rs/locales/ja/templates/signer/sign", ["exports", "ember-rs/locales/ja/templates/signer/sign/identify", "ember-rs/locales/ja/templates/signer/sign/locked-modal", "ember-rs/locales/ja/templates/signer/sign/verify"], function (_exports, _identify, _lockedModal, _verify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    identify: _identify.identify,
    lockedModal: _lockedModal.lockedModal,
    verify: _verify.verify,
    "pinSent": "4 桁の確認 PIN が送信されました。",
    "pin": "PIN:",
    "submit": "送信",
    "back": "戻る",
    "deliverPin": "を使用して、次のページに入力するための 4 桁の確認 PIN を送信します。",
    "afterPhoneComplete": "有効な電話番号を入力すると、次のページに入力するための 4 桁の確認 PIN を {{{identityActionName}}} 経由で受信します。",
    "phone": "電話呼び出し",
    "sms": "SMS",
    "countryCode": "国コード:",
    "phoneNumber": "電話番号:",
    "me": "自分",
    "iAgreeComplete": "このドキュメントの署名時に <strong>{{{formattedMoney}}} (米ドル)</strong> が請求されることに同意します。",
    "usd": " (米ドル)",
    "uponExecution": " このドキュメントの署名時。",
    "clickSubmit": "[送信] をクリックすることで次の内容に同意します: ",
    "privacyPolicy": "プライバシーポリシー",
    "consent": "電子署名による同意",
    "contents": "、およびこのドキュメントのコンテンツ。",
    "saveSuccess": "進行状況が保存されました。このドキュメントは、メール内のリンクをクリックして取得できます。",
    "submitButton": "送信"
  };
  _exports.sign = sign;
});