define("ember-rs/templates/components/decline-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0HsTo4tA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"decline-document-modal__header\"],[12],[1,[30,[36,0],[\"components.declineDocumentModal.header\"],null]],[13],[2,\"\\n\\n\"],[10,\"p\"],[14,0,\"decline-document-modal__info\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.declineDocumentModal.declineConfirmationInfo\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[12],[2,\"\\n  \"],[8,\"textarea\",[[24,0,\"decline-document-modal__reason-input\"]],[[\"@value\",\"@maxlength\"],[[34,1],1000]],null],[2,\"\"],[13],[2,\"\\n\\n\"],[8,\"rs-alert\",[],[[\"@type\",\"@shouldShowDismissal\"],[\"information\",false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.declineDocumentModal.declineModalInfoMessage\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"decline-document-modal__actions\"],[12],[2,\"\\n  \"],[8,\"rs-button\",[],[[\"@action\",\"@isLoading\",\"@isDisabled\"],[[30,[36,2],[[32,0],\"decline\"],null],[34,3],[30,[36,5],[[35,4]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.declineDocumentModal.decline\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"rs-button\",[],[[\"@action\",\"@isPrimary\",\"@isDisabled\"],[[30,[36,2],[[32,0],\"close\"],null],false,[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.declineDocumentModal.cancel\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"declineReason\",\"action\",\"declining\",\"hasDeclineReason\",\"not\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/decline-document-modal.hbs"
    }
  });

  _exports.default = _default;
});