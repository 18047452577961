define("ember-rs/templates/components/on-boarding-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3m27xEq1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"step-content step-\",[34,2],\" \",[30,[36,1],[[35,0],\"visible\"],null]]]],[12],[2,\"\\n  \"],[19,[35,3],[]],[2,\"\\n  \"],[10,\"button\"],[12],[1,[34,4]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"isCurrentStep\",\"if\",\"stepNumber\",\"stepPartial\",\"nextStepText\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/on-boarding-step.hbs"
    }
  });

  _exports.default = _default;
});