define("ember-rs/locales/de/templates/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlay = void 0;
  var importOverlay = {
    "importFrom": "Felder importieren aus:",
    "toImport": "Komponente(n) zum Importieren",
    "page": "Seite(n)",
    "cancel": "Abbrechen",
    "import": "Importieren",
    "noComponents": "Keine zu importierenden Komponenten.",
    "pastDocs": "Felder aus früheren Dokumenten importieren",
    "noMatch": "Keine Dokumente entsprechen diesem Namen",
    "importSuccess": "{{{components}}} Komponenten wurden erfolgreich importiert."
  };
  _exports.importOverlay = importOverlay;
});