define("ember-rs/locales/en/controllers/document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "notAllowed": "You are not allowed to do that.",
    "voided": "Document has been successfully voided.",
    "voidingFailed": "Failed to void document.",
    "docExtended": "Document has been successfully extended.",
    "tagUpdated": "Successfully updated the tags",
    "tagUpdateFailed": "Failed to update the tags: {{{tags}}} ",
    "viewerListUpdated": "Successfully updated the viewer list",
    "viewerUpdateFailed": "Failed to update the viewer list: {{{viewers}}}"
  };
  _exports.details = details;
});