define("ember-rs/locales/fr/templates/onboarding/send-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding1 = void 0;
  var sendOnboarding1 = {
    "greatWork": "Vous avez envoyé votre premier document.",
    "youHave": "Il reste ",
    "remaining": "dans votre version d'évaluation."
  };
  _exports.sendOnboarding1 = sendOnboarding1;
});