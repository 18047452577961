define("ember-rs/locales/it/mixins/passcode-pin-generator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcodePinGenerator = void 0;
  var passcodePinGenerator = {
    "failedGeneratePin": "Impossibile generare il PIN: {{{response}}}"
  };
  _exports.passcodePinGenerator = passcodePinGenerator;
});