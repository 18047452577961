define("ember-rs/controllers/templates/index", ["exports", "ember-rs/mixins/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pagination.default, {
    queryParams: ['search', 'startingPage', 'perPage'],
    layoutSizeData: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    showSearchInput: false,
    perPage: 14,
    templateSearchStyle: Ember.computed('layoutSizeData.windowWidth', 'layoutSizeData.sidebarWidth', 'layoutSizeData.sidebar', function () {
      var width = this.get('layoutSizeData.windowWidth') - this.get('layoutSizeData.sidebar');
      return "width: ".concat(width, "px; max-width: 1300px;").htmlSafe();
    }),
    scrollTo: function scrollTo(page) {
      this.set('scrollToPage', page);
    },
    actions: {
      createNewTemplate: function createNewTemplate() {
        this.transitionToRoute('reusable-templates.new');
      },
      routeToPrepareSigners: function routeToPrepareSigners(transaction, document) {
        this.transitionToRoute('document.prepare_signers', transaction, document);
      },
      toggleSearchInput: function toggleSearchInput() {
        this.toggleProperty('showSearchInput');
      },
      setCurrentPage: function setCurrentPage(page) {
        this.set('page', page);
      }
    }
  });

  _exports.default = _default;
});