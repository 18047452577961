define("ember-rs/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['force-complete-document-modal'],
    flashMessages: Ember.inject.service(),
    // Params
    document: null,
    close: null,
    // ACTIONS
    actions: {
      forceComplete: function forceComplete() {
        var _this = this;

        this.set('saving', true);
        var doc = this.document;
        return doc.forceComplete().then(function () {
          _this.flashMessages.success(_this.i18n.t('components.forceCompleteDocumentModal.success'));

          doc.reload();

          _this.close();
        }).catch(function (error) {
          _this.flashMessages.warning(_this.i18n.t('components.forceCompleteDocumentModal.failed'));

          console.error(error);
        }).finally(function () {
          _this.set('saving', false);
        });
      },
      closeModal: function closeModal() {
        this.close();
      }
    }
  });

  _exports.default = _default;
});