define("ember-rs/locales/nl/templates/components/transaction-manager-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transactionManagerHeader = void 0;
  var transactionManagerHeader = {
    "close": "SLUITEN",
    "sendDocument": "Document verzenden",
    "signDocument": "Document ondertekenen",
    "inPerson": "Persoonlijke ondertekening",
    "broken": "Afwijkend"
  };
  _exports.transactionManagerHeader = transactionManagerHeader;
});