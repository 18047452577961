define("ember-rs/locales/zh-cn/templates/components/restyle-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.restyleOnboarding = void 0;
  var restyleOnboarding = {
    "welcome": "您好!",
    "introduceNewLook": "我们很高兴为 RightSignature 引入新外观。请随意看看!",
    "gotIt": "明白了!"
  };
  _exports.restyleOnboarding = restyleOnboarding;
});