define("ember-rs/locales/nl/templates/onboarding/signer-onboarding-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboardingOld = void 0;
  var signerOnboardingOld = {
    "title": "Een document ondertekenen met RightSignature&trade; is heel eenvoudig!",
    "completefields": "Vul de velden in die met een vlag zijn gemarkeerd.",
    "submitSignature": "Vul de velden in en verzend uw handtekening.",
    "progress": "De voortgangsbalk geeft aan hoever u bent. Wanneer u alle vereiste velden hebt ingevuld, klikt u op de knop Handtekening verzenden om het ondertekenen te voltooien.",
    "help": "Hulp nodig?",
    "questions": "Als u een vraag hebt of meer informatie nodig hebt, kunt u hier hulp krijgen."
  };
  _exports.signerOnboardingOld = signerOnboardingOld;
});