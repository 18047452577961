define("ember-rs/controllers/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    showReportsSelector: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    loading: Ember.computed.equal('currentReport', 'loading'),
    currentReport: Ember.computed('router.currentURL', function () {
      var path = this.router.currentURL;
      var split = path.split('/');
      var reportsIndex = split.indexOf('reports');
      return split[reportsIndex + 1];
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleReportsSelector: function toggleReportsSelector() {
        this.toggleProperty('showReportsSelector');
      }
    }
  });

  _exports.default = _default;
});