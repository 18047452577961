define("ember-rs/locales/it/templates/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.account = void 0;
  var account = {
    "settings": {
      "header": "Impostazioni",
      "description": "Queste sono le impostazioni per questo utente e account.",
      "personal-info-header": "Informazioni personali",
      "account-info": {
        "header": "Impostazioni",
        "company-name-header": "Nome società",
        "current-plan-header": "Piano corrente",
        "account-name-header": "Nome account"
      },
      "verified-emails": {
        "header": "Impostazioni",
        "header-description": "Aggiungere uno qualsiasi degli indirizzi e-mail a cui possono essere inviati i documenti. Nella dashboard verranno visualizzati tutti i documenti inviati a uno di questi indirizzi e-mail.",
        "add-email": "Aggiungi indirizzo e-mail verificato",
        "send-email": "Invia e-mail di verifica",
        "enter-email": "Inserire un indirizzo e-mail..."
      },
      "account-settings": {
        "header": "Impostazioni",
        "enable-passcode-header": "Richiedi passcode per i documenti",
        "enable-passcode-description": "Sarà necessario il passcode per firmare il documento. I mittenti possono generare un passcode durante il processo di invio per la firma.",
        "enable-token-expiration-header": "Periodo di scadenza predefinito del token",
        "enable-token-expiration-description": "Impostare la durata della scadenza per tutti i collegamenti ai documenti inviati tramite e-mail, inclusi i collegamenti per la firma e la condivisione.",
        "enable-blue-ink-header": "Firme con inchiostro blu",
        "enable-blue-ink-description": "Tutte le firme acquisite sui documenti inviati da questo account saranno blu. Questo aiuterà a distinguere un originale firmato da una fotocopia.",
        "enable-integrations-description": "Consentire a tutti gli utenti di visualizzare e impostare la connessione con altre app in uso. Disattivandola, nessuno degli utenti sarà in grado di visualizzare le impostazioni di integrazione per abilitare/disabilitare le integrazioni predefinite. In questo modo è possibile controllare centralmente l'accesso di tutti gli utenti alle integrazioni disponibili.",
        "enable-signer-download-header": "Signer document download",
        "enable-signer-download-description": "Allow signers to download the original document before signing."
      },
      "mfa-settings": {
        "enable-mfa-header": "Verifica in due passaggi",
        "enable-mfa-description": "La verifica in due passaggi consente agli utenti di configurare un numero di telefono su cui ricevere un codice tramite SMS o messaggio vocale. Gli utenti si iscrivono tramite il processo di accesso.",
        "opt-out-mfa-description": "La verifica in due passaggi è necessaria per impostazione predefinita per tutti gli utenti. Per rifiutare esplicitamente questa impostazione, è possibile compilare un atto di rinuncia, quindi modificare l'impostazione.",
        "complete-mfa-waiver": "Deroga completa",
        "opt-out-waiver-title": "Atto di rinuncia all'autenticazione a due fattori",
        "opt-out-waiver-description": "Citrix raccomanda vivamente la <a href='https://support.citrix.com/article/CTX277910'>Verifica in due passaggi</a>, una forma di autenticazione a più fattori (MFA, Multi-Factor Authentication), disponibile nel servizio RightSignature come ulteriore livello di sicurezza per ridurre le probabilità di accesso non autorizzato al contenuto RightSignature.",
        "opt-out-waiver-consent": "Rifiutando di abilitare la verifica in due passaggi per questo account RightSignature, si certifica di disporre dell'autorità, per conto dell'ente che ha concesso in licenza questa istanza di RightSignature, per assumersi i rischi e le responsabilità della disabilitazione della verifica in due passaggi.",
        "opt-out-quit": "Esci",
        "opt-out-authority": "Ho l'autorità",
        "opt-out-title": "Si prega di scegliere i motivi per cui non si desidera che il contenuto di questo account RightSignature sia protetto dalla verifica in due passaggi:",
        "opt-out-reason-first": "Non conserviamo dati sensibili o riservati nel nostro account RightSignature",
        "opt-out-reason-second": "Non abbiamo la capacità tecnica di utilizzare la verifica in due passaggi",
        "opt-out-description": "Cliccando sul pulsante Rifiuta la verifica in due passaggi qui sotto, certifico di aver compilato il modulo in buona fede e di assumermi tutti i rischi e le responsabilità associati al mancato utilizzo della verifica in due passaggi.",
        "opt-out-decline": "Rifiuta la verifica in due passaggi",
        "opted-out": "L'applicazione obbligatoria è stata rifiutata il {{optOutDate}} da {{optOutEmail}}. La verifica in due passaggi può ancora essere abilitata/disabilitata facendo clic sul pulsante di attivazione/disattivazione sopra.",
        "opted-out-no-context": "L'applicazione obbligatoria è stata rifiutata. La verifica in due passaggi può ancora essere abilitata/disabilitata facendo clic sul pulsante di attivazione/disattivazione sopra.",
        "opted-out-toast": "Ciò conferma che si è rifiutato di distribuire la verifica in due passaggi obbligatoria per gli utenti coperti da questo account e che si è accettato di sostenere i rischi associati per conto dell'organizzazione."
      },
      "personal-settings": {
        "header": "Impostazioni",
        "enable-field-detection-header": "Utilizzare il rilevamento dei campi per la preparazione dei documenti",
        "enable-field-detection-description": "Utilizzare il rilevamento automatico dei campi durante la preparazione dei documenti anziché posizionarli manualmente.",
        "enable-view-notification-header": "Invia un notifica quando un documento è stato visualizzato",
        "enable-view-notification-description": "Le notifiche verranno inviate al mittente quando i firmatari hanno visualizzato un documento inviato per la firma.",
        "enable-signature-notification-header": "Invia un notifica quando un documento è stato firmato",
        "enable-signature-notification-description": "Le notifiche verranno inviate al mittente ogni volta che un firmatario ha firmato un documento di cui il mittente è una parte (il mittente riceverà comunque una notifica al completamento del documento).",
        "enable-document-complete-notification-header": "Invia una notifica quando un documento è stato completato",
        "enable-document-complete-notification-description": "Le notifiche verranno inviate al mittente quando un documento di cui il mittente è una parte è stato reso esecutivo.",
        "enable-contact-searching-header": "Ricerca di contatti",
        "enable-contact-searching-description": "La ricerca di contatti suggerirà contatti durante la digitazione di un nome quando si aggiungono firmatari in base ai documenti inviati in precedenza, oltre a eventuali integrazioni di contatti abilitate.",
        "default-expiration-setting-header": "Periodo di scadenza predefinito",
        "default-expiration-setting-description": "Impostare il numero predefinito di giorni per la scadenza del documento. È inoltre possibile modificarlo per ogni singolo documento quando si invia un nuovo documento.",
        "enable-send-reminder-email-header": "Invia e-mail di promemoria",
        "enable-send-reminder-email-description": "Destinatari di documenti di posta elettronica settimanalmente (il 3°, 7°, 14°, 21°, 28° giorno) o giornalmente (a partire dal 7° giorno fino al 30° giorno) se un documento rimane non firmato.",
        "enable-attach-completed-pdf-header": "Allega PDF completati nelle e-mail",
        "enable-attach-completed-pdf-description": "Il PDF completato verrà allegato alla notifica completa che viene inviata a tutte le parti coinvolte del documento. I PDF di dimensioni superiori a 10 MB non verranno inclusi come allegati.",
        "default-signer-setting-header": "Mittente predefinito come firmatario",
        "default-signer-setting-description": "Assegnare al mittente il ruolo \"firmatario\" come predefinito in tutti i documenti. È inoltre possibile modificarlo per ogni singolo documento quando si invia un nuovo documento.",
        "signature-types-settings-header": "Tipi di firma consentiti",
        "signature-types-settings-description": "Scegliere i tipi di firme che si accetteranno dai firmatari nei propri documenti.",
        "signature-type-drawn": "Disegnata",
        "signature-type-typed": "Digitata"
      },
      "saved-signature-settings": {
        "header": "Impostazioni",
        "no-signature-description": "Nessuna firma salvata. Una volta firmato un documento, le firme verranno memorizzate qui per la gestione.",
        "signature-available-description": "La firma predefinita verrà inserita quando si fa clic su un campo della firma. È inoltre possibile modificare la firma per ogni singolo documento quando si firma un nuovo documento.",
        "confirm-deletion": "Eliminare questa firma?",
        "default": "Predefinito",
        "success": "La firma predefinita è stata impostata correttamente.",
        "error": "Errore durante l'impostazione della firma predefinita."
      }
    },
    "integrations": {
      "header": "Impostazioni",
      "description": "Queste sono le impostazioni per questo utente e account."
    },
    "branding": {
      "header": "Impostazioni",
      "disabled": {
        "description": "Queste sono le impostazioni per questo utente e account."
      },
      "signing": {
        "description": "Queste sono le impostazioni per questo utente e account.",
        "background-color-header": "Colore di sfondo:",
        "footer-color-header": "Colore piè di pagina:",
        "header-options-header": "Opzioni intestazione:",
        "header-show-logo": "Mostra logo",
        "header-no-logo": "Nessun logo",
        "footer-options-header": "Opzioni piè di pagina:",
        "footer-option-send-name-only": "Invia solo il nome",
        "footer-option-company-name-only": "Solo nome società",
        "footer-option-company-name-only-tooltip": "Aggiungere un nome società nella scheda \"Account\" sopra.",
        "footer-document-name": "Nome documento",
        "footer-from": "Da",
        "footer-at": "alle",
        "footer-option-sender-and-company-name": "Mittente e nome società",
        "footer-option-show-sender-avatar": "Mostra avatar mittente"
      },
      "email": {
        "header": "Impostazioni",
        "description": "Queste sono le impostazioni per questo utente e account.",
        "email-header": "Intestazione e-mail:",
        "show-sender-from": "Mostra il nome del mittente nel campo Da",
        "show-company-subject": "Mostra il nome della società nell'oggetto",
        "show-document-name-in-subject": "Usa il documento nell'oggetto",
        "custom-email-greeting-header": "Saluto e-mail personalizzato:",
        "custom-email-greeting-description": "Apparirà come prima sezione in tutte le e-mail del firmatario.",
        "custom-email-signature-header": "Firma e-mail personalizzata:",
        "custom-email-signature-description": "Apparirà come ultima sezione in tutte le e-mail del firmatario.",
        "mergefield-help": "È possibile utilizzare i seguenti campi di unione per aggiungere informazioni personalizzate all'e-mail:",
        "signer-name": "signer_name",
        "signer-first-name": "signer_first_name",
        "sender-name": "sender_name",
        "document-name": "document_name",
        "document-filename": "document_filename",
        "document-personalized-message": "document_personalized_message",
        "preview-from": "Da:",
        "preview-subject": "Oggetto:",
        "preview-no-logo": "Nessun logo",
        "preview-greeting": "Salve",
        "preview-message": "ha inviato il documento \"{{filename}}\" da firmare",
        "preview-description": "Per rivedere il documento e firmare con una firma elettronica, seguire questo collegamento:",
        "preview-action": "RIVEDI DOCUMENTO"
      }
    },
    "users": {
      "trial-account-description": "Il piano corrente non consente di gestire gli utenti. Aggiornare il piano per accedere a questa funzionalità.",
      "admin-section-header": "Gestisci utenti",
      "admin-section-description": "Questi sono gli utenti di questo account RightSignature&trade;. L'amministratore dell'account è in grado di aggiungere, rimuovere e promuovere gli utenti.",
      "non-admin-section-header": "Utenti",
      "maximum-seats-alert-text": "È stato raggiunto il numero massimo di utenti per l'account. Rimuovere un utente se si desidera aggiungerne un altro o aggiornare l'account per aggiungere altre persone.",
      "seats-used": "<b>{{seatsUsed}}</b> di <b>{{totalSeats}}</b> posti utilizzati",
      "unknown-user": "(utente sconosciuto)",
      "add-user-button-text": "Aggiungi utente",
      "edit-user-button-text": "Modifica utente",
      "span-text-me": "IO",
      "span-text-owner": "PROPRIETARIO",
      "span-text-admin": "AMMINISTRATORE",
      "add": "Aggiungi",
      "account-users-new_introduction": "Inserire il nome e l'indirizzo e-mail della persona che si desidera aggiungere a questo account.",
      "first-name": "Nome...",
      "last-name": "Cognome..."
    },
    "bills": {
      "header": "Impostazioni",
      "contact": "Per visualizzare e/o stampare le ricevute di fatturazione di RightSignature, fare clic sul pulsante di download appropriato qui sotto. Si prega di <a href='https://www.citrix.com/contact/technical-support.html'>chiamarci</a> per assistenza o di <a href='https://support.citrix.com/cscase'>contattarci</a> in caso di ulteriori domande.",
      "date": "Data",
      "type": "Tipo",
      "status": "Stato"
    },
    "api": {
      "header": "Impostazioni",
      "description": "Queste sono le impostazioni per questo utente e account.",
      "primary-action": "Richiedi nuova chiave"
    }
  };
  _exports.account = account;
});