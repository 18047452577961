define("ember-rs/locales/en/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingComplete = void 0;
  var signingComplete = {
    "docSigned": "Document Signed!",
    "createTrial": "Create a free RightSignature account to view or download this completed document. You will have access to this document forever, just register with the email address the document was sent to and log in.",
    "createAccount": "Create an account",
    "login": "Login to account",
    "notNow": "Not Now"
  };
  _exports.signingComplete = signingComplete;
});