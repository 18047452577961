define("ember-rs/locales/de/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTagsEditor = void 0;
  var documentTagsEditor = {
    "addTags": "Fügen Sie diesem Dokument Tags hinzu, um es später leichter zu finden. Sie können den Tags auch Werte hinzufügen, z. B. 'client_id: 1234'",
    "tagName": "Tagname...",
    "optional": "Wert (optional)"
  };
  _exports.documentTagsEditor = documentTagsEditor;
});