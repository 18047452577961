define("ember-rs/templates/components/account-users/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QtwJEcZt",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\"],[\"applicationModals\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"persistent-modal\",[],[[\"@class\",\"@cancel\",\"@save\",\"@cancelText\",\"@saveText\",\"@loading\"],[\"persistent-modal--is-half-width\",[30,[36,1],[\"closeModal\",[35,0]],null],[30,[36,1],[\"deleteUser\"],null],[30,[36,2],[\"shared.actions.cancel\"],null],[30,[36,2],[\"shared.actions.delete\"],null],[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h3\"],[14,0,\"modal-heading\"],[12],[1,[30,[36,2],[\"shared.headers.confirm-deletion\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,2],[\"components.account-users.delete.remove-user-confirmation\"],[[\"user\"],[[35,0,[\"name\"]]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"accountUser\",\"route-action\",\"t\",\"loading\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/account-users/delete.hbs"
    }
  });

  _exports.default = _default;
});