define("ember-rs/locales/nl/routes/email-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailVerification = void 0;
  var emailVerification = {
    "verificationSuccess": "Het e-mailadres is geverifieerd.",
    "invalidVerification": "De e-mailverificatie is ongeldig."
  };
  _exports.emailVerification = emailVerification;
});