define("ember-rs/locales/zh-cn/templates/components/signature-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signatureField = void 0;
  var signatureField = {
    "rotateToSign": "旋转以签名。",
    "rotateTip": "您可能必须解锁设备的屏幕旋转。",
    "clear": "清除",
    "signInstn": "单击并拖动以绘制您的签名",
    "typeUrSign": "键入您的签名",
    "typeSign": "键入签名",
    "drawSign": "绘制签名",
    "or": "或",
    "savedSign": "使用保存的签名",
    "apply": "应用"
  };
  _exports.signatureField = signatureField;
});