define("ember-rs/locales/it/templates/document/search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header = void 0;
  var search_header = {
    "from": "Da: ",
    "state": "Stato: ",
    "search_docs": "Cerca documenti...",
    "all": " Tutti",
    "pending": " In sospeso",
    "declined": " Rifiutato",
    "executed": " Completato",
    "expired": " Scaduto",
    "voided": " Annullato",
    "admin_view": "Visualizzazione amministratore",
    "tool_tip": "Visualizzare tutti i documenti inviati da questo account.",
    "filter": "Filtro"
  };
  _exports.search_header = search_header;
});