define("ember-rs/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IzHYU8Jt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"trial_shade\"],[15,0,[31,[[30,[36,1],[[35,0],\"visible\"],null]]]],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"archived_migrated_modal\"],[15,0,[31,[[30,[36,1],[[35,2,[\"useMobileLayout\"]],\"mobile-size\"],null],\" \",[30,[36,1],[[35,0],\"visible\"],null]]]],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"components.archivedMigratedModal.expiredBilling\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,1,\"update_billing\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"components.archivedMigratedModal.updateBillingDetails\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"clickUpdate\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,\"align\",\"center\"],[12],[2,\"\\n      \"],[10,\"a\"],[14,6,\"/upgrade-worldpay\"],[14,0,\"button\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"components.archivedMigratedModal.clickUpdate\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"subtext\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"components.archivedMigratedModal.contactSupport\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"rs-button\",[],[[\"@action\",\"@isPrimary\",\"@isLink\"],[[30,[36,4],[[32,0],\"signOut\"],null],false,true]],[[\"default\"],[{\"statements\":[[1,[30,[36,3],[\"components.archivedMigratedModal.logout\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"show\",\"if\",\"layoutSizeData\",\"t\",\"action\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/archived-migrated-modal.hbs"
    }
  });

  _exports.default = _default;
});