define("ember-rs/components/available-integration", ["exports", "ember-rs/mixins/integration", "ember-rs/mixins/integration-auth"], function (_exports, _integration, _integrationAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_integration.default, _integrationAuth.default, {
    authParams: {},
    description: Ember.computed('provider', function () {
      switch (this.provider) {
        case 'dropbox':
          return "Import documents from your Dropbox. Even if you're using a computer that can't access your Dropbox directly, you can still upload and use your Dropbox documents easily through RightSignature.";

        case 'freshbooks':
          return "Import invoices and estimates from your Freshbooks. Even if you're using a computer that can't access your Freshbooks directly, you can still upload and use your Freshbook invoices and estimates easily through RightSignature.";

        default:
          return 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';
      }
    }),
    actions: {
      checkIntegrationSettings: function checkIntegrationSettings() {
        if (this.isAuthorized && Ember.isEqual(this.get('integration.sending'), true)) {
          this.sendAction('getIntegrationDocuments', this.provider);
        }
      },
      toggleService: function toggleService(availableService, serviceEnabled, params) {
        this.sendAction('toggleService', availableService, serviceEnabled, this.isAuthorized, this.provider, this.integrationMetadata, params);
      }
    }
  });

  _exports.default = _default;
});