define("ember-rs/locales/nl/templates/onboarding/new-user-onboarding-from-sharefile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFromSharefile = void 0;
  var newUserOnboardingFromSharefile = {
    "title": "Documenten verzenden en ondertekenen",
    "startText": "Verzend documenten die door anderen moeten worden ondertekend of onderteken alle documenten die uw handtekening nodig hebben.",
    "clickLearn": "Klik op Volgende voor meer informatie.",
    "addSigner": "Ondertekenaars toevoegen",
    "addAnyone": "Voeg iedereen toe die dit document moet invullen of ondertekenen.",
    "prepare": "Het document voorbereiden",
    "placeFields": "Plaats velden waar ondertekenaars informatie moeten invullen of het document moeten ondertekenen. U kunt velden aan elke ondertekenaar toewijzen.",
    "sendTrack": "Verzenden en voortgang bijhouden",
    "onceSent": "Nadat u het document hebt verzonden, kunt u de voortgang volgen op de detailpagina van het document. Als u naar de detailpagina wilt gaan, klikt u op het document in uw documentendashboard."
  };
  _exports.newUserOnboardingFromSharefile = newUserOnboardingFromSharefile;
});