define("ember-rs/locales/pt-br/templates/components/document-upload-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploadThumbnail = void 0;
  var documentUploadThumbnail = {
    "noThumb": "Sem miniatura...",
    "upload_done": "upload concluído",
    "downloading": "baixando",
    "storing": "armazenando",
    "converting_to_images": "convertendo em imagens",
    "converting_to_pdf": "convertendo em pdf",
    "processing": "processando",
    "created": "criado"
  };
  _exports.documentUploadThumbnail = documentUploadThumbnail;
});