define("ember-rs/locales/de/templates/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerPaymentDetails = void 0;
  var signerPaymentDetails = {
    "paymentInfo": "Zahlungsinformationen",
    "paymentID": "Zahlungs-ID:",
    "retryPayment": "Zahlungsvorgang wird erneut versucht...",
    "failed": "Fehler",
    "chargedSuccess": "Zahlung erfolgt: {{chargedAt}}.",
    "stripeCustomer": "Kunde von Stripe",
    "stripeCharge": "Kosten für Stripe",
    "attempted": "Letzter Versuch: {{chargedAt}}.",
    "retry": "Wiederholen",
    "pending": "Ausstehend...",
    "success": "Die Zahlung war bei erneutem Versuch erfolgreich.",
    "retryError": "Die Zahlung ist beim erneutem Versuch fehlgeschlagen ({{{errorMsg}}})."
  };
  _exports.signerPaymentDetails = signerPaymentDetails;
});