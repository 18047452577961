define("ember-rs/locales/zh-cn/templates/mobile-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mobileNavigation = void 0;
  var mobileNavigation = {
    "documents": "文档",
    "templates": "模板",
    "settings": "您的设置",
    "integrations": "集成",
    "users": "用户",
    "reports": "报告",
    "help": "帮助",
    "logout": "注销"
  };
  _exports.mobileNavigation = mobileNavigation;
});