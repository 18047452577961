define("ember-rs/locales/en/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareNextRoute = void 0;
  var prepareNextRoute = {
    "mergeFieldStepNext": "Next: Merge fields",
    "documentStepNext": "Next Document",
    "reviewStepNext": "Next: Review",
    "placeFieldNext": "Next: Place fields"
  };
  _exports.prepareNextRoute = prepareNextRoute;
});