define("ember-rs/locales/en/templates/document-transaction/upload-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload_signers = void 0;
  var upload_signers = {
    "instructions_header": "Follow the steps below to upload your CSV File",
    "step_1": "STEP 1",
    "step_2": "STEP 2",
    "step_3": "STEP 3",
    "step_1_text": "Download the Bulk Send Signer sample list",
    "step_2_text": "Fill in the name and e-mail fields (required).",
    "step_3_text": "Upload the completed csv.",
    "instructions_note": "Please Note: The number of signers cannot exceed 300",
    "upload_failed": "Upload Failed",
    "upload_failure_message": "Upload failed due to corrupted file or processing error. Make sure the file has valid content and try uploading again.",
    "view_all": "View all",
    "ok": "Ok",
    "validate_and_reupload": "Your file contains more errors, check the validations and try reuploading."
  };
  _exports.upload_signers = upload_signers;
});