define("ember-rs/templates/components/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+k3b3ZPF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"message\",\"hasMessage\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/tool-tip.hbs"
    }
  });

  _exports.default = _default;
});