define("ember-rs/locales/ja/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbarMenuitem = void 0;
  var componentToolbarMenuitem = {
    "signatureComponent": "署名フィールド",
    "textComponent": "テキスト フィールド",
    "dateComponent": "日付フィールド",
    "checkComponent": "チェックボックス",
    "checkGroupComponent": "チェックボックス グループ",
    "initialsComponent": "イニシャル フィールド",
    "selectComponent": "ボックスを選択",
    "signatureAnnotationComponent": "署名を追加",
    "signatureAnnotationName": "署名の注釈",
    "textAnnotationComponent": "テキストを追加",
    "textAnnotationName": "コメントの注釈",
    "checkmarkAnnotationComponent": "チェックマークを追加",
    "checkmarkAnnotationName": "チェックマークの注釈",
    "attachmentComponent": "添付",
    "attachmentComponentName": "要求された添付ファイル",
    "paymentComponent": "支払いを指定",
    "signatureComponenttip": "このフィールドは、署名者が署名する場所に配置します。",
    "textComponenttip": "このフィールドは、署名者がテキスト情報 (名前や住所など) を入力する場所に配置します。",
    "dateComponenttip": "このフィールドを使用して、日付を追加します。ドキュメントに署名するときに、日付を簡単に選択できる日付ピッカーが署名者に表示されます。日付は「MM-DD-YYYY」の形式で表示されます。",
    "checkComponenttip": "このフィールドは、署名者がオプション (「次に同意します」という文章など) を確認する必要がある場合に使用します。ドキュメントに複数のオプションがあり、1 つのオプションのみまたは最低 1 つのオプションを選択する制限がある場合、[チェックボックス グループ] オプションを使用します。",
    "checkGroupComponenttip": "チェックボックス グループは、複数のオプションがあり、署名者が 1 つのオプションのみまたは最低 1 つのオプションを選択する制限がある場合に使用します。",
    "initialsComponenttip": "このフィールドは、署名者がイニシャルを入力する場所に配置します。",
    "selectComponenttip": "[ボックスを選択] は、署名者がオプション一覧から選択する場所に配置します。",
    "signatureAnnotationComponenttip": "ドキュメントに署名を追加します。",
    "textAnnotationComponenttip": "ドキュメントに事前入力される、または注釈として加えるテキストを追加します。",
    "checkmarkAnnotationComponenttip": "ドキュメント内のチェックボックスをオンにします。",
    "attachmentComponenttip": "このボックスは、署名者にドキュメントへの画像またはファイルの添付を求める場所に配置します。",
    "paymentComponenttip": "このオプションを使用してドキュメントにボタンを追加し、支払いの指定時に署名者がクレジットカード番号を入力できるようにします。注: これを使用する前に、アカウントで支払いの統合を有効にする必要があります ([アカウント] で [統合] を選択しますj)。",
    "unknownField": "不明なフィールド"
  };
  _exports.componentToolbarMenuitem = componentToolbarMenuitem;
});