define("ember-rs/locales/pt-br/templates/reports", ["exports", "ember-rs/locales/pt-br/templates/reports/export"], function (_exports, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    export: _export.exportTrans,
    "header": "Relatórios",
    "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
    "select-report-type--text": "Selecionar tipo de relatório",
    "select-report-type-option-overview": "Visão geral",
    "overviewReport": "Relatório de visão geral",
    "select-report-type-option-efficiency": "Eficiência",
    "efficiencyReport": "Relatório de eficiência",
    "select-report-type-option-cycle-time": "Relatório de tempo de ciclo",
    "select-report-type-option-export": "Exportador de dados",
    "select-report-type-option-enterprise": "Empresarial",
    "enterpriseUsageReport": "Relatório de uso da empresa",
    "userUsageReport": "Relatório de uso do usuário",
    "select-report-type-option-user": "Relatório de uso novo",
    "components": {
      "account-document-volume": {
        "header": "Relatórios",
        "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
        "legend-document-sent": "Documento enviado",
        "legend-document-signed": "Documento assinado",
        "legend": "Legenda:"
      },
      "account-statistics": {
        "header": "Relatórios",
        "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
        "column-header-current-month": "Este mês",
        "column-header-previous-month": "Último mês",
        "column-header-average": "Média",
        "row-header-sent-documents": "Documentos enviados",
        "row-header-viewed": "Visto (por pelo menos um signatário)",
        "row-header-expired": "Expirado",
        "row-header-signed": "Assinado",
        "row-header-sent-documents-per-user": "Documentos enviados por usuário (média)",
        "row-header-signed-documents": "Documentos assinados",
        "row-header-pending-documents": "Documentos pendentes",
        "row-header-viewed-pending": "Visto (dos pendentes)",
        "none": "N/D"
      },
      "top-users-by-signed-documents": {
        "header": "Relatórios",
        "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
        "column-header-user": "Usuário",
        "column-header-signed-documents": "Documentos assinados"
      },
      "account-efficiency": {
        "header": "Relatórios",
        "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
        "legend-pending": "Pendente",
        "legend-signed": "Assinado",
        "legend-expired": "Expirado"
      },
      "top-users-by-efficiency": {
        "header": "Relatórios",
        "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
        "column-header-user": "Usuário",
        "column-header-efficiency-percentage": "Eficiência (%)",
        "column-header-ratio": "Proporção",
        "over-gcd": "{{signed}} de {{sent}}"
      },
      "top-users-by-time-to-sign": {
        "header": "Relatórios",
        "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
        "column-header-user": "Usuário",
        "column-header-mean-time": "Tempo médio (horas)"
      },
      "average-cycle-time": {
        "header": "Relatórios",
        "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
        "legend-mean-time-to-view": "Tempo médio para exibir",
        "legend-mean-time-to-sign": "Tempo médio para assinar"
      },
      "cycle-time-statistics": {
        "header": "Relatórios",
        "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
        "column-header-current-month": "Este mês",
        "column-header-previous-month": "Último mês",
        "column-header-all-time": "Todo o tempo",
        "row-header-under-5-mins": "Menos de 5 minutos",
        "row-header-under-30-mins": "Menos de 30 minutos",
        "row-header-under-1-hour": "Menos de uma hora",
        "row-header-under-12-hours": "Menos de 12 horas",
        "row-header-under-1-day": "Menos de um dia"
      },
      "month-export": {
        "header": "Relatórios",
        "description": "Exibir dados para seus documentos enviados. Isso inclui o tempo que normalmente leva para obter documentos assinados, bem como quantos documentos foram concluídos por mês. Atualize seu plano para acessar relatórios.",
        "export-to-csv-section-header": "Exportar para CSV",
        "export-to-csv-section-description": "Um CSV pode ser aberto no Excel para exibir dados em seus documentos enviados."
      },
      "generate-download": {
        "ie-warning-text": "Clique com o botão direito do mouse em 'Exportar CSV' e escolha Salvar como para baixar o CSV.",
        "export-csv-button-text": "Exportar CSV",
        "generate-csv-button-text": "Gerar CSV",
        "send-download-link": "Vamos gerar o relatório e enviar o link de download por email."
      }
    }
  };
  _exports.reports = reports;
});