define("ember-rs/locales/fr/templates/components/document-token-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTokenExpirationSelect = void 0;
  var documentTokenExpirationSelect = {
    "Do_not_expire_links": "Les liens n'expirent pas",
    "1_hour": "1 heure",
    "3_hours": "3 heures",
    "8_hours": "8 heures",
    "24_hours": "24 heures",
    "1_week": "1 semaine"
  };
  _exports.documentTokenExpirationSelect = documentTokenExpirationSelect;
});