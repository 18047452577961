define("ember-rs/locales/zh-cn/templates/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlay = void 0;
  var importOverlay = {
    "importFrom": "从此位置导入字段:",
    "toImport": "组件，要从以下位置导入:",
    "page": "页",
    "cancel": "取消",
    "import": "导入",
    "noComponents": "没有要导入的组件!",
    "pastDocs": "从过去的文档中导入字段",
    "noMatch": "找不到与此名称匹配的文档",
    "importSuccess": "已成功导入 {{{components}}} 组件。"
  };
  _exports.importOverlay = importOverlay;
});