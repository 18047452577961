define("ember-rs/templates/components/signature-text-pad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "erXHERru",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[14,0,\"minimal clear theme_link\"],[15,\"onclick\",[30,[36,0],[[32,0],\"clearSignature\"],null]],[12],[1,[30,[36,1],[\"components.signatureField.clear\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"signature-pad-wrapper text\"],[12],[2,\"\\n  \"],[8,\"limited-input-text-field\",[],[[\"@type\",\"@value\",\"@class\",\"@autofocus\",\"@input\"],[\"text\",[34,4],\"type-pad\",true,[30,[36,0],[[32,0],\"signatureTyped\"],null]]],null],[2,\"\\n  \"],[10,\"canvas\"],[15,\"width\",[34,5]],[15,\"height\",[34,6]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"hidden-resizer\"],[12],[13],[2,\"\\n\"],[10,\"input\"],[14,3,\"output\"],[14,0,\"output\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"currentSignatureText\",\"if\",\"inputValue\",\"width\",\"height\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/signature-text-pad.hbs"
    }
  });

  _exports.default = _default;
});