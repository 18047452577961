define("ember-rs/locales/zh-cn/templates/components/component-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentEditor = void 0;
  var componentEditor = {
    "charge": "在执行后收费?",
    "selectOne": "选择一个...",
    "amount": "付款金额(美元):",
    "enterAmount": "输入付款金额...",
    "required": "必填: 请在下面输入签名者帮助文本以告知收件人要上载的内容。",
    "signerHelp": "签名者帮助文本:",
    "helpText": "输入帮助文本...",
    "selectBox": "选择框至少需要 2 个选项。",
    "selectOptions": "选择选项:",
    "addOption": "添加选项...",
    "addToGroup": "添加到组",
    "assignedTo": "已分配给:",
    "fieldRequired": "必填字段?",
    "autoFillDate": "Auto-fill date signed",
    "autoFillDateHelpText": "A recipient will not be able to edit the field. It will be filled in automatically with the date on which the recipient signs.",
    "mergeField": "合并字段?",
    "mergeFieldName": "合并字段需要一个名称",
    "nameMergeField": "命名合并字段:",
    "setFontSize": "设置字体大小...",
    "muiltiline": "多行?",
    "clearBackground": "清除背景?",
    "addDefaultValue": "添加默认值?",
    "setDefaultValue": "设置默认值...",
    "defaultValue": "默认值:",
    "nameThisField": "命名此字段?",
    "name": "名称:",
    "setName": "设置组件的名称...",
    "textAlign": "文本对齐",
    "showLess": "显示更少",
    "showMore": "显示更多",
    "delete": "删除",
    "saved": "已保存!",
    "save": "保存",
    "dateFormat": "日期格式",
    "left": "左对齐",
    "center": "居中",
    "right": "右对齐",
    "groupRequirement": "组要求",
    "only_one": "仅限一个",
    "one_or_more": "一个或多个",
    "options_warning": "您已添加该选项"
  };
  _exports.componentEditor = componentEditor;
});