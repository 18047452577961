define("ember-rs/controllers/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_ATTEMPTS = 3;

  var _default = Ember.Controller.extend({
    ////////////////////////  SERVICEs  ////////////////////////
    cookies: Ember.inject.service(),
    kbaSession: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    attempts: Ember.computed.alias('model.attempts'),
    attemptMade: Ember.computed.gt('attempts', 0),
    questionSet: Ember.computed.alias('model.questions'),
    questionSetId: Ember.computed.alias('model.question_set_id'),
    attemptsRemaining: Ember.computed('attempts', function () {
      return MAX_ATTEMPTS - this.attempts;
    }),
    questionSetLength: Ember.computed('questionSet', function () {
      return this.questionSet.length;
    }),
    valid: Ember.computed('questionSet.@each.answerId', function () {
      return this.questionSetLength != 0 && this.questionSet.every(function (question) {
        return Ember.isPresent(Ember.get(question, 'answerId'));
      });
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      submit: function submit(questionSet) {
        var _this = this;

        var question_set_id = this.questionSetId;
        var answers = [];
        questionSet.forEach(function (q) {
          answers.push({
            question_id: Ember.get(q, 'id'),
            answer_id: Ember.get(q, 'answerId')
          });
        });
        var data = {
          question_set_id: question_set_id,
          answers: answers
        };
        var kbaSession = this.kbaSession;
        Ember.set(this, 'saving', true);
        return kbaSession.request('/verify', 'POST', data, true).then(function (_ref) {
          var kba = _ref.kba;

          if (kba.success) {
            _this.send('nextStep');
          } else {
            _this.send('reload');

            Ember.set(_this, 'saving', false);
          }
        }, function (error) {
          Ember.set(_this, 'saving', false);

          _this.send('error', error);
        });
      },
      toggleAdvisoryText: function toggleAdvisoryText() {
        this.toggleProperty('showIDAdvisory');
      },
      selectAnswer: function selectAnswer(questionSet, answer, event) {
        var answerId = Ember.get(answer, 'id');

        if (Ember.get(questionSet, 'answerId') !== answerId) {
          Ember.set(questionSet, 'answerId', answerId);
        } else {
          event.preventDefault();
        }
      }
    }
  });

  _exports.default = _default;
});