define("ember-rs/locales/ja/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTagsEditor = void 0;
  var documentTagsEditor = {
    "addTags": "後で見つけやすいように、このドキュメントにタグを追加します。値を追加することもできます (例: 'client_id: 1234')",
    "tagName": "タグ名...",
    "optional": "値 (オプション)"
  };
  _exports.documentTagsEditor = documentTagsEditor;
});