define("ember-rs/controllers/client-application", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    hasRetrievedSecret: Ember.computed.notEmpty('secret'),
    // Prevents generating another token in current session (just need to reload page to reset)
    hasGeneratedToken: false,
    actions: {
      getSecret: function getSecret() {
        var _this = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/client_applications/").concat(this.get('model.id'), "/secrets"),
          type: 'GET',
          success: function success(data) {
            _this.flashMessages.success(_this.i18n.t('controllers.clientApplications.newClientApplication.secretsSuccess'));

            _this.setProperties({
              secret: data.secret,
              privateAPIToken: data.private_api_token
            });
          },
          error: function error(data) {
            _this.flashMessages.warning(_this.i18n.t('controllers.clientApplications.newClientApplication.secretsError', {
              error: data.responseJSON.error
            }));
          }
        });
      },
      generateNewPrivateToken: function generateNewPrivateToken() {
        var _this2 = this;

        if (this.hasGeneratedToken) {
          this.flashMessages.warning('Already generated a new token.');
        } else {
          _jquery.default.ajax({
            url: "".concat(_environment.default.api.endpoint, "/client_applications/").concat(this.get('model.id'), "/generate_new_token"),
            type: 'POST',
            success: function success(data) {
              _this2.flashMessages.success(_this2.i18n.t('controllers.clientApplications.newClientApplication.tokenSuccess'));

              _this2.setProperties({
                secret: data.secret,
                privateAPIToken: data.private_api_token,
                hasGeneratedToken: true
              });
            },
            error: function error(data) {
              _this2.flashMessages.warning(_this2.i18n.t('controllers.clientApplications.newClientApplication.tokenError', {
                error: data.responseJSON.error
              }));
            }
          });
        }
      },
      redirect: function redirect() {
        this.transitionToRoute("client-applications");
      }
    }
  });

  _exports.default = _default;
});