define("ember-rs/locales/ja/templates/components/template/template-signer-link-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.templateSignerLinkItem = void 0;
  var templateSignerLinkItem = {
    "view": "表示",
    "details": "詳細",
    "reenable": "再度有効化",
    "disable": "無効化",
    "signed": "署名済み",
    "identityMethod": "ID 確認の方法: ",
    "expires": "有効期限: ",
    "passcode": "保護されたパスコード: ",
    "maxSigners": "署名者の最大数: ",
    "doesNotExpire": "有効期限はありません"
  };
  _exports.templateSignerLinkItem = templateSignerLinkItem;
});