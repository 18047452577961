define("ember-rs/locales/zh-cn/templates/components/unconfirmed-user-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedUserButton = void 0;
  var unconfirmedUserButton = {
    "emailUnverified": "您的电子邮件地址未经验证",
    "verifySendDocument": "在您发送第一个文档之前，我们需要验证您的电子邮件地址。",
    "verifyCreateTemplate": "在您创建第一个模板之前，我们需要验证您的电子邮件地址。",
    "verifyUpgrade": "在您升级 hybris 帐户之前，我们需要验证您的电子邮件地址。",
    "verifyIntegration": "在您启用集成之前，我们需要验证您的电子邮件地址。",
    "verifyEmail": "我们向下面的地址发送了一封验证电子邮件。",
    "checkEmail": "没有收到电子邮件吗? 请检查您的垃圾邮件文件夹或",
    "resend": "重新发送",
    "verificationMail": "验证电子邮件已发送。请检查 {{{email}}} 并按照说明进行操作。"
  };
  _exports.unconfirmedUserButton = unconfirmedUserButton;
});