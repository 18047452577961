define("ember-rs/locales/es/templates/signer/passcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcode = void 0;
  var passcode = {
    "enterPin": "Introducir PIN.",
    "access": "Introduzca el PIN suministrado para acceder al documento.",
    "unlock": "Desbloquear",
    "docProtected": "Este documento está protegido con un código de acceso.",
    "answer": "respuesta...",
    "show": "mostrar código de acceso",
    "continue": "Continuar con el documento",
    "answerQuestion": "Responda esta pregunta de seguridad para acceder al documento"
  };
  _exports.passcode = passcode;
});