define("ember-rs/locales/pt-br/templates/components/agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "welcome": "Bem-vindo",
    "toRS": "para o RightSignature,",
    "bestOnDesktop": "O RightSignature funciona melhor em um computador desktop.",
    "pleaseRead": "Antes de começarmos, leia o seguinte:",
    "terms": "Ao usar o RightSignature&trade; você está declarando que leu e concorda com nossos ",
    "and": "e",
    "policy": "Política de Privacidade",
    "no": "Não, obrigado",
    "yes": "Concordo"
  };
  _exports.agreeToTerms = agreeToTerms;
});