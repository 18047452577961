define("ember-rs/locales/es/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "unlock_message": "Uno o varios firmantes han excedido la cantidad máxima de intentos de verificación de identidad (3), y el documento ahora está bloqueado para ellos. Para desbloquear el documento y solicitar a los demás firmantes que verifiquen sus identidades respectivas con la autenticación basada en conocimientos, haga clic en Desbloquear. Tenga en cuenta que desbloquear el documento puede entrar en conflicto con las directrices de <a target='_blank' href='https://www.irs.gov/uac/electronic-signature-guidance-for-forms-8878-and-8879'>IRS Electronic Signature Guidance for Forms 8878 and 8879</a>."
  };
  _exports.unlock = unlock;
});