define("ember-rs/locales/de/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pendingIdentityVerification = void 0;
  var pendingIdentityVerification = {
    "pendingIdentityVerification": "Ausstehende Identitätsprüfung",
    "success": "Sie haben dieses Dokument signiert. Um dieses Dokument fertigzustellen und es an den Absender zurückzuschicken, müssen Sie Ihre Identität bestätigen. Rufen Sie dazu Ihre E-Mails ab und klicken Sie in der von RightSignature gesendeten E-Mail auf den Link zum Bestätigen der E-Mail-Adresse.",
    "resend": "Identitätsprüfung erneut senden"
  };
  _exports.pendingIdentityVerification = pendingIdentityVerification;
});