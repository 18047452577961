define("ember-rs/locales/de/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationSettingsService = void 0;
  var integrationSettingsService = {
    "requiresInfo": "Diese Integration erfordert einige Informationen, um fortzufahren:",
    "authorize": "Autorisieren",
    "sending": "Senden Sie Ihre {{{formattedProviderName}}}-Dokumente von RightSignature aus zur E-Signatur",
    "archiving": "Senden Sie eine PDF-Datei mit abgeschlossenen Dokumenten an einen bestimmten Ordner in Ihrem {{{formattedProviderName}}}-Konto",
    "contacts": "Machen Sie Ihre {{{formattedProviderName}}}-Kontakte alle zum Signieren von Dokumenten verfügbar",
    "payments": "Platzieren Sie Zahlungskomponenten in Ihre RightSignature-Dokumente, um Kreditkartendaten sicher zu sammeln und zu speichern und Gebühren mit Ihrem {{{formattedProviderName}}}-Konto zu autorisieren",
    "fieldsInfo": "Bitte füllen Sie alle Felder aus"
  };
  _exports.integrationSettingsService = integrationSettingsService;
});