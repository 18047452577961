define("ember-rs/locales/pt-br/templates/components/account-user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "basic": "Informações básicas",
    "firstName": "Primeiro nome:",
    "lastName": "Sobrenome:",
    "required": "*obrigatório",
    "email": "Email:",
    "emailTip": "*Você deve fornecer um email válido.",
    "password": "Senha:",
    "hiddenForSecurity": "oculto por razões de segurança",
    "reset": "Reiniciar",
    "emailInfo": "Enviar informações de login por email",
    "oldPass": "Senha antiga:",
    "newPass": "Nova senha:",
    "passReq": "*As senhas devem conter no mínimo 8 caracteres, entre eles no mínimo 1 número, 1 letra maiúscula e 1 letra minúscula.",
    "permissions": "Permissões",
    "manageUsers": "Gerenciar usuários",
    "manageDelegateUsers": "Pode delegar usuários",
    "manageViewBills": "Pode ver as contas",
    "customizeBranding": "Personalizar a identidade visual da conta",
    "manageTemplates": "Gerenciar modelos",
    "viewDocuments": "Ver todos os documentos",
    "removeUser": "Remover usuário",
    "reset2fa": "Redefinir a verificação em duas etapas"
  };
  _exports.edit = edit;
});