define("ember-rs/locales/es/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTagsEditor = void 0;
  var documentTagsEditor = {
    "addTags": "Agregue etiquetas a este documento para encontrarlo fácilmente más adelante. También puede agregar valores a las etiquetas; por ejemplo, 'id_cliente: 1234'",
    "tagName": "Nombre de la etiqueta...",
    "optional": "Valor (optativo)"
  };
  _exports.documentTagsEditor = documentTagsEditor;
});