define("ember-rs/locales/fr/mixins/integration-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocuments = void 0;
  var integrationDocuments = {
    "enable_sending": "Envoi activé avec succès pour {{{provider}}}",
    "enable_archiving": "Archivage activé avec succès pour {{{provider}}}",
    "enable_contacts": "Contacts activés avec succès pour {{{provider}}}",
    "enable_payments": "Paiements activés avec succès pour {{{provider}}}",
    "disable_sending": "Envoi désactivé avec succès pour {{{provider}}}",
    "disable_archiving": "Archivage désactivé avec succès pour {{{provider}}}",
    "disable_contacts": "Contacts désactivés avec succès pour {{{provider}}}",
    "disable_payments": "Paiements désactivés avec succès pour {{{provider}}}",
    "errorToggle": "Erreur. Impossible de modifier {{{service}}} pour {{{provider}}} !",
    "errorRetrieve": "Erreur. Impossible de récupérer le document.",
    "errorProviderRetrieve": "Erreur. Impossible de récupérer les documents de {{{provider}}}."
  };
  _exports.integrationDocuments = integrationDocuments;
});