define("ember-rs/locales/nl/templates/onboarding/prepare-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding2 = void 0;
  var prepareOnboarding2 = {
    "useTools": "Met deze hulpmiddelen kunt u aantekeningen in het document maken, zoals <span>uw eigen handtekening, tekst of vinkjes</span>"
  };
  _exports.prepareOnboarding2 = prepareOnboarding2;
});