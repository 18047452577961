define("ember-rs/locales/de/templates/unauthorized/document-executed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExecuted = void 0;
  var documentExecuted = {
    "docComplete": "Dieses Dokument ist fertig",
    "toDownload": "Fordern Sie einen neuen Downloadlink an, um dieses Dokument herunterzuladen.",
    "sendLink": "Neuen Downloadlink senden"
  };
  _exports.documentExecuted = documentExecuted;
});