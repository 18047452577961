define("ember-rs/locales/pt-br/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding1 = void 0;
  var accountOnboarding1 = {
    "body": "Altere <span>suas configurações</span>, <span>a personalização da marca</span> sua conta, <span>integre</span> com outras ferramentas, entre outras coisas."
  };
  _exports.accountOnboarding1 = accountOnboarding1;
});