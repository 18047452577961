define("ember-rs/locales/en/routes/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTransaction = void 0;
  var documentTransaction = {
    "disabledPaymentIntegration": "You must enable a payment integration before sending the following documents: {{names}}",
    "disabledPasscode": "You must set a passcode pin to send the following document(s): {{names}}",
    "successfulDocCreation": "Successfully created documents."
  };
  _exports.documentTransaction = documentTransaction;
});