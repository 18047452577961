define("ember-rs/locales/nl/templates/onboarding/signer-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding1 = void 0;
  var signerOnboarding1 = {
    "redFlag": "De <span>rode vlag</span> wijst u op de gebieden die moeten worden ingevuld.",
    "zoom": "Knijp en zoom uit op uw telefoon om het document te vergroten."
  };
  _exports.signerOnboarding1 = signerOnboarding1;
});