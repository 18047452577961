define("ember-rs/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LQjMldTs",
    "block": "{\"symbols\":[\"preauthParam\",\"&default\"],\"statements\":[[18,2,null],[2,\"\\n\\n\"],[10,\"label\"],[14,0,\"description\"],[12],[2,\"\\n  \"],[1,[34,8]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,10],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"formfields\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[1,[30,[36,3],[\"components.integrationSettingsService.requiresInfo\"],null]],[13],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"integration-setting-service-field\",[],[[\"@name\",\"@description\",\"@authParams\",\"@paramName\"],[[32,1,[\"name\"]],[32,1,[\"description\"]],[34,2],[32,1,[\"param_name\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[11,\"button\"],[4,[38,7],[[32,0],\"savePreauthorization\"],null],[12],[1,[30,[36,3],[\"components.integrationSettingsService.authorize\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,10],[[35,11]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"rs-toggle\",[],[[\"@isToggled\",\"@toggleAction\",\"@isDisabled\"],[[34,0],\"serviceToggled\",[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"unconfirmed-user-button\",[],[[\"@actionPhrase\"],[\"enableIntegration\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"rs-toggle\",[],[[\"@isToggled\",\"@isDisabled\"],[[34,0],[34,1]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"serviceEnabled\",\"disabled\",\"authParams\",\"t\",\"preauthorizationParams\",\"-track-array\",\"each\",\"action\",\"serviceDescription\",\"showParamsFields\",\"if\",\"isConfirmed\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/integration-settings-service.hbs"
    }
  });

  _exports.default = _default;
});