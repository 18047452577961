define("ember-rs/locales/pt-br/templates/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = void 0;
  var verify = {
    "advisory": "O remetente deste documento ativou o recurso Autenticação baseada em conhecimento do RightSignature para confirmar sua identidade. O RightSignature emprega os serviços de terceiros do BlockScore para validar e confirmar as informações pessoais fornecidas. O uso das informações pessoais fornecidas é limitado ao desempenho da validação e confirmação de identidade com os registros de dados proprietários do BlockScore. O desempenho da validação e confirmação de identidade não afetará seu relatório de crédito, pontuação de crédito ou relatórios aos credores. Nem o IRS (fisco dos Estados Unidos), o BlockScore ou o RightSignature terão a capacidade de visualizar ou acessar seu relatório de crédito ou suas informações fiscais como parte desse processo.",
    "step2": "Confirmação de identidade - Etapa 2 de 2",
    "answer": "Responda às perguntas de confirmação de identidade abaixo, marcando a caixa de seleção ao lado da resposta apropriada.",
    "noMatch": "As respostas que você forneceu não correspondem aos nossos registros dessa identidade.",
    "youHave": "Você tem ",
    "remaining": "tentativas restantes de acessar o documento.",
    "complete": "Concluir confirmação",
    "return": "Voltar para o formulário",
    "advisory2": "Aviso sobre a verificação de identidade",
    "unableToAuthenticate": "Não é possível autenticar com sucesso. Entre em contato com <b>{{{sender_email}}}</b> para acessar o documento por meio de um mecanismo de autenticação alternativo."
  };
  _exports.verify = verify;
});