define("ember-rs/locales/nl/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers = void 0;
  var prepare_signers = {
    "add_signers": "Ondertekenaars toevoegen",
    "doc_limit_p1": "U hebt ",
    "doc_limit_p2": "van uw documentlimiet voor de maand gebruikt. Neem contact met ons op om uw account te upgraden.",
    "recentContacts": "Recente contactpersonen"
  };
  _exports.prepare_signers = prepare_signers;
});