define("ember-rs/locales/fr/templates/components/client-application", ["exports", "ember-rs/locales/fr/templates/components/client-application/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clientApplication = void 0;
  var clientApplication = {
    edit: _edit.edit
  };
  _exports.clientApplication = clientApplication;
});