define("ember-rs/locales/ja/templates/components/document-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationSelect = void 0;
  var documentExpirationSelect = {
    "days": "日"
  };
  _exports.documentExpirationSelect = documentExpirationSelect;
});