define("ember-rs/templates/reports/efficiency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FWPeC8d6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"reports/account-efficiency\",[],[[\"@report\"],[[34,0]]],null],[2,\"\\n\\n\"],[8,\"reports/top-users-by-efficiency\",[],[[\"@report\"],[[34,0]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"report\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/reports/efficiency.hbs"
    }
  });

  _exports.default = _default;
});