define("ember-rs/locales/nl/templates/onboarding/welcome-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding1 = void 0;
  var welcomeOnboarding1 = {
    "welcome": "Welkom bij RightSignature,",
    "tips": "We laten u zien waar u functies kunt vinden, hoe u RightSignature moet gebruiken en geven tips om optimaal gebruik te maken van onze service"
  };
  _exports.welcomeOnboarding1 = welcomeOnboarding1;
});