define("ember-rs/locales/it/templates/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.index = void 0;
  var index = {
    "templates": "Modelli",
    "reusableTemplates": "Modelli riutilizzabili",
    "templateExplain": "I modelli consentono di incorporare documenti nel sito, distribuirli in massa o semplicemente inviare i documenti utilizzati di frequente in un solo passaggio.",
    "newTemplate": "Nuovo modello",
    "noResults": "Nessun risultato di ricerca",
    "searchAgain": "La ricerca non ha restituito alcun risultato, provare un'altra ricerca per trovare il modello.",
    "create": "Creare modelli di documenti riutilizzabili da incorporare nel proprio sito e condividere tramite collegamento.",
    "createTemplate": "Crea modello",
    "notAllowed": "Il piano attuale non consente di creare modelli. Se si desidera creare modelli, contattare il supporto e aggiornare l'account."
  };
  _exports.index = index;
});