define("ember-rs/locales/nl/templates/onboarding/migration-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.migrationOnboarding = void 0;
  var migrationOnboarding = {
    "migrationHeading": "Ontdek de nieuwste versie van RightSignature!",
    "slide1Q": "Wat is er nieuw?",
    "slide1a": "Geavanceerde beveiligingsfuncties:",
    "slide1b": "Op kennis gebaseerde verificatie, afdwingbare toegang met wachtwoordcode en meer.",
    "slide1c": "Verbeterde bruikbaarheid:",
    "slide1d": "Een nieuwe gestroomlijnde layout, verbeterde aanpasbaarheid, productiviteitsmeldingen en verbeterde mobiele functionaliteit.",
    "slide2": "Waar zijn de documenten uit de oude versie van RightSignature?",
    "slide3": "Waar zijn de sjablonen uit de oude versie van RightSignature?",
    "slide4": "Hoe kan ik de nieuwste versie van RightSignature omzetten naar een betaald abonnement?",
    "continue": "Doorgaan",
    "dismiss": "Sluiten"
  };
  _exports.migrationOnboarding = migrationOnboarding;
});