define("ember-rs/locales/en/routes/email-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailVerification = void 0;
  var emailVerification = {
    "verificationSuccess": "Successfully verified email.",
    "invalidVerification": "The email verification is invalid."
  };
  _exports.emailVerification = emailVerification;
});