define("ember-rs/locales/ja/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceCompleteDocumentModal = void 0;
  var forceCompleteDocumentModal = {
    "header": "ドキュメントの完了の強制",
    "forceCompleted": "署名していない署名者に割り当てられたすべてのフィールドが削除され、ドキュメントが署名済み (最終状態) になります。これが完了すると、元に戻すことはできません。",
    "briefMoment": "新しい状態がダッシュ​​ボードに反映されるまで、時間がかかることがあります。",
    "forceComplete": "完了の強制",
    "cancel": "キャンセル",
    "success": "ドキュメントの強制完了に成功しました。",
    "failed": "ドキュメントの強制完了に失敗しました"
  };
  _exports.forceCompleteDocumentModal = forceCompleteDocumentModal;
});