define("ember-rs/mixins/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  SERVICES  ////////////////////////
    documentViewerData: Ember.inject.service(),
    ////////////////////////  METHODS  ////////////////////////
    getComponentDimensions: function getComponentDimensions(component) {
      return Ember.Object.create({
        width: component.get('width') * Ember.get(this, 'documentViewerData.pageWidth'),
        height: component.get('height') * Ember.get(this, 'documentViewerData.pageHeight'),
        x: component.get('x') * Ember.get(this, 'documentViewerData.pageWidth'),
        y: component.get('y') * Ember.get(this, 'documentViewerData.pageHeight')
      });
    },
    setComponentDimensions: function setComponentDimensions(component, dimensions) {
      if (dimensions.width > Ember.get(this, 'documentViewerData.pageWidth')) {
        dimensions.width = Ember.get(this, 'documentViewerData.pageWidth');
      }

      if (dimensions.height > Ember.get(this, 'documentViewerData.pageHeight')) {
        dimensions.height = Ember.get(this, 'documentViewerData.pageHeight');
      }

      if (dimensions.x < 0) {
        dimensions.x = 0;
      }

      if (dimensions.x + dimensions.width > Ember.get(this, 'documentViewerData.pageWidth')) {
        dimensions.x = Ember.get(this, 'documentViewerData.pageWidth') - dimensions.width;
      }

      if (dimensions.y < 0) {
        dimensions.y = 0;
      }

      if (dimensions.y + dimensions.height > Ember.get(this, 'documentViewerData.pageHeight')) {
        dimensions.y = Ember.get(this, 'documentViewerData.pageHeight') - dimensions.height;
      }

      component.setProperties({
        'width': dimensions.width / Ember.get(this, 'documentViewerData.pageWidth'),
        'height': dimensions.height / Ember.get(this, 'documentViewerData.pageHeight'),
        'x': dimensions.x / Ember.get(this, 'documentViewerData.pageWidth'),
        'y': dimensions.y / Ember.get(this, 'documentViewerData.pageHeight')
      });
    },
    getNextGroupMemberPlacement: function getNextGroupMemberPlacement(components) {
      // sorting all group members in desdending x order and returning the highest plus spacing based on the width
      var sorted = components.sort(function (a, b) {
        return b.get('x') - a.get('x');
      });
      return {
        width: sorted[0].get('width'),
        height: sorted[0].get('height'),
        x: sorted[0].get('width') * 2 + sorted[0].get('x'),
        y: sorted[0].get('y')
      };
    },
    defaultComponentNames: function defaultComponentNames(componentClass) {
      switch (componentClass) {
        case 'textComponent':
          return this.i18n.t('components.componentToolbarMenuitem.textComponent');

        case 'dateComponent':
          return this.i18n.t('components.componentToolbarMenuitem.dateComponent');

        case 'checkComponent':
          return this.i18n.t('components.componentToolbarMenuitem.checkComponent');

        case 'checkGroupComponent':
          return this.i18n.t('components.componentToolbarMenuitem.checkGroupComponent');

        case 'selectComponent':
          return this.i18n.t('components.componentToolbarMenuitem.selectComponent');
        // non merge fields

        case 'signatureComponent':
          return this.i18n.t('components.componentToolbarMenuitem.signatureComponent');

        case 'initialsComponent':
          return this.i18n.t('components.componentToolbarMenuitem.initialsComponent');

        case 'signatureAnnotationComponent':
          return this.i18n.t('components.componentToolbarMenuitem.signatureAnnotationName');

        case 'textAnnotationComponent':
          return this.i18n.t('components.componentToolbarMenuitem.textAnnotationName');

        case 'checkmarkAnnotationComponent':
          return this.i18n.t('components.componentToolbarMenuitem.checkmarkAnnotationName');

        case 'attachmentComponent':
          return this.i18n.t('components.componentToolbarMenuitem.attachmentComponentName');

        case 'paymentComponent':
          return this.i18n.t('components.componentToolbarMenuitem.paymentComponent');
      }
    }
  });

  _exports.default = _default;
});