define("ember-rs/locales/nl/templates/unauthorized/unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unsubscribed = void 0;
  var unsubscribed = {
    "unsubscribed": "Abonnement opgezegd",
    "unsubscribedTip": "U ontvangt geen herinneringen meer voor dit document. U ontvangt nog steeds e-mails voor andere documenten."
  };
  _exports.unsubscribed = unsubscribed;
});