define("ember-rs/models/template-signer-link", ["exports", "@ember-data/model", "ember-rs/config/environment"], function (_exports, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ////////////////////////  ATTRIBUTES  ////////////////////////
    name: (0, _model.attr)('string'),
    accessToken: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('moment-date'),
    expiresAt: (0, _model.attr)('moment-date'),
    identityMethod: (0, _model.attr)('string'),
    expired: (0, _model.attr)('boolean'),
    embedCode: (0, _model.attr)('boolean'),
    embedType: (0, _model.attr)('string'),
    disabled: (0, _model.attr)('boolean'),
    viewedCount: (0, _model.attr)('number'),
    signedCount: (0, _model.attr)('number'),
    height: (0, _model.attr)('number'),
    width: (0, _model.attr)('number'),
    maxSigners: (0, _model.attr)('number'),
    ////////////////////////  RELATIONSHIPS  ////////////////////////
    template: (0, _model.belongsTo)('reusable-template', {
      async: false
    }),
    ////////////////////////  PROPERTIES  ////////////////////////
    baseUrl: document.location.host,
    thumbnailWidth: '200',
    url: Ember.computed('template.id', 'accessToken', function () {
      return "https://".concat(this.baseUrl, "/templates/").concat(this.get('template.id'), "/template-signer-link/").concat(this.accessToken);
    }),
    linkOrCode: Ember.computed('embedCode', 'url', function () {
      return this.embedCode ? this.code() : this.url;
    }),
    ////////////////////////  METHODS  ////////////////////////
    code: function code() {
      var button_image = 'Sign Document';
      var class_name = 'embed_green_button';

      if (this.embedType === 'image') {
        button_image = "<img src=\"".concat(this.get('template.upload.baseFile.pageImages.firstObject.original'), "\" style=\"width:").concat(this.thumbnailWidth, "px;\" />");
        class_name = 'document_image';
      } else if (this.embedType === 'inline') {
        return this.inline_code();
      }

      return "<a href=\"".concat(this.url, "\" class=\"embed_button ").concat(class_name, "\" id=\"embed_").concat(this.id, "\" data-guid=\"").concat(this.id, "\">").concat(button_image, "</a><script charset=\"ISO-8859-1\" src=\"").concat(_environment.default.rsFullname, "/embed.js\"></script>");
    },
    inline_code: function inline_code() {
      return "<div style=\"display:block;margin:0;padding:0;border:0;outline:0;font-size:10px!important;color:#AAA!important;vertical-align:baseline;background:transparent;width:".concat(this.width, "px;\"><iframe frameborder=\"0\" height=\"").concat(this.height, "\" scrolling=\"no\" src=\"").concat(this.url, "\" width=\"").concat(this.width, "\"></iframe></div>");
    }
  });

  _exports.default = _default;
});