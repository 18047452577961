define("ember-rs/locales/en/templates/onboarding/prepare-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding3 = void 0;
  var prepareOnboarding3 = {
    "seeSigners": "<span>See the signers</span> for this document. Each signer has their own color, which correspond to the fields they need to complete"
  };
  _exports.prepareOnboarding3 = prepareOnboarding3;
});