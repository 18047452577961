define("ember-rs/locales/nl/templates/signer/sign", ["exports", "ember-rs/locales/nl/templates/signer/sign/identify", "ember-rs/locales/nl/templates/signer/sign/locked-modal", "ember-rs/locales/nl/templates/signer/sign/verify"], function (_exports, _identify, _lockedModal, _verify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    identify: _identify.identify,
    lockedModal: _lockedModal.lockedModal,
    verify: _verify.verify,
    "pinSent": "Er is een 4-cijferige PIN voor verificatie naar u verzonden.",
    "pin": "PIN:",
    "submit": "verzenden",
    "back": "Terug",
    "deliverPin": "een 4-cijferige PIN voor verificatie op te geven die u op de volgende pagina moet invoeren.",
    "afterPhoneComplete": "Nadat u een geldig telefoonnummer hebt ingevoerd, sturen we u een 4-cijferige PIN voor verificatie via  {{{identityActionName}}}. U kunt de PIN op de volgende pagina invoeren.",
    "phone": "telefoongesprek",
    "sms": "sms",
    "countryCode": "Landcode:",
    "phoneNumber": "Telefoonnummer:",
    "me": "mij",
    "iAgreeComplete": "Ik ga akkoord om <strong>{{{formattedMoney}}} (USD)</strong> in rekening gebracht te worden bij uitvoering van dit document.",
    "usd": " (USD)",
    "uponExecution": " bij ondertekening van dit document.",
    "clickSubmit": "Door op 'Verzenden' te klikken, gaat u akkoord met onze",
    "privacyPolicy": "Privacybeleid",
    "consent": "E-Sign-toestemming",
    "contents": "en de inhoud van dit document.",
    "saveSuccess": "Uw voortgang is opgeslagen. U kunt dit document ophalen door op de koppeling in uw e-mail te klikken.",
    "submitButton": "Indienen"
  };
  _exports.sign = sign;
});