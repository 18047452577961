define("ember-rs/locales/ja/templates/reusable-templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTrans = void 0;
  var newTrans = {
    "upload": "アップロード",
    "dragDrop": "ここにファイルをドラッグ アンド ドロップ。または:",
    "uploading": "アップロード中",
    "uploadFile": "ファイルのアップロード",
    "chooseCloud": "クラウドから選択",
    "prepare": "ドキュメントを準備"
  };
  _exports.newTrans = newTrans;
});