define("ember-rs/locales/en/templates/components/add-role-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoleField = void 0;
  var addRoleField = {
    "name": "Name...",
    "emailOptional": "Email (optional)...",
    "email": "Email...",
    "nameTip": "Full name required.",
    "emailTip": "Valid email address required.",
    "roleTip": "Role name required."
  };
  _exports.addRoleField = addRoleField;
});