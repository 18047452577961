define("ember-rs/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var classTitles = {
    signatureComponent: 'components.componentToolbarMenuitem.signatureComponent',
    textComponent: 'components.componentToolbarMenuitem.textComponent',
    dateComponent: 'components.componentToolbarMenuitem.dateComponent',
    checkComponent: 'components.componentToolbarMenuitem.checkComponent',
    checkGroupComponent: 'components.componentToolbarMenuitem.checkGroupComponent',
    initialsComponent: 'components.componentToolbarMenuitem.initialsComponent',
    selectComponent: 'components.componentToolbarMenuitem.selectComponent',
    signatureAnnotationComponent: 'components.componentToolbarMenuitem.signatureAnnotationComponent',
    textAnnotationComponent: 'components.componentToolbarMenuitem.textAnnotationComponent',
    checkmarkAnnotationComponent: 'components.componentToolbarMenuitem.checkmarkAnnotationComponent',
    attachmentComponent: 'components.componentToolbarMenuitem.attachmentComponent',
    paymentComponent: 'components.componentToolbarMenuitem.paymentComponent'
  };
  var toolTooltips = {
    signatureComponent: 'components.componentToolbarMenuitem.signatureComponenttip',
    textComponent: 'components.componentToolbarMenuitem.textComponenttip',
    dateComponent: 'components.componentToolbarMenuitem.dateComponenttip',
    checkComponent: 'components.componentToolbarMenuitem.checkComponenttip',
    checkGroupComponent: 'components.componentToolbarMenuitem.checkGroupComponenttip',
    initialsComponent: 'components.componentToolbarMenuitem.initialsComponenttip',
    selectComponent: 'components.componentToolbarMenuitem.selectComponenttip',
    signatureAnnotationComponent: 'components.componentToolbarMenuitem.signatureAnnotationComponenttip',
    textAnnotationComponent: 'components.componentToolbarMenuitem.textAnnotationComponenttip',
    checkmarkAnnotationComponent: 'components.componentToolbarMenuitem.checkmarkAnnotationComponenttip',
    attachmentComponent: 'components.componentToolbarMenuitem.attachmentComponenttip',
    paymentComponent: 'components.componentToolbarMenuitem.paymentComponenttip'
  };

  var _default = Ember.Component.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    overlayerData: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ////////////////////////  FORMATTING  ////////////////////////
    tagName: 'li',
    classNameBindings: [':draggable-component', 'isSelected', 'hideThisTool', ':component-toolbar-menuitem', ':theme__toolbar__item', 'isSelected:theme__toolbar__item--is-selected'],
    attributeBindings: ['data-component-class'],
    ////////////////////////  PROPERTIES  ////////////////////////
    'data-component-class': Ember.computed.alias('componentClass'),
    hideThisTool: Ember.computed('showMoreTools', function () {
      return !this.showMoreTools && this.index > 3;
    }),
    isSelected: Ember.computed('componentClass', 'overlayerData.selectedComponentClass', 'overlayerData.selectedComponentIndex', function () {
      if (!this.isSourceMapped && !this.get('overlayerData.isSourceMapped')) {
        return this.componentClass === this.get('overlayerData.selectedComponentClass');
      }

      return this.componentIndex === this.get('overlayerData.selectedComponentIndex');
    }),
    classTitle: Ember.computed('i18n.locale', 'title', 'componentClass', function () {
      return this.title || this.i18n.t(classTitles[this.componentClass]) || this.i18n.t('components.componentToolbarMenuitem.unknownField');
    }),
    toolTooltip: Ember.computed('componentClass', function () {
      return this.i18n.t(toolTooltips[this.componentClass]) || this.i18n.t('components.componentToolbarMenuitem.unknownField');
    }),
    toolIcon: Ember.computed('componentClass', function () {
      return "icons/".concat(this.componentClass);
    }),
    actions: {
      selectComponentClass: function selectComponentClass() {
        this.set('overlayerData.selectedComponentClass', this.componentClass);

        if (this.isSourceMapped) {
          this.setProperties({
            'overlayerData.selectedComponentIndex': this.componentIndex,
            'overlayerData.selectedComponentData': this.source,
            'overlayerData.isSourceMapped': this.isSourceMapped,
            'overlayerData.isRequired': this.isRequired
          });
        } else {
          this.setProperties({
            'overlayerData.selectedComponentIndex': -1,
            'overlayerData.selectedComponentData': {},
            'overlayerData.isSourceMapped': false
          });
        }
      },
      addComponent: function addComponent() {
        var options = {};

        if (this.componentClass === 'checkGroupComponent') {
          options.createNewGroup = true;
        }

        this.sendAction('addComponent', this.componentClass, options);
      }
    }
  });

  _exports.default = _default;
});