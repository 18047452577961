define("ember-rs/locales/pt-br/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserForm = void 0;
  var newUserForm = {
    "email": "Email",
    "name": "Nome",
    "password": "Senha",
    "hidePassword": "Ocultar senha",
    "create": "Criar conta",
    "haveAccount": "Tem uma conta?"
  };
  _exports.newUserForm = newUserForm;
});