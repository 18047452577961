define("ember-rs/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JCXZy63Z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.confirmIdentityExpired.linkExpired\"],null]],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3],[35,2]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"form\"],[4,[38,1],[[32,0],\"resendIdentityConfirmation\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.confirmIdentityExpired.resendIdentityConfirmation\"],null]],[13],[2,\"\\n    \"],[10,\"button\"],[14,4,\"submit\"],[12],[1,[30,[36,0],[\"unauthorized.confirmIdentityExpired.resendIdentityVerification\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.confirmIdentityExpired.contactSender\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"identity_token\",\"signer_id\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/confirm-identity-expired.hbs"
    }
  });

  _exports.default = _default;
});