define("ember-rs/locales/es/templates/reusable-template/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "upload": "Agregar carga",
    "what": "¿Qué hay que firmar?",
    "uploading": "Cargando",
    "uploadFile": "Cargar un archivo",
    "chooseCloud": "elegirlos desde la nube",
    "prepare": "preparar documento"
  };
  _exports.edit = edit;
});