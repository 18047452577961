define("ember-rs/components/sign-document-page", ["exports", "ember-rs/mixins/document-page", "ember-rs/mixins/signing-component-interactions"], function (_exports, _documentPage, _signingComponentInteractions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_documentPage.default, _signingComponentInteractions.default, {
    doc: Ember.computed.alias('signer.document'),
    documentViewerData: Ember.inject.service(),
    auditingAvailable: true,
    // override in SigningComponentInteractions
    valueObjectName: 'componentValue',
    classNames: ['page'],
    attributeBindings: ['data-page-number'],
    'data-page-number': Ember.computed.alias('pageImage.page_number'),
    pageCssStyle: Ember.computed('documentViewerData.pageHeight', 'documentViewerData.pageWidth', function () {
      return "width: 100%; height: ".concat(this.get('documentViewerData.pageHeight'), "px; margin-bottom: ").concat(this.get('documentViewerData.pageMargin'), "px;").htmlSafe();
    }),
    pageLoadedEvent: Ember.on('didInsertElement', function () {
      var self = this;
      this.$().find('img.page-image').on('load', function () {
        self.sendAction('addAudit', "Page ".concat(self.get('pageNumber'), " loaded."));
      });
    }),
    pageViewed: Ember.on('init', Ember.observer('documentViewerData.currentPage', function () {
      if (this.get('documentViewerData.currentPage') === this.pageNumber) {
        this.sendAction('addAudit', "Viewing page ".concat(this.pageNumber, "."));
      }
    })),
    actions: {
      componentClicked: function componentClicked(component) {
        // Bubble this action up...
        this.sendAction('componentClicked', component);
      },
      signatureCreated: function signatureCreated() {
        this.sendAction.apply(this, ['signatureCreated'].concat(Array.prototype.slice.call(arguments)));
      },
      componentUpdated: function componentUpdated() {
        this.sendAction('componentUpdated');
      }
    }
  });

  _exports.default = _default;
});