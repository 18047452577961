define("ember-rs/locales/es/templates/signer/sign", ["exports", "ember-rs/locales/es/templates/signer/sign/identify", "ember-rs/locales/es/templates/signer/sign/locked-modal", "ember-rs/locales/es/templates/signer/sign/verify"], function (_exports, _identify, _lockedModal, _verify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    identify: _identify.identify,
    lockedModal: _lockedModal.lockedModal,
    verify: _verify.verify,
    "pinSent": "Se le ha enviado un PIN de verificación de 4 dígitos.",
    "pin": "PIN:",
    "submit": "enviar",
    "back": "Atrás",
    "deliverPin": "para entregarle un PIN de verificación de 4 dígitos que debe introducir en la página siguiente.",
    "afterPhoneComplete": "Después de introducir un número de teléfono válido, le enviaremos un PIN de verificación de 4 dígitos a través de  {{{identityActionName}}} para que acceda a la siguiente página.",
    "phone": "llamada telefónica",
    "sms": "SMS",
    "countryCode": "Código de país:",
    "phoneNumber": "Número de teléfono:",
    "me": "yo",
    "iAgreeComplete": "Acepto el cobro de <strong>{{{formattedMoney}}} (USD)</strong> después de la ejecución de este documento.",
    "usd": " (USD)",
    "uponExecution": " después de la ejecución de este documento.",
    "clickSubmit": "Al hacer clic en 'Enviar' usted acepta nuestro",
    "privacyPolicy": "Directiva de privacidad",
    "consent": "Consentimiento E-Sign",
    "contents": "y el contenido de este documento.",
    "saveSuccess": "Progreso guardado correctamente. Para obtener este documento, haga clic en el enlace de su correo.",
    "submitButton": "Enviar"
  };
  _exports.sign = sign;
});