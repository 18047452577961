define("ember-rs/locales/ja/templates/unauthorized/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expired = void 0;
  var expired = {
    "linkExpired": "ドキュメントのリンクの期限が切れました",
    "toDownload": "このドキュメントをダウンロードするには、新しいダウンロード リンクを要求します。",
    "sendLink": "新しいダウンロード リンクを送信する"
  };
  _exports.expired = expired;
});