define("ember-rs/locales/pt-br/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alreadySigned = void 0;
  var alreadySigned = {
    "thankYou": "Obrigado por assinar",
    "youHave": "Assinou este documento com sucesso. Você pode exibir o documento a qualquer momento solicitando um link seguro.",
    "sendNewLink": "Enviar novo link de download",
    "successfully": "Assinou este documento com sucesso."
  };
  _exports.alreadySigned = alreadySigned;
});