define("ember-rs/components/component-editor", ["exports", "ember-rs/mixins/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TRANSACTION_MANAGER_HEADER_HEIGHT = document.getElementsByClassName('transaction-manager-header')[0].offsetHeight;
  var DEFAULT_WORKFLOWS = ['reusableTemplate', 'signatureRequest', 'inPerson', 'packager'];

  var _default = Ember.Component.extend(_component.default, {
    flashMessages: Ember.inject.service(),
    overlayerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    session: Ember.inject.service(),
    wcsWorkflow: Ember.inject.service(),
    classNames: ['component-editor'],
    classNameBindings: ['componentsAreSelected'],
    attributeBindings: ['style'],
    selectedComponents: Ember.computed.alias('overlayerData.selectedComponents'),
    componentsAreSelected: Ember.computed.alias('overlayerData.componentsAreSelected'),
    isMultiselect: Ember.computed.alias('overlayerData.multiComponentSelected'),
    components: Ember.computed.alias('documentTemplate.components'),
    hasMultipleSigners: Ember.computed.alias('documentTemplate.hasMultipleSigners'),
    // TODO: review http://stackoverflow.com/questions/11623645/ember-js-observer-for-all-values/11679850#11679850
    // NOTE: If you add to this object, you need to add the attribute to the observer in
    //       'autoSaveSingleComponentEdits' if you want it autosaved. You must also add it to the 'attributes'
    //       object to say which Components can use it.
    // NOTE: If you ever add 'virtual' attributes here that dont line up with the Component model, you need
    //       to handle them explicitely in the save
    editedAttributes: Ember.Object.create({
      name: '',
      assignedTo: '',
      isRequired: true,
      isMergeField: false,
      isDateSigned: false,
      fontSize: 14,
      multiline: false,
      transparent: true,
      value: '',
      textAlign: 'left',
      dateFormat: localStorage.getItem('defaultDateFormat') ? localStorage.getItem('defaultDateFormat') : 'MM/DD/YYYY',
      groupRequirement: 'only_one',
      // 'one_or_more'
      selectOptions: Ember.A([]),
      helpText: '',
      chargeAfterExecuted: false,
      paymentAmount: 1.00
    }),
    newSelectOption: '',
    // text value of new select option input field
    hasInitializedDefaults: false,
    moreOptions: false,
    showEditValue: false,
    showSavedMessage: false,
    showEditComponentName: false,
    attributes: Ember.computed(function () {
      var attributes = [{
        name: 'name',
        availableFor: ['checkComponent', 'checkGroupComponent', 'dateComponent', 'initialsComponent', 'selectComponent', 'signatureComponent', 'textComponent', 'attachmentComponent', 'paymentComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'assignedTo',
        availableFor: ['checkComponent', 'checkGroupComponent', 'dateComponent', 'initialsComponent', 'selectComponent', 'signatureComponent', 'textComponent', 'attachmentComponent', 'paymentComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'isRequired',
        availableFor: ['checkComponent', 'checkGroupComponent', 'dateComponent', 'initialsComponent', 'selectComponent', 'signatureComponent', 'textComponent', 'attachmentComponent', 'paymentComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'isDateSigned',
        availableFor: ['dateComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'fontSize',
        availableFor: ['textAnnotationComponent'],
        workflows: DEFAULT_WORKFLOWS.concat('selfSign')
      }, {
        name: 'multiline',
        availableFor: ['textComponent', 'textAnnotationComponent'],
        workflows: DEFAULT_WORKFLOWS.concat('selfSign')
      }, {
        name: 'transparent',
        availableFor: ['checkComponent', 'checkGroupComponent', 'dateComponent', 'initialsComponent', 'selectComponent', 'signatureComponent', 'textComponent', 'signatureAnnotationComponent', 'checkmarkAnnotationComponent', 'textAnnotationComponent', 'attachmentComponent', 'paymentComponent'],
        workflows: DEFAULT_WORKFLOWS.concat('selfSign')
      }, {
        name: 'groupRequirement',
        availableFor: ['checkGroupComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'value',
        availableFor: ['textComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'textAlign',
        availableFor: ['dateComponent', 'initialsComponent', 'textComponent', 'textAnnotationComponent'],
        workflows: DEFAULT_WORKFLOWS.concat('selfSign')
      }, {
        name: 'dateFormat',
        availableFor: ['dateComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'selectOptions',
        availableFor: ['selectComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'chargeAfterExecuted',
        availableFor: ['paymentComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'paymentAmount',
        availableFor: ['paymentComponent'],
        workflows: DEFAULT_WORKFLOWS
      }, {
        name: 'helpText',
        availableFor: ['attachmentComponent', 'paymentComponent'],
        workflows: DEFAULT_WORKFLOWS
      }]; //allows components to be marked as merged fields for non wcs workflow

      if (!this.wcsWorkflow.getValue()) {
        attributes.push({
          name: 'isMergeField',
          availableFor: ['checkComponent', 'checkGroupComponent', 'dateComponent', 'selectComponent', 'textComponent'],
          workflows: ['reusableTemplate']
        });
      }

      return attributes;
    }),
    selectedComponentsPage: Ember.computed('selectedComponents.[]', function () {
      return this.get('selectedComponents.firstObject.page');
    }),
    creatingReusableTemplate: Ember.computed('documentTemplate', function () {
      return this.get('documentTemplate.constructor.modelName') === 'reusable-template';
    }),
    assignedToOptions: Ember.computed('documentTemplate.roles.[]', function () {
      return this.get('documentTemplate.roles').mapBy('name');
    }),
    textAlignOptions: ['left', 'center', 'right'],
    dateFormatOptions: ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD', 'MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY'],
    groupRequirementOptions: [{
      label: 'Only One',
      value: 'only_one'
    }, {
      label: 'One or More',
      value: 'one_or_more'
    }],
    booleanOptions: [{
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
    }],
    hasSelectOptions: Ember.computed('editedAttributes.selectOptions.[]', function () {
      return this.get('editedAttributes.selectOptions').length > 0;
    }),
    showMergeName: Ember.computed('editedAttributes.isMergeField', function () {
      return this.get('editedAttributes.isMergeField');
    }),
    canEditName: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('name');
    }),
    canEditAssignedTo: Ember.computed('editableAttributes', 'editedAttributes.isMergeField', function () {
      return this.editableAttributes.includes('assignedTo') && !this.get('editedAttributes.isMergeField');
    }),
    canEditIsMergeField: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('isMergeField');
    }),
    isSourceMapped: Ember.computed('selectedComponents.[]', function () {
      return this.get('selectedComponents.firstObject.isSourceMapped');
    }),
    canEditIsRequired: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('isRequired');
    }),
    canEditFontSize: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('fontSize');
    }),
    canEditMultiline: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('multiline');
    }),
    canEditTransparent: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('transparent');
    }),
    canEditValue: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('value');
    }),
    canEditHelpText: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('helpText');
    }),
    canEditTextAlign: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('textAlign');
    }),
    canEditDateFormat: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('dateFormat');
    }),
    canEditSelectOptions: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('selectOptions');
    }),
    canEditGroupRequirement: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('groupRequirement');
    }),
    canEditChargeAfterExecuted: Ember.computed('editableAttributes', function () {
      return this.editableAttributes.includes('chargeAfterExecuted');
    }),
    canEditPaymentAmount: Ember.computed('editableAttributes', 'editedAttributes.chargeAfterExecuted', function () {
      return this.get("editedAttributes.chargeAfterExecuted") && this.editableAttributes.includes('paymentAmount');
    }),
    selectedComponent: Ember.computed('overlayerData.selectedComponents.[]', function () {
      return this.get('overlayerData.selectedComponents.firstObject');
    }),
    // TODO: refactor this so it's triggered by each component's action
    initEditedAttributes: Ember.observer('overlayerData.selectedComponents.[]', function () {
      var self = this;
      var selectedComponent;
      Ember.run.next(this, function () {
        if (this._state === 'destroying') {
          return;
        }

        if (this.get('overlayerData.noComponentSelected')) {
          return;
        }

        this.set('hasInitializedDefaults', false);
        this.set('showEditValue', false);
        this.set('showEditComponentName', false);

        if (this.get('overlayerData.singleComponentSelected')) {
          selectedComponent = this.selectedComponent;

          if (this.assignedToOptions.includes(selectedComponent.get('assignedTo'))) {
            // Set assignedTo default to the component model assignedTo
            this.set('editedAttributes.assignedTo', selectedComponent.get('assignedTo'));
          } else {
            // Initialize the assignedTo role to the first role in the documentTemplate
            this.set('editedAttributes.assignedTo', this.assignedToOptions[0]);
          } // Assigned defaults to the attribute from the component model


          this.editableAttributes.forEach(function (a) {
            if (a === 'selectOptions') {
              // check to see if component has value, there is no default for selectOptions from component model because couldn't set the attr as type 'Array'
              if (Ember.isNone(selectedComponent.get(a))) {
                // no value, setting as empty array
                self.get('editedAttributes').set(a, Ember.A([]));
              } else {
                // has value, getting from component
                self.get('editedAttributes').set(a, selectedComponent.get(a));
              }
            } else {
              self.get('editedAttributes').set(a, selectedComponent.get(a));

              if (a === 'value' && Ember.isPresent(selectedComponent.get(a))) {
                self.set('showEditValue', true);
              }

              if (a === 'name' && Ember.isPresent(selectedComponent.get(a))) {
                if (!self.isDefaultNameValue(selectedComponent.get(a))) {
                  self.set('showEditComponentName', true);
                }
              }
            }
          }); // Resets unrelated attributes

          this.otherAttributes.forEach(function (a) {
            self.get('editedAttributes').set(a, null);
          });
        } else {
          selectedComponent = self.get('selectedComponent'); // Persists GroupCheck values if it has a value checkGroupComponent in selection

          if (selectedComponent.get('type') === 'checkGroupComponent') {
            if (Ember.isPresent(selectedComponent.get('groupId'))) {
              self.set('editedAttributes.groupId', selectedComponent.get('groupId'));
              self.set('editedAttributes.name', selectedComponent.get('name'));
              self.set('editedAttributes.groupRequirement', selectedComponent.get('groupRequirement'));

              if (this.assignedToOptions.includes(selectedComponent.get('assignedTo'))) {
                // Set assignedTo default to the component model assignedTo
                self.set('editedAttributes.assignedTo', selectedComponent.get('assignedTo'));
              } else {
                // Initialize the assignedTo role to the first role in the documentTemplate
                self.set('editedAttributes.assignedTo', this.assignedToOptions[0]);
              }
            }
          } else {
            // Initialize all editedAttributes to null
            this.editableAttributes.forEach(function (a) {
              self.get('editedAttributes').set(a, null);
            });
          }
        } // in multiselect initialize to null or empty (only apply attributes arent blank on save)


        Ember.run.next(function () {
          self.set('hasInitializedDefaults', true);
        });
      });
    }),
    // checks if the name value is the default given to component
    isDefaultNameValue: function isDefaultNameValue(nameValue) {
      var fieldType = this.get('selectedComponent.type').replace('Component', '').capitalize();
      var pattern = "^".concat(fieldType, " Field [1-9][0-9]*$");
      var regex = new RegExp(pattern);
      return regex.test(nameValue);
    },
    // We only auto save singleComponentSelected, we use the action 'save' for multi select
    autoSaveSingleComponentEdits: Ember.observer('editedAttributes.name', 'editedAttributes.assignedTo', 'editedAttributes.isRequired', 'editedAttributes.isDateSigned', 'editedAttributes.isMergeField', 'editedAttributes.fontSize', 'editedAttributes.multiline', 'editedAttributes.transparent', 'editedAttributes.value', 'editedAttributes.textAlign', 'editedAttributes.dateFormat', 'editedAttributes.groupRequirement', 'editedAttributes.selectOptions.[]', 'editedAttributes.helpText', 'editedAttributes.chargeAfterExecuted', 'editedAttributes.paymentAmount', function () {
      Ember.run.next(this, function () {
        if (!this.hasInitializedDefaults) {
          return;
        }

        if (this.get('overlayerData.singleComponentSelected')) {
          this.send('save');
        }

        if (this.get('editedAttributes').dateFormat) {
          localStorage.setItem('defaultDateFormat', this.get('editedAttributes').dateFormat);
        }
      });
    }),
    editableAttributes: Ember.computed('overlayerData.selectedComponents.[]', 'documentTemplate.workflowType', function () {
      var selectedComponentTypes = this.get('overlayerData.selectedComponents').mapBy('type').uniq();
      var editableAttributeNames = Ember.A();

      if (Ember.isBlank(selectedComponentTypes)) {
        return editableAttributeNames;
      }

      this.attributes.forEach(function (a) {
        if (_lodash.default.intersection(selectedComponentTypes, a.availableFor).length === selectedComponentTypes.length) {
          if (a.workflows.includes(this.get('documentTemplate.workflowType'))) {
            editableAttributeNames.pushObject(a.name);
          }
        }
      }, this);
      return editableAttributeNames;
    }),
    attributeNames: Ember.computed.mapBy('attributes', 'name'),
    otherAttributes: Ember.computed.setDiff('attributeNames', 'editableAttributes'),
    yOffset: 16,
    editorWidth: 220,
    editorHeight: 0,
    // TODO: refactor this so it's triggered by action(s)
    updateEditorHeight: Ember.observer('overlayerData.selectedComponents.[]', 'moreOptions', 'overlayerData.selectedComponents.@each.helpText', function () {
      Ember.run.later(this, function () {
        if (this._state === 'destroying') {
          return;
        }

        if (this.editor) {
          this.set('editorHeight', this.editor.outerHeight());
        }
      }, 100);
    }),
    style: Ember.computed('editorLeft', 'editorTop', 'showEditor', function () {
      var editorLeft = this.editorLeft;
      var editorTop = this.editorTop;
      var showEditor = this.showEditor;
      var display = showEditor ? 'block' : 'none';
      return "left: ".concat(editorLeft, "px; top: ").concat(editorTop, "px; display: ").concat(display, ";").htmlSafe();
    }),
    editorLeft: Ember.computed('overlayerData.selectedComponentsBounds.scaledX', 'overlayerData.selectedComponentsBounds.scaledWidth', 'editorWidth', 'horizontalPosition', function () {
      var scaledX = this.get('overlayerData.selectedComponentsBounds.scaledX');
      var scaledWidth = this.get('overlayerData.selectedComponentsBounds.scaledWidth');
      var editorWidth = this.editorWidth;
      var horizontalPosition = this.horizontalPosition;

      if (horizontalPosition === 'right') {
        return scaledX + scaledWidth - editorWidth;
      } else if (horizontalPosition === 'center') {
        return scaledX - editorWidth / 2 + 11;
      } else {
        return scaledX;
      }
    }),
    editorTop: Ember.computed('overlayerData.selectedComponentsBounds.scaledY', 'pageOffset', 'overlayerData.selectedComponentsBounds.scaledHeight', 'yOffset', 'editorHeight', 'verticalPosition', function () {
      var scaledY = this.get('overlayerData.selectedComponentsBounds.scaledY');
      var pageOffset = this.pageOffset;
      var scaledHeight = this.get('overlayerData.selectedComponentsBounds.scaledHeight');
      var yOffset = this.yOffset;
      var editorHeight = this.editorHeight;
      var verticalPosition = this.verticalPosition;
      var top = scaledY + pageOffset;

      if (verticalPosition === 'top') {
        top -= editorHeight + yOffset;
      } else {
        top += scaledHeight + yOffset;
      }

      return top;
    }),
    showEditor: Ember.computed('overlayerData.componentsAreSelected', 'overlayerData.isDragging', 'overlayerData.isResizing', 'editorHeight', function () {
      var selected = this.get('overlayerData.componentsAreSelected');
      var notPositioning = !this.get('overlayerData.isDragging') && !this.get('overlayerData.isResizing');
      var hasHeight = this.editorHeight > 40;
      return selected && notPositioning && hasHeight;
    }),
    arrowStyle: Ember.computed('editorHeight', 'overlayerData.selectedComponentsBounds.scaledWidth', 'editorWidth', 'horizontalPosition', 'verticalPosition', function () {
      var editorHeight = this.editorHeight;
      var scaledWidth = this.get('overlayerData.selectedComponentsBounds.scaledWidth');
      var editorWidth = this.editorWidth;
      var horizontalPosition = this.horizontalPosition;
      var verticalPosition = this.verticalPosition;
      var left = '20';
      var borderTopBottom = 'top';
      var top = editorHeight + 11;
      var componentIsSmall = scaledWidth < 30;

      if (horizontalPosition === 'right' && componentIsSmall) {
        left = editorWidth - 22;
      } else if (horizontalPosition === 'right') {
        left = editorWidth - 40;
      } else if (horizontalPosition === 'center') {
        left = editorWidth / 2 - 10;
      } else if (componentIsSmall) {
        left = 2;
      }

      if (verticalPosition === 'bottom') {
        top = '-9';
        borderTopBottom = 'bottom';
      }

      return "top: ".concat(top, "px; left: ").concat(left, "px; border: none; border-left: 10px solid transparent; border-right: 10px solid transparent; border-").concat(borderTopBottom, ": 10px solid #e6e6e6").htmlSafe();
    }),
    pageOffset: Ember.computed('documentViewerData.scaledPageMargin', 'documentViewerData.scaledPageHeight', 'selectedComponentsPage', function () {
      var scaledPageMargin = this.get('documentViewerData.scaledPageHeight');
      var scaledPageHeight = this.get('documentViewerData.scaledPageMargin');
      var selectedPage = this.selectedComponentsPage;
      var totalPageHeight = scaledPageMargin + scaledPageHeight;
      return (selectedPage - 1) * totalPageHeight;
    }),
    // for determining if the component is too close to the bottom of a page, if so, the editor will go above the component
    verticalPosition: Ember.computed('overlayerData.selectedComponentsBounds.scaledY', 'editorHeight', function () {
      var scaledY = this.get('overlayerData.selectedComponentsBounds.scaledY');
      var editorHeight = this.editorHeight;
      var tooCloseToTop = scaledY - editorHeight < 0;

      if (tooCloseToTop) {
        return 'bottom';
      } else {
        return 'top';
      }
    }),
    horizontalPosition: Ember.computed('overlayerData.selectedComponentsBounds.scaledX', 'overlayerData.selectedComponentsBounds.scaledWidth', 'editorWidth', 'documentViewerData.scaledPageWidth', function () {
      var scaledX = this.get('overlayerData.selectedComponentsBounds.scaledX');
      var scaledWidth = this.get('overlayerData.selectedComponentsBounds.scaledWidth');
      var editorWidth = this.editorWidth;
      var scaledPageWidth = this.get('documentViewerData.scaledPageWidth');
      var position = 'left';
      var willOverhangRight = scaledX + editorWidth > scaledPageWidth;
      var willOverhangLeft = scaledX - editorWidth / 2 < 0;
      var componentIsSmall = scaledWidth < 30;

      if (componentIsSmall) {
        willOverhangRight = scaledX + editorWidth / 2 > scaledPageWidth;

        if (willOverhangRight) {
          position = 'right';
        } else if (willOverhangLeft) {
          position = 'left';
        } else {
          position = 'center';
        }
      } else if (willOverhangRight) {
        position = 'right';
      }

      return position;
    }),
    handleMouseUp: function handleMouseUp() {
      this.set('overlayerData.componentEditorClicked', false);
    },
    handleMouseDown: function handleMouseDown() {
      this.set('overlayerData.componentEditorClicked', true);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      if (this.componentsAreSelected && this.$().offset().top < TRANSACTION_MANAGER_HEADER_HEIGHT) {
        document.getElementById(this.elementId).scrollIntoView();
      }
    },
    didInsertElement: function didInsertElement() {
      this.set('editor', this.$());
      this.$().on("mousedown.".concat(this.elementId), this.handleMouseDown.bind(this)).on("mouseup.".concat(this.elementId), this.handleMouseUp.bind(this));
    },
    actions: {
      addSelectOption: function addSelectOption() {
        var newSelectOption = this.newSelectOption;

        if (Ember.isPresent(newSelectOption)) {
          var selectOptions = this.get('editedAttributes.selectOptions');

          if (!_lodash.default.includes(selectOptions, newSelectOption)) {
            selectOptions.pushObject(newSelectOption);
            this.set('newSelectOption', '');
            this.updateEditorHeight();
          } else {
            this.flashMessages.warning(this.i18n.t('components.componentEditor.options_warning'));
          }
        }
      },
      removeSelectOption: function removeSelectOption(selectOption) {
        this.get('editedAttributes.selectOptions').removeObject(selectOption);
        this.updateEditorHeight();
      },
      addGroupComponent: function addGroupComponent() {
        var groupId = this.get('selectedComponents.lastObject.groupId');
        var componentsInGroup = this.components.filterBy('groupId', groupId);
        var selectedComponent = componentsInGroup.get('lastObject');
        var nextMemberPlacement = this.getNextGroupMemberPlacement(componentsInGroup);
        this.sendAction('addComponent', this.get('selectedComponents.lastObject.type'), {
          addToGroup: true,
          existingGroupId: selectedComponent.get('groupId'),
          name: selectedComponent.get('name'),
          groupRequirement: selectedComponent.get('groupRequirement'),
          assignedTo: selectedComponent.get('assignedTo'),
          isRequired: selectedComponent.get('isRequired'),
          x: nextMemberPlacement.x,
          width: nextMemberPlacement.width,
          height: nextMemberPlacement.height,
          y: nextMemberPlacement.y,
          page: selectedComponent.get('page')
        });
      },
      showMoreOptions: function showMoreOptions() {
        this.set('moreOptions', true);
      },
      showLessOptions: function showLessOptions() {
        this.set('moreOptions', false);
      },
      flashSavedMessage: function flashSavedMessage() {
        var self = this;
        self.set('showSavedMessage', true);
        Ember.run.later(function () {
          if (self._state === 'destroying') {
            return;
          }

          self.set('showSavedMessage', false);
        }, 2000); // how long to flash 'saved'
      },
      delete: function _delete() {
        this.overlayerData.deleteSelectedComponents();
      },
      // Required should be checked and disabled
      // MergeField should be unchecked and disabled
      dateSignedChecked: function dateSignedChecked() {
        if (this.get('editedAttributes').get('isDateSigned')) {
          this.get('editedAttributes').set('isDateSigned', false);
        } else {
          this.get('editedAttributes').set('isRequired', true);
          this.get('editedAttributes').set('isDateSigned', true);
          this.get('editedAttributes').set('isMergeField', false);
        }
      },
      save: function save() {
        var _this = this;

        this.get('overlayerData.selectedComponents').forEach(function (component) {
          _this.editableAttributes.forEach(function (attr) {
            var editedValue = _this.editedAttributes[attr];

            if (!Ember.isNone(editedValue)) {
              // sync values across component checkbox group if it is part of a group
              // do not sync the names
              if (component.get('groupId') && attr !== 'name') {
                _this.components.forEach(function (member) {
                  if (member.get('groupId') === component.get('groupId')) {
                    member.set(attr, editedValue);
                  }
                });
              } else if (attr === 'paymentAmount') {
                var paymentAmount = parseFloat(editedValue);

                if (!isNaN(paymentAmount)) {
                  component.set(attr, paymentAmount);
                } else {
                  component.set(attr, "");
                } // setting attr values for non checkgroup component

              } else if (attr === 'isMergeField' && editedValue === false && component.get('isMergeField')) {
                component.set('assignedTo', _this.get('documentTemplate.roles').objectAt(0).get('name'));
                component.set(attr, editedValue);
              } else {
                if (attr === "assignedTo") {
                  _this.set('overlayerData.selectedAssignedTo', editedValue);
                }

                component.set(attr, editedValue);
              }
            }
          });
        });
        this.send('flashSavedMessage');
      }
    }
  });

  _exports.default = _default;
});