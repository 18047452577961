define("ember-rs/templates/document-transaction/review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eiyTbopf",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\"],[\"transaction_manager_header\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"header-content\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"document_transaction.review.review\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[35,1,[\"useMobileLayout\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[19,\"document-transaction/review-mobile\",[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[19,\"document-transaction/review-desktop\",[]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"t\",\"layoutSizeData\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/document-transaction/review.hbs"
    }
  });

  _exports.default = _default;
});