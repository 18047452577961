define("ember-rs/locales/it/templates/onboarding/new-user-onboarding-from-sharefile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFromSharefile = void 0;
  var newUserOnboardingFromSharefile = {
    "title": "Invia e firma documenti",
    "startText": "Inviare i documenti che devono essere firmati da altre persone o firmare eventuali documenti che richiedono la propria firma.",
    "clickLearn": "Fare clic su Avanti per scoprire come.",
    "addSigner": "Aggiungi firmatari",
    "addAnyone": "Aggiungere le persone che devono compilare o firmare questo documento.",
    "prepare": "Preparare il documento",
    "placeFields": "Inserire i campi in cui è necessario che i firmatari inseriscano le informazioni o firmino il documento. È possibile assegnare campi a ciascun firmatario.",
    "sendTrack": "Invia e tieni traccia dei progressi",
    "onceSent": "Una volta inviato il documento, è possibile tenere traccia dello stato di avanzamento nella relativa pagina dei dettagli. Per passare alla pagina dei dettagli è sufficiente fare clic sul documento dalla dashboard del documento."
  };
  _exports.newUserOnboardingFromSharefile = newUserOnboardingFromSharefile;
});