define("ember-rs/locales/pt-br/templates/onboarding/signer-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding1 = void 0;
  var signerOnboarding1 = {
    "redFlag": "O <span>sinalizador vermelho</span> aponta para áreas que precisam ser preenchidas.",
    "zoom": "Pince e aplique zoom no seu telefone para ampliar o documento."
  };
  _exports.signerOnboarding1 = signerOnboarding1;
});