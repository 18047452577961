define("ember-rs/components/assign-reusable-template-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    adjustedSequenceNumber: Ember.computed('role.sequence', function () {
      return this.get('role.sequence') + 1;
    }),
    watchSignerInfo: Ember.observer('role.signerName', 'role.signerEmail', function () {
      if (Ember.isPresent(this.get('role.signerName')) || Ember.isPresent(this.get('role.signerEmail'))) {
        this.role.set('signerOmitted', false);
      }
    }),
    actions: {
      clearThisRoleData: function clearThisRoleData() {
        if (this.get('role.isSender') || Ember.isBlank(this.get('role.signerName')) && Ember.isBlank(this.get('role.signerEmail'))) {
          // Omit role
          this.role.set('signerOmitted', true);
        } else {
          this.set('role.signerName', '');
          this.set('role.signerEmail', '');
        }
      },
      // This is useful for un-omitting a sender role (since the text is disabled)
      undoOmit: function undoOmit() {
        this.role.set('signerOmitted', false);
      }
    }
  });

  _exports.default = _default;
});