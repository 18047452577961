define("ember-rs/locales/zh-cn/templates/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = void 0;
  var verify = {
    "advisory": "本文档的发件人已启用 RightSignature 的基于知识的身份验证功能来验证您的身份。RightSignature 采用 BlockScore 的第三方服务来确认和验证提供的个人信息。对提供的个人信息的使用仅限于通过 BlockScore 的专有数据记录进行的身份确认和验证的性能。身份确认和验证的性能不会影响您的信用报告、信用评分或向贷方报告。作为此过程的一部分，IRS、Blockscore 和 RightSignature 都将无法查看或访问您的信用报告或您的税务信息。",
    "step2": "身份验证 - 第 2 步，共 2 步",
    "answer": "通过选中相应答复旁边的复选框，回答下面的身份验证问题。",
    "noMatch": "很抱歉，您提供的答案与我们对此身份的记录不匹配。",
    "youHave": "您还可以尝试访问此文档 ",
    "remaining": "次。",
    "complete": "完成验证",
    "return": "返回到表单",
    "advisory2": "身份验证咨询",
    "unableToAuthenticate": "无法成功进行身份验证。请联系 <b>{{{sender_email}}}</b> 以通过备用身份验证机制访问文档。"
  };
  _exports.verify = verify;
});