define("ember-rs/locales/zh-cn/templates/unauthorized/document-executed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExecuted = void 0;
  var documentExecuted = {
    "docComplete": "本文档已完成",
    "toDownload": "要下载此文档，请申请一个新下载链接。",
    "sendLink": "发送新下载链接"
  };
  _exports.documentExecuted = documentExecuted;
});