define("ember-rs/locales/en/templates/unauthorized/unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unsubscribed = void 0;
  var unsubscribed = {
    "unsubscribed": "Unsubscribed",
    "unsubscribedTip": "You have been removed from getting reminders for this document. You will still receive emails for other documents."
  };
  _exports.unsubscribed = unsubscribed;
});