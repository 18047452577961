define("ember-rs/locales/fr/controllers/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.branding = void 0;
  var branding = {
    "emailUpdateSuccess": "Les paramètres de messagerie utilisateur ont été mis à jour avec succès.",
    "emailUpdateFail": "Erreur lors de la mise à jour des paramètres de messagerie utilisateur.",
    "updateSuccess": "Les paramètres de branding du compte utilisateur ont été mis à jour avec succès.",
    "updateFail": "Erreur lors de la mise à jour des paramètres de branding du compte utilisateur."
  };
  _exports.branding = branding;
});