define("ember-rs/services/kba-session", ["exports", "ember-rs/config/environment", "jquery", "lodash"], function (_exports, _environment, _jquery, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KBA_KEY = "rightsignature-".concat(_environment.default.env.substring(0, 1), "-kba");

  var _default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    routePath: 'signer.sign',
    loggedIn: false,
    action: Ember.computed('routePath', function () {
      return _lodash.default.last(this.getWithDefault('routePath', '').split('.'));
    }),
    setWhenPresent: function setWhenPresent(key, value) {
      if (Ember.isPresent(key) && Ember.isPresent(value)) {
        Ember.set(this, key, value);
      }
    },
    setup: function setup(signerId, identityToken, loggedIn, routePath) {
      this.setWhenPresent('signerId', signerId);
      this.setWhenPresent('identityToken', identityToken);
      this.setWhenPresent('loggedIn', loggedIn);
      this.setWhenPresent('routePath', routePath);
    },
    session: function session() {
      return this.cookies.read(KBA_KEY);
    },
    key: function key() {
      return KBA_KEY;
    },
    validateSessionState: function validateSessionState(redirectCallback) {
      var _this = this;

      var session = this.cookies.read(KBA_KEY);

      if (this.kba === false || this.loggedIn) {
        return Ember.RSVP.resolve(session);
      }

      var request = Ember.isPresent(session) ? this.request('', 'GET', {}, true) : this.request('/generate', 'POST', {});
      return request.then(function (_ref) {
        var kba = _ref.kba;

        if (kba.kba === false || kba.is_sender) {
          Ember.set(_this, 'kba', false);
          return Ember.RSVP.resolve(session, 'Non-KBA Document');
        } else if (kba.action !== _this.action) {
          return redirectCallback("signer.sign.".concat(kba.action));
        }
      });
    },
    request: function request(path, method, data) {
      var includeId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var cookies = this.cookies;
      var id = cookies.read(KBA_KEY);
      var prefix = includeId ? "/".concat(id) : '';
      var url = "".concat(_environment.default.api.endpoint, "/kba").concat(prefix).concat(path);
      var contentType = 'application/json';
      var global = false;
      data.identity_token = this.identityToken;
      data.signer_id = this.signerId;
      data = method === 'GET' || method === 'get' ? data : JSON.stringify(data);
      return _jquery.default.ajax({
        data: data,
        method: method,
        url: url,
        global: global,
        contentType: contentType
      }).then(function (response) {
        cookies.write(KBA_KEY, response.kba.session, {
          domain: location.hostname,
          path: '/',
          expires: new Date(response.kba.expiration)
        });

        _jquery.default.ajaxSetup({
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Rightsignature-Kba-Session', response.kba.session);
          }
        });

        return response;
      });
    }
  });

  _exports.default = _default;
});