define("ember-rs/locales/de/controllers/document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "notAllowed": "Sie haben dazu keine Berechtigung.",
    "voided": "Dokument wurde storniert.",
    "voidingFailed": "Fehler beim Stornieren des Dokuments.",
    "docExtended": "Das Ablaufdatum für das Dokument wurde verlängert.",
    "tagUpdated": "Die Tags wurden aktualisiert",
    "tagUpdateFailed": "Fehler beim Aktualisieren der Tags: {{{tags}}} ",
    "viewerListUpdated": "Die Liste der Leseberechtigten wurde aktualisiert",
    "viewerUpdateFailed": "Fehler beim Aktualisieren der Liste mit den Leseberechtigten: {{{viewers}}}"
  };
  _exports.details = details;
});