define("ember-rs/components/rect-detectable-page", ["exports", "jquery", "ember-rs/config/environment", "ember-rs/mixins/component-dimensions"], function (_exports, _jquery, _environment, _componentDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var BLACK_TRACER_PATH = "".concat(_environment.default.rsFullname, "/workers/black-tracer-v2.js");
  var WHITE_TRACER_PATH = "".concat(_environment.default.rsFullname, "/workers/white-tracer-v2.js");

  var _default = Ember.Component.extend(_componentDimensions.default, {
    ////////////////////////////////////////////////////////////////////////////////
    //                                SERVICES
    ////////////////////////////////////////////////////////////////////////////////
    overlayerData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    ////////////////////////////////////////////////////////////////////////////////
    //                               PROPERTIES
    ////////////////////////////////////////////////////////////////////////////////
    viewerScale: Ember.computed.alias('layoutSizeData.viewerScale'),
    imgLoaded: false,
    checkClasses: Ember.A(['checkmarkAnnotationComponent', 'checkComponent', 'checkGroupComponent']),
    maxCheckSize: 50,
    lineBoxHeight: 50,
    checkBoxHeight: 25,
    tracing: false,
    tracerType: 'black',
    ghostTimeout: 300,
    isGhosting: false,
    mouseOver: false,
    whiteLuminanceThreshold: 92,
    minTextFieldHeight: 10,
    minTextFieldWidth: 30,
    minCheckBoxWidth: 10,
    defaultTextFieldWidth: 100,
    defaultTextFieldHeight: 25,
    defaultCheckBoxWidth: 25,
    defaultCheckBoxHeight: 25,
    workers: Ember.Object.create({
      'n': null,
      's': null,
      'e': null,
      'w': null
    }),
    boundingBox: Ember.Object.create({
      'n': null,
      's': null,
      'e': null,
      'w': null
    }),
    //this always has the current cursor coordinates.
    //useful when we have to draw a default box at the cursor position
    cursorCoordinates: Ember.Object.create({
      'x': null,
      'y': null
    }),
    ////////////////////////////////////////////////////////////////////////////////
    //                          LIFECYCLE HOOKS
    ////////////////////////////////////////////////////////////////////////////////
    didInsertElement: function didInsertElement() {
      var _this = this;

      var $canvas = this.$('canvas');
      this.setProperties({
        canvas: $canvas[0],
        ctx: $canvas[0].getContext('2d')
      });
      $canvas.on("mousemove.".concat(this.elementId), Ember.run.bind(this, this.onCanvasMouseMove));
      $canvas.on("mouseout.".concat(this.elementId), Ember.run.bind(this, this.onCanvasMouseOut));
      $canvas.on("mouseover.".concat(this.elementId), Ember.run.bind(this, this.onCanvasMouseOver));
      $canvas.on("click.".concat(this.elementId), Ember.run.bind(this, this.onCanvasClick));
      var img = new Image();

      img.onload = function () {
        _this.set('imgLoaded', true);

        _this.ctx.drawImage(img, 0, 0);

        _this.set('imgData', _this.ctx.getImageData(0, 0, _this.get('documentViewerData.pageWidth'), _this.get('documentViewerData.pageHeight')));
      }; // img.src = this.get('pageImage.original');
      // Temporary fix for http://stackoverflow.com/questions/26352083/chrome-cors-cache-requesting-same-file-from-two-different-origins
      //   Chrome caching issue
      // Chrome has a bug due to which it calls the cached url again but without the origin header, the solution is to fool chrome to make a // new request. Appending something to the url that does not break aws signature would be fine. The long term solution is to generate  // a new url here instead of using the previously used url.
      // More info https://i.stack.imgur.com/MjRVj.png
      // https://stackoverflow.com/questions/17570100/s3-not-returning-access-control-allow-origin-headers/30697589


      img.crossOrigin = 'anonymous';
      img.src = this.get('pageImage.original') + "&";
      this.set('img', img);
    },
    willDestroyElement: function willDestroyElement() {
      var $canvas = this.$('canvas');
      var timer = this.ghostTimer;

      if (timer) {
        Ember.run.cancel(timer);
      }

      $canvas.off("mousemove.".concat(this.elementId));
      $canvas.off("mouseout.".concat(this.elementId));
      $canvas.off("mouseover.".concat(this.elementId));
      $canvas.off("click.".concat(this.elementId));
      this.setProperties({
        canvas: null,
        ctx: null,
        img: null
      });
    },
    ////////////////////////////////////////////////////////////////////////////////
    //                               HELPERS
    ////////////////////////////////////////////////////////////////////////////////
    getPixel: function getPixel(x, y) {
      return this.ctx.getImageData(x, y, 1, 1).data;
    },
    hslPixel: function hslPixel(x, y) {
      var rgb = this.ctx.getImageData(x, y, 1, 1).data;
      return this.rgbToHsl.apply(this, _toConsumableArray(rgb));
    },
    rgbToHsl: function rgbToHsl(r, g, b) {
      var red = r / 255;
      var green = g / 255;
      var blue = b / 255;
      var max = Math.max(red, green, blue);
      var min = Math.min(red, green, blue);
      var h = (max + min) / 2;
      var s = (max + min) / 2;
      var l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
          case r:
            h = (green - blue) / d + (green < blue ? 6 : 0);
            break;

          case g:
            h = (blue - red) / d + 2;
            break;

          case b:
            h = (red - green) / d + 4;
            break;
        }

        h /= 6;
      }

      return [Math.floor(h * 360), Math.floor(s * 100), Math.floor(l * 100)];
    },
    onCanvasMouseOut: function onCanvasMouseOut() {
      this.set('mouseOver', false);
    },
    onCanvasMouseOver: function onCanvasMouseOver() {
      this.set('mouseOver', true);
    },
    onCanvasMouseMove: function onCanvasMouseMove(event) {
      var position = this.overlayerData.eventPosition(event);

      var _position = _slicedToArray(position, 2),
          x = _position[0],
          y = _position[1];

      var rgb = this.getPixel(x, y);
      var hsl = this.rgbToHsl.apply(this, _toConsumableArray(rgb));
      var $element = (0, _jquery.default)(event.target);
      var timer = this.ghostTimer;
      this.set('cursorCoordinates.x', x);
      this.set('cursorCoordinates.y', y);

      if (this.get('overlayerData.componentsAreSelected')) {
        (0, _jquery.default)(event.target).css('cursor', 'default');
        return;
      }

      if (timer) {
        Ember.run.cancel(timer);
      }

      this.overlayerData.clearGhost();

      if (this.get('overlayerData.showRectDetectGhost')) {
        this.set('ghostTimer', Ember.run.later(this, function () {
          if (!this.get('overlayerData.componentsAreSelected') && this.mouseOver) {
            this.onCanvasClick(event);
          }
        }, this.ghostTimeout));
      } // Set cursor


      if (this.get('overlayerData.selectedComponentClass') === 'textAnnotationComponent') {
        $element.css('cursor', 'text');
      } else if (hsl[2] > this.whiteLuminanceThreshold) {
        $element.css('cursor', 'crosshair');
      } else {
        $element.css('cursor', 'vertical-text');
      }
    },
    terminateWorkers: function terminateWorkers() {
      var _this2 = this;

      Object.keys(this.workers).forEach(function (direction) {
        var worker = _this2.workers.get(direction);

        if (Ember.isPresent(worker)) {
          worker.terminate();
        }
      });
    },
    doneTracing: function doneTracing() {
      var _this3 = this;

      var found_null = false;
      Object.keys(this.boundingBox).forEach(function (direction) {
        if (Ember.isNone(_this3.boundingBox.get(direction))) {
          found_null = true;
        }
      });
      return !found_null;
    },
    traceSuccessful: function traceSuccessful() {
      var _this4 = this;

      var found_nonsuccess = false;
      Object.keys(this.boundingBox).forEach(function (direction) {
        var v = _this4.boundingBox.get(direction);

        if (Ember.isNone(v) || v === -1) {
          found_nonsuccess = true;
          return;
        }
      });
      return !found_nonsuccess;
    },
    addComponentAtLocation: function addComponentAtLocation(x, y) {
      this.sendAction('addComponent', this.get('overlayerData.selectedComponentClass'), {
        page: this.get('pageImage.page_number'),
        x: x / this.get('documentViewerData.pageWidth'),
        y: y / this.get('documentViewerData.pageHeight'),
        type: this.get('overlayerData.selectedComponentClass')
      });
    },
    onWorkerMessage: function onWorkerMessage(event) {
      var originalHeight = null;
      var rect = {};
      this.boundingBox.set(event.data.dir, event.data.coordinate);

      if (this.doneTracing() && this.traceSuccessful()) {
        rect.x = this.get('boundingBox.w');
        rect.type = this.get('overlayerData.selectedComponentClass');
        var isCheckBox = this.checkClasses.includes(this.get('overlayerData.selectedComponentClass')); // if the width of the box is less than minFieldWidth
        // then create a box at cursor position with default dimensions

        if (this.get('boundingBox.e') - this.get('boundingBox.w') < this.minTextFieldWidth && !isCheckBox) {
          rect.x = this.get('cursorCoordinates.x');
          rect.y = this.get('cursorCoordinates.y');
          rect.width = this.defaultTextFieldWidth;
          rect.height = this.defaultTextFieldHeight;
        } else if (this.get('boundingBox.e') - this.get('boundingBox.w') < this.minCheckBoxWidth && isCheckBox) {
          rect.x = this.get('cursorCoordinates.x');
          rect.y = this.get('cursorCoordinates.y');
          rect.width = this.defaultCheckBoxWidth;
          rect.height = this.defaultCheckBoxHeight;
        } else if (this.get('boundingBox.s') - this.get('boundingBox.n') <= this.minTextFieldHeight) {
          //most probably a horizontal line without vertical boundaries
          var rectHeight = isCheckBox ? this.checkBoxHeight : this.lineBoxHeight;
          rect.y = this.get('boundingBox.s') - rectHeight;
          rect.width = this.get('boundingBox.e') - this.get('boundingBox.w');
          rect.height = rectHeight;
        } else {
          rect.y = this.get('boundingBox.n');
          rect.width = this.get('boundingBox.e') - this.get('boundingBox.w');
          rect.height = this.get('boundingBox.s') - this.get('boundingBox.n');
        } // Type specific sizing


        switch (this.get('overlayerData.selectedComponentClass')) {
          case 'checkmarkAnnotationComponent':
          case 'checkComponent':
          case 'checkGroupComponent':
            {
              // TODO: implement a max size
              var shortSize = Math.min(rect.width, rect.height, this.maxCheckSize);
              originalHeight = rect.height;
              rect.x = rect.x + rect.width / 2; // middle x

              rect.width = rect.height = shortSize;
              rect.x = rect.x - shortSize / 2;
              rect.y = rect.y + originalHeight / 2 - shortSize / 2;
              break;
            }

          case 'signatureAnnotationComponent':
          case 'signatureComponent':
            originalHeight = rect.height;
            rect.width = Math.min(300, rect.width);
            rect.height = rect.width / this.defaultComponentRatios['signatureAnnotationComponent'];
            rect.y = rect.y + originalHeight - rect.height;
            break;
        }

        var options = {
          page: this.get('pageImage.page_number'),
          x: rect.x / this.get('documentViewerData.pageWidth'),
          y: rect.y / this.get('documentViewerData.pageHeight'),
          width: rect.width / this.get('documentViewerData.pageWidth'),
          height: rect.height / this.get('documentViewerData.pageHeight')
        };

        if (this.get('overlayerData.selectedComponentClass') === 'checkGroupComponent') {
          options.createNewGroup = true;
        }

        if (this.isGhosting) {
          this.set('overlayerData.ghostComponent', options);
        } else {
          this.sendAction('addComponent', this.get('overlayerData.selectedComponentClass'), options);
        }
      } else {
        this.continueTracing(event);
      }
    },
    onCanvasClick: function onCanvasClick(event, positionOverride) {
      var _this5 = this;

      if (!this.imgLoaded || this._state === 'destroying') {
        return;
      }

      var position = positionOverride ? [parseInt(positionOverride.x), parseInt(positionOverride.y)] : this.overlayerData.eventPosition(event);

      var _position2 = _slicedToArray(position, 2),
          x = _position2[0],
          y = _position2[1];

      if (this.ghostTimer) {
        Ember.run.cancel(this.ghostTimer);
      }

      if (this.tracing) {
        this.terminateWorkers();
      } // If components are selected, deselect instead of creating new component


      if (this.get('overlayerData.componentsAreSelected')) {
        this.overlayerData.deselectComponents();
        return;
      } else if (this.get('overlayerData.selectedComponentClass') === 'textAnnotationComponent' && this.isGhosting) {
        return;
      } else if (this.get('overlayerData.selectedComponentClass') === 'textAnnotationComponent') {
        // Add component at click location
        // Hardcoded coordinates are the text cursor offset to make the text appear where the cursor is instead of
        // creating the top left coordinate
        this.addComponentAtLocation(x - 5, y - 11);
        return;
      }

      this.set('isGhosting', event.type !== 'click' && event.click !== 'click');
      this.set('boundingBox', Ember.Object.create({
        'n': null,
        's': null,
        'e': null,
        'w': null
      }));
      this.set('tracing', true);

      if (this.hslPixel(x, y)[2] < this.whiteLuminanceThreshold) {
        var lowestLum = 100;
        var lowestLumY = y;

        for (var i = 2; i >= 0; i--) {
          var hslR = this.hslPixel(x, y + i)[2];

          if (hslR[2] < lowestLum) {
            lowestLum = hslR[2];
            lowestLumY = y + i;
          }

          var hslL = this.hslPixel(x, y - i)[2];

          if (hslL[2] < lowestLum) {
            lowestLum = hslL[2];
            lowestLumY = y - i;
          }
        }

        this.set('boundingBox.s', y);
        this.set('tracerType', 'black');
        ['e', 'w'].forEach(function (direction) {
          _this5.workers.set(direction, new Worker(BLACK_TRACER_PATH));

          _this5.workers.get(direction).onmessage = Ember.run.bind(_this5, _this5.onWorkerMessage);

          _this5.workers.get(direction).postMessage([_this5.imgData, x, lowestLumY, direction, event.click]);
        });
      } else if (Ember.isNone(this.boundingBox.get('s'))) {
        // go south only if not travelled yet
        this.set('tracerType', 'white');
        this.workers.set('s', new Worker(WHITE_TRACER_PATH));
        this.workers.get('s').onmessage = Ember.run.bind(this, this.onWorkerMessage);
        this.workers.get('s').postMessage([this.imgData, x, y, 's', event.type]);
      }
    },
    continueTracing: function continueTracing(event) {
      var click = event.data.click; // pass the click event to all the workers

      var dir = event.data.dir; // since trip to south has finished, send the tracers east, west now

      if (dir === 's') {
        var positionOverride = {};
        positionOverride.x = event.data.hCoordinate;
        positionOverride.y = event.data.coordinate;
        event.click = click;
        this.onCanvasClick(event, positionOverride);
      } else if (dir === 'e' || dir === 'w') {
        //time to go north
        if (Ember.isPresent(this.boundingBox.get('e')) && Ember.isPresent(this.boundingBox.get('w'))) {
          // the width helps us to ensure that we scan only the box and not areas outside it
          var width = this.boundingBox.get('e') - this.boundingBox.get('w'); // send a trip to north from west

          this.workers.set('n', new Worker(BLACK_TRACER_PATH));
          this.workers.get('n').onmessage = Ember.run.bind(this, this.onWorkerMessage);
          this.workers.get('n').postMessage([this.imgData, this.boundingBox.get('w') + 2, this.boundingBox.get('s'), 'n', click, 'w', width]);
        }
      }
    }
  });

  _exports.default = _default;
});