define("ember-rs/locales/pt-br/mixins/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.settings = void 0;
  var settings = {
    "updateSuccessful": "Configuração atualizada com sucesso",
    "updateError": "Erro ao atualizar configuração"
  };
  _exports.settings = settings;
});