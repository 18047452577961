define("ember-rs/locales/fr/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteDocumentModal = void 0;
  var deleteDocumentModal = {
    "permanentlyDeleted": "Cela supprimera définitivement le document de RightSignature. Cette action ne peut pas être annulée.",
    "email": "Les signataires et les destinataires en Cc recevront un e-mail contenant le document et le certificat de signature dûment remplis à des fins d'archivage.",
    "briefMoment": "Le document disparaîtra de votre tableau de bord dans quelques instants.",
    "delete": "Supprimer",
    "cancel": "Annuler",
    "deleteDocument": "Supprimer document",
    "deleteComplete": "Document supprimé",
    "deleteFailed": "Échec de la destruction du document"
  };
  _exports.deleteDocumentModal = deleteDocumentModal;
});