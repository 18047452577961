define("ember-rs/locales/fr/routes/email-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailVerification = void 0;
  var emailVerification = {
    "verificationSuccess": "E-mail vérifié avec succès.",
    "invalidVerification": "L'e-mail de vérification n'est pas valide."
  };
  _exports.emailVerification = emailVerification;
});