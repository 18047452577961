define("ember-rs/templates/components/restyle-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7iXfnWx1",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\",\"@tagName\"],[\"applicationModals\",\"span\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"rs-modal\",[],[[\"@show\",\"@action\",\"@dismissable\",\"@class\"],[[30,[36,5],[[30,[36,4],[[35,3]],null]],null],[30,[36,8],[[32,0],[30,[36,7],[[35,6]],null],false],null],false,\"restyle-onboarding-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"div\"],[24,0,\"close\"],[4,[38,8],[[32,0],\"dismiss\"],null],[12],[2,\"\\n      \"],[1,[30,[36,9],[\"close\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,10],[\"components.restyleOnboarding.welcome\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,10],[\"components.restyleOnboarding.introduceNewLook\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"image-container\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[35,0],3],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[14,\"src\",\"/assets/v3/onboarding/restyle-onboarding-3.png\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0],2],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[14,\"src\",\"/assets/v3/onboarding/restyle-onboarding-2.png\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"img\"],[14,\"src\",\"/assets/v3/onboarding/restyle-onboarding-1.png\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"footer\"],[12],[11,\"button\"],[4,[38,8],[[32,0],\"dismiss\"],null],[12],[1,[30,[36,10],[\"components.restyleOnboarding.gotIt\"],null]],[13],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentImage\",\"eq\",\"if\",\"hasSeenRestyleOnboarding\",\"not\",\"readonly\",\"dismiss\",\"mut\",\"action\",\"inline-svg\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/restyle-onboarding.hbs"
    }
  });

  _exports.default = _default;
});