define("ember-rs/routes/signer/passcode", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ////////////////////////  HOOKS  ////////////////////////
    model: function model(params, transition) {
      return new Ember.RSVP.Promise(function (resolve) {
        _jquery.default.getJSON("".concat(_environment.default.api.endpoint, "/signers/").concat(transition.to.parent.params.signer_id, "/passcode"), {
          access_token: params.access_token,
          identity_token: params.identity_token
        }).then(function (response) {
          response.signer_id = transition.to.parent.params.signer_id;
          response.query_params = transition.to.queryParams;
          resolve(response);
        });
      }, 'model for signer passcode');
    },
    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});