define("ember-rs/locales/en/mixins/redirect-token-generation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectTokenGeneration = void 0;
  var redirectTokenGeneration = {
    "linkGenerated": "A new secure link has been generated and sent to your email."
  };
  _exports.redirectTokenGeneration = redirectTokenGeneration;
});