define("ember-rs/locales/pt-br/templates/components/unconfirmed-user-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedUserButton = void 0;
  var unconfirmedUserButton = {
    "emailUnverified": "Seu endereço de e-mail não foi verificado",
    "verifySendDocument": "Antes de enviar seu primeiro documento, precisamos verificar seu endereço de email.",
    "verifyCreateTemplate": "Antes de criar seu primeiro modelo, precisamos verificar seu endereço de email.",
    "verifyUpgrade": "Antes de atualizar sua conta hybris, precisamos verificar seu endereço de email.",
    "verifyIntegration": "Antes que seja possível ativar uma integração, precisamos confirmar seu endereço de email.",
    "verifyEmail": "Enviamos um email de verificação para o endereço abaixo.",
    "checkEmail": "Não recebeu o email? Verifique sua pasta de spam ou",
    "resend": "Reenviar",
    "verificationMail": "O e-mail de verificação foi enviado. Verifique {{{email}}} e siga as instruções."
  };
  _exports.unconfirmedUserButton = unconfirmedUserButton;
});