define("ember-rs/locales/en/templates/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentAccessSettings = void 0;
  var documentAccessSettings = {
    "addViewer": "Add Viewer",
    "email": "Email",
    "name": "Name",
    "add": "Add",
    "cancel": "Cancel",
    "editSigner": "Edit Signer",
    "save": "Save",
    "manageAccess": "Manage Access",
    "accessTip": "These people have access to this document and are able to download or view it at any time.",
    "signers": "Signers",
    "edit": "edit",
    "viewers": "Viewers",
    "remove": "remove",
    "updateEmail": "Successfully updated signer email.",
    "updateSigner": "Successfully updated signer details."
  };
  _exports.documentAccessSettings = documentAccessSettings;
});