define("ember-rs/locales/es/templates/support-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportContent = void 0;
  var supportContent = {
    "needHelp": "¿Necesita ayuda con RightSignature&trade;?",
    "articlesBelow": "A continuación, encontrará artículos de asistencia y vídeos que responden a muchas de las preguntas que pueda tener con respecto a RightSignature&trade. Si los artículos y vídeos no responden a su pregunta, no dude en ponerse en contacto con nuestro equipo de asistencia técnica.",
    "supportArticles": "Artículos de asistencia",
    "overview": "Vista general de RightSignature&trade; para ShareFile",
    "activate": "¿Cómo activo RightSignature&trade; para ShareFile en mi cuenta de ShareFile?",
    "send": "¿Cómo envío un documento desde ShareFile usando RS para SF?",
    "sign": "¿Cómo firmo un documento desde ShareFile usando RS para SF?",
    "branding": "¿Cómo configuro la marca de mi empresa?",
    "changeEmail": "¿Cómo cambio la dirección de correo electrónico asociada con mi cuenta de RightSignature&trade;?",
    "addUser": "¿Cómo agrego usuarios a mi cuenta de RightSignature&trade;?",
    "signerOrder": "¿Cómo asigno un pedido de firmantes en el mismo documento?",
    "annotate": "¿Cómo puedo usar RightSignature&trade; para anotar documentos para los firmantes?",
    "mergeFields": "¿Cómo uso los campos de combinación para introducir texto en un documento antes de enviarlo?",
    "passcode": "¿Cómo agrego un código de acceso a mi documento?",
    "importOverlay": "¿Cómo uso la función de Importar superposición?",
    "docLocation": "¿Dónde se almacenan los documentos con RightSignature&trade; para ShareFile?",
    "share": "¿Cómo comparto documentos?",
    "multiple": "¿Cómo envío varios documentos para firmar?",
    "oneOff": "¿Cómo envío un documento? (una sola vez)",
    "template": "¿Cómo creo una plantilla reutilizable?",
    "editTemplate": "¿Cómo edito una plantilla?",
    "sendTemplate": "¿Cómo envío un documento de plantilla?",
    "embed": "¿Cómo incrusto un documento en mi sitio Web para firmar?",
    "generate": "¿Cómo genero un enlace compartido para mis documentos de plantilla?",
    "delete": "¿Cómo elimino un documento?",
    "tag": "¿Cómo etiqueto un documento?",
    "selfSign": "¿Cómo firmo mi propio documento?",
    "reports": "¿Qué informes y estadísticas están disponibles en RightSignature&trade;?",
    "complete": "Cómo completar el documento que le han enviado",
    "remind": "¿Cómo envío un recordatorio para firmar un documento?",
    "inPerson": "¿Cómo puedo obtener un documento firmado en persona?",
    "showLess": "Mostrar menos",
    "showMore": "Mostrar más",
    "sendQuestion": "Envíenos su pregunta",
    "submitRequest": "Envíe su solicitud aquí",
    "call": "Llámenos ahora",
    "free": "Número gratuito"
  };
  _exports.supportContent = supportContent;
});