define("ember-rs/locales/de/routes/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    "embeddedTemplate": "Ihr Konto hat keinen Zugriff auf dieses Feature. Führen Sie ein Upgrade auf ein Pro-Abonnement durch, um eingebettete Vorlagen zu verwenden.",
    "inPerson": "Ihr Konto hat keinen Zugriff auf dieses Feature. Führen Sie ein Upgrade auf ein Pro-Abonnement durch, um 'Persönliche Signatur' zu verwenden."
  };
  _exports.startDocument = startDocument;
});