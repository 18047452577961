define("ember-rs/locales/fr/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newClientApplication = void 0;
  var newClientApplication = {
    "appReview": "Votre demande est en cours de révision. Nous vous notifierons ou approuverons votre demande dans les 24 heures.",
    "secretsSuccess": "Secrets récupérés. Ils ne doivent être utilisés que par vous ou une personne de confiance.",
    "secretsError": "Impossible de récupérer les secrets : {{{error}}}",
    "tokenSuccess": "Nouveau jeton d'API privée généré avec succès",
    "tokenError": "Impossible de générer le jeton : {{{error}}}",
    "singleSecretSuccess": "Secret récupéré pour {{{clientAppName}}}. Il ne doit être utilisé que par vous ou une personne de confiance.",
    "singleSecretError": "Impossible de récupérer le secret : {{{error}}}",
    "tokenRetrieveSuccess": "Jeton d'API privée récupéré pour {{{clientAppName}}}. Il ne doit être utilisé que par vous ou une personne de confiance.",
    "tokenRetrieveError": "Impossible de récupérer le jeton d'API privée : {{{error}}}",
    "tokenRegenerateError": "Impossible de régénérer le jeton : {{{error}}}",
    "confirmInfo": "En êtes-vous sûr ? Une autre solution pourrait consister à demander une nouvelle clé API, car une fois régénérés, les jetons d'API privées écrasés sont irrécupérables. Si vous choisissez de continuer, assurez-vous de supprimer et de remplacer toutes les informations d'identification obsolètes dans toutes vos intégrations."
  };
  _exports.newClientApplication = newClientApplication;
});