define("ember-rs/routes/signer/sign/identify", ["exports", "ember-rs/mixins/kba-route"], function (_exports, _kbaRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_kbaRoute.default, {
    cookies: Ember.inject.service(),
    modelPath: '/status',
    actions: {
      nextStep: function nextStep() {
        this.transitionTo('signer.sign.verify');
      },
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});