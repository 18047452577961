define("ember-rs/locales/en/mixins/distribution-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.distributionMethod = void 0;
  var distributionMethod = {
    "viaEmail": "Send Invite via Email",
    "manual": "Distribute Links Manually",
    "email": "Email",
    "sms": "SMS",
    "none": "None"
  };
  _exports.distributionMethod = distributionMethod;
});