define("ember-rs/locales/en/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanDetails = void 0;
  var hybrisPlanDetails = {
    "document-sending": "Document Sending",
    "document-sending-info": "Upload and send documents in common formats including PDF, .doc, and more.",
    "signature": "Hand-Drawn Signatures",
    "signature-info": "Sign with your mouse or touchscreen - looks just like your paper signature.",
    "customer-support": "24/7 Customer Support",
    "customer-support-info": "Get help whenever you need it from our award-winning technical support team.",
    "email-alert": "Email Alerts",
    "email-alert-info": "Reminders for your signers and instant status updates for your docs.",
    "integration": "Integration Add-Ons",
    "integration-info": "Connect RightSignature to over a dozen of the most popular advanced apps.",
    "type-sign": "Type-to-Sign",
    "type-sign-info": "Allow your signers to sign documents using a keyboard.",
    "mobile": "Sign on Mobile Devices",
    "mobile-info": "Sign documents from anywhere using your smartphone or tablet.",
    "guided-signing": "Guided Signing",
    "guided-signing-info": "Reduce errors and omissions with simple, step-by-step instructions.",
    "archive": "Secure Document Archive",
    "archive-info": "Keep your important documents close with our secure, searchable archive.",
    "encrypt": "256-bit Data Encryption",
    "encrypt-info": "Bank-level security protects your sensitive documents from leaks and theft.",
    "biometric": "Biometric Authentication",
    "biometric-info": "Help verify your signer's authenticity with key biometric data.",
    "fingerprint": "Digital Fingerprints",
    "fingerprint-info": "SHA-1 digital checksums prevent any party from modifying your document.",
    "audit": "Audit Log and Certificate",
    "audit-info": "For legal compliance, every action in a document is logged and secured.",
    "template": "Reusable Templates",
    "template-info": "Share commonly-used documents with your whole team.",
    "branding": "Custom Branding",
    "branding-info": "Add your logo, colors, and a photo of you to your RightSignature documents.",
    "attachments": "Request Attachments",
    "attachments-info": "Allow your signers to attach a file as part of signing your document.",
    "payments": "Request Payments",
    "payments-info": "Process payments or collect credit card information on your documents.",
    "api": "Developer API",
    "api-info": "Integrate RightSignature e-signatures into your own apps or website.",
    "kba": "Knowledge Based Authentication",
    "kba-info": "In certain industries, improve compliance with relevant regulations.",
    "standard": "Standard",
    "advanced": "<span>Advanced<span>",
    "advancedKba": "<span>Advanced KBA<span>",
    "sharefilePremium": "<span><p>Sharefile Premium</p> <p> with e-Signature</p></span>",
    "annually": "Annually",
    "quarterly": "Quarterly",
    "monthly": "Monthly",
    "month": "month",
    "year": "year",
    "quarter": "quarter",
    "document-sends-per-user": "100 document sends per user",
    "hand-written-text-to-sign": "Hand-drawn, text-to-signature, click to agree",
    "bulk-send-for-signature": "Bulk-send for signature",
    "sequenced-signing": "Sequenced signing",
    "knowledge-based-authentication": "Knowledge-Based Authentication",
    "unlimited-document-sends": "Send unlimited documents for signature",
    "store-sync-share-files": "Seamlessly store, sync, and share files",
    "hipaa-compliant": "HIPAA compliance configuration",
    "advanced-caption": "Includes popular features such as:",
    "advanced-kba-caption": "Includes popular features such as:",
    "sharefile-caption": "Includes ALL E-SIGNATURE FEATURES PLUS MORE WITH CITRIX SHAREFILE:",
    "minimum-of": "Minimum of {{{noUsers}}} users",
    "users": "users",
    "user": "user",
    "user-per-month": "per user/month",
    "compare-plans-in-detail": "<a href='https://citrix.sharefile.com/share/view/s1493194af0a44866ae95a6a667e7ffcd' style='color:#2071C5' target='_blank'>Compare plans in detail</a>",
    "selected-plan-requires-3-users": "<span style='color:#536E7B'>The selected plan requires atleast 3 users</span>",
    "more-than-1000-users-contact-sales": "<span style='color:#2071C5'>If you need more than 1000 users, please <a href={{driftLinkId}} style='color: #2071C5; text-decoration: underline; text-transform: lowercase'>chat with sales. </a></span>",
    "trail-upgrade-msg": "You have {{{numberOfDaysLeft}}} days remaining on your trial. To enjoy worry-free usage without interruption, consider upgrading your plan now.",
    "savings": "Savings",
    "contact-sales": "Chat with Sales",
    "select-plan": "Select Plan",
    "selected-plan": "Selected Plan",
    "best-for-team-collaboration": "Best Value"
  };
  _exports.hybrisPlanDetails = hybrisPlanDetails;
});