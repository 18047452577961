define("ember-rs/templates/unauthorized/archived-migrated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vNAWQZ3l",
    "block": "{\"symbols\":[],\"statements\":[[8,\"archived-migrated-modal\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/archived-migrated.hbs"
    }
  });

  _exports.default = _default;
});