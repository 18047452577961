define("ember-rs/locales/zh-cn/controllers/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "upgradeMsg": "您的帐户无权访问此功能。请升级到专业计划以开始使用嵌入的模板。",
    "templateDeleteMsg": "已成功删除模板。",
    "tagUpdateMsg": "已成功更新标记",
    "tagUpdateFailedMsg": "无法更新标记: {{{formattedResponse}}}",
    "templateDeleteConfirm": "是否确实要删除此模板?",
    "loadSignerLinksFailedMsg": "无法加载模板签名者链接"
  };
  _exports.details = details;
});