define("ember-rs/locales/nl/templates/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateSelector = void 0;
  var reusableTemplateSelector = {
    "noTemplates": "U hebt nog geen sjablonen.",
    "templateDesc": "Maak herbruikbare sjablonen uit veelgebruikte documenten voor het snel verzenden, insluiten in uw site of ter verspreiding onder een groot publiek.",
    "createNew": "Nieuwe sjabloon maken",
    "chooseTemplate": "Een sjabloon kiezen",
    "loading": "Laden...",
    "loadMore": "meer sjablonen laden...",
    "upgrade": "Upgrade uw account om sjablonen te gebruiken",
    "upgradeAccount": "Account upgraden",
    "bulkSendInfo": "Bulkverzending werkt alleen met een sjabloon voor één ondertekenaar. Hebt u bulkverzending niet eerder gebruikt?",
    "learnMore": "Meer informatie",
    "searchTemplate": "Sjablonen zoeken",
    "noResults": "Geen resultaten!"
  };
  _exports.reusableTemplateSelector = reusableTemplateSelector;
});