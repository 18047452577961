define("ember-rs/locales/zh-cn/templates/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.index = void 0;
  var index = {
    "templates": "模板",
    "reusableTemplates": "可重用的模板",
    "templateExplain": "通过模板，您只需执行一个步骤即可在站点中嵌入文档、批量分发或仅发送常用文档。",
    "newTemplate": "新建模板",
    "noResults": "无搜索结果",
    "searchAgain": "您的搜索未返回任何结果，请尝试其他搜索以查找您的模板。",
    "create": "创建可在站点中嵌入并通过链接共享的可重用文档模板。",
    "createTemplate": "创建模板",
    "notAllowed": "您当前的计划不允许您创建模板。如果要创建模板，请联系技术支持并升级您的帐户。"
  };
  _exports.index = index;
});