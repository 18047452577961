define("ember-rs/templates/components/integration-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5LPcJ4+1",
    "block": "{\"symbols\":[\"service\"],\"statements\":[[6,[37,14],[[30,[36,13],[[30,[36,13],[[35,12]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,11],[[30,[36,10],[[35,9],[30,[36,8],[[32,1],\"payments\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"integration-settings-service\",[],[[\"@contactServiceEnabled\",\"@availableService\",\"@formattedProviderName\",\"@integration\",\"@toggleService\",\"@provider\"],[[34,0],[32,1],[34,1],[34,2],\"toggleService\",[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"integration-logo\"],[12],[2,\"\\n          \"],[19,[30,[36,4],[[35,3]],null],[1]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n          \"],[1,[34,1]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,5]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[15,0,[31,[\"type \",[32,1]]]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":true,\"upvars\":[\"contactServiceEnabled\",\"formattedProviderName\",\"integration\",\"provider\",\"integration-icon\",\"hideIntegrationType\",\"not\",\"if\",\"equal\",\"paymentsUnavailable\",\"and\",\"unless\",\"filteredServices\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/integration-setting.hbs"
    }
  });

  _exports.default = _default;
});