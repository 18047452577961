define("ember-rs/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Dependencies
    store: Ember.inject.service(),
    overlayerData: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    query: '',
    queryText: '',
    isQuerying: false,
    perPage: 10,
    showImportOverlay: false,
    roleMapping: Ember.Object.create({}),
    importableDocumentPageCount: Ember.computed.alias('importableDocument.upload.baseFile.pageCount'),
    importableComponents: Ember.computed.alias('importableDocument.components'),
    importableRoles: Ember.computed.mapBy('importableComponents', 'assignedTo'),
    roleMappings: Ember.computed.mapBy('documentTemplate.roles', 'name'),
    uniqueImportableRoles: Ember.computed.uniq('importableRoles'),
    ////////////////////////  LIFECYCLE EVENTS  ////////////////////////
    didInsertElement: function didInsertElement() {
      var filename = this.get('documentTemplate.upload.filename') || '';
      this.set('queryText', filename.replace(/\.[^.$]+$/, '')); // remove file extension

      this.input();
    },
    willDestroyElement: function willDestroyElement() {
      this.cancelInputTimer();
    },
    ////////////////////////  EVENT HANDLERS  ////////////////////////
    input: function input() {
      this.cancelInputTimer();
      this.set('isQuerying', true);
      this.set('inputTimer', Ember.run.debounce(this, this.applyQuery, 500));
    },
    ////////////////////////  HELPER FUNCTIONS  ////////////////////////
    cancelInputTimer: function cancelInputTimer() {
      var inputTimer = this.inputTimer;

      if (inputTimer) {
        Ember.run.cancel(inputTimer);
      }
    },
    applyQuery: function applyQuery() {
      this.set('query', this.queryText);
      this.set('isQuerying', false);
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    noDocumentsFound: Ember.computed.empty('importableDocuments'),
    defaultRole: Ember.computed('documentTemplate.roles.@each.name', function () {
      return this.get('documentTemplate.roles.firstObject.name');
    }),
    importableDocuments: Ember.computed.union('relatedDocuments', 'relatedReusableTemplates'),
    relatedReusableTemplates: Ember.computed('query', function () {
      var query = this.query;

      if (Ember.isBlank(query)) {
        return Ember.A();
      }

      return this.store.query('dashboardDocument', {
        search: query
      });
    }),
    relatedDocuments: Ember.computed('query', function () {
      var query = this.query;

      if (Ember.isBlank(query)) {
        return Ember.A();
      }

      return this.store.query('dashboardReusableTemplate', {
        search: query
      });
    }),
    actions: {
      cancelImport: function cancelImport() {
        this.set('importableDocument', null);
      },
      runImport: function runImport() {
        var _this = this;

        var pageCount = this.get('documentTemplate.upload.baseFile.pageCount');
        this.get('documentTemplate.components').forEach(function (component) {
          Ember.run.once(_this, function () {
            component.deleteRecord();
            component.unloadRecord();
          });
        });
        Ember.run.scheduleOnce('afterRender', this, function () {
          var _this2 = this;

          var documentTemplate = this.documentTemplate;
          this.importableComponents.forEach(function (component) {
            var duplicatedComponent = component.toJSON();

            if (duplicatedComponent.page <= pageCount) {
              var newComponent = _this2.store.createRecord(duplicatedComponent.type, duplicatedComponent);

              newComponent.set("id", "component".concat(Ember.uuid())); // assign frontend ID so we can reference in the Ember Data store in other components (selectable)

              if (duplicatedComponent.isMergeField && Ember.isEqual(documentTemplate.get('type'), 'document')) {
                newComponent.set('isMergeField', false);
              }

              if (duplicatedComponent.isAnnotation) {
                newComponent.set('assignedTo', null);
              } else if (Ember.isPresent(duplicatedComponent.assignedTo)) {
                var _duplicatedComponent$;

                var mappedRole = _this2.roleMapping.get((_duplicatedComponent$ = duplicatedComponent.assignedTo) === null || _duplicatedComponent$ === void 0 ? void 0 : _duplicatedComponent$.replace('.', '_'));

                newComponent.set('assignedTo', mappedRole || _this2.defaultRole);
              }

              newComponent.set('documentTemplate', documentTemplate);
            }
          });
          this.overlayerData.deselectComponents();
          this.setProperties({
            importableDocument: null,
            showImportOverlay: false
          });
          this.flashMessages.success(this.i18n.t('components.importOverlay.importSuccess', {
            components: this.get('documentTemplate.components.length')
          }));
        });
      },
      selectForImport: function selectForImport(importableDocument) {
        var _this3 = this;

        this.store.findRecord(importableDocument.get('model'), importableDocument.get('id')).then(function (document) {
          _this3.setProperties({
            roleMapping: Ember.Object.create({}),
            importableDocument: document
          });
        });
      },
      setImportableRoleMapping: function setImportableRoleMapping(role, mappedRole) {
        this.roleMapping.set(role === null || role === void 0 ? void 0 : role.replace('.', '_'), mappedRole);
      }
    }
  });

  _exports.default = _default;
});