define("ember-rs/locales/zh-cn/templates/email-unverified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailUnverified = void 0;
  var emailUnverified = {
    "verify": "您的电子邮件地址尚未经过验证。请检查您的电子邮件，然后单击验证链接以确认您的帐户。"
  };
  _exports.emailUnverified = emailUnverified;
});