define("ember-rs/locales/nl/templates/onboarding/send-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding3 = void 0;
  var sendOnboarding3 = {
    "remind": "Selecteer <span>Herinneren</span> om ondertekenaars opnieuw te vragen.",
    "void": "Selecteer <span>Nietig verklaren</span> om het document te annuleren."
  };
  _exports.sendOnboarding3 = sendOnboarding3;
});