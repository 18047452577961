define("ember-rs/locales/pt-br/templates/document-transaction/upload-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload_signers = void 0;
  var upload_signers = {
    "instructions_header": "Siga as etapas abaixo para carregar seu arquivo CSV",
    "step_1": "PASSO 1",
    "step_2": "PASSO 1",
    "step_3": "PASSO 3",
    "step_1_text": "Baixar a lista de amostras de signatário de envio em massa",
    "step_2_text": "Preencha os campos de nome e email (obrigatórios).",
    "step_3_text": "Carregue o csv concluído.",
    "instructions_note": "Nota: O número de signatários não pode exceder 300",
    "upload_failed": "Falha no upload",
    "upload_failure_message": "O upload falhou devido a erro de arquivamento ou processamento corrompido. Verifique se o arquivo tem conteúdo válido e tente fazer o upload novamente.",
    "view_all": "Exibir todos",
    "ok": "Ok",
    "validate_and_reupload": "Seu arquivo contém mais erros, verifique as validações e tente recarregar."
  };
  _exports.upload_signers = upload_signers;
});