define("ember-rs/locales/de/templates/document-transaction/review-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_desktop = void 0;
  var review_desktop = {
    "send_document": "Dokument senden",
    "save_document": "Dokument speichern",
    "sendDocument": "Dokument senden",
    "bulkSendConfirmation": "Sie sind dabei, dieses Dokument mit mehreren Unterzeichnern zu teilen",
    "confirm": "Bestätigen",
    "cancel": "Abbrechen"
  };
  _exports.review_desktop = review_desktop;
});