define("ember-rs/routes/template/details", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend({
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    tracking: Ember.inject.service(),
    search: Ember.inject.service(),
    titleToken: function titleToken(model) {
      var templateName = model.get('name');
      return "Templates | ".concat(templateName);
    },
    beforeModel: function beforeModel() {
      this.set('layoutSizeData.sidebarWidth', 420);
    },
    model: function model(params, transition) {
      var session = this.search.getSearchSession();
      this.tracking.trackEvent('@citrix/rsig/templateClick', _objectSpread(_objectSpread({}, session), {}, {
        timestamp: _moment.default.utc().toISOString()
      }));
      return this.store.findRecord('reusable-template', transition.to.parent.params.reusable_template_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      if (model.get('visibility') === 'private' && model.get('userId') != this.get('currentUser.id')) this.transitionTo('unauthorized.unauthorized');
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        fetchSignerLinks: true,
        showDesktopSidebar: true
      });
      this.set('documentViewerData.pageHeight', model.get('upload.baseFile.imageHeight'));
      this.set('documentViewerData.pageWidth', model.get('upload.baseFile.imageWidth'));
      this.set('documentViewerData.sidebarWidth', 420);

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});