define("ember-rs/components/payment-component-card-capture", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STRIPE_STYLES = {
    base: {
      '::placeholder': {
        fontSize: '14px',
        color: '#999'
      }
    }
  };

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    paymentComponentData: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    componentValue: Ember.computed.alias("component.componentValue"),
    isSaving: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var insertStripeForm = function insertStripeForm() {
        var stripe = new Stripe(_environment.default.stripe.publishableKey);
        var card = stripe.elements().create('card', {
          style: STRIPE_STYLES
        });
        card.on('change', function (e) {
          return _this.$('#card_errors').text(e.error ? e.error.message : '');
        });
        card.mount("#card_element");

        _this.setProperties({
          card: card,
          stripe: stripe
        });
      };

      if (!window.stripeHasLoaded) {
        _jquery.default.getScript('https://js.stripe.com/v3/').then(function () {
          return window.stripeHasLoaded = true;
        }).then(insertStripeForm);
      } else {
        insertStripeForm();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.card) {
        this.card.off('change');
      }

      (0, _jquery.default)('body > iframe').remove();
    },
    url: Ember.computed('signer.id', 'component.id', 'signer.identityToken', 'signer.accessToken', function () {
      return _environment.default.api.endpoint.concat("/signers/".concat(this.get('signer.id'), "/components/").concat(this.get('component.id'), "/signer_payments?access_token=").concat(this.get('signer.accessToken') || '', "&identity_token=").concat(this.get('signer.identityToken') || ''));
    }),
    saveSignerPayment: function saveSignerPayment(token) {
      var _this2 = this;

      this.set('isSaving', true);

      _jquery.default.ajax({
        url: this.url,
        data: {
          token: token.id
        },
        type: 'POST',
        success: function success(response) {
          _this2.store.pushPayload(response);

          var signerPayment = _this2.store.peekRecord("signerPayment", response.signer_payment.id);

          _this2.set('componentValue.value', signerPayment.get("id"));

          _this2.get('component.documentSigner').addAudit("Applied credit card via signer payment #".concat(signerPayment.get("id"), " to component ").concat(_this2.get('component.id')));

          _this2.set("paymentComponentData.previousSignerPayment", signerPayment);

          _this2.set("paymentComponentData.previousToken", token);

          _this2.card.clear();

          _this2.set("paymentComponentData.showCollectCardModal", false);

          _this2.set('isSaving', false);

          _this2.flashMessages.success('Successfully added credit card.');
        },
        error: function error() {
          _this2.set('isSaving', false);
        }
      });
    },
    actions: {
      save: function save() {
        var _this3 = this;

        this.set('isSaving', true);
        this.stripe.createToken(this.card).then(function (result) {
          if (result.error) {
            var errorElement = _this3.$('#card_errors');

            errorElement.textContent = result.error.message;

            _this3.set('isSaving', false);
          } else {
            _this3.saveSignerPayment(result.token);
          }
        });
      },
      cancel: function cancel() {
        this.card.clear();
        this.set('paymentComponentData.showCollectCardModal', false);
      }
    }
  });

  _exports.default = _default;
});