define("ember-rs/locales/zh-cn/templates/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "addRoles": "添加角色",
    "whatRoles": "此模板需要哪些角色?",
    "signOrder": "设置签名者顺序",
    "uniqueName": "必须分配唯一的角色名称。",
    "addRole": "添加角色",
    "sendRole": "添加发件人角色",
    "next": "下一步: 放置字段"
  };
  _exports.addRoles = addRoles;
});