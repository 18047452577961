define("ember-rs/locales/es/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rs3LoginRedirect = void 0;
  var rs3LoginRedirect = {
    "looking": "¿Busca RightSignature&trade; - ShareFile Edition?",
    "sharefileEdition": "Ha llegado a esta página porque intentó iniciar sesión en una versión anterior de RightSignature&trade;. Nuestros registros indican que tiene una cuenta de RightSignature&trade; - ShareFile Edition; inicie sesión más abajo.",
    "support": "¿Tiene alguna pregunta? <a href='https://support.citrix.com/cscase'>Contacte con la asistencia técnica</a> o llame al 1-800-921-4250"
  };
  _exports.rs3LoginRedirect = rs3LoginRedirect;
});