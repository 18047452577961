define("ember-rs/locales/pt-br/templates/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faqModal = void 0;
  var faqModal = {
    "docId": "ID do documento:",
    "contactSender": "Entre em contato com o remetente: {{{userName}}} em <a href='mailto: {{{userEmail}}}'>{{{userEmail}}}</a> se tiver dúvidas",
    "needHelp": "Precisa de ajuda usando RightSignature&trade;? Tente estes perguntas e respostas comuns:",
    "q1": "Eu tenho uma pergunta sobre o conteúdo deste documento:",
    "rsNote": "O RightSignature&trade; é um serviço de assinatura de documentos online de outro fabricante. Possibilitamos o envio e assinatura de documentos com assinaturas eletrônicas legais, seguras, mas não estamos associados ao remetente ou ao documento de nenhuma forma.",
    "q2": "Como faço para preencher este documento?",
    "a2": "A seta vermelha à esquerda do documento irá direcioná-lo para o próximo campo obrigatório. Assim que todos os campos obrigatórios estiverem preenchidos, o botão 'Enviar' ficará disponível para clicar.",
    "q3": "Isto é legalmente vinculante?",
    "a3": "Sim. Os documentos criados on-line com assinaturas eletrônicas são legalmente válidos e têm a mesma validade legal que os documentos criados em papel e assinados à mão. É muito provável que você já tenha tenha feito contratos online. Por exemplo, quando você clica em um botão “Concordo” na internet, você normalmente é parte de um contrato conhecido Contrato Clickwrap. Além disso, quando você compra artigos on-line, você é parte de um contrato juridicamente vinculativo.",
    "a3part2": "A tecnologia da RightSignature&trade; está em total conformidade com a lei americana Electronic Signatures in Global and National Commerce Act (ESIGN) e a Uniform Electronic Transactions Act (UETA), bem como com a Diretriz Europeia (EC/1999/93), todas com o objetivo de incentivar a rápida adoção de assinaturas eletrônicas e diminuição do uso de métodos de papel antiquados. Cada uma dessas leis reforça a validade dos acordos eletrônicos. De acordo com a ESIGN, por exemplo, um contrato “não pode ser seu efeito legal, a validade ou a exequibilidade negada apenas porque na sua criação foi usada uma assinatura eletrônica ou registro eletrônico.",
    "q4": "O que é o RightSignature™?",
    "a4": "RightSignature&trade; permite que você crie e assine documentos legalmente vinculantes on-line de uma forma mais rápida e segura do que criar documentos em papel. O RightSignature&trade; é um software como serviço baseado na web que utiliza a infraestrutura de dados segura da Amazon Web Services.",
    "q5": "O que o RightSignature™ faz?",
    "a5": "RightSignature&trade; permite que você crie e assine documentos legalmente vinculantes on-line de uma forma mais rápida e segura do que criar documentos em papel. O RightSignature&trade; é um software como serviço baseado na web que utiliza a infraestrutura de dados segura da Amazon Web Services.",
    "q6": "Como o RightSignature™ possibilita assinar de documentos on-line?",
    "a6": "O RightSignature&trade; facilita a obtenção de assinaturas de várias partes on-line. Basta carregar seu documento e inserir as informações do destinatário. O RightSignature&trade; envia emails aos destinatários um link exclusivo para uma página onde eles podem examinar o documento, preencher os campos de texto necessários e assinar. Os destinatários podem iniciar sessão online com um mouse em segundos.",
    "a6part2": "Atualmente, a maioria das empresas assina documentos enviando e recebendo faxes, enviando envelopes por serviços urgentes ou digitalizando e enviando páginas assinadas por email. O RightSignature&trade; elimina a inconveniência, atrasos e despesas desses modos tediosos e antiquados. Criar um documento legalmente vinculativo e armazená-lo com segurança em um servidor seguro leva apenas alguns minutos com alguns cliques do mouse.",
    "q7": "Não é mais seguro assinar um documento em papel?",
    "a7": "Os contratos eletrônicos são significativamente mais seguros que os contratos em papel e têm a mesma validade jurídica. Enquanto que os documentos enviados por fax e correio permanecem expostos em toda a distribuição dentro do escritório, os documentos eletrônicos são mantidos completamente confidenciais e são vistos apenas pelos destinatários designados. O documentos do RightSignature&trade; são armazenados em servidores seguros com cópias redundantes e, ao contrário dos documentos em papel, não podem ser extraviados, perdidos ou destruídos. Além disso, recibos de e-mail, registros de IP e dados de assinatura biométrica fornecem autenticação de cada signatário.",
    "q8": "Para quem é este serviço?",
    "a8": "O RightSignature&trade; é ideal para pequenas e médias empresas, freelancers, designers, desenvolvedores, advogados, terceirizados, escolas, organizações sem fins lucrativos, além de outros. Se o seu trabalho requer documentos assinados, você precisa do RightSignature&trade;.",
    "q9": "Que tipos de documentos devo enviar?",
    "a9": "Os documentos enviados regularmente pelo RightSignature&trade; são contratos, acordos de confidencialidade, aceitações de propostas, pedidos de alteração, provas de anúncios, cartas de compromisso, relatórios de despesas, ordens de compra, contratos de aluguel, contratos de contratação independentes, novos pedidos de clientes, candidaturas de emprego, além de outros. Formulários de conformidade como o W-9, W-4 e I-9 são ideais para o RightSignature&trade;. Você precisa que todos os membros da equipe aprovem uma mudança de política? O RightSignature&trade; torna mais fácil. As empresas enviam desde assinaturas de folha de ponto até contratos de vários milhões de dólares via RightSignature&trade;.",
    "q10": "Quais são os navegadores compatíveis?",
    "a10": "O RightSignature&trade; oferece melhores resultados nos seguintes navegadores. Para atualizar seu navegador para a versão mais recente, visite:",
    "a10part2": "Mozilla Firefox 3+, Microsoft Internet Explorer 9+, Apple Safari 3+, Google Chrome, Opera 9+",
    "q11": "O que devo fazer se não conseguir entrar?",
    "a11": "Verifique cuidadosamente o seu endereço de email para ter certeza de que não o digitou incorretamente. Além disso, digite sua senha de forma lenta e cuidadosa. Se esses dados não funcionarem, você poderá redefinir sua senha clicando em 'Esqueci minha senha'. Se você seguir o processo de redefinição de senha e mesmo assim não conseguir fazer login, entre em contato conosco para obter ajuda.",
    "q12": "Os destinatários precisam ter uma conta do RightSignature™?",
    "a12": "Não. Os destinatários não precisam ter uma conta para receber um documento seu. Não há limite para o número de destinatários para os quais você pode enviar documentos. Quando você envia um documento, seu destinatário receberá um email com um link exclusivo. Depois de clicar no link, o destinatário visualiza e assina o documento no navegador, sem downloads e sem plug-ins. O RightSignature&trade; é tão fácil e intuitivo que os novos destinatários podem assinar em segundos, sem necessitar de instruções.",
    "q13": "O que torna o RightSignature™ melhor do que os concorrentes?",
    "a13": "O RightSignature&trade; é conhecido por ser elegante e intuitivo e pela captura de assinaturas reais manuscritas. Com a infraestrutura da Amazon Web Services, nossa segurança e escalabilidade são de classe mundial. O desenvolvimento contínuo e a melhoria mantêm o RightSignature&trade; na vanguarda. Para o profissional autônomo e pequena empresa, o RightSignature&trade; fornece o software de documentos on-line mais simples e eficaz.",
    "q14": "Como o RightSignature™ oferece suporte a uma iniciativa para reduzir o suo de papel?",
    "a14": "Motivados por preocupações ambientais e economias de custos, muitos escritórios estão buscando iniciativas para reduzir o uso de papel. O RightSignature&trade; é um componente-chave na redução do uso de papel, fornecendo um método seguro e eficaz para obter documentos assinados on-line sem cópias impressas.",
    "q15": "O que é uma assinatura eletrônica?",
    "a15": "O pad de assinatura do RightSignature&trade; permite que você use o mouse para assinar um documento, criando uma assinatura eletrônica válida conforme definido pela legislação e diretivas dos Estados Unidos e da União Europeia. Qualquer marca que você faça no bloco de assinaturas é válida, mesmo que não se assemelhe à sua assinatura manuscrita. A justiça interpreta amplamente o conceito de “assinatura” e uma assinatura não precisa, de fato, de ser uma “assinatura” no sentido tradicional da palavra. Por exemplo, um carimbo foi reconhecido como uma assinatura, assim como uma marca feita por um analfabeto, que ilustram o conceito de que qualquer marca voluntária feita por uma parte em um contrato pode ser reconhecida como uma assinatura. ",
    "a15part2": "<p>Os documentos criados online com assinaturas eletrônicas são legalmente válidos e têm o mesmo status legal que os documentos criados em papel e assinados à mão. A tecnologia da RightSignature&trade; está em total conformidade com a Lei Electronic Signatures in Global and National Commerce Act (ESIGN) de 2000 dos Estados Unidos e a Uniform Electronic Transactions Act (UETA), bem como com a Diretriz Europeia (EC/1999/93), todas elas destinadas a incentivar a rápida adoção de assinaturas eletrônicas e diminuição do uso de métodos de papel antiquado.</p><p>A tecnologia do RightSignature&trade; cumpre a definição dos EUA de 'assinatura eletrônica': um som, símbolo ou processo eletrônico, anexado ou logicamente associado a um contrato ou outro registro e criado ou adotado por uma pessoa com a intenção de assinar o registro.</p><p>A tecnologia do RightSignature&trade; também cumpre os requisitos mais rigorosos da UE de 'assinatura eletrônica avançada':</p>",
    "a15part3": "  está exclusivamente ligada ao signatário; é capaz de identificar o signatário; é criada utilizando meios que o signatário pode manter sob o seu controle exclusivo e é vinculada aos dados a que se refere de modo a que qualquer alteração posterior dos dados seja detectável. Referências para leitura posterior: ''Electronic Signatures and Online Contracts', artigo de nolo.com, Diretriz 1999/93/CE do Parlamento Europeu, U.S. Signatures in Global and National Commerce Act (ESIGN), Uniform Electronic Transactions Act (UETA)",
    "a15part4": "Para obter mais informações sobre a validade legal dos documentos assinados com RightSignature&trade;, consulte nossa página Legalidade.",
    "q16": "Como sei que a outra parte no contrato é quem diz ser?",
    "a16": "Verificar a identidade de uma pessoa é um elemento importante de qualquer assinatura, seja eletrônica ou escrita. Uma assinatura eletrônica deve poder identificar um signatário e ser exclusivamente ligada a esse signatário. O RightSignature&trade; usa um processo de autenticação multivariável, que inclui verificação de endereço de email, rastreamento de endereço IP, algoritmo de assinatura biométrica e outras informações. No RightSignature&trade;, a autenticação é significativamente mais forte do que em documentos em papel, onde as partes geralmente têm um assistente, cônjuge ou outra pessoa para assinar em seu nome.",
    "q17": "Como sabe a localização do signatário?",
    "a17": "Cada computador na Internet é identificado por um endereço IP, ou protocolo de internet. Por exemplo, seu endereço IP é: 99.102.245.244. A partir deste endereço, podemos rastrear seu computador até um local físico. Embora não seja 100% preciso, isso fornece uma ferramenta de autenticação adicional para partes que usam RightSignature&trade; para criar seus documentos.",
    "q18": "Um documento assinado pode ser manipulado por uma das partes após a assinatura?",
    "a18": "Uma vez carregado e enviado para assinaturas, o documento é bloqueado e armazenado em um servidor seguro com cópias redundantes. Após a assinatura, o documento final é vinculado a um algoritmo de hash seguro, garantindo que nenhuma parte possa fazer alterações em um documento assinado. A neutralidade da RightSignature&trade; proporciona maior possibilidades de defesa legal contra qualquer tentativa de invalidar um acordo celebrado. Para obter mais informações sobre nossa infraestrutura de dados segura, visite nossa página Segurança.",
    "q19": "As assinaturas digitais são certificadas por uma autoridade terceirizada?",
    "a19": "O RightSignature&trade;, como um serviço de assinatura terceirizado que coordena o fluxo de trabalho entre signatários de documentos, fornece um avanço significativo na capacidade de auditoria e verificação sobre métodos de fax e email. Um benefício de usar RightSignature&trade; é que nosso serviço é um terceiro neutro, muito parecido com um depositário em uma transação imobiliária. Depois que um documento é assinado, o RightSignature& vincula-o a um algoritmo de hash seguro e armazena cópias redundantes usando a infraestrutura da Amazon Web Services. Se um contrato assinado no RightSignature& for contestado em tribunal, seria muito difícil ou impossível para uma parte afirmar que o documento foi adulterado. O registro do processo do RightSignature& e o bloqueio pós-execução fornecem verificação de terceiros para que os contratos sejam robustos e defensáveis.",
    "q20": "Como posso ter certeza de que estou assinando o mesmo documento que a outra parte?",
    "a20": "Cada documento enviado para o RightSignature&trade; é vinculado a um único número de referência de cadeia longa de caracteres, composto por números, letras maiúsculas e minúsculas e outros caracteres. Este número de referência é incluído em todas as comunicações e telas que contêm o documento, garantindo que todas as partes vejam o mesmo documento em todos os momentos.",
    "a20part2": "Não há nenhuma função para alterar o arquivo uma vez que é carregado e enviado para assinar. Se um documento ainda não tiver sido assinado por nenhum destinatário, a parte que o envia poderá apagá-lo e em seguida carregar e enviar um novo documento, que terá um novo número de referência.",
    "q21": "Posso acrescer RightSignature™ ao meu logotipo?",
    "a21": "Com o RightSignature&trade;..."
  };
  _exports.faqModal = faqModal;
});