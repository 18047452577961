define("ember-rs/locales/es/templates/onboarding/signer-onboarding-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboardingOld = void 0;
  var signerOnboardingOld = {
    "title": "¡Firmar un documento con RightSignature&trade; es muy fácil!",
    "completefields": "Complete los campos marcados con una bandera.",
    "submitSignature": "Complete los campos y envíe su firma.",
    "progress": "Vea su avance en la barra de progreso. Cuando haya completado todos los campos obligatorios, haga clic en el botón Enviar para completar la firma.",
    "help": "¿Necesita ayuda?",
    "questions": "Si tiene alguna pregunta o necesita más información, puede obtener ayuda aquí."
  };
  _exports.signerOnboardingOld = signerOnboardingOld;
});