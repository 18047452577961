define("ember-rs/templates/onboarding/welcome-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+4puHbwv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"onboarding-container welcome\"],[12],[2,\"\\n\\n  \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,0],1,\"onboarding/welcome-onboarding-1\",\"nextStep\",[34,1]]],null],[2,\"\\n\\n  \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,0],2,\"onboarding/welcome-onboarding-2\",\"nextStep\",[34,1]]],null],[2,\"\\n\\n\"],[6,[37,3],[[35,2,[\"useDesktopLayout\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,0],3,\"onboarding/welcome-onboarding-3\",\"nextStep\",[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentStep\",\"nextStepText\",\"layoutSizeData\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/welcome-onboarding.hbs"
    }
  });

  _exports.default = _default;
});