define("ember-rs/mixins/viewer-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    layoutSizeData: Ember.inject.service() // // will need these new break points for signing page and merge fields fill out step.
    // changeBreakPointsForNoSideBar: function () {
    //   if (this.get('noSidebar')) {
    //     this.set('layoutSizeData.largeBreakpoint', 1100);
    //     this.set('layoutSizeData.mediumBreakpoint', 900);
    //     this.set('layoutSizeData.smallBreakpoint', 768);
    //   }
    // }.on('init')

  });

  _exports.default = _default;
});