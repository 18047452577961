define("ember-rs/locales/ja/templates/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "share": "共有",
    "status": "状態",
    "overview": "概要",
    "sent": "送信済み",
    "people_involved": "関連ユーザー:",
    "reference_id": "参照 ID: ",
    "manage": "管理",
    "tags": "タグ: "
  };
  _exports.details = details;
});