define("ember-rs/locales/en/templates/document/prepare-signers-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_desktop = void 0;
  var prepare_signers_desktop = {
    "assign_roles": "Assign roles for:",
    "who_sign": "Who needs to sign ",
    "sign_order": "Set signer order",
    "add_another": "Add another signer",
    "add_yourself": "Add yourself",
    "contact_integtration": "Contact Integration",
    "select_integration": "Select an Integration",
    "who_sign_package": "Who needs to sign the document package?"
  };
  _exports.prepare_signers_desktop = prepare_signers_desktop;
});