define("ember-rs/templates/components/verified-email-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "unsSEp8R",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[14,0,\"fa fa-envelope\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"verified-info\"],[12],[2,\"\\n  \"],[10,\"h5\"],[12],[1,[35,0,[\"email\"]]],[13],[2,\"\\n  \"],[10,\"span\"],[12],[1,[30,[36,1],[\"components.verifiedEmailRow.verified\"],null]],[2,\" \"],[1,[30,[36,2],[[35,0,[\"verifiedAt\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"link-to\",[[24,0,\"fa fa-times remove-email\"]],[[\"@route\",\"@model\"],[\"user.settings.delete-email-verification\",[34,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"tool-tip\",[],[[\"@position\"],[\"top\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[\"components.verifiedEmailRow.remove\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"emailVerification\",\"t\",\"moment-from-now\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/verified-email-row.hbs"
    }
  });

  _exports.default = _default;
});