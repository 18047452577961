define("ember-rs/components/signing-component", ["exports", "moment", "ember-rs/utils/font-scaling"], function (_exports, _moment, _fontScaling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COMPONENT_STARTING_X_THRESHOLD = 800;
  var COMPONENT_STARTING_Y_THRESHOLD = 1000;
  var fieldLabels = {
    signatureComponent: {
      long: 'Sign Here',
      short: 'Sign',
      threshold: 100
    },
    textComponent: {
      long: 'Enter Text...',
      short: 'Text',
      threshold: 120
    },
    dateComponent: {
      long: 'Choose Date',
      short: 'Date',
      threshold: 120
    },
    initialsComponent: {
      long: 'Initial Here',
      short: 'Initial',
      threshold: 100
    },
    selectComponent: {
      long: 'Select One',
      short: 'Select',
      threshold: 120
    },
    attachmentComponent: {
      long: 'Upload File',
      short: 'Upload',
      threshold: 150
    },
    paymentComponent: {
      long: 'Enter Credit Card',
      short: 'Credit Card',
      threshold: 180
    }
  };
  var DATE_FORMAT_OPTIONS = {
    'MM/DD/YYYY': 'm/d/Y',
    'DD/MM/YYYY': 'd/m/Y',
    'YYYY/MM/DD': 'Y/m/d',
    'MM-DD-YYYY': 'm-d-Y',
    'DD-MM-YYYY': 'd-m-Y',
    'YYYY-MM-DD': 'Y-m-d',
    'DD.MM.YYYY': 'd.m.Y'
  };

  var _default = Ember.Component.extend({
    valueObjectName: 'mergefieldValue',
    // inherited
    componentValue: Ember.computed('valueObjectName', 'component', function () {
      return this.get("component.".concat(this.valueObjectName));
    }),
    documentViewerData: Ember.inject.service(),
    store: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    classNameBindings: ['groupId', 'componentType', 'isFocused', ':signing-component', 'isValid', 'isRequired', 'hasAppliedSignature', 'component.textAlignClass', 'component.transparent:is-transparent'],
    attributeBindings: ['style', 'data-id'],
    'data-id': Ember.computed.oneWay('component.id'),
    groupId: Ember.computed('component.groupId', function () {
      if (this.get('component.groupId')) {
        return "group-".concat(this.get('component.groupId'));
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('component.multiline')) {
        this.$('textarea').attr('style', "font-size: ".concat(this.get('componentValue.fontSize'), "px;").htmlSafe());
      }
    },
    input: function input() {
      if (this.isTextField && !this.get('component.multiline')) {
        var fontSize = parseInt(document.getElementById(this.elementId).querySelector('.component-content-wrapper input').style.fontSize.replace('px', ''));
        fontSize = fontSize < _fontScaling.MINIMUM_FONT_SIZE ? _fontScaling.MINIMUM_FONT_SIZE : fontSize;
        this.set('componentValue.fontSize', fontSize);
      }

      if (this.get('component.multiline')) {
        var scrollHeight = document.getElementById(this.elementId).querySelector('.component-content-wrapper textarea').scrollHeight;
        var componentHeight = this.componentHeight;
        var ratio = componentHeight / scrollHeight;

        if (scrollHeight > Math.round(componentHeight)) {
          var _fontSize = this.get('componentValue.fontSize') * ratio < _fontScaling.MINIMUM_FONT_SIZE ? _fontScaling.MINIMUM_FONT_SIZE : this.get('componentValue.fontSize') * ratio;

          this.set('textareaStyle', "font-size: ".concat(_fontSize, "px; line-height: ").concat(_fontScaling.LINE_HEIGHT_MULTIPLIER * _fontSize, "px").htmlSafe());
          this.set('componentValue.fontSize', _fontSize);
        } // Reset the font-size if the user clears the input


        if (Ember.isBlank(this.get('componentValue.value'))) {
          this.set('componentValue.fontSize', _fontScaling.DEFAULT_FONT_SIZE);
          this.set('textareaStyle', "font-size: ".concat(_fontScaling.DEFAULT_FONT_SIZE, "px;").htmlSafe());
        }
      }
    },
    componentWidth: Ember.computed('component.width', 'documentViewerData.pageWidth', function () {
      return this.get('component.width') * this.get('documentViewerData.pageWidth');
    }),
    componentHeight: Ember.computed('component.height', 'documentViewerData.pageHeight', function () {
      return this.get('component.height') * this.get('documentViewerData.pageHeight');
    }),
    isFocused: Ember.computed('documentViewerData.componentToFocus', 'component', function () {
      return this.get('component.id') === this.get('documentViewerData.componentToFocus.id');
    }),
    fieldLabel: Ember.computed('componentWidth', 'component.type', function () {
      var labelData = fieldLabels[this.get('component.type')];

      if (this.componentWidth < labelData.threshold) {
        return labelData.short;
      } else {
        return labelData.long;
      }
    }),
    componentIndex: Ember.computed('sortedComponentsToFillIndexMap', 'component.id', function () {
      var arbitraryExtraTabIndexPadding = 20;
      return this.get('sortedComponentsToFillIndexMap').get(this.get('component.id')) + arbitraryExtraTabIndexPadding;
    }),
    componentType: Ember.computed('component.type', function () {
      return this.get('component.type').dasherize();
    }),
    style: Ember.computed('component.x', 'component.y', 'componentWidth', 'component.height', function () {
      var left = this.get('component.x') * this.get('documentViewerData.pageWidth');
      var top = this.get('component.y') * this.get('documentViewerData.pageHeight');
      var width = this.componentWidth;
      var height = this.get('component.height') * this.get('documentViewerData.pageHeight');
      return "left:".concat(left, "px; top:").concat(top, "px; width:").concat(width, "px; height:").concat(height, "px; line-height:").concat(height, "px").htmlSafe();
    }),
    displayCounter: Ember.computed('componentValue.value', function () {
      if (Ember.isPresent(this.get('componentValue.value'))) {
        return this.get('componentValue.value').length > this.maxLength * 0.75;
      }
    }),
    counterStyle: Ember.computed('componentHeight', function () {
      return "top:".concat(this.componentHeight, "px;").htmlSafe();
    }),
    maxLength: Ember.computed('componentValue.fontSize', 'componentHeight', 'componentWidth', 'component.multiline', function () {
      if (this.get('component.multiline')) {
        var maxCharsPerLine = _fontScaling.CHARACTER_CONSTANT * (this.componentWidth / _fontScaling.MINIMUM_FONT_SIZE);
        return Math.round(maxCharsPerLine * (0, _fontScaling.maxRows)(this.componentHeight));
      }
    }),
    inputStyle: Ember.computed('componentWidth', 'component.multiline', 'componentValue.value', function () {
      var fontSize = this.get('component.fontSize') || 12;

      if (!this.get('component.multiline')) {
        var numChars = this.get('componentValue.value.length');
        var width = this.componentWidth - 15 < 0 ? 15 : this.componentWidth - 15;

        if (numChars * (fontSize * 0.6) > width) {
          var widthPerChar = width / numChars;
          fontSize = widthPerChar * (1 / 0.6);

          if (fontSize > 13) {
            fontSize = 13;
          }
        }
      }

      return "font-size: ".concat(fontSize, "px;").htmlSafe();
    }),
    sizingStyle: Ember.computed('componentWidth', 'component.height', function () {
      var width = this.componentWidth;
      var height = this.get('component.height') * this.get('documentViewerData.pageHeight');
      return "width:".concat(width, "px; height:").concat(height, "px;").htmlSafe();
    }),
    initClickEvent: Ember.on('didInsertElement', function () {
      var self = this;
      this.$().on('click', function (e) {
        var allowPropagation = ['attachmentComponent', 'paymentComponent', 'signatureComponent'].includes(self.get('component.type'));

        if (!allowPropagation) {
          e.stopPropagation();
        }

        self.sendAction('componentClicked', self.get('component'));
      });
    }),
    watchValue: Ember.observer('componentValue.value', function () {
      this.sendAction('valueChanged', this.component, this.get('componentValue.value'));
      this.sendAction('componentUpdated');
    }),
    defaultTextPlaceholder: Ember.computed('component.type', 'component.name', function () {
      if (Ember.isPresent(this.get('component.name'))) {
        return "".concat(this.get('component.name'), "...");
      } else {
        return 'Enter text...';
      }
    }),
    isValid: Ember.computed.alias('componentValue.isValid'),
    isRequired: Ember.computed('component.isRequired', function () {
      return this.get('component.isRequired');
    }),
    isTextField: Ember.computed('component.type', function () {
      return ['textComponent', 'initialsComponent'].includes(this.get('component.type')) && !this.get('component.multiline');
    }),
    isTextArea: Ember.computed('component.type', function () {
      return ['textComponent', 'initialsComponent'].includes(this.get('component.type')) && this.get('component.multiline');
    }),
    isSelectBox: Ember.computed('component.type', function () {
      return 'selectComponent' === this.get('component.type');
    }),
    isCheckBox: Ember.computed('component.type', function () {
      return ['checkComponent', 'checkGroupComponent'].includes(this.get('component.type'));
    }),
    isDate: Ember.computed('component.type', function () {
      return 'dateComponent' === this.get('component.type');
    }),
    isDateSigned: Ember.computed('component.type', function () {
      return 'dateComponent' === this.get('component.type') && this.get('component.isDateSigned');
    }),
    dateSigned: Ember.computed('component.data', function () {
      var compValue = (0, _moment.default)().format(this.get('component.data.dateFormat')); // just for front end

      this.sendAction('valueChanged', this.component, (0, _moment.default)().format('ddd MMM DD YYYY')); // match the flatpickr format

      return compValue;
    }),
    isAttachment: Ember.computed('component.type', function () {
      return 'attachmentComponent' === this.get('component.type');
    }),
    appendTo: Ember.computed('elementId', function () {
      return document.getElementById(this.elementId);
    }),
    getDateFormat: Ember.computed('component.data', function () {
      var dateFormat = this.get('component.data.dateFormat');

      if (DATE_FORMAT_OPTIONS[dateFormat] === undefined) {
        return 'm/d/Y';
      }

      return DATE_FORMAT_OPTIONS[dateFormat];
    }),
    getDatePlaceholder: Ember.computed('component.data', function () {
      return this.get('component.data.dateFormat');
    }),
    getDefaultDate: Ember.computed('componentValue.value', function () {
      var savedDate = this.get('componentValue.value');
      var dateFormat = this.get('component.data.dateFormat');

      if (savedDate) {
        return (0, _moment.default)(new Date(savedDate)).format(dateFormat);
      }

      return null;
    }),
    actions: {
      signatureCreated: function signatureCreated() {
        this.sendAction.apply(this, ['signatureCreated'].concat(Array.prototype.slice.call(arguments)));
      },
      //OnOpen for flatpickr
      onOpen: function onOpen() {
        if (!this.get('layoutSizeData.useMobileLayout')) {
          var left = this.get('component.x') * this.get('documentViewerData.pageWidth');
          var bottom = this.get('component.y') * this.get('documentViewerData.pageHeight');

          if (left > COMPONENT_STARTING_X_THRESHOLD) {
            var flatpickrRef = this.flatpickrRef;
            flatpickrRef.calendarContainer.classList.add("flatPickrLeftAdjust");
          }

          if (bottom > COMPONENT_STARTING_Y_THRESHOLD) {
            var _flatpickrRef = this.flatpickrRef;

            _flatpickrRef.calendarContainer.classList.add("flatPickrBottomAdjust");
          }
        }
      },
      // flatpickr event hook. We need this because the datepicker returns an
      // array of date objects, and so, we cannot use the mut template helper
      onChange: function onChange(selectedDates) {
        this.sendAction('valueChanged', this.component, selectedDates[0]);
      },
      // flatpickr event hook. If a user types in a date and clicks away (instead
      // of hitting enter), save the date. If the user clears the date and clicks
      // away, set value to ''
      onClose: function onClose() {
        var flatpickrRef = this.flatpickrRef;

        if (Ember.isPresent(flatpickrRef.input.value)) {
          flatpickrRef.setDate(flatpickrRef.input.value, true);
        } else {
          this.sendAction('valueChanged', this.component, '');
        }
      }
    }
  });

  _exports.default = _default;
});