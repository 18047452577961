define("ember-rs/locales/nl/templates/components/account-user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "basic": "Basisgegevens",
    "firstName": "Voornaam:",
    "lastName": "Achternaam:",
    "required": "*verplicht",
    "email": "E-mail:",
    "emailTip": "*U moet een geldig e-mailadres opgeven.",
    "password": "Wachtwoord:",
    "hiddenForSecurity": "verborgen ter beveiliging",
    "reset": "Opnieuw instellen",
    "emailInfo": "Aanmeldingsinformatie e-mailen",
    "oldPass": "Oud wachtwoord:",
    "newPass": "Nieuw wachtwoord:",
    "passReq": "*Wachtwoorden moeten minimaal 8 tekens bevatten, met minstens 1 cijfer, 1 hoofdletter en 1 kleine letter.",
    "permissions": "Machtigingen",
    "manageUsers": "Gebruikers beheren",
    "manageDelegateUsers": "Kan delegeren aan gebruikers",
    "manageViewBills": "Kan facturen inzien",
    "customizeBranding": "Huisstijl account aanpassen",
    "manageTemplates": "Sjablonen beheren",
    "viewDocuments": "Alle documenten weergeven",
    "removeUser": "Gebruiker verwijderen",
    "reset2fa": "Verificatie in twee stappen opnieuw instellen"
  };
  _exports.edit = edit;
});