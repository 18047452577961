define("ember-rs/locales/ja/mixins/distribution-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.distributionMethod = void 0;
  var distributionMethod = {
    "viaEmail": "メールで招待を送信",
    "manual": "手動でリンクを配信",
    "email": "メール アドレス",
    "sms": "SMS",
    "none": "なし"
  };
  _exports.distributionMethod = distributionMethod;
});