define("ember-rs/locales/pt-br/templates/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "title": "Atualizando...",
    "desc": "Isso pode levar alguns segundos. Não atualize sua página ou clique no botão Fechar ou Voltar do seu navegador."
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});