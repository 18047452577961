define("ember-rs/models/signer-payment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    signerId: (0, _model.attr)('string'),
    paymentLast4: (0, _model.attr)('string'),
    paymentAmount: (0, _model.attr)('number'),
    paymentType: (0, _model.attr)('string'),
    paymentSharedToken: (0, _model.attr)('string'),
    cardStr: (0, _model.attr)('string'),
    toAuditStr: (0, _model.attr)('string'),
    chargeAfterExecuted: (0, _model.attr)('boolean'),
    chargeId: (0, _model.attr)('string'),
    chargedAt: (0, _model.attr)('moment-date'),
    chargeSuccessful: (0, _model.attr)('boolean'),
    chargeRefunded: (0, _model.attr)('boolean'),
    paymentAmountDollarsFormatted: (0, _model.attr)('string'),
    paymentClientCustomerToken: (0, _model.attr)('string'),
    chargeFailureCode: (0, _model.attr)('string'),
    chargeFailureMessage: (0, _model.attr)('string'),
    successfullyCreatedCustomer: (0, _model.attr)('boolean'),
    failedToCreateCustomer: (0, _model.attr)('boolean'),
    // Components do not belong to to a document so this is virtual vs an association
    componentId: (0, _model.attr)('string'),
    retryCharge: function retryCharge(documentId) {
      var _this = this;

      var modelName = this.constructor.modelName;
      var adapter = this.store.adapterFor(modelName);
      return adapter.retryCharge(documentId, this.id).then(function (data) {
        _this.store.pushPayload(data);

        return _this.store.peekRecord(modelName, _this.id);
      });
    }
  });

  _exports.default = _default;
});