define("ember-rs/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nr8r8sjN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.noUser.unableLogin\"],null]],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.noUser.unableLoginTip\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/no-user.hbs"
    }
  });

  _exports.default = _default;
});