define("ember-rs/models/signature", ["exports", "jquery", "@ember-data/model", "ember-rs/config/environment"], function (_exports, _jquery, _model, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    imageUrl: (0, _model.attr)('string'),
    jsonData: (0, _model.attr)('string'),
    imageData: (0, _model.attr)('string'),
    imageDataRaw: (0, _model.attr)('string'),
    signatureType: (0, _model.attr)('string'),
    signatureText: (0, _model.attr)('string'),
    s3Guid: (0, _model.attr)('string'),
    isDefault: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('moment-date'),
    updatedAt: (0, _model.attr)('moment-date'),
    signerId: (0, _model.attr)('string'),
    user: (0, _model.belongsTo)('user', {
      async: false
    }),
    color: (0, _model.attr)('string'),
    userId: (0, _model.attr)('string'),
    makeDefault: function makeDefault() {
      if (this.isDefault) {
        return _jquery.default.Deferred().reject('This signature is already the default.');
      }

      var self = this;
      return _jquery.default.ajax({
        url: "".concat(_environment.default.api.endpoint, "/signatures/").concat(this.id, "/make_default.json"),
        type: 'PUT'
      }).then(function (response) {
        self.store.pushPayload(response);
      });
    }
  });

  _exports.default = _default;
});