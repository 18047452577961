define("ember-rs/mixins/distribution-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    distributionMethods: [{
      label: "mixins.distributionMethod.viaEmail",
      value: 'email'
    }, {
      label: "mixins.distributionMethod.manual",
      value: 'manual'
    }],
    identityMethods: [{
      label: "mixins.distributionMethod.email",
      value: 'email'
    }, {
      label: "mixins.distributionMethod.sms",
      value: 'sms'
    }, {
      label: "mixins.distributionMethod.none",
      value: 'none'
    }]
  });

  _exports.default = _default;
});