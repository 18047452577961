define("ember-rs/routes/start-document", ["exports", "ember-rs/utils/feature-flags", "ember-rs/config/environment", "jquery"], function (_exports, featureFlags, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    overlayerData: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    featureFlagService: Ember.inject.service(),
    titleToken: 'Start Document',
    queryParams: {
      sharefile_session: {}
    },
    beforeModel: function beforeModel(transition) {
      var packageEnabled = true;
      var workflowsEnabled = Ember.get(this, 'featureFlagService').getFeatureFlag(featureFlags.ENABLE_WORKFLOWS);
      this.controllerFor('start-document').setProperties({
        canSendPackage: packageEnabled,
        canInitiateWorkflows: workflowsEnabled
      }); // resets overlayer selected data

      this.overlayerData.resetSelectedData();

      if (!transition.to.queryParams.sharefile_session && Ember.get(this, 'session.data.sharefileRedirect')) {
        this.session.set('data.sharefileRedirect', null);
      }
    },
    setPropertyAndNavigate: function setPropertyAndNavigate(documentTransaction) {
      Ember.setProperties(this.get('controller'), {
        saving: false,
        workflow: ''
      });
      this.transitionTo('document-transaction.choose_documents', documentTransaction);
    },
    startTransaction: function startTransaction(options) {
      var _this = this;

      this.store.createRecord('document-transaction', options).save().then(function (documentTransaction) {
        if (options.workflow === 'packager') {
          _this.store.createRecord('merge-transaction', {
            documentTransaction: documentTransaction
          }).save().then(function () {
            return _this.setPropertyAndNavigate(documentTransaction);
          }).catch(function () {
            return _this.get('controller').set('saving', false);
          });
        } else {
          _this.setPropertyAndNavigate(documentTransaction);
        }
      }).catch(function () {
        _this.controller.set('saving', false);
      });
    },
    actions: {
      startSignatureRequest: function startSignatureRequest() {
        Ember.setProperties(this.controller, {
          saving: true,
          workflow: 'signatureRequest'
        });
        this.startTransaction({
          workflow: 'signature_request'
        });
      },
      startSelfSign: function startSelfSign() {
        Ember.setProperties(this.controller, {
          saving: true,
          workflow: 'selfSign'
        });
        this.startTransaction({
          workflow: 'self_sign'
        });
      },
      startEmbed: function startEmbed() {
        if (!Ember.get(this, 'currentUser.account.onlineForms')) {
          // TODO: Add upgrade modal
          this.flashMessages.warning(this.i18n.t('routes.startDocument.embeddedTemplate'));
          return;
        }
      },
      startInPerson: function startInPerson() {
        if (!Ember.get(this, 'currentUser.account.inPerson')) {
          // TODO: Add upgrade modal
          this.flashMessages.warning(this.i18n.t('routes.startDocument.inPerson'));
          return;
        }

        Ember.setProperties(this.controller, {
          saving: true,
          workflow: 'inPerson'
        });
        this.startTransaction({
          workflow: 'in_person',
          account: Ember.get(this, 'currentUser.account')
        });
      },
      startBulkSend: function startBulkSend() {
        Ember.setProperties(this.controller, {
          saving: true,
          workflow: 'bulkSend'
        });
        this.startTransaction({
          workflow: 'bulk_send'
        });
      },
      startPackage: function startPackage() {
        Ember.setProperties(this.get('controller'), {
          saving: true,
          workflow: 'packager'
        });
        this.startTransaction({
          workflow: 'packager'
        });
      },
      collectFeedback: function collectFeedback() {
        var _this2 = this;

        Ember.setProperties(this.get('controller'), {
          fetchingFeedbackLink: true
        });

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/workflows"),
          type: 'GET',
          data: {
            workflow_type: 'feedback-workflow'
          }
        }).then(function (response) {
          window.open(response);
        }).catch(function (error) {
          _this2.flashMessages.warning(_this2.i18n.t('components.document.signerRow.resendingFailed', {
            error: error
          }));
        }).then(function () {
          //this block behaves like finally()
          Ember.setProperties(_this2.get('controller'), {
            fetchingFeedbackLink: false
          });
        });
      },
      getApproval: function getApproval() {
        var _this3 = this;

        Ember.setProperties(this.get('controller'), {
          fetchingApprovalLink: true
        });

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/workflows"),
          type: 'GET',
          data: {
            workflow_type: 'approval-workflow'
          }
        }).then(function (response) {
          window.open(response);
        }).catch(function (error) {
          _this3.flashMessages.warning(_this3.i18n.t('components.document.signerRow.resendingFailed', {
            error: error
          }));
        }).then(function () {
          //this block behaves like finally()
          Ember.setProperties(_this3.get('controller'), {
            fetchingApprovalLink: false
          });
        });
      }
    }
  });

  _exports.default = _default;
});