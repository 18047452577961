define("ember-rs/locales/nl/controllers/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "standardInfo": "Het door u geselecteerde abonnement ondersteunt slechts één gebruiker. Als u meer gebruikers in uw account nodig hebt, kiest u het abonnement Advanced.",
    "migratedInfo": "Kies ten minste {{{minimumSeats}}} gebruikers omdat uw proefaccount al {{{minimumSeats}}} seats gebruikt. Neem contact op met Support als u meer hulp nodig hebt bij het upgraden",
    "planInfo": "Het door u geselecteerde abonnement omvat 3 gebruikers. Kies hieronder meer indien nodig.",
    "validUsers": "Voer een geldig aantal gebruikers in"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});