define("ember-rs/locales/es/mixins/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.settings = void 0;
  var settings = {
    "updateSuccessful": "Parámetro actualizado correctamente",
    "updateError": "Error al actualizar el parámetro"
  };
  _exports.settings = settings;
});