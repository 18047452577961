define("ember-rs/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iygVYHsw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[12],[1,[30,[36,0],[\"onboarding.entitlementOptionsAdmin.title\"],null]],[13],[2,\"\\n\"],[6,[37,3],[[35,2,[\"account\",\"accountStatSet\",\"hasSeats\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"onboarding.entitlementOptionsAdmin.hasSeats\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"subtle\"],[4,[38,1],[[32,0],\"notNow\"],null],[12],[1,[30,[36,0],[\"onboarding.entitlementOptionsAdmin.notNow\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[4,[38,1],[[32,0],\"entitleMe\"],null],[12],[1,[30,[36,0],[\"onboarding.entitlementOptionsAdmin.entitleMe\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"onboarding.entitlementOptionsAdmin.noSeats\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"subtle\"],[4,[38,1],[[32,0],\"notNow\"],null],[12],[1,[30,[36,0],[\"onboarding.entitlementOptionsAdmin.proceed\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"currentUser\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/-entitlement-options-admin.hbs"
    }
  });

  _exports.default = _default;
});