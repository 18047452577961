define("ember-rs/locales/es/templates/components/payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentForm = void 0;
  var paymentForm = {
    "name": "Nombre",
    "firstName": "Nombre...",
    "lastName": "Apellido...",
    "address1": "Dirección 1...",
    "address2": "Dirección 2...",
    "city": "Ciudad",
    "state": "Estado/Provincia",
    "zip": "Código postal",
    "country": "País",
    "address": "Dirección",
    "vatId": "ID de IVA",
    "redirect": "Redirigiendo...",
    "upgrade": "Actualizar",
    "payUpgrade": "Pagar y ampliar cuenta",
    "oops": "Se ha producido un error",
    "ok": "Aceptar"
  };
  _exports.paymentForm = paymentForm;
});