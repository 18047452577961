define("ember-rs/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showSigningQuestions: true,
    showGeneralQuestions: false,
    triggerGetFaqHeight: Ember.on('didInsertElement', function () {
      this.sendAction('getFaqHeight');
    }),
    actions: {
      toggleFaqModal: function toggleFaqModal() {
        this.toggleProperty('showFaqModal');
      },
      setAnswer: function setAnswer(answer) {
        if (Ember.isEqual(this.showAnswerFor, answer)) {
          this.set('showAnswerFor', null);
        } else {
          this.set('showAnswerFor', answer);
        }

        this.triggerGetFaqHeight();
      }
    }
  });

  _exports.default = _default;
});