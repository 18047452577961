define("ember-rs/locales/it/routes/email-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailVerification = void 0;
  var emailVerification = {
    "verificationSuccess": "E-mail verificata correttamente.",
    "invalidVerification": "La verifica e-mail non è valida."
  };
  _exports.emailVerification = emailVerification;
});