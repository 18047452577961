define("ember-rs/locales/it/templates/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = void 0;
  var verify = {
    "advisory": "Il mittente di questo documento ha abilitato la funzionalità di autenticazione basata sulla conoscenza di RightSignature per verificare l'identità dell'utente. RightSignature utilizza i servizi di terze parti di BlockScore per convalidare e verificare le informazioni personali fornite. L'utilizzo delle informazioni personali fornite è limitato alle prestazioni della convalida e della verifica dell'identità con i record di dati proprietari di BlockScore. Il rendimento della convalida e della verifica dell'identità non avrà alcun effetto sul rapporto di credito dell'utente, sul suo punteggio di credito o sulle informazioni trasferite ai prestatori. IRS, Blockscore e RightSignature non avranno la possibilità di visualizzare o accedere al rapporto di credito o alle informazioni fiscali dell'utente come parte di questo processo.",
    "step2": "Verifica dell'identità - Passaggio 2 di 2",
    "answer": "Rispondere alle domande di verifica dell'identità riportate di seguito selezionando la casella di controllo accanto alla risposta appropriata.",
    "noMatch": "Siamo spiacenti, le risposte fornite non corrispondono ai nostri record per questa identità.",
    "youHave": "Si hanno a disposizione ",
    "remaining": "tentativi rimanenti di accesso al documento.",
    "complete": "Completa verifica",
    "return": "Ritorna al modulo",
    "advisory2": "Avviso di verifica dell'identità",
    "unableToAuthenticate": "Impossibile eseguire correttamente l'autenticazione. Contattare <b>{{{sender_email}}}</b> per accedere al documento tramite un meccanismo di autenticazione alternativo."
  };
  _exports.verify = verify;
});