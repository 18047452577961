define("ember-rs/components/reminder-frequency-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-wrapper', 'reminder-frequency-select'],
    options: Ember.computed(function () {
      return [{
        text: this.i18n.t('components.reminderFrequency.noAuto'),
        value: "No auto reminders"
      }, {
        text: this.i18n.t('components.reminderFrequency.weekly'),
        value: "Weekly"
      }, {
        text: this.i18n.t('components.reminderFrequency.daily'),
        value: "Daily"
      }];
    }),
    attr: 'reminderFrequency',
    currentSelection: Ember.computed.oneWay('selection'),
    willDestroyElement: function willDestroyElement() {
      this.set('currentSelection', null);
    },
    actions: {
      updateSetting: function updateSetting(value) {
        this.sendAction('updateSetting', value, this);
      }
    }
  });

  _exports.default = _default;
});