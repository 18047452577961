define("ember-rs/locales/pt-br/mixins/integration-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationAuth = void 0;
  var integrationAuth = {
    "unableAuthorize": "Não é possível autorizar a integração!",
    "notComplete": "Não foi possível concluir a autorização"
  };
  _exports.integrationAuth = integrationAuth;
});