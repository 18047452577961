define("ember-rs/locales/ja/mixins/integration-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocuments = void 0;
  var integrationDocuments = {
    "enable_sending": "{{{provider}}} からの送信が有効になりました",
    "enable_archiving": "{{{provider}}} のアーカイブが有効になりました",
    "enable_contacts": "{{{provider}}} の連絡先が有効になりました",
    "enable_payments": "{{{provider}}} の支払いが有効になりました",
    "disable_sending": "{{{provider}}} からの送信が無効になりました",
    "disable_archiving": "{{{provider}}} のアーカイブが無効になりました",
    "disable_contacts": "{{{provider}}} の連絡先が無効になりました",
    "disable_payments": "{{{provider}}} の支払いが無効になりました",
    "errorToggle": "エラー。{{{provider}}} の {{{service}}} を変更できませんでした。",
    "errorRetrieve": "エラー。ドキュメントを取得できませんでした。",
    "errorProviderRetrieve": "エラー。{{{provider}}} のドキュメントを取得できませんでした。"
  };
  _exports.integrationDocuments = integrationDocuments;
});