define("ember-rs/locales/pt-br/routes/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTransaction = void 0;
  var documentTransaction = {
    "disabledPaymentIntegration": "Você deve ativar uma integração de pagamento antes de enviar os seguintes documentos: {{names}}",
    "disabledPasscode": "Você deve definir um código secreto/PIN para enviar o(s) seguinte(s) documento(s): {{names}}",
    "successfulDocCreation": "Documentos criados com sucesso."
  };
  _exports.documentTransaction = documentTransaction;
});