define("ember-rs/locales/nl/templates/onboarding/welcome-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding3 = void 0;
  var welcomeOnboarding3 = {
    "start": "Hier begint u om een document voor ondertekening te verzenden."
  };
  _exports.welcomeOnboarding3 = welcomeOnboarding3;
});