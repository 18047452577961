define("ember-rs/locales/fr/routes/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTransaction = void 0;
  var documentTransaction = {
    "disabledPaymentIntegration": "Vous devez activer l'intégration du paiement avant d'envoyer les documents suivants : {{names}}",
    "disabledPasscode": "Vous devez définir un code PIN ou code secret pour envoyer le(s) document(s) suivant(s) : {{names}}",
    "successfulDocCreation": "Documents créés avec succès."
  };
  _exports.documentTransaction = documentTransaction;
});