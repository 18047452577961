define("ember-rs/locales/pt-br/controllers/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "sender": "Remetente"
  };
  _exports.addRoles = addRoles;
});