define("ember-rs/locales/es/templates/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "addRoles": "Agregar roles",
    "whatRoles": "¿Qué roles necesita esta plantilla?",
    "signOrder": "Establecer orden de firmantes",
    "uniqueName": "Debe asignar nombres de roles únicos.",
    "addRole": "Agregar rol",
    "sendRole": "Agregar rol de remitente",
    "next": "Siguiente: Colocar campos"
  };
  _exports.addRoles = addRoles;
});