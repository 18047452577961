define("ember-rs/locales/ja/controllers/signer/sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    "validPhone": "有効な電話番号を入力してください",
    "passcode": "無効なパスコードです。再試行してください。"
  };
  _exports.sign = sign;
});