define("ember-rs/locales/en/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitlementOptionsAdmin = void 0;
  var entitlementOptionsAdmin = {
    "title": "Welcome to RightSignature!",
    "hasSeats": "You currently have Admin privileges for this account. That means you can grant PRO access and features to yourself and/or other users. Click the button below to grant this access to yourself.",
    "notNow": "Maybe Later",
    "entitleMe": "Get PRO Access",
    "noSeats": "All of the seats in this account are currently used up! You can either remove somebody, get more seats by <a href='https://support.citrix.com/cscase'>contacting Support</a> or by calling <a href='tel:+18009214250'>1-800-921-4250</a>, or you can just proceed without some features.",
    "proceed": "Proceed"
  };
  _exports.entitlementOptionsAdmin = entitlementOptionsAdmin;
});