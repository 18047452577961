define("ember-rs/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YwOZt9k7",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"isAuthenticated\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"user-nav-links\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"session\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/index.hbs"
    }
  });

  _exports.default = _default;
});