define("ember-rs/locales/pt-br/templates/email-unverified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailUnverified = void 0;
  var emailUnverified = {
    "verify": "Seu endereço de email não foi confirmado. Verifique seu email e clique no link de confirmação para confirmar sua conta."
  };
  _exports.emailUnverified = emailUnverified;
});