define("ember-rs/templates/components/import-overlay-role-map-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Z9NoAn7",
    "block": "{\"symbols\":[\"xs\",\"option\"],\"statements\":[[1,[30,[36,1],[\"components.importOverlayRoleMapSelect.assignComponents\"],[[\"signerName\",\"name\"],[[35,0,[\"signerName\"]],[35,0,[\"name\"]]]]]],[2,\" \\n\"],[8,\"x-select\",[],[[\"@value\",\"@on-change\"],[[34,2],[30,[36,3],[[32,0],\"selectMapping\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"option\"]],[],[[\"@value\"],[[32,2,[\"role\"]]]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"label\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"importableRoleObject\",\"t\",\"defaultRoleIfMatches\",\"action\",\"roleMappingOptions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/import-overlay-role-map-select.hbs"
    }
  });

  _exports.default = _default;
});