define("ember-rs/locales/zh-cn/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbarMenuitem = void 0;
  var componentToolbarMenuitem = {
    "signatureComponent": "签名字段",
    "textComponent": "文本字段",
    "dateComponent": "日期字段",
    "checkComponent": "复选框",
    "checkGroupComponent": "复选框组",
    "initialsComponent": "首字母缩写字段",
    "selectComponent": "选择框",
    "signatureAnnotationComponent": "添加签名",
    "signatureAnnotationName": "签名批注",
    "textAnnotationComponent": "添加文本",
    "textAnnotationName": "文本批注",
    "checkmarkAnnotationComponent": "添加复选标记",
    "checkmarkAnnotationName": "复选标记批注",
    "attachmentComponent": "附件",
    "attachmentComponentName": "请求的附件",
    "paymentComponent": "收款",
    "signatureComponenttip": "将此字段放在签名者需要提供其签名的位置。",
    "textComponenttip": "将此字段放在签名者需要输入文本信息(例如，键入其姓名或地址)的位置。",
    "dateComponenttip": "在需要添加日期的位置使用此字段。对文档进行签名时，系统将为签名者提供日期选取器，以便轻松地选择日期。日期将显示为“YYYY-MM-DD”。",
    "checkComponenttip": "在签名者需要查看选项(例如，条款“我同意...”)的位置使用此字段。如果您的文档中包含一系列选项，但签名者被限制为只能选择一个或至少选择一个选项，请使用“复选框组”选项。",
    "checkGroupComponenttip": "如果具有一系列选项，但签名者被限制为只能选择一个或至少选择一个选项，请使用复选框组。",
    "initialsComponenttip": "将此字段放在签名者需要提供其姓名首字母缩写的位置。",
    "selectComponenttip": "将选择框放在在签名者需要从选项列表中进行选择的位置。",
    "signatureAnnotationComponenttip": "将您自己的签名添加到文档中。",
    "textAnnotationComponenttip": "将您自己的文本添加到文档中以预填充或进行批注。",
    "checkmarkAnnotationComponenttip": "选中文档中的某个框。",
    "attachmentComponenttip": "将此框放在您希望签名者将图像或文件附加到您的文档中的位置。",
    "paymentComponenttip": "使用此选项可将按钮添加到您的文档中，以便签名者可以输入信用卡号以进行付款。注意: 使用此选项之前，您必须对您的帐户启用付款集成。(访问“帐户”，然后选择“集成”)。",
    "unknownField": "未知字段"
  };
  _exports.componentToolbarMenuitem = componentToolbarMenuitem;
});