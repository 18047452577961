define("ember-rs/models/archived-document", ["exports", "@ember-data/model", "ember-rs/mixins/taggable", "ember-api-actions"], function (_exports, _model, _taggable, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_taggable.default, {
    templateGuid: (0, _model.attr)('string'),
    version: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    type: (0, _model.attr)('type'),
    model: (0, _model.attr)('type'),
    // from ElasticSearch result
    createdAt: (0, _model.attr)('moment-date'),
    updatedAt: (0, _model.attr)('moment-date'),
    documentState: (0, _model.attr)('string'),
    currentReusableTemplate: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    published: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    name: (0, _model.attr)('string'),
    senderName: (0, _model.attr)('string'),
    filename: (0, _model.attr)('string'),
    expiresIn: (0, _model.attr)('number'),
    signerSequencing: (0, _model.attr)('boolean'),
    personalizedMessages: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    distributionMethod: (0, _model.attr)('string', {
      defaultValue: 'email'
    }),
    identityMethod: (0, _model.attr)('string', {
      defaultValue: 'email'
    }),
    canManage: (0, _model.attr)('boolean'),
    pageImages: (0, _model.attr)('array'),
    audits: (0, _model.attr)('array'),
    documentSentAt: (0, _model.attr)('moment-date'),
    documentExpiresAt: (0, _model.attr)('moment-date'),
    documentExecutedAt: (0, _model.attr)('moment-date'),
    signedPdfUrl: (0, _model.attr)('string'),
    originalPdfUrl: (0, _model.attr)('string'),
    imageWidth: (0, _model.attr)('number'),
    imageHeight: (0, _model.attr)('number'),
    fileAttachments: (0, _model.attr)('array'),
    ccParties: (0, _model.attr)('array'),
    sharedWith: (0, _model.attr)('array'),
    userId: (0, _model.attr)('string'),
    // just used for document but loaded for back in serializer
    fromReusableTemplate: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    tags: (0, _model.attr)('object'),
    dashboardPage: (0, _model.attr)('number'),
    signers: (0, _model.attr)('array'),
    components: (0, _model.attr)('object'),
    mergefieldValues: (0, _model.attr)('object'),
    ////////////////////////  RELATIONSHIPS  ////////////////////////
    account: (0, _model.belongsTo)('account', {
      async: true
    }),
    ////////////////////////  MEMBER ACTIONS  ////////////////////////
    updateSharedWith: (0, _emberApiActions.memberAction)({
      type: 'PUT',
      path: 'update_shared_with'
    }),
    ////////////////////////  PROPERTIES  ////////////////////////
    hasMultipleSigners: Ember.computed.gt('signers.length', 1),
    uploadReady: Ember.computed.equal('documentState', 'ready'),
    hasMergeFields: Ember.computed('components.@each.isMergeField', function () {
      return this.components.filterBy('isMergeField', true).get('length') > 0;
    }),
    currentUserIsSender: Ember.computed('currentUser.model.id', 'userId', function () {
      return this.get('currentUser.model.id') === this.userId;
    })
  });

  _exports.default = _default;
});