define("ember-rs/locales/it/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "notAllowed": "Azione non consentita",
    "noPro": "Al momento non si dispone dell'accesso PRO a questo account. È possibile firmare e visualizzare i documenti, ma le funzionalità PRO non sono disponibili. Contattare un amministratore di questo account per ottenere l'accesso PRO, in modo da poter:",
    "send": "Inviare documenti per la firma",
    "create": "Creare e utilizzare modelli",
    "customize": "Personalizza il branding",
    "enable": "Attivare integrazioni di terze parti",
    "reports": "Visualizzare report",
    "login": "Accedere a RightSignature"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});