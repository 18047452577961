define("ember-rs/locales/zh-cn/templates/template/search-header-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchHeaderMobile = void 0;
  var searchHeaderMobile = {
    "searchTemplates": "搜索模板..."
  };
  _exports.searchHeaderMobile = searchHeaderMobile;
});