define("ember-rs/locales/es/templates/unauthorized/not-your-turn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notYourTurn = void 0;
  var notYourTurn = {
    "heading": "Signer sequencing enabled",
    "line1": "The sender has revised the document and has enabled Signer sequencing.",
    "line2": "You will be notified when it's your time to sign. Reach out to the sender for more information."
  };
  _exports.notYourTurn = notYourTurn;
});