define("ember-rs/locales/es/templates/email-unverified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailUnverified = void 0;
  var emailUnverified = {
    "verify": "Su dirección de correo electrónico no ha sido verificada. Verifique su correo electrónico y haga clic en el enlace de verificación para confirmar su cuenta."
  };
  _exports.emailUnverified = emailUnverified;
});