define("ember-rs/models/bill", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    url: (0, _model.attr)('string'),
    memo: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    statusForDisplay: (0, _model.attr)('string'),
    createdDate: (0, _model.attr)('moment-date'),
    rs3: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});