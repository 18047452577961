define("ember-rs/locales/de/templates/components/component-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbar = void 0;
  var componentToolbar = {
    "useTools": "Verwenden Sie diese Tools für Felder, die die Unterzeichner ausfüllen müssen.",
    "showLess": "Weniger anzeigen",
    "showMore": "Mehr anzeigen",
    "annotate": "Anmerkung:",
    "annotateTip": "Verwenden Sie diese Tools, um das Dokument mit Ihrer eigenen Unterschrift zu signieren und Text oder Häkchen hinzuzufügen.",
    "request": "Anfordern:",
    "dataFields": "Datenfelder",
    "sourceFetchFailed": "Quelleninformationen konnten nicht abgerufen werden.",
    "schemaFetchFailed": "Failed to retrieve source entity schema."
  };
  _exports.componentToolbar = componentToolbar;
});