define("ember-rs/templates/components/payment-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fv5SSdNm",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[24,3,\"payment-button\"],[24,4,\"button\"],[4,[38,2],[[32,0],\"openCardCollection\"],null],[4,[38,2],[[32,0],\"openCardCollection\"],[[\"on\"],[\"touchEnd\"]]],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"pay-label\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"pay-label\"],[12],[10,\"span\"],[12],[10,\"i\"],[14,0,\"fa fa-credit-card\"],[12],[13],[2,\" \"],[1,[34,0]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fieldLabel\",\"lastFour\",\"action\",\"signerPayment\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/payment-component.hbs"
    }
  });

  _exports.default = _default;
});