define("ember-rs/locales/es/templates/components/signer/signer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerInfo = void 0;
  var signerInfo = {
    "toSign": "Para firmar este documento, introduzca su nombre y correo electrónico:",
    "name": "Nombre...",
    "proceed": "Proceda al documento"
  };
  _exports.signerInfo = signerInfo;
});