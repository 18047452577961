define("ember-rs/locales/en/templates/document/prepare-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_document = void 0;
  var prepare_document = {
    "prepare": "Prepare ",
    "prepare_package": "Prepare Document Package",
    "merge_field_message": "This document contains merge fields for you to complete prior to sending.",
    "merge_field_title": "Fill out merge fields",
    "missing_merge_fields": "All required mergefields must be completed."
  };
  _exports.prepare_document = prepare_document;
});