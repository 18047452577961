define("ember-rs/locales/nl/templates/document-transaction/review-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_mobile = void 0;
  var review_mobile = {
    "review_details": "Controleer details voor:",
    "reusable_template": "Herbruikbare sjabloon",
    "distribution_method": "Distributiemethode:",
    "identity_method": "Identiteitsmethode:",
    "save_doc": "Document opslaan",
    "send_doc": "Document verzenden"
  };
  _exports.review_mobile = review_mobile;
});