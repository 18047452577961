define("ember-rs/locales/pt-br/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationEditor = void 0;
  var documentExpirationEditor = {
    "theDocExpired": "O documento expirou em",
    "reactivated": "Selecione quando você deseja que ele expire e ele será reativado.",
    "theDocExpires": "Atualmente, o documento expira em",
    "newExpiration": "Selecione quando você deseja que ele expire.",
    "updateSuccess": "Data de expiração atualizada com sucesso."
  };
  _exports.documentExpirationEditor = documentExpirationEditor;
});