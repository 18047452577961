define("ember-rs/locales/en/templates/unauthorized/redirect-to-rs3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectToRs3 = void 0;
  var redirectToRs3 = {
    "wrongVersion": "Wrong RightSignature Version",
    "classic": "You've tried to log in to RightSignature ShareFile Edition. However, your account gives you access to Classic RightSignature.",
    "go": "Go To RightSignature"
  };
  _exports.redirectToRs3 = redirectToRs3;
});