define("ember-rs/models/account", ["exports", "@ember-data/model", "ember-rs/mixins/account-settings-attributes"], function (_exports, _model, _accountSettingsAttributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Accounts settings that are shared with other models, like branding
  var _default = _model.default.extend(_accountSettingsAttributes.default, {
    name: (0, _model.attr)('string'),
    role: (0, _model.attr)('string'),
    ownerName: (0, _model.attr)('string'),
    plan: (0, _model.attr)('string'),
    source: (0, _model.attr)('string'),
    users: (0, _model.hasMany)('users', {
      async: true
    }),
    logoUrl: (0, _model.attr)('string'),
    logoThumbUrl: (0, _model.attr)('string'),
    displayLogo: (0, _model.attr)('boolean'),
    featureSet: (0, _model.attr)('object'),
    attachPdfOnCompletion: (0, _model.attr)('boolean'),
    createdAt: (0, _model.attr)('moment-date'),
    isHybris: (0, _model.attr)('boolean'),
    isCar: (0, _model.attr)('boolean'),
    isHipaa: (0, _model.attr)('boolean'),
    isTrial: (0, _model.attr)('boolean'),
    isHybrisTrial: (0, _model.attr)('boolean'),
    isArchive: (0, _model.attr)('boolean'),
    isGracePeriod: (0, _model.attr)('boolean'),
    isMigrated: (0, _model.attr)('boolean'),
    cancellationDate: (0, _model.attr)('date'),
    trialExpiresAt: (0, _model.attr)('moment-date'),
    hybrisTrialExpiresAt: (0, _model.attr)('moment-date'),
    accountStatSet: (0, _model.belongsTo)('account-stat-set', {
      async: true
    }),
    isWorkspaceEnabled: (0, _model.attr)('boolean'),
    isWorkflowsEnabled: (0, _model.attr)('boolean'),
    subdomain: (0, _model.attr)('string'),
    sharefileAccountId: (0, _model.attr)('string'),
    hasMergeFieldSources: (0, _model.attr)('boolean'),
    // Settings
    defaultCallbackUrl: (0, _model.attr)('string'),
    emailOnFailedCallbacks: (0, _model.attr)('boolean'),
    signatureColorBlueDefault: (0, _model.attr)('boolean'),
    documentTokenExpiration: (0, _model.attr)('string'),
    enablePasscode: (0, _model.attr)('boolean'),
    enableIntegration: (0, _model.attr)('boolean'),
    enableSignerDownload: (0, _model.attr)('boolean'),
    // Options
    tokenExpirationOptions: (0, _model.attr)('array'),
    //Mfa
    isMfaEnabledForEmployee: (0, _model.attr)('boolean'),
    optOutStatus: (0, _model.attr)('boolean'),
    optOutDate: (0, _model.attr)('string'),
    optOutEmail: (0, _model.attr)('string'),
    // Features
    api: Ember.computed.alias('featureSet.api'),
    attachments: Ember.computed.alias('featureSet.attachments'),
    paymentsIntegration: Ember.computed.alias('featureSet.payments_integration'),
    branding: Ember.computed.alias('featureSet.branding'),
    externallyBilled: Ember.computed.alias('featureSet.externally_billed'),
    manualDistribution: Ember.computed.alias('featureSet.manual_distribution'),
    onlineForms: Ember.computed.alias('featureSet.online_forms'),
    inPerson: Ember.computed.alias('featureSet.in_person'),
    passcode: Ember.computed.alias('featureSet.passcode'),
    planName: Ember.computed.alias('featureSet.plan_name'),
    planBaseName: Ember.computed.alias('featureSet.plan_base_name'),
    enterpriseReporting: Ember.computed.alias('featureSet.enterprise_reporting'),
    disabledReason: Ember.computed.alias('featureSet.disabled_reason'),
    kba: Ember.computed.alias('featureSet.kba'),
    // Stats
    templatesEnabled: Ember.computed.gt('accountStatSet.templateLimit', 0),
    // if limit is 0, templates is disabled
    isPendingArchival: Ember.computed.or('isTrial', 'isGracePeriod', 'isHybrisTrial'),
    isPremiumTrial: Ember.computed('planName', function () {
      return this.planName === 'RightSignature Premium Trial';
    })
  });

  _exports.default = _default;
});