define("ember-rs/locales/ja/templates/onboarding/new-user-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboarding = void 0;
  var newUserOnboarding = {
    "title": "RightSignature™ へようこそ",
    "letsPersonalize": "機能を使用する前に、アカウントをパーソナライズしましょう。",
    "photoText": "写真はドキュメントの署名ページで使用され、パーソナライズ設定と信頼性をレベルアップします。",
    "photoSubtext": "アカウント設定にアクセスしていつでも更新できます。",
    "sendSign": "ドキュメントの送信および署名(&S)",
    "sendDoc": "他のユーザーが署名するためにドキュメントを送信するか、自分の署名が必要なドキュメントに署名します。",
    "knowMore": "詳細情報",
    "viewSupport": "サポート技術文書およびビデオの表示(&V)",
    "createTemplates": "頻繁に署名されるドキュメント用のテンプレートを作成します。",
    "saveTime": "免責条項、NDA、納税申告書、従業員の申請書など、頻繁に使用されるドキュメントのための再利用可能なテンプレートを作成して時間を節約します。",
    "learnMore": "詳細情報",
    "accessSupport": "RightSignature™ を最大限に活用するために必要なビデオのチュートリアルやヒントなどを、<a href='#''>サポート サイト</a>で参照してください。"
  };
  _exports.newUserOnboarding = newUserOnboarding;
});