define("ember-rs/locales/it/templates/document-transaction/review-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_desktop = void 0;
  var review_desktop = {
    "send_document": "invia documento",
    "save_document": "Salva documento",
    "sendDocument": "Invia documento",
    "bulkSendConfirmation": "Si sta per condividere questo documento con più firmatari",
    "confirm": "Conferma",
    "cancel": "Annulla"
  };
  _exports.review_desktop = review_desktop;
});