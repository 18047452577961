define("ember-rs/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ////////////////////////  SERVICES  ////////////////////////
    flashMessages: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    loading: false,
    availableScopes: Ember.computed(function () {
      return Ember.A([{
        scope: 'read',
        label: this.i18n.t('client_application.new.readOnlyAccess')
      }, {
        scope: 'read write',
        label: this.i18n.t('client_application.new.readWriteAccess')
      }]);
    }),
    ////////////////////////  METHODS  ////////////////////////
    closeModal: function closeModal() {
      Ember.setProperties(this, {
        loading: false
      });
      this.transitionToRoute('client-applications');
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      cancel: function cancel() {
        this.closeModal();
      },
      save: function save() {
        var _this = this;

        Ember.set(this, 'loading', true);

        if (Ember.isBlank(Ember.get(this, 'model.websiteUrl'))) {
          Ember.set(this, 'model.websiteUrl', null);
        }

        return this.model.save().then(function () {
          _this.currentUser.reloadAccount().then(function () {
            Ember.get(_this, 'target._routerMicrolib').refresh();

            _this.flashMessages.success(_this.i18n.t('controllers.clientApplications.newClientApplication.appReview'));

            _this.closeModal();
          });
        }, function () {
          Ember.set(_this, 'loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});