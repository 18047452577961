define("ember-rs/locales/nl/templates/onboarding/prepare-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTutorial = void 0;
  var prepareTutorial = {
    "title": "Een document voorbereiden voor ondertekening",
    "selectField": "Selecteer het veld aan de rechterkant dat u wilt toevoegen.",
    "placeField": "Het veld plaatsen",
    "clickPlace": "Klik op de plaats waar u het nieuwe veld wilt plaatsen.",
    "adjustSize": "De grootte van het veld aanpassen",
    "adjustHover": "Beweeg de cursor over het veld en versleep de punten om het veld aan te passen.",
    "adjustOptions": "Aanpassingsopties",
    "moreOptions": "Klik op het veld om meer opties weer te geven en selecteer wie het veld moet invullen.",
    "thatsIt": "Dat is alles!",
    "clickReview": "Wanneer u klaar bent, klikt u op <b>Volgende stap: Controleren</b>",
    "knowMore": "Wilt u meer weten?",
    "support": "Ondersteuningsartikelen en video's weergeven"
  };
  _exports.prepareTutorial = prepareTutorial;
});