define("ember-rs/locales/ja/templates/components/component-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbar = void 0;
  var componentToolbar = {
    "useTools": "署名者が完了する必要があるフィールドには、これらのツールを使用します。",
    "showLess": "簡易表示",
    "showMore": "詳細表示",
    "annotate": "注釈:",
    "annotateTip": "これらのツールを使用して、ドキュメントに署名、テキスト、またはチェックマークの注釈を加えます。",
    "request": "要求:",
    "dataFields": "データ フィールド",
    "sourceFetchFailed": "ソース情報の取得に失敗しました。",
    "schemaFetchFailed": "Failed to retrieve source entity schema."
  };
  _exports.componentToolbar = componentToolbar;
});