define("ember-rs/components/integration-settings-service", ["exports", "ember-rs/mixins/integration"], function (_exports, _integration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_integration.default, {
    flashMessages: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    tagName: 'li',
    contactsServiceEnabled: false,
    classNameBindings: [':integration-service', 'availableService'],
    authParams: Ember.Object.create({}),
    showParamsFields: false,
    isContacts: Ember.computed.equal('availableService', 'contacts'),
    isConfirmed: Ember.computed.alias('currentUser.isConfirmed'),
    disabled: Ember.computed('disabledCar', 'isDisabledContactsIntegration', 'provider', 'availableService', function () {
      return this.disabledCar || this.isDisabledContactsIntegration || this.provider === 'sharefile' && this.availableService === 'sending';
    }),
    disabledCar: Ember.computed('provider', 'currentUser.account.isCar', function () {
      return this.provider === 'sharefile' && Ember.get(this, 'currentUser.account.isCar');
    }),
    toolTipText: Ember.computed('isDisabledContactsIntegration', function () {
      if (this.disabledCar) {
        return 'Your account does not support ShareFile integration.';
      } else if (this.isDisabledContactsIntegration) {
        return 'Cannot have multiple contact integrations enabled at once.';
      }
    }),
    isDisabledContactsIntegration: Ember.computed('contactServiceEnabled', 'isContacts', 'serviceEnabled', function () {
      return this.contactServiceEnabled && this.isContacts && !this.serviceEnabled;
    }),
    enabled: Ember.computed('contactServiceEnabled', 'isContacts', 'serviceEnabled', function () {
      if (!this.serviceEnabled && this.contactServiceEnabled && this.isContacts) {
        return false;
      }

      return true;
    }),
    serviceDescriptions: Ember.computed('formattedProviderName', function () {
      return Ember.Object.create({
        sending: this.i18n.t("components.integrationSettingsService.sending", {
          formattedProviderName: this.formattedProviderName
        }),
        //archiving: `Send a pdf of completed documents to a specific folder in your ${this.get('formattedProviderName')} account`,
        archiving: this.i18n.t("components.integrationSettingsService.archiving", {
          formattedProviderName: this.formattedProviderName
        }),
        //contacts: `Make all of your ${this.get('formattedProviderName')} contacts available for document signing`,
        contacts: this.i18n.t("components.integrationSettingsService.contacts", {
          formattedProviderName: this.formattedProviderName
        }),
        //payments: `Place Payment Components on your RightSignature documents to securely collect and store credit card information and authorize charges through your ${this.get('formattedProviderName')} account`
        payments: this.i18n.t("components.integrationSettingsService.payments", {
          formattedProviderName: this.formattedProviderName
        })
      });
    }),
    serviceDescription: Ember.computed('availableService', 'serviceDescriptions', function () {
      var serviceDescriptions = this.serviceDescriptions;
      var availableService = this.availableService;

      if (serviceDescriptions && availableService) {
        return serviceDescriptions.get(availableService);
      }
    }),
    actions: {
      savePreauthorization: function savePreauthorization() {
        var strippedPreauthParams = this.restrictPreauthParams(this.authParams);

        if (this.isValidPreauthParams(strippedPreauthParams)) {
          // Note: feels dirty to use this to convert Ember Object into JSON object
          var authJSON = JSON.parse(JSON.stringify(strippedPreauthParams));
          this.set('serviceEnabled', true);
          this.sendAction('toggleService', this.availableService, this.serviceEnabled, authJSON);
        } else {
          this.flashMessages.warning(this.i18n.t('components.integrationSettingsService.fieldsInfo'));
        }
      },
      serviceToggled: function serviceToggled(enabled) {
        if (this.unactivatedIntegration) {
          this.setProperties({
            serviceEnabled: false,
            showParamsFields: !this.showParamsFields
          });
        } else {
          this.sendAction('toggleService', this.availableService, enabled);
        }
      }
    }
  });

  _exports.default = _default;
});