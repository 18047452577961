define("ember-rs/locales/es/templates/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlay = void 0;
  var importOverlay = {
    "importFrom": "Importar campos desde:",
    "toImport": "componente(s) para importar desde",
    "page": "página(s)",
    "cancel": "Cancelar",
    "import": "Importar",
    "noComponents": "¡No hay componentes para importar!",
    "pastDocs": "Importar campos de documentos anteriores",
    "noMatch": "No se encontraron documentos con este nombre",
    "importSuccess": "Componentes importados correctamente: {{{components}}}."
  };
  _exports.importOverlay = importOverlay;
});