define("ember-rs/locales/ja/controllers/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.branding = void 0;
  var branding = {
    "emailUpdateSuccess": "ユーザーのメール設定を更新しました。",
    "emailUpdateFail": "ユーザーのメール設定を更新できませんでした。",
    "updateSuccess": "ユーザー アカウントのブランド設定を更新しました。",
    "updateFail": "ユーザー アカウントのブランド設定を更新できませんでした。"
  };
  _exports.branding = branding;
});