define("ember-rs/locales/de/templates/components/document-token-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTokenExpirationSelect = void 0;
  var documentTokenExpirationSelect = {
    "Do_not_expire_links": "Links laufen nicht ab",
    "1_hour": "1 Stunde",
    "3_hours": "3 Stunden",
    "8_hours": "8 Stunden",
    "24_hours": "24 Stunden",
    "1_week": "1 Woche"
  };
  _exports.documentTokenExpirationSelect = documentTokenExpirationSelect;
});