define("ember-rs/routes/document", ["exports", "ember-rs/utils/safe-access"], function (_exports, _safeAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    signatureAuth: Ember.inject.service(),
    ////////////////////////  HOOKS  ////////////////////////
    model: function model(params) {
      this.set('documentId', params.document_id);
      return this.store.findRecord('document', params.document_id, {
        reload: true
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      this.signatureAuth.setDocumentId(this.get('documentId')); // Set documentTransaction to parent route

      model.set('documentTransaction', this.modelFor('document-transaction'));
      model.get('roles').forEach(function (role) {
        role.set('documentTransaction', _this.modelFor('document-transaction'));
      });
    },
    ////////////////////////  ACTIONS  ////////////////////////
    _save: function _save() {
      var _this2 = this;

      this.currentModel.save().then(function () {
        _this2.flashMessages.success(_this2.i18n.t('routes.documents.saved'));
      }, function () {
        throw 'Document was not saved. handle interactions on frontend';
      });
    },
    actions: {
      error: function error(response) {
        var statusCode = (0, _safeAccess.default)(response, '500', 'errors', 0, 'status');

        if (statusCode === '308') {
          this.transitionTo('unauthorized.expired', {
            queryParams: {
              token: this.get('currentUser.token')
            }
          });
        } else if (statusCode === '403') {
          this.transitionTo('unauthorized.unauthorized');
        } else if (statusCode === '404') {
          this.transitionTo('documents');
        }
      },
      save: function save() {
        this._save();
      }
    }
  });

  _exports.default = _default;
});