define("ember-rs/locales/de/templates/document/prepare-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_document = void 0;
  var prepare_document = {
    "prepare": "Vorbereiten ",
    "prepare_package": "Dokumentenpaket vorbereiten",
    "merge_field_message": "Dieses Dokument enthält Seriendruckfelder, die Sie vor dem Senden ausfüllen müssen.",
    "merge_field_title": "Seriendruckfelder ausfüllen",
    "missing_merge_fields": "Alle erforderlichen Seriendruckfelder müssen ausgefüllt werden."
  };
  _exports.prepare_document = prepare_document;
});