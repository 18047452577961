define("ember-rs/components/restyle-onboarding", ["exports", "ember-rs/mixins/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_settings.default, {
    ////////////////////////  DEPENDENCIES  ///////////////////
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    currentImage: 1,
    timeout: null,
    didInsertElement: function didInsertElement() {
      this.setTimer();
    },
    willDestroyElement: function willDestroyElement() {
      var timeout = this.timeout;

      if (timeout) {
        Ember.run.cancel(timeout);
      }
    },
    setTimer: function setTimer() {
      var _this = this;

      this.set('timeout', Ember.run.later(function () {
        _this.incrementImage();
      }, 4000));
    },
    incrementImage: function incrementImage() {
      this.set('currentImage', this.currentImage % 3 + 1);
      this.setTimer();
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      dismiss: function dismiss() {
        this.updateSetting('hasSeenRestyleOnboarding', 'has_seen_restyle_onboarding', true, Ember.get(this, 'currentUser.model'), false);
      }
    }
  });

  _exports.default = _default;
});