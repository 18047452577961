define("ember-rs/locales/nl/templates/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlay = void 0;
  var importOverlay = {
    "importFrom": "Velden importeren uit:",
    "toImport": "onderdelen om te importeren uit",
    "page": "pagina('s)",
    "cancel": "Annuleren",
    "import": "Importeren",
    "noComponents": "Geen onderdelen om te importeren!",
    "pastDocs": "Velden uit eerdere documenten importeren",
    "noMatch": "Geen documenten gevonden die overeenkomen met deze naam",
    "importSuccess": "{{{components}}} onderdelen zijn geïmporteerd."
  };
  _exports.importOverlay = importOverlay;
});