define("ember-rs/locales/it/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserForm = void 0;
  var newUserForm = {
    "email": "E-mail",
    "name": "Nome",
    "password": "Password",
    "hidePassword": "Nascondi password",
    "create": "Crea account",
    "haveAccount": "Si dispone di un account?"
  };
  _exports.newUserForm = newUserForm;
});