define("ember-rs/locales/nl/templates/document/prepare-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_document = void 0;
  var prepare_document = {
    "prepare": "Voorbereiden ",
    "prepare_package": "Documentenpakket voorbereiden",
    "merge_field_message": "Dit document bevat samenvoegvelden die u vóór verzending moet invullen.",
    "merge_field_title": "Samenvoegvelden invullen",
    "missing_merge_fields": "Alle vereiste samenvoegvelden moeten worden ingevuld."
  };
  _exports.prepare_document = prepare_document;
});