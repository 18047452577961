define("ember-rs/locales/fr/templates/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "share": "Partager",
    "status": "État",
    "overview": "Présentation",
    "sent": "envoyé",
    "people_involved": "Personnes impliquées :",
    "reference_id": "ID de référence : ",
    "manage": "Gérer",
    "tags": "Balises : "
  };
  _exports.details = details;
});