define("ember-rs/locales/nl/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrations = void 0;
  var integrations = {
    "successEnabled": "{{{formattedProviderName}}} is ingeschakeld",
    "successAuthorized": "{{{formattedProviderName}}} is geautoriseerd",
    "successDisabled": "{{{formattedProviderName}}} is uitgeschakeld",
    "error": "Fout. Kan {{{formattedProviderName}}} niet wijzigen!",
    "errorAuthorize": "Fout. Kan de integratie van {{{formattedProviderName}}} niet autoriseren!",
    "errorSetup": "Er is een fout opgetreden bij het instellen van de integratie {{{formattedProviderName}}}. Probeer het nog eens."
  };
  _exports.integrations = integrations;
});