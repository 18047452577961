define("ember-rs/mixins/upgrade-worldpay", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    fetchBillingDetails: function fetchBillingDetails() {
      var accountId = this.get('currentUser.account.id');
      return JSON.parse(sessionStorage.getItem(accountId));
    },
    setBillingDetails: function setBillingDetails(billing) {
      var accountId = this.get('currentUser.account.id');
      sessionStorage.setItem(accountId, JSON.stringify(billing));
    },
    upgradeToHybris: function upgradeToHybris(billingDetails) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          type: 'POST',
          url: "".concat(_environment.default.api.endpoint, "/account/upgrade_to_paid_hybris_worldpay"),
          data: billingDetails,
          global: false
        }).then(function () {
          _this.currentUser.reloadAccount().then(function () {
            _this.flashMessages.success(_this.i18n.t("mixins.upgradeWorldpay.successUpgrade"));

            _this.replaceWith('documents');
          });
        }).catch(function (error) {
          var errorMessage = _this.i18n.t("mixins.upgradeWorldpay.dataUnavailable");

          if (error.status.toString().match(/4[0-9][0-9]/) && JSON.parse(error.responseText)[0]) {
            errorMessage = JSON.parse(error.responseText)[0];
          }

          reject(errorMessage);
        });
      }, 'upgrade to hybris');
    }
  });

  _exports.default = _default;
});