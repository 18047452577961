define("ember-rs/routes/integration", ["exports", "ember-rs/mixins/integration-auth"], function (_exports, _integrationAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_integrationAuth.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ////////////////////////  HOOKS  ////////////////////////
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var _this$get = this.get('session.data.integration'),
          provider = _this$get.provider,
          serviceCategory = _this$get.serviceCategory,
          service = _this$get.service,
          integrationMetadata = _this$get.integrationMetadata,
          origin = _this$get.origin,
          requestTokenParams = _this$get.requestTokenParams;

      var queryParams = Ember.assign(transition.to.queryParams, requestTokenParams);
      var authorizationParams = this.parseIntegrationAuthParams(provider, integrationMetadata, queryParams);
      var formattedProviderName = s.titleize(provider.replace(/[-_]/, ' '));

      if (Ember.isPresent(transition.to.queryParams.error)) {
        this.flashMessages.warning(this.i18n.t("routes.user.integrations.errorSetup", {
          formattedProviderName: formattedProviderName
        }));
        this.transitionTo('application');
        return;
      }

      this.integrationRequest(provider, serviceCategory, service, 'POST', authorizationParams).done(function () {
        if (Ember.isPresent(origin)) {
          if (Ember.isPresent(origin.location)) {
            window.location = origin.location;
          } else if (Ember.isPresent(origin.id)) {
            _this.transitionTo(origin.route, origin.id);
          } else {
            _this.transitionTo(origin.route);
          }
        } else {
          _this.transitionTo('user.integrations');
        }

        _this.get('currentUser.model').reload();

        _this.flashMessages.success(_this.i18n.t("routes.user.integrations.successAuthorized", {
          formattedProviderName: formattedProviderName
        }));
      }).fail(function () {
        _this.transitionTo('user.integrations');

        _this.flashMessages.warning(_this.i18n.t("routes.user.integrations.errorAuthorize", {
          formattedProviderName: formattedProviderName
        }));
      });
    }
  });

  _exports.default = _default;
});