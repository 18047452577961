define("ember-rs/components/basic-fallback-page", ["exports", "jquery", "ember-rs/mixins/component-dimensions"], function (_exports, _jquery, _componentDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentDimensions.default, {
    ////////////////////////////////////////////////////////////////////////////////
    //                                SERVICES
    ////////////////////////////////////////////////////////////////////////////////
    overlayerData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    ////////////////////////////////////////////////////////////////////////////////
    //                                HELPERS
    ////////////////////////////////////////////////////////////////////////////////
    handleClick: function handleClick(event) {
      if (this.get('overlayerData.isSelecting')) {
        this.set('overlayerData.isSelecting', false);
      } else if (this.get('overlayerData.selectedComponents.length') > 0) {
        this.overlayerData.deselectComponents();
      } else {
        // component drop of selected item unless there is a selected component
        var x = event.offsetX || event.clientX - (0, _jquery.default)(event.target).offset().left;
        var y = event.offsetY || event.clientY - (0, _jquery.default)(event.target).offset().top;

        if (this.get('overlayerData.selectedComponentClass') === 'textAnnotationComponent') {
          x -= 5;
          y -= 11;
        }

        this.sendAction('addComponent', this.get('overlayerData.selectedComponentClass'), {
          page: this.get('pageImage.page_number'),
          x: x / this.get('documentViewerData.pageWidth'),
          y: y / this.get('documentViewerData.pageHeight'),
          type: this.get('overlayerData.selectedComponentClass')
        });
      }
    },
    showToolPreview: function showToolPreview(event) {
      var x = event.offsetX || event.clientX - (0, _jquery.default)(event.target).offset().left;
      var y = (event.offsetY || event.clientY - (0, _jquery.default)(event.target).offset().top) + (this.get('documentViewerData.pageHeight') + this.get('documentViewerData.pageMargin')) * (this.get('pageImage.page_number') - 1);
      var toolDimensions = this.get("lastComponentDimensions.".concat(this.get('overlayerData.selectedComponentClass')));
      var gutter = this.get('documentViewerData.pageWidth') - toolDimensions.width;
      var overlayerData = this.overlayerData;
      var height;
      overlayerData.set('mouseCoordinates', {
        x: x,
        y: y
      });
      (0, _jquery.default)(event.target).css('cursor', 'crosshair');

      if (x > gutter) {
        var selectedComponentClass = overlayerData.get('selectedComponentClass');
        overlayerData.set('withinGutter', true);
        overlayerData.set('tempToolDimensions.width', toolDimensions.width - (x - gutter));

        if (selectedComponentClass === 'signatureComponent' || selectedComponentClass === 'signatureAnnotationComponent') {
          height = (toolDimensions.width - (x - gutter)) / this.get("defaultComponentRatios.".concat(selectedComponentClass));
        } else if (selectedComponentClass === 'checkComponent' || selectedComponentClass === 'checkGroupComponent' || selectedComponentClass === 'checkmarkAnnotationComponent') {
          height = toolDimensions.width - (x - gutter);
        } else {
          height = toolDimensions.height;
        }

        this.set('overlayerData.tempToolDimensions.height', height); // outside of gutter, no resizing needed, reset all vars
      } else {
        overlayerData.set('withinGutter', false);
        overlayerData.set('tempToolDimensions.width', null);
        overlayerData.set('tempToolDimensions.height', null);
      }
    },
    handleMouseMove: function handleMouseMove(event) {
      if (this.get('overlayerData.showToolPreview')) {
        this.showToolPreview(event);
      } else if (this.get('overlayerData.selectedComponentClass') === 'textAnnotationComponent') {
        (0, _jquery.default)(event.target).css('cursor', 'text');
      }
    },
    ////////////////////////////////////////////////////////////////////////////////
    //                             LIFECYCLE HOOKS
    ////////////////////////////////////////////////////////////////////////////////
    didInsertElement: function didInsertElement() {
      this.$().on("click.".concat(this.elementId), 'img.page-image', this.handleClick.bind(this)).on("mousemove.".concat(this.elementId), this.handleMouseMove.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off("click.".concat(this.elementId)).off("mousemove.".concat(this.elementId));
    }
  });

  _exports.default = _default;
});