define("ember-rs/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hLmdg9Rq",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[30,[36,6],[[35,2,[\"model\"]],[30,[36,3],[[35,2,[\"model\",\"hasAgreedToTerms\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"agree-to-terms\",[],[[\"@agreeToTerms\"],[\"agreeToTerms\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"rs-modal\",[],[[\"@show\",\"@dismissable\",\"@centered\",\"@class\"],[[30,[36,4],[[30,[36,3],[[35,2,[\"entitled\"]]],null]],null],false,true,\"onboard-modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"entitlement-modal \",[30,[36,5],[[30,[36,3],[[35,2,[\"canManageUsers\"]]],null],\"not-admin\"],null]]]],[12],[2,\"\\n\"],[6,[37,5],[[35,2,[\"canManageUsers\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[19,\"onboarding/entitlement-options-admin\",[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"sharefileRedirect.notAllowed\"],null]],[13],[2,\"\\n        \"],[10,\"p\"],[12],[1,[30,[36,0],[\"sharefileRedirect.noPro\"],null]],[13],[2,\"\\n        \"],[10,\"ul\"],[12],[2,\"\\n          \"],[10,\"li\"],[12],[1,[30,[36,0],[\"sharefileRedirect.send\"],null]],[13],[2,\"\\n          \"],[10,\"li\"],[12],[1,[30,[36,0],[\"sharefileRedirect.create\"],null]],[13],[2,\"\\n          \"],[10,\"li\"],[12],[1,[30,[36,0],[\"sharefileRedirect.customize\"],null]],[13],[2,\"\\n          \"],[10,\"li\"],[12],[1,[30,[36,0],[\"sharefileRedirect.enable\"],null]],[13],[2,\"\\n          \"],[10,\"li\"],[12],[1,[30,[36,0],[\"sharefileRedirect.reports\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[11,\"button\"],[4,[38,1],[[32,0],\"notNow\"],null],[12],[1,[30,[36,0],[\"sharefileRedirect.login\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"t\",\"action\",\"currentUser\",\"not\",\"readonly\",\"if\",\"and\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/sharefile-redirect.hbs"
    }
  });

  _exports.default = _default;
});