define("ember-rs/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p+CCP9Xi",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[14,\"src\",\"/assets/images/loading-light.svg\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"img\"],[14,\"src\",\"/assets/images/loading.svg\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"darkBg\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});