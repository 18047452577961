define("ember-rs/locales/pt-br/templates/mobile-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mobileNavigation = void 0;
  var mobileNavigation = {
    "documents": "Documentos",
    "templates": "Modelos",
    "settings": "Suas configurações",
    "integrations": "Integrações",
    "users": "Usuários",
    "reports": "Relatórios",
    "help": "Ajuda",
    "logout": "Logout"
  };
  _exports.mobileNavigation = mobileNavigation;
});