define("ember-rs/locales/nl/routes/routes", ["exports", "ember-rs/locales/nl/routes/reusable-template/add-roles", "ember-rs/locales/nl/routes/user/integrations", "ember-rs/locales/nl/routes/document-transaction", "ember-rs/locales/nl/routes/cc-gateway-redirect", "ember-rs/locales/nl/routes/document", "ember-rs/locales/nl/routes/email-verification", "ember-rs/locales/nl/routes/sharefile-redirect", "ember-rs/locales/nl/routes/start-document"], function (_exports, _addRoles, _integrations, _documentTransaction, _ccGatewayRedirect, _document, _emailVerification, _sharefileRedirect, _startDocument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.routes = void 0;
  var routes = {
    reusableTemplate: {
      addRoles: _addRoles.addRoles
    },
    user: {
      integrations: _integrations.integrations
    },
    documentTransaction: _documentTransaction.documentTransaction,
    ccGatewayRedirect: _ccGatewayRedirect.ccGatewayRedirect,
    documents: _document.documents,
    emailVerification: _emailVerification.emailVerification,
    sharefileRedirect: _sharefileRedirect.sharefileRedirect,
    startDocument: _startDocument.startDocument
  };
  _exports.routes = routes;
});