define("ember-rs/locales/es/templates/onboarding/send-onboarding-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding4 = void 0;
  var sendOnboarding4 = {
    "close": "Seleccione la <span>flecha hacia atrás</span> para cerrar estos detalles y volver al panel de Documentos"
  };
  _exports.sendOnboarding4 = sendOnboarding4;
});