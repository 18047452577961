define("ember-rs/locales/nl/templates/signer/footer-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerMobile = void 0;
  var footerMobile = {
    "submitSig": "Handtekening verzenden"
  };
  _exports.footerMobile = footerMobile;
});