define("ember-rs/locales/pt-br/templates/onboarding/prepare-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTutorial = void 0;
  var prepareTutorial = {
    "title": "Preparar um documento para assinatura",
    "selectField": "Selecione o campo à direita que você deseja adicionar.",
    "placeField": "Posicionar o campo",
    "clickPlace": "Clique em onde deseja colocar o novo campo.",
    "adjustSize": "Ajustar o tamanho do campo",
    "adjustHover": "Para ajustar, passe o mouse sobre o campo e arraste os pontos.",
    "adjustOptions": "Opções de ajuste",
    "moreOptions": "Clique no campo para mostrar mais opções e selecione quem deve completar o campo.",
    "thatsIt": "Pronto!",
    "clickReview": "Quando terminar, clique em <b>Avançar: Revisão</b>",
    "knowMore": "Quer saber mais?",
    "support": "Veja artigos e vídeos explicativos"
  };
  _exports.prepareTutorial = prepareTutorial;
});