define("ember-rs/locales/de/templates/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "title": "Aktualisieren...",
    "desc": "Dies kann einige Zeit dauern. Bitte Seite nicht aktualisieren und klicken Sie nicht auf die Browserschaltflächen 'Schließen' oder 'Zurück'."
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});