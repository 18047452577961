define("ember-rs/services/ajax", ["exports", "ember-ajax/services/ajax", "ember-rs/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.api.endpoint,
    headers: Ember.computed('session.isAuthenticated', {
      get: function get() {
        var headers = {};

        if (this.get('session.isAuthenticated')) {
          var accessToken = this.get('session.data.authenticated.access_token');
          headers['Authorization'] = "Bearer ".concat(accessToken);
        }

        return headers;
      }
    })
  });

  _exports.default = _default;
});