define("ember-rs/locales/de/controllers/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.branding = void 0;
  var branding = {
    "emailUpdateSuccess": "E-Mail-Einstellungen für Benutzer wurden aktualisiert.",
    "emailUpdateFail": "Fehler beim Aktualisieren der E-Mail-Einstellungen für Benutzer.",
    "updateSuccess": "Die Brandingeinstellungen für Benutzerkonten wurden aktualisiert.",
    "updateFail": "Fehler beim Aktualisieren der Brandingeinstellungen für Benutzerkonten."
  };
  _exports.branding = branding;
});