define("ember-rs/locales/fr/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "linkExpired": "Le lien du document a expiré",
    "toSign": "Pour signer ce document, demandez un nouveau lien.",
    "sendLink": "Envoyer nouveau lien de signature",
    "contactSender": "Veuillez contacter l'expéditeur du document pour demander un lien mis à jour."
  };
  _exports.identityExpired = identityExpired;
});