define("ember-rs/locales/fr/templates/users", ["exports", "ember-rs/locales/fr/templates/users/sign-in"], function (_exports, _signIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.users = void 0;
  var users = {
    singIn: _signIn.singIn
  };
  _exports.users = users;
});