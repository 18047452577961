define("ember-rs/locales/es/controllers/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "standardInfo": "El plan seleccionado solo admite 1 usuario. Si necesita más usuarios en su cuenta, elija el plan Advanced.",
    "migratedInfo": "Elige al menos {{{minimumSeats}}} usuarios ya que su cuenta de prueba ya utiliza {{{minimumSeats}}} puestos. De lo contrario, póngase en contacto con asistencia para ampliar la cuenta.",
    "planInfo": "El plan seleccionado incluye 3 usuarios. Elija más a continuación si lo necesita.",
    "validUsers": "Introduzca una cantidad de usuarios válida"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});