define("ember-rs/locales/nl/templates/onboarding/post-sign-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding1 = void 0;
  var postSignOnboarding1 = {
    "tapGear": "<span>Tik op het tandwiel</span> om het document en aanvullende informatie te downloaden.",
    "viewDoc": "Hier kunt u de <span>status</span> van het document zien"
  };
  _exports.postSignOnboarding1 = postSignOnboarding1;
});