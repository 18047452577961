define("ember-rs/locales/nl/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "title": "Servicevoorwaarden",
    "body": "Door het gebruik van RightSignature&trade; verklaart u dat u ons {{partial 'terms-of-use-link'}} en <a href='{{csgHomePage}}/privacy-policy' target='_blank'>privacybeleid</a> hebt gelezen en hiermee akkoord gaat.",
    "noThanks": "Nee, bedankt",
    "iAgree": "Ik ga akkoord"
  };
  _exports.agreeToTerms = agreeToTerms;
});