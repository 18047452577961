define("ember-rs/locales/ja/templates/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateRow = void 0;
  var reusableTemplateRow = {
    "send": "送信",
    "details": "詳細",
    "trialmessageComp": "現在のトライアル プランでは、{{{documentLimit}}} 個を超えるドキュメントを送信する権限がありません。 ",
    "planmessageComp": "現在のプランでは、{{{documentLimit}}} 個を超えるドキュメントを送信する権限がありません。 ",
    "upgrade": "アカウントをアップグレードする場合、ご連絡ください。"
  };
  _exports.reusableTemplateRow = reusableTemplateRow;
});