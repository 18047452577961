define("ember-rs/locales/pt-br/controllers/document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "notAllowed": "Você não tem permissão para fazer isso.",
    "voided": "O documento foi anulado com sucesso.",
    "voidingFailed": "Falha ao anular o documento.",
    "docExtended": "O documento foi prorrogado com sucesso.",
    "tagUpdated": "As marcas foram atualizadas com sucesso",
    "tagUpdateFailed": "Falha ao atualizar as tags: {{{tags}}} ",
    "viewerListUpdated": "Lista de visualizadores atualizada com sucesso",
    "viewerUpdateFailed": "Falha ao atualizar a lista de visualizações: {{{viewers}}}"
  };
  _exports.details = details;
});