define("ember-rs/locales/en/templates/onboarding/post-sign-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding1 = void 0;
  var postSignOnboarding1 = {
    "tapGear": "<span>Tap the gear</span> to download the document and additional info.",
    "viewDoc": "Here's where you can see the <span>status</span> of the document"
  };
  _exports.postSignOnboarding1 = postSignOnboarding1;
});