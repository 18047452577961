define("ember-rs/models/document-share", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    email: (0, _model.attr)('string'),
    documentTemplate: (0, _model.belongsTo)('document-template', {
      polymorphic: true,
      async: false
    })
  });

  _exports.default = _default;
});