define("ember-rs/locales/de/routes/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "tryAgain": "Fehler. Versuchen Sie es erneut."
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});