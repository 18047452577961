define("ember-rs/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    classNameBindings: [':loading-spinner', 'darkBg'],
    darkBg: false,
    size: 18,
    style: Ember.computed('size', function () {
      var size = this.size;
      return "width: ".concat(size, "px; height: ").concat(size, "px;").htmlSafe();
    })
  });

  _exports.default = _default;
});