define("ember-rs/locales/es/templates/components/overlay-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.overlayComponent = void 0;
  var overlayComponent = {
    "mergeField": "Campo de combinación:",
    "signature": "Firma",
    "date": "Fecha",
    "dateAutoFill": "Date Signed",
    "initial": "Inicial",
    "select": "Seleccionar",
    "text": "Texto",
    "attachment": "Datos adjuntos",
    "authorize": "Autorizar pago",
    "collect": "Obtener tarjeta de crédito"
  };
  _exports.overlayComponent = overlayComponent;
});