define("ember-rs/locales/es/templates/components/rs-left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsLeftNav = void 0;
  var rsLeftNav = {
    "Documents": "Documentos",
    "Templates": "Plantillas",
    "Account": "Cuenta",
    "Settings": "Parámetros",
    "Integrations": "Integraciones",
    "Branding": "Personalización de marca",
    "Users": "Usuarios",
    "Reports": "Informes",
    "Api": "API",
    "Billing": "Facturación",
    "Workflows": "Comentarios y aprobación"
  };
  _exports.rsLeftNav = rsLeftNav;
});