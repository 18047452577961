define("ember-rs/components/flash-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':flash-modal', 'flash.exiting::visible', 'errors:error:success'],
    flashMessages: Ember.inject.service(),
    errors: Ember.computed('flash.type', function () {
      return Ember.get(this, 'flash.type') === 'warning';
    }),
    modalMessages: Ember.computed('flash.message', function () {
      var messages = Ember.get(this, 'flash.message');

      if (Ember.isArray(messages)) {
        return messages;
      }

      return [messages];
    }),
    actions: {
      dismiss: function dismiss(flash) {
        var messages = this.flashMessages;
        Ember.set(flash, 'exiting', true);
        Ember.run.later(this, function () {
          Ember.set(messages, 'queue', messages.queue.removeObject(flash));
        }, 1000);
      }
    }
  });

  _exports.default = _default;
});