define("ember-rs/components/csv-uploader", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CSV_MAX_FILE_SIZE = 5120000;

  var _default = Ember.Component.extend({
    classNames: ['uploader-container'],
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    i18n: Ember.inject.service(),
    maxFileSize: CSV_MAX_FILE_SIZE,
    url: Ember.computed('documentTransaction.id', function () {
      return _environment.default.api.endpoint.concat("/document_transactions/".concat(this.get('documentTransaction.id'), "/signer_csv_upload"));
    }),
    fileUploaderEle: function fileUploaderEle() {
      return this.$('.file-uploader');
    },
    initializeUploader: Ember.on('didInsertElement', function () {
      var _this = this;

      var self = this;
      var flash = this.flashMessages;
      var fileupload_options = {
        autoUpload: true,
        singleFileUploads: true
      };
      this.fileUploaderEle().fileupload(_jquery.default.extend(fileupload_options, {
        dataType: 'json',
        method: 'POST',
        url: self.get('url'),
        paramName: 'csv_file',
        global: false,
        submit: function submit(e, data) {
          self.set('isUploading', true);
          data.jqXHR = (0, _jquery.default)(this).fileupload('send', data);
          return false;
        },
        drop: function drop(event, data) {
          if (data.files.length > 1) {
            flash.warning(self.get('i18n').t('document_transaction.choose_documents.upload_multifile'));
            return false;
          }
        },
        add: function add(event, data) {
          var file = data.files[0];

          if (!(file.type === 'text/csv') && !(file.type === 'application/vnd.ms-excel')) {
            flash.warning(self.get('i18n').t('document_transaction.choose_documents.upload_invalid_extension', {
              fileName: file.name
            }));
            return false;
          }

          if (file.size > _this.maxFileSize) {
            flash.warning(self.get('i18n').t('document_transaction.choose_documents.upload_size_limit', {
              fileName: file.name
            }));
            return false;
          }

          data.submit();
        },
        done: function done(event, data) {
          self.fileUploadSuccessful(data.files[0]);
          flash.success(self.get('i18n').t('document_transaction.choose_documents.upload_success'));
        },
        fail: function fail(e, error) {
          self.fileUploadFailed(error.jqXHR.responseJSON);
        }
      })).on('mouseup', function () {
        if (navigator.userAgent.indexOf("MSIE 10") > 0) {
          (0, _jquery.default)(this).trigger('click');
        }
      });
    }),
    cleanup: Ember.on('willDestroyElement', function () {
      this.fileUploaderEle().fileupload('destroy');
    })
  });

  _exports.default = _default;
});