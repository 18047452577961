define("ember-rs/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/PbtRXBj",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\"],[\"applicationModals\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"persistent-modal\",[],[[\"@cancel\",\"@save\",\"@cancelText\",\"@saveText\",\"@class\",\"@loading\"],[[30,[36,0],[[32,0],\"cancelUnlock\"],null],[30,[36,0],[[32,0],\"unlockDocument\"],null],[30,[36,1],[\"shared.actions.cancel\"],null],[30,[36,1],[\"shared.actions.unlock\"],null],\"unlock-modal persistent-modal--is-two-thirds-width\",[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h5\"],[14,0,\"unlock-modal__header\"],[12],[1,[30,[36,1],[\"shared.knowledge-based-authentication\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"document.details.unlock.unlock_message\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"loading\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/document/details/unlock.hbs"
    }
  });

  _exports.default = _default;
});