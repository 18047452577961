define("ember-rs/mixins/sharefile-user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isConfirmed: (0, _model.attr)('boolean'),
    isMasterAdmin: (0, _model.attr)('boolean'),
    canDelegateUsers: (0, _model.attr)('boolean'),
    canManageUsers: (0, _model.attr)('boolean'),
    canSendDocuments: (0, _model.attr)('boolean'),
    canManageBranding: (0, _model.attr)('boolean'),
    canManageTemplates: (0, _model.attr)('boolean'),
    canViewAllDocuments: (0, _model.attr)('boolean'),
    canViewBills: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});