define("ember-rs/components/rs-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    classNames: 'rs-link',
    classNameBindings: ['isPrimary:is-primary:is-standard', 'isBold:is-bold', 'isButton:is-button'],
    activeClass: 'is-active',
    disabledClass: 'is-disabled'
  });

  _exports.default = _default;
});