define("ember-rs/locales/de/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareNextRoute = void 0;
  var prepareNextRoute = {
    "mergeFieldStepNext": "Nächster Schritt: Seriendruckfelder",
    "documentStepNext": "Nächstes Dokument",
    "reviewStepNext": "Nächster Schritt: Überprüfen",
    "placeFieldNext": "Nächster Schritt: Felder platzieren"
  };
  _exports.prepareNextRoute = prepareNextRoute;
});