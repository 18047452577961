define("ember-rs/locales/de/controllers/document/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "success": "Alle Unterzeichner entsperrt",
    "error": "Fehler beim Entsperren der Unterzeichner. Versuchen Sie es später erneut."
  };
  _exports.unlock = unlock;
});