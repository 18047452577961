define("ember-rs/templates/unauthorized/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cqCwifJM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,1],[\"unauthorized.expired.linkExpired\"],null]],[13],[2,\"\\n\\n\"],[11,\"form\"],[4,[38,2],[[32,0],\"requestNewToken\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n  \"],[10,\"p\"],[12],[1,[30,[36,1],[\"unauthorized.expired.toDownload\"],null]],[13],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[35,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"limited-input-text-field\",[],[[\"@type\",\"@value\",\"@required\"],[\"email\",[34,0],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"button\"],[14,4,\"submit\"],[12],[1,[30,[36,1],[\"unauthorized.expired.sendLink\"],null]],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"email\",\"t\",\"action\",\"emailRequired\",\"truthy\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/expired.hbs"
    }
  });

  _exports.default = _default;
});