define("ember-rs/locales/de/templates/components/hybris-trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialBanner = void 0;
  var hybrisTrialBanner = {
    "billingInfo": "Ihre Abrechnungsinformationen und Abonnements sind nicht aktuell.",
    "update": "Zum Aktualisieren hier klicken",
    "hybrisTrialMsg": "Die Testversion steht Ihnen noch {{{numberOfDaysLeft}}} Tag(e) zur Verfügung. Führen Sie ein <a href='/upgrade-worldpay'>Upgrade</a> auf ein kostenpflichtiges Abonnement durch, um den Dienst weiterhin zu verwenden.",
    "premiumTrialMsg": "You are on your Premium Trial. Please contact 1-800-441-3453 or Email us at <a href='mailto:{{{email}}}'>{{{email}}}</a> to upgrade and go completely paperless."
  };
  _exports.hybrisTrialBanner = hybrisTrialBanner;
});