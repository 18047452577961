define("ember-rs/locales/nl/templates/document/details", ["exports", "ember-rs/locales/nl/templates/document/details/unlock"], function (_exports, _unlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    unlock: _unlock.unlock,
    "status": "Status",
    "overview": "Overzicht",
    "history": "Geschiedenis",
    "people_involved": "Betrokken personen:",
    "manage": "Beheren",
    "security_options": "Beveiligingsopties:",
    "declined": "Afgewezen",
    "expired": "Verlopen",
    "expires": "Verloopt",
    "extend": "Uitbreiden",
    "pin": "PIN *****",
    "generate_pin": "Nieuwe PIN genereren",
    "pin_tip": "Als u een nieuwe PIN genereert, wordt er een nieuwe gemaakt, waardoor de huidige PIN ongeldig wordt.",
    "passcode_question": "Wachtwoordcodevraag:",
    "clear_attempts": "Invoerpogingen wachtwoordcode wissen",
    "passcode": "Wachtwoordcode",
    "signer_sequencing": "Volgorde van ondertekenaars",
    "lock_message": "Dit document is vergrendeld.",
    "attachments": "Bijlagen:",
    "reference_id": "Referentie-id: ",
    "tags": "Tags: ",
    "remind_header": "Ondertekeningskoppeling verzonden",
    "remind_body": "We hebben de koppeling om dit document te ondertekenen naar het onderstaande e-mailadres verzonden. Om deze stap in de toekomst over te slaan, kunt u dit e-mailadres verifiëren op uw instellingenpagina.",
    "new_pin": "Nieuwe PIN",
    "new_passcode": "Hier is uw nieuwe PIN. U moet deze bijgewerkte PIN naar de ondertekenaars van het document sturen, zodat ze toegang hebben tot het document.",
    "done": "Gereed",
    "draft": "Concept",
    "pending": "In behandeling",
    "voided": "Nietig verklaard",
    "executed": "Ondertekend",
    "recipient_message": "Bericht aan ontvanger(s):",
    "void_reason": "Reason for Voiding:",
    "document_voided": "Document Voided:",
    "document_declined": "Decline Reason:"
  };
  _exports.details = details;
});