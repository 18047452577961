define("ember-rs/locales/es/templates/components/add-role-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoleField = void 0;
  var addRoleField = {
    "name": "Nombre...",
    "emailOptional": "Correo electrónico (optativo)...",
    "email": "Correo electrónico...",
    "nameTip": "Se necesita el nombre completo.",
    "emailTip": "Se necesita una dirección de correo electrónico válida.",
    "roleTip": "Se necesita el nombre del rol."
  };
  _exports.addRoleField = addRoleField;
});