define("ember-rs/helpers/is-valid-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidEmail = isValidEmail;
  _exports.default = void 0;
  var emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  function isValidEmail(input) {
    var value = Ember.isArray(input) ? input[0] : input;
    return emailPattern.test(value);
  }

  var _default = Ember.Helper.helper(isValidEmail);

  _exports.default = _default;
});