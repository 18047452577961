define("ember-rs/locales/en/templates/components/rs-left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsLeftNav = void 0;
  var rsLeftNav = {
    "Documents": "Documents",
    "Templates": "Templates",
    "Account": "Account",
    "Settings": "Settings",
    "Integrations": "Integrations",
    "Branding": "Branding",
    "Users": "Users",
    "Reports": "Reports",
    "Api": "API",
    "Billing": "Billing",
    "Workflows": "Feedback & Approval"
  };
  _exports.rsLeftNav = rsLeftNav;
});