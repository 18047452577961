define("ember-rs/locales/es/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "title": "Condiciones de servicio",
    "body": "Mediante el uso de RightSignature&trade; usted certifica que ha leído y está de acuerdo con nuestros {{partial 'terms-of-use-link'}} y la <a href='{{csgHomePage}}/privacy-policy' target='_blank'>Directiva de privacidad</a>.",
    "noThanks": "No, gracias",
    "iAgree": "Acepto"
  };
  _exports.agreeToTerms = agreeToTerms;
});