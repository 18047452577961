define("ember-rs/locales/de/templates/reusable-templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTrans = void 0;
  var newTrans = {
    "upload": "Upload",
    "dragDrop": "Dateien hierher ziehen oder:",
    "uploading": "Hochladen",
    "uploadFile": "Datei hochladen",
    "chooseCloud": "In der Cloud auswählen",
    "prepare": "Dokument vorbereiten"
  };
  _exports.newTrans = newTrans;
});