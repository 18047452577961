define("ember-rs/locales/ja/templates/components/unconfirmed-user-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedUserButton = void 0;
  var unconfirmedUserButton = {
    "emailUnverified": "メール アドレスは未確認です",
    "verifySendDocument": "最初のドキュメントを送信する前に、メール アドレスを確認する必要があります。",
    "verifyCreateTemplate": "最初のテンプレートを作成する前に、メール アドレスを確認する必要があります。",
    "verifyUpgrade": "hybris アカウントをアップグレードする前に、メール アドレスを確認する必要があります。",
    "verifyIntegration": "統合を有効にする前に、メール アドレスを確認する必要があります。",
    "verifyEmail": "確認メールは以下のアドレスに送信済みです。",
    "checkEmail": "メールが見つからない場合、迷惑メール フォルダー内を確認するか、または",
    "resend": "再送信",
    "verificationMail": "確認メールが送信されました。{{{email}}} を確認し、指示に従ってください。"
  };
  _exports.unconfirmedUserButton = unconfirmedUserButton;
});