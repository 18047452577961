define("ember-rs/templates/components/delete-email-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rl8BbweH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\"],[\"applicationModals\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"persistent-modal\",[],[[\"@class\",\"@cancel\",\"@save\",\"@cancelText\",\"@saveText\",\"@loading\"],[\"persistent-modal--is-half-width\",[30,[36,0],[\"closeModal\"],null],[30,[36,1],[[32,0],\"deleteEmail\"],null],[30,[36,2],[\"shared.actions.cancel\"],null],[30,[36,2],[\"shared.actions.delete\"],null],[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h3\"],[14,0,\"modal-heading\"],[12],[1,[30,[36,2],[\"shared.headers.confirm-deletion\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"components.delete-email-verification.confirm-delete-email\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"email\"],[12],[1,[35,4,[\"email\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[\"components.delete-email-verification.confirm-delete-warning\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"action\",\"t\",\"loading\",\"emailVerification\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/delete-email-verification.hbs"
    }
  });

  _exports.default = _default;
});