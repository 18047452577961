define("ember-rs/routes/document-transaction/review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Review Document',
    beforeModel: function beforeModel() {
      this.controllerFor('document-transaction').set('currentStep', 'review & send');
      this.modelFor('document-transaction').reload();
    },
    afterModel: function afterModel(documentTransaction) {
      if (!Ember.get(this, 'currentUser.canSendDocuments') && documentTransaction.get('workflow') !== 'self_sign') {
        this.transitionTo('documents');
        return;
      }

      if (documentTransaction.get('isInPerson') || documentTransaction.get('isSelfSign')) {
        // Force distribution method to manual
        documentTransaction.get('documents').forEach(function (doc) {
          doc.set('distributionMethod', 'manual');
          doc.set('identityMethod', 'none');
        });
      }

      if (documentTransaction.get('isPackage')) {
        documentTransaction.get('documents').forEach(function (doc) {
          doc.set('name', '');
        });
      }
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.setProperties({
          showBulkSendConfirmationModal: false
        });
      }
    }
  });

  _exports.default = _default;
});