define("ember-rs/templates/onboarding/send-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Br7wcPz3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"onboarding.sendOnboarding1.greatWork\"],null]],[13],[2,\"\\n\"],[6,[37,4],[[35,3,[\"model\",\"account\",\"isTrial\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"onboarding.sendOnboarding1.youHave\"],null]],[1,[34,1]],[2,\" \"],[1,[30,[36,2],[[35,1],\"document\"],null]],[2,\" \"],[1,[30,[36,0],[\"onboarding.sendOnboarding1.remaining\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,3,[\"model\",\"account\",\"isHybrisTrial\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"onboarding.sendOnboarding1.youHave\"],null]],[1,[34,1]],[2,\" \"],[1,[30,[36,2],[[35,1],\"document\"],null]],[2,\" \"],[1,[30,[36,0],[\"onboarding.sendOnboarding1.remaining\"],null]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"documentsRemaining\",\"pluralize\",\"currentUser\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/send-onboarding-1.hbs"
    }
  });

  _exports.default = _default;
});