define("ember-rs/locales/zh-cn/templates/onboarding/post-sign-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding2 = void 0;
  var postSignOnboarding2 = {
    "viewDoc": "您可以在此处看到文档的<span>状态</span>",
    "downloadDoc": "您可以在此处<span>下载</span>文档。"
  };
  _exports.postSignOnboarding2 = postSignOnboarding2;
});