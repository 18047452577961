define("ember-rs/templates/components/salesforce-opportunity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h34lm8qt",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"],[11,\"button\"],[4,[38,1],[[32,0],\"addSalesforceOpportunity\"],null],[12],[1,[30,[36,2],[\"shared.actions.add\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"formattedName\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/salesforce-opportunity.hbs"
    }
  });

  _exports.default = _default;
});