define("ember-rs/locales/en/templates/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateRow = void 0;
  var reusableTemplateRow = {
    "send": "Send",
    "details": "Details",
    "trialmessageComp": "Your current trial plan only allows you to send {{{documentLimit}}} documents. ",
    "planmessageComp": "Your current plan only allows you to send {{{documentLimit}}} documents. ",
    "upgrade": "Contact us to upgrade your account."
  };
  _exports.reusableTemplateRow = reusableTemplateRow;
});