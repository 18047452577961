define("ember-rs/locales/nl/templates/client-application/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTranslations = void 0;
  var newTranslations = {
    "request_key": "Nieuwe API-sleutel aanvragen",
    "name_tool_tip": "Dit zien gebruikers als u OAuth gebruikt om toegang te vragen tot hun account. Bijvoorbeeld Industry Automative Estimates Generator of WordStar Plugin",
    "name": "Naam:",
    "org_name_tip": "  De naam van uw organisatie",
    "org_name": "Organisatienaam:",
    "description_tip": "Dit zien gebruikers als u OAuth gebruikt om toegang te vragen tot hun account. Bijvoorbeeld, 'Deze toepassing wordt uitsluitend door Industry Automotive gebruikt om kostenramingen binnen onze Enterprise-werkstroom te verzenden' of 'Wordstar-gebruikers kunnen met de WordStar Plugin documenten voor ondertekening via hun RightSignature&trade;-account verzenden.",
    "description": "Beschrijving:",
    "scopes_tip": "  Gewenste toegangsniveau",
    "scopes": "Bereik(en):",
    "app_url_tip": "Dit zien gebruikers als u OAuth gebruikt om toegang te vragen tot hun account. Bijvoorbeeld http://industryauto.com/login of http://wordstarsupportgroup.com/theplugin",
    "app_url": "Toepassings-URL:",
    "redirect_url_tip": "Dit is de standaard terugroepactie voor oauth-autorisatieaanvragen. Dit kan worden opgegeven of worden overschreven in de API-aanroepen. Bijvoorbeeld http://industryauto.com/oauth_callback",
    "redirect_url": "Omleidings-URL:",
    "org_url_tip": "  (optioneel) De website van uw organisatie",
    "org_url": "Organisatie-URL:",
    "Request": "Aanvragen",
    "readOnlyAccess": "Alleen-lezen toegang",
    "readWriteAccess": "Lees- en schrijftoegang"
  };
  _exports.newTranslations = newTranslations;
});