define("ember-rs/locales/nl/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding2 = void 0;
  var accountOnboarding2 = {
    "body": "Geef uw account een aangepaste professionele look door <span>een foto toe te voegen.</span>",
    "mobileBody": "Uw foto wordt weergegeven in de e-mail aan de ontvangers en op de ondertekeningspagina, zodat de ondertekenaars met vertrouwen kunnen ondertekenen."
  };
  _exports.accountOnboarding2 = accountOnboarding2;
});