define("ember-rs/models/account-stat-set", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    documentsSent: (0, _model.attr)('number'),
    documentLimit: (0, _model.attr)('number'),
    templateLimit: (0, _model.attr)('number'),
    templateLimitReached: (0, _model.attr)('boolean'),
    bulkSendLimitReached: (0, _model.attr)('boolean'),
    totalSeats: (0, _model.attr)('number'),
    seatsUsed: (0, _model.attr)('number'),
    canSendDocument: (0, _model.attr)('boolean'),
    canCreateTemplate: (0, _model.attr)('boolean'),
    hasSeats: Ember.computed('seats', 'seatsUsed', function () {
      return this.totalSeats - this.seatsUsed > 0;
    }),
    documentLimitExceeded: Ember.computed('documentLimit', 'documentsSent', function () {
      return this.documentLimit - this.documentsSent <= 0;
    })
  });

  _exports.default = _default;
});