define("ember-rs/routes/documents", ["exports", "lodash", "ember-cli-pagination/remote/route-mixin", "ember-rs/mixins/search-update", "ember-rs/mixins/partial-session", "ember-rs/mixins/track-display-results", "ember-rs/utils/safe-access"], function (_exports, _lodash, _routeMixin, _searchUpdate, _partialSession, _trackDisplayResults, _safeAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var validStates = ['pending', 'executed', 'expired', 'voided', 'declined'];

  var _default = Ember.Route.extend(_routeMixin.default, _searchUpdate.default, _partialSession.default, _trackDisplayResults.default, {
    ////////////////////////  BINDINGS  ////////////////////////
    queryParams: {
      state: {
        refreshModel: true
      },
      template_id: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: false
      },
      account_documents: {
        refreshModel: true
      }
    },
    ////////////////////////  DEFAULTS  ////////////////////////
    page: 1,
    titleToken: 'Documents',
    ////////////////////////  HOOKS  ////////////////////////
    beforeModel: function beforeModel() {
      if (this.isPartialSession) {
        this.transitionTo('login');
      }
    },
    model: function model(params) {
      var isNotPresent = function isNotPresent(value) {
        return _lodash.default.isNull(value) || _lodash.default.isUndefined(value) || value === 'null' || Ember.isBlank(value);
      };

      var validParams = _lodash.default.omitBy(params, isNotPresent);

      if (params.state && !_lodash.default.includes(validStates, params.state) || params.state === "") {
        validParams = _lodash.default.omit(validParams, 'state');
      }

      if (typeof params.template_id === 'undefined' || params.template_id === "") {
        validParams = _lodash.default.omit(validParams, 'template_id');
      }

      return this.findPaged('dashboard-document', validParams);
    },
    setupController: function setupController(controller, model) {
      controller.set('isLoading', false);

      if (controller.state && !_lodash.default.includes(validStates, controller.state)) {
        controller.set('state', null);
      }

      return this._super(controller, model);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.setProperties({
          search: '',
          template_id: ''
        });
      }
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      error: function error(response) {
        var statusCode = (0, _safeAccess.default)(response, '500', 'errors', 0, 'status');

        if (statusCode === '403') {
          this.transitionTo('unauthorized.unauthorized');
        } else if (statusCode === '308') {
          this.transitionTo('unauthorized.expired', {
            queryParams: {
              token: this.get('currentUser.token')
            }
          });
        }
      },
      showDocument: function showDocument(document) {
        this.transitionTo('document.details', document);
      },
      showArchivedDocument: function showArchivedDocument(document) {
        this.transitionTo('archived-document.details', document);
      },
      loading: function loading() {
        if (this.controller) {
          this.set('controller.isLoading', true);
        } // don't bubble up and display spinner


        return false;
      }
    }
  });

  _exports.default = _default;
});