define("ember-rs/routes/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Create Document',
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ////////////////////////  HOOKS  ////////////////////////
    beforeModel: function beforeModel() {
      this.controllerFor('document-transaction').set('currentStep', 'documents');
    },
    setupController: function setupController(controller, model) {
      controller.set('sortedUploads', []);

      if (model.get('isPackage') && Object.keys(model.get('mergeTransaction.uploadSequence')).length > 0) {
        var uploadSequence = model.get('mergeTransaction.uploadSequence');
        var uploadPromises = [];

        for (var key in uploadSequence) {
          uploadPromises[uploadSequence["".concat(key)]] = this.store.findRecord('upload', key);
        }

        Ember.RSVP.Promise.all(uploadPromises).then(function (response) {
          controller.set('sortedUploads', response);
        });
      }

      return this._super(controller, model);
    },
    afterModel: function afterModel(model) {
      if (!Ember.get(this, 'currentUser.canSendDocuments') && model.get('workflow') !== 'self_sign') {
        this.transitionTo('documents');
        return;
      }

      var sharefileRedirect = this.get("session.data.sharefileRedirect");

      if (sharefileRedirect) {
        var itemIds = sharefileRedirect.itemIds;

        if (itemIds) {
          sharefileRedirect.itemIds = null;
          this.session.set('data.sharefileRedirect', sharefileRedirect);
          return model.addSharefileItems(itemIds);
        }
      }
    },
    actions: {
      nextStep: function nextStep() {
        var firstTemplate = this.currentModel.get('sortedDocuments.firstObject');

        if (this.currentModel.get('workflow') == 'bulk_send') {
          this.transitionTo('document.upload_signers', firstTemplate);
        } else {
          this.transitionTo('document.prepare_signers', firstTemplate);
        }
      },
      willTransition: function willTransition() {
        if (!Ember.isBlank(this.controller.get('mergeUploadPoller'))) {
          this.controller.get('mergeUploadPoller').stop();
          this.controller.set('mergeUploadPoller', null);
        }

        this.controller.setProperties({
          'processingMergeUpload': false,
          'shouldPollForMergeUpload': false
        });
      },
      toggleShowTemplatePicker: function toggleShowTemplatePicker() {
        var _this = this;

        var isBulkSend = this.currentModel.get('workflow') == 'bulk_send' ? true : false;
        this.set('controller.loading', true);
        this.store.query('dashboardReusableTemplate', {
          bulk_send: isBulkSend,
          page: 1,
          per_page: 10,
          account_id: this.get('currentUser.account.id')
        }).then(function (results) {
          Ember.setProperties(_this.controller, {
            loading: false,
            showTemplatePicker: true,
            allReusableTemplates: results,
            searchedReusableTemplates: results,
            reusableTemplatesTotalPages: results.meta.total_pages
          });
        });
      },
      searchUpdate: function searchUpdate(query) {
        var _this2 = this;

        var isBulkSend = this.currentModel.get('workflow') == 'bulk_send' ? true : false;
        this.set('controller.loading', true);
        this.store.query('dashboardReusableTemplate', {
          bulk_send: isBulkSend,
          page: 1,
          per_page: 10,
          account_id: this.get('currentUser.account.id'),
          search: query //same API with this extra optional param.

        }).then(function (results) {
          Ember.setProperties(_this2.controller, {
            loading: false,
            searchedReusableTemplates: results,
            reusableTemplatesTotalPages: results.meta.total_pages
          });
        });
      }
    }
  });

  _exports.default = _default;
});