define("ember-rs/components/void-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['void-document-modal'],
    flashMessages: Ember.inject.service(),
    // Params
    document: null,
    close: null,
    voidReason: "",
    actions: {
      close: function close() {
        this.close();
      },
      void: function _void() {
        var _this = this;

        this.set('voiding', true);
        var doc = this.document;
        return doc.void({
          void_reason: this.voidReason
        }).then(function () {
          _this.close();

          _this.flashMessages.success(_this.i18n.t("controllers.document.details.voided"));

          doc.reload();

          _this.set('voiding', false);
        }).catch(function (error) {
          _this.flashMessages.warning(_this.i18n.t('controllers.document.details.voidingFailed', {
            error: error
          })); // handling the error usecase - if user clicks void document on executed document or any other usecase where doucment can not voided.


          _this.set('voiding', false);

          _this.close();

          doc.reload();
        });
      }
    }
  });

  _exports.default = _default;
});