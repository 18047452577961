define("ember-rs/locales/zh-cn/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserForm = void 0;
  var newUserForm = {
    "email": "电子邮件",
    "name": "名称",
    "password": "密码",
    "hidePassword": "隐藏密码",
    "create": "创建帐户",
    "haveAccount": "已有帐户?"
  };
  _exports.newUserForm = newUserForm;
});