define("ember-rs/locales/de/templates/terms-of-use-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.termsOfUseLink = void 0;
  var termsOfUseLink = {
    "terms": "Nutzungsbedingungen",
    "url": "https://www.cloud.com/content/dam/citrix/en_us/documents/buy/enterprise-saas-eusa-de.pdf"
  };
  _exports.termsOfUseLink = termsOfUseLink;
});