define("ember-rs/locales/pt-br/templates/signer/footer-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerDesktop = void 0;
  var footerDesktop = {
    "from": "De",
    "submitSig": "Enviar assinatura",
    "submit": "Enviar",
    "clickSubmit": "Ao clicar em 'Enviar', você concorda com nossos",
    "privacyPolicy": "Política de Privacidade",
    "consent": "Consentimento de assinatura eletrônica",
    "contents": "e o conteúdo deste documento.",
    "left": "esquerda",
    "save": "Salvar",
    "help": "Precisa de ajuda?",
    "saveProgress": "Salvar progresso",
    "savingChanges": "Salvando alterações",
    "field": "campo",
    "fields": "campos"
  };
  _exports.footerDesktop = footerDesktop;
});