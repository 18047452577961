define("ember-rs/locales/pt-br/templates/components/documents/document-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentRow = void 0;
  var documentRow = {
    "signed": "assinado",
    "signer": "Signatário(s)",
    "legacy": " - Doc legado",
    "awaitingSignature": "Aguardando sua assinatura",
    "sign": "assinar",
    "completed": "Concluído ",
    "sent": "Enviado",
    "voided": "Anulado",
    "expired": "Expirado ",
    "auth_failure": "Falha ao autenticar o signatário via sessão: {{{authError}}}"
  };
  _exports.documentRow = documentRow;
});