define("ember-rs/locales/fr/templates/components/certificate-loading-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.certificateLoadingPage = void 0;
  var certificateLoadingPage = {
    "generateCertificate": "Génération d'images de certificat"
  };
  _exports.certificateLoadingPage = certificateLoadingPage;
});