define("ember-rs/routes/document/upload-signers", ["exports", "ember-rs/mixins/prepare-next-route"], function (_exports, _prepareNextRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_prepareNextRoute.default, {
    session: Ember.inject.service(),
    titleToken: 'Upload Signers',
    currentStep: 'upload_signers',
    beforeModel: function beforeModel() {
      this.controllerFor('document-transaction').set('currentStep', this.currentStep);
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.setProperties({
          signerFileName: '',
          fileUploaded: false
        });
      }
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      documents: function documents() {
        this.transitionTo('documents');
      },
      nextStep: function nextStep() {
        var _this = this;

        this.currentTemplate.save().then(function () {
          switch (_this.nextRouteTransition) {
            case 'document-transaction.review':
              _this.transitionTo('document-transaction.review', _this.store.findRecord('document-transaction', _this.get('documentTransaction.id'), {
                reload: true
              }));

              break;

            default:
              _this.transitionTo('document.prepare_document', _this.currentTemplate);

          }
        });
      }
    }
  });

  _exports.default = _default;
});