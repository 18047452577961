define("ember-rs/locales/pt-br/templates/onboarding/send-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding3 = void 0;
  var sendOnboarding3 = {
    "remind": "Selecione <span>Lembrar</span> para notificar novamente os signatários.",
    "void": "Selecione <span>Cancelar</span> para cancelar o documento."
  };
  _exports.sendOnboarding3 = sendOnboarding3;
});