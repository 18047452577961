define("ember-rs/templates/onboarding/prepare-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0go4XLlN",
    "block": "{\"symbols\":[\"componentClass\"],\"statements\":[[10,\"div\"],[14,0,\"onboarding-target-image\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"tool-title theme__toolbar_header\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"components.componentToolbar.annotate\"],null]],[2,\"\\n    \"],[8,\"rs-tooltip\",[],[[\"@isPlacedOnLeft\",\"@class\",\"@message\"],[true,\"header-annotate\",[30,[36,1],[\"components.componentToolbar.annotateTip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"tool-list theme__toolbar__items\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"component-toolbar-menuitem\",[],[[\"@componentClass\",\"@addComponent\"],[[32,1],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding-new/overlay-arrow.svg\"],[14,0,\"onboarding-arrow\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/overlay-arrow.png\"],[15,\"alt\",[30,[36,1],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"onboarding.prepareOnboarding2.useTools\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"addComponent\",\"t\",\"annotationComponents\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/prepare-onboarding-2.hbs"
    }
  });

  _exports.default = _default;
});