define("ember-rs/locales/ja/templates/signer/confirm-identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentity = void 0;
  var confirmIdentity = {
    "confirmed": "ID を確認できました。",
    "submitted": "回答が送信されました。"
  };
  _exports.confirmIdentity = confirmIdentity;
});