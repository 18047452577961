define("ember-rs/models/email-verification", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    email: (0, _model.attr)('string'),
    verified: (0, _model.attr)('boolean'),
    pending: (0, _model.attr)('boolean'),
    expired: (0, _model.attr)('boolean'),
    verifiedAt: (0, _model.attr)('moment-date'),
    userId: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('moment-date'),
    user: (0, _model.belongsTo)('user', {
      async: false
    })
  });

  _exports.default = _default;
});