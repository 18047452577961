define("ember-rs/routes/document-transactions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var _this = this;

      // Create new DocumentTransaction and start sending flow
      this.store.createRecord('document-transaction').save().then(function (dtr) {
        _this.transitionTo('document-transaction.choose_documents', dtr);
      });
    }
  });

  _exports.default = _default;
});