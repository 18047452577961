define("ember-rs/locales/fr/templates/unauthorized/unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unsubscribed = void 0;
  var unsubscribed = {
    "unsubscribed": "Désabonné",
    "unsubscribedTip": "Vous ne recevrez plus de rappels pour ce document. Vous continuerez à recevoir des e-mails pour les autres documents."
  };
  _exports.unsubscribed = unsubscribed;
});