define("ember-rs/locales/de/templates/reusable-template/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "upload": "Upload hinzufügen",
    "what": "Was muss unterschrieben werden?",
    "uploading": "Hochladen",
    "uploadFile": "Hochladen einer Datei",
    "chooseCloud": "In der Cloud auswählen",
    "prepare": "Dokument vorbereiten"
  };
  _exports.edit = edit;
});