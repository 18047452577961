define("ember-rs/locales/de/mixins/integration-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationAuth = void 0;
  var integrationAuth = {
    "unableAuthorize": "Integration kann nicht autorisiert werden!",
    "notComplete": "Autorisierung konnte nicht abgeschlossen werden"
  };
  _exports.integrationAuth = integrationAuth;
});