define("ember-rs/locales/pt-br/templates/onboarding/welcome-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding2 = void 0;
  var welcomeOnboarding2 = {
    "tips": "Acesse seus documentos <span></span> e <span>modelos</span>, <span>gerencie configurações</span>, e encontre <span>tutoriais em vídeoe artigos explicativos</span>."
  };
  _exports.welcomeOnboarding2 = welcomeOnboarding2;
});