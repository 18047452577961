define("ember-rs/locales/pt-br/templates/onboarding/new-user-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboarding = void 0;
  var newUserOnboarding = {
    "title": "Bem vindo ao RightSignature ™",
    "letsPersonalize": "Antes de explorarmos os recursos, vamos personalizar sua conta.",
    "photoText": "Sua foto é usada na página de assinatura do documento. Uma foto adiciona um nível de personalização e autenticidade.",
    "photoSubtext": "Você pode atualizar isso a qualquer momento nas Configurações da sua conta.",
    "sendSign": "Envie e assine seus documentos",
    "sendDoc": "Envie seus documentos para outras pessoas assinem ou assine documentos que precisem da sua assinatura.",
    "knowMore": "Quer saber mais?",
    "viewSupport": "Veja artigos e vídeos explicativos",
    "createTemplates": "Crie modelos para documentos assinados com frequência.",
    "saveTime": "Economize tempo e crie modelos reutilizáveis para seus documentos usados com frequência, incluindo avisos de isenção de responsabilidade, NDAs, formulários fiscais, requisições de funcionários e muito mais.",
    "learnMore": "Quer saber mais?",
    "accessSupport": "Acesse nosso site de suporte <a href='#''></a>, que contém tutoriais em vídeo e dicas úteis para garantir que você aproveite ao máximo o RightSignature™."
  };
  _exports.newUserOnboarding = newUserOnboarding;
});