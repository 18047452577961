define("ember-rs/locales/ja/templates/onboarding/new-user-onboarding-from-sharefile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFromSharefile = void 0;
  var newUserOnboardingFromSharefile = {
    "title": "ドキュメントの送信および署名(&S)",
    "startText": "他のユーザーが署名するためにドキュメントを送信するか、自分の署名が必要なドキュメントに署名します。",
    "clickLearn": "詳細は次をクリックしてください。",
    "addSigner": "署名者を追加",
    "addAnyone": "このドキュメントに記入または署名する必要があるユーザーを追加します。",
    "prepare": "ドキュメントを準備する",
    "placeFields": "署名者が情報を記入したり、ドキュメントに署名する必要があるフィールドを配置します。各署名者にフィールドを割り当てることができます。",
    "sendTrack": "送信および進行状況の追跡",
    "onceSent": "ドキュメントの送信後、ドキュメントの詳細ページで進行状況を追跡できます。詳細ページに移動するには、ドキュメントのダッシュボードで対象ドキュメントをクリックします。"
  };
  _exports.newUserOnboardingFromSharefile = newUserOnboardingFromSharefile;
});