define("ember-rs/locales/zh-cn/templates/onboarding/prepare-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding2 = void 0;
  var prepareOnboarding2 = {
    "useTools": "使用这些工具可借助<span>您自己的签名、文本或选中标记</span>对文档进行批注"
  };
  _exports.prepareOnboarding2 = prepareOnboarding2;
});