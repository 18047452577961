define("ember-rs/locales/ja/templates/components/rs-left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsLeftNav = void 0;
  var rsLeftNav = {
    "Documents": "ドキュメント",
    "Templates": "テンプレート",
    "Account": "アカウント",
    "Settings": "設定",
    "Integrations": "統合",
    "Branding": "ブランド設定",
    "Users": "ユーザー",
    "Reports": "レポート",
    "Api": "API",
    "Billing": "請求",
    "Workflows": "フィードバックと承認"
  };
  _exports.rsLeftNav = rsLeftNav;
});