define("ember-rs/mixins/entitle-sharefile-user", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ////////////////////////  METHODS  ////////////////////////
    entitleSharefileUser: function entitleSharefileUser(sharefileUserId) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/sharefile/users"),
          type: 'POST',
          data: {
            sharefile_user_id: sharefileUserId
          }
        }).then(function (response) {
          _this.store.pushPayload(response);

          _this.currentUser.reloadAccount().then(function () {
            resolve(response);
          });
        }, function (response) {
          reject(response.responseText);
        });
      }, 'entitle sharefile user');
    }
  });

  _exports.default = _default;
});