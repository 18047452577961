define("ember-rs/locales/it/templates/onboarding/new-user-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboarding = void 0;
  var newUserOnboarding = {
    "title": "Ti diamo il benvenuto in RightSignature™",
    "letsPersonalize": "Prima di esplorare le funzionalità, personalizzare l'account.",
    "photoText": "La foto viene utilizzata nella pagina della firma del documento. Aggiunge un livello di personalizzazione e autenticità.",
    "photoSubtext": "È possibile aggiornare questo campo in qualsiasi momento dalle impostazioni dell'account.",
    "sendSign": "Invia e firma documenti",
    "sendDoc": "Inviare i documenti che devono essere firmati da altre persone o firmare eventuali documenti che richiedono la propria firma.",
    "knowMore": "Si desiderano ulteriori informazioni?",
    "viewSupport": "Visualizza articoli e video di supporto",
    "createTemplates": "Creare modelli per documenti firmati frequentemente.",
    "saveTime": "Consente di risparmiare tempo e creare modelli riutilizzabili per i documenti usati di frequente, tra cui atti di rinuncia, accordi di non divulgazione, moduli fiscali, moduli per i dipendenti e altro ancora.",
    "learnMore": "Si desiderano ulteriori informazioni?",
    "accessSupport": "Accedere al nostro <a href='#''>sito di supporto</a>, che contiene esercitazioni video e suggerimenti utili per assicurarsi di ottenere il massimo da RightSignature™."
  };
  _exports.newUserOnboarding = newUserOnboarding;
});