define("ember-rs/components/import-overlay-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['import-overlay-row'],
    store: Ember.inject.service(),
    actions: {
      selectForImport: function selectForImport() {
        this.sendAction('action', this.importableDocument);
      }
    }
  });

  _exports.default = _default;
});