define("ember-rs/mixins/partial-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    partialSession: Ember.inject.service(),
    isPartialSession: Ember.computed('partialSession', function () {
      return this.partialSession.hasValue();
    })
  });

  _exports.default = _default;
});