define("ember-rs/locales/es/controllers/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "upgradeMsg": "Su cuenta no tiene acceso a esta funcionalidad. Por favor, actualice a un plan Pro para comenzar a usar plantillas incrustadas.",
    "templateDeleteMsg": "Plantilla eliminada correctamente.",
    "tagUpdateMsg": "Etiquetas actualizadas correctamente",
    "tagUpdateFailedMsg": "No se pudo actualizar las etiquetas: {{{formattedResponse}}}",
    "templateDeleteConfirm": "¿Seguro que quiere eliminar esta plantilla?",
    "loadSignerLinksFailedMsg": "Error al cargar enlaces del firmante de la plantilla"
  };
  _exports.details = details;
});