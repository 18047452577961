define("ember-rs/locales/fr/templates/components/client-application/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "editKey": "Modifier la clé API ",
    "name_tool_tip": "Présenté aux utilisateurs si vous utilisez OAuth pour demander l'accès à leur compte. Par exemple Industry Automative Estimates Generator ou WordStar Plugin",
    "name": "Nom :",
    "org_name_tip": "  Nom de votre organisation",
    "org_name": "Nom de l'organisation :",
    "description_tip": "Présenté aux utilisateurs si vous utilisez OAuth pour demander l'accès à leur compte. Par exemple « Cette application est exclusivement utilisée par l'industrie automobile pour envoyer des estimations dans notre workflow d'entreprise » ou « WordStar Plugin permet aux utilisateurs de WordStar d'envoyer des documents pour signature via leur compte RightSignature&trade; ».",
    "description": "Description :",
    "app_url_tip": "Présenté aux utilisateurs si vous utilisez OAuth pour demander l'accès à leur compte. Par exemple http://industryauto.com/login ou http://wordstarsupportgroup.com/theplugin",
    "app_url": "URL de l'application :",
    "redirect_url_tip": "Réponse par défaut pour les demandes d'autorisation OAuth. Elle peut être spécifiée ou remplacée dans les appels de l'API. Par exemple, http://industryauto.com/oauth_callback",
    "redirect_url": "URL de redirection :",
    "org_url_tip": "  (facultatif) Site Web de votre organisation",
    "org_url": "URL de l'organisation :",
    "update_success": "Vous avez correctement mis à jour {{{applicationName}}}."
  };
  _exports.edit = edit;
});