define("ember-rs/templates/components/attachment-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rZbo44wc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],[[35,3,[\"filename\"]]],null]],[2,\"\\n  \"],[11,\"i\"],[24,0,\"fa fa-times remove-attachment\"],[4,[38,5],[[32,0],\"removeAttachment\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"loading-spinner\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-paperclip\"],[12],[13],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[10,\"form\"],[15,5,[30,[36,8],[[30,[36,7],[[35,6],\"display:none;\"],null]],null]],[12],[2,\"\\n  \"],[10,\"input\"],[14,0,\"file-uploader\"],[14,3,\"file\"],[14,4,\"file\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fieldLabel\",\"isUploading\",\"if\",\"attachment\",\"truncate\",\"action\",\"showUpload\",\"unless\",\"safe\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/attachment-uploader.hbs"
    }
  });

  _exports.default = _default;
});