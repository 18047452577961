define("ember-rs/locales/pt-br/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationSettingsService = void 0;
  var integrationSettingsService = {
    "requiresInfo": "Esta integração requer algumas informações antes de prosseguir:",
    "authorize": "Autorizar",
    "sending": "Envie seus documentos {{{formattedProviderName}}} para assinatura eletrônica de dentro do RightSignature",
    "archiving": "Envie um pdf de documentos completos para uma pasta específica na sua conta {{{formattedProviderName}}}",
    "contacts": "Disponibilize todos os seus contatos do {{{formattedProviderName}}} para assinatura de documento",
    "payments": "Coloque os componentes de pagamento em seus documentos do RightSignature para levantar e armazenar com segurança informações de cartão de crédito e autorizar cobranças por meio de sua conta do {{{formattedProviderName}}}",
    "fieldsInfo": "Por favor preencha todos os campos"
  };
  _exports.integrationSettingsService = integrationSettingsService;
});