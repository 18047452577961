define("ember-rs/locales/es/templates/components/document/signer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerRow = void 0;
  var signerRow = {
    "pendingIdentityAuthentication": "Autenticación de identidad pendiente",
    "sign": "Firmar",
    "disabledReminder": "Este firmante ha inhabilitado otros recordatorios para este documento",
    "threshold": "Ha excedido el umbral de envío de recordatorios (3 por documento)",
    "signerFailed": "Este firmante ha fallado ",
    "currentlyLocked": "y actualmente está bloqueado sin acceso al documento.",
    "signerLocked": "Este firmante no ha superado la Autenticación basada en conocimientos y su acceso al documento está bloqueado.",
    "remind": "Avisar",
    "resend": "Volver a enviar",
    "createLink": "Crear enlace",
    "copyLink": "Copiar enlace",
    "copyLinkSuccess": "Enlace copiado en el portapapeles",
    "copyLinkError": "No se pudo copiar el enlace al portapapeles",
    "signerAuthFailed": "Error al autenticar al firmante a través de la sesión: {{{error}}}",
    "reminderSuccess": "{{{name}}} ha recibido un recordatorio",
    "reminderFailed": "No se pudo generar un recordatorio para {{{name}}}",
    "generateLinkFailed": "No se pudo generar un enlace de firmante: {{{error}}}",
    "resendIdentityVerification": "Verificación de identidad reenviada",
    "resendingFailed": "No se pudo reenviar la verificación de identidad: {{{error}}}"
  };
  _exports.signerRow = signerRow;
});