define("ember-rs/locales/de/templates/components/unconfirmed-user-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedUserButton = void 0;
  var unconfirmedUserButton = {
    "emailUnverified": "Ihre E-Mail-Adresse ist nicht verifiziert",
    "verifySendDocument": "Bevor Sie Ihr erstes Dokument senden können, müssen wir Ihre E-Mail-Adresse verifizieren.",
    "verifyCreateTemplate": "Bevor Sie Ihre erste Vorlage erstellen können, müssen wir Ihre E-Mail-Adresse verifizieren.",
    "verifyUpgrade": "Bevor Sie Ihr Hybris-Konto aktualisieren können, müssen wir Ihre E-Mail-Adresse verifizieren.",
    "verifyIntegration": "Damit Sie eine Integration aktivieren können, müssen wir zuerst Ihre E-Mail-Adresse verifizieren.",
    "verifyEmail": "Wir haben eine Bestätigungs-E-Mail an die unten angegebene Adresse gesendet.",
    "checkEmail": "Haben Sie die E-Mail nicht erhalten? Überprüfen Sie Ihren Spam-Ordner oder",
    "resend": "Erneut senden",
    "verificationMail": "Bestätigungs-E-Mail wurde gesendet. Bitte überprüfen Sie {{{email}}} und folgen Sie den Anweisungen."
  };
  _exports.unconfirmedUserButton = unconfirmedUserButton;
});