define("ember-rs/locales/it/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archivedMigratedModal = void 0;
  var archivedMigratedModal = {
    "expiredBilling": "Fatturazione scaduta.",
    "updateBillingDetails": "I dettagli del pagamento non sono aggiornati. Fare clic di seguito per aggiornare i dettagli di fatturazione e continuare a utilizzare il nostro servizio.",
    "clickUpdate": "Aggiorna",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>Contattare il supporto</a>",
    "logout": "Scollega"
  };
  _exports.archivedMigratedModal = archivedMigratedModal;
});