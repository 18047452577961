define("ember-rs/locales/zh-cn/templates/document", ["exports", "ember-rs/locales/zh-cn/templates/document/prepare-document", "ember-rs/locales/zh-cn/templates/document/details", "ember-rs/locales/zh-cn/templates/document/prepare-signers-desktop", "ember-rs/locales/zh-cn/templates/document/prepare-signers-mobile", "ember-rs/locales/zh-cn/templates/document/prepare-signers", "ember-rs/locales/zh-cn/templates/document/search-header-mobile", "ember-rs/locales/zh-cn/templates/document/search-header"], function (_exports, _prepareDocument, _details, _prepareSignersDesktop, _prepareSignersMobile, _prepareSigners, _searchHeaderMobile, _searchHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTrans = void 0;
  var documentTrans = {
    details: _details.details,
    prepare_document: _prepareDocument.prepare_document,
    prepare_signers_desktop: _prepareSignersDesktop.prepare_signers_desktop,
    prepare_signers_mobile: _prepareSignersMobile.prepare_signers_mobile,
    prepare_signers: _prepareSigners.prepare_signers,
    search_header_mobile: _searchHeaderMobile.search_header_mobile,
    search_header: _searchHeader.search_header,
    "status": "状态"
  };
  _exports.documentTrans = documentTrans;
});