define("ember-rs/validations/identification", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberCpValidations.buildValidations)({
    dob: {
      description: 'Date of Birth',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
        message: '{description} must be in MM/DD/YYYY format.'
      })]
    },
    document_value: {
      description: 'Identification Number',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    address: {
      description: 'Street Address',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    city: {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    state: {
      description: 'State',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    zipcode: {
      description: 'Zipcode',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    country: {
      description: 'Country',
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^[A-Z]{2}$/,
        message: '{description} must be two capital letters'
      })]
    }
  });

  _exports.default = _default;
});