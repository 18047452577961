define("ember-rs/locales/en/templates/document/details", ["exports", "ember-rs/locales/en/templates/document/details/unlock"], function (_exports, _unlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    unlock: _unlock.unlock,
    "status": "Status",
    "overview": "Overview",
    "history": "History",
    "people_involved": "People Involved:",
    "manage": "Manage",
    "security_options": "Security Options:",
    "declined": "Declined",
    "expired": "Expired",
    "expires": "Expires",
    "extend": "Extend",
    "pin": "PIN  *****",
    "generate_pin": "Generate new PIN",
    "pin_tip": "Generating a new PIN will create a new one, making the current one invalid.",
    "passcode_question": "Passcode Question:",
    "clear_attempts": "Clear Passcode Attempts",
    "passcode": "Passcode",
    "signer_sequencing": "Signer Sequencing",
    "lock_message": "This document has been locked.",
    "attachments": "Attachments:",
    "reference_id": "Reference ID: ",
    "tags": "Tags: ",
    "remind_header": "Signing Link Sent",
    "remind_body": "We sent the link to sign this document to the email address below. To skip this step in the future you can verify this email address on your setting page.",
    "new_pin": "New PIN",
    "new_passcode": "Here is your new PIN. You must send this updated PIN to the document's signers, so they can access it.",
    "done": "Done",
    "draft": "Draft",
    "pending": "Pending",
    "voided": "Voided",
    "executed": "Executed",
    "recipient_message": "Message to Recipient(s):",
    "void_reason": "Reason for Voiding:",
    "document_voided": "Document Voided:",
    "document_declined": "Decline Reason:"
  };
  _exports.details = details;
});