define("ember-rs/locales/de/templates/components/payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentForm = void 0;
  var paymentForm = {
    "name": "Name",
    "firstName": "Vorname...",
    "lastName": "Nachname...",
    "address1": "Adresszeile 1...",
    "address2": "Adresszeile 2...",
    "city": "Stadt",
    "state": "Bundesland/Kanton",
    "zip": "Postleitzahl",
    "country": "Land",
    "address": "Adresse",
    "vatId": "Umsatzsteuer-ID",
    "redirect": "Umleiten...",
    "upgrade": "Upgrade",
    "payUpgrade": "Zahlen und Upgrade ausführen",
    "oops": "Ein Fehler ist aufgetreten",
    "ok": "OK"
  };
  _exports.paymentForm = paymentForm;
});