define("ember-rs/locales/en/templates/signer/footer-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerDesktop = void 0;
  var footerDesktop = {
    "from": "From",
    "submitSig": "Submit Signature",
    "submit": "Submit",
    "clickSubmit": "By clicking 'Submit' you agree to our",
    "privacyPolicy": "Privacy Policy",
    "consent": "E-Sign Consent",
    "contents": ", and the contents of this document.",
    "left": "left",
    "save": "Save",
    "help": "Need Help?",
    "saveProgress": "Save Progress",
    "savingChanges": "Saving Changes",
    "field": "field",
    "fields": "fields"
  };
  _exports.footerDesktop = footerDesktop;
});