define("ember-rs/locales/pt-br/templates/reusable-template/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "upload": "Adicionar upload",
    "what": "O que precisa ser assinado?",
    "uploading": "Carregando",
    "uploadFile": "Carregar um arquivo",
    "chooseCloud": "escolha da nuvem",
    "prepare": "preparar documento"
  };
  _exports.edit = edit;
});