define("ember-rs/locales/fr/templates/signer/passcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcode = void 0;
  var passcode = {
    "enterPin": "Entrez le code PIN.",
    "access": "Entrez le code PIN fourni pour accéder au document.",
    "unlock": "Déverrouiller",
    "docProtected": "Ce document est protégé par un mot de passe.",
    "answer": "répondre...",
    "show": "afficher le code d’accès",
    "continue": "Continuer avec le document",
    "answerQuestion": "Répondez à la question de sécurité ci-dessous pour accéder au document"
  };
  _exports.passcode = passcode;
});