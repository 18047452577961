define("ember-rs/locales/zh-cn/templates/onboarding/send-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding1 = void 0;
  var sendOnboarding1 = {
    "greatWork": "非常棒! 您发送了第一个文档。",
    "youHave": "您的试用期还剩 ",
    "remaining": "。"
  };
  _exports.sendOnboarding1 = sendOnboarding1;
});