define("ember-rs/templates/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r7WAZgnv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"content\"],[15,5,[30,[36,1],[[35,0,[\"contentStyle\"]]],null]],[15,0,[31,[[30,[36,3],[[35,0,[\"showMobileNavMenu\"]],\"show-mobile-nav\"],null],\" \",[30,[36,3],[[35,0,[\"showModal\"]],\"show-modal\"],null],\" \",[30,[36,3],[[35,2,[\"model\",\"account\",\"isTrial\"]],\"user-in-trial\"],null],\" \",[30,[36,3],[[35,2,[\"model\",\"account\",\"isHybrisTrial\"]],\"user-in-trial\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"help\"],[15,5,[30,[36,1],[[35,4]],null]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sticky-footer-container\"],[15,5,[30,[36,1],[[35,0,[\"stickyFooterContainerStyle\"]]],null]],[12],[2,\"\\n      \"],[8,\"info-banner\",[],[[],[]],null],[2,\"\\n      \"],[19,\"support-content\",[]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"layoutSizeData\",\"safe\",\"currentUser\",\"if\",\"helpScrollableContentStyle\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/help.hbs"
    }
  });

  _exports.default = _default;
});