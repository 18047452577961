define("ember-rs/locales/en/templates/email-unverified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailUnverified = void 0;
  var emailUnverified = {
    "verify": "Your email address has not been verified. Please check your email and click the verification link to confirm your account."
  };
  _exports.emailUnverified = emailUnverified;
});