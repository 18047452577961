define("ember-rs/locales/ja/templates/components/reports", ["exports", "ember-rs/locales/ja/templates/components/reports/month-select"], function (_exports, _monthSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    monthSelect: _monthSelect.monthSelect
  };
  _exports.reports = reports;
});