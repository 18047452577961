define("ember-rs/locales/nl/routes/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    "embeddedTemplate": "Uw account heeft geen toegang tot deze functie. Upgrade naar een Pro-abonnement om ingesloten sjablonen te gaan gebruiken.",
    "inPerson": "Uw account heeft geen toegang tot deze functie. Upgrade naar een Pro-abonnement om Persoonlijke ondertekening te kunnen gebruiken."
  };
  _exports.startDocument = startDocument;
});