define("ember-rs/locales/en/controllers/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.help = void 0;
  var help = {
    "success": "Your request was submitted. Please allow 24 hours for a response.",
    "error": "Failed to submit: {{{error}}}"
  };
  _exports.help = help;
});