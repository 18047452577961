define("ember-rs/templates/reports/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+fpc9toV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"report-section bottom-border\"],[12],[2,\"\\n  \"],[8,\"reports/account-document-volume\",[],[[\"@report\"],[[34,0]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"report-section\"],[12],[2,\"\\n  \"],[8,\"reports/account-statistics\",[],[[\"@report\"],[[34,0]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"report-section\"],[12],[2,\"\\n  \"],[8,\"reports/top-users-by-signed-documents\",[],[[\"@report\"],[[34,0]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"report\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/reports/overview.hbs"
    }
  });

  _exports.default = _default;
});