define("ember-rs/locales/es/templates/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detailsPanel = void 0;
  var detailsPanel = {
    "clientId": "ID DE CLIENTE:",
    "clientSecret": "SECRETO DE CLIENTE:",
    "lastViewed": "Visto por última vez: ",
    "fromIp": "desde la IP",
    "hidden": "Oculto por seguridad",
    "reveal": "Revelar secreto",
    "privateToken": "TOKEN DE API PRIVADO:",
    "revealToken": "Revelar token",
    "regenerate": "Regenerar token. ADVERTENCIA: Esta es una acción irreversible",
    "description": "DESCRIPCIÓN:",
    "scope": "ÁMBITO(S):",
    "redirectUri": "URI DE REDIRECCIÓN:",
    "applicationUrl": "URL DE APLICACIÓN:",
    "orgUrl": "URL DE ORGANIZACIÓN:",
    "createdAt": "CREADO:",
    "updatedAt": "ACTUALIZADO:",
    "edit": "editar"
  };
  _exports.detailsPanel = detailsPanel;
});