define("ember-rs/locales/it/templates/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "addRoles": "Aggiungi ruoli",
    "whatRoles": "Di quali ruoli ha bisogno questo modello?",
    "signOrder": "Imposta ordine dei firmatari",
    "uniqueName": "È necessario assegnare nomi di ruolo univoci.",
    "addRole": "Aggiungi ruolo",
    "sendRole": "Aggiungi ruolo mittente",
    "next": "Successivo: Posizionamento dei campi"
  };
  _exports.addRoles = addRoles;
});