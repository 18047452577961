define("ember-rs/locales/fr/controllers/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.help = void 0;
  var help = {
    "success": "Votre demande a été envoyée. Veuillez prévoir 24 heures avant de recevoir une réponse.",
    "error": "Échec de l'envoi : {{{error}}}"
  };
  _exports.help = help;
});