define("ember-rs/locales/it/templates/components/restyle-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.restyleOnboarding = void 0;
  var restyleOnboarding = {
    "welcome": "Benvenuti.",
    "introduceNewLook": "Siamo lieti di presentare il nuovo look di RightSignature, pronto da esplorare.",
    "gotIt": "OK"
  };
  _exports.restyleOnboarding = restyleOnboarding;
});