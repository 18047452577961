define("ember-rs/locales/es/templates/onboarding/send-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding1 = void 0;
  var sendOnboarding1 = {
    "greatWork": "¡Buen trabajo! Ha enviado su primer documento.",
    "youHave": "Le quedan ",
    "remaining": "de prueba."
  };
  _exports.sendOnboarding1 = sendOnboarding1;
});