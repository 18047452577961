define("ember-rs/locales/nl/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "documents": "Documenten",
    "noResults": "Geen zoekresultaten",
    "tryAgain": "Uw zoekopdracht heeft geen resultaten opgeleverd. Probeer een andere zoekopdracht om uw document te vinden.",
    "anotherFilter": "Met het door u gekozen filter zijn geen resultaten gevonden. Probeer een ander filter.",
    "documentsTip": "Hier vindt u de documenten die u hebt ondertekend en die in behandeling zijn. Maak uw eerste document om te beginnen.",
    "questions": "Hebt u een vraag? Hier vindt u meer hulp.",
    "gotIt": "OK!",
    "startDocument": "Document starten",
    "userGuide": "Gebruikershandleiding"
  };
  _exports.documents = documents;
});