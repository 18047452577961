define("ember-rs/locales/zh-cn/templates/components/payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentForm = void 0;
  var paymentForm = {
    "name": "名称",
    "firstName": "名字...",
    "lastName": "姓氏...",
    "address1": "地址行 1...",
    "address2": "地址行 2...",
    "city": "城市",
    "state": "州/省/自治区/直辖市",
    "zip": "邮政编码",
    "country": "国家/地区",
    "address": "地址",
    "vatId": "VAT ID",
    "redirect": "正在重定向...",
    "upgrade": "升级",
    "payUpgrade": "付款和升级",
    "oops": "糟糕! 出现错误",
    "ok": "确定"
  };
  _exports.paymentForm = paymentForm;
});