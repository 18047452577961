define("ember-rs/locales/it/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newClientApplication = void 0;
  var newClientApplication = {
    "appReview": "L'applicazione è in fase di revisione. Informeremo o approveremo la richiesta entro 24 ore.",
    "secretsSuccess": "Segreti recuperati; questi dovrebbero essere usati solo dall'utente o da persone di sua fiducia.",
    "secretsError": "Impossibile recuperare i segreti: {{{error}}}",
    "tokenSuccess": "Nuovo token API privato generato correttamente",
    "tokenError": "Impossibile generare il token: {{{error}}}",
    "singleSecretSuccess": "Segreto recuperato per {{{clientAppName}}}, questo dovrebbe essere usato solo dall'utente o da persone di sua fiducia.",
    "singleSecretError": "Impossibile recuperare il segreto: {{{error}}}",
    "tokenRetrieveSuccess": "Token API privato recuperato per {{{clientAppName}}}; dovrebbe essere utilizzato solo dall'utente o da persone di sua fiducia.",
    "tokenRetrieveError": "Impossibile recuperare il token API privato: {{{error}}}",
    "tokenRegenerateError": "Impossibile rigenerare il token: {{{error}}}",
    "confirmInfo": "Si è certi di voler procedere? Un'alternativa potrebbe essere la richiesta di una nuova chiave API, poiché una volta rigenerati, i token API privati sovrascritti sono irrecuperabili. Se si sceglie di continuare, assicurarsi di rimuovere e sostituire eventuali credenziali obsolete in tutte le proprie integrazioni."
  };
  _exports.newClientApplication = newClientApplication;
});