define("ember-rs/locales/es/mixins/distribution-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.distributionMethod = void 0;
  var distributionMethod = {
    "viaEmail": "Enviar invitación por correo electrónico",
    "manual": "Distribuir enlaces manualmente",
    "email": "Correo electrónico",
    "sms": "SMS",
    "none": "Ninguno"
  };
  _exports.distributionMethod = distributionMethod;
});