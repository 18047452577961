define("ember-rs/locales/de/templates/components/template/template-signer-link-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.templateSignerLinkItem = void 0;
  var templateSignerLinkItem = {
    "view": "Anzeigen",
    "details": "Details",
    "reenable": "Erneut aktivieren",
    "disable": "Deaktivieren",
    "signed": "signiert",
    "identityMethod": "Identitätsmethode: ",
    "expires": "Ablauf: ",
    "passcode": "Passcodegeschützt: ",
    "maxSigners": "Max. Unterzeichner: ",
    "doesNotExpire": "Läuft nicht ab"
  };
  _exports.templateSignerLinkItem = templateSignerLinkItem;
});