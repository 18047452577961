define("ember-rs/locales/es/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareNextRoute = void 0;
  var prepareNextRoute = {
    "mergeFieldStepNext": "Siguiente: Campos de combinación",
    "documentStepNext": "Siguiente documento",
    "reviewStepNext": "Siguiente: Revisar",
    "placeFieldNext": "Siguiente: Colocar campos"
  };
  _exports.prepareNextRoute = prepareNextRoute;
});