define("ember-rs/mixins/reusable-template-choose-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  SERVICES ////////////////////////
    layoutSizeData: Ember.inject.service(),
    wcsWorkflow: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    uploads: Ember.computed(function () {
      return [];
    }),
    ////////////////////////  PROPERTIES  ////////////////////////
    isUploading: Ember.computed.notEmpty('uploads'),
    noUploadSelected: Ember.computed.empty('model.upload'),
    isValid: Ember.computed.equal('model.upload.baseFile.processingState', 'ready'),
    isNotValid: Ember.computed.not('isValid'),
    isWCSWorkflow: Ember.computed(function () {
      return this.wcsWorkflow.getValue();
    }),
    stepStyle: Ember.computed('layoutSizeData.windowWidth', 'layoutSizeData.windowHeight', function () {
      var height = Ember.get(this, 'layoutSizeData.windowHeight') - Ember.get(this, 'layoutSizeData.tmHeaderHeight');
      var width = Ember.get(this, 'layoutSizeData.windowWidth');
      return "height: ".concat(height, "px; width: ").concat(width, "px;").htmlSafe();
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      onUploadUpdated: function onUploadUpdated(upload) {
        if (Ember.get(upload, 'state') === 'upload_done') {
          var defaultName = upload.get('filename').split('.')[0].split(/[^a-zA-Z0-9-]+/).join(' ');
          Ember.setProperties(this, {
            'model.name': defaultName,
            'model.upload': upload
          });
          this.uploads.removeObject(upload);
        }
      },
      removeFile: function removeFile() {
        Ember.get(this, 'model.upload').deleteRecord();
        Ember.set(this, 'model.upload', null);
      }
    }
  });

  _exports.default = _default;
});