define("ember-rs/locales/fr/templates/components/payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentForm = void 0;
  var paymentForm = {
    "name": "Nom",
    "firstName": "Prénom...",
    "lastName": "Nom...",
    "address1": "Adresse ligne 1...",
    "address2": "Adresse ligne 2...",
    "city": "Ville",
    "state": "Département/région",
    "zip": "Code postal",
    "country": "Pays",
    "address": "Adresse",
    "vatId": "N° de TVA",
    "redirect": "Redirection...",
    "upgrade": "Mettre à niveau",
    "payUpgrade": "Payer et mettre à niveau",
    "oops": "Un problème s'est produit",
    "ok": "OK"
  };
  _exports.paymentForm = paymentForm;
});