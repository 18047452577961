define("ember-rs/locales/zh-cn/templates/reusable-templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTrans = void 0;
  var newTrans = {
    "upload": "上载",
    "dragDrop": "将文件拖放到此处或:",
    "uploading": "正在上载",
    "uploadFile": "上载文件",
    "chooseCloud": "从云中选择",
    "prepare": "准备文档"
  };
  _exports.newTrans = newTrans;
});