define("ember-rs/locales/ja/templates/logged-out/marketing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.marketing = void 0;
  var marketing = {
    "note": "RightSignature マーケティング ページへようこそ!"
  };
  _exports.marketing = marketing;
});