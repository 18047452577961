define("ember-rs/locales/it/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alreadySigned = void 0;
  var alreadySigned = {
    "thankYou": "Grazie per la firma",
    "youHave": "La firma del documento è stata completata. È possibile visualizzare il documento in qualsiasi momento richiedendo un collegamento sicuro.",
    "sendNewLink": "Invia nuovo collegamento per il download",
    "successfully": "La firma del documento è stata completata."
  };
  _exports.alreadySigned = alreadySigned;
});