define("ember-rs/locales/de/templates/template", ["exports", "ember-rs/locales/de/templates/template/details", "ember-rs/locales/de/templates/template/search-header-mobile"], function (_exports, _details, _searchHeaderMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    details: _details.details,
    searchHeaderMobile: _searchHeaderMobile.searchHeaderMobile,
    "review": {
      "header-content": "Überprüfen",
      "create-button": "Vorlage erstellen",
      "update-button": "Vorlage aktualisieren"
    },
    "index": {
      "subheader-create-button": "Vorlage erstellen",
      "template-limit-modal": {
        "cancellation-grace-period": "Dieses Konto wurde aufgelöst, daher können Sie keine Vorlagen erstellen",
        "limit-reached": "Mit Ihrem aktuellen Abonnement können Sie nur {{templateLimit}} Vorlagen erstellen. Wenn Sie mehr erstellen möchten, bitten Sie den Support um ein Upgrade Ihres Kontos.",
        "not-enabled": "Mit Ihrem aktuellen Abonnement können Sie keine Vorlagen erstellen. Wenn Sie Vorlagen erstellen möchten, bitten Sie den Support um ein Upgrade Ihres Kontos."
      }
    }
  };
  _exports.template = template;
});