define("ember-rs/controllers/client-applications", ["exports", "jquery", "ember-rs/config/environment", "ember-rs/mixins/pagination"], function (_exports, _jquery, _environment, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUS_PRIORITY_MAP = {
    'approved': 1,
    'pending': 2,
    'banned': 3,
    'rejected': 4
  };

  var _default = Ember.Controller.extend(_pagination.default, {
    ////////////////////////  SERVICES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    queryParams: ['page', 'perPage', {
      account_id: {
        replace: true
      }
    }],
    isLoading: false,
    page: 1,
    perPage: 15,
    totalPages: Ember.computed.alias('model.meta.total_pages'),
    sortedClientApps: Ember.computed.sort('model.[]', function (a, b) {
      var priorityA = STATUS_PRIORITY_MAP[Ember.get(a, 'status')];
      var priorityB = STATUS_PRIORITY_MAP[Ember.get(b, 'status')];

      if (priorityA < priorityB || priorityA === priorityB && Ember.get(a, 'createdAt') > Ember.get(b, 'createdAt')) {
        return -1;
      }

      return 1;
    }),
    ////////////////////////  Methods  ////////////////////////
    scrollTo: function scrollTo(page) {
      Ember.set(this, 'scrollToPage', page);
    },
    ////////////////////////  Actions  ////////////////////////
    actions: {
      revealSecret: function revealSecret(id) {
        var _this = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/client_applications/").concat(id, "/secret"),
          type: 'GET',
          success: function success(data) {
            var clientApp = _this.model.findBy('id', id);

            _this.flashMessages.success(_this.i18n.t('controllers.clientApplications.newClientApplication.singleSecretSuccess', {
              clientAppName: Ember.get(clientApp, 'name')
            }));

            Ember.set(clientApp, 'secret', data.secret);
          },
          error: function error(data) {
            _this.flashMessages.warning(_this.i18n.t('controllers.clientApplications.newClientApplication.singleSecretError', {
              error: data.responseJSON.error
            }));
          }
        });
      },
      revealPrivateToken: function revealPrivateToken(id) {
        var _this2 = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/client_applications/").concat(id, "/private_api_token"),
          type: 'GET',
          success: function success(data) {
            var clientApp = _this2.model.findBy('id', id);

            _this2.flashMessages.success(_this2.i18n.t('controllers.clientApplications.newClientApplication.tokenRetrieveSuccess', {
              clientAppName: Ember.get(clientApp, 'name')
            }));

            Ember.set(clientApp, 'privateToken', data.private_api_token);
          },
          error: function error(data) {
            _this2.flashMessages.warning(_this2.i18n.t('controllers.clientApplications.newClientApplication.tokenRetrieveError', {
              error: data.responseJSON.error
            }));
          }
        });
      },
      regenerateToken: function regenerateToken(id) {
        var _this3 = this;

        if (confirm(this.i18n.t('controllers.clientApplications.newClientApplication.confirmInfo'))) {
          var clientApp = this.model.findBy('id', id);
          Ember.set(clientApp, 'privateToken', null);

          _jquery.default.ajax({
            url: "".concat(_environment.default.api.endpoint, "/client_applications/").concat(id, "/generate_new_token"),
            type: 'POST',
            success: function success(data) {
              _this3.flashMessages.success(_this3.i18n.t('controllers.clientApplications.newClientApplication.tokenSuccess'));

              clientApp.setProperties({
                hasGeneratedToken: true,
                privateToken: data.private_api_token
              });
            },
            error: function error(data) {
              _this3.flashMessages.warning(_this3.i18n.t('controllers.clientApplications.newClientApplication.tokenRegenerateError', {
                error: data.responseJSON.error
              }));
            }
          });
        }
      },
      setCurrentPage: function setCurrentPage(page) {
        this.set('page', page);
      }
    }
  });

  _exports.default = _default;
});