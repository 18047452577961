define("ember-rs/templates/components/attached-completed-pdf-confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZNCNPu3w",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"attached-completed-pdf-confirmation-modal__header\"],[12],[1,[30,[36,0],[\"components.attachedCompletedPdfConfirmationModal.header\"],null]],[13],[2,\"\\n\\n\"],[10,\"p\"],[14,0,\"attached-completed-pdf-confirmation-modal__info\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.attachedCompletedPdfConfirmationModal.info\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[14,0,\"attached-completed-pdf-confirmation-modal__info_secondary\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.attachedCompletedPdfConfirmationModal.infoSecondary\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"attached-completed-pdf-confirmation-modal__actions\"],[12],[2,\"\\n  \"],[8,\"rs-button\",[],[[\"@action\"],[[30,[36,1],[[32,0],\"onConfirm\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.attachedCompletedPdfConfirmationModal.confirm\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"rs-button\",[],[[\"@action\",\"@isPrimary\"],[[30,[36,1],[[32,0],\"close\"],null],false]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.attachedCompletedPdfConfirmationModal.cancel\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/attached-completed-pdf-confirmation-modal.hbs"
    }
  });

  _exports.default = _default;
});