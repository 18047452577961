define("ember-rs/locales/es/templates/onboarding/send-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding3 = void 0;
  var sendOnboarding3 = {
    "remind": "Seleccione <span>Recordar</span> para notificar de nuevo a los firmantes.",
    "void": "Seleccione <span>Nulo</span> para cancelar el documento."
  };
  _exports.sendOnboarding3 = sendOnboarding3;
});