define("ember-rs/templates/components/reports/top-users-by-efficiency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mb8glJaR",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[10,\"div\"],[14,0,\"report-section\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"reports.components.top-users-by-efficiency.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"description\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"reports.components.top-users-by-efficiency.description\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"table\"],[14,0,\"statistics-table\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[1,[30,[36,1],[\"reports.components.top-users-by-efficiency.column-header-user\"],null]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[30,[36,1],[\"reports.components.top-users-by-efficiency.column-header-efficiency-percentage\"],null]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[30,[36,1],[\"reports.components.top-users-by-efficiency.column-header-ratio\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n          \"],[10,\"td\"],[12],[1,[30,[36,0],[[32,1,[\"percentage\"]]],null]],[2,\"%\"],[13],[2,\"\\n          \"],[10,\"td\"],[12],[1,[30,[36,1],[\"reports.components.top-users-by-efficiency.over-gcd\"],[[\"signed\",\"sent\"],[[32,1,[\"signedOverGCD\"]],[32,1,[\"sentOverGCD\"]]]]]],[2,\" \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"round\",\"t\",\"topUsers\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/reports/top-users-by-efficiency.hbs"
    }
  });

  _exports.default = _default;
});