define("ember-rs/locales/de/templates/onboarding/prepare-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTutorial = void 0;
  var prepareTutorial = {
    "title": "Vorbereiten eines Dokuments für die Signatur",
    "selectField": "Wählen Sie auf der rechten Seite das Feld aus, das Sie hinzufügen möchten.",
    "placeField": "Platzieren des Felds",
    "clickPlace": "Klicken Sie auf die Stelle, an der Sie das neue Feld platzieren möchten.",
    "adjustSize": "Anpassen der Feldgröße",
    "adjustHover": "Platzieren Sie den Mauszeiger über dem Feld und ziehen Sie die Punkte.",
    "adjustOptions": "Anpassen der Optionen",
    "moreOptions": "Klicken Sie auf das Feld, um weitere Optionen anzuzeigen, und wählen Sie, wer das Feld ausfüllen soll.",
    "thatsIt": "Fertig!",
    "clickReview": "Wenn Sie fertig sind, klicken Sie auf <b>Nächster Schritt: Überprüfen</b>",
    "knowMore": "Möchten Sie mehr erfahren?",
    "support": "Supportartikel und Videos anzeigen"
  };
  _exports.prepareTutorial = prepareTutorial;
});