define("ember-rs/templates/components/payment-component-card-capture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IAZJ87oq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"form\"],[14,1,\"payment_form\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,1,\"card_element\"],[14,0,\"field\"],[12],[2,\"\\n      \"],[3,\" a Stripe Element will be inserted here. \"],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[3,\" Used to display Element errors \"],[2,\"\\n    \"],[10,\"div\"],[14,1,\"card_errors\"],[14,\"role\",\"alert\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"button\"],[4,[38,0],[[32,0],\"save\"],null],[12],[1,[30,[36,1],[\"shared.actions.save\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"is-secondary\"],[4,[38,0],[[32,0],\"cancel\"],null],[12],[1,[30,[36,1],[\"shared.actions.cancel\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/payment-component-card-capture.hbs"
    }
  });

  _exports.default = _default;
});