define("ember-rs/locales/en/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    "choose": "3. Choose the data to export",
    "columnNames": "The column names are based on the names of the components in the document:",
    "export": "4. Export to CSV",
    "selectColumns": "Select columns",
    "view": "A CSV can be opened in Excel to view data on your sent documents."
  };
  _exports.template = template;
});