define("ember-rs/locales/de/templates/onboarding/signer-onboarding-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboardingOld = void 0;
  var signerOnboardingOld = {
    "title": "Das Signieren eines Dokuments mit RightSignature&trade; ist spielend einfach!",
    "completefields": "Füllen Sie die Felder aus, die mit einer Markierung gekennzeichnet sind.",
    "submitSignature": "Füllen Sie die Felder aus und senden Sie Ihre Unterschrift.",
    "progress": "Anhand der Fortschrittsanzeige sehen Sie, wie weit Sie sind. Wenn Sie alle erforderlichen Felder ausgefüllt haben, klicken Sie auf die Schaltfläche 'Unterschrift senden', um die Unterzeichnung abzuschließen.",
    "help": "Benötigen Sie Hilfe?",
    "questions": "Wenn Sie eine Frage haben oder weitere Informationen benötigen, erhalten Sie hier Hilfe."
  };
  _exports.signerOnboardingOld = signerOnboardingOld;
});