define("ember-rs/locales/it/templates/unauthorized/unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unsubscribed = void 0;
  var unsubscribed = {
    "unsubscribed": "Iscrizione annullata",
    "unsubscribedTip": "La ricezione di promemoria per questo documento è stata rimossa. Si continuerà comunque a ricevere e-mail per altri documenti."
  };
  _exports.unsubscribed = unsubscribed;
});