define("ember-rs/components/document-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    classNameBindings: [':history'],
    sorting: ['created_at:desc'],
    sortedAudits: Ember.computed.sort('document.audits', 'sorting')
  });

  _exports.default = _default;
});