define("ember-rs/locales/it/templates/onboarding/prepare-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding3 = void 0;
  var prepareOnboarding3 = {
    "seeSigners": "<span>Visualizzare i firmatari</span> per questo documento. Ogni firmatario ha il proprio colore, che corrisponde ai campi da completare"
  };
  _exports.prepareOnboarding3 = prepareOnboarding3;
});