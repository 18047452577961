define("ember-rs/locales/it/templates/onboarding/signer-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding1 = void 0;
  var signerOnboarding1 = {
    "redFlag": "La <span>bandiera rossa</span> indica le aree che devono essere completate.",
    "zoom": "Usa il movimento di avvicinamento e allontanamento delle dita sul telefono per ingrandire il documento."
  };
  _exports.signerOnboarding1 = signerOnboarding1;
});