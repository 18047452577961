define("ember-rs/components/rs-more-options-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['rs-more-options-mobile'],
    classNameBindings: ['isToggled:is-toggled'],
    isToggled: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      document.addEventListener('click', function () {
        if (_this.isToggled) {
          _this.toggleProperty('isToggled');
        }
      });
    },
    actions: {
      onClickMobileMoreOptions: function onClickMobileMoreOptions(event) {
        this.toggleProperty('isToggled');
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});