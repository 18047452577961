define("ember-rs/locales/zh-cn/controllers/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "standardInfo": "您选择的计划仅支持 1 个用户。如果您的帐户需要更多用户，请选择 Advanced 计划。",
    "migratedInfo": "由于您的试用帐户已用完 {{{minimumSeats}}} 个席位，因此请至少选择 {{{minimumSeats}}} 个用户。否则，请与支持人员联系以获得更多升级方面的帮助",
    "planInfo": "您选择的计划包括 3 个用户。如果需要，请在下面选择更多。",
    "validUsers": "请输入有效的用户数"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});