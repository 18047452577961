define("ember-rs/locales/de/templates/onboarding/new-user-onboarding-from-sharefile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFromSharefile = void 0;
  var newUserOnboardingFromSharefile = {
    "title": "Senden und Signieren von Dokumenten",
    "startText": "Senden Sie Dokumente, um sie von anderen unterschreiben zu lassen, oder signieren Sie alle Dokumente, die Ihre Unterschrift erfordern.",
    "clickLearn": "Klicken Sie auf 'Weiter', um mehr zu erfahren.",
    "addSigner": "Unterzeichner hinzufügen",
    "addAnyone": "Fügen Sie Personen hinzu, die dieses Dokument ausfüllen oder signieren müssen.",
    "prepare": "Dokument vorbereiten",
    "placeFields": "Platzieren Sie Felder, wo Unterzeichner Informationen angeben oder das Dokument unterschreiben müssen. Sie können jedem Unterzeichner Felder zuweisen.",
    "sendTrack": "Senden und Fortschritt verfolgen",
    "onceSent": "Wenn Sie das Dokument gesendet haben, können Sie den Fortschritt auf der Dokumentdetailseite verfolgen. Um zur Detailseite zu gelangen, klicken Sie einfach auf das Dokument in Ihrem Dokumentdashboard."
  };
  _exports.newUserOnboardingFromSharefile = newUserOnboardingFromSharefile;
});