define("ember-rs/templates/components/terms-of-use-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ULSIePYu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,6,[31,[[30,[36,0],[\"termsOfUseLink.url\"],null]]]],[14,\"target\",\"blank\"],[12],[1,[30,[36,0],[\"termsOfUseLink.terms\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/terms-of-use-link.hbs"
    }
  });

  _exports.default = _default;
});