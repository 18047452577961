define("ember-rs/locales/nl/mixins/distribution-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.distributionMethod = void 0;
  var distributionMethod = {
    "viaEmail": "Uitnodiging via e-mail verzenden",
    "manual": "Koppelingen handmatig distribueren",
    "email": "E-mail",
    "sms": "SMS",
    "none": "Geen"
  };
  _exports.distributionMethod = distributionMethod;
});