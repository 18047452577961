define("ember-rs/locales/pt-br/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingComplete = void 0;
  var signingComplete = {
    "docSigned": "Documento assinado!",
    "createTrial": "Crie uma conta gratuita do RightSignature para visualizar ou baixar este documento preenchido. Você terá acesso a este documento para sempre, basta você se registrar com o endereço de email para o qual o documento foi enviado e fazer logon.",
    "createAccount": "Criar uma conta",
    "login": "Entrar na conta",
    "notNow": "Não agora"
  };
  _exports.signingComplete = signingComplete;
});