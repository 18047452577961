define("ember-rs/locales/es/templates/reports/export/month", ["exports", "ember-rs/locales/es/templates/reports/export/month/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.month = void 0;
  var month = {
    template: _template.template,
    "header": "Filtrar por plantilla",
    "description": "Seleccione la plantilla por la que filtraremos los documentos",
    "template-select-text": "Elija una plantilla"
  };
  _exports.month = month;
});