define("ember-rs/locales/fr/templates/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faqModal = void 0;
  var faqModal = {
    "docId": "ID du document :",
    "contactSender": "Contactez l'expéditeur, {{{userName}}}, à <a href='mailto: {{{userEmail}}}'>{{{userEmail}}}</a> si vous avez des questions",
    "needHelp": "Vous avez besoin d'aide pour utiliser RightSignature&trade; ? Consultez ces questions fréquemment posées :",
    "q1": "J'ai une question sur le contenu de ce document :",
    "rsNote": "RightSignature&trade; est un service tiers de signature de documents en ligne. Nous facilitons l'envoi et la signature de documents avec des signatures électroniques légales et sécurisées, mais nous ne sommes en aucun cas associés à l'expéditeur ou au document.",
    "q2": "Comment remplir ce document ?",
    "a2": "La flèche rouge à gauche du document vous indique le prochain champ obligatoire. Une fois que tous les champs obligatoires sont remplis, le bouton « Envoyer » devient disponible.",
    "q3": "Est-ce juridiquement valable ?",
    "a3": "Oui. Les documents exécutés en ligne avec des signatures électroniques sont valables d'un point de vue juridique et ont le même statut juridique que les documents papier exécutés à la main. Il est fort probable que vous vous ayez déjà souscrit un contrat en ligne. Par exemple, lorsque vous cliquez sur un bouton « J'accepte » sur Internet, vous êtes généralement partie à ce que l'on appelle un contrat au clic (Clickwrap). De plus, lorsque vous achetez des biens en ligne, vous êtes partie à un contrat juridiquement contraignant.",
    "a3part2": "La technologie de RightSignature&trade; se conforme pleinement à la loi américaine Electronic Signatures in Global and National Commerce Act (ESIGN) entrée en vigueur 2000, à la loi Uniform Electronic Transactions Act (UETA), ainsi qu'à la directive européenne (EC/1999/93). Toutes ces lois ont pour but d’encourager l’adoption rapide de signatures électroniques et de réduire l’utilisation des méthodes papier désuètes. Chacune d'elle renforce la validité des contrats électroniques. Selon ESIGN, on ne peut nier ni l'effet juridique ni la force exécutoire d'un enregistrement ou d'une signature du seul fait que ces derniers sont sous forme électronique.",
    "q4": "Qu'est-ce que RightSignature™ ?",
    "a4": "RightSignature&trade; vous permet de créer et de signer des documents juridiquement contraignants en ligne, de manière plus rapide et plus sécurisée qu'avec des documents papier. RightSignature&trade; est un Saas Web qui utilise l'infrastructure de données sécurisée de Amazon Web Services.",
    "q5": "À quoi RightSignature™ sert-il ?",
    "a5": "RightSignature&trade; vous permet de créer et de signer des documents juridiquement contraignants en ligne, de manière plus rapide et plus sécurisée qu'avec des documents papier. RightSignature&trade; est un Saas Web qui utilise l'infrastructure de données sécurisée de Amazon Web Services.",
    "q6": "Comment RightSignature™ permet-il de signer des documents en ligne ?",
    "a6": "RightSignature&trade; facilite l'obtention de signatures auprès de plusieurs parties en ligne. Il suffit pour cela de charger votre document et d'entrer les informations du destinataire. RightSignature&trade; envoie aux destinataires un lien unique vers une page sur laquelle ils peuvent consulter le document, remplir les champs de texte obligatoires et signer. Il leur suffit de quelques secondes pour signer le document.",
    "a6part2": "Aujourd'hui, la plupart des entreprises signent des documents par télécopie, par service de courrier rapide ou en numérisant et en envoyant par e-mail des pages pour signature. RightSignature&trade; élimine les contraintes, les dépenses et les délais associés à ces méthodes fastidieuses et désuètes. L'exécution d'un document juridiquement contraignant et son stockage sécurisé sur un serveur sécurisé peuvent être réalisés en quelques minutes et en quelques clics de souris.",
    "q7": "N'est-il pas plus sûr de signer un document papier ?",
    "a7": "Les contrats électroniques sont beaucoup plus sécurisés que les contrats sur papier et ont le même statut juridique. Alors que les documents envoyés par télécopie et par courrier sont exposés tout au long de la distribution inter-services, les documents électroniques sont traités en toute confidentialité et sont uniquement consultés par les destinataires désignés. Les documents RightSignature&trade; sont stockés sur des serveurs sécurisés avec des copies redondantes et, contrairement aux documents papier, ils ne peuvent pas être égarés, perdus ou détruits. En outre, les accusés de réception, les enregistrements IP et les données de signature biométrique permettent d’authentifier chaque signataire.",
    "q8": "A qui s'adresse ce service ?",
    "a8": "RightSignature&trade; est idéal pour les PME, les indépendants, les designers, les développeurs, les avocats, les consultants, les écoles, les organisations à but non lucratif et bien plus encore. Si votre travail nécessite des documents signés, RightSignature&trade; est la solution qu'il vous faut.",
    "q9": "Quels types de documents dois-je envoyer ?",
    "a9": "Les documents suivants sont régulièrement envoyés sur RightSignature&trade; : contrats, accords de non-divulgation, acceptation d'offres, rectificatifs de commande, épreuves publicitaires, lettres de mission, notes de frais, bons de commande, contrats de location, contrats de prestataire de service, nouvelles candidatures de client, demandes d'emplois et bien plus encore. Les formulaires de conformité tels que le W-9, le W-4 et le I-9 sont idéaux pour RightSignature&trade;. Vous souhaitez que tous les membres d'une équipe approuvent un changement de stratégie ? Rien de plus facile avec RightSignature&trade;. Les entreprises utilisent RightSignature&trade; pour envoyer tout type de document, des approbations de feuilles de temps aux contrats de plusieurs millions de dollars.",
    "q10": "Quels sont les navigateurs pris en charge ?",
    "a10": "RightSignature&trade; garantit une expérience d'utilisation optimale sur les navigateurs suivants. Pour mettre à niveau votre navigateur vers la dernière version, visitez :",
    "a10part2": "Mozilla Firefox 3+, Microsoft Internet Explorer 9+, Apple Safari 3+, Google Chrome, Opera 9+",
    "q11": "Que dois-je faire si je ne peux pas me connecter ?",
    "a11": "Vérifiez votre adresse e-mail pour vous assurer de ne pas avoir fait de faute lors de sa saisie. Tapez également votre mot de passe lentement et attentivement. Si cela ne fonctionne pas, vous pouvez réinitialiser votre mot de passe en cliquant sur « J'ai oublié mon mot de passe ». Si vous suivez le processus de réinitialisation du mot de passe et que vous ne pouvez toujours pas vous connecter, veuillez nous contacter pour obtenir de l'aide.",
    "q12": "Les destinataires ont-ils besoin d'un compte RightSignature™ ?",
    "a12": "Non. Les destinataires n'ont pas besoin d'avoir un compte pour recevoir un document de votre part. Le nombre de destinataires auxquels vous pouvez envoyer des documents est illimité. Lorsque vous envoyez un document, votre destinataire reçoit un e-mail contenant un lien unique. Après avoir cliqué sur le lien, le destinataire consulte et signe le document dans le navigateur, sans téléchargement ni plug-in. RightSignature&trade; est si simple et intuitif que les nouveaux destinataires peuvent signer un document sans avoir reçu d'instructions en quelques secondes.",
    "q13": "Pourquoi RightSignature™ est-il meilleur que la concurrence ?",
    "a13": "RightSignature&trade; est réputé pour son expérience utilisateur élégante et intuitive et la capture de signatures manuscrites. Grâce à l'infrastructure d'Amazon Web Services, notre sécurité et notre scalabilité sont inégalées. Une politique de développement et d'amélioration continus permet à RightSignature&trade; de rester à la pointe de la technologie. Pour les professionnels et les petites entreprises, RightSignature&trade; offre le logiciel de signatures de document en ligne le plus simple et le plus efficace.",
    "q14": "Comment RightSignature™ cautionne-t-il une initiative zéro papier ?",
    "a14": "Motivées par des préoccupations environnementales et des considérations d'ordre économique, de nombreuses entreprises mènent des initiatives visant à tendre vers le zéro papier. RightSignature&trade; est une composante essentielle dans la réduction de la consommation de papier en offrant une méthode sécurisée et efficace pour obtenir des documents signés en ligne sans exemplaires imprimés.",
    "q15": "Qu'est-ce qu'une signature électronique ?",
    "a15": "La pavé de signature de RightSignature&trade; vous permet d'utiliser votre souris pour signer un document, ce qui crée une signature électronique valable conformément aux directives et règlements des États-Unis et de l'Union Européenne. Toute marque que vous apposez dans le pavé de signature est valide, même si elle ne ressemble pas à votre signature manuscrite. Les tribunaux interprètent de manière large le concept de 'signature' et une signature n'a pas besoin d'être une 'signature' au sens traditionnel du terme. À titre d'exemple, un tampon encreur a été reconnu en tant que signature, tout comme une marque laissée par un analphabète, ce qui illustre le concept selon lequel toute marque apposée délibérément par une partie au contrat peut être reconnue comme une signature. ",
    "a15part2": "<p>Les documents exécutés en ligne avec des signatures électroniques sont valables d'un point de vue juridique et ont le même statut juridique que les documents papier exécutés à la main. La technologie de RightSignature&trade; se conforme pleinement à la loi américaine Electronic Signatures in Global and National Commerce Act (ESIGN) entrée en vigueur 2000, à la loi Uniform Electronic Transactions Act (UETA), ainsi qu'à la directive européenne (EC/1999/93). Toutes ces lois ont pour but d’encourager l’adoption rapide de signatures électroniques et de réduire l’utilisation des méthodes papier désuètes.</p><p>La technologie de RightSignature&trade; répond à la définition américaine d'une « signature électronique » : un son, un symbole ou un processus électronique lié à un contrat ou logiquement associé à ce dernier ou à un autre enregistrement et exécuté ou adopté par une personne dans l'intention de signer cet enregistrement</p><p>La technologie de RightSignature&trade; respecte également les exigences plus strictes de l'Union Européenne en matière de 'signature électronique avancée :'</p>",
    "a15part3": "  Elle est liée uniquement au signataire ; elle permet d'identifier le signataire ; elle est créée par des moyens que le signataire puisse garder sous son contrôle exclusif ; elle est liée aux données auxquelles elle se rapporte de telle sorte que toute modification ultérieure des données soit détectable. Lecture complémentaire : article 'Electronic Signatures and Online Contracts' sur Nolo.com, Directive 1999/93/EC du Parlement européen, U.S. Signatures in Global and National Commerce Act (ESIGN), Uniform Electronic Transactions Act (UETA)",
    "a15part4": "Pour de plus amples informations sur la valeur juridique des documents exécutés avec RightSignature&trade;, veuillez consulter notre page Légalité.",
    "q16": "Comment savoir si l'autre partie au contrat est celle qu'elle prétend être ?",
    "a16": "La vérification de l'identité d'un individu est un élément important de toute signature, qu'elle soit électronique ou manuscrite. Une signature électronique doit pouvoir identifier un signataire et doit être liée uniquement au signataire. RightSignature&trade; utilise un processus d'authentification à plusieurs variables, qui comprend la vérification des adresses e-mail, le suivi des adresses IP, un algorithme de signature biométrique et d'autres informations. L'authentification sur RightSignature&trade; est nettement plus forte que sur les documents papier, qui sont souvent signés par un assistant, un conjoint ou une autre personne.",
    "q17": "Comment connaissez-vous l'emplacement du signataire ?",
    "a17": "Chaque ordinateur sur Internet est identifié par une adresse IP ou un protocole Internet. Par exemple, votre adresse IP est : 99.102.245.244. Grâce à cette adresse, nous pouvons localiser l'emplacement géographique de votre ordinateur. Bien que cela ne soit pas précis à 100 %, cela fournit un outil d'authentification supplémentaire pour les parties utilisant RightSignature&trade; pour exécuter leurs documents.",
    "q18": "Un document exécuté peut-il être manipulé par l'une des parties après la signature ?",
    "a18": "Une fois chargé et envoyé pour signature, un document est verrouillé et stocké sur un serveur sécurisé avec des copies redondantes. Après exécution, le document final est lié à un algorithme de hachage sécurisé, garantissant qu'aucune partie ne peut apporter de modifications à un document exécuté. La neutralité de RightSignature&trade; confère une plus grande protection juridique contre toute tentative visant à invalider un accord signé. Pour plus d'informations sur notre infrastructure de données sécurisée, visitez notre page Sécurité.",
    "q19": "Les signatures numériques sont-elles certifiées par une autorité tierce ?",
    "a19": "En tant que service de signature tiers coordonnant le flux entre les signataires de documents, RightSignature&trade; représente une avancée considérable en matière de capacité d'audit et de vérification par rapport aux méthodes de télécopie et de courrier électronique. RightSignature&trade; présente l'avantage d'être un service tiers neutre, tout comme une agence de titres de propriétés dans une transaction immobilière. Une fois qu'un document est exécuté, RightSignature&trade; y associe un algorithme de hachage sécurisé et stocke des copies redondantes à l'aide de l'infrastructure Amazon Web Services. Dans le cas où un contrat signé sur RightSignature&trade; serait contesté devant un tribunal, il serait très difficile, voire impossible pour une partie d’affirmer que le document a été falsifié. L'enregistrement du processus de RightSignature&trade; et son verrouillage post-exécution offrent une vérification assurée par un tiers, ce qui rend les contrats plus robustes et défendables.",
    "q20": "Comment puis-je être sûr de signer le même document que l'autre partie ?",
    "a20": "Chaque document chargé sur RightSignature&trade; est associé à un numéro de référence unique, composé de chiffres, de majuscules et minuscules et de caractères. Ce numéro de référence est inclus dans chaque communication et écran contenant le document, garantissant ainsi que toutes les parties consultent le même document à tout moment.",
    "a20part2": "Le fichier ne peut pas être modifié une fois qu'il a été chargé et envoyé pour signature. Si un document n'a été signé par aucun des destinataires, l'expéditeur du document peut le supprimer et en envoyer un nouveau. Ce document aura un nouveau numéro de référence.",
    "q21": "Puis-je ajouter mon propre logo à RightSignature™ ?",
    "a21": "Avec RightSignature&trade;..."
  };
  _exports.faqModal = faqModal;
});