define("ember-rs/templates/components/import-overlay-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n3aOei7Z",
    "block": "{\"symbols\":[\"component\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"import-overlay-preview-component\",[],[[\"@pageWidth\",\"@pageHeight\",\"@documentTemplate\",\"@component\"],[[34,0],[34,1],[34,2],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[10,\"img\"],[14,0,\"page-image\"],[15,\"src\",[34,6]],[15,5,[30,[36,8],[[35,7]],null]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pageWidth\",\"pageHeight\",\"documentTemplate\",\"components\",\"-track-array\",\"each\",\"firstPageImage\",\"style\",\"safe\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/import-overlay-preview.hbs"
    }
  });

  _exports.default = _default;
});