define("ember-rs/components/selected-components-bounding-box", ["exports", "ember-rs/mixins/component-dragging"], function (_exports, _componentDragging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentDragging.default, {
    overlayerData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    classNames: ['selected-components-bounding-box'],
    attributeBindings: ['style'],
    padding: 4,
    style: Ember.computed('overlayerData.selectedComponentsBounds.x', 'overlayerData.selectedComponentsBounds.y', 'overlayerData.selectedComponentsBounds.width', 'overlayerData.selectedComponentsBounds.height', function () {
      var top = this.get('overlayerData.selectedComponentsBounds.y') - this.padding;

      if (top < 0) {
        top = 0;
      }

      var left = this.get('overlayerData.selectedComponentsBounds.x') - this.padding;

      if (left < 0) {
        left = 0;
      }

      var width = this.get('overlayerData.selectedComponentsBounds.width') + this.padding * 2;

      if (width > this.get('documentViewerData.pageWidth')) {
        width = this.get('documentViewerData.pageWidth');
      }

      var height = this.get('overlayerData.selectedComponentsBounds.height') + this.padding * 2;

      if (height > this.get('documentViewerData.pageHeight')) {
        height = this.get('documentViewerData.pageHeight');
      }

      return "top: ".concat(top, "px; left: ").concat(left, "px; width: ").concat(width, "px; height: ").concat(height, "px;").htmlSafe();
    }),
    dimensions: Ember.computed('overlayerData.selectedComponentsBounds', function () {
      return this.get('overlayerData.selectedComponentsBounds');
    }),
    updateSelectedComponentsPosition: function updateSelectedComponentsPosition(event, ui) {
      var self = this;
      var dragDeltaLeft = ui.position.left - this.get('dragPosition.previous.x');
      var dragDeltaTop = ui.position.top - this.get('dragPosition.previous.y');
      this.get('overlayerData.selectedComponents').forEach(function (component) {
        var componentPX = component.get('x') * self.get('documentViewerData.pageWidth');
        var componentPY = component.get('y') * self.get('documentViewerData.pageHeight');
        component.set('x', (componentPX + dragDeltaLeft) / self.get('documentViewerData.pageWidth'));
        component.set('y', (componentPY + dragDeltaTop) / self.get('documentViewerData.pageHeight'));
      });
      this.set('dragPosition.previous.x', ui.position.left);
      this.set('dragPosition.previous.y', ui.position.top);
    },
    // Overwriting this method so we can position the selected components in multiselect
    onDragging: function onDragging(evt, ui) {
      this.updateUiPosition(evt, ui);
      this.updateSelectedComponentsPosition(evt, ui);
    }
  });

  _exports.default = _default;
});