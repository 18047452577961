define("ember-rs/locales/pt-br/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentityExpired = void 0;
  var confirmIdentityExpired = {
    "linkExpired": "Link expirado",
    "resendIdentityConfirmation": "Reenviar email de confirmação de identidade.",
    "resendIdentityVerification": "Reenviar verificação de identidade",
    "contactSender": "Entre em contato com o remetente do documento para solicitar um link atualizado."
  };
  _exports.confirmIdentityExpired = confirmIdentityExpired;
});