define("ember-rs/templates/components/form-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gHD9J/Zl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[8,\"limited-input-text-field\",[],[[\"@type\",\"@value\",\"@id\",\"@class\"],[[34,1],[34,2],[34,0],\"form-control\"]],null],[2,\"\\n  \"],[8,\"error-messages\",[],[[\"@errors\"],[[34,3]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"type\",\"value\",\"errors\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/form-field.hbs"
    }
  });

  _exports.default = _default;
});