define("ember-rs/components/document/attachment-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    store: Ember.inject.service(),
    ////////////////////////  BINDINGS  ////////////////////////
    classNameBindings: [':attachment'],
    ////////////////////////  PROPERTIES  ////////////////////////
    attachment: Ember.computed('component.componentValue.value', function () {
      if (this.get('component.componentValue.value')) {
        return this.store.findRecord('attachment', this.get('component.componentValue.value'));
      }
    })
  });

  _exports.default = _default;
});