define("ember-rs/components/rs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'rs-tooltip',
    classNameBindings: ['isPlacedOnLeft:is-placed-on-left'],
    tagName: 'span',
    icon: '/assets/v3/icons/tooltip.svg',
    iconType: 'image/svg+xml'
  });

  _exports.default = _default;
});