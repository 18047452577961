define("ember-rs/locales/it/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "documents": "Documenti",
    "noResults": "Nessun risultato di ricerca",
    "tryAgain": "La ricerca non ha restituito alcun risultato, provare un'altra ricerca per trovare il documento.",
    "anotherFilter": "Il filtro scelto non ha prodotto risultati, provare con un altro filtro.",
    "documentsTip": "Qui si trovano i documenti firmati e in sospeso. Per iniziare, creare il primo documento.",
    "questions": "Per eventuali domande, è disponibile assistenza qui.",
    "gotIt": "OK",
    "startDocument": "Inizia documento",
    "userGuide": "Guida utente"
  };
  _exports.documents = documents;
});