define("ember-rs/locales/nl/templates/signer/sign/locked-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lockedModal = void 0;
  var lockedModal = {
    "title": "Document vergrendeld",
    "maxAttempts": "U hebt het maximum aantal pogingen om uw identiteit te verifiëren overschreden.",
    "locked": "Het document is nu vergrendeld en de afzender is op de hoogte gebracht. Neem contact op met de afzender van het document om door te gaan."
  };
  _exports.lockedModal = lockedModal;
});