define("ember-rs/locales/en/templates/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "share": "Share",
    "status": "Status",
    "overview": "Overview",
    "sent": "sent",
    "people_involved": "People Involved:",
    "reference_id": "Reference ID: ",
    "manage": "Manage",
    "tags": "Tags: "
  };
  _exports.details = details;
});