define("ember-rs/instance-initializers/error", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals Bugsnag */
  var _default = {
    name: 'error-service',
    errorText: function errorText(jqxhr) {
      var responseJSON = jqxhr.responseJSON;
      var responseText = jqxhr.responseText;

      if (responseJSON && responseJSON.errors) {
        return responseJSON.errors.map(function (error) {
          return error.detail;
        });
      } else if (responseJSON) {
        return responseJSON;
      }

      return responseText;
    },

    /*
     * Global error handler for AJAX error responses. This is used to handle errors that occur in
     * API requests that we want to gracefully hook into. *Order* is very important and intentional
     * so be careful changing order.
     *
     * @param applicationRoute {Ember.Route} - The applications main route
     * @param flashMessages {Ember.Service} - ember-cli-flash service for adding a message to the queue
     * @param event {jQuery.Event} - The original event that triggered the response
     * @param jqxhr {jQuery.jqxhr} - The original request object
     * @param settings {Object} - The settings from the orignal request object
     * @param thrownError {Error} - the original error context
     */
    handleErrors: function handleErrors(applicationRoute, flashMessages, event, jqxhr) {
      var errorText = this.errorText(jqxhr);
      var unprocessable = jqxhr.status === 422 || jqxhr.status === 400 || jqxhr.status === 403; // The session has expired or the user needs to login

      if (jqxhr.status === 401) {
        applicationRoute.transitionTo('login');
      } else if (jqxhr.status === 308 && /expired/.test(errorText) && /token/.test(errorText)) {
        // The redirect token has expired, and the user should request another one
        applicationRoute.transitionTo('unauthorized.expired', {
          queryParams: jqxhr.responseJSON
        });
      } else if (jqxhr.status === 404) {
        // The expected resource was not found
        flashMessages.warning('The page you were looking for could not be found. Please contact support for further assistance');
      } else if (unprocessable && /verification/i.test(jqxhr.responseText)) {
        // The current user has not verified their email address, and should verify their email address to continue
        applicationRoute.displayEmailVerificationInstructions();
      } else if (jqxhr.status === 403 && /This user is not enabled/.test(errorText)) {
        //The current user is not entitled
        if (applicationRoute.get('session.isAuthenticated')) {
          applicationRoute.get('session').invalidate();
        }

        applicationRoute.transitionTo('unauthorized.no-user', {
          queryParams: jqxhr.responseJSON
        });
      } else if (unprocessable && /try again in 24 hours/.test(errorText)) {
        // This is a no-op because we handle the error already
        return null;
      } else if (unprocessable && /retrieving documents/.test(errorText)) {
        // This is a no-op because we handle the error already
        return null;
      } else if (jqxhr.status === 408 && errorText) {
        // This is a no-op because we handle the error already
        return null;
      } else if (unprocessable && errorText) {
        // A POST / PUT operation has failed, possibly due to model validations
        flashMessages.warning(errorText, {
          sticky: true
        });
      } // eslint-disable-next-line no-unused-vars


      Bugsnag.beforeNotify = function (payload, metaData) {
        //do not send Bugsnag notifications for 401, 408 & 422
        if (jqxhr.status === 401 || jqxhr.status === 408 || jqxhr.status === 422) {
          return false;
        }

        var payloadFilter = /((access|identity)_token|token)=(\w+)/;
        payload.url = payload.url.replace(payloadFilter, '$1={filtered}');
      };
    },
    // Global AJAX error handler
    //  add 'global: false' to options on an ajax call to stop this from being triggered
    initialize: function initialize(application) {
      var _this = this;

      var applicationRoute = application.lookup('route:application');
      var flashMessages = application.lookup('service:flash-messages');

      if (_environment.default.environment === 'development') {
        window.Bugsnag = {
          notifyException: function notifyException(exception) {
            return console.error(exception);
          }
        };
      }

      (0, _jquery.default)(document).ajaxError(function (event, jqxhr, settings, thrownError) {
        _this.handleErrors(applicationRoute, flashMessages, event, jqxhr, settings, thrownError);
      });
    }
  };
  _exports.default = _default;
});