define("ember-rs/components/search-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SPECIAL_KEYS = {
    9: 'tab',
    13: 'enter',
    27: 'escape',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down'
  };

  var _default = Ember.Component.extend({
    classNames: ['search-field'],
    didInsertElement: function didInsertElement() {
      this.$('input.search').focus();
    },
    willDestroyElement: function willDestroyElement() {
      var searchDebounce = this.searchDebounce;

      if (searchDebounce) {
        Ember.run.cancel(searchDebounce);
      }
    },
    actions: {
      search: function search(event) {
        var _this = this;

        var isValidKey = !(event.metaKey || event.ctrlKey || SPECIAL_KEYS[event.keyCode]);

        if (isValidKey) {
          if (this.searchDebounce) {
            Ember.run.cancel(this.searchDebounce);
          }

          this.set('searchDebounce', Ember.run.debounce('search', function () {
            _this.sendAction('searchUpdate', event.target.value);
          }, 500));
        }
      }
    }
  });

  _exports.default = _default;
});