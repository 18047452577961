define("ember-rs/templates/integration-icon/-google-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8jAPW+VX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,\"src\",\"/assets/images/integrations/google_contacts.svg\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/integration-icon/-google-contacts.hbs"
    }
  });

  _exports.default = _default;
});