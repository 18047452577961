define("ember-rs/components/client-applications/index-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statusIcons = {
    banned: 'icons/status_voided',
    pending: 'icons/status_pending',
    approved: 'icons/status_complete',
    rejected: 'icons/status_voided'
  };

  var _default = Ember.Component.extend({
    ////////////////////////  PROPERTIES  ////////////////////////
    showTable: false,
    statusIcon: Ember.computed('clientApplication.status', function () {
      return statusIcons[Ember.get(this, 'clientApplication.status')] || 'icons/status_pending';
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      showTable: function showTable() {
        this.toggleProperty('showTable');
      },
      revealSecret: function revealSecret(id) {
        this.sendAction('revealSecret', id);
      },
      revealPrivateToken: function revealPrivateToken(id) {
        this.sendAction('revealPrivateToken', id);
      },
      regenerateToken: function regenerateToken(id) {
        this.sendAction('regenerateToken', id);
      }
    }
  });

  _exports.default = _default;
});