define("ember-rs/locales/es/templates/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "title": "Actualizando...",
    "desc": "Esto puede tardar unos segundos. No actualice la página ni haga clic en los botones Cerrar o Atrás del explorador."
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});