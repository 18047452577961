define("ember-rs/templates/components/document-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OprwVaEk",
    "block": "{\"symbols\":[\"audit\"],\"statements\":[[10,\"ul\"],[14,0,\"audits\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"document-audit\",[],[[\"@audit\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"sortedAudits\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/document-history.hbs"
    }
  });

  _exports.default = _default;
});