define("ember-rs/locales/en/mixins/integration-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationAuth = void 0;
  var integrationAuth = {
    "unableAuthorize": "Unable to authorize integration!",
    "notComplete": "Could not complete authorization"
  };
  _exports.integrationAuth = integrationAuth;
});