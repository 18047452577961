define("ember-rs/mixins/component-dimensions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // change ratio here to propogate aspectRatio for component throughout the app, the ratio is width / height
    ////////////////////////  DEFAULTS  ////////////////////////
    componentTypes: ['checkComponent', 'checkGroupComponent', 'checkmarkAnnotationComponent', 'signatureComponent', 'signatureAnnotationComponent', 'textComponent', 'selectComponent', 'dateComponent', 'initialsComponent', 'textAnnotationComponent', 'attachmentComponent', 'paymentComponent'],
    // starting values do not change, need these if we ever need to reset
    defaultComponentDimensions: {
      'checkComponent': {
        width: 25,
        height: 25
      },
      'checkGroupComponent': {
        width: 25,
        height: 25
      },
      'checkmarkAnnotationComponent': {
        width: 25,
        height: 25
      },
      'signatureComponent': {
        width: 200,
        height: 50
      },
      'signatureAnnotationComponent': {
        width: 200,
        height: 50
      },
      'textComponent': {
        width: 100,
        height: 25
      },
      'selectComponent': {
        width: 100,
        height: 25
      },
      'dateComponent': {
        width: 100,
        height: 25
      },
      'initialsComponent': {
        width: 100,
        height: 25
      },
      'textAnnotationComponent': {
        width: 100,
        height: 25
      },
      'attachmentComponent': {
        width: 140,
        height: 40
      },
      'paymentComponent': {
        width: 140,
        height: 40
      }
    },
    // current dimensions, change all the time
    lastComponentDimensions: {
      'checkComponent': {
        width: 25,
        height: 25
      },
      'checkGroupComponent': {
        width: 25,
        height: 25
      },
      'checkmarkAnnotationComponent': {
        width: 25,
        height: 25
      },
      'signatureComponent': {
        width: 200,
        height: 50
      },
      'signatureAnnotationComponent': {
        width: 200,
        height: 50
      },
      'textComponent': {
        width: 100,
        height: 25
      },
      'selectComponent': {
        width: 100,
        height: 25
      },
      'dateComponent': {
        width: 100,
        height: 25
      },
      'initialsComponent': {
        width: 100,
        height: 25
      },
      'textAnnotationComponent': {
        width: 100,
        height: 25
      },
      'attachmentComponent': {
        width: 140,
        height: 40
      },
      'paymentComponent': {
        width: 140,
        height: 40
      }
    },
    defaultComponentRatios: {
      'checkComponent': 1,
      'checkGroupComponent': 1,
      'checkmarkAnnotationComponent': 1,
      'signatureComponent': 4,
      'signatureAnnotationComponent': 4,
      'textComponent': 4,
      'selectComponent': 4,
      'dateComponent': 4,
      'initialsComponent': 4,
      'textAnnotationComponent': 4,
      'attachmentComponent': 3.5,
      'paymentComponent': 3.5
    },
    ////////////////////////  METHODS  ////////////////////////
    setLastComponentDimensions: function setLastComponentDimensions(componentType, width, height) {
      Ember.set(this, "lastComponentDimensions.".concat(componentType, ".width"), width);
      Ember.set(this, "lastComponentDimensions.".concat(componentType, ".height"), height);
    }
  });

  _exports.default = _default;
});