define("ember-rs/templates/reusable-template/prepare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MfJpeiix",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"transaction-manager-header\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"title\"],[12],[1,[30,[36,0],[\"reusableTemplate.prepare.prepare\"],null]],[2,\" \"],[1,[35,1,[\"name\"]]],[13],[2,\"\\n  \"],[11,\"div\"],[24,0,\"transaction-manager-close\"],[4,[38,2],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"close\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"document-overlayer\",[],[[\"@documentTemplate\",\"@classNames\",\"@nextStep\",\"@nextRouteText\",\"@showPrepareTutorialPointer\",\"@showPrepareTutorial\",\"@isSaving\"],[[34,1],\"transaction-step prepare\",[30,[36,4],[\"nextStep\"],null],[34,5],[34,6,[\"showPrepareTutorialPointer\"]],[34,7],[34,8]]],null],[2,\"\\n\\n\"],[8,\"on-boarding-new\",[],[[\"@onboardingPartial\",\"@onboardingFlow\",\"@totalSteps\"],[\"onboarding/prepare-onboarding\",\"hasSeenPrepareOnboarding\",3]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"action\",\"inline-svg\",\"route-action\",\"nextStepText\",\"layoutSizeData\",\"showPrepareTutorial\",\"isSaving\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/reusable-template/prepare.hbs"
    }
  });

  _exports.default = _default;
});