define("ember-rs/templates/toolbar-icons/attachment-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5X4WcBqr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"11.966px\"],[14,\"height\",\"10.591px\"],[14,\"viewBox\",\"0 0 11.966 10.591\"],[14,\"enable-background\",\"new 0 0 11.966 10.591\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[2,\"\\n\"],[10,\"g\"],[14,1,\"Layer_2\"],[14,\"display\",\"none\"],[12],[2,\"\\n\\n\\t\\t\"],[10,\"text\"],[14,\"transform\",\"matrix(0.7105 0.7037 -0.7037 0.7105 -1.8265 5.7552)\"],[14,\"display\",\"inline\"],[14,\"fill\",\"#568DF4\"],[14,\"font-family\",\"'FontAwesome'\"],[14,\"font-size\",\"14\"],[12],[2,\"\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"g\"],[14,1,\"Layer_1\"],[12],[2,\"\\n\\t\"],[10,\"path\"],[14,\"d\",\"M0.687,9.89C0.22,9.424-0.029,8.767,0.003,8.089c0.035-0.74,0.386-1.465,1.016-2.094\\n\\t\\tl4.95-4.95c1.558-1.551,3.723-1.226,4.952,0.002c0.001,0.001,0.002,0.002,0.003,0.003c1.227,1.227,1.546,3.393-0.006,4.944\\n\\t\\tl-4.596,4.596L5.615,9.884l4.596-4.596c1.139-1.139,0.854-2.682,0.005-3.531c-0.001-0.001-0.002-0.002-0.002-0.002\\n\\t\\tc-0.851-0.851-2.395-1.14-3.539-0.002L1.726,6.702C0.759,7.668,0.876,8.666,1.394,9.183c0.521,0.521,1.482,0.611,2.453-0.36\\n\\t\\tL8.089,4.58c0.32-0.32,0.255-0.615,0.086-0.784C8.01,3.631,7.703,3.552,7.382,3.873L3.493,7.762L2.786,7.055l3.889-3.889\\n\\t\\tc0.742-0.742,1.691-0.593,2.208-0.077c0.524,0.524,0.664,1.448-0.086,2.198L4.554,9.53C3.167,10.917,1.558,10.762,0.687,9.89z\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/toolbar-icons/attachment-component.hbs"
    }
  });

  _exports.default = _default;
});