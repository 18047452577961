define("ember-rs/locales/en/templates/reusable-template/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "upload": "Add Upload",
    "what": "What needs to be signed?",
    "uploading": "Uploading",
    "uploadFile": "Upload a file",
    "chooseCloud": "choose from cloud",
    "prepare": "prepare document"
  };
  _exports.edit = edit;
});