define("ember-rs/locales/fr/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploader = void 0;
  var documentUploader = {
    "multiFile": "Le chargement de plusieurs fichiers n'est pas pris en charge. Seul le premier fichier sélectionné sera chargé.",
    "alreadyUploaded": "Vous avez déjà chargé ce fichier !",
    "fileSize": "Le fichier {{{fileName}}} dépasse la limite de 20 Mo.",
    "uploadFailed": "Échec de la création du chargement"
  };
  _exports.documentUploader = documentUploader;
});