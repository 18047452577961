define("ember-rs/components/bill-row", ["exports", "jquery", "ember-rs/config/environment", "ember-rs/utils/download-file"], function (_exports, _jquery, _environment, _downloadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "tr",
    ////////////////////////  DEFAULTS  ///////////////////
    loading: false,
    ////////////////////////  ACTIONS  ///////////////////
    actions: {
      downloadBill: function downloadBill(id, rs3) {
        var _this = this;

        this.set('loading', true);
        new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            url: "".concat(_environment.default.api.endpoint, "/account/bills/").concat(id, "?legacy=").concat(rs3),
            type: 'GET'
          }).then(function (response) {
            var file_path = response.url;
            (0, _downloadFile.default)(file_path);

            _this.set('loading', false);

            resolve(response);
          }).fail(function (error) {
            reject(error);

            _this.set('loading', false);
          });
        }, 'get a bill');
      }
    }
  });

  _exports.default = _default;
});