define("ember-rs/locales/zh-cn/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "linkExpired": "文档链接已过期",
    "toSign": "要对此文档签名，请申请一个新链接。",
    "sendLink": "发送新签名者链接",
    "contactSender": "请与文档发件人联系以申请更新后的链接。"
  };
  _exports.identityExpired = identityExpired;
});