define("ember-rs/locales/pt-br/templates/components/code-generated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.codeGenerated = void 0;
  var codeGenerated = {
    "copyToClipboard": "Copiar para a área de transferência",
    "copySuccess": "Copiado!",
    "copyError": "Falha ao copiar para a área de transferência"
  };
  _exports.codeGenerated = codeGenerated;
});