define("ember-rs/components/csv-upload-error-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showList: Ember.computed('uploadErrors', function () {
      return this.uploadErrors.length > 1;
    }),
    showGenericMessage: Ember.computed('uploadErrors', function () {
      return this.uploadErrors.length == 0;
    }),
    didInsertElement: function didInsertElement() {
      this.onRender();
    }
  });

  _exports.default = _default;
});