define("ember-rs/locales/pt-br/controllers/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "standardInfo": "O plano selecionado só dá suporte a um usuário. Se você precisar de mais usuários em sua conta, escolha o plano Avançado.",
    "migratedInfo": "Escolha pelo menos {{{minimumSeats}}} usuários, já que sua conta de teste já usa até {{{minimumSeats}}} estações. Caso contrário, entre em contato com o suporte para obter mais assistência para a atualização",
    "planInfo": "O plano que você selecionou inclui três usuários. Escolha mais abaixo, se necessário.",
    "validUsers": "Insira um número válido de usuários"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});