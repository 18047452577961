define("ember-rs/locales/fr/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers = void 0;
  var prepare_signers = {
    "add_signers": "Ajouter des signataires",
    "doc_limit_p1": "Vous avez utilisé ",
    "doc_limit_p2": "de votre limite de documents pour le mois. Contactez-nous pour mettre à niveau votre compte.",
    "recentContacts": "Contacts récents"
  };
  _exports.prepare_signers = prepare_signers;
});