define("ember-rs/components/signature-text-pad", ["exports", "jquery", "ember-rs/utils/media", "ember-rs/utils/escape-html"], function (_exports, _jquery, _media, _escapeHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PEN_COLORS = {
    black: '#333',
    blue: '#001DA3'
  };
  var MAX_FONT_SIZE = 120;
  var MIN_FONT_SIZE = 20;

  var _default = Ember.Component.extend({
    classNames: ['signature-text-pad'],
    maxFontSize: MAX_FONT_SIZE,
    fontSize: MAX_FONT_SIZE,
    width: 800,
    height: 200,
    lineFromBottom: 93,
    currentSignatureText: '',
    // passed in
    color: 'black',
    // passed in
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._refreshFromCurrentSignatureText();
    },
    didInsertElement: function didInsertElement() {
      var width = (0, _jquery.default)(window).width();

      if (width < _media.PHONE_SIZE) {
        var height = 150;
        width = height * 4; // width = width - 40;
        // let height = width/4;

        var lineFromBottom = height - 86 / 300 * width;
        this.set('width', width);
        this.set('height', height);
        this.set('lineFromBottom', lineFromBottom);
        this.set('maxFontSize', (height - lineFromBottom) / 2 + 5);
      }

      var typePad = this.$('input.type-pad'); // typePad.css('bottom', `${this.get('lineFromBottom')}px`);

      typePad.css('color', PEN_COLORS[this.color]);
      typePad.focus();
      var signaturePad = this.$().signaturePad({
        drawOnly: true,
        drawBezierCurves: true,
        variableStrokeWidth: true,
        lineWidth: 4,
        penWidth: 4,
        penColour: PEN_COLORS[this.color],
        bgColour: 'rgba(255,255,255,0)',
        lineTop: 9000
      });

      this._refreshFromCurrentSignatureText(signaturePad);
    },
    willDestroyElement: function willDestroyElement() {
      var signaturePad = this.$().signaturePad();

      if (signaturePad) {
        signaturePad.destroy();
      }
    },
    actions: {
      signatureTyped: function signatureTyped() {
        this._resizeText();

        this._fillTextIntoCanvas(this.inputValue);

        if (this.inputValue === '') {
          this.sendAction('signatureDrawn', null);
        } else {
          this.sendAction('signatureDrawn', {
            signatureText: this.inputValue,
            imageDataRaw: this._getSignatureImageDataRaw(),
            signatureType: 'typed',
            color: this.color
          });
        }
      },
      clearSignature: function clearSignature() {
        this.sendAction('signatureDrawn', null);
      }
    },
    _resizeText: function _resizeText() {
      var inputValue = (0, _escapeHtml.escapeHtml)(this.inputValue);
      var resizer = this.$('span.hidden-resizer');

      if (inputValue !== resizer.html()) {
        var width = this.width - 20;
        var spaceMatch = inputValue.match(/^\s+|\s+$/gm);
        var whitespace = spaceMatch ? spaceMatch.join('').length : 0;
        var fontSize = this.maxFontSize;

        if (new RegExp(resizer.html()).test(inputValue)) {
          fontSize = parseInt(resizer.css('font-size'), 10);
        } else {
          resizer.css('font-size', fontSize);
        }

        resizer.html(inputValue);

        while (resizer.width() + whitespace * fontSize / 2 > width && fontSize > MIN_FONT_SIZE) {
          fontSize = parseInt(resizer.css('font-size'), 10);
          resizer.css('font-size', fontSize - 1);
        }

        this.set('fontSize', fontSize);
        this.$('input.type-pad').css('font-size', fontSize);
      }
    },
    _refreshFromCurrentSignatureText: function _refreshFromCurrentSignatureText(signaturePad) {
      signaturePad = signaturePad || this.$().signaturePad();

      if (Ember.isPresent(this.currentSignatureText)) {
        this.set('inputValue', this.currentSignatureText);

        this._resizeText();

        this._fillTextIntoCanvas(this.inputValue, signaturePad);
      } else {
        this.set('inputValue', '');
        signaturePad.clearCanvas();
      }
    },
    _fillTextIntoCanvas: function _fillTextIntoCanvas(signatureText, signaturePad) {
      signaturePad = signaturePad || this.$().signaturePad();
      signaturePad.clearCanvas();
      var textBottom = this.$('canvas')[0].height / 1.5;
      var context = this.$('canvas')[0].getContext('2d');
      context.fillStyle = PEN_COLORS[this.color];
      context.font = "".concat(this.fontSize, "px Cedarville Cursive");
      context.fillText(signatureText, 0, textBottom);
    },
    _getSignatureImageDataRaw: function _getSignatureImageDataRaw() {
      return this.$('canvas')[0].toDataURL();
    }
  });

  _exports.default = _default;
});