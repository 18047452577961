define("ember-rs/mixins/integration-documents-list", ["exports", "ember-rs/mixins/integration-auth"], function (_exports, _integrationAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_integrationAuth.default, {
    ////////////////////////  SERVICES ////////////////////////
    flashMessages: Ember.inject.service(),
    ////////////////////////  DEFAULTS ////////////////////////
    showIntegrationPicker: false,
    loading: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    formattedFolderName: Ember.computed('folderName', function () {
      return (this.folderName || '').replace(/^\//, '');
    }),
    ////////////////////////  METHODS  ////////////////////////
    toggleService: function toggleService(service, serviceEnabled, isAuthorized, provider, integrationMetadata, params) {
      var serviceCategory = /sending|archiving/.test(service) ? 'documents' : 'contacts';
      var requestType = Ember.isEqual(serviceEnabled, true) ? 'POST' : 'DELETE';
      var self = this; // The endpoint for contacts is the root resource :contacts

      if (serviceCategory === 'contacts') {
        service = 'root';
      }

      if (isAuthorized) {
        // This integration is already authorized
        this.integrationRequest(provider, serviceCategory, service, requestType, params).then(function () {
          if (serviceEnabled) {
            var key = "mixins.integrationDocuments.enable_" + service;
            self.get('flashMessages').success(self.get('i18n').t(key, {
              provider: provider
            }));
          } else {
            var _key = "mixins.integrationDocuments.disable_" + service;

            self.get('flashMessages').success(self.get('i18n').t(_key, {
              provider: provider
            }));
          }

          self.get('currentUser').model.reload();
        }, function () {
          self.get('flashMessages').warning(self.get('i18n').t("mixins.integrationDocuments.errorToggle", {
            service: service,
            provider: provider
          }));
          self.get('currentUser').model.reload();
        });
      } else {
        // This integration needs to be authorized before we can toggle a service.
        this.integrationAuthorize({
          provider: provider,
          serviceCategory: serviceCategory,
          service: service,
          integrationMetadata: integrationMetadata,
          authorizationOrigin: this.authorizationOrigin,
          authParams: params
        });
      }
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleShowIntegrationPicker: function toggleShowIntegrationPicker() {
        this.toggleProperty('showIntegrationPicker');
      },
      hideIntegrationPicker: function hideIntegrationPicker() {
        Ember.set(this, 'showIntegrationPicker', false);
      },
      getIntegrationDocument: function getIntegrationDocument(doc, provider, integration) {
        var self = this;
        this.send('hideIntegrationPicker');
        this.integrationRequest(provider, 'documents', 'document', 'POST', doc).then(function (request) {
          self.toggleProperty('loading');
          return self.store.find('upload', request.upload.id).then(function (u) {
            u.setProperties({
              _state: 'upload_done',
              originalFilename: request.upload.filename
            });
            self.get('uploads').pushObject(u);
            self.send('onUploadUpdated', u, {
              integrationProvider: provider,
              integration: integration,
              integrationMetadata: doc
            });
          });
        }, function (error) {
          self.set('loading', false);
          self.get('flashMessages').warning(self.get('i18n').t("mixins.integrationDocuments.errorRetrieve"));
          self.send('onUploadError', error);
        });
      },
      getIntegrationDocuments: function getIntegrationDocuments(provider) {
        var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var self = this;
        this.toggleProperty('loading');
        this.set('folderName', data.folder_name);
        this.integrationRequest(provider, 'documents', 'root', 'GET', data).then(function (request) {
          self.toggleProperty('loading');
          self.set('integrationDocuments', request.documents);
        }, function () {
          self.get('flashMessages').warning(self.get('i18n').t("mixins.integrationDocuments.errorProviderRetrieve", {
            provider: provider
          }), {
            sticky: true
          });
        });
      },
      toggleService: function toggleService(service, serviceEnabled, isAuthorized, provider, integrationMetadata, params) {
        this.toggleService(service, serviceEnabled, isAuthorized, provider, integrationMetadata, params);
      }
    }
  });

  _exports.default = _default;
});