define("ember-rs/locales/nl/templates/onboarding/post-sign-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding2 = void 0;
  var postSignOnboarding2 = {
    "viewDoc": "Hier kunt u de <span>status</span> van het document zien",
    "downloadDoc": "Hier kunt u het document <span>downloaden</span>."
  };
  _exports.postSignOnboarding2 = postSignOnboarding2;
});