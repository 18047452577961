define("ember-rs/locales/pt-br/templates/components/transaction-manager-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transactionManagerHeader = void 0;
  var transactionManagerHeader = {
    "close": "FECHAR",
    "sendDocument": "Enviar documento",
    "signDocument": "Assinar documento",
    "inPerson": "Assinatura em pessoa",
    "broken": "Não funciona"
  };
  _exports.transactionManagerHeader = transactionManagerHeader;
});