define("ember-rs/locales/es/templates/components/unconfirmed-user-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedUserButton = void 0;
  var unconfirmedUserButton = {
    "emailUnverified": "Su dirección de correo electrónico no está verificada",
    "verifySendDocument": "Antes de poder enviar su primer documento, necesitamos verificar su dirección de correo electrónico.",
    "verifyCreateTemplate": "Antes de poder crear su primera plantilla, necesitamos verificar su dirección de correo electrónico.",
    "verifyUpgrade": "Antes de poder ampliar su cuenta hybris, necesitamos verificar su dirección de correo electrónico.",
    "verifyIntegration": "Antes de que pueda habilitar una integración, necesitamos verificar su dirección de correo electrónico.",
    "verifyEmail": "Se envió un correo electrónico de verificación a la siguiente dirección.",
    "checkEmail": "¿No recibió el correo electrónico? Consulte la carpeta de spam o",
    "resend": "Volver a enviar",
    "verificationMail": "Correo electrónico de verificación enviado. Consulte {{{email}}} y siga las instrucciones."
  };
  _exports.unconfirmedUserButton = unconfirmedUserButton;
});