define("ember-rs/locales/en/templates/template", ["exports", "ember-rs/locales/en/templates/template/details", "ember-rs/locales/en/templates/template/search-header-mobile"], function (_exports, _details, _searchHeaderMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    details: _details.details,
    searchHeaderMobile: _searchHeaderMobile.searchHeaderMobile,
    "review": {
      "header-content": "Review",
      "create-button": "Create Template",
      "update-button": "Update Template"
    },
    "index": {
      "subheader-create-button": "Create Template",
      "template-limit-modal": {
        "cancellation-grace-period": "This account is cancelled, so you can't create templates",
        "limit-reached": "Your current plan only allows you to create {{templateLimit}} templates. If you would like to create more, please contact support and upgrade your account.",
        "not-enabled": "Your current plan does not allow you to create templates. If you would like to create templates, please contact support and upgrade your account."
      }
    }
  };
  _exports.template = template;
});