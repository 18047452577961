define("ember-rs/locales/pt-br/templates/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateSelector = void 0;
  var reusableTemplateSelector = {
    "noTemplates": "Você não tem nenhum modelo ainda.",
    "templateDesc": "Crie modelos reutilizáveis com base nos documentos usados com frequência. Envie com rapidez, incorpore em seu site ou distribua em massa.",
    "createNew": "Criar um novo modelo",
    "chooseTemplate": "Escolher um modelo",
    "loading": "Carregando...",
    "loadMore": "carregar mais modelos...",
    "upgrade": "Atualize sua conta para usar modelos",
    "upgradeAccount": "Atualizar conta",
    "bulkSendInfo": "O envio em massa só funciona com um único modelo de signatário. É a primeira vez que você usa o envio em massa?",
    "learnMore": "Saber Mais",
    "searchTemplate": "Buscar modelos",
    "noResults": "Nenhum resultado!"
  };
  _exports.reusableTemplateSelector = reusableTemplateSelector;
});