define("ember-rs/controllers/template/details", ["exports", "jquery", "lodash", "ember-rs/config/environment"], function (_exports, _jquery, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    section: 'document',
    identityMethods: [{
      label: 'Email',
      value: 'email'
    }, {
      label: 'SMS',
      value: 'sms'
    }, {
      label: 'None',
      value: 'none'
    }],
    embedTypes: [{
      label: 'Button',
      value: 'button'
    }, {
      label: 'Thumbnail',
      value: 'image'
    }, {
      label: 'Inline',
      value: 'inline'
    }],
    loading: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    pageCount: Ember.computed.alias('model.upload.baseFile.pageCount'),
    displayTypeInline: Ember.computed.equal('linkOptions.embedType', 'inline'),
    hasMultipleRoles: Ember.computed.gte('model.roles.length', 2),
    tslSorting: ['createdAt:desc'],
    sortedTemplateSignerLinks: Ember.computed.sort('model.templateSignerLinks', 'tslSorting'),
    showDocument: Ember.computed.equal('section', 'document'),
    showLinks: false,
    showAllLinkOptions: false,
    showGeneratedLinkModal: false,
    showMobileSidebar: false,
    showDesktopSidebar: true,
    fetchSignerLinks: true,
    canManageTemplate: Ember.computed('currentUser.canManageTemplates', 'currentUser.id', 'model.userId', function () {
      return Ember.isPresent(this.get('currentUser.id')) && (this.get('currentUser.canManageTemplates') || this.get('currentUser.id') === this.get('model.userId'));
    }),
    // Gets set by the select box, this is just the default
    linkRoleName: Ember.computed('model.roles.@each.name', function () {
      return this.get('model.roles.firstObject.name');
    }),
    ////////////////////////  STYLES  ////////////////////////
    templateContentContainerStyle: Ember.computed('layoutSizeData.windowWidth', 'layoutSizeData.windowHeight', 'layoutSizeData.sidebar', 'layoutSizeData.sidebarWidth', 'layoutSizeData.tmHeaderHeight', function () {
      var width = this.get('layoutSizeData.windowWidth') - this.get('layoutSizeData.sidebarWidth');
      var height = this.get('layoutSizeData.windowHeight') - this.get('layoutSizeData.tmHeaderHeight');
      return "width: ".concat(width, "px; min-height: ").concat(height, "px; max-width: 1100px;").htmlSafe();
    }),
    sideBarStyle: Ember.computed('documentViewerData.sidebarWidth', 'layoutSizeData.scrollbarWidth', 'layoutSizeData.tmContentHeight', 'layoutSizeData.useMobileLayout', 'layoutSizeData.windowHeight', function () {
      var width = this.get('documentViewerData.sidebarWidth') + this.get('layoutSizeData.scrollbarWidth');
      var height = this.get('layoutSizeData.tmContentHeight');

      if (this.get('layoutSizeData.useMobileLayout')) {
        height = this.get('layoutSizeData.windowHeight');
        return "height: ".concat(height, "px;").htmlSafe();
      } else {
        return "width: ".concat(width, "px; height: ").concat(height, "px;").htmlSafe();
      }
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleSidebar: function toggleSidebar() {
        if (window.innerWidth <= 1024) {
          this.toggleProperty('showMobileSidebar'); // this.set('showSidebar', true);
          // this.set('layoutSizeData.showDetailsSidebar', !this.get('layoutSizeData.showDetailsSidebar'));
        } else {
          this.toggleProperty('showDesktopSidebar'); // if (this.get('showSidebar')) {
          //   this.set('documentViewerData.sidebarWidth', 0);
          //   this.set('showSidebar', false);
          //   this.get("session").set("data.showDetailsSidebar", false);
          // } else {
          //   this.set('documentViewerData.sidebarWidth', this.get('detailsSidebarWidth'));
          //   this.set('showSidebar', true);
          //   this.get("session").set("data.showDetailsSidebar", true);
          // }
        }
      },
      toggleEditor: function toggleEditor(type) {
        this.toggleProperty(type);
      },
      addNewTag: function addNewTag(tags) {
        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/reusable_templates/").concat(this.get('model.id'), "/update_tags"),
          type: 'POST',
          data: {
            tags: tags
          }
        });
      },
      closeTagsEditor: function closeTagsEditor() {
        this.send('toggleEditor', 'showTagsEditor');
      },
      toggleSetUseLimit: function toggleSetUseLimit(value) {
        this.set('linkOptions.setUseLimit', value);
      },
      toggleSetExpiration: function toggleSetExpiration(value) {
        this.set('linkOptions.setExpiration', value);
      },
      toggleShowLinks: function toggleShowLinks() {
        if (this.fetchSignerLinks) {
          this.send('getSignerLinks');
        }

        this.toggleProperty('showLinks');
      },
      toggleShowLinkModal: function toggleShowLinkModal() {
        this.toggleProperty('showLinkModal');
      },
      sendForSignature: function sendForSignature() {
        var _this = this;

        // start us a transaction
        this.store.createRecord('document-transaction', {
          workflow: 'signature_request',
          account: this.get('currentUser.account')
        }).save().then(function (documentTransaction) {
          // clone the template into a document
          _this.model.cloneDocument().then(function (doc) {
            _this.transitionToRoute('document.prepare_signers', documentTransaction, doc);
          });
        });
      },
      setShow: function setShow(type) {
        if (_lodash.default.includes(['document', 'links'], type)) {
          this.set('section', type); // close the modal for mobile web

          if (this.get('layoutSizeData.useMobileLayout')) {
            this.set('showTemplateOptions', false);
          }
        } else {
          this.set('section', 'document');
        }
      },
      openModal: function openModal(type) {
        if (!this.get('currentUser.model.account.onlineForms')) {
          // TODO: Add upgrade modal
          this.flashMessages.warning(this.i18n.t('controllers.template.details.upgradeMsg'));
          return;
        }

        this.set('linkOptions', {
          setUseLimit: false,
          maxSigners: 1,
          setExpiration: false,
          expirationDays: 30,
          linkName: '',
          setPasscode: false,
          passcodeQuestion: '',
          passcodeAnswer: '',
          identityMethod: this.get('model.identityMethod') || 'email',
          embedType: 'button',
          height: 500,
          width: 755
        });

        if (_lodash.default.includes(['link', 'embed'], type)) {
          this.set("show".concat(type.capitalize(), "Modal"), true);
        } else {
          this.set('showLinkModal', false);
          this.set('showEmbedModal', false);
        }
      },
      openMobileModal: function openMobileModal() {
        this.set('showTemplateOptions', true);
      },
      delete: function _delete() {
        var _this2 = this;

        var id = this.get('model.id');
        var dashboardModel = this.store.peekRecord('dashboard-reusable-template', id);

        if (confirm(this.i18n.t('controllers.template.details.templateDeleteConfirm'))) {
          this.model.destroyRecord().then(function () {
            if (dashboardModel) {
              _this2.store.unloadRecord(dashboardModel);
            }

            _this2.flashMessages.success(_this2.i18n.t('controllers.template.details.templateDeleteMsg'));

            _this2.transitionToRoute('templates');
          }, function () {
            _this2.model.rollback();
          });
        }
      },
      getSignerLinks: function getSignerLinks() {
        var _this3 = this;

        this.set('loading', true); // unload all the signer links to get rid of dirty attrs, pushing to the store does not
        // override the dirty attributes

        var templateSignerLinks = this.store.peekAll('template-signer-link');
        templateSignerLinks.forEach(function (link) {
          return _this3.store.unloadRecord(link);
        });
        new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            type: 'GET',
            url: "".concat(_environment.default.api.endpoint, "/reusable_templates/").concat(_this3.get('model.id'), "/template_signer_links")
          }).then(function (signer_links) {
            signer_links.template_signer_links.forEach(function (link) {
              _this3.store.pushPayload({
                template_signer_link: _jquery.default.extend(link, {
                  template_id: _this3.get('model.id')
                })
              });
            });

            _this3.set('fetchSignerLinks', false);

            _this3.set('loading', false);
          }, function (signer_links) {
            _this3.flashMessages.warning(_this3.i18n.t('controllers.template.details.loadSignerLinksFailedMsg', {
              formattedResponse: JSON.stringify(signer_links)
            }));

            reject(signer_links);
          });
        });
      },
      generateTemplateLink: function generateTemplateLink(embed) {
        var _this4 = this;

        var templateSignerLinkOptions = {
          role_name: this.linkRoleName,
          identity_method: this.get('linkOptions.identityMethod'),
          name: this.get('linkOptions.linkName')
        };

        if (this.get('linkOptions.setUseLimit')) {
          templateSignerLinkOptions.max_signers = this.get('linkOptions.maxSigners');
        }

        if (this.get('linkOptions.setExpiration')) {
          templateSignerLinkOptions.expires_in_days = this.get('linkOptions.expirationDays');
        }

        if (embed) {
          templateSignerLinkOptions.embed_code = true;
          templateSignerLinkOptions.embed_type = this.get('linkOptions.embedType');

          if (this.displayTypeInline) {
            templateSignerLinkOptions.height = this.get('linkOptions.height');
            templateSignerLinkOptions.width = this.get('linkOptions.width');
          }
        }

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/reusable_templates/").concat(this.get('model.id'), "/generate_template_signer_link"),
          type: 'POST',
          data: {
            template_signer_link: templateSignerLinkOptions
          }
        }).then(function (response) {
          _this4.store.pushPayload({
            template_signer_link: _jquery.default.extend(response.template_signer_link, {
              template_id: _this4.get('model.id')
            })
          });

          _this4.set('newTemplateSignerLink', _this4.store.peekRecord('template-signer-link', response.template_signer_link.id));

          _this4.set('showGeneratedLinkModal', true);

          _this4.send('openModal');
        });
      },
      updateTags: function updateTags(newTags) {
        var _this5 = this;

        new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            type: 'PUT',
            url: "".concat(_environment.default.api.endpoint, "/reusable_templates/").concat(_this5.get('model.id'), "/update_tags"),
            data: {
              tags: newTags,
              is_current_template: true
            }
          }).then(function (response) {
            _this5.set('model.tags', newTags);

            _this5.flashMessages.success(_this5.i18n.t('controllers.template.details.tagUpdateMsg'));

            resolve(response);
          }, function (response) {
            _this5.flashMessages.warning(_this5.i18n.t('controllers.template.details.tagUpdateFailedMsg', {
              formattedResponse: JSON.stringify(response)
            }));

            reject(response);
          });
        }, 'update tags');
      },
      toggleVisiblity: function toggleVisiblity() {
        var _this6 = this;

        var newVisibilityState = this.get('model.visibility') === 'public' ? 'private' : 'public';
        new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            type: 'PUT',
            url: "".concat(_environment.default.api.endpoint, "/reusable_templates/").concat(_this6.get('model.id'), "/toggle_visibility"),
            data: {
              visibility: newVisibilityState
            }
          }).then(function (response) {
            _this6.set('model.visibility', newVisibilityState);

            resolve(response);
          }, function (response) {
            reject(response);
          });
        });
      },
      togglePinModal: function togglePinModal(showNewPinModal) {
        this.set('showNewPinModal', !showNewPinModal);
      }
    }
  });

  _exports.default = _default;
});