define("ember-rs/adapters/application", ["exports", "jquery", "ember-rs/config/environment", "active-model-adapter", "@ember-data/adapter/error", "ember-simple-auth/mixins/data-adapter-mixin", "ember-inflector"], function (_exports, _jquery, _environment, _activeModelAdapter, _error, _dataAdapterMixin, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FILTERED_ERRORS = Ember.A([]);

  var _default = _activeModelAdapter.default.extend(_dataAdapterMixin.default, {
    redirectToken: Ember.inject.service(),
    kbaSession: Ember.inject.service(),
    session: Ember.inject.service(),
    partialSession: Ember.inject.service(),
    signatureAuth: Ember.inject.service(),
    host: _environment.default.api.host,
    namespace: _environment.default.api.namespace,

    get headers() {
      var headers = {};
      var token = this.redirectToken;
      var kbaSession = this.kbaSession.session();
      var partialSession = this.partialSession;
      var signatureAuth = this.signatureAuth;
      var access_token = this.get('session.data.authenticated.access_token');

      if (access_token) {
        Ember.assign(headers, {
          'Authorization': "Bearer ".concat(access_token)
        });
      }

      if (token.hasValue()) {
        Ember.assign(headers, {
          'Rightsignature-Redirect-Token': token.getValue()
        });
      } else if (partialSession.hasValue()) {
        Ember.assign(headers, {
          'Rightsignature-Document-Grant-Token': partialSession.getValue()
        });
      }

      if (Ember.isPresent(kbaSession)) {
        Ember.assign(headers, {
          'Rightsignature-Kba-Session': kbaSession
        });
      }

      if (signatureAuth.hasDocumentId()) {
        Ember.assign(headers, {
          'Document-Id': signatureAuth.getDocumentId()
        });
      }

      if (signatureAuth.hasSignerId()) {
        Ember.assign(headers, {
          'Signer-Id': signatureAuth.getSignerId()
        });

        if (Ember.isPresent(signatureAuth.getIdentityToken())) {
          Ember.assign(headers, {
            'Identity-Token': signatureAuth.getIdentityToken()
          });
        } else if (Ember.isPresent(signatureAuth.getAccessToken())) {
          Ember.assign(headers, {
            'Access-Token': signatureAuth.getAccessToken()
          });
        }
      }

      return headers;
    },

    pathForType: function pathForType(type) {
      var underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    },
    excludedError: function excludedError(text) {
      return FILTERED_ERRORS.map(function (filter) {
        return filter.test(text);
      }).any(function (result) {
        return result;
      });
    },
    ajaxError: function ajaxError(event, jqXHR) {
      var error = this._super(jqXHR); // Be sure to only pass jqXHR to super: http://api.jquery.com/ajaxerror/


      if (this.excludedError(event.responseText)) {
        return error;
      } // 422 is used by this fictional server to signal a validation error


      if (jqXHR && jqXHR.status === 422) {
        var jsonErrors = _jquery.default.parseJSON(jqXHR.responseText);

        return new _error.InvalidError(jsonErrors);
      } else {
        // The ajax request failed however it is not a result of this
        // record being in an invalid state so we do not return a
        // `InvalidError` object.
        return error;
      }
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    }
  });

  _exports.default = _default;
});