define("ember-rs/locales/it/templates/signer/sign/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identify = void 0;
  var identify = {
    "advisory": "Il mittente di questo documento ha abilitato la funzionalità di autenticazione basata sulla conoscenza di RightSignature per verificare l'identità dell'utente. RightSignature utilizza i servizi di terze parti di BlockScore per convalidare e verificare le informazioni personali fornite. L'utilizzo delle informazioni personali fornite è limitato alle prestazioni della convalida e della verifica dell'identità con i record di dati proprietari di BlockScore. Le prestazioni della convalida e della verifica dell'identità non avranno alcun effetto sul rapporto di credito dell'utente, sul suo punteggio di credito o sulle informazioni trasferite ai prestatori. IRS, Blockscore e RightSignature non avranno la possibilità di visualizzare o accedere al rapporto di credito o alle informazioni fiscali dell'utente come parte di questo processo.",
    "step1": "Verifica dell'identità - Passaggio 1 di 2",
    "beforeSigning": "Prima di firmare il documento, inserire le proprie informazioni personali per aiutarci a verificare l'identità.",
    "notValid": "Siamo spiacenti, non è stata trovata un'identità valida corrispondente alle informazioni fornite.  Assicurarsi che le informazioni riportate di seguito siano corrette e fare clic su Avanti per riprovare.",
    "youHave": "Si hanno a disposizione ",
    "remaining": " tentativi rimanenti di accesso al documento.",
    "name": "Nome:",
    "dob": "Data di nascita:",
    "ssn": "Ultime 4 cifre del numero SSN:",
    "passport": "Numero di passaporto:",
    "address": "Indirizzo:",
    "address2": "Indirizzo 2:",
    "city": "Città:",
    "state": "Stato:",
    "zip": "Codice postale:",
    "country": "Paese:",
    "return": "Ritorna al modulo",
    "advisory2": "Avviso di verifica dell'identità",
    "sampleAddress1": "120 S. West St.",
    "sampleAddress2": "Suite 100",
    "sampleAddress3": "Raleigh",
    "submit": "Invia"
  };
  _exports.identify = identify;
});