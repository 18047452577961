define("ember-rs/models/role", ["exports", "@ember-data/model", "ember-rs/helpers/is-valid-email"], function (_exports, _model, _isValidEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // Attributes
    name: (0, _model.attr)('string'),
    signerName: (0, _model.attr)('string'),
    signerEmail: (0, _model.attr)('string'),
    signerOmitted: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    sequence: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    isSender: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    message: (0, _model.attr)('string'),
    // Relationships
    documentTemplate: (0, _model.belongsTo)('document-template', {
      polymorphic: true,
      async: false
    }),
    isValid: Ember.computed('name', 'sequence', function () {
      if (Ember.isPresent(this.name)) {
        if (this.sequence >= 0) {
          return true;
        }
      }

      return false;
    }),
    hasValidEmail: Ember.computed('signerEmail', function () {
      return (0, _isValidEmail.isValidEmail)(this.signerEmail);
    }),
    signerEmailOptional: Ember.computed.not('documentTemplate.documentTransaction.signerEmailRequired'),
    isSignerValid: Ember.computed('signerName', 'signerEmail', 'hasValidEmail', 'signerEmailOptional', function () {
      if (Ember.isPresent(this.signerName)) {
        if (this.signerEmailOptional && Ember.isBlank(this.signerEmail) || this.hasValidEmail) {
          return true;
        }
      }

      return false;
    })
  });

  _exports.default = _default;
});