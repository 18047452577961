define("ember-rs/templates/components/rs-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QlcP/1Jf",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"tooltip\"],[[\"class\"],[\"rs-tooltip-icon\"]]]],[2,\"\\n\"],[10,\"span\"],[14,0,\"rs-tooltip-message\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"message\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/rs-tooltip.hbs"
    }
  });

  _exports.default = _default;
});