define("ember-rs/services/sharefile-post-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    post: function post() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.store.findRecord('sessionCredential', 'current').then(function (sessionCredential) {
        var parentWindowUrl;

        if (document.location.ancestorOrigins && document.location.ancestorOrigins.length > 0) {
          parentWindowUrl = new URL(document.location.ancestorOrigins[0]);
        } //firefox doesn't support ancestorOrigins
        //post message without targetUrl and any listener listening to this would take action


        parentWindowUrl && parentWindowUrl.hostname.endsWith(sessionCredential.get('appcp')) ? parent.postMessage(message, parentWindowUrl.origin) : parent.postMessage(message, "*");
      });
    }
  });

  _exports.default = _default;
});