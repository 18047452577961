define("ember-rs/locales/zh-cn/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFree = void 0;
  var newUserOnboardingFree = {
    "title": "欢迎使用 RightSignature!",
    "body": "您当前只对 RightSignature™ 具有基本访问权限。这使您可以对文档自助签名以及查看过去的文档。要访问 PRO 功能，请与 RightSignature™ 帐户的管理员联系。"
  };
  _exports.newUserOnboardingFree = newUserOnboardingFree;
});