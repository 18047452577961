define("ember-rs/services/document-viewer-data", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentPage: 0,
    // Scroll offset for viewer
    scrollTop: 100,
    // Scroll offset from currentPage
    scrollTopPage: 0,
    // Set dynamically via setupController on pages using documentViewerData
    pageHeight: 1424.0,
    pageWidth: 1100.0,
    pageMargin: 10,
    sidebarWidth: 0,
    // Array of page indexes and whether they have loaded
    pagesLoaded: Ember.A(),
    layoutSizeData: Ember.inject.service(),
    targetDocumentWidth: Ember.computed('layoutSizeData.containerWidth', 'sidebarWidth', function () {
      if (this.get("layoutSizeData.useMobileLayout") || this.get("layoutSizeData.isPhoneSize") || this.get("layoutSizeData.isTabletSize")) {
        var currentRoute = Ember.getOwner(this).lookup('controller:application').currentPath;
        var rightPadding = 20;

        if (currentRoute.match('prepare')) {
          return this.get('layoutSizeData.containerWidth') - this.sidebarWidth - rightPadding;
        }

        return this.get('layoutSizeData.containerWidth');
      } else {
        return this.get('layoutSizeData.containerWidth') - this.sidebarWidth - this.get('layoutSizeData.totalSidePadding');
      }
    }),
    viewerScale: Ember.computed('targetDocumentWidth', 'pageWidth', function () {
      var calculatedScale = this.targetDocumentWidth / this.pageWidth;

      if (calculatedScale > 1) {
        return 1;
      } else {
        return calculatedScale;
      }
    }),
    reverseViewerScale: Ember.computed('viewerScale', 'targetDocumentWidth', function () {
      var small = this.targetDocumentWidth * this.viewerScale;
      return this.targetDocumentWidth / small * 100;
    }),
    scaledPageMargin: Ember.computed('viewerScale', 'pageMargin', function () {
      return this.pageMargin * this.viewerScale;
    }),
    scaledPageWidth: Ember.computed.alias('targetDocumentWidth'),
    nextStepButtonStyle: Ember.computed('layoutSizeData.windowWidth', 'scaledPageWidth', 'layoutSizeData.containerWidth', 'layoutSizeData.scrollbarWidth', function () {
      var left = (this.get('layoutSizeData.windowWidth') - this.get('layoutSizeData.containerWidth')) / 2 + this.scaledPageWidth + 30 + this.get('layoutSizeData.scrollbarWidth');
      var width = this.sidebarWidth;
      return "left: ".concat(left, "px; width: ").concat(width, "px").htmlSafe();
    }),
    documentContainerStyle: Ember.computed('targetDocumentWidth', 'layoutSizeData.scrollbarWidth', function () {
      var width = this.targetDocumentWidth + this.get('layoutSizeData.scrollbarWidth');
      var marginLeft = this.get("layoutSizeData.totalSidePadding") / 3;
      return "margin-left: ".concat(marginLeft, "px; width: ").concat(width, "px;").htmlSafe();
    }),
    scaledPageHeight: Ember.computed('viewerScale', 'pageHeight', function () {
      return this.pageHeight * this.viewerScale;
    }),
    scrollToPage: function scrollToPage(pageNumber) {
      (0, _jquery.default)('.fullscreen-container').animate({
        scrollTop: (0, _jquery.default)(".page[data-page-number='".concat(pageNumber, "']")).position().top
      }, 500);
    },
    scrollToElement: function scrollToElement(element, shouldFocus) {
      var paddingTop = parseFloat((0, _jquery.default)('#document_container, #sign_document').css('padding-top'));
      var viewHeight = this.get('layoutSizeData.windowHeight') - this.get('layoutSizeData.signingFooterHeight') - paddingTop;
      var pageTop = element.parents('.page[data-page-number]').position().top;
      var offset = viewHeight / 2 + element.height() / 2; // vertically centers element

      var position = pageTop + element.position().top - offset;
      var input = element.find('input');
      var select = element.find('select'); // scroll in desktop layout

      (0, _jquery.default)('#document_container, #sign_document').scrollTop(position); // scroll in mobile layout

      if (this.get('layoutSizeData.useMobileLayout') && this.myScroll) {
        this.myScroll.scrollTo(0, -position);
      }

      if (shouldFocus && input.length > 0) {
        input.focus();
      } else if (shouldFocus && select.length > 0) {
        select.focus();
      }
    }
  });

  _exports.default = _default;
});