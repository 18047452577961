define("ember-rs/locales/it/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationSettingsService = void 0;
  var integrationSettingsService = {
    "requiresInfo": "Questa integrazione richiede alcune informazioni prima di procedere:",
    "authorize": "Autorizza",
    "sending": "Inviare i documenti {{{formattedProviderName}}} per la firma elettronica dall'interno di RightSignature",
    "archiving": "Inviare un pdf dei documenti completati a una cartella specifica nel proprio account {{{formattedProviderName}}}",
    "contacts": "Rendere tutti i contatti {{{formattedProviderName}}} disponibili per la firma dei documenti",
    "payments": "Inserire i componenti di pagamento nei documenti RightSignature per ottenere e memorizzare in modo sicuro i dati della carta di credito e autorizzare gli addebiti dal conto {{{formattedProviderName}}}",
    "fieldsInfo": "Compilare tutti i campi"
  };
  _exports.integrationSettingsService = integrationSettingsService;
});