define("ember-rs/utils/poller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    interval: Ember.computed(function () {
      return 1000; // Time between polls (in ms)
    }).readOnly(),
    // Schedules the function `f` to be executed every `interval` time.
    schedule: function schedule(f) {
      return Ember.run.later(this, function () {
        f.apply(this);
        this.set('timer', this.schedule(f));
      }, this.interval);
    },
    // Stops the pollster
    stop: function stop() {
      Ember.run.cancel(this.timer);
    },
    // Starts the pollster, i.e. executes the `onPoll` function every interval.
    start: function start() {
      this.set('timer', this.schedule(this.onPoll));
    },
    onPoll: function onPoll() {// Issue JSON request and add data to the store
    }
  });

  _exports.default = _default;
});