define("ember-rs/locales/fr/templates/components/rs-left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsLeftNav = void 0;
  var rsLeftNav = {
    "Documents": "Documents",
    "Templates": "Modèles",
    "Account": "Compte",
    "Settings": "Paramètres",
    "Integrations": "Intégrations",
    "Branding": "Branding",
    "Users": "Utilisateurs",
    "Reports": "Rapports",
    "Api": "API",
    "Billing": "Facturation",
    "Workflows": "Commentaires et approbations"
  };
  _exports.rsLeftNav = rsLeftNav;
});