define("ember-rs/locales/de/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentityExpired = void 0;
  var confirmIdentityExpired = {
    "linkExpired": "Link abgelaufen",
    "resendIdentityConfirmation": "E-Mail zur Identitätsbestätigung erneut senden.",
    "resendIdentityVerification": "Identitätsprüfung erneut senden",
    "contactSender": "Bitten Sie den Absender des Dokuments, Ihnen einen aktuellen Link zu senden."
  };
  _exports.confirmIdentityExpired = confirmIdentityExpired;
});