define("ember-rs/locales/de/templates/components/document", ["exports", "ember-rs/locales/de/templates/components/document/signer-row", "ember-rs/locales/de/templates/components/document/attachment-row"], function (_exports, _signerRow, _attachmentRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTrans = void 0;
  var documentTrans = {
    signerRow: _signerRow.signerRow,
    attachmentRow: _attachmentRow.attachmentRow
  };
  _exports.documentTrans = documentTrans;
});