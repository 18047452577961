define("ember-rs/routes/account", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Account',
    ////////////////////////  HOOKS  ////////////////////////
    title: function title(tokens) {
      this.controllerFor('account').set('settingsPage', tokens[tokens.length - 1]);
      var titleTokens = Ember.A(['RightSignature', tokens]);
      return _lodash.default.flatten(titleTokens).join(' | ');
    },
    model: function model() {
      return this.get('currentUser.account');
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      // TODO: Review in the future as this triggers 3 requests (might just be necessary)
      reloadAccount: function reloadAccount() {
        this.currentModel.reload();
        this.get('currentModel.users').reload();
      }
    }
  });

  _exports.default = _default;
});