define("ember-rs/locales/it/templates/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateRow = void 0;
  var reusableTemplateRow = {
    "send": "Invia",
    "details": "Dettagli",
    "trialmessageComp": "Il piano di prova corrente consente di inviare solo {{{documentLimit}}} documenti. ",
    "planmessageComp": "Il piano corrente consente solo di inviare solo {{{documentLimit}}} documenti. ",
    "upgrade": "Contattarci per aggiornare l'account."
  };
  _exports.reusableTemplateRow = reusableTemplateRow;
});