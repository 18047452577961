define("ember-rs/locales/en/templates/document/search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header = void 0;
  var search_header = {
    "from": "From: ",
    "state": "State: ",
    "search_docs": "Search Documents...",
    "all": " All",
    "pending": " Pending",
    "declined": " Declined",
    "executed": " Complete",
    "expired": " Expired",
    "voided": " Voided",
    "admin_view": "Admin view",
    "tool_tip": "View all documents sent from this account.",
    "filter": "Filter"
  };
  _exports.search_header = search_header;
});