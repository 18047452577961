define("ember-rs/locales/de/controllers/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "standardInfo": "Das von Ihnen ausgewählte Abonnement unterstützt nur einen Benutzer. Wenn Sie mehr Benutzer für Ihr Konto benötigen, wählen Sie das Abonnement 'Erweitert' aus.",
    "migratedInfo": "Wählen Sie mindestens {{{minimumSeats}}} Benutzer aus, da Ihr Testkonto bereits {{{minimumSeats}}} Sitze belegt. Andernfalls wenden Sie sich an den Support, um weitere Unterstützung beim Upgrade zu erhalten",
    "planInfo": "Das von Ihnen ausgewählte Abo umfasst 3 Benutzer. Wählen Sie unten bei Bedarf mehr aus.",
    "validUsers": "Bitte geben Sie eine gültige Benutzerzahl ein"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});