define("ember-rs/locales/zh-cn/templates/onboarding/new-user-onboarding-from-sharefile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFromSharefile = void 0;
  var newUserOnboardingFromSharefile = {
    "title": "发送您的文档并对其签名",
    "startText": "发送您的文档以由其他人签名或者对需要您签名的任何文档签名。",
    "clickLearn": "单击“下一页”以了解操作方法。",
    "addSigner": "添加签名者",
    "addAnyone": "添加需要填写此文档或对其签名的任何人。",
    "prepare": "准备文档",
    "placeFields": "将字段放置在需要签名者填写信息或对文档签名的位置。可以将字段分配给每个签名者。",
    "sendTrack": "发送和跟踪进度",
    "onceSent": "发送文档后，可以在文档详细信息页面上跟踪其进度。要导航到详细信息页面，只需单击文档控制板中的文档即可。"
  };
  _exports.newUserOnboardingFromSharefile = newUserOnboardingFromSharefile;
});