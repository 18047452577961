define("ember-rs/locales/es/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFound = void 0;
  var notFound = {
    "doesNotExist": "Esta página no existe.",
    "didYou": "¿Ha seguido un enlace desde otro lugar en RightSignature&trade;?",
    "anotherPart": "Si ha llegado a esta página desde otra parte de RightSignature&trade;, <a href='https://support.citrix.com/cscase'>póngase en contacto con la Asistencia técnica</a> para que podamos corregir el error.",
    "followLink": "¿Ha seguido un enlace desde otro sitio?",
    "otherSite": "Los enlaces de otros sitios a veces pueden estar desactualizados o mal escritos. <a href='https://support.citrix.com/cscase'>Póngase en contacto con la Asistencia técnica</a> e indíquenos qué enlace utilizó para llegar hasta aquí, de modo que podamos contactar con el otro sitio con el fin de solucionar el problema.",
    "typeUrl": "¿Escribió la URL?",
    "doubleCheck": "Es posible que haya escrito la dirección (URL) incorrectamente. Asegúrese de que la ortografía, el uso de mayúsculas, etc. son correctos.",
    "home": "Inicio"
  };
  _exports.notFound = notFound;
});