define("ember-rs/components/rs-popover", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'rs-popover',
    classNameBindings: ['positionModifier', 'isToggled:is-toggled'],
    positionModifier: Ember.computed('position', function () {
      return this.position ? "is-".concat(this.position) : 'is-top-left';
    }),
    click: function click(event) {
      event.stopPropagation();
    },
    didRender: function didRender() {
      this.send('initializeToggleElement');
      this.send('initializeExternalClickHandler');
    },
    actions: {
      togglePopover: function togglePopover() {
        this.toggleProperty('isToggled');

        if (this.isToggled) {
          this.send('initializeExternalClickHandler');
        } else {
          this.send('destroyExternalClickHandler');
        }
      },
      destroyExternalClickHandler: function destroyExternalClickHandler() {
        //cleans up the listen events when the component is no longer visible
        (0, _jquery.default)('body').off('click');
      },
      initializeExternalClickHandler: function initializeExternalClickHandler() {
        var _this = this;

        //toggles component off, if something is clicked outside of the component
        (0, _jquery.default)('body').on('click', function () {
          _this.set('isToggled', false);
        });
      },
      initializeToggleElement: function initializeToggleElement() {
        var _this2 = this;

        // if a selector was provided, use that to target the element
        // that is responsible for toggling this component
        if (this.toggleSelector) {
          this.set('toggleElement', (0, _jquery.default)(this.toggleSelector));
        } else {
          this.set('toggleElement', this.$().prev());
        } // if a toggle element was found, start toggling when it is clicked


        if (this.toggleElement.length) {
          this.toggleElement.on({
            click: function click(event) {
              event.stopPropagation();
              (0, _jquery.default)('body').click(); //used to ensure other popovers are disabled

              _this2.send('togglePopover');
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});