define("ember-rs/locales/zh-cn/routes/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTransaction = void 0;
  var documentTransaction = {
    "disabledPaymentIntegration": "您必须在发送以下文档之前启用付款集成: {{names}}",
    "disabledPasscode": "您必须设置通行码 PIN 码才能发送以下文档: {{names}}",
    "successfulDocCreation": "已成功创建文档。"
  };
  _exports.documentTransaction = documentTransaction;
});