define("ember-rs/locales/zh-cn/mixins/distribution-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.distributionMethod = void 0;
  var distributionMethod = {
    "viaEmail": "通过电子邮件发送邀请",
    "manual": "手动分发链接",
    "email": "电子邮件",
    "sms": "短信",
    "none": "无"
  };
  _exports.distributionMethod = distributionMethod;
});