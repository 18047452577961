define("ember-rs/locales/ja/mixins/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "successUpgrade": "アカウントがアップグレードされました。RightSignature にようこそ。",
    "dataUnavailable": "現在、データは利用できません。しばらくしてからこのページに戻ってください。"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});