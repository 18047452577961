define("ember-rs/locales/pt-br/templates/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.index = void 0;
  var index = {
    "templates": "Modelos",
    "reusableTemplates": "Modelos reutilizáveis",
    "templateExplain": "Os modelos permitem que você incorpore documentos em seu site, distribua em massa ou envie documentos usados com frequência em apenas uma etapa.",
    "newTemplate": "Novo modelo",
    "noResults": "Sem resultados de busca",
    "searchAgain": "Sua busca não retornou nenhum resultado. Tente outra busca para encontrar seu modelo.",
    "create": "Crie modelos de documentos reutilizáveis que você pode incorporar em seu site e compartilhar via link.",
    "createTemplate": "Criar modelo",
    "notAllowed": "Seu plano atual não permite criar modelos. Se você deseja criar modelos, entre em contato com o suporte e atualize a sua conta."
  };
  _exports.index = index;
});