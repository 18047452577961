define("ember-rs/locales/en/templates/signer/sign/locked-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lockedModal = void 0;
  var lockedModal = {
    "title": "Document Locked",
    "maxAttempts": "You have exceeded the maximum number of attempts to verify your identity.",
    "locked": "The document is now locked and the sender has been notified.  Please contact the sender of the document to proceed."
  };
  _exports.lockedModal = lockedModal;
});