define("ember-rs/locales/en/templates/onboarding/signer-onboarding-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboardingOld = void 0;
  var signerOnboardingOld = {
    "title": "Signing a document with RightSignature&trade; is easy!",
    "completefields": "Complete the fields marked with a flag.",
    "submitSignature": "Complete fields and submit your signature.",
    "progress": "See how far along you are with the progress bar. When you've completed all the required fields, click the Submit Signature button to finish signing.",
    "help": "Need help?",
    "questions": "If you have a question or need more information, get help here."
  };
  _exports.signerOnboardingOld = signerOnboardingOld;
});