define("ember-rs/locales/es/templates/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faqModal = void 0;
  var faqModal = {
    "docId": "ID del documento:",
    "contactSender": "Póngase en contacto con el remitente: {{{userName}}} en <a href='mailto: {{{userEmail}}}'>{{{userEmail}}}</a> para compartir cualquier pregunta",
    "needHelp": "¿Necesita ayuda con RightSignature&trade;? Consulte las preguntas frecuentes:",
    "q1": "Tengo una duda sobre el contenido de este documento:",
    "rsNote": "RightSignature&trade; es un servicio externo de firma de documentos en línea. Nosotros facilitamos el envío y la firma de documentos con firmas legales, seguras y electrónicas, pero no estamos asociados con el remitente ni con el documento de ninguna manera.",
    "q2": "¿Cómo completo este documento?",
    "a2": "La flecha roja a la izquierda del documento lo dirigirá al siguiente campo requerido. Una vez que se completen todos los campos necesarios, el botón 'Enviar' estará disponible para hacer clic.",
    "q3": "¿Esto es legalmente vinculante?",
    "a3": "Sí. Los documentos ejecutados en línea con firmas electrónicas son legalmente válidos y tienen la misma validez legal que los documentos en papel ejecutados a mano. Es muy probable que ya haya firmado un contracto en línea. Por ejemplo, cuando hace clic en un botón \"Estoy de acuerdo\" en Internet, generalmente participa en lo que se denomina un contrato Clickwrap. Además, cuando compra bienes en línea, usted participa de un contrato legalmente vinculante.",
    "a3part2": "La tecnología de RightSignature&trade; cumple plenamente con la Ley estadounidense de Firmas Electrónicas en el Comercio nacional y global, de 2000 (ESIGN) y con la Ley de Transacciones electrónicas (UETA), así como con la Directiva europea (EC / 1999/93), todas ellas dirigidas a promover la rápida adopción de las firmas electrónicas y reducir el uso de métodos en papel anticuados. Cada una de estas leyes refuerza la validez de los contratos electrónicos. De acuerdo con ESIGN, por ejemplo, \"no puede denegarse el efecto legal, la validez ni la aplicabilidad de un contrato únicamente porque se haya utilizado una firma electrónica o un registro electrónico al crearlo\".",
    "q4": "¿Qué es RightSignature™?",
    "a4": "RightSignature&trade; le permite crear y firmar documentos legalmente vinculantes en línea, de una forma más rápida y segura que la ejecución de documentos en papel. RightSignature&trade; es un servicio de software servicio basado en Web que utiliza la infraestructura de datos segura de Amazon Web Services.",
    "q5": "¿Qué hace RightSignature™?",
    "a5": "RightSignature&trade; le permite crear y firmar documentos legalmente vinculantes en línea, de una forma más rápida y segura que la ejecución de documentos en papel. RightSignature&trade; es un servicio de software servicio basado en Web que utiliza la infraestructura de datos segura de Amazon Web Services.",
    "q6": "¿Cómo habilita RightSignature™ la firma de documentos en línea?",
    "a6": "RightSignature&trade; facilita la obtención de firmas en línea de varias personas participantes. Simplemente tiene que cargar su documento e introducir la información del destinatario. RightSignature&trade; envía por correo electrónico a los destinatarios un enlace único a una página donde pueden revisar el documento, completar los campos de texto necesarios y firmar. Los destinatarios pueden firmar en línea con un mouse en tan solo unos segundos.",
    "a6part2": "En la actualidad, la mayoría de las empresas firman documentos mediante el envío de faxes, o enviando sobres de un día para otro, o escaneando y enviando páginas de firma por correo electrónico. RightSignature&trade; elimina las molestias, las demoras y los gastos que conllevan estas prácticas tediosas y anticuadas. Ejecutar un documento legalmente vinculante y almacenarlo de manera segura en un servidor seguro se puede lograr en cuestión de minutos, con unos cuantos clics.",
    "q7": "¿Pero no es más seguro firmar un documento en papel?",
    "a7": "Los contratos electrónicos son significativamente más seguros que los contratos en papel y tienen el mismo valor legal. Mientras que los documentos enviados por fax y correo quedan expuestos durante su distribución dentro de la oficina, los documentos electrónicos se mantienen completamente confidenciales y solo los pueden ver los destinatarios designados. Los documentos de RightSignature&trade; se almacenan en servidores seguros con copias redundantes y, a diferencia de los documentos en papel, no se pueden extraviar, perder o destruir. Además, los recibos de correo electrónico, los registros de IP y los datos de firma biométrica proporcionan autenticación de cada una de las partes firmantes.",
    "q8": "¿Para quién es este servicio?",
    "a8": "RightSignature&trade; es ideal para pequeñas y medianas empresas, autónomos diseñadores, desarrolladores, abogados, contratistas, escuelas, organizaciones sin fines de lucro, etcétera. Si su trabajo requiere la firma de documentos, necesita RightSignature&trade;.",
    "q9": "¿Qué tipo de documentos puedo enviar?",
    "a9": "Entre los documentos enviados regularmente mediante RightSignature&trade; se incluyen contratos, acuerdos de confidencialidad, aprobaciones de propuestas, órdenes de cambio, pruebas de anuncios, cartas de compromiso, informes de gastos, órdenes de compra, contratos de alquiler, contratos de profesionales independientes, nuevas aplicaciones de clientes, solicitudes de empleo, y muchos más. Los formularios de conformidad, como W-9, W-4 y I-9 son ideales para RightSignature&trade;. ¿Necesita que todos los miembros del equipo firmen un cambio de directiva? RightSignature&trade; lo hace más fácil. Las empresas envían todo, desde planillas horarias a contratos multimillonarios, utilizando RightSignature&trade;.",
    "q10": "¿Qué exploradores Web son compatibles?",
    "a10": "RightSignature&trade; proporciona la mejor experiencia de usuario en los siguientes exploradores Web. Para actualizar su explorador Web con la versión más reciente, visite:",
    "a10part2": "Mozilla Firefox 3+, Microsoft Internet Explorer 9+, Apple Safari 3+, Google Chrome, Opera 9+",
    "q11": "¿Qué hago si no puedo iniciar sesión?",
    "a11": "Revise cuidadosamente su dirección de correo electrónico para asegurarse de que la escribe correctamente. Además, teclee su contraseña lenta y cuidadosamente. Si esto no funciona, puede restablecer su contraseña haciendo clic en 'He olvidado mi contraseña'. Si después de seguir el proceso de restablecimiento de contraseña, aún no puede iniciar sesión, póngase en contacto con nosotros.",
    "q12": "¿Es necesario que los destinatarios tengan una cuenta de RightSignature™?",
    "a12": "No. No es necesario que los destinatarios tengan una cuenta para recibir un documento suyo. No hay límite para la cantidad de destinatarios a quienes envía documentos. Cuando envíe un documento, su destinatario recibirá un correo electrónico que contiene un enlace único. Después de hacer clic en el enlace, el destinatario ve y firma el documento en el explorador Web, sin necesidad de descargarlo ni usar complementos del explorador. RightSignature&trade; es tan fácil e intuitivo que los nuevos destinatarios pueden iniciar sesión en segundos, sin instrucciones.",
    "q13": "¿Qué hace que RightSignature™ sea mejor que la competencia?",
    "a13": "RightSignature&trade; es famoso por su experiencia de usuario elegante e intuitiva y la captura de firmas manuscritas reales. Con la infraestructura de Amazon Web Services, nuestra seguridad y escalabilidad son de primera clase. El desarrollo y la mejora continua mantienen RightSignature&trade; a la vanguardia de esta tecnología. Para el profesional independiente y para la pequeña empresa, RightSignature&trade; proporciona el software de documento electrónico más simple y eficaz.",
    "q14": "¿Cómo ayuda RightSignature™ a las iniciativas para utilizar menos papel?",
    "a14": "Motivadas por las preocupaciones medioambientales y el ahorro de costos, muchas oficinas están ya aplicando una serie de iniciativas para reducir el uso de papel. RightSignature&trade; es un componente clave en la reducción del uso de papel, ofreciendo un método seguro y efectivo para obtener documentos firmados en línea, sin copias impresas.",
    "q15": "¿Qué es una firma electrónica?",
    "a15": "El panel de firma de RightSignature&trade; le permite usar su mouse para firmar un documento, creando una firma electrónica válida, tal como se define en la legislación y las directivas de los EE. UU. y la UE. Cualquier marca que haga en el panel de firmas es válida, incluso si no se asemeja a su firma manuscrita. Los tribunales aplican un concepto de \"firma\" amplio, y una firma no necesita, de hecho, ser una \"firma\" en el sentido tradicional de la palabra. Por ejemplo, un sello de goma o una marca hecha por una persona iletrada se han reconocido como firmas. Ambos ejemplos ilustran el concepto de que cualquier marca hecha voluntariamente por la parte de un contrato puede ser reconocida como una firma. ",
    "a15part2": "<p>Los documentos ejecutados en línea con firmas electrónicas son legalmente válidos y tienen el mismo peso legal que los documentos en papel ejecutados a mano. La tecnología de RightSignature&trade; cumple plenamente con la Ley estadounidense de Firmas Electrónicas en el Comercio nacional y global, de 2000 (ESIGN) y con la Ley de Transacciones electrónicas (UETA), así como con la Directiva europea (EC/1999/93), todas ellas dirigidas a promover la rápida adopción de las firmas electrónicas y reducir el uso de métodos en papel anticuados.</p><p>La tecnología de RightSignature&trade; cumple con la definición estadounidense de \"firma electrónica\": un sonido, símbolo o proceso electrónico, asociado física o lógicamente a un contrato u otro registro, y ejecutado o adoptado por una persona con la intención de firmar el registro</p><p>La tecnología de RightSignature&trade; también cumple con los requisitos más estrictos de la UE de una \"firma electrónica avanzada\":</p>",
    "a15part3": "  está vinculado de manera única al signatario; es capaz de identificar al signatario; se ha creado utilizando medios que el signatario puede mantener bajo su control exclusivo; y está vinculado a los datos a los que hace referencia de tal manera que todo cambio posterior que se haga en los datos es detectable. Referencias para consultas adicionales: 'Electronic Signatures and Online Contracts,' Nolo.com article, Directiva 1999/93/EC del Parlamento Europeo, U.S. Signatures in Global and National Commerce Act (ESIGN), Uniform Electronic Transactions Act (UETA)",
    "a15part4": "Para obtener más información sobre la validez legal de los documentos ejecutados con RightSignature&trade;, consulte nuestra página de Legalidad.",
    "q16": "¿Cómo sé con seguridad que la otra parte del contrato es quien dice ser?",
    "a16": "La verificación de la identidad de un individuo es un elemento importante de cualquier firma, ya sea electrónica o escrita. Una firma electrónica debe ser capaz de identificar a un signatario y debe estar vinculada de manera única al mismo. RightSignature&trade; utiliza un proceso de autenticación multivariable, que incluye la verificación de la dirección de correo electrónico, el rastreo de la dirección IP y el algoritmo de firma biométrica, entre otro tipo de información. La autenticación en RightSignature&trade; es significativamente más fuerte que en los documentos en papel, donde las partes a menudo tienen un ayudante, o su cónyuge, o alguna otra persona, que firman en su nombre.",
    "q17": "¿Cómo puedo averiguar la ubicación del firmante?",
    "a17": "Cada equipo informático se identifica por una dirección IP (Protocolo de Internet) en Internet. Por ejemplo, su dirección IP es: 99.102.245.244. Desde esta dirección, podemos rastrear su equipo a una ubicación física. Aunque no es posible alcanzar una precisión del 100%, esto proporciona una herramienta de autenticación adicional para las partes de los documentos ejecutados mediante RightSignature&trade;.",
    "q18": "¿Puede un documento ya ejecutado ser manipulado por alguna de las partes después de la firma?",
    "a18": "Una vez cargado y enviado para la firma, un documento queda bloqueado y almacenado en un servidor seguro con copias redundantes. Después de la ejecución, el documento final queda vinculado con un algoritmo hash seguro, para que ninguna de las partes pueda realizar cambios en un documento ejecutado. La neutralidad de RightSignature&trade; proporciona una mayor defensa legal ante cualquier intento de invalidar un contrato ejecutado. Para obtener más información sobre nuestra infraestructura de datos segura, visite nuestra página de Seguridad.",
    "q19": "¿Las firmas digitales están certificadas por una entidad externa?",
    "a19": "RightSignature&trade;, como servicio externo de firma que coordina el flujo de trabajo entre los firmantes de documentos, ofrece una mejora muy significativa en la capacidad de auditoría y verificación por encima de los métodos tradicionales de fax y correo electrónico. Una ventaja de usar RightSignature&trade; es que nuestro servicio es una parte neutral, con un rol muy similar al que tendría un agente depositario en una transacción inmobiliaria. Una vez que se ejecuta un documento, RightSignature&trade; lo vincula con un algoritmo hash seguro y almacena copias redundantes utilizando la infraestructura de Amazon Web Services. En el caso de que un contrato de RightSignature&trade; fuera impugnado en los tribunales, sería muy difícil o imposible que una de las partes pudiera argumentar que el documento fue manipulado. El registro del bloqueo que RightSignature&trade; aplica al proceso y la post-ejecución proporciona una verificación externa para conseguir contratos sólidos y defendibles.",
    "q20": "¿Cómo puedo estar seguro de que estoy firmando el mismo documento que la otra parte?",
    "a20": "Cada documento que se carga en RightSignature&trade; queda vinculado a único número de referencia de cadena larga, compuesto de números, letras mayúsculas y minúsculas y otros caracteres. Este número de referencia se incluye en todas las comunicaciones y pantallas que contienen el documento, lo que garantiza que todas las partes están viendo el mismo documento en todo momento.",
    "a20part2": "No hay ninguna función para cambiar el archivo una vez que se carga y se envía para firmar. Si un documento aún no ha sido firmado por los destinatarios, la parte remitente puede eliminarlo y cargar y enviar un nuevo documento, que tendrá un nuevo número de referencia.",
    "q21": "¿Puedo marcar RightSignature™ con mi logo?",
    "a21": "Con RightSignature&trade;..."
  };
  _exports.faqModal = faqModal;
});