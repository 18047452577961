define("ember-rs/templates/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WX3eDQc0",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,4],[[35,1,[\"branding\"]],[35,3,[\"canManageBranding\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[19,\"account/-branding-signing-settings\",[]],[2,\"\\n  \"],[19,\"account/-branding-email-settings\",[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1,[\"branding\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[19,\"account/-branding-email-settings\",[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"setting-info\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"account.branding.header\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"account.branding.disabled.description\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/branding-examples.svg\"],[14,0,\"paywall-image\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/branding-examples.png\"],[15,\"alt\",[30,[36,0],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"t\",\"model\",\"if\",\"currentUser\",\"and\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/account/branding.hbs"
    }
  });

  _exports.default = _default;
});