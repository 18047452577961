define("ember-rs/locales/de/templates/signer/sign", ["exports", "ember-rs/locales/de/templates/signer/sign/identify", "ember-rs/locales/de/templates/signer/sign/locked-modal", "ember-rs/locales/de/templates/signer/sign/verify"], function (_exports, _identify, _lockedModal, _verify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    identify: _identify.identify,
    lockedModal: _lockedModal.lockedModal,
    verify: _verify.verify,
    "pinSent": "Eine vierstellige Bestätigungs-PIN wurde an Sie gesendet.",
    "pin": "PIN:",
    "submit": "Senden",
    "back": "Zurück",
    "deliverPin": "you to deliver a 4-digit verification PIN for you to enter on the following page.",
    "afterPhoneComplete": "Wenn Sie eine gültige Telefonnummer eingeben, senden wir Ihnen zur Verifizierung eine 4-stellige PIN per {{{identityActionName}}}. Diese PIN müssen Sie auf der folgenden Seite eingeben.",
    "phone": "Telefonanruf",
    "sms": "SMS",
    "countryCode": "Ländercode:",
    "phoneNumber": "Telefonnummer:",
    "me": "mir",
    "iAgreeComplete": "Ich bin damit einverstanden, nach Abschluss des Dokuments eine Gebühr von <strong>{{{formattedMoney}}} (USD)</strong> zu zahlen.",
    "usd": " (USD)",
    "uponExecution": " nach Abschluss des Dokuments.",
    "clickSubmit": "Durch Klicken auf 'Senden' akzeptieren Sie unsere",
    "privacyPolicy": "Datenschutzrichtlinie",
    "consent": "E-Signatur-Zustimmung",
    "contents": " und den Inhalt dieses Dokuments.",
    "saveSuccess": "Ihr Fortschritt wurde gespeichert. Sie können dieses Dokument abrufen, indem Sie auf den Link in Ihrer E-Mail klicken.",
    "submitButton": "Senden"
  };
  _exports.sign = sign;
});