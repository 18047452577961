define("ember-rs/components/integration-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    providers: Ember.computed.alias('user.allIntegrationProviders'),
    integrationFilter: function integrationFilter()
    /* integration */
    {
      return true; // default - return all results
    },
    actions: {
      toggleService: function toggleService(service, serviceEnabled, isAuthorized, provider, integrationMetadata, params) {
        this.sendAction('toggleService', service, serviceEnabled, isAuthorized, provider, integrationMetadata, params);
      }
    }
  });

  _exports.default = _default;
});