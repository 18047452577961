define("ember-rs/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o5xB0eD6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sub-header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.documentTagsEditor.addTags\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[11,\"form\"],[24,0,\"add-viewer-form\"],[4,[38,3],[[32,0],\"addNewTagAndCloseModal\",[35,2],[35,1]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"tag_form\"],[12],[2,\"\\n    \"],[8,\"limited-input-text-field\",[],[[\"@type\",\"@id\",\"@value\",\"@placeholder\",\"@required\"],[\"text\",\"newTagName\",[34,2],[30,[36,0],[\"components.documentTagsEditor.tagName\"],null],true]],null],[2,\"\\n    \"],[8,\"limited-input-text-field\",[],[[\"@type\",\"@id\",\"@class\",\"@value\",\"@placeholder\"],[\"text\",\"newTagValue\",\"last\",[34,1],[30,[36,0],[\"components.documentTagsEditor.optional\"],null]]],null],[2,\"\\n    \"],[10,\"input\"],[14,0,\"button\"],[15,2,[30,[36,0],[\"shared.actions.add\"],null]],[14,4,\"submit\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"newTagValue\",\"newTagName\",\"action\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/document-tags-editor.hbs"
    }
  });

  _exports.default = _default;
});