define("ember-rs/mixins/integration", ["exports", "ember-rs/helpers/format-provider-name"], function (_exports, _formatProviderName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    user: Ember.computed.alias('currentUser.model'),
    userIntegrations: Ember.computed.alias('user.integrations'),
    metadata: Ember.computed.alias('user.integrationProviderMetadata'),
    isAuthorized: Ember.computed.notEmpty('integration'),
    hasPreauthorization: Ember.computed.notEmpty('integrationMetadata.preauthorization_keys'),
    unactivatedIntegration: Ember.computed('hasPreauthorization', 'isAuthorized', function () {
      return this.hasPreauthorization && !this.isAuthorized;
    }),
    formattedProviderName: Ember.computed('provider', function () {
      return (0, _formatProviderName.formatProviderName)(this.provider);
    }),
    integrationMetadata: Ember.computed('provider', 'metadata', function () {
      return this.metadata[this.provider];
    }),
    integration: Ember.computed('provider', 'userIntegrations.[]', function () {
      if (Ember.isPresent(this.userIntegrations)) {
        return this.userIntegrations.filterBy('provider', this.provider).get('firstObject');
      } else {
        return null;
      }
    }),
    isDocumentIntegration: Ember.computed('metadata', function () {
      return this.providerMetadata('services').includes('sending');
    }),
    providerMetadata: function providerMetadata(attr) {
      return Ember.get(this, "metadata.".concat(this.provider, ".").concat(attr)) || [];
    },
    availableServices: Ember.computed('metadata', function () {
      return this.providerMetadata('services');
    }),
    filteredServices: Ember.computed('availableServices', 'integrationFilter', function () {
      var services = this.availableServices;
      var filterFn = this.integrationFilter;

      if (services && filterFn) {
        return services.filter(filterFn);
      } else {
        return services;
      }
    }),
    preauthorizationParams: Ember.computed('integrationMetadata', function () {
      return this.integrationMetadata['preauthorization_keys'];
    }),
    serviceEnabled: Ember.computed('integration', 'integration.contacts', 'integration.archiving', 'integration.sending', 'availableService', function () {
      return Ember.isEqual(Ember.get(this, "integration.".concat(this.availableService)), true);
    }),
    // Takes in an Object and returns Object with allowed preauthorizationParams
    restrictPreauthParams: function restrictPreauthParams(preauthParams) {
      var strictParams = Ember.Object.create({});
      var allowedParams = this.preauthorizationParams;
      allowedParams.forEach(function (preauthParam) {
        strictParams.set(preauthParam.param_name, preauthParams.get(preauthParam.param_name));
      });
      return strictParams;
    },
    isValidPreauthParams: function isValidPreauthParams(preauthParams) {
      var isValid = true;
      this.preauthorizationParams.forEach(function (preauthParam) {
        if (Ember.isBlank(preauthParams.get(preauthParam.param_name))) {
          isValid = false;
          return false;
        }
      });
      return isValid;
    },
    hasDocumentQuerying: Ember.computed('metadata', function () {
      return this.providerMetadata('document_search');
    })
  });

  _exports.default = _default;
});