define("ember-rs/locales/ja/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding2 = void 0;
  var accountOnboarding2 = {
    "body": "<span>写真を追加</span>することで、アカウントをパーソナライズし、ビジネス向けの外観にします。",
    "mobileBody": "受信者のメールとドキュメントの署名ページに追加した写真が表示されるため、署名者は署名時に安心感を得られます。"
  };
  _exports.accountOnboarding2 = accountOnboarding2;
});