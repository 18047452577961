define("ember-rs/services/tracking", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trackEvent = _trackEvent;
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    trackEvent: function trackEvent(eventName, properties) {
      _trackEvent(eventName, properties);
    }
  });

  _exports.default = _default;

  function _trackEvent(eventName, properties) {
    var _window$pendo;

    var propertiesWithProductInformation = _objectSpread(_objectSpread({}, properties), {}, {
      product: "RightSignature",
      type: eventName,
      id: (properties === null || properties === void 0 ? void 0 : properties.id) || (0, _uuid.v4)()
    });

    if ((_window$pendo = window.pendo) !== null && _window$pendo !== void 0 && _window$pendo.track) {
      window.pendo.track(eventName, propertiesWithProductInformation);
    }
  }
});