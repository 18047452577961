define("ember-rs/locales/fr/templates/signer/sign/locked-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lockedModal = void 0;
  var lockedModal = {
    "title": "Document verrouillé",
    "maxAttempts": "Vous avez dépassé le nombre maximal de tentatives de vérification de votre identité.",
    "locked": "Le document est maintenant verrouillé et l'expéditeur a été notifié. Veuillez contacter l'expéditeur du document pour continuer."
  };
  _exports.lockedModal = lockedModal;
});