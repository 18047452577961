define("ember-rs/utils/download-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = download;

  function download(file_path) {
    var fake_element = document.createElement('A');
    fake_element.href = file_path;
    fake_element.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(fake_element);
    fake_element.click();
    document.body.removeChild(fake_element);
  }
});