define("ember-rs/locales/nl/templates/signer/footer-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerDesktop = void 0;
  var footerDesktop = {
    "from": "Van",
    "submitSig": "Handtekening verzenden",
    "submit": "Verzenden",
    "clickSubmit": "Door op 'Verzenden' te klikken, gaat u akkoord met onze",
    "privacyPolicy": "Privacybeleid",
    "consent": "E-Sign-toestemming",
    "contents": "en de inhoud van dit document.",
    "left": "resterend",
    "save": "Opslaan",
    "help": "Hulp nodig?",
    "saveProgress": "Voortgang opslaan",
    "savingChanges": "Wijzigingen opslaan",
    "field": "veld",
    "fields": "velden"
  };
  _exports.footerDesktop = footerDesktop;
});