define("ember-rs/controllers/reusable-templates/new", ["exports", "ember-rs/mixins/reusable-template-choose-document", "ember-rs/mixins/integration-documents-list"], function (_exports, _reusableTemplateChooseDocument, _integrationDocumentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reusableTemplateChooseDocument.default, _integrationDocumentsList.default, {
    authorizationOrigin: {
      route: 'reusable-templates.new'
    }
  });

  _exports.default = _default;
});