define("ember-rs/locales/es/templates/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = void 0;
  var verify = {
    "advisory": "El remitente de este documento ha habilitado la función de autenticación basada en conocimientos de RightSignature para verificar su identidad. RightSignature emplea servicios externos de BlockScore para validar y verificar la información personal proporcionada. El uso de la información personal proporcionada se limita a la validación y verificación de la identidad con los registros de datos propiedad de BlockScore. La validación y verificación de la identidad no tendrán efecto en su informe crediticio, su calificación crediticia ni en informes para proveedores de préstamos. Ni IRS, ni BlockScore ni RightSignature tendrán la capacidad de ver o acceder a su informe crediticio o su información fiscal como parte de este proceso.",
    "step2": "Verificación de identidad - Paso 2 de 2",
    "answer": "Responda a las siguientes preguntas de verificación de identidad marcando la casilla junto a la respuesta adecuada.",
    "noMatch": "Lo sentimos. Las respuestas que ha proporcionado no coinciden con nuestros registros de esta identidad.",
    "youHave": "Le quedan ",
    "remaining": "intentos para acceder al documento.",
    "complete": "Completar verificación",
    "return": "Volver al formulario",
    "advisory2": "Aviso de verificación de identidad",
    "unableToAuthenticate": "No se puede autenticar correctamente. Póngase en contacto con <b>{{{sender_email}}}</b> para acceder al documento a través de otro mecanismo de autenticación."
  };
  _exports.verify = verify;
});