define("ember-rs/locales/fr/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbarMenuitem = void 0;
  var componentToolbarMenuitem = {
    "signatureComponent": "Champ de signature",
    "textComponent": "Champ de texte",
    "dateComponent": "Champ de date",
    "checkComponent": "Case à cocher",
    "checkGroupComponent": "Groupe de cases à cocher",
    "initialsComponent": "Champ d'initiales",
    "selectComponent": "Zone de sélection",
    "signatureAnnotationComponent": "Ajouter une signature",
    "signatureAnnotationName": "Annotation signature",
    "textAnnotationComponent": "Ajouter du texte",
    "textAnnotationName": "Annotation texte",
    "checkmarkAnnotationComponent": "Ajouter une case à cocher",
    "checkmarkAnnotationName": "Annotation case à cocher",
    "attachmentComponent": "Pièce jointe",
    "attachmentComponentName": "Pièce jointe demandée",
    "paymentComponent": "Recevoir un paiement",
    "signatureComponenttip": "Placez ce champ là où un signataire doit apposer sa signature.",
    "textComponenttip": "Placez ce champ là où un signataire doit saisir des informations textuelles (par exemple, entrer son nom ou son adresse).",
    "dateComponenttip": "Utilisez ce champ lorsque des dates doivent être ajoutées. Lors de la signature du document, le signataire se verra présenter un sélecteur de date, qui facilitera la sélection d'une date. La date s'affichera au format « MM-JJ-AAAA ».",
    "checkComponenttip": "Utilisez ce champ lorsque le signataire doit cocher une option (par exemple, une clause « J'accepte de ... »). Si votre document propose plusieurs options, c'est-à-dire que le signataire est limité à la sélection d'une ou de plusieurs des options, utilisez l'option Groupe de cases à cocher.",
    "checkGroupComponenttip": "Utilisez un groupe de cases à cocher si vous disposez d'une série d'options et que le signataire est limité à la sélection d'une ou de plusieurs des options.",
    "initialsComponenttip": "Placez ce champ là où un signataire doit apposer ses initiales.",
    "selectComponenttip": "Placez une zone de sélection lorsque les signataires doivent choisir parmi une liste d'options.",
    "signatureAnnotationComponenttip": "Ajoutez votre propre signature au document.",
    "textAnnotationComponenttip": "Ajoutez votre propre texte au document à pré-remplir ou à annoter.",
    "checkmarkAnnotationComponenttip": "Cochez une case sur le document.",
    "attachmentComponenttip": "Placez cette zone là où vous souhaitez qu'un signataire joigne une image ou un fichier à votre document.",
    "paymentComponenttip": "Utilisez cette option pour ajouter un bouton à votre document afin qu'un signataire puisse entrer un numéro de carte de crédit pour effectuer un paiement. Remarque : avant de l'utiliser, vous devez activer une intégration de paiement sur votre compte. (Accédez à Compte, puis sélectionnez Intégrations).",
    "unknownField": "Champ inconnu"
  };
  _exports.componentToolbarMenuitem = componentToolbarMenuitem;
});