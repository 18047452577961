define("ember-rs/components/signing-page-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    documentViewerData: Ember.inject.service(),
    classNameBindings: [':signing-page-thumbnail', 'isEmpty', 'isComplete', 'isTodo', 'isCurrent'],
    attributeBindings: ['title'],
    title: Ember.computed('page', 'totalRequiredComponents', 'totalCompletedRequiredComponents', function () {
      return "Page ".concat(this.page, ": ").concat(this.totalRequiredComponents - this.totalCompletedRequiredComponents, " required fields remaining");
    }),
    onClick: Ember.on('didInsertElement', function () {
      var self = this;
      this.$().on('click', function () {
        self.get('documentViewerData').scrollToPage(self.get('page'));
      });
    }),
    destroyClick: Ember.on('willDestroyElement', function () {
      this.$().off('click');
    }),
    isEmpty: Ember.computed('totalRequiredComponents', 'totalCompletedRequiredComponents', function () {
      return this.totalRequiredComponents === 0;
    }),
    isComplete: Ember.computed('totalRequiredComponents', 'totalCompletedRequiredComponents', function () {
      return this.totalRequiredComponents === this.totalCompletedRequiredComponents;
    }),
    isTodo: Ember.computed.not('isComplete'),
    isCurrent: Ember.computed('documentViewerData.currentPage', 'page', function () {
      return this.get('documentViewerData.currentPage') === this.page;
    })
  });

  _exports.default = _default;
});