define("ember-rs/mixins/integration-auth", ["exports", "jquery", "ember-rs/config/environment", "cryptojs"], function (_exports, _jquery, _environment, _cryptojs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ////////////////////////  METHODS  ////////////////////////
    integrationRequest: function integrationRequest(provider, serviceCategory, service, type, data) {
      var url, baseEndpoint;
      baseEndpoint = "/integrations/".concat(provider, "/").concat(serviceCategory);
      url = Ember.isEqual(service, 'root') ? baseEndpoint : [baseEndpoint, service].join('/');
      return _jquery.default.ajax({
        url: _environment.default.api.endpoint.concat(url),
        type: type,
        data: data
      });
    },
    integrationAuthorize: function integrationAuthorize(options) {
      var provider = options.provider,
          integrationMetadata = options.integrationMetadata,
          authParams = options.authParams,
          service = options.service,
          serviceCategory = options.serviceCategory,
          authorizationOrigin = options.authorizationOrigin;
      this.session.set('data.integration', {
        provider: provider,
        service: service,
        serviceCategory: serviceCategory,
        integrationMetadata: integrationMetadata,
        origin: authorizationOrigin,
        requestTokenParams: {},
        randomValue: Math.floor(Math.random() * 10000)
      });
      var state_value, state_param, random_val;

      switch (integrationMetadata.auth_type) {
        case 'oauth2':
          random_val = this.get('session.data.integration.randomValue');
          state_value = _cryptojs.default.SHA256(this.get('session.data.authenticated.access_token') + provider + random_val);
          state_param = "&state=".concat(state_value);
          window.location.href = integrationMetadata.authorize_url + state_param;
          break;

        case 'oauth1.0a':
          this.requestToken(provider);
          break;

        case 'token':
          // We just pass off the creds to API and gets response from API
          this.transitionTo('integration', provider, {
            queryParams: authParams
          });
          break;
      }
    },
    requestToken: function requestToken(provider) {
      var _this = this;

      _jquery.default.ajax({
        url: "".concat(_environment.default.api.endpoint, "/integrations/").concat(provider, "/request_token"),
        type: 'GET',
        success: function success(response) {
          var authorize_url = response.authorize_url,
              params = response.params;

          if (Ember.isPresent(params)) {
            var current = _this.get('session.data.integration');

            var newSession = Ember.assign(current, {
              requestTokenParams: params
            });

            _this.session.set('data.integration', newSession);
          }

          window.location.href = authorize_url;
        },
        error: function error() {
          _this.flashMessages.warning(_this.i18n.t("mixins.integrationAuth.unableAuthorize"));
        }
      });
    },
    parseIntegrationAuthParams: function parseIntegrationAuthParams(provider, integrationMetadata, params) {
      var authorizationParams = {};
      var state_param = true;
      var random_val = this.get('session.data.integration.randomValue');

      var state_value = _cryptojs.default.SHA256(this.get('session.data.authenticated.access_token') + provider + random_val);

      integrationMetadata['authorization_keys'].forEach(function (authorizationKey) {
        if (authorizationKey === "state") {
          if (params[authorizationKey] == state_value) {
            state_param = true;
          } else {
            state_param = false;
          }
        }

        authorizationParams[authorizationKey] = params[authorizationKey];
      });

      if (state_param) {
        return authorizationParams;
      } else {
        this.flashMessages.warning(this.i18n.t("mixins.integrationAuth.notComplete"));
      }
    }
  });

  _exports.default = _default;
});