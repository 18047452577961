define("ember-rs/templates/components/document/attachment-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HSOhuhqn",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3,[\"filename\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"attachment-left\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"attachment-name\"],[12],[1,[30,[36,2],[[35,3,[\"filename\"]],18],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"attachment-meta\"],[12],[1,[30,[36,2],[[35,1,[\"documentSigner\",\"name\"]],18],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"attachment-right\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[[34,3,[\"url\"]]]]],[14,\"download\",\"\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-download\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"attachment-left\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"attachment-pending\"],[12],[1,[30,[36,0],[\"components.document.attachmentRow.filePending\"],null]],[2,\" \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"attachment-meta\"],[12],[1,[30,[36,2],[[35,1,[\"documentSigner\",\"name\"]],18],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"attachment-right\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-circle-o\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"component\",\"truncate-middle\",\"attachment\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/document/attachment-row.hbs"
    }
  });

  _exports.default = _default;
});