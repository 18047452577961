define("ember-rs/locales/zh-cn/templates/components/hybris-trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialBanner = void 0;
  var hybrisTrialBanner = {
    "billingInfo": "您的账单信息和价格计划不是最新的。",
    "update": "单击此处进行更新",
    "hybrisTrialMsg": "您的预览期限还剩 {{{numberOfDaysLeft}}} 天! 请<a href='/upgrade-worldpay'>升级</a>为付费计划，以继续使用我们的服务。",
    "premiumTrialMsg": "You are on your Premium Trial. Please contact 1-800-441-3453 or Email us at <a href='mailto:{{{email}}}'>{{{email}}}</a> to upgrade and go completely paperless."
  };
  _exports.hybrisTrialBanner = hybrisTrialBanner;
});