define("ember-rs/components/document-limit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    currentUser: Ember.inject.service(),
    dismissed: false,
    admin: Ember.computed.alias('currentUser.canManageUsers'),
    documentLimit: Ember.computed.alias('currentUser.account.accountStatSet.documentLimit'),
    documentsSent: Ember.computed.alias('currentUser.account.accountStatSet.documentsSent'),
    dismissable: Ember.computed('used', function () {
      return this.used !== 100;
    }),
    showing: Ember.computed('dismissed', 'documentLimitExceeded', function () {
      var _Ember$getProperties = Ember.getProperties(this, 'dismissed', 'documentLimitExceeded'),
          dismissed = _Ember$getProperties.dismissed,
          documentLimitExceeded = _Ember$getProperties.documentLimitExceeded;

      return !dismissed && documentLimitExceeded;
    }),
    used: Ember.computed('documentLimit', 'documentsSent', function () {
      var _Ember$getProperties2 = Ember.getProperties(this, 'documentLimit', 'documentsSent'),
          documentLimit = _Ember$getProperties2.documentLimit,
          documentsSent = _Ember$getProperties2.documentsSent;

      if (documentLimit < 1) {
        return 100;
      }

      return documentsSent / documentLimit * 100 | 0;
    }),
    documentLimitExceeded: Ember.computed('admin', 'used', function () {
      var _Ember$getProperties3 = Ember.getProperties(this, 'admin', 'used'),
          admin = _Ember$getProperties3.admin,
          used = _Ember$getProperties3.used;

      if (admin) {
        return used >= 90;
      } else {
        return used === 100;
      }
    })
  });

  _exports.default = _default;
});