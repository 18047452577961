define("ember-rs/controllers/unauthorized/expired", ["exports", "ember-rs/mixins/redirect-token-generation"], function (_exports, _redirectTokenGeneration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_redirectTokenGeneration.default, {
    queryParams: ['emailRequired']
  });

  _exports.default = _default;
});