define("ember-rs/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eZxgNMCz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"step free\"],[15,5,[30,[36,1],[[35,0]],null]],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,2],[\"onboarding.newUserOnboardingFree.title\"],null]],[13],[2,\"\\n\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding/welcome/welcome-start-doc.svg\"],[14,0,\"graphic\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding/welcome/welcome-start-doc.png\"],[15,\"alt\",[30,[36,2],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[1,[30,[36,2],[\"onboarding.newUserOnboardingFree.body\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"stepStyle\",\"safe\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/new-user-onboarding-free.hbs"
    }
  });

  _exports.default = _default;
});