define("ember-rs/locales/fr/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanDetails = void 0;
  var hybrisPlanDetails = {
    "document-sending": "Envoi de documents",
    "document-sending-info": "Chargez et envoyez des documents dans des formats courants, notamment PDF, .doc, etc.",
    "signature": "Signatures manuscrites",
    "signature-info": "Signez avec votre souris ou votre écran tactile - ressemble à votre signature papier.",
    "customer-support": "Assistance client 24h/24 et 7j/7",
    "customer-support-info": "Notre équipe d'assistance primée se tient à votre entière disposition.",
    "email-alert": "Alertes par e-mail",
    "email-alert-info": "Rappels pour vos signataires et mises à jour instantanées de l'état de vos documents.",
    "integration": "Intégration d'add-ons",
    "integration-info": "Connectez RightSignature à plus d'une douzaine des applications avancées les plus populaires.",
    "type-sign": "Taper pour signer",
    "type-sign-info": "Permet à vos signataires de signer des documents à l'aide d'un clavier.",
    "mobile": "Signature sur appareils mobiles",
    "mobile-info": "Signez des documents en tout lieu à l'aide de votre smartphone ou de votre tablette.",
    "guided-signing": "Signature guidée",
    "guided-signing-info": "Réduisez les erreurs et les omissions grâce à des instructions simples et détaillées.",
    "archive": "Archivage sécurisé des documents",
    "archive-info": "Protégez vos documents importants grâce à nos archives sécurisées et consultables.",
    "encrypt": "Chiffrement des données 256 bits",
    "encrypt-info": "La sécurité de niveau bancaire protège vos documents sensibles contre les fuites et le vol.",
    "biometric": "Authentification biométrique",
    "biometric-info": "Permet de vérifier l'authenticité de votre signataire à l'aide de données biométriques clés.",
    "fingerprint": "Empreintes digitales numériques",
    "fingerprint-info": "Les sommes de contrôle numériques SHA-1 empêchent toute partie de modifier votre document.",
    "audit": "Journal d'audit et certificat",
    "audit-info": "Pour assurer le respect de la législation, chaque action dans un document est consignée et sécurisée.",
    "template": "Modèles réutilisables",
    "template-info": "Partagez des documents couramment utilisés avec toute votre équipe.",
    "branding": "Branding personnalisé",
    "branding-info": "Ajoutez votre logo, vos couleurs et votre photo aux documents RightSignature.",
    "attachments": "Demander des pièces jointes",
    "attachments-info": "Permet à vos signataires de joindre un fichier dans le cadre de la signature de votre document.",
    "payments": "Demander des paiements",
    "payments-info": "Traitez les paiements ou recueillez les informations de carte de crédit sur vos documents.",
    "api": "API pour développeur",
    "api-info": "Intégrez les signatures électroniques RightSignature à vos propres applications ou sites Web.",
    "kba": "Authentification basée sur les connaissances",
    "kba-info": "Dans certaines industries, permet de renforcer le respect des réglementations applicables.",
    "standard": "Standard",
    "advanced": "<span>Avancé<span>",
    "advancedKba": "<span>KBA avancé<span>",
    "sharefilePremium": "<span><p>Sharefile Premium</p> <p> avec e-Signature</p></span>",
    "annually": "Annuelle",
    "quarterly": "Trimestrielle",
    "monthly": "Tous les mois",
    "month": "mois",
    "year": "année",
    "quarter": "trimestre",
    "document-sends-per-user": "100 envois de documents par utilisateur",
    "hand-written-text-to-sign": "Signature manuscrite, texte en signature, cliquer pour accepter",
    "bulk-send-for-signature": "Envoi en bloc pour signature",
    "sequenced-signing": "Séquence de signature",
    "knowledge-based-authentication": "Authentification basée sur les connaissances",
    "unlimited-document-sends": "Envoyez un nombre illimité de documents pour signature",
    "store-sync-share-files": "Stockez, synchronisez et partagez des fichiers en toute simplicité",
    "hipaa-compliant": "Configuration de la conformité à la loi HIPAA",
    "advanced-caption": "Comprend des fonctionnalités populaires telles que :",
    "advanced-kba-caption": "Comprend des fonctionnalités populaires telles que :",
    "sharefile-caption": "Inclut TOUTES LES FONCTIONNALITÉS DE SIGNATURE ÉLECTRONIQUE ET PLUS ENCORE AVEC CITRIX SHAREFILE :",
    "minimum-of": "Minimum de {{{noUsers}}} utilisateurs",
    "users": "utilisateurs",
    "user": "utilisateur",
    "user-per-month": "par utilisateur/mois",
    "compare-plans-in-detail": "<a href='https://citrix.sharefile.com/share/view/s1493194af0a44866ae95a6a667e7ffcd' style='color:#2071C5' target='_blank'>Comparer les plans en détail</a>",
    "selected-plan-requires-3-users": "<span style='color:#536E7B'>Le plan sélectionné nécessite au moins 3 utilisateurs</span>",
    "more-than-1000-users-contact-sales": "<span style='color:#2071C5'>Si vous avez besoin de plus de 1000 utilisateurs, veuillez <a href={{driftLinkId}} style='color: #2071C5; text-decoration: underline; text-transform: lowercase'>contacter un représentant commercial. </a></span>",
    "trail-upgrade-msg": "Nombre de jours restant dans votre version d'évaluation : {{{numberOfDaysLeft}}}. Pour bénéficier d'une expérience optimale sans interruption, envisagez de mettre à niveau votre plan dès maintenant.",
    "savings": "Économies",
    "contact-sales": "Chatter avec un commercial",
    "select-plan": "Sélectionner un plan",
    "selected-plan": "Plan sélectionné",
    "best-for-team-collaboration": "Meilleure offre"
  };
  _exports.hybrisPlanDetails = hybrisPlanDetails;
});