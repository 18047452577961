define("ember-rs/locales/zh-cn/templates/unauthorized/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unauthorizedFile = void 0;
  var unauthorizedFile = {
    "unauthorized": "未授权",
    "youAreNot": "您无权查看该页面。"
  };
  _exports.unauthorizedFile = unauthorizedFile;
});