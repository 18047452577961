define("ember-rs/locales/it/templates/reports/export/month", ["exports", "ember-rs/locales/it/templates/reports/export/month/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.month = void 0;
  var month = {
    template: _template.template,
    "header": "Filtra per modello",
    "description": "Selezionare il modello in base al quale verranno filtrati i documenti",
    "template-select-text": "Scegli modello"
  };
  _exports.month = month;
});