define("ember-rs/locales/pt-br/controllers/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "success": "Reenviar link de assinatura",
    "failure": "Falha ao enviar o link: {{{response}}}"
  };
  _exports.identityExpired = identityExpired;
});