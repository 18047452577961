define("ember-rs/locales/en/templates/components/integration-document-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocumentSelector = void 0;
  var integrationDocumentSelector = {
    "chooseDocFrom": "Choose a Document from:",
    "loading": "Loading...",
    "back": "Back",
    "search": "Search Documents...",
    "use": "Use",
    "empty": "This folder is empty",
    "success": "Successfully disabled sending for {{{provider}}}",
    "error": "Error. Could not modify sending for {{{provider}}}"
  };
  _exports.integrationDocumentSelector = integrationDocumentSelector;
});