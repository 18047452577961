define("ember-rs/locales/en/mixins/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "successUpgrade": "You've successfully upgraded your account. Welcome to RightSignature!",
    "dataUnavailable": "Data is not available at this moment. Please come back to this page later."
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});