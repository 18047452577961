define("ember-rs/components/reports/cycle-time-statistics", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var periods = ['thisMonth', 'lastMonth'];

  var splitByCounts = function splitByCounts(datum, counts) {
    var output = {};

    for (var i = 0, iMax = counts.length, count; i < iMax; i++) {
      count = counts[i];
      output[count] = datum[count] || 0;
    }

    return output;
  };

  var formatByMonth = function formatByMonth(timeSeries, total, months, today, counts) {
    counts = counts || ['count'];
    var output = {
      total: splitByCounts(total, counts)
    };

    for (var i = 0, iMax = periods.length, monthLabel, currentMonth, j, jMax, datum; i < iMax; i++) {
      monthLabel = periods[i];
      output[monthLabel] = splitByCounts({}, counts);
      currentMonth = (0, _moment.default)(today).subtract(i, 'months').month();

      for (j = 0, jMax = timeSeries.length; j < jMax; j++) {
        datum = timeSeries[j];

        if ((0, _moment.default)(datum.date).month() === currentMonth) {
          output[monthLabel] = splitByCounts(datum, counts);
        }
      }
    }

    return output;
  };

  var _default = Ember.Component.extend({
    formattedByMonth: Ember.computed('report', function () {
      return formatByMonth(this.get("report.time_to_sign") || [], this.get("report.time_to_sign_total") || 0, this.get("report.months_elapsed") || 1, this.get("report.date"), ['five_minutes', 'thirty_minutes', 'one_hour', 'twelve_hours', 'one_day']);
    })
  });

  _exports.default = _default;
});