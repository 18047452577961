define("ember-rs/locales/en/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFound = void 0;
  var notFound = {
    "doesNotExist": "This page does not exist.",
    "didYou": "Did you follow a link from somewhere else at RightSignature&trade;?",
    "anotherPart": "If you reached this page from another part of RightSignature&trade;, please <a href='https://support.citrix.com/cscase'>contact Support</a> so we can correct our mistake.",
    "followLink": "Did you follow a link from another site?",
    "otherSite": "Links from other sites can sometimes be outdated or misspelled. Please <a href='https://support.citrix.com/cscase'>contact Support</a> and let us know what link you clicked to get here so that we can attempt to contact the other site in order to fix the problem.",
    "typeUrl": "Did you type the URL?",
    "doubleCheck": "You may have typed the address (URL) incorrectly. Check to make sure you've got the exact right spelling, capitalization, etc.",
    "home": "Home"
  };
  _exports.notFound = notFound;
});