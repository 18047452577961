define("ember-rs/locales/ja/templates/components/payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentForm = void 0;
  var paymentForm = {
    "name": "名前",
    "firstName": "名...",
    "lastName": "姓...",
    "address1": "住所 1...",
    "address2": "住所 2...",
    "city": "市区町村",
    "state": "都道府県",
    "zip": "郵便番号",
    "country": "国",
    "address": "アドレス",
    "vatId": "VAT ID",
    "redirect": "リダイレクト中...",
    "upgrade": "アップグレード",
    "payUpgrade": "支払いとアップグレード",
    "oops": "問題が発生しました",
    "ok": "OK"
  };
  _exports.paymentForm = paymentForm;
});