define("ember-rs/locales/zh-cn/templates/signer/passcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcode = void 0;
  var passcode = {
    "enterPin": "输入 PIN。",
    "access": "输入提供的 PIN 以访问文档。",
    "unlock": "解锁",
    "docProtected": "本文档受通行码保护。",
    "answer": "答案...",
    "show": "显示通行码",
    "continue": "继续访问文档",
    "answerQuestion": "回答下面的安全问题以访问文档"
  };
  _exports.passcode = passcode;
});