define("ember-rs/components/review-and-send-item", ["exports", "ember-rs/mixins/distribution-method", "ember-rs/mixins/tags-collection", "ember-rs/mixins/passcode-pin-generator"], function (_exports, _distributionMethod, _tagsCollection, _passcodePinGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_PASSCODE_QUESTION = "Enter Passcode PIN";

  var _default = Ember.Component.extend(_distributionMethod.default, _tagsCollection.default, _passcodePinGenerator.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ////////////////////////  FORMATTING  ////////////////////////
    classNames: ['review-template'],
    ////////////////////////  DEFAULTS  ////////////////////////
    messageToAll: '',
    newShareWith: '',
    showShareModal: false,
    showExpirationModal: false,
    showPasscodeModal: false,
    showKBAModal: false,
    showKBALimitExceededModal: false,
    showTagsModal: false,
    showSalesforceModal: false,
    hasMessageLengthError: false,
    didInsertElement: function didInsertElement() {
      if (Ember.isBlank(this.get('documentTemplate.name')) && !this.get('documentTransaction.isPackage')) {
        var defaultName = this.get('documentTemplate.upload.baseFile.filename').split('.')[0].split(/[^a-zA-Z0-9-]+/).join(' ');
        this.set('documentTemplate.name', defaultName);
      }

      if (Ember.isBlank(this.get('documentTemplate.expiresIn'))) {
        this.set('documentTemplate.expiresIn', this.get('currentUser.model.documentExpiration') || 30);
      }

      if (!this.get('documentTemplate.personalizedMessages')) {
        this.set('messageToAll', this.get('documentTemplate.roles.firstObject.message'));
      }

      if (Ember.isBlank(this.messageToAll)) {
        if (this.get('documentTransaction.isSelfSign')) {
          this.set('messageToAll', "".concat(this.get('documentTemplate.senderName'), " has sent you the following document:"));
        } else {
          // this.set('messageToAll', `Please sign this document.`);
          this.set('messageToAll', "");
        }
      }

      if (!this.creatingReusableTemplate) {
        if (Ember.isBlank(this.get('documentTemplate.passcodeQuestion'))) {
          this.set('documentTemplate.passcodeQuestion', DEFAULT_PASSCODE_QUESTION);
        }

        if (Ember.isBlank(this.get('documentTemplate.passcodeAnswer'))) {
          this.set('documentTemplate.passcodeAnswer', this.generatePasscodePIN());
        }
      }

      if (this.get('documentTemplate.passcode') && this.get('documentTransaction.isBulkSend')) {
        this.toggleProperty('documentTemplate.passcode');
      }

      if (this.get('documentTransaction.isPackage')) {
        this.$().find('#packagerName').focus();
      }
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    disablePasscode: Ember.computed.or('currentUser.account.enablePasscode', 'documentTemplate.kba'),
    hasMultipleRoles: Ember.computed.gte('documentTemplate.roles.length', 2),
    notShared: Ember.computed.empty('documentTemplate.sharedWith'),
    creatingReusableTemplate: Ember.computed.empty('documentTransaction'),
    newTagNameEmpty: Ember.computed.empty('newTagName'),
    firstPageImage: Ember.computed.alias('documentTemplate.upload.baseFile.pageImages.firstObject'),
    tags: Ember.computed.alias('documentTemplate.tags'),
    // overrides tags in TagsCollection
    expiresIn: Ember.computed('documentTemplate.expiresIn', {
      get: function get() {
        return this.get('documentTemplate.expiresIn');
      },
      set: function set(key, value) {
        var expires = value | 0;
        this.set('documentTemplate.expiresIn', expires);
        return expires;
      }
    }),
    hideMessageField: Ember.computed('documentTransaction.isSelfSign', 'documentTransaction.isInPerson', 'notShared', function () {
      return this.get('documentTransaction.isSelfSign') && this.notShared || this.get('documentTransaction.isInPerson');
    }),
    salesforceEnabled: Ember.computed('currentUser.model.integrations.[]', function () {
      return this.get('currentUser.model.integrations').isAny('provider', 'salesforce');
    }),
    updateMessages: Ember.observer('messageToAll', function () {
      var customMessage = this.messageToAll || '';
      var hasMessageLengthError = !!customMessage && customMessage.length > 100000;
      this.validateMessageLength(hasMessageLengthError);
      this.set('hasMessageLengthError', hasMessageLengthError);
      this.documentTemplate.setMessages(customMessage);
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleModal: function toggleModal(modalName) {
        if (modalName === 'Passcode') {
          this.toggleProperty('documentTemplate.passcode');
        }

        this.toggleProperty("show".concat(modalName, "Modal"));
      },
      toggleKBAModal: function toggleKBAModal() {
        if (this.get('currentUser.account.exceededKbaLimit')) {
          this.toggleProperty('showKBALimitExceededModal');
        } else {
          this.toggleProperty('showKBAModal');
        }
      },
      addShareWith: function addShareWith() {
        this.get('documentTemplate.sharedWith').pushObject(this.newShareWith);
        this.setProperties({
          newShareWith: '',
          showShareModal: false
        });
      },
      removeShareWith: function removeShareWith(documentShare) {
        this.get('documentTemplate.sharedWith').removeObject(documentShare);
      },
      addSalesforceOpportunityTag: function addSalesforceOpportunityTag(opportunityTag) {
        this.send('addNewTag', opportunityTag.name, opportunityTag.id);
      },
      addNewTagAndUpdateTags: function addNewTagAndUpdateTags(newTagName, newTagValue) {
        this.send('addNewTag', newTagName, newTagValue);
        this.set('documentTemplate.tags', this.updateDocumentTags());
      },
      updateTags: function updateTags(newTags) {
        this.set('documentTemplate.tags', newTags);
      },
      validateExpiresIn: function validateExpiresIn(expiresIn) {
        if (!Ember.isPresent(expiresIn) || expiresIn < 1 || expiresIn > 365) {
          this.flashMessages.warning(this.i18n.t('components.reviewAndSendItem.expiresIn'));
        }
      },
      isPinValid: function isPinValid(pin) {
        if (Number.isNaN(Number(pin))) {
          this.flashMessages.warning(this.i18n.t("components.reviewAndSendItem.pin-specification"));
        }
      },
      regeneratePasscodePIN: function regeneratePasscodePIN() {
        this.set('documentTemplate.passcodeAnswer', this.generatePasscodePIN());
      },
      togglePasscodeForKBA: function togglePasscodeForKBA(kbaDisabled) {
        if (kbaDisabled && this.get('currentUser.account.enablePasscode')) {
          this.set('documentTemplate.passcode', true);
          this.set('documentTemplate.passcodeAnswer', this.generatePasscodePIN());
        } else {
          this.set('documentTemplate.passcode', false);
          this.set('documentTemplate.passcodeAnswer', '');
        }
      }
    }
  });

  _exports.default = _default;
});