define("ember-rs/locales/nl/templates/components/plan-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planInfo = void 0;
  var planInfo = {
    "perMonth": "/maand",
    "teamMember": "Teamlid",
    "teamMembers": "Teamleden",
    "call": "Bel +1-855-895-4862",
    "selectedPlan": "Geselecteerd abonnement",
    "selectPlan": "Abonnement selecteren"
  };
  _exports.planInfo = planInfo;
});