define("ember-rs/locales/zh-cn/templates/document-transaction/upload-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload_signers = void 0;
  var upload_signers = {
    "instructions_header": "按照以下步骤上载您的 CSV 文件",
    "step_1": "步骤 1",
    "step_2": "步骤 2",
    "step_3": "步骤 3",
    "step_1_text": "下载批量发送签名者示例列表",
    "step_2_text": "填写姓名和电子邮件字段(必填)。",
    "step_3_text": "上载已填写的 csv。",
    "instructions_note": "请注意: 签名者数量不能超过 300",
    "upload_failed": "上载失败",
    "upload_failure_message": "由于文件损坏或处理错误，上载失败。请确保文件包含有效内容，然后再次尝试上载。",
    "view_all": "查看全部",
    "ok": "确定",
    "validate_and_reupload": "您的文件包含更多错误，请检查验证结果并尝试重新上载。"
  };
  _exports.upload_signers = upload_signers;
});