define("ember-rs/locales/ja/templates/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detailsPanel = void 0;
  var detailsPanel = {
    "clientId": "クライアント ID:",
    "clientSecret": "クライアント シークレット:",
    "lastViewed": "最終閲覧: ",
    "fromIp": "IP から",
    "hidden": "セキュリティのため非表示",
    "reveal": "シークレットを表示",
    "privateToken": "プライベート API トークン:",
    "revealToken": "トークンを表示",
    "regenerate": "トークンの再生成。警告: この操作を取り消すことができません",
    "description": "説明:",
    "scope": "スコープ:",
    "redirectUri": "リダイレクト URI:",
    "applicationUrl": "アプリケーション URL:",
    "orgUrl": "組織 URL:",
    "createdAt": "作成:",
    "updatedAt": "更新:",
    "edit": "編集"
  };
  _exports.detailsPanel = detailsPanel;
});