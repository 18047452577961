define("ember-rs/locales/ja/templates/components/document-token-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTokenExpirationSelect = void 0;
  var documentTokenExpirationSelect = {
    "Do_not_expire_links": "リンクを無期限にする",
    "1_hour": "1時間",
    "3_hours": "3時間",
    "8_hours": "8時間",
    "24_hours": "24 時間",
    "1_week": "1週間"
  };
  _exports.documentTokenExpirationSelect = documentTokenExpirationSelect;
});