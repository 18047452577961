define("ember-rs/locales/fr/mixins/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.settings = void 0;
  var settings = {
    "updateSuccessful": "Paramètre mis à jour avec succès",
    "updateError": "Erreur lors de la mise à jour du paramètre"
  };
  _exports.settings = settings;
});