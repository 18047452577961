define("ember-rs/locales/ja/templates/onboarding/welcome-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding3 = void 0;
  var welcomeOnboarding3 = {
    "start": "ここから署名用のドキュメントの送信を開始できます。"
  };
  _exports.welcomeOnboarding3 = welcomeOnboarding3;
});