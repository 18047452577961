define("ember-rs/templates/components/provider-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HIqcrF9t",
    "block": "{\"symbols\":[\"result\"],\"statements\":[[10,\"li\"],[14,0,\"provider-header\"],[12],[2,\"\\n  \"],[1,[34,5]],[2,\"\\n\"],[6,[37,0],[[35,2,[\"isLoading\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"loading-spinner\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,0],[[35,6]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"results\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"li\"],[16,0,[31,[[30,[36,0],[[32,1,[\"selected\"]],\"selected\"],null],\" result\"]]],[4,[38,1],[[32,0],\"select\",[32,1]],null],[4,[38,1],[[32,0],\"select\",[32,1]],[[\"on\"],[\"mouseEnter\"]]],[12],[2,\"\\n      \"],[11,\"span\"],[24,0,\"name-result\"],[4,[38,1],[[32,0],\"select\",[32,1]],null],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n      \"],[11,\"span\"],[24,0,\"email-result\"],[4,[38,1],[[32,0],\"select\",[32,1]],null],[12],[1,[32,1,[\"email\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"provider\",\"-track-array\",\"each\",\"formattedProviderName\",\"hasResults\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/provider-results.hbs"
    }
  });

  _exports.default = _default;
});