define("ember-rs/locales/nl/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pendingIdentityVerification = void 0;
  var pendingIdentityVerification = {
    "pendingIdentityVerification": "Identiteitverificatie in behandeling",
    "success": "U hebt dit document met succes ondertekend. Om dit document te voltooien en terug te sturen naar de afzender, moet u uw identiteit verifiëren. Dit is heel eenvoudig. Controleer uw e-mail en klik op de koppeling 'e-mail verifiëren' die door RightSignature aan u is verzonden.",
    "resend": "Identiteitverificatie opnieuw verzenden"
  };
  _exports.pendingIdentityVerification = pendingIdentityVerification;
});