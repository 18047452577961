define("ember-rs/components/document-overlayer", ["exports", "jquery", "ember-rs/mixins/component", "ember-rs/mixins/component-dimensions"], function (_exports, _jquery, _component, _componentDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_component.default, _componentDimensions.default, {
    classNames: ['document-overlayer'],
    ////////////////////////  DEPENDENCIES  ////////////////////////
    documentViewerData: Ember.inject.service(),
    overlayerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    minComponentSize: 10,
    showDetectionHelp: false,
    showImportOverlay: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    checkGroupCounter: Ember.computed('documentTemplate.components.[]', function () {
      var hasCheckGroup = this.get('documentTemplate.components').any(function (component) {
        return component.get('type') === 'checkGroupComponent';
      });

      if (hasCheckGroup) {
        var groupIds = this.get('documentTemplate.components').mapBy('groupId').compact().uniq();
        return Math.max.apply(Math, _toConsumableArray(groupIds));
      } else {
        return 0;
      }
    }),
    ////////////////////////  STYLE  ////////////////////////
    suggestDetectionStyle: Ember.computed('isSelfSign', function () {
      if (this.isSelfSign) {
        return 'top: 150px'.htmlSafe();
      } else {
        return 'top: 360px'.htmlSafe();
      }
    }),
    toolPreviewStyle: Ember.computed('overlayerData.withinGutter', 'overlayerData.tempToolDimensions', 'overlayerData.{selectedComponentClass,componentBorderWidth}', 'overlayerData.mouseCoordinates.{x,y}', function () {
      var dimensions;

      if (this.get('overlayerData.withinGutter')) {
        dimensions = this.get('overlayerData.tempToolDimensions');
      } else {
        dimensions = this.get("lastComponentDimensions.".concat(this.get('overlayerData.selectedComponentClass')));
      } // adjusting width and height to account for 1px border


      return "left: ".concat(this.get('overlayerData.mouseCoordinates.x'), "px; top: ").concat(this.get('overlayerData.mouseCoordinates.y'), "px; width: ").concat(dimensions.width - this.get('overlayerData.componentBorderWidth') * 2, "px; height: ").concat(dimensions.height - this.get('overlayerData.componentBorderWidth') * 2, "px;").htmlSafe();
    }),
    ////////////////////////  PROPERTIES  ////////////////////////
    pageCount: Ember.computed.alias('documentTemplate.upload.baseFile.pageCount'),
    transactionUser: Ember.computed.alias('documentTemplate.documentTransaction.user'),
    suggestDetection: Ember.computed.not('hasSeenRectDetect'),
    isSelfSign: Ember.computed.equal('documentTemplate.workflowType', 'selfSign'),
    hasSeenRectDetect: Ember.computed('transactionUser.hasSeenRectDetect', 'confirmedRectDetectHelp', function () {
      return this.get('transactionUser.hasSeenRectDetect') || this.confirmedRectDetectHelp;
    }),
    isInvalid: Ember.computed('documentTemplate.components.@each.isValid', function () {
      return this.get('documentTemplate.components').isAny('isValid', false);
    }),
    ////////////////////////  HOOKS  ////////////////////////
    init: function init() {
      this._super.apply(this, arguments);

      this.set('documentViewerData.sidebarWidth', 420);

      if (this.isSelfSign) {
        this.set('overlayerData.selectedComponentClass', 'textAnnotationComponent');
      } else {
        this.set('overlayerData.selectedComponentClass', 'textComponent');
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // Make sure components are assigned to a valid role, otherwise assign to first role


      var validRoles = this.get('documentTemplate.roles').mapBy('name');
      this.get('documentTemplate.components').forEach(function (component) {
        if (!validRoles.includes(component.get('assignedTo')) && !component.get('isAnnotation') && !component.get('isMergeField')) {
          component.set('assignedTo', validRoles[0]);
        }
      });
      (0, _jquery.default)(window).on("keydown.".concat(this.elementId), Ember.run.bind(this, this.onKeyDown));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(window).off("keydown.".concat(this.elementId));
    },
    onKeyDown: function onKeyDown(e) {
      var self = this;
      var selectedComponent, dimensions;

      if (e.target.nodeName !== 'INPUT' && e.target.nodeName !== 'TEXTAREA') {
        if (e.keyCode === 8 || e.keyCode === 46) {
          // Delete
          e.preventDefault();
          this.overlayerData.deleteSelectedComponents();
        } else if (e.keyCode === 37) {
          // left
          e.preventDefault();

          if (Ember.get(this, 'overlayerData.singleComponentSelected')) {
            selectedComponent = Ember.get(this, 'overlayerData.selectedComponents')[0];
            dimensions = this.getComponentDimensions(selectedComponent);
            dimensions.x -= 1;
            this.setComponentDimensions(selectedComponent, dimensions);
          } else if (Ember.get(this, 'overlayerData.multiComponentSelected')) {
            Ember.get(this, 'overlayerData.selectedComponents').forEach(function (selectedComponent) {
              dimensions = self.getComponentDimensions(selectedComponent);
              dimensions.x -= 1;
              self.setComponentDimensions(selectedComponent, dimensions);
            });
            self.get('overlayerData').updateSelectedComponents();
          }
        } else if (e.keyCode === 38) {
          // up
          e.preventDefault();

          if (Ember.get(this, 'overlayerData.singleComponentSelected')) {
            selectedComponent = Ember.get(this, 'overlayerData.selectedComponents')[0];
            dimensions = this.getComponentDimensions(selectedComponent);
            dimensions.y -= 1;
            this.setComponentDimensions(selectedComponent, dimensions);
          } else if (Ember.get(this, 'overlayerData.multiComponentSelected')) {
            Ember.get(this, 'overlayerData.selectedComponents').forEach(function (selectedComponent) {
              dimensions = self.getComponentDimensions(selectedComponent);
              dimensions.y -= 1;
              self.setComponentDimensions(selectedComponent, dimensions);
            });
            self.get('overlayerData').updateSelectedComponents();
          }
        } else if (e.keyCode === 39) {
          // right
          e.preventDefault();

          if (Ember.get(this, 'overlayerData.singleComponentSelected')) {
            selectedComponent = Ember.get(this, 'overlayerData.selectedComponents')[0];
            dimensions = this.getComponentDimensions(selectedComponent);
            dimensions.x += 1;
            this.setComponentDimensions(selectedComponent, dimensions);
          } else if (Ember.get(this, 'overlayerData.multiComponentSelected')) {
            Ember.get(this, 'overlayerData.selectedComponents').forEach(function (selectedComponent) {
              dimensions = self.getComponentDimensions(selectedComponent);
              dimensions.x += 1;
              self.setComponentDimensions(selectedComponent, dimensions);
            });
            self.get('overlayerData').updateSelectedComponents();
          }
        } else if (e.keyCode === 40) {
          // down
          e.preventDefault();

          if (Ember.get(this, 'overlayerData.singleComponentSelected')) {
            selectedComponent = Ember.get(this, 'overlayerData.selectedComponents')[0];
            dimensions = this.getComponentDimensions(selectedComponent);
            dimensions.y += 1;
            this.setComponentDimensions(selectedComponent, dimensions);
          } else if (Ember.get(this, 'overlayerData.multiComponentSelected')) {
            Ember.get(this, 'overlayerData.selectedComponents').forEach(function (selectedComponent) {
              dimensions = self.getComponentDimensions(selectedComponent);
              dimensions.y += 1;
              self.setComponentDimensions(selectedComponent, dimensions);
            });
            self.get('overlayerData').updateSelectedComponents();
          }
        }
      }
    },
    ////////////////////////  METHODS  ////////////////////////
    createComponent: function createComponent(componentClass, options) {
      var componentName = this.generateComponentName(componentClass);
      var assignedTo = options.assignedTo; // If a Check Group and a name is specified, use that instead

      if (componentClass === 'checkGroupComponent' && Ember.isPresent(options.name)) {
        componentName = options.name;
      }

      if (componentClass.match(/.*AnnotationComponent$/)) {
        assignedTo = null;
      }

      var component = this.store.createRecord(componentClass, _jquery.default.extend(options, {
        id: "component".concat(Ember.uuid()),
        // assign frontend ID so we can reference in the Ember Data store in other components (selectable)
        documentTemplate: this.documentTemplate,
        name: componentName,
        type: componentClass,
        assignedTo: assignedTo
      }));

      if (['attachmentComponent', 'selectComponent'].includes(componentClass)) {
        this.overlayerData.selectComponents(Ember.A([component]));
      }

      return component;
    },
    generateComponentName: function generateComponentName(componentClass) {
      var name = this.defaultComponentNames(componentClass);
      var siblings = this.get('documentTemplate.components').filterBy('type', componentClass).get('length');
      return "".concat(name, " ").concat(++siblings);
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      signatureCreated: function signatureCreated(signatureData, component) {
        var _this = this;

        var setSavedSignature = function setSavedSignature(signature) {
          component.set('signature', signature);

          _this.set('previousSignature', signature);
        };

        if (Ember.typeOf(signatureData) === 'instance') {
          setSavedSignature(signatureData);
        } else {
          this.store.createRecord('signature', signatureData).save().then(function (signature) {
            setSavedSignature(signature);
          });
        }
      },
      deleteSignatureComponent: function deleteSignatureComponent(component) {
        var overlayerData = this.overlayerData;
        overlayerData.selectComponents(Ember.A([component]));
        overlayerData.deleteSelectedComponents();
      },
      toggleDetection: function toggleDetection() {
        this.toggleProperty('overlayerData.useRectDetect');
      },
      nextStep: function nextStep() {
        this.sendAction('nextStep');
      },
      addComponent: function addComponent(componentClass, options) {
        if (componentClass === 'attachmentComponent') {
          if (Ember.isBlank(this.get('currentUser.account.attachments')) || !this.get('currentUser.account.attachments')) {
            this.flashMessages.warning(this.i18n.t('components.documentOverlayer.attachmentUnavailable'));
            return;
          }
        }

        if (componentClass === 'paymentComponent') {
          if (Ember.isBlank(this.get('currentUser.account.paymentsIntegration')) || !this.get('currentUser.account.paymentsIntegration')) {
            this.flashMessages.warning(this.i18n.t('components.documentOverlayer.paymentUnavailable'));
            return;
          } else if (!this.get('currentUser.model.integrations').mapBy('provider').includes('stripe')) {
            this.flashMessages.warning(this.i18n.t('components.documentOverlayer.paymentNotEnabled'));
            return;
          }
        }

        options = _jquery.default.extend({}, options);
        var dimensions;

        if (Ember.isBlank(options.page)) {
          options.page = this.get('documentViewerData.currentPage');
        }

        if (Ember.isBlank(options.width)) {
          // adjust sizing of new component depending on if the click was too close to the right hand of the page or not to avoid the compononent going off the page
          if (this.get('overlayerData.withinGutter')) {
            dimensions = this.get('overlayerData.tempToolDimensions');
            this.setLastComponentDimensions(componentClass, dimensions.width, dimensions.height);
          } else {
            dimensions = this.get("lastComponentDimensions.".concat(componentClass));
          }

          options.width = dimensions.width / this.get('documentViewerData.pageWidth');
          options.height = dimensions.height / this.get('documentViewerData.pageHeight');
        }

        if (Ember.isBlank(options.x)) {
          // Default, placed in the center of the document-viewer horizontally
          options.x = 0.5 - options.width / 2;
        }

        if (Ember.isBlank(options.y)) {
          // Default, placed in the center of the document-viewer vertically
          // accounts for scrollTop, pageHeight, headerHeight, windowHeight, and component height
          options.y = this.get('documentViewerData.scrollTop') / this.get('documentViewerData.pageHeight') + (this.get('layoutSizeData.windowHeight') / 2 - options.height / 2 - this.get('layoutSizeData.tmHeaderHeight')) / this.get('documentViewerData.pageHeight'); // correct the y position and page number

          if (options.y > 1) {
            options.page = Math.floor(options.y) + 1;
            options.y = options.y - Math.floor(options.y);
          }
        } // Enforce minimum sizing


        var minWidth = this.minComponentSize / this.get('documentViewerData.pageWidth');
        var minHeight = this.minComponentSize / this.get('documentViewerData.pageHeight');

        if (options.height < minHeight || options.width < minWidth) {
          options.height = minHeight;
          options.width = minWidth * this.defaultComponentRatios[componentClass];
        } // TODO: Enforce in bounds of page


        if (this.get('documentTemplate.roles.length') === 0) {
          this.flashMessages.warning(this.i18n.t('components.documentOverlayer.noRoles'));
          return;
        }

        if (Ember.isBlank(this.get('overlayerData.selectedAssignedTo')) || !this.get('documentTemplate.roles').mapBy('name').includes(this.get('overlayerData.selectedAssignedTo'))) {
          this.set('overlayerData.selectedAssignedTo', this.get('documentTemplate.roles').objectAt(0).get('name'));
        }

        var newComponent; // special logic for checkbox groups

        if (componentClass === 'checkGroupComponent') {
          // checkbox group created from sidebar
          if (options.createNewGroup) {
            options.assignedTo = this.get('overlayerData.selectedAssignedTo');
            options.groupId = this.checkGroupCounter + 1;
            delete options.createNewGroup;
            newComponent = this.createComponent(componentClass, options); // TODO Math to ensure second component does not get placed off the page

            options.x = options.x + options.width * 2;
            newComponent = this.createComponent(componentClass, options); // adding an additional checkbox to existing group
          } else if (options.addToGroup) {
            options.groupId = options.existingGroupId;
            delete options.addToGroup;
            delete options.existingGroupId;
            newComponent = this.createComponent(componentClass, options);
          }
        } else {
          // default signer role name
          options.assignedTo = this.get('overlayerData.selectedAssignedTo');

          if (this.get('overlayerData.isSourceMapped')) {
            options.isMergeField = true;
            options.isSourceMapped = true;
            options.source = this.get('overlayerData.selectedComponentData');
            options.isRequired = this.get('overlayerData.isRequired');
          }

          newComponent = this.createComponent(componentClass, options);
        } // Make sure newComponent is in bounds of page


        var newComponentDimensions = this.getComponentDimensions(newComponent);
        this.setComponentDimensions(newComponent, newComponentDimensions);

        if (componentClass === 'signatureAnnotationComponent') {
          this.overlayerData.deselectComponents();
          this.set('signaturePadComponent', newComponent);

          if (this.hasDrawnSignature) {
            this.set('applyPreviousSignature', true);
          } else {
            this.set('showSignaturePad', true);
          }
        } else if (componentClass === 'textAnnotationComponent') {
          Ember.run.next(this, function () {
            (0, _jquery.default)(".component[data-id='".concat(newComponent.get('id'), "'] textarea")).focus();
          }); // adding to selected components
        } else if (componentClass === 'checkGroupComponent') {
          if (this.get('overlayerData.selectedComponents').length > 0) {
            this.get('overlayerData.selectedComponents').pushObject(newComponent);
          }
        }
      },
      componentClicked: function componentClicked(component) {
        // don't select an annotation, zoom back in to edit signature
        if (component.get('type') !== 'signatureAnnotationComponent') {
          this.overlayerData.selectComponents(Ember.A([component]));
        }
      },
      toggleImportOverlay: function toggleImportOverlay(value) {
        this.set('showImportOverlay', value);
      }
    }
  });

  _exports.default = _default;
});