define("ember-rs/locales/es/templates/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateSelector = void 0;
  var reusableTemplateSelector = {
    "noTemplates": "Aún no tiene ninguna plantilla.",
    "templateDesc": "Cree plantillas reutilizables a partir de sus documentos de uso frecuente. Así podrá incorporar documentos a su sitio web, distribuirlos en masa o enviarlos rápidamente.",
    "createNew": "Crear plantilla",
    "chooseTemplate": "Elija una plantilla",
    "loading": "Cargando...",
    "loadMore": "cargar más plantillas...",
    "upgrade": "Actualice su cuenta para usar plantillas",
    "upgradeAccount": "Actualizar su cuenta",
    "bulkSendInfo": "El envío en bloque solo funciona con plantillas de un solo firmante. ¿Es la primera vez que usa el envío en bloque?",
    "learnMore": "Más información",
    "searchTemplate": "Buscar plantillas",
    "noResults": "No hay resultados"
  };
  _exports.reusableTemplateSelector = reusableTemplateSelector;
});