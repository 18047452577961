define("ember-rs/components/limited-input-text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    maxlength: 150
  });

  _exports.default = _default;
});