define("ember-rs/locales/pt-br/templates/signer/sign/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identify = void 0;
  var identify = {
    "advisory": "O remetente deste documento ativou o recurso Autenticação baseada em conhecimento do RightSignature para confirmar sua identidade. O RightSignature emprega os serviços de terceiros do BlockScore para validar e confirmar as informações pessoais fornecidas. O uso das informações pessoais fornecidas é limitado ao desempenho da validação e confirmação de identidade com os registros de dados proprietários do BlockScore. O desempenho da validação e confirmação de identidade não afetará seu relatório de crédito, pontuação de crédito ou relatórios aos credores. Nem o IRS (fisco dos Estados Unidos), o BlockScore ou o RightSignature terão a capacidade de visualizar ou acessar seu relatório de crédito ou suas informações fiscais como parte desse processo.",
    "step1": "Confirmação de identidade - Etapa 1 de 2",
    "beforeSigning": "Antes de assinar o documento, insira suas informações pessoais para que possamos confirmar sua identidade.",
    "notValid": "Não foi possível encontrar uma identidade válida que corresponda às informações fornecidas. Verifique se as informações abaixo estão corretas e clique em Avançar para tentar novamente.",
    "youHave": "Você tem ",
    "remaining": " tentativas restantes de acessar o documento.",
    "name": "Nome:",
    "dob": "Data de nascimento:",
    "ssn": "Últimos quatro números do SSN:",
    "passport": "Número do passaporte:",
    "address": "Endereço:",
    "address2": "Endereço 2:",
    "city": "Cidade:",
    "state": "Estado:",
    "zip": "Código postal:",
    "country": "País:",
    "return": "Voltar ao formulário",
    "advisory2": "Aviso sobre a verificação de identidade",
    "sampleAddress1": "120 S. West St.",
    "sampleAddress2": "Suite 100",
    "sampleAddress3": "Raleigh",
    "submit": "Enviar"
  };
  _exports.identify = identify;
});