define("ember-rs/locales/en/templates/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "addRoles": "Add Roles",
    "whatRoles": "What roles does this template need?",
    "signOrder": "Set signer order",
    "uniqueName": "You must assign unique role names.",
    "addRole": "Add role",
    "sendRole": "Add sender role",
    "next": "Next: Place fields"
  };
  _exports.addRoles = addRoles;
});