define("ember-rs/locales/ja/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "linkExpired": "ドキュメントのリンクの期限が切れました",
    "toSign": "このドキュメントに署名するには、新しいリンクを要求します。",
    "sendLink": "新しい署名者のリンクを送信する",
    "contactSender": "更新されたリンクを要求するには、ドキュメントの送信者に連絡してください。"
  };
  _exports.identityExpired = identityExpired;
});