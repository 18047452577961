define("ember-rs/locales/pt-br/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrations = void 0;
  var integrations = {
    "successEnabled": "Ativado com sucesso {{{formattedProviderName}}}",
    "successAuthorized": "Autorizado com sucesso {{{formattedProviderName}}}",
    "successDisabled": "Desativado com sucesso {{{formattedProviderName}}}",
    "error": "Erro. Não foi possível modificar {{{formattedProviderName}}}!",
    "errorAuthorize": "Erro. Não foi possível autorizar a integração de  {{{formattedProviderName}}}!",
    "errorSetup": "Ocorreu um erro ao tentar configurar a integração {{{formattedProviderName}}}. Tente novamente."
  };
  _exports.integrations = integrations;
});