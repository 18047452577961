define("ember-rs/locales/en/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding2 = void 0;
  var accountOnboarding2 = {
    "body": "Personalize and make your account look professional by <span>adding a photo.</span>",
    "mobileBody": "Your photo appears in the recipient email and document signing page so your signers can feel confident in the signing experience."
  };
  _exports.accountOnboarding2 = accountOnboarding2;
});