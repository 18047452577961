define("ember-rs/locales/pt-br/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploader = void 0;
  var documentUploader = {
    "multiFile": "O upload de vários arquivos não é possível. Somente o primeiro arquivo selecionado será carregado.",
    "alreadyUploaded": "Você já carregou este arquivo!",
    "fileSize": "O arquivo {{{fileName}}} é maior que nosso limite de 20 MB.",
    "uploadFailed": "Falha ao criar o upload"
  };
  _exports.documentUploader = documentUploader;
});