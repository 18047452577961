define("ember-rs/controllers/access/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    documentViewerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    showSidebar: false,
    detailsSidebarWidth: 420,
    showAccount: true,
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleSidebar: function toggleSidebar() {
        if (this.showSidebar) {
          this.set('documentViewerData.sidebarWidth', 0);
          this.set('showSidebar', false);
        } else {
          this.set('documentViewerData.sidebarWidth', this.detailsSidebarWidth);
          this.set('showSidebar', true);
        }
      },
      toggleDownload: function toggleDownload() {
        this.toggleProperty('showDownloads');
      }
    }
  });

  _exports.default = _default;
});