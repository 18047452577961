define("ember-rs/templates/reports/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mii1Xg2J",
    "block": "{\"symbols\":[],\"statements\":[[8,\"reports/month-export\",[],[[\"@account\",\"@generateURL\"],[[34,0],[34,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"generateURL\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/reports/user.hbs"
    }
  });

  _exports.default = _default;
});