define("ember-rs/locales/zh-cn/templates/components/salesforce-opportunities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.salesforceOpportunities = void 0;
  var salesforceOpportunities = {
    "searchOpportunities": "搜索商机...",
    "searchError": "搜索商机时出错: {{{resp}}}"
  };
  _exports.salesforceOpportunities = salesforceOpportunities;
});