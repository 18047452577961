define("ember-rs/locales/pt-br/templates/components/support-when-externally-billed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportWhenExternallyBilled = void 0;
  var supportWhenExternallyBilled = {
    "partnerSale": "Sua conta foi criada por meio de uma venda de parceiros. Entre em contato com o suporte se quiser atualizar sua conta."
  };
  _exports.supportWhenExternallyBilled = supportWhenExternallyBilled;
});