define("ember-rs/components/transaction-manager-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    classNames: ['transaction-manager-header', 'theme__transaction-header'],
    layoutSizeData: Ember.inject.service(),
    transactionSteps: Ember.A(['documents', 'prepare', 'review & send']),
    style: Ember.computed('layoutSizeData.tmHeaderHeight', function () {
      var height = this.get('layoutSizeData.tmHeaderHeight');
      return "height: ".concat(height, "px; line-height: ").concat(height, "px;").htmlSafe();
    }),
    title: Ember.computed('documentTransaction.workflow', 'reusableTemplate', function () {
      if (this.documentTransaction) {
        switch (this.get('documentTransaction.workflow')) {
          case 'signature_request':
            return this.i18n.t('components.transactionManagerHeader.sendDocument');

          case 'self_sign':
            return this.i18n.t('components.transactionManagerHeader.signDocument');

          case 'in_person':
            return this.i18n.t('components.transactionManagerHeader.inPerson');

          default:
            return this.i18n.t('components.transactionManagerHeader.broken');
        }
      } else if (this.reusableTemplate) {
        if (Ember.isPresent(this.get('reusableTemplate.id')) && this.get('reusableTemplate.version') > 1) {
          return 'Edit Reusable Template';
        } else {
          return 'Create Reusable Template';
        }
      } else {
        return 'N/A';
      }
    }),
    actions: {
      close: function close() {
        if (window.self !== window.top) {
          // iframed
          parent.postMessage({
            eventName: 'rsClose',
            data: {}
          }, '*');
        } else {
          // this.transitionToRoute('application');
          this.sendAction('close');
        }
      },
      saveTransaction: function saveTransaction() {
        this.documentTransaction.saveAndTemplates();
      }
    }
  });

  _exports.default = _default;
});