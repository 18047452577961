define("ember-rs/locales/nl/templates/mobile-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mobileNavigation = void 0;
  var mobileNavigation = {
    "documents": "Documenten",
    "templates": "Sjablonen",
    "settings": "Uw instellingen",
    "integrations": "Integraties",
    "users": "Gebruikers",
    "reports": "Rapporten",
    "help": "Help",
    "logout": "Afmelden"
  };
  _exports.mobileNavigation = mobileNavigation;
});