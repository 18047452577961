define("ember-rs/locales/nl/templates/components/document-overlayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentOverlayer = void 0;
  var documentOverlayer = {
    "prepare": "Document voorbereiden",
    "tools": "geavanceerde hulpmiddelen: ",
    "importOverlay": "Overlay importeren",
    "toolTip": "Selecteer een eerder verzonden document om die velden op dezelfde locatie bovenop dit document te plaatsen. Opmerking: het document moet dezelfde naam hebben.",
    "fieldDetection": "Velddetectie",
    "fieldDetectionTip": "Met velddetectie kunt u velden nauwkeuriger uitlijnen op lijnen en vakken in uw document.",
    "roles": "rollen",
    "rolesTip": "Zie de rollen voor dit document. Elke rol heeft zijn eigen kleur die overeenkomt met de velden die ingevuld moeten worden.",
    "signers": "ondertekenaars",
    "signersTip": "Zie de ondertekenaars voor dit document. Elke ondertekenaar heeft zijn eigen kleur die overeenkomt met de velden die door hem/haar moeten worden ingevuld.",
    "attachmentUnavailable": "Bijlagen zijn niet beschikbaar voor het geselecteerde account. Upgrade naar een Pro-abonnement om bijlagen bij ondertekenaars aan te vragen.",
    "paymentUnavailable": "Betalingen zijn niet beschikbaar voor het geselecteerde account. Upgrade naar een Pro-abonnement om betalingen te innen bij ondertekenaars.",
    "paymentNotEnabled": "Schakel een betalingsintegratie in voordat u betalingsonderdelen gebruikt. Ga hiervoor in uw accountinstellingen naar de sectie Integraties.",
    "noRoles": "Geen rollen gevonden! Hebt u opnieuw geladen zonder op te slaan?"
  };
  _exports.documentOverlayer = documentOverlayer;
});