define("ember-rs/templates/components/signer/signer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xBgK1sR1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[1,[30,[36,0],[\"components.signer.signerInfo.toSign\"],null]],[13],[2,\"\\n\\n\"],[11,\"form\"],[4,[38,1],[[32,0],\"proceed\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"field field-with-icon\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-user field-icon\"],[12],[13],[2,\"\\n    \"],[8,\"limited-input-text-field\",[],[[\"@type\",\"@value\",\"@placeholder\"],[\"text\",[34,2,[\"name\"]],[30,[36,0],[\"components.signer.signerInfo.name\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"field field-with-icon\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-envelope field-icon\"],[12],[13],[2,\"\\n    \"],[8,\"limited-input-text-field\",[],[[\"@type\",\"@value\",\"@placeholder\"],[\"email\",[34,2,[\"email\"]],[34,3]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"button\"],[15,\"disabled\",[30,[36,5],[[35,4]],null]],[14,4,\"submit\"],[12],[1,[30,[36,0],[\"components.signer.signerInfo.proceed\"],null]],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"signer\",\"emailPlaceholder\",\"isValid\",\"not\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/signer/signer-info.hbs"
    }
  });

  _exports.default = _default;
});