define("ember-rs/locales/fr/templates/unauthorized/document-declined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDeclined = void 0;
  var documentDeclined = {
    "documentDeclined": "Document Declined",
    "declineBy": "This document has been declined by one of the signer(s).",
    "canNotExecuted": "As a result, the document cannot be executed."
  };
  _exports.documentDeclined = documentDeclined;
});