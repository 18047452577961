define("ember-rs/templates/components/account-users/reset-mfa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n29tTXKi",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\"],[\"applicationModals\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"persistent-modal\",[],[[\"@class\",\"@cancel\",\"@save\",\"@cancelText\",\"@saveText\",\"@loading\"],[\"persistent-modal--is-half-width\",[30,[36,1],[\"closeModal\",[35,0]],null],[30,[36,1],[\"reset_mfa\",[35,0]],null],[30,[36,2],[\"shared.actions.cancel\"],null],[30,[36,2],[\"shared.actions.reset\"],null],[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h3\"],[14,0,\"modal-heading\"],[12],[1,[30,[36,2],[\"shared.headers.confirm-reset2fa\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,2],[\"components.account-users.reset2fa.reset-2fa-confirmation_1\"],null]],[2,\"\\n    \"],[1,[30,[36,2],[\"components.account-users.reset2fa.reset-2fa-confirmation_2\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"accountUser\",\"route-action\",\"t\",\"loading\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/account-users/reset-mfa.hbs"
    }
  });

  _exports.default = _default;
});