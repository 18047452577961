define("ember-rs/locales/it/templates/components/signature-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signatureField = void 0;
  var signatureField = {
    "rotateToSign": "Ruotare per firmare.",
    "rotateTip": "Potrebbe essere necessario attivare la rotazione dello schermo del dispositivo.",
    "clear": "Cancella",
    "signInstn": "Fare clic e trascinare per disegnare la firma",
    "typeUrSign": "Digitare la firma",
    "typeSign": "Disegnare la firma",
    "drawSign": "Disegna firma",
    "or": "o",
    "savedSign": "Utilizza firma salvata",
    "apply": "Applica"
  };
  _exports.signatureField = signatureField;
});