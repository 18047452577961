define("ember-rs/locales/en/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationSettingsService = void 0;
  var integrationSettingsService = {
    "requiresInfo": "This Integration requires some information before proceeding:",
    "authorize": "Authorize",
    "sending": "Send your {{{formattedProviderName}}} documents for e-signature from inside RightSignature",
    "archiving": "Send a pdf of completed documents to a specific folder in your {{{formattedProviderName}}} account",
    "contacts": "Make all of your {{{formattedProviderName}}} contacts available for document signing",
    "payments": "Place Payment Components on your RightSignature documents to securely collect and store credit card information and authorize charges through your {{{formattedProviderName}}} account",
    "fieldsInfo": "Please fill in all fields"
  };
  _exports.integrationSettingsService = integrationSettingsService;
});