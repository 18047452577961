define("ember-rs/locales/de/templates/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateSelector = void 0;
  var reusableTemplateSelector = {
    "noTemplates": "Sie haben noch keine Vorlagen.",
    "templateDesc": "Erstellen Sie wiederverwendbare Vorlagen aus häufig verwendeten Dokumenten zum Einbetten in Ihre Site, zum schnellen Senden oder zum Verteilen von großen Mengen.",
    "createNew": "Neue Vorlage erstellen",
    "chooseTemplate": "Vorlage auswählen",
    "loading": "Laden...",
    "loadMore": "Weitere Vorlagen laden...",
    "upgrade": "Führen Sie ein Upgrade Ihres Kontos durch, um Vorlagen zu verwenden",
    "upgradeAccount": "Konto-Ugrade",
    "bulkSendInfo": "Massensendung funktioniert nur bei einer Vorlage für einen Unterzeichner. Ist die Massensendung neu für Sie?",
    "learnMore": "Weitere Informationen",
    "searchTemplate": "Vorlagen suchen",
    "noResults": "Keine Ergebnisse!"
  };
  _exports.reusableTemplateSelector = reusableTemplateSelector;
});