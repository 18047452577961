define("ember-rs/locales/es/templates/onboarding/post-sign-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding1 = void 0;
  var postSignOnboarding1 = {
    "tapGear": "<span>Toque el icono de engranaje</span> para descargar el documento e información adicional.",
    "viewDoc": "Aquí es donde se puede ver el <span>estado</span> del documento"
  };
  _exports.postSignOnboarding1 = postSignOnboarding1;
});