define("ember-rs/locales/ja/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserForm = void 0;
  var newUserForm = {
    "email": "メール アドレス",
    "name": "名前",
    "password": "パスワード",
    "hidePassword": "パスワードの非表示",
    "create": "アカウントの作成",
    "haveAccount": "アカウントを作成済み?"
  };
  _exports.newUserForm = newUserForm;
});