define("ember-rs/locales/en/templates/components/document-upload-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploadThumbnail = void 0;
  var documentUploadThumbnail = {
    "noThumb": "No thumbnail...",
    "upload_done": "upload done",
    "downloading": "downloading",
    "storing": "storing",
    "converting_to_images": "converting to images",
    "converting_to_pdf": "converting to pdf",
    "processing": "processing",
    "created": "created"
  };
  _exports.documentUploadThumbnail = documentUploadThumbnail;
});