define("ember-rs/locales/zh-cn/templates/components/document/signer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerRow = void 0;
  var signerRow = {
    "pendingIdentityAuthentication": "待执行身份验证",
    "sign": "签名",
    "disabledReminder": "此签名者已禁用针对本文档的进一步提醒",
    "threshold": "您已超出发送提醒阈值(每个文档 3 次)",
    "signerFailed": "此签名者无法执行 ",
    "currentlyLocked": "，并且当前无法对文档进行签名。",
    "signerLocked": "此签名者无法进行基于知识的身份验证且当前无法对文档进行签名。",
    "remind": "提醒",
    "resend": "重新发送",
    "createLink": "创建链接",
    "copyLink": "复制链接",
    "copyLinkSuccess": "链接已复制到剪贴板",
    "copyLinkError": "无法将链接复制到剪贴板",
    "signerAuthFailed": "无法通过会话对签名者进行身份验证: {{{error}}}",
    "reminderSuccess": "已成功提醒 {{{name}}}",
    "reminderFailed": "无法为 {{{name}}} 生成提醒",
    "generateLinkFailed": "无法生成签名者链接: {{{error}}}",
    "resendIdentityVerification": "重新发送身份验证",
    "resendingFailed": "无法重新发送身份验证: {{{error}}}"
  };
  _exports.signerRow = signerRow;
});