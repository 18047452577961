define("ember-rs/services/wcs-workflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setValue: function setValue(isWCSWorkflow) {
      this.set('value', isWCSWorkflow);
    },
    getValue: function getValue() {
      return window.self !== window.top && this.value;
    }
  });

  _exports.default = _default;
});