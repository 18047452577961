define("ember-rs/locales/fr/templates/components/document-overlayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentOverlayer = void 0;
  var documentOverlayer = {
    "prepare": "Préparer le document",
    "tools": "outils avancés : ",
    "importOverlay": "Importer superposition",
    "toolTip": "Sélectionnez un document précédemment envoyé afin de placer ces champs au même endroit sur ce document. Remarque : le document doit avoir le même nom.",
    "fieldDetection": "Détection des champs",
    "fieldDetectionTip": "La détection de champs vous permet d'aligner plus précisément les champs avec les lignes et les cases de votre document.",
    "roles": "rôles",
    "rolesTip": "Permet d'afficher les rôles pour ce document. Chaque rôle dispose de sa propre couleur, qui correspond aux champs à compléter.",
    "signers": "signataires",
    "signersTip": "Permet d'afficher les signataires pour ce document. Chaque signataire dispose de sa propre couleur, qui correspond aux champs à compléter.",
    "attachmentUnavailable": "Aucune pièce jointe n'est disponible sur le compte sélectionné. Veuillez passer à un abonnement professionnel pour demander des pièces jointes aux signataires.",
    "paymentUnavailable": "Aucun mode de paiement n'est disponible sur le compte sélectionné. Veuillez passer à un abonnement professionnel pour encaisser les paiements des signataires.",
    "paymentNotEnabled": "Activez l'intégration de modes de paiement avant d'utiliser des composants de paiement. Pour ce faire, accédez à la section Intégrations dans vos paramètres de compte.",
    "noRoles": "Aucun rôle trouvé ! Vous avez probablement rechargé sans sauvegarder ?"
  };
  _exports.documentOverlayer = documentOverlayer;
});