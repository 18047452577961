define("ember-rs/locales/es/templates/components/transaction-manager-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transactionManagerHeader = void 0;
  var transactionManagerHeader = {
    "close": "CERRAR",
    "sendDocument": "Enviar documento",
    "signDocument": "Firmar documento",
    "inPerson": "Firma en persona",
    "broken": "No funciona"
  };
  _exports.transactionManagerHeader = transactionManagerHeader;
});