define("ember-rs/locales/nl/templates/template", ["exports", "ember-rs/locales/nl/templates/template/details", "ember-rs/locales/nl/templates/template/search-header-mobile"], function (_exports, _details, _searchHeaderMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    details: _details.details,
    searchHeaderMobile: _searchHeaderMobile.searchHeaderMobile,
    "review": {
      "header-content": "Controleren",
      "create-button": "Sjabloon maken",
      "update-button": "Sjabloon bijwerken"
    },
    "index": {
      "subheader-create-button": "Sjabloon maken",
      "template-limit-modal": {
        "cancellation-grace-period": "Dit account is geannuleerd. U kunt geen sjablonen maken.",
        "limit-reached": "Met uw huidige abonnement kunt u slechts {{templateLimit}} sjablonen maken. Als u er meer wilt maken, neemt u contact op met Support om uw account te upgraden.",
        "not-enabled": "Met uw huidige abonnement kunt u geen sjablonen maken. Als u sjablonen wilt maken, neemt u contact op met Support om uw account te upgraden."
      }
    }
  };
  _exports.template = template;
});