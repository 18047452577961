define("ember-rs/components/informational-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':informational-modal', 'show:informational-modal--is-active'],
    show: true,
    closeModal: function closeModal() {
      this.toggleProperty('show');
      this.sendAction();
    },
    doubleClick: function doubleClick() {
      this.closeModal();
    },
    click: function click(e) {
      if ((0, _jquery.default)(e.target).hasClass('informational-modal')) {
        this.closeModal();
      }
    }
  });

  _exports.default = _default;
});