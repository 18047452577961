define("ember-rs/locales/pt-br/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pendingIdentityVerification = void 0;
  var pendingIdentityVerification = {
    "pendingIdentityVerification": "Verificação de identidade pendente",
    "success": "Você assinou este documento com sucesso. Para preencher este documento e retorná-lo ao remetente, você precisará confirmar sua identidade. Para isso, basta consultar seu email e clicar no link \"verificar e-mail\" que foi enviado a você pelo RightSignature.",
    "resend": "Reenviar confirmação de Identidade"
  };
  _exports.pendingIdentityVerification = pendingIdentityVerification;
});