define("ember-rs/components/documents/document-row-signer-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: [':signer', 'isSigned:signed'],
    isSigned: Ember.computed.equal('signer.state', 'signed'),
    statusStyle: Ember.computed('isSigned', function () {
      return this.isSigned ? 'icons/status_complete' : 'icons/status_pending';
    })
  });

  _exports.default = _default;
});