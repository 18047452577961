define("ember-rs/templates/components/signature-pad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kO8Ga4At",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[14,0,\"minimal clear theme_link\"],[15,\"onclick\",[30,[36,0],[[32,0],\"clearSignature\"],null]],[12],[1,[30,[36,1],[\"components.signatureField.clear\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"signature-pad-wrapper\"],[15,5,[30,[36,5],[[35,4]],null]],[12],[2,\"\\n  \"],[10,\"canvas\"],[14,1,\"signature-pad\"],[15,\"width\",[30,[36,5],[[35,6]],null]],[15,\"height\",[30,[36,5],[[35,7]],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"input\"],[14,3,\"output\"],[14,0,\"output\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"currentSignatureJsonData\",\"if\",\"wrapperStyle\",\"safe\",\"width\",\"height\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/signature-pad.hbs"
    }
  });

  _exports.default = _default;
});