define("ember-rs/locales/nl/templates/document-transaction/review-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_desktop = void 0;
  var review_desktop = {
    "send_document": "document verzenden",
    "save_document": "Document opslaan",
    "sendDocument": "Document verzenden",
    "bulkSendConfirmation": "U staat op het punt om dit document te delen met meerdere ondertekenaars",
    "confirm": "Bevestigen",
    "cancel": "Annuleren"
  };
  _exports.review_desktop = review_desktop;
});