define("ember-rs/templates/components/user-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ImplbP1W",
    "block": "{\"symbols\":[],\"statements\":[[8,\"edit-avatar\",[],[[\"@currentUserModel\"],[[34,1]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"profile\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[1,[30,[36,0],[\"components.userFields.name\"],null]],[13],[2,\"\\n  \"],[10,\"h5\"],[12],[1,[35,1,[\"name\"]]],[13],[2,\"\\n\\n  \"],[10,\"label\"],[12],[1,[30,[36,0],[\"components.userFields.email\"],null]],[13],[2,\"\\n  \"],[10,\"h5\"],[12],[1,[35,1,[\"email\"]]],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,2,[\"isConfirmed\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[],[[\"@route\"],[\"user.settings.edit\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-lock\"],[12],[13],[2,\"\\n      \"],[1,[30,[36,0],[\"components.userFields.editInfo\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"currentUser\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/user-fields.hbs"
    }
  });

  _exports.default = _default;
});