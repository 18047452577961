define("ember-rs/locales/en/templates/components/plan-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planInfo = void 0;
  var planInfo = {
    "perMonth": "/month",
    "teamMember": "Team Member",
    "teamMembers": "Team Members",
    "call": "Call 1-855-895-4862",
    "selectedPlan": "Selected Plan",
    "selectPlan": "Select Plan"
  };
  _exports.planInfo = planInfo;
});