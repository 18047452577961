define("ember-rs/controllers/document/details", ["exports", "jquery", "moment", "ember-rs/config/environment", "ember-rs/mixins/passcode-pin-generator", "ember-rs/mixins/partial-session"], function (_exports, _jquery, _moment, _environment, _passcodePinGenerator, _partialSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var duration = _moment.default.duration;
  var stateIconsForState = {
    voided: 'fa-ban',
    pending: 'fa-circle-o',
    executed: 'fa-check-circle',
    expired: 'fa-clock-o',
    declined: 'fa-times-circle'
  };

  var _default = Ember.Controller.extend(_passcodePinGenerator.default, _partialSession.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    paymentComponentData: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    //////////////////////////  DEFAULTS  //////////////////////////
    queryParams: ['signerPaymentId', 'activePanel'],
    ////////////////////////  PROPERTIES  ////////////////////////
    document: Ember.computed.alias('model'),
    overviewPanelIsActive: Ember.computed('activePanel', function () {
      return this.activePanel === 'overview' || !this.activePanel;
    }),
    historyPanelIsActive: Ember.computed.equal('activePanel', 'history'),
    pageCount: Ember.computed.readOnly('model.upload.baseFile.pageCount'),
    hasShared: Ember.computed.notEmpty('model.sharedWith'),
    canVoid: Ember.computed('model.isPending', 'canManageDocument', 'currentUser.canManageTemplates', function () {
      return this.get('model.isPending') && (this.canManageDocument || this.get('currentUser.canManageTemplates'));
    }),
    canManageDocument: Ember.computed('model.userId', 'currentUser.id', function () {
      return Ember.isPresent(this.get('currentUser.id')) && this.get('model.userId') === this.get('currentUser.id');
    }),
    canForceComplete: Ember.computed('model.signedSignersCount', 'model.isPending', 'canManageDocument', 'currentUser.canManageTemplates', function () {
      return this.get('model.signedSignersCount') > 0 && (this.canManageDocument || this.get('currentUser.canManageTemplates')) && this.get('model.isPending');
    }),
    showMobileSidebar: false,
    showDesktopSidebar: true,
    isShowingEditSigner: false,
    showDeleteModal: false,
    showForceCompleteModal: false,
    showVoidModal: false,
    isDeleteDisabled: Ember.computed('model.documentSignedBaseFile', 'model.upload.baseFile.processingState', function () {
      // Do not allow delete if the document is pending OR if it is executed and the signed base file processing fails.
      return this.get('model.isPending') || this.get('model.isExecuted') && (this.get('model.upload.baseFile.processingState') == 'error' || !this.get('model.documentSignedBaseFile'));
    }),
    hasDownloadableCertificate: Ember.computed('model.workflow', function () {
      return this.get('model.workflow') !== 'self_sign';
    }),
    certificateImages: Ember.computed.alias('model.documentSignedBaseFile.certificateImages'),
    wasExecutedRecently: Ember.computed('model.documentExecutedAt', 'model.processingState', function () {
      var now = new Date();
      var executedAt = this.get('model.documentExecutedAt');

      if (this.get('model.hasProcessingError') === 'error') {
        return false;
      }

      return executedAt ? now - executedAt < duration(20, 'minutes') : false;
    }).readOnly(),
    initializeSignerPaymentModal: Ember.observer('signerPaymentId', function () {
      if (Ember.isPresent(this.signerPaymentId)) {
        var signerPayment = this.store.peekRecord('signerPayment', this.signerPaymentId);

        if (signerPayment) {
          this.set('paymentComponentData.selectedSignerPayment', signerPayment);
          this.set('paymentComponentData.showSignerPaymentModal', true);
          this.set('signerPaymentId', null);
        }
      }
    }),
    stateIcon: Ember.computed('model.documentState', function () {
      return (stateIconsForState[this.get('model.documentState')] || 'fa-circle-o').htmlSafe();
    }),
    signerMessage: Ember.computed('model.signers', function () {
      return this.get('model.signers').toArray()[0].message;
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      closeDocumentAccessSettings: function closeDocumentAccessSettings() {
        this.get('model.signers').filterBy('hasDirtyAttributes', true).forEach(function (signer) {
          return signer.rollbackAttributes();
        });
        this.set('showAccessSettings', false);
        this.set('isShowingEditSigner', false);
      },
      toggleSidebar: function toggleSidebar() {
        var sideBarMode = window.innerWidth <= 1024 ? 'showMobileSidebar' : 'showDesktopSidebar';
        this.toggleProperty(sideBarMode);
      },
      closeTagsEditor: function closeTagsEditor() {
        this.send('toggleEditor', 'showTagsEditor');
      },
      documents: function documents() {
        this.transitionToRoute('documents', {
          queryParams: {
            startingPage: this.get('model.dashboardPage')
          }
        });
      },
      toggleDownload: function toggleDownload() {
        // pop up the downloads modal
        this.toggleProperty('showDownloads');
      },
      showRemindMyselfModal: function showRemindMyselfModal(email) {
        this.set('remindMyselfModal', email);
      },
      toggleEditor: function toggleEditor(editor) {
        if (!(this.canManageDocument || this.get('currentUser.canManageTemplates'))) {
          this.flashMessages.warning(this.i18n.t("controllers.document.details.notAllowed"));
          return;
        } // pop up the sharing modal


        this.toggleProperty(editor);
      },
      extendExpiration: function extendExpiration(by) {
        var _this = this;

        if (by) {
          new Ember.RSVP.Promise(function (resolve, reject) {
            _jquery.default.ajax({
              url: "".concat(_environment.default.api.endpoint, "/documents/").concat(_this.get('model.id'), "/extend_expiration"),
              type: 'PUT',
              data: {
                by: by
              }
            }).done(function (response) {
              _this.flashMessages.success(_this.i18n.t("controllers.document.details.docExtended"));

              _this.model.reload();

              resolve(response);
            }).fail(function (response) {
              reject(response);
            });
          }, 'extend the expiration of the document');
        }
      },
      clearFailedPasscodeAttempts: function clearFailedPasscodeAttempts(id) {
        var _this2 = this;

        new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            url: "".concat(_environment.default.api.endpoint, "/documents/").concat(id, "/reset_passcode_attempts"),
            type: 'DELETE'
          }).then(function () {
            return _this2.model.reload();
          }).done(resolve).fail(reject);
        }, "reset password attempts for document: ".concat(id));
      },
      updateTags: function updateTags(newTags) {
        var _this3 = this;

        new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            type: 'PUT',
            url: "".concat(_environment.default.api.endpoint, "/documents/").concat(_this3.get('model.id'), "/update_tags"),
            data: {
              tags: newTags
            }
          }).then(function (response) {
            _this3.set('model.tags', newTags);

            _this3.flashMessages.success(_this3.i18n.t("controllers.document.details.tagUpdated"));

            resolve(response);
          }, function (response) {
            _this3.flashMessages.warning(_this3.i18n.t('controllers.document.details.tagUpdateFailed', {
              tags: JSON.stringify(response)
            }));

            reject(response);
          });
        }, 'update tags');
      },
      showUnlockModal: function showUnlockModal() {
        this.transitionToRoute('document.details.unlock');
      },
      updateSigner: function updateSigner(signer, success, error) {
        var id = signer.id,
            email = signer.email,
            name = signer.name;
        var documentId = signer.get('document.id');
        var url = "".concat(_environment.default.api.endpoint, "/documents/").concat(documentId, "/signers/").concat(id, "/modify_signer");

        var jqxhr = _jquery.default.ajax({
          type: 'PUT',
          url: url,
          data: {
            email: email,
            name: name
          }
        });

        jqxhr.done(success);
        jqxhr.fail(error);
      },
      addViewer: function addViewer(email) {
        this.get('model.sharedWith').push(email);
        this.send('saveViewers');
      },
      removeViewer: function removeViewer(email) {
        var updatedViewers = this.get('model.sharedWith').filter(function (viewer) {
          return viewer !== email;
        });
        this.set('model.sharedWith', updatedViewers);
        this.send('saveViewers');
      },
      saveViewers: function saveViewers() {
        var _this4 = this;

        _jquery.default.ajax({
          type: 'PUT',
          url: "".concat(_environment.default.api.endpoint, "/documents/").concat(this.get('model.id'), "/update_shared_with"),
          data: {
            shared_with: this.get('model.sharedWith')
          }
        }).done(function () {
          _this4.flashMessages.success(_this4.i18n.t('controllers.document.details.viewerListUpdated'));

          _this4.model.reload();
        }).fail(function (response) {
          _this4.flashMessages.warning(_this4.i18n.t('controllers.document.details.viewerUpdateFailed', {
            viewers: JSON.stringify(response)
          }));
        });
      },
      changeActivePanel: function changeActivePanel(panelName) {
        this.set('activePanel', panelName);
      },
      regeneratePasscodePIN: function regeneratePasscodePIN() {
        this.regeneratePasscodePIN();
      },
      togglePinModal: function togglePinModal(showNewPinModal) {
        this.set('showNewPinModal', !showNewPinModal);
      },
      toggleShowDeleteModal: function toggleShowDeleteModal() {
        this.toggleProperty('showDeleteModal');
      },
      toggleShowForceCompleteModal: function toggleShowForceCompleteModal() {
        this.toggleProperty('showForceCompleteModal');
      },
      toggleShowVoidModal: function toggleShowVoidModal() {
        this.toggleProperty('showVoidModal');
      }
    }
  });

  _exports.default = _default;
});