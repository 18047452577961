define("ember-rs/locales/ja/templates/reusable-template/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "upload": "アップロードを追加",
    "what": "署名が必要な対象",
    "uploading": "アップロード中",
    "uploadFile": "ファイルのアップロード",
    "chooseCloud": "クラウドから選択",
    "prepare": "ドキュメントを準備"
  };
  _exports.edit = edit;
});