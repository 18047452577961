define("ember-rs/routes/template/template-signer-link", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    queryParams: {
      access_token: {},
      name: {},
      email: {}
    },
    beforeModel: function beforeModel(transition) {
      var template_signer_link_access_token = transition.to.params['template_signer_link_access_token'];

      if (Ember.isBlank(template_signer_link_access_token)) {
        this.flashMessages.warning(this.i18n.t('components.templates.missing-access'));
        return undefined;
      }

      var self = this;
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.getJSON("".concat(_environment.default.api.endpoint, "/signers/").concat(template_signer_link_access_token, "/generate_from_template_signer_link"), function (response) {
          self.transitionTo('signer.sign', response.signer_id, {
            queryParams: {
              access_token: response.access_token,
              name: transition.to.queryParams.name,
              email: transition.to.queryParams.email
            }
          });
        }).fail(function (response) {
          if (response.status === 308 || response.status === 422 && /maximum/i.test(response.responseJSON[0])) {
            self.transitionTo('unauthorized.signing-link-disabled');
          } else {
            self.transitionTo('unauthorized');
            self.get('flashMessages').warning(self.get('i18n').t('components.templates.error-generating-document'));
          }

          reject(response);
        });
      });
      return promise;
    }
  });

  _exports.default = _default;
});