define("ember-rs/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      (false && !(this.currentStep) && Ember.assert("".concat(this.constructor, " must have a 'currentStep' property. It is required by the PrepareNextRoute Mixin."), this.currentStep));

      this._super.apply(this, arguments);
    },
    nextRouteText: Ember.computed.alias('nextRoute.text'),
    nextRouteTransition: Ember.computed.alias('nextRoute.transition'),
    fromReusableTemplate: Ember.computed.alias('currentTemplate.fromReusableTemplate'),
    hasMergeFields: Ember.computed.alias('currentTemplate.hasMergeFields'),
    nextRoute: Ember.computed('currentStep', 'fromReusableTemplate', 'hasMergeFields', 'nextTemplate', function () {
      var currentStep = this.currentStep;
      var fromReusableTemplate = this.fromReusableTemplate;
      var hasMergeFields = this.hasMergeFields;
      var preparingSigners = currentStep === 'prepare_signers' || currentStep === 'upload_signers';
      var hasMoreTemplates = Ember.isPresent(this.nextTemplate);

      if (preparingSigners && fromReusableTemplate && hasMergeFields) {
        return {
          text: this.i18n.t("mixins.prepareNextRoute.mergeFieldStepNext"),
          transition: 'document.prepare_document'
        };
      } else if (preparingSigners && fromReusableTemplate && !hasMergeFields && hasMoreTemplates) {
        return {
          text: this.i18n.t("mixins.prepareNextRoute.documentStepNext"),
          transition: 'document.prepare_signers'
        };
      } else if (preparingSigners && fromReusableTemplate) {
        return {
          text: this.i18n.t("mixins.prepareNextRoute.reviewStepNext"),
          transition: 'document-transaction.review'
        };
      } else if (preparingSigners) {
        return {
          text: this.i18n.t("mixins.prepareNextRoute.placeFieldNext"),
          transition: 'document.prepare_document'
        };
      } else if (hasMoreTemplates) {
        return {
          text: this.i18n.t("mixins.prepareNextRoute.documentStepNext"),
          transition: 'document.prepare_signers'
        };
      } else {
        return {
          text: this.i18n.t("mixins.prepareNextRoute.reviewStepNext"),
          transition: 'document-transaction.review'
        };
      }
    }),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var documents = model.get('documentTransaction.sortedDocuments');
      (false && !(documents.indexOf(model) >= 0) && Ember.assert("Did not find template with id: ".concat(Ember.get(model, 'id')), documents.indexOf(model) >= 0));
      Ember.set(this, 'documentTransaction', Ember.get(model, 'documentTransaction'));
      Ember.set(this, 'currentTemplateIndex', documents.indexOf(model));
      Ember.set(this, 'currentTemplate', model);
      Ember.set(this, 'nextTemplate', documents.objectAt(this.currentTemplateIndex + 1));
      controller.set('nextRouteText', this.nextRouteText);
    }
  });

  _exports.default = _default;
});