define("ember-rs/templates/components/on-boarding-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "anw2/Q2c",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[30,[36,9],[[30,[36,2],[[35,0,[\"model\"]]],null],[30,[36,1],[[35,0,[\"model\",\"hasAgreedToTerms\"]],[35,0,[\"model\",\"hasSeenRestyleOnboarding\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"new-onboarding-content \",[30,[36,4],[[35,7],\"logged-out\"],null],\" \",[30,[36,4],[[35,3,[\"useMobileLayout\"]],\"mobile-size\"],null],\" \",[30,[36,4],[[35,3,[\"isPhoneSize\"]],\"phone-size\"],null],\" \",[30,[36,4],[[35,3,[\"isTabletSize\"]],\"tablet-size\"],null],\" \",[30,[36,4],[[35,6],\"narrow-desktop-size\"],null],\" \",[30,[36,4],[[35,5],\"visible\"],null],\" \",[30,[36,4],[[30,[36,1],[[35,3,[\"useDesktopLayout\"]],[30,[36,1],[[35,0,[\"model\",\"account\",\"isMigrated\"]],[30,[36,2],[[30,[36,1],[[35,0,[\"model\",\"hasSeenMigrationOnboarding\"]],[35,0,[\"model\",\"openMigrationPopup\"]]],null]],null]],null]],null],\"visible\"],null]]]],[12],[2,\"\\n    \"],[19,[35,8],[]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"onboarding-step-shade\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"currentUser\",\"and\",\"not\",\"layoutSizeData\",\"if\",\"hasNotSeenOnboardingFlow\",\"narrowDesktopSize\",\"notAuthenticated\",\"onboardingPartial\",\"or\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/on-boarding-new.hbs"
    }
  });

  _exports.default = _default;
});