define("ember-rs/locales/de/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanDetails = void 0;
  var hybrisPlanDetails = {
    "document-sending": "Dokumente senden",
    "document-sending-info": "Hochladen und Senden von Dokumenten in gängigen Formaten wie PDF, DOC und andere.",
    "signature": "Handgeschriebene Signaturen",
    "signature-info": "Mit der Maus oder per Touchscreen signieren - wie Ihre handschriftliche Signatur.",
    "customer-support": "Täglicher Support rund um die Uhr",
    "customer-support-info": "Unterstützung von unserem ausgezeichneten technischen Supportteam, wenn Sie Hilfe benötigen.",
    "email-alert": "E-Mail-Benachrichtigungen",
    "email-alert-info": "Erinnerungen für die Unterzeichner und sofortige Statusaktualisierungen für Ihre Dokumente.",
    "integration": "Integrations-Add-Ons",
    "integration-info": "Verbinden Sie RightSignature mit über einem Dutzend beliebter Apps.",
    "type-sign": "Zum Signieren tippen",
    "type-sign-info": "Ermöglichen Sie Unterzeichnern, Dokumente per Tastatur zu signieren.",
    "mobile": "Auf Mobilgeräten signieren",
    "mobile-info": "Signieren Sie Dokumente mit Ihrem Smartphone oder Tablet, wo immer Sie sind.",
    "guided-signing": "Geführtes Signieren",
    "guided-signing-info": "Reduzieren Sie Fehler und Auslassungen durch einfache, schrittweise Anleitungen.",
    "archive": "Sicheres Dokumentenarchiv",
    "archive-info": "Bewahren Sie Ihre wichtigen Dokumente in unserem sicheren, durchsuchbaren Archiv auf.",
    "encrypt": "256-Bit-Datenverschlüsselung",
    "encrypt-info": "Sicherheitsmaßnahmen wie die einer Bank schützen Ihre Dokumente vor Informationsverlust und Diebstahl.",
    "biometric": "Biometrische Authentifizierung",
    "biometric-info": "Überprüfen Sie die Authentizität Ihres Unterzeichners anhand biometrischer Daten.",
    "fingerprint": "Digitale Fingerabdrücke",
    "fingerprint-info": "Die digitalen SHA-1-Prüfsummen verhindern, dass eine Partei Ihr Dokument ändert.",
    "audit": "Auditprotokoll und Zertifikat",
    "audit-info": "Zur Einhaltung der Compliance wird jede Aktion in einem Dokument protokolliert und gesichert.",
    "template": "Wiederverwendbare Vorlagen",
    "template-info": "Teilen Sie häufig verwendete Dokumente mit Ihrem gesamten Team.",
    "branding": "Angepasstes Branding",
    "branding-info": "Fügen Sie Ihr Logo, Ihre Farben und ein Foto von Ihnen zu den RightSignature-Dokumenten hinzu.",
    "attachments": "Anlagen anfordern",
    "attachments-info": "Ermöglichen Sie den Unterzeichnern, eine Datei als Teil der Unterzeichnung des Dokuments anzuhängen.",
    "payments": "Zahlungen anfordern",
    "payments-info": "Nehmen Sie Zahlungen an oder sammeln Sie Kreditkarteninformationen in Ihren Dokumenten.",
    "api": "Entwickler-API",
    "api-info": "Integrieren Sie RightSignature-E-Signaturen in Ihre eigenen Apps oder Websites.",
    "kba": "Wissensbasierte Authentifizierung (KBA)",
    "kba-info": "Verbessern Sie die Compliance in Branchen durch die Einhaltung relevanter Vorschriften.",
    "standard": "Standard",
    "advanced": "<span>Erweitert<span>",
    "advancedKba": "<span>Erweiterte KBA<span>",
    "sharefilePremium": "<span><p>ShareFile Premium</p> <p> mit E-Signatur</p></span>",
    "annually": "Jährlich",
    "quarterly": "vierteljährlich",
    "monthly": "monatlich",
    "month": "Monat",
    "year": "Jahr",
    "quarter": "Quartal",
    "document-sends-per-user": "100 Dokumentsendungen pro Benutzer",
    "hand-written-text-to-sign": "Handgeschriebener Text-zu-Signatur, zum Zustimmen klicken",
    "bulk-send-for-signature": "Massensendung zur Signatur",
    "sequenced-signing": "Sequenziertes Signieren",
    "knowledge-based-authentication": "Wissensbasierte Authentifizierung",
    "unlimited-document-sends": "Unbegrenzte Anzahl an Dokumenten zur Signatur senden",
    "store-sync-share-files": "Nahtloses Speichern, Synchronisieren und Freigeben von Dateien",
    "hipaa-compliant": "Konfiguration für HIPAA-Compliance",
    "advanced-caption": "Enthält u. a. folgende beliebte Features:",
    "advanced-kba-caption": "Enthält u. a. folgende beliebte Features:",
    "sharefile-caption": "ALLE E-SIGNATUR-FEATURES UND MEHR MIT CITRIX SHAREFILE:",
    "minimum-of": "Mindestens {{{noUsers}}} Benutzer",
    "users": "Benutzer",
    "user": "Benutzer",
    "user-per-month": "pro Benutzer/Monat",
    "compare-plans-in-detail": "<a href='https://citrix.sharefile.com/share/view/s1493194af0a44866ae95a6a667e7ffcd' style='color:#2071C5' target='_blank'>Abo-Details vergleichen</a>",
    "selected-plan-requires-3-users": "<span style='color:#536E7B'>Das ausgewählte Abo erfordert mindestens 3 Benutzer</span>",
    "more-than-1000-users-contact-sales": "<span style='color:#2071C5'>Wenn Sie mehr als 1000 Benutzer benötigen, wenden Sie sich bitte <a href={{driftLinkId}} style='color: #2071C5; text-decoration: underline; text-transform: lowercase'>per Chat an den Vertrieb. </a></span>",
    "trail-upgrade-msg": "Sie haben noch {{{numberOfDaysLeft}}} Tage für Ihre Testversion. Erwägen Sie jetzt ein Upgrade Ihres Plans, um eine sorgenfreie Nutzung ohne Unterbrechung zu genießen.",
    "savings": "Ersparnisse",
    "contact-sales": "Chat mit Vertrieb",
    "select-plan": "Abonnement auswählen",
    "selected-plan": "Ausgewähltes Abonnement",
    "best-for-team-collaboration": "Bestes Preis-Leistungs-Verhältnis"
  };
  _exports.hybrisPlanDetails = hybrisPlanDetails;
});