define("ember-rs/components/calculate-window-size", ["exports", "jquery", "lodash"], function (_exports, _jquery, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layoutSizeData: Ember.inject.service(),
    wcsWorkflow: Ember.inject.service(),
    setWindowSize: function setWindowSize() {
      this.set('layoutSizeData.windowHeight', (0, _jquery.default)(window).height());
      this.set('layoutSizeData.windowWidth', (0, _jquery.default)(window).width());
    },
    setScrollbarWidth: function setScrollbarWidth() {
      var scrollDiv = document.createElement("div");
      scrollDiv.className = "scrollbar-measure";
      document.body.appendChild(scrollDiv);
      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      this.set('layoutSizeData.scrollbarWidth', scrollbarWidth);
    },
    setWithinEmbed: function setWithinEmbed() {
      //set to false when iframed for WCS Workflow
      this.set('layoutSizeData.withinEmbed', top.location !== window.location && !this.wcsWorkflow.getValue());
    },
    initWindowSize: Ember.on('init', function () {
      this.setWindowSize();
      this.setScrollbarWidth();
      this.setWithinEmbed();
    }),
    initWindowResizeEvent: Ember.on('didInsertElement', function () {
      (0, _jquery.default)(window).on('resize', _lodash.default.debounce(Ember.run.bind(this, this.setWindowSize), 300));
    }),
    destroyWindowResizeEvent: Ember.on('willDestroyElement', function () {
      (0, _jquery.default)(window).off('resize');
    })
  });

  _exports.default = _default;
});