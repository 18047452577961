define("ember-rs/locales/it/templates/unauthorized/redirect-to-rs3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectToRs3 = void 0;
  var redirectToRs3 = {
    "wrongVersion": "Versione di RightSignature errata",
    "classic": "Si è tentato di accedere a RightSignature ShareFile Edition. Tuttavia, l'account consente di accedere a Classic RightSignature.",
    "go": "Vai a RightSignature"
  };
  _exports.redirectToRs3 = redirectToRs3;
});