define("ember-rs/routes/signer/sign", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: function titleToken(model) {
      return model.get('document.name');
    },
    ////////////////////////  DEPENDENCIES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    fingerprinter: Ember.inject.service(),
    cookies: Ember.inject.service(),
    kbaSession: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    signatureAuth: Ember.inject.service(),
    session: Ember.inject.service(),
    wcsWorkflow: Ember.inject.service(),
    ////////////////////////  PARAMS  ////////////////////////
    queryParams: {
      access_token: {
        refreshModel: true
      },
      identity_token: {
        refreshModel: true
      },
      passcode_answer_digest: {
        refreshModel: true
      },
      kba: {
        refreshModel: true
      },
      is_wcs_workflow: {
        refreshModel: true
      },
      name: {},
      email: {},
      fields: {}
    },
    ////////////////////////  HOOKS  ////////////////////////
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var kbaSession = this.kbaSession;

      if (transition.to.queryParams.is_wcs_workflow) {
        this.wcsWorkflow.setValue(true);
      }

      if (transition.targetName !== 'signer.sign.locked' && Ember.isBlank(transition.to.queryParams.access_token) && transition.to.queryParams.kba === "true") {
        // We don't want to block access tokens
        var identityToken = transition.to.queryParams.identity_token;
        var signerId = this.paramsFor('signer').signer_id;
        var path = transition.targetName;
        kbaSession.setup(signerId, identityToken, Ember.get(this, 'currentUser.loggedIn'), path);
        return kbaSession.validateSessionState(function (path) {
          return _this.transitionTo(path);
        });
      }
    },
    model: function model(params, transition) {
      var _this2 = this;

      if (params.fields) {
        this.set('fields', JSON.parse(params.fields));
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var signerId = _this2.paramsFor('signer').signer_id;

        _jquery.default.ajax({
          dataType: 'json',
          url: "".concat(_environment.default.api.endpoint, "/signers/").concat(signerId),
          data: transition.to.queryParams,
          global: false
        }).then(function (response) {
          _this2.store.pushPayload(response);

          var signer = _this2.store.peekRecord('signer', signerId);

          signer.setProperties({
            identityToken: transition.to.queryParams.identity_token,
            accessToken: transition.to.queryParams.access_token,
            passcodeAnswerDigest: transition.to.queryParams.passcode_answer_digest
          });
          resolve(signer);

          if (!Ember.isBlank(transition.to.queryParams.access_token)) {
            var signatureComponents = _this2.store.peekAll('signature-component');

            if (signatureComponents.length > 0 && _this2.get('session.isAuthenticated')) {
              var loggedInUserId = _this2.get('session.data.authenticated.user_id');

              !Ember.isBlank(loggedInUserId) && _this2.retreiveSignatures(loggedInUserId);
            }
          }
        }, function (error) {
          if (/passcode/i.test(error.responseText)) {
            _this2.transitionTo('signer.passcode', signerId, {
              queryParams: transition.to.queryParams
            });

            return;
          } else if (error.status === 308) {
            var responseJSON = JSON.parse(error.responseText)[0];
            var errorMessage = JSON.parse(responseJSON).message;

            if (/voided/i.test(errorMessage)) {
              _this2.transitionTo('unauthorized.document-voided');
            } else if (/declined/i.test(errorMessage)) {
              _this2.transitionTo('unauthorized.document-declined');
            } else if (/editing/i.test(errorMessage)) {
              _this2.transitionTo('unauthorized.document-editing');
            } else if (/kba/i.test(errorMessage)) {
              _this2.transitionTo('signer.sign.identify', {
                queryParams: {
                  signer_id: signerId,
                  identity_token: transition.to.queryParams.identity_token,
                  kba: true
                }
              });
            } else if (/expired/i.test(errorMessage)) {
              _this2.transitionTo('unauthorized.document-expired');
            } else if (/executed/i.test(errorMessage)) {
              _this2.transitionTo('unauthorized.document-executed', {
                queryParams: {
                  signer_id: signerId
                }
              });
            } else if (/pending identity verification/i.test(errorMessage)) {
              _this2.transitionTo('unauthorized.pending-identity-verification', {
                queryParams: {
                  signer_id: signerId
                }
              });
            } else if (/signer is not pending/i.test(errorMessage)) {
              _this2.transitionTo('unauthorized.already-signed', {
                queryParams: {
                  signer_id: signerId
                }
              });
            } else if (/not your turn/i.test(errorMessage)) {
              _this2.transitionTo('unauthorized.not-your-turn', {
                queryParams: {
                  signer_id: signerId
                }
              });
            }
          } else if (error.status == 404) {
            _this2.transitionTo('unauthorized.signing-link-disabled');
          } else {
            reject(error);
          }
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      this.fingerprinter.initFingerprint();
      var response = model.get('response') || this.store.createRecord('response', {
        signer: model
      });

      if (Ember.isPresent(transition.to.queryParams.name)) {
        model.set('name', transition.to.queryParams.name);
      }

      if (Ember.isPresent(transition.to.queryParams.email)) {
        model.set('email', transition.to.queryParams.email);
      }

      this.signatureAuth.setDocumentId(model.get('document.id'));
      this.signatureAuth.setSignerId(model.id);

      if (Ember.isPresent(transition.to.queryParams.identity_token)) {
        this.signatureAuth.setIdentityToken(transition.to.queryParams.identity_token);
      } else if (Ember.isPresent(transition.to.queryParams.access_token)) {
        this.signatureAuth.setAccessToken(transition.to.queryParams.access_token);
      } // Set response manually on components
      // this is intended to be virtual to the signing page and not persisted
      // as its just used to reference componentValues via the response on each component


      if (Ember.get(model, 'document.components')) {
        var fields = this.fields;
        var value;
        model.get('document.components').forEach(function (component) {
          if (component.get('assignedTo') === model.get('roleName')) {
            component.set('response', response);
          }

          if (fields) {
            value = fields[component.get('name')];

            if (value) {
              component.setValueFromText(value);
            }
          }
        });
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('document', model.get('document'));
      model.addAudit("Started session for signer ".concat(model.get('id'), "."));
      controller.set('showTemplateSignerInfoSplash', model.get('document.workflowType') === 'templateSignerLink'); // Used in next-required-field to prevent hijacking tab key

      this.set("documentViewerData.pageHeight", model.get("document.upload.baseFile.imageHeight"));
      this.set("documentViewerData.pageWidth", model.get("document.upload.baseFile.imageWidth"));
      var self = this;
      (0, _jquery.default)(window).on('beforeunload', function (e) {
        //set any string for 'returnValue'
        if (controller.hasUnsavedChanges) {
          return e.originalEvent.returnValue = self.get('i18n').t('components.signers.unsaved-changes');
        }
      });

      this._super(controller, model);
    },
    retreiveSignatures: function retreiveSignatures() {
      var _this3 = this;

      _jquery.default.ajax({
        url: "".concat(_environment.default.api.endpoint, "/signatures"),
        type: 'GET'
      }).then(function (response) {
        _this3.store.pushPayload(response);
      }).catch();
    },
    actions: {
      error: function error(_error, transition) {
        var trans = transition || {};
        var kbaSession = this.kbaSession;

        if (_error.status === 422 && trans.targetName === 'signer.sign.index' && Ember.isPresent(trans.to.queryParams.identity_token)) {
          return this.transitionTo('unauthorized.identity-expired', {
            queryParams: {
              signer_id: trans.to.params['signer'].signer_id,
              identity_token: trans.to.queryParams.identity_token
            }
          });
        } else if (_error.status === 403 && trans.targetName !== 'signer.sign.locked') {
          // Session locked
          return this.transitionTo('signer.sign.locked');
        } else if (_error.status === 406) {
          // The cookie has expired
          document.cookie = "".concat(kbaSession.key(), "=; domain=").concat(location.hostname, "; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT");
          this.flashMessages.warning(this.i18n.t('components.signers.session-expired'));
          this.refresh();
        } else if (_error.status === 401) {
          return this.transitionTo('unauthorized.identity-expired');
        }
      }
    }
  });

  _exports.default = _default;
});