define("ember-rs/locales/ja/templates/onboarding/signer-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding2 = void 0;
  var signerOnboarding2 = {
    "submit": "ドキュメントの進行状況を参照してください。すべてのフィールドの入力後、<span>[送信]</span> が表示されます。"
  };
  _exports.signerOnboarding2 = signerOnboarding2;
});