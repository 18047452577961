define("ember-rs/components/document-token-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    classNames: ['select-wrapper', 'document-token-expiration-select'],
    options: Ember.computed('currentUser.account.tokenExpirationOptions', function () {
      return this.get('currentUser.account.tokenExpirationOptions').map(function (value) {
        return {
          'label': value.split(' ').join('_'),
          'value': value
        };
      });
    }),
    attr: 'documentTokenExpiration',
    currentSelection: Ember.computed.oneWay('selection'),
    actions: {
      updateSetting: function updateSetting(value) {
        this.sendAction('updateSetting', value, this);
      }
    }
  });

  _exports.default = _default;
});