define("ember-rs/templates/components/paywall-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HXcMY+f3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[19,\"components/paywall-action/toggles\",[]],[2,\"\\n\"],[18,1,[[35,5],[35,4],[35,3],[35,0],[30,[36,2],[[32,0],\"buttonPressed\"],null],[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null],false],null]]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"showModal\",\"mut\",\"action\",\"free\",\"entitled\",\"accessible\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/paywall-button.hbs"
    }
  });

  _exports.default = _default;
});