define("ember-rs/locales/de/controllers/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "upgradeMsg": "Ihr Konto hat keinen Zugriff auf dieses Feature. Führen Sie ein Upgrade auf ein Pro-Abonnement durch, um eingebettete Vorlagen zu verwenden.",
    "templateDeleteMsg": "Die Vorlage wurde gelöscht.",
    "tagUpdateMsg": "Die Tags wurden aktualisiert",
    "tagUpdateFailedMsg": "Fehler beim Aktualisieren der Tags: {{{formattedResponse}}}",
    "templateDeleteConfirm": "Möchten Sie diese Vorlage wirklich löschen?",
    "loadSignerLinksFailedMsg": "Fehler beim Laden der Links für Vorlagenunterzeichner"
  };
  _exports.details = details;
});