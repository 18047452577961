define("ember-rs/locales/pt-br/templates/upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgrade = void 0;
  var upgrade = {
    "noUpgrade": "Não é possível atualizar a conta",
    "noPermission": "Parece que você não tem permissão para atualizar sua conta. Fale com o administrador da conta sobre a atualização para um novo plano do RightSignature.",
    "upgrade": "Atualizar sua conta",
    "upgradeSubtitle": "Durante a sua avaliação você usou o plano Business. Você pode permanecer nesse plano ou escolher outro plano mais conveniente para você.",
    "choosePlan": "1. Escolha um plano",
    "allInOne": "A solução de assinatura eletrônica completa",
    "standard": "Características padrão ",
    "usersNeeded": "2. De quantos usuários você precisa?",
    "checkout": "Pagamento",
    "showLess": "Mostrar menos",
    "showMore": "Mostrar mais",
    "users": "Usuários",
    "confirm": "Pressione enter para confirmar",
    "billing": "Faturamento",
    "billingDetails": "Detalhes de faturamento",
    "noOfUser": "Número de usuários",
    "billAmount": "Quantia faturável",
    "creditAmount": "Montante de crédito do antigo RightSignature",
    "total": "Total",
    "taxCharges": "encargos fiscais adicionais podem ser aplicáveis",
    "oops": "Algo saiu errado",
    "dataUnavailable": "Os dados não estão disponíveis neste momento. Volte para esta página mais tarde."
  };
  _exports.upgrade = upgrade;
});