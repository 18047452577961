define("ember-rs/locales/zh-cn/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploader = void 0;
  var documentUploader = {
    "multiFile": "不支持多文件上载。将仅上载选中的第一个文件。",
    "alreadyUploaded": "您已上载此文件!",
    "fileSize": "文件 {{{fileName}}} 大于我们的 20 MB 限制。",
    "uploadFailed": "无法创建上载"
  };
  _exports.documentUploader = documentUploader;
});