define("ember-rs/locales/ja/templates/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlay = void 0;
  var importOverlay = {
    "importFrom": "フィールドのインポート元:",
    "toImport": "コンポーネントのインポート元",
    "page": "ページ",
    "cancel": "キャンセル",
    "import": "インポート",
    "noComponents": "インポートするコンポーネントはありません。",
    "pastDocs": "過去のドキュメントからフィールドをインポート",
    "noMatch": "この名前と一致するドキュメントが見つかりませんでした",
    "importSuccess": "{{{components}}} 個のコンポーネントがインポートされました。"
  };
  _exports.importOverlay = importOverlay;
});