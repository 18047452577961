define("ember-rs/templates/reusable-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xow+OtdO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"theme\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"transaction_manager_header\"],[12],[13],[2,\"\\n  \"],[8,\"transaction-manager-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/reusable-template.hbs"
    }
  });

  _exports.default = _default;
});