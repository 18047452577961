define("ember-rs/locales/ja/templates/components/document-download-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDownloadOptions = void 0;
  var documentDownloadOptions = {
    "originalPDF": "元の PDF",
    "completedPDF": "完了した PDF",
    "overlay": "印刷可能なオーバーレイ PDF",
    "failed": "完了した PDF (失敗)",
    "support": "ドキュメントの処理中に問題が発生しました。サポートに問い合わせる必要があります。",
    "cert": "署名付き証明書",
    "mergedPDF": "統合 PDF"
  };
  _exports.documentDownloadOptions = documentDownloadOptions;
});