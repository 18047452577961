define("ember-rs/locales/de/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserForm = void 0;
  var newUserForm = {
    "email": "E-Mail",
    "name": "Name",
    "password": "Kennwort",
    "hidePassword": "Kennwort ausblenden",
    "create": "Konto erstellen",
    "haveAccount": "Haben Sie ein Konto?"
  };
  _exports.newUserForm = newUserForm;
});