define("ember-rs/locales/pt-br/templates/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentAccessSettings = void 0;
  var documentAccessSettings = {
    "addViewer": "Adicionar observador",
    "email": "Email",
    "name": "Name",
    "add": "Adicionar",
    "cancel": "Cancelar",
    "editSigner": "Editar signatário",
    "save": "Salvar",
    "manageAccess": "Gerenciar acesso",
    "accessTip": "Essas pessoas têm acesso a este documento e podem baixá-lo ou visualizá-lo a qualquer momento.",
    "signers": "Signatários",
    "edit": "editar",
    "viewers": "Observadores",
    "remove": "remover",
    "updateEmail": "Endereço de email do signatário atualizado com sucesso.",
    "updateSigner": "Successfully updated signer details."
  };
  _exports.documentAccessSettings = documentAccessSettings;
});