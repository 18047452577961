define("ember-rs/locales/es/templates/document-transaction/review-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_desktop = void 0;
  var review_desktop = {
    "send_document": "enviar documento",
    "save_document": "Guardar documento",
    "sendDocument": "Enviar documento",
    "bulkSendConfirmation": "Está a punto de compartir este documento con varios firmantes",
    "confirm": "Confirmar",
    "cancel": "Cancelar"
  };
  _exports.review_desktop = review_desktop;
});