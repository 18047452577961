define("ember-rs/models/document", ["exports", "@ember-data/model", "ember-api-actions", "ember-rs/mixins/account-settings-attributes", "ember-rs/models/document-template"], function (_exports, _model, _emberApiActions, _accountSettingsAttributes, _documentTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // flatten Accounts Settings that are shared with other models, like branding
  var _default = _documentTemplate.default.extend(_accountSettingsAttributes.default, {
    ////////////////////////  ATTRIBUTES  ////////////////////////
    documentState: (0, _model.attr)('string'),
    documentSentAt: (0, _model.attr)('moment-date'),
    documentExpiresAt: (0, _model.attr)('moment-date'),
    documentExecutedAt: (0, _model.attr)('moment-date'),
    provider: (0, _model.attr)('string'),
    audits: (0, _model.attr)('array'),
    //hasMany('audit'),
    documentCertificateUrl: (0, _model.attr)('string'),
    dashboardPage: (0, _model.attr)('number'),
    senderEmail: (0, _model.attr)('string'),
    senderAvatarUrl: (0, _model.attr)('string'),
    senderAllowDrawnSignature: (0, _model.attr)('boolean'),
    senderAllowTypedSignature: (0, _model.attr)('boolean'),
    senderAllowWebcamSignature: (0, _model.attr)('boolean'),
    expirationExtendable: (0, _model.attr)('boolean'),
    signedPdfUrl: (0, _model.attr)('string'),
    nextSignerId: (0, _model.attr)('string'),
    signerFailedPasscodeAttempts: (0, _model.attr)('boolean'),
    mergedDocumentCertificateUrl: (0, _model.attr)('string'),
    declineReason: (0, _model.attr)('string'),
    previewUrl: (0, _model.attr)('string'),
    ////////////////////////  RELATIONSHIPS  ////////////////////////
    signers: (0, _model.hasMany)('signer', {
      inverse: 'document',
      async: false
    }),
    componentValues: (0, _model.hasMany)('component-value', {
      inverse: 'document',
      async: false
    }),
    integration: (0, _model.belongsTo)('integration', {
      async: false
    }),
    documentSignedBaseFile: (0, _model.belongsTo)('document-signed-base-file', {
      async: false
    }),
    printableOverlayBaseFile: (0, _model.belongsTo)('printable-overlay-base-file', {
      async: false
    }),
    attachmentComponents: Ember.computed.filterBy('components', 'type', 'attachmentComponent'),
    ////////////////////////  MEMBER ACTIONS  ////////////////////////
    void: (0, _emberApiActions.memberAction)({
      type: 'POST',
      path: 'void'
    }),
    forceComplete: (0, _emberApiActions.memberAction)({
      type: 'POST',
      path: 'force_complete'
    }),
    ////////////////////////  PROPERTIES  ////////////////////////
    isDraft: Ember.computed.equal('documentState', 'draft'),
    isPending: Ember.computed.equal('documentState', 'pending'),
    isVoided: Ember.computed.equal('documentState', 'voided'),
    isExpired: Ember.computed.equal('documentState', 'expired'),
    isDeclined: Ember.computed.equal('documentState', 'declined'),
    isExecuted: Ember.computed.equal('documentState', 'executed'),
    uploadCompletedAt: Ember.computed.alias('upload.uploadCompletedAt'),
    uploadReady: Ember.computed.alias('upload.uploadReady'),
    companyName: Ember.computed.readOnly('account.companyName'),
    currentUserSigners: Ember.computed.filterBy('signers', 'isCurrentUser'),
    currentUserIsSigner: Ember.computed.gt('currentUserSigners.length', 0),
    signedSignersCount: Ember.computed('signers.@each.status', function () {
      return this.signers.filterBy('status', 'signed').get('length');
    }),
    // Just the basics for frontend validation, full validation happens server side
    isReviewStepValid: Ember.computed('name', 'expiresIn', function () {
      if (!Ember.isPresent(this.name)) {
        return false;
      }

      var expiresIn = this.expiresIn;

      if (!Ember.isPresent(expiresIn) || expiresIn <= 0 || expiresIn > 365) {
        return false;
      }

      return true;
    }),
    senderRole: Ember.computed('roles.@each.isSender', function () {
      return this.roles.findBy('isSender', true);
    }),
    senderInitials: Ember.computed('senderName', function () {
      var names = this.senderName.split(' ');
      return [names[0].charAt(0), names.pop().charAt(0)].join('').toUpperCase();
    })
  });

  _exports.default = _default;
});