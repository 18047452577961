define("ember-rs/locales/en/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTagsEditor = void 0;
  var documentTagsEditor = {
    "addTags": "Add tags to this document to find it easily later. You can also add values to tags e.g. 'client_id: 1234'",
    "tagName": "Tag name...",
    "optional": "Value (optional)"
  };
  _exports.documentTagsEditor = documentTagsEditor;
});