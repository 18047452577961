define("ember-rs/locales/nl/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rs3LoginRedirect = void 0;
  var rs3LoginRedirect = {
    "looking": "Bent u op zoek naar RightSignature&trade; - ShareFile Edition?",
    "sharefileEdition": "U bent op deze pagina gekomen, omdat u geprobeerd hebt aan te melden bij een oudere versie van RightSignature&trade;. Volgens onze records hebt u een RightSignature&trade; - ShareFile Edition-account. Meld u hieronder aan.",
    "support": "Hebt u een vraag? <a href='https://support.citrix.com/cscase'>Neem contact op met Support</a> of bel naar +1-800-921-4250"
  };
  _exports.rs3LoginRedirect = rs3LoginRedirect;
});