define("ember-rs/models/document-template", ["exports", "jquery", "@ember-data/model", "ember-rs/mixins/workflow-type", "ember-rs/mixins/taggable", "ember-rs/mixins/embedded-records-has-many"], function (_exports, _jquery, _model, _workflowType, _taggable, _embeddedRecordsHasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // workflowType, isSelfSign .. are included from WorkflowType
  // hasTags is included from Taggable
  var _default = _model.default.extend(_embeddedRecordsHasMany.default, _workflowType.default, _taggable.default, {
    ////////////////////////  ATTRIBUTES  ////////////////////////
    templateGuid: (0, _model.attr)('string'),
    version: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    type: (0, _model.attr)('type'),
    createdAt: (0, _model.attr)('moment-date'),
    updatedAt: (0, _model.attr)('moment-date'),
    currentReusableTemplate: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    published: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    name: (0, _model.attr)('string'),
    senderName: (0, _model.attr)('string'),
    filename: (0, _model.attr)('string'),
    expiresIn: (0, _model.attr)('number'),
    signerSequencing: (0, _model.attr)('boolean'),
    personalizedMessages: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    sharedWith: (0, _model.attr)('array'),
    distributionMethod: (0, _model.attr)('string', {
      defaultValue: 'email'
    }),
    identityMethod: (0, _model.attr)('string', {
      defaultValue: 'email'
    }),
    passcode: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    passcodeQuestion: (0, _model.attr)('string'),
    passcodeAnswer: (0, _model.attr)('string'),
    workflow: (0, _model.attr)('string'),
    kba: (0, _model.attr)('boolean', {
      defaultValue: false,
      readOnly: true
    }),
    kbaLocked: (0, _model.attr)('boolean', {
      defaultValue: false,
      readOnly: true
    }),
    signatureColor: (0, _model.attr)('string'),
    visibility: (0, _model.attr)('string'),
    // just used for document but loaded for back in serializer
    fromReusableTemplate: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    tags: (0, _model.attr)('object'),
    dashboardPage: (0, _model.attr)('number'),
    integrationMetadata: (0, _model.attr)('object'),
    integrationProvider: (0, _model.attr)('string'),
    archivedToOrigin: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    passcodePinEnabled: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    voidReason: (0, _model.attr)('string'),
    forceEdit: (0, _model.attr)('boolean', {
      defaultValue: false,
      serialize: false
    }),
    ////////////////////////  RELATIONSHIPS  ////////////////////////
    documentTransaction: (0, _model.belongsTo)('document-transaction', {
      async: false
    }),
    // Ember-data polymorphic association is broken, so we need to track upload per type of DocumentTemplate
    // upload: belongsTo('upload', {inverse: 'documentTemplates' }),
    upload: (0, _model.belongsTo)('upload', {
      async: false
    }),
    roles: (0, _model.hasMany)('role', {
      async: false
    }),
    components: (0, _model.hasMany)('component', {
      polymorphic: true,
      inverse: 'documentTemplate',
      async: false
    }),
    account: (0, _model.belongsTo)('account', {
      async: true
    }),
    mergefieldValues: (0, _model.hasMany)('mergefield-value', {
      inverse: 'document',
      async: false
    }),
    userId: (0, _model.attr)('string'),
    ////////////////////////  PROPERTIES  ////////////////////////
    hasMultipleSigners: Ember.computed.gt('roles.length', 1),
    isReusable: Ember.computed.equal('type', 'reusableTemplate'),
    uploadReady: Ember.computed.alias('upload.uploadReady'),
    hasMergeFields: Ember.computed('components.@each.isMergeField', function () {
      return this.components.any(function (component) {
        return Ember.get(component, 'isMergeField');
      });
    }),
    hasRequiredMergeFields: Ember.computed('components.@each.{isMergeField,isRequired}', function () {
      return this.components.any(function (component) {
        return Ember.get(component, 'isMergeField') && Ember.get(component, 'isRequired');
      });
    }),
    validPasscode: Ember.computed('passcode', 'passcodeQuestion', 'passcodeAnswer', function () {
      if (this.passcode === false) {
        return true;
      }

      return Ember.isPresent(this.passcodeQuestion) && Ember.isPresent(this.passcodeAnswer);
    }),
    hasPaymentComponents: Ember.computed('components.@each.type', function () {
      return this.components.any(function (component) {
        return Ember.get(component, 'type') === 'paymentComponent';
      });
    }),
    ////////////////////////  METHODS  ////////////////////////
    newRole: function newRole(roleOptions) {
      var highestSignerId = 0;
      var temp = this; // needed to store the context for translation

      this.roles.forEach(function (r) {
        var id = parseInt(r.get('name').replace(temp.get('i18n').t('models.documentTemplate.signer').string, ''));

        if (id > highestSignerId) {
          highestSignerId = id;
        }
      });
      return this.store.createRecord('role', _jquery.default.extend({
        name: this.i18n.t('models.documentTemplate.signer').string + "".concat(highestSignerId + 1),
        sequence: this.get('roles.length'),
        documentTemplate: this
      }, roleOptions));
    },
    setMessages: function setMessages(messageToAll) {
      if (!this.personalizedMessages) {
        this.roles.forEach(function (r) {
          r.set('message', messageToAll);
        });
      }
    }
  });

  _exports.default = _default;
});