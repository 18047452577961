define("ember-rs/locales/pt-br/templates/components/signature-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signatureField = void 0;
  var signatureField = {
    "rotateToSign": "Girar para assinar.",
    "rotateTip": "Talvez seja necessário desbloquear a rotação de tela do dispositivo.",
    "clear": "Limpar",
    "signInstn": "Clique e arraste para traçar sua assinatura",
    "typeUrSign": "Digitar sua assinatura",
    "typeSign": "Digitar assinatura",
    "drawSign": "Traçar assinatura",
    "or": "ou",
    "savedSign": "Usar assinatura salva",
    "apply": "Aplicar"
  };
  _exports.signatureField = signatureField;
});