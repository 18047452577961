define("ember-rs/components/fillout-mergefields-page", ["exports", "ember-rs/mixins/document-page", "ember-rs/mixins/signing-component-interactions", "ember-rs/mixins/component-fillin"], function (_exports, _documentPage, _signingComponentInteractions, _componentFillin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_documentPage.default, _signingComponentInteractions.default, _componentFillin.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    documentViewerData: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    annotations: Ember.computed.filterBy('componentsForPage', 'isAnnotation', true),
    mergefields: Ember.computed.filterBy('componentsForPage', 'isMergeField', true),
    componentsToFill: Ember.computed.readOnly('mergefields'),
    components: Ember.computed.readOnly('mergefields'),
    componentsForPage: Ember.computed.filter('doc.components', function (component) {
      return component.get('page') === this.get('pageImage.page_number');
    })
  });

  _exports.default = _default;
});