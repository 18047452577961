define("ember-rs/locales/ja/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    "choose": "3. エクスポートするデータを選択します",
    "columnNames": "列名はドキュメントのコンポーネント名に基づきます:",
    "export": "4. CSV にエクスポート",
    "selectColumns": "列を選択",
    "view": "CSV ファイルを Excel で開いて、送信済みドキュメントのデータを表示できます。"
  };
  _exports.template = template;
});