define("ember-rs/locales/pt-br/templates/components/component-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentEditor = void 0;
  var componentEditor = {
    "charge": "Cobrar depois de executado?",
    "selectOne": "Selecione um...",
    "amount": "Valor do pagamento (USD):",
    "enterAmount": "Insira o valor do pagamento...",
    "required": "OBRIGATÓRIO: Digite o texto de ajuda de signatário abaixo para que o destinatário saiba o que carregar.",
    "signerHelp": "Texto de ajuda do signatário:",
    "helpText": "Digite o texto de ajuda ...",
    "selectBox": "A caixa de seleção precisa de pelo menos duas opções.",
    "selectOptions": "Selecionar opções:",
    "addOption": "Adicionar opção...",
    "addToGroup": "Adicionar ao grupo",
    "assignedTo": "Atribuído a:",
    "fieldRequired": "Campo obrigatório?",
    "autoFillDate": "Auto-fill date signed",
    "autoFillDateHelpText": "A recipient will not be able to edit the field. It will be filled in automatically with the date on which the recipient signs.",
    "mergeField": "Campo de mesclagem?",
    "mergeFieldName": "campo de mesclagem precisa de um nome",
    "nameMergeField": "Nome do campo de mesclagem:",
    "setFontSize": "Definir tamanho da fonte...",
    "muiltiline": "Multilinhas?",
    "clearBackground": "Fundo transparente?",
    "addDefaultValue": "Adicionar valor padrão?",
    "setDefaultValue": "Definir valor padrão...",
    "defaultValue": "Valor padrão:",
    "nameThisField": "Nomear este campo?",
    "name": "Nome:",
    "setName": "Definir o nome do componente...",
    "textAlign": "Alinhar o texto",
    "showLess": "Mostrar menos",
    "showMore": "Mostrar mais",
    "delete": "Excluir",
    "saved": "Salvo!",
    "save": "Salvar",
    "dateFormat": "Formato de data",
    "left": "esquerda",
    "center": "centro",
    "right": "direita",
    "groupRequirement": "Requisito de grupo",
    "only_one": "Apenas um",
    "one_or_more": "Um ou mais",
    "options_warning": "Você já adicionou essa opção"
  };
  _exports.componentEditor = componentEditor;
});