define("ember-rs/routes/logged-out", ["exports", "ember-rs/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === 'logged-out.index' || transition.targetName === 'logged-out') {
        window.location = _environment.default.marketingHost;
        return Ember.RSVP.reject('landed on the logged out route', 'redirect to marketing');
      }
    }
  });

  _exports.default = _default;
});