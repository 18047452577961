define("ember-rs/locales/de/templates/document-transaction/upload-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload_signers = void 0;
  var upload_signers = {
    "instructions_header": "Führen Sie die folgenden Schritte aus, um die CSV-Datei hochzuladen",
    "step_1": "SCHRITT 1",
    "step_2": "SCHRITT 2",
    "step_3": "SCHRITT 3",
    "step_1_text": "Beispielliste für Massensendungsunterzeichner herunterladen",
    "step_2_text": "Füllen Sie die Felder für Name und E-Mail-Adresse aus (erforderlich).",
    "step_3_text": "Laden Sie die fertige CSV-Datei hoch.",
    "instructions_note": "Achtung: Die Anzahl der Unterzeichner darf 300 nicht überschreiten",
    "upload_failed": "Fehler beim Upload",
    "upload_failure_message": "Das Hochladen ist wegen einer beschädigten Datei oder eines Verarbeitungsfehlers fehlgeschlagen. Stellen Sie sicher, dass die Datei gültigen Inhalt hat und versuchen Sie erneut, sie hochzuladen.",
    "view_all": "Alle anzeigen",
    "ok": "OK",
    "validate_and_reupload": "Ihre Datei enthält weitere Fehler. Überprüfen Sie die Validierungen und versuchen Sie erneut, die Datei hochzuladen."
  };
  _exports.upload_signers = upload_signers;
});