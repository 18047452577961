define("ember-rs/locales/es/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFree = void 0;
  var newUserOnboardingFree = {
    "title": "Bienvenido a RightSignature",
    "body": "Actualmente, solo tiene acceso básico a RightSignature™. Esto le permite autofirmar documentos y ver sus documentos anteriores. Para acceder a las funciones PRO, contacte con un administrador de su cuenta de RightSignature™."
  };
  _exports.newUserOnboardingFree = newUserOnboardingFree;
});