define("ember-rs/templates/components/available-integration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KHzbZ2xs",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"integration-settings-service\",[],[[\"@availableService\",\"@integration\",\"@toggleService\",\"@formattedProviderName\",\"@provider\"],[\"sending\",[34,0],\"toggleService\",[34,1],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n    \"],[11,\"div\"],[24,0,\"integration-popup-row\"],[4,[38,3],[[32,0],\"checkIntegrationSettings\"],null],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"integration-logo\"],[12],[2,\"\\n        \"],[19,[30,[36,4],[[35,2]],null],[]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"title\"],[12],[1,[30,[36,5],[[35,2]],null]],[13],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-caret-right\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"integration\",\"formattedProviderName\",\"provider\",\"action\",\"integration-icon\",\"format-provider-name\",\"isDocumentIntegration\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/available-integration.hbs"
    }
  });

  _exports.default = _default;
});