define("ember-rs/locales/en/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noUser = void 0;
  var noUser = {
    "unableLogin": "Unable to Login",
    "unableLoginTip": "You are not able to log in to this RightSignature account.<br> Contact an account administrator to get access."
  };
  _exports.noUser = noUser;
});