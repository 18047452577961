define("ember-rs/components/logo-uploader", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['uploader-container'],
    flashMessages: Ember.inject.service(),
    url: Ember.computed('account', function () {
      return _environment.default.api.endpoint.concat("/account/logo");
    }),
    fileUploaderEle: function fileUploaderEle() {
      return this.$('.file-uploader');
    },
    initializeUploader: Ember.on('didInsertElement', function () {
      var self = this;
      var flash = this.flashMessages;
      var fileupload_options = {
        autoUpload: true,
        sequentialUploads: true
      };
      this.fileUploaderEle().fileupload(_jquery.default.extend(fileupload_options, {
        dataType: 'json',
        method: 'POST',
        url: self.get('url'),
        paramName: 'logo',
        submit: function submit(e, data) {
          self.setProperties({
            isUploadingLogo: true
          });
          data.jqXHR = (0, _jquery.default)(this).fileupload('send', data);
          return false;
        },
        done: function done(e, data) {
          self.setProperties({
            isUploadingLogo: false,
            brandingLogoUrl: data.result.account.logo_url,
            brandingLogoThumbUrl: data.result.account.logo_thumb_url
          });
          flash.success('Uploaded Logo.');
        },
        fail: function fail(e) {
          self.setProperties({
            isUploadingLogo: false
          });
          flash.warning(e.errors.error);
        }
      }));
    }),
    cleanup: Ember.on('willDestroyElement', function () {
      this.fileUploaderEle().fileupload('destroy');
    })
  });

  _exports.default = _default;
});