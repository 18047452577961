define("ember-rs/templates/components/on-boarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KlXjCf5U",
    "block": "{\"symbols\":[\"step\",\"&default\"],\"statements\":[[10,\"div\"],[14,1,\"steps_window\"],[15,5,[30,[36,9],[[35,8]],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"steps_container\"],[15,5,[30,[36,9],[[35,10]],null]],[12],[2,\"\\n\"],[6,[37,11],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[35,7],[35,6],[35,5]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[19,[35,4],[]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"footer\"],[12],[2,\"\\n\"],[6,[37,11],[[35,12]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ul\"],[14,0,\"step-dots\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"step-dot\",[],[[\"@step\",\"@currentStep\",\"@goToStep\"],[[32,1],[34,0],\"goToStep\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[11,\"button\"],[4,[38,13],[[32,0],\"nextStep\"],null],[12],[1,[34,14]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"currentStep\",\"stepsArray\",\"-track-array\",\"each\",\"flowPartial\",\"stepStyle\",\"admin\",\"showFreeSteps\",\"stepWindowStyle\",\"safe\",\"stepContainerStyle\",\"if\",\"multipleSteps\",\"action\",\"nextButtonText\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/on-boarding.hbs"
    }
  });

  _exports.default = _default;
});