define("ember-rs/locales/es/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    "choose": "3. Elija los datos para exportar",
    "columnNames": "Los nombres de las columnas se basan en los nombres de los componentes en el documento:",
    "export": "4. Exportar a CSV",
    "selectColumns": "Seleccionar columnas",
    "view": "Se puede abrir un archivo CSV en Excel para ver los datos sobre documentos enviados."
  };
  _exports.template = template;
});