define("ember-rs/locales/it/templates/components/unconfirmed-user-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedUserButton = void 0;
  var unconfirmedUserButton = {
    "emailUnverified": "L'indirizzo email non è verificato",
    "verifySendDocument": "Prima di poter inviare il primo documento, dobbiamo verificare l'indirizzo e-mail.",
    "verifyCreateTemplate": "Prima di poter creare il primo modello, dobbiamo verificare l'indirizzo e-mail.",
    "verifyUpgrade": "Prima di poter aggiornare l'account hybris, dobbiamo verificare l'indirizzo e-mail.",
    "verifyIntegration": "Prima di poter abilitare un'integrazione, dobbiamo verificare il tuo indirizzo e-mail.",
    "verifyEmail": "Abbiamo inviato un'e-mail di verifica all'indirizzo qui sotto.",
    "checkEmail": "E-mail non ricevuta? Controllare la cartella spam o",
    "resend": "Invia di nuovo",
    "verificationMail": "L'e-mail di verifica è stata inviata. Si prega di controllare {{{email}}} e seguire le istruzioni."
  };
  _exports.unconfirmedUserButton = unconfirmedUserButton;
});