define("ember-rs/templates/unauthorized/hybris-trial-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qNu1ab9A",
    "block": "{\"symbols\":[],\"statements\":[[8,\"hybris-trial-modal\",[],[[\"@hybrisTrialExpired\"],[true]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/hybris-trial-expired.hbs"
    }
  });

  _exports.default = _default;
});