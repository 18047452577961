define("ember-rs/locales/zh-cn/mixins/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "successUpgrade": "您已成功升级帐户。欢迎使用 RightSignature!",
    "dataUnavailable": "当前没有可用数据。请稍后返回此页面。"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});