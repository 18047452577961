define("ember-rs/locales/en/templates/components/signature-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signatureField = void 0;
  var signatureField = {
    "rotateToSign": "Rotate to sign.",
    "rotateTip": "You might have to unlock your device's screen rotation.",
    "clear": "Clear",
    "signInstn": "Click and drag to draw your signature",
    "typeUrSign": "Type your signature",
    "typeSign": "Type Signature",
    "drawSign": "Draw Signature",
    "or": "or",
    "savedSign": "Use Saved Signature",
    "apply": "Apply"
  };
  _exports.signatureField = signatureField;
});