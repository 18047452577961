define("ember-rs/locales/es/templates/components/show-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showComponent = void 0;
  var showComponent = {
    "completed": "Completado",
    "pending": "Pendientes",
    "mergefield": "es un campo de combinación",
    "requiredAssignedTo": "El campo necesario \"{{{fieldName}}}\" se asignó a <b>{{{assignedToName}}}</b>",
    "optionalAssignedTo": "El campo opcional \"{{{fieldName}}}\" se asignó a <b>{{{assignedToName}}}</b>"
  };
  _exports.showComponent = showComponent;
});