define("ember-rs/components/document-tags-list", ["exports", "ember-rs/mixins/tags-collection", "ember-rs/mixins/partial-session"], function (_exports, _tagsCollection, _partialSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_partialSession.default, _tagsCollection.default, {
    ////////////////////////  DEFAULTS  ////////////////////////
    cantRemoveTags: false,
    darkBackground: false,
    actions: {
      updateTags: function updateTags(newTags) {
        this.send('updateTags', newTags);
      }
    }
  });

  _exports.default = _default;
});