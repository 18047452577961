define("ember-rs/locales/pt-br/mixins/distribution-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.distributionMethod = void 0;
  var distributionMethod = {
    "viaEmail": "Enviar convite via email",
    "manual": "Distribuir links manualmente",
    "email": "Email",
    "sms": "SMS",
    "none": "Nenhum"
  };
  _exports.distributionMethod = distributionMethod;
});