define("ember-rs/locales/nl/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alreadySigned = void 0;
  var alreadySigned = {
    "thankYou": "Bedankt voor het ondertekenen",
    "youHave": "U hebt dit document met succes ondertekend. U kunt het document op elk moment bekijken door een beveiligde koppeling aan te vragen.",
    "sendNewLink": "Nieuwe downloadkoppeling verzenden",
    "successfully": "U hebt dit document met succes ondertekend."
  };
  _exports.alreadySigned = alreadySigned;
});