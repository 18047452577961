define("ember-rs/helpers/truthy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truthy = truthy;
  _exports.default = void 0;

  function truthy(input) {
    var value = Ember.isArray(input) ? input[0] : input;
    return [true, 'true', 'checked', 1, '1'].indexOf(value) > -1;
  }

  var _default = Ember.Helper.helper(truthy);

  _exports.default = _default;
});