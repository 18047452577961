define("ember-rs/locales/fr/templates/mobile-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mobileNavigation = void 0;
  var mobileNavigation = {
    "documents": "Documents",
    "templates": "Modèles",
    "settings": "Vos paramètres",
    "integrations": "Intégrations",
    "users": "Utilisateurs",
    "reports": "Rapports",
    "help": "Aide",
    "logout": "Déconnexion"
  };
  _exports.mobileNavigation = mobileNavigation;
});