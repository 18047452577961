define("ember-rs/mixins/pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  DEFAULTS  ////////////////////////
    page: 1,
    perPage: 15,
    totalPages: Ember.computed.alias('model.meta.total_pages'),
    ////////////////////////  PROPERTIES  ////////////////////////
    hasMultiplePages: Ember.computed.gt('totalPages', 1)
  });

  _exports.default = _default;
});