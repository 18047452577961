define("ember-rs/locales/it/templates/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detailsPanel = void 0;
  var detailsPanel = {
    "clientId": "ID CLIENT:",
    "clientSecret": "SEGRETO CLIENT:",
    "lastViewed": "Ultima visualizzazione: ",
    "fromIp": "da IP",
    "hidden": "Nascosto per sicurezza",
    "reveal": "Rivela segreto",
    "privateToken": "TOKEN API PRIVATO:",
    "revealToken": "Rivela token",
    "regenerate": "Genera nuovamente il token. ATTENZIONE: questa azione è irreversibile",
    "description": "DESCRIZIONE:",
    "scope": "AMBITO/I:",
    "redirectUri": "URI DI REINDIRIZZAMENTO:",
    "applicationUrl": "URL APPLICAZIONE:",
    "orgUrl": "URL ORGANIZZAZIONE:",
    "createdAt": "CREATO ALLE:",
    "updatedAt": "AGGIORNATO ALLE:",
    "edit": "modifica"
  };
  _exports.detailsPanel = detailsPanel;
});