define("ember-rs/components/attached-completed-pdf-confirmation-modal", ["exports", "ember-rs/mixins/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_settings.default, {
    currentUser: Ember.inject.service(),
    actions: {
      close: function close() {
        this.close();
      },
      onConfirm: function onConfirm() {
        this.updateSetting('attachPdfOnCompletion', 'attach_pdf_on_completion', true, Ember.get(this, 'currentUser.model'), false);
        this.close();
      }
    }
  });

  _exports.default = _default;
});