define("ember-rs/locales/it/templates/onboarding/send-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding1 = void 0;
  var sendOnboarding1 = {
    "greatWork": "Ottimo, il primo documento è stato inviato.",
    "youHave": "Si hanno a disposizione ",
    "remaining": "per la prova."
  };
  _exports.sendOnboarding1 = sendOnboarding1;
});