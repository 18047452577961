define("ember-rs/locales/fr/templates/signer/footer-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerDesktop = void 0;
  var footerDesktop = {
    "from": "De",
    "submitSig": "Envoyer signature",
    "submit": "Envoyer",
    "clickSubmit": "En cliquant sur « Envoyer », vous acceptez notre",
    "privacyPolicy": "Déclaration de confidentialité",
    "consent": "Consentement E-Sign",
    "contents": "et le contenu de ce document.",
    "left": "gauche",
    "save": "Enregistrer",
    "help": "Besoin d'aide ?",
    "saveProgress": "Enregistrer la progression",
    "savingChanges": "Enregistrement des modifications",
    "field": "champ",
    "fields": "champs"
  };
  _exports.footerDesktop = footerDesktop;
});