define("ember-rs/controllers/account/users", ["exports", "ember-rs/mixins/pagination"], function (_exports, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pagination.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    queryParams: ['page', 'perPage'],
    totalPages: Ember.computed.alias('model.totalPages'),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      setCurrentPage: function setCurrentPage(page) {
        this.set('page', page);
      }
    }
  });

  _exports.default = _default;
});