define("ember-rs/locales/en/templates/components/document-token-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTokenExpirationSelect = void 0;
  var documentTokenExpirationSelect = {
    "Do_not_expire_links": "Do not expire links",
    "1_hour": "1 hour",
    "3_hours": "3 hours",
    "8_hours": "8 hours",
    "24_hours": "24 hours",
    "1_week": "1 week"
  };
  _exports.documentTokenExpirationSelect = documentTokenExpirationSelect;
});