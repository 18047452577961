define("ember-rs/models/signer", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ////////////////////////  SERVICES  ////////////////////////
    currentUser: Ember.inject.service(),
    ////////////////////////  ATTRIBUTES  ////////////////////////
    email: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    roleName: (0, _model.attr)('string'),
    message: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    sequence: (0, _model.attr)('number'),
    viewedAt: (0, _model.attr)('moment-date'),
    signedAt: (0, _model.attr)('moment-date'),
    phoneNumber: (0, _model.attr)('string'),
    dismissReminders: (0, _model.attr)('boolean'),
    existingUserByEmail: (0, _model.attr)('boolean'),
    passcodeAnswerDigest: (0, _model.attr)('string'),
    hasPreviouslySigned: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    remindersRemaining: (0, _model.attr)('number', {
      defaultValue: 3
    }),
    senderEmail: (0, _model.attr)('string'),
    // This is not passed from the endpoint, but is used to
    // serialize on a signer#save and proxy auth variables that
    // were specified in the query params
    identityToken: (0, _model.attr)('string'),
    accessToken: (0, _model.attr)('string'),
    redirectToken: (0, _model.attr)('string'),
    ////////////////////////  RELATIONSHIPS  ////////////////////////
    user: (0, _model.belongsTo)('user', {
      async: false
    }),
    document: (0, _model.belongsTo)('document', {
      async: false
    }),
    response: (0, _model.belongsTo)('response', {
      async: false
    }),
    ////////////////////////  PROPERTIES  ////////////////////////
    initials: Ember.computed('name', function () {
      var names = this.name.split(' ');
      var initials = '';
      initials += names.length ? names[0].charAt(0).toUpperCase() : '';
      initials += names.length > 1 ? names[names.length - 1].charAt(0).toUpperCase() : '';
      return initials;
    }),
    canBeModified: Ember.computed('status', 'document.documentState', 'document.currentUserIsSender', function () {
      return this.get('document.currentUserIsSender') && this.status === 'pending' && this.get('document.documentState') === 'pending';
    }),
    ////////////////////////  METHODS  ////////////////////////
    addAudit: function addAudit(action) {
      if (Ember.isBlank(this.get('response.fingerprintData.audits'))) {
        this.set('response.fingerprintData.audits', Ember.A([]));
      }

      this.get('response.fingerprintData.audits').pushObject({
        action: action,
        timestamp: new Date().toTimeString()
      });
    }
  });

  _exports.default = _default;
});