define("ember-rs/locales/zh-cn/templates/document-transaction/redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirect = void 0;
  var redirect = {
    "please_wait": " 正在处理您的文档，请稍候..."
  };
  _exports.redirect = redirect;
});