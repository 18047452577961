define("ember-rs/mixins/signing-component-interactions", ["exports", "ember-rs/helpers/truthy"], function (_exports, _truthy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    layoutSizeData: Ember.inject.service(),
    valueObjectName: 'mergefieldValue',
    updatingComponents: true,
    auditingAvailable: false,
    checkComponents: Ember.computed.filterBy('components', 'isCheckBox', true),
    booleanizeCheckboxValues: Ember.on('init', function () {
      var self = this;
      this.checkComponents.forEach(function (component) {
        var valueObject = component.get(this.valueObjectName);
        valueObject.set('value', (0, _truthy.truthy)(valueObject.get('value')));
      }, this);
      Ember.run.next(function () {
        self.set('updatingComponents', false);
      });
    }),
    reinitializeGrouped: Ember.on('didInsertElement', function () {
      var self = this;
      Ember.run.next(this, function () {
        this.components.forEach(function (component) {
          if (component.get('isGrouped')) {
            var value = component.get(self.get('valueObjectName')).get('value');

            if (value) {
              self.toggleGroupedComponent(component, value);
            }
          }
        });
      });
    }),
    toggleGroupedComponent: function toggleGroupedComponent(component, value) {
      var self = this;
      var groupedComponents;

      switch (component.get('groupRequirement')) {
        case 'only_one':
          groupedComponents = this.components.filter(function (c) {
            return c.get('isGrouped') && c.get('groupId') === component.get('groupId');
          });
          groupedComponents.forEach(function (groupedComponent) {
            groupedComponent.get(self.get('valueObjectName')).set('groupIsValid', value);

            if (groupedComponent.get('id') === component.get('id')) {
              return;
            }

            groupedComponent.get(self.get('valueObjectName')).set('value', false);
          });
          component.get(self.get('valueObjectName')).set('value', value);
          break;

        case 'one_or_more':
          {
            component.get(self.get('valueObjectName')).set('value', value);
            groupedComponents = this.components.filter(function (c) {
              return c.get('isGrouped') && c.get('groupId') === component.get('groupId');
            });
            var componentValues = groupedComponents.map(function (gc) {
              return gc.get(self.get('valueObjectName'));
            }); // if required, any components in group must be checked

            var isValid = component.get('isRequired') ? componentValues.isAny('value', true) : true;
            componentValues.forEach(function (componentValue) {
              componentValue.set('groupIsValid', isValid);
            });
            break;
          }
      }
    },
    actions: {
      componentValueChanged: function componentValueChanged(component, value) {
        if (component.get('type') === 'dateComponent' && Ember.isPresent(value) && component.get('isDateSigned')) {
          component.get(this.get('valueObjectName')).set('value', value);
        }

        if (this.updatingComponents) {
          return;
        }

        Ember.set(this, 'updatingComponents', true);
        var self = this;

        if (component.get('type') === 'dateComponent' && Ember.isPresent(value) && !this.get('layoutSizeData.useMobileLayout')) {
          value = value.toDateString();
        }

        if (component.get('isGrouped')) {
          this.toggleGroupedComponent(component, value);
        } else {
          component.get(self.get('valueObjectName')).set('value', value);
        }

        Ember.run.next(function () {
          self.set('updatingComponents', false);
        });
      }
    }
  });

  _exports.default = _default;
});