define("ember-rs/templates/components/document-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "scnMscM+",
    "block": "{\"symbols\":[\"xs\",\"option\",\"&default\"],\"statements\":[[8,\"x-select\",[],[[\"@value\",\"@on-change\"],[[34,1],[30,[36,2],[[32,0],\"updateSetting\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[6,[37,3],[[27,[32,3]]],null,[[\"default\"],[{\"statements\":[[2,\" \"],[18,3,[[32,1]]],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"option\"]],[],[[\"@value\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\" \"],[1,[32,2]],[2,\" \"],[1,[30,[36,0],[\"components.documentExpirationSelect.days\"],null]],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"currentSelection\",\"action\",\"if\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/document-expiration-select.hbs"
    }
  });

  _exports.default = _default;
});