define("ember-rs/locales/nl/mixins/integration-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationAuth = void 0;
  var integrationAuth = {
    "unableAuthorize": "Kan integratie niet autoriseren!",
    "notComplete": "Kan de autorisatie niet voltooien"
  };
  _exports.integrationAuth = integrationAuth;
});