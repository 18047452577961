define("ember-rs/locales/it/templates/components/plan-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planInfo = void 0;
  var planInfo = {
    "perMonth": "/mese",
    "teamMember": "Membro del team",
    "teamMembers": "Membri del team",
    "call": "Chiamare il numero 1-855-895-4862",
    "selectedPlan": "Piano selezionato",
    "selectPlan": "Seleziona piano"
  };
  _exports.planInfo = planInfo;
});