define("ember-rs/components/fullscreen-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layoutSizeData: Ember.inject.service(),
    classNameBindings: [':fullscreen-container', 'layoutSizeData.showModal', 'layoutSizeData.showDetailsSidebar']
  });

  _exports.default = _default;
});