define("ember-rs/locales/zh-cn/templates/components/client-application/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "editKey": "编辑 API 密钥 ",
    "name_tool_tip": "如果您使用 OAuth 申请对用户帐户的访问权限，则会向用户显示此消息。例如，Industry Automative Estimates Generator 或 WordStar Plugin",
    "name": "名称:",
    "org_name_tip": "  贵组织的名称",
    "org_name": "组织名称:",
    "description_tip": "如果您使用 OAuth 申请对用户帐户的访问权限，则会向用户显示此消息。例如，“此应用程序仅由 Industry Automotive 在发送我们企业工作流程内部的预算时使用”或“WordStar Plugin 允许 WordStar 用户通过其 RightSignature&trade; 帐户发送文档以获取签名”。",
    "description": "说明:",
    "app_url_tip": "如果您使用 OAuth 申请对用户帐户的访问权限，则会向用户显示此消息。例如 http://industryauto.com/login 或 http://wordstarsupportgroup.com/theplugin",
    "app_url": "应用程序 URL:",
    "redirect_url_tip": "这是 OAuth 授权请求的默认回调。可以在 API 调用中对其进行指定或替代。例如 http://industryauto.com/oauth_callback",
    "redirect_url": "重定向 URL:",
    "org_url_tip": "  (可选)贵组织的 Web 站点",
    "org_url": "组织 URL:",
    "update_success": "您已成功更新 {{{applicationName}}}。"
  };
  _exports.edit = edit;
});