define("ember-rs/locales/it/templates/unauthorized/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unauthorizedFile = void 0;
  var unauthorizedFile = {
    "unauthorized": "Non autorizzato",
    "youAreNot": "Non si è autorizzati a visualizzare questa pagina."
  };
  _exports.unauthorizedFile = unauthorizedFile;
});