define("ember-rs/locales/ja/templates/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "send": "署名用に送信",
    "notAllowed": " 現在のプランでは、ドキュメントを送信する権限がありません。この機能を利用するには、プランをアップグレードしてください。",
    "embed": "Web サイトに埋め込む",
    "share": "共有リンクを生成する",
    "manageLinks": "リンクと埋め込みを管理する(&E)",
    "viewResponses": "応答の表示",
    "edit": "編集",
    "delete": "削除",
    "roles": "役割",
    "security": "セキュリティのオプション:",
    "expires": "有効期限まで: ",
    "days": "日",
    "passcodeQuestion": "パスコードの質問:",
    "passcode": "パスコード:",
    "tags": "タグ:",
    "linkHistory": "リンクと埋め込みコードの履歴",
    "linkHistoryTip": "このテンプレートのすべてのリンクおよび埋め込みの使用情報を管理して表示します。",
    "generate": "共有リンクを生成する",
    "nameLink": "共有リンクに名前を付けます。",
    "nameLinkTip": "リンクの管理が簡単になります。",
    "whichSigner": "署名者の役割",
    "whichSignerTip": "埋め込まれたテンプレートおよびリンクには、1 署名者のみを追加できます。",
    "limitUses": "使用回数の制限",
    "limitUsesTip": "このリンクに署名できる回数を設定します。",
    "expireDays": "有効期限までの日数",
    "expireDaysTip": "有効期限後、このリンクは機能しなくなります。",
    "identityMethod": "ID 確認の方法:",
    "identityMethodTip": "署名者の ID を確認する方法です。",
    "generateLink": "リンクを生成する",
    "generateEmbed": "埋め込みコードを生成する",
    "nameCode": "このコードに名前を付ける。",
    "nameCodeTip": "生成されたコードの管理が簡単になります。",
    "displayType": "表示の種類",
    "displayTypeTip": "埋め込みをどのように表示するかを指定します",
    "pixel": "ピクセル",
    "codeGenerated": "生成されたコード",
    "linkGenerated": "生成されたリンク",
    "clickingManage": "このために、テンプレート ページで [リンクと埋め込みを管理する(&E)] をクリックします。",
    "youCanAccessCode": "テンプレート ページで [リンクと埋め込みを管理する(&E)] をクリックして、このコードにアクセス、無効化、編集できます。",
    "youCanAccessLink": "テンプレート ページで [リンクと埋め込みを管理する(&E)] をクリックして、このリンクにアクセス、無効化、編集できます。",
    "newPin": "新しいPIN",
    "done": "完了",
    "email": "メール アドレス",
    "sms": "SMS",
    "none": "なし",
    "height": "高さ",
    "Width": "幅",
    "button": "ボタン",
    "image": "画像",
    "inline": "インライン",
    "createdBy": "作成者",
    "actions": "操作",
    "visibility-toggle-text": "テンプレートをプライベートに設定",
    "visibility-toogle-info": "プライベートに設定するとテンプレートが作成者にのみ表示されます",
    "template-signer-link": {
      "passcode-message": "パスコード機能はこのアカウントで有効になっていますが、共有リンクや埋め込みドキュメントには適用されません。このリンク/埋め込みコードにより、パスコードなしでこのドキュメントにアクセスできます。"
    },
    "embed-in-website": {
      "disabled-merge-fields": "現在、このテンプレートで埋め込みコードは利用できません。このテンプレートの埋め込みコードを生成するには、まずすべての必須マージ フィールドを削除します。",
      "disabled-no-online-forms": "現在のプランでは、テンプレートを埋め込む権限がありません。この機能を利用するには、プランをアップグレードしてください。"
    },
    "generate-share-link": {
      "disabled-merge-fields": "現在、このテンプレートで埋め込みコードは利用できません。このテンプレートの埋め込みコードを生成するには、まずすべての必須マージ フィールドを削除します。",
      "disabled-no-manual-distribution": "現在のプランでは、共有リンクを生成する権限がありません。この機能を利用するには、プランをアップグレードしてください。"
    }
  };
  _exports.details = details;
});