define("ember-rs/templates/logged-out/features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5YUql50v",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"logged_out.features.note\"],null]]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/logged-out/features.hbs"
    }
  });

  _exports.default = _default;
});