define("ember-rs/locales/es/templates/components/document-token-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTokenExpirationSelect = void 0;
  var documentTokenExpirationSelect = {
    "Do_not_expire_links": "No permitir que los enlaces caduquen",
    "1_hour": "1 hora",
    "3_hours": "3 horas",
    "8_hours": "8 horas",
    "24_hours": "24 horas",
    "1_week": "1 semana"
  };
  _exports.documentTokenExpirationSelect = documentTokenExpirationSelect;
});