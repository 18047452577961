define("ember-rs/locales/fr/templates/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlay = void 0;
  var importOverlay = {
    "importFrom": "Importer champs depuis :",
    "toImport": "composant(s) à importer depuis",
    "page": "page(s)",
    "cancel": "Annuler",
    "import": "Importer",
    "noComponents": "Aucun composant à importer !",
    "pastDocs": "Importer champs de documents précédents",
    "noMatch": "Aucun document trouvé ne correspond à ce nom",
    "importSuccess": "Composants importés avec succès : {{{components}}}."
  };
  _exports.importOverlay = importOverlay;
});