define("ember-rs/mixins/kba-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    kbaSession: Ember.inject.service(),
    model: function model() {
      this._super.apply(this, arguments);

      var kbaSession = this.kbaSession;
      var path = this.modelPath;
      return kbaSession.request(path, 'GET', {}, true).then(function (_ref) {
        var kba = _ref.kba;
        return kba;
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('signer', this.modelFor('signer.sign'));
    }
  });

  _exports.default = _default;
});