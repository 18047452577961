define("ember-rs/locales/de/templates/reusable-template/prepare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare = void 0;
  var prepare = {
    "prepare": "Vorbereiten",
    "next": "Nächster Schritt: Überprüfen"
  };
  _exports.prepare = prepare;
});