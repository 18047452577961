define("ember-rs/locales/fr/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding1 = void 0;
  var accountOnboarding1 = {
    "body": "Modifiez <span>vos paramètres</span>, <span>personnalisez votre</span> compte avec votre marque et <span>intégrez</span> d'autres outils et bien plus encore."
  };
  _exports.accountOnboarding1 = accountOnboarding1;
});