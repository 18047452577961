define("ember-rs/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yQfVrOUC",
    "block": "{\"symbols\":[\"admin\",\"used\"],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\"],[\"transaction_manager_header\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"header-content\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"document.prepare_signers.add_signers\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,4],[[35,3,[\"data\",\"sharefileRedirect\",\"skipToPrepare\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"document-limit-modal\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"document.prepare_signers.doc_limit_p1\"],null]],[1,[32,2]],[2,\"% \"],[1,[30,[36,0],[\"document.prepare_signers.doc_limit_p2\"],null]],[13],[2,\"\\n  \"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[6,[37,2],[[35,1,[\"useMobileLayout\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[19,\"document/prepare-signers-mobile\",[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[19,\"document/prepare-signers-desktop\",[]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"t\",\"layoutSizeData\",\"if\",\"session\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/document/prepare-signers.hbs"
    }
  });

  _exports.default = _default;
});