define("ember-rs/locales/de/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noUser = void 0;
  var noUser = {
    "unableLogin": "Anmeldung nicht möglich",
    "unableLoginTip": "Sie können sich nicht an diesem RightSignature-Konto anmelden.<br> Wenden Sie sich an einen Kontoadministrator, um Zugriff zu erhalten."
  };
  _exports.noUser = noUser;
});