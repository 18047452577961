define("ember-rs/locales/de/templates/components/user-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userFields = void 0;
  var userFields = {
    "name": "Name",
    "email": "E-Mail",
    "editInfo": "Informationen bearbeiten"
  };
  _exports.userFields = userFields;
});