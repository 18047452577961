define("ember-rs/locales/zh-cn/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alreadySigned = void 0;
  var alreadySigned = {
    "thankYou": "感谢您签名",
    "youHave": "您已成功对此文档签名。可以通过申请一个安全链接随时查看此文档。",
    "sendNewLink": "发送新下载链接",
    "successfully": "您已成功对此文档签名。"
  };
  _exports.alreadySigned = alreadySigned;
});