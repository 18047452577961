define("ember-rs/locales/zh-cn/templates/reports", ["exports", "ember-rs/locales/zh-cn/templates/reports/export"], function (_exports, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    export: _export.exportTrans,
    "header": "报告",
    "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
    "select-report-type--text": "选择报告类型",
    "select-report-type-option-overview": "概览",
    "overviewReport": "概览报告",
    "select-report-type-option-efficiency": "效率",
    "efficiencyReport": "效率报告",
    "select-report-type-option-cycle-time": "周期时间报告",
    "select-report-type-option-export": "数据导出程序",
    "select-report-type-option-enterprise": "企业",
    "enterpriseUsageReport": "企业使用情况报告",
    "userUsageReport": "用户使用情况报告",
    "select-report-type-option-user": "新建使用情况报告",
    "components": {
      "account-document-volume": {
        "header": "报告",
        "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
        "legend-document-sent": "已发送的文档",
        "legend-document-signed": "已签名的文档",
        "legend": "图例:"
      },
      "account-statistics": {
        "header": "报告",
        "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
        "column-header-current-month": "本月",
        "column-header-previous-month": "上月",
        "column-header-average": "平均值",
        "row-header-sent-documents": "已发送的文档",
        "row-header-viewed": "已查看(至少由一位签名者查看)",
        "row-header-expired": "已过期",
        "row-header-signed": "已签名",
        "row-header-sent-documents-per-user": "每个用户已发送的文档(平均值)",
        "row-header-signed-documents": "已签名的文档",
        "row-header-pending-documents": "待签名的文档",
        "row-header-viewed-pending": "已查看(待查看)",
        "none": "不适用"
      },
      "top-users-by-signed-documents": {
        "header": "报告",
        "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
        "column-header-user": "用户",
        "column-header-signed-documents": "已签名的文档"
      },
      "account-efficiency": {
        "header": "报告",
        "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
        "legend-pending": "待签名",
        "legend-signed": "已签名",
        "legend-expired": "已过期"
      },
      "top-users-by-efficiency": {
        "header": "报告",
        "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
        "column-header-user": "用户",
        "column-header-efficiency-percentage": "效率百分比(%)",
        "column-header-ratio": "比率",
        "over-gcd": "已签名 {{signed}} 个，共发送 {{sent}} 个"
      },
      "top-users-by-time-to-sign": {
        "header": "报告",
        "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
        "column-header-user": "用户",
        "column-header-mean-time": "平均时间(小时)"
      },
      "average-cycle-time": {
        "header": "报告",
        "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
        "legend-mean-time-to-view": "平均查看时间",
        "legend-mean-time-to-sign": "平均签名时间"
      },
      "cycle-time-statistics": {
        "header": "报告",
        "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
        "column-header-current-month": "本月",
        "column-header-previous-month": "上月",
        "column-header-all-time": "所有时间",
        "row-header-under-5-mins": "5 分钟以内",
        "row-header-under-30-mins": "30 分钟以内",
        "row-header-under-1-hour": "1 小时以内",
        "row-header-under-12-hours": "12 小时以内",
        "row-header-under-1-day": "1 天以内"
      },
      "month-export": {
        "header": "报告",
        "description": "查看已发送的文档的数据。这包括对文档签名通常需要的时间长度以及每月完成的文档数量。请升级您的计划以访问报告。",
        "export-to-csv-section-header": "导出为 CSV",
        "export-to-csv-section-description": "可以在 Excel 中打开 CSV 以查看与您已发送的文档有关的数据。"
      },
      "generate-download": {
        "ie-warning-text": "请右键单击“导出 CSV”并选择“另存为”以下载 CSV。",
        "export-csv-button-text": "导出 CSV",
        "generate-csv-button-text": "生成 CSV",
        "send-download-link": "我们将生成报告并通过电子邮件发送下载链接。"
      }
    }
  };
  _exports.reports = reports;
});