define("ember-rs/routes/document/prepare-document", ["exports", "ember-rs/mixins/prepare-next-route"], function (_exports, _prepareNextRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_prepareNextRoute.default, {
    titleToken: 'Prepare Document',
    currentStep: 'prepare_document',
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    overlayerData: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.controllerFor('document-transaction').set('currentStep', this.currentStep);
    },
    afterModel: function afterModel(doc) {
      if (!Ember.get(this, 'currentUser.canSendDocuments') && doc.get('workflow') !== 'self_sign') {
        this.transitionTo('documents');
        return;
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.set('layoutSizeData.sidebarWidth', 400);
      this.set("documentViewerData.pageHeight", model.get("upload.baseFile.imageHeight"));
      this.set("documentViewerData.pageWidth", model.get("upload.baseFile.imageWidth"));
    },
    syncMergefieldValuesRelationships: function syncMergefieldValuesRelationships() {
      var doc = this.controller.get('model');

      if (doc.get('hasMergeFields')) {
        doc.get('mergefieldValues').forEach(function (mf) {
          return mf.set('document', doc);
        });
      }
    },
    actions: {
      nextStep: function nextStep() {
        var _this = this;

        this.currentTemplate.save().then(function () {
          _this.syncMergefieldValuesRelationships();

          if (_this.nextRouteTransition === 'document.prepare_signers') {
            _this.transitionTo('document.prepare_signers', _this.nextTemplate);
          } else {
            _this.transitionTo('document-transaction.review', _this.documentTransaction);
          }
        });
      }
    }
  });

  _exports.default = _default;
});