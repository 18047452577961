define("ember-rs/locales/fr/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationSettingsService = void 0;
  var integrationSettingsService = {
    "requiresInfo": "Cette intégration nécessite quelques informations avant de pouvoir continuer :",
    "authorize": "Autoriser",
    "sending": "Envoyez vos documents {{{formattedProviderName}}} pour signature électronique depuis RightSignature",
    "archiving": "Envoyez un PDF des documents complétés dans un dossier spécifique de votre compte {{{formattedProviderName}}}",
    "contacts": "Rendez tous vos contacts {{{formattedProviderName}}} disponibles pour la signature de documents",
    "payments": "Placez des composants de paiement sur vos documents RightSignature pour collecter et stocker en toute sécurité les informations de carte de crédit et autoriser les débits via votre compte {{{formattedProviderName}}}",
    "fieldsInfo": "Veuillez remplir tous les champs"
  };
  _exports.integrationSettingsService = integrationSettingsService;
});