define("ember-rs/locales/pt-br/templates/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "send": "Enviar para assinatura",
    "notAllowed": " Seu plano atual não permite que você envie documentos. Atualize seu plano para ter acesso a esse recurso.",
    "embed": "Incorporar no site",
    "share": "Gerar o link de compartilhamento",
    "manageLinks": "Gerenciar links e incorporações",
    "viewResponses": "Ver respostas",
    "edit": "Editar",
    "delete": "Excluir",
    "roles": "Funções",
    "security": "Opções de segurança:",
    "expires": "Expira em:",
    "days": "dias",
    "passcodeQuestion": "Pergunta do código secreto:",
    "passcode": "Código secreto:",
    "tags": "Marcas:",
    "linkHistory": "Histórico de códigos de incorporação e links",
    "linkHistoryTip": "Gerenciar e exibir informações de uso de todos os links e incorporações deste modelo.",
    "generate": "Gerar o link de compartilhamento",
    "nameLink": "Dê um nome a este link de compartilhamento.",
    "nameLinkTip": "Isso pode facilitar o gerenciamento de links.",
    "whichSigner": "Qual função de signatário?",
    "whichSignerTip": "Os modelos incorporados e links só podem ter um único signatário.",
    "limitUses": "Limitar o número de usos?",
    "limitUsesTip": "Limite número de vezes que este link pode ser assinado.",
    "expireDays": "Expirar após o número de dias?",
    "expireDaysTip": "Após expirar, este link não funcionará mais.",
    "identityMethod": "Método de identidade:",
    "identityMethodTip": "Como devemos verificar a identidade dos signatários?",
    "generateLink": "Gerar link",
    "generateEmbed": "Gerar código de incorporação",
    "nameCode": "Dê um nome a este código.",
    "nameCodeTip": "Isso pode facilitar o gerenciamento dos códigos gerados.",
    "displayType": "Tipo de exibição",
    "displayTypeTip": "Isto especifica como deve aparecer a incorporação",
    "pixel": "px",
    "codeGenerated": "Código gerado",
    "linkGenerated": "Link gerado",
    "clickingManage": "by clicking on “Manage Links &amp; Embeds” on the template page.",
    "youCanAccessCode": "Você pode acessar, desativar e editar esse código clicando em “Gerenciar links e incorporações” na página do modelo.",
    "youCanAccessLink": "Você pode acessar, desativar e editar esse link clicando em “Gerenciar links e incorporações” na página do modelo.",
    "newPin": "Novo PIN",
    "done": "Concluído",
    "email": "Email",
    "sms": "SMS",
    "none": "Nenhum",
    "height": "Altura",
    "Width": "Largura",
    "button": "botão",
    "image": "imagem",
    "inline": "integrado",
    "createdBy": "Criado por",
    "actions": "Ações",
    "visibility-toggle-text": "Definir modelo como privado",
    "visibility-toogle-info": "Se forem definidos como privados, os modelos serão visíveis apenas para seu criador",
    "template-signer-link": {
      "passcode-message": "O recurso de código de acesso foi ativado para esta conta, mas não será aplicado em links de compartilhamento ou documentos incorporados. Este código de link/incorporação permitirá o acesso a este documento sem o código de acesso."
    },
    "embed-in-website": {
      "disabled-merge-fields": "Os códigos de incorporação não estão disponíveis no momento para este modelo. Para gerar um código de incorporação para este modelo, primeiro remova todos os campos de mesclagem necessários.",
      "disabled-no-online-forms": "Seu plano atual não permite que você incorpore modelos. Atualize seu plano para ter acesso a esse recurso."
    },
    "generate-share-link": {
      "disabled-merge-fields": "Os códigos de incorporação não estão disponíveis no momento para este modelo. Para gerar um código de incorporação para este modelo, primeiro remova todos os campos de mesclagem necessários.",
      "disabled-no-manual-distribution": "Seu plano atual não permite que você gere links de compartilhamento. Atualize seu plano para ter acesso a esse recurso."
    }
  };
  _exports.details = details;
});