define("ember-rs/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EUYcVWTE",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[10,\"div\"],[14,1,\"personal_info\"],[14,0,\"report-section\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"reports.export.month.template.choose\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"description\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"reports.export.month.template.columnNames\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \\n  \"],[8,\"power-select-multiple\",[],[[\"@searchenabled\",\"@options\",\"@selected\",\"@onchange\",\"@searchField\",\"@placeholder\"],[true,[34,4],[34,5],[30,[36,6],[[32,0],\"selectionsChanged\"],null],\"name\",[30,[36,0],[\"reports.export.month.template.selectColumns\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1,[\"name\"]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[14,1,\"personal_info\"],[14,0,\"report-section\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"reports.export.month.template.export\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"description\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"reports.export.month.template.view\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"reports/generate-download\",[],[[\"@generateURL\",\"@generateData\",\"@downloadURL\"],[[34,1],[34,2],[34,3]]],null],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"generateURL\",\"generateData\",\"downloadURL\",\"columns\",\"columnsSelected\",\"action\",\"readyToGenerate\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/reports/export/month/template.hbs"
    }
  });

  _exports.default = _default;
});