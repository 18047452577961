define("ember-rs/routes/document-transaction", ["exports", "ember-rs/utils/safe-access"], function (_exports, _safeAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    i18n: Ember.inject.service(),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      saveTransaction: function saveTransaction() {
        var _this = this;

        this.controllerFor('document-transaction').set('saving', true);
        this.currentModel.saveAndTemplates().then(function () {
          _this.controllerFor('document-transaction').set('saving', false);
        });
      },
      sendTransaction: function sendTransaction() {
        var _this2 = this;

        var documents = this.get('currentModel.documents');
        this.controllerFor('document-transaction.review').set('saving', true);
        this.currentModel.set('status', 'live');

        if (documents.isAny('validPasscode', false)) {
          var names = documents.filterBy('validPasscode', false).mapBy('name').join(', ');
          this.flashMessages.warning(this.i18n.t('routes.documentTransaction.disabledPasscode', {
            names: names
          }));
          return false;
        }

        if (documents.isAny('hasPaymentComponents', true)) {
          if (!this.get('currentUser.model.integrations').isAny('payments', true)) {
            var _names = documents.filterBy('hasPaymentComponents', true).mapBy('name').join(', ');

            var warning = this.i18n.t('routes.documentTransaction.disabledPaymentIntegration', {
              names: _names
            });
            this.flashMessages.warning(warning);
            this.controllerFor('document-transaction.review').set('saving', false);
            return false;
          }
        }

        this.currentModel.saveAndTemplates().then(function () {
          var sharefileRedirectUrl = _this2.get('session.data.sharefileRedirect.redirectUrl');

          var redirectUrl = _this2.currentModel.get('redirectUrl');

          var redirectPattern = /https?:\/\//;

          if (sharefileRedirectUrl && sharefileRedirectUrl.match(redirectPattern)) {
            _this2.transitionTo('document-transaction.redirect', _this2.currentModel);

            return;
          }

          if (redirectUrl && redirectUrl.match(redirectPattern)) {
            window.location = redirectUrl;
            return;
          }

          if (_this2.currentModel.get('account')) {
            _this2.currentModel.get('account').belongsTo("accountStatSet").reload();
          } // iframed


          if (window.self !== window.top) {
            // TOOD: If there is a redirect url we should close the window when we're done with
            // the transaction. This should probably happen in the interstitial page and force a reload
            // of the parent window
            parent.postMessage({
              eventName: 'rsSent',
              data: {}
            }, '*');
          } else if (_this2.currentModel.get('isBulkSend')) {
            _this2.transitionTo('documents');

            _this2.flashMessages.success(_this2.i18n.t('routes.documentTransaction.successfulDocCreation'));
          } else {
            _this2.flashMessages.success(_this2.i18n.t('routes.documentTransaction.successfulDocCreation'));

            var model = _this2.currentModel;
            var firstDocument = model.get('documents').objectAt(0);

            _this2.transitionTo('document.details', firstDocument);
          }
        }).finally(function () {
          return _this2.controllerFor('document-transaction.review').set('saving', false);
        });
      },
      error: function error(response) {
        var statusCode = (0, _safeAccess.default)(response, '500', 'errors', 0, 'status');

        if (statusCode === '404') {
          this.transitionTo('documents');
        }
      }
    }
  });

  _exports.default = _default;
});