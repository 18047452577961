define("ember-rs/locales/it/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploader = void 0;
  var documentUploader = {
    "multiFile": "Caricamento di più file non supportato. Verrà caricato solo il primo file selezionato.",
    "alreadyUploaded": "Questo file è stato già caricato.",
    "fileSize": "Il file {{{fileName}}} è più grande del nostro limite di 20 MB.",
    "uploadFailed": "Impossibile creare il caricamento"
  };
  _exports.documentUploader = documentUploader;
});