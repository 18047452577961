define("ember-rs/locales/zh-cn/controllers/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.help = void 0;
  var help = {
    "success": "您的请求已提交。请等待 24 小时进行答复。",
    "error": "无法提交: {{{error}}}"
  };
  _exports.help = help;
});