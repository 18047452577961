define("ember-rs/locales/ja/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationEditor = void 0;
  var documentExpirationEditor = {
    "theDocExpired": "このドキュメントの有効期限:",
    "reactivated": "ドキュメントの有効期限および再アクティブ化する日付を選択してください。",
    "theDocExpires": "このドキュメントの現在の有効期限:",
    "newExpiration": "ドキュメントの有効期限を選択してください。",
    "updateSuccess": "有効期限が更新されました。"
  };
  _exports.documentExpirationEditor = documentExpirationEditor;
});