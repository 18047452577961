define("ember-rs/locales/pt-br/templates/client-application/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTranslations = void 0;
  var newTranslations = {
    "request_key": "Solicitar nova chave de API",
    "name_tool_tip": "Isso será exibido aos usuários se você usar o OAuth para solicitar acesso à conta deles. Por exemplo, Industry Automative Estimates Generator ou WordStar Plugin",
    "name": "Nome:",
    "org_name_tip": "  Nome da sua organização",
    "org_name": "Nome da organização:",
    "description_tip": "Isso será exibido aos usuários se você usar o OAuth para solicitar acesso à conta deles. Por exemplo, 'Este aplicativo é usado exclusivamente pela Industry Automotive para enviar estimativas dentro do nosso fluxo de trabalho empresarial' ou 'O WordStar Plugin permite que os usuários do WordStar enviem documentos para assinatura através de sua conta do RightSignature&trade;'.",
    "description": "Descrição:",
    "scopes_tip": "  Nível de acesso desejado",
    "scopes": "Escopo(s):",
    "app_url_tip": "Isso será exibido aos usuários se você usar o OAuth para solicitar acesso à conta deles. Por exemplo, http://industryauto.com/login ou http://wordstarsupportgroup.com/theplugin",
    "app_url": "URL do aplicativo:",
    "redirect_url_tip": "Este é o retorno de chamada padrão para solicitações de autorização OAuth. Ele pode ser especificado ou substituído nas chamadas de API. Por exemplo, http://industryauto.com/oauth_callback",
    "redirect_url": "URL de redirecionamento:",
    "org_url_tip": "  (opcional) Site da sua organização",
    "org_url": "URL da organização:",
    "Request": "Solicitar",
    "readOnlyAccess": "Acesso de somente leitura",
    "readWriteAccess": "Acesso de leitura e gravação"
  };
  _exports.newTranslations = newTranslations;
});