define("ember-rs/locales/zh-cn/templates/onboarding/signer-onboarding-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboardingOld = void 0;
  var signerOnboardingOld = {
    "title": "通过 RightSignature&trade; 对文档进行签名非常简单!",
    "completefields": "填写标有标志的字段。",
    "submitSignature": "填写字段并提交您的签名。",
    "progress": "查看您与进度条之间的距离。填写完所有必填字段后，请单击“提交签名”按钮以完成签名。",
    "help": "需要帮助?",
    "questions": "如有疑问或者需要更多信息，请在此处获取帮助。"
  };
  _exports.signerOnboardingOld = signerOnboardingOld;
});