define("ember-rs/locales/ja/templates/components/show-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showComponent = void 0;
  var showComponent = {
    "completed": "完了",
    "pending": "保留",
    "mergefield": "マージ フィールドです",
    "requiredAssignedTo": "必要な \"{{{fieldName}}}\" フィールドが <b>{{{assignedToName}}}</b> に割り当てられました",
    "optionalAssignedTo": "オプションの \"{{{fieldName}}}\" フィールドが <b>{{{assignedToName}}}</b> に割り当てられました"
  };
  _exports.showComponent = showComponent;
});