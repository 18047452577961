define("ember-rs/locales/en/templates/onboarding/welcome-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding1 = void 0;
  var welcomeOnboarding1 = {
    "welcome": "Welcome to RightSignature,",
    "tips": "We'll show you where things are, how to use RightSignature, and tips for making the most of our service"
  };
  _exports.welcomeOnboarding1 = welcomeOnboarding1;
});