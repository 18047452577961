define("ember-rs/components/signature-pad", ["exports", "jquery", "ember-rs/utils/media", "signature_pad"], function (_exports, _jquery, _media, _signature_pad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var PEN_COLORS = {
    black: 'rgb(51,51,51)',
    blue: 'rgb(0,29,163)'
  };
  var HEIGHT_ASPECT_RATIO = 200;

  var _default = Ember.Component.extend({
    classNames: ['signature-pad'],
    width: HEIGHT_ASPECT_RATIO * 4,
    height: HEIGHT_ASPECT_RATIO,
    lineFromBottom: 93,
    hrStyle: 'bottom: 93px; width: 820px;',
    currentSignatureJsonData: '',
    // passed in
    color: 'black',
    // passed in
    signaturePad: null,
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._refreshFromCurrentSignatureJsonData();
    },
    wrapperStyle: Ember.computed('width', 'height', function () {
      return "width: ".concat(this.width, "; height: ").concat(this.height, "px;display:flex;justify-content:center;");
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var width = (0, _jquery.default)(window).width();

      if (width < _media.PHONE_SIZE) {
        width = width - 40;
        var height = width / 4;
        this.set('width', width);
        this.set('height', height);
        this.set('lineFromBottom', 0);
      }

      this.set('hrStyle', "bottom: ".concat(this.lineFromBottom, "px; width: ").concat(this.width, "px;"));
      var canvas = document.getElementById('signature-pad');
      window["signPad"] = new _signature_pad.default(canvas, {
        minWidth: 2,
        penColor: PEN_COLORS[this.color]
      });
      this.set('signaturePad', window["signPad"]);
      var self = this;
      this.signaturePad.addEventListener("endStroke", function () {
        self.sendAction('signatureDrawn', {
          jsonData: JSON.stringify(window["signPad"].toData()),
          imageDataRaw: window["signPad"].toDataURL(),
          signatureType: 'drawn',
          color: self.color
        });
      });
      Ember.run.next(function () {
        _this._refreshFromCurrentSignatureJsonData(self.signaturePad);
      });
    },
    _refreshFromCurrentSignatureJsonData: function _refreshFromCurrentSignatureJsonData(signaturePad) {
      var canvas = document.getElementById('signature-pad');

      if (!signaturePad) {
        signaturePad = new _signature_pad.default(canvas, {
          minWidth: 2,
          penColor: PEN_COLORS[this.color]
        });
      }

      if (Ember.isPresent(this.currentSignatureJsonData)) {
        var signatureJSONData = JSON.parse(this.currentSignatureJsonData); // determine old or new signature pad

        if (Object.prototype.hasOwnProperty.call(signatureJSONData[0], 'dotSize')) {
          signaturePad.fromData(signatureJSONData);
        } else {
          var signaturesPoints = Array();

          var _iterator = _createForOfIteratorHelper(signatureJSONData),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var point = _step.value;
              signaturesPoints.push({
                time: Date.now(),
                x: point['mx'],
                y: point['my'],
                pressure: 0.5
              }, {
                time: Date.now(),
                x: point['lx'],
                y: point['ly'],
                pressure: 0.5
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          var signatureData = [{
            penColor: PEN_COLORS[this.color],
            dotSize: 0,
            minWidth: 2,
            maxWidth: 2.5,
            velocityFilterWeight: 0.7,
            points: signaturesPoints
          }];
          signaturePad.fromData(signatureData);
        }
      } else {
        signaturePad.clear();
      }
    },
    actions: {
      clearSignature: function clearSignature() {
        this.sendAction('signatureDrawn', null);

        if (Ember.isPresent(this.signaturePad)) {
          this.signaturePad.clear();
        }
      }
    }
  });

  _exports.default = _default;
});