define("ember-rs/templates/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yDlsI3d+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"unauthorized\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"notice\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"a\"],[14,0,\"button\"],[15,6,[31,[[34,0]]]],[12],[1,[30,[36,1],[\"unauthorized.return\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"marketingHost\",\"t\",\"-outlet\",\"component\",\"displayRedirectButton\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized.hbs"
    }
  });

  _exports.default = _default;
});