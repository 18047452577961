define("ember-rs/locales/pt-br/templates/components/signer-alert-on-edit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerAlertonEditModal = void 0;
  var signerAlertonEditModal = {
    "header": "We couldn’t submit your signature",
    "info": "The sender is currently revising this document. Try again later or reach out to the sender (<a class='signer-alert-on-edit-modal__link' href='mailto: {{{senderEmail}}}'>{{{senderEmail}}}</a>) for more information.",
    "gotIt": "Got it"
  };
  _exports.signerAlertonEditModal = signerAlertonEditModal;
});