define("ember-rs/locales/nl/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingComplete = void 0;
  var signingComplete = {
    "docSigned": "Document ondertekend!",
    "createTrial": "Maak een gratis RightSignature-account om dit voltooide document te bekijken of te downloaden. Registreer u met het e-mailadres waarnaar het document is verzonden en meld u aan, zodat u onbeperkt toegang hebt tot dit document.",
    "createAccount": "Een account maken",
    "login": "Aanmelden bij account",
    "notNow": "Niet nu"
  };
  _exports.signingComplete = signingComplete;
});