define("ember-rs/locales/ja/templates/components/rs-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsActions = void 0;
  var rsActions = {
    "Actions": "操作"
  };
  _exports.rsActions = rsActions;
});