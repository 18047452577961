define("ember-rs/templates/components/logo-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qt17CMoo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"form\"],[15,\"action\",[34,0]],[12],[2,\"\\n  \"],[10,\"input\"],[14,0,\"file-uploader\"],[14,3,\"file\"],[14,4,\"file\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"uploadLogo\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/logo-uploader.hbs"
    }
  });

  _exports.default = _default;
});