define("ember-rs/locales/de/templates/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.account = void 0;
  var account = {
    "settings": {
      "header": "Einstellungen",
      "description": "Dies sind die Einstellungen für diesen Benutzer und dieses Konto.",
      "personal-info-header": "Ihre Informationen",
      "account-info": {
        "header": "Einstellungen",
        "company-name-header": "Firmenname",
        "current-plan-header": "Aktuelles Abonnement",
        "account-name-header": "Kontoname"
      },
      "verified-emails": {
        "header": "Einstellungen",
        "header-description": "Fügen Sie die E-Mail-Adressen hinzu, an die Dokumente gesendet werden können. In Ihrem Dashboard werden alle Dokumente angezeigt, die an eine dieser E-Mail-Adressen gesendet wurden.",
        "add-email": "Verifizierte E-Mail-Adresse hinzufügen",
        "send-email": "Verifizierungs-E-Mail senden",
        "enter-email": "Geben Sie eine E-Mail-Adresse ein..."
      },
      "account-settings": {
        "header": "Einstellungen",
        "enable-passcode-header": "Passcode für Dokumente erforderlich",
        "enable-passcode-description": "Ein Passcode ist erforderlich, um das Dokument zu signieren. Absender können einen Passcode generieren, wenn das Dokument zur Signatur gesendet wird.",
        "enable-token-expiration-header": "Standardtokenablaufzeit",
        "enable-token-expiration-description": "Legen Sie die Ablaufzeit für alle per E-Mail verschickten Links für Dokumente fest, einschließlich Signaturlinks und Freigabelinks.",
        "enable-blue-ink-header": "Signaturen mit blauer Tinte",
        "enable-blue-ink-description": "Alle Signaturen auf Dokumenten, die von diesem Konto gesendet wurden, sind blau. So können Sie ein signiertes Original von einer Fotokopie unterscheiden.",
        "enable-integrations-description": "Erlauben Sie allen Benutzern, die Verbindung mit anderen von Ihnen verwendeten Apps anzuzeigen und festzulegen. Wenn Sie die Option deaktivieren, kann kein Benutzer die Einstellungen für Integrationen anzeigen und die Standardintegrationen aktivieren/deaktivieren. Auf diese Weise können Sie den Zugriff aller Benutzer auf die verfügbaren Integrationen zentral steuern.",
        "enable-signer-download-header": "Signer document download",
        "enable-signer-download-description": "Allow signers to download the original document before signing."
      },
      "mfa-settings": {
        "enable-mfa-header": "Zweistufige Verifizierung",
        "enable-mfa-description": "Mit der zweistufigen Verifizierung können Benutzer eine Telefonnummer für den Empfang eines Codes per SMS oder Sprachnachricht einrichten. Die Benutzer registrieren sich über den Anmeldevorgang.",
        "opt-out-mfa-description": "Standardmäßig ist für alle Benutzer die zweistufige Verifizierung erforderlich. Wenn Sie diese Einstellung deaktivieren möchten, füllen Sie eine Verzichtserklärung aus und ändern Sie die Einstellung.",
        "complete-mfa-waiver": "Verzichtserklärung ausfüllen",
        "opt-out-waiver-title": "Verzicht auf zweistufige Authentifizierung",
        "opt-out-waiver-description": "Citrix empfiehlt nachdrücklich die <a href='https://support.citrix.com/article/CTX277910'>Two-Step Verification</a> (eine Form der Multifaktorauthentifizierung) für den RightSignature-Dienst als zusätzliche Sicherheitsstufe zur Senkung des Risikos unbefugter Zugriffe auf RightSignature-Inhalte.",
        "opt-out-waiver-consent": "Durch Ablehnen der Aktivierung der zweistufigen Verifizierung für dieses RightSignature-Konto bestätigen Sie, dass Sie befugt sind, im Namen der Organisation, die diese Instanz von RightSignature lizenziert hat, Risiken und Verbindlichkeiten der Deaktivierung der zweistufigen Verifizierung zu übernehmen.",
        "opt-out-quit": "Beenden",
        "opt-out-authority": "Ich bin befugt",
        "opt-out-title": "Wählen Sie den oder die Gründe, aus denen der Inhalt dieses RightSignature-Kontos nicht durch die zweistufige Verifizierung geschützt werden soll:",
        "opt-out-reason-first": "In dem RightSignature-Konto werden keine sensiblen oder vertraulichen Daten gespeichert",
        "opt-out-reason-second": "Wir besitzen nicht die technische Fähigkeit zur Nutzung der zweistufigen Verifizierung",
        "opt-out-description": "Durch Klicken auf die Schaltfläche \"Zweistufige Verifizierung ablehnen\" unten bestätige ich, dass ich das Formular in gutem Glauben ausgefüllt habe und alle Risiken und Verbindlichkeiten übernehme, die mit der Nichtverwendung der zweistufigen Verifizierung verbunden sind.",
        "opt-out-decline": "Zweistufige Verifizierung ablehnen",
        "opted-out": "Auf obligatorische Durchsetzung wurde am {{optOutDate}} von {{optOutEmail}} verzichtet. Die zweistufige Verifizierung kann weiterhin durch Klicken auf die obige Umschaltfläche aktiviert bzw. deaktiviert werden.",
        "opted-out-no-context": "Auf obligatorische Durchsetzung wurde verzichtet. Die zweistufige Verifizierung kann weiterhin durch Klicken auf die obige Umschaltfläche aktiviert bzw. deaktiviert werden.",
        "opted-out-toast": "Hierdurch wird bestätigt, dass Sie die obligatorische Verwendung der zweistufigen Verifizierung für Benutzer unter diesem Konto abgelehnt und sich bereit erklärt haben, die damit verbundenen Risiken im Namen Ihrer Organisation zu tragen."
      },
      "personal-settings": {
        "header": "Einstellungen",
        "enable-field-detection-header": "Felderkennung für die Dokumentvorbereitung verwenden",
        "enable-field-detection-description": "Verwenden Sie die automatische Felderkennung beim Vorbereiten von Dokumenten, anstatt Felder manuell zu platzieren.",
        "enable-view-notification-header": "Nach Anzeige von Dokument benachrichtigen",
        "enable-view-notification-description": "Sie erhalten Benachrichtigungen, wenn Unterzeichner ein von Ihnen zur Signatur gesendetes Dokument angezeigt haben.",
        "enable-signature-notification-header": "Nach Signatur von Dokument benachrichtigen",
        "enable-signature-notification-description": "Sie erhalten jedes Mal Benachrichtigungen, wenn ein Unterzeichner ein Dokument unterzeichnet hat, bei dem Sie eine Partei sind. (Sie erhalten auch nach Abschluss des Dokuments eine Benachrichtigung.)",
        "enable-document-complete-notification-header": "Nach Abschluss von Dokument benachrichtigen",
        "enable-document-complete-notification-description": "Benachrichtigungen werden an Sie gesendet, wenn ein Dokument, an dem Sie beteiligt sind, fertig ist.",
        "enable-contact-searching-header": "Kontaktsuche",
        "enable-contact-searching-description": "Wenn Sie beim Hinzufügen von Unterzeichnern einen Namen eingeben, schlägt die Kontaktsuche Kontakte vor, und zwar basierend auf zuvor gesendeten Dokumenten zusammen mit aktivierten Kontaktintegrationen.",
        "default-expiration-setting-header": "Standardablaufzeit",
        "default-expiration-setting-description": "Legen Sie die Standardanzahl von Tagen für den Ablauf von Dokumenten fest. Sie können die Einstellung pro Dokument ändern, wenn Sie ein neues Dokument senden.",
        "enable-send-reminder-email-header": "Erinnerungs-E-Mails senden",
        "enable-send-reminder-email-description": "Wenn eine Signatur aussteht, den Empfängern des Dokuments wöchentlich (am 3., 7., 14., 21., 28. Tag) oder täglich (ab dem 7. bis 30. Tag) eine E-Mail senden.",
        "enable-attach-completed-pdf-header": "Fertige PDF an E-Mails anfügen",
        "enable-attach-completed-pdf-description": "Die fertige PDF-Datei wird an die Abschlussbenachrichtigung angefügt, die an alle am Dokument beteiligten Personen gesendet wird. PDFs, die größer als 10 MB sind, werden nicht als Anlage angefügt.",
        "default-signer-setting-header": "Absender = Unterzeichner",
        "default-signer-setting-description": "Absender standardmäßig Rolle eines Unterzeichners in allen Dokumenten zuweisen. Sie können dies auch pro Dokument ändern, wenn Sie ein neues Dokument senden.",
        "signature-types-settings-header": "Zulässige Signaturtypen",
        "signature-types-settings-description": "Wählen Sie die Signaturtypen aus, die Sie von Unterzeichnern für Ihre Dokumente akzeptieren.",
        "signature-type-drawn": "Gezeichnet",
        "signature-type-typed": "Getippt"
      },
      "saved-signature-settings": {
        "header": "Einstellungen",
        "no-signature-description": "Keine gespeicherten Signaturen. Wenn Sie ein Dokument signiert haben, werden Ihre Signaturen hier gespeichert.",
        "signature-available-description": "Die Standardsignatur wird verwendet, wenn Sie auf ein Unterschriftenfeld klicken. Bei einem neuen Dokument können Sie Ihre Signatur ändern.",
        "confirm-deletion": "Möchten Sie diese Signatur wirklich löschen?",
        "default": "Standard",
        "success": "Standardsignatur wurde festgelegt.",
        "error": "Fehler beim Festlegen der Standardsignatur."
      }
    },
    "integrations": {
      "header": "Einstellungen",
      "description": "Dies sind die Einstellungen für diesen Benutzer und dieses Konto."
    },
    "branding": {
      "header": "Einstellungen",
      "disabled": {
        "description": "Dies sind die Einstellungen für diesen Benutzer und dieses Konto."
      },
      "signing": {
        "description": "Dies sind die Einstellungen für diesen Benutzer und dieses Konto.",
        "background-color-header": "Hintergrundfarbe:",
        "footer-color-header": "Fußzeilenfarbe:",
        "header-options-header": "Kopfzeilenoptionen:",
        "header-show-logo": "Logo anzeigen",
        "header-no-logo": "Kein Logo",
        "footer-options-header": "Fußzeilenoptionen:",
        "footer-option-send-name-only": "Nur den Namen senden",
        "footer-option-company-name-only": "Nur Firmenname",
        "footer-option-company-name-only-tooltip": "Fügen Sie oben auf der Registerkarte 'Konto' einen Firmennamen hinzu.",
        "footer-document-name": "Name des Dokuments",
        "footer-from": "Von",
        "footer-at": "um",
        "footer-option-sender-and-company-name": "Absender und Firmenname",
        "footer-option-show-sender-avatar": "Absenderavatar anzeigen"
      },
      "email": {
        "header": "Einstellungen",
        "description": "Dies sind die Einstellungen für diesen Benutzer und dieses Konto.",
        "email-header": "E-Mail-Header:",
        "show-sender-from": "Absendername unter 'Von' anzeigen",
        "show-company-subject": "Firmennamen im Betreff anzeigen",
        "show-document-name-in-subject": "Dokument im Betreff verwenden",
        "custom-email-greeting-header": "Benutzerdefinierte E-Mail-Begrüßung:",
        "custom-email-greeting-description": "Dies wird als erster Abschnitt in allen Unterzeichner-E-Mails angezeigt.",
        "custom-email-signature-header": "Benutzerdefinierte E-Mail-Signatur:",
        "custom-email-signature-description": "Dies wird als letzter Abschnitt in allen Unterzeichner-E-Mails angezeigt.",
        "mergefield-help": "Sie können die folgenden Seriendruckfelder verwenden, um Ihrer E-Mail benutzerdefinierte Informationen hinzuzufügen:",
        "signer-name": "signer_name",
        "signer-first-name": "signer_first_name",
        "sender-name": "sender_name",
        "document-name": "document_name",
        "document-filename": "document_filename",
        "document-personalized-message": "document_personalized_message",
        "preview-from": "Von:",
        "preview-subject": "Betreff:",
        "preview-no-logo": "Kein Logo",
        "preview-greeting": "Hallo",
        "preview-message": "hat Ihnen das Dokument '{{filename}}' zum Unterzeichnen gesendet",
        "preview-description": "Folgen Sie dem Link, um das Dokument zu überprüfen und mit einer elektronischen Signatur zu unterzeichnen:",
        "preview-action": "DOKUMENT ÜBERPRÜFEN"
      }
    },
    "users": {
      "trial-account-description": "Ihr aktuelles Abonnement erlaubt Ihnen nicht, Benutzer zu verwalten. Erweitern Sie Ihr Abonnement, um auf dieses Feature zuzugreifen.",
      "admin-section-header": "Benutzer verwalten",
      "admin-section-description": "Dies sind die Benutzer in diesem RightSignature&trade;-Konto. Als Kontoadministrator können Sie Benutzer hinzufügen, entfernen und höherstufen.",
      "non-admin-section-header": "Benutzer",
      "maximum-seats-alert-text": "Sie haben die maximale Anzahl an Benutzern für Ihr Konto erreicht. Bitte entfernen Sie einen Benutzer, wenn Sie einen anderen hinzufügen möchten, oder führen Sie ein Upgrade Ihres Kontos durch, um weitere Sitze hinzuzufügen.",
      "seats-used": "<b>{{seatsUsed}}</b> von <b>{{totalSeats}}</b> Sitzen verwendet",
      "unknown-user": "(unbekannter Benutzer)",
      "add-user-button-text": "Benutzer hinzufügen",
      "edit-user-button-text": "Benutzer bearbeiten",
      "span-text-me": "ICH",
      "span-text-owner": "BESITZER",
      "span-text-admin": "ADMIN",
      "add": "Hinzufügen",
      "account-users-new_introduction": "Geben Sie den Namen und die E-Mail-Adresse der Person ein, die Sie diesem Konto hinzufügen möchten.",
      "first-name": "Vorname...",
      "last-name": "Nachname..."
    },
    "bills": {
      "header": "Einstellungen",
      "contact": "Um RightSignature-Abrechnungsbelege anzuzeigen und/oder zu drucken, klicken Sie unten auf die entsprechende Downloadschaltfläche. Wenn Sie weitere Fragen haben, <a href='https://www.citrix.com/contact/technical-support.html'>rufen Sie uns an</a> oder <a href='https://support.citrix.com/cscase'>kontaktieren Sie uns</a>.",
      "date": "Datum",
      "type": "Typ",
      "status": "Status"
    },
    "api": {
      "header": "Einstellungen",
      "description": "Dies sind die Einstellungen für diesen Benutzer und dieses Konto.",
      "primary-action": "Neuen Schlüssel anfordern"
    }
  };
  _exports.account = account;
});