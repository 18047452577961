define("ember-rs/locales/en/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.application = void 0;
  var application = {
    "logout": "Logout",
    "start": "Start",
    "startDocument": "Start Document",
    "help": "Help"
  };
  _exports.application = application;
});