define("ember-rs/locales/en/templates/components/reminder-frequency-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reminderFrequency = void 0;
  var reminderFrequency = {
    "noAuto": "No auto reminders",
    "daily": "Daily",
    "weekly": "Weekly"
  };
  _exports.reminderFrequency = reminderFrequency;
});