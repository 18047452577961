define("ember-rs/locales/en/templates/components/unconfirmed-user-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedUserButton = void 0;
  var unconfirmedUserButton = {
    "emailUnverified": "Your email address is unverified",
    "verifySendDocument": "Before you can send your first document, we need to verify your email address.",
    "verifyCreateTemplate": "Before you can create your first template, we need to verify your email address.",
    "verifyUpgrade": "Before you can upgrade your hybris account, we need to verify your email address.",
    "verifyIntegration": "Before you can enable an integration, we need to verify your email address.",
    "verifyEmail": "We sent a verification email to the address below.",
    "checkEmail": "Didn't receive the email? Check your spam folder or",
    "resend": "Resend",
    "verificationMail": "Verification email has been sent. Please check {{{email}}} and follow the instructions."
  };
  _exports.unconfirmedUserButton = unconfirmedUserButton;
});