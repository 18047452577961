define("ember-rs/locales/it/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitlementOptionsAdmin = void 0;
  var entitlementOptionsAdmin = {
    "title": "Ti diamo il benvenuto in RightSignature",
    "hasSeats": "Al momento si dispone dei privilegi di amministratore per questo account. Ciò significa che è possibile concedere l'accesso e le funzionalità PRO a sé stessi e/o ad altri utenti. Fare clic sul pulsante sottostante per concedere l'accesso a sé stessi.",
    "notNow": "Forse in futuro",
    "entitleMe": "Ottieni accesso PRO",
    "noSeats": "Tutti i posti in questo account sono attualmente esauriti. È possibile rimuovere qualcuno, ottenere più posti <a href='https://support.citrix.com/cscase'>contattando il supporto</a> o chiamando il numero <a href='tel:+18009214250'>1-800-921-4250</a>, oppure si può semplicemente procedere senza alcune funzioni.",
    "proceed": "Procedi"
  };
  _exports.entitlementOptionsAdmin = entitlementOptionsAdmin;
});