define("ember-rs/locales/ja/templates/components/component-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentEditor = void 0;
  var componentEditor = {
    "charge": "署名後に請求しますか?",
    "selectOne": "1 つ選択...",
    "amount": "支払い額 (米ドル):",
    "enterAmount": "支払い額の入力...",
    "required": "必須: 以下に署名者用ヘルプの内容を入力して、受信者に何をアップロードすべきかを知らせます。",
    "signerHelp": "署名者用ヘルプの内容:",
    "helpText": "ヘルプの内容を入力...",
    "selectBox": "ボックスを選択するには最低 2 つのオプションが必要です。",
    "selectOptions": "オプションの選択:",
    "addOption": "オプションの追加...",
    "addToGroup": "グループに追加",
    "assignedTo": "割り当て先:",
    "fieldRequired": "必須フィールドですか?",
    "autoFillDate": "Auto-fill date signed",
    "autoFillDateHelpText": "A recipient will not be able to edit the field. It will be filled in automatically with the date on which the recipient signs.",
    "mergeField": "マージ フィールドですか?",
    "mergeFieldName": "マージ フィールドには名前が必要です",
    "nameMergeField": "マージ フィールドの名前:",
    "setFontSize": "フォント サイズの設定...",
    "muiltiline": "複数行ですか?",
    "clearBackground": "背景をクリアしますか?",
    "addDefaultValue": "デフォルト値を追加しますか?",
    "setDefaultValue": "デフォルト値の設定...",
    "defaultValue": "デフォルト値:",
    "nameThisField": "このフィールドに名前を付けますか?",
    "name": "名前:",
    "setName": "コンポーネントの名前の設定...",
    "textAlign": "文字の配置",
    "showLess": "簡易表示",
    "showMore": "詳細表示",
    "delete": "削除",
    "saved": "保存済みです。",
    "save": "保存",
    "dateFormat": "日付の書式",
    "left": "左",
    "center": "中央",
    "right": "右",
    "groupRequirement": "グループの要件",
    "only_one": "1 つのみ",
    "one_or_more": "1 つまたは複数",
    "options_warning": "このオプションはすでに追加されています"
  };
  _exports.componentEditor = componentEditor;
});