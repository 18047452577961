define("ember-rs/locales/it/templates/signer/footer-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerDesktop = void 0;
  var footerDesktop = {
    "from": "Da",
    "submitSig": "Invia firma",
    "submit": "Invia",
    "clickSubmit": "Facendo clic su \"Invia\" si accetta la nostra",
    "privacyPolicy": "Informativa sulla privacy",
    "consent": "Consenso con firma elettronica",
    "contents": "e i contenuti di questo documento.",
    "left": "sinistra",
    "save": "Salva",
    "help": "Serve assistenza?",
    "saveProgress": "Salva avanzamento",
    "savingChanges": "Salvataggio delle modifiche",
    "field": "campo",
    "fields": "campi"
  };
  _exports.footerDesktop = footerDesktop;
});