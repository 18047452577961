define("ember-rs/locales/zh-cn/templates/document/prepare-signers-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_desktop = void 0;
  var prepare_signers_desktop = {
    "assign_roles": "为此文档分配角色:",
    "who_sign": "需要签名的人员 ",
    "sign_order": "设置签名者顺序",
    "add_another": "添加其他签名者",
    "add_yourself": "添加您自己",
    "contact_integtration": "联系人集成",
    "select_integration": "选择集成",
    "who_sign_package": "谁需要在文档包上签名?"
  };
  _exports.prepare_signers_desktop = prepare_signers_desktop;
});