define("ember-rs/routes/reports/export/month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ////////////////////////  HOOKS  ////////////////////////
    model: function model() {
      var accountId = this.get('currentUser.account.id');
      return this.store.query('dashboardReusableTemplate', {
        account_id: accountId,
        per_page: 75
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('templateIdSelected', this.paramsFor('reports.export.month.template').template_id);
      return this._super(controller, model);
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      templateWasSelected: function templateWasSelected(selection) {
        if (selection) {
          this.transitionTo('reports.export.month.template', selection);
        } else {
          this.transitionTo('reports.export.month');
        }
      }
    }
  });

  _exports.default = _default;
});