define("ember-rs/components/salesforce-opportunity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['salesforce-opportunity-result'],
    formattedName: Ember.computed('opportunity', function () {
      var o = this.opportunity;
      return "".concat(o.Name, "-").concat(o.Id);
    }),
    actions: {
      addSalesforceOpportunity: function addSalesforceOpportunity() {
        this.sendAction('addSalesforceOpportunity', this.opportunity);
      }
    }
  });

  _exports.default = _default;
});