define("ember-rs/templates/toolbar-icons/check-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MEZLwAzc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"12px\"],[14,\"height\",\"12px\"],[14,\"viewBox\",\"0 0 12 12\"],[14,\"enable-background\",\"new 0 0 12 12\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[2,\"\\n\"],[10,\"g\"],[12],[2,\"\\n\\t\"],[10,\"g\"],[12],[2,\"\\n\\n\\t\\t\\t\"],[10,\"rect\"],[14,\"x\",\"6.196\"],[14,\"y\",\"3.571\"],[14,\"transform\",\"matrix(0.7071 0.7071 -0.7071 0.7071 6.2436 -2.9611)\"],[14,\"width\",\"1\"],[14,\"height\",\"4.97\"],[12],[13],[2,\"\\n\\n\\t\\t\\t\"],[10,\"rect\"],[14,\"x\",\"3.112\"],[14,\"y\",\"6.263\"],[14,\"transform\",\"matrix(-0.7071 -0.7071 0.7071 -0.7071 3.0644 14.796)\"],[14,\"width\",\"2.969\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"path\"],[14,\"d\",\"M8,1c1.654,0,3,1.346,3,3v4c0,1.654-1.346,3-3,3H4c-1.654,0-3-1.346-3-3V4c0-1.654,1.346-3,3-3H8 M8,0H4\\n\\t\\tC1.8,0,0,1.8,0,4v4c0,2.2,1.8,4,4,4h4c2.2,0,4-1.8,4-4V4C12,1.8,10.2,0,8,0L8,0z\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/toolbar-icons/check-component.hbs"
    }
  });

  _exports.default = _default;
});