define("ember-rs/locales/es/templates/unauthorized/document-executed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExecuted = void 0;
  var documentExecuted = {
    "docComplete": "Este documento ha sido completado",
    "toDownload": "Para descargar este documento solicite un nuevo enlace de descarga.",
    "sendLink": "Enviar nuevo enlace de descarga"
  };
  _exports.documentExecuted = documentExecuted;
});