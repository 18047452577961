define("ember-rs/locales/it/templates/reports", ["exports", "ember-rs/locales/it/templates/reports/export"], function (_exports, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    export: _export.exportTrans,
    "header": "Report",
    "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
    "select-report-type--text": "Seleziona il tipo di report",
    "select-report-type-option-overview": "Panoramica",
    "overviewReport": "Report generale",
    "select-report-type-option-efficiency": "Efficienza",
    "efficiencyReport": "Report sull'efficienza",
    "select-report-type-option-cycle-time": "Report sul tempo di ciclo",
    "select-report-type-option-export": "Esportazione dati",
    "select-report-type-option-enterprise": "Enterprise",
    "enterpriseUsageReport": "Report sull'utilizzo aziendale",
    "userUsageReport": "Report sull'utilizzo degli utenti",
    "select-report-type-option-user": "Nuovo report sull'utilizzo",
    "components": {
      "account-document-volume": {
        "header": "Report",
        "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
        "legend-document-sent": "Documento inviato",
        "legend-document-signed": "Documento firmato",
        "legend": "Legenda:"
      },
      "account-statistics": {
        "header": "Report",
        "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
        "column-header-current-month": "Questo mese",
        "column-header-previous-month": "Il mese scorso",
        "column-header-average": "Media",
        "row-header-sent-documents": "Documenti inviati",
        "row-header-viewed": "Visualizzato (da almeno un firmatario)",
        "row-header-expired": "Scaduto",
        "row-header-signed": "Firmato",
        "row-header-sent-documents-per-user": "Documenti inviati per utente (in media)",
        "row-header-signed-documents": "Documenti firmati",
        "row-header-pending-documents": "Documenti in sospeso",
        "row-header-viewed-pending": "Visualizzati (di documenti sospesi)",
        "none": "N/A"
      },
      "top-users-by-signed-documents": {
        "header": "Report",
        "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
        "column-header-user": "Utente",
        "column-header-signed-documents": "Documenti firmati"
      },
      "account-efficiency": {
        "header": "Report",
        "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
        "legend-pending": "In sospeso",
        "legend-signed": "Firmato",
        "legend-expired": "Scaduto"
      },
      "top-users-by-efficiency": {
        "header": "Report",
        "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
        "column-header-user": "Utente",
        "column-header-efficiency-percentage": "% di efficienza",
        "column-header-ratio": "Rapporto",
        "over-gcd": "{{signed}} di {{sent}}"
      },
      "top-users-by-time-to-sign": {
        "header": "Report",
        "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
        "column-header-user": "Utente",
        "column-header-mean-time": "Tempo medio (ore)"
      },
      "average-cycle-time": {
        "header": "Report",
        "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
        "legend-mean-time-to-view": "Tempo medio per visualizzare",
        "legend-mean-time-to-sign": "Tempo medio per firmare"
      },
      "cycle-time-statistics": {
        "header": "Report",
        "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
        "column-header-current-month": "Questo mese",
        "column-header-previous-month": "Il mese scorso",
        "column-header-all-time": "Tutto il tempo",
        "row-header-under-5-mins": "Meno di 5 minuti",
        "row-header-under-30-mins": "Meno di 30 minuti",
        "row-header-under-1-hour": "Meno di 1 ora",
        "row-header-under-12-hours": "Meno di 12 ore",
        "row-header-under-1-day": "Meno di 1 giorno"
      },
      "month-export": {
        "header": "Report",
        "description": "Consente di visualizzare i dati per i documenti inviati. Questi comprendono il tempo medio necessario per far firmare i documenti e il numero di documenti completati ogni mese. Aggiornare il piano per accedere ai report.",
        "export-to-csv-section-header": "Esporta in formato CSV",
        "export-to-csv-section-description": "È possibile aprire un file CSV in Excel per visualizzare i dati nei documenti inviati."
      },
      "generate-download": {
        "ie-warning-text": "Fare clic con il pulsante destro del mouse su \"Esporta CSV\" e scegliere Salva con nome per scaricare il file CSV.",
        "export-csv-button-text": "Esporta CSV",
        "generate-csv-button-text": "Genera CSV",
        "send-download-link": "Genereremo il report e invieremo il collegamento per il download via e-mail."
      }
    }
  };
  _exports.reports = reports;
});