define("ember-rs/locales/de/templates/document/search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header = void 0;
  var search_header = {
    "from": "Von: ",
    "state": "Status: ",
    "search_docs": "Dokumente suchen...",
    "all": " Alle",
    "pending": " Ausstehend",
    "declined": " Abgelehnt",
    "executed": " Fertig",
    "expired": " Abgelaufen",
    "voided": " Storniert",
    "admin_view": "Administratoransicht",
    "tool_tip": "Alle Dokumente anzeigen, die von diesem Konto gesendet wurden.",
    "filter": "Filter"
  };
  _exports.search_header = search_header;
});