define("ember-rs/locales/en/controllers/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "standardInfo": "The plan you have selected only supports 1 user. If you need more users on your account, choose the Advanced plan.",
    "migratedInfo": "Choose at least {{{minimumSeats}}} users since your trial account already uses up {{{minimumSeats}}} seats. Else, contact support for further assistance in upgrading",
    "planInfo": "The plan you have selected includes 3 users. Choose more below if needed.",
    "validUsers": "Please enter a valid number of user"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});