define("ember-rs/templates/components/view-document-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qsaNX7Y+",
    "block": "{\"symbols\":[\"component\",\"component\"],\"statements\":[[10,\"div\"],[14,0,\"page\"],[15,\"data-page-number\",[34,4]],[15,5,[30,[36,6],[[35,5]],null]],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"page-image\"],[15,\"src\",[34,7,[\"original\"]]],[12],[13],[2,\"\\n\\n\"],[6,[37,10],[[30,[36,9],[[35,8],\"certificate\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"show-component\",[],[[\"@component\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"flattened-component\",[],[[\"@component\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"componentsToFill\",\"-track-array\",\"each\",\"flattenedComponents\",\"pageNumber\",\"pageCssStyle\",\"safe\",\"pageImage\",\"pageType\",\"eq\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/view-document-page.hbs"
    }
  });

  _exports.default = _default;
});