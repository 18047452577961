define("ember-rs/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hoHhreJ7",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[16,0,[31,[\"component-item \",[34,1],\" theme__toolbar__button\"]]],[4,[38,2],[[32,0],\"selectComponentClass\"],null],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"theme__toolbar__button__icon\"],[12],[2,\"\\n      \"],[1,[30,[36,4],[[35,3]],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"theme__toolbar__button__text\"],[12],[1,[34,5]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"theme__toolbar__button__help\"],[12],[2,\"\\n    \"],[8,\"rs-tooltip\",[],[[\"@message\",\"@isPlacedOnLeft\"],[[34,0],true]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toolTooltip\",\"componentClass\",\"action\",\"toolIcon\",\"inline-svg\",\"classTitle\",\"isSourceMapped\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/component-toolbar-menuitem.hbs"
    }
  });

  _exports.default = _default;
});