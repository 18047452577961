define("ember-rs/services/signature-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setDocumentId: function setDocumentId(docId) {
      this.set('documentId', docId);
    },
    getDocumentId: function getDocumentId() {
      return this.documentId;
    },
    hasDocumentId: function hasDocumentId() {
      return Ember.isPresent(this.getDocumentId());
    },
    setSignerId: function setSignerId(signerId) {
      this.set('signerId', signerId);
    },
    getSignerId: function getSignerId() {
      return this.signerId;
    },
    hasSignerId: function hasSignerId() {
      return Ember.isPresent(this.getSignerId());
    },
    setIdentityToken: function setIdentityToken(token) {
      this.set('identityToken', token);
    },
    getIdentityToken: function getIdentityToken() {
      return this.identityToken;
    },
    setAccessToken: function setAccessToken(token) {
      this.set('accessToken', token);
    },
    getAccessToken: function getAccessToken() {
      return this.accessToken;
    }
  });

  _exports.default = _default;
});