define("ember-rs/locales/de/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceCompleteDocumentModal = void 0;
  var forceCompleteDocumentModal = {
    "header": "Abschluss von Dokument erzwingen",
    "forceCompleted": "Dadurch werden alle Felder entfernt, die Unterzeichnern zugewiesen sind, die das Dokument noch nicht signiert haben, und das Dokument erhält den Endstatus 'unterzeichnet'. Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "briefMoment": "Es kann einen Moment dauern, bis der neue Status im Dashboard angezeigt wird.",
    "forceComplete": "Abschluss erzwingen",
    "cancel": "Abbrechen",
    "success": "Der Abschluss des Dokuments wurde erzwungen.",
    "failed": "Der Abschluss des Dokuments konnte nicht erzwungen werden"
  };
  _exports.forceCompleteDocumentModal = forceCompleteDocumentModal;
});