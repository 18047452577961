define("ember-rs/locales/en/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding1 = void 0;
  var accountOnboarding1 = {
    "body": "Change <span>your settings</span>, <span>custom brand</span> your account, <span>integrate</span> with other tools and more."
  };
  _exports.accountOnboarding1 = accountOnboarding1;
});