define("ember-rs/locales/de/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newClientApplication = void 0;
  var newClientApplication = {
    "appReview": "Ihr Antrag wird geprüft. Wir werden Sie innerhalb von 24 Stunden benachrichtigen oder Ihre Anfrage genehmigen.",
    "secretsSuccess": "Abgerufene Geheimnisse sollten nur von Ihnen oder Personen Ihres Vertrauens verwendet werden.",
    "secretsError": "Geheimnisse konnten nicht abgerufen werden: {{{error}}}",
    "tokenSuccess": "Erfolgreich neues privates API Token generiert",
    "tokenError": "Token konnte nicht generiert werden: {{{error}}}",
    "singleSecretSuccess": "Geheimnis für {{{clientAppName}}} abgerufen. Es sollte nur von Ihnen oder Personen Ihres Vertrauens verwendet werden.",
    "singleSecretError": "Geheimnis konnte nicht abgerufen werden: {{{error}}}",
    "tokenRetrieveSuccess": "Privaten API-Token für {{{clientAppName}}} abgerufen. Er sollte nur von Ihnen oder Personen Ihres Vertrauens verwendet werden.",
    "tokenRetrieveError": "Privater API-Token konnte nicht abgerufen werden: {{{error}}}",
    "tokenRegenerateError": "Token konnte nicht erneut generiert werden: {{{error}}}",
    "confirmInfo": "Sind Sie sicher? Alternativ können Sie einen neuen API-Schlüssel anfordern, da überschriebene private API-Token nach der Neuerstellung nicht mehr wiederhergestellt werden können. Wenn Sie fortfahren möchten, müssen Sie veraltete Anmeldeinformationen in all Ihren Integrationen entfernen und ersetzen."
  };
  _exports.newClientApplication = newClientApplication;
});