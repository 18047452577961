define("ember-rs/locales/es/templates/components/document-overlayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentOverlayer = void 0;
  var documentOverlayer = {
    "prepare": "Preparar documento",
    "tools": "herramientas avanzadas: ",
    "importOverlay": "Importar superposición",
    "toolTip": "Seleccione un documento enviado previamente para colocar esos campos sobre este documento en la misma ubicación. Nota: el documento debe tener el mismo nombre.",
    "fieldDetection": "Detección de campos",
    "fieldDetectionTip": "La detección de campos le ayuda a alinear los campos con mayor precisión a las líneas y cuadros de su documento.",
    "roles": "roles",
    "rolesTip": "Vea los roles de este documento. Cada rol tiene su propio color, que corresponde a los campos que tiene que completar.",
    "signers": "firmantes",
    "signersTip": "Vea los firmantes de este documento. Cada firmante tiene su propio color, que corresponde a los campos que tiene que completar.",
    "attachmentUnavailable": "Los datos adjuntos de correo no están disponibles en la cuenta seleccionada. Actualice al plan Professional para solicitar los datos adjuntos de correo de los firmantes.",
    "paymentUnavailable": "Los pagos no están disponibles en la cuenta seleccionada. Actualice al plan Professional para solicitar los pagos de los firmantes.",
    "paymentNotEnabled": "Por favor, habilite una integración de pago antes de usar los Componentes de pago. Puede hacerlo en la sección Integraciones en los Parámetros de cuenta.",
    "noRoles": "No se encontró ningún rol. ¿Tal vez haya vuelto a cargar sin guardar?"
  };
  _exports.documentOverlayer = documentOverlayer;
});