define("ember-rs/mixins/trial-methods", ["exports", "ember-rs/mixins/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_component.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    allowedRoutes: ["upgrade-worldpay.index", "cc-gateway-redirect.index"],
    trialDaysRemaining: Ember.computed('currentUser.model.account.hybrisTrialExpiresAt', function () {
      var daysRemaining = Math.ceil(((0, _moment.default)(Ember.get(this, 'currentUser.model.account.hybrisTrialExpiresAt')) - (0, _moment.default)()) / (1000 * 60 * 60 * 24));
      return daysRemaining < 0 ? 0 : daysRemaining;
    }),
    documentsLimit: Ember.computed.alias('currentUser.account.accountStatSet.documentLimit')
  });

  _exports.default = _default;
});