define("ember-rs/routes/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      controller.set('router', this.router);
      return this._super(controller, model);
    }
  });

  _exports.default = _default;
});