define("ember-rs/locales/ja/templates/components/account-user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "basic": "基本情報",
    "firstName": "名:",
    "lastName": "姓:",
    "required": "*必須",
    "email": "メールアドレス:",
    "emailTip": "*有効なメールアドレスを指定してください。",
    "password": "パスワード:",
    "hiddenForSecurity": "セキュリティのため非表示",
    "reset": "リセット",
    "emailInfo": "メールのログイン情報",
    "oldPass": "古いパスワード:",
    "newPass": "新しいパスワード:",
    "passReq": "*パスワードには、8 個以上の文字、 1 個以上の数字、および大文字と小文字を 1 つずつ含める必要があります。",
    "permissions": "権限",
    "manageUsers": "ユーザーの管理",
    "manageDelegateUsers": "ユーザーに委任できます",
    "manageViewBills": "請求書を表示できます",
    "customizeBranding": "アカウントのブランド設定をカスタマイズ",
    "manageTemplates": "テンプレートの管理",
    "viewDocuments": "すべてのドキュメントを表示",
    "removeUser": "ユーザーの削除",
    "reset2fa": "2 段階認証をリセットする"
  };
  _exports.edit = edit;
});