define("ember-rs/locales/en/templates/components/document-overlayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentOverlayer = void 0;
  var documentOverlayer = {
    "prepare": "Prepare Document",
    "tools": "advanced tools: ",
    "importOverlay": "Import overlay",
    "toolTip": "Select a document previously sent out to place those fields over this document in the same location. Note: The document needs to have the same name.",
    "fieldDetection": "Field detection",
    "fieldDetectionTip": "Field detection helps you align fields more accurately to lines and boxes in your document.",
    "roles": "roles",
    "rolesTip": "See the roles for this document. Each role has their own color, which correspond to the fields they need to complete.",
    "signers": "signers",
    "signersTip": "See the signers for this document. Each signer has their own color, which correspond to the fields they need to complete.",
    "attachmentUnavailable": "Attachments are not available on the account selected. Please upgrade to a pro plan to request attachments from signers.",
    "paymentUnavailable": "Payments are not available on the account selected. Please upgrade to a pro plan to collect payments from signers.",
    "paymentNotEnabled": "Please enable a payments integration before using payment components. You can do this in the Integrations section in your Account Settings.",
    "noRoles": "No roles found! Probably reloaded without saving?"
  };
  _exports.documentOverlayer = documentOverlayer;
});