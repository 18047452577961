define("ember-rs/locales/it/controllers/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "success": "Invia di nuovo il collegamento per la firma",
    "failure": "Impossibile inviare il collegamento: {{{response}}}"
  };
  _exports.identityExpired = identityExpired;
});