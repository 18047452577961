define("ember-rs/routes/account/users/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('accountUser', params.userId);
    },
    actions: {
      deleteUser: function deleteUser() {
        var _this = this;

        this.controller.set('loading', true);
        this.modelFor('account.users.delete').destroyRecord().then(function () {
          _this.flashMessages.success(_this.i18n.t('components.account-users.delete.delete-success-message'));

          _this.controller.set('loading', false);

          _this.modelFor('account').belongsTo('accountStatSet').reload();

          _this.send('saveAndCloseModal');
        }, function () {
          _this.flashMessages.warning(_this.i18n.t('components.account-users.delete.delete-warning-message'));

          _this.controller.set('loading', false);

          _this.send('closeModal');
        });
      },
      saveAndCloseModal: function saveAndCloseModal() {
        this.transitionTo('account.users');
      },
      closeModal: function closeModal(model) {
        this.transitionTo('account.users.edit', model.get('id'));
      }
    }
  });

  _exports.default = _default;
});