define("ember-rs/locales/fr/templates/components/main-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mainFooter = void 0;
  var mainFooter = {
    "Support": "Assistance",
    "privacy": "Politique de confidentialité",
    "copyright": "Citrix Systems, Inc. Tous droits réservés."
  };
  _exports.mainFooter = mainFooter;
});