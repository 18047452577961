define("ember-rs/templates/components/bill-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RbcnMLPI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[14,0,\"billing date\"],[12],[1,[30,[36,2],[[35,0,[\"createdDate\"]]],null]],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"billing memo\"],[12],[1,[35,0,[\"memo\"]]],[13],[2,\"\\n\"],[10,\"td\"],[15,0,[31,[\"status \",[34,0,[\"status\"]]]]],[12],[1,[35,0,[\"statusForDisplay\"]]],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"loading-spinner\",[],[[\"@size\"],[12]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"a\"],[4,[38,1],[[32,0],\"downloadBill\",[35,0,[\"id\"]],[35,0,[\"rs3\"]]],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-download\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"bill\",\"action\",\"rs-date\",\"loading\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/bill-row.hbs"
    }
  });

  _exports.default = _default;
});