define("ember-rs/locales/ja/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingComplete = void 0;
  var signingComplete = {
    "docSigned": "ドキュメントが署名されました。",
    "createTrial": "この完了したドキュメントを表示またはダウンロードするには、無料の RightSignature アカウントを作成します。ドキュメントを送信したメールアドレスで登録してログインするだけで、ドキュメントに永続的にアクセスできます。",
    "createAccount": "アカウントの作成",
    "login": "アカウントにログイン",
    "notNow": "後で変更"
  };
  _exports.signingComplete = signingComplete;
});