define("ember-rs/locales/fr/templates/unauthorized/redirect-to-rs3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectToRs3 = void 0;
  var redirectToRs3 = {
    "wrongVersion": "Version de RightSignature incorrecte",
    "classic": "Vous avez essayé de vous connecter à RightSignature ShareFile Edition. Cependant, votre compte vous donne accès à Classic RightSignature.",
    "go": "Aller à RightSignature"
  };
  _exports.redirectToRs3 = redirectToRs3;
});