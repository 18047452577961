define("ember-rs/routes/user/settings/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    ////////////////////////  HOOKS  ////////////////////////
    model: function model() {
      return this.store.findRecord('accountUser', Ember.get(this, 'currentUser.model.id'));
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      closeModal: function closeModal() {
        return this.transitionTo('user.settings');
      }
    }
  });

  _exports.default = _default;
});