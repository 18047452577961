define("ember-rs/locales/es/controllers/document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "notAllowed": "No tiene permiso para hacer eso.",
    "voided": "Documento anulado correctamente.",
    "voidingFailed": "Error al anular el documento.",
    "docExtended": "Documento prolongado correctamente.",
    "tagUpdated": "Etiquetas actualizadas correctamente",
    "tagUpdateFailed": "Error al actualizar las etiquetas: {{{tags}}} ",
    "viewerListUpdated": "Lista de participantes actualizada correctamente",
    "viewerUpdateFailed": "Error al actualizar la lista de participantes: {{{viewers}}}"
  };
  _exports.details = details;
});