define("ember-rs/locales/nl/templates/components/payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentForm = void 0;
  var paymentForm = {
    "name": "Naam",
    "firstName": "Voornaam...",
    "lastName": "Achternaam...",
    "address1": "Adresregel 1...",
    "address2": "Adresregel 2...",
    "city": "Plaats",
    "state": "Provincie",
    "zip": "Postcode",
    "country": "Land",
    "address": "Adres",
    "vatId": "BTW-nummer",
    "redirect": "Omleiden...",
    "upgrade": "Upgrade",
    "payUpgrade": "Betalen en upgraden",
    "oops": "Er is iets fout gegaan",
    "ok": "OK"
  };
  _exports.paymentForm = paymentForm;
});