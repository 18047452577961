define("ember-rs/locales/zh-cn/templates/components/void-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.voidDocumentModal = void 0;
  var voidDocumentModal = {
    "header": "Void Document Confirmation",
    "voidConfirmationInfo": "Optional accompanying comment",
    "optionalVoidMessage": "",
    "void": "Confirm",
    "cancel": "Cancel"
  };
  _exports.voidDocumentModal = voidDocumentModal;
});