define("ember-rs/locales/ja/templates/components/signature-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signatureField = void 0;
  var signatureField = {
    "rotateToSign": "回転して署名する。",
    "rotateTip": "デバイス画面の回転のロック解除が必要な場合があります。",
    "clear": "クリア",
    "signInstn": "クリックおよびドラッグして署名を手書き入力",
    "typeUrSign": "署名を入力する",
    "typeSign": "署名を入力する",
    "drawSign": "署名を手書き入力する",
    "or": "または",
    "savedSign": "保存済みの署名を使用する",
    "apply": "適用"
  };
  _exports.signatureField = signatureField;
});