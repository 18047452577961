define("ember-rs/locales/de/templates/components/documents/document-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentRow = void 0;
  var documentRow = {
    "signed": "signiert",
    "signer": "Unterzeichner",
    "legacy": " - Legacy-Dok.",
    "awaitingSignature": "Ihre Unterschrift ist erforderlich",
    "sign": "Signieren",
    "completed": "Fertig ",
    "sent": "Gesendet",
    "voided": "Storniert",
    "expired": "Abgelaufen ",
    "auth_failure": "Fehler beim Authentifizieren des Unterzeichners in der Sitzung: {{{authError}}}"
  };
  _exports.documentRow = documentRow;
});