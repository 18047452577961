define("ember-rs/locales/es/templates/components/document-upload-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploadThumbnail = void 0;
  var documentUploadThumbnail = {
    "noThumb": "Sin miniatura...",
    "upload_done": "carga finalizada",
    "downloading": "descargando",
    "storing": "almacenando",
    "converting_to_images": "convirtiendo a imágenes",
    "converting_to_pdf": "convirtiendo a pdf",
    "processing": "procesando",
    "created": "creado"
  };
  _exports.documentUploadThumbnail = documentUploadThumbnail;
});