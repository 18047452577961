define("ember-rs/locales/de/templates/signer/passcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcode = void 0;
  var passcode = {
    "enterPin": "Geben Sie die PIN ein.",
    "access": "Geben Sie die PIN für den Zugriff auf das Dokument ein.",
    "unlock": "Entsperren",
    "docProtected": "Dieses Dokument ist durch einen Passcode geschützt.",
    "answer": "Antwort...",
    "show": "Passcode anzeigen",
    "continue": "Weiter zum Dokument",
    "answerQuestion": "Beantworten Sie die folgende Sicherheitsfrage, um auf das Dokument zuzugreifen"
  };
  _exports.passcode = passcode;
});