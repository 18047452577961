define("ember-rs/locales/nl/templates/components/import-overlay-role-map-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlayRoleMapSelect = void 0;
  var importOverlayRoleMapSelect = {
    "assignComponents": "Assign {{signerName}} ({{name}}) components to:"
  };
  _exports.importOverlayRoleMapSelect = importOverlayRoleMapSelect;
});