define("ember-rs/locales/pt-br/templates/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choose_documents = void 0;
  var choose_documents = {
    "drag_drop": "Arraste e solte arquivos aqui ou:",
    "choose_computer": "Escolher no computador",
    "choose_template": "Escolha o modelo que deseja usar para envio em massa",
    "use_template": "Usar um modelo",
    "other_source": "Outras fontes",
    "from_cloud": "da nuvem",
    "template": "modelo",
    "add_another": "Adicionar outro arquivo",
    "prepare_document": "Preparar documento",
    "prepare_documents": "Preparar documentos",
    "upload_signers": "Carregar signatários",
    "drag_drop_csv": "Arraste e solte seu arquivo CSV:",
    "select_file_computer": "Selecione um arquivo do seu computador",
    "remove_file": "Remover",
    "upload_success": "Arquivo carregado com sucesso.",
    "upload_multifile": "Não há suporte para upload de vários arquivos. Faça upload de apenas um arquivo.",
    "upload_invalid_extension": "O arquivo {{{fileName}}} tem uma extensão inválida.",
    "upload_size_limit": "O arquivo {{{fileName}}} é maior que o nosso limite de 5MB.",
    "send_package": "Enviar pacote de documentos",
    "file_size_exceeded": "O tamanho total do arquivo não pode exceder {{{maxFileSize}}} MB. Remova um ou mais documentos para continuar.",
    "max_files_exceeded": "Você pode preparar apenas {{{maxFileCount}}} documentos de uma só vez.",
    "min_files_required": "Um pacote requer pelo menos dois documentos.",
    "preparing_package": "Estamos preparando seu documento. Isso pode levar alguns segundos.",
    "merge_upload_error": "Ocorreu um problema ao preparar o documento. Remova e recarregue seus documentos."
  };
  _exports.choose_documents = choose_documents;
});