define("ember-rs/locales/nl/templates/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choose_documents = void 0;
  var choose_documents = {
    "drag_drop": "Bestanden hier slepen en neerzetten of:",
    "choose_computer": "Kiezen van computer",
    "choose_template": "Kies de sjabloon die u wilt gebruiken voor bulkverzending",
    "use_template": "Een sjabloon gebruiken",
    "other_source": "Andere bronnen",
    "from_cloud": "uit de cloud",
    "template": "sjabloon",
    "add_another": "Nog een bestand toevoegen",
    "prepare_document": "Document voorbereiden",
    "prepare_documents": "Document voorbereiden",
    "upload_signers": "Ondertekenaars uploaden",
    "drag_drop_csv": "CSV-bestand slepen en neerzetten:",
    "select_file_computer": "Een bestand op uw computer selecteren",
    "remove_file": "Verwijderen",
    "upload_success": "Bestand is geüpload.",
    "upload_multifile": "Het uploaden van meerdere bestanden wordt niet ondersteund. Upload slechts één bestand.",
    "upload_invalid_extension": "Het bestand {{{fileName}}} heeft een ongeldige extensie.",
    "upload_size_limit": "Het bestand {{{fileName}}} is groter dan de limiet van 5 Mb.",
    "send_package": "Documentenpakket verzenden",
    "file_size_exceeded": "De totale bestandsgrootte mag niet groter zijn dan {{{maxFileSize}}} MB. Verwijder een of meer documenten om door te gaan.",
    "max_files_exceeded": "U kunt slechts {{{maxFileCount}}} documenten tegelijk voorbereiden.",
    "min_files_required": "Een pakket vereist minstens twee documenten.",
    "preparing_package": "Uw document wordt voorbereid. Dit kan een paar seconden duren.",
    "merge_upload_error": "Er is een probleem opgetreden bij het voorbereiden van uw document. Probeer het opnieuw door uw documenten te verwijderen en opnieuw te uploaden."
  };
  _exports.choose_documents = choose_documents;
});