define("ember-rs/locales/es/templates/templates", ["exports", "ember-rs/locales/es/templates/templates/desktop-header", "ember-rs/locales/es/templates/templates/index"], function (_exports, _desktopHeader, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.templates = void 0;
  var templates = {
    desktopHeader: _desktopHeader.desktopHeader,
    index: _index.index
  };
  _exports.templates = templates;
});