define("ember-rs/locales/zh-cn/templates/signer/confirm-identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentity = void 0;
  var confirmIdentity = {
    "confirmed": "已成功确认身份。",
    "submitted": "您的答复已提交。"
  };
  _exports.confirmIdentity = confirmIdentity;
});