define("ember-rs/templates/components/search-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IwwEwG4G",
    "block": "{\"symbols\":[],\"statements\":[[8,\"limited-input-text-field\",[],[[\"@type\",\"@class\",\"@value\",\"@placeholder\",\"@autofocus\",\"@input\",\"@maxlength\"],[\"text\",\"search\",[34,0],[34,1],\"autofocus\",[30,[36,2],[[32,0],\"search\"],null],80]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"query\",\"placeholder\",\"action\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/search-field.hbs"
    }
  });

  _exports.default = _default;
});