define("ember-rs/templates/components/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "euCymgJI",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[15,\"src\",[34,0]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"img\"],[14,\"src\",\"/assets/images/default-avatar.svg\"],[14,\"alt\",\"\"],[14,0,\"default\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"avatarUrl\",\"hasAvatar\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/user-avatar.hbs"
    }
  });

  _exports.default = _default;
});