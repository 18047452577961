define("ember-rs/templates/toolbar-icons/initials-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vFjSCpPm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"13.366px\"],[14,\"height\",\"6.481px\"],[14,\"viewBox\",\"0 0 13.366 6.481\"],[14,\"enable-background\",\"new 0 0 13.366 6.481\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[2,\"\\n\"],[10,\"g\"],[12],[2,\"\\n\\t\"],[10,\"g\"],[12],[2,\"\\n\\t\\t\"],[10,\"path\"],[14,\"d\",\"M4.042,6.392L2.494,4.195H1.107v2.196H0V0.09h2.809c1.449,0,2.341,0.765,2.341,1.998\\n\\t\\t\\tc0,1.045-0.622,1.675-1.503,1.918l1.701,2.386H4.042z M2.719,1.098H1.107v2.116h1.621c0.792,0,1.296-0.414,1.296-1.062\\n\\t\\t\\tC4.024,1.458,3.538,1.098,2.719,1.098z\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"path\"],[14,\"d\",\"M5.535,6.392V5.158h1.197v1.233H5.535z\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"path\"],[14,\"d\",\"M9.811,2.736c1.332,0.324,2.025,0.802,2.025,1.854c0,1.188-0.928,1.891-2.251,1.891\\n\\t\\t\\tc-0.963,0-1.872-0.333-2.629-1.008l0.667-0.793C8.226,5.203,8.83,5.5,9.613,5.5c0.685,0,1.116-0.314,1.116-0.801\\n\\t\\t\\tc0-0.459-0.252-0.702-1.422-0.973c-1.342-0.324-2.098-0.72-2.098-1.891C7.209,0.738,8.109,0,9.361,0\\n\\t\\t\\tc0.918,0,1.647,0.278,2.286,0.792l-0.594,0.837c-0.567-0.423-1.135-0.647-1.711-0.647c-0.647,0-1.026,0.333-1.026,0.756\\n\\t\\t\\tC8.316,2.232,8.604,2.448,9.811,2.736z\"],[12],[13],[2,\"\\n\\t\\t\"],[10,\"path\"],[14,\"d\",\"M12.168,6.392V5.158h1.197v1.233H12.168z\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/toolbar-icons/initials-component.hbs"
    }
  });

  _exports.default = _default;
});