define("ember-rs/mixins/account-settings-attributes", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // flatten Account attributes that Document is using
    accountName: (0, _model.attr)('string'),
    // Account settings/features that's shared between Account and Document
    branding: (0, _model.attr)('boolean'),
    signingBackgroundColor: (0, _model.attr)('string'),
    signingFooterColor: (0, _model.attr)('string'),
    logoUrl: (0, _model.attr)('string'),
    logoThumbUrl: (0, _model.attr)('string'),
    displayLogo: (0, _model.attr)('boolean'),
    showSenderAvatar: (0, _model.attr)('boolean'),
    footerOption: (0, _model.attr)('string'),
    companyName: (0, _model.attr)('string')
  });

  _exports.default = _default;
});