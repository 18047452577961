define("ember-rs/locales/de/templates/start-document/-send-for-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendForSignature = void 0;
  var sendForSignature = {
    "support": "Wenn Sie Hilfe benötigen, <a href='https://support.citrix.com/cscase'>kontaktieren Sie den Support</a>."
  };
  _exports.sendForSignature = sendForSignature;
});