define("ember-rs/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hidePassword: true,
    passwordFieldType: Ember.computed('hidePassword', function () {
      if (this.hidePassword) {
        return 'password';
      } else {
        return 'text';
      }
    }),
    actions: {
      confirm: function confirm() {
        this.sendAction('confirm');
      }
    }
  });

  _exports.default = _default;
});