define("ember-rs/templates/components/certificate-loading-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9y3nu8km",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"certificate-loader\"],[15,5,[30,[36,1],[[35,0]],null]],[12],[2,\"\\n  \"],[8,\"loading-spinner\",[],[[\"@size\"],[40]],null],[2,\"\\n  \"],[1,[30,[36,2],[\"components.certificateLoadingPage.generateCertificate\"],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"pageCssStyle\",\"safe\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/certificate-loading-page.hbs"
    }
  });

  _exports.default = _default;
});