define("ember-rs/locales/nl/templates/components/support-when-externally-billed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportWhenExternallyBilled = void 0;
  var supportWhenExternallyBilled = {
    "partnerSale": "Uw account is aangemaakt na verkoop via een partner. Neem contact op met Support als u uw account wilt upgraden."
  };
  _exports.supportWhenExternallyBilled = supportWhenExternallyBilled;
});