define("ember-rs/locales/pt-br/templates/components/review-and-send-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewAndSendItem = void 0;
  var reviewAndSendItem = {
    "passcode-message": "Não se esqueça! Você tem que enviar este PIN para os signatários do documento.",
    "bulk-send-passcode-message": "Você habilitou o código secreto para transações de documentos. O envio em massa não suporta isso atualmente.",
    "generate-new-pin": "Gerar novo PIN",
    "pin-specification": "O PIN deve ser um número de cinco dígitos.",
    "expiration-message": "Após a data de expiração, o documento não estará mais disponível para assinar.",
    "ExpiresIn": "Expira em",
    "days": "dias",
    "loading": "carregando",
    "page": "página(s)",
    "emailTip1": "Um link para visualizar este documento será enviado para {{{email}}}.",
    "emailTip2": "O destinatário poderá visualizar e baixar este documento.",
    "enterEmail": "Insira o endereço de e-mail",
    "shareDoc": "Digite o endereço de email para compartilhar este documento",
    "ccMsg": "Envie cópias para as partes para dar-lhes acesso de somente leitura a este documento. As partes receberão notificações, poderão visualizar o progresso e baixar o documento concluído. Você pode revogar acesso das partes mais tarde, se necessário.",
    "add": "adicionar",
    "roles": "funções",
    "recipients": "destinatários",
    "cc": "cc: ",
    "ccCaps": "CC",
    "messageLimit": "A mensagem não pode ter mais de 100.000 caracteres.",
    "addMsg": "Adicionar uma mensagem personalizada...",
    "for": "para",
    "message": "mensagem",
    "pin": "PIN",
    "tags": "marcas",
    "passcodeOn": "Código secreto ativado",
    "passcodeOff": "Código secreto desativado",
    "restrictedFeatureMsg": "Seu plano atual não permite adicionar códigos secretos a documentos. Atualize seu plano para acessar esse recurso.",
    "kba": "KBA",
    "on": "Ativado",
    "off": "Desativado",
    "kbaTip": "Exigir que os signatários verifiquem sua identidade com um questionário baseado em conhecimento. Cada signatário precisará verificar de forma independente antes de assinar o documento. O acesso dos signatários será bloqueado após três tentativas malsucedidas.",
    "enable": "Ativar",
    "addTag": "adicionar marca",
    "tagTip": "Adicione marcas a este documento para encontrá-lo com facilidade mais tarde. Você também pode adicionar valores a marcas, por exemplo, \"client_id: 1234\"",
    "addSalesforceOpportunity": "Adicionar oportunidade do Salesforce",
    "addSalesforceOpportunityMsg": "Adicionar uma oportunidade do Salesforce a este documento",
    "exceededKbaLimit": "Limite de KBA atingido",
    "exceededKbaMsg": "Você atingiu o limite mensal da sua assinatura atual para documentos compatíveis com KBA. Para enviar mais documentos com o recurso de autenticação baseada em conhecimento ativada, <a href='https://support.citrix.com/cscase'>entre em contato com o suporte</a> para atualizar sua conta.",
    "tagName": "Nome da marca...",
    "tagValue": "Valor (opcional)",
    "sendTo": "Enviar para",
    "name": "Nome",
    "docName": "Nome do documento...",
    "totalNumberOfSigners": "Número total de signatários:",
    "expiresIn": "O prazo de expiração deve ser entre 1 e 365 dias."
  };
  _exports.reviewAndSendItem = reviewAndSendItem;
});