define("ember-rs/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DfyhnxWp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.rs3LoginRedirect.looking\"],null]],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.rs3LoginRedirect.sharefileEdition\"],null]],[13],[2,\"\\n\\n\"],[8,\"link-to\",[[24,0,\"button\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Log In\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"footer\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"unauthorized.rs3LoginRedirect.support\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/rs3-login-redirect.hbs"
    }
  });

  _exports.default = _default;
});