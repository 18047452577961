define("ember-rs/locales/zh-cn/controllers/account/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUser = void 0;
  var newUser = {
    "success": "已成功添加用户 {{{searchValue}}}。",
    "error": "无法成功添加用户 {{{searchValue}}}。"
  };
  _exports.newUser = newUser;
});