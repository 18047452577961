define("ember-rs/locales/en/templates/onboarding/send-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding2 = void 0;
  var sendOnboarding2 = {
    "status": "Here's where you can see the <span>status</span> of the document"
  };
  _exports.sendOnboarding2 = sendOnboarding2;
});