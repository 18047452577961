define("ember-rs/locales/ja/templates/components/document-upload-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploadThumbnail = void 0;
  var documentUploadThumbnail = {
    "noThumb": "サムネイルがありません...",
    "upload_done": "アップロード完了",
    "downloading": "ダウンロード中",
    "storing": "保存中",
    "converting_to_images": "画像に変換中",
    "converting_to_pdf": "PDF に変換中",
    "processing": "処理中",
    "created": "作成されました"
  };
  _exports.documentUploadThumbnail = documentUploadThumbnail;
});