define("ember-rs/locales/ja/controllers/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "upgradeMsg": "このアカウントはこの機能にアクセスできません。プロ プランにアップグレードして埋め込まれたテンプレートの使用を開始してください。",
    "templateDeleteMsg": "テンプレートを削除しました。",
    "tagUpdateMsg": "タグを更新しました。",
    "tagUpdateFailedMsg": "次のタグを更新できませんでした: {{{formattedResponse}}}",
    "templateDeleteConfirm": "このテンプレートを削除してもよろしいですか?",
    "loadSignerLinksFailedMsg": "テンプレートの署名者リンクを読み込めませんでした"
  };
  _exports.details = details;
});