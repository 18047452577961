define("ember-rs/locales/en/templates/onboarding/signer-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding1 = void 0;
  var signerOnboarding1 = {
    "redFlag": "The <span>red flag</span> points to areas that need to be complete.",
    "zoom": "Pinch and zoom on your phone to enlarge the document."
  };
  _exports.signerOnboarding1 = signerOnboarding1;
});