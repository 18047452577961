define("ember-rs/locales/pt-br/templates/components/assign-reusable-template-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assignReusableTemplateRole = void 0;
  var assignReusableTemplateRole = {
    "omitted": "omitido",
    "undo": "desfazer"
  };
  _exports.assignReusableTemplateRole = assignReusableTemplateRole;
});