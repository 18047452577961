define("ember-rs/models/mergefield-value", ["exports", "@ember-data/model", "ember-rs/models/component-value"], function (_exports, _model, _componentValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _componentValue.default.extend({
    document: (0, _model.belongsTo)('document', {
      inverse: 'mergefieldValues',
      async: false
    })
  });

  _exports.default = _default;
});