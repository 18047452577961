define("ember-rs/controllers/reports/export/month/template", ["exports", "ember-rs/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var metaColumns = [{
    type: 'meta',
    name: 'document_guid',
    attribute: 'id'
  }, {
    type: 'meta',
    name: 'parties',
    attribute: 'parties'
  }, {
    type: 'meta',
    name: 'status',
    attribute: 'document_state'
  }, {
    type: 'meta',
    name: 'sent_at',
    attribute: 'document_sent_at'
  }, {
    type: 'meta',
    name: 'completed_at',
    attribute: 'document_executed_at'
  }, {
    type: 'meta',
    name: 'expires_at',
    attribute: 'document_expires_at'
  }, {
    type: 'meta',
    name: 'cycle_time',
    attribute: 'cycle_time'
  }];

  var _default = Ember.Controller.extend({
    columnsSelected: Ember.computed(function () {
      return [];
    }),
    columns: Ember.computed('model.components', function () {
      return metaColumns.concat(this.get('model.components').map(function (component) {
        return {
          type: component.get('type'),
          name: component.get('name'),
          id: component.get('id')
        };
      }));
    }),
    generateURL: Ember.computed('model.id', function () {
      return "".concat(_environment.default.api.endpoint, "/reusable_templates/").concat(this.get('model.id'), "/export");
    }),
    generateData: Ember.computed('month', 'columnsSelected', function () {
      return {
        columns: this.columnsSelected,
        month: this.month
      };
    }),
    readyToGenerate: Ember.computed('columnsSelected', function () {
      return this.columnsSelected.length > 0;
    }),
    actions: {
      selectionsChanged: function selectionsChanged(selectedColumns) {
        this.setProperties({
          columnsSelected: selectedColumns,
          downloadURL: null
        });
      }
    }
  });

  _exports.default = _default;
});