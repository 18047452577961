define("ember-rs/locales/pt-br/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFree = void 0;
  var newUserOnboardingFree = {
    "title": "Bem-vindo ao RightSignature!",
    "body": "Atualmente, você só tem acesso básico ao RightSignature™. Isso permite que você autoassine documentos, bem como ver seus documentos anteriores. Para acessar os recursos PRO, entre em contato com um administrador da sua conta do RightSignature™."
  };
  _exports.newUserOnboardingFree = newUserOnboardingFree;
});