define("ember-rs/locales/zh-cn/templates/document-transaction/review-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_desktop = void 0;
  var review_desktop = {
    "send_document": "发送文档",
    "save_document": "保存文档",
    "sendDocument": "发送文档",
    "bulkSendConfirmation": "您即将与多个签名者共享此文档",
    "confirm": "确认",
    "cancel": "取消"
  };
  _exports.review_desktop = review_desktop;
});