define("ember-rs/locales/zh-cn/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "unlock_message": "一个或多个签名者已超出身份验证尝试的次数上限(3 次)，并且该文档现在已对这些签名者锁定。要解锁文档并要求任何待定的签名者使用基于知识的身份验证验证各自的身份，请单击“解锁”。请注意，解锁文档可能会与 <a target='_blank' href='https://www.irs.gov/uac/electronic-signature-guidance-for-forms-8878-and-8879'>IRS Electronic Signature Guidance for Forms 8878 and 8879</a> (表 8878 和 8879 的 IRS 电子签名指南)相冲突。"
  };
  _exports.unlock = unlock;
});