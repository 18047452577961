define("ember-rs/locales/pt-br/templates/components/hybris-trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialModal = void 0;
  var hybrisTrialModal = {
    "ohNo": "Oh, não!",
    "reminder": "Um lembrete:",
    "previewHasEnded": "Seu período de avaliação com a versão mais recente do RightSignature terminou.",
    "freeTrialEnds": "Sua avaliação gratuita termina em {{trialDaysRemaining}} dia(s).",
    "needToGetThingsSigned": "Ainda precisa de assinaturas em documentos? Os novos planos do RightSignature têm um preço que oferece muitas vantagens.",
    "exploreAdminFeatures": "Explore recursos avançados de administração e segurança",
    "retainDocuments": "Mantenha os documentos e a configuração da conta da versão original",
    "easySetup": "Configuração fácil, sem contrato – cancele a qualquer momento",
    "saveTimeAndPaper": "Economize uma hora ou mais por semana no trabalho com papelada",
    "choosePlan": "Escolha o plano para continuar",
    "needAssitance": "Precisa de ajuda com sua avaliação?",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>Suporte de contato</a>",
    "notSureYet": "Ainda não está convencido? Veja o que o RightSignature pode fazer pela sua empresa.",
    "logout": "Logout"
  };
  _exports.hybrisTrialModal = hybrisTrialModal;
});