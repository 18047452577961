define("ember-rs/locales/nl/templates/document-transaction/redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirect = void 0;
  var redirect = {
    "please_wait": " Een ogenblik geduld terwijl uw document wordt verwerkt..."
  };
  _exports.redirect = redirect;
});