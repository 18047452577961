define("ember-rs/locales/en/templates/signer/sign/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identify = void 0;
  var identify = {
    "advisory": "The sender of this document has enabled RightSignature's Knowledge-Based Authentication feature to verify your identity. RightSignature employs the third-party services of BlockScore to validate and verify the provided personal information. The use of the provided personal information is limited to the performance of the identity validation and verification with BlockScore’s proprietary data records. The performance of the identity validation and verification will have no effect on your credit report, credit score, or reporting to lenders. Neither the IRS, Blockscore nor RightSignature will have the ability to view or access your credit report or your tax information as part of this process.",
    "step1": "Identity Verification - Step 1 of 2",
    "beforeSigning": "Before signing the document, please enter your personal information to help us verify your identity.",
    "notValid": "Sorry, we couldn't find a valid identity to match the provided information.  Please ensure the information below is correct and click Next to try again.",
    "youHave": "You have ",
    "remaining": " remaining attempts to access the document.",
    "name": "Name:",
    "dob": "Date of Birth:",
    "ssn": "Last 4 of SSN:",
    "passport": "Passport number:",
    "address": "Address:",
    "address2": "Address 2:",
    "city": "City:",
    "state": "State:",
    "zip": "Zipcode:",
    "country": "Country:",
    "return": "Return to Form",
    "advisory2": "Identity Verification Advisory",
    "sampleAddress1": "120 S. West St.",
    "sampleAddress2": "Suite 100",
    "sampleAddress3": "Raleigh",
    "submit": "Submit"
  };
  _exports.identify = identify;
});