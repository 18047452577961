define("ember-rs/locales/ja/mixins/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.settings = void 0;
  var settings = {
    "updateSuccessful": "設定を更新しました",
    "updateError": "設定の更新エラー"
  };
  _exports.settings = settings;
});