define("ember-rs/templates/components/reusable-template-selector-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T3NMYXmR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[15,\"src\",[31,[[34,1,[\"thumbnailUrl\"]]]]],[14,\"alt\",\"\"],[14,0,\"document-thumbnail\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"template-info\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[1,[35,1,[\"name\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[8,\"rs-button\",[],[[\"@action\",\"@isPrimary\",\"@isDisabled\",\"@isSmall\"],[[30,[36,2],[[32,0],\"select\"],null],true,[34,3],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"loading-spinner\",[],[[\"@darkBg\"],[true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"shared.actions.add\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"reusableTemplate\",\"action\",\"isSelected\",\"loading\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/reusable-template-selector-row.hbs"
    }
  });

  _exports.default = _default;
});