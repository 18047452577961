define("ember-rs/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ////////////////////////  HOOKS  ////////////////////////
    isRetrying: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    stripeCustomerUrl: Ember.computed('signerPayment.paymentClientCustomerToken', function () {
      return "https://dashboard.stripe.com/customers/".concat(this.get('signerPayment.paymentClientCustomerToken'));
    }),
    stripeChargeUrl: Ember.computed('signerPayment.chargeId', function () {
      return "https://dashboard.stripe.com/payments/".concat(this.get('signerPayment.chargeId'));
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      retryCharge: function retryCharge() {
        var _this = this;

        var flashMessages = this.flashMessages;
        this.set('isRetrying', true);
        this.signerPayment.retryCharge(this.get('document.id')).then(function (signerPayment) {
          if (signerPayment.get('chargeSuccessful')) {
            flashMessages.success(_this.i18n.t('components.signerPaymentDetails.success'));
          } else {
            flashMessages.warning(_this.i18n.t('components.signerPaymentDetails.retryError', {
              errorMsg: signerPayment.get('chargeFailureMessage')
            }));
          }
        }).finally(function () {
          return _this.set('isRetrying', false);
        });
      }
    }
  });

  _exports.default = _default;
});