define("ember-rs/locales/en/templates/components/component-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentEditor = void 0;
  var componentEditor = {
    "charge": "Charge after executed?",
    "selectOne": "Select one...",
    "amount": "Payment amount (USD):",
    "enterAmount": "Enter payment amount...",
    "required": "REQUIRED: Enter signer help text below to let the recipient know what to upload.",
    "signerHelp": "Signer help text:",
    "helpText": "Enter help text ...",
    "selectBox": "Select box needs at least 2 options.",
    "selectOptions": "Select Options:",
    "addOption": "Add option...",
    "addToGroup": "Add to Group",
    "assignedTo": "Assigned To:",
    "fieldRequired": "Field required?",
    "autoFillDate": "Auto-fill date signed",
    "autoFillDateHelpText": "A recipient will not be able to edit the field. It will be filled in automatically with the date on which the recipient signs.",
    "mergeField": "Merge field?",
    "mergeFieldName": "merge field needs a name",
    "nameMergeField": "Name merge field:",
    "setFontSize": "Set font size...",
    "muiltiline": "Multiline?",
    "clearBackground": "Clear background?",
    "addDefaultValue": "Add default value?",
    "setDefaultValue": "Set default value...",
    "defaultValue": "Default Value:",
    "nameThisField": "Name this field?",
    "name": "Name:",
    "setName": "Set name of component...",
    "textAlign": "Text Align",
    "showLess": "Show less",
    "showMore": "Show More",
    "delete": "Delete",
    "saved": "Saved!",
    "save": "Save",
    "dateFormat": "Date Format",
    "left": "left",
    "center": "center",
    "right": "right",
    "groupRequirement": "Group Requirement",
    "only_one": "Only One",
    "one_or_more": "One or More",
    "options_warning": "You've already added that option"
  };
  _exports.componentEditor = componentEditor;
});