define("ember-rs/locales/es/templates/unauthorized/document-voided", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentVoided = void 0;
  var documentVoided = {
    "documentVoided": "Documento anulado",
    "sender": "Este documento ha sido anulado por el remitente."
  };
  _exports.documentVoided = documentVoided;
});