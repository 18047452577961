define("ember-rs/components/client-application/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  SERVICES  ////////////////////////
    flashMessages: Ember.inject.service(),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      cancelEdit: function cancelEdit() {
        this.model.rollbackAttributes();
        this.sendAction('redirect');
      },
      updateClientApplication: function updateClientApplication() {
        var _this = this;

        if (Ember.isBlank(Ember.get(this, 'model.websiteUrl'))) {
          Ember.set(this, 'model.websiteUrl', null);
        }

        this.model.save().then(function () {
          _this.flashMessages.success(_this.i18n.t('components.clientApplication.edit.update_success', {
            applicationName: Ember.get(_this, 'model.name')
          }));

          _this.sendAction("redirect");
        });
      }
    }
  });

  _exports.default = _default;
});