define("ember-rs/locales/zh-cn/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingComplete = void 0;
  var signingComplete = {
    "docSigned": "文档已签名!",
    "createTrial": "创建一个免费的 RightSignature 帐户以查看或下载这一完成的文档。您将永久有权访问此文档，只需使用文档发送到的电子邮件地址进行注册并登录即可。",
    "createAccount": "创建帐户",
    "login": "登录到帐户",
    "notNow": "以后再说"
  };
  _exports.signingComplete = signingComplete;
});