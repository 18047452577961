define("ember-rs/locales/zh-cn/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTagsEditor = void 0;
  var documentTagsEditor = {
    "addTags": "向此文档添加标记以便以后轻松进行查找。还可以向标记中添加值，例如“client_id: 1234”",
    "tagName": "标记名称...",
    "optional": "值(可选)"
  };
  _exports.documentTagsEditor = documentTagsEditor;
});