define("ember-rs/templates/onboarding/prepare-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0p6YRJyw",
    "block": "{\"symbols\":[\"componentClass\",\"index\"],\"statements\":[[10,\"div\"],[14,0,\"onboarding-target-image\"],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"tool-title theme__toolbar_header\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"components.componentToolbar.request\"],null]],[2,\"\\n    \"],[8,\"rs-tooltip\",[],[[\"@isPlacedOnLeft\",\"@class\",\"@message\"],[true,\"header-request\",[30,[36,0],[\"components.componentToolbar.useTools\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"tool-list theme__toolbar__items\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"component-toolbar-menuitem\",[],[[\"@componentClass\",\"@index\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[12],[1,[34,4]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding-new/overlay-arrow.svg\"],[14,0,\"onboarding-arrow\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/overlay-arrow.png\"],[15,\"alt\",[30,[36,0],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"onboarding.prepareOnboarding1.useTools\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"requestComponents\",\"-track-array\",\"each\",\"name\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/prepare-onboarding-1.hbs"
    }
  });

  _exports.default = _default;
});