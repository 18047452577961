define("ember-rs/templates/logged-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "irCzNDAA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[[35,0,[\"model\"]],[30,[36,1],[[35,0,[\"model\",\"hasSeenRestyleOnboarding\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"restyle-onboarding\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentUser\",\"not\",\"and\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/logged-in.hbs"
    }
  });

  _exports.default = _default;
});