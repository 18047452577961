define("ember-rs/locales/de/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrations = void 0;
  var integrations = {
    "successEnabled": "{{{formattedProviderName}}} wurde aktiviert",
    "successAuthorized": "{{{formattedProviderName}}} wurde autorisiert",
    "successDisabled": "{{{formattedProviderName}}} wurde deaktiviert",
    "error": "Fehler. {{{formattedProviderName}}} konnte nicht geändert werden.",
    "errorAuthorize": "Fehler. Die Integration von {{{formattedProviderName}}} konnte nicht autorisiert werden!",
    "errorSetup": "Beim Einrichten der Integration '{{{formattedProviderName}}}' ist ein Fehler aufgetreten. Versuchen Sie es noch einmal."
  };
  _exports.integrations = integrations;
});