define("ember-rs/locales/zh-cn/templates/onboarding/new-user-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboarding = void 0;
  var newUserOnboarding = {
    "title": "欢迎使用 RightSignature™",
    "letsPersonalize": "在我们探索这些功能之前，让我们先对您的帐户进行个性化设置。",
    "photoText": "您的照片将在文档签名页面上使用。它增加了个性化和真实性的级别。",
    "photoSubtext": "可以随时通过访问您的帐户设置来更新此信息。",
    "sendSign": "发送您的文档并对其签名",
    "sendDoc": "发送您的文档以由其他人签名或者对需要您签名的任何文档签名。",
    "knowMore": "想了解更多信息吗?",
    "viewSupport": "查看支持文章和视频",
    "createTemplates": "为经常签名的文档创建模板。",
    "saveTime": "节省时间并为常用文档创建可重用的模板，包括弃权、NDA、纳税表单、员工应用程序等。",
    "learnMore": "想了解更多信息吗?",
    "accessSupport": "访问我们的<a href='#''>支持站点</a>，其中包含用来确保您充分利用 RightSignature™ 的视频教程和有用的提示。"
  };
  _exports.newUserOnboarding = newUserOnboarding;
});