define("ember-rs/templates/components/spinner-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "10VUr64T",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@destinationElement\"],[[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"content-wrapper\"],[12],[2,\"\\n    \"],[8,\"rs-spinner\",[],[[],[]],null],[2,\"\\n\"],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"summary-text\"],[12],[2,\" \"],[1,[34,1]],[2,\" \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"detailed-text\"],[12],[2,\" \"],[1,[34,0]],[2,\" \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"description\",\"title\",\"rootElement\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/spinner-page.hbs"
    }
  });

  _exports.default = _default;
});