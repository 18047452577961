define("ember-rs/locales/it/templates/components/payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentForm = void 0;
  var paymentForm = {
    "name": "Nome",
    "firstName": "Nome...",
    "lastName": "Cognome...",
    "address1": "Riga indirizzo 1...",
    "address2": "Riga indirizzo 2...",
    "city": "Città",
    "state": "Stato/provincia",
    "zip": "CAP",
    "country": "Paese",
    "address": "Indirizzo",
    "vatId": "Partita IVA",
    "redirect": "Reindirizzamento...",
    "upgrade": "Aggiorna",
    "payUpgrade": "Pagamento e aggiornamento",
    "oops": "Ops! Qualcosa è andato storto",
    "ok": "OK"
  };
  _exports.paymentForm = paymentForm;
});