define("ember-rs/locales/nl/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceCompleteDocumentModal = void 0;
  var forceCompleteDocumentModal = {
    "header": "Voltooien van document afdwingen",
    "forceCompleted": "Hiermee worden alle velden verwijderd die zijn toegewezen aan ondertekenaars die nog niet hebben ondertekend en krijgt het document de eindstatus 'ondertekend'. Deze actie kan deze niet ongedaan worden gemaakt.",
    "briefMoment": "Het kan even duren voordat de nieuwe status op het dashboard wordt weergegeven.",
    "forceComplete": "Voltooien afdwingen",
    "cancel": "Annuleren",
    "success": "Het document is voltooid door afdwingen.",
    "failed": "Kan voltooiing van document niet afdwingen"
  };
  _exports.forceCompleteDocumentModal = forceCompleteDocumentModal;
});