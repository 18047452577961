define("ember-rs/templates/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wrd+T9C4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"spinner-page\",[],[[\"@title\",\"@description\"],[[30,[36,0],[\"ccGatewayRedirect.title\"],null],[30,[36,0],[\"ccGatewayRedirect.desc\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/cc-gateway-redirect.hbs"
    }
  });

  _exports.default = _default;
});