define("ember-rs/locales/pt-br/templates/archived-document", ["exports", "ember-rs/locales/pt-br/templates/archived-document/details"], function (_exports, _details) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archived_document = void 0;
  var archived_document = {
    details: _details.details
  };
  _exports.archived_document = archived_document;
});