define("ember-rs/locales/pt-br/templates/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detailsPanel = void 0;
  var detailsPanel = {
    "clientId": "ID DO CLIENTE:",
    "clientSecret": "SEGREDO DO CLIENTE:",
    "lastViewed": "Última visualização: ",
    "fromIp": "do IP",
    "hidden": "Oculto por razões de segurança",
    "reveal": "Revelar segredo",
    "privateToken": "TOKEN DE API PRIVADO:",
    "revealToken": "Revelar token",
    "regenerate": "Regenerar token. ATENÇÃO: Esta é uma ação irreversível",
    "description": "DESCRIÇÃO:",
    "scope": "ÂMBITO(S):",
    "redirectUri": "URI DE REDIRECIONAMENTO:",
    "applicationUrl": "URL DO APLICATIVO:",
    "orgUrl": "URL DE ORGANIZAÇÃO:",
    "createdAt": "CRIADO EM:",
    "updatedAt": "ATUALIZADO EM:",
    "edit": "editar"
  };
  _exports.detailsPanel = detailsPanel;
});