define("ember-rs/locales/es/templates/document-transaction/upload-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload_signers = void 0;
  var upload_signers = {
    "instructions_header": "Siga estos pasos para cargar el archivo CSV",
    "step_1": "PASO 1",
    "step_2": "PASO 2",
    "step_3": "PASO 3",
    "step_1_text": "Descargar la lista de ejemplo de firmantes para envío en bloque",
    "step_2_text": "Rellene los campos de nombre y correo electrónico (obligatorio).",
    "step_3_text": "Cargue el CSV completado.",
    "instructions_note": "Nota: No puede haber más de 300 firmantes",
    "upload_failed": "La carga falló",
    "upload_failure_message": "Error de carga debido a un archivo dañado o a un problema de procesamiento. Compruebe que el archivo tiene contenido válido e intente cargarlo de nuevo.",
    "view_all": "Ver todo",
    "ok": "Aceptar",
    "validate_and_reupload": "El archivo contiene más errores. Consulte las validaciones e intente cargarlo de nuevo."
  };
  _exports.upload_signers = upload_signers;
});