define("ember-rs/locales/en/templates/signer/confirm-identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentity = void 0;
  var confirmIdentity = {
    "confirmed": "Successfully confirmed identity.",
    "submitted": "Your response has been submitted."
  };
  _exports.confirmIdentity = confirmIdentity;
});