define("ember-rs/locales/en/controllers/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.branding = void 0;
  var branding = {
    "emailUpdateSuccess": "Successfully updated user email settings.",
    "emailUpdateFail": "Error updating user email settings.",
    "updateSuccess": "Successfully updated user account branding settings.",
    "updateFail": "Error updating user account branding settings."
  };
  _exports.branding = branding;
});