define("ember-rs/locales/de/templates/onboarding/welcome-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding1 = void 0;
  var welcomeOnboarding1 = {
    "welcome": "Willkommen bei RightSignature,",
    "tips": "Wir zeigen Ihnen, wo bestimmte Funktionen sind, wie Sie RightSignature verwenden und geben Ihnen Tipps, wie Sie den Service optimal nutzen"
  };
  _exports.welcomeOnboarding1 = welcomeOnboarding1;
});