define("ember-rs/locales/it/templates/components/account-user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "basic": "Informazioni di base",
    "firstName": "Nome:",
    "lastName": "Cognome:",
    "required": "*obbligatorio",
    "email": "E-mail:",
    "emailTip": "*È necessario fornire un indirizzo e-mail valido.",
    "password": "Password:",
    "hiddenForSecurity": "nascosto per sicurezza",
    "reset": "Reimposta",
    "emailInfo": "Invia informazioni di accesso via e-mail",
    "oldPass": "Password precedente:",
    "newPass": "Nuova password:",
    "passReq": "*Le password devono contenere almeno 8 caratteri, almeno 1 numero, 1 lettera maiuscola e 1 lettera minuscola.",
    "permissions": "Autorizzazioni",
    "manageUsers": "Gestisci utenti",
    "manageDelegateUsers": "Può delegare utenti",
    "manageViewBills": "Può visualizzare le fatture",
    "customizeBranding": "Personalizza il branding dell'account",
    "manageTemplates": "Gestisci modelli",
    "viewDocuments": "Visualizza tutti i documenti",
    "removeUser": "Rimuovi utente",
    "reset2fa": "Reimposta verifica in due passaggi"
  };
  _exports.edit = edit;
});