define("ember-rs/initializers/overlayer-data-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'overlayerDataService', 'service:overlayer-data');
  }

  var _default = {
    name: 'overlayer-data-service',
    initialize: initialize
  };
  _exports.default = _default;
});