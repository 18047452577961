define("ember-rs/components/edit-avatar", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['avatar-section'],
    flashMessages: Ember.inject.service(),
    userAvatarUrl: Ember.computed.alias('currentUserModel.avatarUrl'),
    ajax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    actions: {
      delete: function _delete() {
        var _this = this;

        this.ajax.request("/users/".concat(this.get('currentUserModel.id'), "/avatar"), {
          method: 'DELETE'
        }).then(function () {
          _this.flashMessages.success(_this.i18n.t('components.edit-avatar.flash-messages.removed-avatar'));

          _this.set('userAvatarUrl', '');
        }).catch(function () {
          _this.flashMessages.warning(_this.i18n.t('components.edit-avatar.flash-messages.remove-avatar-failed'));
        });
      }
    },
    onInitUploader: Ember.on('didInsertElement', function () {
      var self = this;
      var flash = this.flashMessages;
      this.uploaderLink().on('click', function (e) {
        e.preventDefault();
        self.uploader().trigger('click');
      });
      this.uploader().fileupload({
        dataType: 'json',
        method: 'POST',
        url: _environment.default.api.endpoint.concat("/users/".concat(this.get('currentUserModel.id'), "/avatar")),
        paramName: 'avatar',
        submit: function submit(e, data) {
          if (['image/jpeg', 'image/png'].indexOf(data.files[0].type) === -1) {
            flash.warning(self.get('i18n').t('components.edit-avatar.flash-messages.invalid-file-type'));
            return false;
          }

          data.jqXHR = (0, _jquery.default)(this).fileupload('send', data);
          return false;
        },
        done: function done(e, data) {
          self.set('userAvatarUrl', data.result.user.avatar_url);
          flash.success(self.get('i18n').t('components.edit-avatar.flash-messages.uploaded-avatar'));
        },
        fail: function fail(e) {
          flash.warning(e.errors.error);
        }
      });
    }),
    cleanup: Ember.on('willDestroyElement', function () {
      this.uploaderLink().off('click');
      this.uploader().fileupload('destroy');
    }),
    uploaderLink: function uploaderLink() {
      return this.$('.upload-avatar');
    },
    uploader: function uploader() {
      return this.$('#uploader');
    }
  });

  _exports.default = _default;
});