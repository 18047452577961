define("ember-rs/locales/en/templates/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detailsPanel = void 0;
  var detailsPanel = {
    "clientId": "CLIENT ID:",
    "clientSecret": "CLIENT SECRET:",
    "lastViewed": "Last viewed: ",
    "fromIp": "from IP",
    "hidden": "Hidden for security",
    "reveal": "Reveal secret",
    "privateToken": "PRIVATE API TOKEN:",
    "revealToken": "Reveal token",
    "regenerate": "Regenerate token. WARNING: This is an irreversible action",
    "description": "DESCRIPTION:",
    "scope": "SCOPE(S):",
    "redirectUri": "REDIRECT URI:",
    "applicationUrl": "APPLICATION URL:",
    "orgUrl": "ORGANIZATION URL:",
    "createdAt": "CREATED AT:",
    "updatedAt": "UPDATED AT:",
    "edit": "edit"
  };
  _exports.detailsPanel = detailsPanel;
});