define("ember-rs/locales/es/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploader = void 0;
  var documentUploader = {
    "multiFile": "No se pueden cargar varios archivos. Solo se cargará el primer archivo seleccionado.",
    "alreadyUploaded": "Ya cargó este archivo.",
    "fileSize": "El archivo {{{fileName}}} supera nuestro límite de 20 MB.",
    "uploadFailed": "Error al crear la carga"
  };
  _exports.documentUploader = documentUploader;
});