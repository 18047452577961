define("ember-rs/locales/en/templates/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = void 0;
  var verify = {
    "advisory": "The sender of this document has enabled RightSignature's Knowledge-Based Authentication feature to verify your identity. RightSignature employs the third-party services of BlockScore to validate and verify the provided personal information. The use of the provided personal information is limited to the performance of the identity validation and verification with BlockScore’s proprietary data records. The performance of the identity validation and verification will have no effect on your credit report, credit score, or reporting to lenders. Neither the IRS, Blockscore nor RightSignature will have the ability to view or access your credit report or your tax information as part of this process.",
    "step2": "Identity Verification - Step 2 of 2",
    "answer": "Answer the identity verification questions below by selecting the checkbox next to the appropriate response.",
    "noMatch": "Sorry, the answers you have provided do not match our records for this identity.",
    "youHave": "You have ",
    "remaining": "remaining attempts to access the document.",
    "complete": "Complete Verification",
    "return": "Return to Form",
    "advisory2": "Identity Verification Advisory",
    "unableToAuthenticate": "Unable to authenticate successfully. Contact <b>{{{sender_email}}}</b> to access the document through an alternative authentication mechanism."
  };
  _exports.verify = verify;
});