define("ember-rs/controllers/reusable-template/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // TODO: allow roles to be omitted as long as at least 1 is present
    isValid: Ember.computed('transactionRoles.@each.signerName', 'transactionRoles.@each.signerEmail', 'transactionRoles.[]', function () {
      var valid = true;
      this.transactionRoles.forEach(function (r) {
        if (!r.get('isSignerValid')) {
          valid = false;
          return false;
        }
      });
      return valid;
    }),
    isNotValid: Ember.computed.not('isValid'),
    // roles from transaction that match the reusable template
    transactionRoles: Ember.computed.filter('model.documentTransaction.transactionRoles', function (role) {
      return role.get('documentTemplate.id') === this.get('model.id');
    })
  });

  _exports.default = _default;
});