define("ember-rs/locales/ja/templates/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentAccessSettings = void 0;
  var documentAccessSettings = {
    "addViewer": "閲覧者を追加",
    "email": "メールアドレス",
    "name": "Name",
    "add": "追加",
    "cancel": "キャンセル",
    "editSigner": "署名者の編集",
    "save": "保存",
    "manageAccess": "アクセスの管理",
    "accessTip": "これらのユーザーはこのドキュメントにアクセスでき、いつでもダウンロードまたは表示できます。",
    "signers": "署名者",
    "edit": "編集",
    "viewers": "閲覧者",
    "remove": "削除",
    "updateEmail": "署名者のメール アドレスを更新しました。",
    "updateSigner": "Successfully updated signer details."
  };
  _exports.documentAccessSettings = documentAccessSettings;
});