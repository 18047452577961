define("ember-rs/locales/de/templates/reports", ["exports", "ember-rs/locales/de/templates/reports/export"], function (_exports, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    export: _export.exportTrans,
    "header": "Berichte",
    "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
    "select-report-type--text": "Berichtstyp auswählen",
    "select-report-type-option-overview": "Übersicht",
    "overviewReport": "Übersichtsbericht",
    "select-report-type-option-efficiency": "Effizienz",
    "efficiencyReport": "Effizienzbericht",
    "select-report-type-option-cycle-time": "Zykluszeitbericht",
    "select-report-type-option-export": "Datenexport",
    "select-report-type-option-enterprise": "Unternehmen",
    "enterpriseUsageReport": "Unternehmensnutzungsbericht",
    "userUsageReport": "Benutzerbenutzungsbericht",
    "select-report-type-option-user": "Neuer Nutzungsbericht",
    "components": {
      "account-document-volume": {
        "header": "Berichte",
        "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
        "legend-document-sent": "Dokument gesendet",
        "legend-document-signed": "Dokument signiert",
        "legend": "Legende:"
      },
      "account-statistics": {
        "header": "Berichte",
        "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
        "column-header-current-month": "Dieser Monat",
        "column-header-previous-month": "Letzter Monat",
        "column-header-average": "Durchschnitt",
        "row-header-sent-documents": "Gesendete Dokumente",
        "row-header-viewed": "Angezeigt (von mindestens einem Signaturgeber)",
        "row-header-expired": "Abgelaufen",
        "row-header-signed": "Signiert",
        "row-header-sent-documents-per-user": "Gesendete Dokumente pro Benutzer (Durchschnitt)",
        "row-header-signed-documents": "Signierte Dokumente",
        "row-header-pending-documents": "Ausstehende Dokumente",
        "row-header-viewed-pending": "Angezeigt (ausstehende Dok.)",
        "none": "Nicht zutreffend"
      },
      "top-users-by-signed-documents": {
        "header": "Berichte",
        "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
        "column-header-user": "Benutzer",
        "column-header-signed-documents": "Signierte Dokumente"
      },
      "account-efficiency": {
        "header": "Berichte",
        "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
        "legend-pending": "Ausstehend",
        "legend-signed": "Signiert",
        "legend-expired": "Abgelaufen"
      },
      "top-users-by-efficiency": {
        "header": "Berichte",
        "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
        "column-header-user": "Benutzer",
        "column-header-efficiency-percentage": "Effizienz %",
        "column-header-ratio": "Verhältnis",
        "over-gcd": "{{signed}} von {{sent}}"
      },
      "top-users-by-time-to-sign": {
        "header": "Berichte",
        "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
        "column-header-user": "Benutzer",
        "column-header-mean-time": "Durchschnittszeit (Stunden)"
      },
      "average-cycle-time": {
        "header": "Berichte",
        "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
        "legend-mean-time-to-view": "Durchschnittliche Anzeigezeit",
        "legend-mean-time-to-sign": "Durchschnittliche Signaturzeit"
      },
      "cycle-time-statistics": {
        "header": "Berichte",
        "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
        "column-header-current-month": "Dieser Monat",
        "column-header-previous-month": "Letzter Monat",
        "column-header-all-time": "Gesamte Zeit",
        "row-header-under-5-mins": "Unter 5 Minuten",
        "row-header-under-30-mins": "Unter 30 Minuten",
        "row-header-under-1-hour": "Unter 1 Stunde",
        "row-header-under-12-hours": "Unter 12 Stunden",
        "row-header-under-1-day": "Unter 1 Tag"
      },
      "month-export": {
        "header": "Berichte",
        "description": "Zeigen Sie Daten für Ihre gesendeten Dokumente an. Dazu gehört, wie lange es normalerweise dauert, um Dokumente zu signieren, und wie viele Dokumente pro Monat fertiggestellt wurden. Erweitern Sie Ihr Abonnement, um auf Berichte zuzugreifen.",
        "export-to-csv-section-header": "Als CSV-Datei exportieren",
        "export-to-csv-section-description": "Eine CSV-Datei kann in Excel geöffnet werden, um Daten zu Ihren gesendeten Dokumenten anzuzeigen."
      },
      "generate-download": {
        "ie-warning-text": "Klicken Sie mit der rechten Maustaste auf 'CSV-Datei exportieren' und wählen Sie 'Speichern unter', um die CSV-Datei herunterzuladen.",
        "export-csv-button-text": "CSV-Datei exportieren",
        "generate-csv-button-text": "CSV generieren",
        "send-download-link": "Wir generieren den Bericht und senden den Downloadlink per E-Mail."
      }
    }
  };
  _exports.reports = reports;
});