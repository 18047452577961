define("ember-rs/locales/es/templates/components/trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.trialModal = void 0;
  var trialModal = {
    "ohNo": "¡Oh, no!",
    "reminder": "Un pequeño recordatorio:",
    "trialExpired": "Prueba gratuita caducada.",
    "trialEnds_singular": "Su prueba gratuita finaliza en {{trialDays}} día.",
    "trialEnds_plural": "Su prueba gratuita finaliza en {{trialDays}} días.",
    "day": "día",
    "upgradeMessage": "¿Todavía necesita que le firmen documentos? Los precios de los planes de RightSignature permitirán a su empresa ahorrarle tiempo y dinero.",
    "accountSetup": "Instalación sencilla, sin contratos ni permanencia.",
    "trialSetup": "Conserve los documentos y la configuración de la cuenta de su versión de prueba.",
    "paperWork": "¡Ahórrese como mínimo una hora de papeleo a la semana!",
    "moreTime": "¿Necesita más tiempo para su evaluación?",
    "call": "<a href='https://www.citrix.com/contact/technical-support.html'>Llámenos</a>",
    "logout": "Cerrar sesión",
    "notSure": "¿Todavía no lo tiene claro? Vea cómo RightSignature puede beneficiar a su negocio."
  };
  _exports.trialModal = trialModal;
});