define("ember-rs/locales/fr/templates/onboarding/prepare-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTutorial = void 0;
  var prepareTutorial = {
    "title": "Préparer un document pour signature",
    "selectField": "Sélectionnez le champ à droite que vous souhaitez ajouter.",
    "placeField": "Placer le champ",
    "clickPlace": "Cliquez sur l'emplacement où vous souhaitez placer le nouveau champ.",
    "adjustSize": "Ajuster la taille du champ",
    "adjustHover": "Pour ajuster, survolez le champ et faites glisser les points.",
    "adjustOptions": "Régler les options",
    "moreOptions": "Cliquez sur le champ pour afficher plus d'options et sélectionnez la personne qui doit compléter le champ.",
    "thatsIt": "C'est tout !",
    "clickReview": "Lorsque vous avez terminé, cliquez sur <b>Suivant : Vérifier</b>",
    "knowMore": "Vous souhaitez en savoir plus ?",
    "support": "Consulter articles de support &amp; vidéos"
  };
  _exports.prepareTutorial = prepareTutorial;
});