define("ember-rs/locales/nl/templates/document/prepare-signers-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_desktop = void 0;
  var prepare_signers_desktop = {
    "assign_roles": "Rollen toewijzen voor:",
    "who_sign": "Wie moet er ondertekenen? ",
    "sign_order": "Volgorde van ondertekening instellen",
    "add_another": "Nog een ondertekenaar toevoegen",
    "add_yourself": "Uzelf toevoegen",
    "contact_integtration": "Integratie voor contactpersonen",
    "select_integration": "Selecteer een integratie",
    "who_sign_package": "Wie moet het documentenpakket ondertekenen?"
  };
  _exports.prepare_signers_desktop = prepare_signers_desktop;
});