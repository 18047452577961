define("ember-rs/locales/nl/templates/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faqModal = void 0;
  var faqModal = {
    "docId": "Document-id:",
    "contactSender": "Neem contact op met de afzender: {{{userName}}} op <a href='mailto: {{{userEmail}}}'>{{{userEmail}}}</a> als u vragen hebt",
    "needHelp": "Hebt u hulp nodig bij het gebruik van RightSignature&trade;? Kijk eens naar deze veelgestelde vragen en antwoorden:",
    "q1": "Ik heb een vraag over de inhoud van dit document:",
    "rsNote": "RightSignature&trade; is een onlineservice van derden voor het ondertekenen van documenten. We maken het verzenden en ondertekenen van documenten mogelijk met wettelijk erkende, beveiligde, digitale handtekeningen, maar zijn op geen enkele manier verbonden met de afzender van het document.",
    "q2": "Hoe vul ik dit document in?",
    "a2": "De rode pijl aan de linkerkant van het document geeft aan waar het volgende vereiste veld is. Zodra alle vereiste velden zijn ingevuld, wordt de knop 'Verzenden' beschikbaar om op te klikken.",
    "q3": "Is dit wettelijk bindend?",
    "a3": "Ja, documenten die online met digitale handtekeningen zijn ondertekend, zijn rechtsgeldig en hebben dezelfde juridische status als papieren documenten die handmatig zijn ondertekend. Hoogstwaarschijnlijk hebt u al veel vaker een online contract afgesloten. Wanneer u bijvoorbeeld op internet op de knop 'Ik ga akkoord' klikt, bent u meestal wettelijk partij bij een zogenaamde Clickwrap-overeenkomst. Ook als u online artikelen koopt, sluit u bij aankoop een juridisch bindend contract af.",
    "a3part2": "De technologie van RightSignature&trade; is volledig in naleving van de Amerikaanse wetgeving inzake elektronische handtekeningen in de Global and National Commerce Act (ESIGN) uit het jaar 2000 en de Uniform Electronic Transactions Act (UETA), evenals de Europese richtlijn 1999/93/EG. Deze wetten waren alle bedoeld om de snelle invoering van elektronische handtekeningen aan te moedigen en het gebruik van verouderde papiermethoden te verminderen. Elk van deze wetten versterkt de geldigheid van elektronische overeenkomsten. Volgens ESIGN bijvoorbeeld kan het rechtsgevolg, de rechtsgeldigheid of afdwingbaarheid van een contract niet worden geweigerd alleen omdat een elektronische handtekening of elektronische record bij de totstandkoming is gebruikt.",
    "q4": "Wat is RightSignature™?",
    "a4": "Met RightSignature&trade; bent u in staat om wettelijk bindende documenten online te maken en te ondertekenen, op een snellere en veiligere manier dan bij de ondertekening van papieren documenten. RightSignature&trade; is een webgebaseerde software-as-a-service die gebruikmaakt van de beveiligde gegevensinfrastructuur van Amazon Web Services.",
    "q5": "Wat doet RightSignature™?",
    "a5": "Met RightSignature&trade; bent u in staat om wettelijk bindende documenten online te maken en te ondertekenen, op een snellere en veiligere manier dan bij de ondertekening van papieren documenten. RightSignature&trade; is een webgebaseerde software-as-a-service die gebruikmaakt van de beveiligde gegevensinfrastructuur van Amazon Web Services.",
    "q6": "Hoe maakt RightSignature™ het online ondertekenen van documenten mogelijk?",
    "a6": "Met RightSignature&trade; is het gemakkelijk om handtekeningen van meerdere partijen online te verkrijgen. Upload uw document en voer de informatie van de ontvangers in. RightSignature&trade; stuurt ontvangers via e-mail een unieke koppeling naar een pagina waar ze het document kunnen controleren, de nodige tekstvelden kunnen invullen en het document kunnen ondertekenen. Ontvangers kunnen online in enkele seconden met een muis ondertekenen.",
    "a6part2": "De meeste bedrijven ondertekenen tegenwoordig documenten door ze heen en weer te faxen, 's nachts via spoedpost te verzenden of door handtekeningpagina's te scannen en te e-mailen. RightSignature&trade; elimineert het gedoe, de vertragingen en de kosten van deze saaie, verouderde methoden. Het ondertekenen van een wettelijk bindend document en veilig opslaan op een beveiligde server kan in slechts enkele minuten met een paar muisklikken worden bewerkstelligd.",
    "q7": "Is het niet veiliger om een ​​papieren document te ondertekenen?",
    "a7": "Elektronische contracten zijn aanzienlijk veiliger dan papieren contracten en hebben dezelfde juridische status. Terwijl documenten die per fax en post worden verzonden, blootgesteld zijn aan risico's tijdens de distributie tussen kantoren, blijven elektronische documenten volledig vertrouwelijk en worden alleen door de aangewezen ontvangers bekeken. RightSignature&trade;-documenten worden met redundante kopieën op beveiligde servers opgeslagen en kunnen, in tegenstelling tot papieren documenten, niet zoek raken, verloren gaan of vernietigd worden. Bovendien wordt elke ondertekenende partij geverifieerd aan de hand van e-mailontvangstbewijzen, IP-records en biometrische handtekeninggegevens.",
    "q8": "Voor wie is deze service bedoeld?",
    "a8": "RightSignature&trade; is ideaal voor kleine en middelgrote bedrijven, freelancers, ontwerpers, ontwikkelaars, advocaten, aannemers, scholen, non-profitorganisaties en nog veel meer. Als er voor uw werk documenten moeten worden ondertekend, hebt u RightSignature&trade; nodig.",
    "q9": "Welke soorten documenten moet ik verzenden?",
    "a9": "Documenten die regelmatig via RightSignature&trade; worden verzonden, omvatten contracten, geheimhoudingsovereenkomsten, acceptaties van voorstellen, wijzigingsopdrachten, reclamegoedkeuringen, werkovereenkomsten, onkostenrapporten, inkooporders, huurovereenkomsten, onafhankelijke aannemersovereenkomsten, nieuwe klantaanvragen, sollicitatieformulieren en nog veel meer. Conformiteitsformulieren zoals W-9, W-4 en I-9 zijn ideaal voor RightSignature&trade;. Moet een beleidswijziging door alle teamleden worden ondertekend? Geen probleem met RightSignature&trade;. Bedrijven versturen alles via RightSignature&trade;, van afgetekende timesheets tot miljoenencontracten.",
    "q10": "Welke browsers worden ondersteund?",
    "a10": "RightSignature&trade; biedt de beste gebruikerservaring in de volgende browsers. Om uw browser te upgraden naar de nieuwste versie, gaat u naar:",
    "a10part2": "Mozilla Firefox 3+, Microsoft Internet Explorer 9+, Apple Safari 3+, Google Chrome, Opera 9+",
    "q11": "Wat moet ik doen als ik me niet kan aanmelden?",
    "a11": "Controleer uw e-mailadres zorgvuldig om er zeker van te zijn dat u het niet verkeerd hebt getypt. Typ uw wachtwoord daarna langzaam en zorgvuldig in. Als dit niet werkt, kunt u uw wachtwoord opnieuw instellen door op 'Ik ben mijn wachtwoord vergeten' te klikken. Als u de stappen voor het opnieuw instellen van het wachtwoord volgt en u zich nog steeds niet kunt aanmelden, neemt u contact met ons op voor assistentie.",
    "q12": "Moeten ontvangers een RightSignature™-account hebben?",
    "a12": "Nee, ontvangers hoeven geen account te hebben om een ​​document van u te ontvangen. Er is geen limiet voor het aantal ontvangers aan wie u documenten verzendt. Wanneer u een document verzendt, krijgt de ontvanger een e-mail met een unieke koppeling. De ontvanger klikt op de koppeling om het document in de browser te bekijken en te ondertekenen, zonder downloads en zonder plug-ins. RightSignature&trade; is zo eenvoudig en intuïtief dat nieuwe ontvangers zonder instructies in seconden kunnen ondertekenen.",
    "q13": "Wat maakt RightSignature™ beter dan het aanbod van de concurrentie?",
    "a13": "RightSignature&trade; staat bekend om zijn elegante, intuïtieve gebruikerservaring en het vastleggen van echte, handgeschreven handtekeningen. De door ons gebruikte infrastructuur van Amazon Web Services biedt ongeëvenaarde beveiliging en schaalbaarheid. Dankzij voortdurende ontwikkeling en verbetering blijft RightSignature&trade; toonaangevend in zijn vooruitstrevendheid. Voor zelfstandige professionals en kleine bedrijven biedt RightSignature&trade; de eenvoudigste en meest effectieve online documentsoftware.",
    "q14": "Hoe ondersteunt RightSignature™ initiatieven om papierloos te werken?",
    "a14": "Gemotiveerd door milieuoverwegingen en kostenbesparingen, ondernemen veel kantoren papierloze initiatieven. RightSignature&trade; is een essentieel onderdeel in het verminderen van het papiergebruik en biedt een veilige, effectieve methode om documenten online te ondertekenen zonder afgedrukte exemplaren.",
    "q15": "Wat is een digitale handtekening?",
    "a15": "In het handtekeningveld van RightSignature&trade; kunt u met uw muis een ​​document ondertekenen en een geldige digitale handtekening maken volgens de bepalingen in de Amerikaanse en EU-wetgeving en -richtlijnen. Elk teken dat u in het handtekeningveld maakt is geldig, ook al lijkt het niet op uw handgeschreven handtekening. De rechtbanken hanteren een ruime interpretatie van het concept 'handtekening' en een handtekening hoeft feitelijk geen 'handtekening' in de traditionele zin van het woord te zijn. Een rubberstempel is bijvoorbeeld erkend als handtekening, evenals een teken gemaakt door een analfabeet. Beide onderschrijven het concept dat elk teken dat met de intentie tot ondertekening is gemaakt door een partij bij een contract, als een handtekening kan worden erkend. ",
    "a15part2": "<p>Documenten die online met een digitale handtekening zijn ondertekend, zijn rechtsgeldig en hebben dezelfde juridische status als papieren documenten die handmatig zijn ondertekend. De technologie van RightSignature&trade; is volledig in naleving van de Amerikaanse wetgeving inzake elektronische handtekeningen in de Global and National Commerce Act (ESIGN) uit het jaar 2000 en de Uniform Electronic Transactions Act (UETA), evenals de Europese richtlijn 1999/93/EG. Deze wetten waren alle bedoeld om de snelle invoering van elektronische handtekeningen aan te moedigen en het gebruik van verouderde papiermethoden te verminderen. De technologie van </p><p>RightSignature&trade; voldoet aan de Amerikaanse definitie van een 'elektronische handtekening': een elektronisch geluid, symbool of proces dat gekoppeld is aan of logisch geassocieerd wordt met een contract of ander document en gebruikt of gehanteerd wordt door een persoon met de intentie om het document te ondertekenen. De technologie van </p><p>RightSignature&trade; voldoet ook aan de strengere EU-vereisten van een 'geavanceerde elektronische handtekening':</p>",
    "a15part3": "  de handtekening is op unieke wijze verbonden met de ondertekenaar; kan worden gebruikt om de ondertekenaar te identificeren; is gemaakt met behulp van middelen die de ondertekenaar op eigen gezag kan beheren; en is gekoppeld aan de gegevens waarop deze betrekking heeft op zodanige wijze dat elke daaropvolgende verandering van de gegevens detecteerbaar is. Bronnen voor meer informatie: 'Electronic Signatures and Online Contracts', een artikel op Nolo.com, Richtlijn 1999/93/EG van het Europees Parlement, de Amerikaanse wetgeving Signatures in Global and National Commerce Act (ESIGN), Uniform Electronic Transactions Act (UETA)",
    "a15part4": "Zie voor meer informatie over de rechtsgeldigheid van documenten die met RightSignature&trade; zijn ondertekend onze websitepagina 'Legality'.",
    "q16": "Hoe weet ik dat de andere partij bij het contract de persoon is die ze zeggen dat ze zijn?",
    "a16": "Het verifiëren van de identiteit van een persoon is een belangrijk element van elke handtekening, zowel elektronisch als schriftelijk. Een elektronische handtekening moet het mogelijk maken een ondertekenaar te identificeren en moet uniek toe te schrijven zijn aan de ondertekenaar. RightSignature&trade; maakt gebruik van een multivariaat verificatieproces dat bestaat uit verificatie van e-mailadressen, bijhouden van IP-adressen, een biometrische handtekeningalgoritme en andere informatie. De verificatie bij RightSignature&trade; is aanzienlijk sterker dan bij papieren documenten, waar partijen vaak een assistent, echtgenoot of een andere persoon namens hen laten tekenen.",
    "q17": "Hoe weet u de locatie van de ondertekenaar?",
    "a17": "Elke computer op het internet wordt geïdentificeerd door een IP- of internetprotocoladres. Uw IP-adres is bijvoorbeeld 99.102.245.244. Aan de hand van dit adres kunnen we uw computer volgen naar een fysieke locatie. Hoewel dit niet 100% nauwkeurig is, biedt het partijen die RightSignature&trade; gebruiken een extra verificatiemiddel bij het ondertekenen van hun documenten.",
    "q18": "Kan een ondertekend document na ondertekening door een van de partijen worden gemanipuleerd?",
    "a18": "Nadat een document is geüpload en voor ondertekening is verzonden, wordt het vergrendeld en met redundante kopieën op een beveiligde server opgeslagen. Na ondertekening wordt het definitieve document gebonden aan een veilig hash-algoritme, dat de verzekering biedt dat geen enkele partij wijzigingen in een ondertekend document kan aanbrengen. De neutraliteit van RightSignature&trade; biedt verdere wettelijke bescherming tegen pogingen om een ​​uitgevoerde overeenkomst ongeldig te maken. Zie onze websitepagina 'Security' voor meer informatie over onze beveiligde gegevensinfrastructuur.",
    "q19": "Worden de digitale handtekeningen gecertificeerd door een derde instantie?",
    "a19": "Als een externe ondertekeningsservice die de werkstroom tussen documentondertekenaars coördineert, biedt RightSignature&trade; een aanzienlijke verbetering van de audit- en verificatiefuncties vergeleken met fax- en e-mailmethoden. Een voordeel van het gebruik van RightSignature&trade; is dat onze service een neutrale derde partij is, vergelijkbaar met een escrow-agent of borgstelling bij een vastgoedtransactie. Nadat een document is ondertekend wordt het door RightSignature&trade; gebonden aan een veilig hash-algoritme en met redundante kopieën opgeslagen met behulp van de Amazon Web Services-infrastructuur. In het geval dat een contract dat in RightSignature&trade; is ondertekend in een rechtzaak wordt aangevochten, dan zou het zeer moeilijk of onmogelijk zijn voor een partij om te beweren dat er met het document is geknoeid. Het vastgelegde RightSignature&trade;-proces en de vergrendeling na ondertekening staan garant voor verificatie door derden voor robuuste, verdedigbare contracten.",
    "q20": "Hoe weet ik zeker dat ik hetzelfde document onderteken als de andere partij?",
    "a20": "Elk document dat naar RightSignature&trade; wordt geüpload wordt gebonden aan een uniek referentienummer met een lange tekenreeks bestaande uit cijfers, hoofdletters, kleine letters en tekens. Dit referentienummer wordt vermeld in elke communicatie over het document en weergegeven in elk scherm van het document en garandeert dat alle partijen altijd hetzelfde document bekijken.",
    "a20part2": "Er is geen functie om het bestand te wijzigen nadat het is geüpload en voor ondertekening is verzonden. Als een document nog niet door een ontvanger is ondertekend, kan de verzendende partij het verwijderen en een nieuw document uploaden en verzenden, dat een nieuw referentienummer krijgt.",
    "q21": "Kan ik RightSignature™ aanpassen met mijn logo?",
    "a21": "Met RightSignature&trade;..."
  };
  _exports.faqModal = faqModal;
});