define("ember-rs/mixins/component-fillin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  PROPERTIES  ////////////////////////
    originalRequiredComponentsToFill: Ember.computed.filterBy('componentsToFill', 'isRequired'),
    requiredComponentsToFillComplete: Ember.computed.equal('requiredComponentsToFillRemaining', 0),
    componentValues: Ember.computed.mapBy('componentsToFill', 'componentValue'),
    requiredComponentValues: Ember.computed.mapBy('requiredComponentsToFill', 'componentValue'),
    completedRequiredComponentsToFill: Ember.computed.filterBy('requiredComponentValues', 'isValid'),
    requiredComponentsToFillRemaining: Ember.computed('completedRequiredComponentsToFill.[]', 'requiredComponentsToFill.[]', function () {
      return Ember.get(this, 'requiredComponentsToFill.length') - Ember.get(this, 'completedRequiredComponentsToFill.length');
    }),
    progressBarStyle: Ember.computed('requiredComponentsToFill.[]', 'completedRequiredComponentsToFill.[]', function () {
      var requiredProgress = parseFloat(Ember.get(this, 'completedRequiredComponentsToFill.length')) / parseFloat(Ember.get(this, 'requiredComponentsToFill.length'));
      return "width: ".concat(requiredProgress * 100, "%;").htmlSafe();
    }),
    requiredComponentsToFill: Ember.computed('originalRequiredComponentsToFill', function () {
      return this.originalRequiredComponentsToFill.filterBy('isDateSigned', false);
    }),
    sortedComponentsToFill: Ember.computed.sort('componentsToFill', function (a, b) {
      var a_y = a.get('y') + a.get('page');
      var b_y = b.get('y') + b.get('page');
      var a_x = a.get('x');
      var b_x = b.get('x');

      if (Math.abs(a_y - b_y) < 0.015) {
        if (a_x < b_x) {
          return -1;
        }

        if (a_x > b_x) {
          return 1;
        }

        return 0;
      } else {
        if (a_y < b_y) {
          return -1;
        }

        if (a_y > b_y) {
          return 1;
        }

        return 0;
      }
    }),
    // { <component.id> : <sorted index> }
    sortedComponentsToFillIndexMap: Ember.computed('sortedComponentsToFill', function () {
      var componentIndexMap = Ember.Object.create({});
      var roleName = Ember.get(this, 'model.roleName');
      var index = 1;
      this.sortedComponentsToFill.forEach(function (component) {
        if (component.get('type') !== 'signatureComponent' && component.get('type') !== 'signatureAnnotationComponent' && (component.get('assignedTo') === roleName || component.get('isMergeField'))) {
          componentIndexMap.set(component.get('id'), index++);
        } else {
          componentIndexMap.set(component.get('id'), -1);
        }
      });
      return componentIndexMap;
    }),
    nextRequiredField: Ember.computed('completedRequiredComponentsToFill.@each.isValid', 'sortedComponentsToFill', 'sortedComponentsIndexMap', function () {
      return Ember.get(this, 'sortedComponentsToFill').find(function (component) {
        return component.get('isRequired') && !component.get('componentValue.isValid') && !component.get('isDateSigned');
      });
    })
  });

  _exports.default = _default;
});