define("ember-rs/templates/components/rs-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oUwUfkIX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"rs-toggle-value\"],[12],[1,[30,[36,0],[\"components.rsToggle.on\"],null]],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"rs-toggle-value\"],[12],[1,[30,[36,0],[\"components.rsToggle.off\"],null]],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"rs-toggle-switch\"],[12],[13],[2,\"\\n\"],[10,\"input\"],[14,0,\"rs-toggle-input\"],[15,\"checked\",[34,1]],[15,\"disabled\",[34,2]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"isToggled\",\"isDisabled\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/rs-toggle.hbs"
    }
  });

  _exports.default = _default;
});