define("ember-rs/locales/fr/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alreadySigned = void 0;
  var alreadySigned = {
    "thankYou": "Merci d'avoir signé",
    "youHave": "Vous avez signé ce document avec succès. Vous pouvez consulter le document à tout moment en demandant un lien sécurisé.",
    "sendNewLink": "Envoyer nouveau lien de téléchargement",
    "successfully": "Vous avez signé ce document avec succès."
  };
  _exports.alreadySigned = alreadySigned;
});