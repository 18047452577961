define("ember-rs/locales/de/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitlementOptionsAdmin = void 0;
  var entitlementOptionsAdmin = {
    "title": "Willkommen bei RightSignature.",
    "hasSeats": "Sie haben derzeit Administratorrechte für dieses Konto. Das bedeutet, dass Sie sich und anderen Benutzern PRO-Zugriff und -Features gewähren können. Klicken Sie auf die Schaltfläche unten, um sich selbst diesen Zugriff zu gewähren.",
    "notNow": "Vielleicht später",
    "entitleMe": "PRO-Zugriff erhalten",
    "noSeats": "Alle Sitze in diesem Konto werden derzeit verwendet. Sie können jemanden entfernen, Sie können den <a href='https://support.citrix.com/cscase'>Support kontaktieren</a> oder uns unter <a href='tel:+18009214250'>1-800-921-4250</a> anrufen und mehr Sitze anfordern, oder fahren Sie einfach ohne bestimmte Features fort.",
    "proceed": "Fortfahren"
  };
  _exports.entitlementOptionsAdmin = entitlementOptionsAdmin;
});