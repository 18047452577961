define("ember-rs/templates/start-document/-in-person-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cko4dRy1",
    "block": "{\"symbols\":[\"accessible\",\"entitled\",\"free\",\"showModal\",\"buttonAction\",\"closeModalAction\"],\"statements\":[[2,\"\\n  \"],[10,\"li\"],[14,0,\"theme__workflow\"],[12],[2,\"\\n    \"],[8,\"paywall-button\",[],[[\"@action\",\"@accessible\"],[[30,[36,2],[[32,0],\"toggleShowMoreOptions\"],null],[34,3,[\"account\",\"inPerson\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[11,\"div\"],[16,0,[31,[\"card \",[30,[36,7],[[30,[36,6],[[35,5],[35,4]],null],\"is-saving\"],null]]]],[4,[38,2],[[32,0],\"startInPerson\"],null],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,5],[35,4]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[8,\"rs-spinner\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,0],[\"signinperson\"],null]],[2,\"\\n          \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"start-document.in-person.title\"],null]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"theme_generic-text\"],[12],[1,[30,[36,1],[\"start-document.in-person.description\"],null]],[13],[2,\"\\n\\n\"],[2,\"      \"],[8,\"rs-modal\",[],[[\"@show\",\"@action\"],[[30,[36,8],[[32,4]],null],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[\"start-document.in-person.upgrade-message\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2,3,4,5,6]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"t\",\"action\",\"currentUser\",\"inPerson\",\"saving\",\"and\",\"if\",\"readonly\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/start-document/-in-person-signature.hbs"
    }
  });

  _exports.default = _default;
});