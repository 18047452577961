define("ember-rs/locales/en/templates/reports", ["exports", "ember-rs/locales/en/templates/reports/export"], function (_exports, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    export: _export.exportTrans,
    "header": "Reports",
    "description": "View data for your sent documents. This includes how long it typically takes to get documents signed as well as how many documents have been completed by month. Upgrade your plan to access reports.",
    "select-report-type--text": "Select Report Type",
    "select-report-type-option-overview": "Overview",
    "overviewReport": "Overview Report",
    "select-report-type-option-efficiency": "Efficiency",
    "efficiencyReport": "Efficiency Report",
    "select-report-type-option-cycle-time": "Cycle Time Report",
    "select-report-type-option-export": "Data Exporter",
    "select-report-type-option-enterprise": "Enterprise",
    "enterpriseUsageReport": "Enterprise Usage Report",
    "userUsageReport": "User Usage Report",
    "select-report-type-option-user": "New Usage Report",
    "components": {
      "account-document-volume": {
        "header": "Account Document Volume",
        "description": "Shown below is the total sent and signed document volume by month on your account. <b>Note</b> that the current month shows a cumulative count of documents for this month through today.",
        "legend-document-sent": "Document Sent",
        "legend-document-signed": "Document Signed",
        "legend": "Legend:"
      },
      "account-statistics": {
        "header": "Account Statistics",
        "description": "Shown below are some of your account's current month averages along with both last month as well as all-time averages per month.",
        "column-header-current-month": "This Month",
        "column-header-previous-month": "Last Month",
        "column-header-average": "Average",
        "row-header-sent-documents": "Sent Documents",
        "row-header-viewed": "Viewed (by at least one signer)",
        "row-header-expired": "Expired",
        "row-header-signed": "Signed",
        "row-header-sent-documents-per-user": "Sent Documents per User (avg)",
        "row-header-signed-documents": "Signed Documents",
        "row-header-pending-documents": "Pending Documents",
        "row-header-viewed-pending": "Viewed (of pending)",
        "none": "N/A"
      },
      "top-users-by-signed-documents": {
        "header": "Top Users by Signed Documents",
        "description": "Shown below are the top 25 users according to signed documents in the last 3 months.",
        "column-header-user": "User",
        "column-header-signed-documents": "Signed Documents"
      },
      "account-efficiency": {
        "header": "Account Efficiency",
        "description": "Shown below is the current month's efficiency breakdown as well as the historical efficiency of the last few months.",
        "legend-pending": "Pending",
        "legend-signed": "Signed",
        "legend-expired": "Expired"
      },
      "top-users-by-efficiency": {
        "header": "Top Users by Efficiency",
        "description": "Shown below are the top 25 users according to Signed vs. Sent efficiency over the last 3 months.",
        "column-header-user": "User",
        "column-header-efficiency-percentage": "Efficiency %",
        "column-header-ratio": "Ratio",
        "over-gcd": "{{signed}} of {{sent}}"
      },
      "top-users-by-time-to-sign": {
        "header": "Quickest Time-to-Sign Users",
        "description": "Shown below are the top 25 users in the last 3 months according to mean time to sign.",
        "column-header-user": "User",
        "column-header-mean-time": "Mean Time (Hours)"
      },
      "average-cycle-time": {
        "header": "Mean Cycle Time",
        "description": "Shown below are the average cycle times (in hours) for your account's documents.",
        "legend-mean-time-to-view": "Mean Time to View",
        "legend-mean-time-to-sign": "Mean Time to Sign"
      },
      "cycle-time-statistics": {
        "header": "Cycle Time Statistics",
        "description": "Shown below are some statistics on document cycle time from your account.",
        "column-header-current-month": "This Month",
        "column-header-previous-month": "Last Month",
        "column-header-all-time": "All Time",
        "row-header-under-5-mins": "Under 5 Minutes",
        "row-header-under-30-mins": "Under 30 Minutes",
        "row-header-under-1-hour": "Under 1 Hour",
        "row-header-under-12-hours": "Under 12 Hours",
        "row-header-under-1-day": "Under 1 Day"
      },
      "month-export": {
        "header": "Select a month",
        "description": "We will select the documents that were sent in the following month",
        "export-to-csv-section-header": "Export to CSV",
        "export-to-csv-section-description": "A CSV can be opened in Excel to view data on your sent documents."
      },
      "generate-download": {
        "ie-warning-text": "Please right click 'Export CSV' and choose save-as to download the CSV.",
        "export-csv-button-text": "Export CSV",
        "generate-csv-button-text": "Generate CSV",
        "send-download-link": "We will generate the report and send the download link through email."
      }
    }
  };
  _exports.reports = reports;
});