define("ember-rs/routes/signer/dismiss-reminders", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    queryParams: {
      access_token: {}
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var signerId = this.paramsFor('signer').signer_id;
      var accessToken = transition.to.queryParams.access_token;

      if (Ember.isBlank(signerId) || Ember.isBlank(accessToken)) {
        this.flashMessages.warning(this.i18n.t('components.signers.missing-access'));
        this.transitionTo('unauthorized.unauthorized');
        return;
      }

      new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/signers/").concat(signerId, "/dismiss_reminders"),
          type: 'POST',
          data: {
            access_token: accessToken
          }
        }).then(function (response) {
          _this.flashMessages.success(_this.i18n.t('components.signers.unsubscribed'));

          _this.transitionTo('unauthorized.unsubscribed');

          resolve(response);
        }, function (response) {
          _this.transitionTo('unauthorized.unauthorized');

          reject(response);
        });
      }, 'dismiss reminders for a document');
    }
  });

  _exports.default = _default;
});