define("ember-rs/locales/de/templates/components/restyle-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.restyleOnboarding = void 0;
  var restyleOnboarding = {
    "welcome": "Willkommen!",
    "introduceNewLook": "Wir freuen uns, den neuen Look für RightSignature vorstellen zu können. Sehen Sie sich um!",
    "gotIt": "Alles klar."
  };
  _exports.restyleOnboarding = restyleOnboarding;
});