define("ember-rs/locales/nl/controllers/document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "notAllowed": "U bent niet gemachtigd om dit te doen.",
    "voided": "Document is ongeldig gemaakt.",
    "voidingFailed": "Kan document niet ongeldig maken.",
    "docExtended": "Het document is succesvol verlengd.",
    "tagUpdated": "De tags zijn bijgewerkt",
    "tagUpdateFailed": "Kan de tags niet bijwerken: {{{tags}}} ",
    "viewerListUpdated": "De lijst met waarnemers is bijgewerkt",
    "viewerUpdateFailed": "Kan de lijst met waarnemers niet bijwerken: {{{viewers}}}"
  };
  _exports.details = details;
});