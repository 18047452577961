define("ember-rs/locales/pt-br/controllers/signer/sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    "validPhone": "Insira um número de telefone válido",
    "passcode": "Código secreto inválido. Tente novamente."
  };
  _exports.sign = sign;
});