define("ember-rs/locales/nl/templates/components/integration-document-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocumentSelector = void 0;
  var integrationDocumentSelector = {
    "chooseDocFrom": "Kies een document uit:",
    "loading": "Laden...",
    "back": "Terug",
    "search": "Documenten zoeken...",
    "use": "Gebruik",
    "empty": "Deze map is leeg",
    "success": "Verzenden is uitgeschakeld voor {{{provider}}}",
    "error": "Fout. Verzenden kan niet worden gewijzigd voor {{{provider}}}"
  };
  _exports.integrationDocumentSelector = integrationDocumentSelector;
});