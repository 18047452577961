define("ember-rs/components/step-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['isCurrentStep:current-step', ':step-dot'],
    click: function click() {
      this.sendAction('goToStep', this.step);
    },
    step: 1,
    currentStep: 1,
    isCurrentStep: Ember.computed('currentStep', 'step', function () {
      return this.currentStep === this.step;
    })
  });

  _exports.default = _default;
});