define("ember-rs/locales/es/templates/onboarding/send-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding2 = void 0;
  var sendOnboarding2 = {
    "status": "Aquí es donde se puede ver el <span>estado</span> del documento"
  };
  _exports.sendOnboarding2 = sendOnboarding2;
});