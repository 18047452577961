define("ember-rs/locales/zh-cn/templates/components/transaction-manager-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transactionManagerHeader = void 0;
  var transactionManagerHeader = {
    "close": "关闭",
    "sendDocument": "发送文档",
    "signDocument": "对文档进行签名",
    "inPerson": "本人签名",
    "broken": "已损坏"
  };
  _exports.transactionManagerHeader = transactionManagerHeader;
});