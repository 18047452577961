define("ember-rs/components/reports/month-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    generateData: Ember.computed('monthSelected', function () {
      return {
        month: this.monthSelected
      };
    }),
    actions: {
      monthWasSelected: function monthWasSelected() {
        this.set('downloadURL', null);
      }
    }
  });

  _exports.default = _default;
});