define("ember-rs/locales/it/templates/onboarding/post-sign-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding1 = void 0;
  var postSignOnboarding1 = {
    "tapGear": "<span>Toccare l'ingranaggio</span> per scaricare il documento e le informazioni aggiuntive.",
    "viewDoc": "Qui è possibile visualizzare lo <span>stato</span> del documento"
  };
  _exports.postSignOnboarding1 = postSignOnboarding1;
});