define("ember-rs/locales/pt-br/controllers/controllers", ["exports", "ember-rs/locales/pt-br/controllers/reusable-template/add-roles", "ember-rs/locales/pt-br/controllers/template/details", "ember-rs/locales/pt-br/controllers/account/branding", "ember-rs/locales/pt-br/controllers/account/users", "ember-rs/locales/pt-br/controllers/document/details", "ember-rs/locales/pt-br/controllers/document/unlock", "ember-rs/locales/pt-br/controllers/client-applications/new", "ember-rs/locales/pt-br/controllers/signer/sign", "ember-rs/locales/pt-br/controllers/unauthorized/identity-expired", "ember-rs/locales/pt-br/controllers/help", "ember-rs/locales/pt-br/controllers/upgrade-worldpay"], function (_exports, _addRoles, _details, _branding, _users, _details2, _unlock, _new, _sign, _identityExpired, _help, _upgradeWorldpay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.controllers = void 0;
  var controllers = {
    reusableTemplate: {
      addRoles: _addRoles.addRoles
    },
    template: {
      details: _details.details
    },
    account: {
      branding: _branding.branding,
      newUser: _users.newUser
    },
    document: {
      details: _details2.details,
      unlock: _unlock.unlock
    },
    clientApplications: {
      newClientApplication: _new.newClientApplication
    },
    signer: {
      sign: _sign.sign
    },
    unauthorized: {
      identityExpired: _identityExpired.identityExpired
    },
    help: _help.help,
    upgradeWorldpay: _upgradeWorldpay.upgradeWorldpay
  };
  _exports.controllers = controllers;
});