define("ember-rs/templates/components/reports/top-users-by-time-to-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mdft09du",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[10,\"div\"],[14,0,\"report-section\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"reports.components.top-users-by-time-to-sign.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"description\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"reports.components.top-users-by-time-to-sign.description\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"table\"],[14,0,\"statistics-table\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[1,[30,[36,1],[\"reports.components.top-users-by-time-to-sign.column-header-user\"],null]],[13],[2,\"\\n        \"],[10,\"td\"],[12],[1,[30,[36,1],[\"reports.components.top-users-by-time-to-sign.column-header-mean-time\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"top_users\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"td\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n          \"],[10,\"td\"],[12],[1,[30,[36,0],[[32,1,[\"mean_time\"]]],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"round\",\"t\",\"report\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/reports/top-users-by-time-to-sign.hbs"
    }
  });

  _exports.default = _default;
});