define("ember-rs/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y4h8WW4Q",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"delete-document-modal__header\"],[12],[1,[30,[36,0],[\"components.deleteDocumentModal.deleteDocument\"],null]],[13],[2,\"\\n\\n\"],[10,\"p\"],[14,0,\"delete-document-modal__info\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.deleteDocumentModal.permanentlyDeleted\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"delete-document-modal__info\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"components.deleteDocumentModal.email\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"p\"],[14,0,\"delete-document-modal__info\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.deleteDocumentModal.briefMoment\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"delete-document-modal__actions\"],[12],[2,\"\\n  \"],[8,\"rs-button\",[],[[\"@action\",\"@isLoading\"],[[30,[36,4],[[35,3]],null],[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.deleteDocumentModal.delete\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"rs-button\",[],[[\"@action\",\"@isPrimary\",\"@isDisabled\"],[[30,[36,6],[[32,0],\"close\"],null],false,[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.deleteDocumentModal.cancel\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"isArchivedDocument\",\"unless\",\"deleteDocumentTask\",\"perform\",\"deleting\",\"action\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/delete-document-modal.hbs"
    }
  });

  _exports.default = _default;
});