define("ember-rs/locales/nl/templates/components/attached-completed-pdf-confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.attachedCompletedPdfConfirmationModal = void 0;
  var attachedCompletedPdfConfirmationModal = {
    "header": "HIPAA Confirmation",
    "info": "All recipients will receive a copy of the signed document as an email attachment when this setting is on. As a HIPAA customer, it's your responsibility to confirm that recipients allow their information to be handled this way.",
    "infoSecondary": "Are you sure you want to turn this setting on?",
    "confirm": "Confirm",
    "cancel": "Cancel"
  };
  _exports.attachedCompletedPdfConfirmationModal = attachedCompletedPdfConfirmationModal;
});