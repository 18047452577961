define("ember-rs/locales/en/templates/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerPaymentDetails = void 0;
  var signerPaymentDetails = {
    "paymentInfo": "Payment Info",
    "paymentID": "Payment ID:",
    "retryPayment": "Retrying payment...",
    "failed": "Failed",
    "chargedSuccess": "Charged successfully at {{chargedAt}}.",
    "stripeCustomer": "Stripe Customer",
    "stripeCharge": "Stripe Charge",
    "attempted": "Last attempted at {{chargedAt}}.",
    "retry": "Retry",
    "pending": "Pending...",
    "success": "Payment succeeded on retry.",
    "retryError": "Payment failed to retry ({{{errorMsg}}})."
  };
  _exports.signerPaymentDetails = signerPaymentDetails;
});