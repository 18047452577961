define("ember-rs/locales/de/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFound = void 0;
  var notFound = {
    "doesNotExist": "Diese Seite existiert nicht.",
    "didYou": "Sind Sie einem Link von woanders in RightSignature&trade; gefolgt?",
    "anotherPart": "Wenn Sie diese Seite von einem anderen Bereich in RightSignature&trade; erreicht haben, wenden Sie sich an den <a href='https://support.citrix.com/cscase'>Support</a>, damit wir den Fehler beheben können.",
    "followLink": "Sind Sie einem Link von einer anderen Site gefolgt?",
    "otherSite": "Links von anderen Sites sind u. U. veraltet oder falsch geschrieben. Wenden Sie sich an den <a href='https://support.citrix.com/cscase'>Support</a> und teilen Sie uns mit, welcher Link Sie hierher geleitet hat, damit wir die andere Site kontaktieren und das Problem beheben können.",
    "typeUrl": "Haben Sie die URL eingegeben?",
    "doubleCheck": "Möglicherweise haben Sie die Adresse (URL) falsch eingegeben. Vergewissern Sie sich, dass die Schreibweise, Groß-/Kleinschreibung usw. genau richtig sind.",
    "home": "Home"
  };
  _exports.notFound = notFound;
});