define("ember-rs/locales/zh-cn/templates/components/review-and-send-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewAndSendItem = void 0;
  var reviewAndSendItem = {
    "passcode-message": "请谨记! 必须将此 PIN 发送给文档的签名者。",
    "bulk-send-passcode-message": "您已为文档交易启用密码。批量发送目前支持此功能。",
    "generate-new-pin": "生成新 PIN",
    "pin-specification": "PIN 必须是五位数字。",
    "expiration-message": "过期日期之后，该文档将不再可供签名。",
    "ExpiresIn": "过期时间",
    "days": "天",
    "loading": "正在加载",
    "page": "页",
    "emailTip1": "用于查看此文档的链接将被发送至 {{{email}}}。",
    "emailTip2": "收件人将能够查看和下载此文档。",
    "enterEmail": "输入电子邮件地址",
    "shareDoc": "输入电子邮件地址以共享此文档",
    "ccMsg": "抄送各方以授予他们对此文档的只读访问权限。收件人将收到通知、能够查看进度，以及下载已完成的文档。您可以根据需要稍后撤消其访问权限。",
    "add": "添加",
    "roles": "角色",
    "recipients": "收件人",
    "cc": "抄送: ",
    "ccCaps": "抄送",
    "messageLimit": "邮件不能超过 100000 个字符。",
    "addMsg": "添加个性化消息...",
    "for": "用于",
    "message": "消息",
    "pin": "PIN",
    "tags": "标记",
    "passcodeOn": "打开通行码",
    "passcodeOff": "关闭通行码",
    "restrictedFeatureMsg": "您当前的计划不允许您将通行码添加到文档中。请升级您的计划以访问此功能。",
    "kba": "KBA",
    "on": "开",
    "off": "关",
    "kbaTip": "要求签名者通过知识问答测验验证其身份。每个签名者都需要在对文档进行签名之前单独验证。尝试 3 次失败后，签名者将被锁定，无法对文档进行签名。",
    "enable": "启用",
    "addTag": "添加标记",
    "tagTip": "向此文档添加标记以便以后轻松进行查找。还可以向标记中添加值，例如“client_id: 1234”",
    "addSalesforceOpportunity": "添加 Salesforce 机会",
    "addSalesforceOpportunityMsg": "向此文档添加 Salesforce 机会",
    "exceededKbaLimit": "达到 KBA 限制",
    "exceededKbaMsg": "您已达到启用了 KBA 的文档的当前订阅的月度限制。要在启用了基于知识的身份验证功能的情况下发送更多文档，请<a href='https://support.citrix.com/cscase'>联系技术支持</a>以升级您的帐户。",
    "tagName": "标记名称...",
    "tagValue": "值(可选)",
    "sendTo": "发送到",
    "name": "名称",
    "docName": "文档名称...",
    "totalNumberOfSigners": "签名者总数:",
    "expiresIn": "有效期必须介于 1 到 365 天之间。"
  };
  _exports.reviewAndSendItem = reviewAndSendItem;
});