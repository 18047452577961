define("ember-rs/locales/it/templates/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "send": "Invia per la firma",
    "notAllowed": " Il piano corrente non consente di inviare documenti. Aggiornare il piano per accedere a questa funzionalità.",
    "embed": "Incorpora nel sito Web",
    "share": "Genera collegamento di condivisione",
    "manageLinks": "Gestisci collegamenti e incorporamenti",
    "viewResponses": "Visualizza risposte",
    "edit": "Modifica",
    "delete": "Elimina",
    "roles": "Ruoli",
    "security": "Opzioni di sicurezza:",
    "expires": "Scade tra:",
    "days": "giorni",
    "passcodeQuestion": "Domanda per il passcode:",
    "passcode": "Passcode:",
    "tags": "Contrassegni:",
    "linkHistory": "Collega e incorpora la cronologia dei codici",
    "linkHistoryTip": "Gestisci e visualizza le informazioni di utilizzo per tutti i collegamenti e gli incorporamenti di questo modello.",
    "generate": "Genera collegamento di condivisione",
    "nameLink": "Assegnare un nome a questo collegamento di condivisione.",
    "nameLinkTip": "Questo può semplificare la gestione dei collegamenti.",
    "whichSigner": "Quale ruolo di firmatario?",
    "whichSignerTip": "I modelli e i collegamenti incorporati possono avere solo un firmatario.",
    "limitUses": "Limitare il numero di utilizzi?",
    "limitUsesTip": "Impostare un limite per il numero di volte in cui questo collegamento può essere firmato.",
    "expireDays": "Far scadere dopo un certo numero di giorni?",
    "expireDaysTip": "Dopo la scadenza questo collegamento non funzionerà più.",
    "identityMethod": "Metodo di identità:",
    "identityMethodTip": "Come dobbiamo verificare l'identità dei firmatari?",
    "generateLink": "Genera collegamento",
    "generateEmbed": "Genera codice di incorporamento",
    "nameCode": "Assegnare un nome a questo codice.",
    "nameCodeTip": "Questo può semplificare la gestione dei codici generati.",
    "displayType": "Tipo di visualizzazione",
    "displayTypeTip": "Specifica come deve apparire l'incorporamento",
    "pixel": "px",
    "codeGenerated": "Codice generato",
    "linkGenerated": "Collegamento generato",
    "clickingManage": "by clicking on “Manage Links &amp; Embeds” on the template page.",
    "youCanAccessCode": "È possibile accedere a questo codice, disabilitarlo e modificarlo facendo clic su \"Gestisci collegamenti e incorporamenti\" nella pagina del modello.",
    "youCanAccessLink": "È possibile accedere a questo collegamento, disabilitarlo e modificarlo facendo clic su \"Gestisci collegamenti e incorporamenti\" nella pagina del modello.",
    "newPin": "Nuovo PIN",
    "done": "Fine",
    "email": "E-mail",
    "sms": "SMS",
    "none": "Nessuno",
    "height": "Altezza",
    "Width": "Larghezza",
    "button": "pulsante",
    "image": "immagine",
    "inline": "in linea",
    "createdBy": "Creato da",
    "actions": "Azioni",
    "visibility-toggle-text": "Imposta modello su privato",
    "visibility-toogle-info": "L'impostazione privata renderà i modelli visibili solo al suo creatore",
    "template-signer-link": {
      "passcode-message": "La funzionalità di passcode è stata abilitata per questo account, ma non verrà applicata per i collegamenti di condivisione o i documenti incorporati. Questo codice di collegamento/incorporamento consentirà l'accesso a questo documento senza il passcode."
    },
    "embed-in-website": {
      "disabled-merge-fields": "I codici di incorporamento non sono attualmente disponibili per questo modello. Per generare un codice di incorporamento per questo modello, rimuovere innanzitutto tutti i campi di unione obbligatori.",
      "disabled-no-online-forms": "Il piano corrente non consente di incorporare modelli. Aggiornare il piano per accedere a questa funzionalità."
    },
    "generate-share-link": {
      "disabled-merge-fields": "I codici di incorporamento non sono attualmente disponibili per questo modello. Per generare un codice di incorporamento per questo modello, rimuovere innanzitutto tutti i campi di unione obbligatori.",
      "disabled-no-manual-distribution": "Il piano corrente non consente di generare collegamenti di condivisione. Aggiornare il piano per accedere a questa funzionalità."
    }
  };
  _exports.details = details;
});