define("ember-rs/locales/de/mixins/integration-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocuments = void 0;
  var integrationDocuments = {
    "enable_sending": "Das Senden wurde für {{{provider}}} aktiviert",
    "enable_archiving": "Das Archivieren wurde für {{{provider}}} aktiviert",
    "enable_contacts": "Kontakte wurden für {{{provider}}} aktiviert",
    "enable_payments": "Zahlungen wurden für {{{provider}}} aktiviert",
    "disable_sending": "Das Senden wurde für {{{provider}}} deaktiviert",
    "disable_archiving": "Das Archivieren wurde für {{{provider}}} deaktiviert",
    "disable_contacts": "Kontakte wurden für {{{provider}}} deaktiviert",
    "disable_payments": "Zahlungen wurden für {{{provider}}} deaktiviert",
    "errorToggle": "Fehler. Ändern von {{{service}}} für {{{provider}}} nicht möglich!",
    "errorRetrieve": "Fehler. Dokument konnte nicht abgerufen werden.",
    "errorProviderRetrieve": "Fehler. Dokumente von {{{provider}}} konnten nicht abgerufen werden."
  };
  _exports.integrationDocuments = integrationDocuments;
});