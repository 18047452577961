define("ember-rs/locales/zh-cn/controllers/document/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "success": "已解锁所有签名者",
    "error": "无法解锁签名者，请稍后再试。"
  };
  _exports.unlock = unlock;
});