define("ember-rs/locales/it/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.application = void 0;
  var application = {
    "logout": "Scollega",
    "start": "Avvia",
    "startDocument": "Inizia documento",
    "help": "Guida"
  };
  _exports.application = application;
});