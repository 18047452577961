define("ember-rs/locales/en/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteDocumentModal = void 0;
  var deleteDocumentModal = {
    "permanentlyDeleted": "This will permanently delete the document from RightSignature. Once this is done, there is no undo.",
    "email": "Signers and CC's will receive an email with the completed document and signature certificate for their records.",
    "briefMoment": "It may take a brief moment for the document to disappear from your dashboard.",
    "delete": "Delete",
    "cancel": "Cancel",
    "deleteDocument": "Delete Document",
    "deleteComplete": "Deleted document",
    "deleteFailed": "Failed to destroy document"
  };
  _exports.deleteDocumentModal = deleteDocumentModal;
});