define("ember-rs/locales/zh-cn/templates/onboarding/prepare-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding1 = void 0;
  var prepareOnboarding1 = {
    "useTools": "请对<span>签名者需要填写的字段</span>使用这些工具"
  };
  _exports.prepareOnboarding1 = prepareOnboarding1;
});