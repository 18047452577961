define("ember-rs/locales/de/templates/components/rs-left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsLeftNav = void 0;
  var rsLeftNav = {
    "Documents": "Dokumente",
    "Templates": "Vorlagen",
    "Account": "Konto",
    "Settings": "Einstellungen",
    "Integrations": "Integrationen",
    "Branding": "Branding",
    "Users": "Benutzer",
    "Reports": "Berichte",
    "Api": "API",
    "Billing": "Abrechnung",
    "Workflows": "Feedback und Genehmigung"
  };
  _exports.rsLeftNav = rsLeftNav;
});