define("ember-rs/locales/fr/templates/unauthorized/document-executed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExecuted = void 0;
  var documentExecuted = {
    "docComplete": "Ce document a été complété",
    "toDownload": "Pour télécharger ce document, demandez un nouveau lien de téléchargement.",
    "sendLink": "Envoyer nouveau lien de téléchargement"
  };
  _exports.documentExecuted = documentExecuted;
});