define("ember-rs/locales/fr/templates/components/document-upload-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploadThumbnail = void 0;
  var documentUploadThumbnail = {
    "noThumb": "Aucune miniature...",
    "upload_done": "chargement terminé",
    "downloading": "téléchargement",
    "storing": "stockage",
    "converting_to_images": "conversion en images",
    "converting_to_pdf": "conversion en PDF",
    "processing": "traitement",
    "created": "créé"
  };
  _exports.documentUploadThumbnail = documentUploadThumbnail;
});