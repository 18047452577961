define("ember-rs/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qvJJC0nF",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[4,[38,0],[[32,0],\"confirm\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"email\"],[12],[1,[30,[36,1],[\"components.newUserForm.email\"],null]],[13],[8,\"limited-input-text-field\",[],[[\"@disabled\",\"@value\"],[[34,2],[34,3,[\"email\"]]]],null],[2,\"\\n\\n  \"],[10,\"label\"],[14,\"for\",\"name\"],[12],[1,[30,[36,1],[\"components.newUserForm.name\"],null]],[13],[8,\"limited-input-text-field\",[],[[\"@value\"],[[34,3,[\"name\"]]]],null],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"password\"],[12],[1,[30,[36,1],[\"components.newUserForm.password\"],null]],[13],[8,\"limited-input-text-field\",[],[[\"@type\",\"@value\"],[[34,4],[34,3,[\"password\"]]]],null],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"hide_password\"],[12],[8,\"input\",[],[[\"@type\",\"@name\",\"@checked\"],[\"checkbox\",\"hidePassword\",[34,5]]],null],[2,\" \"],[1,[30,[36,1],[\"components.newUserForm.hidePassword\"],null]],[13],[2,\"\\n\\n  \"],[10,\"button\"],[14,4,\"submit\"],[12],[1,[30,[36,1],[\"components.newUserForm.create\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"links\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[\"components.newUserForm.haveAccount\"],null]],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"standard-link\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Sign In\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"emailFromParam\",\"model\",\"passwordFieldType\",\"hidePassword\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/new-user-form.hbs"
    }
  });

  _exports.default = _default;
});