define("ember-rs/locales/de/templates/mobile-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mobileNavigation = void 0;
  var mobileNavigation = {
    "documents": "Dokumente",
    "templates": "Vorlagen",
    "settings": "Ihre Einstellungen",
    "integrations": "Integrationen",
    "users": "Benutzer",
    "reports": "Berichte",
    "help": "Hilfe",
    "logout": "Abmelden"
  };
  _exports.mobileNavigation = mobileNavigation;
});