define("ember-rs/locales/en/mixins/workflow-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.workflowType = void 0;
  var workflowType = {
    "signatureRequest": "Sent by you",
    "selfSign": "Self signed",
    "templateSignerLink": "From template",
    "inPerson": "In person signing",
    "apiEmbedded": "Api Embedded signing",
    "bulkSend": "Bulk Send"
  };
  _exports.workflowType = workflowType;
});