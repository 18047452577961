define("ember-rs/templates/components/support-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6E7RRW+B",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"h4\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[10,\"iframe\"],[14,1,\"video_iframe\"],[14,0,\"vimeo-video\"],[15,\"src\",[31,[\"https://player.vimeo.com/video/\",[34,1],\"?autoplay=1&title=0&byline=0&portrait=0\"]]],[14,\"frameborder\",\"0\"],[14,\"webkitallowfullscreen\",\"\"],[14,\"mozallowfullscreen\",\"\"],[14,\"allowfullscreen\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"loading-spinner\",[],[[\"@size\"],[24]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"videoName\",\"videoID\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/support-video.hbs"
    }
  });

  _exports.default = _default;
});