define("ember-rs/locales/de/templates/components/reminder-frequency-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reminderFrequency = void 0;
  var reminderFrequency = {
    "noAuto": "Keine automatischen Erinnerungen",
    "daily": "Täglich",
    "weekly": "Wöchentlich"
  };
  _exports.reminderFrequency = reminderFrequency;
});