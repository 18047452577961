define("ember-rs/models/payment-component", ["exports", "ember-rs/models/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    isValid: Ember.computed('isValidPaymentAmount', function () {
      return this._super() && this.isValidPaymentAmount;
    }),
    paymentType: Ember.computed("componentValue.value", function () {
      if (this.get('componentValue.value')) {
        return "New Credit Card";
      } else if (this.chargeAfterExecuted) {
        return "Authorize Payment";
      } else {
        return "Collect Credit Card";
      }
    }),
    isValidPaymentAmount: Ember.computed('paymentAmount', 'chargeAfterExecuted', function () {
      if (this.chargeAfterExecuted) {
        var amountFloat = parseFloat(this.paymentAmount);
        return !isNaN(amountFloat) && amountFloat > 0;
      } else {
        return true;
      }
    }),
    isInvalidPaymentAmount: Ember.computed.not('isValidPaymentAmount')
  });

  _exports.default = _default;
});