define("ember-rs/locales/pt-br/routes/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    "embeddedTemplate": "Sua conta não tem acesso a esse recurso. Atualize para um plano Pro para poder usar modelos incorporados.",
    "inPerson": "Sua conta não tem acesso a este recurso. Faça upgrade para um plano Pro para começar a usar o InPerson Signing."
  };
  _exports.startDocument = startDocument;
});