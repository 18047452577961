define("ember-rs/templates/components/rs-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jd/rPdIP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"button\"],[14,0,\"rs-actions-toggle\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.rsActions.Actions\"],null]],[2,\" \"],[10,\"i\"],[15,0,[31,[\"fa \",[30,[36,2],[[35,1],\"fa-caret-up\",\"fa-caret-down\"],null]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"showDownloads\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/rs-actions.hbs"
    }
  });

  _exports.default = _default;
});