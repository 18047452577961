define("ember-rs/locales/it/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingComplete = void 0;
  var signingComplete = {
    "docSigned": "Documento firmato",
    "createTrial": "Creare un account RightSignature gratuito per visualizzare o scaricare il documento completato. Si avrà accesso al documento per sempre, è sufficiente registrarsi con l'indirizzo e-mail a cui il documento è stato inviato e accedere.",
    "createAccount": "Creare un account",
    "login": "Accedere all'account",
    "notNow": "Non ora"
  };
  _exports.signingComplete = signingComplete;
});