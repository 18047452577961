define("ember-rs/locales/de/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers = void 0;
  var prepare_signers = {
    "add_signers": "Unterzeichner hinzufügen",
    "doc_limit_p1": "Sie haben ",
    "doc_limit_p2": "Ihres Dokumentlimits für den Monat verwendet. Kontaktieren Sie uns, um ein Upgrade für Ihr Konto zu erhalten.",
    "recentContacts": "Letzte Kontakte"
  };
  _exports.prepare_signers = prepare_signers;
});