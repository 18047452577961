define("ember-rs/locales/it/templates/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faqModal = void 0;
  var faqModal = {
    "docId": "ID documento:",
    "contactSender": "Contattare il mittente: {{{userName}}} all'indirizzo <a href='mailto: {{{userEmail}}}'>{{{userEmail}}}</a> per eventuali domande",
    "needHelp": "Serve assistenza nell'utilizzo di RightSignature&trade;? Provare a consultare queste domande e risposte comuni:",
    "q1": "Domanda sui contenuti di questo documento:",
    "rsNote": "RightSignature&trade; è un servizio di terze parti per la firma di documenti online. Facilitiamo l'invio e la firma di documenti con firme legali, sicure, elettroniche, tuttavia non siamo associati in alcun modo con il mittente o il documento.",
    "q2": "Come si completa questo documento?",
    "a2": "La freccia rossa a sinistra del documento indicherà il campo obbligatorio successivo. Una volta completati tutti i campi obbligatori, il pulsante \"Invia\" sarà disponibile.",
    "q3": "È legalmente vincolante?",
    "a3": "Sì. I documenti che sono stati resi esecutivi online con firme elettroniche sono legalmente validi e hanno lo stesso valore legale dei documenti cartacei resi esecutivi a mano. È altamente probabile che siano già stati sottoscritti contratti online. Ad esempio, quando si fa clic su un pulsante \"Accetto\" su Internet, di solito si sottoscrive un contratto detto \"clickwrap\". Inoltre, quando si fanno acquisti online si sottoscrive un contratto legalmente vincolante.",
    "a3part2": "La tecnologia di RightSignature&trade; è pienamente conforme alle normative statunitensi del 2000 ESIGN (Electronic Signatures in Global and National Commerce Act) e UETA (Uniform Electronic Transactions Act), nonché alla direttiva europea 1999/93/CE. Tutte queste normative mirano a incoraggiare la rapida adozione delle firme elettroniche e ridurre l'uso di metodi cartacei antiquati. Ciascuna di queste normative sottolinea la validità dei contratti elettronici. Secondo la normativa ESIGN, ad esempio, non è possibile negare l'efficacia giuridica, la validità o l'applicabilità di un contratto esclusivamente perché nella sua preparazione è stato utilizzato un record elettronico o una firma elettronica.",
    "q4": "Che cos'è RightSignature™?",
    "a4": "RightSignature&trade; consente di creare e firmare documenti legalmente vincolanti online, in modo più veloce e più sicuro rispetto all'impiego di documenti cartacei. RightSignature&trade; è un software basato su Web che utilizza l'infrastruttura dati sicura di Amazon Web Services.",
    "q5": "Che cosa fa RightSignature™?",
    "a5": "RightSignature&trade; consente di creare e firmare documenti legalmente vincolanti online, in modo più veloce e più sicuro rispetto all'impiego di documenti cartacei. RightSignature&trade; è un software basato su Web che utilizza l'infrastruttura dati sicura di Amazon Web Services.",
    "q6": "In che modo RightSignature™ abilita la firma di documenti online?",
    "a6": "RightSignature&trade; consente di ottenere facilmente firme online da più persone. È sufficiente caricare il documento e inserire le informazioni sul destinatario. RightSignature&trade; invia tramite e-mail ai destinatari un collegamento univoco a una pagina in cui possono esaminare il documento, compilare i campi di testo obbligatori e firmare. I destinatari possono firmare online con il mouse in pochi secondi.",
    "a6part2": "Oggi la maggior parte delle aziende firma documenti inviando e ricevendo fax, inviando buste per posta celere o scansionando e inviando pagine firmate via e-mail. RightSignature&trade; elimina la scomodità, i ritardi di tempo e le spese che questi metodi tediosi e antiquati comportano. È possibile rendere esecutivo un documento legalmente vincolante e archiviarlo in modo sicuro su un server affidabile in pochi minuti, con pochi clic del mouse.",
    "q7": "Non è più sicuro firmare un documento cartaceo?",
    "a7": "I contratti elettronici sono decisamente più sicuri e più affidabili dei contratti cartacei e hanno lo stesso valore legale. Mentre i documenti inviati via fax e posta sono esposti a rischi durante la distribuzione tra un ufficio e l'altro, i documenti elettronici vengono mantenuti completamente riservati e vengono visualizzati solo dai destinatari designati. I documenti di RightSignature&trade; vengono archiviati su server sicuri con copie ridondanti e, a differenza dei documenti cartacei, non possono essere smarriti, persi o distrutti. Inoltre, le ricevute e-mail, i record IP e i dati biometrici delle firme forniscono l'autenticazione di ogni firmatario.",
    "q8": "A chi è destinato questo servizio?",
    "a8": "RightSignature&trade; è ideale per piccole e medie imprese, liberi professionisti, progettisti, sviluppatori, procuratori legali, lavoratori a contratto, scuole, organizzazioni no profit e molti altri tipi di attività. Se il lavoro da svolgere richiede la firma documenti, RightSignature&trade; è lo strumento giusto.",
    "q9": "Quali tipi di documenti è possibile inviare?",
    "a9": "I documenti inviati regolarmente con RightSignature&trade; includono contratti, accordi di non divulgazione, accettazioni di proposte, ordini di cambiamento, bozze di annunci, lettere di incarico, note spese, ordini di acquisto, contratti di noleggio, contratti per lavoratori indipendenti, nuovi ordini dei clienti, domande di impiego e molti altri ancora. Moduli di conformità come W-9, W-4 e I-9 sono ideali per RightSignature&trade;. È necessario che tutti i membri del team approvino una modifica dei criteri? Con RightSignature&trade; è facile. Le aziende possono inviare di tutto, da approvazioni delle ore di lavoro svolte a contratti multimilionari, tramite RightSignature&trade;.",
    "q10": "Quali sono i browser supportati?",
    "a10": "RightSignature&trade; fornisce la migliore esperienza utente nei seguenti browser. Per aggiornare il browser alla versione più recente, visitare:",
    "a10part2": "Mozilla Firefox 3+, Microsoft Internet Explorer 9+, Apple Safari 3+, Google Chrome, Opera 9+",
    "q11": "Cosa fare se non si riesce ad accedere?",
    "a11": "Controllare attentamente il proprio indirizzo e-mail per assicurarsi di non averlo digitato in modo errato. Inoltre, digitare la password lentamente e con attenzione. Se il problema persiste, è possibile reimpostare la password facendo clic su \"Password dimenticata'\". Se si segue il processo di reimpostazione della password e non è ancora possibile accedere, contattarci per assistenza.",
    "q12": "I destinatari devono avere un account RightSignature™?",
    "a12": "No. Non è necessario che i destinatari dispongano di un account per ricevere un documento dall'utente. Non vi sono limiti al numero di destinatari a cui si inviano i documenti. Quando si invia un documento, il destinatario riceverà un'e-mail contenente un collegamento univoco. Dopo aver fatto clic sul collegamento, il destinatario visualizza e firma il documento nel browser, senza che siano necessari download e plug-in. RightSignature.&trade; è così facile e intuitivo che i nuovi destinatari sono in grado di accedere in pochi secondi, senza istruzioni.",
    "q13": "Cosa rende RightSignature™ migliore rispetto ai concorrenti?",
    "a13": "RightSignature&trade; si distingue per l'esperienza utente elegante e intuitiva e l'acquisizione di firme reali scritte a mano. Grazie all'infrastruttura di Amazon Web Services, la nostra sicurezza e scalabilità sono di prima classe. Lo sviluppo e il miglioramento continui mantengono RightSignature&trade; all'avanguardia. Per i professionisti indipendenti e le piccole imprese, RightSignature&trade; rappresenta il software per documenti online più semplice ed efficace.",
    "q14": "In che modo RightSignature™ supporta un'iniziativa per la riduzione della carta?",
    "a14": "Motivati dall'attenzione all'ambiente e da risparmi sui costi, molti uffici hanno intrapreso iniziative per la riduzione della carta. RightSignature&trade; è un componente fondamentale per la riduzione dell'uso della carta, in quanto fornisce un metodo sicuro ed efficace per far firmare i documenti online, senza bisogno di copie stampate.",
    "q15": "Che cos'è una firma elettronica?",
    "a15": "Il signature pad di RightSignature&trade; consente di utilizzare il mouse per firmare un documento, creando una firma elettronica valida come definito dalle normative e dalle direttive europee e statunitensi. Qualsiasi segno tracciato con il signature pad è valido, anche se non somiglia alla firma a mano dell'utente. I tribunali interpretano in modo generale il concetto di \"firma\" e non è necessario che una firma sia tale nel senso tradizionale della parola. Ad esempio, un timbro è stato riconosciuto come firma, così come un segno tracciato da una persona analfabeta. Entrambi questi esempi illustrano il concetto che qualsiasi segno intenzionalmente inserito in un contratto da una delle parti può essere riconosciuto come firma. ",
    "a15part2": "<p>I documenti che vengono resi esecutivi online con firme elettroniche sono legalmente validi e hanno lo stesso valore legale dei documenti cartacei che vengono resi esecutivi a mano. La tecnologia di RightSignature&trade; è pienamente conforme alle normative statunitensi del 2000 ESIGN (Electronic Signatures in Global and National Commerce Act) e UETA (Uniform Electronic Transactions Act), nonché alla direttiva europea 1999/93/CE. Tutte queste normative mirano a incoraggiare la rapida adozione delle firme elettroniche e ridurre l'uso di metodi cartacei antiquati.</p><p>La tecnologia di RightSignature&trade; è conforme alla definizione statunitense di \"firma elettronica:\" un suono, un simbolo o un processo elettronico, collegato o logicamente associato a un contratto o a un altro documento e inserito o adottato da una persona con l'intento di firmare il documento</p><p>La tecnologia di RightSignature&trade; è inoltre conforme ai requisiti più stringenti dell'UE relativi alla \"firma elettronica avanzata\", ossia:'</p>",
    "a15part3": "  la firma è connessa unicamente al firmatario, è idonea a identificare il firmatario, è creata mediante mezzi che il firmatario può utilizzare sotto il proprio esclusivo controllo ed è collegata ai dati sottoscritti in modo da consentire l’identificazione di ogni successiva modifica di tali dati. Riferimenti per ulteriori approfondimenti: articolo di Nolo.com \"Electronic Signatures and Online Contracts\", Direttiva 1999/93/CE del Parlamento Europeo, normativa ESIGN (U.S. Signatures in Global and National Commerce Act), normativa UETA (Uniform Electronic Transactions Act)",
    "a15part4": "Per ulteriori informazioni sulla validità legale dei documenti che sono stati resi esecutivi con RightSignature&trade, consultare la nostra pagina relativa agli aspetti legali.",
    "q16": "Come è possibile verificare l'identità effettiva dell'altra parte del contratto?",
    "a16": "La verifica dell'identità di un individuo è un elemento importante di qualsiasi firma, elettronica o scritta. Una firma elettronica deve essere in grado di identificare un firmatario e deve essere connessa in modo univoco al firmatario. RightSignature&trade; utilizza un processo di autenticazione multivariato, che include la verifica degli indirizzi e-mail, il monitoraggio degli indirizzi IP, l'algoritmo biometrico della firma e altre informazioni. L'autenticazione con RightSignature&trade; è notevolmente più approfondita rispetto ai documenti cartacei, in cui le parti spesso hanno un assistente, un coniuge o un'altra persona che firma per loro conto.",
    "q17": "Come è possibile conoscere la posizione del firmatario?",
    "a17": "Ogni computer collegato a Internet è identificato da un indirizzo IP (protocollo internet). Ad esempio, l'indirizzo IP del computer in uso è: 99.102.245.244. Da questo indirizzo è possibile risalire alla posizione fisica del computer. Anche se questo processo non è accurato al 100%, fornisce uno strumento di autenticazione aggiuntivo per le parti che utilizzano RightSignature&trade; per rendere esecutivi i loro documenti.",
    "q18": "Un documento che è stato reso esecutivo può essere manipolato da una delle parti dopo la firma?",
    "a18": "Una volta caricato e inviato per essere firmato, un documento viene bloccato e archiviato su un server sicuro con copie ridondanti. Dopo che viene reso esecutivo, il documento finale viene protetto con un algoritmo hash sicuro, che garantisce che nessuna delle parti possa apportare modifiche a un documento che è stato reso esecutivo. La neutralità di RightSignature&trade; fornisce ulteriore difendibilità giuridica per qualsiasi tentativo di invalidare un contratto che è stato reso esecutivo. Per ulteriori informazioni sulla nostra infrastruttura dei dati sicura, visitare la nostra pagina relativa alla sicurezza.",
    "q19": "Le firme digitali sono certificate da un'autorità di terze parti?",
    "a19": "RightSignature&trade;, in qualità di servizio di firma di terze parti che coordina il flusso di lavoro tra i firmatari dei documenti, rappresenta un notevole passo avanti per le funzionalità di controllo e verifica rispetto al fax e all'e-mail. Un vantaggio dell'utilizzo di RightSignature&trade; è che il nostro servizio è una terza parte neutrale, proprio come un agente di deposito in una transazione immobiliare. Dopo che un documento viene reso esecutivo, RightSignature&trade; lo protegge con un algoritmo hash sicuro e archivia copie ridondanti utilizzando l'infrastruttura Amazon Web Services. Se un contratto firmato tramite RightSignature&trade; viene contestato in tribunale, sarebbe molto difficile o impossibile per una parte affermare che il documento era stato manomesso. Il record del processo di RightSignature&trade; e il blocco applicato dopo che un documento viene reso esecutivo offre una verifica di terze parti che garantisce contratti sicuri e difendibili.",
    "q20": "Come si può essere sicuri che si stia firmando lo stesso documento dell'altra parte?",
    "a20": "Ogni documento caricato in RightSignature&trade; è associato a un lungo numero di riferimento univoco, composto da numeri, lettere maiuscole e minuscole e caratteri. Questo numero di riferimento è incluso in ogni comunicazione e in ogni schermata riguardanti il documento, assicurando che tutte le parti visualizzino sempre lo stesso documento.",
    "a20part2": "Non è disponibile nessuna funzione per modificare il file una volta che è stato caricato e inviato per essere firmato. Se un documento non è ancora stato firmato da alcun destinatario, il mittente può eliminarlo e caricare e inviare un nuovo documento, che avrà un nuovo numero di riferimento.",
    "q21": "È possibile personalizzare RightSignature™ con il proprio logo?",
    "a21": "Con RightSignature&trade;..."
  };
  _exports.faqModal = faqModal;
});