define("ember-rs/templates/components/paywall-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VlrZvAV3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[19,\"components/paywall-action/toggles\",[]],[2,\"\\n\"],[18,1,[[35,2],[35,1],[35,0]]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"free\",\"entitled\",\"accessible\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/paywall-action.hbs"
    }
  });

  _exports.default = _default;
});