define("ember-rs/locales/ja/templates/components/client-applications", ["exports", "ember-rs/locales/ja/templates/components/client-applications/details-panel"], function (_exports, _detailsPanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clientApplications = void 0;
  var clientApplications = {
    detailsPanel: _detailsPanel.detailsPanel
  };
  _exports.clientApplications = clientApplications;
});