define("ember-rs/locales/pt-br/templates/client-application", ["exports", "ember-rs/locales/pt-br/templates/client-application/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.client_application = void 0;
  var client_application = {
    new: _new.newTranslations
  };
  _exports.client_application = client_application;
});