define("ember-rs/locales/es/templates/components/code-generated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.codeGenerated = void 0;
  var codeGenerated = {
    "copyToClipboard": "Copiar al portapapeles",
    "copySuccess": "¡Copiado!",
    "copyError": "No se pudo copiar al portapapeles"
  };
  _exports.codeGenerated = codeGenerated;
});