define("ember-rs/locales/nl/templates/reports", ["exports", "ember-rs/locales/nl/templates/reports/export"], function (_exports, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    export: _export.exportTrans,
    "header": "Rapporten",
    "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
    "select-report-type--text": "Rapporttype selecteren",
    "select-report-type-option-overview": "Overzicht",
    "overviewReport": "Overzichtsrapport",
    "select-report-type-option-efficiency": "Efficiëntie",
    "efficiencyReport": "Efficiëntierapport",
    "select-report-type-option-cycle-time": "Rapport cyclusduur",
    "select-report-type-option-export": "Gegevens exporteren",
    "select-report-type-option-enterprise": "Onderneming",
    "enterpriseUsageReport": "Gebruiksrapport onderneming",
    "userUsageReport": "Gebruiksrapport gebruiker",
    "select-report-type-option-user": "Nieuw gebruiksrapport",
    "components": {
      "account-document-volume": {
        "header": "Rapporten",
        "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
        "legend-document-sent": "Document verzonden",
        "legend-document-signed": "Document ondertekend",
        "legend": "Legenda:"
      },
      "account-statistics": {
        "header": "Rapporten",
        "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
        "column-header-current-month": "Deze maand",
        "column-header-previous-month": "Laatste maand",
        "column-header-average": "Gemiddelde",
        "row-header-sent-documents": "Verzonden documenten",
        "row-header-viewed": "Weergegeven (door ten minste één ondertekenaar)",
        "row-header-expired": "Verlopen",
        "row-header-signed": "Ondertekend",
        "row-header-sent-documents-per-user": "Verzonden documenten per gebruiker (gem.)",
        "row-header-signed-documents": "Ondertekende documenten",
        "row-header-pending-documents": "In behandeling zijnde documenten",
        "row-header-viewed-pending": "Weergegeven (in behandeling zijnde)",
        "none": "N.v.t."
      },
      "top-users-by-signed-documents": {
        "header": "Rapporten",
        "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
        "column-header-user": "Gebruiker",
        "column-header-signed-documents": "Ondertekende documenten"
      },
      "account-efficiency": {
        "header": "Rapporten",
        "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
        "legend-pending": "In behandeling",
        "legend-signed": "Ondertekend",
        "legend-expired": "Verlopen"
      },
      "top-users-by-efficiency": {
        "header": "Rapporten",
        "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
        "column-header-user": "Gebruiker",
        "column-header-efficiency-percentage": "Efficiëntie %",
        "column-header-ratio": "Ratio",
        "over-gcd": "{{signed}} van {{sent}}"
      },
      "top-users-by-time-to-sign": {
        "header": "Rapporten",
        "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
        "column-header-user": "Gebruiker",
        "column-header-mean-time": "Gemiddelde tijd (uren)"
      },
      "average-cycle-time": {
        "header": "Rapporten",
        "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
        "legend-mean-time-to-view": "Gemiddelde tijd tot weergave",
        "legend-mean-time-to-sign": "Gemiddelde tijd tot ondertekening"
      },
      "cycle-time-statistics": {
        "header": "Rapporten",
        "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
        "column-header-current-month": "Deze maand",
        "column-header-previous-month": "Laatste maand",
        "column-header-all-time": "Onbeperkt",
        "row-header-under-5-mins": "Minder dan 5 minuten",
        "row-header-under-30-mins": "Minder dan 30 minuten",
        "row-header-under-1-hour": "Minder dan 1 uur",
        "row-header-under-12-hours": "Minder dan 12 uur",
        "row-header-under-1-day": "Minder dan 1 dag"
      },
      "month-export": {
        "header": "Rapporten",
        "description": "Bekijk gegevens voor uw verzonden documenten. Onder meer over hoe lang het doorgaans duurt om documenten ondertekend te krijgen en hoeveel documenten er per maand zijn voltooid. Upgrade uw abonnement om toegang te krijgen tot rapporten.",
        "export-to-csv-section-header": "Exporteren naar CSV",
        "export-to-csv-section-description": "Een CSV-bestand kan in Excel worden geopend om gegevens over uw verzonden documenten te bekijken."
      },
      "generate-download": {
        "ie-warning-text": "Klik met de rechtermuisknop op 'Exporteren naar CSV' en kies Opslaan als om het CSV-bestand te downloaden.",
        "export-csv-button-text": "CSV-bestand exporteren",
        "generate-csv-button-text": "CSV genereren",
        "send-download-link": "We genereren het rapport en sturen de downloadkoppeling via e-mail."
      }
    }
  };
  _exports.reports = reports;
});