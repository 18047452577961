define("ember-rs/locales/de/templates/onboarding/account-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding = void 0;
  var accountOnboarding = {
    "nextStep": "Alles klar."
  };
  _exports.accountOnboarding = accountOnboarding;
});