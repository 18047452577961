define("ember-rs/locales/pt-br/templates/signer/passcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcode = void 0;
  var passcode = {
    "enterPin": "Insira o PIN.",
    "access": "Digite o PIN fornecido para acessar o documento.",
    "unlock": "Desbloquear",
    "docProtected": "Este documento é protegido por senha.",
    "answer": "resposta...",
    "show": "mostrar código de accesso",
    "continue": "Continuar com o documento",
    "answerQuestion": "Responda à pergunta de segurança abaixo para acessar o documento"
  };
  _exports.passcode = passcode;
});