define("ember-rs/locales/fr/templates/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerPaymentDetails = void 0;
  var signerPaymentDetails = {
    "paymentInfo": "Informations de paiement",
    "paymentID": "ID de paiement :",
    "retryPayment": "Nouvelle tentative de paiement...",
    "failed": "Échec",
    "chargedSuccess": "Facturé avec succès à {{chargedAt}}.",
    "stripeCustomer": "Client Stripe",
    "stripeCharge": "Frais Stripe",
    "attempted": "Dernière tentative à {{chargedAt}}.",
    "retry": "Réessayer",
    "pending": "En attente...",
    "success": "Nouvelle tentative de paiement acceptée.",
    "retryError": "Échec de la nouvelle tentative de paiement ({{{errorMsg}}})."
  };
  _exports.signerPaymentDetails = signerPaymentDetails;
});