define("ember-rs/locales/it/templates/components/code-generated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.codeGenerated = void 0;
  var codeGenerated = {
    "copyToClipboard": "Copia negli Appunti",
    "copySuccess": "Copiato",
    "copyError": "Impossibile copiare negli Appunti"
  };
  _exports.codeGenerated = codeGenerated;
});