define("ember-rs/locales/ja/templates/onboarding/welcome-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding2 = void 0;
  var welcomeOnboarding2 = {
    "tips": "<span>ドキュメント</span>や<span>テンプレート</span>にアクセスし、<span>設定を管理</span>しビデオの<span>チュートリアルや使用方法記事</span>を参照できます。"
  };
  _exports.welcomeOnboarding2 = welcomeOnboarding2;
});