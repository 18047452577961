define("ember-rs/locales/es/templates/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.index = void 0;
  var index = {
    "templates": "Plantillas",
    "reusableTemplates": "Plantillas reutilizables",
    "templateExplain": "Las plantillas le permiten incrustar documentos en su sitio, distribuir en masa o simplemente enviar documentos usados ​​frecuentemente, en solo un paso.",
    "newTemplate": "Nueva plantilla",
    "noResults": "No hay resultados de búsqueda",
    "searchAgain": "Su búsqueda no arrojó resultados. Intente otro patrón de búsqueda para encontrar su plantilla.",
    "create": "Cree plantillas de documentos reutilizables que puede incrustar en su sitio y compártalas mediante un enlace.",
    "createTemplate": "Crear plantilla",
    "notAllowed": "Su plan actual no le permite crear plantillas. Si desea crear plantillas, contáctese con soporte y actualice su cuenta."
  };
  _exports.index = index;
});