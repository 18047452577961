define("ember-rs/locales/zh-cn/templates/components/agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "welcome": "欢迎",
    "toRS": "使用 RightSignature，",
    "bestOnDesktop": "RightSignature 在台式机上能够提供最佳用户体验。",
    "pleaseRead": "在我们向您展示 RightSignature 之前，请阅读以下内容:",
    "terms": "使用 RightSignature&trade; 即表明您已阅读并同意我们的 ",
    "and": "和",
    "policy": "隐私政策",
    "no": "否，谢谢",
    "yes": "我同意"
  };
  _exports.agreeToTerms = agreeToTerms;
});