define("ember-rs/locales/ja/controllers/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "success": "署名リンクを再送信しました",
    "failure": "リンクの送信に失敗しました: {{{response}}}"
  };
  _exports.identityExpired = identityExpired;
});