define("ember-rs/locales/es/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingComplete = void 0;
  var signingComplete = {
    "docSigned": "¡Documento firmado!",
    "createTrial": "Cree una cuenta de RightSignature gratis para ver o descargar este documento completo. Tendrá acceso perpetuo a este documento. Solo tiene que registrarse con la dirección de correo electrónico a la que se envió el documento e inicia sesión.",
    "createAccount": "Crear una cuenta",
    "login": "Iniciar sesión en la cuenta",
    "notNow": "Ahora no"
  };
  _exports.signingComplete = signingComplete;
});