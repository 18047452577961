define("ember-rs/locales/it/templates/onboarding/welcome-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding3 = void 0;
  var welcomeOnboarding3 = {
    "start": "Da qui si inizia a inviare un documento per la firma."
  };
  _exports.welcomeOnboarding3 = welcomeOnboarding3;
});