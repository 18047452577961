define("ember-rs/routes/template", ["exports", "ember-rs/utils/safe-access"], function (_exports, _safeAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {// leave blank to avoid the child routes automatically trying to GET templates/id
      // since some are unauthenticated (ex: template-signer-link)
    },
    actions: {
      error: function error(response) {
        var statusCode = (0, _safeAccess.default)(response, '500', 'errors', 0, 'status');

        if (statusCode === '404') {
          this.transitionTo('templates');
        }
      }
    }
  });

  _exports.default = _default;
});