define("ember-rs/components/user-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    editProfileUrl: Ember.computed('model.sharefileAccountUrl', function () {
      return this.get('model.sharefileAccountUrl') + "/app/#settings/editbasicinfo";
    })
  });

  _exports.default = _default;
});