define("ember-rs/locales/pt-br/templates/unauthorized/unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unsubscribed = void 0;
  var unsubscribed = {
    "unsubscribed": "Assinatura cancelada",
    "unsubscribedTip": "Você não receberá mais lembretes com relação a este documento. Você ainda receberá emails com relação a outros documentos."
  };
  _exports.unsubscribed = unsubscribed;
});