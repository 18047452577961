define("ember-rs/locales/zh-cn/templates/onboarding/prepare-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding = void 0;
  var prepareOnboarding = {
    "nextStep": "明白了!"
  };
  _exports.prepareOnboarding = prepareOnboarding;
});