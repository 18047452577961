define("ember-rs/utils/escape-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.escapeHtml = escapeHtml;

  function escapeHtml(value) {
    //HTML Encode Before Inserting Untrusted Data into HTML Element Content
    //The 5 characters significant in XML (&, <, >, ", ')
    //https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#rule-1-html-encode-before-inserting-untrusted-data-into-html-element-content
    var htmlEscapes = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#x27;"
    };
    var htmlEscaper = /[&<>"']/g;
    return String(value).replace(htmlEscaper, function (s) {
      return htmlEscapes[s];
    });
  }
});