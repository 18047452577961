define("ember-rs/locales/it/templates/unauthorized/document-executed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExecuted = void 0;
  var documentExecuted = {
    "docComplete": "Il documento è stato completato",
    "toDownload": "Per scaricare questo documento, richiedere un nuovo collegamento per il download.",
    "sendLink": "Invia nuovo collegamento per il download"
  };
  _exports.documentExecuted = documentExecuted;
});