define("ember-rs/locales/de/templates/document-transaction/review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review = void 0;
  var review = {
    "review": "Überprüfen"
  };
  _exports.review = review;
});