define("ember-rs/locales/ja/templates/onboarding/post-sign-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding1 = void 0;
  var postSignOnboarding1 = {
    "tapGear": "<span>歯車をタップ</span>して、ドキュメントやその他の情報をダウンロードします。",
    "viewDoc": "ここでドキュメントの<span>状態</span>を表示できます"
  };
  _exports.postSignOnboarding1 = postSignOnboarding1;
});