define("ember-rs/locales/fr/templates/onboarding/send-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding3 = void 0;
  var sendOnboarding3 = {
    "remind": "Sélectionnez <span>Rappel</span> pour notifier de nouveau les signataires.",
    "void": "Sélectionnez <span>Annuler</span> pour annuler le document."
  };
  _exports.sendOnboarding3 = sendOnboarding3;
});