define("ember-rs/locales/fr/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTagsEditor = void 0;
  var documentTagsEditor = {
    "addTags": "Ajoutez des balises à ce document pour le retrouver facilement plus tard. Vous pouvez également ajouter des valeurs aux balises, par exemple 'id_client : 1234'",
    "tagName": "Nom de la balise...",
    "optional": "Valeur (facultative)"
  };
  _exports.documentTagsEditor = documentTagsEditor;
});