define("ember-rs/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SCOPES_MAPPING = {
    'read': 'Read',
    'read write': 'Read/Write'
  };

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    ////////////////////////  BINDINGS  ////////////////////////
    classNames: ['details-panel'],
    classNameBindings: ['showTable', 'overflowVisible'],
    timestamp: Ember.computed.alias('currentUser.model.timestamp'),
    ////////////////////////  PROPERTIES  ////////////////////////
    status: Ember.computed.alias('clientApplication.status'),
    secretAudit: Ember.computed.alias('clientApplication.secretRequestAudit.firstObject'),
    tokenAudit: Ember.computed.alias('clientApplication.privateTokenRequestAudit.firstObject'),
    canRegenerate: Ember.computed.equal('status', 'approved'),
    canEdit: Ember.computed('status', function () {
      return ['approved', 'pending'].includes(this.status);
    }),
    scopes: Ember.computed('clientApplication.scopes', function () {
      return SCOPES_MAPPING[Ember.get(this, 'clientApplication.scopes')];
    }),
    approvedOrBanned: Ember.computed('status', function () {
      return this.status === 'approved' || this.status === 'banned';
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      // In order to achieve the sliding transition, overflow: hidden was used. However,
      // this was causing the reveal and regenerate tooltips to get clipped. This class
      // binding allows for the tooltips to be positioned in any place
      overflowVisible: function overflowVisible() {
        Ember.set(this, 'overflowVisible', true);
      },
      overflowHidden: function overflowHidden() {
        Ember.set(this, 'overflowVisible', false);
      },
      revealSecret: function revealSecret(id) {
        this.sendAction('revealSecret', id);
      },
      revealPrivateToken: function revealPrivateToken(id) {
        this.sendAction('revealPrivateToken', id);
      },
      regenerateToken: function regenerateToken(id) {
        this.sendAction('regenerateToken', id);
      }
    }
  });

  _exports.default = _default;
});