define("ember-rs/locales/ja/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noUser = void 0;
  var noUser = {
    "unableLogin": "ログインできません",
    "unableLoginTip": "この RightSignature アカウントにログインできません。<br>アクセスするには、アカウント管理者に連絡してください。"
  };
  _exports.noUser = noUser;
});