define("ember-rs/locales/ja/controllers/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "standardInfo": "選択したプランでは、1 ユーザーのみがサポートされます。アカウントにさらにユーザーが必要な場合は、Advanced プランを選択してください。",
    "migratedInfo": "トライアル アカウントで既に {{{minimumSeats}}} シートを使用しているため、最低 {{{minimumSeats}}} ユーザーを選択するか、アップグレードについてサポートに連絡してください",
    "planInfo": "選択したプランには 3 ユーザーが含まれています。それ以上のユーザーが必要な場合は、以下で選択してください。",
    "validUsers": "有効なユーザー数を入力してください"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});