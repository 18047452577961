define("ember-rs/locales/de/templates/components/component-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentEditor = void 0;
  var componentEditor = {
    "charge": "Nach Unterzeichnung in Rechnung stellen?",
    "selectOne": "Treffen Sie eine Auswahl...",
    "amount": "Zahlungsbetrag (USD):",
    "enterAmount": "Zahlungsbetrag eingeben...",
    "required": "ERFORDERLICH: Geben Sie unten Anleitungen für den Unterzeichner ein, um dem Empfänger mitzuteilen, was hochgeladen werden soll.",
    "signerHelp": "Anleitungen für den Unterzeichner:",
    "helpText": "Anleitungen eingeben...",
    "selectBox": "Das Auswahlfeld erfordert mindestens 2 Optionen.",
    "selectOptions": "Optionen wählen:",
    "addOption": "Option hinzufügen...",
    "addToGroup": "Zu Gruppe hinzufügen",
    "assignedTo": "Zugewiesen zu:",
    "fieldRequired": "Ist ein Feld erforderlich?",
    "autoFillDate": "Auto-fill date signed",
    "autoFillDateHelpText": "A recipient will not be able to edit the field. It will be filled in automatically with the date on which the recipient signs.",
    "mergeField": "Seriendruckfeld?",
    "mergeFieldName": "Name für Seriendruckfeld erforderlich",
    "nameMergeField": "Name für Seriendruckfeld:",
    "setFontSize": "Schriftgröße festlegen...",
    "muiltiline": "Mehrzeilig?",
    "clearBackground": "Durchsichtiger Hintergrund?",
    "addDefaultValue": "Standardwert hinzufügen?",
    "setDefaultValue": "Standardwert festlegen...",
    "defaultValue": "Standardwert:",
    "nameThisField": "Feld benennen?",
    "name": "Name:",
    "setName": "Name der Komponente festlegen...",
    "textAlign": "Textausrichtung",
    "showLess": "Weniger anzeigen",
    "showMore": "Mehr anzeigen",
    "delete": "Löschen",
    "saved": "Gespeichert.",
    "save": "Speichern",
    "dateFormat": "Datumsformat",
    "left": "Links",
    "center": "Zentrieren",
    "right": "Rechts",
    "groupRequirement": "Gruppenanforderung",
    "only_one": "Nur 1",
    "one_or_more": "1 oder mehr",
    "options_warning": "Sie haben diese Option bereits hinzugefügt"
  };
  _exports.componentEditor = componentEditor;
});