define("ember-rs/locales/nl/templates/unauthorized/redirect-to-rs3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectToRs3 = void 0;
  var redirectToRs3 = {
    "wrongVersion": "Verkeerde RightSignature-versie",
    "classic": "U hebt geprobeerd aan te melden bij RightSignature ShareFile Edition. Met uw account hebt u echter alleen toegang tot Classic RightSignature.",
    "go": "Ga naar RightSignature"
  };
  _exports.redirectToRs3 = redirectToRs3;
});