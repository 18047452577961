define("ember-rs/locales/de/mixins/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "successUpgrade": "Ihr Konto wurde aktualisiert. Willkommen bei RightSignature!",
    "dataUnavailable": "Daten sind derzeit nicht verfügbar. Bitte kommen Sie später auf diese Seite zurück."
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});