define("ember-rs/locales/fr/templates/unauthorized/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expired = void 0;
  var expired = {
    "linkExpired": "Le lien du document a expiré",
    "toDownload": "Pour télécharger ce document, demandez un nouveau lien de téléchargement.",
    "sendLink": "Envoyer nouveau lien de téléchargement"
  };
  _exports.expired = expired;
});