define("ember-rs/controllers/sharefile-redirect", ["exports", "ember-rs/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    showInitialOnboarding: Ember.computed.and('currentUser.model.hasSeenWelcomeOnboarding', 'currentUser.model.hasSeenPrepareOnboarding'),
    marketingHost: _environment.default.marketingHost
  });

  _exports.default = _default;
});