define("ember-rs/locales/ja/templates/document-transaction/review-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_desktop = void 0;
  var review_desktop = {
    "send_document": "ドキュメントを送信",
    "save_document": "ドキュメントを保存",
    "sendDocument": "ドキュメントを送信",
    "bulkSendConfirmation": "このドキュメントは複数の署名者と共有されます",
    "confirm": "確認",
    "cancel": "キャンセル"
  };
  _exports.review_desktop = review_desktop;
});