define("ember-rs/locales/fr/templates/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateRow = void 0;
  var reusableTemplateRow = {
    "send": "Envoyer",
    "details": "Détails",
    "trialmessageComp": "Votre plan d'évaluation actuel vous permet uniquement d'envoyer {{{documentLimit}}} documents. ",
    "planmessageComp": "Votre plan actuel vous permet uniquement d'envoyer {{{documentLimit}}} documents. ",
    "upgrade": "Contactez-nous pour mettre à niveau votre compte."
  };
  _exports.reusableTemplateRow = reusableTemplateRow;
});