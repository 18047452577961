define("ember-rs/locales/nl/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newClientApplication = void 0;
  var newClientApplication = {
    "appReview": "Uw aanvraag wordt beoordeeld. Binnen 24 uur zullen we u op de hoogte stellen of uw aanvraag goedkeuren.",
    "secretsSuccess": "Geheimen opgehaald. Deze mogen alleen door u of personen die u vertrouwt worden gebruikt.",
    "secretsError": "Kan geheimen niet ophalen: {{{error}}}",
    "tokenSuccess": "Nieuw privé API-token gegenereerd",
    "tokenError": "Kan token niet genereren: {{{error}}}",
    "singleSecretSuccess": "Geheim opgehaald voor {{{clientAppName}}}. Dit mag alleen door u of personen die u vertrouwt worden gebruikt.",
    "singleSecretError": "Kan geheim niet ophalen: {{{error}}}",
    "tokenRetrieveSuccess": "Privé API-token opgehaald voor {{{clientAppName}}}. Dit mag alleen door u of personen die u vertrouwt worden gebruikt.",
    "tokenRetrieveError": "Kan privé API-token niet ophalen: {{{error}}}",
    "tokenRegenerateError": "Kan token niet opnieuw genereren: {{{error}}}",
    "confirmInfo": "Weet u het zeker? U zou ook een nieuwe API-sleutel kunnen aanvragen, want overschreven privé API-tokens zijn onherstelbaar als ze opnieuw gegenereerd zijn. Als u kiest om door te gaan, zorg er dan voor dat u verouderde referenties in al uw integraties verwijdert en vervangt."
  };
  _exports.newClientApplication = newClientApplication;
});