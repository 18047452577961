define("ember-rs/locales/zh-cn/templates/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "share": "共享",
    "status": "状态",
    "overview": "概览",
    "sent": "已发送",
    "people_involved": "涉及的人员:",
    "reference_id": "参考 ID: ",
    "manage": "管理",
    "tags": "标记: "
  };
  _exports.details = details;
});