define("ember-rs/locales/es/templates/components/restyle-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.restyleOnboarding = void 0;
  var restyleOnboarding = {
    "welcome": "Bienvenido",
    "introduceNewLook": "Nos complace presentar el nuevo aspecto de RightSignature. ¡Adelante!",
    "gotIt": "De acuerdo"
  };
  _exports.restyleOnboarding = restyleOnboarding;
});