define("ember-rs/locales/fr/templates/components/integration-document-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocumentSelector = void 0;
  var integrationDocumentSelector = {
    "chooseDocFrom": "Choisissez un document parmi :",
    "loading": "Chargement...",
    "back": "Précédent",
    "search": "Rechercher documents...",
    "use": "Utilisation",
    "empty": "Ce dossier est vide",
    "success": "Envoi désactivé avec succès pour {{{provider}}}",
    "error": "Erreur. Impossible de modifier l'envoi pour {{{provider}}}"
  };
  _exports.integrationDocumentSelector = integrationDocumentSelector;
});