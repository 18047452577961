define("ember-rs/locales/ja/templates/upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgrade = void 0;
  var upgrade = {
    "noUpgrade": "アカウントをアップグレードできません",
    "noPermission": "アカウントをアップグレードする権限がないようです。新しい RightSignature プランへのアップグレードについて、アカウント管理者に相談してください。",
    "upgrade": "アカウントをアップグレード",
    "upgradeSubtitle": "トライアル中、Business プランを使用していました。このプランのまま続行することも、より適したプランを選択することもできます。",
    "choosePlan": "1. プランを選択",
    "allInOne": "オールインワンの電子署名ソリューション",
    "standard": "標準機能 ",
    "usersNeeded": "2. 必要なユーザー数を選択",
    "checkout": "購入手続きへ進む",
    "showLess": "簡易表示",
    "showMore": "詳細表示",
    "users": "ユーザー",
    "confirm": "Enter キーを押して確認します",
    "billing": "請求",
    "billingDetails": "請求の詳細",
    "noOfUser": "ユーザー数",
    "billAmount": "請求可能な金額",
    "creditAmount": "古い RightSignature からのクレジット金額",
    "total": "合計",
    "taxCharges": "追加の税金が適用される場合があります",
    "oops": "問題が発生しました",
    "dataUnavailable": "現在、データは利用できません。しばらくしてからこのページに戻ってください。"
  };
  _exports.upgrade = upgrade;
});