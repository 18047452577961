define("ember-rs/locales/nl/templates/signer", ["exports", "ember-rs/locales/nl/templates/signer/sign", "ember-rs/locales/nl/templates/signer/confirm-identity", "ember-rs/locales/nl/templates/signer/footer-desktop", "ember-rs/locales/nl/templates/signer/footer-mobile", "ember-rs/locales/nl/templates/signer/passcode", "ember-rs/locales/nl/templates/signer/signing-complete"], function (_exports, _sign, _confirmIdentity, _footerDesktop, _footerMobile, _passcode, _signingComplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signer = void 0;
  //signing-complete
  var signer = {
    sign: _sign.sign,
    confirmIdentity: _confirmIdentity.confirmIdentity,
    footerDesktop: _footerDesktop.footerDesktop,
    footerMobile: _footerMobile.footerMobile,
    passcode: _passcode.passcode,
    signingComplete: _signingComplete.signingComplete
  };
  _exports.signer = signer;
});