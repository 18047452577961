define("ember-rs/components/spinner-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rootElement: Ember.computed(function () {
      return document.querySelector('body');
    }),
    title: '',
    description: ''
  });

  _exports.default = _default;
});