define("ember-rs/locales/fr/templates/components/show-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showComponent = void 0;
  var showComponent = {
    "completed": "Terminé",
    "pending": "En attente",
    "mergefield": "est un champ de fusion",
    "requiredAssignedTo": "Le champ requis \"{{{fieldName}}}\" a été attribué à <b>{{{assignedToName}}}</b>",
    "optionalAssignedTo": "Le champ facultatif \"{{{fieldName}}}\" a été attribué à <b>{{{assignedToName}}}</b>"
  };
  _exports.showComponent = showComponent;
});