define("ember-rs/locales/fr/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrations = void 0;
  var integrations = {
    "successEnabled": "{{{formattedProviderName}}} a été activé avec succès",
    "successAuthorized": "{{{formattedProviderName}}} a été autorisé avec succès",
    "successDisabled": "{{{formattedProviderName}}} a été désactivé avec succès",
    "error": "Erreur. Impossible de modifier {{{formattedProviderName}}} !",
    "errorAuthorize": "Erreur. Impossible d'autoriser l'intégration de {{{formattedProviderName}}} !",
    "errorSetup": "Une erreur s'est produite lors de la tentative de configuration de l'intégration de {{{formattedProviderName}}} Veuillez réessayer."
  };
  _exports.integrations = integrations;
});