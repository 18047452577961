define("ember-rs/templates/components/transaction-manager-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zG5HjgQx",
    "block": "{\"symbols\":[\"step\",\"index\"],\"statements\":[[10,\"h3\"],[14,0,\"title theme_heading\"],[12],[1,[34,1]],[13],[2,\"\\n\"],[10,\"ul\"],[14,1,\"breadcrumbs\"],[14,0,\"breadcrumbs theme__breadcrumbs\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"transaction-breadcrumb-step\",[],[[\"@step\",\"@contentIndex\",\"@currentStep\"],[[32,1],[32,2],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n\"],[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"close-tm theme_alt-link\"],[4,[38,5],[[32,0],\"close\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-times\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,6],[\"components.transactionManagerHeader.close\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentStep\",\"title\",\"transactionSteps\",\"-track-array\",\"each\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/transaction-manager-header.hbs"
    }
  });

  _exports.default = _default;
});