define("ember-rs/locales/nl/templates/components/agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "welcome": "Welkom",
    "toRS": "aan RightSignature,",
    "bestOnDesktop": "Op een desktopcomputer hebt u de beste gebruikerservaring met RightSignature.",
    "pleaseRead": "Lees het volgende voordat u de rondleiding volgt:",
    "terms": "Door RightSignature&trade; te gebruiken, verklaart u dat u het volgende hebt gelezen en hiermee akkoord gaat met: ",
    "and": "en",
    "policy": "Privacybeleid",
    "no": "Nee, bedankt",
    "yes": "Ik ga akkoord"
  };
  _exports.agreeToTerms = agreeToTerms;
});