define("ember-rs/models/session-credential", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    expiresAt: (0, _model.attr)('date'),
    expiresIn: (0, _model.attr)('number'),
    apicp: (0, _model.attr)('string'),
    appcp: (0, _model.attr)('string'),
    subdomain: (0, _model.attr)('string'),
    accessToken: (0, _model.attr)('string'),
    userId: (0, _model.attr)('string'),
    sharefileUserId: (0, _model.attr)('string'),
    user: (0, _model.belongsTo)('user'),
    account: (0, _model.belongsTo)('account')
  });

  _exports.default = _default;
});