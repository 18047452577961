define("ember-rs/locales/de/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "documents": "Dokumente",
    "noResults": "Keine Suchergebnisse",
    "tryAgain": "Ihre Suche ergab keine Ergebnisse. Probieren Sie eine andere Suche, um Ihr Dokument zu finden.",
    "anotherFilter": "Der von Ihnen gewählte Filter ergab keine Ergebnisse. Probieren Sie einen anderen Filter.",
    "documentsTip": "Hier finden Sie Ihre signierten und ausstehenden Dokumente. Erstellen Sie Ihr erstes Dokument.",
    "questions": "Haben Sie Fragen? Hier finden Sie weitere Hilfe.",
    "gotIt": "Alles klar.",
    "startDocument": "Dokument starten",
    "userGuide": "Dokumentation"
  };
  _exports.documents = documents;
});