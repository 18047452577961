define("ember-rs/templates/client-applications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wFrLlMT6",
    "block": "{\"symbols\":[\"clientApplication\"],\"statements\":[[10,\"div\"],[14,0,\"client-application-index\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"setting-info\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,4],[\"account.api.header\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,4],[\"account.api.description\"],null]],[13],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"button request-key-button\"]],[[\"@route\"],[\"client-applications.new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,4],[\"account.api.primary-action\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"client-applications-list\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"client-applications/index-row\",[],[[\"@clientApplication\",\"@revealSecret\",\"@revealPrivateToken\",\"@regenerateToken\"],[[32,1],[30,[36,3],[[32,0],\"revealSecret\"],null],[30,[36,3],[[32,0],\"revealPrivateToken\"],null],[30,[36,3],[[32,0],\"regenerateToken\"],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"page-numbers\",[],[[\"@content\",\"@currentPage\",\"@totalPages\",\"@setCurrentPage\"],[[34,0],[34,1],[34,2],[30,[36,3],[[32,0],\"setCurrentPage\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,11],[[30,[36,10],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"page\",\"totalPages\",\"action\",\"t\",\"sortedClientApps\",\"-track-array\",\"each\",\"hasMultiplePages\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/client-applications.hbs"
    }
  });

  _exports.default = _default;
});