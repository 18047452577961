define("ember-rs/templates/components/client-applications/index-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n/SKtb+T",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"client-application-row\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"basic-info\"],[4,[38,0],[[32,0],\"showTable\"],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"client-application-name\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[1,[35,1,[\"name\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"status-info-block\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"client-application-status\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[1,[30,[36,2],[[35,1,[\"status\"]]],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"client-application-icon\"],[12],[2,\"\\n        \"],[1,[30,[36,4],[[35,3],[35,1,[\"status\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"accordion-toggle\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[[30,[36,6],[[35,5],\"expanded\"],null]]]],[12],[1,[30,[36,4],[\"right-arrow\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"client-applications/details-panel\",[],[[\"@clientApplication\",\"@showTable\",\"@revealSecret\",\"@revealPrivateToken\",\"@regenerateToken\"],[[34,1],[34,5],[30,[36,0],[[32,0],\"revealSecret\"],null],[30,[36,0],[[32,0],\"revealPrivateToken\"],null],[30,[36,0],[[32,0],\"regenerateToken\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"clientApplication\",\"capitalize\",\"statusIcon\",\"inline-svg\",\"showTable\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/client-applications/index-row.hbs"
    }
  });

  _exports.default = _default;
});