define("ember-rs/locales/it/templates/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choose_documents = void 0;
  var choose_documents = {
    "drag_drop": "Trascina i file qui o:",
    "choose_computer": "Scegli dal computer",
    "choose_template": "Scegliere il modello da usare per l'invio in blocco",
    "use_template": "Utilizza un modello",
    "other_source": "Altre fonti",
    "from_cloud": "dal cloud",
    "template": "modello",
    "add_another": "Aggiungi un altro file",
    "prepare_document": "Prepara documento",
    "prepare_documents": "Prepara documenti",
    "upload_signers": "Carica firmatari",
    "drag_drop_csv": "Trascinare il file CSV:",
    "select_file_computer": "Selezionare un file dal computer",
    "remove_file": "Rimuovi",
    "upload_success": "File caricato correttamente.",
    "upload_multifile": "Caricamento di più file non supportato. Caricare un solo file.",
    "upload_invalid_extension": "Il file {{{fileName}}} ha un'estensione non valida.",
    "upload_size_limit": "Il file {{{fileName}}} è più grande del nostro limite di 5 MB.",
    "send_package": "Invia pacchetto di documenti",
    "file_size_exceeded": "Le dimensioni totali del file non possono superare {{{maxFileSize}}} MB. Rimuovere uno o più documenti per continuare.",
    "max_files_exceeded": "È possibile preparare solo {{{maxFileCount}}} documenti allo stesso tempo.",
    "min_files_required": "Un pacchetto richiede almeno due documenti.",
    "preparing_package": "Stiamo preparando il documento. Potrebbero essere necessari alcuni secondi.",
    "merge_upload_error": "Si è verificato un problema durante la preparazione del documento. Riprovare rimuovendo e ricaricando i documenti."
  };
  _exports.choose_documents = choose_documents;
});