define("ember-rs/templates/onboarding/send-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1NH5JlnY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"onboarding-target-image content-viewer-aside-header\"],[12],[2,\"\\n   \"],[10,\"span\"],[14,0,\"content-viewer-status-label\"],[12],[1,[30,[36,0],[\"document.details.status\"],null]],[13],[2,\"\\n   \"],[10,\"span\"],[14,0,\"content-viewer-status-tag is-pending\"],[12],[2,\"\\n     \"],[10,\"i\"],[15,0,[31,[\"fa \",[34,1]]]],[12],[13],[2,\"\\n     \"],[1,[30,[36,0],[\"document.details.pending\"],null]],[2,\"\\n   \"],[13],[2,\"\\n \"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding-new/overlay-arrow.svg\"],[14,0,\"onboarding-arrow\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/overlay-arrow.png\"],[15,\"alt\",[30,[36,0],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"onboarding.sendOnboarding2.status\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"stateIcon\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/send-onboarding-2.hbs"
    }
  });

  _exports.default = _default;
});