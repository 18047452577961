define("ember-rs/locales/en/templates/support-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportContent = void 0;
  var supportContent = {
    "needHelp": "Need some help with RightSignature&trade;?",
    "articlesBelow": "Below are support articles and videos that answer many of the questions you might have regarding RightSignature&trade;. If the articles and videos don't answer your question, feel free to contact our support representatives.",
    "supportArticles": "Support Articles",
    "overview": "RightSignature&trade; for ShareFile Overview",
    "activate": "How do I activate the RightSignature&trade; for ShareFile feature in my ShareFile Account?",
    "send": "How do I send a document from ShareFile using RS for SF?",
    "sign": "How do I sign a document from ShareFile using RS for SF?",
    "branding": "How do I set up my company's branding?",
    "changeEmail": "How do I change the email address associated with my RightSignature&trade; account?",
    "addUser": "How do I add users to my RightSignature&trade; account?",
    "signerOrder": "How do I assign an order for signers on the same document?",
    "annotate": "How can I use RightSignature&trade; to annotate documents for my signers?",
    "mergeFields": "How do I use Merge Fields to enter text into a document before sending?",
    "passcode": "How do I add a passcode to my document?",
    "importOverlay": "How do I use the Import Overlay feature?",
    "docLocation": "Where are documents stored using RightSignature&trade; for ShareFile?",
    "share": "How do I share documents?",
    "multiple": "How do I send multiple documents for signature?",
    "oneOff": "How do I send a document? (one-off equivalent)",
    "template": "How do I create a Reusable Template?",
    "editTemplate": "How do I edit a Template?",
    "sendTemplate": "How do I send a Templated document?",
    "embed": "How do I embed a document in my website for signature?",
    "generate": "How do I generate a Share Link for my Templated documents?",
    "delete": "How do I delete a document?",
    "tag": "How do I tag a document?",
    "selfSign": "How do I sign my own document?",
    "reports": "What reporting and statistics are available with RightSignature&trade;?",
    "complete": "How to Complete the Document that was Sent to You",
    "remind": "How do I send a reminder to sign a document?",
    "inPerson": "How do I get a document signed in person?",
    "showLess": "Show Less",
    "showMore": "Show More",
    "sendQuestion": "Send Us Your Question",
    "submitRequest": "Submit your request here",
    "call": "Call Us Now",
    "free": "Toll Free"
  };
  _exports.supportContent = supportContent;
});