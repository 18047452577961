define("ember-rs/locales/fr/templates/components/documents/document-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentRow = void 0;
  var documentRow = {
    "signed": "signé",
    "signer": "Signataire(s)",
    "legacy": " - Ancienne documentation",
    "awaitingSignature": "En attente de votre signature",
    "sign": "signer",
    "completed": "Terminé ",
    "sent": "Envoyé",
    "voided": "Annulé",
    "expired": "Expiré ",
    "auth_failure": "Impossible d'authentifier le signataire via la session : {{{authError}}}"
  };
  _exports.documentRow = documentRow;
});