define("ember-rs/locales/de/templates/unauthorized/signing-link-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingLinkDisabled = void 0;
  var signingLinkDisabled = {
    "signingLinkDisabled": "Signaturlink deaktiviert",
    "signingLinkDisabledTip": "Dieser Signierungslink wurde deaktiviert."
  };
  _exports.signingLinkDisabled = signingLinkDisabled;
});