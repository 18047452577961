define("ember-rs/locales/es/templates/document/prepare-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_document = void 0;
  var prepare_document = {
    "prepare": "Preparar ",
    "prepare_package": "Preparar paquete de documentos",
    "merge_field_message": "Este documento contiene campos de combinación para que los complete antes de enviarlo.",
    "merge_field_title": "Rellenar campos de combinación",
    "missing_merge_fields": "Se deben completar todos los campos de combinación requeridos."
  };
  _exports.prepare_document = prepare_document;
});