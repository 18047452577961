define("ember-rs/locales/en/templates/onboarding/post-sign-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding3 = void 0;
  var postSignOnboarding3 = {
    "downloadDoc": "Here's where you can <span>download</span> the document."
  };
  _exports.postSignOnboarding3 = postSignOnboarding3;
});