define("ember-rs/components/overlay-text-annotation-component", ["exports", "ember-rs/mixins/component-style", "ember-rs/mixins/overlay-component"], function (_exports, _componentStyle, _overlayComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentStyle.default, _overlayComponent.default, {
    overlayerData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    classNames: ['component'],
    classNameBindings: ['componentType', 'component.transparent:is-transparent'],
    attributeBindings: ['style', 'data-id'],
    'data-id': Ember.computed.oneWay('component.id'),
    onSizeChange: function onSizeChange(evt, size) {
      var width = size.width / this.get('documentViewerData.pageWidth');
      var height = size.height / this.get('documentViewerData.pageHeight');
      this.set('component.width', width);
      this.set('component.height', height);
    },
    textAreaFontSize: Ember.observer('component.fontSize', Ember.on('didInsertElement', function () {
      var fontSize = this.get('component.fontSize');
      var lineHeight = fontSize;

      if (lineHeight < 14) {
        lineHeight = 14;
      }

      this.$().find('textarea').css({
        'font-size': "".concat(fontSize, "px"),
        'line-height': "".concat(lineHeight, "px")
      }).trigger('update.autogrow');
    })),
    unbindBlur: Ember.on('willDestroyElement', function () {
      this.$().find('textarea').off('blur');
    }),
    initAutoGrowTextarea: Ember.on('didInsertElement', function () {
      this.$().find('textarea').on('sizechange.autogrow', Ember.run.bind(this, this.onSizeChange)).autogrow({
        horizontal: true
      }); // Ember is redrawing all components when a new one is added... (future performance issue)
      // this.$().find('textarea').focus();
    }),
    destroyAutoGrowTextarea: Ember.on('willDestroyElement', function () {
      this.$().find('textarea').trigger('remove.autogrow');
    }),
    initFocus: Ember.on('didInsertElement', function () {
      var self = this;
      this.$().find('textarea').on('focus', function () {
        self.get('overlayerData').selectComponents(Ember.A([self.get('component')]));
      });
    }),
    destroyFocus: Ember.on('willDestroyElement', function () {
      this.$().find('textarea').off('focus');
    })
  });

  _exports.default = _default;
});