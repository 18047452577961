define("ember-rs/locales/it/templates/onboarding/send-onboarding-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding4 = void 0;
  var sendOnboarding4 = {
    "close": "Selezionare la <span>freccia indietro</span> per chiudere questi dettagli e andare alla dashboard dei documenti"
  };
  _exports.sendOnboarding4 = sendOnboarding4;
});