define("ember-rs/locales/fr/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFree = void 0;
  var newUserOnboardingFree = {
    "title": "Bienvenue à RightSignature !",
    "body": "Vous disposez uniquement d'un accès de base à RightSignature™. Cela vous permet d'auto-signer des documents et de consulter vos anciens documents. Pour accéder aux fonctionnalités PRO, contactez un administrateur de votre compte RightSignature™."
  };
  _exports.newUserOnboardingFree = newUserOnboardingFree;
});