define("ember-rs/locales/ja/templates/components/reports/month-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.monthSelect = void 0;
  var monthSelect = {
    "selectMonth": "月を選択..."
  };
  _exports.monthSelect = monthSelect;
});