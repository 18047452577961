define("ember-rs/locales/es/templates/onboarding/prepare-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding1 = void 0;
  var prepareOnboarding1 = {
    "useTools": "Use estas herramientas para <span>los campos que los firmantes deben completar</span>"
  };
  _exports.prepareOnboarding1 = prepareOnboarding1;
});