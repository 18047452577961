define("ember-rs/components/verified-email-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  BINDINGS  ////////////////////////
    tagName: "li"
  });

  _exports.default = _default;
});