define("ember-rs/components/signer/signer-info", ["exports", "ember-rs/helpers/is-valid-email"], function (_exports, _isValidEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  PROPERTIES  ////////////////////////
    isValid: Ember.computed('signer.name', 'signer.email', 'signer.document.identityMethod', function () {
      if (Ember.isBlank(Ember.get(this, 'signer.name'))) {
        return false;
      }

      if (Ember.get(this, 'signer.document.identityMethod') === 'email' && !(0, _isValidEmail.isValidEmail)(Ember.get(this, 'signer.email'))) {
        return false;
      }

      if (Ember.get(this, 'signer.document.identityMethod') === 'none') {
        if (Ember.isPresent(Ember.get(this, 'signer.email')) && !(0, _isValidEmail.isValidEmail)(Ember.get(this, 'signer.email'))) {
          return false;
        }
      }

      return true;
    }),
    emailPlaceholder: Ember.computed('signer.document.identityMethod', function () {
      if (Ember.get(this, 'signer.document.identityMethod') === 'email') {
        return this.i18n.t("components.addRoleField.email");
      } else {
        return this.i18n.t("components.addRoleField.emailOptional");
      }
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      proceed: function proceed() {
        Ember.set(this, 'showTemplateSignerInfoSplash', false);
      }
    }
  });

  _exports.default = _default;
});