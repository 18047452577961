define("ember-rs/locales/en/templates/reusable-templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTrans = void 0;
  var newTrans = {
    "upload": "Upload",
    "dragDrop": "Drag and drop files here or:",
    "uploading": "Uploading",
    "uploadFile": "Upload a file",
    "chooseCloud": "choose from cloud",
    "prepare": "prepare document"
  };
  _exports.newTrans = newTrans;
});