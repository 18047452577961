define("ember-rs/locales/zh-cn/templates/unauthorized/unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unsubscribed = void 0;
  var unsubscribed = {
    "unsubscribed": "已取消订阅",
    "unsubscribedTip": "已将您从收到此帐户的提醒的用户中删除。您仍会收到关于其他文档的电子邮件。"
  };
  _exports.unsubscribed = unsubscribed;
});