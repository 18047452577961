define("ember-rs/locales/en/controllers/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "upgradeMsg": "Your account does not have access to this feature. Please upgrade to a Pro plan to begin using embedded templates.",
    "templateDeleteMsg": "Successfully deleted the template.",
    "tagUpdateMsg": "Successfully updated the tags",
    "tagUpdateFailedMsg": "Failed to update the tags: {{{formattedResponse}}}",
    "templateDeleteConfirm": "Are you sure you want to delete this template ?",
    "loadSignerLinksFailedMsg": "Failed to load template signer links"
  };
  _exports.details = details;
});