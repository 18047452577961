define("ember-rs/locales/nl/mixins/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "successUpgrade": "De upgrade van uw account is geslaagd. Welkom bij RightSignature!",
    "dataUnavailable": "Er zijn op dit moment geen gegevens beschikbaar. Kom later terug naar deze pagina."
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});