define("ember-rs/locales/zh-cn/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "documents": "文档",
    "noResults": "无搜索结果",
    "tryAgain": "您的搜索未返回任何结果，请尝试其他搜索以查找您的文档。",
    "anotherFilter": "您选择的过滤器没有结果，请尝试其他过滤器。",
    "documentsTip": "这是您的已签名和待签名的文档的存储位置。请创建您的第一个文档以开始操作。",
    "questions": "有问题? 请单击此处查找更多帮助信息。",
    "gotIt": "明白了!",
    "startDocument": "启动文档",
    "userGuide": "用户指南"
  };
  _exports.documents = documents;
});