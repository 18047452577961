define("ember-rs/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'document-access-settings',
    ////////////////////////  DEPENDENCIES  ////////////////////////
    flashMessages: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    documentType: 'document',
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleAddViewer: function toggleAddViewer() {
        this.toggleProperty('isShowingAddViewer');
      },
      toggleEditSigner: function toggleEditSigner(signer) {
        this.toggleProperty('isShowingEditSigner');

        if (this.isShowingEditSigner) {
          this.set('currentSigner', signer);
        }

        if (signer.get('hasDirtyAttributes')) {
          signer.rollbackAttributes();
        }
      },
      addViewer: function addViewer(email) {
        this.sendAction('addViewer', email);
        this.send('toggleAddViewer');
        this.set('newEmail', "");
      },
      removeViewer: function removeViewer(email) {
        this.sendAction('removeViewer', email);
      },
      updateSigner: function updateSigner(signer) {
        var _this = this;

        var flash = this.flashMessages;

        var success = function success() {
          signer.set('hasDirtyAttributes', false);
          flash.success(_this.i18n.t('components.documentAccessSettings.updateSigner'));

          _this.setProperties({
            saving: false,
            isShowingEditSigner: false
          });
        };

        var error = function error() {
          _this.setProperties({
            saving: false,
            isShowingEditSigner: false
          });
        };

        this.set('saving', true);
        this.sendAction('updateSigner', signer, success, error);
      }
    }
  });

  _exports.default = _default;
});