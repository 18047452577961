define("ember-rs/locales/fr/templates/onboarding/prepare-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding3 = void 0;
  var prepareOnboarding3 = {
    "seeSigners": "<span>Voir les signataires</span> pour ce document. Chaque signataire dispose de sa propre couleur, qui correspond aux champs à compléter"
  };
  _exports.prepareOnboarding3 = prepareOnboarding3;
});