define("ember-rs/locales/it/templates/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentAccessSettings = void 0;
  var documentAccessSettings = {
    "addViewer": "Aggiungi partecipante",
    "email": "E-mail",
    "name": "Name",
    "add": "Aggiungi",
    "cancel": "Annulla",
    "editSigner": "Modifica firmatario",
    "save": "Salva",
    "manageAccess": "Gestisci accesso",
    "accessTip": "Queste persone hanno accesso a questo documento e sono in grado di scaricarlo o visualizzarlo in qualsiasi momento.",
    "signers": "Firmatari",
    "edit": "modifica",
    "viewers": "Partecipanti",
    "remove": "rimuovi",
    "updateEmail": "Indirizzo di posta elettronica del firmatario aggiornato correttamente.",
    "updateSigner": "Successfully updated signer details."
  };
  _exports.documentAccessSettings = documentAccessSettings;
});