define("ember-rs/locales/fr/templates/reusable-templates", ["exports", "ember-rs/locales/fr/templates/reusable-templates/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplates = void 0;
  var reusableTemplates = {
    new: _new.newTrans
  };
  _exports.reusableTemplates = reusableTemplates;
});