define("ember-rs/locales/it/templates/components/document-token-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTokenExpirationSelect = void 0;
  var documentTokenExpirationSelect = {
    "Do_not_expire_links": "Non far scadere i collegamenti",
    "1_hour": "1 ora",
    "3_hours": "3 ore",
    "8_hours": "8 ore",
    "24_hours": "24 ore",
    "1_week": "1 settimana"
  };
  _exports.documentTokenExpirationSelect = documentTokenExpirationSelect;
});