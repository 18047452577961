define("ember-rs/models/document-transaction", ["exports", "jquery", "@ember-data/model", "ember-rs/config/environment", "moment", "ember-rs/mixins/embedded-records-has-many"], function (_exports, _jquery, _model, _environment, _moment, _embeddedRecordsHasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_embeddedRecordsHasMany.default, {
    ////////////////////////  ATTRIBUTES  ////////////////////////
    callbackUrl: (0, _model.attr)('string'),
    redirectUrl: (0, _model.attr)('string'),
    workflow: (0, _model.attr)('string', {
      defaultValue: 'signature_request'
    }),
    status: (0, _model.attr)('string'),
    signersCount: (0, _model.attr)('number'),
    account: (0, _model.belongsTo)('account', {
      async: false
    }),
    user: (0, _model.belongsTo)('user', {
      async: false
    }),
    mergeTransaction: (0, _model.belongsTo)('merge-transaction', {
      async: false
    }),
    documents: (0, _model.hasMany)('document', {
      async: true
    }),
    sortedDocuments: Ember.computed.sort('documents', function (a, b) {
      var aTime = (0, _moment.default)(a.getWithDefault('uploadCompletedAt', new Date()));
      var bTime = (0, _moment.default)(b.getWithDefault('uploadCompletedAt', new Date()));

      if (aTime > bTime) {
        return 1;
      } else if (aTime < bTime) {
        return -1;
      } else {
        return 0;
      }
    }),
    mergefieldValues: (0, _model.hasMany)('mergefield-value', {
      async: false
    }),
    ////////////////////////  PROPERTIES  ////////////////////////
    isSignatureRequest: Ember.computed.equal('workflow', 'signature_request'),
    isSelfSign: Ember.computed.equal('workflow', 'self_sign'),
    isBulkSend: Ember.computed.equal('workflow', 'bulk_send'),
    isPackage: Ember.computed.equal('workflow', 'packager'),
    notSelfSign: Ember.computed.not('isSelfSign'),
    isInPerson: Ember.computed.equal('workflow', 'in_person'),
    signerEmailRequired: Ember.computed.not('isInPerson'),
    ////////////////////////  METHODS  ////////////////////////
    // Save model and templates
    saveAndTemplates: function saveAndTemplates() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var savedDocuments = 0;

        var documentsToSave = _this.get('documents.length');

        _this.documents.forEach(function (doc) {
          doc.save().then(function () {
            savedDocuments += 1;

            if (savedDocuments === documentsToSave) {
              _this.save().then(function (documentTransaction) {
                resolve(documentTransaction);
              }, function () {
                return reject();
              });
            }
          }, function () {
            return reject();
          });
        });
      }, 'saving documents');
    },
    addSharefileItems: function addSharefileItems(sharefileItemIds) {
      var _this2 = this;

      var itemPromises = Ember.A();
      Ember.A(sharefileItemIds).forEach(function (itemId) {
        if (Ember.isPresent(itemId)) {
          var documentPromise = new Ember.RSVP.Promise(function (resolve, reject) {
            _jquery.default.ajax({
              type: 'GET',
              url: "".concat(_environment.default.api.endpoint, "/sharefile/").concat(itemId, "/item")
            }).done(function (itemMetadata) {
              _this2.createDocumentFromSharefileItem(itemMetadata).done(function (response) {
                var doc = _this2.createDocumentFromResponse(response, itemMetadata);

                doc.save().then(function (doc) {
                  resolve(doc);
                }).catch(function () {
                  reject();
                });
              }).fail(function () {
                reject();
              });
            }).fail(function () {
              reject();
            });
          }, 'creating document from SF item');
          itemPromises.pushObject(documentPromise);
        }
      });
      return Ember.RSVP.all(itemPromises);
    },
    createDocumentFromSharefileItem: function createDocumentFromSharefileItem(itemMetadata) {
      return _jquery.default.ajax({
        type: 'POST',
        url: "".concat(_environment.default.api.endpoint, "/integrations/sharefile/documents/document"),
        data: itemMetadata
      });
    },
    createDocumentFromResponse: function createDocumentFromResponse(response, metadata) {
      this.store.pushPayload('upload', response);
      var upload = this.store.peekRecord("upload", response.upload.id);
      var createOptions = {
        upload: upload,
        documentTransaction: this,
        account: this.account
      };
      var sharefileIntegration = this.get('user.integrations').findBy('provider', 'sharefile');

      if (sharefileIntegration) {
        metadata['sharefile_type'] = "external";
        createOptions.integration = sharefileIntegration;
        createOptions.integrationProvider = "sharefile";
        createOptions.integrationMetadata = metadata;
      }

      return this.store.createRecord('document', createOptions);
    }
  });

  _exports.default = _default;
});