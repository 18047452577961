define("ember-rs/templates/unauthorized/redirect-to-rs3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I9/EF/AG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.redirectToRs3.wrongVersion\"],null]],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.redirectToRs3.classic\"],null]],[13],[2,\"\\n\\n\"],[10,\"a\"],[14,0,\"button submit\"],[14,6,\"https://www.rightsignature.com\"],[12],[1,[30,[36,0],[\"unauthorized.redirectToRs3.go\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/redirect-to-rs3.hbs"
    }
  });

  _exports.default = _default;
});