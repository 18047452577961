define("ember-rs/locales/pt-br/templates/components/initial-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialOnboarding = void 0;
  var initialOnboarding = {
    "start": "Comece a usar o RightSignature™"
  };
  _exports.initialOnboarding = initialOnboarding;
});