define("ember-rs/locales/en/templates/components/account-user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "basic": "Basic Information",
    "firstName": "First Name:",
    "lastName": "Last Name:",
    "required": "*required",
    "email": "Email:",
    "emailTip": "*You must provide a valid email.",
    "password": "Password:",
    "hiddenForSecurity": "hidden for security",
    "reset": "Reset",
    "emailInfo": "Email Login Information",
    "oldPass": "Old Password:",
    "newPass": "New Password:",
    "passReq": "*Passwords must contain at least 8 characters, containing at least 1 number, 1 upper case letter, and 1 lower case letter.",
    "permissions": "Permissions",
    "manageUsers": "Manage users",
    "manageDelegateUsers": "Can delegate users",
    "manageViewBills": "Can view bills",
    "customizeBranding": "Customize account branding",
    "manageTemplates": "Manage templates",
    "viewDocuments": "View all documents",
    "removeUser": "Remove User",
    "reset2fa": "Reset two-step verification"
  };
  _exports.edit = edit;
});