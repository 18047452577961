define("ember-rs/locales/es/templates/components/document-download-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDownloadOptions = void 0;
  var documentDownloadOptions = {
    "originalPDF": "PDF original",
    "completedPDF": "PDF completado",
    "overlay": "PDF de superposición imprimible",
    "failed": "PDF completado (Fallido)",
    "support": "Hubo un problema al procesar el documento. Contacte con la asistencia técnica.",
    "cert": "Certificado de firma",
    "mergedPDF": "PDF combinado"
  };
  _exports.documentDownloadOptions = documentDownloadOptions;
});