define("ember-rs/components/integration-document-selector", ["exports", "ember-rs/mixins/integration-auth", "ember-rs/mixins/integration"], function (_exports, _integrationAuth, _integration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_integrationAuth.default, _integration.default, {
    //////////////////////////// DEPENDENCIES ////////////////////////////////
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    //////////////////////////// Properties ////////////////////////////////
    classNames: ['integrations-picker', 'document-picker-popup'],
    choosingIntegrations: true,
    choosingIntegrationDocuments: false,
    selectingFromRootFolder: true,
    hasQueried: false,
    //////////////////////////// Observers ///////////////////////////////
    hasMinQuery: Ember.computed.gt('_query.length', 2),
    hasQuery: Ember.computed.notEmpty('_query'),
    observesQueryDelegate: Ember.observer('queryDelegate', function () {
      var self = this;
      Ember.run.debounce(function () {
        self.set('_query', self.get('queryDelegate'));
      }, 1250);
    }),
    observesQuery: Ember.observer('_query', function () {
      if (this.hasMinQuery) {
        this.queryDocuments();
      } else if (this.hasQueried || this.get('queryDelegate.length') === 0) {
        this.set('queried', false);
        this.selectFromRootFolder();
      }
    }),
    //////////////////////////// Private Api ///////////////////////////////
    toggleChoosing: function toggleChoosing() {
      this.toggleProperty('choosingIntegrations');
      this.toggleProperty('choosingIntegrationDocuments');
    },
    selectFromRootFolder: function selectFromRootFolder() {
      this.set('selectingFromRootFolder', true);
      this.sendAction('getIntegrationDocuments', this.provider);
    },
    queryDocuments: function queryDocuments() {
      var self = this;
      var provider = this.provider;
      var query = this._query;
      this.set('loading', true);
      this.integrationRequest(provider, 'documents', 'search', 'POST', {
        query: query
      }).then(function (resp) {
        self.setProperties({
          integrationDocuments: resp.documents,
          loading: false,
          queried: true
        });
      }, function () {
        self.set('loading', false);
      });
    },
    //////////////////////////// Actions ///////////////////////////////
    actions: {
      getIntegrationDocuments: function getIntegrationDocuments(provider) {
        var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        this.set('provider', provider);
        this.toggleChoosing();
        this.sendAction('getIntegrationDocuments', provider, data);
      },
      selectIntegrationFolder: function selectIntegrationFolder(folder) {
        this.set('selectingFromRootFolder', false);
        this.sendAction('getIntegrationDocuments', this.provider, {
          folder_id: folder.file_id,
          folder_name: folder.folder_name
        });
      },
      navigateBack: function navigateBack() {
        if (Ember.isEqual(this.selectingFromRootFolder, false)) {
          this.selectFromRootFolder();
        } else {
          this.toggleChoosing();
        }
      },
      getIntegrationDocument: function getIntegrationDocument(doc) {
        this.toggleProperty('loading');
        return this.sendAction('getIntegrationDocument', doc, this.provider, this.integration);
      },
      deactivate: function deactivate() {
        var self = this;
        var provider = this.provider;
        this.toggleProperty('loading');
        this.integrationRequest(provider, 'documents', 'sending', 'DELETE').then(function () {
          self.get('flashMessages').success(self.get('i18n').t('components.integrationDocumentSelector.success', {
            provider: provider
          }));
          self.get('currentUser.model').reload();
          self.toggleChoosing();
        }, function () {
          self.get('flashMessages').warning(self.get('i18n').t('components.integrationDocumentSelector.error', {
            provider: provider
          }));
        });
        this.toggleProperty('loading');
      },
      toggleService: function toggleService(service, serviceEnabled, isAuthorized, provider, providerMetadata) {
        var params = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
        this.sendAction('toggleService', service, serviceEnabled, isAuthorized, provider, providerMetadata, params);
      }
    }
  });

  _exports.default = _default;
});