define("ember-rs/locales/de/templates/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choose_documents = void 0;
  var choose_documents = {
    "drag_drop": "Dateien hierher ziehen oder:",
    "choose_computer": "Auf einem Computer auswählen",
    "choose_template": "Wählen Sie die Vorlage für die Massensendung aus",
    "use_template": "Vorlage verwenden",
    "other_source": "Andere Quellen",
    "from_cloud": "aus der Cloud",
    "template": "Vorlage",
    "add_another": "Weitere Datei hinzufügen",
    "prepare_document": "Dokument vorbereiten",
    "prepare_documents": "Dokumente vorbereiten",
    "upload_signers": "Unterzeichner hochladen",
    "drag_drop_csv": "Ziehen Sie Ihre CSV-Datei hierher:",
    "select_file_computer": "Wählen Sie eine Datei auf Ihrem Computer aus",
    "remove_file": "Entfernen",
    "upload_success": "Datei wurde hochgeladen.",
    "upload_multifile": "Das Hochladen mehrerer Dateien wird nicht unterstützt. Bitte laden Sie nur eine Datei hoch.",
    "upload_invalid_extension": "Die Datei '{{{fileName}}}' hat eine ungültige Erweiterung.",
    "upload_size_limit": "Die Datei '{{{fileName}}}' ist größer als das Limit von 5 MB.",
    "send_package": "Dokumentenpaket senden",
    "file_size_exceeded": "Die Datei darf insgesamt nicht größer als {{{maxFileSize}}} MB sein. Entfernen Sie mindestens ein Dokument, um fortzufahren.",
    "max_files_exceeded": "Sie können nur {{{maxFileCount}}} Dokumente gleichzeitig vorbereiten.",
    "min_files_required": "Ein Paket erfordert mindestens zwei Dokumente.",
    "preparing_package": "Wir bereiten Ihr Dokument vor. Dies kann einige Sekunden dauern.",
    "merge_upload_error": "Beim Vorbereiten Ihres Dokuments ist ein Problem aufgetreten. Versuchen Sie es erneut, indem Sie Ihre Dokumente entfernen und erneut hochladen."
  };
  _exports.choose_documents = choose_documents;
});