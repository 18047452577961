define("ember-rs/locales/zh-cn/templates/signer/sign/locked-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lockedModal = void 0;
  var lockedModal = {
    "title": "文档已锁定",
    "maxAttempts": "您已超出尝试验证身份的次数上限。",
    "locked": "文档现已锁定，并已通知发件人。请与文档的发件人联系以继续。"
  };
  _exports.lockedModal = lockedModal;
});