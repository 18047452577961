define("ember-rs/locales/nl/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "notAllowed": "Actie niet toegestaan",
    "noPro": "U hebt momenteel geen PRO-toegang tot dit account. U kunt uw documenten ondertekenen en weergeven, maar PRO-functies zijn niet beschikbaar. Neem contact op met een beheerder van dit account die u PRO-toegang kan verlenen tot het volgende:",
    "send": "Documenten verzenden voor ondertekening",
    "create": "Sjablonen maken en gebruiken",
    "customize": "Huisstijl aanpassen",
    "enable": "Integraties van derden inschakelen",
    "reports": "Rapporten weergeven",
    "login": "Aanmelden bij RightSignature"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});