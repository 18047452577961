define("ember-rs/locales/zh-cn/templates/components/document-upload-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploadThumbnail = void 0;
  var documentUploadThumbnail = {
    "noThumb": "无缩略图...",
    "upload_done": "上载完成",
    "downloading": "正在下载",
    "storing": "正在存储",
    "converting_to_images": "正在转换为图像",
    "converting_to_pdf": "正在转换为 PDF",
    "processing": "正在处理",
    "created": "已创建"
  };
  _exports.documentUploadThumbnail = documentUploadThumbnail;
});