define("ember-rs/locales/es/templates/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerPaymentDetails = void 0;
  var signerPaymentDetails = {
    "paymentInfo": "Información de pago",
    "paymentID": "ID de pago:",
    "retryPayment": "Reintentando el pago...",
    "failed": "Falló",
    "chargedSuccess": "Cargo correcto en {{chargedAt}}.",
    "stripeCustomer": "Cliente de Stripe",
    "stripeCharge": "Cargo de Stripe",
    "attempted": "Último intento en {{chargedAt}}.",
    "retry": "Reintentar",
    "pending": "Pendiente...",
    "success": "Pago correcto tras reintentarlo.",
    "retryError": "Error al reintentar el pago ({{{errorMsg}}})."
  };
  _exports.signerPaymentDetails = signerPaymentDetails;
});