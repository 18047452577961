define("ember-rs/locales/ja/templates/signer/passcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcode = void 0;
  var passcode = {
    "enterPin": "PINの入力。",
    "access": "ドキュメントにアクセスするには提供された PIN を入力してください。",
    "unlock": "ロック解除",
    "docProtected": "このドキュメントはパスコードで保護されています。",
    "answer": "回答...",
    "show": "パスコードの表示",
    "continue": "ドキュメントに移動",
    "answerQuestion": "ドキュメントにアクセスするには、以下のセキュリティの質問に答えてください"
  };
  _exports.passcode = passcode;
});