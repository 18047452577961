define("ember-rs/locales/it/templates/components/transaction-manager-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transactionManagerHeader = void 0;
  var transactionManagerHeader = {
    "close": "CHIUDI",
    "sendDocument": "Invia documento",
    "signDocument": "Firma documento",
    "inPerson": "Firma di persona",
    "broken": "Interrotto"
  };
  _exports.transactionManagerHeader = transactionManagerHeader;
});