define("ember-rs/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ////////////////////////  BINDINGS  ////////////////////////
    queryParams: ['token', {
      applicationTheme: 'theme'
    }],
    showUserPopup: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    isAuthenticated: Ember.computed.readOnly('session.isAuthenticated'),
    notAuthenticated: Ember.computed.not('isAuthenticated'),
    isSigner: Ember.computed('router.currentRouteName', function () {
      return this.router.currentRouteName === 'signer' || this.router.currentRouteName === 'signer-passcode';
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleShowUserPopup: function toggleShowUserPopup() {
        this.toggleProperty('showUserPopup');
      },
      linkToEditInfo: function linkToEditInfo() {
        this.set('showUserPopup', false);
        this.transitionToRoute('user.settings');
      },
      startDocument: function startDocument() {
        var user = this.get("currentUser.model");

        if (user.get("account.isGracePeriod")) {
          console.log("grace period");
          this.set("showGracePeriodModal", true);
        } else {
          this.transitionToRoute("start-document");
        }
      },
      toggleShowGracePeriodModal: function toggleShowGracePeriodModal() {
        this.toggleProperty("showGracePeriodModal");
      }
    }
  });

  _exports.default = _default;
});