define("ember-rs/locales/pt-br/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newClientApplication = void 0;
  var newClientApplication = {
    "appReview": "Sua inscrição está sendo revisada. Nós notificaremos ou aprovaremos sua solicitação dentro de 24 horas.",
    "secretsSuccess": "Segredos recuperados, só devem ser usados por você ou por pessoas de sua confiança.",
    "secretsError": "Não foi possível recuperar os segredos: {{{error}}}",
    "tokenSuccess": "Novo token de API privado gerado com sucesso",
    "tokenError": "Não foi possível gerar token: {{{error}}}",
    "singleSecretSuccess": "Segredo recuperado para {{{clientAppName}}}. Ele só deve ser usado por você ou por pessoas de sua confiança.",
    "singleSecretError": "Não foi possível recuperar o segredo: {{{error}}}",
    "tokenRetrieveSuccess": "Token de API privado recuperado para {{{clientAppName}}}. Ele só deve ser usado por você ou por pessoas de sua confiança.",
    "tokenRetrieveError": "Não foi possível gerar token de API privado: {{{error}}}",
    "tokenRegenerateError": "Não foi possível regenerar o token: {{{error}}}",
    "confirmInfo": "Tem certeza? Uma alternativa pode ser solicitar uma nova chave de API, pois, uma vez regenerados, os tokens de API privados substituídos são irrecuperáveis. Se você optar por continuar, tenha o cuidado de remover e substituir as credenciais desatualizadas em todas as suas integrações."
  };
  _exports.newClientApplication = newClientApplication;
});