define("ember-rs/locales/zh-cn/mixins/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.settings = void 0;
  var settings = {
    "updateSuccessful": "已成功更新设置",
    "updateError": "更新设置时出错"
  };
  _exports.settings = settings;
});