define("ember-rs/components/center-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    attributeBindings: ['style'],
    centerHorizontal: true,
    centerVertical: false,
    thisWidth: 0,
    thisHeight: 0,
    parentWidth: 0,
    parentHeight: 0,
    setParentSize: function setParentSize() {
      this.set('parentWidth', this.$().parent().width());
      this.set('parentHeight', this.$().parent().height());
    },
    setThisSize: function setThisSize() {
      if (Ember.isNone(this.$())) {
        return;
      }

      this.set('thisWidth', this.$().width());
      this.set('thisHeight', this.$().height());
    },
    // TODO: ember deprecation warning
    // This is causing A property of <ember-rs@view:-outlet::ember628> was modified inside the didInsertElement hook. You should never change properties on components, services or models during didInsertElement because it causes significant performance degradation.
    updateSizeOnContentChange: Ember.observer('contentChanged', Ember.on('didInsertElement', function () {
      this.setThisSize();
      this.setParentSize();
      Ember.run.next(this, function () {
        this.setThisSize();
        this.setParentSize();
      });
    })),
    initResizeListener: Ember.on('didInsertElement', function () {
      // added namespace to resize event to be able to unbind just this event later
      this.$(window).on('resize.center', Ember.run.bind(this, this.setParentSize));
    }),
    destroyResizeEvent: Ember.on('willDestroyElement', function () {
      this.$(window).off('resize.center');
    }),
    style: Ember.computed('thisWidth', 'parentWidth', 'thisHeight', 'parentHeight', function () {
      var horizontalStyle = '';
      var verticalStyle = ''; // Set horizontal style

      if (this.centerHorizontal) {
        var marginLeft;
        var computedLeft = (this.parentWidth - this.thisWidth) / 2;

        if (computedLeft < 0) {
          marginLeft = 0;
        } else {
          marginLeft = computedLeft;
        }

        horizontalStyle = "margin-left: ".concat(marginLeft, "px; float: left;");
      } // Set vertical style


      if (this.centerVertical) {
        var marginTop;
        var computedTop = (this.parentHeight - this.thisHeight) / 2;

        if (computedTop < 0) {
          marginTop = 0;
        } else {
          marginTop = computedTop;
        }

        verticalStyle = "margin-top: ".concat(marginTop, "px;");
      }

      return (horizontalStyle + verticalStyle).htmlSafe();
    })
  });

  _exports.default = _default;
});