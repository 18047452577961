define("ember-rs/services/feature-flag-service", ["exports", "@harnessio/ff-javascript-client-sdk", "ember-rs/config/environment"], function (_exports, _ffJavascriptClientSdk, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    attributes: null,
    ldClient: null,
    harnessClient: null,
    ffUser: null,
    init: function init() {
      var attributes = {
        "subdomain": this.get('currentUser.account.subdomain'),
        "accountId": this.get('currentUser.accountId'),
        "IsMigrated": this.get('currentUser.account.isMigrated'),
        "WorkspaceEnabled": this.get('currentUser.account.isWorkspaceEnabled'),
        "IsCAR": this.get('currentUser.account.isCar'),
        "plan": this.get('currentUser.account.planName'),
        "billingType": this.get('currentUser.account.isHybris') ? "Hybris" : "Legacy",
        "trialType": this.get('currentUser.account.isHybrisTrial') ? "Hybris Trial" : this.get('currentUser.account.isTrial') ? "Legacy Trial" : "Not Trial",
        "trialExpiresAt": this.get('currentUser.account.trialExpiresAt') || this.get('currentUser.account.hybrisTrialExpiresAt'),
        "mfaEnabled": this.get('currentUser.account.isMfaEnabledForEmployee')
      };

      this._super.apply(this, arguments);

      this.set('attributes', attributes);
    },
    setFFUser: function setFFUser() {
      var userParam = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var ffUser = {};

      if (userParam == null) {
        ffUser = {
          "key": this.get('currentUser.id'),
          "email": this.get('currentUser.email'),
          "custom": this.get('attributes')
        };
      } else {
        if (userParam.id) {
          ffUser.key = userParam.id;
        }

        if (userParam.email) {
          ffUser.email = userParam.email;
        }

        if (userParam.anonymous) {
          ffUser.anonymous = userParam.anonymous;
        }
      }

      this.set('ffUser', ffUser);

      if (_environment.default.useHarness) {
        return this.setHarnessClient();
      } else {
        return this.setLDClient();
      }
    },
    setLDClient: function setLDClient() {
      var _this = this;

      var self = this;
      var options = {
        disableSyncEventPost: true
      };
      return new Ember.RSVP.Promise(function (resolve) {
        var ldClient = LDClient.initialize(_environment.default.launchDarkly.clientSideId, _this.ffUser, options);
        ldClient.on('ready', function () {
          self.set('ldClient', ldClient);
          resolve();
        });
      });
    },
    setHarnessClient: function setHarnessClient() {
      var _this2 = this;

      var options = {
        streamEnabled: true,
        pollingEnabled: true,
        pollingInterval: 90000
      };
      var attributes = this.get('attributes');
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        var harnessClient = (0, _ffJavascriptClientSdk.initialize)(_environment.default.harness.clientSDKKey, {
          identifier: _this2.ffUser.key,
          name: _this2.ffUser.custom.subdomain,
          attributes: attributes
        }, options);
        harnessClient.on(_ffJavascriptClientSdk.Event.READY, function () {
          self.set('harnessClient', harnessClient);
          resolve();
        });
      });
    },
    getFeatureFlag: function getFeatureFlag(_flagKey) {
      if (_environment.default.useHarness) {
        var flagKey = _flagKey.replaceAll('-', "_");

        return this.harnessClient ? this.harnessClient.variation(flagKey, false) : false;
      } else {
        return this.ldClient ? this.ldClient.variation(_flagKey, false) : false;
      }
    }
  });

  _exports.default = _default;
});