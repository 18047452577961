define("ember-rs/templates/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hk2r5DUC",
    "block": "{\"symbols\":[\"showFreeSteps\",\"isAdmin\",\"stepStyle\"],\"statements\":[[10,\"div\"],[14,0,\"theme\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"transaction_manager_header\"],[12],[13],[2,\"\\n  \"],[8,\"transaction-manager-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"rs-modal\",[],[[\"@show\",\"@action\",\"@class\",\"@centered\"],[[30,[36,3],[[35,2]],null],[30,[36,5],[[32,0],[30,[36,4],[[35,2]],null],false],null],\"onboard-modal initial-onboarding\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"on-boarding\",[],[[\"@currentUser\",\"@show\",\"@lastStepText\"],[[34,6],[34,2],[30,[36,7],[\"document_transactions.getStarted\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[19,\"onboarding/new-user-onboarding-from-sharefile\",[1,2,3]],[2,\"\\n  \"]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"-outlet\",\"component\",\"showOnboarding\",\"readonly\",\"mut\",\"action\",\"currentUser\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/document-transaction.hbs"
    }
  });

  _exports.default = _default;
});