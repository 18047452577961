define("ember-rs/components/document-overlayer-page", ["exports", "jquery", "ember-rs/mixins/document-page", "ember-rs/mixins/component-dimensions"], function (_exports, _jquery, _documentPage, _componentDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_documentPage.default, _componentDimensions.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    overlayerData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    didInsertElement: function didInsertElement() {
      var self = this; // initialize ghost events

      this.$().on('mousemove', '.ghost-component', function () {
        self.get('overlayerData').clearGhost();
      }).on('click', '.ghost-component', function (evt) {
        var x = self.get('overlayerData.ghostComponent.x') * self.get('documentViewerData.pageWidth');
        var y = self.get('overlayerData.ghostComponent.y') * self.get('documentViewerData.pageHeight');
        var position = self.get('overlayerData').eventPosition(evt);
        (0, _jquery.default)(evt.currentTarget).hide();
        (0, _jquery.default)(document.elementFromPoint(evt.clientX, evt.clientY)).trigger('click', {
          x: x + position[0],
          y: y + position[1]
        });
      }); // initialize selectable

      this.$().selectable({
        filter: '.component',
        distance: 30,
        start: function start() {
          self.set('overlayerData.isSelecting', true);
          self.get('overlayerData').deselectComponents();
        },
        stop: function stop() {
          var selectedComponents = Ember.A();
          (0, _jquery.default)('.ui-selected', this).each(function () {
            var componentId = (0, _jquery.default)(this).data('id');
            var component = self.get('components').filterBy('id', componentId)[0];

            if (component) {
              selectedComponents.pushObject(component);
            }
          });
          self.get('overlayerData').selectComponents(selectedComponents);
        }
      }); // initialize droppable

      this.$().find('.component-droppable').droppable({
        accept: '.draggable-component',
        activeClass: 'ui-state-default',
        hoverClass: 'ui-state-hover',
        drop: function drop(evt, ui) {
          var droppableEle = (0, _jquery.default)(event.target);

          if (!droppableEle.hasClass('component-droppable')) {
            droppableEle = droppableEle.parents('.component-droppable');
          }

          var x = evt.offsetX || evt.clientX - (0, _jquery.default)(evt.target).offset().left;
          var y = evt.offsetY || evt.clientY - (0, _jquery.default)(evt.target).offset().top;
          var options = {
            page: parseInt(droppableEle.data('page-number')),
            x: x / self.get('documentViewerData.pageWidth'),
            y: y / self.get('documentViewerData.pageHeight')
          };
          self.sendAction('addComponent', ui.draggable.data('component-class'), options);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      // destroy ghost events
      this.$().off('mousemove', '.ghost-component').off('click', '.ghost-component'); // destroy selectable

      this.$().selectable('destroy'); // destroy droppable

      this.$().find('.component-droppable').droppable('destroy');
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    isSingleComponentSelected: Ember.computed.equal('overlayerData.selectedComponents.length', 1),
    components: Ember.computed.filter('documentTemplate.components', function (component) {
      return component.get('page') === this.pageNumber;
    }),
    isComponentsSelectedOnPage: Ember.computed('overlayerData.selectedComponentsPage', function () {
      return this.pageNumber === this.get('overlayerData.selectedComponentsPage');
    }),
    pageCssStyle: Ember.computed('documentViewerData.pageHeight', 'documentViewerData.pageWidth', 'documentViewerData.reverseViewerScale', function () {
      return "width: ".concat(this.get('documentViewerData.reverseViewerScale'), "%; height: ").concat(this.get('documentViewerData.pageHeight'), "px; margin-bottom: ").concat(this.get('documentViewerData.pageMargin'), "px;").htmlSafe();
    }),
    ghostStyle: Ember.computed('overlayerData.ghostComponent', function () {
      if (Ember.isBlank(this.get('overlayerData.ghostComponent'))) {
        return 'display: none;'.htmlSafe();
      }

      var left = this.get('overlayerData.ghostComponent.x') * this.get('documentViewerData.pageWidth');
      var top = this.get('overlayerData.ghostComponent.y') * this.get('documentViewerData.pageHeight');
      var width = this.get('overlayerData.ghostComponent.width') * this.get('documentViewerData.pageWidth');
      var height = this.get('overlayerData.ghostComponent.height') * this.get('documentViewerData.pageHeight');
      return "left:".concat(left, "px; top:").concat(top, "px; width:").concat(width, "px; height:").concat(height, "px;").htmlSafe();
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      signatureCreated: function signatureCreated() {
        this.sendAction.apply(this, ['signatureCreated'].concat(Array.prototype.slice.call(arguments)));
      },
      deleteSignatureComponent: function deleteSignatureComponent() {
        this.sendAction.apply(this, ['deleteSignatureComponent'].concat(Array.prototype.slice.call(arguments)));
      },
      // TODO: better way to handle this bubbling up action from nested component?
      addComponent: function addComponent(componentClass, options) {
        if (componentClass === 'checkGroupComponent') {
          options.createNewGroup = true;
        }

        this.sendAction('addComponent', componentClass, options);
      },
      componentClicked: function componentClicked(component) {
        // bubble event
        this.sendAction('componentClicked', component);
      }
    }
  });

  _exports.default = _default;
});