define("ember-rs/templates/components/reports/average-cycle-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T8eOUIFy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"personal_info\"],[14,0,\"report-section\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"reports.components.average-cycle-time.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"description\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"reports.components.average-cycle-time.description\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"chart-legend\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"reports.components.account-document-volume.legend\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"ul\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[10,\"i\"],[14,0,\"fa fa-circle-o\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"reports.components.average-cycle-time.legend-mean-time-to-view\"],null]],[13],[2,\"\\n      \"],[10,\"li\"],[12],[10,\"i\"],[14,0,\"fa fa-check-circle\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"reports.components.average-cycle-time.legend-mean-time-to-sign\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"account-average-cycle-time-chart\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"type\",\"width\",\"height\",\"options\",\"data\",\"class\"],[\"line\",[35,3],200,[35,2],[35,1],\"average-cycle-time\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"chartData\",\"chartOptions\",\"chartWidth\",\"ember-chart\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/reports/average-cycle-time.hbs"
    }
  });

  _exports.default = _default;
});