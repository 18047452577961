define("ember-rs/locales/ja/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanDetails = void 0;
  var hybrisPlanDetails = {
    "document-sending": "ドキュメントの送信",
    "document-sending-info": "PDF、.doc などの一般的な形式でドキュメントをアップロードおよび送信します。",
    "signature": "手書きの署名",
    "signature-info": "マウスまたはタッチスクリーンで署名。紙の署名と同じように見えます。",
    "customer-support": "年中無休のカスタマーサポート",
    "customer-support-info": "優れた実績のあるテクニカル サポート チームから、必要なときにいつでもサポートを受けられます。",
    "email-alert": "アラート メール",
    "email-alert-info": "署名者への通知とドキュメントの状態の即時更新。",
    "integration": "統合アドオン",
    "integration-info": "RightSignature を 15 におよぶ最も人気のある高度なアプリに接続します。",
    "type-sign": "入力による署名",
    "type-sign-info": "署名者がキーボードを使用してドキュメントに署名できるようにします。",
    "mobile": "モバイル デバイスで署名",
    "mobile-info": "スマートフォンやタブレットを使用して、どこからでもドキュメントに署名できます。",
    "guided-signing": "ガイド付き署名",
    "guided-signing-info": "簡単な手順に従って、エラーや省略を減らします。",
    "archive": "セキュアなドキュメント アーカイブ",
    "archive-info": "セキュリティが保護され検索可能なアーカイブにより、重要なドキュメントを守ります。",
    "encrypt": "256 ビットのデータ暗号化",
    "encrypt-info": "銀行レベルのセキュリティで、機密文書を漏洩や盗難から保護します。",
    "biometric": "生体認証",
    "biometric-info": "主要な生体認証データを使用するため、署名者の本人確認に役立ちます。",
    "fingerprint": "デジタル指紋",
    "fingerprint-info": "SHA-1 デジタル チェックサムにより、関係者がドキュメントを変更するのを防ぎます。",
    "audit": "監査ログと証明書",
    "audit-info": "法令遵守のために、ドキュメント内でのすべての操作がログに記録され、保護されます。",
    "template": "再利用可能なテンプレート",
    "template-info": "よく使用されるドキュメントをチーム全体で共有します。",
    "branding": "カスタムブランディング",
    "branding-info": "RightSignature ドキュメントに組織のロゴ、色、写真を追加します。",
    "attachments": "添付ファイルを要求する",
    "attachments-info": "署名者がドキュメントを署名するときにファイルを添付できるようにします。",
    "payments": "支払いを要求する",
    "payments-info": "支払いを処理したり、ドキュメント上でクレジットカード情報を収集します。",
    "api": "開発者 API",
    "api-info": "RightSignature の電子署名を自分のアプリまたは Web サイトに統合します。",
    "kba": "ナレッジベース認証",
    "kba-info": "特定の業界で、関連する規制へのコンプライアンスを向上させます。",
    "standard": "Standard",
    "advanced": "<span>Advanced<span>",
    "advancedKba": "<span>Advanced KBA<span>",
    "sharefilePremium": "<span><p>Sharefile Premium</p> <p> (電子署名機能付き)</p></span>",
    "annually": "年単位",
    "quarterly": "四半期単位",
    "monthly": "月単位",
    "month": "月",
    "year": "年",
    "quarter": "四半期",
    "document-sends-per-user": "ユーザーあたり 100 件のドキュメント送信",
    "hand-written-text-to-sign": "手書き、テキストから署名への変換、クリックして同意",
    "bulk-send-for-signature": "署名用に一括送信",
    "sequenced-signing": "シーケンスに従って署名",
    "knowledge-based-authentication": "ナレッジベース認証",
    "unlimited-document-sends": "署名用に送信するドキュメント数は無制限",
    "store-sync-share-files": "ファイルをシームレスに保存、同期、共有",
    "hipaa-compliant": "HIPAA 準拠の構成",
    "advanced-caption": "次のような一般的な機能が含まれています:",
    "advanced-kba-caption": "次のような一般的な機能が含まれています:",
    "sharefile-caption": "Citrix ShareFile にすべての電子署名機能とそのほかの追加機能が含まれています:",
    "minimum-of": "{{{noUsers}}} ユーザー以上",
    "users": "ユーザー",
    "user": "ユーザー",
    "user-per-month": "1 ユーザー/月",
    "compare-plans-in-detail": "<a href='https://citrix.sharefile.com/share/view/s1493194af0a44866ae95a6a667e7ffcd' style='color:#2071C5' target='_blank'>プランの詳細比較</a>",
    "selected-plan-requires-3-users": "<span style='color:#536E7B'>選択したプランには最低 3 ユーザーが必要です</span>",
    "more-than-1000-users-contact-sales": "<span style='color:#2071C5'>1000 ユーザー以上が必要な場合は、<a href={{driftLinkId}} style='color: #2071C5; text-decoration: underline; text-transform: lowercase'>営業担当者にチャットでお問い合わせください。</a></span>",
    "trail-upgrade-msg": "トライアル期間は残り {{{numberOfDaysLeft}}} 日です。中断することなく安心してご利用いただくために、プランを今すぐアップグレードすることをご検討ください。",
    "savings": "節約額",
    "contact-sales": "営業担当者とチャット",
    "select-plan": "プランの選択",
    "selected-plan": "選択したプラン",
    "best-for-team-collaboration": "最安値"
  };
  _exports.hybrisPlanDetails = hybrisPlanDetails;
});