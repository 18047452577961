define("ember-rs/locales/zh-cn/controllers/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.branding = void 0;
  var branding = {
    "emailUpdateSuccess": "已成功更新用户电子邮件设置。",
    "emailUpdateFail": "更新用户电子邮件设置时出错。",
    "updateSuccess": "已成功更新用户帐户外观方案设置。",
    "updateFail": "更新用户帐户外观方案设置时出错。"
  };
  _exports.branding = branding;
});