define("ember-rs/serializers/document", ["exports", "ember-rs/serializers/document-template"], function (_exports, _documentTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentTemplate.default.extend({});

  _exports.default = _default;
});