define("ember-rs/locales/it/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    "choose": "3. Scegliere i dati da esportare",
    "columnNames": "I nomi delle colonne si basano sui nomi dei componenti nel documento:",
    "export": "4. Esportare in formato CSV",
    "selectColumns": "Seleziona colonne",
    "view": "È possibile aprire un file CSV in Excel per visualizzare i dati nei documenti inviati."
  };
  _exports.template = template;
});