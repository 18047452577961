define("ember-rs/locales/zh-cn/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding2 = void 0;
  var accountOnboarding2 = {
    "body": "通过<span>添加照片</span>将您的帐户外观个性化并使其看起来非常专业。",
    "mobileBody": "您的照片将显示在收件人电子邮件和文档签名页面中，以便签名者能够对签名体验充满信心。"
  };
  _exports.accountOnboarding2 = accountOnboarding2;
});