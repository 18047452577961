define("ember-rs/locales/pt-br/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "documents": "Documentos",
    "noResults": "Sem resultados de busca",
    "tryAgain": "Sua busca não retornou nenhum resultado. Tente outra busca para encontrar seu documento.",
    "anotherFilter": "O filtro que você escolheu não tem resultados. Tente outro filtro.",
    "documentsTip": "É aqui que ficam os seus documentos assinados e pendentes. Crie seu primeiro documento para começar.",
    "questions": "Tem uma pergunta? Encontre mais ajuda aqui.",
    "gotIt": "OK!",
    "startDocument": "Iniciar documento",
    "userGuide": "Guia do usuário"
  };
  _exports.documents = documents;
});