define("ember-rs/locales/nl/templates/components/component-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentEditor = void 0;
  var componentEditor = {
    "charge": "In rekening brengen na ondertekening?",
    "selectOne": "Selecteer een optie...",
    "amount": "Betalingsbedrag (USD):",
    "enterAmount": "Voer het betalingsbedrag in...",
    "required": "VEREIST: voer onderstaande helptekst van de ondertekenaar in, zodat de ontvanger weet wat hij/zij moet uploaden.",
    "signerHelp": "Helptekst ondertekenaar:",
    "helpText": "Helptekst invoeren...",
    "selectBox": "Selectievak moet minstens 2 opties bevatten.",
    "selectOptions": "Opties selecteren:",
    "addOption": "Optie toevoegen...",
    "addToGroup": "Toevoegen aan groep",
    "assignedTo": "Toegewezen aan:",
    "fieldRequired": "Verplicht veld?",
    "autoFillDate": "Auto-fill date signed",
    "autoFillDateHelpText": "A recipient will not be able to edit the field. It will be filled in automatically with the date on which the recipient signs.",
    "mergeField": "Samenvoegveld?",
    "mergeFieldName": "samenvoegveld heeft een naam nodig",
    "nameMergeField": "Naam samenvoegveld:",
    "setFontSize": "Tekengrootte instellen...",
    "muiltiline": "Meerdere regels?",
    "clearBackground": "Achtergrond wissen?",
    "addDefaultValue": "Standaardwaarde toevoegen?",
    "setDefaultValue": "Standaardwaarde instellen...",
    "defaultValue": "Standaardwaarde:",
    "nameThisField": "Dit veld een naam geven?",
    "name": "Naam:",
    "setName": "Naam van onderdeel instellen...",
    "textAlign": "Tekst uitlijnen",
    "showLess": "Minder weergeven",
    "showMore": "Meer weergeven",
    "delete": "Verwijderen",
    "saved": "Opgeslagen!",
    "save": "Opslaan",
    "dateFormat": "Datumnotatie",
    "left": "links",
    "center": "centreren",
    "right": "rechts",
    "groupRequirement": "Groepsvereiste",
    "only_one": "Slechts één",
    "one_or_more": "Eén of meer",
    "options_warning": "U hebt die optie al toegevoegd"
  };
  _exports.componentEditor = componentEditor;
});