define("ember-rs/locales/de/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    "choose": "3. Zu exportierende Daten auswählen",
    "columnNames": "Die Spaltennamen basieren auf den Namen der Komponenten im Dokument:",
    "export": "4. Als CSV-Datei exportieren",
    "selectColumns": "Spalten auswählen",
    "view": "Eine CSV-Datei kann in Excel geöffnet werden, um Daten zu Ihren gesendeten Dokumenten anzuzeigen."
  };
  _exports.template = template;
});