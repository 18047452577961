define("ember-rs/locales/en/templates/reusable-template/prepare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare = void 0;
  var prepare = {
    "prepare": "Prepare",
    "next": "Next: Review"
  };
  _exports.prepare = prepare;
});