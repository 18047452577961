define("ember-rs/locales/en/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationEditor = void 0;
  var documentExpirationEditor = {
    "theDocExpired": "The document expired on",
    "reactivated": "Please select when you would like it to expire and it will be reactivated.",
    "theDocExpires": "The document currently expires on",
    "newExpiration": "Please select when you would like it to expire.",
    "updateSuccess": "Expiration date successfully updated."
  };
  _exports.documentExpirationEditor = documentExpirationEditor;
});