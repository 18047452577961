define("ember-rs/locales/en/templates/components/signer", ["exports", "ember-rs/locales/en/templates/components/signer/signer-info"], function (_exports, _signerInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signer = void 0;
  var signer = {
    signerInfo: _signerInfo.signerInfo
  };
  _exports.signer = signer;
});