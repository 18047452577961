define("ember-rs/templates/components/faq-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FTFyaUmF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"question\"],[4,[38,0],[[32,0],\"setAnswer\"],null],[12],[2,\"\\n  \"],[1,[34,1]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"answer\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"question\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/faq-item.hbs"
    }
  });

  _exports.default = _default;
});