define("ember-rs/templates/onboarding/welcome-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P/c7abml",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[12],[2,\"  \"],[1,[30,[36,0],[\"onboarding.welcomeOnboarding1.welcome\"],null]],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[35,1,[\"model\",\"name\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"onboarding.welcomeOnboarding1.tips\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"currentUser\",\"first-name\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/welcome-onboarding-1.hbs"
    }
  });

  _exports.default = _default;
});