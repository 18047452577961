define("ember-rs/components/import-overlay-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    firstPageImage: Ember.computed.alias('documentTemplate.upload.baseFile.pageImages.firstObject.original'),
    originalPageWidth: Ember.computed.alias('documentTemplate.upload.baseFile.imageWidth'),
    originalPageHeight: Ember.computed.alias('documentTemplate.upload.baseFile.imageHeight'),
    scaleFactor: Ember.computed('originalPageHeight', 'originalPageWidth', function () {
      return this.originalPageWidth / this.originalPageHeight;
    }),
    pageWidth: 300,
    pageHeight: Ember.computed('pageWidth', 'scaleFactor', function () {
      return this.pageWidth * (1 / this.scaleFactor);
    }),
    classNameBindings: [':page'],
    attributeBindings: ['style'],
    components: Ember.computed.filterBy('documentTemplate.components', 'page', 1),
    style: Ember.computed('pageWidth', 'pageHeight', function () {
      return "width: ".concat(this.pageWidth, "px; height: ").concat(this.pageHeight, "px;").htmlSafe();
    })
  });

  _exports.default = _default;
});