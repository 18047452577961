define("ember-rs/components/component-toolbar", ["exports", "ember-rs/config/environment", "jquery", "ember-rs/utils/feature-flags"], function (_exports, _environment, _jquery, featureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    classNames: ['component-toolbar', 'theme__toolbar'],
    ////////////////////////  DEPENDENCIES  ////////////////////////
    overlayerData: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    featureFlagService: Ember.inject.service(),
    ////////////////////////  SETUP  ////////////////////////
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.$().find('.draggable-component').draggable({
        revert: true,
        helper: 'clone'
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      // fetch the merge field sources and schema, only for template preparation
      if (this.get('documentTemplate.type') === 'reusableTemplate') {
        if (this.get('currentUser.account.hasMergeFieldSources') && this.get("session.data.sharefileRedirect.dataSources")) {
          this.fetchDataFields();
        } else if (this.get('featureFlagService').getFeatureFlag(featureFlags.ENABLE_SALESFORCE_DATA_SOURCE)) {
          this.set('fetchingDataFields', true);

          _jquery.default.ajax({
            type: 'GET',
            url: "".concat(_environment.default.api.endpoint, "/merge_field_sources")
          }).then(function (response) {
            var source = response.merge_field_sources.find(function (item) {
              return item.source.toLowerCase() === 'quote';
            });

            if (!source) {
              return;
            }

            _this.set('isSalesforceSource', true);

            _this.fetchSchema(source);
          }).fail(function () {
            _this.flashMessages.warning(_this.i18n.t('components.componentToolbar.sourceFetchFailed'));
          });
        }
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$().find('.draggable-component').draggable('destroy');
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    annotationComponentsNotAllowed: Ember.computed.empty('annotationComponentClassesForTemplateTypeAndWorkflow'),
    listExpanded: false,
    showDataFields: Ember.computed('documentTemplate', 'currentUser.account', 'session.data.sharefileRedirect.dataSources', 'featureFlagService', function () {
      return this.get('documentTemplate.isReusable') && this.get('currentUser.account.hasMergeFieldSources') && (this.get('session.data.sharefileRedirect.dataSources') || this.get('featureFlagService').getFeatureFlag(featureFlags.ENABLE_SALESFORCE_DATA_SOURCE));
    }),
    requestComponentClassesForTemplateTypeAndWorkflow: Ember.computed('documentTemplate', 'overlayerData.componentClasses', function () {
      return this.componentClassesForTemplateTypeAndWorkflow('request');
    }),
    annotationComponentClassesForTemplateTypeAndWorkflow: Ember.computed('documentTemplate', 'overlayerData.componentClasses', function () {
      return this.componentClassesForTemplateTypeAndWorkflow('annotation');
    }),
    dataComponentForTemplateType: [],
    dataFieldTitle: '',
    fetchingDataFields: false,
    flattenSourceObjects: function flattenSourceObjects(components) {
      var componentWithChildren = components.find(function (component) {
        return component.source.children;
      });
      var flattenedChildren = [];

      var _loop = function _loop(i) {
        var children = componentWithChildren.source.children.map(function (child) {
          return {
            componentClass: 'textComponent',
            isSourceMapped: true,
            isRequired: false,
            source: {
              name: componentWithChildren.source.name,
              entity: componentWithChildren.source.entity,
              attribute_path: "".concat(componentWithChildren.source.attribute_path, "[").concat(i, "].").concat(child.path),
              display_name: "".concat(componentWithChildren.source.display_name, "-").concat(i + 1, "-").concat(child.name)
            }
          };
        });
        flattenedChildren = [].concat(_toConsumableArray(flattenedChildren), _toConsumableArray(children));
      };

      for (var i = 0; i < 50; i++) {
        _loop(i);
      }

      var componentsWithoutChildren = components.filter(function (component) {
        return !component.source.children;
      });
      this.set('dataComponentForTemplateType', [].concat(_toConsumableArray(componentsWithoutChildren), _toConsumableArray(flattenedChildren)));
    },
    fetchDataFields: function fetchDataFields() {
      var _this2 = this;

      this.set('fetchingDataFields', true);

      _jquery.default.ajax({
        type: 'GET',
        url: "".concat(_environment.default.api.endpoint, "/merge_field_sources")
      }).then(function (response) {
        //support only first source from the list
        //TODO: Support all the data_sources from the list
        var source;

        if (_this2.get('session.data.sharefileRedirect.dataSources')) {
          source = response.merge_field_sources.find(function (item) {
            return item.source.toLowerCase() === _this2.get("session.data.sharefileRedirect.dataSources")[0].toLowerCase();
          });
        } else if (_this2.get('featureFlagService').getFeatureFlag(featureFlags.ENABLE_SALESFORCE_DATA_SOURCE)) {
          source = response.merge_field_sources.find(function (item) {
            return item.source.toLowerCase() === 'salesforce.quote';
          });
        }

        if (!source) {
          _this2.set('fetchingDataFields', false);

          return;
        }

        _this2.fetchSchema(source);
      }).fail(function () {
        _this2.set('fetchingDataFields', false);

        _this2.flashMessages.warning(_this2.i18n.t('components.componentToolbar.sourceFetchFailed'));
      });
    },
    fetchSchema: function fetchSchema(source) {
      var _this3 = this;

      _jquery.default.ajax({
        type: 'GET',
        url: "".concat(_environment.default.api.endpoint, "/merge_field_sources/").concat(source.id, "/schema")
      }).then(function (response) {
        var attributes = response.schema;
        var dataFields = attributes.map(function (attribute) {
          return {
            componentClass: 'textComponent',
            isSourceMapped: true,
            isRequired: !!attribute.is_required,
            source: {
              name: source.source,
              entity: source.entity,
              attribute_path: attribute.path,
              display_name: attribute.name,
              children: attribute.children
            }
          };
        });

        _this3.setProperties({
          dataFieldTitle: source.source,
          dataComponentForTemplateType: dataFields
        });

        if (dataFields.some(function (field) {
          return field.source.children;
        })) {
          _this3.flattenSourceObjects(dataFields);
        }

        _this3.set('fetchingDataFields', false);
      }).fail(function () {
        _this3.set('fetchingDataFields', false);

        _this3.flashMessages.warning(_this3.i18n.t('components.componentToolbar.sourceFetchFailed'));
      });
    },
    componentClassesForTemplateTypeAndWorkflow: function componentClassesForTemplateTypeAndWorkflow(type) {
      var _this4 = this;

      if (type == 'data') {
        return (this.get('overlayerData.componentClasses') || []).filter(function (componentClass) {
          return componentClass.className === "textComponent";
        }).mapBy('className');
      }

      var workflow = this.get('documentTemplate.documentTransaction.workflow');
      return (this.get('overlayerData.componentClasses') || []).filter(function (componentClass) {
        return componentClass.availableFor.includes(_this4.get('documentTemplate.type').camelize()) && (workflow ? componentClass.availableFor.includes(workflow.camelize()) : true) && componentClass.type === type;
      }).mapBy('className');
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      addComponent: function addComponent(componentClass, options) {
        this.sendAction('addComponent', componentClass, options);
      },
      toggleMoreTools: function toggleMoreTools() {
        this.toggleProperty('showMoreTools');
      },
      expandList: function expandList() {
        this.toggleProperty('listExpanded');
      }
    }
  });

  _exports.default = _default;
});