define("ember-rs/locales/pt-br/templates/components/document-overlayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentOverlayer = void 0;
  var documentOverlayer = {
    "prepare": "Preparar documento",
    "tools": "ferramentas avançadas: ",
    "importOverlay": "Importar sobreposição",
    "toolTip": "Selecione um documento enviado anteriormente para colocar esses campos sobre este documento no mesmo local. Nota: O documento deve ter o mesmo nome.",
    "fieldDetection": "Detecção de campo",
    "fieldDetectionTip": "A detecção de campo ajuda você a alinhar campos com mais precisão a linhas e caixas em seu documento.",
    "roles": "funções",
    "rolesTip": "Veja as funções deste documento. Cada função tem sua própria cor, que corresponde aos campos que precisa preencher.",
    "signers": "signatários",
    "signersTip": "Veja os signatários deste documento. Cada signatário tem sua própria cor, que corresponde aos campos que precisa preencher.",
    "attachmentUnavailable": "Na conta selecionada não é possível utilizar anexos. Atualize para um plano profissional para solicitar anexos aos signatários.",
    "paymentUnavailable": "Na conta selecionada não é possível efetuar pagamentos. Atualize para um plano profissional para receber pagamentos de signatários.",
    "paymentNotEnabled": "Ative uma integração de pagamentos antes de usar componentes de pagamento. Você pode fazer isso na seção Integrações nas Configurações da sua conta.",
    "noRoles": "Nenhuma função encontrada! Provavelmente recarregado sem salvar?"
  };
  _exports.documentOverlayer = documentOverlayer;
});