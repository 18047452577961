define("ember-rs/locales/fr/templates/unauthorized/document-editing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentEditing = void 0;
  var documentEditing = {
    "heading": "Paused for revisions",
    "line1": "The sender is currently revising this document. Try again later or reach out to the sender for more information."
  };
  _exports.documentEditing = documentEditing;
});