define("ember-rs/locales/en/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers = void 0;
  var prepare_signers = {
    "add_signers": "Add Signers",
    "doc_limit_p1": "You have used ",
    "doc_limit_p2": "of your document limit for the month. Contact us to upgrade your account.",
    "recentContacts": "Recent Contacts"
  };
  _exports.prepare_signers = prepare_signers;
});