define("ember-rs/locales/en/templates/signer/sign", ["exports", "ember-rs/locales/en/templates/signer/sign/identify", "ember-rs/locales/en/templates/signer/sign/locked-modal", "ember-rs/locales/en/templates/signer/sign/verify"], function (_exports, _identify, _lockedModal, _verify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    identify: _identify.identify,
    lockedModal: _lockedModal.lockedModal,
    verify: _verify.verify,
    "pinSent": "A 4-digit verification PIN has been sent to you.",
    "pin": "PIN:",
    "submit": "submit",
    "back": "Back",
    "deliverPin": "you to deliver a 4-digit verification PIN for you to enter on the following page.",
    "afterPhoneComplete": "After entering a valid phone number, we will send you a 4-digit verification PIN via  {{{identityActionName}}} for you to enter on the following page.",
    "phone": "phone call",
    "sms": "sms",
    "countryCode": "Country Code:",
    "phoneNumber": "Phone Number:",
    "me": "me",
    "iAgreeComplete": "I agree to be charged <strong>{{{formattedMoney}}} (USD)</strong> upon execution of this document.",
    "usd": " (USD)",
    "uponExecution": " upon execution of this document.",
    "clickSubmit": "By clicking 'Submit' you agree to our",
    "privacyPolicy": "Privacy Policy",
    "consent": "E-Sign Consent",
    "contents": ", and the contents of this document.",
    "saveSuccess": "Successfully saved your progress. You can retrieve this document by clicking on the link in your email.",
    "submitButton": "Submit"
  };
  _exports.sign = sign;
});