define("ember-rs/locales/zh-cn/templates/components/code-generated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.codeGenerated = void 0;
  var codeGenerated = {
    "copyToClipboard": "复制到剪贴板",
    "copySuccess": "已复制!",
    "copyError": "无法复制剪贴板"
  };
  _exports.codeGenerated = codeGenerated;
});