define("ember-rs/locales/it/templates/signer/sign", ["exports", "ember-rs/locales/it/templates/signer/sign/identify", "ember-rs/locales/it/templates/signer/sign/locked-modal", "ember-rs/locales/it/templates/signer/sign/verify"], function (_exports, _identify, _lockedModal, _verify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    identify: _identify.identify,
    lockedModal: _lockedModal.lockedModal,
    verify: _verify.verify,
    "pinSent": "Ti è stato inviato un PIN di verifica di 4 cifre.",
    "pin": "PIN:",
    "submit": "invia",
    "back": "Indietro",
    "deliverPin": "you to deliver a 4-digit verification PIN for you to enter on the following page.",
    "afterPhoneComplete": "Dopo aver inserito un numero di telefono valido, invieremo un codice PIN di verifica di 4 cifre tramite {{{identityActionName}}} da inserire nella pagina seguente.",
    "phone": "telefonata",
    "sms": "sms",
    "countryCode": "Prefisso internazionale:",
    "phoneNumber": "Numero di telefono:",
    "me": "io",
    "iAgreeComplete": "Accetto di pagare <strong>{{{formattedMoney}}} (USD)</strong> quando questo documento viene reso esecutivo.",
    "usd": " (USD)",
    "uponExecution": " upon execution of this document.",
    "clickSubmit": "Facendo clic su \"Invia\" si accetta la nostra",
    "privacyPolicy": "Informativa sulla privacy",
    "consent": "Consenso con firma elettronica",
    "contents": "e i contenuti di questo documento.",
    "saveSuccess": "I progressi sono stati salvati correttamente. È possibile recuperare questo documento facendo clic sul collegamento presente nell'e-mail.",
    "submitButton": "Invia"
  };
  _exports.sign = sign;
});