define("ember-rs/components/archived-migrated-modal", ["exports", "ember-rs/mixins/invalidate-and-sign-out"], function (_exports, _invalidateAndSignOut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_invalidateAndSignOut.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    show: false,
    didInsertElement: function didInsertElement() {
      this.set('show', true);
    },
    actions: {
      signOut: function signOut() {
        this.invalidateAndSignOut();
      }
    }
  });

  _exports.default = _default;
});