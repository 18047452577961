define("ember-rs/locales/it/templates/components/template/template-signer-link-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.templateSignerLinkItem = void 0;
  var templateSignerLinkItem = {
    "view": "Visualizza",
    "details": "Dettagli",
    "reenable": "Abilita nuovamente",
    "disable": "Disabilita",
    "signed": "firmato",
    "identityMethod": "Metodo di identità: ",
    "expires": "Scade il: ",
    "passcode": "Protetto da passcode: ",
    "maxSigners": "Numero massimo di firmatari: ",
    "doesNotExpire": "Non scade"
  };
  _exports.templateSignerLinkItem = templateSignerLinkItem;
});