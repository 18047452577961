define("ember-rs/routes/templates/index", ["exports", "ember-cli-pagination/remote/route-mixin", "ember-rs/mixins/search-update", "ember-rs/mixins/track-display-results", "lodash"], function (_exports, _routeMixin, _searchUpdate, _trackDisplayResults, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, _searchUpdate.default, _trackDisplayResults.default, {
    currentUser: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: false
      }
    },
    page: 1,
    titleToken: 'Reusable Templates',
    model: function model(params) {
      params.page = parseInt(params.page || 1);

      var isNotPresent = function isNotPresent(value) {
        return _lodash.default.isNull(value) || _lodash.default.isUndefined(value) || value === 'null';
      };

      params.current_reusable_template = true;
      return this.findPaged('dashboard-reusable-template', _lodash.default.omit(params, isNotPresent));
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('search', '');
      }
    }
  });

  _exports.default = _default;
});