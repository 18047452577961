define("ember-rs/locales/zh-cn/templates/reusable-template/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "upload": "添加上载",
    "what": "需要签名的对象是什么?",
    "uploading": "正在上载",
    "uploadFile": "上载文件",
    "chooseCloud": "从云中选择",
    "prepare": "准备文档"
  };
  _exports.edit = edit;
});