define("ember-rs/locales/es/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationEditor = void 0;
  var documentExpirationEditor = {
    "theDocExpired": "El documento caducó el",
    "reactivated": "Seleccione cuándo quiere que caduque y se reactivará.",
    "theDocExpires": "El documento caduca actualmente el",
    "newExpiration": "Seleccione cuándo quiere que caduque.",
    "updateSuccess": "Fecha de caducidad actualizada correctamente."
  };
  _exports.documentExpirationEditor = documentExpirationEditor;
});