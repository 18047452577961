define("ember-rs/locales/nl/controllers/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "upgradeMsg": "Uw account heeft geen toegang tot deze functie. Upgrade naar een Pro-abonnement om ingesloten sjablonen te gaan gebruiken.",
    "templateDeleteMsg": "De sjabloon is verwijderd.",
    "tagUpdateMsg": "De tags zijn bijgewerkt",
    "tagUpdateFailedMsg": "Kan de tags niet bijwerken: {{{formattedResponse}}}",
    "templateDeleteConfirm": "Weet u zeker dat u deze sjabloon wilt verwijderen?",
    "loadSignerLinksFailedMsg": "Kan koppelingen voor sjabloonondertekenaar niet laden"
  };
  _exports.details = details;
});