define("ember-rs/locales/fr/templates/unauthorized/no-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noIntegrations = void 0;
  var noIntegrations = {
    "noIntegrations": "Votre plan ShareFile ne prend pas en charge l'intégration de signatures électroniques",
    "support": "Vous avez des questions ? <a href='https://support.citrix.com/cscase'>Contactez le support</a> ou appelez le 1-800-921-4250"
  };
  _exports.noIntegrations = noIntegrations;
});