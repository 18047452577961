define("ember-rs/locales/de/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFree = void 0;
  var newUserOnboardingFree = {
    "title": "Willkommen bei RightSignature.",
    "body": "Sie haben derzeit nur grundlegenden Zugriff auf RightSignature™. Sie können Dokumente selbst signieren sowie Ihre früheren Dokumente anzeigen. Wenden Sie sich an einen Administrator Ihres RightSignature™-Kontos, um Zugriff auf die PRO-Features zu erhalten."
  };
  _exports.newUserOnboardingFree = newUserOnboardingFree;
});