define("ember-rs/locales/en/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pendingIdentityVerification = void 0;
  var pendingIdentityVerification = {
    "pendingIdentityVerification": "Pending Identity Verification",
    "success": "You have successfully signed this document. To complete this document and return it to the sender you will need to verify your identity. To do this, simply check your email and click the 'verify email' link that was sent to you by RightSignature.",
    "resend": "Resend Identity Verificatio"
  };
  _exports.pendingIdentityVerification = pendingIdentityVerification;
});