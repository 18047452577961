define("ember-rs/mixins/taggable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    hasTags: Ember.computed('tags.[]', function () {
      return this.tags && Object.keys(this.tags).length > 0;
    })
  });

  _exports.default = _default;
});