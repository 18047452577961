define("ember-rs/templates/components/transaction-manager-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g3di96JL",
    "block": "{\"symbols\":[\"flash\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"flash_message_container\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0,[\"content\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"flash-message\",[],[[\"@flash\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"flashMessages\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/transaction-manager-container.hbs"
    }
  });

  _exports.default = _default;
});