define("ember-rs/locales/zh-cn/templates/components/documents/document-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentRow = void 0;
  var documentRow = {
    "signed": "已签名",
    "signer": "签名者",
    "legacy": " - 旧文档",
    "awaitingSignature": "等待您的签名",
    "sign": "签名",
    "completed": "已完成 ",
    "sent": "已发送",
    "voided": "已失效",
    "expired": "已过期 ",
    "auth_failure": "无法通过会话对签名者进行身份验证: {{{authError}}}"
  };
  _exports.documentRow = documentRow;
});