define("ember-rs/controllers/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    saving: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    showOnboarding: Ember.computed('session.data.sharefileRedirect', 'currentUser.model.hasSeenWelcomeOnboarding', 'currentUser.model.hasSeenPrepareOnboarding', function () {
      return Ember.get(this, 'session.data.sharefileRedirect') && !Ember.get(this, 'currentUser.model.hasSeenWelcomeOnboarding') && !Ember.get(this, 'currentUser.model.hasSeenPrepareOnboarding');
    })
  });

  _exports.default = _default;
});