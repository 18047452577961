define("ember-rs/components/show-component", ["exports", "ember-rs/mixins/component-style", "ember-rs/mixins/component-dimensions", "ember-rs/utils/font-scaling"], function (_exports, _componentStyle, _componentDimensions, _fontScaling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var fieldTypeNames = {
    signatureComponent: 'components.overlayComponent.signature',
    textComponent: 'components.overlayComponent.text',
    dateComponent: 'components.overlayComponent.date',
    checkComponent: 'components.componentToolbarMenuitem.checkComponent',
    checkGroupComponent: 'components.componentToolbarMenuitem.checkGroupComponent',
    initialsComponent: 'components.componentToolbarMenuitem.initialsComponent',
    selectComponent: 'components.componentToolbarMenuitem.selectComponent',
    signatureAnnotationComponent: 'components.componentToolbarMenuitem.signatureAnnotationComponent',
    textAnnotationComponent: 'components.componentToolbarMenuitem.textAnnotationComponent',
    checkmarkAnnotationComponent: 'components.componentToolbarMenuitem.checkmarkAnnotationComponent',
    attachmentComponent: 'components.componentToolbarMenuitem.attachmentComponent',
    paymentComponent: 'components.componentToolbarMenuitem.paymentComponent',
    unknownField: 'components.componentToolbarMenuitem.unknownField'
  };

  var _default = Ember.Component.extend(_componentStyle.default, _componentDimensions.default, {
    store: Ember.inject.service(),
    paymentComponentData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    classNameBindings: [':component', 'componentType', 'component.isRequired:isRequired', 'hasValue', 'component.isSignature:flattened-as-signature', 'component.isSignature:flattened-component', 'documentState', 'submittedWithoutValue', 'component.textAlignClass', 'component.transparent:is-transparent'],
    attributeBindings: ['style', 'dataId:data-id'],
    dataId: Ember.computed.oneWay('component.id'),
    componentData: Ember.computed('component.isMergeField', function () {
      return Ember.get(this, 'component.isMergeField') ? Ember.get(this, 'component.mergefieldValue') : Ember.get(this, 'component.componentValue');
    }),
    hasValue: Ember.computed.notEmpty('componentData.value'),
    hasNoValue: Ember.computed.not('hasValue'),
    signerSigned: Ember.computed.notEmpty('component.documentSigner.signedAt'),
    assignedToName: Ember.computed('component.documentTemplate.type', 'component.documentSigner.name', 'component.assignedTo', function () {
      if (this.get('component.documentTemplate.type') === "document") {
        return this.get('component.documentSigner.name');
      }

      return this.get('component.assignedTo');
    }),
    submittedWithoutValue: Ember.computed('signerSigned', 'hasValue', 'isCheckBox', 'component.componentValue.value', function () {
      return this.signerSigned && (this.isCheckBox ? this.get('component.componentValue.value') === 'false' : !this.hasValue);
    }),
    fieldName: Ember.computed('component.type', function () {
      if (this.get('component.isDateSigned')) {
        return this.get('i18n').t('components.overlayComponent.dateAutoFill');
      }

      if (this.get('component.isTextField') && this.get('component.isSourceMapped')) {
        return this.get('component.source.display_name');
      } else {
        return this.get('i18n').t(fieldTypeNames[this.get('component.type')] || fieldTypeNames['unknownField']);
      }
    }),
    requiredStateText: Ember.computed('component.isRequired', function () {
      return this.get('component.isRequired') ? "required" : "optional";
    }),
    isSignature: Ember.computed.oneWay('component.isSignature'),
    dateFormat: Ember.computed.oneWay('component.data.dateFormat'),
    signature: Ember.computed('componentData.value', function () {
      if (this.get('componentData.value')) {
        return this.store.findRecord('signature', this.get('componentData.value'));
      }
    }),
    isAttachment: Ember.computed.oneWay('component.isAttachment'),
    attachment: Ember.computed('componentData.value', function () {
      if (this.get('componentData.value')) {
        return this.store.findRecord('attachment', this.get('componentData.value'));
      }
    }),
    isPayment: Ember.computed.oneWay('component.isPayment'),
    signerPayment: Ember.computed('componentData.value', function () {
      if (this.get('componentData.value')) {
        return this.store.findRecord('signerPayment', this.get('componentData.value'));
      }
    }),
    isCheckBox: Ember.computed.or('component.isCheckBox', 'component.isGrouped'),
    // overwrite overlay-component hover
    initHover: false,
    componentWidth: Ember.computed('component.width', 'documentViewerData.pageWidth', function () {
      return this.get('component.width') * this.get('documentViewerData.pageWidth');
    }),
    isMultiline: Ember.computed.bool('component.multiline'),
    isSingleLine: Ember.computed.not('isMultiline'),
    multilineStyle: Ember.computed('componentData.fontSize', function () {
      var fontSize = this.get('componentData.fontSize') || _fontScaling.DEFAULT_FONT_SIZE;

      return "font-size: ".concat(fontSize, "px; line-height: ").concat(_fontScaling.LINE_HEIGHT_MULTIPLIER * fontSize, "px").htmlSafe();
    }),
    userInputStyle: Ember.computed('componentWidth', 'isSingleLine', 'componentData.value', function () {
      var fontSize = this.get('component.fontSize') || 12;

      if (this.isSingleLine) {
        var numChars = this.get('componentData.value.length');
        var width = this.componentWidth - 15;

        if (numChars * (fontSize * 0.6) > width) {
          var widthPerChar = width / numChars;
          fontSize = widthPerChar * (1 / 0.6);

          if (fontSize > 13) {
            fontSize = 13;
          }
        }
      }

      return "font-size: ".concat(fontSize, "px;").htmlSafe();
    }),
    actions: {
      showSignerPayment: function showSignerPayment() {
        this.paymentComponentData.set("selectedSignerPayment", this.signerPayment);
        this.paymentComponentData.set("showSignerPaymentModal", true);
      }
    }
  });

  _exports.default = _default;
});