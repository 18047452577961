define("ember-rs/locales/fr/templates/signer/sign", ["exports", "ember-rs/locales/fr/templates/signer/sign/identify", "ember-rs/locales/fr/templates/signer/sign/locked-modal", "ember-rs/locales/fr/templates/signer/sign/verify"], function (_exports, _identify, _lockedModal, _verify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    identify: _identify.identify,
    lockedModal: _lockedModal.lockedModal,
    verify: _verify.verify,
    "pinSent": "Un code PIN de vérification à 4 chiffres vous a été envoyé.",
    "pin": "Code PIN :",
    "submit": "envoyer",
    "back": "Précédent",
    "deliverPin": "de fournir un code PIN de vérification à 4 chiffres que vous devrez saisir sur la page suivante.",
    "afterPhoneComplete": "Après avoir entré un numéro de téléphone valide, nous vous enverrons un code PIN de vérification à 4 chiffres via un {{{identityActionName}}}. Vous devrez le saisir sur la page suivante.",
    "phone": "appel téléphonique",
    "sms": "SMS",
    "countryCode": "Indicatif du pays :",
    "phoneNumber": "Numéro de téléphone :",
    "me": "moi",
    "iAgreeComplete": "J'accepte d'être facturé <strong>{{{formattedMoney}}} (USD)</strong> lors de l'exécution de ce document.",
    "usd": " (USD)",
    "uponExecution": " lors de l'exécution de ce document.",
    "clickSubmit": "En cliquant sur « Envoyer », vous acceptez notre",
    "privacyPolicy": "Déclaration de confidentialité",
    "consent": "Consentement E-Sign",
    "contents": "et le contenu de ce document.",
    "saveSuccess": "Votre progression a bien été enregistrée. Vous pouvez récupérer ce document en cliquant sur le lien dans votre e-mail.",
    "submitButton": "Envoyer"
  };
  _exports.sign = sign;
});