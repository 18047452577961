define("ember-rs/locales/de/templates/onboarding/send-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding3 = void 0;
  var sendOnboarding3 = {
    "remind": "Wählen Sie <span>Erinnerung</span>, um Unterzeichner zu erinnern.",
    "void": "Wählen Sie <span>Stornieren</span>, um das Dokument zu stornieren."
  };
  _exports.sendOnboarding3 = sendOnboarding3;
});