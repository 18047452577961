define("ember-rs/locales/zh-cn/templates/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentAccessSettings = void 0;
  var documentAccessSettings = {
    "addViewer": "添加查看者",
    "email": "电子邮件",
    "name": "Name",
    "add": "添加",
    "cancel": "取消",
    "editSigner": "编辑签名者",
    "save": "保存",
    "manageAccess": "管理访问权限",
    "accessTip": "这些人有权访问此文档并且能够随时进行下载或查看。",
    "signers": "签名者",
    "edit": "编辑",
    "viewers": "查看者",
    "remove": "删除",
    "updateEmail": "已成功更新签名者电子邮件地址。",
    "updateSigner": "Successfully updated signer details."
  };
  _exports.documentAccessSettings = documentAccessSettings;
});