define("ember-rs/locales/de/templates/components/add-role-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoleField = void 0;
  var addRoleField = {
    "name": "Name...",
    "emailOptional": "E-Mail (optional)...",
    "email": "E-Mail...",
    "nameTip": "Vollständiger Name erforderlich.",
    "emailTip": "Gültige E-Mail-Adresse erforderlich.",
    "roleTip": "Rollenname erforderlich."
  };
  _exports.addRoleField = addRoleField;
});