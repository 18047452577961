define("ember-rs/controllers/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    layoutSizeData: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    supportContactFormVisible: false,
    currentVideoID: false,
    currentVideoName: false,
    showMoreSupportLinks: false,
    email: Ember.computed.alias('currentUser.email'),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      setCurrentVideo: function setCurrentVideo(id, name) {
        if (this.get('layoutSizeData.useMobileLayout')) {
          window.open("https://vimeo.com/".concat(id), "_blank");
        } else {
          this.set('currentVideoID', id);
          this.set('currentVideoName', name);
        }
      },
      toggleShowMoreSupportLinks: function toggleShowMoreSupportLinks() {
        this.toggleProperty('showMoreSupportLinks');
      },
      submitSupportRequest: function submitSupportRequest() {
        var _this = this;

        this.set('supportContactFormVisible', false);
        return this.ajax.post('inquiries', {
          data: {
            inquiry: this.getProperties('email', 'subject', 'body')
          }
        }).then(function () {
          _this.flashMessages.success(_this.i18n.t('controllers.help.success'));
        }).catch(function (response) {
          _this.flashMessages.warning(_this.i18n.t('controllers.help.error', {
            error: response.message
          }));
        }).finally(function () {
          _this.set('subject', '');

          _this.set('body', '');
        });
      }
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    name: Ember.computed.alias('currentUser.model.name'),
    helpScrollableContentStyle: Ember.computed('layoutSizeData.windowHeight', 'layoutSizeData.mainHeaderHeight', function () {
      var height = this.get('layoutSizeData.windowHeight') - this.get('layoutSizeData.mainHeaderHeight');
      return "width: 100%; height: ".concat(height, "px; overflow: auto;").htmlSafe();
    })
  });

  _exports.default = _default;
});