define("ember-rs/locales/en/templates/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faqModal = void 0;
  var faqModal = {
    "docId": "Document ID:",
    "contactSender": "Contact the sender: {{{userName}}} at <a href='mailto: {{{userEmail}}}'>{{{userEmail}}}</a> with any questions",
    "needHelp": "Need some help using RightSignature&trade;? Try these common Q&As:",
    "q1": "I have a question about the contents of this document:",
    "rsNote": "RightSignature&trade; is a third-party online document signing service. We facilitate the sending and signing of documents with legal, secure, electronic signatures but are not associated with the sender or the document in any way.",
    "q2": "How do I complete this document?",
    "a2": "The red arrow at the left of the document will point you to the next required field. Once all required fields are complete the 'Submit' button will become available to click.",
    "q3": "Is this legally binding?",
    "a3": "Yes. Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. It is highly likely that you have already contracted online. For example, when you click on an 'I Agree' button on the internet, you are usually a party to what is known as a Clickwrap Contract. Also, when you purchase goods online, you are a party to a legally binding contract.",
    "a3part2": "RightSignature&trade;'s technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods. Each of these acts reinforces the validity of electronic agreements. According to ESIGN, for example, a contract 'may not be denied legal effect, validity, or enforceability solely because an electronic signature or electronic record was used in its formation.",
    "q4": "What is RightSignature™?",
    "a4": "RightSignature&trade; allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. RightSignature&trade; is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
    "q5": "What does RightSignature™ do?",
    "a5": "RightSignature&trade; allows you to create and sign legally binding documents online, in a way that is faster and more secure than executing paper documents. RightSignature&trade; is a web-based software-as-a-service that utilizes the secure data infrastructure of Amazon Web Services.",
    "q6": "How does RightSignature™ enable online document signing?",
    "a6": "RightSignature&trade; makes it easy to obtain signatures from multiple parties online. Simply upload your document and enter recipient information. RightSignature&trade; emails recipients a unique link to a page where they can review the document, fill out any necessary text fields, and sign. Recipients may sign online with a mouse in seconds.",
    "a6part2": "Today, most businesses sign documents by faxing back and forth, sending overnight envelopes, or scanning and emailing signature pages. RightSignature&trade; eliminates the hassle, time delays, and expense of these tedious, antiquated methods. Executing a legally binding document and safely storing it on a secure server can be accomplished in minutes, with a few mouse clicks.",
    "q7": "Isn't it safer to sign a paper document?",
    "a7": "Electronic contracts are significantly safer and more secure than paper contracts and have the same legal standing. While documents sent by fax and mail are exposed throughout intra-office distribution, electronic documents are kept completely confidential and are viewed only by designated recipients. RightSignature&trade; documents are stored on secure servers with redundant copies and, unlike paper documents, cannot be misplaced, lost, or destroyed. Furthermore, email receipts, IP records, and biometric signature data provide authentication of each signing party.",
    "q8": "Who is this service for?",
    "a8": "RightSignature&trade; is ideal for small and medium businesses, freelancers, designers, developers, attorneys, contractors, schools, non-profits, and many more. If your work requires signed documents, you need RightSignature&trade;.",
    "q9": "What kinds of documents should I send?",
    "a9": "Documents sent regularly on RightSignature&trade; include contracts, non-disclosure agreements, proposal acceptances, change orders, ad proofs, engagement letters, expense reports, purchase orders, rental agreements, independent contractor agreements, new client applications, employment applications, and many more. Compliance forms like the W-9, W-4, and I-9 are ideal for RightSignature&trade;. Need to have all team members sign off on a policy change? RightSignature&trade; makes it easy. Businesses send everything from timesheet sign-offs to multi-million-dollar contracts via RightSignature&trade;.",
    "q10": "What are the supported browsers?",
    "a10": "RightSignature&trade; provides the best user experience in the following browsers. To upgrade your browser to the latest version, visit:",
    "a10part2": "Mozilla Firefox 3+, Microsoft Internet Explorer 9+, Apple Safari 3+, Google Chrome, Opera 9+",
    "q11": "What should I do if I can't log in?",
    "a11": "Carefully check your email address to be sure you haven't mistyped it. Also, type your password slowly and carefully. If those don't work, you can reset your password by clicking 'I have forgotten my password.' If you follow the password reset process, and you are still unable to log in, please contact us for assistance.",
    "q12": "Do recipients need to have a RightSignature™ account?",
    "a12": "No. Recipients do not need to have an account to receive a document from you. There is no limit to the number of recipients to whom you send documents. When you send a document, your recipient will receive an email containing a unique link. After clicking the link, the recipient views and signs the document in the browser, with no downloads and no plug-ins. RightSignature&trade; is so easy and intuitive that new recipients are able to sign in seconds, without instructions.",
    "q13": "What makes RightSignature™ better than the competitors?",
    "a13": "RightSignature&trade; is renowned for its elegant, intuitive user experience and capture of real, handwritten signatures. With infrastructure by Amazon Web Services, our security and scalability are world-class. Continuous development and improvement keeps RightSignature&trade; on the cutting edge. For the solo professional and small business, RightSignature&trade; provides the simplest and most effective online document software.",
    "q14": "How does RightSignature™ support a paper-free initiative?",
    "a14": "Motivated by environmental concerns and cost savings, many offices are pursuing paper-free initiatives. RightSignature&trade; is a key component in reducing paper use, providing a secure, effective method to get documents signed online without printed copies.",
    "q15": "What is an electronic signature?",
    "a15": "RightSignature&trade;'s signature pad enables you to use your= mouse to sign a document, creating a valid electronic signature as defined by U.S. and E.U. legislation and directives. Any mark you make in the signature pad is valid, even if it does not resemble your handwritten signature. The courts broadly interpret the concept of 'signature' and a signature need not, in fact, be a 'signature' in the traditional sense of the word. For example, a rubber stamp has been recognized as a signature, as has a mark made by an illiterate, both of which illustrate the concept that any willful mark made by a party to a contract can be recognized as a signature. ",
    "a15part2": "<p>Documents executed online with electronic signatures are legally valid and have the same legal standing as paper documents executed by hand. RightSignature&trade;'s technology is in full compliance with the 2000 U.S. Electronic Signatures in Global and National Commerce Act (ESIGN) and the Uniform Electronic Transactions Act (UETA), as well as the European Directive (EC/1999/93), all of which were intended to encourage the rapid adoption of electronic signatures and decrease use of antiquated paper methods.</p><p>RightSignature&trade;'s technology fulfills the U.S. definition of an 'electronic signature:' an electronic sound, symbol, or process, attached to or logically associated with a contract or other record and executed or adopted by a person with the intent to sign the record</p><p>RightSignature&trade;'s technology also fulfills the more stringent E.U. requirements of an 'advanced electronic signature:'</p>",
    "a15part3": "  it is uniquely linked to the signatory;  it is capable of identifying the signatory;  it is created using means that the signatory can maintain under his sole control; and it is linked to the data to which it relates in such a manner that any subsequent change of the data is detectable. References for further reading: 'Electronic Signatures and Online Contracts,' Nolo.com article, Directive 1999/93/EC of the European Parliament, U.S. Signatures in Global and National Commerce Act (ESIGN), Uniform Electronic Transactions Act (UETA)",
    "a15part4": "For more information on the legal validity of documents executed with RightSignature&trade;, please see our Legality page.",
    "q16": "How do I know the other party to the contract is who they say they are?",
    "a16": "Verifying the identity of an individual is an important element of any signature, whether electronic or written. An electronic signature must be capable of identifying a signatory and must be uniquely linked to the signatory. RightSignature&trade; uses a multi-variate authentication process, which includes email address verification, IP address tracking, biometric signature algorithm, and other information. Authentication on RightSignature&trade; is significantly stronger than it is on paper documents, where parties often have an assistant, spouse, or other person sign on their behalf.",
    "q17": "How do you know the location of the signer?",
    "a17": "Every computer on the internet is identified by an IP, or internet protocol, address. For example, your IP address is: 99.102.245.244. From this address, we can track your computer to a physical location. Although this is not 100% accurate, it provides an additional authentication tool for parties using RightSignature&trade; to execute their documents.",
    "q18": "Can an executed document be manipulated by one of the parties after signing?",
    "a18": "Once uploaded and sent for signatures, a document is locked and stored on a secure server with redundant copies. After execution, the final document is bound with a secure hash algorithm, ensuring that no party may make changes to an executed document. RightSignature&trade;'s neutrality provides further legal defensibility to any attempts to invalidate an executed agreement. For more information on our secure data infrastructure, visit our Security page.",
    "q19": "Are the digital signatures certified by a third-party authority?",
    "a19": "RightSignature&trade;, as a third-party signing service coordinating workflow between document signers, provides a significant step-up in audit and verification capability over fax and email methods. A benefit of using RightSignature&trade; is that our service is a neutral third-party, much like an escrow/title agency in a real estate transaction. Once a document is executed, RightSignature&trade; bounds it with a secure hash algorithm and stores redundant copies using the Amazon Web Services infrastructure. Should a contract signed on RightSignature&trade; be challenged in court, it would be very difficult or impossible for a party to assert that the document had been tampered with. The record of the RightSignature&trade; process and post-execution lock provides third-party verification for robust, defensible contracts.",
    "q20": "How can I be sure that I am signing the same document as the other party?",
    "a20": "Every document uploaded to RightSignature&trade; is bound to a unique, long-string reference number, comprised of numerals, upper and lower case letters, and characters. This reference number is included in every communication about and screen containing the document, ensuring that all parties are viewing the same document at all times.",
    "a20part2": "There is no function to change the file once it is uploaded and sent out for signatures. If a document has not been signed by any recipients yet, the sending party may trash it and upload and send a new document, which will have a new reference number.",
    "q21": "Can I brand RightSignature™ with my logo?",
    "a21": "With RightSignature&trade;..."
  };
  _exports.faqModal = faqModal;
});