define("ember-rs/components/attachment-uploader", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    classNames: ['attachment'],
    signer: Ember.computed.alias('component.documentSigner'),
    isUploading: false,
    showUpload: Ember.computed('isUploading', 'componentValue.value', function () {
      return !this.isUploading && Ember.isBlank(this.get('componentValue.value'));
    }),
    attachment: Ember.computed('componentValue.value', function () {
      if (Ember.isPresent(this.get('componentValue.value'))) {
        return this.store.findRecord('attachment', this.get('componentValue.value'));
      }
    }),
    url: Ember.computed('signer.id', 'component.id', 'signer.identityToken', 'signer.accessToken', function () {
      return _environment.default.api.endpoint.concat("/signers/".concat(this.get('signer.id'), "/components/").concat(this.get('component.id'), "/attachments?access_token=").concat(this.get('signer.accessToken') || '', "&identity_token=").concat(this.get('signer.identityToken') || ''));
    }),
    fileUploaderEle: function fileUploaderEle() {
      return this.$('.file-uploader');
    },
    initializeUploader: Ember.on('didInsertElement', function () {
      var self = this;
      var store = this.store;
      var flash = this.flashMessages;
      var fileupload_options = {
        autoUpload: true,
        sequentialUploads: true,
        singleFileUploads: true
      };
      this.fileUploaderEle().fileupload(_jquery.default.extend(fileupload_options, {
        dataType: 'json',
        method: 'POST',
        url: self.get('url'),
        paramName: 'attachment',
        submit: function submit(e, data) {
          self.set('isUploading', true);
          data.jqXHR = (0, _jquery.default)(this).fileupload('send', data);
          return false;
        },
        done: function done(e, data) {
          var attachment = data.jqXHR.responseJSON.attachment;
          store.pushPayload(data.jqXHR.responseJSON);
          self.set('isUploading', false);
          self.set('componentValue.value', attachment.id);
          self.get('component.documentSigner').addAudit("Applied Attachment ".concat(attachment.id, " to component ").concat(self.get('component.id')));
          flash.success(self.get('i18n').t('components.attachmentUploader.uploadSuccess'));
        },
        fail: function fail() {
          self.set('isUploading', false);
        }
      }));
    }),
    cleanup: Ember.on('willDestroyElement', function () {
      this.fileUploaderEle().fileupload('destroy');
    }),
    actions: {
      removeAttachment: function removeAttachment() {
        this.set('componentValue.value', '');
        this.get('component.documentSigner').addAudit("Removed Attachment from component ".concat(this.get('component.id')));
      }
    }
  });

  _exports.default = _default;
});