define("ember-rs/locales/pt-br/utils/utils", ["exports", "ember-rs/locales/pt-br/utils/hybris-plan-details"], function (_exports, _hybrisPlanDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.utils = void 0;
  var utils = {
    hybrisPlanDetails: _hybrisPlanDetails.hybrisPlanDetails
  };
  _exports.utils = utils;
});