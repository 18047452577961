define("ember-rs/locales/en/templates/onboarding/send-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding1 = void 0;
  var sendOnboarding1 = {
    "greatWork": "Great work! You've sent your first document.",
    "youHave": "You have ",
    "remaining": "remaining in your trial."
  };
  _exports.sendOnboarding1 = sendOnboarding1;
});