define("ember-rs/locales/de/templates/email-unverified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailUnverified = void 0;
  var emailUnverified = {
    "verify": "Ihre E-Mail-Adresse wurde nicht verifiziert. Überprüfen Sie Ihre E-Mail und klicken Sie auf den Verifizierungslink, um Ihr Konto zu bestätigen."
  };
  _exports.emailUnverified = emailUnverified;
});