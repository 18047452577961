define("ember-rs/components/integration-setting-service-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    paramValue: '',
    watchInput: Ember.observer('paramValue', function () {
      this.set("authParams.".concat(this.paramName), this.paramValue);
    })
  });

  _exports.default = _default;
});