define("ember-rs/locales/en/templates/components/component-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbar = void 0;
  var componentToolbar = {
    "useTools": "Use these tools for fields that signers need to complete.",
    "showLess": "Show Less",
    "showMore": "Show More",
    "annotate": "annotate:",
    "annotateTip": "Use these tools to annotate  the document with your own signature, text, or checkmarks.",
    "request": "Request:",
    "dataFields": "Data Fields",
    "sourceFetchFailed": "Failed to retrieve source information.",
    "schemaFetchFailed": "Failed to retrieve source entity schema."
  };
  _exports.componentToolbar = componentToolbar;
});