define("ember-rs/locales/ja/templates/components/contacts-typeahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contactsTypeahead = void 0;
  var contactsTypeahead = {
    "noResults": "結果なし"
  };
  _exports.contactsTypeahead = contactsTypeahead;
});