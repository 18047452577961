define("ember-rs/locales/zh-cn/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding1 = void 0;
  var accountOnboarding1 = {
    "body": "更改<span>您的设置</span>、<span>自定义您的帐户的外观信息</span>、通过其他工具进行<span>集成</span>等。"
  };
  _exports.accountOnboarding1 = accountOnboarding1;
});