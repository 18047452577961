define("ember-rs/components/hover-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hovering: false,
    mouseEnter: function mouseEnter() {
      this.set('hovering', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hovering', false);
    }
  });

  _exports.default = _default;
});