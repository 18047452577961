define("ember-rs/models/reusable-template", ["exports", "@ember-data/model", "ember-rs/models/document-template"], function (_exports, _model, _documentTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentTemplate.default.extend({
    ajax: Ember.inject.service(),
    upload: (0, _model.belongsTo)('upload', {
      inverse: 'reusableTemplate',
      async: false
    }),
    templateSignerLinks: (0, _model.hasMany)('template-signer-link', {
      async: false
    }),
    creatorString: Ember.computed('userId', 'currentUser.model.id', 'senderName', function () {
      return this.userId === Ember.get(this, 'currentUser.model.id') ? 'me' : this.senderName || 'someone else';
    }),
    cloneDocument: function cloneDocument() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.reload().then(function () {
          _this.ajax.post("reusable_templates/".concat(_this.id, "/clone_document.json")).then(function (response) {
            _this.store.pushPayload(response); // Need to reload doc since doc.get('roles') is blank. The records
            // are actually loaded into the store, but the relationships are not
            // correct linked up in ED. TODO: Look into getting rid of the
            // embedded record stuff wrt roles so this reload isn't necessary.


            _this.store.findRecord('document', response.document.id, {
              reload: true
            }).then(resolve);
          }).catch(reject);
        });
      });
    },
    // Just the basics for frontend validation, full validation happens server side
    isReviewStepValid: Ember.computed('name', 'expiresIn', function () {
      if (!Ember.isPresent(this.name)) {
        return false;
      }

      var expiresIn = this.expiresIn;

      if (!Ember.isPresent(expiresIn) || expiresIn <= 0 || expiresIn > 365) {
        return false;
      }

      return true;
    }),
    isRolesStepValid: Ember.computed('roles.[]', function () {
      if (this.get('roles.length') === 0) {
        return false;
      }

      return true;
    })
  });

  _exports.default = _default;
});