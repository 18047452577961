define("ember-rs/routes/reusable-template/review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.controllerFor('reusable-template').set('currentStep', 'review & send');
    },
    actions: {
      publishReusableTemplate: function publishReusableTemplate() {
        var self = this;
        this.controller.set('isSaving', true);
        this.currentModel.set('current', true);
        this.currentModel.set('published', true);
        this.currentModel.set('forceEdit', false);
        this.currentModel.save().then(function () {
          var reusableTemplates = self.store.peekAll('reusableTemplate');
          reusableTemplates.forEach(function (reusableTemplate) {
            if (reusableTemplate.get('templateGuid') === self.currentModel.get('templateGuid') && reusableTemplate.get('id') !== self.currentModel.get('id')) {
              reusableTemplate.set('currentReusableTemplate', false);
            }
          });
          self.controller.set('isSaving', false);
          self.transitionTo('template.details', self.currentModel.id);
        }, function () {
          self.controller.set('isSaving', false);
          self.currentModel.set('current', false);
        });
      }
    }
  });

  _exports.default = _default;
});