define("ember-rs/locales/zh-cn/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrations = void 0;
  var integrations = {
    "successEnabled": "已成功启用 {{{formattedProviderName}}}",
    "successAuthorized": "已成功授权 {{{formattedProviderName}}}",
    "successDisabled": "已成功禁用 {{{formattedProviderName}}}",
    "error": "错误。无法修改 {{{formattedProviderName}}}!",
    "errorAuthorize": "错误。无法授权 {{{formattedProviderName}}} 集成!",
    "errorSetup": "尝试设置 {{{formattedProviderName}}} 集成时出错。请重试。"
  };
  _exports.integrations = integrations;
});