define("ember-rs/components/document-download-options", ["exports", "jquery", "moment", "ember-rs/config/environment"], function (_exports, _jquery, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var duration = _moment.default.duration;

  var _default = Ember.Component.extend({
    redirectToken: Ember.inject.service(),
    classNames: ['download-options'],
    certificateUrl: Ember.computed.alias('document.documentCertificateUrl'),
    signedBaseFile: Ember.computed.alias('document.documentSignedBaseFile'),
    previewUrl: Ember.computed.alias('document.previewUrl'),
    printableOverlayBaseFile: Ember.computed.alias('document.printableOverlayBaseFile'),
    signedPdfUrl: Ember.computed.oneWay('document.signedPdfUrl'),
    downloadedFileUrl: Ember.computed.alias('document.upload.baseFile.downloadedFileUrl'),
    printableOverlayPdfUrl: Ember.computed.alias('printableOverlayBaseFile.processedPdfUrl'),
    hasPrintableOverlay: Ember.computed.notEmpty('printableOverlayBaseFile'),
    processingState: Ember.computed.alias('document.upload.baseFile.processingState'),
    hasProcessingError: Ember.computed.equal('processingState', 'error'),
    mergedDocumentCertificateUrl: Ember.computed.alias('document.mergedDocumentCertificateUrl'),
    pollingTimeout: 1000,
    signedBaseFileSuccessful: Ember.computed('signedBaseFile', 'processingState', function () {
      return !this.hasProcessingError && this.signedBaseFile;
    }),
    hasDownloadableCertificate: Ember.computed('document.workflow', function () {
      return this.get('document.workflow') !== 'self_sign';
    }),
    wasExecutedRecently: Ember.computed('document.documentExecutedAt', 'hasProcessingError', function () {
      var now = new Date();
      var executedAt = this.get('document.documentExecutedAt');

      if (this.hasProcessingError) {
        return false;
      }

      return executedAt ? now - executedAt < duration(20, 'minutes') : false;
    }).readOnly(),
    didInsertElement: function didInsertElement() {
      var processingIncomplete = Ember.isBlank(this.mergedDocumentCertificateUrl);

      if (processingIncomplete && this.wasExecutedRecently) {
        // TODO this needs a backoff and shouldnt probably reload the entire document
        this.reloadDocument(); // Poll for processing complete
      }
    },
    willDestroyElement: function willDestroyElement() {
      var reloader = this.documentReloader;

      if (reloader) {
        Ember.run.cancel(reloader);
      }
    },
    reloadDocument: function reloadDocument() {
      if (this.hasProcessingError) {
        return;
      }

      var reloader = Ember.run.later(this, function () {
        var _this = this;

        //signedPdfUrl will be nil until we reload ember data on line 87
        //to handle self-sign flows signedPdfUrl is checked instead of mergedDocumentCertificateUrl
        var processingIncomplete = Ember.isBlank(this.signedPdfUrl);

        if (processingIncomplete) {
          this.set("pollingTimeout", this.pollingTimeout * 1.25); // backoff
          // Manually fetching data to prevent from loading into ember data and causing the document viewer
          // to reset and flash every time it polls. Once the status has changed, we reload the entire document

          var ajaxHash = {
            type: 'GET',
            url: "".concat(_environment.default.api.endpoint, "/documents/").concat(this.get('document.id'))
          };
          var redirectToken = this.redirectToken;

          if (redirectToken.hasValue()) {
            ajaxHash['beforeSend'] = function (xhr) {
              xhr.setRequestHeader('Rightsignature-Redirect-Token', redirectToken.getValue());
            };
          }

          _jquery.default.ajax(ajaxHash).done(function (data) {
            var signedBaseFile = data.document_signed_base_files[0];
            var mergedDocumentCertificateUrl = data.document.merged_document_certificate_url;

            if (Ember.isPresent(signedBaseFile) && (_this.isProcessingComplete(signedBaseFile.processing_state, mergedDocumentCertificateUrl) || signedBaseFile.processing_state === "error")) {
              _this.document.reload().then(function () {
                _this.reloadDocument();
              });
            } else {
              _this.reloadDocument();
            }
          });
        }
      }, this.pollingTimeout);
      this.set('documentReloader', reloader);
    },
    isProcessingComplete: function isProcessingComplete(processingState, mergedPdfUrl) {
      return this.hasDownloadableCertificate ? processingState === "ready" && mergedPdfUrl : processingState === "ready";
    }
  });

  _exports.default = _default;
});