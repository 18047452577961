define("ember-rs/locales/it/templates/mobile-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mobileNavigation = void 0;
  var mobileNavigation = {
    "documents": "Documenti",
    "templates": "Modelli",
    "settings": "Impostazioni",
    "integrations": "Integrazioni",
    "users": "Utenti",
    "reports": "Report",
    "help": "Guida",
    "logout": "Scollega"
  };
  _exports.mobileNavigation = mobileNavigation;
});