define("ember-rs/locales/fr/templates/onboarding/send-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding2 = void 0;
  var sendOnboarding2 = {
    "status": "C'est ici que vous pouvez voir <span>l'état</span> du document"
  };
  _exports.sendOnboarding2 = sendOnboarding2;
});