define("ember-rs/locales/nl/templates/document/search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header = void 0;
  var search_header = {
    "from": "Van: ",
    "state": "Status: ",
    "search_docs": "Documenten zoeken...",
    "all": " Alle",
    "pending": " In behandeling",
    "declined": " Afgewezen",
    "executed": " Voltooid",
    "expired": " Verlopen",
    "voided": " Nietig verklaard",
    "admin_view": "Beheerweergave",
    "tool_tip": "Alle documenten weergeven die vanaf dit account zijn verzonden.",
    "filter": "Filteren"
  };
  _exports.search_header = search_header;
});