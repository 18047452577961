define("ember-rs/locales/en/templates/onboarding/post-sign-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding2 = void 0;
  var postSignOnboarding2 = {
    "viewDoc": "Here's where you can see the <span>status</span> of the document",
    "downloadDoc": "Here's where you can <span>download</span> the document."
  };
  _exports.postSignOnboarding2 = postSignOnboarding2;
});