define("ember-rs/locales/it/templates/document/prepare-signers-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_desktop = void 0;
  var prepare_signers_desktop = {
    "assign_roles": "Assegna ruoli per:",
    "who_sign": "Chi deve firmare ",
    "sign_order": "Imposta ordine dei firmatari",
    "add_another": "Aggiungi un altro firmatario",
    "add_yourself": "Aggiungi te stesso",
    "contact_integtration": "Integrazione contatti",
    "select_integration": "Seleziona un'integrazione",
    "who_sign_package": "Chi deve firmare il pacchetto di documenti?"
  };
  _exports.prepare_signers_desktop = prepare_signers_desktop;
});