define("ember-rs/locales/de/templates/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faqModal = void 0;
  var faqModal = {
    "docId": "Dokument-ID:",
    "contactSender": "Wenden Sie sich bei Fragen an den Absender: {{{userName}}} unter <a href='mailto: {{{userEmail}}}'>{{{userEmail}}}</a>",
    "needHelp": "Benötigen Sie Hilfe bei der Verwendung von RightSignature&trade;? Werfen Sie einen Blick auf die häufig gestellten Fragen und Antworten:",
    "q1": "Ich habe eine Frage zum Inhalt dieses Dokuments:",
    "rsNote": "RightSignature&trade; ist der Onlineservice eines Drittanbieters zum Signieren von Dokumenten. Wir unterstützen das Senden und Signieren von Dokumenten mit legalen, sicheren, elektronischen Signaturen, jedoch sind wir in keiner Weise mit dem Absender oder dem Dokument verbunden.",
    "q2": "Wie fülle ich dieses Dokument aus?",
    "a2": "Der rote Pfeil links neben dem Dokument zeigt auf das nächste erforderliche Feld. Sobald alle erforderlichen Felder ausgefüllt sind, wird die Schaltfläche 'Senden' zum Klicken verfügbar.",
    "q3": "Ist das Dokument rechtsverbindlich?",
    "a3": "Ja. Dokumente, die online mit elektronischen Signaturen unterzeichnet werden, sind rechtsgültig und haben die gleiche rechtliche Stellung wie manuell unterzeichnete Papierdokumente. Es ist sehr wahrscheinlich, dass Sie bereits online Verträge abgeschlossen haben. Wenn Sie zum Beispiel im Internet auf die Schaltfläche 'Ich stimme zu' klicken, schließen Sie normalerweise einen sogenannten Durchklickvertrag ab. Wenn Sie Waren online kaufen, schließen Sie ebenfalls einen rechtsverbindlichen Vertrag ab.",
    "a3part2": "Die Technologie von RightSignature&trade; entspricht in vollem Umfang den US-amerikanischen Gesetzen Electronic Signatures in Global and National Commerce Act (ESIGN) aus dem Jahr 2000 und Uniform Electronic Transactions Act (UETA) sowie der europäischen Richtlinie (EC/1999/93). Diese sollen die schnelle Einführung elektronischer Signaturen fördern und die Verwendung veralteter Papiermethoden verringern. Jedes dieser Gesetze verstärkt die Gültigkeit elektronischer Vereinbarungen. Beispielsweise darf nach ESIGN einem Vertrag die Rechtswirkung, Gültigkeit oder Vollstreckbarkeit nicht allein deshalb abgesprochen werden, weil bei seinem Zustandekommen eine elektronische Signatur oder ein elektronischer Datensatz verwendet wurde.",
    "q4": "Was ist RightSignature™?",
    "a4": "RightSignature&trade; ermöglicht es Ihnen, rechtsverbindliche Dokumente online zu erstellen und zu signieren, und zwar schneller und sicherer als mit Papierdokumenten. RightSignature&trade; ist ein webbasierter Software-as-a-Service, der die sichere Dateninfrastruktur von Amazon Web Services nutzt.",
    "q5": "Was macht RightSignature™?",
    "a5": "RightSignature&trade; ermöglicht es Ihnen, rechtsverbindliche Dokumente online zu erstellen und zu signieren, und zwar schneller und sicherer als mit Papierdokumenten. RightSignature&trade; ist ein webbasierter Software-as-a-Service, der die sichere Dateninfrastruktur von Amazon Web Services nutzt.",
    "q6": "Wie ermöglicht RightSignature™ die Onlinesignatur von Dokumenten?",
    "a6": "RightSignature&trade; ermöglicht es ganz einfach, Signaturen von mehreren Parteien online einzuholen. Laden Sie einfach Ihr Dokument hoch und geben Sie die Empfängerinformationen ein. RightSignature&trade; sendet E-Mail-Empfängern einen eindeutigen Link zu einer Seite, auf der sie das Dokument überprüfen, erforderliche Textfelder ausfüllen und das Dokument signieren können. Empfänger können mit einer Maus online in Sekunden unterzeichnen.",
    "a6part2": "Heutzutage signieren die meisten Unternehmen Dokumente per Fax, senden Übernachtbriefe oder scannen und mailen Signaturseiten. Mit RightSignature&trade; gehören diese Mühen, Zeitverzögerungen und Kosten der Vergangenheit an. Die Unterzeichnung eines rechtsverbindlichen Dokuments und die sichere Speicherung auf einem sicheren Server können innerhalb weniger Minuten mit wenigen Mausklicks durchgeführt werden.",
    "q7": "Ist es nicht sicherer, ein Papierdokument zu unterzeichnen?",
    "a7": "Elektronische Verträge sind wesentlich sicherer als Papierverträge und haben die gleiche rechtliche Stellung. Während Dokumente, die per Fax und Post versandt werden, im Büro Risiken ausgesetzt sind, werden elektronische Dokumente vollständig vertraulich behandelt und können nur von bestimmten Empfängern angezeigt werden. RightSignature&trade;-Dokumente werden auf sicheren Servern mit redundanten Kopien gespeichert und können im Gegensatz zu Papierdokumenten nicht verlegt, verloren oder zerstört werden. Darüber hinaus ermöglichen E-Mail-Empfangsbestätigungen, IP-Datensätze und biometrische Signaturdaten die Authentifizierung jeder unterzeichnenden Partei.",
    "q8": "Für wen ist dieser Service gedacht?",
    "a8": "RightSignature&trade; ist ideal für kleine und mittlere Unternehmen, Freiberufler, Designer, Entwickler, Rechtsanwälte, Auftragnehmer, Schulen, gemeinnützige Organisationen und viele mehr. Wenn Ihre Arbeit signierte Dokumente erfordert, benötigen Sie RightSignature&trade;.",
    "q9": "Welche Arten von Dokumenten kann ich senden?",
    "a9": "Dokumente, die regelmäßig mit RightSignature&trade; gesendet werden; umfassen Verträge, Vertraulichkeitsvereinbarungen, Antragsannahmen, Änderungsaufträge, Korrekturfahnen für Anzeigen, Auftragsschreiben, Spesenabrechnungen, Bestellungen, Mietverträge, unabhängige Auftragnehmerverträge, neue Kundenbewerbungen, Stellenbewerbungen und vieles mehr. Konformitätsformulare wie W-9, W-4 und I-9 sind ideal für RightSignature&trade;. Müssen alle Teammitglieder eine Richtlinienänderung unterschreiben? RightSignature&trade; macht es einfach. Unternehmen senden alles mit RightSignature&trade;, von abzuzeichnenden Zeitnachweisformularen bis hin zu Verträgen im Wert von mehreren Millionen Euro.",
    "q10": "Welche Browser werden unterstützt?",
    "a10": "RightSignature&trade; bietet in den folgenden Browsern die beste Benutzererfahrung. Gehen Sie zu folgender Site, um Ihren Browser auf die neueste Version zu aktualisieren:",
    "a10part2": "Mozilla Firefox 3+, Microsoft Internet Explorer 9+, Apple Safari 3+, Google Chrome, Opera 9+",
    "q11": "Was soll ich tun, wenn ich mich nicht anmelden kann?",
    "a11": "Überprüfen Sie sorgfältig Ihre E-Mail-Adresse, um sicherzugehen, dass Sie sie nicht falsch eingegeben haben. Geben Sie außerdem Ihr Kennwort langsam und sorgfältig ein. Wenn dies nicht funktioniert, können Sie Ihr Kennwort zurücksetzen, indem Sie auf 'Ich habe mein Kennwort vergessen' klicken. Wenn Sie den Prozess zum Zurücksetzen des Kennworts befolgen und Sie sich immer noch nicht anmelden können, wenden Sie sich bitte an uns.",
    "q12": "Müssen Empfänger ein RightSignature™-Konto haben?",
    "a12": "Nein. Empfänger benötigen kein Konto, um ein Dokument von Ihnen zu erhalten. Die Anzahl der Empfänger, an die Sie Dokumente senden können, ist nicht begrenzt. Wenn Sie ein Dokument senden, erhält der Empfänger eine E-Mail mit einem eindeutigen Link. Wenn der Empfänger auf den Link klickt, kann er das Dokument im Browser anzeigen und signieren, ohne Downloads oder Plug-Ins. RightSignature&trade; ist so einfach und intuitiv, dass neue Benutzer ohne besondere Anleitungen in Sekunden unterzeichnen können.",
    "q13": "Was macht RightSignature™ besser als Konkurrenzprodukte?",
    "a13": "RightSignature&trade; ist bekannt für seine elegante, intuitive Benutzererfahrung und Erfassung von echten, handschriftlichen Signaturen. Mit der Infrastruktur von Amazon Web Services bieten wir Sicherheit und Skalierbarkeit von Weltklasse. Durch kontinuierliche Entwicklung und Verbesserung ist RightSignature&trade; Spitzentechnik. Für Freiberufler und kleine Unternehmen bietet RightSignature&trade; die einfachste und effektivste Onlinesoftware zur Dokumentunterzeichnung.",
    "q14": "Wie unterstützt RightSignature™ eine papierfreie Arbeitsweise?",
    "a14": "Motiviert durch Umweltbelange und Kosteneinsparungen verfolgen viele Büros eine papierfreie Arbeitsweise. RightSignature&trade; ist eine Schlüsselkomponente bei der Reduzierung des Papierverbrauchs und bietet eine sichere und effektive Methode, Dokumente online ohne gedruckte Kopien zu signieren.",
    "q15": "Was ist eine elektronische Signatur?",
    "a15": "Das Unterschriftenfeld in RightSignature&trade; ermöglicht es Ihnen, mit Ihrer Maus ein Dokument zu signieren, indem eine gültige elektronische Signatur gemäß den US- und EU-Rechtsvorschriften erstellt wird. Jedes Zeichen, das Sie im Unterschriftenfeld erstellen, ist gültig, auch wenn es Ihrer handschriftlichen Unterschrift nicht ähnelt. Die Gerichte verwenden eine weite Auslegung des Begriffs 'Unterschrift' und eine Unterschrift muss keine 'Unterschrift' im herkömmlichen Sinn des Wortes sein. Zum Beispiel wurde ein Stempel als eine Unterschrift anerkannt, ebenso wie ein Zeichen, das von einem Analphabeten gemacht wurde. Beide Beispiele veranschaulichen das Konzept, dass jedes von einer Vertragspartei vorsätzlich erstellte Zeichen als eine Unterschrift anerkannt werden kann. ",
    "a15part2": "<p>Dokumente, die online mit elektronischen Signaturen unterschrieben werden, sind rechtsgültig und haben die gleiche rechtliche Stellung wie handschriftlich signierte Papierdokumente. Die Technologie von RightSignature&trade; entspricht in vollem Umfang den US-amerikanischen Gesetzen Electronic Signatures in Global and National Commerce Act (ESIGN) aus dem Jahr 2000 und Uniform Electronic Transactions Act (UETA) sowie der europäischen Richtlinie 1999/93/EG. Diese sollen die schnelle Einführung elektronischer Signaturen fördern und die Verwendung veralteter Papiermethoden verringern.</p><p>Die RightSignature&trade;-Technologie erfüllt die US-Definition einer 'elektronischen Signatur': ein elektronischer Ton, ein elektronischer Prozess oder ein elektronisches Symbol, die einem Vertrag oder einem anderen Dokument zugeordnet oder logisch mit ihm verknüpft sind und von einer Person mit der Absicht ausgeführt oder vorgenommen werden, das Dokument zu unterzeichnen.</p><p>Die RightSignature&trade;-Technologie erfüllt auch die strengeren EU-Anforderungen einer 'fortgeschrittenen elektronischen Signatur':</p>",
    "a15part3": "  Sie ist eindeutig mit dem Unterzeichner verbunden; sie ist in der Lage, den Unterzeichner zu identifizieren; sie wird mit Mitteln geschaffen, die der Unterzeichner unter seiner alleinigen Kontrolle halten kann, und sie ist mit den Daten verknüpft, auf die sie sich bezieht, sodass jede nachfolgende Änderung der Daten feststellbar ist. Vertiefende Lektüre: 'Electronic Signatures and Online Contracts' (ein Artikel auf Nolo.com), Richtlinie 1999/93/EG des Europäischen Parlaments, U.S. Signatures in Global and National Commerce Act (ESIGN), Uniform Electronic Transactions Act (UETA)",
    "a15part4": "Weitere Informationen zur Rechtsgültigkeit von Dokumenten, die mit RightSignature&trade; signiert wurden, finden Sie auf unserer Website unter 'Legality'.",
    "q16": "Woher weiß ich, dass die andere Vertragspartei diejenige ist, die sie zu sein vorgibt?",
    "a16": "Die Überprüfung der Identität eines Individuums ist ein wichtiges Element jeder Unterschrift, ob elektronisch oder schriftlich. Eine elektronische Signatur muss in der Lage sein, einen Unterzeichner zu identifizieren und muss eindeutig mit dem Unterzeichner verbunden sein. RightSignature&trade; verwendet einen multivariaten Authentifizierungsprozess, der die Überprüfung der E-Mail-Adresse, IP-Adressverfolgung, einen biometrischen Signaturalgorithmus und andere Informationen umfasst. Die Authentifizierung bei RightSignature&trade; ist deutlich stärker als auf Papierdokumenten, wo Parteien oft durch einen Assistenten, Ehepartner oder eine andere Person vertreten werden.",
    "q17": "Woher wissen Sie den Ort des Unterzeichners?",
    "a17": "Jeder Computer im Internet wird durch eine IP-Adresse (Internetprotokolladresse) identifiziert. Zum Beispiel ist Ihre IP-Adresse 99.102.245.244. Von dieser Adresse aus können wir Ihren Computer zu einem physischen Ort verfolgen. Obwohl dies nicht 100%ig genau ist, ist es ein zusätzliches Authentifizierungstool für Parteien, die RightSignature&trade; zum Unterzeichnen ihrer Dokumente verwenden.",
    "q18": "Kann ein signiertes Dokument nach dem Signieren von einer der Parteien manipuliert werden?",
    "a18": "Wenn ein Dokument hochgeladen und zur Signatur gesendet wurde, wird das Dokument gesperrt und auf einem sicheren Server mit redundanten Kopien gespeichert. Nach dem Signieren wird das endgültige Dokument mit einem sicheren Hashalgorithmus gebunden, um sicherzustellen, dass keine Partei Änderungen an einem signierten Dokument vornehmen kann. Die Neutralität von RightSignature&trade; bietet weiteren rechtlichen Schutz gegen alle Versuche, eine signierte Vereinbarung ungültig zu machen. Weitere Informationen zu unserer sicheren Dateninfrastruktur finden Sie auf unserer Website unter 'Security'.",
    "q19": "Werden die digitalen Signaturen von einer dritten Instanz zertifiziert?",
    "a19": "RightSignature&trade; bietet als Signaturdienst, der den Arbeitsablauf zwischen den Unterschriftsberechtigten koordiniert, eine erhebliche Steigerung der Kontroll- und Überprüfungsfunktionen gegenüber Fax- und E-Mail-Methoden. Ein Vorteil der Verwendung von RightSignature&trade; ist, dass unser Service ein neutraler Dritter ist, ähnlich einem Treuhänder. Sobald ein Dokument signiert wurde, bindet RightSignature&trade; das Dokument mit einem sicheren Hashalgorithmus und speichert redundante Kopien mithilfe der Amazon Web Services-Infrastruktur. Sollte ein in RightSignature&trade; signierter Vertrag vor Gericht angefochten werden, wäre es für eine Partei sehr schwierig oder sogar unmöglich zu behaupten, dass das Dokument manipuliert worden sei. Der Datensatz des RightSignature&trade;-Vorgangs und die nach der Signatur hinzugefügte Sperre bieten die durch eine dritte Partei gebotene Verifizierung für robuste, vertretbare Verträge.",
    "q20": "Wie kann ich sicher sein, dass ich dasselbe Dokument wie die andere Partei unterschreibe?",
    "a20": "Jedes zu RightSignature&trade; hochgeladene Dokument ist an eine eindeutige, aus einer langen Zeichenfolge bestehende Referenznummer gebunden. Diese besteht aus Ziffern, Groß- und Kleinbuchstaben und Satzzeichen. Diese Referenznummer ist in jeder Kommunikation und auf jedem Bildschirm enthalten, die/der das Dokument enthält, und stellt sicher, dass alle Parteien immer dasselbe Dokument sehen.",
    "a20part2": "Es gibt keine Funktion, die das Ändern der Datei ermöglicht, nachdem die Datei hochgeladen und zur Signatur gesendet wurde. Solange ein Dokument noch nicht von einem Empfänger signiert worden ist, kann der Absender es löschen und ein neues Dokument hochladen und senden, das dann eine neue Referenznummer erhält.",
    "q21": "Kann ich RightSignature™ mit meinem Logo versehen?",
    "a21": "Mit RightSignature&trade;..."
  };
  _exports.faqModal = faqModal;
});