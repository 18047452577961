define("ember-rs/models/component", ["exports", "@ember-data/model", "ember-rs/helpers/truthy", "lodash"], function (_exports, _model, _truthy, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    ////////////////////////  ATTRIBUTES  ////////////////////////
    type: (0, _model.attr)('type', {
      defaultValue: 'textComponent'
    }),
    x: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    y: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    width: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    height: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    page: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    name: (0, _model.attr)('string'),
    assignedTo: (0, _model.attr)('string'),
    isRequired: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    groupRequirement: (0, _model.attr)('string', {
      defaultValue: 'only_one'
    }),
    groupName: (0, _model.attr)('string'),
    groupId: (0, _model.attr)('number'),
    fontSize: (0, _model.attr)('number', {
      defaultValue: 14
    }),
    multiline: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    transparent: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    value: (0, _model.attr)('string'),
    textAlign: (0, _model.attr)('string', {
      defaultValue: 'left'
    }),
    selectOptions: (0, _model.attr)(),
    helpText: (0, _model.attr)('string'),
    chargeAfterExecuted: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    paymentAmount: (0, _model.attr)('number'),
    isMergeField: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    isSourceMapped: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    source: (0, _model.attr)('object'),
    isDateSigned: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    dateFormat: (0, _model.attr)('string', {
      defaultValue: function defaultValue() {
        return localStorage.getItem('defaultDateFormat') ? localStorage.getItem('defaultDateFormat') : 'MM/DD/YYYY';
      }
    }),
    ////////////////////////  RELATIONSHIPS  ////////////////////////
    documentTemplate: (0, _model.belongsTo)('document-template', {
      polymorphic: true,
      inverse: 'components',
      async: false
    }),
    signature: (0, _model.belongsTo)('signature', {
      async: true
    }),
    // This is virtual and not meant to be persisted (should persist null, or just not be saved on the backend)
    // This is just meant to provide an association from component to componentValues (via response) while
    // on the signing page
    response: (0, _model.belongsTo)('response', {
      async: false
    }),
    // role: belongsTo('role'),
    mergefieldValue: Ember.computed('documentTemplate', function () {
      if (this.get('documentTemplate.type') === 'ReusableTemplate') {
        return false;
      }

      var existingMergefieldValue;

      if (this.get('documentTemplate.mergefieldValues')) {
        existingMergefieldValue = this.get('documentTemplate.mergefieldValues').findBy('componentId', this.id);
      }

      if (Ember.isPresent(existingMergefieldValue)) {
        return existingMergefieldValue;
      } else if (this.get('documentTemplate.type') === 'reusableTemplate') {
        return undefined;
      } else {
        return this.store.createRecord('mergefieldValue', {
          value: this.value,
          // default value
          componentId: this.id,
          document: this.documentTemplate
        });
      }
    }),
    componentValue: Ember.computed('response', 'documentTemplate.componentValues.[]', function () {
      if (this.get('documentTemplate.type') === 'ReusableTemplate' || Ember.isBlank(this.response) && Ember.isBlank(this.get('documentTemplate.componentValues'))) {
        return undefined;
      }

      var existingComponentValue;

      if (this.response) {
        // get it from the response
        existingComponentValue = this.get('response.componentValues').findBy('componentId', this.id);
      }

      if (!existingComponentValue && this.get('documentTemplate.componentValues')) {
        // get it from the document
        existingComponentValue = this.get('documentTemplate.componentValues').findBy('componentId', this.id);
      }

      if (Ember.isPresent(existingComponentValue)) {
        return existingComponentValue;
      } else {
        // create a new one
        return this.store.createRecord('componentValue', {
          value: this.value,
          componentId: this.id,
          response: this.response,
          document: this.documentTemplate
        });
      }
    }),
    // If document, find the signer associated with the component
    documentSigner: Ember.computed('documentTemplate.signers.@each.roleName', 'assignedTo', function () {
      if (this.get('documentTemplate.type') !== 'document') {
        return undefined;
      }

      return this.get('documentTemplate.signers').findBy('roleName', this.assignedTo);
    }),
    ////////////////////////  PROPERTIES  ////////////////////////
    isText: Ember.computed('type', function () {
      return this.type === 'textComponent' || this.type === 'textAnnotationComponent';
    }),
    isSignature: Ember.computed('type', function () {
      return this.type === 'signatureAnnotationComponent' || this.type === 'signatureComponent';
    }),
    isCheckBox: Ember.computed('type', function () {
      return this.type === 'checkComponent' || this.type === 'checkGroupComponent';
    }),
    isAnnotation: Ember.computed('type', function () {
      return ['signatureAnnotationComponent', 'textAnnotationComponent', 'checkmarkAnnotationComponent'].includes(this.type);
    }),
    isTextAnnotation: Ember.computed.equal('type', 'textAnnotationComponent'),
    isCheckmarkAnnotation: Ember.computed.equal('type', 'checkmarkAnnotationComponent'),
    isTextField: Ember.computed.equal('type', 'textComponent'),
    isDateField: Ember.computed.equal('type', 'dateComponent'),
    isSignatureField: Ember.computed.equal('type', 'signatureComponent'),
    isInitialsField: Ember.computed.equal('type', 'initialsComponent'),
    isSelectField: Ember.computed.equal('type', 'selectComponent'),
    isAttachment: Ember.computed.equal('type', 'attachmentComponent'),
    isPayment: Ember.computed.equal('type', 'paymentComponent'),
    isGrouped: Ember.computed.equal('type', 'checkGroupComponent'),
    isSelect: Ember.computed.equal('type', 'selectComponent'),
    isInvalid: Ember.computed.not('isValid'),
    isInvalidMergeField: Ember.computed.not('isValidMergeField'),
    isValidMergeField: Ember.computed('name', 'isMergeField', function () {
      if (Ember.isEqual(this.isMergeField, true)) {
        return Ember.isPresent(this.name);
      } else {
        return true;
      }
    }),
    // This exists so other validations can hook into the valid state of a component. See: app/models/select-components.js
    isValid: Ember.computed('isValidMergeField', function () {
      return this.isValidMergeField;
    }),
    renderFlattenedAsText: Ember.computed('type', 'hasComponentValue', function () {
      return ['textAnnotationComponent', 'textComponent', 'selectComponent', 'dateComponent', 'initialsComponent'].includes(this.type) && this.hasComponentValue;
    }),
    renderFlattenedAsCheck: Ember.computed('type', 'hasComponentValue', function () {
      return ['checkmarkAnnotationComponent', 'checkComponent', 'checkGroupComponent'].includes(this.type) && this.hasComponentValue;
    }),
    renderFlattenedAsSignature: Ember.computed('type', 'hasComponentValue', function () {
      return ['signatureComponent', 'signatureAnnotationComponent'].includes(this.type) && this.hasComponentValue;
    }),
    textAlignClass: Ember.computed('textAlign', function () {
      if (_lodash.default.includes(['dateComponent', 'initialsComponent', 'textComponent', 'textAnnotationComponent'], this.type)) {
        if (_lodash.default.includes(['left', 'center', 'right'], this.textAlign)) {
          return "text-align-".concat(this.textAlign);
        } else {
          return "text-align-left";
        }
      } else {
        return "text-align-center";
      }
    }),
    // Has a value set and not set to false or null on either mergefieldValue or componentValue
    hasComponentValue: Ember.computed('isMergeField', 'mergefieldValue.value', 'componentValue.value', 'isCheckBox', 'isAnnotation', function () {
      if (this.isAnnotation) {
        // always render annotations
        return true;
      }

      var valueObject = this.isMergeField ? this.mergefieldValue : this.componentValue;

      if (valueObject) {
        return this.isCheckBox ? (0, _truthy.truthy)(valueObject.get('value')) : Ember.isPresent(valueObject.get('value'));
      }

      return undefined;
    }),
    setValueFromText: function setValueFromText(text) {
      var type = this.type;
      var componentValue = this.componentValue;

      if (componentValue && !_lodash.default.includes(['checkGroupComponent', 'signatureComponent', 'attachmentComponent', 'paymentComponent'], type)) {
        if (type === 'checkComponent') {
          text = (0, _truthy.truthy)(text);
        }

        componentValue.set('value', text);
      }
    }
  });

  _exports.default = _default;
});