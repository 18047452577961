define("ember-rs/locales/fr/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceCompleteDocumentModal = void 0;
  var forceCompleteDocumentModal = {
    "header": "Forcer exécution du document",
    "forceCompleted": "Cela supprimera tous les champs assignés aux signataires qui n'ont pas encore signé et terminera l'exécution du document (état final). Cette action ne peut pas être annulée.",
    "briefMoment": "Le nouvel état sera reflété sur le tableau de bord dans quelques instants.",
    "forceComplete": "Forcer exécution",
    "cancel": "Annuler",
    "success": "Le document a été complété de force avec succès.",
    "failed": "Impossible de compléter le document de force"
  };
  _exports.forceCompleteDocumentModal = forceCompleteDocumentModal;
});