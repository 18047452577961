define("ember-rs/templates/components/main-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ht1R7Uk0",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"account\",\"isWorkspaceEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"left\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/citrix-logo-black.svg\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"right\"],[12],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"footer-link\"],[12],[2,\"\\n      \"],[10,\"a\"],[15,6,[31,[[34,2],\"/privacy-policy\"]]],[12],[1,[30,[36,3],[\"components.mainFooter.privacy\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"footer-link\"],[12],[2,\"\\n      \"],[8,\"terms-of-use-link\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"copyright\"],[12],[2,\"\\n    © 1999-\"],[1,[34,4]],[2,\" \"],[1,[30,[36,3],[\"components.mainFooter.copyright\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentUser\",\"if\",\"csgHomePage\",\"t\",\"currentYear\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/main-footer.hbs"
    }
  });

  _exports.default = _default;
});