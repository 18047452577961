define("ember-rs/locales/fr/controllers/account/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUser = void 0;
  var newUser = {
    "success": "L'utilisateur {{{searchValue}}} a été ajouté avec succès.",
    "error": "L'utilisateur {{{searchValue}}} n'a pas pu être ajouté."
  };
  _exports.newUser = newUser;
});