define("ember-rs/locales/zh-cn/templates/unauthorized/redirect-to-rs3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectToRs3 = void 0;
  var redirectToRs3 = {
    "wrongVersion": "RightSignature 版本不正确",
    "classic": "您已尝试登录 RightSignature ShareFile 版。但是，您的帐户向您授予的是对 Classic RightSignature 的访问权限。",
    "go": "转至 RightSignature"
  };
  _exports.redirectToRs3 = redirectToRs3;
});