define("ember-rs/locales/en/templates/components/overlay-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.overlayComponent = void 0;
  var overlayComponent = {
    "mergeField": "Merge Field:",
    "signature": "Signature",
    "date": "Date",
    "dateAutoFill": "Date Signed",
    "initial": "Initial",
    "select": "Select",
    "text": "Text",
    "attachment": "Attachment",
    "authorize": "Authorize Payment",
    "collect": "Collect Credit Card"
  };
  _exports.overlayComponent = overlayComponent;
});