define("ember-rs/locales/es/templates/onboarding/signer-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding2 = void 0;
  var signerOnboarding2 = {
    "submit": "Vea el progreso de su documento. Aparece el botón <span>Enviar</span> cuando se han completado todos los campos."
  };
  _exports.signerOnboarding2 = signerOnboarding2;
});