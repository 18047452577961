define("ember-rs/locales/es/templates/signer/footer-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerDesktop = void 0;
  var footerDesktop = {
    "from": "De",
    "submitSig": "Enviar firma",
    "submit": "Enviar",
    "clickSubmit": "Al hacer clic en 'Enviar' usted acepta nuestro",
    "privacyPolicy": "Directiva de privacidad",
    "consent": "Consentimiento E-Sign",
    "contents": "y el contenido de este documento.",
    "left": "a la izquierda",
    "save": "Guardar",
    "help": "¿Necesita ayuda?",
    "saveProgress": "Guardar progreso",
    "savingChanges": "Guardando cambios",
    "field": "campo",
    "fields": "campos"
  };
  _exports.footerDesktop = footerDesktop;
});