define("ember-rs/locales/it/controllers/document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "notAllowed": "Non si è autorizzati a compiere questa operazione.",
    "voided": "Il documento è stato annullato correttamente.",
    "voidingFailed": "Impossibile annullare il documento.",
    "docExtended": "Il documento è stato esteso correttamente.",
    "tagUpdated": "Aggiornamento dei contrassegni completato",
    "tagUpdateFailed": "Impossibile aggiornare i tag: {{{tags}}} ",
    "viewerListUpdated": "Aggiornamento dell'elenco dei visualizzatori completato",
    "viewerUpdateFailed": "Impossibile aggiornare l'elenco dei visualizzatori: {{{viewers}}}"
  };
  _exports.details = details;
});