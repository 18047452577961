define("ember-rs/locales/ja/templates/onboarding/send-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding1 = void 0;
  var sendOnboarding1 = {
    "greatWork": "完了です。最初のドキュメントが送信されました。",
    "youHave": "まだ ",
    "remaining": "トライアルを使用できます。"
  };
  _exports.sendOnboarding1 = sendOnboarding1;
});