define("ember-rs/locales/fr/templates/unauthorized/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unauthorizedFile = void 0;
  var unauthorizedFile = {
    "unauthorized": "Non autorisé",
    "youAreNot": "Vous n’êtes pas autorisé(e) à consulter cette page."
  };
  _exports.unauthorizedFile = unauthorizedFile;
});