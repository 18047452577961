define("ember-rs/locales/es/mixins/tags-collection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tagsCollection = void 0;
  var tagsCollection = {
    "supplyTagName": "Indique un nombre de etiqueta"
  };
  _exports.tagsCollection = tagsCollection;
});