define("ember-rs/routes/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    ////////////////////////  PARAMS  ////////////////////////
    queryParams: {
      signerPaymentId: {
        refreshModel: false
      }
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        signerPaymentId: null,
        detailsSidebarWidth: 420,
        showDeleteModal: false,
        showDesktopSidebar: true
      });

      if (controller.get("session.data.showDetailsSidebar") || Ember.isNone(controller.get("session.data.showDetailsSidebar"))) {
        this.set('documentViewerData.sidebarWidth', controller.detailsSidebarWidth);
        controller.set('showSidebar', true);
      } else {
        // This gets set to 0 in routes/sign and persists after signing
        this.set('documentViewerData.sidebarWidth', 0);
      }

      this.set('layoutSizeData.tmHeaderHeight', 60);
      this.set('documentViewerData.pageHeight', model.get('imageHeight'));
      this.set('documentViewerData.pageWidth', model.get('imageWidth'));

      this._super.apply(this, arguments);
    },
    titleToken: function titleToken(model) {
      var documentName = model.get('name');
      return "Archvied Document | ".concat(documentName);
    }
  });

  _exports.default = _default;
});