define("ember-rs/mixins/component-dragging", ["exports", "ember-rs/mixins/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_component.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    documentViewerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    overlayerData: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    init: function init() {
      this._super.apply(this, arguments);

      this.dragPosition = {
        start: {
          x: 0,
          y: 0
        },
        // scaled position for use with the bounding box
        previous: {
          x: 0,
          y: 0
        },
        // unscaled original for calculating actual position
        original: {
          x: 0,
          y: 0
        }
      };
    },
    ////////////////////////  HOOKS  ////////////////////////
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$().draggable({
        start: Ember.run.bind(this, this.onDragStart),
        stop: Ember.run.bind(this, this.onDragStop),
        drag: Ember.run.bind(this, this.onDragging)
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$().draggable('destroy');
    },
    ////////////////////////  METHODS  ////////////////////////
    onDragStart: function onDragStart(evt, ui) {
      Ember.setProperties(this, {
        'overlayerData.isDragging': true,
        'dragPosition.previous.x': ui.originalPosition.left / this.get('documentViewerData.viewerScale'),
        'dragPosition.previous.y': ui.originalPosition.top / this.get('documentViewerData.viewerScale'),
        'dragPosition.original.x': ui.originalPosition.left,
        'dragPosition.original.y': ui.originalPosition.top,
        'dragPosition.start.x': evt.clientX,
        'dragPosition.start.y': evt.clientY
      });
    },
    onDragStop: function onDragStop() {
      Ember.set(this, 'overlayerData.isDragging', false);
    },
    onDragging: function onDragging(evt, ui) {
      this.updateUiPosition(evt, ui);
    },
    updateUiPosition: function updateUiPosition(evt, ui) {
      var dimensions = this.dimensions;
      var x = (evt.clientX - Ember.get(this, 'dragPosition.start.x') + Ember.get(this, 'dragPosition.original.x')) / Ember.get(this, 'documentViewerData.viewerScale');

      if (x < 0) {
        x = 0;
      }

      if (x + dimensions.width > Ember.get(this, 'documentViewerData.pageWidth')) {
        x = Ember.get(this, 'documentViewerData.pageWidth') - dimensions.width;
      }

      var y = (evt.clientY - Ember.get(this, 'dragPosition.start.y') + Ember.get(this, 'dragPosition.original.y')) / Ember.get(this, 'documentViewerData.viewerScale');

      if (y < 0) {
        y = 0;
      }

      if (y + dimensions.height > Ember.get(this, 'documentViewerData.pageHeight')) {
        y = Ember.get(this, 'documentViewerData.pageHeight') - dimensions.height;
      }

      ui.position = {
        left: x,
        top: y
      };
    }
  });

  _exports.default = _default;
});