define("ember-rs/controllers/document/upload-signers", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_ERROR_LIST_LENGTH = 15;
  var CONTENT_MAX_HEIGHT = 88; //Line height 22px * 4

  var _default = Ember.Controller.extend({
    ////////////////////////  PROPERTIES  ////////////////////////
    fileUploaded: false,
    signerFileName: '',
    showErrorModal: false,
    expandList: false,
    isUploading: false,
    uploadErrors: [],
    hasTooManyErrors: false,
    contentStyle: '',
    filterErrors: function filterErrors(errorList) {
      return errorList && errorList.length > 0 ? errorList.slice(0, MAX_ERROR_LIST_LENGTH) : [];
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      fileUploadSuccessful: function fileUploadSuccessful(file) {
        this.setProperties({
          signerFileName: file.name,
          isUploading: false,
          fileUploaded: true
        });
      },
      fileUploadFailed: function fileUploadFailed(errorList) {
        this.setProperties({
          isUploading: false,
          hasTooManyErrors: errorList && errorList.length > MAX_ERROR_LIST_LENGTH,
          uploadErrors: this.filterErrors(errorList),
          showErrorModal: true,
          fileUploaded: false,
          signerFileName: ''
        });
      },
      dismissModal: function dismissModal() {
        this.setProperties({
          showErrorModal: false,
          expandList: false
        });
      },
      expandErrorList: function expandErrorList() {
        this.setProperties({
          expandList: true,
          contentStyle: "overflow: auto; max-height:".concat(CONTENT_MAX_HEIGHT * 1.5 + 10, "px").htmlSafe()
        });
      },
      removeFile: function removeFile() {
        this.setProperties({
          fileUploaded: false,
          signerFileName: ''
        });
      },
      onListRender: function onListRender() {
        var listHeight = (0, _jquery.default)('.error-list').height();
        this.setProperties({
          expandList: listHeight < CONTENT_MAX_HEIGHT,
          contentStyle: listHeight < CONTENT_MAX_HEIGHT ? "min-height:".concat(CONTENT_MAX_HEIGHT / 2, "px").htmlSafe() : "overflow:hidden; max-height:".concat(CONTENT_MAX_HEIGHT, "px").htmlSafe()
        });
      }
    }
  });

  _exports.default = _default;
});