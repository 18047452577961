define("ember-rs/locales/ja/templates/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.index = void 0;
  var index = {
    "templates": "テンプレート",
    "reusableTemplates": "再利用可能なテンプレート",
    "templateExplain": "テンプレートを使用すると、簡単にドキュメントをサイトに埋め込んだり、大量に配布したり、頻繁に使用するドキュメントを送信したりできます。",
    "newTemplate": "新規テンプレート",
    "noResults": "検索結果がありません",
    "searchAgain": "検索結果がありませんでした。テンプレートを見つけるには、さらに検索してみてください。",
    "create": "サイトに埋め込み、リンク経由て共有できる、再利用可能なドキュメント テンプレートを作成します。",
    "createTemplate": "テンプレートの作成",
    "notAllowed": "現在のプランでは、テンプレートを作成する権限がありません。テンプレートを作成するには、サポートに連絡してアカウントをアップグレードしてください。"
  };
  _exports.index = index;
});