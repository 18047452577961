define("ember-rs/locales/pt-br/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rs3LoginRedirect = void 0;
  var rs3LoginRedirect = {
    "looking": "Procurando pelo RightSignature&trade; - ShareFile Edition?",
    "sharefileEdition": "Você chegou a esta página porque você tentou fazer login em uma versão mais antiga do RightSignature&trade;. Nossos registros indicam que você tem uma conta do RightSignature&trade; - ShareFile Edition, faça login abaixo.",
    "support": "Tem perguntas? <a href='https://support.citrix.com/cscase'>Entre em contato com o suporte</a> ou ligue para 1-800-921-4250"
  };
  _exports.rs3LoginRedirect = rs3LoginRedirect;
});