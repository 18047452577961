define("ember-rs/routes/reports/export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Data Exporter',
    ////////////////////////  HOOKS  ////////////////////////
    model: function model() {
      return this.modelFor('reports');
    },
    setupController: function setupController(controller, model) {
      controller.set('monthSelected', this.paramsFor('reports.export.month').month);
      return this._super(controller, model);
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      monthWasSelected: function monthWasSelected(selection) {
        if (selection) {
          var templateIdSelected = this.paramsFor('reports.export.month.template').template_id;

          if (templateIdSelected) {
            this.transitionTo('reports.export.month.template', selection, templateIdSelected);
          } else {
            this.transitionTo('reports.export.month', selection);
          }
        } else {
          this.transitionTo('reports.export');
        }
      }
    }
  });

  _exports.default = _default;
});