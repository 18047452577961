define("ember-rs/locales/fr/templates/components/account-user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "basic": "Informations de base",
    "firstName": "Prénom :",
    "lastName": "Nom :",
    "required": "*Champs obligatoires",
    "email": "E-mail :",
    "emailTip": "*Vous devez entrer une adresse e-mail valide.",
    "password": "Mot de passe :",
    "hiddenForSecurity": "masqué pour des raisons de sécurité",
    "reset": "Réinitialiser",
    "emailInfo": "Envoyer les informations de connexion par e-mail",
    "oldPass": "Ancien mot de passe :",
    "newPass": "Nouveau mot de passe :",
    "passReq": "*Les mots de passe doivent contenir au moins 8 caractères, au moins 1 chiffre, 1 majuscule et 1 minuscule.",
    "permissions": "Autorisations",
    "manageUsers": "Gérer les utilisateurs",
    "manageDelegateUsers": "Peut déléguer des utilisateurs",
    "manageViewBills": "Peut voir les factures",
    "customizeBranding": "Personnaliser marque du compte",
    "manageTemplates": "Gérer les modèles",
    "viewDocuments": "Afficher tous les documents",
    "removeUser": "Supprimer utilisateur",
    "reset2fa": "Réinitialiser la validation en deux étapes"
  };
  _exports.edit = edit;
});