define("ember-rs/locales/fr/templates/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateSelector = void 0;
  var reusableTemplateSelector = {
    "noTemplates": "Vous ne disposez pas encore de modèle.",
    "templateDesc": "Créez des modèles réutilisables à partir de vos documents fréquemment utilisés. Cela vous permet d'incorporer des documents à votre site, de les distribuer en masse ou de les envoyer rapidement.",
    "createNew": "Créer un nouveau modèle",
    "chooseTemplate": "Choisir un modèle",
    "loading": "Chargement...",
    "loadMore": "charger plus de modèles...",
    "upgrade": "Mettez à niveau votre compte pour utiliser des modèles",
    "upgradeAccount": "Mettre à niveau votre compte",
    "bulkSendInfo": "L'envoi en bloc ne fonctionne qu'avec les modèles d'un seul signataire. C'est la première fois que vous utilisez l'envoi en bloc ?",
    "learnMore": "En savoir plus",
    "searchTemplate": "Rechercher modèles",
    "noResults": "Aucun résultat"
  };
  _exports.reusableTemplateSelector = reusableTemplateSelector;
});