define("ember-rs/locales/ja/routes/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    "embeddedTemplate": "このアカウントはこの機能にアクセスできません。プロ プランにアップグレードして埋め込まれたテンプレートの使用を開始してください。",
    "inPerson": "このアカウントはこの機能にアクセスできません。Pro プランにアップグレードして対面署名の使用を開始してください。"
  };
  _exports.startDocument = startDocument;
});