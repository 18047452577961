define("ember-rs/components/documents/document-row", ["exports", "jquery", "ember-rs/config/environment", "moment"], function (_exports, _jquery, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var statusIcons = {
    voided: 'icons/status_voided',
    pending: 'icons/status_pending',
    executed: 'icons/status_complete',
    expired: 'icons/status_expired',
    declined: 'icons/status_declined'
  };

  var _default = Ember.Component.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    tracking: Ember.inject.service(),
    search: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ////////////////////////  BINDINGS  ////////////////////////
    classNameBindings: ['doc.workflowType', ':document-row', ':scrollable-item', 'sentToYou'],
    tagName: 'li',
    ////////////////////////  HOOKS  ////////////////////////
    click: function click() {
      if (this.isArchivedDocument) {
        this.sendAction('showArchivedDocument', Ember.get(this, 'doc.id'));
      } else {
        this.sendAction('showDocument', Ember.get(this, 'doc.id'));
      }

      var session = this.search.getSearchSession();
      this.tracking.trackEvent('@citrix/rsig/showDocument', _objectSpread(_objectSpread({}, session), {}, {
        timestamp: _moment.default.utc().toISOString(),
        isArchivedDocument: this.isArchivedDocument
      }));
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    isPending: Ember.computed.equal('doc.state', 'pending'),
    isVoided: Ember.computed.equal('doc.state', 'voided'),
    isExpired: Ember.computed.equal('doc.state', 'expired'),
    isDeclined: Ember.computed.equal('doc.state', 'declined'),
    isArchivedDocument: Ember.computed.equal('doc.model', 'archivedDocument'),
    currentUserCanViewTags: Ember.computed('doc.userId', 'currentUser.model.id', 'doc.accountId', 'currentUser.accountId', 'currentUser.canViewAllDocuments', function () {
      return this.get('currentUser.canViewAllDocuments') && this.get('doc.accountId') === this.get('currentUser.accountId') || this.get('doc.userId') === this.get('currentUser.model.id');
    }),
    currentUserSignerId: Ember.computed('currentUser.emailVerifications.[]', 'doc.signers.[]', function () {
      var verifiedEmails = this.get('currentUser.emailVerifications').map(function (emailVerification) {
        if (emailVerification.get('verified')) {
          return emailVerification.get('email');
        }
      });
      var matchingSigner = this.get('doc.signers').find(function (signer) {
        return Ember.isPresent(signer.get('email')) && verifiedEmails.indexOf(signer.get('email').toLowerCase()) > -1;
      });
      return matchingSigner && matchingSigner.get('id');
    }),
    currentUserSigner: Ember.computed('doc.signers.[]', 'currentUserSignerId', function () {
      return Ember.get(this, 'doc.signers').findBy('id', this.currentUserSignerId);
    }),
    currentUserSignerPendingSignature: Ember.computed('currentUserSigner.state', 'currentUserSigner.status', 'isPending', 'nextSignerId', function () {
      var currentUserSignerStatus = Ember.get(this, 'currentUserSigner.state') || Ember.get(this, 'currentUserSigner.status');
      return Ember.isPresent(this.currentUserSigner) && currentUserSignerStatus === 'pending' && this.isPending && (!Ember.get(this, 'doc.nextSignerId') || this.currentUserSignerId === Ember.get(this, 'doc.nextSignerId'));
    }),
    statusIcon: Ember.computed('doc.state', function () {
      return statusIcons[Ember.get(this, 'doc.state')] || 'icons/status_pending';
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      sign: function sign() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            type: 'GET',
            url: "".concat(_environment.default.api.endpoint, "/signers/").concat(Ember.get(_this, 'currentUserSigner.id'), "/session_authenticate")
          }).then(function (response) {
            var kbaParam = Ember.get(_this, 'doc.kba') ? '&kba=true' : '';
            var signUrl = "/signers/".concat(Ember.get(_this, 'currentUserSigner.id'), "/sign?access_token=").concat(response.access_token).concat(kbaParam);
            window.location.href = signUrl;
            resolve(response);
          }, function (error) {
            _this.flashMessages.warning(_this.i18n.t('components.documents.documentRow.auth_failure', {
              authError: error
            }));

            reject(error);
          });
        }, 'sign a document');
      }
    }
  });

  _exports.default = _default;
});