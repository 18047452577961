define("ember-rs/locales/it/templates/onboarding/welcome-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding1 = void 0;
  var welcomeOnboarding1 = {
    "welcome": "Ti diamo il benvenuto in RightSignature,",
    "tips": "Mostreremo dove sono i pulsanti e le azioni, spiegheremo come usare RightSignature e forniremo suggerimenti per utilizzare al meglio il nostro servizio"
  };
  _exports.welcomeOnboarding1 = welcomeOnboarding1;
});