define("ember-rs/locales/pt-br/templates/components/document-download-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDownloadOptions = void 0;
  var documentDownloadOptions = {
    "originalPDF": "PDF original",
    "completedPDF": "PDF pronto",
    "overlay": "Sobreposição de PDF imprimível",
    "failed": "PDF pronto (com falha)",
    "support": "Ocorreu um problema ao processar o documento. Entre em contato com o suporte.",
    "cert": "Certificado de assinatura",
    "mergedPDF": "PDF combinado"
  };
  _exports.documentDownloadOptions = documentDownloadOptions;
});