define("ember-rs/locales/es/templates/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choose_documents = void 0;
  var choose_documents = {
    "drag_drop": "Arrastrar y soltar archivos aquí o:",
    "choose_computer": "Selecciónelos en el equipo",
    "choose_template": "Elija la plantilla que quiere usar para el envío en bloque",
    "use_template": "Usar una plantilla",
    "other_source": "Otras fuentes",
    "from_cloud": "de la nube",
    "template": "plantilla",
    "add_another": "Agregar otro archivo",
    "prepare_document": "Preparar documento",
    "prepare_documents": "Preparar documentos",
    "upload_signers": "Cargar firmantes",
    "drag_drop_csv": "Arrastre su archivo CSV y suéltelo aquí:",
    "select_file_computer": "Seleccione un archivo de su equipo",
    "remove_file": "Quitar",
    "upload_success": "Archivo cargado correctamente.",
    "upload_multifile": "La carga de varios archivos no está disponible. Cargue solamente un archivo.",
    "upload_invalid_extension": "La extensión del archivo {{{fileName}}} no es válida.",
    "upload_size_limit": "El archivo {{{fileName}}} supera nuestro límite de 5 MB.",
    "send_package": "Enviar paquete de documentos",
    "file_size_exceeded": "El tamaño total de los archivos no puede superar los {{{maxFileSize}}} MB. Quite al menos un documento para continuar.",
    "max_files_exceeded": "Solo puede preparar {{{maxFileCount}}} documentos a la vez.",
    "min_files_required": "Un paquete necesita al menos dos documentos.",
    "preparing_package": "Estamos preparando su documento. Esto puede tardar unos segundos.",
    "merge_upload_error": "Se ha producido un problema al preparar los documentos. Quítelos y cárguelos de nuevo."
  };
  _exports.choose_documents = choose_documents;
});