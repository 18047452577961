define("ember-rs/locales/it/templates/onboarding/welcome-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding2 = void 0;
  var welcomeOnboarding2 = {
    "tips": "Accedere ai propri <span>documenti</span> e <span>modelli</span>, <span>gestire le impostazioni</span> e trovare <span>esercitazioni video e articoli informativi</span>."
  };
  _exports.welcomeOnboarding2 = welcomeOnboarding2;
});