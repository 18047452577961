define("ember-rs/locales/zh-cn/templates/template", ["exports", "ember-rs/locales/zh-cn/templates/template/details", "ember-rs/locales/zh-cn/templates/template/search-header-mobile"], function (_exports, _details, _searchHeaderMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    details: _details.details,
    searchHeaderMobile: _searchHeaderMobile.searchHeaderMobile,
    "review": {
      "header-content": "检查",
      "create-button": "创建模板",
      "update-button": "更新模板"
    },
    "index": {
      "subheader-create-button": "创建模板",
      "template-limit-modal": {
        "cancellation-grace-period": "此帐户已取消，因此您无法创建模板",
        "limit-reached": "您当前的计划仅允许您创建 {{templateLimit}} 个模板。如果要创建更多模板，请联系技术支持并升级您的帐户。",
        "not-enabled": "您当前的计划不允许您创建模板。如果要创建模板，请联系技术支持并升级您的帐户。"
      }
    }
  };
  _exports.template = template;
});