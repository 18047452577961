define("ember-rs/locales/ja/templates/onboarding/prepare-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding2 = void 0;
  var prepareOnboarding2 = {
    "useTools": "これらのツールを使用して、ドキュメントに<span>署名、テキスト、またはチェックマーク</span>の注釈を加えます"
  };
  _exports.prepareOnboarding2 = prepareOnboarding2;
});