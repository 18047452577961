define("ember-rs/locales/de/templates/client-application/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTranslations = void 0;
  var newTranslations = {
    "request_key": "Neuen API-Schlüssel anfordern",
    "name_tool_tip": "Dies wird Benutzern angezeigt, wenn Sie mit OAuth Zugriff auf deren Konten anfordern. Beispiel: Industry Automative Estimates Generator oder WordStar Plugin",
    "name": "Name:",
    "org_name_tip": "  Name Ihres Unternehmens",
    "org_name": "Organisationsname:",
    "description_tip": "Dies wird Benutzern angezeigt, wenn Sie mit OAuth Zugriff auf deren Konten anfordern. Beispiel: 'Diese Anwendung wird ausschließlich von Industry Automotive verwendet, um Kalkulationen innerhalb unseres Unternehmensworkflows zu senden' oder 'Mit dem WordStar Plugin können WordStar-Benutzer Dokumente über ihr RightSignature&trade;-Konto zur Signatur senden'.",
    "description": "Beschreibung:",
    "scopes_tip": "  Gewünschte Zugriffsebene",
    "scopes": "Geltungsbereich(e):",
    "app_url_tip": "Dies wird Benutzern angezeigt, wenn Sie mit OAuth Zugriff auf deren Konten anfordern. Beispiel: http://industryauto.com/login oder http://wordstarsupportgroup.com/theplugin",
    "app_url": "Anwendungs-URL:",
    "redirect_url_tip": "Dies ist der Standardrückruf für OAuth-Autorisierungsanforderungen. Er kann in den API-Aufrufen angegeben oder überschrieben werden. Beispiel: http://industryauto.com/oauth_callback",
    "redirect_url": "Umleitungs-URL:",
    "org_url_tip": "  Website Ihrer Organisation (optional)",
    "org_url": "Organisations-URL:",
    "Request": "Anfordern",
    "readOnlyAccess": "Schreibgeschützter Zugriff",
    "readWriteAccess": "Lese- und Schreibzugriff"
  };
  _exports.newTranslations = newTranslations;
});