define("ember-rs/components/signer-alert-on-edit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    senderEmail: Ember.computed('signer.document.senderEmail', function () {
      return this.get('signer.document.senderEmail');
    }),
    actions: {
      signerAlert: function signerAlert() {
        this.get('router').transitionTo('unauthorized.document-editing');
      }
    }
  });

  _exports.default = _default;
});