define("ember-rs/locales/es/templates/components/component-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbar = void 0;
  var componentToolbar = {
    "useTools": "Use estas herramientas para los campos que los firmantes deben completar.",
    "showLess": "Mostrar menos",
    "showMore": "Mostrar más",
    "annotate": "anotar:",
    "annotateTip": "Use estas herramientas para anotar el documento con su propia firma, texto o marcas de verificación.",
    "request": "Solicitar:",
    "dataFields": "Campos de datos",
    "sourceFetchFailed": "No se pudo obtener la información de origen.",
    "schemaFetchFailed": "No se pudo obtener el esquema de entidades de origen."
  };
  _exports.componentToolbar = componentToolbar;
});