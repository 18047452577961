define("ember-rs/locales/it/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding1 = void 0;
  var accountOnboarding1 = {
    "body": "È possibile modificare <span>le impostazioni</span>, <span>personalizzare l'account con il proprio marchio</span>, <span>eseguire l'integrazione</span> con altri strumenti e altro ancora."
  };
  _exports.accountOnboarding1 = accountOnboarding1;
});