define("ember-rs/controllers/document-transaction/choose-documents", ["exports", "ember-rs/config/environment", "jquery", "ember-rs/mixins/integration-documents-list", "ember-rs/utils/poller"], function (_exports, _environment, _jquery, _integrationDocumentsList, _poller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PACKAGE_MAX_FILE_SIZE = 20;
  var PACKAGE_UPLOAD_LIMIT = 5;

  var _default = Ember.Controller.extend(_integrationDocumentsList.default, {
    flashMessages: Ember.inject.service(),
    uploads: Ember.A(),
    layoutSizeData: Ember.inject.service(),
    session: Ember.inject.service(),
    showTemplatePicker: false,
    addAnotherFile: false,
    hasAttemptedSkipToPrepare: false,
    removingTemplate: false,
    shouldPollForMergeUpload: false,
    processingMergeUpload: false,
    packageMaxFileCountReached: false,
    packageMaxFileCountExceeded: false,
    authorizationOrigin: Ember.computed('model', function () {
      return {
        route: 'document-transaction.choose_documents',
        id: this.get('model.id')
      };
    }),
    sortedUploads: Ember.A(),
    isValid: Ember.computed('model.documents.@each.uploadReady', 'sortedUploads.@each.uploadReady', function () {
      var uploadStatus = [];

      if (this.get('model.isPackage')) {
        uploadStatus = this.get('sortedUploads').map(function (upload) {
          return upload.uploadReady;
        });
      } else {
        uploadStatus = this.get('model.documents').map(function (document) {
          return document.get('upload').uploadReady;
        });
      }

      return uploadStatus.length > 0 && uploadStatus.every(function (status) {
        return status === 'ready';
      });
    }),
    isNotValid: Ember.computed.not('isValid'),
    isValidPackage: Ember.computed('isValid', 'packageMaxFileCountExceeded', 'mergedUploadSize', function () {
      return this.get('isValid') && !this.get('packageMaxFileCountExceeded') && this.get('mergedUploadSize') <= PACKAGE_MAX_FILE_SIZE;
    }),
    watchUploads: Ember.observer('sortedUploads.[]', function () {
      if (!this.get('model.isPackage')) {
        return;
      }

      if (this.get('mergedUploadSize') > PACKAGE_MAX_FILE_SIZE) {
        this.get('flashMessages').warning(this.get('i18n').t('document_transaction.choose_documents.file_size_exceeded', {
          maxFileSize: PACKAGE_MAX_FILE_SIZE
        }), {
          sticky: true
        });
      }

      var totalUploads = this.get('sortedUploads').length;

      if (totalUploads > PACKAGE_UPLOAD_LIMIT && !this.get('packageMaxFileCountExceeded')) {
        this.get('flashMessages').warning(this.get('i18n').t('document_transaction.choose_documents.max_files_exceeded', {
          maxFileCount: PACKAGE_UPLOAD_LIMIT
        }), {
          sticky: true
        });
      }

      this.setProperties({
        packageMaxFileCountReached: totalUploads >= PACKAGE_UPLOAD_LIMIT,
        packageMaxFileCountExceeded: totalUploads > PACKAGE_UPLOAD_LIMIT
      });
    }),
    skipToPrepare: Ember.observer('isValid', 'model.documents.@each.uploadReady', function () {
      if (this.isValid && this.get('session.data.sharefileRedirect.skipToPrepare') && !this.hasAttemptedSkipToPrepare) {
        this.set("hasAttemptedSkipToPrepare", true);
        this.send("nextStep");
      }
    }),
    mergeTransaction: Ember.computed.alias('model.mergeTransaction'),
    mergeTransactionStatus: Ember.computed.alias('model.mergeTransaction.status'),
    mergedUploadSize: Ember.computed.alias('model.mergeTransaction.totalUploadSize'),
    hideActions: Ember.computed('session.data.sharefileRedirect.skipToPrepare', function () {
      return this.get("session.data.sharefileRedirect.skipToPrepare");
    }),
    hideActionsPackager: Ember.computed('mergeTransactionStatus', 'processingMergeUpload', function () {
      return this.get('processingMergeUpload') || this.get('mergeTransactionStatus') === 'completed';
    }),
    documentsSelected: Ember.computed('model.documents.[]', 'sortedUploads.[]', function () {
      return this.get('model.documents.length') > 0 || this.get('sortedUploads.length') > 0;
    }),
    noDocumentsSelected: Ember.computed.not('documentsSelected'),
    isUploading: Ember.computed.notEmpty('uploads'),
    hasProvidedDocuments: Ember.computed.alias('documentsSelected'),
    stepStyle: Ember.computed('layoutSizeData.windowWidth', 'layoutSizeData.windowHeight', function () {
      var height = this.get('layoutSizeData.windowHeight') - this.get('layoutSizeData.tmHeaderHeight');
      var width = this.get('layoutSizeData.windowWidth');
      return "height: ".concat(height, "px; width: ").concat(width, "px;").htmlSafe();
    }),
    nextStepButtonText: Ember.computed('model.documents.[]', function () {
      if (this.get('model.documents.length') > 1 || this.get('sortedUploads.length') > 1) {
        return this.get('i18n').t("document_transaction.choose_documents.prepare_documents");
      } else if (this.get('model.isBulkSend')) {
        return this.i18n.t("document_transaction.choose_documents.upload_signers");
      } else {
        return this.i18n.t("document_transaction.choose_documents.prepare_document");
      }
    }),
    documentsChanged: Ember.computed('model.documents.[]', function () {
      return this.get('model.documents.length');
    }),
    hidePopupsOnAdd: Ember.observer('model.documents.[]', 'sortedUploads.[]', function () {
      this.set('showTemplatePicker', false);
      this.set('showIntegrationPicker', false);
      this.set('addAnotherFile', false);
    }),
    pollForMergeUpload: Ember.observer('shouldPollForMergeUpload', function () {
      if (this.get('shouldPollForMergeUpload') && this.get('mergeTransactionStatus') === 'pending' && Ember.isBlank(this.get('mergeUploadPoller'))) {
        var self = this;

        if (Ember.isBlank(this.get('mergeUploadPoller'))) {
          this.set('mergeUploadPoller', _poller.default.create({
            onPoll: function onPoll() {
              return self.get('model').get('mergeTransaction').reload().then(function (mergeTransaction) {
                if (mergeTransaction.get('status') !== 'pending') {
                  self.get('mergeUploadPoller').stop();
                  self.setProperties({
                    'processingMergeUpload': false,
                    'shouldPollForMergeUpload': false
                  });
                  self.get('mergeTransactionStatus') === 'error' ? self.get('flashMessages').warning(self.get('i18n').t('document_transaction.choose_documents.merge_upload_error'), {
                    sticky: true
                  }) : self.createNewDocument();
                }
              }).catch(function () {
                self.set('shouldPollForMergeUpload', 'false');
                self.get('mergeUploadPoller').stop();
              });
            }
          }));
        }

        this.get('mergeUploadPoller').start();
      }
    }),
    ////////////////////////  METHODS  ////////////////////////
    removeUpload: function removeUpload(uploadId) {
      this.set('sortedUploads', this.get('sortedUploads').filter(function (upload) {
        return upload.id !== uploadId;
      }));
      this.store.findRecord('mergeTransaction', this.get('model.mergeTransaction.id')).then(function (record) {
        var uploadSequence = record.get('uploadSequence') || {};
        var sequenceNumber = uploadSequence[uploadId];

        for (var key in uploadSequence) {
          if (uploadSequence[key] > sequenceNumber) {
            --uploadSequence[key];
          }
        }

        delete uploadSequence[uploadId];
        record.set('uploadSequence', uploadSequence);
        record.save();
      });
    },
    createNewDocument: function createNewDocument() {
      var _this = this;

      this.store.find('upload', this.get('mergeTransaction.mergedUploadId')).then(function (upload) {
        var createOptions = {
          upload: upload,
          documentTransaction: _this.get('model'),
          account: _this.get('currentUser.account'),
          name: null,
          roles: [],
          components: [],
          mergefieldValues: []
        };
        var self = _this;

        _this.store.createRecord('document', createOptions).save().then(function () {
          self.get('model').save();
          self.set('processingMergeUpload', false);
          self.send("nextStep");
        });
      }).catch(function () {
        _this.set('shouldPollForMergeUpload', false);

        _this.set('processingMergeUpload', false);
      });
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      isValidCheck: function isValidCheck() {
        this.set('isValid', false);
        var uploadStatus = [];

        if (this.get('model.isPackage')) {
          uploadStatus = this.get('sortedUploads').map(function (upload) {
            return upload.uploadReady;
          });
        } else {
          uploadStatus = this.get('model.documents').map(function (document) {
            return document.get('upload').uploadReady;
          });
        }

        var uploadsCompleted = uploadStatus.length > 0 && uploadStatus.every(function (status) {
          return status === 'ready';
        });
        this.set('isValid', uploadsCompleted);
      },
      hideAddFile: function hideAddFile() {
        this.set('addAnotherFile', false);
      },
      showAddFile: function showAddFile() {
        this.set('addAnotherFile', true);
      },
      hideTemplatePicker: function hideTemplatePicker() {
        this.set('showTemplatePicker', false);
      },
      mergeAndNextStep: function mergeAndNextStep() {
        if (this.get('sortedUploads').length < 2) {
          this.get('flashMessages').warning(this.get('i18n').t('document_transaction.choose_documents.min_files_required'), {
            sticky: true
          });
          return;
        }

        if (this.get('mergeTransactionStatus') !== 'completed') {
          this.set('processingMergeUpload', true);
          var self = this;

          _jquery.default.ajax({
            url: _environment.default.api.endpoint.concat("/merge_transactions/".concat(this.get('mergeTransaction.id'), "/merge_upload")),
            type: 'POST',
            success: function success(response) {
              self.store.pushPayload(response);
              self.set('shouldPollForMergeUpload', true);
            },
            error: function error() {
              self.set('processingMergeUpload', false);
            }
          });
        } else {
          this.send("nextStep");
        }
      },
      onUploadUpdated: function onUploadUpdated(upload) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var self = this;

        if (upload.get('state') === 'upload_done') {
          if (this.get('model.isPackage')) {
            this.store.findRecord('mergeTransaction', this.get('mergeTransaction.id')).then(function (record) {
              var uploadSequence = record.get('uploadSequence');
              uploadSequence[upload.get('id')] = self.get('sortedUploads.length');
              record.set('uploadSequence', uploadSequence);
              self.get('sortedUploads').addObject(upload);
              self.get('uploads').removeObject(upload);
              record.save();
            });
          } else {
            var defaultName = upload.get('filename').split('.')[0].split(/[_`~!@#$%^&*(){};:'",.?[\]|\s\\|/<>=]+/).join(' ');
            var createOptions = {
              upload: upload,
              documentTransaction: this.get('model'),
              account: this.get('currentUser.account'),
              name: defaultName,
              roles: [],
              components: [],
              mergefieldValues: []
            };

            if (options.integration) {
              var metadata = options.integrationMetadata;

              if (options.integrationProvider === "sharefile") {
                metadata['sharefile_type'] = "internal";
              }

              createOptions.integration = options.integration;
              createOptions.integrationProvider = options.integrationProvider;
              createOptions.integrationMetadata = metadata;
            }

            this.store.createRecord('document', createOptions).save().then(function () {
              // Remove upload from uploads
              self.get('uploads').removeObject(upload); // Save the transaction when no uploads are in progress

              if (self.get('uploads.length') === 0) {
                self.get('model').save();
              }
            });
          }
        }
      },
      toggleService: function toggleService(service, serviceEnabled, isAuthorized, provider, integrationMetadata, params) {
        var _this2 = this;

        this.model.save().then(function () {
          _this2.toggleService(service, serviceEnabled, isAuthorized, provider, integrationMetadata, params);
        });
      },
      templateSelected: function templateSelected() {
        this.set('showTemplatePicker', false);
        this.model.save();
      },
      removeTemplate: function removeTemplate(template) {
        this.set('removingTemplate', true);
        template.destroyRecord();
        this.get('model.isPackage') && this.removeUpload(template.id);
      }
    }
  });

  _exports.default = _default;
});