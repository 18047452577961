define("ember-rs/templates/user/settings/delete-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "97oewC3u",
    "block": "{\"symbols\":[],\"statements\":[[8,\"delete-signature\",[],[[\"@signature\",\"@closeAction\"],[[34,0],\"closeModal\"]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/user/settings/delete-signature.hbs"
    }
  });

  _exports.default = _default;
});