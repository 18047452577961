define("ember-rs/locales/zh-cn/templates/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "send": "发送以获取签名",
    "notAllowed": " 您当前的计划不允许您发送文档。请升级您的计划以访问此功能。",
    "embed": "在 Web 站点中嵌入",
    "share": "生成共享链接",
    "manageLinks": "管理链接和嵌入",
    "viewResponses": "查看答复",
    "edit": "编辑",
    "delete": "删除",
    "roles": "角色",
    "security": "安全选项:",
    "expires": "过期时间:",
    "days": "天",
    "passcodeQuestion": "通行码问题:",
    "passcode": "通行码:",
    "tags": "标记:",
    "linkHistory": "链接和嵌入代码历史记录",
    "linkHistoryTip": "管理和查看此模板的所有链接和嵌入的使用信息。",
    "generate": "生成共享链接",
    "nameLink": "命名此共享链接。",
    "nameLinkTip": "这可以使管理链接更容易。",
    "whichSigner": "哪个签名者角色?",
    "whichSignerTip": "嵌入的模板和链接只能有一个签名者。",
    "limitUses": "限制使用次数?",
    "limitUsesTip": "设置可以对此链接签名的次数限制。",
    "expireDays": "几天后过期?",
    "expireDaysTip": "过期后，此链接将不再有效。",
    "identityMethod": "身份识别方法:",
    "identityMethodTip": "我们应如何验证签名者的身份?",
    "generateLink": "生成链接",
    "generateEmbed": "生成嵌入代码",
    "nameCode": "命名此代码。",
    "nameCodeTip": "这可以使管理生成的代码更容易。",
    "displayType": "显示类型",
    "displayTypeTip": "这将指定嵌入应如何显示",
    "pixel": "像素",
    "codeGenerated": "生成的代码",
    "linkGenerated": "生成的链接",
    "clickingManage": "，方法是单击模板页面上的“管理链接和嵌入”。",
    "youCanAccessCode": "您可以通过单击模板页面上的“管理链接和嵌入”来访问、禁用和编辑此代码。",
    "youCanAccessLink": "您可以通过单击模板页面上的“管理链接和嵌入”来访问、禁用和编辑此链接。",
    "newPin": "新 PIN",
    "done": "完成",
    "email": "电子邮件",
    "sms": "短信",
    "none": "无",
    "height": "高度",
    "Width": "宽度",
    "button": "button",
    "image": "图片",
    "inline": "内联",
    "createdBy": "创建者",
    "actions": "操作",
    "visibility-toggle-text": "将模板设置为专用",
    "visibility-toogle-info": "设置为专用将使模板仅对其创建者可见",
    "template-signer-link": {
      "passcode-message": "已为此帐户启用通行码功能，但不会对共享链接或嵌入的文档强制执行。此链接/嵌入代码将允许在没有通行码的情况下访问此文档。"
    },
    "embed-in-website": {
      "disabled-merge-fields": "嵌入代码当前不适用于此模板。要为此模板生成嵌入代码，请先删除所有必填的合并字段。",
      "disabled-no-online-forms": "您当前的计划不允许您嵌入模板。请升级您的计划以访问此功能。"
    },
    "generate-share-link": {
      "disabled-merge-fields": "嵌入代码当前不适用于此模板。要为此模板生成嵌入代码，请先删除所有必填的合并字段。",
      "disabled-no-manual-distribution": "您当前的计划不允许您生成共享链接。请升级您的计划以访问此功能。"
    }
  };
  _exports.details = details;
});