define("ember-rs/locales/it/templates/components/document-overlayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentOverlayer = void 0;
  var documentOverlayer = {
    "prepare": "Prepara documento",
    "tools": "strumenti avanzati: ",
    "importOverlay": "Importa sovrapposizione",
    "toolTip": "Selezionare un documento inviato in precedenza per posizionare i campi su questo documento nella stessa posizione. Nota: il documento deve avere lo stesso nome.",
    "fieldDetection": "Rilevamento dei campi",
    "fieldDetectionTip": "Il rilevamento dei campi consente di allineare i campi in modo più accurato alle righe e alle caselle del documento.",
    "roles": "ruoli",
    "rolesTip": "Consente di visualizzare i ruoli per questo documento. Ogni ruolo ha il proprio colore, che corrisponde ai campi che devono essere completati.",
    "signers": "firmatari",
    "signersTip": "Consente di visualizzare i firmatari per questo documento. Ogni firmatario ha il proprio colore, che corrisponde ai campi che devono essere completati.",
    "attachmentUnavailable": "Gli allegati non sono disponibili nell'account selezionato. Eseguire l'aggiornamento a un piano pro per richiedere gli allegati ai firmatari.",
    "paymentUnavailable": "I pagamenti non sono disponibili nell'account selezionato. Eseguire l'aggiornamento a un piano pro per riscuotere pagamenti dai firmatari.",
    "paymentNotEnabled": "Abilitare l'integrazione dei pagamenti prima di utilizzare i componenti di pagamento. È possibile farlo nella sezione Integrazioni delle impostazioni dell'account.",
    "noRoles": "Nessun ruolo trovato. Probabilmente si è ricaricato senza salvare?"
  };
  _exports.documentOverlayer = documentOverlayer;
});