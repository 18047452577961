define("ember-rs/locales/pt-br/templates/components/add-role-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoleField = void 0;
  var addRoleField = {
    "name": "Nome...",
    "emailOptional": "Email (opcional)...",
    "email": "Email...",
    "nameTip": "Nome completo obrigatório.",
    "emailTip": "É necessário um endereço de email válido.",
    "roleTip": "O nome da função é obrigatório."
  };
  _exports.addRoleField = addRoleField;
});