define("ember-rs/locales/it/templates/logged-out/features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.features = void 0;
  var features = {
    "note": "Sono disponibili molte eccellenti funzionalità."
  };
  _exports.features = features;
});