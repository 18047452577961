define("ember-rs/templates/components/import-overlay-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sBrsa4x6",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"row-container\"],[4,[38,3],[[32,0],\"selectForImport\"],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"document-thumbnail\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[34,1,[\"thumbnailUrl\"]]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"name\"],[12],[2,\"\\n    \"],[1,[35,1,[\"name\"]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"meta\"],[12],[2,\"\\n      \"],[1,[35,1,[\"filename\"]]],[2,\" -\\n\"],[6,[37,4],[[35,1,[\"sentAt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"shared.actions.sent\"],null]],[2,\" \"],[1,[30,[36,2],[[35,1,[\"sentAt\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-caret-right\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"importableDocument\",\"moment-from-now\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/import-overlay-row.hbs"
    }
  });

  _exports.default = _default;
});