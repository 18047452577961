define("ember-rs/locales/fr/templates/components/add-role-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoleField = void 0;
  var addRoleField = {
    "name": "Nom...",
    "emailOptional": "E-mail (facultatif)...",
    "email": "E-mail...",
    "nameTip": "Nom complet requis.",
    "emailTip": "Adresse e-mail valide requise.",
    "roleTip": "Nom du rôle requis."
  };
  _exports.addRoleField = addRoleField;
});