define("ember-rs/locales/ja/mixins/passcode-pin-generator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcodePinGenerator = void 0;
  var passcodePinGenerator = {
    "failedGeneratePin": "PIN の生成に失敗しました: {{{response}}}"
  };
  _exports.passcodePinGenerator = passcodePinGenerator;
});