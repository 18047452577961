define("ember-rs/locales/en/templates/logged-out/features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.features = void 0;
  var features = {
    "note": "We have so many awesome features!"
  };
  _exports.features = features;
});