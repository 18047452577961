define("ember-rs/models/upload", ["exports", "@ember-data/model", "ember-rs/mixins/embedded-records-has-many"], function (_exports, _model, _embeddedRecordsHasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_embeddedRecordsHasMany.default, {
    filename: (0, _model.attr)('string'),
    originalFilename: (0, _model.attr)('string'),
    contentType: (0, _model.attr)('string'),
    size: (0, _model.attr)('number'),
    s3Upload: (0, _model.attr)('object'),
    fromRemoteUrl: (0, _model.attr)('boolean'),
    remoteUrl: (0, _model.attr)('string'),
    uploadCompletedAt: (0, _model.attr)('moment-date'),
    user: (0, _model.belongsTo)('user', {
      async: false
    }),
    baseFile: (0, _model.belongsTo)('base-file', {
      async: true
    }),
    // Ember-data polymorphic association is broken, so we need to track upload per type of DocumentTemplate
    // documentTemplates: hasMany('documentTemplate', { polymorphic: true }),
    reusableTemplate: (0, _model.belongsTo)('reusable-template', {
      async: false
    }),
    _state: (0, _model.attr)('string', {
      transient: true
    }),
    uploadReady: Ember.computed.alias('baseFile.processingState'),
    state: Ember.computed('_state', 'uploadCompletedAt', 'baseFile.processingState', function () {
      if (this.uploadCompletedAt) {
        return this.get('baseFile.processingState');
      } else if (this._state) {
        return this._state;
      } else {
        return 'processing';
      }
    }).readOnly(),
    displayState: Ember.computed('state', function () {
      return this.i18n.t("components.documentUploadThumbnail.".concat(this.state));
    }),
    stateText: Ember.computed('state', function () {
      switch (this.state) {
        case 'uploading':
          return "uploading ".concat(this.filename);

        case 'upload_done':
          return "upload complete";

        default:
          return this.state;
      }
    }).readOnly()
  });

  _exports.default = _default;
});