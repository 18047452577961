define("ember-rs/locales/fr/mixins/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "successUpgrade": "Vous avez mis à niveau votre compte avec succès. Bienvenue sur RightSignature !",
    "dataUnavailable": "Les données ne sont pas disponibles pour le moment. Revenez sur cette page plus tard."
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});