define("ember-rs/components/mobile-next-required-field", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['button', 'scroll-to-next'],
    classNameBindings: ['showHide'],
    documentViewerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    showHide: Ember.computed('nextRequiredField', function () {
      if (this.nextRequiredField) {
        return 'show';
      } else {
        return 'hide';
      }
    }),
    click: function click() {
      var componentEle = (0, _jquery.default)(".signing-component[data-id='".concat(this.get('nextRequiredField.id'), "']"));
      this.documentViewerData.scrollToElement(componentEle, true);
    }
  });

  _exports.default = _default;
});