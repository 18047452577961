define("ember-rs/locales/de/templates/upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgrade = void 0;
  var upgrade = {
    "noUpgrade": "Upgrade für Konto nicht möglich",
    "noPermission": "Sie haben keine Berechtigung, ein Upgrade für Ihr Konto anzufordern. Sprechen Sie mit Ihrem Kontoadministrator über das Upgrade auf ein neues RightSignature-Abo.",
    "upgrade": "Upgrade Ihres Kontos durchführen",
    "upgradeSubtitle": "Während Ihrer Testphase hatten Sie ein Business-Abonnement. Sie können bei diesem Abo bleiben oder ein anderes, für Sie passenderes Abo wählen.",
    "choosePlan": "1. Abonnement wählen",
    "allInOne": "Die Komplettlösung für die elektronische Signatur",
    "standard": "Standardfunktionen ",
    "usersNeeded": "2. Wie viele Benutzer benötigen Sie?",
    "checkout": "Zahlung",
    "showLess": "Weniger anzeigen",
    "showMore": "Mehr anzeigen",
    "users": "Benutzer",
    "confirm": "Drücken Sie zur Bestätigung die Eingabetaste",
    "billing": "Abrechnung",
    "billingDetails": "Abrechnungsdetails",
    "noOfUser": "Anzahl der Benutzer",
    "billAmount": "Fakturierbarer Betrag",
    "creditAmount": "Guthaben aus alter RightSignature-Version",
    "total": "Insgesamt",
    "taxCharges": "Es können zusätzliche Steuern anfallen",
    "oops": "Ein Fehler ist aufgetreten",
    "dataUnavailable": "Daten sind derzeit nicht verfügbar. Bitte kommen Sie später auf diese Seite zurück."
  };
  _exports.upgrade = upgrade;
});