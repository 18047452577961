define("ember-rs/locales/zh-cn/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers = void 0;
  var prepare_signers = {
    "add_signers": "添加签名者",
    "doc_limit_p1": "您使用了本月 ",
    "doc_limit_p2": "的文档限制。请与我们联系以升级您的帐户。",
    "recentContacts": "最近的联系人"
  };
  _exports.prepare_signers = prepare_signers;
});