define("ember-rs/locales/pt-br/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanDetails = void 0;
  var hybrisPlanDetails = {
    "document-sending": "Envio de documentos",
    "document-sending-info": "Faça upload e envie documentos em formatos comuns, incluindo PDF, .doc, além de outros.",
    "signature": "Assinaturas manuscritas",
    "signature-info": "Assine com o mouse ou tela sensível ao toque - tem a mesma aparência da sua assinatura em papel.",
    "customer-support": "Suporte ao cliente 24 horas por dia",
    "customer-support-info": "Obtenha ajuda sempre que precisar da nossa premiada equipe de suporte técnico.",
    "email-alert": "Alertas email",
    "email-alert-info": "Lembretes para os signatários e atualizações instantâneas do status dos seus documentos.",
    "integration": "Complementos de integração",
    "integration-info": "Conecte o RightSignature a mais de uma dúzia dos aplicativos avançados mais difundidos.",
    "type-sign": "Digite para assinar",
    "type-sign-info": "Permita que seus signatários assinem documentos usando um teclado.",
    "mobile": "Assinar em dispositivos móveis",
    "mobile-info": "Assine documentos de qualquer lugar usando seu smartphone ou tablet.",
    "guided-signing": "Assinatura guiada",
    "guided-signing-info": "Reduza erros e omissões com instruções simples e passo a passo.",
    "archive": "Arquivamento seguro de documentos",
    "archive-info": "Proteja seus documentos importantes com o nosso arquivo seguro e pesquisável.",
    "encrypt": "Criptografia de dados de 256 bits",
    "encrypt-info": "A segurança de nível bancário protege seus documentos confidenciais contra vazamentos e roubos.",
    "biometric": "Autenticação biométrica",
    "biometric-info": "Ajude a verificar a autenticidade do seu signatário com os principais dados biométricos.",
    "fingerprint": "Impressões digitais",
    "fingerprint-info": "As somas de verificação digitais SHA-1 impedem que alguma parte modifique seu documento.",
    "audit": "Log de auditoria e certificado",
    "audit-info": "Para conformidade legal, todas as ações em um documento são registradas e protegidas.",
    "template": "Modelos reusáveis",
    "template-info": "Compartilhe documentos comumente usados com toda a sua equipe.",
    "branding": "Identidade visual personalizada",
    "branding-info": "Adicione seu logotipo, cores e uma foto sua aos documentos do RightSignature.",
    "attachments": "Solicitar anexos",
    "attachments-info": "Permita que seus signatários anexem um arquivo como parte da assinatura do documento.",
    "payments": "Solicitar pagamentos",
    "payments-info": "Processar pagamentos ou coletar informações de cartão de crédito em seus documentos.",
    "api": "API do desenvolvedor",
    "api-info": "Integre assinaturas eletrônicas do RightSignature em seus próprios aplicativos ou site.",
    "kba": "Autenticação baseada em conhecimento",
    "kba-info": "Em certos setores, é possível melhorar a conformidade com os regulamentos relevantes.",
    "standard": "Padrão",
    "advanced": "<span>Avançado<span>",
    "advancedKba": "<span>KBA avançado<span>",
    "sharefilePremium": "<span><p>Sharefile Premium</p> <p> com assinatura eletrônica</p></span>",
    "annually": "Anualmente",
    "quarterly": "Trimestral",
    "monthly": "Mensal",
    "month": "mês",
    "year": "ano",
    "quarter": "trimestre",
    "document-sends-per-user": "100 envios de documentos por usuário",
    "hand-written-text-to-sign": "Assinatura manuscrita, texto em assinatura, clique para concordar",
    "bulk-send-for-signature": "Envio em massa para assinatura",
    "sequenced-signing": "Assinatura sequenciada",
    "knowledge-based-authentication": "Autenticação baseada em conhecimento",
    "unlimited-document-sends": "Envie documentos ilimitados para assinatura",
    "store-sync-share-files": "Armazene, sincronize e compartilhe arquivos sem problemas",
    "hipaa-compliant": "Configuração em conformidade com a HIPAA",
    "advanced-caption": "Inclui recursos muito utilizados como:",
    "advanced-kba-caption": "Inclui recursos muito utilizados como:",
    "sharefile-caption": "Inclui TODOS OS RECURSOS DE ASSINATURA ELETRÔNICA E MAIS AINDA COM CITRIX SHAREFILE:",
    "minimum-of": "Mínimo de {{{noUsers}}} usuários",
    "users": "usuários",
    "user": "usuário",
    "user-per-month": "por usuário/mês",
    "compare-plans-in-detail": "<a href='https://citrix.sharefile.com/share/view/s1493194af0a44866ae95a6a667e7ffcd' style='color:#2071C5' target='_blank'>Comparara planos em detalhes</a>",
    "selected-plan-requires-3-users": "<span style='color:#536E7B'>O plano selecionado exige pelo menos três usuários</span>",
    "more-than-1000-users-contact-sales": "<span style='color:#2071C5'>Se você precisar de mais de 1000 usuários, <a href={{driftLinkId}} style='color: #2071C5; text-decoration: underline; text-transform: lowercase'>converse pelo chat com nosso setor de vendas. </a></span>",
    "trail-upgrade-msg": "Você tem {{{numberOfDaysLeft}}} dias restantes no teste. Para aproveitar o uso sem preocupações e sem interrupções, considere a possibilidade de atualizar seu plano agora.",
    "savings": "Poupança",
    "contact-sales": "Conversar por chat com o setor de vendas",
    "select-plan": "Selecionar Plano",
    "selected-plan": "Plano selecionado",
    "best-for-team-collaboration": "Melhor valor"
  };
  _exports.hybrisPlanDetails = hybrisPlanDetails;
});