define("ember-rs/components/transaction-manager-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layoutSizeData: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    classNameBindings: [':transaction-manager', 'chooseType'],
    // pass in true to add class to target the 'start' step
    chooseType: false
  });

  _exports.default = _default;
});