define("ember-rs/locales/ja/templates/document-transaction/upload-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload_signers = void 0;
  var upload_signers = {
    "instructions_header": "CSV ファイルをアップロードするには、以下の手順に従ってください",
    "step_1": "手順 1",
    "step_2": "手順 2",
    "step_3": "手順 3",
    "step_1_text": "一括送信署名者のサンプルリストをダウンロードします",
    "step_2_text": "名前とメールのフィールドに入力します (必須)。",
    "step_3_text": "完成した CSV ファイルをアップロードします。",
    "instructions_note": "注: 署名者の数は 300 を超えることはできません",
    "upload_failed": "アップロードできません",
    "upload_failure_message": "ファイルの破損または処理中のエラーにより、アップロードに失敗しました。ファイルに有効なコンテンツが含まれていることを確認し、再度アップロードしてください。",
    "view_all": "すべて表示",
    "ok": "OK",
    "validate_and_reupload": "ファイルにさらにエラーがあります。検証内容を確認して、再度アップロードしてください。"
  };
  _exports.upload_signers = upload_signers;
});