define("ember-rs/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p7agCt+M",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"force-complete-document-modal__header\"],[12],[2,\" \"],[1,[30,[36,0],[\"components.forceCompleteDocumentModal.header\"],null]],[2,\" \"],[13],[2,\"\\n\\n\"],[10,\"p\"],[14,0,\"force-complete-document-modal__info\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.forceCompleteDocumentModal.forceCompleted\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"force-complete-document-modal__info\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.forceCompleteDocumentModal.briefMoment\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"force-complete-document-modal__actions\"],[12],[2,\"\\n  \"],[8,\"rs-button\",[],[[\"@action\",\"@isLoading\"],[[30,[36,1],[[32,0],\"forceComplete\"],null],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.forceCompleteDocumentModal.forceComplete\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"rs-button\",[],[[\"@action\",\"@isPrimary\",\"@isDisabled\"],[[30,[36,1],[[32,0],\"closeModal\"],null],false,[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.forceCompleteDocumentModal.cancel\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"saving\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/force-complete-document-modal.hbs"
    }
  });

  _exports.default = _default;
});