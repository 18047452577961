define("ember-rs/locales/en/templates/components/agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "welcome": "Welcome",
    "toRS": "to RightSignature,",
    "bestOnDesktop": "RightSignature is best experienced on a desktop computer.",
    "pleaseRead": "Before we show you around, please read the following:",
    "terms": "By using RightSignature&trade; you are certifying that you have read and agree to our ",
    "and": "and",
    "policy": "Privacy Policy",
    "no": "No Thanks",
    "yes": "I Agree"
  };
  _exports.agreeToTerms = agreeToTerms;
});