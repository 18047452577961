define("ember-rs/locales/es/templates/document/prepare-signers-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_desktop = void 0;
  var prepare_signers_desktop = {
    "assign_roles": "Asignar roles para:",
    "who_sign": "Quién necesita firmar ",
    "sign_order": "Establecer orden de firmas",
    "add_another": "Agregar otro firmante",
    "add_yourself": "Agregarse a sí mismo",
    "contact_integtration": "Integración de contactos",
    "select_integration": "Seleccionar una integración",
    "who_sign_package": "¿Quién debe firmar el paquete de documentos?"
  };
  _exports.prepare_signers_desktop = prepare_signers_desktop;
});