define("ember-rs/locales/ja/templates/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerPaymentDetails = void 0;
  var signerPaymentDetails = {
    "paymentInfo": "支払い情報",
    "paymentID": "支払い ID:",
    "retryPayment": "支払いを再試行しています...",
    "failed": "失敗しました",
    "chargedSuccess": "請求完了: {{chargedAt}}",
    "stripeCustomer": "Stripe の顧客",
    "stripeCharge": "Stripe での請求",
    "attempted": "最後の試行: {{chargedAt}}",
    "retry": "再試行",
    "pending": "保留中...",
    "success": "再試行で支払いに成功しました。",
    "retryError": "支払いの再試行に失敗しました ({{{errorMsg}}})。"
  };
  _exports.signerPaymentDetails = signerPaymentDetails;
});