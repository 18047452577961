define("ember-rs/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kOBazr9H",
    "block": "{\"symbols\":[],\"statements\":[[10,\"form\"],[15,\"action\",[34,0]],[12],[2,\"\\n  \"],[10,\"input\"],[14,3,\"success_action_status\"],[14,2,\"200\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,3,\"x-amz-server-side-encryption\"],[14,2,\"AES256\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,3,\"x-amz-algorithm\"],[14,2,\"AWS4-HMAC-SHA256\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n  \"],[3,\" populated from server... \"],[2,\"\\n  \"],[10,\"input\"],[14,3,\"key\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,3,\"policy\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,3,\"x-amz-credential\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,3,\"x-amz-date\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,3,\"x-amz-signature\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n  \"],[10,\"input\"],[14,3,\"x-amz-security-token\"],[14,4,\"hidden\"],[12],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"input\"],[14,0,\"file-uploader\"],[14,3,\"file\"],[14,4,\"file\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"input\"],[14,0,\"file-uploader\"],[14,3,\"file\"],[14,\"multiple\",\"\"],[14,4,\"file\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"awsBucketUrl\",\"singleFileUpload\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/document-uploader.hbs"
    }
  });

  _exports.default = _default;
});