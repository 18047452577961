define("ember-rs/components/rs-left-nav", ["exports", "ember-rs/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FULL_WIDTH_PAGES = ['unauthorized.expired', 'unauthorized.document-executed', 'unauthorized.document-voided', 'unauthorized.document-declined', 'unauthorized.document-editing', 'unauthorized.document-expired', 'unauthorized.signing-link-disabled', 'unauthorized.already-signed', 'unauthorized.pending-identity-verification', 'unauthorized.not-your-turn', 'unauthorized.index', 'unauthorized.unauthorized', 'unauthorized.rs3-login-redirect', 'unauthorized.unconfirmed-email', 'unauthorized.no-user', 'unauthorized.unsubscribed', 'unauthorized.redirect-to-rs3', 'unauthorized.no-integrations', 'unauthorized.trial-expired', 'unauthorized.identity-expired', 'unauthorized.confirm-identity-expired', 'not-found', 'signer.confirm-identity'];

  var _default = Ember.Component.extend({
    attributeBindings: ['shouldHide:hidden'],
    currentUser: Ember.inject.service(),
    classNames: 'rs-left-nav',
    currentRouteName: '',
    loading: false,
    shouldHide: Ember.computed('currentRouteName', 'currentUser.loggedIn', function () {
      if (FULL_WIDTH_PAGES.includes(this.currentRouteName) || !this.get('currentUser.loggedIn')) {
        return true;
      } else {
        return false;
      }
    }),
    navItems: Ember.computed('canAccessApi', 'canViewBills', 'canManageBranding', 'canManageUsers', 'canAccessUserSettings', 'canAccessIntegrations', 'i18n.locale', 'canInitiateWorkflows', function () {
      var navItems = Ember.A([{
        key: 'documents',
        icon: 'documents',
        text: this.i18n.t('components.rsLeftNav.Documents'),
        route: 'documents'
      }, {
        key: 'templates',
        icon: 'templates',
        text: this.i18n.t('components.rsLeftNav.Templates'),
        route: 'templates'
      }, {
        key: 'account',
        icon: 'settings',
        text: this.i18n.t('components.rsLeftNav.Account'),
        children: Ember.A([{
          text: this.i18n.t('components.rsLeftNav.Settings'),
          route: 'user.settings',
          hasAccess: true
        }, {
          text: this.i18n.t('components.rsLeftNav.Integrations'),
          route: 'user.integrations',
          hasAccess: this.canAccessIntegrations
        }, {
          text: this.i18n.t('components.rsLeftNav.Branding'),
          route: 'account.branding',
          hasAccess: true
        }, {
          text: this.i18n.t('components.rsLeftNav.Users'),
          route: 'account.users',
          hasAccess: this.canAccessUserSettings
        }, {
          text: this.i18n.t('components.rsLeftNav.Reports'),
          route: 'reports',
          hasAccess: true
        }, {
          text: this.i18n.t('components.rsLeftNav.Api'),
          route: 'client-applications',
          hasAccess: this.canAccessApi
        }, {
          text: this.i18n.t('components.rsLeftNav.Billing'),
          route: 'account.bills',
          hasAccess: this.canViewBills
        }])
      }]);

      if (this.get('canInitiateWorkflows') && Ember.get(this, 'currentUser.account.isWorkflowsEnabled')) {
        navItems.splice(1, 0, {
          key: 'workflows',
          icon: 'workflows',
          text: this.i18n.t('components.rsLeftNav.Workflows'),
          redirect: true
        });
      }

      return navItems;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var navItem = this.currentRouteIsChildOf;

      if (navItem) {
        Ember.set(navItem, 'submenuLinkIsActive', true);
      } else {
        this.navItems.forEach(function (navItem) {
          if (navItem.children) {
            Ember.set(navItem, 'submenuLinkIsActive', false);
            Ember.set(navItem, 'isExpanded', false);
          }
        });
      }
    },
    didRender: function didRender() {
      var navItem = this.currentRouteIsChildOf;

      if (navItem) {
        Ember.set(navItem, 'isExpanded', true);
      }
    },
    currentRouteIsChildOf: Ember.computed('currentRouteName', function () {
      var _this = this;

      return this.navItems.find(function (item) {
        if (item.children) {
          var currentRouteName = _this.currentRouteName;
          return item.children.any(function (child) {
            return currentRouteName && currentRouteName.match(new RegExp(child.route + '?'));
          });
        }
      });
    }),
    actions: {
      toggleSubmenu: function toggleSubmenu(navItem) {
        Ember.set(navItem, 'isExpanded', !Ember.get(navItem, 'isExpanded'));
      },
      openUrl: function openUrl() {
        var _this2 = this;

        this.set('loading', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/workflows"),
          type: 'GET'
        }).then(function (response) {
          window.open(response);
        }).catch(function (error) {
          _this2.flashMessages.warning(_this2.i18n.t('components.document.signerRow.resendingFailed', {
            error: error
          }));
        }).then(function () {
          //this block behaves like finally()
          _this2.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});