define("ember-rs/locales/pt-br/templates/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlay = void 0;
  var importOverlay = {
    "importFrom": "Importar campos de:",
    "toImport": "componente(s) para importar de",
    "page": "página(s)",
    "cancel": "Cancelar",
    "import": "Importar",
    "noComponents": "Nenhum componente para importar!",
    "pastDocs": "Importar campos de documentos antigos",
    "noMatch": "Nenhum documento encontrado que corresponda a este nome",
    "importSuccess": "{{{components}}} componentes importados com sucesso."
  };
  _exports.importOverlay = importOverlay;
});