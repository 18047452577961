define("ember-rs/locales/ja/templates/document-transactions", ["exports", "ember-rs/locales/ja/templates/document-transactions/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.document_transactions = void 0;
  var document_transactions = {
    new: _new.newTranslations,
    "getStarted": "開始する"
  };
  _exports.document_transactions = document_transactions;
});