define("ember-rs/templates/account/users/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/gqevruk",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account-users/delete\",[],[[\"@accountUser\",\"@closeAction\",\"@saveAndCloseAction\",\"@loading\"],[[34,0],[30,[36,1],[\"closeModal\",[35,0]],null],[30,[36,1],[\"saveAndCloseModal\"],null],[34,2]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"route-action\",\"loading\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/account/users/delete.hbs"
    }
  });

  _exports.default = _default;
});