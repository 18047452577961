define("ember-rs/templates/components/reports/generate-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vXg45Ymk",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"ie-warning\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"reports.components.generate-download.ie-warning-text\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"a\"],[15,6,[34,5]],[14,\"download\",\"\"],[12],[2,\"\\n    \"],[10,\"button\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-table\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"reports.components.generate-download.export-csv-button-text\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[4,[38,1],[[32,0],\"generateCSV\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-table\"],[12],[13],[2,\" \"],[1,[30,[36,0],[\"reports.components.generate-download.generate-csv-button-text\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"ie-warning\"],[12],[2,\"\\n    \"],[10,\"br\"],[12],[13],[1,[30,[36,0],[\"reports.components.generate-download.send-download-link\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"downloadLater\",\"if\",\"isIeExplorer\",\"downloadURL\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/reports/generate-download.hbs"
    }
  });

  _exports.default = _default;
});