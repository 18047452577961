define("ember-rs/locales/zh-cn/templates/onboarding/welcome-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding1 = void 0;
  var welcomeOnboarding1 = {
    "welcome": "欢迎使用 RightSignature，",
    "tips": "我们将向您展示各种按钮和操作的位置、如何使用 RightSignature 以及充分利用我们的服务的提示"
  };
  _exports.welcomeOnboarding1 = welcomeOnboarding1;
});