define("ember-rs/locales/it/mixins/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "successUpgrade": "Account aggiornato correttamente. Benvenuti in RightSignature!",
    "dataUnavailable": "I dati al momento non sono disponibili. Si prega di tornare a questa pagina più tardi."
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});