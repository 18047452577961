define("ember-rs/locales/pt-br/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitlementOptionsAdmin = void 0;
  var entitlementOptionsAdmin = {
    "title": "Bem-vindo ao RightSignature!",
    "hasSeats": "Atualmente, você tem privilégios de administrador para usar esta conta. Isso significa que você pode conceder acesso e recursos PRO a si mesmo e/ou a outros usuários. Clique no botão abaixo para conceder esse acesso a você mesmo.",
    "notNow": "Talvez mais tarde",
    "entitleMe": "Obtenha acesso PRO",
    "noSeats": "Todos os assentos desta conta estão sendo usados no momento! Você pode remover alguém, obter mais assentos <a href='https://support.citrix.com/cscase'>entrando em contato com o suporte</a> ou ligando para <a href='tel:+18009214250'>1-800-921-4250</a>, ou você pode simplesmente continuar sem alguns recursos.",
    "proceed": "Continuar"
  };
  _exports.entitlementOptionsAdmin = entitlementOptionsAdmin;
});