define("ember-rs/transforms/moment-date", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Converts centigrade in the JSON to fahrenheit in the app
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return serialized ? (0, _moment.default)(serialized) : serialized;
    },
    serialize: function serialize(deserialized) {
      return deserialized ? (0, _moment.default)(deserialized) : deserialized;
    }
  });

  _exports.default = _default;
});