define("ember-rs/utils/media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasMobileUserAgent = hasMobileUserAgent;
  _exports.PHONE_SIZE = void 0;
  var PHONE_SIZE = 860;
  _exports.PHONE_SIZE = PHONE_SIZE;

  function hasMobileUserAgent() {
    return navigator && navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|Blackberry/i);
  }
});