define("ember-rs/locales/de/templates/onboarding/prepare-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding1 = void 0;
  var prepareOnboarding1 = {
    "useTools": "Verwenden Sie diese Tools für <span>Felder, die Unterzeichner ausfüllen müssen</span>"
  };
  _exports.prepareOnboarding1 = prepareOnboarding1;
});