define("ember-rs/locales/ja/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.application = void 0;
  var application = {
    "logout": "ログアウト",
    "start": "開始日",
    "startDocument": "ドキュメント作成を開始",
    "help": "ヘルプ"
  };
  _exports.application = application;
});