define("ember-rs/mixins/workflow-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var workflowTexts = {
    signatureRequest: 'mixins.workflowType.signatureRequest',
    selfSign: 'mixins.workflowType.selfSign',
    templateSignerLink: 'mixins.workflowType.templateSignerLink',
    inPerson: 'mixins.workflowType.inPerson',
    apiEmbedded: 'mixins.workflowType.apiEmbedded',
    bulkSend: 'mixins.workflowType.bulkSend'
  };

  var _default = Ember.Mixin.create({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    isSelfSign: Ember.computed.equal('workflowType', 'selfSign'),
    // return selfSign, inPerson, signatureRequest, templateSignerLink
    workflowType: Ember.computed('type', 'workflow', 'documentTransaction.workflow', function () {
      if (this.type === 'reusableTemplate') {
        return 'reusableTemplate';
      } else if (Ember.isPresent(Ember.get(this, 'documentTransaction.workflow'))) {
        return Ember.get(this, 'documentTransaction.workflow').camelize();
      } else if (Ember.isPresent(this.workflow)) {
        return this.workflow.camelize();
      } else {
        return null;
      }
    }),
    currentUserIsSender: Ember.computed('currentUser.model.id', 'userId', function () {
      return this.get('currentUser.model.id') === this.userId;
    }),
    workflowText: Ember.computed('workflowType', 'currentUserIsSender', 'senderName', function () {
      if (this.workflowType === 'signatureRequest' && !this.currentUserIsSender) {
        if (this.currentUserIsSigner) {
          return "Sent to you by ".concat(this.senderName).htmlSafe();
        } else {
          return "Sent by ".concat(this.senderName).htmlSafe();
        }
      } else {
        return (this.i18n.t(workflowTexts[this.workflowType]).string || this.workflowType || '').htmlSafe();
      }
    })
  });

  _exports.default = _default;
});