define("ember-rs/locales/nl/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationEditor = void 0;
  var documentExpirationEditor = {
    "theDocExpired": "Het document is verlopen op",
    "reactivated": "Selecteer wanneer u het document wilt laten verlopen. Het wordt dan opnieuw geactiveerd.",
    "theDocExpires": "Het document verloopt momenteel op",
    "newExpiration": "Selecteer wanneer u het document wilt laten verlopen.",
    "updateSuccess": "De vervaldatum is bijgewerkt."
  };
  _exports.documentExpirationEditor = documentExpirationEditor;
});