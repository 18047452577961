define("ember-rs/routes/account/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('accountUser', params.userId);
    },
    actions: {
      closeModal: function closeModal() {
        this.transitionTo('account.users');
      }
    }
  });

  _exports.default = _default;
});