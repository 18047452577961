define("ember-rs/components/decline-document-modal", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['decline-document-modal'],
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    hasDeclineReason: Ember.computed.notEmpty('declineReason'),
    actions: {
      close: function close() {
        this.close();
      },
      decline: function decline() {
        var _this = this;

        this.set('declining', true);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            url: "".concat(_environment.default.api.endpoint, "/signers/").concat(_this.get('signer.id'), "/decline"),
            type: 'POST',
            data: {
              access_token: _this.get('signer').accessToken,
              identity_token: _this.get('signer').identityToken,
              reason_for_decline: _this.declineReason
            }
          }).done(function (response) {
            resolve(response);

            _this.set('declining', false);

            _this.close();

            _this.get('router').transitionTo('unauthorized.document-declined');
          }).fail(function (response) {
            _this.set('declining', false);

            if (response['errors']) {
              _this.flashMessages.warning(response['errors'].join(','));
            } else if (response.status === 308 && response.responseJSON && /Sender is editing the document/i.test(response.responseJSON[0])) {
              _this.close();

              _this.toggleProperty('showSignerAlertOnEditModal');
            } else if (response.responseJSON && response.responseJSON['error']) {
              _this.flashMessages.warning(response['error']);
            } else if (response.responseJSON && response.responseJSON[0]) {
              _this.flashMessages.warning(response.responseJSON[0]);
            } else {
              _this.flashMessages.warning(response);
            }

            reject(response);
          });
        }, 'decline the document');
      }
    }
  });

  _exports.default = _default;
});