define("ember-rs/locales/it/templates/onboarding/post-sign-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding3 = void 0;
  var postSignOnboarding3 = {
    "downloadDoc": "Qui è possibile <span>scaricare</span> il documento."
  };
  _exports.postSignOnboarding3 = postSignOnboarding3;
});