define("ember-rs/locales/nl/templates/components/show-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showComponent = void 0;
  var showComponent = {
    "completed": "Voltooid",
    "pending": "In behandeling",
    "mergefield": "is een samenvoegveld",
    "requiredAssignedTo": "Verplicht veld \"{{{fieldName}}}\" is toegewezen aan <b>{{{assignedToName}}}</b>",
    "optionalAssignedTo": "Optioneel veld \"{{{fieldName}}}\" is toegewezen aan <b>{{{assignedToName}}}</b>"
  };
  _exports.showComponent = showComponent;
});