define("ember-rs/locales/nl/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanDetails = void 0;
  var hybrisPlanDetails = {
    "document-sending": "Documenten verzenden",
    "document-sending-info": "Documenten uploaden en verzenden in gangbare indelingen, zoals PDF, .DOC en meer.",
    "signature": "Handgeschreven handtekeningen",
    "signature-info": "Met uw muis of touchscreen ondertekenen - lijkt exact op uw handtekening op papier.",
    "customer-support": "24/7 klantenondersteuning",
    "customer-support-info": "Ondersteuning wanneer u die nodig hebt van ons bekroonde technische ondersteuningsteam.",
    "email-alert": "E-mailmeldingen",
    "email-alert-info": "Herinneringen voor ondertekenaars en directe statusupdates voor uw documenten.",
    "integration": "Integratie-uitbreidingen",
    "integration-info": "Verbind RightSignature met meer dan twaalf van de populairste apps.",
    "type-sign": "Ondertekenen door typen",
    "type-sign-info": "Sta toe dat ondertekenaars documenten ondertekenen met behulp van een toetsenbord.",
    "mobile": "Ondertekenen op mobiele apparaten",
    "mobile-info": "Onderteken documenten waar u ook bent met uw smartphone of tablet.",
    "guided-signing": "Begeleid ondertekenen",
    "guided-signing-info": "Verminder fouten en weglatingen met eenvoudige, stapsgewijze instructies.",
    "archive": "Beveiligde archivering van documenten",
    "archive-info": "Bewaar uw belangrijke documenten in ons veilige, doorzoekbare archief.",
    "encrypt": "256-bits gegevensversleuteling",
    "encrypt-info": "Beveiliging zoals bij een bank beschermt uw gevoelige documenten tegen lekken en diefstal.",
    "biometric": "Biometrische verificatie",
    "biometric-info": "Verifieer de authenticiteit van uw ondertekenaars aan de hand van belangrijke biometrische gegevens.",
    "fingerprint": "Digitale vingerafdrukken",
    "fingerprint-info": "SHA-1 digitale controlesommen voorkomen dat derden uw document wijzigen.",
    "audit": "Auditlogboek en certificaat",
    "audit-info": "Voor wettelijke naleving wordt elke handeling in een document vastgelegd en beveiligd.",
    "template": "Herbruikbare sjablonen",
    "template-info": "Deel veelgebruikte documenten met uw hele team.",
    "branding": "Aangepaste huisstijl",
    "branding-info": "Voeg uw logo, kleuren en een foto van uzelf toe aan uw RightSignature-documenten.",
    "attachments": "Bijlagen aanvragen",
    "attachments-info": "Sta toe dat ondertekenaars een bestand bijvoegen als onderdeel van het ondertekenen van uw document.",
    "payments": "Betalingen aanvragen",
    "payments-info": "Verwerk betalingen of verzamel creditcardgegevens voor uw documenten.",
    "api": "API voor ontwikkelaars",
    "api-info": "Integreer digitale handtekeningen van RightSignature in uw eigen apps of website.",
    "kba": "Op kennis gebaseerde verificatie (KBA)",
    "kba-info": "Verbeter de naleving van de relevante regelgeving in bepaalde bedrijfstakken.",
    "standard": "Standaard",
    "advanced": "<span>Geavanceerd<span>",
    "advancedKba": "<span>Geavanceerde KBA<span>",
    "sharefilePremium": "<span><p>ShareFile Premium</p> <p> met digitale handtekening</p></span>",
    "annually": "Jaarlijks",
    "quarterly": "Per kwartaal",
    "monthly": "Maandelijks",
    "month": "maand",
    "year": "jaar",
    "quarter": "kwartaal",
    "document-sends-per-user": "100 documentverzendingen per gebruiker",
    "hand-written-text-to-sign": "Handgetekend, tekst-naar-handtekening, klik om akkoord te gaan",
    "bulk-send-for-signature": "Bulkverzending voor ondertekening",
    "sequenced-signing": "In volgorde ondertekenen",
    "knowledge-based-authentication": "Op kennis gebaseerde verificatie",
    "unlimited-document-sends": "Onbeperkt documenten ter ondertekening verzenden",
    "store-sync-share-files": "Bestanden naadloos opslaan, synchroniseren en delen",
    "hipaa-compliant": "Configuratie van HIPAA-naleving",
    "advanced-caption": "Bevat populaire functies zoals:",
    "advanced-kba-caption": "Bevat populaire functies zoals:",
    "sharefile-caption": "BEVAT ALLE DIGITALE HANDTEKENINGFUNCTIES EN MEER MET CITRIX SHAREFILE:",
    "minimum-of": "Minimaal {{{noUsers}}} gebruikers",
    "users": "gebruikers",
    "user": "gebruiker",
    "user-per-month": "per gebruiker/maand",
    "compare-plans-in-detail": "<a href='https://citrix.sharefile.com/share/view/s1493194af0a44866ae95a6a667e7ffcd' style='color:#2071C5' target='_blank'>Abonnementen in detail vergelijken</a>",
    "selected-plan-requires-3-users": "<span style='color:#536E7B'>Het geselecteerde abonnement vereist minimaal 3 gebruikers</span>",
    "more-than-1000-users-contact-sales": "<span style='color:#2071C5'>Als u meer dan 1000 gebruikers nodig hebt, kunt u <a href={{driftLinkId}} style='color: #2071C5; text-decoration: underline; text-transform: lowercase'>chatten met sales. </a></span>",
    "trail-upgrade-msg": "U hebt nog {{{numberOfDaysLeft}}} dagen over van uw proefperiode. Overweeg om uw abonnement nu te upgraden om te profiteren van zorgeloos gebruik zonder onderbrekingen.",
    "savings": "Besparingen",
    "contact-sales": "Chatten met sales",
    "select-plan": "Abonnement selecteren",
    "selected-plan": "Geselecteerd abonnement",
    "best-for-team-collaboration": "Beste aanbieding"
  };
  _exports.hybrisPlanDetails = hybrisPlanDetails;
});