define("ember-rs/components/reports/average-cycle-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultLine = {
    datasets: [{
      label: 'Time to View',
      borderColor: "#FBB03B",
      fill: false,
      lineTension: 0,
      pointBackgroundColor: "#FBB03B",
      pointBorderColor: "#FBB03B",
      pointRadius: 4,
      pointHoverRadius: 5
    }, {
      label: 'Time to Sign',
      borderColor: "#47A459",
      fill: false,
      lineTension: 0,
      pointBackgroundColor: "#47A459",
      pointBorderColor: "#47A459",
      pointRadius: 4,
      pointHoverRadius: 5
    }]
  };
  var setNames = ['time_to_view', 'time_to_sign'];

  var _default = Ember.Component.extend({
    layoutSizeData: Ember.inject.service(),
    chartWidth: Ember.computed('layoutSizeData.useMobileLayout', function () {
      var width = '540';

      if (this.get('layoutSizeData.useMobileLayout')) {
        width = '300';
      }

      return width;
    }),
    chartOptions: {
      datasetFill: false,
      responsive: false,
      legend: {
        display: false
      }
    },
    chartData: Ember.computed('report', function () {
      var output = defaultLine;
      var today = this.get('report.date');
      output.labels = [(0, _moment.default)(today).subtract(3, 'months').format('MMMM'), (0, _moment.default)(today).subtract(2, 'months').format('MMMM'), (0, _moment.default)(today).subtract(1, 'months').format('MMMM'), (0, _moment.default)(today).format('MMMM')];

      for (var i = 0, iMax = setNames.length, setName, data, j, jMax, datum, index; i < iMax; i++) {
        setName = setNames[i];
        data = this.get("report.".concat(setName));
        output.datasets[i].data = [0, 0, 0, 0];

        if (data) {
          for (j = 0, jMax = data.length; j < jMax; j++) {
            datum = data[j];
            index = output.labels.indexOf((0, _moment.default)(datum.date).format('MMMM'));
            output.datasets[i].data[index] = datum.mean_time;
          }
        }
      }

      return output;
    })
  });

  _exports.default = _default;
});