define("ember-rs/locales/ja/templates/reports", ["exports", "ember-rs/locales/ja/templates/reports/export"], function (_exports, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    export: _export.exportTrans,
    "header": "レポート",
    "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
    "select-report-type--text": "レポートの種類を選択",
    "select-report-type-option-overview": "概要",
    "overviewReport": "概要レポート",
    "select-report-type-option-efficiency": "効率性",
    "efficiencyReport": "効率性レポート",
    "select-report-type-option-cycle-time": "サイクル時間レポート",
    "select-report-type-option-export": "データのエクスポート",
    "select-report-type-option-enterprise": "エンタープライズ",
    "enterpriseUsageReport": "エンタープライズ使用状況レポート",
    "userUsageReport": "ユーザー使用状況レポート",
    "select-report-type-option-user": "新しい使用状況レポート",
    "components": {
      "account-document-volume": {
        "header": "レポート",
        "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
        "legend-document-sent": "送信済みドキュメント",
        "legend-document-signed": "署名済みドキュメント",
        "legend": "凡例:"
      },
      "account-statistics": {
        "header": "レポート",
        "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
        "column-header-current-month": "今月",
        "column-header-previous-month": "先月",
        "column-header-average": "平均",
        "row-header-sent-documents": "送信済みドキュメント",
        "row-header-viewed": "閲覧済み (最低 1 人の署名者による)",
        "row-header-expired": "期限切れ",
        "row-header-signed": "署名済み",
        "row-header-sent-documents-per-user": "ユーザーごとの送信済みドキュメント (平均)",
        "row-header-signed-documents": "署名済みドキュメント",
        "row-header-pending-documents": "保留中のドキュメント",
        "row-header-viewed-pending": "閲覧済み (保留中のうち)",
        "none": "使用できません"
      },
      "top-users-by-signed-documents": {
        "header": "レポート",
        "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
        "column-header-user": "ユーザー",
        "column-header-signed-documents": "署名済みドキュメント"
      },
      "account-efficiency": {
        "header": "レポート",
        "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
        "legend-pending": "保留",
        "legend-signed": "署名済み",
        "legend-expired": "期限切れ"
      },
      "top-users-by-efficiency": {
        "header": "レポート",
        "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
        "column-header-user": "ユーザー",
        "column-header-efficiency-percentage": "効率性 %",
        "column-header-ratio": "比率",
        "over-gcd": "{{signed}} / {{sent}}"
      },
      "top-users-by-time-to-sign": {
        "header": "レポート",
        "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
        "column-header-user": "ユーザー",
        "column-header-mean-time": "平均時間 (時間)"
      },
      "average-cycle-time": {
        "header": "レポート",
        "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
        "legend-mean-time-to-view": "平均閲覧時間",
        "legend-mean-time-to-sign": "平均署名時間"
      },
      "cycle-time-statistics": {
        "header": "レポート",
        "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
        "column-header-current-month": "今月",
        "column-header-previous-month": "先月",
        "column-header-all-time": "常時",
        "row-header-under-5-mins": "5 分未満",
        "row-header-under-30-mins": "30 分未満",
        "row-header-under-1-hour": "1 時間未満",
        "row-header-under-12-hours": "12 時間未満",
        "row-header-under-1-day": "1 日未満"
      },
      "month-export": {
        "header": "レポート",
        "description": "送信済みドキュメントのデータを表示します。このデータには、通常ドキュメントの署名にかかる時間と、月ごとに完了したドキュメントの数が含まれます。レポートを利用するには、プランをアップグレードしてください。",
        "export-to-csv-section-header": "CSV にエクスポート",
        "export-to-csv-section-description": "CSV ファイルを Excel で開いて、送信済みドキュメントのデータを表示できます。"
      },
      "generate-download": {
        "ie-warning-text": "CSV をダウンロードするには、[CSV をエクスポート] を右クリックし、名前を付けて保存を選択してください。",
        "export-csv-button-text": "CSV をエクスポート",
        "generate-csv-button-text": "CSV を生成",
        "send-download-link": "レポートを生成し、ダウンロード リンクをメールで送信します。"
      }
    }
  };
  _exports.reports = reports;
});