define("ember-rs/locales/it/templates/components/document-upload-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploadThumbnail = void 0;
  var documentUploadThumbnail = {
    "noThumb": "Nessuna miniatura...",
    "upload_done": "caricamento eseguito",
    "downloading": "download",
    "storing": "archiviazione",
    "converting_to_images": "conversione a immagini",
    "converting_to_pdf": "conversione a pdf",
    "processing": "in elaborazione",
    "created": "creato"
  };
  _exports.documentUploadThumbnail = documentUploadThumbnail;
});