define("ember-rs/locales/de/routes/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTransaction = void 0;
  var documentTransaction = {
    "disabledPaymentIntegration": "Sie müssen eine Zahlungsintegration aktivieren, bevor Sie die folgenden Dokumente senden: {{names}}",
    "disabledPasscode": "Sie müssen als Passcode eine PIN festlegen, um folgende Dokumente zu senden: {{names}}",
    "successfulDocCreation": "Dokumente wurden erfolgreich erstellt."
  };
  _exports.documentTransaction = documentTransaction;
});