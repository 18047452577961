define("ember-rs/templates/document/prepare-signers-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mL/Ob9BB",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[10,\"div\"],[14,0,\"transaction-manager-header\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[1,[30,[36,2],[\"document.prepare_signers_mobile.assign_roles\"],null]],[13],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"title\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[24,0,\"close\"],[4,[38,3],[[32,0],\"transitionToRoot\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-times\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"transaction-step signers\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"add_signers\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"signers-list\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[2,\"\\n          \"],[8,\"assign-reusable-template-role\",[],[[\"@role\",\"@documentTemplate\",\"@contactSearchingEndpoints\"],[[32,1],[34,0],[34,1]]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"footer\"],[12],[2,\"\\n      \"],[8,\"rs-button\",[],[[\"@action\",\"@isDisabled\"],[[30,[36,7],[\"nextStep\"],null],[34,8]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[34,9]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"contactSearchingEndpoints\",\"t\",\"action\",\"sortedRoles\",\"-track-array\",\"each\",\"route-action\",\"isNotValid\",\"nextRouteText\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/document/prepare-signers-mobile.hbs"
    }
  });

  _exports.default = _default;
});