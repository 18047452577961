define("ember-rs/locales/de/templates/components/document-overlayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentOverlayer = void 0;
  var documentOverlayer = {
    "prepare": "Dokument vorbereiten",
    "tools": "erweiterte Tools: ",
    "importOverlay": "Überlagerung importieren",
    "toolTip": "Wählen Sie ein Dokument aus, das zuvor gesendet wurde, um die Felder an der gleichen Stelle in diesem Dokument zu platzieren. Hinweis: Das Dokument muss denselben Namen haben.",
    "fieldDetection": "Felderkennung",
    "fieldDetectionTip": "Mit der Felderkennung können Sie Felder genauer an Linien und Kästchen in Ihrem Dokument ausrichten.",
    "roles": "Rollen",
    "rolesTip": "Zeigen Sie die Rollen für das Dokument an. Jede Rolle hat ihre eigene Farbe, die den Feldern entspricht, die ausgefüllt werden müssen.",
    "signers": "Unterzeichner",
    "signersTip": "Zeigen Sie die Unterzeichner des Dokuments an. Jeder Unterzeichner hat seine eigene Farbe, die den Feldern entspricht, die ausgefüllt werden müssen.",
    "attachmentUnavailable": "Anlagen sind für das ausgewählte Konto nicht verfügbar. Aktualisieren Sie auf ein Pro-Abonnement, um Anlagen von Unterzeichnern anzufordern.",
    "paymentUnavailable": "Zahlungen sind für das ausgewählte Konto nicht verfügbar. Bitte aktualisieren Sie auf ein Pro-Abonnement, um Zahlungen von Unterzeichnern einzuziehen.",
    "paymentNotEnabled": "Bitte aktivieren Sie eine Zahlungsintegration, bevor Sie Zahlungskomponenten verwenden. Gehen Sie dazu in den Kontoeinstellungen zu den Integrationen.",
    "noRoles": "Keine Rollen gefunden! Haben Sie ohne zu speichern neu geladen?"
  };
  _exports.documentOverlayer = documentOverlayer;
});