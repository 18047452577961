define("ember-rs/templates/components/document-limit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lOz1PN5I",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"rs-modal\",[],[[\"@show\",\"@action\",\"@class\",\"@centered\",\"@dismissable\"],[[30,[36,1],[[35,0]],null],[30,[36,4],[[32,0],[30,[36,3],[[35,2]],null],true],null],\"document-limit-modal\",true,[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,1,[[35,7],[35,6]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"showing\",\"readonly\",\"dismissed\",\"mut\",\"action\",\"dismissable\",\"used\",\"admin\",\"documentLimitExceeded\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/document-limit-modal.hbs"
    }
  });

  _exports.default = _default;
});