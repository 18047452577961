define("ember-rs/locales/pt-br/templates/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "share": "Compartilhar",
    "status": "Status",
    "overview": "Visão geral",
    "sent": "enviado",
    "people_involved": "Pessoas envolvidas:",
    "reference_id": "ID de referência: ",
    "manage": "Gerenciar",
    "tags": "Marcas: "
  };
  _exports.details = details;
});