define("ember-rs/locales/ja/templates/components/account-user", ["exports", "ember-rs/locales/ja/templates/components/account-user/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountUser = void 0;
  var accountUser = {
    edit: _edit.edit
  };
  _exports.accountUser = accountUser;
});