define("ember-rs/routes/reusable-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      // Fetches reusableTemplate from server to load Components
      return this.store.findRecord('reusableTemplate', params.reusable_template_id);
    },
    beforeModel: function beforeModel() {
      if (!Ember.get(this, 'currentUser.canSendDocuments') && !Ember.get(this, 'currentUser.canManageTemplates')) {
        this.transitionTo('templates');
        return;
      }
    },
    afterModel: function afterModel(model) {
      // Set documentTransaction to parent route
      model.set('documentTransaction', this.modelFor('document-transaction'));
    },
    actions: {
      save: function save() {
        this.currentModel.save().then(function () {// notify the user that something happened?
        }, function (error) {
          throw "Reusable template was not saved. Handle this in the frontend. ".concat(error.message);
        });
      }
    }
  });

  _exports.default = _default;
});