define("ember-rs/routes/client-applications", ["exports", "ember-cli-pagination/remote/route-mixin", "lodash"], function (_exports, _routeMixin, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    queryParams: {
      account_id: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: false
      }
    },
    page: 1,
    beforeModel: function beforeModel() {
      if (!Ember.get(this.modelFor('account'), 'api')) {
        return this.transitionTo('/not-found');
      }
    },
    model: function model(params) {
      params.page = parseInt(params.page || 1);

      var isNotPresent = function isNotPresent(value) {
        return Ember.isNone(value) || Ember.isBlank(value) || value === 'null';
      };

      Ember.set(this, 'account_id', Ember.get(this, 'currentUser.account.id'));
      return this.findPaged('client-application', _lodash.default.omit(params, isNotPresent));
    },
    actions: {
      willTransition: function willTransition() {
        Ember.get(this.controller, 'model').forEach(function (clientApplication) {
          if (Ember.get(clientApplication, 'secret') || Ember.get(clientApplication, 'privateToken')) {
            clientApplication.setProperties({
              secret: null,
              privateToken: null,
              hasGeneratedToken: false
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});