define("ember-rs/locales/es/templates/signer/sign/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identify = void 0;
  var identify = {
    "advisory": "El remitente de este documento ha habilitado la función de autenticación basada en conocimientos de RightSignature para verificar su identidad. RightSignature emplea servicios externos de BlockScore para validar y verificar la información personal proporcionada. El uso de la información personal proporcionada se limita a la validación y verificación de la identidad con los registros de datos propiedad de BlockScore. La validación y verificación de la identidad no tendrán efecto en su informe crediticio, su calificación crediticia ni en informes para proveedores de préstamos. Ni IRS, ni BlockScore ni RightSignature tendrán la capacidad de ver o acceder a su informe crediticio o su información fiscal como parte de este proceso.",
    "step1": "Verificación de identidad - Paso 1 de 2",
    "beforeSigning": "Antes de firmar el documento, introduzca su información personal para ayudarnos a verificar su identidad.",
    "notValid": "Lo sentimos, no pudimos encontrar una identidad válida que coincida con la información proporcionada. Asegúrese de que la información a continuación sea correcta y haga clic en Siguiente para volver a intentarlo.",
    "youHave": "Le quedan ",
    "remaining": " intentos para acceder al documento.",
    "name": "Nombre:",
    "dob": "Fecha de nacimiento:",
    "ssn": "4 últimos dígitos del número de la SS:",
    "passport": "Número de pasaporte:",
    "address": "Dirección:",
    "address2": "Dirección 2:",
    "city": "Ciudad:",
    "state": "Estado:",
    "zip": "Código postal:",
    "country": "País:",
    "return": "Volver al formulario",
    "advisory2": "Aviso de verificación de identidad",
    "sampleAddress1": "120 S. West St.",
    "sampleAddress2": "Suite 100",
    "sampleAddress3": "Raleigh",
    "submit": "Enviar"
  };
  _exports.identify = identify;
});