define("ember-rs/routes/document-transaction/redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    documentsStillArchiving: Ember.computed.gt('archvingDocuments.[].length', 0),
    documentsUnarchived: function documentsUnarchived(transaction) {
      return Ember.get(transaction, 'documents').then(function (documents) {
        return documents.filterBy('archivedToOrigin', false);
      });
    },
    redirectToSharefile: function redirectToSharefile(documents) {
      var sharefileRedirect = this.get('session.data.sharefileRedirect');
      var redirectUrl = sharefileRedirect.redirectUrl;
      this.session.set('data.sharefileRedirect', null);
      var documentIds = documents.mapBy('id');
      var documentIdQueryParams = documentIds.map(function (id) {
        return "document_ids[]=" + id;
      }).join("&");
      var queryDelimiter = "?";

      if (redirectUrl.match(/\?/)) {
        queryDelimiter = "&";
      }

      window.location = redirectUrl + queryDelimiter + documentIdQueryParams;
    },
    shutdownPolling: function shutdownPolling() {
      var poller = this.poller;

      if (poller) {
        Ember.set(this, 'poller', null);
        Ember.run.cancel(poller);
      }
    },
    poll: function poll(model) {
      var _this = this;

      var transaction = model || this.currentModel;
      var poller = this.poller;
      var promises = Ember.A();
      this.documentsUnarchived(transaction).then(function (resolvedDocuments) {
        if (Ember.get(resolvedDocuments, 'length') < 1) {
          _this.redirectToSharefile(resolvedDocuments);
        } else {
          promises = resolvedDocuments.map(function (document) {
            return document.reload();
          });
        }

        if (transaction) {
          Ember.RSVP.allSettled(promises, 'reload documents for archive state').then(function () {
            if (poller) {
              _this.shutdownPolling();
            }

            Ember.set(_this, 'poller', Ember.run.later(_this, _this.poll, 1000));
          });
        }
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      if (model.get("isSelfSign")) {
        return this.documentsUnarchived(model).then(function () {
          return _this2.poll(model);
        });
      } else {
        return this.redirectToSharefile(model.get("documents"));
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.shutdownPolling();
      }
    }
  });

  _exports.default = _default;
});