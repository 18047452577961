define("ember-rs/locales/fr/templates/onboarding/signer-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding2 = void 0;
  var signerOnboarding2 = {
    "submit": "Affichez la progression de votre document. Le bouton <span>Envoyer</span> apparaît lorsque vous avez rempli tous les champs."
  };
  _exports.signerOnboarding2 = signerOnboarding2;
});