define("ember-rs/locales/pt-br/templates/components/document/signer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerRow = void 0;
  var signerRow = {
    "pendingIdentityAuthentication": "Autenticação de identidade pendente",
    "sign": "Assinar",
    "disabledReminder": "Este signatário desativou mais lembretes para este documento",
    "threshold": "Você excedeu o limite de envio de lembrete (3 por documento)",
    "signerFailed": "This signer has failed ",
    "currentlyLocked": "and is currently locked out of the document.",
    "signerLocked": "Este signatário não foi aprovado na autenticação baseada em conhecimento e o seu acesso ao documento está bloqueado.",
    "remind": "Lembrete",
    "resend": "Reenviar",
    "createLink": "Criar link",
    "copyLink": "Copiar link",
    "copyLinkSuccess": "O link foi copiado para a área de transferência",
    "copyLinkError": "Falha ao copiar o link para a área de transferência",
    "signerAuthFailed": "Falha ao autenticar o signatário via sessão: {{{error}}}",
    "reminderSuccess": "Relembrado com sucesso {{{name}}}",
    "reminderFailed": "Falha ao gerar lembrete para {{{name}}}",
    "generateLinkFailed": "Falha ao gerar o link do signatário: {{{error}}}",
    "resendIdentityVerification": "Reenviar verificação de identidade",
    "resendingFailed": "Falha ao reenviar a verificação de identidade: {{{error}}}"
  };
  _exports.signerRow = signerRow;
});