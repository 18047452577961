define("ember-rs/templates/onboarding/signer-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hl4i4XIK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"onboarding-container signer\"],[15,5,[30,[36,1],[[35,0,[\"onboardingContentStyle\"]]],null]],[12],[2,\"\\n  \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,2],1,\"onboarding/signer-onboarding-1\",\"nextStep\",[30,[36,3],[\"onboarding.signerOnboarding.gotIt\"],null]]],null],[2,\"\\n\\n  \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,2],2,\"onboarding/signer-onboarding-2\",\"nextStep\",[30,[36,3],[\"onboarding.signerOnboarding.gotIt\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"layoutSizeData\",\"safe\",\"currentStep\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/signer-onboarding.hbs"
    }
  });

  _exports.default = _default;
});