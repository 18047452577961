define("ember-rs/controllers/reusable-template/edit", ["exports", "ember-rs/mixins/reusable-template-choose-document", "ember-rs/mixins/integration-documents-list"], function (_exports, _reusableTemplateChooseDocument, _integrationDocumentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reusableTemplateChooseDocument.default, _integrationDocumentsList.default, {
    authorizationOrigin: Ember.computed('model', function () {
      return {
        route: 'reusable-template.edit',
        id: this.get('model.id')
      };
    })
  });

  _exports.default = _default;
});