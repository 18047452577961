define("ember-rs/locales/zh-cn/templates/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateSelector = void 0;
  var reusableTemplateSelector = {
    "noTemplates": "您还没有任何模板。",
    "templateDesc": "根据常用文档创建可重用的模板。快速发送、嵌入到您的站点中或批量分发。",
    "createNew": "创建新模板",
    "chooseTemplate": "选择模板",
    "loading": "正在加载...",
    "loadMore": "加载更多模板...",
    "upgrade": "升级您的帐户以使用模板",
    "upgradeAccount": "升级帐户",
    "bulkSendInfo": "批量发送仅适用于单个签名者模板。首次使用批量发送吗?",
    "learnMore": "了解更多",
    "searchTemplate": "搜索模板",
    "noResults": "无结果!"
  };
  _exports.reusableTemplateSelector = reusableTemplateSelector;
});