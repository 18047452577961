define("ember-rs/locales/it/controllers/document/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "success": "Sbloccati tutti i firmatari",
    "error": "Impossibile sbloccare i firmatari, riprovare in un secondo momento."
  };
  _exports.unlock = unlock;
});