define("ember-rs/locales/de/templates/components/plan-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planInfo = void 0;
  var planInfo = {
    "perMonth": "pro Monat",
    "teamMember": "Teammitglied",
    "teamMembers": "Teammitglieder",
    "call": "Rufen Sie uns an: 1-855-895-4862",
    "selectedPlan": "Ausgewähltes Abonnement",
    "selectPlan": "Abonnement auswählen"
  };
  _exports.planInfo = planInfo;
});