define("ember-rs/locales/ja/templates/unauthorized/document-executed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExecuted = void 0;
  var documentExecuted = {
    "docComplete": "このドキュメントは完了しました",
    "toDownload": "このドキュメントをダウンロードするには、新しいダウンロード リンクを要求します。",
    "sendLink": "新しいダウンロード リンクを送信する"
  };
  _exports.documentExecuted = documentExecuted;
});