define("ember-rs/controllers/transaction-manager-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layoutSizeData: Ember.inject.service(),
    attributeBindings: ['style'],
    classNameBindings: [':transaction-manager', 'chooseType'],
    // pass in true to add class to target the 'start' step
    chooseType: false,
    style: Ember.computed('layoutSizeData.windowWidth', 'layoutSizeData.windowHeight', function () {
      var width = this.get('layoutSizeData.windowWidth');
      var height = this.get('layoutSizeData.windowHeight');
      return "width: ".concat(width, "px; height: ").concat(height, "px;").htmlSafe();
    })
  });

  _exports.default = _default;
});