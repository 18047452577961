define("ember-rs/locales/fr/templates/document/details", ["exports", "ember-rs/locales/fr/templates/document/details/unlock"], function (_exports, _unlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    unlock: _unlock.unlock,
    "status": "État",
    "overview": "Présentation",
    "history": "Historique",
    "people_involved": "Personnes impliquées :",
    "manage": "Gérer",
    "security_options": "Options de sécurité :",
    "declined": "Refusée",
    "expired": "Expiré",
    "expires": "Expire",
    "extend": "Étendre",
    "pin": "PIN *****",
    "generate_pin": "Générer nouveau code PIN",
    "pin_tip": "Si vous générez un nouveau code PIN, le code PIN actuel ne sera plus valide.",
    "passcode_question": "Question de code d'accès :",
    "clear_attempts": "Effacer tentatives de saisie du code d'accès",
    "passcode": "Code d'accès",
    "signer_sequencing": "Séquence de signature",
    "lock_message": "Ce document a été verrouillé.",
    "attachments": "Pièces jointes :",
    "reference_id": "ID de référence : ",
    "tags": "Balises : ",
    "remind_header": "Lien de signature envoyé",
    "remind_body": "Nous avons envoyé le lien pour signer ce document à l’adresse de courriel ci-dessous. Pour ignorer cette étape dans le futur vous pouvez vérifier cette adresse e-mail sur votre page de réglage.",
    "new_pin": "Nouveau code PIN",
    "new_passcode": "Voici votre nouveau code PIN. Vous devez envoyer ce code PIN mis à jour aux signataires du document afin qu'ils puissent y accéder.",
    "done": "Terminé",
    "draft": "Brouillon",
    "pending": "En attente",
    "voided": "Annulé",
    "executed": "Exécuté",
    "recipient_message": "Message aux destinataires :",
    "void_reason": "Reason for Voiding:",
    "document_voided": "Document Voided:",
    "document_declined": "Decline Reason:"
  };
  _exports.details = details;
});