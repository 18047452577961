define("ember-rs/locales/it/mixins/integration-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocuments = void 0;
  var integrationDocuments = {
    "enable_sending": "Invio abilitato correttamente per {{{provider}}}",
    "enable_archiving": "Archiviazione abilitata correttamente per {{{provider}}}",
    "enable_contacts": "Contatti abilitati correttamente per {{{provider}}}",
    "enable_payments": "Pagamenti abilitati correttamente per {{{provider}}}",
    "disable_sending": "Invio disabilitato correttamente per {{{provider}}}",
    "disable_archiving": "Archiviazione disabilitata correttamente per {{{provider}}}",
    "disable_contacts": "Contatti disabilitati correttamente per {{{provider}}}",
    "disable_payments": "Pagamenti disabilitati correttamente per {{{provider}}}",
    "errorToggle": "Errore. Impossibile modificare {{{service}}} per {{{provider}}}!",
    "errorRetrieve": "Errore. Impossibile recuperare il documento.",
    "errorProviderRetrieve": "Errore. Impossibile recuperare i documenti di {{{provider}}}."
  };
  _exports.integrationDocuments = integrationDocuments;
});