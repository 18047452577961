define("ember-rs/routes/sign-out", ["exports", "ember-rs/mixins/invalidate-and-sign-out"], function (_exports, _invalidateAndSignOut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_invalidateAndSignOut.default, {
    beforeModel: function beforeModel() {
      return this.invalidateAndSignOut();
    }
  });

  _exports.default = _default;
});