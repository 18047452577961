define("ember-rs/locales/es/templates/components/support-when-externally-billed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportWhenExternallyBilled = void 0;
  var supportWhenExternallyBilled = {
    "partnerSale": "Su cuenta se creó a través de la venta de un socio. Póngase en contacto con asistencia si quiere ampliar su cuenta."
  };
  _exports.supportWhenExternallyBilled = supportWhenExternallyBilled;
});