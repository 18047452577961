define("ember-rs/locales/nl/templates/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerPaymentDetails = void 0;
  var signerPaymentDetails = {
    "paymentInfo": "Betalingsgegevens",
    "paymentID": "Betalings-id:",
    "retryPayment": "Nieuwe betalingspoging...",
    "failed": "Mislukt",
    "chargedSuccess": "In rekening gebracht op {{chargedAt}}.",
    "stripeCustomer": "Klant van Stripe",
    "stripeCharge": "Kosten van Stripe",
    "attempted": "Laatste poging op {{chargedAt}}.",
    "retry": "Opnieuw proberen",
    "pending": "In behandeling...",
    "success": "Nieuwe betalingspoging is geslaagd.",
    "retryError": "Nieuwe betalingspoging is mislukt ({{{errorMsg}}})."
  };
  _exports.signerPaymentDetails = signerPaymentDetails;
});