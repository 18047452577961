define("ember-rs/locales/it/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationEditor = void 0;
  var documentExpirationEditor = {
    "theDocExpired": "Il documento è scaduto il",
    "reactivated": "Selezionare la data in cui si desidera che scada e verrà riattivato.",
    "theDocExpires": "Attualmente il documento scade il",
    "newExpiration": "Selezionare la data in cui si desidera che scada.",
    "updateSuccess": "Data di scadenza aggiornata correttamente."
  };
  _exports.documentExpirationEditor = documentExpirationEditor;
});