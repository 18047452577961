define("ember-rs/services/search", ["exports", "moment", "uuid", "ember-rs/services/tracking"], function (_exports, _moment, _uuid, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOrStartSession = _getOrStartSession;
  _exports.startSearchSession = _startSearchSession;
  _exports.endSearchSession = _endSearchSession;
  _exports.getSearchSession = _getSearchSession;
  _exports.Session = _exports.SessionEnd = _exports.default = void 0;
  var _excluded = ["_searchSessionEndingType"];

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    tracking: Ember.inject.service(),
    getOrStartSession: function getOrStartSession() {
      var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return _getOrStartSession(properties);
    },
    getSearchSession: function getSearchSession() {
      return _getSearchSession();
    },
    startSearchSession: function startSearchSession() {
      var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return _startSearchSession(properties);
    },
    endSearchSession: function endSearchSession() {
      var searchSessionEndingType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return _endSearchSession(searchSessionEndingType);
    }
  });

  _exports.default = _default;
  var SessionEnd = {
    RESULT_OPENED_MODAL: 'result-opened-modal',
    RESULT_OPENED_ADVANCED: 'result-opened-advanced'
  };
  _exports.SessionEnd = SessionEnd;
  var Session = {
    CONVERTED: 'converted',
    CANCELLED: 'cancelled'
  };
  _exports.Session = Session;

  var _singletonSession;

  function _getOrStartSession() {
    var _singletonSession2;

    var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var hasSession = !!_singletonSession;
    var hasSessionAlreadyEnded = ((_singletonSession2 = _singletonSession) === null || _singletonSession2 === void 0 ? void 0 : _singletonSession2._searchSessionEndingType) !== undefined;

    if (!hasSession || hasSessionAlreadyEnded) {
      return _startSearchSession(properties);
    } else {
      return _getSearchSession();
    }
  }

  function _startSearchSession() {
    var properties = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    _singletonSession = {
      searchSessionId: (0, _uuid.v4)(),
      searchSessionStartTime: _moment.default.utc().toISOString()
    };
    (0, _tracking.trackEvent)('@citrix/search/startSession', _objectSpread(_objectSpread(_objectSpread({}, properties), _getSearchSession()), {}, {
      timestamp: _moment.default.utc().toISOString()
    }));
    return _getSearchSession();
  }

  function _endSearchSession() {
    var _singletonSession3;

    var searchSessionEndingType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var hasSession = !!_singletonSession;
    var hasSessionAlreadyEnded = ((_singletonSession3 = _singletonSession) === null || _singletonSession3 === void 0 ? void 0 : _singletonSession3._searchSessionEndingType) !== undefined;

    if (!hasSession || hasSessionAlreadyEnded) {
      // if we don't actually have a session or it has already "ended" per our heuristic... just return.
      // follow up events will still get the session id (if existing) but we won't fire another end event.
      return _getSearchSession();
    }

    _singletonSession = _objectSpread(_objectSpread({}, _singletonSession), {}, {
      _searchSessionEndingType: searchSessionEndingType
    });

    var oldSession = _getSearchSession();

    var elapsedDuration = _moment.default.duration(_moment.default.utc().diff((0, _moment.default)(oldSession.searchSessionStartTime)));

    var endSessionName = '@citrix/search/endSession';

    if (searchSessionEndingType) {
      var isConversion = searchSessionEndingType === SessionEnd.RESULT_OPENED_ADVANCED || searchSessionEndingType === SessionEnd.RESULT_OPENED_MODAL;
      endSessionName += isConversion ? "-".concat(Session.CONVERTED) : "-".concat(Session.CANCELLED);
    }

    (0, _tracking.trackEvent)(endSessionName, _objectSpread(_objectSpread({}, oldSession), {}, {
      timestamp: _moment.default.utc().toISOString(),
      searchSessionElapsedSeconds: elapsedDuration.asSeconds()
    }, searchSessionEndingType && {
      searchSessionEndingType: searchSessionEndingType
    }));
    return oldSession;
  }

  function _getSearchSession() {
    if (!_singletonSession) {
      return null;
    } // Omit the private "ending type" property.
    // eslint-disable-next-line no-unused-vars


    var _singletonSession4 = _singletonSession,
        _searchSessionEndingType = _singletonSession4._searchSessionEndingType,
        session = _objectWithoutProperties(_singletonSession4, _excluded);

    return session;
  }
});