define("ember-rs/locales/fr/templates/onboarding/post-sign-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding3 = void 0;
  var postSignOnboarding3 = {
    "downloadDoc": "C'est ici que vous pouvez <span>télécharger</span> le document."
  };
  _exports.postSignOnboarding3 = postSignOnboarding3;
});