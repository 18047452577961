define("ember-rs/locales/en/templates/components/review-and-send-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewAndSendItem = void 0;
  var reviewAndSendItem = {
    "passcode-message": "Don't forget! You have to send this PIN to the document's signers.",
    "bulk-send-passcode-message": "You have enabled passcode for document transactions. Bulk send does not support this currently.",
    "generate-new-pin": "Generate new PIN",
    "pin-specification": "PIN must be a five digit number.",
    "expiration-message": "After the expiration date the document will no-longer be available to sign.",
    "ExpiresIn": "Expires in",
    "days": "days",
    "loading": "loading",
    "page": "page(s)",
    "emailTip1": "A link to view this document will be sent to {{{email}}}.",
    "emailTip2": "The recipient will be able to view and download this document.",
    "enterEmail": "Enter email address",
    "shareDoc": "Enter email address to share this document",
    "ccMsg": "CC parties to give them read only access to this document. They will receive notifications, will be able to view progress, and download the completed document. You can revoke their access later if needed.",
    "add": "add",
    "roles": "roles",
    "recipients": "recipients",
    "cc": "cc: ",
    "ccCaps": "CC",
    "messageLimit": "Message cannot be larger than 100000 characters.",
    "addMsg": "Add a personalized message...",
    "for": "for",
    "message": "message",
    "pin": "pin",
    "tags": "tags",
    "passcodeOn": "Passcode On",
    "passcodeOff": "Passcode Off",
    "restrictedFeatureMsg": "Your current plan does not allow you to add passcodes to documents. Upgrade your plan to access this feature.",
    "kba": "KBA",
    "on": "On",
    "off": "Off",
    "kbaTip": "Require signers to verify their identity with a knowledge-based quiz. Each signer will need to independently verify before signing the document. Signers will be locked out of the document after 3 failed attempts.",
    "enable": "Enable",
    "addTag": "add tag",
    "tagTip": "Add tags to this document to find it easily later. You can also add values to tags e.g. \"client_id: 1234\"",
    "addSalesforceOpportunity": "Add Salesforce Opportunity",
    "addSalesforceOpportunityMsg": "Add a Salesforce Opportunity to this document",
    "exceededKbaLimit": "KBA Limit Reached",
    "exceededKbaMsg": "You have reached your current subscription's monthly limit for KBA-enabled documents. To send more documents with the Knowledge-Based Authentication feature enabled, please <a href='https://support.citrix.com/cscase'>contact support</a> to upgrade your account.",
    "tagName": "Tag name...",
    "tagValue": "Value (optional)",
    "sendTo": "Send to",
    "name": "Name",
    "docName": "Document Name...",
    "totalNumberOfSigners": "Total number of Signers:",
    "expiresIn": "Expires in must be between 1 and 365 days."
  };
  _exports.reviewAndSendItem = reviewAndSendItem;
});