define("ember-rs/locales/en/templates/onboarding/send-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding3 = void 0;
  var sendOnboarding3 = {
    "remind": "Select <span>Remind</span> to prompt signers again.",
    "void": "Select <span>Void</span> to cancel the document."
  };
  _exports.sendOnboarding3 = sendOnboarding3;
});