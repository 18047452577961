define("ember-rs/locales/es/templates/document-transactions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTranslations = void 0;
  var newTranslations = {
    "create_new": "Creando nueva transacción..."
  };
  _exports.newTranslations = newTranslations;
});