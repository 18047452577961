define("ember-rs/locales/en/templates/components/document-download-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDownloadOptions = void 0;
  var documentDownloadOptions = {
    "originalPDF": "Original PDF",
    "completedPDF": "Completed PDF",
    "overlay": "Printable Overlay PDF",
    "failed": "Completed PDF (Failed)",
    "support": "There was a problem processing the document. You should contact support.",
    "cert": "Signature Certificate",
    "mergedPDF": "Combined PDF"
  };
  _exports.documentDownloadOptions = documentDownloadOptions;
});