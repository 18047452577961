define("ember-rs/locales/ja/templates/components/document/signer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerRow = void 0;
  var signerRow = {
    "pendingIdentityAuthentication": "保留中の ID 確認",
    "sign": "署名",
    "disabledReminder": "この署名者に対する以降のこのドキュメントの通知が無効になりました",
    "threshold": "送信通知のしきい値 (ドキュメントごとに 3 通) に達しました",
    "signerFailed": "この署名者による次の処理が失敗しました:  ",
    "currentlyLocked": "現在ドキュメントからロックアウトされています。",
    "signerLocked": "この署名者はナレッジベース認証に失敗し、現在このドキュメントからロックアウトされています。",
    "remind": "通知",
    "resend": "再送信",
    "createLink": "リンクの作成",
    "copyLink": "リンクのコピー",
    "copyLinkSuccess": "クリップボードにリンクがコピーされました",
    "copyLinkError": "クリップボードへのリンクのコピーに失敗しました",
    "signerAuthFailed": "セッション経由での署名者の認証に失敗しました： {{{error}}}",
    "reminderSuccess": "{{{name}}} に通知しました",
    "reminderFailed": "{{{name}}} への通知を生成できませんでした",
    "generateLinkFailed": "署名者リンクの生成に失敗しました: {{{error}}}",
    "resendIdentityVerification": "再送信された ID 確認",
    "resendingFailed": "ID 確認の再送信に失敗しました: {{{error}}}"
  };
  _exports.signerRow = signerRow;
});