define("ember-rs/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lEsZeM3c",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"not_found\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"notice\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"notFound.doesNotExist\"],null]],[13],[2,\"\\n\\n    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"notFound.didYou\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"notFound.anotherPart\"],null]],[13],[2,\"\\n\\n    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"notFound.followLink\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"notFound.otherSite\"],null]],[13],[2,\"\\n\\n    \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"notFound.typeUrl\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"notFound.doubleCheck\"],null]],[13],[2,\"\\n\\n    \"],[8,\"link-to\",[[24,0,\"button\"]],[[\"@route\"],[\"documents\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"notFound.home\"],null]]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});