define("ember-rs/locales/it/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTagsEditor = void 0;
  var documentTagsEditor = {
    "addTags": "Aggiungere contrassegni a questo documento per trovarlo facilmente in un secondo momento. È anche possibile aggiungere valori ai contrassegni, ad esempio \"id_client: 1234\"",
    "tagName": "Nome contrassegno...",
    "optional": "Valore (facoltativo)"
  };
  _exports.documentTagsEditor = documentTagsEditor;
});