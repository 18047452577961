define("ember-rs/controllers/document-transaction/review", ["exports", "ember-rs/mixins/distribution-method"], function (_exports, _distributionMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_distributionMethod.default, {
    layoutSizeData: Ember.inject.service(),
    saving: false,
    hasMessageLengthError: false,
    showBulkSendConfirmationModal: false,
    isValid: Ember.computed('model.documents.@each.isReviewStepValid', function () {
      var valid = true;
      this.get('model.documents').forEach(function (doc) {
        if (!doc.get('isReviewStepValid')) {
          valid = false;
          return;
        }
      });
      return valid;
    }),
    isNotValid: Ember.computed.not('isValid'),
    distributionMethod: Ember.computed('model', function () {
      if (Ember.isPresent(this.get('model.documents'))) {
        // documentTransaction
        return this.get('model.documents').objectAt(0).get('distributionMethod');
      } else {
        return 'email'; // default
      }
    }),
    isSelfSign: Ember.computed('model.workflow', function () {
      return this.get('model.workflow') === 'self_sign';
    }),
    isBulkSend: Ember.computed('model.workflow', function () {
      return this.get('model.workflow') === 'bulk_send';
    }),
    identityMethod: Ember.computed('model', function () {
      if (Ember.isPresent(this.get('model.documents'))) {
        // documentTransaction
        return this.get('model.documents').objectAt(0).get('identityMethod');
      } else {
        return 'email'; // default
      }
    }),
    proxyDistributionMethod: Ember.observer('distributionMethod', function () {
      var self = this;
      this.get('model.documents').forEach(function (doc) {
        doc.set('distributionMethod', self.get('distributionMethod'));
      });
    }),
    proxyIdentityMethod: Ember.observer('identityMethod', function () {
      var self = this;
      this.get('model.documents').forEach(function (doc) {
        doc.set('identityMethod', self.get('identityMethod'));
      });
    }),
    actions: {
      showFreeOrUpgradeModal: function showFreeOrUpgradeModal() {
        this.set('showFreeOrUpgrade', true);
      },
      hideFreeOrUpgradeModal: function hideFreeOrUpgradeModal() {
        this.set('showFreeOrUpgrade', false);
      },
      validateMessageLength: function validateMessageLength(value) {
        this.set('hasMessageLengthError', value);
      },
      toggleBulkSendModal: function toggleBulkSendModal() {
        this.toggleProperty('showBulkSendConfirmationModal');
        this.set('saving', false);
      }
    }
  });

  _exports.default = _default;
});