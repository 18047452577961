define("ember-rs/locales/en/templates/components/template/template-signer-link-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.templateSignerLinkItem = void 0;
  var templateSignerLinkItem = {
    "view": "View",
    "details": "Details",
    "reenable": "Re-enable",
    "disable": "Disable",
    "signed": "signed",
    "identityMethod": "Identity method: ",
    "expires": "Expires: ",
    "passcode": "Passcode protected: ",
    "maxSigners": "Max Signers: ",
    "doesNotExpire": "Does not expire"
  };
  _exports.templateSignerLinkItem = templateSignerLinkItem;
});