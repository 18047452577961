define("ember-rs/locales/pt-br/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.application = void 0;
  var application = {
    "logout": "Logout",
    "start": "Início",
    "startDocument": "Iniciar documento",
    "help": "Ajuda"
  };
  _exports.application = application;
});