define("ember-rs/locales/zh-cn/templates/unauthorized/document-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpired = void 0;
  var documentExpired = {
    "documentExpired": "文档已过期",
    "contactSender": "The document expired before it was completed. Contact the sender for more information."
  };
  _exports.documentExpired = documentExpired;
});