define("ember-rs/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WSzcdY0U",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sub-header\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4,[\"isExpired\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"components.documentExpirationEditor.theDocExpired\"],null]],[2,\" \"],[1,[30,[36,3],[[35,2],[35,1,[\"model\",\"dateformat\"]]],null]],[2,\". \"],[1,[30,[36,0],[\"components.documentExpirationEditor.reactivated\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"components.documentExpirationEditor.theDocExpires\"],null]],[2,\" \"],[1,[30,[36,3],[[35,2],[35,1,[\"model\",\"dateformat\"]]],null]],[2,\". \"],[1,[30,[36,0],[\"components.documentExpirationEditor.newExpiration\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[11,\"form\"],[24,0,\"update-expiration-form\"],[4,[38,6],[[32,0],\"saveExpirationAndCloseModal\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n    \"],[8,\"rs-date-picker\",[],[[\"@onChange\",\"@value\",\"@dateFormat\",\"@minDate\",\"@maxDate\"],[[30,[36,6],[[32,0],\"updateExpiration\"],null],[34,7],[34,8],\"today\",[34,9]]],null],[2,\"\\n    \"],[10,\"input\"],[14,0,\"button\"],[15,2,[30,[36,0],[\"shared.actions.update\"],null]],[14,4,\"submit\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"currentUser\",\"expiresAt\",\"moment-format\",\"document\",\"if\",\"action\",\"newExpiration\",\"datePickerFormat\",\"maxExpiration\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/document-expiration-editor.hbs"
    }
  });

  _exports.default = _default;
});