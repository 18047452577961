define("ember-rs/components/template/template-signer-link-item", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  STYLE  ////////////////////////
    tagName: 'li',
    ////////////////////////  DEFAULTS  ////////////////////////
    showDetails: false,
    showModal: false,
    loading: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    // Make this property point to code type once that's available
    codeType: Ember.computed('link.embedCode', function () {
      return this.get('link.embedCode') ? 'code' : 'link';
    }),
    expiresIn: Ember.computed('link.expiresAt', function () {
      return this.get('link.expiresAt') || this.i18n.t('components.template.templateSignerLinkItem.doesNotExpire');
    }),
    viewText: Ember.computed(function () {
      return this.i18n.t('components.template.templateSignerLinkItem.view').toString();
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleDetails: function toggleDetails() {
        this.toggleProperty('showDetails');

        if (this.showDetails) {
          // if there is no view count, make the api call
          // fetching for the first time after the template details page is loaded
          var link = this.link;

          if (!link.viewedCount) {
            this.set('loading', true);
            this.getDetails();
          }
        }
      },
      toggleModal: function toggleModal() {
        this.toggleProperty('showModal');
      },
      setDisabled: function setDisabled(disabledValue) {
        var link = this.link;
        link.set('disabled', disabledValue);
        link.save();
      },
      willDestroy: function willDestroy() {
        if (this.showDetails) {
          this.toggleProperty('showDetails');
        }
      }
    },
    getDetails: function getDetails() {
      var _this = this;

      _jquery.default.ajax({
        type: 'GET',
        url: "".concat(_environment.default.api.endpoint, "/template_signer_links/").concat(this.get('link.id')),
        contentType: 'application/json',
        success: function success(response) {
          _this.set('link.viewedCount', response['viewed_count']);

          _this.set('link.signedCount', response['signed_count']);

          _this.set('loading', false);
        }
      });
    }
  });

  _exports.default = _default;
});