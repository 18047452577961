define("ember-rs/templates/unauthorized/document-voided", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AZEKExZ3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.documentVoided.documentVoided\"],null]],[13],[2,\"\\n\\n\"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.documentVoided.sender\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/document-voided.hbs"
    }
  });

  _exports.default = _default;
});