define("ember-rs/locales/pt-br/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "linkExpired": "Link do documento expirado",
    "toSign": "Para assinar este documento, solicite um novo link.",
    "sendLink": "Enviar novo link para o signatário",
    "contactSender": "Entre em contato com o remetente do documento para solicitar um link atualizado."
  };
  _exports.identityExpired = identityExpired;
});