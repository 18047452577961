define("ember-rs/locales/zh-cn/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteDocumentModal = void 0;
  var deleteDocumentModal = {
    "permanentlyDeleted": "此操作将从 RightSignature 中永久删除该文档。此操作完成后将无法撤消。",
    "email": "签名者和抄送者将收到一封电子邮件，其中包含已完成的文档和签名证书以供其记录在案。",
    "briefMoment": "该文档从您的控制板中消失可能需要一小段时间。",
    "delete": "删除",
    "cancel": "取消",
    "deleteDocument": "删除文档",
    "deleteComplete": "已删除文档",
    "deleteFailed": "无法销毁文档"
  };
  _exports.deleteDocumentModal = deleteDocumentModal;
});