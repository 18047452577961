define("ember-rs/locales/es/templates/unauthorized/signing-link-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingLinkDisabled = void 0;
  var signingLinkDisabled = {
    "signingLinkDisabled": "Enlace de firma inhabilitado",
    "signingLinkDisabledTip": "Este enlace de firma ha sido inhabilitado."
  };
  _exports.signingLinkDisabled = signingLinkDisabled;
});