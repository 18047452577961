define("ember-rs/locales/ja/templates/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "title": "アップグレード中..",
    "desc": "数秒かかることがあります。ページを更新したり、ブラウザーの [閉じる] または [戻る] ボタンをクリックしないでください。"
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});