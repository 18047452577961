define("ember-rs/locales/nl/templates/components/document-download-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDownloadOptions = void 0;
  var documentDownloadOptions = {
    "originalPDF": "Originele PDF",
    "completedPDF": "Voltooide PDF",
    "overlay": "Afdrukbare overlay-PDF",
    "failed": "Voltooide PDF (mislukt)",
    "support": "Er is een probleem opgetreden bij het verwerken van het document. Neem contact op met Support.",
    "cert": "Handtekeningcertificaat",
    "mergedPDF": "Gecombineerde PDF"
  };
  _exports.documentDownloadOptions = documentDownloadOptions;
});