define("ember-rs/locales/es/templates/unauthorized/unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unsubscribed = void 0;
  var unsubscribed = {
    "unsubscribed": "No suscrito",
    "unsubscribedTip": "Se le ha quitado de la lista de destinatarios de recordatorios para este documento. Seguirá recibiendo correos electrónicos referentes a otros documentos."
  };
  _exports.unsubscribed = unsubscribed;
});