define("ember-rs/locales/es/templates/components/hybris-trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialBanner = void 0;
  var hybrisTrialBanner = {
    "billingInfo": "La información de facturación y los planes de los precios no están actualizados.",
    "update": "Haga clic aquí para actualizar todo",
    "hybrisTrialMsg": "En {{{numberOfDaysLeft}}} d se terminará el período de prueba. <a href='/upgrade-worldpay'>Actualice su plan</a> a uno de pago para poder seguir utilizando nuestros servicios.",
    "premiumTrialMsg": "You are on your Premium Trial. Please contact 1-800-441-3453 or Email us at <a href='mailto:{{{email}}}'>{{{email}}}</a> to upgrade and go completely paperless."
  };
  _exports.hybrisTrialBanner = hybrisTrialBanner;
});