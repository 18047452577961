define("ember-rs/locales/en/templates/components/user-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userFields = void 0;
  var userFields = {
    "name": "name",
    "email": "email",
    "editInfo": "Edit information"
  };
  _exports.userFields = userFields;
});