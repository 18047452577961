define("ember-rs/locales/de/templates/unauthorized/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unauthorizedFile = void 0;
  var unauthorizedFile = {
    "unauthorized": "Nicht autorisiert",
    "youAreNot": "Sie sind nicht berechtigt, diese Seite anzuzeigen."
  };
  _exports.unauthorizedFile = unauthorizedFile;
});