define("ember-rs/locales/pt-br/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "unlock_message": "Um ou mais signatários excederam o número máximo de tentativas de verificação de identidade (3) e o documento agora está bloqueado para esses signatários. Para desbloquear o documento e exigir que todos os signatários pendentes verifiquem suas respectivas identidades com a autenticação baseada em conhecimento, clique em Desbloquear. Note que se o documento for desbloqueado, pode ocorrer um conflito com a <a target='_blank' href='https://www.irs.gov/uac/electronic-signature-guidance-for-forms-8878-and-8879'>IRS Electronic Signature Guidance for Forms 8878 and 8879</a>."
  };
  _exports.unlock = unlock;
});