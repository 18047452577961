define("ember-rs/locales/zh-cn/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentityExpired = void 0;
  var confirmIdentityExpired = {
    "linkExpired": "链接已过期",
    "resendIdentityConfirmation": "重新发送身份确认电子邮件。",
    "resendIdentityVerification": "重新发送身份验证",
    "contactSender": "请与文档发件人联系以申请更新后的链接。"
  };
  _exports.confirmIdentityExpired = confirmIdentityExpired;
});