define("ember-rs/components/document-uploader", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Actions
  //  * added
  //  * updated
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    awsBucket: _environment.default.awsBucket,
    classNames: ['uploader-container'],
    classNameBindings: ['nonProduction:show-upload'],
    maxFileSize: 20480000,
    nonProduction: _environment.default.environment !== 'production',
    awsBucketUrl: Ember.computed('awsBucket', function () {
      return "https://".concat(this.awsBucket, ".s3.amazonaws.com");
    }),
    fileUploaderEle: function fileUploaderEle() {
      return this.$('.file-uploader');
    },
    uploadFromFilename: function uploadFromFilename(filename) {
      return this.uploads.findBy('originalFilename', filename);
    },
    initializeUploader: Ember.on('didInsertElement', function () {
      var _this = this;

      var self = this;
      var fileupload_options = {
        // Might need to figure out how to implement this for old IE support
        // forceIframeTransport: true,
        autoUpload: true,
        sequentialUploads: true
      };

      if (this.singleFileUpload) {
        _jquery.default.extend(fileupload_options, {
          singleFileUploads: true
        });
      } else {
        _jquery.default.extend(fileupload_options, {
          limitMultiFileUploads: 5
        });
      }

      this.fileUploaderEle().fileupload(_jquery.default.extend(fileupload_options, {
        submit: function submit() {},
        start: function start() {},
        drop: function drop(event, data) {
          if (_this.singleFileUpload && data.files.length > 1) {
            _this.flashMessages.warning(_this.i18n.t('components.documentUploader.multiFile'));

            data.files = [data.files.shift()];
          }
        },
        send: function send(event, data) {
          self.uploadFromFilename(data.files[0].name).set('_state', 'uploading');
        },
        // TODO: Verify we dont need something synchronous for multiple simultaneous uploads
        // TODO: disallow duplicate filename
        add: function add(event, data) {
          if (self.uploadFromFilename(data.files[0].name)) {
            self.get('flashMessages').warning(_this.i18n.t('components.documentUploader.alreadyUploaded'));
            return false;
          }

          _jquery.default.each(data.files, function (i, file) {
            if (file.size > _this.maxFileSize) {
              _this.flashMessages.warning(_this.i18n.t('components.documentUploader.fileSize', {
                fileName: file.name
              }));

              return false;
            } // Attempt to create via ember-data: Incomplete because data.submit() does not work in the promise


            var upload = self.get('store').createRecord('upload', {
              filename: file.name,
              size: file.size,
              contentType: file.type,
              user: self.get('currentUser.model')
            });
            return upload.save().then(function () {
              upload.setProperties({
                _state: 'queued',
                originalFilename: file.name // need to retain this as we sanitize filenames on the Upload side

              });
              self.get('uploads').pushObject(upload);
              var s3upload = Ember.Object.create(upload.get('s3Upload'));
              self.$().find('input[name=key]').val(s3upload.get('key'));
              self.$().find('input[name=policy]').val(s3upload.get('policy'));
              self.$().find('input[name=x-amz-credential]').val(s3upload.get('credential'));
              self.$().find('input[name=x-amz-date]').val(s3upload.get('date'));
              self.$().find('input[name=x-amz-signature]').val(s3upload.get('signature'));
              self.$().find('input[name=x-amz-security-token]').val(s3upload.get('security_token'));
              self.sendAction('added', upload);
              data.submit();
            });
          });
        },
        done: function done(event, data) {
          var upload = self.uploadFromFilename(data.files[0].name);
          upload.set('_state', 'upload_done');

          _jquery.default.ajax({
            url: "".concat(_environment.default.api.endpoint, "/uploads/").concat(upload.get('id'), "/done_uploading.json"),
            type: 'PUT',
            success: function success(data) {
              upload.set('baseFileGuid', data.upload.base_file_guid);
              self.sendAction('updated', upload);
            },
            error: function error() {
              self.get('flashMessages').warning(self.get('i18n').t('components.documentUploader.uploadFailed'));
            }
          });
        },
        fail: function fail() {
          throw 'fail!';
        }
      })).on('mouseup', function () {
        //this is here due to a bug in the fileuploader plugin, which only occurs on IE10
        //https://github.com/tors/jquery-fileupload-rails/issues/33
        if (navigator.userAgent.indexOf("MSIE 10") > 0) {
          (0, _jquery.default)(this).trigger('click');
        }
      });
    }),
    cleanup: Ember.on('willDestroyElement', function () {
      this.uploads.clear();
      this.fileUploaderEle().fileupload('destroy');
    })
  });

  _exports.default = _default;
});