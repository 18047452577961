define("ember-rs/routes/email-verification", ["exports", "jquery", "ember-rs/config/environment", "ember-rs/mixins/current-user-route"], function (_exports, _jquery, _environment, _currentUserRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_currentUserRoute.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ////////////////////////  HOOKS  ////////////////////////
    beforeModel: function beforeModel(transition) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax("".concat(_environment.default.api.endpoint, "/email_verifications/").concat(transition.to.params['email_verification_id'], "/verify"), {
          method: 'PUT',
          data: {
            code: transition.to.queryParams.code
          }
        }).done(function (response) {
          _this.flashMessages.success(_this.i18n.t('routes.emailVerification.verificationSuccess'));

          if (_this.get("session.isAuthenticated")) {
            _this.getCurrentUser().then(function () {
              _this.transitionTo('user.settings');
            });
          } else {
            _this.transitionTo('application');
          }

          resolve(response);
        }).fail(function (response) {
          _this.flashMessages.warning(_this.i18n.t('routes.emailVerification.invalidVerification'));

          _this.transitionTo('application');

          reject(response);
        });
      }, 'verify an email');
    },
    model: function model() {
      return null; // so that we don't fire a GET /email_verifications
    }
  });

  _exports.default = _default;
});