define("ember-rs/locales/zh-cn/templates/components/rs-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsToggle = void 0;
  var rsToggle = {
    "on": "开",
    "off": "关"
  };
  _exports.rsToggle = rsToggle;
});