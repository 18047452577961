define("ember-rs/locales/de/templates/components/delete-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteSignature = void 0;
  var deleteSignature = {
    "deleteSuccess": "Die Signatur wurde gelöscht.",
    "deleteFailed": "Fehler beim Löschen der Signatur."
  };
  _exports.deleteSignature = deleteSignature;
});