define("ember-rs/components/document-upload-thumbnail", ["exports", "ember-rs/utils/poller"], function (_exports, _poller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    wcsWorkflow: Ember.inject.service(),
    shouldPollForReady: false,
    // whether to poll for processing of upload to be ready
    classNames: ['document-thumbnail', 'theme__thumbnail'],
    // pass in true if you only need a thumbnail under 100px height
    returnSmall: false,
    hideRemoveButton: false,
    remove: false,
    isRemoving: Ember.computed('removingTemplate', 'remove', function () {
      return this.removingTemplate && this.remove;
    }),
    uploadReady: Ember.computed('upload.baseFile.processingState', function () {
      return this.get('upload.baseFile.processingState') === 'ready';
    }),
    hasFirstPageImageURL: Ember.computed('upload.baseFile.processingState', 'upload.baseFile.pageImages.[]', function () {
      return Ember.isPresent(this.get('upload.baseFile.pageImages')) && Ember.isPresent(this.get('upload.baseFile.pageImages')[0].original);
    }),
    isLandscape: Ember.computed('upload.baseFile.processingState', 'upload.baseFile.imageHeight', 'upload.baseFile.imageWidth', function () {
      if (this.upload) {
        return this.get('upload.baseFile.imageWidth') > this.get('upload.baseFile.imageHeight');
      } else {
        return null;
      }
    }),
    firstPageImage: Ember.computed('upload.baseFile.processingState', 'upload.baseFile.pageImages.[]', function () {
      if (this.upload) {
        return this.get('upload.baseFile.pageImages')[0];
      } else {
        return null;
      }
    }),
    processingError: Ember.computed('upload.baseFile.processingState', function () {
      return this.get('upload.baseFile.processingState') === 'error';
    }),
    pollForReady: Ember.on('didInsertElement', function () {
      this.sendAction('isValidCheck');

      if (this.get('upload.baseFile.processingState') === 'ready') {
        this.upload.set('uploadReady', 'ready');
      }

      if (this.shouldPollForReady && this.get('upload.baseFile.processingState') !== 'ready' && Ember.isBlank(this.uploadProcessingPoller)) {
        var self = this;
        this.set('uploadProcessingPoller', _poller.default.create({
          onPoll: function onPoll() {
            return self.get('upload').reload().then(function (upload) {
              var processingState = upload.get('baseFile.processingState');

              if (processingState === 'ready' || processingState === 'error') {
                upload.set('uploadReady', processingState);
                self.get('uploadProcessingPoller').stop();

                if (self.wcsWorkflow.getValue() && processingState === 'ready') {
                  self.sendAction('skipToPrepareDocument');
                }
              }

              return upload;
            });
          }
        }));
        this.uploadProcessingPoller.start();
      }
    }).observes('upload.baseFile.processingState'),
    cleanup: Ember.on('willDestroyElement', function () {
      if (this.uploadProcessingPoller) {
        this.uploadProcessingPoller.stop();
      }
    }),
    actions: {
      removeTemplate: function removeTemplate() {
        this.set('remove', true);
        var template = this.get('doc') || this.get('upload');
        this.sendAction('removeTemplate', template);
      }
    }
  });

  _exports.default = _default;
});