define("ember-rs/locales/pt-br/templates/onboarding/prepare-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding3 = void 0;
  var prepareOnboarding3 = {
    "seeSigners": "<span>Veja os signatários</span> deste documento. Cada signatário tem sua própria cor, que corresponde aos campos que precisa preencher"
  };
  _exports.prepareOnboarding3 = prepareOnboarding3;
});