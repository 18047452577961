define("ember-rs/mixins/document-page", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  PROPERTIES  ////////////////////////
    pageNumber: Ember.computed.alias('pageImage.page_number'),
    ////////////////////////  HOOKS  ////////////////////////
    initOnPageImageLoad: Ember.on('didInsertElement', function () {
      this.$().find('.page-image').on('load', Ember.run.bind(this, this.onPageImageLoad));
    }),
    destroyOnPageImageLoad: Ember.on('willDestroyElement', function () {
      this.$().find('.page-image').off('load');
    }),
    onPageImageLoad: function onPageImageLoad(evt) {
      // Pass the page number to the pagesLoaded array
      Ember.get(this, 'documentViewerData.pagesLoaded').addObject(parseInt((0, _jquery.default)(evt.target).parents('.page').data('page-number')));
    }
  });

  _exports.default = _default;
});