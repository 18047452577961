define("ember-rs/locales/nl/templates/document/prepare-signers-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_mobile = void 0;
  var prepare_signers_mobile = {
    "assign_roles": "Rollen toewijzen voor:"
  };
  _exports.prepare_signers_mobile = prepare_signers_mobile;
});