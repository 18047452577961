define("ember-rs/locales/it/mixins/workflow-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.workflowType = void 0;
  var workflowType = {
    "signatureRequest": "Inviato dall'utente",
    "selfSign": "Autofirmato",
    "templateSignerLink": "Da modello",
    "inPerson": "Firma di persona",
    "apiEmbedded": "API della firma incorporata",
    "bulkSend": "Invio in blocco"
  };
  _exports.workflowType = workflowType;
});