define("ember-rs/locales/zh-cn/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.notFound = void 0;
  var notFound = {
    "doesNotExist": "此页面不存在。",
    "didYou": "您是否访问了来自 RightSignature&trade; 的其他位置的链接?",
    "anotherPart": "如果您从 RightSignature&trade; 的另一个部分进入了此页面，请<a href='https://support.citrix.com/cscase'>联系技术支持</a>以便我们能够纠正错误。",
    "followLink": "您是否访问了来自其他站点的链接?",
    "otherSite": "来自其他站点的链接有时会过时或存在拼写错误。请<a href='https://support.citrix.com/cscase'>联系技术支持</a>并告诉我们您进入此页面时单击的链接，以便我们能够尝试联系其他站点以修复此问题。",
    "typeUrl": "您键入的 URL 吗?",
    "doubleCheck": "键入的地址(URL)可能不正确。请检查以确保您获得了完全正确的拼写、大小写等。",
    "home": "主页"
  };
  _exports.notFound = notFound;
});