define("ember-rs/locales/it/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noUser = void 0;
  var noUser = {
    "unableLogin": "Impossibile accedere",
    "unableLoginTip": "Non è possibile accedere a questo account RightSignature.<br> Contattare un amministratore dell'account per ottenere l'accesso."
  };
  _exports.noUser = noUser;
});