define("ember-rs/locales/es/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pendingIdentityVerification = void 0;
  var pendingIdentityVerification = {
    "pendingIdentityVerification": "Verificación de identidad pendiente",
    "success": "Ha firmado con éxito este documento. Para completar este documento y devolverlo al remitente, deberá verificar su identidad. Para ello, compruebe su correo electrónico y haga clic en el enlace \"verificar correo electrónico\" que RightSignature le envió.",
    "resend": "Volver a enviar la verificación de identidad"
  };
  _exports.pendingIdentityVerification = pendingIdentityVerification;
});