define("ember-rs/locales/en/templates/components/payment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paymentForm = void 0;
  var paymentForm = {
    "name": "Name",
    "firstName": "First Name...",
    "lastName": "Last Name...",
    "address1": "Address Line 1...",
    "address2": "Address Line 2...",
    "city": "City",
    "state": "State/Province",
    "zip": "ZIP Code",
    "country": "Country",
    "address": "Address",
    "vatId": "VAT ID",
    "redirect": "Redirecting...",
    "upgrade": "Upgrade",
    "payUpgrade": "Pay and Upgrade",
    "oops": "Oops! Something went wrong",
    "ok": "OK"
  };
  _exports.paymentForm = paymentForm;
});