define("ember-rs/locales/nl/templates/components/overlay-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.overlayComponent = void 0;
  var overlayComponent = {
    "mergeField": "Samenvoegveld:",
    "signature": "Handtekening",
    "date": "Datum",
    "dateAutoFill": "Date Signed",
    "initial": "Initiële",
    "select": "Selecteren",
    "text": "Tekst",
    "attachment": "Bijlage",
    "authorize": "Betaling autoriseren",
    "collect": "Creditcardgegevens verzamelen"
  };
  _exports.overlayComponent = overlayComponent;
});