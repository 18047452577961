define("ember-rs/locales/pt-br/templates/document/details", ["exports", "ember-rs/locales/pt-br/templates/document/details/unlock"], function (_exports, _unlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    unlock: _unlock.unlock,
    "status": "Status",
    "overview": "Visão geral",
    "history": "Histórico",
    "people_involved": "Pessoas envolvidas:",
    "manage": "Gerenciar",
    "security_options": "Opções de segurança:",
    "declined": "Recusada",
    "expired": "Expirado",
    "expires": "Expira",
    "extend": "Estender",
    "pin": "PIN  *****",
    "generate_pin": "Gerar novo PIN",
    "pin_tip": "Se você criar um novo PIN, o atual será invalidado.",
    "passcode_question": "Pergunta do código secreto:",
    "clear_attempts": "Limpar tentativas de código secreto",
    "passcode": "Código secreto",
    "signer_sequencing": "Sequenciamento de signatários",
    "lock_message": "Este documento foi bloqueado.",
    "attachments": "Anexos:",
    "reference_id": "ID de referência: ",
    "tags": "Marcas: ",
    "remind_header": "Link de assinatura enviado",
    "remind_body": "Enviamos o link para assinar este documento para o endereço de email abaixo. Para ignorar esta etapa no futuro, você pode confirmar esse endereço de email na página de configuração.",
    "new_pin": "Novo PIN",
    "new_passcode": "Aqui está o seu novo PIN. Você deve enviar esse PIN atualizado para os signatários do documento para que eles possam ter acesso a ele.",
    "done": "Concluído",
    "draft": "Rascunho",
    "pending": "Pendente",
    "voided": "Anulado",
    "executed": "Executado",
    "recipient_message": "Mensagem para o(s) destinatário(s):",
    "void_reason": "Reason for Voiding:",
    "document_voided": "Document Voided:",
    "document_declined": "Decline Reason:"
  };
  _exports.details = details;
});