define("ember-rs/locales/es/templates/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentAccessSettings = void 0;
  var documentAccessSettings = {
    "addViewer": "Agregar participante",
    "email": "Correo electrónico",
    "name": "Name",
    "add": "Agregar",
    "cancel": "Cancelar",
    "editSigner": "Editar firmante",
    "save": "Guardar",
    "manageAccess": "Administrar acceso",
    "accessTip": "Estas personas tienen acceso a este documento y pueden descargarlo o verlo en cualquier momento.",
    "signers": "Firmantes",
    "edit": "editar",
    "viewers": "Participantes",
    "remove": "quitar",
    "updateEmail": "Dirección de correo electrónico del firmante actualizada correctamente.",
    "updateSigner": "Successfully updated signer details."
  };
  _exports.documentAccessSettings = documentAccessSettings;
});