define("ember-rs/locales/ja/templates/signer/footer-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerDesktop = void 0;
  var footerDesktop = {
    "from": "差出人",
    "submitSig": "署名の送信",
    "submit": "送信",
    "clickSubmit": "[送信] をクリックすることで次の内容に同意します: ",
    "privacyPolicy": "プライバシーポリシー",
    "consent": "電子署名による同意",
    "contents": "、およびこのドキュメントのコンテンツ。",
    "left": "左",
    "save": "保存",
    "help": "ヘルプ",
    "saveProgress": "進行状況の保存",
    "savingChanges": "変更の保存中",
    "field": "フィールド",
    "fields": "フィールド"
  };
  _exports.footerDesktop = footerDesktop;
});