define("ember-rs/locales/es/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbarMenuitem = void 0;
  var componentToolbarMenuitem = {
    "signatureComponent": "Campo de firma",
    "textComponent": "Campo de texto",
    "dateComponent": "Campo de fecha",
    "checkComponent": "Casilla de verificación",
    "checkGroupComponent": "Grupo de casillas de verificación",
    "initialsComponent": "Campo de iniciales",
    "selectComponent": "Cuadro de selección",
    "signatureAnnotationComponent": "Agregar firma",
    "signatureAnnotationName": "Anotación de firma",
    "textAnnotationComponent": "Añadir texto",
    "textAnnotationName": "Anotación de texto",
    "checkmarkAnnotationComponent": "Agregar marca de verificación",
    "checkmarkAnnotationName": "Anotación de la marca de verificación",
    "attachmentComponent": "Datos adjuntos",
    "attachmentComponentName": "Archivo adjunto solicitado",
    "paymentComponent": "Cobrar pago",
    "signatureComponenttip": "Coloque este campo donde el firmante deba proporcionar su firma.",
    "textComponenttip": "Coloque este campo donde el firmante deba introducir información de texto (por ejemplo, escribiendo su nombre o dirección).",
    "dateComponenttip": "Utilice este campo donde se deban agregar fechas. Al firmar el documento, al firmante se le presentará un selector de fechas, que facilitará la selección. La fecha se mostrará en el formato 'MM-DD-AAAA'.",
    "checkComponenttip": "Use este campo donde el firmante deba marcar una opción (por ejemplo, una cláusula \"Estoy de acuerdo con...\"). Si tiene un documento con una serie de opciones, donde el firmante se ve limitado a seleccionar solo una o al menos una de las opciones, use la opción Grupo de casillas de verificación.",
    "checkGroupComponenttip": "Utilice un grupo de casillas de verificación si tiene una serie de opciones, donde el firmante está limitado a seleccionar solo una o al menos una de las opciones.",
    "initialsComponenttip": "Coloque este campo donde el firmante deba proporcionar sus iniciales.",
    "selectComponenttip": "Coloque un cuadro de selección donde los firmantes deban elegir desde una lista de opciones.",
    "signatureAnnotationComponenttip": "Agregue su propia firma al documento.",
    "textAnnotationComponenttip": "Agregue su propio texto al documento para rellenarlo o anotarlo.",
    "checkmarkAnnotationComponenttip": "Marque una casilla en el documento.",
    "attachmentComponenttip": "Coloque este cuadro donde desee que un firmante adjunte una imagen o archivo a su documento.",
    "paymentComponenttip": "Use esta opción para agregar un botón a su documento con el que el firmante pueda introducir un número de tarjeta de crédito para realizar el pago. Nota: Antes de usar esto, debe habilitar una integración de pago en su cuenta. (Vaya a Cuenta y luego seleccione Integraciones).",
    "unknownField": "Campo desconocido"
  };
  _exports.componentToolbarMenuitem = componentToolbarMenuitem;
});