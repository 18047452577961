define("ember-rs/locales/ja/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrations = void 0;
  var integrations = {
    "successEnabled": "{{{formattedProviderName}}} が有効になりました",
    "successAuthorized": "{{{formattedProviderName}}} が承認されました",
    "successDisabled": "{{{formattedProviderName}}} が無効になりました",
    "error": "エラー。{{{formattedProviderName}}} を変更できませんでした。",
    "errorAuthorize": "エラー。{{{formattedProviderName}}} の統合を承認できませんでした。",
    "errorSetup": "{{{formattedProviderName}}} 統合のセットアップ中にエラーが発生しました。再試行してください。"
  };
  _exports.integrations = integrations;
});