define("ember-rs/locales/ja/templates/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faqModal = void 0;
  var faqModal = {
    "docId": "ドキュメント ID:",
    "contactSender": "送信者に連絡する: {{{userName}}}、<a href='mailto: {{{userEmail}}}'>{{{userEmail}}}</a> (質問がある場合)",
    "needHelp": "RightSignature&trade; の使用方法についてご不明の点があれば、一般的な Q&A を参照してください:",
    "q1": "このドキュメントの内容についての質問:",
    "rsNote": "RightSignature&trade; はサードパーティのオンライン ドキュメント署名サービスです。ドキュメントの送信や、法的な、セキュリティ保護された電子署名を可能にしますが、いかなる方法でも送信者やドキュメントに関連付けられることはありません。",
    "q2": "ドキュメントの完了方法。",
    "a2": "ドキュメントの左の赤い矢印は、入力者に必須のフィールドを示します。すべての必須フィールドの入力後、[送信] ボタンがクリックできるようになります。",
    "q3": "法的拘束力があるか。",
    "a3": "あります。オンラインで電子署名されたドキュメントは、法的に有効であり手書き署名の紙のドキュメントと同等の法的拘束力があります。既にオンラインで契約を完了している可能性があります。たとえば、インターネット経由で [同意します] ボタンをクリックすると、一般的に「クリックラップ契約」と呼ばれる契約の当事者になります。またオンラインで物品を購入すると、法的拘束力のある契約の当事者になります。",
    "a3part2": "RightSignature&trade; テクノロジは、電子署名を急速に普及させ、紙ベースでの従来の商取引を減らすことを意図する、米国の『グローバル商取引及び国内商取引における電子署名法』 (ESIGN 法、2000年制定) と『統一電子取引法』 (UETA法) ならびに EU 指令 (EC/1999/93) に完全準拠しています。これらの法律はいずれも、電子契約の有効性を補強するものであり、たとえば、ESIGN 法では、成立に当たり電子署名や電子記録が作成されたという理由のみで契約が法的効力、妥当性、強制力を失うことはないとしています。",
    "q4": "RightSignature™ とは何か。",
    "a4": "RightSignature&trade; によって、紙のドキュメントで実行するより迅速かつより安全な方法で、オンラインで法的拘束力のあるドキュメントを作成し署名することができます。RightSignature&trade; は Web ベースの SaaS であり、Amazon Web Services のセキュリティ保護されたデータ インフラストラクチャを活用します。",
    "q5": "RightSignature™ の機能。",
    "a5": "RightSignature&trade; によって、紙のドキュメントで実行するより迅速かつより安全な方法で、オンラインで法的拘束力のあるドキュメントを作成し署名することができます。RightSignature&trade; は Web ベースの SaaS であり、Amazon Web Services のセキュリティ保護されたデータ インフラストラクチャを活用します。",
    "q6": "RightSignature™ のオンライン ドキュメント署名機能。",
    "a6": "RightSignature&trade; により、複数の関係者からの署名を簡単にオンラインで入手できます。ドキュメントをアップロードし、受信者の情報を入力するだけです。RightSignature&trade; から一意のリンクを含むメールを受け取った受信者は、リンク先のページでドキュメントを確認し、必要なテキスト フィールドに記入し、署名することができます。または、マウス操作により数秒でオンライン署名することもできます。",
    "a6part2": "今日、ほとんどの企業は、ファックスでやり取りしたり、宅急便で送付したり、署名ページをスキャンしてメールしたりしてドキュメントに署名しています。RightSignature&trade; を使用すると、このような手間と時間と費用のかかる従来のやり方を廃止できます。法的拘束力を持つドキュメントに署名してセキュリティ保護されたサーバーに安全に保管するまでの作業が、マウス クリック数回の短時間で完了します。",
    "q7": "紙のドキュメントへの署名との安全性の比較。",
    "a7": "電子契約書は、紙の契約書と比較して安全性が大幅に高く、法的な位置付けは同等です。ファックスや郵便で送付されるドキュメントは社内での回付を通じて危険にさらされる一方、電子ドキュメントであれば機密が完全に守られ、指定された受取人のみが閲覧できます。RightSignature&trade; ドキュメントは、セキュリティで保護されたサーバーに冗長コピーを保持して保存され、紙のドキュメントとは異なり紛失や破損の心配がありません。さらに、受信確認メール、IP レコード、バイオメトリック署名データにより、各署名者の認証を行います。",
    "q8": "本サービスの対象者。",
    "a8": "RightSignature&trade; は、中小企業、フリーランサー、デザイナー、開発者、弁護士、請負業者、学校、非営利団体など、多くのお客様にお使いいただけます。お客様の業務でドキュメントへの署名が必要な場合、RightSignature&trade; をぜひご利用ください。",
    "q9": "送信できるドキュメントの種類。",
    "a9": "RightSignature&trade; を頻繁に使用して送信されるドキュメントには、契約書、秘密保持契約書、提案受諾書、注文変更書、広告校正原稿、委任契約書、経費報告書、発注書、賃貸借契約書、独立業務請負人契約書、新規取引申請書、雇用に関する申請書など、さまざまな種類があります。米国の雇用および納税申告書式 W-9、W-4、I-9 などを処理するには、RightSignature&trade; が最適です。ポリシー変更にあたってチーム メンバー全員の署名が必要な場合などにも、RightSignature&trade; を使うと簡単です。タイムシートの承認から何百万ドルもの契約書まで、業務上のあらゆるドキュメントの送信に RightSignature&trade; をご利用いただけます。",
    "q10": "サポートされているブラウザーの一覧。",
    "a10": "RightSignature&trade; は、次のブラウザーで最適なユーザー エクスペリエンスを提供します。お使いのブラウザーを最新バージョンにアップグレードするには、次にアクセスしてください:",
    "a10part2": "Mozilla Firefox 3 以降、Microsoft Internet Explorer 9 以降、Apple Safari 3 以降、Google Chrome、Opera 9 以降",
    "q11": "ログインできない場合の対処方法。",
    "a11": "メールアドレスが正しく入力されていることを確認してください。また、パスワードをゆっくりと確実に入力してください。それでもログインできない場合、[パスワードを忘れた場合] をクリックしてパスワードをリセットできます。パスワード リセット手順に従ってもログインできない場合は、カスタマーサポートにお問い合わせください。",
    "q12": "受信者側での RightSignature™ アカウントの必要性。",
    "a12": "受信者がドキュメントを受信するためのアカウントを持つ必要はありません。ドキュメントを送信する宛先数に上限はありません。ドキュメントが送信されると、受信者は一意のリンクを含むメールを受信します。リンクをクリックすると、受信者はブラウザーに表示されたドキュメントを閲覧し、署名することができます。ダウンロードやプラグインは必要ありません。RightSignature&trade; の操作は非常に簡単で直感的なので、初めて使う受信者でも説明を必要とせず、わずか数秒で署名できます。",
    "q13": "競合製品と比較した RightSignature™ の優位性。",
    "a13": "RightSignature&trade; は、洗練された使いやすいユーザー エクスペリエンスと、実際の手書き署名をキャプチャするサービスでご好評をいただいております。Amazon Web Services のインフラストラクチャにより、世界トップレベルのセキュリティ保護とスケーラビリティを誇ります。また、最先端のサービスを提供するため常に開発、機能の向上が続けられています。個人事業主、中小企業のお客様にとって、RightSignature&trade; は最もシンプルかつ効果的なオンライン ドキュメント ソフトウェアです。",
    "q14": "RightSignature™ によるペーパーレス化の取り組みサポート。",
    "a14": "環境問題およびコスト削減の観点から、多くの会社がペーパーレス化の取り組みを進めています。RightSignature&trade; により、紙を印刷することなく安全かつ効率的にドキュメントに署名できるため、オフィスにおける紙の使用量の削減に大きく貢献します。",
    "q15": "電子署名について。",
    "a15": "RightSignature&trade; の署名パッドでは、マウスを使ってドキュメントに署名し、米国および EU の法令に定義されている有効な電子署名を作成することができます。署名パッドに残した痕跡は、署名者の手書き署名と類似していなくても、すべて有効となります。法廷において「署名」の概念は広義に解釈されており、署名は事実上、従来の意味での文字による「署名」である必要はないとされています。たとえば、ゴム印および非識字者が付けた印が署名として過去に認められており、その両方とも、当事者が意図して契約書に残したあらゆる痕跡が署名として認められる可能性があるという概念を示しています。 ",
    "a15part2": "<p>オンラインで電子署名されたドキュメントは、法的に有効であり手書き署名の紙のドキュメントと同等の法的拘束力があります。RightSignature&trade; テクノロジは、電子署名を急速に普及させ、紙ベースでの従来の商取引を減らすことを意図する、米国の『グローバル商取引及び国内商取引における電子署名法』 (ESIGN 法、2000年制定) と『統一電子取引法』 (UETA 法) ならびに EU 指令 (EC/1999/93) に完全準拠しています。</p><p>RightSignature&trade; テクノロジは、契約書などの記録に論理的に添付または関連付けられ、その記録に署名する意図で自然人が実行または採用した電子的な音、シンボル、またはプロセスである、という米国法における「電子署名」の定義を満たしています。</p><p>RightSignature&trade; テクノロジはまた、さらに厳しい EU の「先進電子署名」要件も満たします。</p>",
    "a15part3": "  EU において電子署名は、署名者と一意に結びついていること、署名者を特定することができること、署名者本人のみが維持管理できる手段で作成されていること、リンクされたデータが署名後に加えられたあらゆる変更を検出可能な方法で関連付けられていること、と定義されています。詳しくは、『Electronic Signatures and Online Contracts』 (Nolo.com の記事)、欧州議会および理事会指令 1999/93/EC、米国の『グローバル商取引及び国内商取引における電子署名法』 (ESIGN 法) と『統一電子取引法』 (UETA 法) を参照してください。",
    "a15part4": "RightSignature&trade; で署名されたドキュメントの法的妥当性について詳しくは、シトリックスの法的情報ページを参照してください。",
    "q16": "契約書の他方当事者の本人確認。",
    "a16": "本人確認は、署名の重要な要素です。これは、電子署名でも紙への署名でも同じです。電子署名は、署名者の特定が可能で、署名者と一意に結びついている必要があります。RightSignature&trade; は、メールアドレス確認、IP アドレス トラッキング、バイオメトリック署名生成アルゴリズム、およびそのほかの複数の情報に基づく認証プロセスを使用しています。そのため RightSignature&trade; の認証強度は、アシスタント、配偶者などの第三者が代理で署名することの多い紙のドキュメントより大幅に高くなります。",
    "q17": "署名者の場所の確認方法。",
    "a17": "インターネット上のすべてのコンピューターは、IP またはインターネット プロトコル、アドレスで識別されます。たとえば、IP アドレスは 99.102.245.244 です。このアドレスから、コンピューターの物理的な場所を追跡できます。100％ 正確ではありませんが、RightSignature&trade; を使用してドキュメントを署名する関係者の追加の認証ツールとして機能します。",
    "q18": "署名後のドキュメントが関係者によって操作されることはあるか。",
    "a18": "署名のためにアップロードされ送信されたドキュメントは、セキュリティ保護されたサーバーにロックされ、冗長コピーを保持して保存されます。署名後、最終ドキュメントはセキュリティ保護されたハッシュ アルゴリズムにバインドされ、関係者がドキュメントを変更できないようにします。RightSignature&trade の中立性は、署名された契約を無効にしようとする試みに対する法的な防御となります。セキュリティ保護されたデータ インフラストラクチャについて詳しくは、セキュリティのページを参照してください。",
    "q19": "デジタル署名はサードパーティの証明機関によって証明されるか。",
    "a19": "RightSignature&trade; は、ドキュメントの署名者間のワークフローを調整するサードパーティの署名サービスであり、ファックスやメールに比べて監査と検証の機能が大幅に強化されます。RightSignature&trade; の利点は、このサービスが不動産取引におけるエスクロー/タイトル代理店のような中立的なサードパーティによって提供されるということです。ドキュメントが署名されると、RightSignature&trade; はセキュリティ保護されたハッシュ アルゴリズムでバインドし、Amazon Web Services インフラストラクチャを使用して冗長コピーを格納します。 RightSignature&trade; で署名された契約が法廷で使用される場合、ドキュメントが改ざんされたと関係者が主張することは非常に困難または不可能です。RightSignature&trade; の記録プロセスおよび署名後のロックによってサードパーティの検証が提供され、堅牢で防御に優れた契約を実現します。",
    "q20": "ほかの関係者と同じドキュメントに署名していることを確認する方法。",
    "a20": "RightSignature&trade; にアップロードされたすべてのドキュメントは、数字、大文字と小文字、および文字で構成された固有の長い文字列の参照番号にバインドされています。この参照番号は、すべての関係者が常に同じ文書を閲覧していることを保証するために、ドキュメントに関するすべてのコミュニケーションおよび画面に含まれます。",
    "a20part2": "署名用にアップロードされ送信されると、ファイルを変更する機能はありません。まだ受信者によって署名されていないドキュメントの場合は、送信者がゴミ箱に移動し、新しいドキュメントをアップロードし、新しい参照番号が付与されたドキュメントを送信できます。",
    "q21": "RightSignature™ に独自のロゴを設定できますか?",
    "a21": "RightSignature&trade; を使用..."
  };
  _exports.faqModal = faqModal;
});