define("ember-rs/locales/en/templates/onboarding/prepare-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTutorial = void 0;
  var prepareTutorial = {
    "title": "Prepare a document for signature",
    "selectField": "Select the field on the right that you want to add.",
    "placeField": "Place the Field",
    "clickPlace": "Click where you want to place the new field.",
    "adjustSize": "Adjust the Field's Size",
    "adjustHover": "To adjust, hover over the field and drag the dots.",
    "adjustOptions": "Adjust Options",
    "moreOptions": "Click the field to show more options and select who should complete the field.",
    "thatsIt": "That's it!",
    "clickReview": "When you're done, click <b>Next: Review</b>",
    "knowMore": "Want to know more?",
    "support": "View support articles &amp; videos"
  };
  _exports.prepareTutorial = prepareTutorial;
});