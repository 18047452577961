define("ember-rs/locales/zh-cn/templates/components/faq-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faqModal = void 0;
  var faqModal = {
    "docId": "文档 ID:",
    "contactSender": "如有任何问题，请通过 <a href='mailto: {{{userEmail}}}'>{{{userEmail}}}</a> 联系发件人 {{{userName}}}",
    "needHelp": "是否需要有关使用 RightSignature&trade; 的一些帮助? 请试试下面的常见问题解答:",
    "q1": "我对本文档的内容有疑问:",
    "rsNote": "RightSignature&trade; 是一项第三方在线文档签名服务。我们通过合法、安全的电子签名来加快文档的发送和签名速度，但与发件人或文档没有任何关联。",
    "q2": "如何完成本文档?",
    "a2": "文档左侧的红色箭头将指向下一个必填字段。填写完所有必填字段后，即可单击“提交”按钮。",
    "q3": "文档具有法律约束力吗?",
    "a3": "是。通过电子签名在线签名的文档具有法律效力，并且与手动签名的纸质文档具有同等的法律地位。您很可能已在线签订合同。例如，当您在 Internet 上单击“我同意”按钮时，通常就成为了称为“点击生效的合同”的一方。此外，当您在线购物时，您也是具有法律约束力的合同的一方。",
    "a3part2": "RightSignature&trade; 采用的技术完全符合美国 2000 年颁布的《全球及全美商务电子签名法》(ESIGN)和《统一电子交易法》(UETA)以及《欧洲指令》(EC/1999/93)的要求，所有这些法案都鼓励尽快采用电子签名，减少使用陈旧的纸质签名方法。每种法案都提高了电子协议的有效性。例如，根据 ESIGN，合同方可能无法单方面否认合同的法律效力、有效性或强制性，因为合同的构成中使用了电子签名或电子记录。",
    "q4": "RightSignature™ 是什么?",
    "a4": "RightSignature&trade; 支持您在线创建具有法律约束力的文档并进行签名，与执行纸质文档签名相比，这种方式更快更安全。RightSignature&trade; 是基于 Web 的软件即服务，它利用 Amazon Web Services 的安全数据基础结构。",
    "q5": "RightSignature™ 有哪些功能?",
    "a5": "RightSignature&trade; 支持您在线创建具有法律约束力的文档并进行签名，与执行纸质文档签名相比，这种方式更快更安全。RightSignature&trade; 是基于 Web 的软件即服务，它利用 Amazon Web Services 的安全数据基础结构。",
    "q6": "RightSignature™ 如何实现在线文档签名?",
    "a6": "RightSignature&trade; 可以轻松地在线获取多方签名。只需上载您的文档并输入收件人信息即可。RightSignature&trade; 通过电子邮件向收件人发送一个指向可以在其中查看文档、填写所有必填文本字段并进行签名的页面的唯一链接。收件人使用鼠标在几秒内即可完成在线签名。",
    "a6part2": "如今，大部分企业都通过来回发送传真、发送次日达信件或扫描后通过电子邮件发送签名页的方式对文档进行签名。与这些繁琐的陈旧方法相比，RightSignature&trade; 非常简单，并且不存在时间延迟和费用的问题。单击几下鼠标，即可在几分钟内完成签署具有法律约束力的文档并将其安全地存储在安全的服务器上。",
    "q7": "在纸质文档上签名不是更安全吗?",
    "a7": "与纸质合同相比，电子合同明显更安全并且具有同等的法律地位。通过传真和邮件发送的文档会暴露在整个办公室内，而电子文档则会完全保密并且只能由指定的收件人查看。RightSignature&trade; 文档存储在具有冗余备份功能的安全服务器上，并且不会像纸质文档一样被放错位置、丢失或毁坏。此外，电子邮件收件人、IP 记录和生物识别签名数据都会提供每个签名方的身份验证信息。",
    "q8": "此服务适用的对象有哪些?",
    "a8": "RightSignature&trade; 是中小型企业、自由职业者、设计师、开发人员、律师、承包商、学校、非盈利组织等的理想选择。如果您的工作需要签名的文档，则需要 RightSignature&trade;。",
    "q9": "我应该发送哪些类型的文档?",
    "a9": "定期通过 RightSignature&trade; 发送的文档包括合同、保密协议、提案验收、更改订单、广告样稿、聘书、费用报告、采购订单、租赁协议、独立承包商协议、新客户申请、就职申请等。W-9、W-4 和 I-9 等合规表非常适合 RightSignature&trade;。需要所有团队成员签收政策变更吗? RightSignature&trade; 让一切变得简单。企业可以通过 RightSignature&trade; 发送任何内容，小到时间表签收，大到数百万美元的合同。",
    "q10": "支持的浏览器有哪些?",
    "a10": "RightSignature&trade; 可以在以下浏览器中提供最佳用户体验。要将您的浏览器升级到最新版本，请访问:",
    "a10part2": "Mozilla Firefox 3+、Microsoft Internet Explorer 9+、Apple Safari 3+、Google Chrome、Opera 9+",
    "q11": "如果我无法登录该这么办?",
    "a11": "请仔细检查您的电子邮件地址，确保没有输错。此外，请缓慢而仔细地键入您的密码。如果这些都不起作用，可以通过单击“我忘记了我的密码”来重置您的密码。如果按照密码重置过程操作后仍无法登录，请与我们联系以获得帮助。",
    "q12": "收件人是否需要 RightSignature™ 帐户?",
    "a12": "否。收件人不需要帐户即可接收您发送的文档。向其发送文档的收件人数量没有限制。发送文档后，收件人将收到一封包含唯一链接的电子邮件。单击该链接后，收件人可以在浏览器中查看文档并进行签名，既不需要下载，也不需要安装任何插件。RightSignature&trade; 简单且直观，新收件人也能够在没有说明的情况下在几秒钟内完成签名。",
    "q13": "RightSignature™ 优于竞争对手的原因是什么?",
    "a13": "RightSignature&trade; 以其简洁、直观的用户体验并且能够捕获真实的手写签名而著称。借助 Amazon Web Services 提供的基础结构，我们的安全性和可扩展性达到了世界一流水平。持续的开发和改进让 RightSignature&trade; 始终保持领先地位。RightSignature&trade; 为个人专家和小型企业提供了最简单且最高效的在线文档软件。",
    "q14": "RightSignature™ 如何支持无纸化倡议?",
    "a14": "出于对环境问题和节省成本的考虑，很多办公室都在贯彻无纸化倡议。RightSignature&trade; 是减少纸张使用的一个关键组成部分，可提供安全、高效的方法来在线对文档签名，无需纸质打印版本。",
    "q15": "电子签名是什么?",
    "a15": "RightSignature&trade; 的签名垫支持您使用鼠标对文档进行签名，创建符合美国和欧盟法规与指令的有效电子签名。您在签名垫上留下的任何标记都是有效的，即使该标记不像您的手写签名亦如此。法院对“签名”概念的解释非常广泛，事实上，签名不必是传统意义上的“签名”。例如，橡皮图章已被认定为签名，目不识丁之人留下的标记也属于签名，这两种方式都说明由一方对合同做出任何有意的标记都可以被认定为签名。 ",
    "a15part2": "<p>使用电子签名在线签名的文档具有法律效力并且与手动签名的纸质文档具有同等法律地位。RightSignature&trade; 采用的技术完全符合美国 2000 年颁布的《全球及全美商务电子签名法》(ESIGN)和《统一电子交易法》(UETA)以及《欧洲指令》(EC/1999/93)的要求，所有这些法案都鼓励尽快采用电子签名，减少使用陈旧的纸质签名方法。</p><p>RightSignature&trade; 采用的技术符合美国对“电子签名”的定义: 附加到合同或其他记录或与其存在逻辑关联的电子声音、符号或流程，并且由旨在对记录进行签名的人签名或采用 </p><p>RightSignature&trade; 采用的技术也符合欧盟对“高级电子签名”的更严格要求:</p>",
    "a15part3": "  与签名者具有唯一关联；能够识别签名者；使用签名者可以保持完全控制的方式创建；链接到与其相关的数据，只要数据发生任何后续变化，都能够被察觉。供进一步阅读的参考资料:“电子签名和在线合同”、Nolo.com 文章、欧洲议会颁发的《1999/93/EC 指令》、美国颁发的《全球及全美商务电子签名法》(ESIGN) 以及《统一电子交易法》(UETA)",
    "a15part4": "有关使用 RightSignature&trade; 签名的文档的法律效力的详细信息，请参阅我们的“合法性”页面。",
    "q16": "如何知道合同的另一方即为所宣称的一方?",
    "a16": "不管是电子签名还是书面签名，验证个人身份都是非常重要的环节。电子签名必须能够识别签名者，并且必须与签名者具有唯一的关联。RightSignature&trade; 使用多重身份验证过程，包括电子邮件地址验证、IP 地址跟踪、生物识别签名算法以及其他信息。针对 RightSignature&trade; 的身份验证明显比针对纸质文档的身份验证更加强大，纸质文档的各方通常可以让助手、配偶或其他人代表其进行签名。",
    "q17": "如何知晓签名者的位置?",
    "a17": "Internet 上的每台计算机都通过 IP 或 Internet 协议、地址来识别。例如，您的 IP 地址为 99.102.245.244。根据此地址，我们可以跟踪您的计算机至实际位置。虽然正确率并非 100%，但会为使用 RightSignature&trade; 的各方提供其他身份验证工具以使其能够对文档进行签名。",
    "q18": "签名后的文档可以在签名后被其中一方篡改吗?",
    "a18": "将文档上载并发送以获取签名后，该文档即被锁定并存储在具有冗余备份功能的安全服务器上。签名后，最终的文档会通过安全的哈希算法加以约束，从而确保任何一方都无法对已签名的文档进行更改。RightSignature&trade; 的中立性可进一步提供法律防卫，以抵制任何尝试控诉已签名的协议无效的举动。有关我们的安全数据基础结构的详细信息，请访问我们的“安全性”页面。",
    "q19": "数字签名会由第三方机构认证吗?",
    "a19": "作为协调文档签名者之间的工作流程的第三方签名服务，RightSignature&trade; 可提供比传真和电子邮件方法明显更强的审核和验证功能。使用 RightSignature&trade; 的一大优势在于我们的服务是中立的第三方，与房地产交易中的中介/产权机构非常类似。文档签名后，RightSignature&trade; 将使用哈希算法对其进行约束并使用 Amazon Web Services 基础结构存储冗余备份。如果通过 RightSignature&trade; 签名的合同在法庭上受到质疑，另一方将很难或不可能断言该文档被篡改。RightSignature&trade; 流程和签名后锁定的记录可提供第三方验证，以确保合同坚不可摧。",
    "q20": "如何确定我要签名的文档与另一方提供的文档相同?",
    "a20": "上载到 RightSignature&trade; 的每个文档都绑定到唯一的长字符串参考编号，由数字、大写字母和小写字母以及字符组成。此参考编号包含在每次的通信内容以及包含文档的屏幕中，从而确保各方看到的始终都是同一份文档。",
    "a20part2": "将文件上载并发出以获取签名后，没有可对其进行更改的功能。如果文档尚未被任何收件人签名，发送方可以将其作废，然后上载并发送一个新文档，该文档将具有新的参考编号。",
    "q21": "我可以使用我的徽标定制 RightSignature™ 的外观吗?",
    "a21": "正在使用 RightSignature&trade;..."
  };
  _exports.faqModal = faqModal;
});