define("ember-rs/locales/fr/templates/components/document/signer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerRow = void 0;
  var signerRow = {
    "pendingIdentityAuthentication": "Authentification d'identité en attente",
    "sign": "Signer",
    "disabledReminder": "Ce signataire a désactivé d'autres rappels pour ce document",
    "threshold": "Vous avez dépassé le nombre d'envoi de rappels (3 par document)",
    "signerFailed": "Ce signataire a échoué ",
    "currentlyLocked": "et ne peut plus accéder au document.",
    "signerLocked": "Ce signataire a échoué l'authentification basée sur les connaissances et ne peut plus accéder au document.",
    "remind": "Rappel",
    "resend": "Renvoyer",
    "createLink": "Créer lien",
    "copyLink": "Copier le lien",
    "copyLinkSuccess": "Lien copié sur le presse-papiers",
    "copyLinkError": "Impossible de copier le lien dans le Presse-papiers",
    "signerAuthFailed": "Impossible d'authentifier le signataire via la session : {{{error}}}",
    "reminderSuccess": "{{{name}}} a reçu un rappel",
    "reminderFailed": "Impossible de générer un rappel pour {{{name}}}",
    "generateLinkFailed": "Impossible de générer le lien de signataire : {{{error}}}",
    "resendIdentityVerification": "Vérification de l'identité renvoyée",
    "resendingFailed": "Impossible de renvoyer la vérification d'identité : {{{error}}}"
  };
  _exports.signerRow = signerRow;
});