define("ember-rs/locales/es/controllers/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.branding = void 0;
  var branding = {
    "emailUpdateSuccess": "Se actualizaron correctamente los parámetros de correo electrónico del usuario.",
    "emailUpdateFail": "No se pudieron actualizar los parámetros de correo electrónico del usuario.",
    "updateSuccess": "Los parámetros de marca de la cuenta de usuario se actualizaron correctamente.",
    "updateFail": "Error al actualizar los parámetros de marca de la cuenta de usuario."
  };
  _exports.branding = branding;
});