define("ember-rs/locales/it/templates/components/hybris-trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialBanner = void 0;
  var hybrisTrialBanner = {
    "billingInfo": "Le informazioni di fatturazione e i piani tariffari non sono aggiornati.",
    "update": "Fare clic qui per aggiornare",
    "hybrisTrialMsg": "Si hanno ancora a disposizione {{{numberOfDaysLeft}}} giorni per il periodo di anteprima. Eseguire <a href='/upgrade-worldpay'><a href='/upgrade-worldpay'>l'aggiornamento</a> a un piano a pagamento e continuare a utilizzare i nostri servizi.",
    "premiumTrialMsg": "You are on your Premium Trial. Please contact 1-800-441-3453 or Email us at <a href='mailto:{{{email}}}'>{{{email}}}</a> to upgrade and go completely paperless."
  };
  _exports.hybrisTrialBanner = hybrisTrialBanner;
});