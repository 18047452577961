define("ember-rs/locales/de/templates/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "addRoles": "Rollen hinzufügen",
    "whatRoles": "Welche Rollen erfordert diese Vorlage?",
    "signOrder": "Reihenfolge der Unterzeichner festlegen",
    "uniqueName": "Sie müssen eindeutige Rollennamen zuweisen.",
    "addRole": "Rolle hinzufügen",
    "sendRole": "Absenderrolle hinzufügen",
    "next": "Nächster Schritt: Felder platzieren"
  };
  _exports.addRoles = addRoles;
});