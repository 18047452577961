define("ember-rs/controllers/signer/passcode", ["exports", "jquery", "cryptojs", "ember-rs/config/environment"], function (_exports, _jquery, _cryptojs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PASSCODE_PIN_LENGTH = 5;

  var _default = Ember.Controller.extend({
    layoutSizeData: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: ['access_token', 'identity_token'],
    ////////////////////////  DEFAULTS  ////////////////////////
    passcodeAnswer: '',
    firstDigit: '',
    secondDigit: '',
    thirdDigit: '',
    fourthDigit: '',
    fifthDigit: '',
    passcodePin: Ember.computed('firstDigit', 'secondDigit', 'thirdDigit', 'fourthDigit', 'fifthDigit', function () {
      return this.firstDigit.concat(this.secondDigit).concat(this.thirdDigit).concat(this.fourthDigit).concat(this.fifthDigit);
    }),
    passcodePinValid: Ember.computed('passcodePin', function () {
      return Ember.isPresent(this.passcodePin) && this.passcodePin.length === PASSCODE_PIN_LENGTH;
    }),
    passcodePinEmpty: Ember.computed.not('passcodePinValid'),
    ////////////////////////  PROPERTIES  ////////////////////////
    answerPresent: Ember.computed.notEmpty('passcodeAnswer'),
    answerEmpty: Ember.computed.not('answerPresent'),
    containerStyle: Ember.computed('layoutSizeData.windowWidth', 'backgroundColor', 'layoutSizeData.windowHeight', function () {
      return "width: ".concat(this.get('layoutSizeData.windowWidth'), "px; height: ").concat(this.get('layoutSizeData.windowHeight'), "px; background-color: ").concat(this.backgroundColor, ";").htmlSafe();
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      submitAnswer: function submitAnswer() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            url: "".concat(_environment.default.api.endpoint, "/signers/").concat(_this.get('model.signer_id'), "/check_passcode"),
            type: 'POST',
            data: {
              passcode_answer: _this.get('model.passcode_pin_enabled') ? _this.passcodePin : _this.passcodeAnswer,
              access_token: _this.get('model.query_params.access_token'),
              identity_token: _this.get('model.query_params.identity_token')
            }
          }).then(function (data) {
            if (data.passcode_valid) {
              _this.transitionToRoute('signer.sign', _this.get('model.signer_id'), {
                queryParams: {
                  access_token: _this.get('model.query_params.access_token'),
                  identity_token: _this.get('model.query_params.identity_token'),
                  passcode_answer_digest: _cryptojs.default.SHA256(_this.get('model.passcode_pin_enabled') ? _this.passcodePin : _this.passcodeAnswer)
                }
              });

              resolve(data);
            } else {
              _this.set('passcodeAnswer', '');

              _this.flashMessages.warning(_this.i18n.t('controllers.signer.sign.passcode'));

              reject(data);
            }
          }, function (response) {
            _this.set('passcodeAnswer', '');

            _this.send('reloadModel');

            reject();

            if (response && response.status === 308 && /Sender is editing the document/i.test(response.responseJSON[0])) {
              _this.get('router').transitionTo('unauthorized.document-editing');
            }
          });
        }, 'submit a passcode');
      },
      moveOnMax: function moveOnMax(nextFieldID, value) {
        if (value.length === 1) {
          (0, _jquery.default)('#' + nextFieldID).focus();
        }
      }
    }
  });

  _exports.default = _default;
});