define("ember-rs/components/signer-color-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // This component is for the color code squares on prepare, document show
    classNameBindings: ['sequenceClass', ':sequence-color-code', ':theme__legend_key'],
    sequenceClass: Ember.computed('sequence', function () {
      var adjustedSequence = this.sequence + 1;
      return "signer_".concat(adjustedSequence);
    })
  });

  _exports.default = _default;
});