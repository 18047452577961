define("ember-rs/locales/pt-br/controllers/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.branding = void 0;
  var branding = {
    "emailUpdateSuccess": "Configurações de e-mail do usuário atualizadas com sucesso.",
    "emailUpdateFail": "Erro ao atualizar as configurações de email do usuário.",
    "updateSuccess": "Configurações de identidade visual de conta de usuário atualizadas com sucesso.",
    "updateFail": "Erro ao atualizar as configurações de identidade visual da conta do usuário."
  };
  _exports.branding = branding;
});