define("ember-rs/locales/en/templates/mobile-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mobileNavigation = void 0;
  var mobileNavigation = {
    "documents": "Documents",
    "templates": "Templates",
    "settings": "Your Settings",
    "integrations": "Integrations",
    "users": "Users",
    "reports": "Reports",
    "help": "Help",
    "logout": "Logout"
  };
  _exports.mobileNavigation = mobileNavigation;
});