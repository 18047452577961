define("ember-rs/locales/es/templates/unauthorized/redirect-to-rs3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectToRs3 = void 0;
  var redirectToRs3 = {
    "wrongVersion": "Versión incorrecta de RightSignature",
    "classic": "Ha intentado iniciar sesión en RightSignature ShareFile Edition. Sin embargo, su cuenta le da acceso a Classic RightSignature.",
    "go": "Ir a RightSignature"
  };
  _exports.redirectToRs3 = redirectToRs3;
});