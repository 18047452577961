define("ember-rs/locales/en/templates/components/support-when-externally-billed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportWhenExternallyBilled = void 0;
  var supportWhenExternallyBilled = {
    "partnerSale": "Your account was created through a partner sale. Please contact support if you would like to upgrade your account."
  };
  _exports.supportWhenExternallyBilled = supportWhenExternallyBilled;
});