define("ember-rs/locales/nl/templates/components/rs-left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsLeftNav = void 0;
  var rsLeftNav = {
    "Documents": "Documenten",
    "Templates": "Sjablonen",
    "Account": "Account",
    "Settings": "Instellingen",
    "Integrations": "Integraties",
    "Branding": "Huisstijl",
    "Users": "Gebruikers",
    "Reports": "Rapporten",
    "Api": "API",
    "Billing": "Facturering",
    "Workflows": "Feedback en goedkeuring"
  };
  _exports.rsLeftNav = rsLeftNav;
});