define("ember-rs/locales/fr/templates/document/prepare-signers-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_desktop = void 0;
  var prepare_signers_desktop = {
    "assign_roles": "Attribuer des rôles pour :",
    "who_sign": "Qui doit signer ",
    "sign_order": "Définir ordre de signature",
    "add_another": "Ajouter un autre signataire",
    "add_yourself": "Vous ajouter",
    "contact_integtration": "Intégration de contacts",
    "select_integration": "Sélectionner une intégration",
    "who_sign_package": "Qui doit signer le paquet de documents ?"
  };
  _exports.prepare_signers_desktop = prepare_signers_desktop;
});