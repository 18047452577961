define("ember-rs/locales/ja/templates/templates/desktop-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.desktopHeader = void 0;
  var desktopHeader = {
    "searchTemplates": "テンプレートの検索..."
  };
  _exports.desktopHeader = desktopHeader;
});