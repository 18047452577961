define("ember-rs/locales/fr/templates/onboarding/post-sign-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding1 = void 0;
  var postSignOnboarding1 = {
    "tapGear": "<span>Appuyez sur l'icône d'engrenage</span> pour télécharger le document et des informations supplémentaires.",
    "viewDoc": "C'est ici que vous pouvez voir <span>l'état</span> du document"
  };
  _exports.postSignOnboarding1 = postSignOnboarding1;
});