define("ember-rs/locales/nl/controllers/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "success": "Ondertekeningskoppeling opnieuw verzonden",
    "failure": "Kan de koppeling niet verzenden: {{{response}}}"
  };
  _exports.identityExpired = identityExpired;
});