define("ember-rs/locales/it/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "linkExpired": "Collegamento al documento scaduto",
    "toSign": "Per firmare questo documento, richiedere un nuovo collegamento.",
    "sendLink": "Invia nuovo collegamento firmatario",
    "contactSender": "Contattare il mittente del documento per richiedere un collegamento aggiornato."
  };
  _exports.identityExpired = identityExpired;
});