define("ember-rs/locales/ja/templates/onboarding/prepare-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding3 = void 0;
  var prepareOnboarding3 = {
    "seeSigners": "このドキュメントの<span>署名者を表示</span>します。各署名者には、入力が必要なフィールドに関連付けられた色が割り当てられます"
  };
  _exports.prepareOnboarding3 = prepareOnboarding3;
});