define("ember-rs/locales/fr/templates/document/search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header = void 0;
  var search_header = {
    "from": "Depuis : ",
    "state": "État : ",
    "search_docs": "Rechercher documents...",
    "all": " Tout",
    "pending": " En attente",
    "declined": " Refusée",
    "executed": " Terminé",
    "expired": " Expiré",
    "voided": " Annulé",
    "admin_view": "Vue administrateur",
    "tool_tip": "Affichez tous les documents envoyés depuis ce compte.",
    "filter": "Filtrer"
  };
  _exports.search_header = search_header;
});