define("ember-rs/locales/fr/templates/onboarding/welcome-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding2 = void 0;
  var welcomeOnboarding2 = {
    "tips": "Accédez à vos <span>documents</span> et <span>modèles</span>, <span>gérez les paramètres</span> et consultez des <span>didacticiels vidéo ainsi que des articles pratiques</span>."
  };
  _exports.welcomeOnboarding2 = welcomeOnboarding2;
});