define("ember-rs/locales/es/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding2 = void 0;
  var accountOnboarding2 = {
    "body": "Personalice su cuenta y déle un aspecto profesional <span>agregando una foto.</span>",
    "mobileBody": "Su foto aparece en el mensaje de correo electrónico y en la página de firma de documento de los destinatarios para que puedan firmar con toda confianza."
  };
  _exports.accountOnboarding2 = accountOnboarding2;
});