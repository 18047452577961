define("ember-rs/locales/zh-cn/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceCompleteDocumentModal = void 0;
  var forceCompleteDocumentModal = {
    "header": "强制完成文档",
    "forceCompleted": "此操作将删除分配给尚未签名的签名者的所有字段，并将文档设置为已执行(最终状态)。此操作完成后将无法撤消。",
    "briefMoment": "在控制板上反映新状态可能需要一小段时间。",
    "forceComplete": "强制完成",
    "cancel": "取消",
    "success": "已成功强制完成文档。",
    "failed": "无法强制完成文档"
  };
  _exports.forceCompleteDocumentModal = forceCompleteDocumentModal;
});