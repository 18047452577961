define("ember-rs/locales/de/templates/document/prepare-signers-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_desktop = void 0;
  var prepare_signers_desktop = {
    "assign_roles": "Rollen zuweisen für:",
    "who_sign": "Wer muss unterschreiben? ",
    "sign_order": "Reihenfolge der Unterzeichner festlegen",
    "add_another": "Weiteren Unterzeichner hinzufügen",
    "add_yourself": "Sich selbst hinzufügen",
    "contact_integtration": "Kontaktintegration",
    "select_integration": "Wählen Sie eine Integration",
    "who_sign_package": "Wer muss das Dokumentenpaket signieren?"
  };
  _exports.prepare_signers_desktop = prepare_signers_desktop;
});