define("ember-rs/templates/components/integration-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "30SGXrdj",
    "block": "{\"symbols\":[\"provider\"],\"statements\":[[10,\"ul\"],[14,0,\"available-integrations\"],[12],[2,\"\\n  \"],[3,\" user.allIntegrationProviders \"],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"   \"],[8,\"integration-setting\",[],[[\"@integrationFilter\",\"@hideIntegrationType\",\"@user\",\"@provider\",\"@metadata\",\"@toggleService\"],[[34,0],[34,1],[34,2],[32,1],[34,2,[\"integrationProviderMetadata\"]],\"toggleService\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"integrationFilter\",\"hideIntegrationType\",\"user\",\"providers\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/integration-list.hbs"
    }
  });

  _exports.default = _default;
});