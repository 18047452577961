define("ember-rs/mixins/component-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    ////////////////////////  STYLES  ////////////////////////
    classNameBindings: ['signerSequenceClass', 'layoutSizeData.useMobileLayout:scaled-small', 'component.isInvalid', 'component.isMergeField'],
    style: Ember.computed('component.x', 'component.y', 'component.width', 'component.height', 'documentViewerData.pageWidth', 'documentViewerData.pageHeight', function () {
      var left = Ember.get(this, 'component.x') * Ember.get(this, 'documentViewerData.pageWidth');
      var top = Ember.get(this, 'component.y') * Ember.get(this, 'documentViewerData.pageHeight');
      var width = Ember.get(this, 'component.width') * Ember.get(this, 'documentViewerData.pageWidth');
      var height = Ember.get(this, 'component.height') * Ember.get(this, 'documentViewerData.pageHeight');
      return "left:".concat(left, "px; top:").concat(top, "px; width:").concat(width, "px; height:").concat(height, "px; line-height:").concat(height, "px;").htmlSafe();
    }),
    signerSequenceClass: Ember.computed('component.assignedTo', 'component.documentTemplate.roles.@each.name', function () {
      var _this = this;

      var assignedToRole = 0;

      if (Ember.get(this, 'component.documentTemplate')) {
        assignedToRole = Ember.get(Ember.get(this, 'component.documentTemplate.roles').find(function (role) {
          return Ember.get(role, 'name') === Ember.get(_this, 'component.assignedTo');
        }) || {}, 'sequence');
      }

      return "signer_".concat(assignedToRole + 1);
    }),
    componentType: Ember.computed('component.type', function () {
      return Ember.get(this, 'component.type').dasherize();
    })
  });

  _exports.default = _default;
});