define("ember-rs/templates/start-document/-self-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WfF5a6aa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"li\"],[14,0,\"theme__workflow\"],[12],[2,\"\\n\"],[6,[37,5],[[35,7,[\"isConfirmed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[16,0,[31,[\"card \",[30,[36,5],[[30,[36,4],[[35,3],[35,2]],null],\"is-saving\"],null]]]],[4,[38,6],[[32,0],\"startSelfSign\"],null],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3],[35,2]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"rs-spinner\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"signyourself\"],null]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"start-document.self-signature.title\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"theme_generic-text\"],[12],[1,[30,[36,1],[\"start-document.self-signature.description\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"unconfirmed-user-button\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"signyourself\"],null]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"start-document.self-signature.title\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"theme_generic-text\"],[12],[1,[30,[36,1],[\"start-document.self-signature.description\"],null]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"t\",\"selfSign\",\"saving\",\"and\",\"if\",\"action\",\"currentUser\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/start-document/-self-signature.hbs"
    }
  });

  _exports.default = _default;
});