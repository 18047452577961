define("ember-rs/locales/pt-br/templates/support-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportContent = void 0;
  var supportContent = {
    "needHelp": "Precisa de ajuda com o RightSignature&trade;?",
    "articlesBelow": "Abaixo encontram-se artigos de suporte e vídeos que respondem a muitas das perguntas que você possa ter em relação ao RightSignature&trade;. Se os artigos e vídeos não responderem à sua pergunta, não hesite em entrar em contato com nossos representantes de suporte.",
    "supportArticles": "Artigos de suporte",
    "overview": "Visão geral do RightSignature&trade; para ShareFile",
    "activate": "Como faço para ativar o RightSignature&trade; para ShareFile na minha conta do ShareFile?",
    "send": "Como faço para enviar um documento do ShareFile usando o RS para SF?",
    "sign": "Como faço para assinar um documento do ShareFile usando o RS para SF?",
    "branding": "Como faço para configurar a identidade visual da minha empresa?",
    "changeEmail": "Como faço para alterar o endereço de email associado à minha conta do RightSignature&trade;?",
    "addUser": "Como posso adicionar usuários à minha conta do RightSignature&trade;?",
    "signerOrder": "Como posso atribuir um pedido para signatários no mesmo documento?",
    "annotate": "Como posso usar o RightSignature&trade; para anotar documentos para meus signatários?",
    "mergeFields": "Como usar os campos de mesclagem para inserir texto em um documento antes de enviar?",
    "passcode": "Como posso adicionar um código de acesso ao meu documento?",
    "importOverlay": "Como posso usar o recurso Importar sobreposição?",
    "docLocation": "Onde os documentos são armazenados usando o RightSignature&trade; para ShareFile?",
    "share": "Como compartilho documentos?",
    "multiple": "Como faço para enviar vários documentos para assinatura?",
    "oneOff": "Como faço para enviar um documento? (uma única vez)",
    "template": "Como faço para criar um modelo reutilizável?",
    "editTemplate": "Como faço para editar um modelo?",
    "sendTemplate": "Como faço para enviar um documento baseado em um modelo?",
    "embed": "Como faço para incorporar um documento no meu site para assinatura?",
    "generate": "Como gerar um link de compartilhamento para meus documentos baseados em um modelo?",
    "delete": "Como faço para excluir um documento?",
    "tag": "Como posso marcar um documento?",
    "selfSign": "Como assino meu próprio documento?",
    "reports": "Quais relatórios e estatísticas estão disponíveis com o RightSignature&trade;?",
    "complete": "Como preencher o documento enviado para você",
    "remind": "Como envio um lembrete para assinar um documento?",
    "inPerson": "Como faço para obter um documento assinado pessoalmente?",
    "showLess": "Mostrar menos",
    "showMore": "Mostrar mais",
    "sendQuestion": "Envie-nos a sua pergunta",
    "submitRequest": "Envie sua solicitação aqui",
    "call": "Ligue-nos agora",
    "free": "Ligação gratuita"
  };
  _exports.supportContent = supportContent;
});