define("ember-rs/locales/en/templates/components/show-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showComponent = void 0;
  var showComponent = {
    "completed": "Completed",
    "pending": "Pending",
    "mergefield": "is a merge field",
    "requiredAssignedTo": "Required \"{{{fieldName}}}\" field assigned to <b>{{{assignedToName}}}</b>",
    "optionalAssignedTo": "Optional \"{{{fieldName}}}\" field assigned to <b>{{{assignedToName}}}</b>"
  };
  _exports.showComponent = showComponent;
});