define("ember-rs/locales/de/templates/signer/sign/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identify = void 0;
  var identify = {
    "advisory": "Der Absender dieses Dokuments hat die wissensbasierte Authentifizierung von RightSignature aktiviert, um Ihre Identität zu überprüfen. RightSignature verwendet den Drittanbieterdienst BlockScore, um die bereitgestellten persönlichen Informationen zu überprüfen und zu bestätigen. Die Verwendung der bereitgestellten persönlichen Informationen ist auf die Identitätsprüfung und -bestätigung mit den Datensätzen von BlockScore beschränkt. Die Identitätsprüfung und -bestätigung hat keine Auswirkungen auf Ihre Kreditauskunft, Kreditwürdigkeit oder Berichterstattung an Kreditgeber. Weder die IRS (Bundessteuerbehörde der USA) noch BlockScore oder RightSignature haben die Möglichkeit, Ihren Kreditbericht oder Ihre Steuerinformationen als Teil dieses Prozesses einzusehen oder darauf zuzugreifen.",
    "step1": "Identitätsprüfung - Schritt 1 von 2",
    "beforeSigning": "Bevor Sie das Dokument unterschreiben, geben Sie bitte Ihre persönlichen Daten ein, um uns bei der Überprüfung Ihrer Identität zu unterstützen.",
    "notValid": "Leider konnten wir keine gültige Identität finden, die den angegebenen Informationen entspricht. Stellen Sie sicher, dass die Informationen korrekt sind und klicken Sie auf 'Weiter', um es erneut zu versuchen.",
    "youHave": "Sie haben noch ",
    "remaining": " Versuche, um auf das Dokument zuzugreifen.",
    "name": "Name:",
    "dob": "Geburtsdatum:",
    "ssn": "Die letzten 4 Zeichen der Sozialversicherungsnummer:",
    "passport": "Ausweisnummer:",
    "address": "Adresse:",
    "address2": "Adresse 2:",
    "city": "Stadt:",
    "state": "Status:",
    "zip": "Postleitzahl:",
    "country": "Land:",
    "return": "Zurück zum Formular",
    "advisory2": "Hinweis zur Identitätsprüfung",
    "sampleAddress1": "120 S. West St.",
    "sampleAddress2": "Suite 100",
    "sampleAddress3": "Raleigh",
    "submit": "Senden"
  };
  _exports.identify = identify;
});