define("ember-rs/templates/onboarding/welcome-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CPiqJjyT",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"useMobileLayout\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/mobile-menu-icon.png\"],[14,0,\"onboarding-target-image\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"rs-left-nav\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding-new/overlay-arrow.svg\"],[14,0,\"onboarding-arrow\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/overlay-arrow.png\"],[15,\"alt\",[30,[36,2],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"onboarding.welcomeOnboarding2.tips\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"layoutSizeData\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/welcome-onboarding-2.hbs"
    }
  });

  _exports.default = _default;
});