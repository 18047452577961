define("ember-rs/locales/pt-br/controllers/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.help = void 0;
  var help = {
    "success": "Sua solicitação foi enviada. Aguarde 24 horas para uma resposta.",
    "error": "Falha ao enviar: {{{error}}}"
  };
  _exports.help = help;
});