define("ember-rs/locales/es/templates/onboarding/post-sign-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding3 = void 0;
  var postSignOnboarding3 = {
    "downloadDoc": "Aquí es donde se puede <span>descargar</span> el documento."
  };
  _exports.postSignOnboarding3 = postSignOnboarding3;
});