define("ember-rs/mixins/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  DEFAULTS  ////////////////////////
    defaultBackgroundColor: '#D7D7D7',
    defaultFooterColor: '#FFFFFF',
    ////////////////////////  PROPERTIES  ////////////////////////
    brandingAllowed: Ember.computed.alias('brandingSettingsObject.branding'),
    signingBackgroundColor: Ember.computed.alias('brandingSettingsObject.signingBackgroundColor'),
    signingFooterColor: Ember.computed.alias('brandingSettingsObject.signingFooterColor'),
    brandingLogoUrl: Ember.computed.alias('brandingSettingsObject.logoUrl'),
    brandingLogoThumbUrl: Ember.computed.alias('brandingSettingsObject.logoThumbUrl'),
    displayLogo: Ember.computed.alias('brandingSettingsObject.displayLogo'),
    footerColor: Ember.computed('signingFooterColor', 'brandingAllowed', function () {
      if (this.brandingAllowed && Ember.isPresent(this.signingFooterColor)) {
        return this.signingFooterColor;
      } else {
        return this.defaultFooterColor;
      }
    }),
    backgroundColor: Ember.computed('signingBackgroundColor', 'brandingAllowed', function () {
      if (this.brandingAllowed && Ember.isPresent(this.signingBackgroundColor)) {
        return this.signingBackgroundColor;
      } else {
        return this.defaultBackgroundColor;
      }
    }),
    shouldShowLogo: Ember.computed('displayLogo', 'brandingAllowed', 'brandingLogoUrl', 'layoutSizeData.useMobileLayout', function () {
      return this.displayLogo && this.brandingAllowed && Ember.isPresent(this.brandingLogoUrl) && !Ember.get(this, 'layoutSizeData.useMobileLayout');
    }),
    darkFooter: Ember.computed('footerColor', function () {
      return this.colorIsDark(this.footerColor);
    }),
    darkBackground: Ember.computed('backgroundColor', function () {
      return this.colorIsDark(this.backgroundColor);
    }),
    colorIsDark: function colorIsDark(color) {
      var c = color.substring(1);
      var rgb = parseInt(c, 16);
      var r = rgb >> 16 & 0xff;
      var g = rgb >> 8 & 0xff;
      var b = rgb >> 0 & 0xff;
      var brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return brightness < 128;
    }
  });

  _exports.default = _default;
});