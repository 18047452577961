define("ember-rs/locales/de/templates/users/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.singIn = void 0;
  var singIn = {
    "redirect": "Umleiten zur Anmeldung..."
  };
  _exports.singIn = singIn;
});