define("ember-rs/locales/nl/mixins/integration-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocuments = void 0;
  var integrationDocuments = {
    "enable_sending": "Verzenden is ingeschakeld voor {{{provider}}}",
    "enable_archiving": "Archiveren is ingeschakeld voor {{{provider}}}",
    "enable_contacts": "Contactpersonen zijn ingeschakeld voor {{{provider}}}",
    "enable_payments": "Betalingen zijn ingeschakeld voor {{{provider}}}",
    "disable_sending": "Verzenden is uitgeschakeld voor {{{provider}}}",
    "disable_archiving": "Archiveren is uitgeschakeld voor {{{provider}}}",
    "disable_contacts": "Contactpersonen zijn uitgeschakeld voor {{{provider}}}",
    "disable_payments": "Betalingen zijn uitgeschakeld voor {{{provider}}}",
    "errorToggle": "Fout. Kan {{{service}}} niet wijzigen voor {{{provider}}}!",
    "errorRetrieve": "Fout. Kan document niet ophalen.",
    "errorProviderRetrieve": "Fout. Kan documenten van {{{provider}}} niet ophalen."
  };
  _exports.integrationDocuments = integrationDocuments;
});