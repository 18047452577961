define("ember-rs/locales/zh-cn/mixins/integration-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocuments = void 0;
  var integrationDocuments = {
    "enable_sending": "已成功对 {{{provider}}} 启用发送",
    "enable_archiving": "已成功对 {{{provider}}} 启用存档",
    "enable_contacts": "已成功对 {{{provider}}} 启用联系人",
    "enable_payments": "已成功对 {{{provider}}} 启用付款",
    "disable_sending": "已成功禁止 {{{provider}}} 的发送",
    "disable_archiving": "已成功对 {{{provider}}} 禁用存档",
    "disable_contacts": "已成功对 {{{provider}}} 禁用联系人",
    "disable_payments": "已成功对 {{{provider}}} 禁用付款",
    "errorToggle": "错误。无法为 {{{provider}}} 修改 {{{service}}}!",
    "errorRetrieve": "错误。无法检索文档。",
    "errorProviderRetrieve": "错误。无法检索 {{{provider}}} 文档。"
  };
  _exports.integrationDocuments = integrationDocuments;
});