define("ember-rs/locales/ja/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archivedMigratedModal = void 0;
  var archivedMigratedModal = {
    "expiredBilling": "請求が期限切れです",
    "updateBillingDetails": "支払情報が最新ではありません。サービスの使用を続けるには、以下をクリックして請求の詳細を更新してください。",
    "clickUpdate": "更新",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>サポートに連絡</a>",
    "logout": "ログアウト"
  };
  _exports.archivedMigratedModal = archivedMigratedModal;
});