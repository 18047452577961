define("ember-rs/locales/es/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "documents": "Documentos",
    "noResults": "No hay resultados de búsqueda",
    "tryAgain": "Su búsqueda no arrojó resultados. Intente otro patrón de búsqueda para encontrar su documento.",
    "anotherFilter": "El filtro que ha elegido no arrojó resultados. Inténtelo con otro filtro.",
    "documentsTip": "Aquí es donde están sus documentos firmados y pendientes. Cree su primer documento para comenzar.",
    "questions": "¿Tiene alguna pregunta? Encuentre ayuda aquí.",
    "gotIt": "De acuerdo",
    "startDocument": "Iniciar documento",
    "userGuide": "Guía del usuario"
  };
  _exports.documents = documents;
});