define("ember-rs/locales/fr/templates/reports", ["exports", "ember-rs/locales/fr/templates/reports/export"], function (_exports, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    export: _export.exportTrans,
    "header": "Rapports",
    "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
    "select-report-type--text": "Sélectionner type de rapport",
    "select-report-type-option-overview": "Présentation",
    "overviewReport": "Rapport général",
    "select-report-type-option-efficiency": "Efficacité",
    "efficiencyReport": "Rapport d'efficacité",
    "select-report-type-option-cycle-time": "Rapport sur la durée des cycles",
    "select-report-type-option-export": "Exportateur de données",
    "select-report-type-option-enterprise": "Enterprise",
    "enterpriseUsageReport": "Rapport d'utilisation de l'entreprise",
    "userUsageReport": "Rapport d'utilisation utilisateur",
    "select-report-type-option-user": "Nouveau rapport d'utilisation",
    "components": {
      "account-document-volume": {
        "header": "Rapports",
        "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
        "legend-document-sent": "Document envoyé",
        "legend-document-signed": "Document signé",
        "legend": "Légende :"
      },
      "account-statistics": {
        "header": "Rapports",
        "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
        "column-header-current-month": "Ce mois-ci",
        "column-header-previous-month": "Le mois dernier",
        "column-header-average": "Moyenne",
        "row-header-sent-documents": "Documents envoyés",
        "row-header-viewed": "Consultés (par au moins un signataire)",
        "row-header-expired": "Expiré",
        "row-header-signed": "Signé",
        "row-header-sent-documents-per-user": "Documents envoyés par utilisateur (moyenne)",
        "row-header-signed-documents": "Documents signés",
        "row-header-pending-documents": "Documents en attente",
        "row-header-viewed-pending": "Consultés (en attente)",
        "none": "S/O"
      },
      "top-users-by-signed-documents": {
        "header": "Rapports",
        "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
        "column-header-user": "Utilisateur",
        "column-header-signed-documents": "Documents signés"
      },
      "account-efficiency": {
        "header": "Rapports",
        "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
        "legend-pending": "En attente",
        "legend-signed": "Signé",
        "legend-expired": "Expiré"
      },
      "top-users-by-efficiency": {
        "header": "Rapports",
        "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
        "column-header-user": "Utilisateur",
        "column-header-efficiency-percentage": "Efficacité (%)",
        "column-header-ratio": "Ratio",
        "over-gcd": "{{signed}} sur {{sent}}"
      },
      "top-users-by-time-to-sign": {
        "header": "Rapports",
        "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
        "column-header-user": "Utilisateur",
        "column-header-mean-time": "Temps moyen (heures)"
      },
      "average-cycle-time": {
        "header": "Rapports",
        "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
        "legend-mean-time-to-view": "Durée moyenne de consultation",
        "legend-mean-time-to-sign": "Durée moyenne de signature"
      },
      "cycle-time-statistics": {
        "header": "Rapports",
        "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
        "column-header-current-month": "Ce mois-ci",
        "column-header-previous-month": "Le mois dernier",
        "column-header-all-time": "Toute période",
        "row-header-under-5-mins": "Moins de 5 minutes",
        "row-header-under-30-mins": "Moins de 30 minutes",
        "row-header-under-1-hour": "Moins d'1 heure",
        "row-header-under-12-hours": "Moins de 12 heures",
        "row-header-under-1-day": "Moins d'1 jour"
      },
      "month-export": {
        "header": "Rapports",
        "description": "Affichez les données liées aux documents envoyés. Cela inclut généralement le temps requis pour signer les documents ainsi que le nombre de documents complétés par mois. Mettez à niveau votre plan pour accéder aux rapports.",
        "export-to-csv-section-header": "Exporter au format CSV",
        "export-to-csv-section-description": "Un fichier CSV peut être ouvert dans Excel pour afficher des données relatives aux documents que vous avez envoyés."
      },
      "generate-download": {
        "ie-warning-text": "Cliquez avec le bouton droit sur « Exporter CSV » et choisissez Enregistrer sous pour télécharger le fichier CSV.",
        "export-csv-button-text": "Exporter CSV",
        "generate-csv-button-text": "Générer CSV",
        "send-download-link": "Nous allons générer le rapport et envoyer le lien de téléchargement par e-mail."
      }
    }
  };
  _exports.reports = reports;
});