define("ember-rs/locales/ja/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationSettingsService = void 0;
  var integrationSettingsService = {
    "requiresInfo": "この統合を続行する前に情報が必要です:",
    "authorize": "承認",
    "sending": "RightSignature から電子署名用に {{{formattedProviderName}}} ドキュメントを送信する",
    "archiving": "完了したドキュメントの PDF を {{{formattedProviderName}}} アカウントの特定のフォルダーに送信する",
    "contacts": "ドキュメントの署名用に {{{formattedProviderName}}} のすべての連絡先を使用できるようにする",
    "payments": "RightSignature ドキュメントに支払い用コンポーネントを追加して、クレジットカード情報を安全に収集、格納し、{{{formattedProviderName}}} アカウント経由で請求を承認します",
    "fieldsInfo": "すべてのフィールドに記入してください"
  };
  _exports.integrationSettingsService = integrationSettingsService;
});