define("ember-rs/locales/nl/templates/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "addRoles": "Rollen toevoegen",
    "whatRoles": "Welke rollen heeft deze sjabloon nodig?",
    "signOrder": "Volgorde van ondertekening instellen",
    "uniqueName": "U moet unieke rolnamen toewijzen.",
    "addRole": "Rol toevoegen",
    "sendRole": "Rol van afzender toevoegen",
    "next": "Volgende stap: Velden plaatsen"
  };
  _exports.addRoles = addRoles;
});