define("ember-rs/locales/ja/templates/components/rs-more-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsMoreOptions = void 0;
  var rsMoreOptions = {
    "MoreOptions": "More Options"
  };
  _exports.rsMoreOptions = rsMoreOptions;
});