define("ember-rs/locales/es/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentityExpired = void 0;
  var confirmIdentityExpired = {
    "linkExpired": "Enlace caducado",
    "resendIdentityConfirmation": "Volver a enviar el correo electrónico de confirmación de identidad.",
    "resendIdentityVerification": "Volver a enviar la verificación de identidad",
    "contactSender": "Póngase en contacto con el remitente del documento para solicitar un enlace actualizado."
  };
  _exports.confirmIdentityExpired = confirmIdentityExpired;
});