define("ember-rs/locales/es/templates/onboarding/migration-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.migrationOnboarding = void 0;
  var migrationOnboarding = {
    "migrationHeading": "¡Descubra lo último de RightSignature!",
    "slide1Q": "Novedades",
    "slide1a": "Funciones de seguridad avanzadas:",
    "slide1b": "Autenticación basada en conocimientos, acceso mediante código de acceso obligatorio y más.",
    "slide1c": "Usabilidad mejorada:",
    "slide1d": "Un nuevo diseño optimizado, personalización mejorada, notificaciones de productividad y funcionalidad móvil mejoradas.",
    "slide2": "¿Dónde están los documentos de la antigua versión de RightSigntature?",
    "slide3": "¿Dónde están las plantillas de la antigua versión de RightSigntature?",
    "slide4": "¿Cómo me convierto a un plan de pago en la versión más reciente de RightSignature?",
    "continue": "Continuar",
    "dismiss": "Descartar"
  };
  _exports.migrationOnboarding = migrationOnboarding;
});