define("ember-rs/locales/nl/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareNextRoute = void 0;
  var prepareNextRoute = {
    "mergeFieldStepNext": "Volgende: samenvoegvelden",
    "documentStepNext": "Volgend document",
    "reviewStepNext": "Volgende stap: Controleren",
    "placeFieldNext": "Volgende stap: Velden plaatsen"
  };
  _exports.prepareNextRoute = prepareNextRoute;
});