define("ember-rs/authenticators/sharefile", ["exports", "jquery", "ember-simple-auth/authenticators/base", "ember-rs/config/environment"], function (_exports, _jquery, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    store: Ember.inject.service(),
    restore: function restore(data) {
      return Ember.RSVP.resolve(data, 'sharefile restore session');
    },
    // auth_options:
    // * code - (Required) OAuth code
    // * subdomain - (Optional)
    // * appcp - (Optional)
    authenticate: function authenticate(auth_options) {
      var _this = this;

      if (Ember.isBlank(auth_options.code)) {
        return Ember.RSVP.reject('No ShareFile code on callback!', 'sharefile authenticate - no code');
      } // Remove when ShareFile passes the subdomain on all requests.
      // It is required when doing control plane lookup.


      if (Ember.isBlank(auth_options.subdomain) && Ember.isPresent(document.referrer)) {
        var link = document.createElement('a');
        link.href = document.referrer;
        auth_options.subdomain = link.hostname.match(/(.*)\.(.*)\.\w{2,}/)[1];
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/sharefile/login"),
          data: auth_options,
          type: 'POST',
          success: function success(response) {
            _this.store.pushPayload(response);

            resolve(response.session_credential);
          },
          error: reject
        });
      }, 'sharefile authentication');
    },
    invalidate: function invalidate() {
      return Ember.RSVP.resolve(null, 'sharefile invalidate session');
    }
  });

  _exports.default = _default;
});