define("ember-rs/locales/es/templates/onboarding/welcome-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding2 = void 0;
  var welcomeOnboarding2 = {
    "tips": "Acceda a sus <span>documentos</span> y <span>plantillas</span>, <span>administre parámetros</span>, y encuentre <span>tutoriales de vídeo y artículos de ayuda para procedimientos</span>."
  };
  _exports.welcomeOnboarding2 = welcomeOnboarding2;
});