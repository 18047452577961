define("ember-rs/locales/it/templates/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "share": "Condividi",
    "status": "Stato",
    "overview": "Panoramica",
    "sent": "inviato",
    "people_involved": "Persone coinvolte:",
    "reference_id": "ID di riferimento: ",
    "manage": "Gestisci",
    "tags": "Contrassegni: "
  };
  _exports.details = details;
});