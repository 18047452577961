define("ember-rs/routes/document/prepare-signers", ["exports", "ember-rs/mixins/prepare-next-route"], function (_exports, _prepareNextRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_prepareNextRoute.default, {
    session: Ember.inject.service(),
    titleToken: 'Prepare Signers',
    currentStep: 'prepare_signers',
    beforeModel: function beforeModel() {
      this.controllerFor('document-transaction').set('currentStep', this.currentStep);
    },
    afterModel: function afterModel(doc) {
      var _this = this;

      if (!Ember.get(this, 'currentUser.canSendDocuments') && doc.get('workflow') !== 'self_sign') {
        this.transitionTo('documents');
        return;
      } // if this is not the first, copy over the signers from the last document


      var documents = doc.get('documentTransaction.sortedDocuments');
      var currentIndex = documents.indexOf(doc);
      var docToCopy = documents.objectAt(currentIndex - 1) || null;

      if (currentIndex > 0 && Ember.get(doc, 'roles.length') <= 0) {
        docToCopy.get('roles').forEach(function (role) {
          var senderRole = doc.newRole();
          senderRole.setProperties({
            signerName: role.get('signerName'),
            signerEmail: role.get('signerEmail')
          });
        }); // otherwise, if we have the setting, set the first signer as self
      } else if (this.get('currentUser.model.senderAsSigner') && !doc.get('documentTransaction.isSelfSign') && !doc.get('fromReusableTemplate')) {
        var senderRole = doc.newRole();
        senderRole.setProperties({
          signerName: this.get('currentUser.model.name'),
          signerEmail: this.get('currentUser.model.email')
        });
        doc.newRole();
      } // templates get some other business


      if (doc.get('fromReusableTemplate')) {
        if (doc.get('senderRole')) {
          // prefill isSender...
          doc.get('senderRole').set('signerName', this.get('currentUser.model.name'));
          doc.get('senderRole').set('signerEmail', this.get('currentUser.model.email'));
        }
      } else {
        var documentTransaction = doc.get('documentTransaction');

        if (documentTransaction.get('isSelfSign')) {
          // Add single role that is currentUser
          doc.get('roles').forEach(function (r) {
            r.set('documentTemplate', null);
          });
          var role = doc.newRole();

          if (Ember.isPresent(this.get('currentUser.model.name'))) {
            role.set('signerName', this.get('currentUser.model.name'));
          } else {
            role.set('signerName', 'Self Signer');
          }

          return doc.save().then(function () {
            _this.transitionTo('document.prepare_document');
          });
        } else if (documentTransaction.get('isSignatureRequest') || documentTransaction.get('isInPerson') || documentTransaction.get('isPackage')) {
          var sharefileRedirect = this.get("session.data.sharefileRedirect");

          if (this.get("session.data.sharefileRedirect.recipients")) {
            var recipients = sharefileRedirect.recipients;

            if (recipients) {
              sharefileRedirect.recipients = null;
              this.session.set('data.sharefileRedirect', sharefileRedirect);
              recipients.sortBy("ordinal").forEach(function (recipient) {
                if (recipient.role === "signer") {
                  doc.newRole({
                    signerName: recipient.name,
                    signerEmail: recipient.email
                  });
                } else {
                  doc.get("sharedWith").pushObject(recipient.email);
                }
              });
            }
          } else if (doc.get('roles.length') === 0) {
            // call the add role action on page load
            doc.newRole();
          }
        }
      }
    },
    // Saving currentTemplate resets role -> document relationships such that
    // role.get('documentTemplate') is null. Since the relationship is specified
    // as `async: false`, Ember assumes this is the result of some issue with our
    // backend and raises an error. TODO: Sync backend and frontend models,
    // serializers, and adapters such that relationship links are preserved. Test
    // by removing this function and trying to send a document for signature.
    syncRoleRelationships: function syncRoleRelationships() {
      var doc = this.controller.get('model');
      doc.get('roles').forEach(function (role) {
        return role.set('documentTemplate', doc);
      });
    },
    actions: {
      didTransition: function didTransition() {
        if (this.get("session.data.sharefileRedirect.skipToPrepare")) {
          this.send("nextStep");
        }

        return true;
      },
      nextStep: function nextStep() {
        var _this2 = this;

        if (this.controller.get('isValid')) {
          this.currentTemplate.save().then(function () {
            _this2.syncRoleRelationships();

            switch (_this2.nextRouteTransition) {
              case 'document.prepare_document':
                _this2.transitionTo('document.prepare_document', _this2.currentTemplate);

                break;

              case 'document.prepare_signers':
                _this2.transitionTo('document.prepare_signers', _this2.nextTemplate);

                break;

              case 'document-transaction.review':
                _this2.transitionTo('document-transaction.review', _this2.documentTransaction);

                break;

              default:
                _this2.transitionTo('document.prepare_document', _this2.currentTemplate);

            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});