define("ember-rs/mixins/passcode-pin-generator", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_PIN_VALUE = 99999;
  var MIN_PIN_VALUE = 10000;

  var _default = Ember.Mixin.create({
    generatePasscodePIN: function generatePasscodePIN() {
      return Math.floor(Math.random() * (MAX_PIN_VALUE - MIN_PIN_VALUE)) + MIN_PIN_VALUE;
    },
    regeneratePasscodePIN: function regeneratePasscodePIN() {
      var _this = this;

      var pin = this.generatePasscodePIN();
      var url = "".concat(_environment.default.api.endpoint, "/documents/").concat(this.get('model.id'), "/update_pin");
      new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          type: 'PUT',
          url: url,
          data: {
            pin: pin
          }
        }).then(function (response) {
          _this.set('model.passcodeAnswer', pin);

          _this.set('showNewPinModal', true);

          resolve(response);
        }, function (response) {
          _this.flashMessages.warning(_this.i18n.t("mixins.passcodePinGenerator.failedGeneratePin", {
            response: JSON.stringify(response)
          }));

          reject(response);
        });
      });
    }
  });

  _exports.default = _default;
});