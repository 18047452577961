define("ember-rs/locales/de/mixins/workflow-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.workflowType = void 0;
  var workflowType = {
    "signatureRequest": "Von Ihnen gesendet",
    "selfSign": "Selbst signiert",
    "templateSignerLink": "Von einer Vorlage",
    "inPerson": "Persönliche Signatur",
    "apiEmbedded": "In API-eingebettete Signatur",
    "bulkSend": "Massensendung"
  };
  _exports.workflowType = workflowType;
});