define("ember-rs/locales/it/templates/components/salesforce-opportunities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.salesforceOpportunities = void 0;
  var salesforceOpportunities = {
    "searchOpportunities": "Cerca opportunità...",
    "searchError": "Errore durante la ricerca di opportunità: {{{resp}}}"
  };
  _exports.salesforceOpportunities = salesforceOpportunities;
});