define("ember-rs/templates/components/rs-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GP+JzZge",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"rs-button__loading-container\"],[12],[2,\"\\n    \"],[8,\"rs-spinner\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/rs-button.hbs"
    }
  });

  _exports.default = _default;
});