define("ember-rs/locales/nl/templates/unauthorized/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unauthorizedFile = void 0;
  var unauthorizedFile = {
    "unauthorized": "Niet gemachtigd",
    "youAreNot": "U bent niet gemachtigd om die pagina te bekijken."
  };
  _exports.unauthorizedFile = unauthorizedFile;
});