define("ember-rs/locales/fr/templates/components/support-when-externally-billed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportWhenExternallyBilled = void 0;
  var supportWhenExternallyBilled = {
    "partnerSale": "Votre compte a été créé via un partenaire commercial. Veuillez contacter l'assistance si vous souhaitez mettre votre compte à niveau."
  };
  _exports.supportWhenExternallyBilled = supportWhenExternallyBilled;
});