define("ember-rs/locales/nl/templates/document-transaction/upload-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload_signers = void 0;
  var upload_signers = {
    "instructions_header": "Volg de onderstaande stappen om uw CSV-bestand te uploaden",
    "step_1": "STAP 1",
    "step_2": "STAP 2",
    "step_3": "STAP 3",
    "step_1_text": "Download de voorbeeldlijst voor ondertekenaar bulkverzending",
    "step_2_text": "Vul de velden voor naam en e-mailadres in (verplicht).",
    "step_3_text": "Upload het voltooide CSV-bestand.",
    "instructions_note": "Let op: het aantal ondertekenaars mag niet groter zijn dan 300",
    "upload_failed": "Upload is mislukt",
    "upload_failure_message": "De upload is mislukt vanwege een beschadigd bestand of een verwerkingsfout. Zorg dat het bestand geldige inhoud heeft en probeer opnieuw te uploaden.",
    "view_all": "Alles weergeven",
    "ok": "OK",
    "validate_and_reupload": "Uw bestand bevat meer fouten. Controleer de validaties en probeer opnieuw te uploaden."
  };
  _exports.upload_signers = upload_signers;
});