define("ember-rs/components/faq-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    questionNumber: 0,
    tagName: 'li',
    classNames: ['qa'],
    classNameBindings: ['questionClassName'],
    questionClassName: Ember.computed('questionNumber', 'showAnswerFor', function () {
      if (Ember.isEqual(this.showAnswerFor, this.questionNumber)) {
        return 'show-answer';
      }

      return '';
    }),
    actions: {
      setAnswer: function setAnswer() {
        this.sendAction('setAnswer', this.questionNumber);
      }
    }
  });

  _exports.default = _default;
});