define("ember-rs/locales/de/controllers/signer/sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    "validPhone": "Geben Sie eine gültige Telefonnummer ein",
    "passcode": "Ungültiger Passcode. Versuchen Sie es noch einmal."
  };
  _exports.sign = sign;
});