define("ember-rs/locales/es/controllers/account/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUser = void 0;
  var newUser = {
    "success": "Usuario {{{searchValue}}} agregado correctamente.",
    "error": "El usuario {{{searchValue}}} no se pudo agregar correctamente."
  };
  _exports.newUser = newUser;
});