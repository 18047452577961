define("ember-rs/controllers/unauthorized", ["exports", "lodash", "ember-rs/config/environment"], function (_exports, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var displayRedirectButtonList = ['signing-link-disabled', 'unauthorized', 'unconfirmed-email', 'no-user', 'unsubscribed'];

  var _default = Ember.Controller.extend({
    marketingHost: _environment.default.marketingHost,
    displayRedirectButton: Ember.computed('router.currentRouteName', function () {
      var pathList = this.get('router.currentRouteName').split('.');
      return _lodash.default.includes(displayRedirectButtonList, pathList[pathList.length - 1]);
    })
  });

  _exports.default = _default;
});