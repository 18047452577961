define("ember-rs/locales/ja/mixins/workflow-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.workflowType = void 0;
  var workflowType = {
    "signatureRequest": "送信済み",
    "selfSign": "自己署名済み",
    "templateSignerLink": "テンプレート利用",
    "inPerson": "対面署名",
    "apiEmbedded": "API 埋め込みによる署名",
    "bulkSend": "一括送信"
  };
  _exports.workflowType = workflowType;
});