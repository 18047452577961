define("ember-rs/locales/it/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding2 = void 0;
  var accountOnboarding2 = {
    "body": "È possibile personalizzare e rendere l'account professionale con <span>l'aggiunta di una foto.</span>",
    "mobileBody": "La foto viene visualizzata nell'e-mail del destinatario e nella pagina di firma dei documenti, in modo che i firmatari possano sentirsi sicuri quando devono firmare."
  };
  _exports.accountOnboarding2 = accountOnboarding2;
});