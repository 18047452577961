define("ember-rs/locales/de/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alreadySigned = void 0;
  var alreadySigned = {
    "thankYou": "Danke für Ihre Unterschrift",
    "youHave": "Sie haben dieses Dokument erfolgreich signiert. Sie können das Dokument jederzeit anzeigen, indem Sie einen sicheren Link anfordern.",
    "sendNewLink": "Neuen Downloadlink senden",
    "successfully": "Sie haben dieses Dokument erfolgreich signiert."
  };
  _exports.alreadySigned = alreadySigned;
});