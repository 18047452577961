define("ember-rs/locales/pt-br/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    "choose": "3. Escolha os dados para exportar",
    "columnNames": "Os nomes das colunas são baseados nos nomes dos componentes no documento:",
    "export": "4. Exportar para CSV",
    "selectColumns": "Selecionar colunas",
    "view": "Pode ser aberto no Excel um CSV para exibir dados nos seus documentos enviados."
  };
  _exports.template = template;
});