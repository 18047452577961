define("ember-rs/locales/en/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploader = void 0;
  var documentUploader = {
    "multiFile": "Multi-file upload not supported.  Only the first file selected will be uploaded.",
    "alreadyUploaded": "You already uploaded this file!",
    "fileSize": "The file {{{fileName}}} is bigger than our 20MB limit.",
    "uploadFailed": "Failed to create Upload"
  };
  _exports.documentUploader = documentUploader;
});