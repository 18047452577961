define("ember-rs/controllers/account/branding", ["exports", "jquery", "ember-rs/mixins/branding", "ember-rs/config/environment"], function (_exports, _jquery, _branding, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_branding.default, {
    ////////////////////////  DEFAULTS  ////////////////////////
    flashMessages: Ember.inject.service(),
    showLogo: false,
    isUploadingLogo: false,
    uploadLogoButtonText: 'Upload',
    ////////////////////////  PROPERTIES  ////////////////////////
    brandingSettingsObject: Ember.computed.alias('model'),
    noCompanyName: Ember.computed.empty('model.companyName'),
    showSenderAvatar: Ember.computed.alias('model.showSenderAvatar'),
    footerOption: Ember.computed.alias('model.footerOption'),
    useCustomEmailSignature: Ember.computed.notEmpty("currentUser.model.emailSignature"),
    useCustomEmailGreeting: Ember.computed.notEmpty("currentUser.model.emailGreeting"),
    logoName: Ember.computed('model.logoUrl', function () {
      var logoUrl = Ember.get(this, 'model.logoUrl') || '';
      return logoUrl.split('/').pop().split('?').shift();
    }),
    footerColorStyle: Ember.computed('footerColor', function () {
      return "background: ".concat(this.signingFooterColor, ";").htmlSafe();
    }),
    backgroundColorStyle: Ember.computed('backgroundColor', function () {
      return "background: ".concat(this.signingBackgroundColor, ";").htmlSafe();
    }),
    hasLogoAndShouldDisplay: Ember.computed('model.logoUrl', 'model.displayLogo', function () {
      return Ember.isPresent(Ember.get(this, 'model.logoUrl')) && Ember.isEqual(Ember.get(this, 'model.displayLogo'), true);
    }),
    showSenderName: Ember.computed('footerOption', function () {
      return this.footerOption === 'sender_name' || this.footerOption === 'both';
    }),
    showCompanyName: Ember.computed('footerOption', function () {
      return this.footerOption === 'company_name' || this.footerOption === 'both';
    }),
    showCompanyAndSenderName: Ember.computed('footerOption', function () {
      return this.footerOption === 'both';
    }),
    showEmailCompanyName: Ember.computed('footerOption', function () {
      return this.footerOption !== 'sender_name';
    }),
    //////////////////////////// OBSERVERS ///////////////////////////////
    setupDefautColors: Ember.observer('model', function () {
      if (Ember.isNone(this.signingBackgroundColor)) {
        this.set('signingBackgroundColor', this.defaultBackgroundColor);
      }

      if (Ember.isNone(this.signingFooterColor)) {
        this.set('signingFooterColor', this.defaultFooterColor);
      }
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleBoolean: function toggleBoolean(value, component) {
        var attr = component.get('attr');
        this.set(attr, value);
      },
      updateEmailSettings: function updateEmailSettings() {
        var _this = this;

        if (!this.useCustomEmailGreeting) {
          this.set("currentUser.model.emailGreeting", '');
        }

        if (!this.useCustomEmailSignature) {
          this.set("currentUser.model.emailSignature", '');
        }

        return _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/users/").concat(Ember.get(this, 'currentUser.model.id'), "/settings"),
          type: 'PUT',
          data: {
            settings: {
              email_greeting: Ember.get(this, "currentUser.model.emailGreeting"),
              email_signature: Ember.get(this, "currentUser.model.emailSignature"),
              show_email_sender_name_in_from: Ember.get(this, "currentUser.model.showEmailSenderNameInFrom"),
              show_email_subject_document_filename: Ember.get(this, "currentUser.model.showEmailSubjectDocumentFilename"),
              show_email_subject_company_name: Ember.get(this, "currentUser.model.showEmailSubjectCompanyName")
            }
          }
        }).then(function () {
          _this.flashMessages.success(_this.i18n.t('controllers.account.branding.emailUpdateSuccess'));
        }, function () {
          _this.flashMessages.warning(_this.i18n.t('controllers.account.branding.emailUpdateFail'));
        });
      },
      updateBranding: function updateBranding() {
        var _this2 = this;

        return _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/account/branding"),
          type: 'PUT',
          data: {
            branding_setting: {
              signing_background_color: this.backgroundColor,
              signing_footer_color: this.footerColor,
              show_sender_avatar: this.showSenderAvatar,
              footer_option: this.footerOption,
              display_logo: this.displayLogo
            }
          }
        }).then(function () {
          _this2.flashMessages.success(_this2.i18n.t('controllers.account.branding.updateSuccess'));
        }, function () {
          _this2.flashMessages.warning(_this2.i18n.t('controllers.account.branding.updateFail'));
        });
      }
    }
  });

  _exports.default = _default;
});