define("ember-rs/locales/nl/templates/components/review-and-send-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewAndSendItem = void 0;
  var reviewAndSendItem = {
    "passcode-message": "Vergeet niet om deze PIN naar de ondertekenaars van het document te sturen.",
    "bulk-send-passcode-message": "U hebt wachtwoordcode ingeschakeld voor documenttransacties. Dit wordt momenteel niet ondersteund voor bulkverzendingen.",
    "generate-new-pin": "Nieuwe PIN genereren",
    "pin-specification": "De PIN moet een nummer van vijf cijfers zijn.",
    "expiration-message": "Na de vervaldatum is het document niet meer beschikbaar om te ondertekenen.",
    "ExpiresIn": "Verloopt over",
    "days": "dagen",
    "loading": "bezig met laden",
    "page": "pagina('s)",
    "emailTip1": "Een koppeling om dit document te bekijken wordt verzonden naar {{{email}}}.",
    "emailTip2": "De ontvanger kan dit document weergeven en downloaden.",
    "enterEmail": "Voer e-mailadres in",
    "shareDoc": "Voer een e-mailadres in om dit document te delen",
    "ccMsg": "Voeg partijen onder CC toe om ze alleen-lezen toegang tot dit document te geven. Ze ontvangen meldingen, kunnen de voortgang bekijken en het voltooide document downloaden. U kunt de toegang indien nodig later intrekken.",
    "add": "toevoegen",
    "roles": "rollen",
    "recipients": "ontvangers",
    "cc": "cc: ",
    "ccCaps": "CC",
    "messageLimit": "Het bericht mag niet meer dan 100.000 tekens bevatten.",
    "addMsg": "Voeg een persoonlijk bericht toe...",
    "for": "voor",
    "message": "bericht",
    "pin": "PIN",
    "tags": "tags",
    "passcodeOn": "Wachtwoordcode Aan",
    "passcodeOff": "Wachtwoordcode Uit",
    "restrictedFeatureMsg": "Met uw huidige abonnement kunt u geen wachtwoordcodes aan documenten toevoegen. Upgrade uw abonnement om toegang tot deze functie te krijgen.",
    "kba": "KBA",
    "on": "Aan",
    "off": "Uit",
    "kbaTip": "Ondertekenaars moeten hun identiteit verifiëren met een op kennis gebaseerde quiz. Elke ondertekenaar moet een onafhankelijke verificatie doorlopen voordat hij/zij het document ondertekent. Na drie mislukte pogingen wordt het document voor ondertekenaars vergrendeld.",
    "enable": "Inschakelen",
    "addTag": "Tag toevoegen",
    "tagTip": "Voeg tags toe aan dit document om het later gemakkelijk te vinden. U kunt ook waarden aan tags toevoegen, bijvoorbeeld \"client_id: 1234\"",
    "addSalesforceOpportunity": "Salesforce-verkoopkans toevoegen",
    "addSalesforceOpportunityMsg": "Een Salesforce-verkoopkans aan dit document toevoegen",
    "exceededKbaLimit": "KBA-limiet bereikt",
    "exceededKbaMsg": "U hebt de maandelijkse limiet van uw huidige abonnement bereikt voor documenten met op kennis gebaseerde verificatie (KBA). Als u meer documenten wilt verzenden met de KBA-functie ingeschakeld, neemt u <a href='https://support.citrix.com/cscase'>contact op met Support</a> om uw account te upgraden.",
    "tagName": "Tagnaam...",
    "tagValue": "Waarde (optioneel)",
    "sendTo": "Verzenden naar",
    "name": "Naam",
    "docName": "Documentnaam...",
    "totalNumberOfSigners": "Totaal aantal ondertekenaars:",
    "expiresIn": "De verloopdatum moet tussen 1 en 365 dagen zijn."
  };
  _exports.reviewAndSendItem = reviewAndSendItem;
});