define("ember-rs/templates/components/void-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5H3w2tdq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"void-document-modal__header\"],[12],[1,[30,[36,0],[\"components.voidDocumentModal.header\"],null]],[13],[2,\"\\n\\n\"],[10,\"p\"],[14,0,\"void-document-modal__info\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.voidDocumentModal.voidConfirmationInfo\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[12],[2,\"\\n  \"],[8,\"textarea\",[[24,0,\"void-document-modal__reason-input\"]],[[\"@value\",\"@placeholder\"],[[34,1],[30,[36,0],[\"components.voidDocumentModal.optionalVoidMessage\"],null]]],null],[2,\"\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"void-document-modal__actions\"],[12],[2,\"\\n  \"],[8,\"rs-button\",[],[[\"@action\",\"@isLoading\"],[[30,[36,2],[[32,0],\"void\"],null],[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.voidDocumentModal.void\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"rs-button\",[],[[\"@action\",\"@isPrimary\",\"@isDisabled\"],[[30,[36,2],[[32,0],\"close\"],null],false,[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.voidDocumentModal.cancel\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"voidReason\",\"action\",\"voiding\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/void-document-modal.hbs"
    }
  });

  _exports.default = _default;
});