define("ember-rs/locales/nl/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserForm = void 0;
  var newUserForm = {
    "email": "E-mail",
    "name": "Naam",
    "password": "Wachtwoord",
    "hidePassword": "Wachtwoord verborgen",
    "create": "Account maken",
    "haveAccount": "Hebt u een account?"
  };
  _exports.newUserForm = newUserForm;
});