define("ember-rs/locales/es/mixins/integration-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationAuth = void 0;
  var integrationAuth = {
    "unableAuthorize": "No se puede autorizar la integración.",
    "notComplete": "No se pudo completar la autorización"
  };
  _exports.integrationAuth = integrationAuth;
});