define("ember-rs/templates/onboarding/welcome-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O7jun45s",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"start-button\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"button-wrapper\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,6,\"#\"],[14,0,\"button\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"application.startDocument\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding-new/overlay-arrow.svg\"],[14,0,\"onboarding-arrow\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/overlay-arrow.png\"],[15,\"alt\",[30,[36,0],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"onboarding.welcomeOnboarding3.start\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/welcome-onboarding-3.hbs"
    }
  });

  _exports.default = _default;
});