define("ember-rs/locales/zh-cn/templates/components/reminder-frequency-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reminderFrequency = void 0;
  var reminderFrequency = {
    "noAuto": "无自动提醒",
    "daily": "每天",
    "weekly": "每周"
  };
  _exports.reminderFrequency = reminderFrequency;
});