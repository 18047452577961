define("ember-rs/locales/it/templates/upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgrade = void 0;
  var upgrade = {
    "noUpgrade": "Impossibile aggiornare l'account",
    "noPermission": "Sembra che non si disponga dell'autorizzazione per aggiornare l'account. Rivolgersi all'amministratore dell'account riguardo all'aggiornamento a un nuovo piano RightSignature.",
    "upgrade": "Aggiorna l'account",
    "upgradeSubtitle": "Durante la prova è stato utilizzato il piano Business. È possibile mantenere questo piano o scegliere un altro piano più adatto alle proprie esigenze.",
    "choosePlan": "1. Scegliere un piano",
    "allInOne": "La soluzione completa per la firma elettronica",
    "standard": "Funzionalità standard ",
    "usersNeeded": "2. Quanti utenti servono?",
    "checkout": "Pagamento",
    "showLess": "Mostra meno",
    "showMore": "Mostra altro",
    "users": "Utenti",
    "confirm": "Premere Invio per confermare",
    "billing": "Fatturazione",
    "billingDetails": "Dettagli fatturazione",
    "noOfUser": "Numero di utenti",
    "billAmount": "Importo fatturabile",
    "creditAmount": "Importo del credito da RightSignature precedente",
    "total": "Totale",
    "taxCharges": "possono essere applicabili oneri fiscali aggiuntivi",
    "oops": "Ops! Qualcosa è andato storto",
    "dataUnavailable": "I dati al momento non sono disponibili. Si prega di tornare a questa pagina più tardi."
  };
  _exports.upgrade = upgrade;
});