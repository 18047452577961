define("ember-rs/routes/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this.controllerFor('reusable-template').set('currentStep', 'prepare_signers');
    },
    afterModel: function afterModel(model) {
      if (model.get('roles.length') === 0) {
        model.newRole({
          name: this.i18n.t('routes.reusableTemplate.addRoles.signer1').string
        });
      }
    }
  });

  _exports.default = _default;
});