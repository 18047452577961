define("ember-rs/locales/it/controllers/account/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUser = void 0;
  var newUser = {
    "success": "Utente {{{searchValue}}} aggiunto correttamente.",
    "error": "Impossibile aggiungere correttamente l'utente {{{searchValue}}}."
  };
  _exports.newUser = newUser;
});