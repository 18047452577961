define("ember-rs/locales/pt-br/templates/components/integration-document-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocumentSelector = void 0;
  var integrationDocumentSelector = {
    "chooseDocFrom": "Escolha um documento de:",
    "loading": "Carregando...",
    "back": "Voltar",
    "search": "Pesquisar documentos...",
    "use": "Usar",
    "empty": "Esta pasta está vazia",
    "success": "Envio desativado com sucesso para {{{provider}}}",
    "error": "Erro. Não foi possível modificar o envio de {{{provider}}}"
  };
  _exports.integrationDocumentSelector = integrationDocumentSelector;
});