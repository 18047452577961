define("ember-rs/locales/fr/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.application = void 0;
  var application = {
    "logout": "Déconnexion",
    "start": "Début",
    "startDocument": "Démarrer document",
    "help": "Aide"
  };
  _exports.application = application;
});