define("ember-rs/locales/it/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers = void 0;
  var prepare_signers = {
    "add_signers": "Aggiungi firmatari",
    "doc_limit_p1": "È stato utilizzato ",
    "doc_limit_p2": "del limite di documenti per il mese. Contattarci per aggiornare l'account.",
    "recentContacts": "Contatti recenti"
  };
  _exports.prepare_signers = prepare_signers;
});