define("ember-rs/locales/fr/routes/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    "embeddedTemplate": "Votre compte n'a pas accès à cette fonctionnalité. Passez à un abonnement Pro pour pouvoir utiliser des modèles intégrés.",
    "inPerson": "Votre compte n'a pas accès à cette fonctionnalité. Passez à un plan Pro pour pouvoir utiliser la signature en personne."
  };
  _exports.startDocument = startDocument;
});