define("ember-rs/locales/de/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "notAllowed": "Aktion nicht zulässig",
    "noPro": "Sie haben derzeit keinen PRO-Zugriff auf dieses Konto. Sie können Ihre Dokumente signieren und anzeigen, aber PRO-Funktionen sind nicht verfügbar. Kontaktieren Sie einen Administrator dieses Kontos, um Ihnen PRO-Zugriff zu gewähren. Sie haben dann folgende Möglichkeiten:",
    "send": "Dokumente zur Signatur senden",
    "create": "Vorlagen erstellen und verwenden",
    "customize": "Branding anpassen",
    "enable": "Drittanbieter-Integrationen aktivieren",
    "reports": "Berichte anzeigen",
    "login": "Bei RightSignature anmelden"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});