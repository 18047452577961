define("ember-rs/locales/pt-br/templates/onboarding/post-sign-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding2 = void 0;
  var postSignOnboarding2 = {
    "viewDoc": "Aqui é onde você pode ver o <span>status</span> do documento",
    "downloadDoc": "Aqui é onde você pode <span>baixar</span> o documento."
  };
  _exports.postSignOnboarding2 = postSignOnboarding2;
});