define("ember-rs/locales/it/templates/onboarding/migration-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.migrationOnboarding = void 0;
  var migrationOnboarding = {
    "migrationHeading": "Esplora la versione più recente di RightSignature",
    "slide1Q": "Novità",
    "slide1a": "Funzioni di sicurezza avanzate:",
    "slide1b": "Autenticazione basata sulla conoscenza, accesso tramite passcode obbligatorio e altro ancora.",
    "slide1c": "Miglioramento dell'usabilità:",
    "slide1d": "Nuovo layout semplificato, migliore personalizzazione, notifiche di produttività e funzionalità per i dispositivi mobili migliorate.",
    "slide2": "Dove sono i documenti della versione precedente di RightSigntature?",
    "slide3": "Dove sono i modelli della versione precedente di RightSigntature?",
    "slide4": "Come è possibile passare a un piano a pagamento con la versione più recente di RightSignature?",
    "continue": "Continua",
    "dismiss": "Ignora"
  };
  _exports.migrationOnboarding = migrationOnboarding;
});