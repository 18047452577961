define("ember-rs/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zVvpVR9P",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.pendingIdentityVerification.pendingIdentityVerification\"],null]],[13],[2,\"\\n\\n\"],[11,\"form\"],[4,[38,1],[[32,0],\"resendIdentityVerification\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.pendingIdentityVerification.success\"],null]],[13],[2,\"\\n\\n  \"],[10,\"button\"],[15,\"disabled\",[34,2]],[14,4,\"submit\"],[12],[1,[30,[36,0],[\"unauthorized.pendingIdentityVerification.resend\"],null]],[2,\"n\"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"identityVerificationSent\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/pending-identity-verification.hbs"
    }
  });

  _exports.default = _default;
});