define("ember-rs/locales/ja/controllers/document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "notAllowed": "この操作は許可されていません。",
    "voided": "ドキュメントが取り消されました。",
    "voidingFailed": "ドキュメントの取り消しに失敗しました。",
    "docExtended": "ドキュメントの有効期限が延長されました。",
    "tagUpdated": "タグを更新しました。",
    "tagUpdateFailed": "タグの更新に失敗しました: {{{tags}}} ",
    "viewerListUpdated": "閲覧者一覧を更新しました。",
    "viewerUpdateFailed": "閲覧者一覧の更新に失敗しました: {{{viewers}}}"
  };
  _exports.details = details;
});