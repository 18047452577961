define("ember-rs/locales/nl/templates/components/unconfirmed-user-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedUserButton = void 0;
  var unconfirmedUserButton = {
    "emailUnverified": "Uw e-mailadres is niet geverifieerd",
    "verifySendDocument": "Voordat u uw eerste document kunt verzenden, moeten we uw e-mailadres verifiëren.",
    "verifyCreateTemplate": "Voordat u uw eerste sjabloon kunt maken, moeten we uw e-mailadres verifiëren.",
    "verifyUpgrade": "Voordat u uw hybris-account kunt upgraden, moeten we uw e-mailadres verifiëren.",
    "verifyIntegration": "Voordat u een integratie kunt inschakelen, moeten we uw e-mailadres verifiëren.",
    "verifyEmail": "We hebben een verificatie-e-mail naar het onderstaande adres verzonden.",
    "checkEmail": "Heb u de e-mail niet ontvangen? Controleer uw map Ongewenste e-mail of",
    "resend": "Opnieuw verzenden",
    "verificationMail": "Verificatie-e-mail is verzonden. Controleer {{{email}}} en volg de instructies."
  };
  _exports.unconfirmedUserButton = unconfirmedUserButton;
});