define("ember-rs/locales/es/templates/document/details", ["exports", "ember-rs/locales/es/templates/document/details/unlock"], function (_exports, _unlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    unlock: _unlock.unlock,
    "status": "Estado",
    "overview": "Vista general",
    "history": "Historial",
    "people_involved": "Personas involucradas:",
    "manage": "Administrar",
    "security_options": "Opciones de seguridad:",
    "declined": "Rechazada",
    "expired": "Caducado",
    "expires": "Caduca",
    "extend": "Prolongar",
    "pin": "PIN  *****",
    "generate_pin": "Generar PIN nuevo",
    "pin_tip": "Generar un nuevo PIN creará uno nuevo, y el PIN actual dejará de ser válido.",
    "passcode_question": "Pregunta del código de acceso:",
    "clear_attempts": "Borrar intentos del código de acceso",
    "passcode": "Código de acceso",
    "signer_sequencing": "Secuencia de firma",
    "lock_message": "Este documento ha sido bloqueado.",
    "attachments": "Datos adjuntos:",
    "reference_id": "ID de referencia: ",
    "tags": "Etiquetas: ",
    "remind_header": "Enlace de firma enviado",
    "remind_body": "Hemos enviado el enlace para firmar este documento a la dirección de correo electrónico siguiente. Para omitir este paso en el futuro, puede verificar esta dirección de correo electrónico en sus parámetros.",
    "new_pin": "Nuevo PIN",
    "new_passcode": "Aquí está su nuevo PIN. Debe enviar este PIN actualizado a los firmantes del documento, para que puedan acceder a él.",
    "done": "Listo",
    "draft": "Borrador",
    "pending": "Pendiente",
    "voided": "Anulado",
    "executed": "Ejecutado",
    "recipient_message": "Mensaje a los destinatarios:",
    "void_reason": "Motivo de la anulación:",
    "document_voided": "Documento anulado:",
    "document_declined": "Motivo del rechazo:"
  };
  _exports.details = details;
});