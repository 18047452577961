define("ember-rs/locales/nl/mixins/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.settings = void 0;
  var settings = {
    "updateSuccessful": "De instelling is bijgewerkt",
    "updateError": "Fout bij het bijwerken van instelling"
  };
  _exports.settings = settings;
});