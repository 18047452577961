define("ember-rs/locales/de/mixins/distribution-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.distributionMethod = void 0;
  var distributionMethod = {
    "viaEmail": "Einladung per E-Mail senden",
    "manual": "Links manuell verteilen",
    "email": "E-Mail",
    "sms": "SMS",
    "none": "Ohne"
  };
  _exports.distributionMethod = distributionMethod;
});