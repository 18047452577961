define("ember-rs/locales/es/mixins/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "successUpgrade": "La cuenta se actualizó correctamente. ¡Le damos la bienvenida a RightSignature!",
    "dataUnavailable": "Los datos no están disponibles en este momento. Vuelva a esta página más tarde."
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});