define("ember-rs/locales/de/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.application = void 0;
  var application = {
    "logout": "Abmelden",
    "start": "Start",
    "startDocument": "Dokument starten",
    "help": "Hilfe"
  };
  _exports.application = application;
});