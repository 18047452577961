define("ember-rs/locales/ja/templates/unauthorized/unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unsubscribed = void 0;
  var unsubscribed = {
    "unsubscribed": "サブスクリプションが解除されました",
    "unsubscribedTip": "このドキュメントの通知受信先から削除されました。その他のドキュメントの通知は、引き続き受信します。"
  };
  _exports.unsubscribed = unsubscribed;
});