define("ember-rs/locales/es/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newClientApplication = void 0;
  var newClientApplication = {
    "appReview": "La solicitud se está revisando. Le avisaremos o aprobaremos su solicitud en un plazo de 24 horas.",
    "secretsSuccess": "Secretos obtenidos. Solo usted o personas de confianza deben utilizarlos.",
    "secretsError": "No se pudieron obtener los secretos: {{{error}}}",
    "tokenSuccess": "Nuevo token de API privada generado correctamente",
    "tokenError": "No se pudo generar el token: {{{error}}}",
    "singleSecretSuccess": "Secreto obtenido para {{{clientAppName}}}. Solo usted o personas de confianza deben utilizarlo.",
    "singleSecretError": "No se pudo obtener el secreto: {{{error}}}",
    "tokenRetrieveSuccess": "Token de API privada obtenido para {{{clientAppName}}}. Solo usted o personas de confianza deben utilizarlo.",
    "tokenRetrieveError": "No se pudo obtener el token de API privada: {{{error}}}",
    "tokenRegenerateError": "No se pudo regenerar el token: {{{error}}}",
    "confirmInfo": "¿Seguro? También puede solicitar una nueva clave de API, ya que los tokens de API privada sobrescritos no se pueden obtener una vez regenerados. Si decide continuar, debe quitar y reemplazar las credenciales obsoletas en todas sus integraciones."
  };
  _exports.newClientApplication = newClientApplication;
});