define("ember-rs/locales/fr/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "documents": "Documents",
    "noResults": "Aucun résultat trouvé",
    "tryAgain": "Votre recherche n'a donné aucun résultat. Effectuez une autre recherche pour trouver votre document.",
    "anotherFilter": "Le filtre que vous avez choisi n'a donné aucun résultat. Essayez avec un autre filtre.",
    "documentsTip": "Endroit dans lequel vos documents signés et en attente de signature sont conservés. Créez votre premier document pour commencer.",
    "questions": "Vous avez une question ? Vous trouverez plus d’aide ici.",
    "gotIt": "OK",
    "startDocument": "Démarrer document",
    "userGuide": "Guide de l’utilisateur"
  };
  _exports.documents = documents;
});