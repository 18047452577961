define("ember-rs/transforms/object", ["exports", "jquery", "@ember-data/serializer/transform"], function (_exports, _jquery, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(value) {
      if (!_jquery.default.isPlainObject(value)) {
        return {};
      } else {
        return value;
      }
    },
    serialize: function serialize(value) {
      if (!_jquery.default.isPlainObject(value)) {
        return {};
      } else {
        return value;
      }
    }
  });

  _exports.default = _default;
});