define("ember-rs/locales/de/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteDocumentModal = void 0;
  var deleteDocumentModal = {
    "permanentlyDeleted": "Hiermit wird das Dokument endgültig aus RightSignature gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden.",
    "email": "Unterzeichner und CCs erhalten eine E-Mail mit dem ausgefüllten Dokument und dem Signaturzertifikat für ihre Unterlagen.",
    "briefMoment": "Es kann einen Moment dauern, bis das Dokument aus Ihrem Dashboard verschwunden ist.",
    "delete": "Löschen",
    "cancel": "Abbrechen",
    "deleteDocument": "Dokument löschen",
    "deleteComplete": "Dokument gelöscht",
    "deleteFailed": "Dokument konnte nicht zerstört werden"
  };
  _exports.deleteDocumentModal = deleteDocumentModal;
});