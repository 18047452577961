define("ember-rs/locales/de/templates/onboarding/new-user-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboarding = void 0;
  var newUserOnboarding = {
    "title": "Willkommen bei RightSignature™",
    "letsPersonalize": "Bevor wir zu den Features kommen, geben Sie Ihrem Konto eine persönliche Note.",
    "photoText": "Ihr Foto wird auf der Seite zum Signieren von Dokumenten verwendet. Es gibt der Seite einen individuellen und authentischen Stil.",
    "photoSubtext": "Sie können dies jederzeit über Ihre Kontoeinstellungen aktualisieren.",
    "sendSign": "Senden und Signieren von Dokumenten",
    "sendDoc": "Senden Sie Dokumente, um sie von anderen unterschreiben zu lassen, oder signieren Sie alle Dokumente, die Ihre Unterschrift erfordern.",
    "knowMore": "Möchten Sie mehr erfahren?",
    "viewSupport": "Supportartikel und Videos anzeigen",
    "createTemplates": "Erstellen Sie Vorlagen für häufig signierte Dokumente.",
    "saveTime": "Sparen Sie Zeit und erstellen Sie wiederverwendbare Vorlagen für häufig verwendete Dokumente wie Verzichtserklärungen, NDAs, Steuerformulare, Mitarbeiteranträge und vieles mehr.",
    "learnMore": "Möchten Sie mehr erfahren?",
    "accessSupport": "Navigieren Sie zu unserer <a href='#''>Supportsite</a>, auf der Sie Videoanleitungen und hilfreiche Tipps finden, damit Sie RightSignature™ optimal nutzen können."
  };
  _exports.newUserOnboarding = newUserOnboarding;
});