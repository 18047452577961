define("ember-rs/locales/ja/templates/components/document-overlayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentOverlayer = void 0;
  var documentOverlayer = {
    "prepare": "ドキュメントを準備",
    "tools": "高度なツール: ",
    "importOverlay": "オーバレイのインポート",
    "toolTip": "以前に送信したドキュメントを選択して、そのフィールドをこのドキュメントの同じ場所に配置します。メモ: ドキュメントに同じ名前を付ける必要があります。",
    "fieldDetection": "フィールドの検出",
    "fieldDetectionTip": "フィールドの検出は、フィールドをより正確にドキュメント内の行やボックスに揃えるのに役立ちます。",
    "roles": "役割",
    "rolesTip": "このドキュメントの役割を表示します。各役割には、入力が必要なフィールドに関連付けられた色が割り当てられます。",
    "signers": "署名者",
    "signersTip": "このドキュメントの署名者を表示します。各署名者には、入力が必要なフィールドに関連付けられた色が割り当てられます。",
    "attachmentUnavailable": "選択したアカウントで添付ファイルを利用できません。署名者に添付ファイルを要求するにはプロ プランにアップグレードしてください。",
    "paymentUnavailable": "選択したアカウントで支払いを利用できません。署名者から支払いを収集するにはプロ プランにアップグレードしてください。",
    "paymentNotEnabled": "支払い用コンポーネントを使用するには支払いの統合を有効にしてください。[アカウント設定] の [統合]セクションで実行できます。",
    "noRoles": "役割が見つかりませんでした。保存せずに再読み込みした可能性があります。"
  };
  _exports.documentOverlayer = documentOverlayer;
});