define("ember-rs/locales/zh-cn/templates/components/show-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showComponent = void 0;
  var showComponent = {
    "completed": "已完成",
    "pending": "待签名",
    "mergefield": "为合并字段",
    "requiredAssignedTo": "分配给 <b>{{{assignedToName}}}</b> 的“{{{fieldName}}}”字段为必填项",
    "optionalAssignedTo": "分配给 <b>{{{assignedToName}}}</b> 的“{{{fieldName}}}”字段为选填项"
  };
  _exports.showComponent = showComponent;
});