define("ember-rs/components/document-audit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // WHEN ADDING `history-` ASSETS TO `public/assets/v3/icons`
  // be sure to update the list below. This guards against an unsupported svg
  // from crashing the app.
  var ALLOWED_STATUS_ICONS = Ember.A(['attachment', 'authenticated', 'created', 'declined', 'distribution', 'expired', 'identity_authenticated', 'executed', 'modified_email', 'modified_detail', 'modified_signer', 'reminder', 'self_signed', 'signed', 'signer_payment', 'viewed', 'voided', 'updated_tags', 'identity_verification', 'unlocked_kba']);

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    tagName: 'li',
    classNames: ['audit'],
    statusIcon: Ember.computed('audit.metadata.keyword', function () {
      var keyword = this.get('audit.metadata.keyword');
      return ALLOWED_STATUS_ICONS.includes(keyword) ? "history-".concat(keyword) : null;
    })
  });

  _exports.default = _default;
});