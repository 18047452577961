define("ember-rs/locales/nl/templates/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.index = void 0;
  var index = {
    "templates": "Sjablonen",
    "reusableTemplates": "Herbruikbare sjablonen",
    "templateExplain": "Met sjablonen kunt u documenten insluiten in uw site, verspreiden via massadistributies of veelgebruikte documenten eenvoudig in één stap verzenden.",
    "newTemplate": "Nieuwe sjabloon",
    "noResults": "Geen zoekresultaten",
    "searchAgain": "Uw zoekopdracht heeft geen resultaten opgeleverd. Probeer een andere zoekopdracht om uw sjabloon te vinden.",
    "create": "Maak herbruikbare documentsjablonen die u kunt insluiten in uw site en delen via een koppeling.",
    "createTemplate": "Sjabloon maken",
    "notAllowed": "Met uw huidige abonnement kunt u geen sjablonen maken. Als u sjablonen wilt maken, neemt u contact op met Support om uw account te upgraden."
  };
  _exports.index = index;
});