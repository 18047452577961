define("ember-rs/locales/ja/templates/start-document/-send-for-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendForSignature = void 0;
  var sendForSignature = {
    "support": "必要に応じて、<a href='https://support.citrix.com/cscase'>サポートに連絡</a>してください。"
  };
  _exports.sendForSignature = sendForSignature;
});