define("ember-rs/locales/it/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFree = void 0;
  var newUserOnboardingFree = {
    "title": "Ti diamo il benvenuto in RightSignature",
    "body": "Al momento disponi solo di accesso di base a RightSignature™. In questo modo è possibile firmare autonomamente i documenti e visualizzare i documenti precedenti. Per accedere alle funzioni PRO, contattare un amministratore dell'account RightSignature™."
  };
  _exports.newUserOnboardingFree = newUserOnboardingFree;
});