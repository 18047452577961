define("ember-rs/templates/components/fillout-mergefields-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQzN00nw",
    "block": "{\"symbols\":[\"component\",\"mergefield\"],\"statements\":[[10,\"div\"],[14,0,\"page\"],[15,\"data-page-number\",[34,3]],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"signing-component\",[],[[\"@component\",\"@valueObjectName\",\"@valueChanged\",\"@sortedComponentsToFillIndexMap\"],[[30,[36,0],[[32,2]],null],[30,[36,0],[[35,1]],null],\"componentValueChanged\",[30,[36,0],[[35,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"flattened-component\",[],[[\"@component\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[10,\"img\"],[14,0,\"page-image\"],[15,\"src\",[34,8,[\"original\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"readonly\",\"valueObjectName\",\"sortedComponentsToFillIndexMap\",\"pageNumber\",\"mergefields\",\"-track-array\",\"each\",\"annotations\",\"pageImage\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/fillout-mergefields-page.hbs"
    }
  });

  _exports.default = _default;
});