define("ember-rs/locales/pt-br/templates/document/prepare-signers-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers_desktop = void 0;
  var prepare_signers_desktop = {
    "assign_roles": "Atribuir funções para:",
    "who_sign": "Quem precisa assinar ",
    "sign_order": "Definir ordem de signatários",
    "add_another": "Adicionar outro signatário",
    "add_yourself": "Adicionar você mesmo",
    "contact_integtration": "Integração de contatos",
    "select_integration": "Selecionar uma integração",
    "who_sign_package": "Quem precisa assinar o pacote de documentos?"
  };
  _exports.prepare_signers_desktop = prepare_signers_desktop;
});