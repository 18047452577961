define("ember-rs/locales/de/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "unlock_message": "Mindestens ein Unterzeichner hat die maximale Anzahl von Identitätsverifizierungsversuchen (3) überschritten und das Dokument ist nun für diese Unterzeichner gesperrt. Um das Dokument zu entsperren und ausstehende Unterzeichner zu veranlassen, ihre Identität anhand der wissensbasierten Authentifizierung zu verifizieren, klicken Sie auf 'Entsperren'. Beachten Sie, dass das Entsperren des Dokuments u. U. Konflikte mit <a target='_blank' href='https://www.irs.gov/uac/electronic-signature-guidance-for-forms-8878-and-8879'>IRS Electronic Signature Guidance for Forms 8878 and 8879</a> verursacht."
  };
  _exports.unlock = unlock;
});