define("ember-rs/templates/account/bills", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G7NA//1Z",
    "block": "{\"symbols\":[\"bill\"],\"statements\":[[10,\"div\"],[14,0,\"setting-info\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"account.bills.header\"],null]],[13],[2,\"\\n  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"account.bills.contact\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"billing-index\"],[12],[2,\"\\n  \"],[10,\"table\"],[14,0,\"billing-table\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[1,[30,[36,0],[\"account.bills.date\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[12],[1,[30,[36,0],[\"account.bills.type\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[12],[1,[30,[36,0],[\"account.bills.status\"],null]],[13],[2,\"\\n      \"],[10,\"th\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"bill-row\",[],[[\"@bill\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/account/bills.hbs"
    }
  });

  _exports.default = _default;
});