define("ember-rs/templates/onboarding/prepare-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qQF7c8Xm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"onboarding-container prepare\"],[15,5,[30,[36,1],[[35,0,[\"onboardingContentStyle\"]]],null]],[12],[2,\"\\n  \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@requestComponents\",\"@nextStepText\"],[[34,2],1,\"onboarding/prepare-onboarding-1\",\"nextStep\",[34,3],[30,[36,4],[\"onboarding.prepareOnboarding.nextStep\"],null]]],null],[2,\"\\n\\n  \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@annotationComponents\",\"@nextStepText\"],[[34,2],2,\"onboarding/prepare-onboarding-2\",\"nextStep\",[34,5],[30,[36,4],[\"onboarding.prepareOnboarding.nextStep\"],null]]],null],[2,\"\\n\\n  \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@signers\",\"@nextStepText\"],[[34,2],3,\"onboarding/prepare-onboarding-3\",\"nextStep\",[34,6],[30,[36,4],[\"onboarding.prepareOnboarding.nextStep\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"layoutSizeData\",\"safe\",\"currentStep\",\"requestComponents\",\"t\",\"annotationComponents\",\"signers\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/prepare-onboarding.hbs"
    }
  });

  _exports.default = _default;
});