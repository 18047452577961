define("ember-rs/locales/nl/templates/onboarding/prepare-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding3 = void 0;
  var prepareOnboarding3 = {
    "seeSigners": "<span>Zie de ondertekenaars</span> voor dit document. Elke ondertekenaar heeft zijn eigen kleur die overeenkomt met de velden die ingevuld moeten worden"
  };
  _exports.prepareOnboarding3 = prepareOnboarding3;
});