define("ember-rs/locales/it/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pendingIdentityVerification = void 0;
  var pendingIdentityVerification = {
    "pendingIdentityVerification": "In attesa di verifica dell'identità",
    "success": "La firma del documento è stata completata. Per completare questo documento e restituirlo al mittente è necessario verificare la propria identità. Per farlo è sufficiente controllare l'e-mail e fare clic sul collegamento per la verifica dell'e-mail inviato da RightSignature.",
    "resend": "Invia nuovamente la verifica dell'identità"
  };
  _exports.pendingIdentityVerification = pendingIdentityVerification;
});