define("ember-rs/locales/fr/templates/reusable-templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTrans = void 0;
  var newTrans = {
    "upload": "Charger",
    "dragDrop": "Déposez les fichiers ici ou :",
    "uploading": "Chargement en cours",
    "uploadFile": "Charger un fichier",
    "chooseCloud": "choisir sur le cloud",
    "prepare": "préparer le document"
  };
  _exports.newTrans = newTrans;
});