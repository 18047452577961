define("ember-rs/routes/account/branding", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    titleToken: 'Branding',
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      updateAccount: function updateAccount() {
        var _this = this;

        this.currentModel.save().then(function () {
          _this.flashMessages.success(_this.i18n.t('components.branding.update-account-setting'));
        });
      },
      deleteLogo: function deleteLogo() {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax("".concat(_environment.default.api.endpoint, "/account/logo"), {
            type: 'DELETE',
            data: {}
          }).done(function (response) {
            _this2.currentModel.setProperties({
              logoUrl: '',
              logoThumbUrl: ''
            });

            _this2.flashMessages.success(_this2.i18n.t('components.branding.delete-logo'));

            resolve(response);
          }).fail(function (response) {
            _this2.flashMessages.warning(response.errors.error);

            reject(response);
          });
        }, 'Delete a logo.');
      }
    }
  });

  _exports.default = _default;
});