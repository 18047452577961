define("ember-rs/locales/fr/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitlementOptionsAdmin = void 0;
  var entitlementOptionsAdmin = {
    "title": "Bienvenue à RightSignature !",
    "hasSeats": "Vous disposez actuellement de privilèges d'administrateur pour ce compte. Cela signifie que vous pouvez accorder l'accès à PRO et ses fonctionnalités à vous-même et/ou à d'autres utilisateurs. Cliquez sur le bouton ci-dessous pour vous accorder cet accès.",
    "notNow": "Peut-être plus tard",
    "entitleMe": "Obtenir l’accès PRO",
    "noSeats": "Toutes les licences de ce compte sont utilisées ! Vous pouvez supprimer un utilisateur, obtenir plus de licences en <a href='https://support.citrix.com/cscase'>contactant le support</a> ou en téléphonant au <a href='tel:+18009214250'>1-800-921-4250</a>, ou vous pouvez continuer sans certaines fonctionnalités.",
    "proceed": "Continuer"
  };
  _exports.entitlementOptionsAdmin = entitlementOptionsAdmin;
});