define("ember-rs/locales/zh-cn/templates/components/document-download-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDownloadOptions = void 0;
  var documentDownloadOptions = {
    "originalPDF": "原始 PDF",
    "completedPDF": "已完成的 PDF",
    "overlay": "可打印的叠加 PDF",
    "failed": "已完成的 PDF (失败)",
    "support": "处理文档时出现问题。应与技术支持人员联系。",
    "cert": "签名证书",
    "mergedPDF": "组合 PDF"
  };
  _exports.documentDownloadOptions = documentDownloadOptions;
});