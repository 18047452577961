define("ember-rs/locales/ja/templates/onboarding/send-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding = void 0;
  var sendOnboarding = {
    "continue": "続行",
    "gotIt": "OK!"
  };
  _exports.sendOnboarding = sendOnboarding;
});