define("ember-rs/locales/en/templates/unauthorized/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unauthorizedFile = void 0;
  var unauthorizedFile = {
    "unauthorized": "Unauthorized",
    "youAreNot": "You are not authorized to see that page."
  };
  _exports.unauthorizedFile = unauthorizedFile;
});