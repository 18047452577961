define("ember-rs/locales/es/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers = void 0;
  var prepare_signers = {
    "add_signers": "Agregar firmantes",
    "doc_limit_p1": "Ha utilizado ",
    "doc_limit_p2": "de su límite de documentos para el mes. Contacte con nosotros para actualizar su cuenta.",
    "recentContacts": "Contactos recientes"
  };
  _exports.prepare_signers = prepare_signers;
});