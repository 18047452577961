define("ember-rs/components/add-role-field", ["exports", "ember-rs/helpers/is-valid-email"], function (_exports, _isValidEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':field', ':field-with-icon', 'fieldType', 'fieldIsNotValid', ':theme__field'],
    autofocus: false,
    fieldIsNotValid: Ember.computed.not('fieldIsValid'),
    isNameField: Ember.computed.equal('fieldType', 'name'),
    isEmailField: Ember.computed.equal('fieldType', 'email'),
    isRoleNameField: Ember.computed.equal('fieldType', 'roleName'),
    isDisabled: Ember.computed.alias('role.isSender'),
    emailOptional: Ember.computed.not('role.documentTemplate.documentTransaction.signerEmailRequired'),
    hintText: Ember.computed('fieldType', function () {
      switch (this.fieldType) {
        case 'name':
          return this.i18n.t("components.addRoleField.nameTip");

        case 'email':
          return this.i18n.t("components.addRoleField.emailTip");

        case 'roleName':
          return this.i18n.t("components.addRoleField.roleTip");
      }
    }),
    isTextField: Ember.computed('fieldType', function () {
      return this.fieldType === 'name' || this.fieldType === 'roleName';
    }),
    emailPlaceholder: Ember.computed('role', 'emailOptional', function () {
      if (this.emailOptional) {
        return this.i18n.t("components.addRoleField.emailOptional");
      } else {
        return this.i18n.t("components.addRoleField.email");
      }
    }),
    fieldIsValid: Ember.computed('isTextField', 'isEmailField', 'value', function () {
      var value = this.value;

      if (this.isTextField) {
        return Ember.isPresent(value);
      } else if (this.isEmailField && !this.emailOptional) {
        return (0, _isValidEmail.isValidEmail)(value);
      }

      return true;
    }),
    actions: {
      input: function input() {
        this.sendAction('updated', this.$('input').val());
      },
      focused: function focused() {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.sendAction('focused');
        });
      },
      blur: function blur() {
        Ember.run.later(this, function () {
          if (this._state === 'destroying') {
            return;
          }

          this.sendAction('blur');
        }, 400);
      }
    }
  });

  _exports.default = _default;
});