define("ember-rs/locales/nl/templates/components/signer/signer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerInfo = void 0;
  var signerInfo = {
    "toSign": "Voer uw naam en e-mailadres in om dit document te ondertekenen:",
    "name": "Naam...",
    "proceed": "Doorgaan naar document"
  };
  _exports.signerInfo = signerInfo;
});