define("ember-rs/locales/en/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "notAllowed": "Action not allowed",
    "noPro": "You currently don't have PRO access to this account. You are able to sign and view your documents but PRO features are not available. Contact an admin of this account to grant you PRO access so that you can:",
    "send": "Send Documents for Signature",
    "create": "Create and use Templates",
    "customize": "Customize Branding",
    "enable": "Enable 3rd Party Integrations",
    "reports": "View Reports",
    "login": "Log in to RightSignature"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});