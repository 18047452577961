define("ember-rs/locales/en/templates/components/transaction-manager-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transactionManagerHeader = void 0;
  var transactionManagerHeader = {
    "close": "CLOSE",
    "sendDocument": "Send Document",
    "signDocument": "Sign Document",
    "inPerson": "In-Person Signing",
    "broken": "Broken"
  };
  _exports.transactionManagerHeader = transactionManagerHeader;
});