define("ember-rs/locales/de/templates/reports/export/month", ["exports", "ember-rs/locales/de/templates/reports/export/month/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.month = void 0;
  var month = {
    template: _template.template,
    "header": "Nach Vorlage filtern",
    "description": "Wählen Sie die Vorlage zum Filtern der Dokumente",
    "template-select-text": "Vorlage auswählen"
  };
  _exports.month = month;
});