define("ember-rs/locales/nl/templates/components/signature-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signatureField = void 0;
  var signatureField = {
    "rotateToSign": "Roteren om te ondertekenen.",
    "rotateTip": "Mogelijk moet u de schermrotatie van uw apparaat ontgrendelen.",
    "clear": "Wissen",
    "signInstn": "Klik en sleep om uw handtekening te zetten",
    "typeUrSign": "Typ uw handtekening",
    "typeSign": "Handtekening typen",
    "drawSign": "Handtekening tekenen",
    "or": "of",
    "savedSign": "Opgeslagen handtekening gebruiken",
    "apply": "Toepassen"
  };
  _exports.signatureField = signatureField;
});