define("ember-rs/locales/es/templates/components/review-and-send-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewAndSendItem = void 0;
  var reviewAndSendItem = {
    "passcode-message": "¡Recuerde! Debe enviar este PIN a los firmantes del documento.",
    "bulk-send-passcode-message": "Habilitó el código de acceso para las transacciones de documentos. Por ahora el envío en bloque no admite esto.",
    "generate-new-pin": "Generar PIN nuevo",
    "pin-specification": "El PIN debe ser un número de cinco dígitos.",
    "expiration-message": "Después de la fecha de caducidad, el documento ya no podrá firmarse.",
    "ExpiresIn": "Caduca dentro de",
    "days": "días",
    "loading": "cargando",
    "page": "página(s)",
    "emailTip1": "Se enviará un enlace para ver este documento a {{{email}}}.",
    "emailTip2": "El destinatario podrá ver y descargar el documento.",
    "enterEmail": "Introducir dirección de correo electrónico",
    "shareDoc": "Introduzca la dirección de correo electrónico para compartir este documento",
    "ccMsg": "Añada en CC a los grupos a quienes quiera darles acceso de solo lectura a este documento. Recibirán notificaciones, podrán ver el progreso y podrán descargar el documento completo. Si lo necesita, puede revocarles el acceso más adelante.",
    "add": "agregar",
    "roles": "roles",
    "recipients": "destinatarios",
    "cc": "cc: ",
    "ccCaps": "CC",
    "messageLimit": "El mensaje no puede contener más de 100000 caracteres.",
    "addMsg": "Agregue un mensaje personalizado...",
    "for": "de",
    "message": "mensaje",
    "pin": "PIN",
    "tags": "etiquetas",
    "passcodeOn": "Código de acceso activado",
    "passcodeOff": "Código de acceso desactivado",
    "restrictedFeatureMsg": "Su plan actual no le permite agregar códigos de acceso a los documentos. Actualice el plan para acceder a esta función.",
    "kba": "KBA",
    "on": "Activado",
    "off": "Desactivado",
    "kbaTip": "Exige a los firmantes que verifiquen su identidad con un cuestionario de conocimientos. Cada firmante deberá verificarla de forma independiente antes de firmar el documento. Si hay 3 intentos fallidos, se les bloqueará el acceso.",
    "enable": "Habilitar",
    "addTag": "Agregar etiqueta",
    "tagTip": "Agregue etiquetas a este documento para encontrarlo fácilmente más adelante. También puede agregar valores a las etiquetas; por ejemplo, \"id_cliente: 1234\".",
    "addSalesforceOpportunity": "Agregar oportunidad de Salesforce",
    "addSalesforceOpportunityMsg": "Agregar una oportunidad de Salesforce a este documento",
    "exceededKbaLimit": "Límite de KBA alcanzado",
    "exceededKbaMsg": "Ha alcanzado el límite mensual de su suscripción actual para los documentos habilitados para KBA. Para enviar más documentos con la función Autenticación basada en conocimientos habilitada, por favor, <a href='https://support.citrix.com/cscase'>contacte con la asistencia técnica</a> para actualizar su cuenta.",
    "tagName": "Nombre de la etiqueta...",
    "tagValue": "Valor (optativo)",
    "sendTo": "Enviar a",
    "name": "Nombre",
    "docName": "Nombre del documento...",
    "totalNumberOfSigners": "Total de firmantes:",
    "expiresIn": "\"Caduca en\" debe oscilar entre 1 y 365 días."
  };
  _exports.reviewAndSendItem = reviewAndSendItem;
});