define("ember-rs/locales/nl/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "unlock_message": "Het maximum aantal pogingen voor identiteitsverificatie (3) is door een of meer ondertekenaars overschreden en het document is nu vergrendeld voor deze ondertekenaars. Als u het document wilt ontgrendelen en wachtende ondertekenaars wilt verplichten hun identiteit te verifiëren via op kennis gebaseerde verificatie, klikt u op Ontgrendelen. Houd er rekening mee dat het ontgrendelen van het document mogelijk in strijd is met de <a target='_blank' href='https://www.irs.gov/uac/electronic-signature-guidance-for-forms-8878-and-8879'>IRS Electronic Signature Guidance for Forms 8878 and 8879</a>."
  };
  _exports.unlock = unlock;
});