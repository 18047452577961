define("ember-rs/templates/components/salesforce-opportunities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eXqJIJRx",
    "block": "{\"symbols\":[\"opportunity\"],\"statements\":[[8,\"search-field\",[],[[\"@query\",\"@searchUpdate\",\"@placeholder\"],[[34,4],\"searchSalesforceOpportunities\",[30,[36,5],[\"components.salesforceOpportunities.searchOpportunities\"],null]]],null],[2,\"\\n\\n\"],[10,\"ul\"],[14,1,\"salesforce-opportunity-results\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"loading-with-message\",[],[[\"@message\"],[[34,3]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"salesforce-opportunity\",[],[[\"@opportunity\",\"@addSalesforceOpportunity\"],[[32,1],\"addSalesforceOpportunity\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"salesforceOpportunities\",\"-track-array\",\"each\",\"loadingMessage\",\"salesforceOpportunityQuery\",\"t\",\"loading\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/salesforce-opportunities.hbs"
    }
  });

  _exports.default = _default;
});