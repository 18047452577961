define("ember-rs/locales/zh-cn/templates/components/certificate-loading-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.certificateLoadingPage = void 0;
  var certificateLoadingPage = {
    "generateCertificate": "生成证书映像"
  };
  _exports.certificateLoadingPage = certificateLoadingPage;
});