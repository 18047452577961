define("ember-rs/locales/zh-cn/templates/components/component-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbar = void 0;
  var componentToolbar = {
    "useTools": "请对签名者需要填写的字段使用这些工具。",
    "showLess": "显示更少",
    "showMore": "显示更多",
    "annotate": "批注:",
    "annotateTip": "使用这些工具可借助您自己的签名、文本或选中标记对文档进行批注。",
    "request": "请求:",
    "dataFields": "数据字段",
    "sourceFetchFailed": "无法检索源信息。",
    "schemaFetchFailed": "无法检索源实体架构。"
  };
  _exports.componentToolbar = componentToolbar;
});