define("ember-rs/locales/it/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentityExpired = void 0;
  var confirmIdentityExpired = {
    "linkExpired": "Collegamento scaduto",
    "resendIdentityConfirmation": "Inviare di nuovo l'e-mail di conferma dell'identità.",
    "resendIdentityVerification": "Invia nuovamente la verifica dell'identità",
    "contactSender": "Contattare il mittente del documento per richiedere un collegamento aggiornato."
  };
  _exports.confirmIdentityExpired = confirmIdentityExpired;
});