define("ember-rs/locales/nl/models/document-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTemplate = void 0;
  var documentTemplate = {
    "signer": "ondertekenaar"
  };
  _exports.documentTemplate = documentTemplate;
});