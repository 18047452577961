define("ember-rs/locales/pt-br/templates/signer/confirm-identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentity = void 0;
  var confirmIdentity = {
    "confirmed": "Identidade confirmada com sucesso.",
    "submitted": "Sua resposta foi enviada."
  };
  _exports.confirmIdentity = confirmIdentity;
});