define("ember-rs/locales/de/templates/components/rs-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsToggle = void 0;
  var rsToggle = {
    "on": "Ein",
    "off": "Aus"
  };
  _exports.rsToggle = rsToggle;
});