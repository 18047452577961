define("ember-rs/locales/nl/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentityExpired = void 0;
  var confirmIdentityExpired = {
    "linkExpired": "Koppeling is verlopen",
    "resendIdentityConfirmation": "Verzend e-mail met identiteitsbevestiging opnieuw.",
    "resendIdentityVerification": "Identiteitverificatie opnieuw verzenden",
    "contactSender": "Neem contact op met de afzender van het document om een ​​bijgewerkte koppeling aan te vragen."
  };
  _exports.confirmIdentityExpired = confirmIdentityExpired;
});