define("ember-rs/templates/toolbar-icons/select-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CkAIt3VX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"14px\"],[14,\"height\",\"10px\"],[14,\"viewBox\",\"0 0 14 10\"],[14,\"enable-background\",\"new 0 0 14 10\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[2,\"\\n\"],[10,\"g\"],[12],[2,\"\\n\\t\"],[10,\"g\"],[12],[2,\"\\n\\t\\t\"],[10,\"g\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"path\"],[14,\"d\",\"M14,8.5c0,0.828-0.672,1.5-1.5,1.5h-11C0.672,10,0,9.328,0,8.5v-7C0,0.672,0.672,0,1.5,0h11\\n\\t\\t\\t\\tC13.328,0,14,0.672,14,1.5V8.5z M13,1.5C13,1.224,12.776,1,12.5,1h-11C1.224,1,1,1.224,1,1.5v7C1,8.776,1.224,9,1.5,9h11\\n\\t\\t\\t\\tC12.776,9,13,8.776,13,8.5V1.5z\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"polygon\"],[14,\"points\",\"7.209,4.405 9.449,6.418 11.69,4.422 \\t\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/toolbar-icons/select-component.hbs"
    }
  });

  _exports.default = _default;
});