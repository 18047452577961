define("ember-rs/locales/de/routes/email-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailVerification = void 0;
  var emailVerification = {
    "verificationSuccess": "Die E-Mail-Adresse wurde verifiziert.",
    "invalidVerification": "Die Verifizierung der E-Mail-Adresse ist ungültig."
  };
  _exports.emailVerification = emailVerification;
});