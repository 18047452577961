define("ember-rs/templates/components/delete-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U31z6DXb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"ember-wormhole\",[],[[\"@to\"],[\"applicationModals\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"persistent-modal\",[],[[\"@class\",\"@cancel\",\"@save\",\"@cancelText\",\"@saveText\",\"@loading\"],[\"persistent-modal--is-half-width\",[30,[36,0],[[32,0],\"cancelEdit\"],null],[30,[36,0],[[32,0],\"deleteSignature\"],null],[30,[36,1],[\"shared.actions.cancel\"],null],[30,[36,1],[\"shared.actions.delete\"],null],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h3\"],[14,0,\"modal-heading\"],[12],[1,[30,[36,1],[\"shared.headers.confirm-deletion\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,1],[\"account.settings.saved-signature-settings.confirm-deletion\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"loading\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/delete-signature.hbs"
    }
  });

  _exports.default = _default;
});