define("ember-rs/locales/en/templates/onboarding/prepare-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding1 = void 0;
  var prepareOnboarding1 = {
    "useTools": "Use these tools for <span>fields that signers need to complete</span>"
  };
  _exports.prepareOnboarding1 = prepareOnboarding1;
});