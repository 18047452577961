define("ember-rs/locales/ja/templates/components/documents/document-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentRow = void 0;
  var documentRow = {
    "signed": "署名済み",
    "signer": "署名者",
    "legacy": " - レガシー ドキュメント",
    "awaitingSignature": "署名を待機中",
    "sign": "署名",
    "completed": "完了 ",
    "sent": "送信済み",
    "voided": "取り消し済み",
    "expired": "期限切れ ",
    "auth_failure": "セッション経由での署名者の認証に失敗しました: {{{authError}}}"
  };
  _exports.documentRow = documentRow;
});