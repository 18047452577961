define("ember-rs/locales/de/templates/document/details", ["exports", "ember-rs/locales/de/templates/document/details/unlock"], function (_exports, _unlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    unlock: _unlock.unlock,
    "status": "Status",
    "overview": "Übersicht",
    "history": "Verlauf",
    "people_involved": "Beteiligte Personen:",
    "manage": "Verwalten",
    "security_options": "Sicherheitsoptionen:",
    "declined": "Abgelehnt",
    "expired": "Abgelaufen",
    "expires": "Ablauf",
    "extend": "Verlängern",
    "pin": "PIN *****",
    "generate_pin": "Neue PIN generieren",
    "pin_tip": "Durch das Generieren einer neuen PIN wird eine neue PIN erstellt, wodurch die aktuelle PIN ungültig wird.",
    "passcode_question": "Passcodefrage:",
    "clear_attempts": "Passcodeversuche löschen",
    "passcode": "Passcode",
    "signer_sequencing": "Unterzeichnersequenzierung",
    "lock_message": "Dieses Dokument wurde gesperrt.",
    "attachments": "Anlagen:",
    "reference_id": "Referenz-ID: ",
    "tags": "Tags: ",
    "remind_header": "Signaturlink gesendet",
    "remind_body": "Wir haben den Link zum Unterzeichnen dieses Dokuments an die unten angegebene E-Mail-Adresse gesendet. Um diesen Schritt in Zukunft zu überspringen, können Sie diese E-Mail-Adresse auf Ihrer Einstellungsseite bestätigen.",
    "new_pin": "Neue PIN",
    "new_passcode": "Hier ist Ihre neue PIN. Sie müssen diese aktualisierte PIN an die Unterzeichner des Dokuments senden, damit sie darauf zugreifen können.",
    "done": "Fertig",
    "draft": "Entwurf",
    "pending": "Ausstehend",
    "voided": "Storniert",
    "executed": "Abgeschlossen",
    "recipient_message": "Nachricht an Empfänger:",
    "void_reason": "Reason for Voiding:",
    "document_voided": "Document Voided:",
    "document_declined": "Decline Reason:"
  };
  _exports.details = details;
});