define("ember-rs/models/client-application", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    uid: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    applicationUrl: (0, _model.attr)('string'),
    organization: (0, _model.attr)('string'),
    websiteUrl: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('moment-date'),
    updatedAt: (0, _model.attr)('moment-date'),
    redirectUri: (0, _model.attr)('string'),
    scopes: (0, _model.attr)('string'),
    secretRequestAudit: (0, _model.attr)('array'),
    privateTokenRequestAudit: (0, _model.attr)('array'),
    owner: (0, _model.belongsTo)('user', {
      async: true
    }),
    account: (0, _model.belongsTo)('account', {
      async: true
    })
  });

  _exports.default = _default;
});