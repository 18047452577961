define("ember-rs/locales/de/templates/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detailsPanel = void 0;
  var detailsPanel = {
    "clientId": "CLIENT-ID:",
    "clientSecret": "GEHEIMER CLIENTSCHLÜSSEL:",
    "lastViewed": "Zuletzt angezeigt: ",
    "fromIp": "von IP",
    "hidden": "Zur Sicherheit ausgeblendet",
    "reveal": "Geheimnis anzeigen",
    "privateToken": "PRIVATER API-TOKEN:",
    "revealToken": "Token zeigen",
    "regenerate": "Token erneut generieren. ACHTUNG: Diese Aktion kann nicht rückgängig gemacht werden",
    "description": "BESCHREIBUNG:",
    "scope": "BEREICH(E):",
    "redirectUri": "UMLEITUNGS-URL:",
    "applicationUrl": "ANWENDUNGS-URL:",
    "orgUrl": "ORGANISATIONS-URL:",
    "createdAt": "ERSTELLT UM:",
    "updatedAt": "AKTUALISIERT UM:",
    "edit": "Bearbeiten"
  };
  _exports.detailsPanel = detailsPanel;
});