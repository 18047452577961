define("ember-rs/routes/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      // Create new ClientApplication
      return this.store.createRecord('client-application', {
        owner: this.get('currentUser.model.id'),
        account: this.get('currentUser.account')
      });
    }
  });

  _exports.default = _default;
});