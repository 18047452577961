define("ember-rs/locales/en/templates/document-transaction/review-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_desktop = void 0;
  var review_desktop = {
    "send_document": "send document",
    "save_document": "Save Document",
    "sendDocument": "Send Document",
    "bulkSendConfirmation": "You are about to share this document with multiple signers",
    "confirm": "Confirm",
    "cancel": "Cancel"
  };
  _exports.review_desktop = review_desktop;
});