define("ember-rs/locales/pt-br/templates/reusable-templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTrans = void 0;
  var newTrans = {
    "upload": "Carregar",
    "dragDrop": "Arraste e solte arquivos aqui ou:",
    "uploading": "Fazendo upload",
    "uploadFile": "Carregar um arquivo",
    "chooseCloud": "escolha da nuvem",
    "prepare": "preparar documento"
  };
  _exports.newTrans = newTrans;
});