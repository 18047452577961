define("ember-rs/locales/fr/templates/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "send": "Envoyer pour signature",
    "notAllowed": " Votre plan actuel ne vous permet pas d'envoyer des documents. Mettez à niveau votre plan pour accéder à cette fonctionnalité.",
    "embed": "Intégrer dans le site Web",
    "share": "Générer lien de partage",
    "manageLinks": "Gérer liens &amp; incorporations",
    "viewResponses": "Voir les réponses",
    "edit": "Modifier",
    "delete": "Supprimer",
    "roles": "Rôles",
    "security": "Options de sécurité :",
    "expires": "Expire dans :",
    "days": "jours",
    "passcodeQuestion": "Question de code d'accès :",
    "passcode": "Code d’accès :",
    "tags": "Balises :",
    "linkHistory": "Historique de liens et de codes incorporés",
    "linkHistoryTip": "Gérez et affichez les informations d'utilisation de tous les liens et de toutes les incorporations de ce modèle.",
    "generate": "Générer lien de partage",
    "nameLink": "Donnez un nom à ce lien de partage.",
    "nameLinkTip": "Cela peut faciliter la gestion des liens.",
    "whichSigner": "Quel rôle de signataire ?",
    "whichSignerTip": "Les modèles et les liens incorporés ne peuvent avoir qu'un seul signataire.",
    "limitUses": "Limiter le nombre d’utilisations ?",
    "limitUsesTip": "Limitez le nombre de fois que ce lien peut être signé.",
    "expireDays": "Expirer après un certain nombre de jours ?",
    "expireDaysTip": "Après expiration, ce lien ne fonctionnera plus.",
    "identityMethod": "Méthode d'identité :",
    "identityMethodTip": "Comment vérifier l'identité des signataires ?",
    "generateLink": "Générer lien",
    "generateEmbed": "Générer code incorporé",
    "nameCode": "Nommez ce code.",
    "nameCodeTip": "Cela peut faciliter la gestion des codes générés.",
    "displayType": "Type d'affichage",
    "displayTypeTip": "Indique comment l'incorporation doit apparaître",
    "pixel": "px",
    "codeGenerated": "Code généré",
    "linkGenerated": "Lien généré",
    "clickingManage": "en cliquant sur « Gérer les liens &amp; incorporations » sur la page du modèle.",
    "youCanAccessCode": "Vous pouvez accéder à, désactiver et modifier ce code en cliquant sur « Gérer liens &amp; incorporations » sur la page du modèle.",
    "youCanAccessLink": "Vous pouvez accéder à, désactiver et modifier ce lien en cliquant sur « Gérer liens &amp; incorporations » sur la page du modèle.",
    "newPin": "Nouveau code PIN",
    "done": "Terminé",
    "email": "E-mail",
    "sms": "SMS",
    "none": "Aucun",
    "height": "Hauteur",
    "Width": "Largeur",
    "button": "button",
    "image": "image",
    "inline": "en ligne",
    "createdBy": "Créé par",
    "actions": "Actions",
    "visibility-toggle-text": "Définir le modèle sur privé",
    "visibility-toogle-info": "Si un modèle est défini sur privé, seul son créateur peut le voir",
    "template-signer-link": {
      "passcode-message": "La fonctionnalité de code d'accès a été activée pour ce compte, mais elle ne sera pas appliquée aux liens de partage ou aux documents incorporés. Ce code de lien/incorporé permettra d'accéder à ce document sans le code d'accès."
    },
    "embed-in-website": {
      "disabled-merge-fields": "Les codes incorporés ne sont pas disponibles pour ce modèle. Pour générer un code incorporé pour ce modèle, supprimez d'abord tous les champs de fusion requis.",
      "disabled-no-online-forms": "Votre plan actuel ne vous permet pas d'incorporer des modèles. Mettez à niveau votre plan pour accéder à cette fonctionnalité."
    },
    "generate-share-link": {
      "disabled-merge-fields": "Les codes incorporés ne sont pas disponibles pour ce modèle. Pour générer un code incorporé pour ce modèle, supprimez d'abord tous les champs de fusion requis.",
      "disabled-no-manual-distribution": "Votre plan actuel ne vous permet pas de générer des liens de partage. Mettez à niveau votre plan pour accéder à cette fonctionnalité."
    }
  };
  _exports.details = details;
});