define("ember-rs/locales/fr/templates/components/signature-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signatureField = void 0;
  var signatureField = {
    "rotateToSign": "Faites pivoter pour signer.",
    "rotateTip": "Vous devrez peut-être déverrouiller la rotation de l'écran de votre appareil.",
    "clear": "Effacer",
    "signInstn": "Cliquez et faites glisser pour dessiner votre signature",
    "typeUrSign": "Tapez votre signature",
    "typeSign": "Taper signature",
    "drawSign": "Dessiner signature",
    "or": "ou",
    "savedSign": "Utiliser signature enregistrée",
    "apply": "Appliquer"
  };
  _exports.signatureField = signatureField;
});