define("ember-rs/templates/signer/confirm-identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2pMAFn26",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"theme\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"signing_complete\"],[14,0,\"verified-identity\"],[12],[2,\"\\n\\n\\n    \"],[10,\"div\"],[14,1,\"done_icon\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-user\"],[12],[13],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-circle\"],[12],[13],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-check-circle\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"signer.confirmIdentity.confirmed\"],null]],[13],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"signer.confirmIdentity.submitted\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/signer/confirm-identity.hbs"
    }
  });

  _exports.default = _default;
});