define("ember-rs/locales/fr/templates/document-transaction/upload-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload_signers = void 0;
  var upload_signers = {
    "instructions_header": "Suivez les étapes ci-dessous pour charger votre fichier CSV",
    "step_1": "ÉTAPE 1",
    "step_2": "ÉTAPE 2",
    "step_3": "ÉTAPE 3",
    "step_1_text": "Télécharger la liste d'exemples de signataires pour l'envoi en bloc",
    "step_2_text": "Remplissez les champs de nom et d’e-mail (obligatoire).",
    "step_3_text": "Chargez le csv terminé.",
    "instructions_note": "Remarque : le nombre de signataires ne peut pas dépasser 300",
    "upload_failed": "Échec du chargement",
    "upload_failure_message": "Le chargement a échoué en raison d'un fichier corrompu ou d'une erreur de traitement. Assurez-vous que le contenu du fichier est valide et réessayez de charger.",
    "view_all": "Tout afficher",
    "ok": "OK",
    "validate_and_reupload": "Votre fichier contient plus d'erreurs. Vérifiez les validations et essayez à nouveau de charger."
  };
  _exports.upload_signers = upload_signers;
});