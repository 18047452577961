define("ember-rs/templates/email-unverified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N9QgMYev",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[14,1,\"email-verification-instructions\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,1,\"logo\"],[14,\"src\",\"/assets/images/white-logo.svg\"],[14,\"alt\",\"Header logo\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"center\"],[12],[1,[30,[36,0],[\"emailUnverified.verify\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/email-unverified.hbs"
    }
  });

  _exports.default = _default;
});