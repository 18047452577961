define("ember-rs/locales/it/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrations = void 0;
  var integrations = {
    "successEnabled": "{{{formattedProviderName}}} abilitato correttamente",
    "successAuthorized": "{{{formattedProviderName}}} autorizzato correttamente",
    "successDisabled": "{{{formattedProviderName}}} disabilitato correttamente",
    "error": "Errore. Impossibile modificare {{{formattedProviderName}}}",
    "errorAuthorize": "Errore. Impossibile autorizzare l'integrazione di {{{formattedProviderName}}}",
    "errorSetup": "Errore durante il tentativo di configurazione dell'integrazione {{{formattedProviderName}}}. Riprovare."
  };
  _exports.integrations = integrations;
});