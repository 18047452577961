define("ember-rs/locales/fr/templates/components/agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "welcome": "Bienvenue",
    "toRS": "à RightSignature,",
    "bestOnDesktop": "RightSignature fonctionne de manière optimale sur un ordinateur de bureau.",
    "pleaseRead": "Avant de commencer la visite, veuillez lire ce qui suit :",
    "terms": "En utilisant RightSignature&trade; vous certifiez avoir lu et accepté nos ",
    "and": "et notre",
    "policy": "Politique de confidentialité",
    "no": "Non merci",
    "yes": "J'accepte"
  };
  _exports.agreeToTerms = agreeToTerms;
});