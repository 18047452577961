define("ember-rs/locales/pt-br/templates/unauthorized/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expired = void 0;
  var expired = {
    "linkExpired": "Link do documento expirado",
    "toDownload": "Para baixar este documento, solicite um novo link de download.",
    "sendLink": "Enviar novo link de download"
  };
  _exports.expired = expired;
});