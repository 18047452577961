define("ember-rs/locales/de/templates/components/document/signer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerRow = void 0;
  var signerRow = {
    "pendingIdentityAuthentication": "Ausstehende Identitätsauthentifizierung",
    "sign": "Signieren",
    "disabledReminder": "Dieser Unterzeichner hat weitere Erinnerungen für dieses Dokument deaktiviert",
    "threshold": "Sie haben den Schwellenwert für die Sendeerinnerung überschritten (3 pro Dokument)",
    "signerFailed": "This signer has failed ",
    "currentlyLocked": "and is currently locked out of the document.",
    "signerLocked": "Bei diesem Unterzeichner ist die wissensbasierte Authentifizierung fehlgeschlagen und das Dokument ist derzeit gesperrt.",
    "remind": "Erinnern",
    "resend": "Erneut senden",
    "createLink": "Link erstellen",
    "copyLink": "Link kopieren",
    "copyLinkSuccess": "Link wurde in Zwischenablage kopiert",
    "copyLinkError": "Fehler beim Kopieren des Links in die Zwischenablage",
    "signerAuthFailed": "Fehler beim Authentifizieren des Unterzeichners in der Sitzung: {{{error}}}",
    "reminderSuccess": "{{{name}}} wurde erinnert",
    "reminderFailed": "Fehler beim Generieren der Erinnerung für {{{name}}}",
    "generateLinkFailed": "Signaturlink wurde nicht generiert: {{{error}}}",
    "resendIdentityVerification": "Identitätsprüfung wurde erneut gesendet",
    "resendingFailed": "Fehler beim erneuten Senden der Identitätsprüfung: {{{error}}}"
  };
  _exports.signerRow = signerRow;
});