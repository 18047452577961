define("ember-rs/routes/archived-document", ["exports", "ember-rs/utils/safe-access"], function (_exports, _safeAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    ////////////////////////  HOOKS  ////////////////////////
    model: function model(params) {
      this.set('archivedDocumentId', params.archive_document_id);
      return this.store.findRecord('archived-document', params.archived_document_id, {
        reload: true
      });
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      error: function error(response) {
        var statusCode = (0, _safeAccess.default)(response, '500', 'errors', 0, 'status');

        if (statusCode === '308') {
          this.transitionTo('unauthorized.expired', {
            queryParams: {
              token: this.get('currentUser.token'),
              emailRequired: /Email/.test((0, _safeAccess.default)(response, '500', 'errors', 0, 'detail'))
            }
          });
        } else if (statusCode === '403') {
          this.transitionTo('unauthorized.unauthorized');
        }
      }
    }
  });

  _exports.default = _default;
});