define("ember-rs/locales/es/templates/components/plan-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planInfo = void 0;
  var planInfo = {
    "perMonth": "/mes",
    "teamMember": "Miembro del equipo",
    "teamMembers": "Miembros del equipo",
    "call": "Llame al +1 (855) 895-4862",
    "selectedPlan": "Plan seleccionado",
    "selectPlan": "Seleccionar un plan"
  };
  _exports.planInfo = planInfo;
});