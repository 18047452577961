define("ember-rs/locales/zh-cn/mixins/redirect-token-generation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectTokenGeneration = void 0;
  var redirectTokenGeneration = {
    "linkGenerated": "新的安全链接已生成并发送到您的电子邮件。"
  };
  _exports.redirectTokenGeneration = redirectTokenGeneration;
});