define("ember-rs/locales/it/templates/components/documents/document-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentRow = void 0;
  var documentRow = {
    "signed": "firmato",
    "signer": "Firmatario/i",
    "legacy": " - Documento legacy",
    "awaitingSignature": "In attesa di firma",
    "sign": "firmare",
    "completed": "Completato ",
    "sent": "Inviato",
    "voided": "Annullato",
    "expired": "Scaduto ",
    "auth_failure": "Impossibile autenticare il firmatario tramite sessione: {{{authError}}}"
  };
  _exports.documentRow = documentRow;
});