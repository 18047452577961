define("ember-rs/locales/es/templates/onboarding/prepare-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding3 = void 0;
  var prepareOnboarding3 = {
    "seeSigners": "<span>Vea los firmantes</span> de este documento. Cada firmante tiene su propio color, que corresponde a los campos que necesitan completar"
  };
  _exports.prepareOnboarding3 = prepareOnboarding3;
});