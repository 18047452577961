define("ember-rs/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PmfOIJo4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"entitlement-modal terms-modal\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"onboarding.agreeToTerms.title\"],null]],[13],[2,\"\\n  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"onboarding.agreeToTerms.body\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"subtle\"],[4,[38,1],[[32,0],\"disagreeToTerms\"],null],[12],[1,[30,[36,0],[\"onboarding.agreeToTerms.noThanks\"],null]],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"agree-button\"],[4,[38,1],[[32,0],\"agreeToTerms\"],null],[12],[1,[30,[36,0],[\"onboarding.agreeToTerms.iAgree\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/-agree-to-terms.hbs"
    }
  });

  _exports.default = _default;
});