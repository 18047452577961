define("ember-rs/locales/es/templates/components/client-application/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "editKey": "Modificar clave de API ",
    "name_tool_tip": "Esto se muestra a los usuarios si usa OAuth para solicitar acceso a su cuenta. Por ejemplo: Industry Automotive Estimates Generator o WordStar Plugin",
    "name": "Nombre:",
    "org_name_tip": "  Nombre de su organización",
    "org_name": "Nombre de la organización:",
    "description_tip": "Esto se muestra a los usuarios si usa OAuth para solicitar acceso a su cuenta. Por ejemplo, 'Esta aplicación solo la utiliza Industry Automotive para enviar estimaciones dentro de nuestro flujo de trabajo Enterprise' o 'WordStar Plugin permite a los usuarios de WordStar enviar documentos para firmar a través de su cuenta de RightSignature&trade;'.",
    "description": "Descripción:",
    "app_url_tip": "Esto se muestra a los usuarios si usa OAuth para solicitar acceso a su cuenta. Por ejemplo: http://industryauto.com/login o http://wordstarsupportgroup.com/theplugin",
    "app_url": "URL de la aplicación:",
    "redirect_url_tip": "Esta es la respuesta predeterminada para las solicitudes de autorización OAuth. Se puede especificar o anular en las llamadas API. Por ejemplo: http://industryauto.com/oauth_callback",
    "redirect_url": "URL de redireccionamiento:",
    "org_url_tip": "  (opcional) Sitio Web de la organización",
    "org_url": "URL de organización:",
    "update_success": "{{{applicationName}}} se actualizó correctamente."
  };
  _exports.edit = edit;
});