define("ember-rs/locales/nl/templates/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentAccessSettings = void 0;
  var documentAccessSettings = {
    "addViewer": "Waarnemer toevoegen",
    "email": "E-mail",
    "name": "Name",
    "add": "Toevoegen",
    "cancel": "Annuleren",
    "editSigner": "Ondertekenaar bewerken",
    "save": "Opslaan",
    "manageAccess": "Toegang beheren",
    "accessTip": "Deze mensen hebben toegang tot dit document en kunnen het altijd downloaden of weergeven.",
    "signers": "Ondertekenaars",
    "edit": "bewerken",
    "viewers": "Waarnemers",
    "remove": "verwijderen",
    "updateEmail": "E-mailadres van ondertekenaar is bijgewerkt.",
    "updateSigner": "Successfully updated signer details."
  };
  _exports.documentAccessSettings = documentAccessSettings;
});