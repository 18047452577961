define("ember-rs/locales/it/templates/signer/passcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcode = void 0;
  var passcode = {
    "enterPin": "Immettere il PIN.",
    "access": "Immettere il PIN fornito per accedere al documento.",
    "unlock": "Sblocca",
    "docProtected": "Questo documento è protetto da passcode.",
    "answer": "risposta...",
    "show": "mostra passcode",
    "continue": "Continua con il documento",
    "answerQuestion": "Rispondere alla domanda di sicurezza riportata di seguito per accedere al documento"
  };
  _exports.passcode = passcode;
});