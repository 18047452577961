define("ember-rs/templates/components/toggle-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dGV7cLCM",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"label\"],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tool-tip\",[],[[\"@class\",\"@position\"],[\"toggle-tool-tip\",\"top\"]],[[\"default\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toolTipText\",\"labelText\",\"if\",\"small\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/toggle-switch.hbs"
    }
  });

  _exports.default = _default;
});