define("ember-rs/locales/it/templates/components/decline-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.declineDocumentModal = void 0;
  var declineDocumentModal = {
    "header": "Decline Document Confirmation",
    "declineConfirmationInfo": "Reason for Decline (will be shared only with the sender)",
    "optionalDeclineMessage": "",
    "decline": "Confirm",
    "cancel": "Cancel",
    "declineModalInfoMessage": "If the document is once declined, there is no way back. The notification email will be sent to all relevant participants."
  };
  _exports.declineDocumentModal = declineDocumentModal;
});