define("ember-rs/locales/zh-cn/templates/upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgrade = void 0;
  var upgrade = {
    "noUpgrade": "无法升级帐户",
    "noPermission": "您似乎无权升级您的帐户。请与帐户管理员联系，了解如何升级到新的 RightSignature 计划。",
    "upgrade": "升级您的帐户",
    "upgradeSubtitle": "您在试用期间使用的是 Business 计划。可以继续使用此计划，或者选择适用于您的其他计划。",
    "choosePlan": "1. 选择计划",
    "allInOne": "一站式电子签名解决方案",
    "standard": "标准功能 ",
    "usersNeeded": "2. 您需要多少用户?",
    "checkout": "结帐",
    "showLess": "显示更少",
    "showMore": "显示更多",
    "users": "用户",
    "confirm": "按 Enter 进行确认",
    "billing": "账单",
    "billingDetails": "账单详情",
    "noOfUser": "用户数",
    "billAmount": "计费金额",
    "creditAmount": "来自旧 RightSignature 的贷记金额",
    "total": "总计",
    "taxCharges": "可能需要支付额外的税费",
    "oops": "糟糕! 出现错误",
    "dataUnavailable": "当前没有可用数据。请稍后返回此页面。"
  };
  _exports.upgrade = upgrade;
});