define("ember-rs/locales/fr/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentityExpired = void 0;
  var confirmIdentityExpired = {
    "linkExpired": "Expiration du lien",
    "resendIdentityConfirmation": "Renvoyez l'e-mail de confirmation d'identité.",
    "resendIdentityVerification": "Renvoyer vérification d'identité",
    "contactSender": "Veuillez contacter l'expéditeur du document pour demander un lien mis à jour."
  };
  _exports.confirmIdentityExpired = confirmIdentityExpired;
});