define("ember-rs/locales/zh-cn/routes/email-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailVerification = void 0;
  var emailVerification = {
    "verificationSuccess": "已成功验证电子邮件。",
    "invalidVerification": "电子邮件验证无效。"
  };
  _exports.emailVerification = emailVerification;
});