define("ember-rs/locales/pt-br/templates/components/signer/signer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerInfo = void 0;
  var signerInfo = {
    "toSign": "Para assinar este documento, digite seu nome e e-mail:",
    "name": "Nome...",
    "proceed": "Continuar para o documento"
  };
  _exports.signerInfo = signerInfo;
});