define("ember-rs/locales/fr/templates/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.index = void 0;
  var index = {
    "templates": "Modèles",
    "reusableTemplates": "Modèles réutilisables",
    "templateExplain": "Les modèles vous permettent d'incorporer des documents à votre site, de les distribuer en masse ou simplement d'envoyer les documents fréquemment utilisés en une seule étape.",
    "newTemplate": "Nouveau modèle",
    "noResults": "Aucun résultat trouvé",
    "searchAgain": "Votre recherche n'a donné aucun résultat. Effectuez une autre recherche pour trouver votre modèle.",
    "create": "Créez des modèles de documents réutilisables que vous pouvez incorporer à votre site et partager via un lien.",
    "createTemplate": "Créer un modèle",
    "notAllowed": "Votre plan actuel ne vous permet pas de créer des modèles. Si vous souhaitez créer des modèles, contactez le support et mettez à niveau votre compte."
  };
  _exports.index = index;
});