define("ember-rs/locales/pt-br/templates/template", ["exports", "ember-rs/locales/pt-br/templates/template/details", "ember-rs/locales/pt-br/templates/template/search-header-mobile"], function (_exports, _details, _searchHeaderMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    details: _details.details,
    searchHeaderMobile: _searchHeaderMobile.searchHeaderMobile,
    "review": {
      "header-content": "Revisão",
      "create-button": "Criar modelo",
      "update-button": "Atualizar modelo"
    },
    "index": {
      "subheader-create-button": "Criar modelo",
      "template-limit-modal": {
        "cancellation-grace-period": "Esta conta foi cancelada, portanto, você não pode criar modelos",
        "limit-reached": "Seu plano atual só permite criar {{templateLimit}} modelos. Se você quiser criar mais, entre em contato com o suporte e atualize sua conta.",
        "not-enabled": "Seu plano atual não permite criar modelos. Se você deseja criar modelos, entre em contato com o suporte e atualize a sua conta."
      }
    }
  };
  _exports.template = template;
});