define("ember-rs/locales/nl/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding1 = void 0;
  var accountOnboarding1 = {
    "body": "Wijzig <span>uw instellingen</span>, <span>personaliseer</span> uw account, <span>integreer</span> met andere hulpmiddelen en meer."
  };
  _exports.accountOnboarding1 = accountOnboarding1;
});