define("ember-rs/locales/it/controllers/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "standardInfo": "Il piano selezionato supporta solo 1 utente. Se si ha bisogno di più utenti nell'account, scegliere il piano avanzato.",
    "migratedInfo": "Scegliere almeno {{{minimumSeats}}} utenti dal momento che il proprio account di prova utilizza già {{{minimumSeats}}} posti a sedere. Altrimenti, contattare il supporto per ulteriore assistenza nell'aggiornamento",
    "planInfo": "Il piano selezionato include 3 utenti. Sceglierne altri di seguito se necessario.",
    "validUsers": "Immettere un numero valido di utenti"
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});