define("ember-rs/locales/ja/templates/signer/sign/locked-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lockedModal = void 0;
  var lockedModal = {
    "title": "ドキュメントがロックされました",
    "maxAttempts": "許可されている ID 確認の最大試行回数に達しました。",
    "locked": "ドキュメントがロックされ、送信者に通知されました。続行するには、ドキュメントの送信者に連絡してください。"
  };
  _exports.lockedModal = lockedModal;
});