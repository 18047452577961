define("ember-rs/locales/pt-br/templates/onboarding/send-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding1 = void 0;
  var sendOnboarding1 = {
    "greatWork": "Muito bem! Você enviou seu primeiro documento.",
    "youHave": "Você tem ",
    "remaining": "restantes na sua avaliação."
  };
  _exports.sendOnboarding1 = sendOnboarding1;
});