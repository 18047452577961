define("ember-rs/locales/fr/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareNextRoute = void 0;
  var prepareNextRoute = {
    "mergeFieldStepNext": "Suivant : champs de fusion",
    "documentStepNext": "Document suivant",
    "reviewStepNext": "Suivant : Vérifier",
    "placeFieldNext": "Suivant : Placer les champs"
  };
  _exports.prepareNextRoute = prepareNextRoute;
});