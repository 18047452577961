define("ember-rs/services/layout-size-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    windowHeight: 0,
    windowWidth: 0,
    tmHeaderHeight: 60,
    tmFooterHeight: 60,
    mainHeaderHeight: 75,
    subHeaderHeight: 70,
    // maxContentWidth: 1100,
    maxContentWidth: 2400,
    signingFooterHeight: 70,
    documentViewerData: Ember.inject.service(),
    showMobileNavMenu: false,
    showModal: false,
    showDetailsSidebar: false,
    showTip: false,
    sidebarScrollPosition: 0,
    showPrepareTutorialPointer: false,
    scrollbarWidth: 0,
    withinEmbed: false,
    // breakpoints
    mobileWebCutoff: 600,
    // for desktop browsers that are really small
    phoneSize: 768,
    // for mobile browsers
    desktopSize: 1024,
    // for mobile browsers
    hasMobileUserAgent: function hasMobileUserAgent() {
      if (navigator && navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|Blackberry/i)) {
        return true;
      } else {
        return false;
      }
    },
    isPhoneSize: Ember.computed.lt('windowWidth', 768),
    isTabletSize: Ember.computed('windowWidth', 'useMobileLayout', function () {
      if (this.useMobileLayout) {
        if (this.windowWidth >= this.phoneSize) {
          return true;
        } else {
          return false;
        }
      } else if (this.windowWidth >= this.phoneSize && this.windowWidth < this.desktopSize) {
        return true;
      } else {
        return false;
      }
    }),
    isNarrowSize: Ember.computed.lt('windowWidth', 880),
    useMobileLayout: Ember.computed('windowWidth', function () {
      if (this.hasMobileUserAgent()) {
        return true;
      } else {
        if (this.withinEmbed) {
          return false;
        }

        if (this.windowWidth < this.mobileWebCutoff) {
          return true;
        }
      }

      return false;
    }),
    useDesktopLayout: Ember.computed.not('useMobileLayout'),
    isLandscape: Ember.computed('windowWidth', 'windowHeight', function () {
      if (this.windowWidth > this.windowHeight) {
        return true;
      } else {
        return false;
      }
    }),
    isPortrait: Ember.computed.not('isLandscape'),
    sidePadding: Ember.computed('useMobileLayout', function () {
      var padding = 30;

      if (this.useMobileLayout) {
        padding = 0;
      }

      return padding;
    }),
    totalSidePadding: Ember.computed('sidePadding', 'useMobileLayout', function () {
      if (this.useMobileLayout) {
        return 0;
      } else {
        return this.sidePadding * 2;
      }
    }),
    containerWidth: Ember.computed('windowWidth', 'totalSidePadding', 'maxContentWidth', 'scrollbarWidth', function () {
      if (this.windowWidth > this.maxContentWidth + this.totalSidePadding) {
        return this.maxContentWidth + this.totalSidePadding; // + this.get('scrollbarWidth');
      } else {
        return this.windowWidth; // + this.get('scrollbarWidth');
      }
    }),
    containerStyle: Ember.computed('windowHeight', 'containerWidth', 'sidePadding', function () {
      var height = this.windowHeight;
      var width = this.containerWidth;
      var sidePadding = this.sidePadding;
      return "width: ".concat(width, "px; height: ").concat(height, "px; padding: 0 ").concat(sidePadding, "px").htmlSafe();
    }),
    mainHeaderStyle: Ember.computed('mainHeaderHeight', function () {
      var height = this.mainHeaderHeight;
      return "height: ".concat(height, "px; width: 100%;").htmlSafe();
    }),
    contentStyle: Ember.computed('windowHeight', function () {
      var headerHeight = 0;

      if (this.useMobileLayout) {
        headerHeight = 60;
      } else {
        headerHeight = 75;
      }

      var height = this.windowHeight - headerHeight;
      return "width: 100%; height: ".concat(height, "px;").htmlSafe();
    }),
    subHeaderStyle: Ember.computed('mainHeaderHeight', 'useMobileLayout', function () {
      var height = "".concat(this.subHeaderHeight, "px");

      if (this.useMobileLayout) {
        height = 'auto';
      }

      return "height: ".concat(height, "; width: 100%;").htmlSafe();
    }),
    scrollableContentStyle: Ember.computed('windowHeight', function () {
      var height = this.windowHeight - this.mainHeaderHeight - this.subHeaderHeight;
      return "width: 100%; height: ".concat(height, "px; overflow: auto;").htmlSafe();
    }),
    stickyFooterContainerStyle: Ember.computed('windowHeight', function () {
      var height = this.windowHeight - this.mainHeaderHeight - this.subHeaderHeight;
      return "width: 100%; min-height: ".concat(height, "px;").htmlSafe();
    }),
    tmHeaderStyle: Ember.computed('tmHeaderHeight', function () {
      return "height: ".concat(this.tmHeaderHeight, "px").htmlSafe();
    }),
    tmHeaderContainerStyle: Ember.computed('containerWidth', function () {
      if (this.useMobileLayout) {
        return "width: ".concat(this.containerWidth, "px; padding: 0; height: ").concat(this.windowHeight, "px;").htmlSafe();
      } else {
        return "width: ".concat(this.containerWidth, "px; padding: 0 ").concat(this.sidePadding, "px;").htmlSafe();
      }
    }),
    sigingFooterStyle: Ember.computed('containerWidth', function () {
      return "width: ".concat(this.containerWidth, "px; padding: 0 ").concat(this.sidePadding, "px;").htmlSafe();
    }),
    tmContentHeight: Ember.computed('windowHeight', 'tmHeaderHeight', 'tmFooterHeight', function () {
      return this.windowHeight - this.tmHeaderHeight - this.tmFooterHeight;
    }),
    tmContentStyle: Ember.computed('tmContentHeight', 'containerWidth', function () {
      var width = this.containerWidth;
      var padding = 0;

      if (this.get('layoutSizeData.useDesktopLayout')) {
        padding = this.sidePadding;
      }

      return "width: ".concat(width, "px; height: ").concat(this.tmContentHeight, "px; padding: ").concat(padding, "px;").htmlSafe();
    }),
    onboardingContentStyle: Ember.computed('tmContentHeight', 'containerWidth', 'windowWidth', function () {
      var width = this.containerWidth;
      var left = (this.windowWidth - width) / 2;
      return "width: ".concat(width, "px; height: ").concat(this.tmContentHeight, "px; left: ").concat(left, "px;").htmlSafe();
    }),
    reviewStepHeightStyle: Ember.computed('containerWidth', 'tmHeaderHeight', 'windowHeight', function () {
      var height = this.windowHeight - this.tmHeaderHeight - this.subHeaderHeight;
      return "height: ".concat(height, "px; overflow: auto;").htmlSafe();
    })
  });

  _exports.default = _default;
});