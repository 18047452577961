define("ember-rs/locales/it/templates/start-document", ["exports", "ember-rs/locales/it/templates/start-document/-send-for-signature"], function (_exports, _sendForSignature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    sendForSignature: _sendForSignature.sendForSignature,
    "title": "Inizia documento"
  };
  _exports.startDocument = startDocument;
});