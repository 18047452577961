define("ember-rs/locales/fr/templates/components/document-access-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentAccessSettings = void 0;
  var documentAccessSettings = {
    "addViewer": "Ajouter observateur",
    "email": "E-mail",
    "name": "Name",
    "add": "Ajouter",
    "cancel": "Annuler",
    "editSigner": "Modifier signataire",
    "save": "Enregistrer",
    "manageAccess": "Gérer l’accès",
    "accessTip": "Ces personnes ont accès à ce document et peuvent le télécharger ou le consulter à tout moment.",
    "signers": "Signataires",
    "edit": "modifier",
    "viewers": "Observateurs",
    "remove": "supprimer",
    "updateEmail": "Adresse e-mail du signataire mise à jour avec succès.",
    "updateSigner": "Successfully updated signer details."
  };
  _exports.documentAccessSettings = documentAccessSettings;
});