define("ember-rs/locales/fr/controllers/document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "notAllowed": "Vous n'êtes pas autorisé à faire cela.",
    "voided": "Le document a été annulé avec succès.",
    "voidingFailed": "Impossible d'annuler le document.",
    "docExtended": "Le document a été étendu avec succès.",
    "tagUpdated": "Les tags ont été mis à jour avec succès",
    "tagUpdateFailed": "Impossible de mettre les balises à jour : {{{tags}}} ",
    "viewerListUpdated": "Liste des observateurs mise à jour avec succès",
    "viewerUpdateFailed": "Impossible de mettre à jour la liste des observateurs : {{{viewers}}}"
  };
  _exports.details = details;
});