define("ember-rs/locales/zh-cn/templates/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choose_documents = void 0;
  var choose_documents = {
    "drag_drop": "将文件拖放到此处或:",
    "choose_computer": "从计算机中选择",
    "choose_template": "选择要用于批量发送的模板",
    "use_template": "使用模板",
    "other_source": "其他来源",
    "from_cloud": "来自云",
    "template": "模板",
    "add_another": "添加其他文件",
    "prepare_document": "准备文档",
    "prepare_documents": "准备文档",
    "upload_signers": "上载签名者",
    "drag_drop_csv": "拖放 CSV 文件:",
    "select_file_computer": "从您的计算机中选择一个文件",
    "remove_file": "删除",
    "upload_success": "文件已成功上载。",
    "upload_multifile": "不支持多文件上载。请仅上载一个文件。",
    "upload_invalid_extension": "文件 {{{fileName}}} 的扩展名无效。",
    "upload_size_limit": "文件 {{{fileName}}} 大于我们的 5 MB 限制。",
    "send_package": "发送文档包",
    "file_size_exceeded": "您的总文件大小不能超过 {{{maxFileSize}}} MB。请删除一个或多个文档以继续。",
    "max_files_exceeded": "只能同时准备 {{{maxFileCount}}} 个文档。",
    "min_files_required": "一个包至少需要两个文档。",
    "preparing_package": "我们正在准备您的文档。这可能需要几秒钟时间。",
    "merge_upload_error": "准备您的文档时出现问题。请通过删除并重新上载您的文档进行重试。"
  };
  _exports.choose_documents = choose_documents;
});