define("ember-rs/models/attachment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    filename: (0, _model.attr)('string'),
    url: (0, _model.attr)('string'),
    // Components do not belong to to a document so this is virtual vs an association
    componentId: (0, _model.attr)('string')
  });

  _exports.default = _default;
});