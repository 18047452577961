define("ember-rs/routes/reports/enterprise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Enterprise Usage'
  });

  _exports.default = _default;
});