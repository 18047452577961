define("ember-rs/locales/en/templates/onboarding/welcome-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding2 = void 0;
  var welcomeOnboarding2 = {
    "tips": "Access your <span>documents</span> and <span>templates</span>, <span>manage settings</span>, and find video <span>tutorials and how-to articles</span>."
  };
  _exports.welcomeOnboarding2 = welcomeOnboarding2;
});