define("ember-rs/locales/es/templates/onboarding/new-user-onboarding-from-sharefile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFromSharefile = void 0;
  var newUserOnboardingFromSharefile = {
    "title": "Enviar y firmar sus documentos",
    "startText": "Envíe documentos para que los firmen otras personas, o firme los documentos que necesitan su firma.",
    "clickLearn": "Haga clic en Siguiente para aprender cómo hacerlo.",
    "addSigner": "Agregar firmantes",
    "addAnyone": "Agregue a cualquier persona que necesite completar o firmar este documento.",
    "prepare": "Preparar el documento",
    "placeFields": "Coloque los campos donde necesita que los firmantes rellenen la información o firmen el documento. Puede asignar campos a cada firmante.",
    "sendTrack": "Enviar y seguir el progreso",
    "onceSent": "Una vez que haya enviado el documento, puede seguir su progreso en la página de detalles del documento. Para navegar a la página de detalles, simplemente haga clic en el documento desde el panel de documentos."
  };
  _exports.newUserOnboardingFromSharefile = newUserOnboardingFromSharefile;
});