define("ember-rs/routes/logged-in", ["exports", "ember-rs/mixins/current-user-route", "ember-rs/mixins/trial-methods", "ember-rs/utils/feature-flags"], function (_exports, _currentUserRoute, _trialMethods, featureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_trialMethods.default, _currentUserRoute.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    featureFlagService: Ember.inject.service(),
    ////////////////////////  HOOKS  ////////////////////////
    model: function model(params, transition) {
      Ember.set(this, '_oldAccessToken', Ember.get(this, 'session.data.authenticated.access_token'));
      return this.getCurrentUser(params, transition);
    },
    afterModel: function afterModel(model, transition) {
      var self = this;
      return Ember.RSVP.allSettled([Ember.get(this, 'currentUser.model'), Ember.get(this, 'currentUser.account'), Ember.get(this, 'currentUser.account.accountStatSet')], 'loading user and account and stat set').then(function (resolvedArray) {
        if (self.currentUser.model) {
          self.currentUser.model.loadSignatures().catch(function () {
            //try one more time to fetch signatures if it fails
            self.currentUser.model.loadSignatures();
          });
          var defaultDateFormat = self.currentUser.model.dateformat || 'MM/DD/YYYY';
          localStorage.setItem('defaultDateFormat', defaultDateFormat); // Retry if accountStatSet fetch fails

          if (resolvedArray[2].state === "rejected") {
            self.currentUser.get('account').then(function (account) {
              account.belongsTo("accountStatSet").reload();
            });
          }
        } // initialize launch darkly


        Ember.get(self, 'featureFlagService').setFFUser().then(function () {
          self.controllerFor('application').set('canInitiateWorkflows', self.featureFlagService.getFeatureFlag(featureFlags.ENABLE_WORKFLOWS));
        });

        if (Ember.get(self, 'currentUser.model.account.isArchive')) {
          var infos = transition.routeInfos;
          var currentRoute = infos[infos.length - 1].name;

          if (!Ember.get(self, 'allowedRoutes').includes(currentRoute) && Ember.get(self, 'currentUser.model.account.isMigrated') && Ember.get(self, 'trialDaysRemaining') > 0) {
            self.transitionTo('unauthorized.archived-migrated');
          } else if (!Ember.get(self, 'allowedRoutes').includes(currentRoute) && Ember.get(self, 'currentUser.model.account.isHybrisTrial')) {
            self.transitionTo('unauthorized.hybris-trial-expired');
          }

          if (currentRoute !== "upgrade.index" && !Ember.get(self, 'currentUser.model.account.isHybrisTrial')) {
            self.transitionTo('unauthorized.trial-expired');
          }
        }
      });
    },
    getCurrentUserOnReAuthentication: Ember.observer('session.data.authenticated.access_token', function () {
      if (Ember.get(this, 'session.data.authenticated.access_token') && Ember.get(this, 'session.data.authenticated.access_token') !== this._oldAccessToken) {
        Ember.set(this, '_oldAccessToken', Ember.get(this, 'session.data.authenticated.access_token'));
        this.store.unloadAll('user');
        this.store.unloadAll('account');
        this.refresh();
      }
    })
  });

  _exports.default = _default;
});