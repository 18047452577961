define("ember-rs/mixins/add-roles", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  DEFAULTS  ////////////////////////
    addSignersPadding: 120,
    addSignersOuterHeight: 0,
    ////////////////////////  SERVICES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    multipleRoles: Ember.computed('model.roles.[]', function () {
      return Ember.get(this, 'model.roles.length') > 1;
    }),
    hasFirstPageImageURL: Ember.computed('firstPageImage', function () {
      var firstImage = this.firstPageImage || {};
      return Ember.isPresent(firstImage.original);
    }),
    firstPageImage: Ember.computed('model.upload.baseFile.pageImages.[]', function () {
      var pageImages = Ember.get(this, 'model.upload.baseFile.pageImages') || [];
      return pageImages[0];
    }),
    availableHeight: Ember.computed('layoutSizeData.windowHeight', 'layoutSizeData.tmHeaderHeight', function () {
      return Ember.get(this, 'layoutSizeData.windowHeight') - Ember.get(this, 'layoutSizeData.tmHeaderHeight');
    }),
    addSignersStyle: Ember.computed('availableHeight', 'addSignersPadding', function () {
      return "max-height: ".concat(this.availableHeight, "px;").htmlSafe();
    }),
    ////////////////////////  OBSERVERS  ////////////////////////
    updateSizing: Ember.observer('model.roles.[]', 'availableHeight', function () {
      Ember.run.next(this, function () {
        Ember.set(this, 'addSignersOuterHeight', (0, _jquery.default)('#add_signers').outerHeight());

        if (this.addSignersPadding === 120 && this.addSignersOuterHeight >= this.availableHeight) {
          Ember.set(this, 'addSignersPadding', 60);
        }

        if (this.addSignersPadding === 60 && this.addSignersOuterHeight + this.addSignersPadding * 2 < this.availableHeight) {
          Ember.set(this, 'addSignersPadding', 120);
        }
      });
    })
  });

  _exports.default = _default;
});