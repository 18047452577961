define("ember-rs/locales/fr/controllers/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "upgradeMsg": "Votre compte n'a pas accès à cette fonctionnalité. Passez à un abonnement Pro pour pouvoir utiliser des modèles intégrés.",
    "templateDeleteMsg": "Le modèle a été supprimé avec succès.",
    "tagUpdateMsg": "Les tags ont été mis à jour avec succès",
    "tagUpdateFailedMsg": "Impossible de mettre à jour les tags : {{{formattedResponse}}}",
    "templateDeleteConfirm": "Êtes-vous sûr de vouloir supprimer ce modèle ?",
    "loadSignerLinksFailedMsg": "Impossible de charger les liens du signataire du modèle"
  };
  _exports.details = details;
});