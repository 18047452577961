define("ember-rs/locales/zh-cn/templates/unauthorized/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expired = void 0;
  var expired = {
    "linkExpired": "文档链接已过期",
    "toDownload": "要下载此文档，请申请一个新下载链接。",
    "sendLink": "发送新下载链接"
  };
  _exports.expired = expired;
});