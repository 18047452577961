define("ember-rs/components/document-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-wrapper', 'document-expiration-select'],
    options: [2, 5, 14, 30, 60, 90, 180, 365],
    attr: 'documentExpiration',
    currentSelection: Ember.computed.oneWay('selection'),
    willDestroyElement: function willDestroyElement() {
      this.set('currentSelection', null);
    },
    actions: {
      updateSetting: function updateSetting(value) {
        this.sendAction('updateSetting', value, this);
      }
    }
  });

  _exports.default = _default;
});