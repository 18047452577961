define("ember-rs/components/reports/account-statistics", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var divide = function divide(numerator, denominator) {
    if (!denominator) {
      return 0;
    }

    return numerator / denominator;
  };

  var formatByMonth = function formatByMonth(timeSeries, total, months, today) {
    var output = {
      thisMonth: 0,
      lastMonth: 0,
      average: divide(total, months)
    };

    for (var i = 0, monthLabel, currentMonth, j, jMax, datum; i < 2; i++) {
      monthLabel = ['thisMonth', 'lastMonth'][i];
      currentMonth = (0, _moment.default)(today).subtract(i, 'months').month();

      for (j = 0, jMax = timeSeries.length; j < jMax; j++) {
        datum = timeSeries[j];

        if ((0, _moment.default)(datum.date).month() === currentMonth) {
          output[monthLabel] = datum.count;
        }
      }
    }

    return output;
  };

  var _default = Ember.Component.extend({
    formattedByMonth: Ember.computed('report', function () {
      var output = {};
      var queries = ['sent', 'viewed', 'expired', 'executed', 'per_user'];

      for (var i = 0, iMax = queries.length, query; i < iMax; i++) {
        query = queries[i];
        output[query] = formatByMonth(this.get("report.".concat(query)) || [], this.get("report.".concat(query, "_total")) || 0, this.get("report.months_elapsed") || 1, this.get("report.date"));
      }

      return output;
    }),
    percentages: Ember.computed('formattedByMonth', function () {
      var output = {};
      var queries = ['viewed', 'expired', 'executed'];
      var periods = ['thisMonth', 'lastMonth', 'average'];

      for (var i = 0, iMax = queries.length, j, jMax = periods.length, query, period; i < iMax; i++) {
        query = queries[i];
        output[query] = {};

        for (j = 0; j < jMax; j++) {
          period = periods[j];
          output[query][period] = divide(this.get("formattedByMonth.".concat(query, ".").concat(period)) * 100, this.get("formattedByMonth.sent.".concat(period)));
        }
      }

      return output;
    }),
    perUser: Ember.computed('formattedByMonth', function () {
      var output = {};
      var periods = ['thisMonth', 'lastMonth', 'average'];

      for (var i = 0, iMax = periods.length, period; i < iMax; i++) {
        period = periods[i];
        output[period] = divide(this.get("formattedByMonth.sent.".concat(period)), this.get("report.user_count"));
      }

      return output;
    })
  });

  _exports.default = _default;
});