define("ember-rs/components/styled-text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    attributeBindings: ['style', 'type', 'value', 'size', 'placeholder']
  });

  _exports.default = _default;
});