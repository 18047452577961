define("ember-rs/locales/nl/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTagsEditor = void 0;
  var documentTagsEditor = {
    "addTags": "Voeg tags toe aan dit document om het later gemakkelijk te vinden. U kunt ook waarden aan tags toevoegen, bijvoorbeeld 'client_id: 1234'",
    "tagName": "Tagnaam...",
    "optional": "Waarde (optioneel)"
  };
  _exports.documentTagsEditor = documentTagsEditor;
});