define("ember-rs/templates/components/document-token-expiration-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9AjGFoq1",
    "block": "{\"symbols\":[\"xs\",\"option\",\"&default\"],\"statements\":[[8,\"x-select\",[],[[\"@value\",\"@on-change\"],[[34,2],[30,[36,3],[[32,0],\"updateSetting\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[27,[32,3]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"option\"]],[],[[\"@value\"],[[32,2,[\"value\"]]]],[[\"default\"],[{\"statements\":[[2,\" \"],[1,[30,[36,1],[[30,[36,0],[\"components.documentTokenExpirationSelect.\",[32,2,[\"label\"]]],null]],null]],[2,\" \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"t\",\"currentSelection\",\"action\",\"if\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/document-token-expiration-select.hbs"
    }
  });

  _exports.default = _default;
});