define("ember-rs/locales/zh-cn/templates/onboarding/prepare-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTutorial = void 0;
  var prepareTutorial = {
    "title": "准备文档以获取签名",
    "selectField": "选择右侧要添加的字段。",
    "placeField": "放置字段",
    "clickPlace": "单击要放置新字段的位置。",
    "adjustSize": "调整字段的大小",
    "adjustHover": "要进行调整，请将鼠标悬停在字段上并拖动点。",
    "adjustOptions": "调整选项",
    "moreOptions": "单击该字段以显示更多选项，然后选择应填写该字段的人员。",
    "thatsIt": "就是这样了!",
    "clickReview": "完成后，单击<b>下一步: 检查</b>",
    "knowMore": "想了解更多信息吗?",
    "support": "查看支持文章和视频"
  };
  _exports.prepareTutorial = prepareTutorial;
});