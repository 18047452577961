define("ember-rs/locales/en/controllers/signer/sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    "validPhone": "Please enter a valid phone number",
    "passcode": "Invalid passcode. Please try again."
  };
  _exports.sign = sign;
});