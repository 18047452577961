define("ember-rs/locales/ja/templates/document/details", ["exports", "ember-rs/locales/ja/templates/document/details/unlock"], function (_exports, _unlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    unlock: _unlock.unlock,
    "status": "状態",
    "overview": "概要",
    "history": "履歴",
    "people_involved": "関連ユーザー:",
    "manage": "管理",
    "security_options": "セキュリティのオプション:",
    "declined": "辞退しました",
    "expired": "期限切れ",
    "expires": "有効期限",
    "extend": "延長する",
    "pin": "PIN  *****",
    "generate_pin": "新しい PIN の生成",
    "pin_tip": "新しい PIN が作成され、現在の PIN が無効になります。",
    "passcode_question": "パスコードの質問:",
    "clear_attempts": "入力したパスコードのクリア",
    "passcode": "パスコード",
    "signer_sequencing": "署名者のシーケンス",
    "lock_message": "このドキュメントはロックされました。",
    "attachments": "添付ファイル:",
    "reference_id": "参照 ID: ",
    "tags": "タグ: ",
    "remind_header": "署名リンクの送信",
    "remind_body": "このドキュメントに署名するためのリンクを以下のメールアドレスに送信しました。この手順をスキップする場合、設定ページでこのアドレスを確認できます。",
    "new_pin": "新しいPIN",
    "new_passcode": "新しい PIN です。この PIN をドキュメントの署名者に送信して、署名者がアクセスできるようにする必要があります。",
    "done": "完了",
    "draft": "下書き",
    "pending": "保留",
    "voided": "取り消し済み",
    "executed": "署名済み",
    "recipient_message": "受信者へのメッセージ:",
    "void_reason": "Reason for Voiding:",
    "document_voided": "Document Voided:",
    "document_declined": "Decline Reason:"
  };
  _exports.details = details;
});