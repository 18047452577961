define("ember-rs/templates/start-document/-collect-feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w0+fRfYP",
    "block": "{\"symbols\":[\"accessible\",\"entitled\",\"free\",\"showModal\",\"buttonAction\",\"closeModalAction\"],\"statements\":[[10,\"li\"],[14,0,\"theme__workflow\"],[12],[2,\"\\n\"],[6,[37,8],null,[[\"accessible\",\"action\"],[[35,7,[\"account\",\"isWorkflowsEnabled\"]],\"collectFeedback\"]],[[\"default\"],[{\"statements\":[[6,[37,5],[[35,7,[\"isConfirmed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[16,0,[31,[\"card \",[30,[36,5],[[35,4],\"is-saving\"],null]]]],[4,[38,6],[[32,0],\"collectFeedback\"],null],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[34,3]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"collectfeedback\"],null]],[2,\"\\n        \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"start-document.collect-feedback.title\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"theme_generic-text\"],[12],[1,[30,[36,1],[\"start-document.collect-feedback.description\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"collectfeedback\"],null]],[2,\"\\n          \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"start-document.collect-feedback.title\"],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"theme_generic-text\"],[12],[1,[30,[36,1],[\"start-document.collect-feedback.description\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3,4,5,6]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"t\",\"unconfirmed-user-button\",\"rs-spinner\",\"fetchingFeedbackLink\",\"if\",\"action\",\"currentUser\",\"paywall-button\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/start-document/-collect-feedback.hbs"
    }
  });

  _exports.default = _default;
});