define("ember-rs/controllers/document/details/unlock", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    paymentComponentData: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    //////////////////////////  DEFAULTS  //////////////////////////
    loading: false,
    ////////////////////////  METHODS  ////////////////////////
    closeModal: function closeModal() {
      this.transitionToRoute('document.details');
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      cancelUnlock: function cancelUnlock() {
        this.closeModal();
      },
      unlockDocument: function unlockDocument() {
        var _this = this;

        var flash = this.flashMessages;
        new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            url: "".concat(_environment.default.api.endpoint, "/documents/").concat(_this.get('model.id'), "/unlock_kba"),
            type: 'PUT'
          }).done(function (response) {
            _this.set('model.kbaLocked', false);

            _this.closeModal();

            flash.success(_this.i18n.t('controllers.document.unlock.success'));
            resolve(response);
          }).fail(function (error) {
            _this.set('model.kbaLocked', true);

            flash.warning(_this.i18n.t('controllers.document.unlock.error'));
            reject(error);
          });
        }, 'reset kba signers');
      }
    }
  });

  _exports.default = _default;
});