define("ember-rs/locales/en/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceCompleteDocumentModal = void 0;
  var forceCompleteDocumentModal = {
    "header": "Force Complete Document",
    "forceCompleted": "This will remove all the fields assigned to signers who have not yet signed and will make the document as executed (final state). Once this is done, there is no undo.",
    "briefMoment": "It may take a brief moment to reflect the new status on dashboard.",
    "forceComplete": "Force Complete",
    "cancel": "Cancel",
    "success": "Document has been successfully force completed.",
    "failed": "Failed to force complete the document"
  };
  _exports.forceCompleteDocumentModal = forceCompleteDocumentModal;
});