define("ember-rs/locales/it/templates/onboarding/signer-onboarding-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboardingOld = void 0;
  var signerOnboardingOld = {
    "title": "Firmare un documento con RightSignature&trade; è semplice.",
    "completefields": "Compilare i campi indicati da un contrassegno.",
    "submitSignature": "Compilare i campi e inviare la firma.",
    "progress": "La barra di avanzamento consente di visualizzare il progresso raggiunto. Dopo aver completato tutti i campi obbligatori, fare clic sul pulsante Invia firma per completare la firma.",
    "help": "Serve assistenza?",
    "questions": "In caso di domande o se sono necessarie maggiori informazioni, chiedere aiuto qui."
  };
  _exports.signerOnboardingOld = signerOnboardingOld;
});