define("ember-rs/routes/signer/confirm-identity", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    errorHandler: function errorHandler(signerId, identityToken) {
      var _this = this;

      return function (error) {
        if (error.responseJSON.toString() === "Identity token for document is expired.") {
          _this.transitionTo('unauthorized.confirm-identity-expired', {
            queryParams: {
              signer_id: signerId,
              identity_token: identityToken
            }
          });
        } else {
          _this.flashMessages.warning(_this.i18n.t('components.signers.error-confirm-identity'));
        }
      };
    },
    model: function model(queryParams, transition) {
      // transition.routeInfos[0] -> application
      // transition.routeInfos[1] -> signer
      // transition.routeInfos[2] -> signer.confirm-identity
      var signerId = transition.routeInfos[1].params.signer_id;
      var identityToken = queryParams.identity_token;

      if (Ember.isBlank(signerId) || Ember.isBlank(identityToken)) {
        this.flashMessages.warning(this.i18n.t('components.signers.missing-identity'));
        return undefined;
      }

      var errorHandler = this.errorHandler(signerId, identityToken);
      return _jquery.default.ajax({
        url: "".concat(_environment.default.api.endpoint, "/signers/").concat(signerId, "/confirm_identity"),
        type: 'POST',
        data: {
          identity_token: identityToken
        }
      }).then(function (result) {
        return result;
      }, errorHandler);
    }
  });

  _exports.default = _default;
});