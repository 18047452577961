define("ember-rs/locales/ja/templates/onboarding/signer-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding1 = void 0;
  var signerOnboarding1 = {
    "redFlag": "<span>赤いフラグ</span>は入力が必要な領域を示しています。",
    "zoom": "スマートフォンでピンチズームしてドキュメントを拡大します。"
  };
  _exports.signerOnboarding1 = signerOnboarding1;
});