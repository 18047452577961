define("ember-rs/routes/reusable-template/prepare", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    documentViewerData: Ember.inject.service(),
    overlayerData: Ember.inject.service(),
    session: Ember.inject.service(),
    sharefilePostMessage: Ember.inject.service(),
    wcsWorkflow: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.controllerFor('reusable-template').set('currentStep', 'prepare_document');
    },
    setupController: function setupController(controller, model) {
      this.set("documentViewerData.pageHeight", model.get("upload.baseFile.imageHeight"));
      this.set("documentViewerData.pageWidth", model.get("upload.baseFile.imageWidth"));
      this.overlayerData.deselectComponents();

      this._super(controller, model);
    },
    actions: {
      nextStep: function nextStep() {
        if (this.wcsWorkflow.getValue()) {
          //iframed for WCS Workflow
          var self = this;
          this.controller.set('isSaving', true);
          this.currentModel.set('current', true);
          this.currentModel.set('published', true);
          this.currentModel.set('forceEdit', false);

          if (Ember.isBlank(this.currentModel.get('name'))) {
            var defaultName = this.get('documentTemplate.upload.baseFile.filename').split('.')[0].split(/[^a-zA-Z0-9-]+/).join(' ');
            this.currentModel.set('name', defaultName);
          }

          if (Ember.isBlank(this.currentModel.get('expiresIn'))) {
            this.currentModel.set('expiresIn', 30);
          }

          this.currentModel.save().then(function () {
            var reusableTemplates = self.store.peekAll('reusableTemplate');
            reusableTemplates.forEach(function (reusableTemplate) {
              if (reusableTemplate.get('templateGuid') === self.currentModel.get('templateGuid') && reusableTemplate.get('id') !== self.currentModel.get('id')) {
                reusableTemplate.set('currentReusableTemplate', false);
              }
            });
            self.controller.set('isSaving', false);
            self.sharefilePostMessage.post({
              event_type: "template_created",
              template_id: self.currentModel.id
            });
          }, function () {
            self.controller.set('isSaving', false);
            self.currentModel.set('current', false);
          });
        } else {
          this.transitionTo('reusable-template.review');
        }
      },
      close: function close() {
        if (this.wcsWorkflow.getValue()) {
          //iframed for WCS Workflow
          this.sharefilePostMessage.post({
            event_type: "template_creation_cancelled"
          });
        } else {
          this.transitionTo('templates');
        }
      }
    }
  });

  _exports.default = _default;
});