define("ember-rs/components/document-expiration-editor", ["exports", "jquery", "moment", "ember-rs/config/environment"], function (_exports, _jquery, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    dateformat: Ember.computed.alias('currentUser.model.dateformat'),
    datePickerFormat: Ember.computed.alias('currentUser.model.dateFormatFlatpickr'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var documentExpiration = this.get('document.documentExpiresAt');
      var documentSentAt = this.get('document.documentSentAt');
      var newExpiration, maxExpiration;

      if (documentExpiration) {
        newExpiration = documentExpiration.format(this.dateformat);
      } else {
        newExpiration = (0, _moment.default)().format(this.dateformat);
      }

      if (documentSentAt) {
        maxExpiration = documentSentAt.add(1, 'year').subtract(1, 'day').format(this.dateformat);
      } else {
        maxExpiration = (0, _moment.default)().add(1, 'year').format(this.dateformat);
      }

      this.set('newExpiration', newExpiration);
      this.set('maxExpiration', maxExpiration);
    },
    actions: {
      updateExpiration: function updateExpiration(newExpiration) {
        this.set('expirationOriginalFormat', newExpiration[0]);
        newExpiration = (0, _moment.default)(newExpiration[0]).format(this.dateformat);
        this.set('newExpiration', newExpiration);
      },
      saveExpirationAndCloseModal: function saveExpirationAndCloseModal() {
        var _this = this;

        var newExpiration = (0, _moment.default)(this.expirationOriginalFormat).format("YYYY-MM-DD");

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/documents/").concat(this.get('document.id'), "/extend_expiration"),
          type: 'PUT',
          data: {
            new_expiration: newExpiration
          }
        }).then(function (response) {
          _this.setProperties({
            'document.documentExpiresAt': (0, _moment.default)(response.document.document_expires_at),
            'document.documentState': 'pending'
          });

          _this.sendAction('closeModal');

          _this.flashMessages.success(_this.i18n.t('components.documentExpirationEditor.updateSuccess'));
        });
      }
    }
  });

  _exports.default = _default;
});