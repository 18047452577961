define("ember-rs/components/reusable-template-selector-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    templateSelected: false,
    loading: Ember.computed('addingTemplate', 'templateSelected', function () {
      return this.addingTemplate && this.templateSelected;
    }),
    isSelected: Ember.computed('documentTransaction.documents.[]', function () {
      var self = this;
      return Ember.isPresent(this.get('documentTransaction.documents').filter(function (doc) {
        return doc.get('fromReusableTemplate') && doc.get('templateGuid') === self.get('reusableTemplate.templateGuid') && doc.get('version') === self.get('reusableTemplate.version');
      }));
    }),
    actions: {
      select: function select() {
        this.set('templateSelected', true);
        this.sendAction('action', this.reusableTemplate);
      }
    }
  });

  _exports.default = _default;
});