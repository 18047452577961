define("ember-rs/components/paywall-action", ["exports", "ember-rs/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    currentUser: Ember.inject.service(),
    accessible: true,
    showToggles: true,
    showTogglesModal: false,
    entitled: Ember.computed.alias('currentUser.entitled'),
    free: Ember.computed.alias('currentUser.free'),
    isDemo: Ember.computed(function () {
      return Ember.isPresent(_environment.default.demo);
    }),
    isDevelopment: Ember.computed(function () {
      return _environment.default.environment === 'development';
    }),
    showTogglesInUI: Ember.computed('isDevelopment', 'showToggles', 'isDemo', function () {
      var _Ember$getProperties = Ember.getProperties(this, 'isDevelopment', 'showToggles', 'isDemo'),
          isDevelopment = _Ember$getProperties.isDevelopment,
          showToggles = _Ember$getProperties.showToggles,
          isDemo = _Ember$getProperties.isDemo;

      return showToggles && isDevelopment && !isDemo;
    }),
    safeToggle: function safeToggle(property) {
      if (this.isDevelopment) {
        this.toggleProperty(property);
      }
    },
    actions: {
      toggleToggleModal: function toggleToggleModal() {
        this.safeToggle('showTogglesModal');
      },
      toggleAccessible: function toggleAccessible() {
        this.safeToggle('accessible');
      },
      toggleEntitled: function toggleEntitled() {
        this.safeToggle('currentUser.entitled');
      },
      toggleFree: function toggleFree() {
        this.safeToggle('currentUser.free');
      }
    }
  });

  _exports.default = _default;
});