define("ember-rs/components/reports/month-select", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var today = (0, _moment.default)();

  var _default = Ember.Component.extend({
    months: Ember.computed('account.id', function () {
      var beginAt = (0, _moment.default)(this.get('account.createdAt')).startOf('month');
      var endAt = (0, _moment.default)(today).endOf('month');
      var numberOfMonths = Math.round(_moment.default.duration(endAt.diff(beginAt)).asMonths());
      var dates = [];

      for (var i = 0, monthAtHand; i < numberOfMonths; i++) {
        monthAtHand = (0, _moment.default)(today).subtract(i, 'months');
        dates.push({
          label: monthAtHand.format('MMMM YYYY'),
          value: monthAtHand.format('YYYY-MM-01')
        });
      }

      if (this.monthSelected && !_lodash.default.find(dates, {
        value: this.monthSelected
      })) {
        this.set('monthSelected', null);
      }

      return dates;
    }),
    actions: {
      monthWasSelected: function monthWasSelected(selection) {
        this.set('monthSelected', selection);
        this.sendAction('action', selection);
      }
    }
  });

  _exports.default = _default;
});