define("ember-rs/locales/it/templates/onboarding/prepare-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTutorial = void 0;
  var prepareTutorial = {
    "title": "Prepara un documento per la firma",
    "selectField": "Selezionare il campo a destra che si desidera aggiungere.",
    "placeField": "Posiziona il campo",
    "clickPlace": "Fare clic nel punto in cui si desidera inserire il nuovo campo.",
    "adjustSize": "Regola le dimensioni del campo",
    "adjustHover": "Per regolarle, passare il mouse sopra il campo e trascinare i puntini.",
    "adjustOptions": "Opzioni di regolazione",
    "moreOptions": "Fare clic sul campo per visualizzare altre opzioni e selezionare chi deve completare il campo.",
    "thatsIt": "Ecco fatto.",
    "clickReview": "Al termine, fare clic su <b>Avanti: Revisione</b>",
    "knowMore": "Si desiderano ulteriori informazioni?",
    "support": "Visualizza articoli e video di supporto"
  };
  _exports.prepareTutorial = prepareTutorial;
});