define("ember-rs/locales/es/templates/document/search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header = void 0;
  var search_header = {
    "from": "De: ",
    "state": "Estado: ",
    "search_docs": "Buscar documentos...",
    "all": " Todos",
    "pending": " Pendientes",
    "declined": " Rechazada",
    "executed": " Completados",
    "expired": " Caducados",
    "voided": " Anulados",
    "admin_view": "Vista de administrador",
    "tool_tip": "Ver todos los documentos enviados desde esta cuenta.",
    "filter": "Filtrar"
  };
  _exports.search_header = search_header;
});