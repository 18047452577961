define("ember-rs/components/hybris-trial-modal", ["exports", "ember-rs/mixins/trial-methods", "ember-rs/mixins/invalidate-and-sign-out"], function (_exports, _trialMethods, _invalidateAndSignOut) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_trialMethods.default, _invalidateAndSignOut.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    cookies: Ember.inject.service(),
    show: false,
    hybrisTrialExpired: false,
    didInsertElement: function didInsertElement() {
      if (this.hybrisTrialExpired) {
        this.set('show', true);
      } else {
        var cookieService = this.cookies;

        if (this.hybrisTrialDaysRemaining <= 5) {
          if (Ember.isEmpty(cookieService.read('hasSeenHybrisTrialModal'))) {
            var tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            cookieService.write('hasSeenHybrisTrialModal', true, {
              expires: tomorrow
            });
            this.set('show', true);
          }
        }
      }
    },
    actions: {
      closeModal: function closeModal() {
        this.set('show', false);
      },
      signOut: function signOut() {
        this.invalidateAndSignOut();
      }
    }
  });

  _exports.default = _default;
});