define("ember-rs/locales/en/templates/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choose_documents = void 0;
  var choose_documents = {
    "drag_drop": "Drag and drop files here or:",
    "choose_computer": "Choose From Computer",
    "choose_template": "Choose the template you want to use for bulk send",
    "use_template": "Use a template",
    "other_source": "Other Sources",
    "from_cloud": "from cloud",
    "template": "template",
    "add_another": "Add another file",
    "prepare_document": "Prepare Document",
    "prepare_documents": "Prepare Documents",
    "upload_signers": "Upload Signers",
    "drag_drop_csv": "Drag and drop your CSV File:",
    "select_file_computer": "Select a file from your computer",
    "remove_file": "Remove",
    "upload_success": "File uploaded successfully.",
    "upload_multifile": "Multi-file upload not supported. Please upload only one file.",
    "upload_invalid_extension": "The file {{{fileName}}} has an invalid extension.",
    "upload_size_limit": "The file {{{fileName}}} is bigger than our 5MB limit.",
    "send_package": "Send document package",
    "file_size_exceeded": "Your total file size can't exceed {{{maxFileSize}}} MB. Remove one or more documents to continue.",
    "max_files_exceeded": "You can only prepare {{{maxFileCount}}} documents at once.",
    "min_files_required": "A package requires at least two documents.",
    "preparing_package": "We're preparing your document. This may take a few seconds.",
    "merge_upload_error": "There was an issue preparing your document. Please try again by removing and re-uploading your documents."
  };
  _exports.choose_documents = choose_documents;
});