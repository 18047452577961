define("ember-rs/routes/user/integrations", ["exports", "ember-rs/helpers/format-provider-name", "ember-rs/mixins/integration-auth"], function (_exports, _formatProviderName, _integrationAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var serviceCategories = {
    sending: 'documents',
    archiving: 'documents',
    payments: 'payments',
    mobile: null // TODO

  };

  var _default = Ember.Route.extend(_integrationAuth.default, {
    flashMessages: Ember.inject.service(),
    titleToken: 'Integrations',
    ////////////////////////  HOOKS  ////////////////////////
    beforeModel: function beforeModel() {
      if (!this.get('currentUser.account.enableIntegration')) {
        this.transitionTo('unauthorized.unauthorized');
      }
    },
    model: function model() {
      return this.store.findRecord('user', this.currentUser.get('model.id'), {
        reload: true
      });
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      toggleService: function toggleService(service, serviceEnabled, isAuthorized, provider, integrationMetadata, params) {
        var _this = this;

        var serviceCategory = serviceCategories[service] || 'contacts';
        var requestType = Ember.isEqual(serviceEnabled, true) ? 'POST' : 'DELETE';
        var formattedProviderName = (0, _formatProviderName.formatProviderName)(provider); // The endpoint for contacts is the root resource :contacts

        if (serviceCategory === 'contacts' || serviceCategory === "payments") {
          service = 'root';
        }

        if (isAuthorized) {
          // This integration is already authorized
          this.integrationRequest(provider, serviceCategory, service, requestType).done(function () {
            _this.flashMessages.success("".concat(serviceEnabled ? _this.i18n.t("routes.user.integrations.successEnabled", {
              formattedProviderName: "".concat(formattedProviderName)
            }) : _this.i18n.t("routes.user.integrations.successDisabled", {
              formattedProviderName: "".concat(formattedProviderName)
            })));

            _this.get('currentUser.model').reload();
          }).fail(function () {
            _this.flashMessages.warning(_this.i18n.t("routes.user.integrations.error", {
              formattedProviderName: "".concat(formattedProviderName)
            }));
          });
        } else {
          var authorizationOrigin = {
            route: 'user.integrations'
          };
          this.integrationAuthorize({
            provider: provider,
            service: service,
            serviceCategory: serviceCategory,
            integrationMetadata: integrationMetadata,
            authParams: params,
            authorizationOrigin: authorizationOrigin
          });
        }
      }
    }
  });

  _exports.default = _default;
});