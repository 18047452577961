define("ember-rs/controllers/reusable-template/add-roles", ["exports", "ember-rs/mixins/add-roles"], function (_exports, _addRoles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_addRoles.default, {
    ////////////////////////  PROPERTIES  ////////////////////////
    isNotValid: Ember.computed('model.roles.@each.name', function () {
      return Ember.isBlank(this.get('model.roles')) || this.get('model.roles').filterBy('isValid', false).get('length') > 0 || !this.uniqueRoleNames;
    }),
    uniqueRoleNames: Ember.computed('model.roles.@each.name', function () {
      return this.get('model.roles').mapBy('name').uniq().get('length') === this.get('model.roles.length');
    }),
    senderRole: Ember.computed('model.roles.@each.isSender', function () {
      return this.get('model.roles').findBy('isSender', true);
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      nextStep: function nextStep() {
        var _this = this;

        // Save roles in case of reload
        this.model.set('forceEdit', true);
        this.model.save().then(function () {
          _this.transitionToRoute('reusable-template.prepare');
        });
      },
      addSenderRole: function addSenderRole() {
        if (Ember.isBlank(this.senderRole)) {
          var senderProperties = {
            isSender: true,
            name: this.i18n.t('controllers.reusableTemplate.addRoles.sender').string
          };

          if (this.get('model.roles.length') === 1 && Ember.isBlank(this.get('model.roles.firstObject.name'))) {
            this.get('model.roles.firstObject').setProperties(senderProperties);
          } else {
            this.model.newRole(senderProperties);
          }
        }
      },
      addRole: function addRole() {
        this.model.newRole();
      }
    }
  });

  _exports.default = _default;
});