define("ember-rs/locales/en/templates/onboarding/new-user-onboarding-from-sharefile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFromSharefile = void 0;
  var newUserOnboardingFromSharefile = {
    "title": "Send &amp; Sign your Documents",
    "startText": "Send your documents to be signed by others or sign any documents that need your signature.",
    "clickLearn": "Click next to learn how.",
    "addSigner": "Add signers",
    "addAnyone": "Add anyone who needs to fill out or sign this document.",
    "prepare": "Prepare the document",
    "placeFields": "Place fields where you need signers to fill out information or sign the document. You can assign fields to each signer.",
    "sendTrack": "Send and track progress",
    "onceSent": "Once you have sent the document you can track it's progress on the document details page. To navigate to the details page, just click on the document from your document dashboard."
  };
  _exports.newUserOnboardingFromSharefile = newUserOnboardingFromSharefile;
});