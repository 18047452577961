define("ember-rs/locales/zh-cn/templates/document/details", ["exports", "ember-rs/locales/zh-cn/templates/document/details/unlock"], function (_exports, _unlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    unlock: _unlock.unlock,
    "status": "状态",
    "overview": "概览",
    "history": "历史记录",
    "people_involved": "涉及的人员:",
    "manage": "管理",
    "security_options": "安全选项:",
    "declined": "已拒绝",
    "expired": "已过期",
    "expires": "过期时间",
    "extend": "扩展",
    "pin": "PIN  *****",
    "generate_pin": "生成新 PIN",
    "pin_tip": "生成新 PIN 将创建一个新 PIN，并使当前 PIN 无效。",
    "passcode_question": "通行码问题:",
    "clear_attempts": "尝试清除通行码的次数",
    "passcode": "通行码",
    "signer_sequencing": "签名者排序",
    "lock_message": "本文档已锁定。",
    "attachments": "附件:",
    "reference_id": "参考 ID: ",
    "tags": "标记: ",
    "remind_header": "已发送签名链接",
    "remind_body": "我们已将用于对此文档进行签名的链接发送到下面的电子邮件地址。要在将来跳过此步骤，可以在您的设置页面上验证此电子邮件地址。",
    "new_pin": "新 PIN",
    "new_passcode": "这是您的新 PIN。必须将这一更新后的 PIN 码发送给文档的签名者，以便其能够访问。",
    "done": "完成",
    "draft": "草稿",
    "pending": "待签名",
    "voided": "已失效",
    "executed": "已执行",
    "recipient_message": "给收件人的消息:",
    "void_reason": "失效原因:",
    "document_voided": "文档已失效:",
    "document_declined": "拒绝原因:"
  };
  _exports.details = details;
});