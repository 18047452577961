define("ember-rs/locales/fr/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingComplete = void 0;
  var signingComplete = {
    "docSigned": "Document signé !",
    "createTrial": "Créez un compte RightSignature gratuit pour afficher ou télécharger ce document terminé. Vous aurez accès à ce document pour toujours. Il suffit pour cela de vous enregistrer avec l'adresse e-mail avec laquelle le document a été envoyé et de vous connecter.",
    "createAccount": "Créer un compte",
    "login": "Connexion au compte",
    "notNow": "Pas maintenant"
  };
  _exports.signingComplete = signingComplete;
});