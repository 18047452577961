define("ember-rs/controllers/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['sharefile_session'],
    showMoreOptions: false,
    disableOtherFlows: Ember.computed.not('currentUser.account'),
    signatureRequest: Ember.computed.equal('workflow', "signatureRequest"),
    selfSign: Ember.computed.equal('workflow', "selfSign"),
    inPerson: Ember.computed.equal('workflow', 'inPerson'),
    bulkSend: Ember.computed.equal('workflow', 'bulkSend'),
    packager: Ember.computed.equal('workflow', 'packager'),
    actions: {
      createNewTemplate: function createNewTemplate() {
        this.transitionToRoute('reusable-templates.new');
      },
      toggleShowMoreOptions: function toggleShowMoreOptions() {
        this.toggleProperty('showMoreOptions');
      },
      close: function close() {
        if (window.self !== window.top) {
          // iframed
          parent.postMessage({
            eventName: 'rsClose',
            data: {}
          }, '*');
        } else {
          this.transitionToRoute('application');
        }
      }
    }
  });

  _exports.default = _default;
});