define("ember-rs/locales/fr/templates/unauthorized/document-voided", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentVoided = void 0;
  var documentVoided = {
    "documentVoided": "Document annulé",
    "sender": "Ce document a été annulé par l'expéditeur."
  };
  _exports.documentVoided = documentVoided;
});