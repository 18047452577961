define("ember-rs/templates/components/flattened-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oTdeQW8T",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"component-content-wrapper\"],[12],[2,\"\\n\"],[6,[37,7],[[35,5,[\"renderFlattenedAsText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flattened-text-value\"],[15,5,[30,[36,1],[[35,3]],null]],[12],[2,\"\\n\"],[6,[37,7],[[35,5,[\"isDateField\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,6],[[35,4],[35,5,[\"data\",\"dateFormat\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"multiline-text\"],[15,5,[30,[36,1],[[35,3]],null]],[12],[1,[34,4]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[35,5,[\"renderFlattenedAsSignature\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[34,2,[\"imageUrl\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,7],[[35,5,[\"renderFlattenedAsCheck\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-check\"],[15,5,[30,[36,1],[[35,0]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"checkStyle\",\"safe\",\"signature\",\"textStyle\",\"textValue\",\"component\",\"rs-date\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/flattened-component.hbs"
    }
  });

  _exports.default = _default;
});