define("ember-rs/locales/ja/templates/onboarding/welcome-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding1 = void 0;
  var welcomeOnboarding1 = {
    "welcome": "RightSignature へようこそ。",
    "tips": "機能の内容、RightSignature の使用方法、サービスを活用するヒントをご紹介します"
  };
  _exports.welcomeOnboarding1 = welcomeOnboarding1;
});