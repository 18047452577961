define("ember-rs/locales/en/controllers/client-applications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newClientApplication = void 0;
  var newClientApplication = {
    "appReview": "Your application is being reviewed. We will notify or approve your request within 24 hours.",
    "secretsSuccess": "Secrets retrieved, these should only be used by you or those you trust.",
    "secretsError": "Could not retrieve secrets: {{{error}}}",
    "tokenSuccess": "Successfully generated new Private API Token",
    "tokenError": "Could not generate token: {{{error}}}",
    "singleSecretSuccess": "Secret retrieved for {{{clientAppName}}}, this should only be used by you or those you trust.",
    "singleSecretError": "Could not retrieve secret: {{{error}}}",
    "tokenRetrieveSuccess": "Private API token retrieved for {{{clientAppName}}}, this should only be used by you or those you trust.",
    "tokenRetrieveError": "Could not retrieve private API token: {{{error}}}",
    "tokenRegenerateError": "Could not regenerate token: {{{error}}}",
    "confirmInfo": "Are you sure? An alternative could be to request a new API key, as once regenerated, overwritten private API tokens are irretrievable. If you choose to continue, please be sure to remove and replace any outdated credentials in all your integrations."
  };
  _exports.newClientApplication = newClientApplication;
});