define("ember-rs/mixins/settings", ["exports", "jquery", "ember-rs/config/environment", "lodash"], function (_exports, _jquery, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    flashMessages: Ember.inject.service(),
    ////////////////////////  METHODS  ////////////////////////
    updateSetting: function updateSetting(attr, key, value, modelObject, showFlash) {
      var _this = this;

      var _model = modelObject || this.model;

      var previousValue = _model.get(attr);

      var modelName = _model.get('constructor.modelName').pluralize();

      var setting = {};
      setting[key] = value;

      if (!key || _lodash.default.isUndefined(value) || _lodash.default.isNull(value) || previousValue === value) {
        return;
      }

      return _jquery.default.ajax({
        url: "".concat(_environment.default.api.endpoint, "/").concat(modelName, "/").concat(_model.get('id'), "/settings"),
        type: 'PUT',
        data: {
          settings: setting
        }
      }).then(function () {
        if (showFlash) {
          _this.flashMessages.success(_this.i18n.t("mixins.settings.updateSuccessful"));
        }

        _model.set(attr, value);
      }, function () {
        if (showFlash) {
          _this.flashMessages.warning(_this.i18n.t("mixins.settings.updateError"));
        }

        _model.set(attr, previousValue);
      });
    },
    updateAccount: function updateAccount(attr, key, value) {
      var _model = Ember.get(this, 'currentUser.account');

      var previousValue = _model.get(attr);

      var setting = {};
      setting[key] = value;

      if (!key || _lodash.default.isUndefined(value) || _lodash.default.isNull(value)) {
        return;
      }

      return _jquery.default.ajax({
        url: "".concat(_environment.default.api.endpoint, "/account/settings"),
        type: 'PUT',
        data: {
          settings: setting
        }
      }).then(function () {
        _model.set(attr, value);
      }, function () {
        _model.set(attr, previousValue);
      });
    },
    updateMfa: function updateMfa(key, value) {
      var _this2 = this;

      var _model = Ember.get(this, 'currentUser.account');

      if (!key || _lodash.default.isUndefined(value) || _lodash.default.isNull(value)) {
        return;
      }

      return _jquery.default.ajax({
        url: "".concat(_environment.default.api.endpoint, "/accounts/").concat(_model.get('id')),
        type: 'PUT',
        data: {
          account: {
            id: _model.get('id')
          },
          mfa: value
        }
      }).catch(function () {
        _this2.set('model.account.isMfaEnabledForEmployee', !value);

        _this2.flashMessages.warning(_this2.i18n.t("mixins.settings.updateError"));
      });
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      updateSetting: function updateSetting(value, component) {
        var attr = component.get('attr');
        this.updateSetting(attr, attr.underscore(), value);
      },
      updateAccount: function updateAccount(value, component) {
        var attr = component.get('attr');
        this.updateAccount(attr, attr.underscore(), value);
      },
      updateMfa: function updateMfa(value) {
        this.set('model.account.isMfaEnabledForEmployee', value);
        this.updateMfa('mfa', value);
      },
      updateAttachedPdfSetting: function updateAttachedPdfSetting(value, component) {
        var isHipaa = this.get('currentUser.account.isHipaa');
        var attr = component.get('attr');
        var currentValue = value === this.model.get(attr) ? !value : value;

        if (currentValue && isHipaa) {
          this.toggleProperty('showAttachedCompletedPdfConfirmationModal');
        } else {
          this.updateSetting(attr, attr.underscore(), currentValue);
        }
      }
    }
  });

  _exports.default = _default;
});