define("ember-rs/utils/bugsnag-configuration", ["exports", "ember-cli-bugsnag/utils/bugsnag-configuration"], function (_exports, _bugsnagConfiguration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _bugsnagConfiguration.default;
    }
  });
});