define("ember-rs/locales/it/templates/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateSelector = void 0;
  var reusableTemplateSelector = {
    "noTemplates": "Non sono ancora presenti modelli.",
    "templateDesc": "Creare modelli riutilizzabili a partire dai documenti utilizzati di frequente. È possibile inviarli rapidamente, incorporarli nel proprio sito o distribuirli in massa.",
    "createNew": "Crea nuovo modello",
    "chooseTemplate": "Scegli modello",
    "loading": "Caricamento...",
    "loadMore": "carica più modelli...",
    "upgrade": "Aggiorna l'account per utilizzare i modelli",
    "upgradeAccount": "Aggiorna account",
    "bulkSendInfo": "L'invio in blocco funziona solo con un modello di un singolo firmatario. È la prima volta che lo si usa?",
    "learnMore": "Ulteriori informazioni",
    "searchTemplate": "Modelli di ricerca",
    "noResults": "Nessun risultato"
  };
  _exports.reusableTemplateSelector = reusableTemplateSelector;
});