define("ember-rs/locales/nl/templates/unauthorized/document-executed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExecuted = void 0;
  var documentExecuted = {
    "docComplete": "Dit document is voltooid",
    "toDownload": "Vraag een nieuwe downloadkoppeling aan om dit document te downloaden.",
    "sendLink": "Nieuwe downloadkoppeling verzenden"
  };
  _exports.documentExecuted = documentExecuted;
});