define("ember-rs/locales/it/templates/reusable-template/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "upload": "Aggiungi caricamento",
    "what": "Cosa deve essere firmato?",
    "uploading": "Caricamento",
    "uploadFile": "Carica un file",
    "chooseCloud": "scegliere dal cloud",
    "prepare": "preparare il documento"
  };
  _exports.edit = edit;
});