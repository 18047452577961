define("ember-rs/locales/pt-br/controllers/document/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "success": "Todos os signatários desbloqueados",
    "error": "Falha ao desbloquear assinantes, tente novamente mais tarde."
  };
  _exports.unlock = unlock;
});