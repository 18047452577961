define("ember-rs/locales/it/controllers/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.help = void 0;
  var help = {
    "success": "La richiesta è stata inviata. Si prega di attendere 24 ore per una risposta.",
    "error": "Impossibile inviare: {{{error}}}"
  };
  _exports.help = help;
});