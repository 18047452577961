define("ember-rs/locales/nl/templates/components/document-upload-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploadThumbnail = void 0;
  var documentUploadThumbnail = {
    "noThumb": "Geen miniatuur...",
    "upload_done": "uploaden voltooid",
    "downloading": "downloaden",
    "storing": "opslaan",
    "converting_to_images": "converteren naar afbeeldingen",
    "converting_to_pdf": "converteren naar PDF",
    "processing": "verwerken",
    "created": "gemaakt"
  };
  _exports.documentUploadThumbnail = documentUploadThumbnail;
});