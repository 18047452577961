define("ember-rs/templates/components/non-production", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IKbryqFc",
    "block": "{\"symbols\":[\"feature\"],\"statements\":[[10,\"h1\"],[12],[2,\"Override Account Plan\"],[13],[2,\"\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Updating...\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"small\"],[4,[38,0],[[32,0],\"downgrade\"],null],[12],[2,\"Downgrade Plan\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"small\"],[4,[38,0],[[32,0],\"upgrade\"],null],[12],[2,\"Upgrade Plan\"],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"small\"],[4,[38,0],[[32,0],\"clearOverride\"],null],[12],[2,\"Clear Override\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"Current Plan: \"],[1,[34,3]],[13],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,1,[\"name\"]]],[2,\" \"],[8,\"toggle-switch\",[],[[\"@toggled\",\"@toggleAction\",\"@attr\"],[[32,1,[\"value\"]],\"updateFeature\",[32,1,[\"name\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"isUpgraded\",\"if\",\"currentPlan\",\"featuresCollection\",\"-track-array\",\"each\",\"updating\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/non-production.hbs"
    }
  });

  _exports.default = _default;
});