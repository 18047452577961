define("ember-rs/locales/it/templates/components/agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "welcome": "Ti diamo il benvenuto",
    "toRS": "in RightSignature,",
    "bestOnDesktop": "RightSignature funziona meglio su un computer desktop.",
    "pleaseRead": "Leggere quanto segue prima di cominciare:",
    "terms": "Utilizzando RightSignature&trade; si certifica di aver letto e accettato i nostri ",
    "and": "e",
    "policy": "Informativa sulla privacy",
    "no": "No grazie",
    "yes": "Accetto"
  };
  _exports.agreeToTerms = agreeToTerms;
});