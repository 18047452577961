define("ember-rs/locales/de/templates/onboarding/post-sign-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding2 = void 0;
  var postSignOnboarding2 = {
    "viewDoc": "Hier sehen Sie den <span>Status</span> des Dokuments",
    "downloadDoc": "Hier können Sie das Dokument <span>herunterladen</span>."
  };
  _exports.postSignOnboarding2 = postSignOnboarding2;
});