define("ember-rs/locales/de/templates/unauthorized/unsubscribed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unsubscribed = void 0;
  var unsubscribed = {
    "unsubscribed": "Abonnement gekündigt",
    "unsubscribedTip": "Sie werden keine Erinnerungen mehr für dieses Dokument erhalten. Sie erhalten weiterhin E-Mails für andere Dokumente."
  };
  _exports.unsubscribed = unsubscribed;
});