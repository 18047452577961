define("ember-rs/helpers/list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.listItem = listItem;
  _exports.default = void 0;

  function listItem(indexNumber) {
    return indexNumber[0] + 1;
  }

  var _default = Ember.Helper.helper(listItem);

  _exports.default = _default;
});