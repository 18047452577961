define("ember-rs/templates/components/rs-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kb9D4hWY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"width\",\"64px\"],[14,\"height\",\"64px\"],[14,\"viewBox\",\"0 0 100 100\"],[14,\"version\",\"1.1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[12],[2,\"\\n    \"],[10,\"style\"],[12],[2,\"\\n      .is-themed { stroke: currentColor; }\\n    \"],[13],[2,\"\\n    \"],[10,\"title\"],[12],[2,\"spinner\"],[13],[2,\"\\n    \"],[10,\"defs\"],[12],[2,\"\\n      \"],[10,\"mask\"],[14,1,\"oneFourthMask\"],[12],[2,\"\\n        \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[14,\"height\",\"45\"],[14,\"width\",\"45\"],[14,\"fill\",\"white\"],[12],[13],[2,\"\\n        \"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"55\"],[14,\"height\",\"45\"],[14,\"width\",\"45\"],[14,\"fill\",\"white\"],[12],[13],[2,\"\\n        \"],[10,\"rect\"],[14,\"x\",\"55\"],[14,\"y\",\"55\"],[14,\"height\",\"45\"],[14,\"width\",\"45\"],[14,\"fill\",\"white\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"mask\"],[14,1,\"threeFourthMask\"],[12],[2,\"\\n        \"],[10,\"rect\"],[14,\"x\",\"55\"],[14,\"y\",\"0\"],[14,\"height\",\"45\"],[14,\"width\",\"45\"],[14,\"fill\",\"white\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"circle\"],[14,\"cx\",\"50\"],[14,\"cy\",\"50\"],[14,\"r\",\"45\"],[14,\"fill\",\"transparent\"],[14,\"stroke-width\",\"10\"],[14,\"stroke\",\"#cccccc\"],[14,\"mask\",\"url(#oneFourthMask)\"],[12],[13],[2,\"\\n    \"],[10,\"circle\"],[14,0,\"is-themed\"],[14,\"cx\",\"50\"],[14,\"cy\",\"50\"],[14,\"r\",\"45\"],[14,\"fill\",\"transparent\"],[14,\"stroke-width\",\"10\"],[14,\"mask\",\"url(#threeFourthMask)\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/rs-spinner.hbs"
    }
  });

  _exports.default = _default;
});