define("ember-rs/locales/ja/templates/onboarding/post-sign-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding2 = void 0;
  var postSignOnboarding2 = {
    "viewDoc": "ここでドキュメントの<span>状態</span>を表示できます",
    "downloadDoc": "ここでドキュメントを<span>ダウンロード</span>できます。"
  };
  _exports.postSignOnboarding2 = postSignOnboarding2;
});