define("ember-rs/locales/it/templates/document/details", ["exports", "ember-rs/locales/it/templates/document/details/unlock"], function (_exports, _unlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    unlock: _unlock.unlock,
    "status": "Stato",
    "overview": "Panoramica",
    "history": "Cronologia",
    "people_involved": "Persone coinvolte:",
    "manage": "Gestisci",
    "security_options": "Opzioni di sicurezza:",
    "declined": "Rifiutato",
    "expired": "Scaduto",
    "expires": "Scade il",
    "extend": "Estendi",
    "pin": "PIN  *****",
    "generate_pin": "Genera nuovo PIN",
    "pin_tip": "La generazione di un nuovo PIN ne creerà uno nuovo e quello corrente non sarà più valido.",
    "passcode_question": "Domanda per il passcode:",
    "clear_attempts": "Cancella tentativi di immissione passcode",
    "passcode": "Passcode",
    "signer_sequencing": "Sequenza dei firmatari",
    "lock_message": "Questo documento è stato bloccato.",
    "attachments": "Allegati:",
    "reference_id": "ID di riferimento: ",
    "tags": "Contrassegni: ",
    "remind_header": "Collegamento per la firma inviato",
    "remind_body": "Il collegamento per firmare questo documento è stato inviato all'indirizzo e-mail riportato di seguito. Per saltare questo passaggio in futuro, è possibile verificare questo indirizzo e-mail nella pagina delle impostazioni.",
    "new_pin": "Nuovo PIN",
    "new_passcode": "Ecco il nuovo PIN. È necessario inviare questo PIN aggiornato ai firmatari del documento, in modo che possano effettuare l'accesso.",
    "done": "Fine",
    "draft": "Bozza",
    "pending": "In sospeso",
    "voided": "Annullato",
    "executed": "Reso esecutivo",
    "recipient_message": "Messaggio ai destinatari:",
    "void_reason": "Motivo dell'annullamento:",
    "document_voided": "Documento annullato:",
    "document_declined": "Motivo del rifiuto:"
  };
  _exports.details = details;
});