define("ember-rs/locales/ja/templates/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choose_documents = void 0;
  var choose_documents = {
    "drag_drop": "ここにファイルをドラッグ アンド ドロップ。または:",
    "choose_computer": "コンピューターから選択",
    "choose_template": "一括送信に使用するテンプレートを選択してください",
    "use_template": "テンプレートを使用",
    "other_source": "その他の場所",
    "from_cloud": "クラウドから",
    "template": "テンプレート",
    "add_another": "別のファイルを追加",
    "prepare_document": "ドキュメントを準備",
    "prepare_documents": "ドキュメントを準備",
    "upload_signers": "署名者のアップロード",
    "drag_drop_csv": "CSV ファイルをドラッグ アンド ドロップ:",
    "select_file_computer": "コンピューターからファイルを選択します",
    "remove_file": "削除",
    "upload_success": "ファイルをアップロードしました。",
    "upload_multifile": "複数ファイルのアップロードはサポートされていません。1 つのファイルのみをアップロードしてください。",
    "upload_invalid_extension": "ファイル {{{fileName}}} の拡張子が無効です。",
    "upload_size_limit": "ファイル {{{fileName}}} は、5 MB の上限を超えています。",
    "send_package": "ドキュメント パッケージの送信",
    "file_size_exceeded": "合計ファイル サイズは、{{{maxFileSize}}} MB を超えることはできません。続行するには、1 つまたは複数のドキュメントを削除してください。",
    "max_files_exceeded": "一度に準備できるドキュメントの最大数は {{{maxFileCount}}} 個です。",
    "min_files_required": "パッケージには少なくとも 2 つのドキュメントが必要です。",
    "preparing_package": "ドキュメントを準備しています。これには数秒かかる場合があります。",
    "merge_upload_error": "ドキュメントの準備中に問題が発生しました。ドキュメントの削除、再アップロードの後、再試行してください。"
  };
  _exports.choose_documents = choose_documents;
});