define("ember-rs/locales/pt-br/templates/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateRow = void 0;
  var reusableTemplateRow = {
    "send": "Enviar",
    "details": "Detalhes",
    "trialmessageComp": "Seu plano de avaliação atual permite enviar apenas {{{documentLimit}}} documentos. ",
    "planmessageComp": "Seu plano atual só permite enviar {{{documentLimit}}} documentos. ",
    "upgrade": "Entre em contato conosco para atualizar sua conta."
  };
  _exports.reusableTemplateRow = reusableTemplateRow;
});