define("ember-rs/locales/fr/templates/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "addRoles": "Ajouter des rôles",
    "whatRoles": "De quels rôles ce modèle a-t-il besoin ?",
    "signOrder": "Définir ordre de signature",
    "uniqueName": "Vous devez attribuer des noms de rôles uniques.",
    "addRole": "Ajouter rôle",
    "sendRole": "Ajouter rôle d'expéditeur",
    "next": "Suivant : Placer les champs"
  };
  _exports.addRoles = addRoles;
});