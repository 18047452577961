define("ember-rs/templates/components/edit-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x4TFwsIE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"uploader-wrapper\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,1,\"uploader\"],[14,3,\"file\"],[14,4,\"file\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"rs-avatar\",[],[[\"@size\",\"@photo\"],[\"large\",[34,2]]],null],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"uploader\"],[12],[2,\" \"],[1,[30,[36,1],[\"components.edit-avatar.change-avatar-label\"],null]],[13],[2,\"\\n  \"],[11,\"label\"],[4,[38,3],[[32,0],\"delete\"],null],[12],[2,\" \"],[1,[30,[36,1],[\"components.edit-avatar.remove-avatar-label\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"rs-avatar\",[],[[\"@size\",\"@initials\"],[\"large\",[34,0,[\"model\",\"initials\"]]]],null],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"uploader\"],[12],[2,\" \"],[1,[30,[36,1],[\"components.edit-avatar.upload-avatar-label\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"currentUser\",\"t\",\"userAvatarUrl\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/edit-avatar.hbs"
    }
  });

  _exports.default = _default;
});