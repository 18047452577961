define("ember-rs/locales/pt-br/mixins/upgrade-worldpay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgradeWorldpay = void 0;
  var upgradeWorldpay = {
    "successUpgrade": "Você atualizou sua conta com sucesso. Bem-vindo ao RightSignature!",
    "dataUnavailable": "Os dados não estão disponíveis neste momento. Volte para esta página mais tarde."
  };
  _exports.upgradeWorldpay = upgradeWorldpay;
});