define("ember-rs/components/payment-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    paymentComponentData: Ember.inject.service(),
    isLoading: false,
    signerPayment: Ember.computed('componentValue.value', function () {
      if (Ember.isPresent(Ember.get(this, 'componentValue.value'))) {
        return this.store.findRecord('signerPayment', Ember.get(this, 'componentValue.value'));
      }
    }),
    lastFour: Ember.computed('signerPayment.cardStr', function () {
      return Ember.get(this, 'signerPayment.cardStr').replace(/ending in/i, '');
    }),
    actions: {
      openCardCollection: function openCardCollection() {
        Ember.set(this, "paymentComponentData.paymentComponentSelected", this.component);
        Ember.set(this, "paymentComponentData.showCollectCardModal", true);
      },
      removeSignerPayment: function removeSignerPayment() {
        Ember.set(this, "componentValue.value", null);
      }
    }
  });

  _exports.default = _default;
});