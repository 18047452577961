define("ember-rs/locales/it/templates/unauthorized/unconfirmed-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedEmail = void 0;
  var unconfirmedEmail = {
    "loginFailed": "Accesso non riuscito",
    "loginFailedTip": "È necessario innanzitutto confermare l'indirizzo e-mail dell'account ShareFile. Controllare l'e-mail per verificare se è presente un collegamento di conferma proveniente da ShareFile."
  };
  _exports.unconfirmedEmail = unconfirmedEmail;
});