define("ember-rs/locales/nl/mixins/workflow-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.workflowType = void 0;
  var workflowType = {
    "signatureRequest": "Door u verzonden",
    "selfSign": "Zelfondertekend",
    "templateSignerLink": "Van sjabloon",
    "inPerson": "In persoon ondertekenen",
    "apiEmbedded": "In API ingesloten handtekening",
    "bulkSend": "Bulkverzending"
  };
  _exports.workflowType = workflowType;
});