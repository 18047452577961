define("ember-rs/locales/de/controllers/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "success": "Signaturlink erneut gesendet",
    "failure": "Fehler beim Senden des Links: {{{response}}}"
  };
  _exports.identityExpired = identityExpired;
});