define("ember-rs/locales/en/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "unlock_message": "One or more signers has exceeded the maximum number of idenity verification attempts (3), and the document is now locked for these signers. To unlock the document and require any pending signers to verify their respective identities with the Knowledge-Based Authentication, click Unlock. Note that unlocking the document may conflict with the <a target='_blank' href='https://www.irs.gov/uac/electronic-signature-guidance-for-forms-8878-and-8879'>IRS Electronic Signature Guidance for Forms 8878 and 8879</a>."
  };
  _exports.unlock = unlock;
});