define("ember-rs/controllers/document/prepare-signers", ["exports", "ember-rs/mixins/add-roles", "ember-rs/mixins/integration-documents-list"], function (_exports, _addRoles, _integrationDocumentsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_addRoles.default, _integrationDocumentsList.default, {
    sortedRolesKey: ['sequence:asc'],
    sortedRoles: Ember.computed.sort('model.roles', 'sortedRolesKey'),
    hoveringSubmit: false,
    ////////////////////////  FUNCTIONS  ////////////////////////
    integrationFilter: function integrationFilter(service) {
      return service === 'contacts';
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    integrations: Ember.computed.alias('currentUser.model.integrations'),
    contactSearchingEndpoints: Ember.computed('integrations.@each.contacts', function () {
      var endpoints = Ember.A();
      endpoints.pushObject({
        name: this.i18n.t("document.prepare_signers.recentContacts"),
        url: '/contacts',
        key: 'local'
      });
      this.integrations.filterBy('contacts', true).forEach(function (integration) {
        var provider = Ember.get(integration, 'provider');
        var name = provider.replace(/_/, ' ').capitalize();
        endpoints.pushObject({
          name: name,
          url: "/integrations/".concat(provider, "/contacts"),
          key: provider
        });
      });
      return endpoints;
    }),
    authorizationOrigin: Ember.computed('model.id', function () {
      return {
        location: "".concat(window.location)
      };
    }),
    isValid: Ember.computed('model.roles.@each.isSignerValid', 'model.roles.@each.signerOmitted', function () {
      var valid = true;
      this.get('model.roles').forEach(function (r) {
        if (!r.get('signerOmitted') && !r.get('isSignerValid')) {
          valid = false;
        }
      });

      if (Ember.isBlank(this.get('model.roles').filterBy('signerOmitted', false))) {
        return false;
      }

      return valid;
    }),
    isNotValid: Ember.computed.not('isValid'),
    senderIsSigner: Ember.computed('model.roles.@each.signerEmail', function () {
      var currentUserEmail = this.currentUser.get('model.email');
      var signerEmails = this.get('model.roles').filterBy('signerEmail') // Skip roles with no signerEmail
      .mapBy('signerEmail');

      if (currentUserEmail) {
        return !!signerEmails.find(function (signerEmail) {
          return signerEmail.toLowerCase() === currentUserEmail.toLowerCase();
        });
      }

      return false;
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      addRole: function addRole() {
        this.model.newRole();
      },
      toggleService: function toggleService(service, serviceEnabled, isAuthorized, provider, integrationMetadata, params) {
        var _this = this;

        this.get('model.documentTransaction').save().then(function () {
          _this.toggleService(service, serviceEnabled, isAuthorized, provider, integrationMetadata, params);
        });
      },
      addMe: function addMe() {
        if (!this.senderIsSigner) {
          if (this.get('model.roles.length') === 1 && Ember.isBlank(this.get('model.roles').objectAt(0).get('signerName'))) {
            var emptyRole = this.get('model.roles').objectAt(0);
            emptyRole.set('signerName', this.currentUser.get('model.name'));
            emptyRole.set('signerEmail', this.currentUser.getWithDefault('model.email', '').toLowerCase());
          } else {
            this.model.newRole({
              signerName: this.currentUser.get('model.name'),
              signerEmail: this.currentUser.getWithDefault('model.email', '').toLowerCase()
            });
          }
        }
      }
    }
  });

  _exports.default = _default;
});