define("ember-rs/locales/nl/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    "choose": "3. Kies de gegevens om te exporteren",
    "columnNames": "De kolomnamen zijn gebaseerd op de namen van de onderdelen in het document:",
    "export": "4. Exporteren als CSV-bestand",
    "selectColumns": "Kolommen selecteren",
    "view": "Een CSV-bestand kan in Excel worden geopend om gegevens over uw verzonden documenten weer te geven."
  };
  _exports.template = template;
});