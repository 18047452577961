define("ember-rs/templates/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FOe7YyaT",
    "block": "{\"symbols\":[\"accessible\",\"entitled\",\"free\",\"provider\"],\"statements\":[[8,\"paywall-action\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,1,\"integrations_content\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"setting-info\"],[12],[2,\"\\n        \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"account.integrations.header\"],null]],[13],[2,\"\\n        \"],[10,\"p\"],[12],[1,[30,[36,0],[\"account.integrations.description\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"allIntegrationProviders\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"integration-setting\",[],[[\"@contactServiceEnabled\",\"@user\",\"@provider\",\"@metadata\",\"@toggleService\"],[[34,1],[34,2],[32,4],[34,2,[\"integrationProviderMetadata\"]],\"toggleService\"]],null],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"setting-info\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"account.integrations.header\"],null]],[13],[2,\"\\n      \"],[10,\"p\"],[12],[1,[30,[36,0],[\"account.integrations.description\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"object\"],[14,\"data\",\"/assets/images/integrations-icons.svg\"],[14,0,\"paywall-image\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/integrations-icons.png\"],[15,\"alt\",[30,[36,0],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"contactServiceEnabled\",\"model\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/user/integrations.hbs"
    }
  });

  _exports.default = _default;
});