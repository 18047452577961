define("ember-rs/locales/it/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareNextRoute = void 0;
  var prepareNextRoute = {
    "mergeFieldStepNext": "Successivo: Campi di unione",
    "documentStepNext": "Documento successivo",
    "reviewStepNext": "Successivo: Revisione",
    "placeFieldNext": "Successivo: Posizionamento dei campi"
  };
  _exports.prepareNextRoute = prepareNextRoute;
});