define("ember-rs/locales/ja/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alreadySigned = void 0;
  var alreadySigned = {
    "thankYou": "署名の完了です",
    "youHave": "このドキュメントの署名が完了しました。安全なリンクを要求することで、いつでもドキュメントを表示できます。",
    "sendNewLink": "新しいダウンロード リンクを送信する",
    "successfully": "このドキュメントの署名が完了しました。"
  };
  _exports.alreadySigned = alreadySigned;
});