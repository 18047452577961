define("ember-rs/locales/pt-br/templates/components/component-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbar = void 0;
  var componentToolbar = {
    "useTools": "Use essas ferramentas para campos que os signatários precisam preencher.",
    "showLess": "Mostrar menos",
    "showMore": "Mostrar mais",
    "annotate": "anotar:",
    "annotateTip": "Use essas ferramentas para anotar o documento com sua própria assinatura, texto ou marcas de seleção.",
    "request": "Solicitar:",
    "dataFields": "Campos de data",
    "sourceFetchFailed": "Falha ao obter as informações de origem.",
    "schemaFetchFailed": "Failed to retrieve source entity schema."
  };
  _exports.componentToolbar = componentToolbar;
});