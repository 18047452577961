define("ember-rs/locales/en/templates/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "send": "Send for Signature",
    "notAllowed": " Your current plan does not allow you to send documents. Upgrade your plan to access this feature.",
    "embed": "Embed in Website",
    "share": "Generate Share Link",
    "manageLinks": "Manage Links &amp; Embeds",
    "viewResponses": "View Responses",
    "edit": "Edit",
    "delete": "Delete",
    "roles": "Roles",
    "security": "Security Options:",
    "expires": "Expires in:",
    "days": "days",
    "passcodeQuestion": "Passcode Question:",
    "passcode": "Passcode:",
    "tags": "Tags:",
    "linkHistory": "Link and Embed Code History",
    "linkHistoryTip": "Manage and view usage information for all links and embeds of this template.",
    "generate": "Generate Sharing Link",
    "nameLink": "Name this sharing link.",
    "nameLinkTip": "This can make managing links easier.",
    "whichSigner": "Which signer role?",
    "whichSignerTip": "Embeded templates and links can only have a single signer.",
    "limitUses": "Limit number of uses?",
    "limitUsesTip": "Set a limit to the number of times this link can be signed.",
    "expireDays": "Expire after number of days?",
    "expireDaysTip": "After expiration this link will no longer work.",
    "identityMethod": "Identity Method:",
    "identityMethodTip": "How should we verify the signers identity?",
    "generateLink": "Generate Link",
    "generateEmbed": "Generate Embed Code",
    "nameCode": "Name this code.",
    "nameCodeTip": "This can make managing generated codes easier.",
    "displayType": "Display Type",
    "displayTypeTip": "This specifies how the embed should appear",
    "pixel": "px",
    "codeGenerated": "Code Generated",
    "linkGenerated": "Link Generated",
    "clickingManage": "by clicking on “Manage Links &amp; Embeds” on the template page.",
    "youCanAccessCode": "You can access, disable, and edit this code by clicking on “Manage Links &amp; Embeds” on the template page.",
    "youCanAccessLink": "You can access, disable, and edit this link by clicking on “Manage Links &amp; Embeds” on the template page.",
    "newPin": "New PIN",
    "done": "Done",
    "email": "Email",
    "sms": "SMS",
    "none": "None",
    "height": "Height",
    "Width": "Width",
    "button": "button",
    "image": "image",
    "inline": "inline",
    "createdBy": "Created by",
    "actions": "Actions",
    "visibility-toggle-text": "Set template to private",
    "visibility-toogle-info": "Setting to private will make the templates visible only to its creator",
    "template-signer-link": {
      "passcode-message": "The passcode feature has been enabled for this account but will not be enforced for share links or embedded documents. This link/embed code will allow access to this document without the passcode."
    },
    "embed-in-website": {
      "disabled-merge-fields": "Embed codes are currently not available for this template. To generate an embed code for this template, first remove all required merge fields.",
      "disabled-no-online-forms": "Your current plan does not allow you to embed templates. Upgrade your plan to access this feature."
    },
    "generate-share-link": {
      "disabled-merge-fields": "Share links are currently not available for this template. To generate a share link for this template, first remove all required merge fields.",
      "disabled-no-manual-distribution": "Your current plan does not allow you to generate share links. Upgrade your plan to access this feature."
    }
  };
  _exports.details = details;
});