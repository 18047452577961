define("ember-rs/components/rs-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'rs-toggle',
    classNameBindings: ['isSmall:is-small', 'isBaby:is-baby', 'isToggled:is-toggled', '_isDisabled:is-disabled'],
    _isDisabled: Ember.computed('isDisabled', function () {
      return this.isDisabled;
    }),
    _isToggled: Ember.computed('isToggled', function () {
      return this.isToggled;
    }),
    click: function click() {
      var newValue = this.toggleProperty('_isToggled');
      this.sendAction('toggleAction', newValue, this);
    }
  });

  _exports.default = _default;
});