define("ember-rs/locales/en/templates/onboarding/send-onboarding-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding4 = void 0;
  var sendOnboarding4 = {
    "close": "Select the <span>back arrow</span> to close these details and go to your Documents dashboard"
  };
  _exports.sendOnboarding4 = sendOnboarding4;
});