define("ember-rs/models/account-user", ["exports", "@ember-data/model", "ember-rs/mixins/sharefile-user"], function (_exports, _model, _sharefileUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_sharefileUser.default, {
    name: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    avatarUrl: (0, _model.attr)('string'),
    sharefileUserId: (0, _model.attr)('string'),
    sharefileRoles: (0, _model.attr)('array'),
    firstName: (0, _model.attr)('string'),
    lastName: (0, _model.attr)('string'),
    newPassword: (0, _model.attr)('string'),
    oldPassword: (0, _model.attr)('string'),
    initials: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.firstName || '';
      var lastName = this.lastName || '';
      var initials = firstName.charAt(0) + lastName.charAt(0);
      return initials.toUpperCase();
    })
  });

  _exports.default = _default;
});