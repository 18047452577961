define("ember-rs/locales/zh-cn/templates/components/document-expiration-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExpirationEditor = void 0;
  var documentExpirationEditor = {
    "theDocExpired": "文档过期时间",
    "reactivated": "请选择您希望文档过期的时间，之后可以将其重新激活。",
    "theDocExpires": "文档当前过期时间",
    "newExpiration": "请选择您希望文档过期的时间。",
    "updateSuccess": "已成功更新过期日期。"
  };
  _exports.documentExpirationEditor = documentExpirationEditor;
});