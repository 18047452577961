define("ember-rs/serializers/document-template", ["exports", "ember-rs/serializers/application", "@ember-data/serializer/rest"], function (_exports, _application, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_rest.EmbeddedRecordsMixin, {
    attrs: {
      roles: {
        serialize: 'records',
        deserialize: 'ids'
      },
      components: {
        serialize: 'records',
        // deserialize: 'ids'
        deserialize: 'records'
      },
      mergefieldValues: {
        serialize: 'records',
        deserialize: 'ids'
      }
    }
  });

  _exports.default = _default;
});