define("ember-rs/locales/en/templates/onboarding/new-user-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboarding = void 0;
  var newUserOnboarding = {
    "title": "Welcome to RightSignature™",
    "letsPersonalize": "Before we explore the features, let's personalize your account.",
    "photoText": "Your photo is used on the document signing page. It adds a level of personalization and authenticity.",
    "photoSubtext": "You can update this at any time by visiting your account Settings.",
    "sendSign": "Send &amp; Sign your Documents",
    "sendDoc": "Send your documents to be signed by others or sign any documents that need your signature.",
    "knowMore": "Want to know more?",
    "viewSupport": "View support articles &amp; videos",
    "createTemplates": "Create templates for frequently signed documents.",
    "saveTime": "Save time and create reusable templates for your frequently used documents including waivers, NDAs, tax forms, employee applications, and more.",
    "learnMore": "Want to learn more?",
    "accessSupport": "Access our <a href='#''>support site</a>, which contains video tutorials and helpful tips to ensure you get the most out of RightSignature™."
  };
  _exports.newUserOnboarding = newUserOnboarding;
});