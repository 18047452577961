define("ember-rs/locales/de/controllers/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.help = void 0;
  var help = {
    "success": "Ihre Anfrage wurde übermittelt. Wir versuchen, innerhalb von 24 Stunden zu antworten.",
    "error": "Fehler beim Übermitteln: {{{error}}}"
  };
  _exports.help = help;
});