define("ember-rs/services/payment-component-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    // Signing Page
    showCollectCardModal: false,
    // Details Page
    showSignerPaymentModal: false
  });

  _exports.default = _default;
});