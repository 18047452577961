define("ember-rs/locales/it/routes/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    "embeddedTemplate": "L'account non ha accesso a questa funzione. Eseguire l'aggiornamento a un piano Pro per iniziare a utilizzare i modelli incorporati.",
    "inPerson": "L'account non ha accesso a questa funzione. Eseguire l'aggiornamento a un piano Pro per iniziare a utilizzare InPerson Signing."
  };
  _exports.startDocument = startDocument;
});