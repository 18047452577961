define("ember-rs/locales/pt-br/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteDocumentModal = void 0;
  var deleteDocumentModal = {
    "permanentlyDeleted": "Isso excluirá permanentemente o documento do RightSignature. Uma vez feito isso, não é possível desfazer.",
    "email": "Os signatários e os CCs receberão um email com o documento preenchido e o certificado de assinatura para arquivamento.",
    "briefMoment": "Pode levar um breve momento para que o documento desapareça do painel.",
    "delete": "Excluir",
    "cancel": "Cancelar",
    "deleteDocument": "Excluir documento",
    "deleteComplete": "Documento excluído",
    "deleteFailed": "Falha ao destruir o documento"
  };
  _exports.deleteDocumentModal = deleteDocumentModal;
});