define("ember-rs/locales/fr/templates/unauthorized/unconfirmed-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedEmail = void 0;
  var unconfirmedEmail = {
    "loginFailed": "Échec de la connexion",
    "loginFailedTip": "L'adresse e-mail de votre compte ShareFile doit d'abord être confirmée. ShareFile vous a envoyé un lien de confirmation par e-mail."
  };
  _exports.unconfirmedEmail = unconfirmedEmail;
});