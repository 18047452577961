define("ember-rs/locales/ja/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers = void 0;
  var prepare_signers = {
    "add_signers": "署名者を追加",
    "doc_limit_p1": "使用済み: ",
    "doc_limit_p2": "(対象月のドキュメント上限のうち)。アカウントをアップグレードする場合、ご連絡ください。",
    "recentContacts": "最近の連絡先"
  };
  _exports.prepare_signers = prepare_signers;
});