define("ember-rs/locales/en/templates/unauthorized/confirm-identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.confirmIdentityExpired = void 0;
  var confirmIdentityExpired = {
    "linkExpired": "Link Expired",
    "resendIdentityConfirmation": "Resend Identity Confirmation email.",
    "resendIdentityVerification": "Resend Identity Verification",
    "contactSender": "Please contact the document sender to request an updated link."
  };
  _exports.confirmIdentityExpired = confirmIdentityExpired;
});