define("ember-rs/locales/ja/templates/unauthorized/redirect-to-rs3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.redirectToRs3 = void 0;
  var redirectToRs3 = {
    "wrongVersion": "RightSignature の無効なバージョン",
    "classic": "RightSignature ShareFile Edition にログインしようとしましたが、ご利用中のアカウントでアクセスできるのは Classic RightSignature です。",
    "go": "RightSignature に移動"
  };
  _exports.redirectToRs3 = redirectToRs3;
});