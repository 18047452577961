define("ember-rs/templates/components/rs-more-options-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tgfqpz24",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"rs-button\",[[24,0,\"rs-more-options-mobile-toggle button\"]],[[\"@isPrimary\",\"@click\"],[false,[30,[36,0],[[32,0],\"onClickMobileMoreOptions\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],[\"components.rsMoreOptions.MoreOptions\"],null]],[2,\" \"],[10,\"i\"],[15,0,[31,[\"fa \",\"fa-caret-down\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/rs-more-options-mobile.hbs"
    }
  });

  _exports.default = _default;
});