define("ember-rs/templates/signer/sign/locked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aPO+LYYo",
    "block": "{\"symbols\":[],\"statements\":[[19,\"signer/sign/locked-modal\",[]],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/signer/sign/locked.hbs"
    }
  });

  _exports.default = _default;
});