define("ember-rs/locales/zh-cn/templates/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerPaymentDetails = void 0;
  var signerPaymentDetails = {
    "paymentInfo": "付款信息",
    "paymentID": "付款 ID:",
    "retryPayment": "正在重试付款...",
    "failed": "失败",
    "chargedSuccess": "已成功从 {{chargedAt}} 扣费。",
    "stripeCustomer": "Stripe 客户",
    "stripeCharge": "Stripe 支付",
    "attempted": "上次尝试从 {{chargedAt}} 扣费。",
    "retry": "重试",
    "pending": "正在挂起...",
    "success": "重试后付款成功。",
    "retryError": "重试付款失败({{{errorMsg}}})。"
  };
  _exports.signerPaymentDetails = signerPaymentDetails;
});