define("ember-rs/locales/nl/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFree = void 0;
  var newUserOnboardingFree = {
    "title": "Welkom bij RightSignature!",
    "body": "U hebt momenteel alleen basistoegang tot RightSignature™. Hiermee kunt u documenten zelf ondertekenen en uw oude documenten weergeven. Neem voor toegang tot de PRO-functies contact op met een beheerder voor uw RightSignature™-account."
  };
  _exports.newUserOnboardingFree = newUserOnboardingFree;
});