define("ember-rs/locales/fr/templates/components/new-user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserForm = void 0;
  var newUserForm = {
    "email": "E-mail",
    "name": "Nom",
    "password": "Mot de passe",
    "hidePassword": "Masquer le mot de passe",
    "create": "Créer compte",
    "haveAccount": "Vous avez un compte ?"
  };
  _exports.newUserForm = newUserForm;
});