define("ember-rs/locales/nl/templates/unauthorized/unconfirmed-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedEmail = void 0;
  var unconfirmedEmail = {
    "loginFailed": "Aanmelding mislukt",
    "loginFailedTip": "Het e-mailadres van uw ShareFile-account moet eerst worden bevestigd. Controleer uw e-mail voor een bevestigingskoppeling van ShareFile."
  };
  _exports.unconfirmedEmail = unconfirmedEmail;
});