define("ember-rs/locales/pt-br/routes/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "includeKey": "Inclua o seguinte item para realizar esta ação: {{{key}}}",
    "validKey": "Inclui o seguinte item válido: {{{key}}} ({{{validKey}}})"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});