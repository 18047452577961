define("ember-rs/locales/ja/templates/components/review-and-send-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewAndSendItem = void 0;
  var reviewAndSendItem = {
    "passcode-message": "必ずこの PIN をドキュメントの署名者に送信してください。",
    "bulk-send-passcode-message": "ドキュメント トランザクションに使用するパスコードを有効にしました。一括送信では、現在この機能はサポートされていません。",
    "generate-new-pin": "新しい PIN の生成",
    "pin-specification": "PIN は 5 桁の数字にする必要があります。",
    "expiration-message": "有効期限が切れると、ドキュメントに署名できなくなります。",
    "ExpiresIn": "有効期限まで: ",
    "days": "日",
    "loading": "読み込み中",
    "page": "ページ",
    "emailTip1": "このドキュメントを表示するためのリンクが、{{{email}}} に送付されます。",
    "emailTip2": "受信者は、このドキュメントを表示およびダウンロードできます。",
    "enterEmail": "メール アドレスを入力",
    "shareDoc": "このドキュメントを共有するためにメール アドレスを入力してください",
    "ccMsg": "「CC」に指定した宛先には、このドキュメントへの読み取り専用アクセス権限が付与されます。これらの宛先は通知を受信したり、進捗状況を表示したり、完了したドキュメントをダウンロードしたりできます。このアクセス権限は、必要に応じて後から取り消すことができます。",
    "add": "追加する",
    "roles": "役割",
    "recipients": "受信者",
    "cc": "cc: ",
    "ccCaps": "CC",
    "messageLimit": "メッセージを 100000 文字以上にすることはできません。",
    "addMsg": "個人用メッセージを追加...",
    "for": "対象:",
    "message": "メッセージ",
    "pin": "PIN",
    "tags": "タグ",
    "passcodeOn": "パスコード有効",
    "passcodeOff": "パスコード無効",
    "restrictedFeatureMsg": "現在のプランでは、ドキュメントにパスコードを追加する権限がありません。この機能を利用するには、プランをアップグレードしてください。",
    "kba": "KBA",
    "on": "オン",
    "off": "オフ",
    "kbaTip": "この認証では、署名者は、ナレッジベースに登録された本人確認のための質問に回答する必要があります。署名者ごとに、ドキュメントに署名する前に確認を求められます。3 回失敗すると、署名者はドキュメントからロックアウトされます。",
    "enable": "有効",
    "addTag": "タグを追加",
    "tagTip": "後で見つけやすいように、このドキュメントにタグを追加します。値を追加することもできます (例:「client_id: 1234」)",
    "addSalesforceOpportunity": "Salesforce の商談を追加",
    "addSalesforceOpportunityMsg": "このドキュメントに Salesforce の商談を追加します",
    "exceededKbaLimit": "KBA の上限に達しました",
    "exceededKbaMsg": "ナレッジベース認証 (KBA) を有効にしたドキュメントの数が、現在のサブスクリプションで送信できる 1 か月あたりの上限に達しました。KBA 機能を有効にしたドキュメントをさらに送信するには、<a href='https://support.citrix.com/cscase'>サポートに連絡</a>してアカウントをアップグレードしてください。",
    "tagName": "タグ名...",
    "tagValue": "値 (オプション)",
    "sendTo": "送信先",
    "name": "名前",
    "docName": "ドキュメント名...",
    "totalNumberOfSigners": "署名者の合計数:",
    "expiresIn": "[有効期限まで] は、1 日から 365 日の間で指定してください。"
  };
  _exports.reviewAndSendItem = reviewAndSendItem;
});