define("ember-rs/components/rs-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['rs-actions'],
    classNameBindings: ['isToggled:is-toggled'],
    click: function click() {
      this.toggleProperty('isToggled');
      return true;
    }
  });

  _exports.default = _default;
});