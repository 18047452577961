define("ember-rs/locales/ja/templates/onboarding/prepare-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTutorial = void 0;
  var prepareTutorial = {
    "title": "署名用のドキュメントを準備する",
    "selectField": "追加するフィールドを右で選択します。",
    "placeField": "フィールドを配置する",
    "clickPlace": "新しいフィールドを配置する場所をクリックします。",
    "adjustSize": "フィールドのサイズを調整する",
    "adjustHover": "調整するには、フィールド上をホバーしてドットをドラッグします。",
    "adjustOptions": "オプションを調整する",
    "moreOptions": "フィールドをクリックしてさらにオプションを表示し、フィールドに入力するユーザーを選択します。",
    "thatsIt": "これで終了です。",
    "clickReview": "完了後、<b>[次へ: 確認]</b> をクリックします",
    "knowMore": "詳細情報",
    "support": "サポート技術文書およびビデオの表示(&V)"
  };
  _exports.prepareTutorial = prepareTutorial;
});