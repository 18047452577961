define("ember-rs/locales/fr/templates/onboarding/migration-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.migrationOnboarding = void 0;
  var migrationOnboarding = {
    "migrationHeading": "Découvrez la dernière mouture de RightSignature !",
    "slide1Q": "Nouveautés",
    "slide1a": "Fonctionnalités de sécurité avancées :",
    "slide1b": "Authentification basée sur les connaissances, accès par code d'accès obligatoire, etc.",
    "slide1c": "Convivialité améliorée :",
    "slide1d": "Nouvelle interface épurée, capacités de personnalisation étendues, notifications de productivité et fonctionnalités mobiles améliorées.",
    "slide2": "Où sont les documents provenant de l'ancienne version de RightSignature ?",
    "slide3": "Où sont les modèles provenant de l'ancienne version de RightSignature ?",
    "slide4": "Comment convertir la dernière version de RightSignature en forfait payant ?",
    "continue": "Continuer",
    "dismiss": "Ignorer"
  };
  _exports.migrationOnboarding = migrationOnboarding;
});