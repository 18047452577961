define("ember-rs/locales/nl/templates/reusable-template/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "upload": "Upload toevoegen",
    "what": "Wat moet worden ondertekend?",
    "uploading": "Bezig met uploaden",
    "uploadFile": "Een bestand uploaden",
    "chooseCloud": "kiezen uit de cloud",
    "prepare": "document voorbereiden"
  };
  _exports.edit = edit;
});