define("ember-rs/components/transaction-breadcrumb-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    attributeBindings: ['style'],
    classNameBindings: [':transaction-breadcrumb-step', ':theme_breadcrumb', 'isActive:theme_breadcrumb--is-active'],
    stepCompleted: Ember.computed('step', 'currentStep', function () {
      if (this.step === 'prepare' && this.normalizedCurrentStep === 'review & send') {
        return true;
      } else if (this.step === 'documents' && (this.normalizedCurrentStep === 'prepare' || this.normalizedCurrentStep === 'review & send')) {
        return true;
      }
    }),
    normalizedCurrentStep: Ember.computed('currentStep', function () {
      if (this.currentStep === 'prepare_document' || this.currentStep === 'prepare_signers') {
        return 'prepare';
      } else {
        return this.currentStep;
      }
    }),
    adjustedIndex: Ember.computed('contentIndex', function () {
      return this.contentIndex + 1;
    }),
    isActive: Ember.computed('currentStep', 'step', function () {
      if (this.step === this.normalizedCurrentStep) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});