define("ember-rs/locales/en/templates/components/on-boarding-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onboarding = void 0;
  var onboarding = {
    "gotIt": "Got it!",
    "getStarted": "Get Started!"
  };
  _exports.onboarding = onboarding;
});