define("ember-rs/locales/pt-br/templates/document/prepare-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_document = void 0;
  var prepare_document = {
    "prepare": "Preparar ",
    "prepare_package": "Preparar o pacote de documentos",
    "merge_field_message": "Este documento contém campos de mesclagem que você deve preencher antes do envio.",
    "merge_field_title": "Preencha os campos de mesclagem",
    "missing_merge_fields": "Todos os campos de mesclagem necessários devem ser preenchidos."
  };
  _exports.prepare_document = prepare_document;
});