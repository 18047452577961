define("ember-rs/routes/signer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    titleToken: 'Sign',
    layoutSizeData: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.set('layoutSizeData.sidebarWidth', 0);
      this.set('layoutSizeData.tmHeaderHeight', 0);
    },
    model: function model() {// leave blank to avoid the children routes automatically trying to GET signers/id
      // since some (like confirm-identity cannot GET signer)
    }
  });

  _exports.default = _default;
});