define("ember-rs/locales/it/templates/template", ["exports", "ember-rs/locales/it/templates/template/details", "ember-rs/locales/it/templates/template/search-header-mobile"], function (_exports, _details, _searchHeaderMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    details: _details.details,
    searchHeaderMobile: _searchHeaderMobile.searchHeaderMobile,
    "review": {
      "header-content": "Rivedi",
      "create-button": "Crea modello",
      "update-button": "Aggiorna modello"
    },
    "index": {
      "subheader-create-button": "Crea modello",
      "template-limit-modal": {
        "cancellation-grace-period": "Questo account è stato annullato, quindi non è possibile creare modelli",
        "limit-reached": "Il piano attuale consente di creare solo {{templateLimit}} modelli. Se si desidera crearne altri, contattare il supporto e aggiornare l'account.",
        "not-enabled": "Il piano attuale non consente di creare modelli. Se si desidera creare modelli, contattare il supporto e aggiornare l'account."
      }
    }
  };
  _exports.template = template;
});