define("ember-rs/locales/fr/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archivedMigratedModal = void 0;
  var archivedMigratedModal = {
    "expiredBilling": "Infos de facturation expirées !",
    "updateBillingDetails": "Vos informations de paiement ne sont pas à jour. Cliquez ci-dessous pour mettre à jour les détails de facturation afin de continuer à utiliser notre service.",
    "clickUpdate": "Actualiser",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>Contactez le support</a>",
    "logout": "Déconnexion"
  };
  _exports.archivedMigratedModal = archivedMigratedModal;
});