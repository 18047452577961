define("ember-rs/locales/fr/templates/document-transaction/choose-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.choose_documents = void 0;
  var choose_documents = {
    "drag_drop": "Déposez les fichiers ici ou :",
    "choose_computer": "Choisir depuis l'ordinateur",
    "choose_template": "Choisissez le modèle que vous souhaitez utiliser pour l'envoi en bloc",
    "use_template": "Utiliser un modèle",
    "other_source": "Autres sources",
    "from_cloud": "depuis le cloud",
    "template": "modèle",
    "add_another": "Ajouter un autre fichier",
    "prepare_document": "Préparer le document",
    "prepare_documents": "Préparer les documents",
    "upload_signers": "Charger signataires",
    "drag_drop_csv": "Déposez votre fichier CSV :",
    "select_file_computer": "Sélectionnez un fichier sur votre ordinateur",
    "remove_file": "Supprimer",
    "upload_success": "Fichier chargé avec succès.",
    "upload_multifile": "Chargement de plusieurs fichiers non pris en charge. Veuillez charger un seul fichier.",
    "upload_invalid_extension": "L'extension du fichier {{{fileName}}} n'est pas valide.",
    "upload_size_limit": "Le fichier {{{fileName}}} dépasse la limite de 5 Mo.",
    "send_package": "Envoyer paquet de documents",
    "file_size_exceeded": "La taille totale de votre fichier ne peut pas dépasser {{{maxFileSize}}}  Mo. Supprimez un ou plusieurs document pour continuer.",
    "max_files_exceeded": "Vous ne pouvez préparer que {{{maxFileCount}}} documents à la fois.",
    "min_files_required": "Un paquet nécessite au moins deux documents.",
    "preparing_package": "Nous préparons votre document. Cela peut prendre quelques secondes.",
    "merge_upload_error": "Un problème est survenu lors de la préparation de votre document. Veuillez réessayer en supprimant et en chargeant à nouveau vos documents."
  };
  _exports.choose_documents = choose_documents;
});