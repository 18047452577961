define("ember-rs/locales/ja/templates/components/agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "welcome": "ようこそ",
    "toRS": "RightSignature へ。",
    "bestOnDesktop": "RightSignature はデスクトップ コンピューターに最適です。",
    "pleaseRead": "始める前に、以下をお読みください:",
    "terms": "RightSignature&trade; を使用することにより、次をお読みになり、同意いただいたものとします:  ",
    "and": "および",
    "policy": "プライバシーポリシー",
    "no": "同意しません",
    "yes": "同意します"
  };
  _exports.agreeToTerms = agreeToTerms;
});