define("ember-rs/locales/zh-cn/templates/document-transaction", ["exports", "ember-rs/locales/zh-cn/templates/document-transaction/choose-documents", "ember-rs/locales/zh-cn/templates/document-transaction/upload-signers", "ember-rs/locales/zh-cn/templates/document-transaction/redirect", "ember-rs/locales/zh-cn/templates/document-transaction/review-desktop", "ember-rs/locales/zh-cn/templates/document-transaction/review-mobile", "ember-rs/locales/zh-cn/templates/document-transaction/review"], function (_exports, _chooseDocuments, _uploadSigners, _redirect, _reviewDesktop, _reviewMobile, _review) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.document_transaction = void 0;
  var document_transaction = {
    choose_documents: _chooseDocuments.choose_documents,
    upload_signers: _uploadSigners.upload_signers,
    redirect: _redirect.redirect,
    review_desktop: _reviewDesktop.review_desktop,
    review_mobile: _reviewMobile.review_mobile,
    review: _review.review
  };
  _exports.document_transaction = document_transaction;
});