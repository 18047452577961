define("ember-rs/serializers/application", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      if (attribute.options.transient) {
        return;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});