define("ember-rs/locales/pt-br/templates/components/document-tags-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTagsEditor = void 0;
  var documentTagsEditor = {
    "addTags": "Adicione marcas a este documento para encontrá-lo com facilidade mais tarde. Você também pode adicionar valores a marcas, por exemplo, 'client_id: 1234'",
    "tagName": "Nome da marca...",
    "optional": "Valor (opcional)"
  };
  _exports.documentTagsEditor = documentTagsEditor;
});