define("ember-rs/locales/es/templates/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "share": "Compartir",
    "status": "Estado",
    "overview": "Información general",
    "sent": "enviado",
    "people_involved": "Personas involucradas:",
    "reference_id": "ID de referencia: ",
    "manage": "Administrar",
    "tags": "Etiquetas: "
  };
  _exports.details = details;
});