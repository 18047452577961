define("ember-rs/locales/zh-cn/templates/components/document-overlayer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentOverlayer = void 0;
  var documentOverlayer = {
    "prepare": "准备文档",
    "tools": "高级工具: ",
    "importOverlay": "导入叠加",
    "toolTip": "选择一个以前发出的文档以将这些字段放置在此文档上方的同一位置。注意: 该文档需要具有相同的名称。",
    "fieldDetection": "字段检测",
    "fieldDetectionTip": "字段检测可帮助您更准确地将字段与文档中的行和框对齐。",
    "roles": "角色",
    "rolesTip": "查看此文档的角色。每个角色都有自己的颜色，这些颜色与角色需要填写的字段相对应。",
    "signers": "签名者",
    "signersTip": "查看此文档的签名者。每个签名者都有自己的颜色，这些颜色与签名者需要填写的字段相对应。",
    "attachmentUnavailable": "附件对选定帐户不可用。请升级到专业计划以向签名者请求附件。",
    "paymentUnavailable": "支付对选定帐户不可用。请升级到专业计划以向签名者收取费用。",
    "paymentNotEnabled": "请在使用支付组件之前启用支付集成。您可以在“帐户设置”的“集成”部分中执行此操作。",
    "noRoles": "找不到角色! 可能在没有保存的情况下重新加载?"
  };
  _exports.documentOverlayer = documentOverlayer;
});