define("ember-rs/locales/zh-cn/templates/signer/sign/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identify = void 0;
  var identify = {
    "advisory": "本文档的发件人已启用 RightSignature 的基于知识的身份验证功能来验证您的身份。RightSignature 采用 BlockScore 的第三方服务来确认和验证提供的个人信息。对提供的个人信息的使用仅限于通过 BlockScore 的专有数据记录进行的身份确认和验证的性能。身份确认和验证的性能不会影响您的信用报告、信用评分或向贷方报告。作为此过程的一部分，IRS、Blockscore 和 RightSignature 都将无法查看或访问您的信用报告或您的税务信息。",
    "step1": "身份验证 - 第 1 步，共 2 步",
    "beforeSigning": "在对文档签名之前，请输入您的个人信息以帮助我们验证您的身份。",
    "notValid": "很抱歉，我们找不到与提供的信息匹配的有效身份。请确保下面的信息正确无误，然后单击“下一步”重试。",
    "youHave": "您还可以尝试访问此文档 ",
    "remaining": " 次。",
    "name": "姓名:",
    "dob": "出生日期:",
    "ssn": "SSN 的最后 4 位数字:",
    "passport": "护照号码:",
    "address": "地址:",
    "address2": "地址 2:",
    "city": "城市:",
    "state": "州/省/自治区/直辖市:",
    "zip": "邮政编码:",
    "country": "国家/地区:",
    "return": "返回到表单",
    "advisory2": "身份验证咨询",
    "sampleAddress1": "120 S. West St.",
    "sampleAddress2": "Suite 100",
    "sampleAddress3": "Raleigh",
    "submit": "提交"
  };
  _exports.identify = identify;
});