define("ember-rs/templates/components/rs-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GU7bTuE6",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[14,0,\"rs-avatar-photo\"],[15,\"src\",[31,[[34,3]]]],[14,\"alt\",\"user avatar\"],[14,\"width\",\"60px\"],[14,\"height\",\"60px\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"rs-avatar-initials\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"person\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"initials\",\"if\",\"photo\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/rs-avatar.hbs"
    }
  });

  _exports.default = _default;
});