define("ember-rs/templates/onboarding/post-sign-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CqZkCChH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"onboarding-container post-signer\"],[15,5,[30,[36,3],[[35,2,[\"onboardingContentStyle\"]]],null]],[12],[2,\"\\n  \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,0],1,\"onboarding/post-sign-onboarding-1\",\"nextStep\",[30,[36,1],[\"onboarding.postSignOnboarding.nextStep\"],null]]],null],[2,\"\\n\\n  \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,0],2,\"onboarding/post-sign-onboarding-2\",\"nextStep\",[30,[36,1],[\"onboarding.postSignOnboarding.nextStep\"],null]]],null],[2,\"\\n\\n\"],[6,[37,4],[[35,2,[\"useMobileLayout\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,0],3,\"onboarding/post-sign-onboarding-3\",\"nextStep\",[30,[36,1],[\"onboarding.postSignOnboarding.nextStep\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentStep\",\"t\",\"layoutSizeData\",\"safe\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/post-sign-onboarding.hbs"
    }
  });

  _exports.default = _default;
});