define("ember-rs/models/select-component", ["exports", "ember-rs/models/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    overlayerData: Ember.inject.service(),
    hasMinSelectOptions: Ember.computed.gt('selectOptions.length', 1),
    isValid: Ember.computed('selectOptions.[]', 'name', 'isMergeField', function () {
      return this._super() && this.isValidSelect;
    }),
    isValidSelect: Ember.computed('selectOptions.[]', 'isSelect', function () {
      return this.hasMinSelectOptions;
    }),
    isInvalidSelect: Ember.computed.not('isValidSelect')
  });

  _exports.default = _default;
});