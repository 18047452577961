define("ember-rs/locales/es/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrations = void 0;
  var integrations = {
    "successEnabled": "{{{formattedProviderName}}} ha sido habilitado correctamente",
    "successAuthorized": "{{{formattedProviderName}}} ha sido autorizado correctamente",
    "successDisabled": "{{{formattedProviderName}}} ha sido inhabilitado correctamente",
    "error": "Error. ¡{{{formattedProviderName}}} no se pudo modificar!",
    "errorAuthorize": "Error. ¡La integración de {{{formattedProviderName}}} no se pudo autorizar!",
    "errorSetup": "Error al intentar configurar la integración de {{{formattedProviderName}}}. Inténtelo de nuevo."
  };
  _exports.integrations = integrations;
});