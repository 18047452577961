define("ember-rs/locales/pt-br/templates/components/reminder-frequency-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reminderFrequency = void 0;
  var reminderFrequency = {
    "noAuto": "Sem lembretes automáticos",
    "daily": "Diário",
    "weekly": "Semanalmente"
  };
  _exports.reminderFrequency = reminderFrequency;
});