define("ember-rs/locales/nl/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteDocumentModal = void 0;
  var deleteDocumentModal = {
    "permanentlyDeleted": "Hiermee wordt het document permanent uit RightSignature verwijderd. Nadat dit is gebeurd, kan het niet ongedaan worden gemaakt.",
    "email": "Ondertekenaars en CC's ontvangen een e-mail met het ingevulde document en een handtekeningcertificaat voor hun administratie.",
    "briefMoment": "Het kan even duren voordat het document uit uw dashboard verdwijnt.",
    "delete": "Verwijderen",
    "cancel": "Annuleren",
    "deleteDocument": "Document verwijderen",
    "deleteComplete": "Verwijderd document",
    "deleteFailed": "Kan document niet vernietigen"
  };
  _exports.deleteDocumentModal = deleteDocumentModal;
});