define("ember-rs/services/current-user", ["exports", "ember-rs/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ////////////////////////  PROPERTIES  ////////////////////////
    id: Ember.computed.readOnly('model.id'),
    loggedIn: Ember.computed.notEmpty('model.id'),
    account: Ember.computed.alias('model.account'),
    entitled: Ember.computed.readOnly('model.entitled'),
    free: Ember.computed.readOnly('model.free'),
    accountId: Ember.computed.readOnly('model.accountId'),
    email: Ember.computed.readOnly('model.email'),
    sharefileUserId: Ember.computed.readOnly('model.sharefileUserId'),
    sharefileAccountId: Ember.computed.readOnly('model.account.sharefileAccountId'),
    isConfirmed: Ember.computed.readOnly('model.isConfirmed'),
    isMasterAdmin: Ember.computed.readOnly('model.isMasterAdmin'),
    canDelegateUsers: Ember.computed.readOnly('model.canDelegateUsers'),
    canManageUsers: Ember.computed.readOnly('model.canManageUsers'),
    canSendDocuments: Ember.computed.readOnly('model.canSendDocuments'),
    canManageBranding: Ember.computed.readOnly('model.canManageBranding'),
    canManageTemplates: Ember.computed.readOnly('model.canManageTemplates'),
    canViewAllDocuments: Ember.computed.readOnly('model.canViewAllDocuments'),
    canViewBills: Ember.computed.readOnly('model.canViewBills'),
    emailVerifications: Ember.computed.alias('model.emailVerifications'),
    nonProduction: _environment.default.environment !== 'production',
    ////////////////////////  METHODS  ////////////////////////
    reloadAccount: function reloadAccount() {
      var model = this.model;

      if (Ember.isPresent(model)) {
        return model.belongsTo('account').reload();
      }
    }
  });

  _exports.default = _default;
});