define("ember-rs/locales/nl/templates/components/documents/document-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentRow = void 0;
  var documentRow = {
    "signed": "ondertekend",
    "signer": "Ondertekenaar(s)",
    "legacy": " - Oud document",
    "awaitingSignature": "Wacht op uw handtekening",
    "sign": "ondertekenen",
    "completed": "Voltooid ",
    "sent": "Verzonden",
    "voided": "Nietig verklaard",
    "expired": "Verlopen ",
    "auth_failure": "Kan ondertekenaar niet verifiëren via sessie: {{{authError}}}"
  };
  _exports.documentRow = documentRow;
});