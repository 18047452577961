define("ember-rs/components/on-boarding-new", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    cookies: Ember.inject.service(),
    i18n: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var cookieService = this.cookies;

      if (Ember.isEmpty(cookieService.read('hasSeenSignerOnboarding'))) {
        cookieService.write('hasSeenSignerOnboarding', true, {
          maxAge: 31536000
        });
      } else {
        this.set('signerCookiePresent', true);
      }
    },
    signerCookiePresent: false,
    nextStepText: Ember.computed(function () {
      return this.i18n.t('components.onboarding.getStarted');
    }),
    ////////////////////////  EVENTS  ////////////////////////
    click: function click() {
      this.send('nextStep');
    },
    narrowDesktopSize: Ember.computed('layoutSizeData.windowWidth', 'layoutSizeData.maxContentWidth', 'layoutSizeData.totalSidePadding', function () {
      return this.get('layoutSizeData.windowWidth') < this.get('layoutSizeData.maxContentWidth') + this.get('layoutSizeData.totalSidePadding');
    }),
    currentStep: 1,
    hasSeenOnboardingFlow: Ember.computed('currentUser.model', 'signerCookiePresent', 'onboardingFlow', function () {
      if (this.onboardingFlow === "hasSeenSignerOnboarding") {
        return this.signerCookiePresent;
      } else {
        return this.get("currentUser.model.".concat(this.onboardingFlow));
      }
    }),
    hasNotSeenOnboardingFlow: Ember.computed.not('hasSeenOnboardingFlow'),
    markAsSeen: function markAsSeen(onboardingFlow) {
      var _this = this;

      if (!this.get('session.isAuthenticated') || Ember.isBlank(this.get('currentUser.model')) || Ember.isBlank(onboardingFlow)) {
        this.set('hasSeenOnboardingFlow', true);

        if (this.onboardingFlow === 'hasSeenSignerOnboarding') {
          this.sendAction('onComplete');
        }

        return undefined;
      }

      if (this.onboardingFlow === 'hasSeenMigrationOnboarding' && this.get('currentUser.model.hasSeenMigrationOnboarding')) {
        this.set('currentUser.model.openMigrationPopup', !this.get('currentUser.model.openMigrationPopup'));
        return undefined;
      }

      var settings = {};
      settings[onboardingFlow.underscore()] = true;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/users/").concat(_this.get('currentUser.model.id'), "/settings"),
          type: 'PUT',
          data: {
            settings: settings
          }
        }).then(function (result) {
          _this.set("currentUser.model.".concat(onboardingFlow), true);

          _this.set('hasSeenOnboardingFlow', true);

          if (_this.onboardingFlow === 'hasSeenMigrationOnboarding') {
            _this.set('currentUser.model.openMigrationPopup', true);
          }

          resolve(result);
        }, function (error) {
          reject(error);
        });
      }, 'mark as seen');
    },
    requestComponents: ["signatureComponent", "textComponent", "dateComponent", "dateComponent"],
    annotationComponents: ["signatureAnnotationComponent", "textAnnotationComponent", "checkmarkAnnotationComponent"],
    signers: Ember.computed('i18n.locale', function () {
      return [{
        signerName: "".concat(this.i18n.t('components.document.signerRow.sign'), " 1")
      }, {
        signerName: "".concat(this.i18n.t('components.document.signerRow.sign'), " 2")
      }, {
        signerName: "".concat(this.i18n.t('components.document.signerRow.sign'), " 3")
      }];
    }),
    accountNavItems: Ember.computed('i18n.locale', function () {
      return [this.i18n.t('components.rsLeftNav.Settings'), this.i18n.t('components.rsLeftNav.Integrations'), this.i18n.t('components.rsLeftNav.Branding'), this.i18n.t('components.rsLeftNav.Users'), this.i18n.t('components.rsLeftNav.Reports')];
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      nextStep: function nextStep() {
        if (this.onboardingFlow === 'hasSeenMigrationOnboarding') return;

        if (this.currentStep >= this.totalSteps) {
          this.markAsSeen(this.onboardingFlow);
        } else {
          this.incrementProperty('currentStep');
          this.set('nextStepText', this.i18n.t('components.onboarding.gotIt'));
        }
      },
      changeMigrationOnboardingPage: function changeMigrationOnboardingPage(page) {
        var curPage = this.get("currentUser.model.migrationOnboardingPageNo"); // expected page values =  page number, next or prev

        var pageNo = typeof page == 'number' && page || (page == 'next' ? ++curPage : --curPage);

        if (curPage > 4) {
          this.markAsSeen(this.onboardingFlow);
          return;
        }

        this.set("currentUser.model.migrationOnboardingPageNo", pageNo);
      },
      dismissMigrationOnboarding: function dismissMigrationOnboarding() {
        this.markAsSeen(this.onboardingFlow);
      }
    }
  });

  _exports.default = _default;
});