define("ember-rs/locales/ja/templates/components/plan-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.planInfo = void 0;
  var planInfo = {
    "perMonth": "/月",
    "teamMember": "チーム メンバー",
    "teamMembers": "チーム メンバー",
    "call": "1-855-895-4862 に電話",
    "selectedPlan": "選択したプラン",
    "selectPlan": "プランの選択"
  };
  _exports.planInfo = planInfo;
});