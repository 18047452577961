define("ember-rs/locales/de/templates/logged-out/features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.features = void 0;
  var features = {
    "note": "Wir haben viele großartige Features zu bieten."
  };
  _exports.features = features;
});