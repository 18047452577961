define("ember-rs/locales/en/templates/onboarding/migration-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.migrationOnboarding = void 0;
  var migrationOnboarding = {
    "migrationHeading": "Explore the newest RightSignature!",
    "slide1Q": "What’s new?",
    "slide1a": "Advanced security features:",
    "slide1b": "Knowledge-based authentication, enforceable passcode access, and more.",
    "slide1c": "Enhanced usability:",
    "slide1d": "A new streamlined layout, enhanced customizability, productivity notifications, and improved mobile functionality.",
    "slide2": "Where are the documents from old RightSigntature?",
    "slide3": "Where are the templates from old RightSigntature?",
    "slide4": "How do I convert to a paid plan on newest RightSignature?",
    "continue": "Continue",
    "dismiss": "Dismiss"
  };
  _exports.migrationOnboarding = migrationOnboarding;
});