define("ember-rs/routes/login", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    addEmailToUrl: function addEmailToUrl(url, email) {
      var escapedEmail = encodeURIComponent(email);
      return email ? "".concat(url, "&Email=").concat(escapedEmail) : url;
    },
    // Simple wrapper to allow for stubbing in tests
    setHref: function setHref(to) {
      window.location.href = to;
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var expiresAt = this.get('session.data.authenticated.expires_at');
      var expired = true;

      if (Ember.isPresent(expiresAt)) {
        expired = Date.now() > Date.parse(expiresAt * 1000);
      }

      if (this.get("session.isAuthenticated") && !expired) {
        this.transitionTo("application");
      } else {
        return _jquery.default.getJSON("".concat(_environment.default.api.endpoint, "/sharefile/authorize_url")).then(function (response) {
          var authorizeUrl = response.authorize_url;
          var email = transition.to.queryParams.email;

          var fullUrl = _this.addEmailToUrl(authorizeUrl, email);

          _this.setHref(fullUrl);

          transition.abort();
        });
      }
    }
  });

  _exports.default = _default;
});