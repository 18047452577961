define("ember-rs/locales/pt-br/templates/signer/sign", ["exports", "ember-rs/locales/pt-br/templates/signer/sign/identify", "ember-rs/locales/pt-br/templates/signer/sign/locked-modal", "ember-rs/locales/pt-br/templates/signer/sign/verify"], function (_exports, _identify, _lockedModal, _verify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    identify: _identify.identify,
    lockedModal: _lockedModal.lockedModal,
    verify: _verify.verify,
    "pinSent": "Foi enviado para você um PIN de verificação de 4 dígitos.",
    "pin": "PIN:",
    "submit": "enviar",
    "back": "Voltar",
    "deliverPin": "you to deliver a 4-digit verification PIN for you to enter on the following page.",
    "afterPhoneComplete": "Depois de você inserir um número de telefone válido, nós lhe enviaremos um PIN de verificação de 4 dígitos via  {{{identityActionName}}} para você entrar na página seguinte.",
    "phone": "chamada telefônica",
    "sms": "sms",
    "countryCode": "Código do país:",
    "phoneNumber": "Número de telefone:",
    "me": "eu",
    "iAgreeComplete": "Concordo que me sejam cobrados <strong>{{{formattedMoney}}} (USD)</strong> após a execução deste documento.",
    "usd": " (USD)",
    "uponExecution": " upon execution of this document.",
    "clickSubmit": "Ao clicar em 'Enviar', você concorda com nossos",
    "privacyPolicy": "Política de Privacidade",
    "consent": "Consentimento de assinatura eletrônica",
    "contents": "e o conteúdo deste documento.",
    "saveSuccess": "Seu progresso foi salvo com sucesso. Você pode recuperar este documento clicando no link no email.",
    "submitButton": "Enviar"
  };
  _exports.sign = sign;
});