define("ember-rs/components/application-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    actions: {
      toggleSidebar: function toggleSidebar() {
        if (this.get('layoutSizeData.useMobileLayout')) {
          this.toggleProperty('layoutSizeData.showMobileNavMenu');
        }
      },
      invalidateSession: function invalidateSession() {
        this.sendAction('invalidateSession');
      }
    }
  });

  _exports.default = _default;
});