define("ember-rs/utils/mfa-reasons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mfaReasons = void 0;
  var mfaReasons = Ember.A([{
    id: "no_confidential_data",
    selected: false,
    description: 'account.settings.mfa-settings.opt-out-reason-first'
  }, {
    id: "no_technical_capability",
    selected: false,
    description: 'account.settings.mfa-settings.opt-out-reason-second'
  }]);
  _exports.mfaReasons = mfaReasons;
});