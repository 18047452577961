define("ember-rs/locales/pt-br/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "notAllowed": "Ação não permitida",
    "noPro": "No momento, você não tem acesso PRO a esta conta. Você pode assinar e visualizar seus documentos, mas os recursos PRO não estão disponíveis. Entre em contato com um administrador desta conta para conceder acesso PRO para que você possa:",
    "send": "Enviar documentos para assinatura",
    "create": "Crie e use modelos",
    "customize": "Personalizar a identidade visual",
    "enable": "Ativar integrações de terceiros",
    "reports": "Visualizar relatórios",
    "login": "Entrar no RightSignature"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});