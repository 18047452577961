define("ember-rs/templates/onboarding/send-onboarding-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Jb6GXHA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"img\"],[14,\"src\",\"/assets/v3/onboarding/back-arrow-new.png\"],[14,0,\"onboarding-target-image\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding-new/overlay-arrow.svg\"],[14,0,\"onboarding-arrow\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/overlay-arrow.png\"],[15,\"alt\",[30,[36,0],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"onboarding.sendOnboarding4.close\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/send-onboarding-4.hbs"
    }
  });

  _exports.default = _default;
});