define("ember-rs/locales/de/templates/signer/footer-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerDesktop = void 0;
  var footerDesktop = {
    "from": "Von",
    "submitSig": "Unterschrift senden",
    "submit": "Senden",
    "clickSubmit": "Durch Klicken auf 'Senden' akzeptieren Sie unsere",
    "privacyPolicy": "Datenschutzrichtlinie",
    "consent": "E-Signatur-Zustimmung",
    "contents": " und den Inhalt dieses Dokuments.",
    "left": "Links",
    "save": "Speichern",
    "help": "Benötigen Sie Hilfe?",
    "saveProgress": "Fortschritt speichern",
    "savingChanges": "Änderungen speichern",
    "field": "Feld",
    "fields": "Felder"
  };
  _exports.footerDesktop = footerDesktop;
});