define("ember-rs/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ba/hKmMN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"signing_complete\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,1,\"done_icon\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-file-text-o\"],[12],[13],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-circle\"],[12],[13],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-check-circle\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"signer.signingComplete.docSigned\"],null]],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"signer.signingComplete.createTrial\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,1,[\"existingUserByEmail\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"button\"]],[[\"@route\",\"@query\"],[\"\",[30,[36,2],null,[[\"email\"],[[35,1,[\"email\"]]]]]]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"signer.signingComplete.createAccount\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[[24,0,\"button\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"signer.signingComplete.login\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"link-to\",[[24,0,\"button subtle\"]],[[\"@route\"],[\"application\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"signer.signingComplete.notNow\"],null]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"signer\",\"hash\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/signer/signing-complete.hbs"
    }
  });

  _exports.default = _default;
});