define("ember-rs/templates/toolbar-icons/date-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bByL1w9W",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"14px\"],[14,\"height\",\"12.168px\"],[14,\"viewBox\",\"0 0 14 12.168\"],[14,\"enable-background\",\"new 0 0 14 12.168\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[2,\"\\n\"],[10,\"g\"],[12],[2,\"\\n\\t\"],[10,\"g\"],[12],[2,\"\\n\\t\\t\"],[10,\"path\"],[14,\"d\",\"M12,2.169c0.551,0,1,0.449,1,1v6.999c0,0.551-0.449,1-1,1H2c-0.551,0-1-0.449-1-1V3.169\\n\\t\\t\\tc0-0.551,0.449-1,1-1H12 M12,1.169H2c-1.1,0-2,0.9-2,2v6.999c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V3.169\\n\\t\\t\\tC14,2.069,13.1,1.169,12,1.169L12,1.169z\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"rect\"],[14,\"x\",\"4\"],[14,\"y\",\"0\"],[14,\"width\",\"1\"],[14,\"height\",\"3\"],[12],[13],[2,\"\\n\\t\"],[10,\"rect\"],[14,\"x\",\"3\"],[14,\"y\",\"5\"],[14,\"width\",\"2\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\\t\"],[10,\"rect\"],[14,\"x\",\"6\"],[14,\"y\",\"5\"],[14,\"width\",\"2\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\\t\"],[10,\"rect\"],[14,\"x\",\"9\"],[14,\"y\",\"5\"],[14,\"width\",\"2\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\\t\"],[10,\"rect\"],[14,\"x\",\"3\"],[14,\"y\",\"8\"],[14,\"width\",\"2\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\\t\"],[10,\"rect\"],[14,\"x\",\"6\"],[14,\"y\",\"8\"],[14,\"width\",\"2\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\\t\"],[10,\"rect\"],[14,\"x\",\"9\"],[14,\"y\",\"8\"],[14,\"width\",\"2\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\\t\"],[10,\"rect\"],[14,\"x\",\"9\"],[14,\"y\",\"0\"],[14,\"width\",\"1\"],[14,\"height\",\"3\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/toolbar-icons/date-component.hbs"
    }
  });

  _exports.default = _default;
});