define("ember-rs/locales/de/routes/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "includeKey": "Schließen Sie das folgende Element ein, um diese Aktion auszuführen: {{{key}}}",
    "validKey": "Schließen Sie das folgende gültige Element ein: {{{key}}} ({{{validKey}}})"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});