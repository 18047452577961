define("ember-rs/locales/pt-br/templates/onboarding/signer-onboarding-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboardingOld = void 0;
  var signerOnboardingOld = {
    "title": "É muito fácil assinar um documento com o RightSignature&trade;!",
    "completefields": "Preencha os campos marcados com um sinalizador.",
    "submitSignature": "Preencha os campos e envie a sua assinatura.",
    "progress": "Veja em que posição você está com a barra de progresso. Depois de preencher todos os campos obrigatórios, clique no botão Enviar Assinatura para terminar o processo de assinatura.",
    "help": "Precisa de ajuda?",
    "questions": "Se você tiver alguma dúvida ou precisar de mais informações, obtenha ajuda aqui."
  };
  _exports.signerOnboardingOld = signerOnboardingOld;
});