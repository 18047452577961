define("ember-rs/templates/components/document-tags-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Qg4XEpr",
    "block": "{\"symbols\":[\"tag\",\"position\"],\"statements\":[[10,\"ul\"],[14,0,\"tags\"],[12],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[15,0,[31,[\"tag \",[30,[36,3],[[35,2],\"dark\"],null]]]],[12],[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,3],[[32,1,[\"value\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[12],[2,\"=\"],[13],[2,\" \"],[1,[32,1,[\"value\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[30,[36,6],[[35,5],[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"button is-text-only\"],[4,[38,0],[[32,0],\"removeTag\",[32,2],true],null],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"close\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"inline-svg\",\"darkBackground\",\"if\",\"isPartialSession\",\"cantRemoveTags\",\"or\",\"unless\",\"tagsCollection\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/document-tags-list.hbs"
    }
  });

  _exports.default = _default;
});