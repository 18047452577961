define("ember-rs/utils/font-scaling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.maxRows = maxRows;
  _exports.POINT_TO_PIXEL_CONSTANT = _exports.CHARACTER_CONSTANT = _exports.LINE_HEIGHT_MULTIPLIER = _exports.MINIMUM_FONT_SIZE = _exports.DEFAULT_FONT_SIZE = void 0;
  var DEFAULT_FONT_SIZE = 14;
  _exports.DEFAULT_FONT_SIZE = DEFAULT_FONT_SIZE;
  var MINIMUM_FONT_SIZE = 6;
  _exports.MINIMUM_FONT_SIZE = MINIMUM_FONT_SIZE;
  var LINE_HEIGHT_MULTIPLIER = 1.25; // This determines a mathematial relationship between the vertical (font size)
  // and horizontal (character width) dimensions of text. Since we use Courier
  // in the final processed pdf, we use it's associated constant here so that
  // the web app views look as close as possible to the final pdf
  // Taken from https://pearsonified.com/2012/01/characters-per-line.php

  _exports.LINE_HEIGHT_MULTIPLIER = LINE_HEIGHT_MULTIPLIER;
  var CHARACTER_CONSTANT = 1.67; //1 point (computer) = 1.33 pixel

  _exports.CHARACTER_CONSTANT = CHARACTER_CONSTANT;
  var POINT_TO_PIXEL_CONSTANT = 1.33;
  _exports.POINT_TO_PIXEL_CONSTANT = POINT_TO_PIXEL_CONSTANT;

  function maxRows(componentHeight) {
    return Math.floor(componentHeight / (MINIMUM_FONT_SIZE * LINE_HEIGHT_MULTIPLIER));
  }
});