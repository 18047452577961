define("ember-rs/locales/es/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archivedMigratedModal = void 0;
  var archivedMigratedModal = {
    "expiredBilling": "Facturación caducada",
    "updateBillingDetails": "Los detalles del pago no están actualizados. Haga clic a continuación para actualizar los detalles de facturación y seguir utilizando nuestro servicio.",
    "clickUpdate": "Actualizar",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>Contactar con asistencia</a>",
    "logout": "Cerrar sesión"
  };
  _exports.archivedMigratedModal = archivedMigratedModal;
});