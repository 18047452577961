define("ember-rs/locales/pt-br/templates/document/prepare-signers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_signers = void 0;
  var prepare_signers = {
    "add_signers": "Adicionar assinaturas",
    "doc_limit_p1": "Você usou ",
    "doc_limit_p2": "do seu limite de documento para o mês. Entre em contato conosco para atualizar sua conta.",
    "recentContacts": "Contatos recentes"
  };
  _exports.prepare_signers = prepare_signers;
});