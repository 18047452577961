define("ember-rs/locales/fr/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "unlock_message": "Un ou plusieurs signataires ont dépassé le nombre maximal de tentatives de vérification de l'identité (3) et le document est maintenant verrouillé pour ces signataires. Pour déverrouiller le document et demander aux signataires en attente de vérifier leurs identités respectives avec l'authentification basée sur les connaissances, cliquez sur Déverrouiller. Notez que le déverrouillage du document peut entrer en conflit avec le<a target='_blank' href='https://www.irs.gov/uac/electronic-signature-guidance-for-forms-8878-and-8879'>IRS Electronic Signature Guidance for Forms 8878 and 8879</a>."
  };
  _exports.unlock = unlock;
});