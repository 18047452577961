define("ember-rs/templates/components/reports/month-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p9eG0IWx",
    "block": "{\"symbols\":[\"xs\",\"month\"],\"statements\":[[8,\"x-select\",[],[[\"@value\",\"@on-change\"],[[34,0],[30,[36,1],[[32,0],\"monthWasSelected\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"option\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],[\"components.reports.monthSelect.selectMonth\"],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"option\"]],[],[[\"@value\"],[[32,2,[\"value\"]]]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"label\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"monthSelected\",\"action\",\"t\",\"months\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/reports/month-select.hbs"
    }
  });

  _exports.default = _default;
});