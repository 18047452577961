define("ember-rs/locales/es/templates/onboarding/new-user-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboarding = void 0;
  var newUserOnboarding = {
    "title": "Bienvenido a RightSignature™",
    "letsPersonalize": "Antes de explorar las funciones, personalicemos su cuenta.",
    "photoText": "Su foto se usa en la página de firma de documentos. Añade un nivel de personalización y autenticidad.",
    "photoSubtext": "Puede actualizar esto en cualquier momento en los Parámetros de la cuenta.",
    "sendSign": "Enviar y firmar sus documentos",
    "sendDoc": "Envíe documentos para que los firmen otras personas, o firme los documentos que necesitan su firma.",
    "knowMore": "¿Quiere saber más?",
    "viewSupport": "Vea artículos y vídeos de asistencia técnica",
    "createTemplates": "Cree plantillas para documentos firmados con frecuencia.",
    "saveTime": "Ahorre tiempo y cree plantillas reutilizables para sus documentos de uso frecuente, incluidas exenciones, acuerdos de no divulgación (NDA), formularios de impuestos, aplicaciones para empleados y mucho más.",
    "learnMore": "¿Querer aprender más?",
    "accessSupport": "Acceda a nuestro sitio de <a href='#''>asistencia técnica</a>, que contiene tutoriales de vídeo y consejos útiles para aprovechar al máximo RightSignature™."
  };
  _exports.newUserOnboarding = newUserOnboarding;
});