define("ember-rs/locales/es/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationSettingsService = void 0;
  var integrationSettingsService = {
    "requiresInfo": "Esta integración requiere cierta información antes de continuar:",
    "authorize": "Autorizar",
    "sending": "Envíe sus documentos {{{formattedProviderName}}} para la firma electrónica desde dentro de RightSignature",
    "archiving": "Envíe un pdf de documentos completados a una carpeta específica en su cuenta {{{formattedProviderName}}}",
    "contacts": "Haga que todos sus contactos {{{formattedProviderName}}} estén disponibles para la firma de documentos",
    "payments": "Coloque los Componentes de pago en sus documentos de RightSignature para recopilar y almacenar de manera segura la información de la tarjeta de crédito y autorizar los cargos a través de su cuenta {{{formattedProviderName}}}",
    "fieldsInfo": "Rellene todos los campos"
  };
  _exports.integrationSettingsService = integrationSettingsService;
});