define("ember-rs/locales/en/templates/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.index = void 0;
  var index = {
    "templates": "Templates",
    "reusableTemplates": "Reusable Templates",
    "templateExplain": "Templates let you embed documents in your site, mass distribute, or just send frequently used documents in as little as one step.",
    "newTemplate": "New template",
    "noResults": "No search results",
    "searchAgain": "Your search returned no results, please try another search to find your template.",
    "create": "Create reusable document templates you can embed in your site and share via link.",
    "createTemplate": "Create Template",
    "notAllowed": "Your current plan does not allow you to create templates. If you would like to create templates, please contact support and upgrade your account."
  };
  _exports.index = index;
});