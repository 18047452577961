define("ember-rs/locales/en/templates/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documents = void 0;
  var documents = {
    "documents": "Documents",
    "noResults": "No search results",
    "tryAgain": "Your search returned no results, please try another search to find your document.",
    "anotherFilter": "The filter you have chosen has no results, please try another filter.",
    "documentsTip": "This is where your signed and pending documents live. Create your first document to get started.",
    "questions": "Have a question? Find more help here.",
    "gotIt": "Got it!",
    "startDocument": "Start Document",
    "userGuide": "User Guide"
  };
  _exports.documents = documents;
});