define("ember-rs/locales/pt-br/templates/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "addRoles": "Adicionar funções",
    "whatRoles": "De que funções este modelo necessita?",
    "signOrder": "Definir ordem de signatários",
    "uniqueName": "Você deve atribuir nomes de função exclusivos.",
    "addRole": "Adicionar função",
    "sendRole": "Adicionar função de remetente",
    "next": "Próxima etapa: Colocar campos"
  };
  _exports.addRoles = addRoles;
});