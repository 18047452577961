define("ember-rs/locales/nl/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archivedMigratedModal = void 0;
  var archivedMigratedModal = {
    "expiredBilling": "Facturering verlopen",
    "updateBillingDetails": "Uw betalingsgegevens zijn niet up-to-date. Klik hieronder om de factureringsgegevens bij te werken en onze service te blijven gebruiken.",
    "clickUpdate": "Bijwerken",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>Contact opnemen met Support</a>",
    "logout": "Afmelden"
  };
  _exports.archivedMigratedModal = archivedMigratedModal;
});