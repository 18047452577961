define("ember-rs/locales/it/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbarMenuitem = void 0;
  var componentToolbarMenuitem = {
    "signatureComponent": "Campo firma",
    "textComponent": "Campo di testo",
    "dateComponent": "Campo data",
    "checkComponent": "Casella di controllo",
    "checkGroupComponent": "Gruppo di caselle di controllo",
    "initialsComponent": "Campo delle iniziali",
    "selectComponent": "Seleziona casella",
    "signatureAnnotationComponent": "Aggiungi firma",
    "signatureAnnotationName": "Annotazione firma",
    "textAnnotationComponent": "Aggiungi testo",
    "textAnnotationName": "Annotazione testo",
    "checkmarkAnnotationComponent": "Aggiungi segno di spunta",
    "checkmarkAnnotationName": "Annotazione segno di spunta",
    "attachmentComponent": "Allegato",
    "attachmentComponentName": "Allegato richiesto",
    "paymentComponent": "Riscuoti pagamento",
    "signatureComponenttip": "Posizionare questo campo nel punto in cui un firmatario deve inserire la propria firma.",
    "textComponenttip": "Posizionare questo campo nel punto in cui un firmatario deve inserire informazioni in formato testo (ad esempio, digitare il proprio nome o indirizzo).",
    "dateComponenttip": "Utilizzare questo campo nel punto in cui è necessario aggiungere date. Quando si firma il documento, il firmatario visualizzerà un selettore della data, che renderà più semplice selezionare una data. La data verrà visualizzata in formato \"GG-MM-AAAA\".",
    "checkComponenttip": "Utilizzare questo campo nel punto in cui il firmatario deve selezionare un'opzione (ad esempio, una clausola \"Accetto di...\"). Se si dispone di un documento con una serie di opzioni, in cui un firmatario è limitato alla selezione di una o almeno una delle opzioni, utilizzare l'opzione Gruppo di caselle di controllo.",
    "checkGroupComponenttip": "Utilizzare un gruppo di caselle di controllo se si dispone di una serie di opzioni, in cui un firmatario è limitato alla selezione di una o almeno una delle opzioni.",
    "initialsComponenttip": "Posizionare questo campo nel punto in cui un firmatario deve inserire le proprie iniziali.",
    "selectComponenttip": "Inserire una casella di selezione nel punto in cui i firmatari devono effettuare una selezione da un elenco di opzioni.",
    "signatureAnnotationComponenttip": "Aggiungere la propria firma al documento.",
    "textAnnotationComponenttip": "Aggiungere il proprio testo al documento da precompilare o annotare.",
    "checkmarkAnnotationComponenttip": "Selezionare una casella nel documento.",
    "attachmentComponenttip": "Posizionare questa casella nel punto in cui si desidera che un firmatario alleghi un'immagine o un file al documento.",
    "paymentComponenttip": "Utilizzare questa opzione per aggiungere un pulsante al documento in modo che un firmatario possa immettere un numero di carta di credito per fornire il pagamento. Nota: prima di utilizzare questa opzione, è necessario abilitare un'integrazione di pagamento nell'account (accedere ad Account e selezionare Integrazioni).",
    "unknownField": "Campo sconosciuto"
  };
  _exports.componentToolbarMenuitem = componentToolbarMenuitem;
});