define("ember-rs/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t5u+6qiI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"onboarding-target-image avatar-wrapper\"],[12],[2,\"\\n  \"],[8,\"rs-avatar\",[],[[\"@size\",\"@initials\"],[\"medium\",[34,1,[\"model\",\"initials\"]]]],null],[2,\"\\n  \"],[10,\"label\"],[14,\"for\",\"uploader\"],[12],[2,\" \"],[1,[30,[36,0],[\"components.edit-avatar.upload-avatar-label\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding-new/overlay-arrow.svg\"],[14,0,\"onboarding-arrow\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/overlay-arrow.png\"],[15,\"alt\",[30,[36,0],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"onboarding.accountOnboarding2.body\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[35,2,[\"useMobileLayout\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"onboarding.accountOnboarding2.mobileBody\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"currentUser\",\"layoutSizeData\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/account-onboarding-2.hbs"
    }
  });

  _exports.default = _default;
});