define("ember-rs/controllers/reusable-template/review", ["exports", "ember-rs/mixins/distribution-method"], function (_exports, _distributionMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_distributionMethod.default, {
    isSaving: false,
    hasMessageLengthError: false,
    isValid: Ember.computed.not('hasMessageLengthError') && Ember.computed.alias('model.isReviewStepValid'),
    isNotValid: Ember.computed.not('isValid'),
    distributionMethod: Ember.computed.alias('model.distributionMethod'),
    identityMethod: Ember.computed.alias('model.identityMethod'),
    layoutSizeData: Ember.inject.service(),
    actions: {
      validateMessageLength: function validateMessageLength(value) {
        this.set('hasMessageLengthError', value);
      }
    }
  });

  _exports.default = _default;
});