define("ember-rs/locales/ja/templates/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = void 0;
  var verify = {
    "advisory": "このドキュメントの送信者は、ID を確認するために RightSignature のナレッジベース認証機能を有効にしました。RightSignature は、BlockScore のサードパーティ サービスを使用して、提供された個人情報を検証し、確認します。提供された個人情報の使用は、BlockScore 独自のデータ レコードによる ID の検証と確認に限定されます。ID の検証および確認のパフォーマンスが、ユーザーの信用報告書、信用度、または金融機関への報告書に影響を与えることはありません。IRS、Blockscore、RightSignature のいずれも、このプロセスによって対象の信用報告書または税務情報を閲覧したり、こうした情報にアクセスしたりすることはできません。",
    "step2": "ID 確認 - 手順 2/2",
    "answer": "以下の ID 確認用の質問に回答するには、該当する内容の横のチェックボックスを選択してください。",
    "noMatch": "申し訳ありませんが、入力した内容がこの ID のレコードと一致しません。",
    "youHave": "後 ",
    "remaining": "回、ドキュメントへのアクセスを試行できます。",
    "complete": "確認の完了",
    "return": "フォームに戻る",
    "advisory2": "ID 確認に関するアドバイザリ",
    "unableToAuthenticate": "正常に認証できません。別の認証メカニズムを使用してドキュメントにアクセスするには、<b>{{{sender_email}}}</b> に連絡してください。"
  };
  _exports.verify = verify;
});