define("ember-rs/locales/zh-cn/templates/components/add-role-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoleField = void 0;
  var addRoleField = {
    "name": "姓名...",
    "emailOptional": "电子邮件(可选)...",
    "email": "电子邮件...",
    "nameTip": "需要全名。",
    "emailTip": "需要有效的电子邮件地址。",
    "roleTip": "需要角色名称。"
  };
  _exports.addRoleField = addRoleField;
});