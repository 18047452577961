define("ember-rs/locales/es/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alreadySigned = void 0;
  var alreadySigned = {
    "thankYou": "Gracias por firmar",
    "youHave": "Ha firmado con éxito este documento. Puede ver el documento en cualquier momento solicitando un enlace seguro.",
    "sendNewLink": "Enviar nuevo enlace de descarga",
    "successfully": "Ha firmado con éxito este documento."
  };
  _exports.alreadySigned = alreadySigned;
});