define("ember-rs/locales/es/templates/mobile-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mobileNavigation = void 0;
  var mobileNavigation = {
    "documents": "Documentos",
    "templates": "Plantillas",
    "settings": "Sus parámetros",
    "integrations": "Integraciones",
    "users": "Usuarios",
    "reports": "Informes",
    "help": "Ayuda",
    "logout": "Cerrar sesión"
  };
  _exports.mobileNavigation = mobileNavigation;
});