define("ember-rs/controllers/upgrade-worldpay", ["exports", "ember-rs/utils/hybris-plan-details", "ember-rs/mixins/trial-methods", "ember-rs/config/environment"], function (_exports, _hybrisPlanDetails, _trialMethods, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_trialMethods.default, {
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    rootElement: Ember.computed(function () {
      return document.querySelector('body');
    }),
    features: _hybrisPlanDetails.features,
    plans: _hybrisPlanDetails.plans,
    billingFrequency: 'quarter',
    selectedPlanId: undefined,
    minimumSeats: 0,
    selectedSeats: '',
    creditAmount: 0,
    billingOptions: _hybrisPlanDetails.billingOptions,
    proFeatures: Ember.computed.filterBy('features', 'proFeature', true),
    basePriceApiStatus: 'loading',
    discountApiStatus: 'fetched',
    upgradeApiError: '',
    orderId: '',
    MIN_SEATS_ORDER_LIMIT: 3,
    MAX_SEATS_ORDER_LIMIT: 1000,
    billing: {
      country: 'US',
      state: 'AK'
    },
    isLoading: false,
    userFieldInfo: Ember.computed('selectedPlanId', 'minimumSeats', function () {
      if (this.selectedPlanId === 'standard') return this.i18n.t('controllers.upgradeWorldpay.standardInfo');else if (Ember.get(this, 'currentUser.account.isMigrated') && this.minimumSeats > 3) return this.i18n.t('controllers.upgradeWorldpay.migratedInfo', {
        minimumSeats: this.minimumSeats
      });else return this.i18n.t('controllers.upgradeWorldpay.planInfo');
    }),
    selectedPlan: Ember.computed('plans', 'selectedPlanId', function () {
      return this.plans.findBy('id', this.selectedPlanId);
    }),
    billingFrequencyDesc: Ember.computed('billingOptions', 'billingFrequency', function () {
      return this.billingOptions.findBy('name', this.billingFrequency).description;
    }),
    billableAmount: Ember.computed('billingOptions', 'billingFrequency', function () {
      return this.billingOptions.findBy('name', this.billingFrequency).value;
    }),
    savingsAmount: Ember.computed('billingOptions', function () {
      return this.billingOptions.findBy('name', "month").value * 12 - this.billingOptions.findBy('name', "year").value;
    }),
    totalAmount: Ember.computed('billableAmount', 'creditAmount', function () {
      return (this.billableAmount - this.creditAmount).toFixed(2);
    }),
    skipWorldPay: Ember.computed('orderId', function () {
      return this.orderId !== '';
    }),
    userSeatsWarning: Ember.computed('selectedSeats', function () {
      var message = undefined;

      if (+this.selectedSeats < this.MIN_SEATS_ORDER_LIMIT) {
        message = "utils.hybrisPlanDetails.selected-plan-requires-3-users";
      } else if (+this.selectedSeats > this.MAX_SEATS_ORDER_LIMIT) {
        message = "utils.hybrisPlanDetails.more-than-1000-users-contact-sales";
      }

      return message;
    }),
    billingSeats: Ember.computed('selectedSeats', function () {
      if (+this.selectedSeats < this.MIN_SEATS_ORDER_LIMIT) {
        return this.MIN_SEATS_ORDER_LIMIT;
      } else if (+this.selectedSeats > this.MAX_SEATS_ORDER_LIMIT) {
        return this.MAX_SEATS_ORDER_LIMIT;
      }

      return this.selectedSeats;
    }),
    driftLinkId: Ember.computed(function () {
      return this.getDriftLinkId();
    }),
    getDriftLinkId: function getDriftLinkId() {
      var driftLinkId = _environment.default.drift.driftLinkId;
      return driftLinkId;
    },
    actions: {
      selectPlan: function selectPlan(planId) {
        if (planId == 'sharefile premium') {
          Ember.set(this, 'selectedPlanId', planId);
          return;
        }

        if (this.minimumSeats > 1 && planId === 'standard') {
          this.flashMessages.warning('the plan u selected can\'t be choosen');
          return;
        }

        var newPlan = this.plans.findBy('id', planId);

        if (this.minimumSeats <= newPlan.defaultTeamMemberCount) {
          Ember.set(this, 'selectedSeats', newPlan.defaultTeamMemberCount).toString();
        }

        Ember.set(this, 'selectedPlanId', planId);
        this.send('updatePrices');
        setTimeout(function () {
          document.querySelector("#usersSection").scrollIntoView({
            behavior: 'smooth'
          });
        }, 0);
      },
      selectUsers: function selectUsers(val) {
        val = parseInt(val); // typecasting both values to avoid integer to string comparisons.

        var selectedSeats = parseInt(this.selectedSeats);
        var minSeats = this.minimumSeats;
        var newUserVal = val;
        if (selectedSeats === val) return;

        if (!val || val < minSeats || val > _hybrisPlanDetails.maxUserValue || val < Ember.get(this, 'selectedPlan.defaultTeamMemberCount')) {
          if (val > 0) {
            if (val < 3) {
              this.send('updateDefaultPrices', this.MIN_SEATS_ORDER_LIMIT);
            } else {
              this.send('updateDefaultPrices', this.MAX_SEATS_ORDER_LIMIT);
            }
          }
        }

        Ember.set(this, 'selectedSeats', newUserVal.toString());

        if (+newUserVal >= this.MIN_SEATS_ORDER_LIMIT && +newUserVal <= this.MAX_SEATS_ORDER_LIMIT) {
          this.send('updatePrices');
        }
      },
      toggleStandardFeatures: function toggleStandardFeatures() {
        this.toggleProperty('showStandardFeatures');
      },
      upgrade: function upgrade() {
        var orderId = this.orderId;
        Ember.set(this, 'billing.order_id', orderId);
        this.send('upgradeAccount', this.billing);
      },
      processSubmit: function processSubmit() {
        if (this.skipWorldPay) {
          this.send('upgrade');
        } else {
          this.send('redirectToWorldpay', this.billing);
        }
      }
    }
  });

  _exports.default = _default;
});