define("ember-rs/locales/de/templates/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signingComplete = void 0;
  var signingComplete = {
    "docSigned": "Dokument wurde signiert.",
    "createTrial": "Erstellen Sie ein kostenloses RightSignature-Konto, um dieses fertige Dokument anzuzeigen oder herunterzuladen. Der Zugriff auf dieses Dokument läuft nie ab. Registrieren Sie sich einfach mit der E-Mail-Adresse, an die das Dokument gesendet wurde, und melden Sie sich an.",
    "createAccount": "Konto erstellen",
    "login": "An Konto anmelden",
    "notNow": "Jetzt nicht"
  };
  _exports.signingComplete = signingComplete;
});