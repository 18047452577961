define("ember-rs/components/document-viewer", ["exports", "jquery", "ember-rs/mixins/viewer-scale"], function (_exports, _jquery, _viewerScale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals IScroll */
  var _default = Ember.Component.extend(_viewerScale.default, {
    classNames: ['document-viewer'],
    attributeBindings: ['style'],
    documentViewerData: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    style: Ember.computed('layoutSizeData.windowWidth', 'documentViewerData.viewerScale', 'documentViewerData.scaledPageWidth', 'documentViewerData.scaledPageHeight', 'documentViewerData.scaledPageMargin', function () {
      var height = this.pageCount * (this.get('documentViewerData.scaledPageHeight') + this.get('documentViewerData.scaledPageMargin'));
      var scale = this.get('documentViewerData.viewerScale');
      var width = this.get('documentViewerData.scaledPageWidth') - this.get('layoutSizeData.scrollbarWidth');
      return "transform: scale(".concat(scale, "); -webkit-transform: scale(").concat(scale, "); width: ").concat(width, "px; height:").concat(height, "px;").htmlSafe();
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (Ember.isBlank(this.$()) || Ember.isPresent(this.$().parents('#document_details_page')) || Ember.isPresent(this.$().parents('.fill-merge-fields'))) {
        return;
      }

      var updateViewerData = function updateViewerData() {
        var scrollTop = parseFloat(_this.$().parents('#document_container, #sign_document').scrollTop());
        var currentPage = Math.floor(scrollTop / parseFloat(_this.get('documentViewerData.pageHeight'))) + 1;

        _this.set('documentViewerData.scrollTop', parseFloat(scrollTop));

        _this.set('documentViewerData.scrollTopPage', parseFloat(Math.abs(_this.$().find(".page:eq(".concat(currentPage - 1, ")")).position().top)));

        _this.set('documentViewerData.currentPage', currentPage);
      };

      updateViewerData();
      this.$().parents('#document_container, #sign_document').on('scroll', function () {
        var throttler = Ember.run.throttle(_this, updateViewerData, 10);

        _this.set('throttle', throttler);
      });
      this.$().on('click', function () {
        _this.set('documentViewerData.componentToFocus', null);
      });
    },
    willDestroy: function willDestroy() {
      if (this.throttle) {
        Ember.run.cancel(this.throttle);
      }
    },
    destroyScrollEvent: Ember.on('willDestroyElement', function () {
      this.$().off('scroll');
      this.$().off('click');
    }),
    // iScroll plugin for mobile web
    initIscroll: Ember.on('didInsertElement', function () {
      var myScroll;
      var self = this; // only intialize for signing page for now

      if (this.get('layoutSizeData.useMobileLayout') && Ember.isPresent(this.$().parents('#sign_document'))) {
        // setting the height to the height of all the pages, and margins + the combined height of the header and footer (150px)
        var height = this.pageCount * (this.get('documentViewerData.scaledPageHeight') + this.get('documentViewerData.scaledPageMargin')) + 150;
        (0, _jquery.default)("#iscroll_wrapper").css({
          "height": "".concat(height, "px")
        });
        Ember.run.later(function () {
          myScroll = new IScroll("#document", {
            zoom: true,
            freeScroll: true,
            scrollX: true,
            scrollY: true
          });
          self.set('documentViewerData.myScroll', myScroll);
        }, 200);
      }
    }),
    destroyIscroll: Ember.on('willDestroyElement', function () {
      if (this.get('layoutSizeData.useMobileLayout') && this.get('documentViewerData.myScroll')) {
        this.get('documentViewerData.myScroll').destroy();
        this.set('documentViewerData.myScroll', null);
      }
    }),
    refreshIscroll: Ember.on('didUpdateAttrs', function () {
      if (this.get('layoutSizeData.useMobileLayout')) {
        if (this.get('documentViewerData.myScroll')) {
          this.get('documentViewerData.myScroll').destroy();
          this.set('documentViewerData.myScroll', null);
        }

        this.initIscroll();
      }
    })
  });

  _exports.default = _default;
});