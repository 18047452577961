define("ember-rs/locales/ja/templates/components/salesforce-opportunities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.salesforceOpportunities = void 0;
  var salesforceOpportunities = {
    "searchOpportunities": "商談の検索...",
    "searchError": "商談の検索中にエラーが発生しました: {{{resp}}}"
  };
  _exports.salesforceOpportunities = salesforceOpportunities;
});