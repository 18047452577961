define("ember-rs/locales/de/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "linkExpired": "Dokumentlink abgelaufen",
    "toSign": "Fordern Sie einen neuen Link an, um dieses Dokument zu signieren.",
    "sendLink": "Neuen Signaturlink senden",
    "contactSender": "Bitten Sie den Absender des Dokuments, Ihnen einen aktuellen Link zu senden."
  };
  _exports.identityExpired = identityExpired;
});