define("ember-rs/locales/en/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbarMenuitem = void 0;
  var componentToolbarMenuitem = {
    "signatureComponent": "Signature Field",
    "textComponent": "Text Field",
    "dateComponent": "Date Field",
    "checkComponent": "Checkbox",
    "checkGroupComponent": "Checkbox Group",
    "initialsComponent": "Initials Field",
    "selectComponent": "Select Box",
    "signatureAnnotationComponent": "Add Signature",
    "signatureAnnotationName": "Signature Annotation",
    "textAnnotationComponent": "Add Text",
    "textAnnotationName": "Text Annotation",
    "checkmarkAnnotationComponent": "Add Checkmark",
    "checkmarkAnnotationName": "Checkmark Annotation",
    "attachmentComponent": "Attachment",
    "attachmentComponentName": "Requested Attachment",
    "paymentComponent": "Collect Payment",
    "signatureComponenttip": "Place this field where a signer needs to provide their signature.",
    "textComponenttip": "Place this field where a signer needs to enter text information (e.g., typing in their name or address).",
    "dateComponenttip": "Use this field where dates need to be added. When signing the document, the signer will be presented with a date picker, which will make it easy for them to select a date. The date will display as 'MM-DD-YYYY'.",
    "checkComponenttip": "Use this field where the signer needs to check an option (e.g., a clause 'I agree to...'). If you have a document with a series of options, where a signer is limited to selecting only one or at least one of the options, use the Check Box Group option.",
    "checkGroupComponenttip": "Use a checkbox group if you have a series of options, where a signer is limited to selecting only one or at least one of the options.",
    "initialsComponenttip": "Place this field where a signer needs to provide their initials.",
    "selectComponenttip": "Place a select box, where signers need to choose from a list of options.",
    "signatureAnnotationComponenttip": "Add your own signature to the document.",
    "textAnnotationComponenttip": "Add you own text to the document to pre-fill or annotate.",
    "checkmarkAnnotationComponenttip": "Check a box on the document.",
    "attachmentComponenttip": "Place this box where you want a signer to attach an image or file to your document.",
    "paymentComponenttip": "Use this option to add a button to your document so a signer can enter a credit card number to provide payment. Note: Before using this, you must enable a payment integration on your account. (Visit Account then select Integrations).",
    "unknownField": "Unknown Field"
  };
  _exports.componentToolbarMenuitem = componentToolbarMenuitem;
});