define("ember-rs/locales/zh-cn/templates/onboarding/send-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding3 = void 0;
  var sendOnboarding3 = {
    "remind": "选择<span>提醒</span>可再次提醒签名者。",
    "void": "选择<span>失效</span>可取消文档。"
  };
  _exports.sendOnboarding3 = sendOnboarding3;
});