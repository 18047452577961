define("ember-rs/components/on-boarding", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    show: false,
    onboardingFlowMarker: '',
    freeSteps: 1,
    currentStep: 1,
    totalSteps: 0,
    ////////////////////////  HOOKS  ////////////////////////
    didRender: function didRender() {
      Ember.run.scheduleOnce('afterRender', this, 'updateSteps');
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    showFreeSteps: Ember.computed.readOnly('currentUser.free'),
    admin: Ember.computed.readOnly('currentUser.canManageUsers'),
    multipleSteps: Ember.computed.gt('totalSteps', 1),
    embedOrMobile: Ember.computed.or('layoutSizeData.useMobileLayout', 'layoutSizeData.withinEmbed'),
    stepWidth: Ember.computed('embedOrMobile', 'totalSteps', function () {
      var desktopWidth = 750;
      var mobileWidth = 100 / this.totalSteps;
      return this.embedOrMobile ? mobileWidth : desktopWidth;
    }),
    stepHeight: Ember.computed('embedOrMobile', 'layoutSizeData.windowHeight', function () {
      var desktopHeight = 520;
      var mobileHeight = this.get('layoutSizeData.windowHeight') - 70;
      return this.embedOrMobile ? mobileHeight : desktopHeight;
    }),
    stepsArray: Ember.computed('totalSteps', function () {
      var array = [];
      var steps = this.totalSteps;

      for (var i = 1; i <= steps; i++) {
        array.push(i);
      }

      return array;
    }),
    stepContainerStyle: Ember.computed('stepWidth', 'totalSteps', 'currentStep', 'embedOrMobile', function () {
      var steps = this.totalSteps;
      var containerWidth = "".concat(this.stepWidth * steps, "px");
      var left = "".concat((this.currentStep - 1) * this.stepWidth, "px");

      if (this.embedOrMobile) {
        containerWidth = "".concat(100 * steps, "%");
        left = "".concat((this.currentStep - 1) * (this.stepWidth * steps), "%");
      }

      return "width: ".concat(containerWidth, "; left: -").concat(left, ";").htmlSafe();
    }),
    stepWindowStyle: Ember.computed('embedOrMobile', function () {
      if (this.embedOrMobile) {
        return "width: 100%; height: ".concat(this.stepHeight, "px").htmlSafe();
      } else {
        return this.stepStyle || ''.htmlSafe();
      }
    }),
    stepStyle: Ember.computed('stepWidth', 'embedOrMobile', function () {
      var width = this.stepWidth;
      var height = this.stepHeight;

      if (this.embedOrMobile) {
        return "width: ".concat(width, "%; height: ").concat(height, "px").htmlSafe();
      } else {
        return "width: ".concat(width, "px; height: ").concat(height, "px").htmlSafe();
      }
    }),
    onLastStep: Ember.computed('currentStep', 'totalSteps', function () {
      return this.currentStep === this.totalSteps;
    }),
    nextButtonText: Ember.computed('onLastStep', 'lastStepText', function () {
      var lastStepText = this.lastStepText;
      return this.onLastStep && Ember.isPresent(lastStepText) ? lastStepText : 'Next';
    }),
    ////////////////////////  METHODS  ////////////////////////
    updateSteps: function updateSteps() {
      var visibleSteps = this.$('.step').length;

      if (this.totalSteps !== visibleSteps) {
        this.setProperties({
          currentStep: 1,
          totalSteps: visibleSteps
        });
      }
    },
    markAsSeen: function markAsSeen(flow) {
      var _this = this;

      if (!this.get('session.isAuthenticated') || Ember.isBlank(this.currentUser) || Ember.isBlank(flow)) {
        return undefined;
      }

      var settings = {};
      settings[flow.underscore()] = true;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/users/").concat(_this.get('currentUser.model.id'), "/settings"),
          type: 'PUT',
          data: {
            settings: settings
          }
        }).then(function (result) {
          _this.set("currentUser.model.".concat(flow), true);

          resolve(result);
        }, function (error) {
          reject(error);
        });
      }, 'mark as seen');
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      nextStep: function nextStep() {
        if (this.currentStep < this.totalSteps) {
          this.incrementProperty('currentStep');
        } else if (this.onLastStep) {
          this.setProperties({
            show: false,
            currentStep: 1
          });
          this.markAsSeen(this.onboardingFlowMarker);

          if (Ember.isPresent('showAfterDismiss')) {
            this.set('showAfterDismiss', true);
          }
        }
      },
      goToStep: function goToStep(step) {
        this.set('currentStep', step);
      }
    }
  });

  _exports.default = _default;
});