define("ember-rs/locales/zh-cn/templates/signer/footer-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.footerDesktop = void 0;
  var footerDesktop = {
    "from": "发件人",
    "submitSig": "提交签名",
    "submit": "提交",
    "clickSubmit": "单击“提交”即表示您同意我们的",
    "privacyPolicy": "隐私政策",
    "consent": "电子签名同意书",
    "contents": "，以及本文档的内容。",
    "left": "左对齐",
    "save": "保存",
    "help": "需要帮助?",
    "saveProgress": "保存进度",
    "savingChanges": "保存更改",
    "field": "字段",
    "fields": "字段"
  };
  _exports.footerDesktop = footerDesktop;
});