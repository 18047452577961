define("ember-rs/locales/zh-cn/templates/users/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.singIn = void 0;
  var singIn = {
    "redirect": "正在重定向以登录..."
  };
  _exports.singIn = singIn;
});