define("ember-rs/locales/es/templates/onboarding/welcome-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding1 = void 0;
  var welcomeOnboarding1 = {
    "welcome": "Bienvenido a RightSignature,",
    "tips": "Le mostraremos dónde están las cosas, cómo usar RightSignature y otros consejos para aprovechar al máximo nuestro servicio"
  };
  _exports.welcomeOnboarding1 = welcomeOnboarding1;
});