define("ember-rs/locales/it/templates/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerPaymentDetails = void 0;
  var signerPaymentDetails = {
    "paymentInfo": "Informazioni di pagamento",
    "paymentID": "ID pagamento:",
    "retryPayment": "Nuovo tentativo di pagamento...",
    "failed": "Operazione non riuscita",
    "chargedSuccess": "Addebito riuscito: {{chargedAt}}.",
    "stripeCustomer": "Cliente Stripe",
    "stripeCharge": "Addebito Stripe",
    "attempted": "Ultimo tentativo: {{chargedAt}}.",
    "retry": "Riprova",
    "pending": "In sospeso...",
    "success": "Pagamento riuscito al nuovo tentativo.",
    "retryError": "Il pagamento non è riuscito al nuovo tentativo ({{{errorMsg}}})."
  };
  _exports.signerPaymentDetails = signerPaymentDetails;
});