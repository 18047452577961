define("ember-rs/utils/safe-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = safeAccess;

  var present = function present(value) {
    return value !== null && typeof value !== 'undefined';
  };

  var empty = function empty(value) {
    return !present(value);
  };
  /**
   * Safely fetches values from a given object. All params can be combined to find
   * an object in a complex manner. Example:
   *
   * ```js
   * safeAccess([{ foo: 'bar' }], 'not found', 0, 'foo')
   * ```
   * will return `'bar'`
   *
   * and
   *
   * ```js
   * safeAccess(['baz'], 'not found', 0)
   * ```
   * will return `'baz'`
   *
   * @param {object} input - The object in which you wish to fetch data from.
   * @param {object} defaultValue - The object that you wish to fall back to if a value can not be retrieved.
   * @param {string} paths - A var arg list of paths in which you want to access an object.
   * @return {object} The found object or defaultValue.
   */


  function safeAccess(input, defaultValue) {
    var result = input;

    if (empty(input)) {
      return defaultValue;
    }

    for (var _len = arguments.length, paths = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      paths[_key - 2] = arguments[_key];
    }

    for (var i = 0; i < (paths || []).length; i++) {
      if (empty(result[paths[i]])) {
        return defaultValue;
      }

      result = result[paths[i]];
    }

    return result;
  }
});