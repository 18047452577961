define("ember-rs/locales/nl/templates/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = void 0;
  var verify = {
    "advisory": "De afzender van dit document heeft de op kennis gebaseerde verificatiefunctie van RightSignature ingeschakeld om uw identiteit te verifiëren. RightSignature maakt gebruik van de externe services van BlockScore om de verstrekte persoonlijke informatie te valideren en te verifiëren. Het gebruik van de verstrekte persoonlijke informatie is beperkt tot het uitvoeren van de identiteitsvalidatie en -verificatie met de eigen gegevensrecords van BlockScore. De identiteitsvalidatie en -verificatie is niet van invloed op uw kredietrapport, kredietwaardigheid of rapportage aan kredietverstrekkers. Noch de IRS (Amerikaanse belastingdienst), Blockscore noch RightSignature hebben de mogelijkheid om inzicht in of toegang tot uw kredietrapport of uw belastinginformatie te krijgen in het kader van dit proces.",
    "step2": "Identiteitverificatie - Stap 2 van 2",
    "answer": "Beantwoord de identiteitverificatievragen hieronder door het selectievakje naast het juiste antwoord in te schakelen.",
    "noMatch": "De door u gegeven antwoorden komen helaas niet overeen met onze records voor deze identiteit.",
    "youHave": "U hebt nog ",
    "remaining": "pogingen om toegang te krijgen tot het document.",
    "complete": "Verificatie voltooien",
    "return": "Terug naar het formulier",
    "advisory2": "Toelichting bij identiteitverificatie",
    "unableToAuthenticate": "Kan niet correct verifiëren. Neem contact op met <b>{{{sender_email}}}</b> om toegang te krijgen tot het document via een andere verificatiemethode."
  };
  _exports.verify = verify;
});