define("ember-rs/locales/pt-br/mixins/integration-documents-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationDocuments = void 0;
  var integrationDocuments = {
    "enable_sending": "Envio ativado com sucesso para {{{provider}}}",
    "enable_archiving": "Arquivamento ativado com sucesso para {{{provider}}}",
    "enable_contacts": "Contatos ativados com sucesso para {{{provider}}}",
    "enable_payments": "Pagamentos ativados com sucesso para {{{provider}}}",
    "disable_sending": "Envio desativado com sucesso para {{{provider}}}",
    "disable_archiving": "Arquivamento desativado com sucesso para {{{provider}}}",
    "disable_contacts": "Contatos desativados com sucesso para {{{provider}}}",
    "disable_payments": "Pagamentos desativados com sucesso para {{{provider}}}",
    "errorToggle": "Erro. Não foi possível modificar {{{service}}} para {{{provider}}}!",
    "errorRetrieve": "Erro. Não foi possível recuperar o documento.",
    "errorProviderRetrieve": "Erro. Não foi possível recuperar documentos de {{{provider}}}."
  };
  _exports.integrationDocuments = integrationDocuments;
});