define("ember-rs/models/response", ["exports", "@ember-data/model", "ember-rs/mixins/embedded-records-has-many"], function (_exports, _model, _embeddedRecordsHasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_embeddedRecordsHasMany.default, {
    fingerprintData: (0, _model.attr)('object', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    // ipAddress: attr('string'),
    // userAgent: attr('string'),
    status: (0, _model.attr)('string', {
      defaultValue: 'draft'
    }),
    signer: (0, _model.belongsTo)('signer', {
      async: false
    }),
    componentValues: (0, _model.hasMany)('component-value', {
      async: false
    })
  });

  _exports.default = _default;
});