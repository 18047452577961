define("ember-rs/locales/nl/routes/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTransaction = void 0;
  var documentTransaction = {
    "disabledPaymentIntegration": "U moet een betalingsintegratie inschakelen voordat u de volgende documenten verzendt: {{names}}",
    "disabledPasscode": "U moet een wachtwoordcode-PIN instellen om de volgende documenten te verzenden: {{names}}",
    "successfulDocCreation": "De documenten zijn gemaakt."
  };
  _exports.documentTransaction = documentTransaction;
});