define("ember-rs/locales/es/templates/reports", ["exports", "ember-rs/locales/es/templates/reports/export"], function (_exports, _export) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reports = void 0;
  var reports = {
    export: _export.exportTrans,
    "header": "Informes",
    "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
    "select-report-type--text": "Seleccionar tipo de informe",
    "select-report-type-option-overview": "Información general",
    "overviewReport": "Informe general",
    "select-report-type-option-efficiency": "Eficiencia",
    "efficiencyReport": "Informe de eficiencia",
    "select-report-type-option-cycle-time": "Informe de tiempos de ciclos",
    "select-report-type-option-export": "Exportador de datos",
    "select-report-type-option-enterprise": "Enterprise",
    "enterpriseUsageReport": "Informe de uso de la empresa",
    "userUsageReport": "Informe de uso por usuario",
    "select-report-type-option-user": "Nuevo informe de uso",
    "components": {
      "account-document-volume": {
        "header": "Informes",
        "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
        "legend-document-sent": "Documento enviado",
        "legend-document-signed": "Documento firmado",
        "legend": "Leyenda:"
      },
      "account-statistics": {
        "header": "Informes",
        "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
        "column-header-current-month": "Este mes",
        "column-header-previous-month": "Mes pasado",
        "column-header-average": "Promedio",
        "row-header-sent-documents": "Documentos enviados",
        "row-header-viewed": "Visto (por al menos un firmante)",
        "row-header-expired": "Caducados",
        "row-header-signed": "Firmados",
        "row-header-sent-documents-per-user": "Documentos enviados por usuario (promedio)",
        "row-header-signed-documents": "Documentos firmados",
        "row-header-pending-documents": "Documentos pendientes",
        "row-header-viewed-pending": "Visto (de pendientes)",
        "none": "N/A"
      },
      "top-users-by-signed-documents": {
        "header": "Informes",
        "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
        "column-header-user": "Usuario",
        "column-header-signed-documents": "Documentos firmados"
      },
      "account-efficiency": {
        "header": "Informes",
        "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
        "legend-pending": "Pendientes",
        "legend-signed": "Firmados",
        "legend-expired": "Caducados"
      },
      "top-users-by-efficiency": {
        "header": "Informes",
        "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
        "column-header-user": "Usuario",
        "column-header-efficiency-percentage": "Eficiencia (%)",
        "column-header-ratio": "Proporción",
        "over-gcd": "{{signed}} de {{sent}}"
      },
      "top-users-by-time-to-sign": {
        "header": "Informes",
        "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
        "column-header-user": "Usuario",
        "column-header-mean-time": "Tiempo medio (horas)"
      },
      "average-cycle-time": {
        "header": "Informes",
        "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
        "legend-mean-time-to-view": "Tiempo medio de vista",
        "legend-mean-time-to-sign": "Tiempo medio de firma"
      },
      "cycle-time-statistics": {
        "header": "Informes",
        "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
        "column-header-current-month": "Este mes",
        "column-header-previous-month": "Mes pasado",
        "column-header-all-time": "Todo el tiempo",
        "row-header-under-5-mins": "Menos de 5 minutos",
        "row-header-under-30-mins": "Menos de 30 minutos",
        "row-header-under-1-hour": "Menos de 1 hora",
        "row-header-under-12-hours": "Menos de 12 horas",
        "row-header-under-1-day": "Menos de 1 día"
      },
      "month-export": {
        "header": "Informes",
        "description": "Consulte datos de sus documentos enviados. Esto incluye cuánto tiempo tardan en firmarse los documentos normalmente y cuántos documentos se han completado por mes. Actualice su plan para acceder a informes.",
        "export-to-csv-section-header": "Exportar a CSV",
        "export-to-csv-section-description": "Un archivo CSV se puede abrir en Excel para ver los datos referentes a los documentos enviados."
      },
      "generate-download": {
        "ie-warning-text": "Haga clic con el botón secundario en 'Exportar CSV' y elija Guardar como para descargar el archivo CSV.",
        "export-csv-button-text": "Exportar CSV",
        "generate-csv-button-text": "Generar CSV",
        "send-download-link": "Generaremos el informe y enviaremos el enlace de descarga por correo electrónico."
      }
    }
  };
  _exports.reports = reports;
});