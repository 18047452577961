define("ember-rs/locales/en/templates/components/signer/signer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerInfo = void 0;
  var signerInfo = {
    "toSign": "To sign this document please enter your name and email:",
    "name": "Name...",
    "proceed": "Proceed to Document"
  };
  _exports.signerInfo = signerInfo;
});