define("ember-rs/locales/it/templates/signer/sign/locked-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lockedModal = void 0;
  var lockedModal = {
    "title": "Documento bloccato",
    "maxAttempts": "È stato superato il numero massimo di tentativi di verifica dell'identità.",
    "locked": "Il documento è ora bloccato e il mittente è stato avvisato. Contattare il mittente del documento per procedere."
  };
  _exports.lockedModal = lockedModal;
});