define("ember-rs/locales/de/templates/components/salesforce-opportunities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.salesforceOpportunities = void 0;
  var salesforceOpportunities = {
    "searchOpportunities": "Verkaufschancen suchen...",
    "searchError": "Fehler beim Suchen nach Verkaufschancen: {{{resp}}}"
  };
  _exports.salesforceOpportunities = salesforceOpportunities;
});