define("ember-rs/locales/pt-br/templates/onboarding/send-onboarding-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding4 = void 0;
  var sendOnboarding4 = {
    "close": "Selecione a <span>seta para trás</span> para fechar estes detalhes e ir para o seu painel Documentos"
  };
  _exports.sendOnboarding4 = sendOnboarding4;
});