define("ember-rs/components/import-overlay-preview-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':component', 'roleIndex'],
    attributeBindings: ['style'],
    // NOTE: possible that the sorting order is not the same every call/browser
    // but seems to be working as of now
    roleIndex: Ember.computed('documentTemplate.roles.[]', 'component.assignedTo', function () {
      return "signer_".concat(this.get('documentTemplate.roles').mapBy('name').indexOf(this.get('component.assignedTo')) + 1);
    }),
    style: Ember.computed('pageWidth', 'pageHeight', 'component.x', 'component.y', 'component.width', 'component.height', function () {
      var width = this.get('component.width') * this.pageWidth;
      var height = this.get('component.height') * this.pageHeight;
      var x = this.get('component.x') * this.pageWidth;
      var y = this.get('component.y') * this.pageHeight;
      return "width: ".concat(width, "px; height: ").concat(height, "px; top: ").concat(y, "px; left: ").concat(x, "px;").htmlSafe();
    })
  });

  _exports.default = _default;
});