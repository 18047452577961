define("ember-rs/locales/nl/templates/components/client-applications/details-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.detailsPanel = void 0;
  var detailsPanel = {
    "clientId": "CLIENT-ID:",
    "clientSecret": "CLIENTGEHEIM:",
    "lastViewed": "Laatst weergegeven: ",
    "fromIp": "van IP",
    "hidden": "Verborgen ter beveiliging",
    "reveal": "Geheim onthullen",
    "privateToken": "PRIVÉ API-TOKEN:",
    "revealToken": "Token onthullen",
    "regenerate": "Token opnieuw genereren. WAARSCHUWING: dit is een onomkeerbare actie",
    "description": "BESCHRIJVING:",
    "scope": "BEREIK(EN):",
    "redirectUri": "OMLEIDINGS-URI:",
    "applicationUrl": "TOEPASSINGS-URL:",
    "orgUrl": "ORGANISATIE-URL:",
    "createdAt": "GEMAAKT OP:",
    "updatedAt": "BIJGEWERKT OP:",
    "edit": "bewerken"
  };
  _exports.detailsPanel = detailsPanel;
});