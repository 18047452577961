define("ember-rs/locales/it/templates/components/hybris-trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialModal = void 0;
  var hybrisTrialModal = {
    "ohNo": "Oh no!",
    "reminder": "Solo un promemoria:",
    "previewHasEnded": "Il periodo di anteprima della versione più recente di RightSignature è terminato.",
    "freeTrialEnds": "La prova gratuita termina tra {{trialDaysRemaining}} giorni.",
    "needToGetThingsSigned": "È ancora necessario far firmare documenti? I prezzi dei piani aziendali rinnovati di RightSignature sono pensati per offrire più valore.",
    "exploreAdminFeatures": "Esplora le funzionalità avanzate di amministrazione e sicurezza",
    "retainDocuments": "Conserva i documenti e la configurazione dell'account della versione originale",
    "easySetup": "Configurazione semplice, nessun contratto, possibilità di annullare in qualsiasi momento",
    "saveTimeAndPaper": "Risparmia un'ora o più di lavoro dedicata a documenti cartacei ogni settimana",
    "choosePlan": "Scegli un piano per continuare",
    "needAssitance": "È necessaria assistenza per la valutazione?",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>Contattare il supporto</a>",
    "notSureYet": "Si è ancora indecisi? Ecco cosa può fare RightSignature per le aziende.",
    "logout": "Scollega"
  };
  _exports.hybrisTrialModal = hybrisTrialModal;
});