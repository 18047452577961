define("ember-rs/locales/ja/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitlementOptionsAdmin = void 0;
  var entitlementOptionsAdmin = {
    "title": "RightSignature へようこそ!",
    "hasSeats": "現在、このアカウントの管理者権限を持っているため、自分自身や他のユーザーに PRO アクセスや機能を許可することができます。自身にアクセスを許可するには、以下のボタンをクリックします。",
    "notNow": "後で",
    "entitleMe": "PRO アクセスを取得する",
    "noSeats": "現在、このアカウントのすべてのシートを使用中です。使用中のユーザーを削除するか、<a href='https://support.citrix.com/cscase'>サポートに連絡</a>、または<a href='tel:+18009214250'>1-800-921-4250</a>に電話してシート数を増やすことができます。または、一部機能が制限された状態で続行できます。",
    "proceed": "続行"
  };
  _exports.entitlementOptionsAdmin = entitlementOptionsAdmin;
});