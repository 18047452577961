define("ember-rs/templates/components/overlay-text-annotation-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TSPxP4li",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"component-content-wrapper\"],[12],[2,\"\\n  \"],[8,\"textarea\",[[16,\"placeholder\",[30,[36,1],[\"components.overlayTextAnnotationComponent.enterText\"],null]],[16,0,[34,0,[\"textAlignClass\"]]]],[[\"@value\"],[[34,0,[\"value\"]]]],null],[2,\"\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/overlay-text-annotation-component.hbs"
    }
  });

  _exports.default = _default;
});