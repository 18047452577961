define("ember-rs/routes/reusable-template/edit", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* globals Bugsnag */
  var _default = Ember.Route.extend({
    titleToken: 'Edit Reusable Template',
    savingNewVersion: false,
    actions: {
      saveNewVersion: function saveNewVersion() {
        var self = this;
        this.set('savingNewVersion', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/reusable_templates/").concat(this.currentModel.get('id'), "/new_version.json"),
          data: {
            reusable_template: {
              upload_id: this.currentModel.get('upload.id')
            }
          },
          type: 'POST',
          success: function success(data) {
            self.transitionTo('reusable-template.add_roles', data.reusable_template.id);
          },
          error: function error(exception) {
            self.set('savingNewVersion', false);
            Bugsnag.notifyException(exception);
          }
        });
      }
    }
  });

  _exports.default = _default;
});