define("ember-rs/locales/pt-br/templates/onboarding/post-sign-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postSignOnboarding1 = void 0;
  var postSignOnboarding1 = {
    "tapGear": "<span>Toque na engrenagem</span> para baixar o documento e informações adicionais.",
    "viewDoc": "Aqui é onde você pode ver o <span>status</span> do documento"
  };
  _exports.postSignOnboarding1 = postSignOnboarding1;
});