define("ember-rs/locales/ja/templates/components/reminder-frequency-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reminderFrequency = void 0;
  var reminderFrequency = {
    "noAuto": "自動通知なし",
    "daily": "毎日",
    "weekly": "毎週"
  };
  _exports.reminderFrequency = reminderFrequency;
});