define("ember-rs/locales/nl/templates/unauthorized/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expired = void 0;
  var expired = {
    "linkExpired": "Documentkoppeling verlopen",
    "toDownload": "Vraag een nieuwe downloadkoppeling aan om dit document te downloaden.",
    "sendLink": "Nieuwe downloadkoppeling verzenden"
  };
  _exports.expired = expired;
});