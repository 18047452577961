define("ember-rs/routes/user/settings/delete-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('signature', params.signatureId);
    },
    actions: {
      closeModal: function closeModal() {
        this.transitionTo('user.settings');
      }
    }
  });

  _exports.default = _default;
});