define("ember-rs/locales/it/templates/components/signer/signer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerInfo = void 0;
  var signerInfo = {
    "toSign": "Per firmare questo documento, inserire il nome e l'indirizzo e-mail:",
    "name": "Nome...",
    "proceed": "Procedere al documento"
  };
  _exports.signerInfo = signerInfo;
});