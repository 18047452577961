define("ember-rs/locales/en/templates/client-application/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTranslations = void 0;
  var newTranslations = {
    "request_key": "Request New API Key",
    "name_tool_tip": "This is displayed to users if you use OAuth to request access to their account.  E.g. Industry Automative Estimates Generator or WordStar Plugin",
    "name": "Name:",
    "org_name_tip": "  Your organization's name",
    "org_name": "Organization Name:",
    "description_tip": "This is displayed to users if you use OAuth to request access to their account. E.g. 'This application is solely used by Industry Automotive to send estimates within our Enterprise workflow' or 'WordStar Plugin allows WordStar users to send documents for signature through their RightSignature&trade; account'.",
    "description": "Description:",
    "scopes_tip": "  Desired level of access",
    "scopes": "Scope(s):",
    "app_url_tip": "This is displayed to users if you use OAuth to request access to their account. E.g. http://industryauto.com/login or http://wordstarsupportgroup.com/theplugin",
    "app_url": "Application URL:",
    "redirect_url_tip": "This is the default callback for oauth authorization requests. It can be specified or overridden in the API calls. E.g. http://industryauto.com/oauth_callback",
    "redirect_url": "Redirect URL:",
    "org_url_tip": "  (optional) Your organization's website",
    "org_url": "Organization URL:",
    "Request": "Request",
    "readOnlyAccess": "Read Only access",
    "readWriteAccess": "Read and Write access"
  };
  _exports.newTranslations = newTranslations;
});