define("ember-rs/components/salesforce-opportunities", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    loading: false,
    loadingMessage: 'Searching Opportunities',
    salesforceOpportunities: Ember.A([]),
    flashMessages: Ember.inject.service(),
    actions: {
      searchSalesforceOpportunities: function searchSalesforceOpportunities(query) {
        var self = this;
        self.set('loading', true);

        _jquery.default.ajax({
          type: 'POST',
          url: "".concat(_environment.default.api.endpoint, "/integrations/salesforce/opportunities"),
          data: {
            query: query
          }
        }).done(function (resp) {
          self.setProperties({
            salesforceOpportunities: resp.opportunities,
            loading: false
          });
        }).fail(function (resp) {
          self.get('flashMessages').warning(self.get('i18n').t('components.salesforceOpportunities.searchError', {
            resp: JSON.stringify(resp)
          }));
          self.set('loading', false);
        });
      },
      addSalesforceOpportunity: function addSalesforceOpportunity(opportunity) {
        var opportunityTag = {
          name: 'SalesforceOpportunity',
          id: opportunity.Id
        };
        this.setProperties({
          showSalesforceModal: false,
          salesforceOpportunities: Ember.A([]),
          salesforceOpportunityQuery: "".concat(opportunity.Name, "-").concat(opportunity.Id)
        });
        this.sendAction('addSalesforceOpportunityTag', opportunityTag);
      }
    }
  });

  _exports.default = _default;
});