define("ember-rs/locales/fr/templates/document/prepare-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_document = void 0;
  var prepare_document = {
    "prepare": "Préparer ",
    "prepare_package": "Préparer paquet de documents",
    "merge_field_message": "Ce document contient des champs de fusion à remplir avant l'envoi.",
    "merge_field_title": "Remplir les champs de fusion",
    "missing_merge_fields": "Tous les champs de fusion requis doivent être complétés."
  };
  _exports.prepare_document = prepare_document;
});