define("ember-rs/locales/es/templates/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateRow = void 0;
  var reusableTemplateRow = {
    "send": "Enviar",
    "details": "Detalles",
    "trialmessageComp": "Su plan de prueba actual solo le permite enviar {{{documentLimit}}} documentos. ",
    "planmessageComp": "Su plan actual solo le permite enviar {{{documentLimit}}} documentos. ",
    "upgrade": "Póngase en contacto con nosotros para actualizar su cuenta."
  };
  _exports.reusableTemplateRow = reusableTemplateRow;
});