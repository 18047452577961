define("ember-rs/locales/de/templates/signer/sign/locked-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lockedModal = void 0;
  var lockedModal = {
    "title": "Dokument ist gesperrt",
    "maxAttempts": "Sie haben die maximale Anzahl an Versuchen zur Bestätigung Ihrer Identität überschritten.",
    "locked": "Das Dokument ist jetzt gesperrt und der Absender wurde benachrichtigt. Bitte kontaktieren Sie den Absender des Dokuments, um fortzufahren."
  };
  _exports.lockedModal = lockedModal;
});