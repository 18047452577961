define("ember-rs/locales/zh-cn/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "title": "服务条款",
    "body": "通过使用 RightSignature&trade;，您证明自己已阅读并同意我们的{{partial 'terms-of-use-link'}}和<a href='{{csgHomePage}}/privacy-policy' target='_blank'>隐私政策</a>。",
    "noThanks": "否，谢谢",
    "iAgree": "我同意"
  };
  _exports.agreeToTerms = agreeToTerms;
});