define("ember-rs/locales/es/templates/components/template", ["exports", "ember-rs/locales/es/templates/components/template/template-signer-link-item"], function (_exports, _templateSignerLinkItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    templateSignerLinkItem: _templateSignerLinkItem.templateSignerLinkItem
  };
  _exports.template = template;
});