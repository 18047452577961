define("ember-rs/locales/de/templates/components/signature-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signatureField = void 0;
  var signatureField = {
    "rotateToSign": "Zum Signieren drehen.",
    "rotateTip": "Sie müssen u. U. die Bildschirmausrichtung Ihres Geräts entsperren.",
    "clear": "Entfernen",
    "signInstn": "Klicken und ziehen, um die Signatur zu zeichnen",
    "typeUrSign": "Geben Sie Ihre Signatur ein",
    "typeSign": "Signatur eingeben",
    "drawSign": "Signatur zeichnen",
    "or": "oder",
    "savedSign": "Gespeicherte Signatur verwenden",
    "apply": "Anwenden"
  };
  _exports.signatureField = signatureField;
});