define("ember-rs/locales/zh-cn/templates/components/rs-left-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsLeftNav = void 0;
  var rsLeftNav = {
    "Documents": "文档",
    "Templates": "模板",
    "Account": "帐户",
    "Settings": "设置",
    "Integrations": "集成",
    "Branding": "外观方案",
    "Users": "用户",
    "Reports": "报告",
    "Api": "API",
    "Billing": "账单",
    "Workflows": "反馈与审批"
  };
  _exports.rsLeftNav = rsLeftNav;
});