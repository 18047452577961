define("ember-rs/locales/it/templates/components/rs-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rsToggle = void 0;
  var rsToggle = {
    "on": "attivato",
    "off": "disattivato"
  };
  _exports.rsToggle = rsToggle;
});