define("ember-rs/locales/zh-cn/controllers/document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "notAllowed": "不允许您执行该操作。",
    "voided": "已成功使文档失效。",
    "voidingFailed": "无法使文档失效。",
    "docExtended": "文档已成功延长期限。",
    "tagUpdated": "已成功更新标记",
    "tagUpdateFailed": "无法更新标记: {{{tags}}} ",
    "viewerListUpdated": "已成功更新查看者列表",
    "viewerUpdateFailed": "无法更新查看者列表: {{{viewers}}}"
  };
  _exports.details = details;
});