define("ember-rs/initializers/i18n-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    ['model', 'route', 'controller', 'component'].forEach(function (type) {
      application.inject(type, 'i18n', 'service:i18n');
    });
  }

  var _default = {
    name: 'i18n-service',
    after: 'ember-i18n',
    initialize: initialize
  };
  _exports.default = _default;
});