define("ember-rs/locales/de/templates/signer/sign/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verify = void 0;
  var verify = {
    "advisory": "Der Absender dieses Dokuments hat die wissensbasierte Authentifizierung von RightSignature aktiviert, um Ihre Identität zu überprüfen. RightSignature verwendet den Drittanbieterdienst BlockScore, um die bereitgestellten persönlichen Informationen zu überprüfen und zu bestätigen. Die Verwendung der bereitgestellten persönlichen Informationen ist auf die Identitätsprüfung und -bestätigung mit den Datensätzen von BlockScore beschränkt. Die Identitätsprüfung und -bestätigung hat keine Auswirkungen auf Ihre Kreditauskunft, Kreditwürdigkeit oder Berichterstattung an Kreditgeber. Weder die IRS (Bundessteuerbehörde der USA) noch BlockScore oder RightSignature haben die Möglichkeit, Ihren Kreditbericht oder Ihre Steuerinformationen als Teil dieses Prozesses einzusehen oder darauf zuzugreifen.",
    "step2": "Identitätsprüfung - Schritt 2 von 2",
    "answer": "Beantworten Sie die unten stehenden Fragen zur Identitätsprüfung, indem Sie das Kontrollkästchen neben der entsprechenden Antwort aktivieren.",
    "noMatch": "Leider stimmen die von Ihnen gegebenen Antworten nicht mit unseren Daten für diese Identität überein.",
    "youHave": "Sie haben noch ",
    "remaining": "Versuche, um auf das Dokument zuzugreifen.",
    "complete": "Verifizierung ausführen",
    "return": "Zurück zum Formular",
    "advisory2": "Hinweis zur Identitätsprüfung",
    "unableToAuthenticate": "Erfolgreiche Authentifizierung nicht möglich. Wenden Sie sich an <b>{{{sender_email}}}</b>, um über einen anderen Authentifizierungsmechanismus auf das Dokument zuzugreifen."
  };
  _exports.verify = verify;
});