define("ember-rs/locales/de/templates/unauthorized/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expired = void 0;
  var expired = {
    "linkExpired": "Dokumentlink abgelaufen",
    "toDownload": "Fordern Sie einen neuen Downloadlink an, um dieses Dokument herunterzuladen.",
    "sendLink": "Neuen Downloadlink senden"
  };
  _exports.expired = expired;
});