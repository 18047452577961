define("ember-rs/locales/en/templates/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateSelector = void 0;
  var reusableTemplateSelector = {
    "noTemplates": "You don't have any templates yet.",
    "templateDesc": "Create reusable templates from your frequently used documents. Send fast, embed in your site, or mass distribute.",
    "createNew": "Create new template",
    "chooseTemplate": "Choose a template",
    "loading": "Loading...",
    "loadMore": "load more templates...",
    "upgrade": "Upgrade your account to use templates",
    "upgradeAccount": "Upgrade Account",
    "bulkSendInfo": "Bulk send only works with a single signer template. New to bulk send?",
    "learnMore": "Learn More",
    "searchTemplate": "Search templates",
    "noResults": "No results !"
  };
  _exports.reusableTemplateSelector = reusableTemplateSelector;
});