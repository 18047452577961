define("ember-rs/locales/it/templates/components/document/signer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerRow = void 0;
  var signerRow = {
    "pendingIdentityAuthentication": "In attesa di autenticazione dell'identità",
    "sign": "Firma",
    "disabledReminder": "Questo firmatario ha disabilitato la ricezione di ulteriori promemoria per questo documento",
    "threshold": "È stata superata la soglia di invio di promemoria (3 per documento)",
    "signerFailed": "This signer has failed ",
    "currentlyLocked": "and is currently locked out of the document.",
    "signerLocked": "Questo firmatario non è riuscito a eseguire l'autenticazione basata sulla conoscenza e attualmente non può più accedere al documento.",
    "remind": "Invia promemoria",
    "resend": "Invia di nuovo",
    "createLink": "Crea collegamento",
    "copyLink": "Copia collegamento",
    "copyLinkSuccess": "Collegamento copiato negli Appunti",
    "copyLinkError": "Impossibile copiare il collegamento negli Appunti",
    "signerAuthFailed": "Impossibile autenticare il firmatario tramite sessione: {{{error}}}",
    "reminderSuccess": "Promemoria inviato a {{{name}}}",
    "reminderFailed": "Impossibile generare un promemoria per {{{name}}}",
    "generateLinkFailed": "Impossibile generare il collegamento del firmatario: {{{error}}}",
    "resendIdentityVerification": "Verifica dell'identità inviata nuovamente",
    "resendingFailed": "Impossibile inviare nuovamente la verifica dell'identità: {{{error}}}"
  };
  _exports.signerRow = signerRow;
});