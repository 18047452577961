define("ember-rs/components/filetype-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var contentTypeMap = {
    'application/pdf': 'pdf',
    'application/doc': 'word',
    'application/docx': 'word'
  };

  var _default = Ember.Component.extend({
    layoutName: Ember.computed('contentType', function () {
      var templateName = contentTypeMap[this.contentType] || 'file';
      return "filetype-icon/".concat(templateName);
    })
  });

  _exports.default = _default;
});