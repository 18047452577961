define("ember-rs/models/signature-annotation-component", ["exports", "ember-rs/models/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    // Follows implementation in app/models/select-components.js
    // See:  app/models/component.js, line 138
    isValid: Ember.computed('isValidSignatureAnnotation', function () {
      return this._super() && this.isValidSignatureAnnotation;
    }),
    isValidSignatureAnnotation: Ember.computed('signature.jsonData', 'signature.signatureText', function () {
      return Ember.isPresent(this.get('signature.jsonData')) || Ember.isPresent(this.get('signature.signatureText'));
    })
  });

  _exports.default = _default;
});