define("ember-rs/locales/fr/templates/components/user-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userFields = void 0;
  var userFields = {
    "name": "nom",
    "email": "e-mail",
    "editInfo": "Modifier les informations"
  };
  _exports.userFields = userFields;
});