define("ember-rs/locales/nl/templates/components/delete-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteSignature = void 0;
  var deleteSignature = {
    "deleteSuccess": "De handtekening is verwijderd.",
    "deleteFailed": "Fout bij het verwijderen van de handtekening."
  };
  _exports.deleteSignature = deleteSignature;
});