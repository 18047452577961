define("ember-rs/components/toggle-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: [':toggle-switch', '_toggled:on:off', 'small', 'extraSmall', 'disabled', ':theme__toolbar__icon', '_toggled:theme__toolbar__icon--is-toggled'],
    // Public API
    enabled: true,
    small: false,
    extraSmall: false,
    toggleItem: false,
    // Private API
    _toggleItem: false,
    labelText: Ember.computed('_toggled', function () {
      return this._toggled ? 'on' : 'off';
    }),
    disabled: Ember.computed.not('enabled'),
    _toggled: Ember.computed('toggled', function () {
      return this.toggled;
    }),
    click: function click() {
      if (this.enabled && !this.disabled) {
        var newValue = this.toggleProperty('_toggled');
        this.sendAction('toggleAction', newValue, this);
      }
    }
  });

  _exports.default = _default;
});