define("ember-rs/locales/es/routes/document-transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentTransaction = void 0;
  var documentTransaction = {
    "disabledPaymentIntegration": "Debe habilitar una integración de pago antes de enviar los siguientes documentos: {{names}}",
    "disabledPasscode": "Debe establecer un PIN de código de acceso para enviar los siguientes documentos: {{names}}",
    "successfulDocCreation": "Documentos creados correctamente."
  };
  _exports.documentTransaction = documentTransaction;
});