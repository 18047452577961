define("ember-rs/services/overlayer-data", ["exports", "jquery", "ember-rs/mixins/component", "ember-rs/mixins/component-dimensions"], function (_exports, _jquery, _component, _componentDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_component.default, _componentDimensions.default, {
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    componentClasses: [{
      className: 'signatureComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'inPerson', 'packager'],
      type: 'request'
    }, {
      className: 'textComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'inPerson', 'packager'],
      type: 'request'
    }, {
      className: 'dateComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'inPerson', 'packager'],
      type: 'request'
    }, {
      className: 'checkComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'inPerson', 'packager'],
      type: 'request'
    }, {
      className: 'checkGroupComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'inPerson', 'packager'],
      type: 'request'
    }, {
      className: 'initialsComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'inPerson', 'packager'],
      type: 'request'
    }, {
      className: 'selectComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'inPerson', 'packager'],
      type: 'request'
    }, {
      className: 'attachmentComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'packager'],
      type: 'request'
    }, {
      className: 'paymentComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'packager'],
      type: 'request'
    }, {
      className: 'signatureAnnotationComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'selfSign', 'packager'],
      type: 'annotation'
    }, {
      className: 'textAnnotationComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'selfSign', 'packager'],
      type: 'annotation'
    }, {
      className: 'checkmarkAnnotationComponent',
      availableFor: ['document', 'reusableTemplate', 'signatureRequest', 'selfSign', 'packager'],
      type: 'annotation'
    }],
    selectedComponents: Ember.A(),
    selectedComponentData: {},
    selectedComponentIndex: -1,
    isSourceMapped: false,
    componentEditorClicked: false,
    selectedComponentClass: 'textComponent',
    showToolbar: true,
    showRectDetectGhost: true,
    updateSelectedComponentsCtr: 0,
    isDragging: false,
    isScrolling: false,
    isResizing: false,
    isHovering: false,
    isSelecting: false,
    mouseCoordinates: {
      x: -100,
      y: -100
    },
    tempToolDimensions: {
      width: null,
      height: null
    },
    withinGutter: false,
    // ghostComponent is set via rect-detectable-page
    componentBorderWidth: 1,
    componentsAreSelected: Ember.computed.notEmpty('selectedComponents'),
    canBrowserRectDetect: Ember.computed(function () {
      return typeof Array.from === 'function';
    }),
    useRectDetect: Ember.computed('currentUser.model.useRectDetect', 'canBrowserRectDetect', {
      get: function get() {
        return this.canBrowserRectDetect && this.get('currentUser.model.useRectDetect');
      },
      set: function set(key, value) {
        if (this.canBrowserRectDetect) {
          if (this.get('currentUser.model')) {
            this.set('currentUser.model.useRectDetect', value);
          }

          return value;
        }

        return false;
      }
    }),
    singleComponentSelected: Ember.computed.equal('selectedComponents.length', 1),
    multiComponentSelected: Ember.computed.gt('selectedComponents.length', 1),
    noComponentSelected: Ember.computed.equal('selectedComponents.length', 0),
    selectedComponent: Ember.computed.alias('selectedComponents.firstObject'),
    showToolPreview: Ember.computed('isDragging', 'isResizing', 'isHovering', 'isSelecting', 'useRectDetect', 'componentsAreSelected', 'selectedComponentClass', function () {
      return !this.isDragging && !this.isResizing && !this.isHovering && !this.isSelecting && !this.useRectDetect && !this.componentsAreSelected && this.selectedComponentClass !== 'textAnnotationComponent';
    }),
    // Which page the selected components are on
    selectedComponentsPage: Ember.computed('selectedComponents.[]', function () {
      if (this.noComponentSelected) {
        return 0;
      }

      return this.selectedComponents[0].get('page');
    }),
    selectedComponentsBounds: Ember.computed('selectedComponents.[]', 'selectedComponents.@each.x', 'selectedComponents.@each.y', 'selectedComponents.@each.width', 'updateSelectedComponentsCtr', 'isDragging', function () {
      var bounds = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: 0,
        width: 0
      };
      var self = this;
      this.selectedComponents.forEach(function (component, i) {
        var componentDimensions = self.getComponentDimensions(component);
        var cTop = componentDimensions.y;
        var cBottom = componentDimensions.y + componentDimensions.height;
        var cLeft = componentDimensions.x;
        var cRight = componentDimensions.x + componentDimensions.width;

        if (i === 0) {
          bounds.top = cTop;
          bounds.left = cLeft;
        }

        if (cTop < bounds.top) {
          bounds.top = cTop;
        }

        if (cBottom > bounds.bottom) {
          bounds.bottom = cBottom;
        }

        if (cLeft < bounds.left) {
          bounds.left = cLeft;
        }

        if (cRight > bounds.right) {
          bounds.right = cRight;
        }

        bounds.width = bounds.right - bounds.left;
        bounds.height = bounds.bottom - bounds.top;
      });
      return Ember.Object.create({
        x: bounds.left,
        y: bounds.top,
        width: bounds.width,
        height: bounds.height,
        scaledX: bounds.left * this.get('documentViewerData.viewerScale'),
        scaledY: bounds.top * this.get('documentViewerData.viewerScale'),
        scaledWidth: bounds.width * this.get('documentViewerData.viewerScale'),
        scaledHeight: bounds.height * this.get('documentViewerData.viewerScale')
      });
    }),
    clearGhost: function clearGhost() {
      this.set('ghostComponent', null);
    },
    eventPosition: function eventPosition(evt) {
      var x = evt.offsetX || evt.clientX - (0, _jquery.default)(evt.target).offset().left;
      var y = evt.offsetY || evt.clientY - (0, _jquery.default)(evt.target).offset().top;
      return [parseInt(x), parseInt(y)];
    },
    deselectComponents: function deselectComponents() {
      if (this.componentsAreSelected) {
        this.set('selectedComponents', Ember.A());
        (0, _jquery.default)('.ui-selected').removeClass('ui-selectee ui-selected');
        (0, _jquery.default)('.text-annotation-component textarea:focus').blur();
      }
    },
    selectComponents: function selectComponents(components) {
      (0, _jquery.default)('.ui-selected').removeClass('ui-selectee ui-selected');
      this.set('selectedComponents', components);
      var selectedComponentsIds = this.selectedComponents.mapBy('id');
      (0, _jquery.default)('.component').filter(function () {
        return selectedComponentsIds.includes((0, _jquery.default)(this).data('id'));
      }).addClass('ui-selectee ui-selected');
    },
    deleteSelectedComponents: function deleteSelectedComponents() {
      if (!this.noComponentSelected) {
        this.selectedComponents.forEach(function (component) {
          component.deleteRecord();
          component.unloadRecord();
        });
        this.set('selectedComponents', Ember.A());
        this.deselectComponents();
      }
    },
    updateSelectedComponents: function updateSelectedComponents() {
      this.set('updateSelectedComponentsCtr', this.updateSelectedComponentsCtr + 1);
    },
    resetSelectedData: function resetSelectedData() {
      // Need to do this because for documents with only 1 role, this option isn't exposed and will carry over previous assigned to.
      this.set('selectedAssignedTo', null);
    }
  });

  _exports.default = _default;
});