define("ember-rs/locales/ja/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding1 = void 0;
  var accountOnboarding1 = {
    "body": "<span>ユーザー設定</span>を変更し、アカウントを<span>ブランドでカスタマイズ</span>し、他のツールなどを<span>統合</span>します。"
  };
  _exports.accountOnboarding1 = accountOnboarding1;
});