define("ember-rs/locales/zh-cn/templates/onboarding/signer-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding1 = void 0;
  var signerOnboarding1 = {
    "redFlag": "<span>红色标志</span>指向需要填写的区域。",
    "zoom": "在您的手机上双指开合和缩放可放大文档。"
  };
  _exports.signerOnboarding1 = signerOnboarding1;
});