define("ember-rs/locales/fr/templates/components/overlay-text-annotation-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.overlayTextAnnotationComponent = void 0;
  var overlayTextAnnotationComponent = {
    "enterText": "Entrez du texte"
  };
  _exports.overlayTextAnnotationComponent = overlayTextAnnotationComponent;
});