define("ember-rs/locales/pt-br/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareNextRoute = void 0;
  var prepareNextRoute = {
    "mergeFieldStepNext": "Seguinte: Campos de mesclagem",
    "documentStepNext": "Próximo documento",
    "reviewStepNext": "Seguinte: Revisão",
    "placeFieldNext": "Seguinte: Posicionar campos"
  };
  _exports.prepareNextRoute = prepareNextRoute;
});