define("ember-rs/locales/en/templates/unauthorized/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.expired = void 0;
  var expired = {
    "linkExpired": "Document Link Expired",
    "toDownload": "To download this document request a new download link.",
    "sendLink": "Send New Download Link"
  };
  _exports.expired = expired;
});