define("ember-rs/components/rs-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'rs-checkbox',
    classNameBindings: ['isChecked:is-checked', 'isDisabled:is-disabled'],
    inputId: Ember.computed(function () {
      return "".concat(this.elementId, "Input");
    }),
    actions: {
      toggleCheck: function toggleCheck() {
        this.toggleProperty('isChecked');
      }
    }
  });

  _exports.default = _default;
});