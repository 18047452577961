define("ember-rs/locales/zh-cn/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pendingIdentityVerification = void 0;
  var pendingIdentityVerification = {
    "pendingIdentityVerification": "待执行的身份验证",
    "success": "您已成功对此文档签名。需要验证您的身份才能完成此文档并将其返回给发件人。要执行此操作，只需检查您的电子邮件，然后单击 RightSignature 发送给您的“验证电子邮件”链接即可。",
    "resend": "重新发送身份验证"
  };
  _exports.pendingIdentityVerification = pendingIdentityVerification;
});