define("ember-rs/locales/zh-cn/mixins/prepare-next-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareNextRoute = void 0;
  var prepareNextRoute = {
    "mergeFieldStepNext": "下一步: 合并字段",
    "documentStepNext": "下一个文档",
    "reviewStepNext": "下一步: 检查",
    "placeFieldNext": "下一步: 放置字段"
  };
  _exports.prepareNextRoute = prepareNextRoute;
});