define("ember-rs/locales/en/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFree = void 0;
  var newUserOnboardingFree = {
    "title": "Welcome to RightSignature!",
    "body": "You currently only have basic access to RightSignature™. This enables you to self-sign documents as well as view your past documents. To access the PRO features, contact an Admin for your RightSignature™ account."
  };
  _exports.newUserOnboardingFree = newUserOnboardingFree;
});