define("ember-rs/locales/pt-br/templates/unauthorized/unconfirmed-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedEmail = void 0;
  var unconfirmedEmail = {
    "loginFailed": "Falha no login",
    "loginFailedTip": "Seu e-mail de conta do ShareFile deve ser confirmado primeiro. Consulte seu email para obter um link de confirmação do ShareFile."
  };
  _exports.unconfirmedEmail = unconfirmedEmail;
});