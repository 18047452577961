define("ember-rs/locales/it/templates/email-unverified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailUnverified = void 0;
  var emailUnverified = {
    "verify": "L'indirizzo e-mail non è stato verificato. Controllare l'e-mail e fare clic sul collegamento di verifica per confermare l'account."
  };
  _exports.emailUnverified = emailUnverified;
});