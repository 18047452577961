define("ember-rs/locales/zh-cn/templates/onboarding/send-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding2 = void 0;
  var sendOnboarding2 = {
    "status": "您可以在此处看到文档的<span>状态</span>"
  };
  _exports.sendOnboarding2 = sendOnboarding2;
});