define("ember-rs/locales/en/templates/components/import-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.importOverlay = void 0;
  var importOverlay = {
    "importFrom": "Import fields from:",
    "toImport": "component(s) to import from",
    "page": "page(s)",
    "cancel": "Cancel",
    "import": "Import",
    "noComponents": "No components to import!",
    "pastDocs": "Import fields from past documents",
    "noMatch": "No documents found that match this name",
    "importSuccess": "Successfully imported {{{components}}} components."
  };
  _exports.importOverlay = importOverlay;
});