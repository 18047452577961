define("ember-rs/templates/components/sign-document-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "flITFBo2",
    "block": "{\"symbols\":[\"component\",\"component\"],\"statements\":[[10,\"div\"],[14,0,\"page\"],[15,5,[30,[36,6],[[35,5]],null]],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"signing-component\",[],[[\"@component\",\"@valueObjectName\",\"@sortedComponentsToFillIndexMap\",\"@valueChanged\",\"@componentClicked\",\"@componentUpdated\",\"@signatureCreated\",\"@previousSignature\",\"@signatureColor\"],[[32,2],[34,0],[34,1],\"componentValueChanged\",\"componentClicked\",\"componentUpdated\",\"signatureCreated\",[34,2],[30,[36,4],[[35,3]],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,10]],null]],null]],[[\"key\"],[\"id\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"flattened-component\",[],[[\"@component\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[10,\"img\"],[14,0,\"page-image\"],[15,\"src\",[34,11,[\"original\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"valueObjectName\",\"sortedComponentsToFillIndexMap\",\"previousSignature\",\"signatureColor\",\"readonly\",\"pageCssStyle\",\"safe\",\"components\",\"-track-array\",\"each\",\"flattenedComponents\",\"pageImage\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/sign-document-page.hbs"
    }
  });

  _exports.default = _default;
});