define("ember-rs/locales/fr/templates/onboarding/signer-onboarding-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboardingOld = void 0;
  var signerOnboardingOld = {
    "title": "Signer un document avec RightSignature&trade; est très simple !",
    "completefields": "Remplissez les champs marqués d'un indicateur.",
    "submitSignature": "Remplissez les champs et envoyez votre signature.",
    "progress": "Affichez l'état d'avancement grâce à la barre de progression. Une fois que tous les champs requis ont été remplis, cliquez sur le bouton Envoyer signature pour terminer le processus de signature.",
    "help": "Besoin d'aide ?",
    "questions": "Si vous avez une question ou besoin de plus d'informations, demandez de l'aide ici."
  };
  _exports.signerOnboardingOld = signerOnboardingOld;
});