define("ember-rs/components/contacts-typeahead", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var SPECIAL_KEYS = {
    13: 'enter',
    27: 'escape',
    38: 'up',
    40: 'down'
  };
  /* Example Endpoints
  endpoints: [
    { name: 'Recent Contacts', url: '/contacts', key: 'local' },
    { name: 'Google Contacts', url: '/integrations/google_contacts/contacts', key: 'google_contacts' }
  ]
  */

  var _default = Ember.Component.extend({
    //////////////////     PUBLIC API    ////////////////////////
    minimum: 2,
    showHeader: true,
    hasSecondary: false,
    resultsMaxHeight: 250,
    showNoResults: true,
    selectOnMatch: false,
    disabled: false,
    endpoints: Ember.computed(function () {
      return Ember.A();
    }),
    //////////////////    PRIVATE API    ////////////////////////
    showing: false,
    term: '',
    //////////////////      SERVICES    ////////////////////////
    currentUser: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    //////////////////    PROPERTIES    ////////////////////////
    hasMinimum: Ember.computed('term', 'minimum', function () {
      return Ember.get(this, 'term.length') >= this.minimum;
    }),
    entryResults: Ember.computed('results.@each.type', function () {
      return Ember.A(this.results).filter(function (result) {
        return result.type === 'entry';
      });
    }),
    typeaheadInlineStyle: Ember.computed(function () {
      var $input = this.$('input');

      if (!$input.length) {
        return;
      }

      $input = $input.first();
      var top = $input.offset().top;
      var maxHeight = this.resultsMaxHeight;
      var height = Ember.get(this, 'layoutSizeData.windowHeight') - top + 10;
      height = height > maxHeight ? maxHeight : height;
      return "top:".concat($input.outerHeight() - 3, "px;max-height: ").concat(height, "px").htmlSafe();
    }),
    //////////////////    EVENTS    ////////////////////////
    keyDown: function keyDown(event) {
      var key = SPECIAL_KEYS[event.keyCode];

      if (key && this.hasMinimum) {
        event.preventDefault();
        this.handleSpecialKey(key);
      }
    },
    //////////////////    ACTIONS    ////////////////////////
    actions: {
      search: function search(term) {
        this.performSearch(term);
      },
      mouseEnter: function mouseEnter(result) {
        Ember.set(this, 'selected', result);
      },
      select: function select(result) {
        if (!result) {
          return;
        }

        var _Ember$getProperties = Ember.getProperties(result, 'primary', 'secondary'),
            primary = _Ember$getProperties.primary,
            secondary = _Ember$getProperties.secondary;

        Ember.setProperties(this, {
          selected: result,
          showing: false
        });
        Ember.setProperties(this, {
          primary: primary,
          secondary: secondary
        });
        this.$('input.primary').val(primary);
        this.$('input.secondary').val(secondary);
        this.sendAction('action', result);
      },
      changeSecondary: function changeSecondary() {
        this.updateInternalValues();
      },
      change: function change() {
        var _this$updateInternalV = this.updateInternalValues(),
            _this$updateInternalV2 = _slicedToArray(_this$updateInternalV, 1),
            term = _this$updateInternalV2[0];

        this.performSearch(term);
        this.sendAction('changed');
      },
      blur: function blur() {
        if (this.showing) {
          this.handleSpecialKey('escape');
        }
      },
      focused: function focused() {
        if (!this.showing) {
          var $input = this.$('input');
          Ember.set(this, 'term', null);

          if ($input.length) {
            this.performSearch($input.first().val());
          }
        }
      }
    },
    //////////////////    HOOKS    ////////////////////////
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.once(function () {
        _this.$('input.primary').val(_this.primary);

        _this.$('input.secondary').val(_this.secondary);
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this2 = this;

      Ember.run.once(function () {
        _this2.$('input.primary').val(_this2.primary);

        _this2.$('input.secondary').val(_this2.secondary);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.cancel();
    },
    //////////////////    HELPERS    ////////////////////////
    performSearch: function performSearch(value) {
      // Below line will check if current User Contact Searching setting is ON/OFF
      if (!this.currentUser.get('model.contactSearching')) {
        return;
      }

      var last = Ember.get(this, 'term');
      var term = Ember.set(this, 'term', value);

      if (this.hasMinimum && term !== last) {
        Ember.set(this, 'pipeline', Ember.run.debounce(this, this.updateResults, 400));
        Ember.set(this, 'showing', true);
      } else {
        this.cancel();
        Ember.set(this, 'showing', false);
      }
    },
    processHeader: function processHeader(name, state) {
      return Ember.Object.create({
        primary: name,
        type: 'header',
        state: state
      });
    },
    processData: function processData(data) {
      if (!data || !data.contacts) {
        return Ember.A();
      }

      return data.contacts.map(function (contact) {
        return Ember.Object.create({
          primary: contact.name,
          secondary: contact.email,
          type: 'entry'
        });
      });
    },
    processResults: function processResults(rsvpHash) {
      var _this3 = this;

      var showing = this.showing;
      var endpoints = this.endpoints;
      var results = Ember.A();
      var headers = {};

      if (showing) {
        Ember.set(this, 'results', results);
        endpoints.forEach(function (endpoint) {
          var header = _this3.processHeader(endpoint.name, 'loading');

          headers[endpoint.key] = header;
          results.pushObject(header);
        });
        rsvpHash.then(function (hash) {
          results.clear();
          endpoints.forEach(function (endpoint) {
            var header = headers[endpoint.key];

            var contacts = _this3.processData(hash[endpoint.key]);

            Ember.set(header, 'state', 'loaded');
            results.pushObject(header);

            if (contacts.length > 0) {
              results.pushObjects(contacts);
            } else if (_this3.showNoResults) {
              results.pushObject(Ember.Object.create({
                primary: _this3.i18n.t("components.contactsTypeahead.noResults"),
                type: 'empty',
                state: 'empty'
              }));
            }
          });

          if (_this3.selectOnMatch && Ember.get(results, 'length') === 2 && Ember.get(results, 'lastObject.secondary') === _this3.term) {
            _this3.send('select', Ember.get(results, 'lastObject'));
          }
        }, function (error) {
          endpoints.forEach(function (endpoint) {
            Ember.setProperties(headers[endpoint.key], {
              state: 'error',
              errorMessage: error.statusText
            });
          });
        });
      }
    },
    updateResults: function updateResults() {
      var _this4 = this;

      var term = this.term;
      var endpoints = this.endpoints;
      var results = {};
      endpoints.forEach(function (endpoint) {
        return results[endpoint.key] = _this4.makeRequest(endpoint, term);
      });
      this.processResults(Ember.RSVP.hash(results, 'typeahead update'));
    },
    makeRequest: function makeRequest(endpoint, term) {
      var _this5 = this;

      var url = "".concat(_environment.default.api.endpoint, "/").concat(endpoint.url);
      var query = {};
      var queryKey = this.queryKey || 'query';
      query[queryKey] = term;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.getJSON(url, query).done(resolve).fail(function (error) {
          // If a token has expired we are informed that we should disable the integration via a 400 and a disabled message
          if (error && "".concat(error.responseText).match(/disabled/)) {
            // We need to reload the user model since one of the integrations was removed
            Ember.get(_this5, 'currentUser.model').reload();
            Ember.set(_this5, 'showing', false);
          }

          reject(error);
        });
      }, 'contact searching request');
    },
    cancel: function cancel() {
      var pipeline = this.pipeline;

      if (pipeline) {
        Ember.run.cancel(pipeline);
      }
    },
    handleSpecialKey: function handleSpecialKey(key) {
      var entries = this.entryResults;
      var showing = this.showing;
      var current = this.selected;
      var currentIndex = entries.indexOf(current);

      switch (key) {
        case 'enter':
          this.send('select', current);
          break;

        case 'escape':
          Ember.setProperties(this, {
            selected: null,
            showing: false
          });
          break;

        case 'up':
          if (showing) {
            Ember.set(this, 'selected', entries.objectAt(currentIndex - 1) || Ember.get(entries, 'lastObject'));
          }

          Ember.set(this, 'showing', true);
          break;

        case 'down':
          if (showing) {
            Ember.set(this, 'selected', entries.objectAt(currentIndex + 1) || Ember.get(entries, 'firstObject'));
          }

          Ember.set(this, 'showing', true);
          break;
      }
    },
    updateInternalValues: function updateInternalValues() {
      var _ref = [this.$('input.primary').val(), this.$('input.secondary').val()],
          primary = _ref[0],
          secondary = _ref[1];
      Ember.setProperties(this, {
        primary: primary,
        secondary: secondary
      });
      return [primary, secondary];
    }
  });

  _exports.default = _default;
});