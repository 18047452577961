define("ember-rs/controllers/unauthorized/identity-expired", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    ////////////////////////  PARAMS  ////////////////////////
    queryParams: ['signer_id', 'identity_token'],
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      extendIdentityToken: function extendIdentityToken() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.post("".concat(_environment.default.api.endpoint, "/signers/").concat(_this.signer_id, "/extend_identity_token_expiry"), {
            identity_token: _this.identity_token
          }).done(function (response) {
            _this.flashMessages.success(_this.i18n.t('controllers.unauthorized.identityExpired.success'));

            resolve(response);
          }).fail(function (response) {
            _this.flashMessages.success(_this.i18n.t('controllers.unauthorized.identityExpired.success', {
              response: response.responseText
            }));

            reject(response);
          });
        }, 'extend identity token');
      }
    }
  });

  _exports.default = _default;
});