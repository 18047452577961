define("ember-rs/templates/components/user-nav-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YzLJdpP1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"user_nav\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"button\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[2,\"Sign In\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/user-nav-links.hbs"
    }
  });

  _exports.default = _default;
});