define("ember-rs/locales/de/templates/onboarding/account-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding2 = void 0;
  var accountOnboarding2 = {
    "body": "Geben Sie Ihrem Konto eine persönliche Note und einen professionellen Look, indem Sie <span>ein Foto hinzufügen</span>.",
    "mobileBody": "Ihr Foto wird in der E-Mail des Empfängers und auf der zu signierenden Seite des Dokuments angezeigt, damit die Unterzeichner sich sicher fühlen."
  };
  _exports.accountOnboarding2 = accountOnboarding2;
});