define("ember-rs/locales/fr/templates/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    "choose": "3. Choisir les données à exporter",
    "columnNames": "Les noms des colonnes sont basés sur les noms des composants dans le document :",
    "export": "4. Exporter au format CSV",
    "selectColumns": "Sélectionner des colonnes",
    "view": "Un fichier CSV peut être ouvert dans Excel pour afficher des données relatives aux documents que vous avez envoyés."
  };
  _exports.template = template;
});