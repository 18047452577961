define("ember-rs/templates/toolbar-icons/signature-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "waajIMW0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"version\",\"1.1\"],[14,1,\"Layer_1\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[14,\"x\",\"0px\"],[14,\"y\",\"0px\"],[14,\"width\",\"14.026px\"],[14,\"height\",\"8.812px\"],[14,\"viewBox\",\"0 0 14.026 8.812\"],[14,\"enable-background\",\"new 0 0 14.026 8.812\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[12],[2,\"\\n\"],[10,\"g\"],[12],[2,\"\\n\\t\"],[10,\"g\"],[12],[2,\"\\n\\t\\t\"],[10,\"g\"],[12],[2,\"\\n\\n\\t\\t\\t\\t\"],[10,\"rect\"],[14,\"x\",\"3.005\"],[14,\"y\",\"-0.926\"],[14,\"transform\",\"matrix(-0.7072 -0.707 0.707 -0.7072 3.5498 8.4836)\"],[14,\"width\",\"1.053\"],[14,\"height\",\"8.865\"],[12],[13],[2,\"\\n\\n\\t\\t\\t\\t\"],[10,\"rect\"],[14,\"x\",\"-0.832\"],[14,\"y\",\"2.98\"],[14,\"transform\",\"matrix(-0.7071 -0.7071 0.7071 -0.7071 3.5494 8.4836)\"],[14,\"width\",\"8.727\"],[14,\"height\",\"1.053\"],[12],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[10,\"rect\"],[14,\"x\",\"0\"],[14,\"y\",\"7.812\"],[14,\"width\",\"14.026\"],[14,\"height\",\"1\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/toolbar-icons/signature-component.hbs"
    }
  });

  _exports.default = _default;
});