define("ember-rs/locales/en/templates/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "linkExpired": "Document Link Expired",
    "toSign": "To sign this document request a new link.",
    "sendLink": "Send New Signer Link",
    "contactSender": "Please contact the document sender to request an updated link."
  };
  _exports.identityExpired = identityExpired;
});