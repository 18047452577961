define("ember-rs/locales/it/templates/document/details/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "unlock_message": "Uno o più firmatari hanno superato il numero massimo di tentativi di verifica dell'identità (3) e il documento è ora bloccato per questi firmatari. Per sbloccare il documento e richiedere ai firmatari in sospeso di verificare le rispettive identità con l'autenticazione basata sulla conoscenza, fare clic su Sblocca. Tenere presente che lo sblocco del documento potrebbe essere in conflitto con le <a target='_blank' href='https://www.irs.gov/uac/electronic-signature-guidance-for-forms-8878-and-8879'>indicazioni dell'IRS sulla firma elettronica per i moduli 8878 e 8879</a>."
  };
  _exports.unlock = unlock;
});