define("ember-rs/locales/de/templates/reports/export", ["exports", "ember-rs/locales/de/templates/reports/export/month"], function (_exports, _month) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.exportTrans = void 0;
  var exportTrans = {
    month: _month.month
  };
  _exports.exportTrans = exportTrans;
});