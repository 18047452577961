define("ember-rs/controllers/signer/sign/identify", ["exports", "ember-rs/validations/identification", "ember-countries", "ember-rs/utils/us-states", "ember-rs/mixins/country-details"], function (_exports, _identification, _emberCountries, _usStates, _countryDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TOTAL_ATTEMPTS = 3;

  var getDocumentType = function getDocumentType(val) {
    if (val == 'US') {
      return 'ssn';
    } else {
      return 'passport';
    }
  };

  var _default = Ember.Controller.extend(_identification.default, _countryDetails.default, {
    ////////////////////////  ATTRIBUTES ////////////////////////
    countries: _emberCountries.COUNTRIES_LIST,
    country: "US",
    state: "NC",
    states: _usStates.usStates,
    dob: new Date("1/1/1972"),
    document_type: "ssn",
    ////////////////////////  SERVICES  ////////////////////////
    cookies: Ember.inject.service(),
    kbaSession: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    attempts: Ember.computed.alias('model.attempts'),
    attemptMade: Ember.computed.gt('attempts', 0),
    showValidationErrors: false,
    attemptsRemaining: Ember.computed('attempts', function () {
      return TOTAL_ATTEMPTS - this.attempts;
    }),
    isSSN: Ember.computed('document_type', function () {
      return this.document_type === 'ssn' ? true : false;
    }),
    requiredHaveValues: Ember.computed('dob', 'document_value', 'address', 'city', 'state', 'zipcode', 'country', function () {
      var dob = this.dob,
          document_value = this.document_value,
          address = this.address,
          city = this.city,
          state = this.state,
          zipcode = this.zipcode,
          country = this.country;
      return Ember.A([dob, document_value, address, city, state, zipcode, country]).every(Ember.isPresent);
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      next: function next(dob, document_value, address_street1, address_street2, address_city, address_subdivision, address_postal_code, address_country_code) {
        var _this = this;

        if (!Ember.get(this, 'validations.isValid')) {
          Ember.set(this, 'showValidationErrors', true);
          return;
        }

        var kbaSession = this.kbaSession;
        var document_type = this.document_type;
        var formattedDate = new Date(dob);
        var _ref = [1 + formattedDate.getMonth(), formattedDate.getDate(), formattedDate.getFullYear()],
            birth_month = _ref[0],
            birth_day = _ref[1],
            birth_year = _ref[2];
        var data = {
          birth_day: birth_day,
          birth_month: birth_month,
          birth_year: birth_year,
          address_street1: address_street1,
          address_street2: address_street2,
          address_city: address_city,
          address_subdivision: address_subdivision,
          address_postal_code: address_postal_code,
          address_country_code: address_country_code,
          document_type: document_type,
          document_value: document_value
        };
        Ember.set(this, 'saving', true);
        return kbaSession.request('/identify', 'POST', data, true).then(function (_ref2) {
          var kba = _ref2.kba;

          if (kba.success) {
            _this.send('nextStep', kba);
          } else {
            _this.send('reload');

            Ember.set(_this, 'saving', false);
          }
        }, function (error) {
          Ember.set(_this, 'saving', false);

          _this.send('error', error);
        });
      },
      toggleAdvisoryText: function toggleAdvisoryText() {
        this.toggleProperty('showIDAdvisory');
      },
      changeCountryDetails: function changeCountryDetails(val) {
        var _this2 = this;

        this.changeCountry(val).then(function (response) {
          var newStates = response.states;

          _this2.set('states', newStates);

          _this2.set('state', Object.keys(newStates)[0]);

          _this2.set('country', val);

          _this2.set('document_type', getDocumentType(val));
        }).catch(function (err) {
          return err;
        });
      }
    }
  });

  _exports.default = _default;
});