define("ember-rs/locales/fr/templates/template", ["exports", "ember-rs/locales/fr/templates/template/details", "ember-rs/locales/fr/templates/template/search-header-mobile"], function (_exports, _details, _searchHeaderMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    details: _details.details,
    searchHeaderMobile: _searchHeaderMobile.searchHeaderMobile,
    "review": {
      "header-content": "Vérifier",
      "create-button": "Créer un modèle",
      "update-button": "Mettre à jour modèle"
    },
    "index": {
      "subheader-create-button": "Créer un modèle",
      "template-limit-modal": {
        "cancellation-grace-period": "Ce compte a été annulé, vous ne pouvez donc pas créer de modèles",
        "limit-reached": "Votre plan actuel vous permet uniquement de créer {{templateLimit}} modèles. Si vous souhaitez en créer davantage, contactez le support et mettez à niveau votre compte.",
        "not-enabled": "Votre plan actuel ne vous permet pas de créer des modèles. Si vous souhaitez créer des modèles, contactez le support et mettez à niveau votre compte."
      }
    }
  };
  _exports.template = template;
});