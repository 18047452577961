define("ember-rs/locales/zh-cn/templates/components/support-when-externally-billed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportWhenExternallyBilled = void 0;
  var supportWhenExternallyBilled = {
    "partnerSale": "您的帐户是通过合作伙伴销售创建的。如果您想升级帐户，请与支持人员联系。"
  };
  _exports.supportWhenExternallyBilled = supportWhenExternallyBilled;
});