define("ember-rs/locales/en/controllers/unauthorized/identity-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identityExpired = void 0;
  var identityExpired = {
    "success": "Resent signing link",
    "failure": "Failed to send the link: {{{response}}}"
  };
  _exports.identityExpired = identityExpired;
});