define("ember-rs/locales/es/templates/onboarding/prepare-tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareTutorial = void 0;
  var prepareTutorial = {
    "title": "Preparar un documento para la firma",
    "selectField": "Seleccione el campo de la derecha que quiere agregar.",
    "placeField": "Colocar el campo",
    "clickPlace": "Haga clic donde quiera colocar el nuevo campo.",
    "adjustSize": "Ajustar el tamaño del campo",
    "adjustHover": "Para ajustar el tamaño, desplace el cursor sobre el campo y arrastre los puntos.",
    "adjustOptions": "Opciones de ajuste",
    "moreOptions": "Haga clic en el campo para mostrar más opciones y seleccione quién debe completar el campo.",
    "thatsIt": "¡Ya está!",
    "clickReview": "Cuando haya terminado, haga clic en <b>Siguiente: Revisar</b>",
    "knowMore": "¿Quiere saber más?",
    "support": "Vea artículos y vídeos de asistencia técnica"
  };
  _exports.prepareTutorial = prepareTutorial;
});