define("ember-rs/locales/pt-br/templates/components/signer-payment-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerPaymentDetails = void 0;
  var signerPaymentDetails = {
    "paymentInfo": "Informações de pagamento",
    "paymentID": "ID de pagamento:",
    "retryPayment": "Tentando novamente o pagamento...",
    "failed": "Com falha",
    "chargedSuccess": "Cobrado com sucesso em {{chargedAt}}.",
    "stripeCustomer": "Cliente Stripe",
    "stripeCharge": "Cobrança de Stripe",
    "attempted": "Última tentativa em {{chargedAt}}.",
    "retry": "Tentar novamente",
    "pending": "Pendente...",
    "success": "O pagamento foi bem sucedido na nova tentativa.",
    "retryError": "Falha na nova tentativa ({{{errorMsg}}})."
  };
  _exports.signerPaymentDetails = signerPaymentDetails;
});