define("ember-rs/locales/nl/templates/components/hybris-trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialBanner = void 0;
  var hybrisTrialBanner = {
    "billingInfo": "Uw factureringsgegevens en abonnementen zijn niet up-to-date.",
    "update": "Klik hier om bij te werken",
    "hybrisTrialMsg": "U hebt nog {{{numberOfDaysLeft}}} dag(en) over van de proefperiode! <a href='/upgrade-worldpay'>Upgrade</a> naar een betaald abonnement om gebruik te blijven maken van onze services.",
    "premiumTrialMsg": "You are on your Premium Trial. Please contact 1-800-441-3453 or Email us at <a href='mailto:{{{email}}}'>{{{email}}}</a> to upgrade and go completely paperless."
  };
  _exports.hybrisTrialBanner = hybrisTrialBanner;
});