define("ember-rs/locales/ja/templates/onboarding/send-onboarding-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendOnboarding4 = void 0;
  var sendOnboarding4 = {
    "close": "<span>戻る矢印</span>を選択して詳細を閉じ、[ドキュメント] ダッシュボードに移動します"
  };
  _exports.sendOnboarding4 = sendOnboarding4;
});