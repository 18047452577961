define("ember-rs/locales/en/templates/unauthorized/document-executed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentExecuted = void 0;
  var documentExecuted = {
    "docComplete": "This Document Has Been Completed",
    "toDownload": "To download this document request a new download link.",
    "sendLink": "Send New Download Link"
  };
  _exports.documentExecuted = documentExecuted;
});