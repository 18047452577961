define("ember-rs/locales/pt-br/templates/unauthorized/unauthorized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unauthorizedFile = void 0;
  var unauthorizedFile = {
    "unauthorized": "Não autorizado",
    "youAreNot": "Você não está autorizado a ver essa página."
  };
  _exports.unauthorizedFile = unauthorizedFile;
});