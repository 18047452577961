define("ember-rs/locales/de/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.accountOnboarding1 = void 0;
  var accountOnboarding1 = {
    "body": "Ändern Sie <span>Ihre Einstellungen</span>, <span>passen Sie das Branding</span> Ihres Kontos an, <span>integrieren</span> Sie andere Tools und vieles mehr."
  };
  _exports.accountOnboarding1 = accountOnboarding1;
});