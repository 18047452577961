define("ember-rs/locales/ja/templates/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateSelector = void 0;
  var reusableTemplateSelector = {
    "noTemplates": "まだテンプレートが作成されていません。",
    "templateDesc": "頻繁に使用するドキュメントから再利用可能なテンプレートを作成します。すばやい送信、サイトへの埋め込み、または大量配布が可能になります。",
    "createNew": "新規テンプレートの作成",
    "chooseTemplate": "テンプレートの選択",
    "loading": "読み込み中...",
    "loadMore": "さらにテンプレートを読み込む...",
    "upgrade": "アカウントをアップグレードしてテンプレートを使用",
    "upgradeAccount": "アカウントをアップグレード",
    "bulkSendInfo": "一括送信機能は、署名者が 1 人のテンプレートでのみ使用できます。一括送信について詳しくは、詳細情報をご覧ください。",
    "learnMore": "詳細情報",
    "searchTemplate": "テンプレートの検索",
    "noResults": "結果なし。"
  };
  _exports.reusableTemplateSelector = reusableTemplateSelector;
});