define("ember-rs/components/flattened-component", ["exports", "ember-rs/utils/font-scaling"], function (_exports, _fontScaling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    componentValue: Ember.computed('component.componentValue', 'component.mergefieldValue', 'component.isMergeField', function () {
      if (this.get('component.isMergeField')) {
        return this.get("component.mergefieldValue");
      } else {
        return this.get("component.componentValue");
      }
    }),
    documentViewerData: Ember.inject.service(),
    classNameBindings: [':flattened-component', 'renderedAsType', 'component.textAlignClass', 'component.transparent:is-transparent'],
    attributeBindings: ['style', 'data-id'],
    'data-id': Ember.computed.oneWay('component.id'),
    renderedAsType: Ember.computed('component.renderFlattenedAsText', 'component.renderFlattenedAsSignature', 'component.renderFlattenedAsCheck', function () {
      if (this.get('component.renderFlattenedAsText')) {
        return 'flattened-as-text';
      }

      if (this.get('component.renderFlattenedAsSignature')) {
        return 'flattened-as-signature';
      }

      if (this.get('component.renderFlattenedAsCheck')) {
        return 'flattened-as-check';
      }

      return undefined;
    }),
    style: Ember.computed('component.x', 'component.y', 'component.width', 'component.height', 'component.isSourceMapped', function () {
      var left = this.get('component.x') * this.get('documentViewerData.pageWidth');
      var top = this.get('component.y') * this.get('documentViewerData.pageHeight');
      var width = this.get('component.width') * this.get('documentViewerData.pageWidth');
      var height = this.get('component.height') * this.get('documentViewerData.pageHeight');
      var style = "left:".concat(left, "px; top:").concat(top, "px; width:").concat(width, "px; height:").concat(height, "px; line-height:").concat(height, "px;");

      if (this.get('component.isSourceMapped')) {
        style += 'display: flex';
      }

      return style.htmlSafe();
    }),
    checkStyle: Ember.computed('component.height', function () {
      var height = this.get('component.height') * this.get('documentViewerData.pageHeight');
      return "font-size: ".concat(height, "px;").htmlSafe();
    }),
    signature: Ember.computed('componentValue.value', 'component.signature', 'component.isAnnotation', function () {
      if (this.get('component.isAnnotation')) {
        return this.get('component.signature');
      } else {
        return this.store.findRecord('signature', this.get('componentValue.value'));
      }
    }),
    textValue: Ember.computed('component.isAnnotation', 'componentValue.value', function () {
      if (this.get('component.isAnnotation')) {
        return this.get('component.value');
      } else {
        return this.get('componentValue.value');
      }
    }),
    textStyle: Ember.computed('component.width', 'component.multiline', 'component.height', 'documentViewerData.pageWidth', 'documentViewerData.pageHeight', function () {
      var fontSize = this.get('component.isMergeField') ? this.get('component.mergefieldValue.fontSize') : this.get('component.fontSize');
      fontSize = fontSize || 12;

      if (!this.get('component.multiline')) {
        var numChars = this.get('componentValue.value.length');
        var width = this.get('component.width') * this.get('documentViewerData.pageWidth') - 15;

        if (numChars * (fontSize * 0.6) > width && !this.get('component.isTextAnnotation')) {
          var widthPerChar = width / numChars;
          fontSize = widthPerChar * (1 / 0.6); //TODO: Check the logic here

          if (!this.get('component.isSourceMapped')) {
            //converting point to pixel since the text will be placed over the PDF.
            fontSize = fontSize * _fontScaling.POINT_TO_PIXEL_CONSTANT;
          }

          if (fontSize > _fontScaling.DEFAULT_FONT_SIZE) {
            fontSize = _fontScaling.DEFAULT_FONT_SIZE;
          }
        }

        return "font-size: ".concat(fontSize, "px;").htmlSafe();
      } else {
        fontSize = this.get('component.isTextAnnotation') ? this.get('component.fontSize') : this.get('componentValue.fontSize') || _fontScaling.DEFAULT_FONT_SIZE;
        return "font-size: ".concat(fontSize, "px; line-height: ").concat(_fontScaling.LINE_HEIGHT_MULTIPLIER * fontSize, "px; white-space: break-spaces;").htmlSafe();
      }
    })
  });

  _exports.default = _default;
});