define("ember-rs/locales/es/templates/onboarding/signer-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding1 = void 0;
  var signerOnboarding1 = {
    "redFlag": "La <span>bandera roja</span> indica áreas que deben completarse.",
    "zoom": "Pellizque y use el zoom de su teléfono para agrandar el documento."
  };
  _exports.signerOnboarding1 = signerOnboarding1;
});