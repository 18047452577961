define("ember-rs/locales/en/routes/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "tryAgain": "Something went wrong! Try again."
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});