define("ember-rs/locales/de/mixins/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.settings = void 0;
  var settings = {
    "updateSuccessful": "Einstellung aktualisiert",
    "updateError": "Fehler beim Aktualisieren der Einstellung"
  };
  _exports.settings = settings;
});