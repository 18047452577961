define("ember-rs/locales/en/templates/components/document/signer-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerRow = void 0;
  var signerRow = {
    "pendingIdentityAuthentication": "Pending identity authentication",
    "sign": "Sign",
    "disabledReminder": "This signer has disabled further reminders for this document",
    "threshold": "You have exceeded the send reminder threshold (3 per document)",
    "signerFailed": "This signer has failed ",
    "currentlyLocked": "and is currently locked out of the document.",
    "signerLocked": "This signer has failed Knowledge-Based Authentication and is currently locked out of the document.",
    "remind": "Remind",
    "resend": "Resend",
    "createLink": "Create Link",
    "copyLink": "Copy Link",
    "copyLinkSuccess": "Link copied to clipboard",
    "copyLinkError": "Failed to copy link to clipboard",
    "signerAuthFailed": "Failed to authenticate signer via session: {{{error}}}",
    "reminderSuccess": "Successfully reminded {{{name}}}",
    "reminderFailed": "Failed to generate reminder for {{{name}}}",
    "generateLinkFailed": "Failed to generate signer link: {{{error}}}",
    "resendIdentityVerification": "Resent identity verification",
    "resendingFailed": "Failed to resend identity verification: {{{error}}}"
  };
  _exports.signerRow = signerRow;
});