define("ember-rs/components/info-banner", ["exports", "ember-rs/mixins/trial-methods", "moment"], function (_exports, _trialMethods, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_trialMethods.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    currentAccount: Ember.computed.alias('currentUser.account'),
    relativeDaysToSuspension: Ember.computed('currentAccount.cancellationDate', function () {
      var cancellationDate = this.get('currentAccount.cancellationDate');
      var suspensionDate = (0, _moment.default)(cancellationDate).add(30, 'days');

      var suspensionInDays = _moment.default.duration(suspensionDate - (0, _moment.default)()).asDays();

      return "".concat(Math.round(suspensionInDays), " days");
    })
  });

  _exports.default = _default;
});