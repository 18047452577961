define("ember-rs/locales/it/templates/document/prepare-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_document = void 0;
  var prepare_document = {
    "prepare": "Prepara ",
    "prepare_package": "Preparare il pacchetto di documenti",
    "merge_field_message": "Questo documento contiene campi di unione da completare prima dell'invio.",
    "merge_field_title": "Compilare i campi di unione",
    "missing_merge_fields": "È necessario completare tutti i campi di unione obbligatori."
  };
  _exports.prepare_document = prepare_document;
});