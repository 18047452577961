define("ember-rs/components/unconfirmed-user-button", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    tagName: 'span',
    actions: {
      resendConfirmation: function resendConfirmation() {
        var _this = this;

        this.set('loading', true);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.getJSON("".concat(_environment.default.api.endpoint, "/account/users/").concat(_this.get('currentUser.model.id'), "/resend_welcome")).then(function (response) {
            _this.flashMessages.success(_this.i18n.t('components.unconfirmedUserButton.verificationMail', {
              email: _this.get('currentUser.email')
            }));

            _this.setProperties({
              loading: false,
              showUnconfirmedUserModal: false
            });

            return resolve(response);
          }).fail(function (response) {
            _this.set('loading', false);

            return reject(response);
          });
        }, 'resend confirmation email to the current user');
      }
    }
  });

  _exports.default = _default;
});