define("ember-rs/locales/ja/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploader = void 0;
  var documentUploader = {
    "multiFile": "複数ファイルのアップロードはサポートされていません。最初の選択ファイルのみがアップロードされます。",
    "alreadyUploaded": "このファイルはすでにアップロードされています。",
    "fileSize": "ファイル {{{fileName}}} は、20MB の上限を超えています。",
    "uploadFailed": "アップロードを作成できませんでした"
  };
  _exports.documentUploader = documentUploader;
});