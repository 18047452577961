define("ember-rs/templates/unauthorized/already-signed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "81w+t/Gx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.alreadySigned.thankYou\"],null]],[13],[2,\"\\n\\n\"],[11,\"form\"],[4,[38,1],[[32,0],\"requestNewToken\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.alreadySigned.youHave\"],null]],[13],[2,\"\\n\\n    \"],[10,\"button\"],[14,4,\"submit\"],[12],[1,[30,[36,0],[\"unauthorized.alreadySigned.sendNewLink\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.alreadySigned.successfully\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"signer_id\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/already-signed.hbs"
    }
  });

  _exports.default = _default;
});