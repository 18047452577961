define("ember-rs/locales/pt-br/templates/onboarding/migration-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.migrationOnboarding = void 0;
  var migrationOnboarding = {
    "migrationHeading": "Explore a versão mais recente do RightSignature!",
    "slide1Q": "O que há de novo?",
    "slide1a": "Recursos de segurança avançados:",
    "slide1b": "Autenticação baseada em conhecimento, acesso por código secreto que pode ser obrigatório e muito mais.",
    "slide1c": "Usabilidade aprimorada:",
    "slide1d": "Um novo layout otimizado, personalização aprimorada, notificações de produtividade e melhor funcionalidade móvel.",
    "slide2": "Onde estão os documentos da versão anterior do RightSigntature?",
    "slide3": "Onde estão os modelos da versão anterior do RightSigntature?",
    "slide4": "Como faço para converter em um plano pago na versão mais recente do RightSignature?",
    "continue": "Continuar",
    "dismiss": "Ignorar"
  };
  _exports.migrationOnboarding = migrationOnboarding;
});