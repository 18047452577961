define("ember-rs/locales/nl/templates/unauthorized/no-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noIntegrations = void 0;
  var noIntegrations = {
    "noIntegrations": "Uw ShareFile-abonnement biedt geen ondersteuning voor integratie met digitale handtekeningen",
    "support": "Hebt u een vraag? <a href='https://support.citrix.com/cscase'>Neem contact op met Support</a> of bel naar +1-800-921-4250"
  };
  _exports.noIntegrations = noIntegrations;
});