define("ember-rs/routes/signer/signing-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      controller.set('signer', model);
      controller.set('document', model.get('document'));
    },
    afterModel: function afterModel(model) {
      this.set('layoutSizeData.tmHeaderHeight', 60);

      if (Ember.isEmpty(model)) {
        this.transitionTo('application');
      } else if (this.get('session.isAuthenticated')) {
        this.flashMessages.success(this.i18n.t('components.signers.success'));

        if (model.get('document')) {
          model.get('document').reload();
          this.transitionTo('document.details', model.get("document"));
        } else {
          this.transitionTo('application');
        }
      }
    }
  });

  _exports.default = _default;
});