define("ember-rs/locales/zh-cn/templates/onboarding/welcome-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.welcomeOnboarding2 = void 0;
  var welcomeOnboarding2 = {
    "tips": "访问您的<span>文档</span>和<span>模板</span>、<span>管理设置</span>以及查找视频<span>教程和操作方法文章</span>。"
  };
  _exports.welcomeOnboarding2 = welcomeOnboarding2;
});