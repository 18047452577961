define("ember-rs/locales/en/templates/components/hybris-trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialBanner = void 0;
  var hybrisTrialBanner = {
    "billingInfo": "Your billing information and price plans are not up-to-date.",
    "update": "Click here to update",
    "hybrisTrialMsg": "You have {{{numberOfDaysLeft}}} day(s) of preview period left! Please <a href='/upgrade-worldpay'>upgrade</a> to a paid plan and continue using our services.",
    "premiumTrialMsg": "You are on your Premium Trial. Please contact 1-800-441-3453 or Email us at <a href='mailto:{{{email}}}'>{{{email}}}</a> to upgrade and go completely paperless."
  };
  _exports.hybrisTrialBanner = hybrisTrialBanner;
});