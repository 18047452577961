define("ember-rs/components/on-boarding-step", ["exports", "ember-rs/config/environment", "ember-rs/mixins/settings", "ember-rs/mixins/invalidate-and-sign-out", "ember-rs/mixins/trial-methods"], function (_exports, _environment, _settings, _invalidateAndSignOut, _trialMethods) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_settings.default, _invalidateAndSignOut.default, _trialMethods.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    layoutSizeData: Ember.inject.service(),
    classNameBindings: [':onboarding-step', 'isCurrentStep'],
    marketingHost: _environment.default.marketingHost,
    isCurrentStep: Ember.computed('currentStep', 'stepNumber', function () {
      return this.currentStep === this.stepNumber;
    }),
    actions: {
      nextStep: function nextStep() {
        this.sendAction('nextStep');
      }
    }
  });

  _exports.default = _default;
});