define("ember-rs/locales/zh-cn/templates/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.account = void 0;
  var account = {
    "settings": {
      "header": "设置",
      "description": "下面是此用户和帐户的设置。",
      "personal-info-header": "您的信息",
      "account-info": {
        "header": "设置",
        "company-name-header": "公司名称",
        "current-plan-header": "当前计划",
        "account-name-header": "帐户名称"
      },
      "verified-emails": {
        "header": "设置",
        "header-description": "添加可以将文档发送到的任何电子邮件地址。您的控制板将显示发送到其中任意电子邮件地址的所有文档。",
        "add-email": "添加已验证的电子邮件地址",
        "send-email": "发送验证电子邮件",
        "enter-email": "输入电子邮件..."
      },
      "account-settings": {
        "header": "设置",
        "enable-passcode-header": "需要文档的通行码",
        "enable-passcode-description": "需要通行码才能对文档签名。发件人可以在发送以获取签名的过程中生成通行码。",
        "enable-token-expiration-header": "默认令牌有效期",
        "enable-token-expiration-description": "设置所有通过电子邮件发送的文档链接(包括签名链接和共享链接)的过期时间长度。",
        "enable-blue-ink-header": "蓝色墨水签名",
        "enable-blue-ink-description": "在从此帐户发送的文档上捕获的所有签名都将显示为蓝色。这是为了帮助区分已签名的原件与复印件。",
        "enable-integrations-description": "允许所有用户查看和设置与您使用的其他应用程序的连接。通过将其关闭，任何用户都无法查看“集成”设置以启用/禁用现成的集成。这有助于集中控制所有用户对可用集成的访问。",
        "enable-signer-download-header": "Signer document download",
        "enable-signer-download-description": "Allow signers to download the original document before signing."
      },
      "mfa-settings": {
        "enable-mfa-header": "双重验证",
        "enable-mfa-description": "双重验证允许用户设置一个电话号码以通过短信或语音来接收验证码。用户将通过登录过程进行注册。",
        "opt-out-mfa-description": "默认情况下，需要对所有用户执行双重验证。如果您想撤消此设置，可以填写弃权书，然后更改该设置。",
        "complete-mfa-waiver": "填写选择退出弃权书",
        "opt-out-waiver-title": "双重身份验证撤消弃权书",
        "opt-out-waiver-description": "Citrix 强烈建议对 RightSignature 服务执行<a href='https://support.citrix.com/article/CTX277910'>双重验证</a>，该验证是一种多重身份验证(MFA)，可提供额外一层的安全保护，降低对 RightSignature 内容进行未经授权的访问的可能性。",
        "opt-out-waiver-consent": "拒绝为此 RightSignature 帐户启用双重验证，即表明您有权代表已对此 RightSignature 实例授予许可的实体承担禁用双重验证的风险和责任。",
        "opt-out-quit": "退出",
        "opt-out-authority": "我有权",
        "opt-out-title": "请选择您不希望此 RightSignature 帐户受到双重验证保护的原因:",
        "opt-out-reason-first": "我们不会在 RightSignature 帐户中存储敏感或机密数据",
        "opt-out-reason-second": "我们没有使用双重验证的技术能力",
        "opt-out-description": "通过单击下面的“拒绝双重验证”按钮，即表明我已如实填写该表格，并承担与不使用双重验证相关的所有风险和责任。",
        "opt-out-decline": "拒绝双重验证",
        "opted-out": "已通过 {{optOutEmail}} 于 {{optOutDate}} 撤消强制执行。单击上面的切换按钮仍可启用/禁用双重验证。",
        "opted-out-no-context": "已选择退出强制执行。单击上面的切换按钮，仍然可以启用/禁用双重验证。",
        "opted-out-toast": "这将确认您已拒绝为此帐户下的用户部署双重验证，并且您已同意代表贵组织承担相关风险。"
      },
      "personal-settings": {
        "header": "设置",
        "enable-field-detection-header": "使用字段检测准备文档",
        "enable-field-detection-description": "准备文档时使用自动字段检测来代替手动放置字段。",
        "enable-view-notification-header": "文档被查看时通知",
        "enable-view-notification-description": "当签名者查看了您发送的进行签名的文档时，将向您发送通知。",
        "enable-signature-notification-header": "文档被签名时通知",
        "enable-signature-notification-description": "每次签名者对您属于其中一方的文档进行签名时都将向您发送通知。(文档完成时，您仍会收到通知。)",
        "enable-document-complete-notification-header": "完成了文档时通知",
        "enable-document-complete-notification-description": "执行了您属于其中一方的文档时向您发送通知。",
        "enable-contact-searching-header": "联系人搜索",
        "enable-contact-searching-description": "根据以前发送的文档以及任何已启用的联系人集成添加签名者时，联系人搜索功能将在您键入姓名时推荐联系人。",
        "default-expiration-setting-header": "默认有效期",
        "default-expiration-setting-description": "设置文档过期的默认天数。还可以在发送新文档时基于每个文档来修改此天数。",
        "enable-send-reminder-email-header": "发送提醒电子邮件",
        "enable-send-reminder-email-description": "如果文档未签名，则每周一次(第 3 天、第 7 天、第 14 天、第 21 天、第 28 天)或每天(从第 7 天到第 30 天)向文档收件人发送电子邮件。",
        "enable-attach-completed-pdf-header": "在电子邮件中附加完成的 PDF",
        "enable-attach-completed-pdf-description": "完成的 PDF 将附加到发送给文档所有各方的完成通知中。大于 10 MB 的 PDF 将不作为附件包含在内。",
        "default-signer-setting-header": "默认发件人作为签名者",
        "default-signer-setting-description": "将发件人设置为所有文档的默认“签名者”角色。还可以在发送新文档时基于每个文档对此设置进行修改。",
        "signature-types-settings-header": "允许的签名类型",
        "signature-types-settings-description": "从文档的签名者中选择要接受的签名类型。",
        "signature-type-drawn": "已绘制",
        "signature-type-typed": "已键入"
      },
      "saved-signature-settings": {
        "header": "设置",
        "no-signature-description": "没有保存的签名。对文档签名后，您的签名将存储在此位置以便您进行管理。",
        "signature-available-description": "单击签名字段时将放置默认签名。还可以在对新文档签名时基于每个文档修改您的签名。",
        "confirm-deletion": "是否确实要删除此签名?",
        "default": "默认",
        "success": "已成功设置默认签名。",
        "error": "设置默认签名时出错。"
      }
    },
    "integrations": {
      "header": "设置",
      "description": "下面是此用户和帐户的设置。"
    },
    "branding": {
      "header": "设置",
      "disabled": {
        "description": "下面是此用户和帐户的设置。"
      },
      "signing": {
        "description": "下面是此用户和帐户的设置。",
        "background-color-header": "背景色:",
        "footer-color-header": "页脚颜色:",
        "header-options-header": "标头选项:",
        "header-show-logo": "显示您的徽标",
        "header-no-logo": "无徽标",
        "footer-options-header": "页脚选项:",
        "footer-option-send-name-only": "仅发送姓名",
        "footer-option-company-name-only": "仅限公司名称",
        "footer-option-company-name-only-tooltip": "请在上面的“帐户”选项卡中添加公司名称。",
        "footer-document-name": "文档名称",
        "footer-from": "发件人",
        "footer-at": "时间",
        "footer-option-sender-and-company-name": "发件人和公司名称",
        "footer-option-show-sender-avatar": "显示发件人头像"
      },
      "email": {
        "header": "设置",
        "description": "下面是此用户和帐户的设置。",
        "email-header": "电子邮件标头:",
        "show-sender-from": "在“发件人”中显示发件人姓名",
        "show-company-subject": "在“主题”中显示公司名称",
        "show-document-name-in-subject": "在“主题”中使用文档",
        "custom-email-greeting-header": "自定义电子邮件问候语:",
        "custom-email-greeting-description": "这将显示为所有签名者电子邮件的第一部分。",
        "custom-email-signature-header": "自定义电子邮件签名:",
        "custom-email-signature-description": "这将显示为所有签名者电子邮件的最后一个部分。",
        "mergefield-help": "可以使用以下合并字段将自定义信息添加到您的电子邮件中:",
        "signer-name": "signer_name",
        "signer-first-name": "signer_first_name",
        "sender-name": "sender_name",
        "document-name": "document_name",
        "document-filename": "document_filename",
        "document-personalized-message": "document_personalized_message",
        "preview-from": "发件人:",
        "preview-subject": "主题:",
        "preview-no-logo": "无徽标",
        "preview-greeting": "您好",
        "preview-message": "已将文档“{{filename}}”发送给您进行签名",
        "preview-description": "要查看文档并通过电子签名进行签名，请访问以下链接:",
        "preview-action": "审阅文档"
      }
    },
    "users": {
      "trial-account-description": "您当前的计划不允许您管理用户。请升级您的计划以访问此功能。",
      "admin-section-header": "管理用户",
      "admin-section-description": "这些用户是此 RightSignature&trade; 帐户中的用户。作为帐户管理员，您可以添加、删除和提升用户。",
      "non-admin-section-header": "用户",
      "maximum-seats-alert-text": "您已达到帐户的用户数量上限。如果要添加其他用户或升级您的帐户以添加更多席位，请删除用户。",
      "seats-used": "已使用 <b>{{seatsUsed}}</b> 个席位，共 <b>{{totalSeats}}</b> 个",
      "unknown-user": "(未知用户)",
      "add-user-button-text": "添加用户",
      "edit-user-button-text": "编辑用户",
      "span-text-me": "我",
      "span-text-owner": "所有者",
      "span-text-admin": "管理",
      "add": "添加",
      "account-users-new_introduction": "输入要添加到此帐户的人员的姓名和电子邮件地址。",
      "first-name": "名字...",
      "last-name": "姓氏..."
    },
    "bills": {
      "header": "设置",
      "contact": "要查看和/或打印 RightSignature 付款收据，请单击下面相应的下载按钮。如果您有其他疑问，请<a href='https://www.citrix.com/contact/technical-support.html'>给我们打电话</a>以获得帮助，或者<a href='https://support.citrix.com/cscase'>与我们联系</a>。",
      "date": "日期",
      "type": "类型",
      "status": "状态"
    },
    "api": {
      "header": "设置",
      "description": "下面是此用户和帐户的设置。",
      "primary-action": "申请新密钥"
    }
  };
  _exports.account = account;
});