define("ember-rs/templates/client-application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wpSt1547",
    "block": "{\"symbols\":[],\"statements\":[[8,\"client-application/edit\",[],[[\"@model\",\"@redirect\"],[[34,0],[30,[36,1],[[32,0],\"redirect\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"action\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/client-application.hbs"
    }
  });

  _exports.default = _default;
});