define("ember-rs/locales/de/templates/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "send": "Zur Signatur senden",
    "notAllowed": " Ihr aktuelles Abonnement erlaubt Ihnen nicht, Dokumente zu senden. Erweitern Sie Ihr Abonnement, um auf dieses Feature zuzugreifen.",
    "embed": "In Website einbetten",
    "share": "Freigabelink generieren",
    "manageLinks": "Links und Einbettungen verwalten",
    "viewResponses": "Antworten anzeigen",
    "edit": "Bearbeiten",
    "delete": "Löschen",
    "roles": "Rollen",
    "security": "Sicherheitsoptionen:",
    "expires": "Ablauf in:",
    "days": "Tagen",
    "passcodeQuestion": "Passcodefrage:",
    "passcode": "Passcode:",
    "tags": "Tags:",
    "linkHistory": "Verlauf für Link und Einbettungscode",
    "linkHistoryTip": "Verwalten und Anzeigen von Nutzungsinformationen für alle Links und Einbettungen dieser Vorlage.",
    "generate": "Freigabelink generieren",
    "nameLink": "Benennen Sie diesen Freigabelink.",
    "nameLinkTip": "Dies vereinfacht die Verwaltung von Links.",
    "whichSigner": "Welche Unterzeichnerrolle?",
    "whichSignerTip": "Eingebettete Vorlagen und Links können nur einen einzigen Unterzeichner haben.",
    "limitUses": "Anzahl der Nutzungen begrenzen?",
    "limitUsesTip": "Legen Sie ein Limit für die Anzahl der Signaturen fest.",
    "expireDays": "Ablauf nach Anzahl der Tage?",
    "expireDaysTip": "Nach Ablauf dieser Frist funktioniert der Link nicht mehr.",
    "identityMethod": "Identitätsmethode:",
    "identityMethodTip": "Wie wird die Identität der Unterzeichner überprüft?",
    "generateLink": "Link generieren",
    "generateEmbed": "Einbettungscode generieren",
    "nameCode": "Geben Sie dem Code einen Namen.",
    "nameCodeTip": "Dies vereinfacht die Verwaltung generierter Codes.",
    "displayType": "Anzeigetyp",
    "displayTypeTip": "Hiermit wird festgelegt, wie die Einbettung angezeigt wird",
    "pixel": "px",
    "codeGenerated": "Code generiert",
    "linkGenerated": "Link generiert",
    "clickingManage": "by clicking on “Manage Links &amp; Embeds” on the template page.",
    "youCanAccessCode": "Sie können auf diesen Code zugreifen, ihn deaktivieren und bearbeiten, indem Sie auf der Vorlagenseite auf 'Links und Einbettungen verwalten' klicken.",
    "youCanAccessLink": "Sie können auf diesen Link zugreifen, ihn deaktivieren und bearbeiten, indem Sie auf der Vorlagenseite auf 'Links und Einbettungen verwalten' klicken.",
    "newPin": "Neue PIN",
    "done": "Fertig",
    "email": "E-Mail",
    "sms": "SMS",
    "none": "Keine",
    "height": "Höhe",
    "Width": "Breite",
    "button": "button",
    "image": "Bild",
    "inline": "Inline",
    "createdBy": "Erstellt von",
    "actions": "Aktionen",
    "visibility-toggle-text": "Vorlage auf 'privat' festlegen",
    "visibility-toogle-info": "Bei der Einstellung 'privat' sind Vorlagen nur für den Ersteller sichtbar",
    "template-signer-link": {
      "passcode-message": "Die Passcodefunktion wurde für dieses Konto aktiviert. Sie wird jedoch nicht für Freigabelinks oder eingebettete Dokumente erzwungen. Dieser Link/Einbettungscode ermöglicht den Zugriff auf dieses Dokument ohne den Passcode."
    },
    "embed-in-website": {
      "disabled-merge-fields": "Für diese Vorlage sind derzeit keine Einbettungscodes verfügbar. Um einen Einbettungscode für diese Vorlage zu generieren, entfernen Sie zuerst alle erforderlichen Seriendruckfelder.",
      "disabled-no-online-forms": "Ihr aktuelles Abonnement erlaubt Ihnen nicht, Vorlagen einzubetten. Erweitern Sie Ihr Abonnement, um auf dieses Feature zuzugreifen."
    },
    "generate-share-link": {
      "disabled-merge-fields": "Für diese Vorlage sind derzeit keine Einbettungscodes verfügbar. Um einen Einbettungscode für diese Vorlage zu generieren, entfernen Sie zuerst alle erforderlichen Seriendruckfelder.",
      "disabled-no-manual-distribution": "Ihr aktuelles Abonnement erlaubt Ihnen nicht, Freigabelinks zu generieren. Erweitern Sie Ihr Abonnement, um auf dieses Feature zuzugreifen."
    }
  };
  _exports.details = details;
});