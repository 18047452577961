define("ember-rs/locales/en/templates/components/restyle-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.restyleOnboarding = void 0;
  var restyleOnboarding = {
    "welcome": "Welcome!",
    "introduceNewLook": "We are excited to introduce the new look for RightSignature. Take a look around!",
    "gotIt": "Got it!"
  };
  _exports.restyleOnboarding = restyleOnboarding;
});