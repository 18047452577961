define("ember-rs/locales/de/templates/onboarding/signer-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding2 = void 0;
  var signerOnboarding2 = {
    "submit": "Die Fortschrittsanzeige Ihres Dokuments. Wenn Sie alle Felder ausgefüllt haben, wird <span>Senden</span> angezeigt."
  };
  _exports.signerOnboarding2 = signerOnboarding2;
});