define("ember-rs/locales/zh-cn/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanDetails = void 0;
  var hybrisPlanDetails = {
    "document-sending": "文件发送",
    "document-sending-info": "上载和发送常见格式的文档，包括 PDF、.doc 等。",
    "signature": "手绘签名",
    "signature-info": "用您的鼠标或触摸屏签名 - 看起来就像您的纸质签名一样。",
    "customer-support": "全天候客户支持",
    "customer-support-info": "无论何时需要，都可以从我们屡获殊荣的技术支持团队获取帮助。",
    "email-alert": "电子邮件警报",
    "email-alert-info": "针对签名者的提醒以及文档的即时状态更新。",
    "integration": "集成加载项",
    "integration-info": "将 RightSignature 连接到十几个最受欢迎的高级应用程序。",
    "type-sign": "键入以签名",
    "type-sign-info": "允许您的签名者使用键盘对文档进行签名。",
    "mobile": "在移动设备上签名",
    "mobile-info": "使用智能手机或平板电脑随时随地对文档进行签名。",
    "guided-signing": "引导式签名",
    "guided-signing-info": "通过简单的分步说明减少错误和遗漏。",
    "archive": "安全文件存档",
    "archive-info": "使用我们安全、可搜索的存档将您的重要文档保存在关闭状态。",
    "encrypt": "256 位数据加密",
    "encrypt-info": "银行级安全性可保护您的敏感文件免遭泄漏和盗窃。",
    "biometric": "生物特征身份验证",
    "biometric-info": "使用关键生物特征数据帮助验证签名者的真实性。",
    "fingerprint": "数字指纹",
    "fingerprint-info": "SHA-1 数字校验和可防止任何一方修改您的文档。",
    "audit": "审核日志和证书",
    "audit-info": "为了符合法律规定，将记录并保护文档中的每个操作。",
    "template": "可重用的模板",
    "template-info": "与您的整个团队共享常用文档。",
    "branding": "定制外观方案",
    "branding-info": "将您的徽标、颜色和照片添加到 RightSignature 文档中。",
    "attachments": "请求附件",
    "attachments-info": "在对文档进行签名的过程中，允许您的签名者附加文件。",
    "payments": "请求付款",
    "payments-info": "处理付款或收集您的文档上的信用卡信息。",
    "api": "开发人员 API",
    "api-info": "将 RightSignature 电子签名集成到您自己的应用程序或 Web 站点中。",
    "kba": "基于知识的身份验证",
    "kba-info": "在某些行业中，请提高对相关法规的遵守程度。",
    "standard": "标准",
    "advanced": "<span>Advanced<span>",
    "advancedKba": "<span>Advanced KBA<span>",
    "sharefilePremium": "<span><p>ShareFile Premium</p> <p> (带电子签名)</p></span>",
    "annually": "按年",
    "quarterly": "按季度",
    "monthly": "按月",
    "month": "月",
    "year": "年",
    "quarter": "季度",
    "document-sends-per-user": "每个用户发送 100 个文档",
    "hand-written-text-to-sign": "手绘，文字转签名，单击同意",
    "bulk-send-for-signature": "批量发送以进行签名",
    "sequenced-signing": "顺序签名",
    "knowledge-based-authentication": "基于知识的身份验证",
    "unlimited-document-sends": "发送无限量文档以供签名",
    "store-sync-share-files": "无缝存储、同步和共享文件",
    "hipaa-compliant": "HIPAA 合规性配置",
    "advanced-caption": "包括多项热门功能，例如:",
    "advanced-kba-caption": "包括多项热门功能，例如:",
    "sharefile-caption": "包括所有电子签名功能以及 Citrix ShareFile 的更多功能:",
    "minimum-of": "最少 {{{noUsers}}} 个用户",
    "users": "用户",
    "user": "用户",
    "user-per-month": "每用户/月",
    "compare-plans-in-detail": "<a href='https://citrix.sharefile.com/share/view/s1493194af0a44866ae95a6a667e7ffcd' style='color:#2071C5' target='_blank'>详细比较计划</a>",
    "selected-plan-requires-3-users": "<span style='color:#536E7B'>所选计划至少需要 3 个用户</span>",
    "more-than-1000-users-contact-sales": "<span style='color:#2071C5'>如果您需要超过 1000 个用户，请<a href={{driftLinkId}} style='color: #2071C5; text-decoration: underline; text-transform: lowercase'>与销售人员交谈。</a></span>",
    "trail-upgrade-msg": "您的试用期还剩 {{{numberOfDaysLeft}}} 天。要享受无忧无虑的使用而不会中断，请考虑立即升级您的计划。",
    "savings": "节省费用",
    "contact-sales": "与销售人员交谈",
    "select-plan": "选择计划",
    "selected-plan": "选定的计划",
    "best-for-team-collaboration": "最优惠价格"
  };
  _exports.hybrisPlanDetails = hybrisPlanDetails;
});