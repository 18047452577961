define("ember-rs/locales/nl/templates/components/add-role-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoleField = void 0;
  var addRoleField = {
    "name": "Naam...",
    "emailOptional": "E-mail (optioneel)...",
    "email": "E-mail...",
    "nameTip": "Volledige naam vereist.",
    "emailTip": "Geldig e-mailadres vereist.",
    "roleTip": "Rolnaam vereist."
  };
  _exports.addRoleField = addRoleField;
});