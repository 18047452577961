define("ember-rs/components/reusable-template-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    loading: false,
    store: Ember.inject.service(),
    page: 1,
    perPage: 10,
    totalPages: 1,
    addingTemplate: false,
    hasNoTemplates: Ember.computed.empty('allReusableTemplates'),
    hasNoSearchResult: Ember.computed.empty('searchedReusableTemplates'),
    hasMorePages: Ember.computed('totalPages', 'page', function () {
      return this.totalPages > this.page;
    }),
    templatesToShow: Ember.computed('searchedReusableTemplates', 'allReusableTemplates', function () {
      return this.searchedReusableTemplates || this.allReusableTemplates;
    }),
    actions: {
      select: function select(reusableTemplate) {
        var _this = this;

        this.set('addingTemplate', true);
        reusableTemplate.cloneDocument().then(function (doc) {
          doc.set('documentTransaction', _this.documentTransaction);

          _this.sendAction('templateSelected', reusableTemplate);

          _this.set('addingTemplate', false);
        });
      },
      loadMore: function loadMore() {
        var _this2 = this;

        var scrollTop = this.$('.available-templates').scrollTop();
        var isBulkSend = this.documentTransaction.get('workflow') == 'bulk_send' ? true : false;
        this.store.query('dashboardReusableTemplate', {
          bulk_send: isBulkSend,
          page: this.page + 1,
          per_page: this.perPage,
          account_id: this.get('currentUser.account.id')
        }).then(function (results) {
          Ember.set(_this2, 'reusableTemplates', _this2.store.peekAll('dashboardReusableTemplate'));

          _this2.searchedReusableTemplates.content.addObjects(results.content);

          Ember.set(_this2, 'page', _this2.page + 1);
          Ember.run.scheduleOnce('afterRender', _this2, function () {
            _this2.$('.available-templates').scrollTop(scrollTop);
          });
        });
      }
    }
  });

  _exports.default = _default;
});