define("ember-rs/locales/en/templates/components/hybris-trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialModal = void 0;
  var hybrisTrialModal = {
    "ohNo": "Oh no!",
    "reminder": "Just a reminder:",
    "previewHasEnded": "Your preview period with Newest version of RightSignature has ended.",
    "freeTrialEnds": "Your free trial ends in {{trialDaysRemaining}} day(s).",
    "needToGetThingsSigned": "Still need to get things signed? The renewed RightSignature business plans are priced to provide more value.",
    "exploreAdminFeatures": "Explore advance admin and security features",
    "retainDocuments": "Retain the documents and account setup from original version",
    "easySetup": "Easy setup, no contract – cancel anytime",
    "saveTimeAndPaper": "Save an hour or more of paperwork every week",
    "choosePlan": "Choose plan to continue",
    "needAssitance": "Need assistance with your evaluation?",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>Contact Support</a>",
    "notSureYet": "Not sure yet? See what RightSignature can do for your business.",
    "logout": "Logout"
  };
  _exports.hybrisTrialModal = hybrisTrialModal;
});