define("ember-rs/locales/zh-cn/templates/components/signer/signer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerInfo = void 0;
  var signerInfo = {
    "toSign": "要对本文档进行签名，请输入您的姓名和电子邮件:",
    "name": "姓名...",
    "proceed": "继续处理文档"
  };
  _exports.signerInfo = signerInfo;
});