define("ember-rs/locales/fr/templates/upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgrade = void 0;
  var upgrade = {
    "noUpgrade": "Impossible de mettre à niveau le compte",
    "noPermission": "Il semble que vous ne soyez pas autorisé à mettre à niveau votre compte. Contactez l'administrateur de votre compte pour savoir comment mettre à niveau vers un nouveau plan RightSignature.",
    "upgrade": "Mettez à niveau votre compte",
    "upgradeSubtitle": "Vous avez utilisé le plan Business durant votre période d'évaluation. Vous pouvez continuer à utiliser ce plan ou en choisir un autre plus adapté à vos besoins.",
    "choosePlan": "1. Choisissez un plan",
    "allInOne": "La solution de signature électronique tout-en-un",
    "standard": "Fonctionnalités standard ",
    "usersNeeded": "2. De combien d'utilisateurs avez-vous besoin ?",
    "checkout": "Paiement",
    "showLess": "Afficher moins",
    "showMore": "Afficher plus",
    "users": "Utilisateurs",
    "confirm": "Appuyez sur Entrée pour confirmer",
    "billing": "Facturation",
    "billingDetails": "Détails de facturation",
    "noOfUser": "Nombre d'utilisateurs",
    "billAmount": "Montant facturable",
    "creditAmount": "Montant du crédit de l'ancien RightSignature",
    "total": "Total",
    "taxCharges": "des taxes fiscales supplémentaires peuvent s'appliquer",
    "oops": "Un problème s'est produit",
    "dataUnavailable": "Les données ne sont pas disponibles pour le moment. Revenez sur cette page plus tard."
  };
  _exports.upgrade = upgrade;
});