define("ember-rs/locales/ja/templates/onboarding/new-user-onboarding-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFree = void 0;
  var newUserOnboardingFree = {
    "title": "RightSignature へようこそ!",
    "body": "現在、RightSignature™ への基本アクセス権しかありません。この権限では、ドキュメントに自己署名し、過去のドキュメントを表示できます。 PRO 機能にアクセスするには、RightSignature™ アカウントの管理者に連絡してください。"
  };
  _exports.newUserOnboardingFree = newUserOnboardingFree;
});