define("ember-rs/locales/nl/templates/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "title": "Upgraden...",
    "desc": "Dit kan enkele seconden duren. Vernieuw uw pagina niet en klik niet op de knop Sluiten of Terug van uw browser."
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});