define("ember-rs/locales/en/routes/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrations = void 0;
  var integrations = {
    "successEnabled": "Successfully enabled {{{formattedProviderName}}}",
    "successAuthorized": "Successfully authorized {{{formattedProviderName}}}",
    "successDisabled": "Successfully disabled {{{formattedProviderName}}}",
    "error": "Error. Could not modify {{{formattedProviderName}}}!",
    "errorAuthorize": "Error. Could not authorize  {{{formattedProviderName}}} integration!",
    "errorSetup": "Error occurred while attempting to setup {{{formattedProviderName}}} integration. Please try again."
  };
  _exports.integrations = integrations;
});