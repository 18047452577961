define("ember-rs/controllers/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ////////////////////////  PARAMS  ////////////////////////
    queryParams: ['signer_id'],
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      resendIdentityVerification: function resendIdentityVerification() {
        var _this = this;

        this.set('loading', true);
        this.ajax.post("signers/".concat(this.signer_id, "/resend_identity_verification")).then(function () {
          _this.flashMessages.success(_this.i18n.t('components.document.signerRow.resendIdentityVerification'));
        }).catch(function (response) {
          _this.flashMessages.warning(_this.i18n.t('components.document.signerRow.resendingFailed', {
            error: response.responseText
          }));

          _this.set('identityVerificationSent', true);
        }).finally(function () {
          _this.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});