define("ember-rs/locales/nl/templates/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateRow = void 0;
  var reusableTemplateRow = {
    "send": "Verzenden",
    "details": "Details",
    "trialmessageComp": "Met uw huidige proefabonnement kunt u niet meer dan {{{documentLimit}}} documenten verzenden. ",
    "planmessageComp": "Met uw huidige abonnement kunt u niet meer dan {{{documentLimit}}} documenten verzenden. ",
    "upgrade": "Neem contact met ons op om uw account te upgraden."
  };
  _exports.reusableTemplateRow = reusableTemplateRow;
});