define("ember-rs/templates/unauthorized/document-executed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vnR/ek2W",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[30,[36,0],[\"unauthorized.documentExecuted.docComplete\"],null]],[13],[2,\"\\n\\n\"],[11,\"form\"],[4,[38,1],[[32,0],\"requestNewToken\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"unauthorized.documentExecuted.toDownload\"],null]],[13],[2,\"\\n\\n  \"],[10,\"button\"],[14,4,\"submit\"],[12],[1,[30,[36,0],[\"unauthorized.documentExecuted.sendLink\"],null]],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/document-executed.hbs"
    }
  });

  _exports.default = _default;
});