define("ember-rs/locales/fr/templates/components/transaction-manager-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transactionManagerHeader = void 0;
  var transactionManagerHeader = {
    "close": "FERMER",
    "sendDocument": "Envoyer document",
    "signDocument": "Signer document",
    "inPerson": "Signature en personne",
    "broken": "Défaillant"
  };
  _exports.transactionManagerHeader = transactionManagerHeader;
});