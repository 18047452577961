define("ember-rs/locales/es/templates/sharefile-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sharefileRedirect = void 0;
  var sharefileRedirect = {
    "notAllowed": "Acción no permitida",
    "noPro": "Actualmente no tiene acceso PRO a esta cuenta. Puede firmar y ver sus documentos, pero las funciones PRO no están disponibles. Póngase en contacto con un administrador de esta cuenta para otorgarle acceso PRO de forma que pueda:",
    "send": "Enviar documentos para firma",
    "create": "Crear y usar plantillas",
    "customize": "Personaliza la marca",
    "enable": "Permitir integraciones de terceros",
    "reports": "Ver informes",
    "login": "Iniciar sesión en RightSignature"
  };
  _exports.sharefileRedirect = sharefileRedirect;
});