define("ember-rs/locales/en/templates/unauthorized/rs3-login-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rs3LoginRedirect = void 0;
  var rs3LoginRedirect = {
    "looking": "Looking for RightSignature&trade; - ShareFile Edition?",
    "sharefileEdition": "You have reached this page because you attampted to login to an older version of RightSignature&trade;. Our records indicate you have a RightSignature&trade; - ShareFile Edition account, log in below.",
    "support": "Have questions? <a href='https://support.citrix.com/cscase'>Contact Support</a> or call 1-800-921-4250k"
  };
  _exports.rs3LoginRedirect = rs3LoginRedirect;
});