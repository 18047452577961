define("ember-rs/locales/fr/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noUser = void 0;
  var noUser = {
    "unableLogin": "Impossible de se connecter",
    "unableLoginTip": "Vous ne pouvez pas vous connecter à ce compte RightSignature.<br> Contactez un administrateur du compte pour obtenir l'accès."
  };
  _exports.noUser = noUser;
});