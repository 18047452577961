define("ember-rs/locales/zh-cn/templates/document/search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.search_header = void 0;
  var search_header = {
    "from": "发件人: ",
    "state": "州/省/自治区/直辖市: ",
    "search_docs": "搜索文档...",
    "all": " 全部",
    "pending": " 待签名",
    "declined": " 已拒绝",
    "executed": " 完成",
    "expired": " 已过期",
    "voided": " 已失效",
    "admin_view": "管理员视图",
    "tool_tip": "查看从此帐户发送的所有文档。",
    "filter": "过滤器"
  };
  _exports.search_header = search_header;
});