define("ember-rs/locales/zh-cn/templates/onboarding/-entitlement-options-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.entitlementOptionsAdmin = void 0;
  var entitlementOptionsAdmin = {
    "title": "欢迎使用 RightSignature!",
    "hasSeats": "您当前具有此帐户的管理员权限。这意味着您可以向自己和/或其他用户授予 PRO 访问和功能。请单击下面的按钮向您自己授予此访问权限。",
    "notNow": "以后再说",
    "entitleMe": "获取 PRO 访问权限",
    "noSeats": "此帐户中的所有席位目前已用完! 您可以删除某个人，通过<a href='https://support.citrix.com/cscase'>联系技术支持</a>或拨打 <a href='tel:+18009214250'>1-800-921-4250</a> 获得更多席位，或者可以在未启用某些功能的情况下继续操作。",
    "proceed": "继续"
  };
  _exports.entitlementOptionsAdmin = entitlementOptionsAdmin;
});