define("ember-rs/locales/zh-cn/templates/reusable-template", ["exports", "ember-rs/locales/zh-cn/templates/reusable-template/add-roles", "ember-rs/locales/zh-cn/templates/reusable-template/edit", "ember-rs/locales/zh-cn/templates/reusable-template/prepare"], function (_exports, _addRoles, _edit, _prepare) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplate = void 0;
  var reusableTemplate = {
    addRoles: _addRoles.addRoles,
    edit: _edit.edit,
    prepare: _prepare.prepare
  };
  _exports.reusableTemplate = reusableTemplate;
});