define("ember-rs/locales/nl/templates/components/integration-settings-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integrationSettingsService = void 0;
  var integrationSettingsService = {
    "requiresInfo": "Deze integratie vereist enige informatie om verder te gaan:",
    "authorize": "Goedkeuren",
    "sending": "Verzend uw {{{formattedProviderName}}}-documenten voor een digitale handtekening vanuit RightSignature",
    "archiving": "Verzend een PDF van voltooide documenten naar een specifieke map in uw {{{formattedProviderName}}}-account",
    "contacts": "Maak al uw {{{formattedProviderName}}}-contactpersonen beschikbaar voor ondertekening van documenten",
    "payments": "Voeg betalingsonderdelen toe aan uw RightSignature-documenten voor het beveiligd verzamelen en opslaan van creditcardgegevens en het autoriseren van kosten via uw {{{formattedProviderName}}}-account",
    "fieldsInfo": "Vul alle velden in"
  };
  _exports.integrationSettingsService = integrationSettingsService;
});