define("ember-rs/locales/nl/templates/components/component-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbar = void 0;
  var componentToolbar = {
    "useTools": "Gebruik deze hulpmiddelen voor de velden die ondertekenaars moeten invullen.",
    "showLess": "Minder weergeven",
    "showMore": "Meer weergeven",
    "annotate": "aantekeningen:",
    "annotateTip": "Met deze hulpmiddelen kunt u aantekeningen in het document maken, zoals uw eigen handtekening, tekst of vinkjes.",
    "request": "Aanvragen:",
    "dataFields": "Gegevensvelden",
    "sourceFetchFailed": "Kan broninformatie niet ophalen.",
    "schemaFetchFailed": "Failed to retrieve source entity schema."
  };
  _exports.componentToolbar = componentToolbar;
});