define("ember-rs/components/document/signer-row", ["exports", "ember-rs/config/environment", "ember-rs/mixins/partial-session"], function (_exports, _environment, _partialSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COPY_LINK_SUCCESS_TRANSLATION = 'components.document.signerRow.copyLinkSuccess';
  var COPY_LINK_ERROR_TRANSLATION = 'components.document.signerRow.copyLinkError'; // TODO: distribution method manual?

  var _default = Ember.Component.extend(_partialSession.default, {
    classNames: 'signer-row',
    ////////////////////////  DEPENDENCIES  ////////////////////////
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ajax: Ember.inject.service(),
    ////////////////////////  BINDINGS  ////////////////////////
    classNameBindings: [':signer'],
    ////////////////////////  DEFAULTS  ////////////////////////
    loading: false,
    showModifyEmailModal: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    statusSigned: Ember.computed.equal('signer.status', 'signed'),
    pendingIdentityAuthentication: Ember.computed.equal('signer.status', 'pending_identity_authentication'),
    remindersRemaining: Ember.computed.alias('signer.remindersRemaining'),
    isCurrentUser: Ember.computed('currentUser.emailVerifications.[]', 'signer.email', function () {
      var userEmailVerifications = this.get('currentUser.emailVerifications') || [];
      var emails = userEmailVerifications.filterBy('verifiedAt').mapBy('email');
      return Ember.isPresent(this.get('signer.email')) && emails.indexOf(this.get('signer.email').toLowerCase()) > -1;
    }),
    canSign: Ember.computed('signerCanSign', 'isCurrentUser', 'signer.document.identityMethod', 'signer.document.currentUserIsSender', function () {
      return this.signerCanSign && (this.isCurrentUser || this.get('signer.document.currentUserIsSender') && this.get('signer.document.identityMethod') === 'none');
    }),
    hasSameAccount: Ember.computed('signer.document.account.id', function () {
      return this.currentUser.accountId === this.get('signer.document.account.id');
    }),
    canRemind: Ember.computed('signerCanSign', 'isCurrentUser', 'signer.document.distributionMethod', 'signer.document.currentUserIsSender', 'signer.kbaLocked', function () {
      return this.signerCanSign && !this.get('signer.kbaLocked') && !this.isCurrentUser && this.get('signer.document.distributionMethod') === 'email' && (this.get('currentUser.canManageTemplates') && this.hasSameAccount || this.get('signer.document.currentUserIsSender'));
    }),
    canRemindSelf: Ember.computed('canRemind', 'currentUser.email', 'signer.email', function () {
      var userEmail = (this.get('currentUser.email') || '').toLowerCase();
      var signerEmail = (this.get('signer.email') || '').toLowerCase();
      return this.canRemind && userEmail === signerEmail;
    }),
    canDistribute: Ember.computed('signerCanSign', 'signer.document.distributionMethod', 'signer.document.currentUserIsSender', function () {
      return this.signerCanSign && this.get('signer.document.currentUserIsSender') && this.get('signer.document.distributionMethod') === 'manual';
    }),
    // private
    signerCanSign: Ember.computed('signer.status', 'signer.document.documentState', 'signer.document.signerSequencing', 'signer.document.nextSignerId', 'signer.id', function () {
      return this.get('signer.status') === 'pending' && this.get('signer.document.documentState') === 'pending' && (!this.get('signer.document.signerSequencing') || this.get('signer.document.nextSignerId') === this.get('signer.id'));
    }),
    canModifyEmail: Ember.computed('signer.status', 'signer.document.documentState', 'signer.document.currentUserIsSender', function () {
      return this.get('signer.document.currentUserIsSender') && this.get('signer.status') === 'pending' && this.get('signer.document.documentState') === 'pending';
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      redirectToSign: function redirectToSign() {
        var _this = this;

        this.set('loading', true);
        var signerId = this.get('signer.id');
        return this.ajax.request("signers/".concat(signerId, "/session_authenticate")).then(function (response) {
          var token = response.access_token;
          var kbaParam = _this.get('signer.document.kba') ? '&kba=true' : '';
          var signUrl = "/signers/".concat(signerId, "/sign?access_token=").concat(token).concat(kbaParam);
          window.location.href = signUrl;
        }).catch(function (error) {
          _this.flashMessages.warning(_this.i18n.t('components.document.signerRow.signerAuthFailed', {
            error: error
          }));
        }).finally(function () {
          _this.set('loading', false);
        });
      },
      remind: function remind(myself) {
        var _this2 = this;

        this.set('loading', true);
        var signerId = this.get('signer.id');
        return this.ajax.put("signers/".concat(signerId, "/remind")).then(function (response) {
          if (myself) {
            _this2.sendAction('showRemindMyselfModal', _this2.get('signer.email'));
          } else {
            _this2.set('remindersRemaining', response.remaining - 1);

            _this2.flashMessages.success(_this2.i18n.t('components.document.signerRow.reminderSuccess', {
              name: _this2.get('signer.name')
            }));
          }
        }).catch(function () {
          _this2.set('remindersRemaining', 0);

          _this2.flashMessages.warning(_this2.i18n.t('components.document.signerRow.reminderFailed', {
            name: _this2.get('signer.name')
          }));
        }).finally(function () {
          _this2.set('loading', false);
        });
      },
      generateSignerLink: function generateSignerLink() {
        var _this3 = this;

        this.set('loading', true);
        return this.ajax.request("/signers/".concat(this.get('signer.id'), "/session_authenticate")).then(function (response) {
          var token = response.access_token;
          var router = Ember.getOwner(_this3).lookup('router:main');
          var path = router.generate('signer.sign', _this3.signer, {
            queryParams: {
              access_token: token
            }
          });
          var signingLink = "".concat(_environment.default.rsFullname).concat(path);

          _this3.set('signingLink', signingLink);
        }).catch(function (error) {
          _this3.flashMessages.warning(_this3.i18n.t('components.document.signerRow.generateLinkFailed', {
            error: error
          }));
        }).finally(function () {
          _this3.set('loading', false);
        });
      },
      resendIdentityVerification: function resendIdentityVerification() {
        var _this4 = this;

        this.set('loading', true);
        return this.ajax.request("signers/".concat(this.get('signer.id'), "/resend_identity_verification")).then(function () {
          _this4.flashMessages.success(_this4.i18n.t('components.document.signerRow.resendIdentityVerification'));
        }).catch(function (error) {
          _this4.flashMessages.warning(_this4.i18n.t('components.document.signerRow.resendingFailed', {
            error: error
          }));
        }).finally(function () {
          _this4.set('loading', false);
        });
      },
      toggleModifyEmailModal: function toggleModifyEmailModal() {
        this.toggleProperty('showModifyEmailModal');
      },
      modifySignerEmail: function modifySignerEmail() {
        this.set('loading', true);
        this.set('showModifyEmailModal', true);
        this.set('loading', false);
      },
      copyLinkToClipboardSuccess: function copyLinkToClipboardSuccess() {
        var message = this.i18n.t(COPY_LINK_SUCCESS_TRANSLATION);
        this.flashMessages.success(message);
      },
      copyLinkToClipboardError: function copyLinkToClipboardError(e) {
        console.error(e);
        var message = this.i18n.t(COPY_LINK_ERROR_TRANSLATION);
        this.flashMessages.danger(message);
      }
    }
  });

  _exports.default = _default;
});