define("ember-rs/templates/onboarding/account-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ITSHc4d",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"onboarding-container account \",[30,[36,4],[[35,3,[\"model\",\"account\",\"isTrial\"]],\"user-in-trial\"],null],\" \",[30,[36,4],[[35,3,[\"model\",\"account\",\"isHybrisTrial\"]],\"user-in-trial\"],null]]]],[12],[2,\"\\n\"],[6,[37,4],[[35,5,[\"useMobileLayout\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,0],1,\"onboarding/account-onboarding-2\",\"nextStep\",[30,[36,2],[\"onboarding.accountOnboarding.nextStep\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@navItems\",\"@nextStepText\"],[[34,0],1,\"onboarding/account-onboarding-1\",\"nextStep\",[34,1],[30,[36,2],[\"onboarding.accountOnboarding.nextStep\"],null]]],null],[2,\"\\n\\n    \"],[8,\"on-boarding-step\",[],[[\"@currentStep\",\"@stepNumber\",\"@stepPartial\",\"@nextStep\",\"@nextStepText\"],[[34,0],2,\"onboarding/account-onboarding-2\",\"nextStep\",[30,[36,2],[\"onboarding.accountOnboarding.nextStep\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currentStep\",\"accountNavItems\",\"t\",\"currentUser\",\"if\",\"layoutSizeData\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/account-onboarding.hbs"
    }
  });

  _exports.default = _default;
});