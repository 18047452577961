define("ember-rs/templates/components/signer-alert-on-edit-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OF/8uPXu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[14,0,\"signer-alert-on-edit-modal__header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.signerAlertonEditModal.header\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"p\"],[14,0,\"signer-alert-on-edit-modal__info\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"components.signerAlertonEditModal.info\"],[[\"senderEmail\"],[[35,1]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"signer-alert-on-edit-modal__actions\"],[12],[2,\"\\n  \"],[8,\"rs-button\",[],[[\"@action\"],[[30,[36,2],[[32,0],\"signerAlert\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"components.signerAlertonEditModal.gotIt\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"senderEmail\",\"action\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/signer-alert-on-edit-modal.hbs"
    }
  });

  _exports.default = _default;
});