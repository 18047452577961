define("ember-rs/templates/document-transactions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GV/y3HiD",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"document_transactions.new.create_new\"],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/document-transactions/new.hbs"
    }
  });

  _exports.default = _default;
});