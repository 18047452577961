define("ember-rs/locales/fr/templates/components/unconfirmed-user-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unconfirmedUserButton = void 0;
  var unconfirmedUserButton = {
    "emailUnverified": "Votre adresse e-mail n'a pas été vérifiée.",
    "verifySendDocument": "Avant de pouvoir envoyer votre premier document, nous devons vérifier votre adresse e-mail.",
    "verifyCreateTemplate": "Avant de pouvoir créer votre premier modèle, nous devons vérifier votre adresse e-mail.",
    "verifyUpgrade": "Avant de pouvoir mettre à niveau votre compte hybris, nous devons vérifier votre adresse e-mail.",
    "verifyIntegration": "Avant de pouvoir activer une intégration, nous devons vérifier votre adresse e-mail.",
    "verifyEmail": "Nous avons envoyé un e-mail de vérification à l'adresse ci-dessous.",
    "checkEmail": "Vous n'avez pas reçu l'e-mail ? Vérifiez votre dossier de spam ou",
    "resend": "Renvoyer",
    "verificationMail": "Un e-mail de vérification a été envoyé. Consultez {{{email}}} et suivez les instructions."
  };
  _exports.unconfirmedUserButton = unconfirmedUserButton;
});