define("ember-rs/locales/en/templates/components/assign-reusable-template-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assignReusableTemplateRole = void 0;
  var assignReusableTemplateRole = {
    "omitted": "omitted",
    "undo": "undo"
  };
  _exports.assignReusableTemplateRole = assignReusableTemplateRole;
});