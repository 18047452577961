define("ember-rs/templates/components/csv-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0RSEFqL3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"form\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,0,\"file-uploader\"],[14,3,\"file\"],[14,\"accept\",\".csv,text/csv,.excel,.comma-separated-values\"],[14,4,\"file\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/csv-uploader.hbs"
    }
  });

  _exports.default = _default;
});