define("ember-rs/services/redirect-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setValue: function setValue(token) {
      this.set('value', token);
    },
    getValue: function getValue() {
      return this.value;
    },
    hasValue: function hasValue() {
      return Ember.isPresent(this.getValue());
    }
  });

  _exports.default = _default;
});