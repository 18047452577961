define("ember-rs/locales/zh-cn/templates/signer/sign", ["exports", "ember-rs/locales/zh-cn/templates/signer/sign/identify", "ember-rs/locales/zh-cn/templates/signer/sign/locked-modal", "ember-rs/locales/zh-cn/templates/signer/sign/verify"], function (_exports, _identify, _lockedModal, _verify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    identify: _identify.identify,
    lockedModal: _lockedModal.lockedModal,
    verify: _verify.verify,
    "pinSent": "我们向您发送了一个 4 位数的验证 PIN 码。",
    "pin": "PIN:",
    "submit": "提交",
    "back": "上一步",
    "deliverPin": "以向您提供一个 4 位数验证 PIN 码以便您在下一页输入。",
    "afterPhoneComplete": "输入有效的电话号码后，我们将通过 {{{identityActionName}}} 向您发送一个 4 位数的验证 PIN 码，以供您在以下页面上输入。",
    "phone": "打电话",
    "sms": "短信",
    "countryCode": "国家/地区代码:",
    "phoneNumber": "电话号码:",
    "me": "我",
    "iAgreeComplete": "我同意在执行此文档时支付 <strong>{{{formattedMoney}}} (USD)</strong>。",
    "usd": " (美元)",
    "uponExecution": " 。",
    "clickSubmit": "单击“提交”即表示您同意我们的",
    "privacyPolicy": "隐私政策",
    "consent": "电子签名同意书",
    "contents": "，以及本文档的内容。",
    "saveSuccess": "已成功保存您的进度。可以通过单击电子邮件中的链接来检索此文档。",
    "submitButton": "提交"
  };
  _exports.sign = sign;
});