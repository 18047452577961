define("ember-rs/locales/en/routes/start-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startDocument = void 0;
  var startDocument = {
    "embeddedTemplate": "Your account does not have access to this feature. Please upgrade to a Pro plan to begin using embedded templates.",
    "inPerson": "Your account does not have access to this feature. Please upgrade to a Pro plan to begin using InPerson Signing."
  };
  _exports.startDocument = startDocument;
});