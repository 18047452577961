define("ember-rs/locales/zh-cn/routes/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "signer1": "signer1",
    "prospect": "prospect",
    "client": "client"
  };
  _exports.addRoles = addRoles;
});