define("ember-rs/locales/pt-br/templates/components/overlay-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.overlayComponent = void 0;
  var overlayComponent = {
    "mergeField": "Campo de mesclagem:",
    "signature": "Assinatura",
    "date": "Data",
    "dateAutoFill": "Date Signed",
    "initial": "Inicial",
    "select": "Selecionar",
    "text": "Texto",
    "attachment": "Anexo",
    "authorize": "Autorizar pagamento",
    "collect": "Obter cartão de crédito"
  };
  _exports.overlayComponent = overlayComponent;
});