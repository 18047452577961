define("ember-rs/locales/nl/templates/start-document/-send-for-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendForSignature = void 0;
  var sendForSignature = {
    "support": "Neem <a href='https://support.citrix.com/cscase'>contact op met Support</a> als u hulp nodig hebt."
  };
  _exports.sendForSignature = sendForSignature;
});