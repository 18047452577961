define("ember-rs/components/account-users/edit", ["exports", "jquery", "ember-rs/config/environment", "ember-rs/helpers/is-valid-email", "ember-rs/utils/safe-access"], function (_exports, _jquery, _environment, _isValidEmail, _safeAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function isValidPassword(password) {
    password = password || '';
    return password.length >= 8 && /\d/.test(password) && /[A-Z]/.test(password) && /[a-z]/.test(password);
  }

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    resettingPassword: false,
    errors: Ember.computed(function () {
      return {};
    }),
    loading: false,
    ////////////////////////  PROPERTIES  ////////////////////////
    isCurrentUser: Ember.computed('accountUser.id', 'currentUser.id', function () {
      return Ember.get(this, 'currentUser.id') === Ember.get(this, 'accountUser.id');
    }),
    canEdit: Ember.computed('loading', 'isCurrentUser', 'canEditOther', function () {
      return !this.loading && (this.isCurrentUser || this.canEditOther);
    }),

    /**
    * Editing Users:
    *
    * 1. Only Master Admins have the ability to view and grant other admins the ability to
    *   toggle CanManageUsers for other account users.  This is possible via the CanDelegateUsers role.
    *
    * 2. Account admins that have CanDelegateUsers can toggle CanManageUsers for other users as
    *    well as remove a user.
    *
    * 3. Account admins who have the CanManageUsers permission can toggle all other permissions
    *    but cannot remove a user or toggle the CanManageUsers permission for other users unless
    *    they have CanDelegateUsers
    */
    canEditOther: Ember.computed('loading', 'isCurrentUser', 'currentUser.isMasterAdmin', 'currentUser.canManageUsers', 'accountUser.isMasterAdmin', function () {
      return !this.loading && !this.isCurrentUser && (Ember.get(this, 'currentUser.isMasterAdmin') || Ember.get(this, 'currentUser.canManageUsers') && !Ember.get(this, 'accountUser.isMasterAdmin'));
    }),
    ////////////////////////  METHODS  ////////////////////////
    closeModal: function closeModal() {
      Ember.setProperties(this, {
        resettingPassword: false,
        errors: {},
        loading: false
      });
      this.sendAction('closeAction');
    },
    validateUser: function validateUser() {
      if (Ember.get(this, 'accountUser.canDelegateUsers') && !Ember.get(this, 'accountUser.canManageUsers')) {
        this.flashMessages.warning(this.i18n.t('components.account-users.edit.validate-user-warning-message'));
      }

      var errors = {
        firstName: Ember.isEmpty(Ember.get(this, 'accountUser.firstName')),
        lastName: Ember.isEmpty(Ember.get(this, 'accountUser.lastName')),
        email: !(0, _isValidEmail.isValidEmail)(Ember.get(this, 'accountUser.email')),
        oldPassword: this.resettingPassword && this.isCurrentUser && Ember.isEmpty(Ember.get(this, 'accountUser.oldPassword')),
        newPassword: this.resettingPassword && !isValidPassword(Ember.get(this, 'accountUser.newPassword')),
        delegateUsers: Ember.get(this, 'accountUser.canDelegateUsers') && !Ember.get(this, 'accountUser.canManageUsers')
      };
      Ember.set(this, 'errors', errors);
      var response = false;

      _jquery.default.each(errors, function (key, value) {
        if (value) {
          response = true;
        }
      });

      return response;
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      cancelEdit: function cancelEdit() {
        this.accountUser.rollbackAttributes();
        this.closeModal();
      },
      saveUser: function saveUser() {
        var _this = this;

        if (this.validateUser()) {
          return this.errors;
        }

        Ember.set(this, 'loading', true);
        return this.accountUser.save().then(function (user) {
          _this.flashMessages.success(_this.i18n.t('components.account-users.edit.save-user-success-message', {
            userName: Ember.get(user, 'name')
          }));

          if (Ember.get(user, 'id') === Ember.get(_this, 'currentUser.id')) {
            Ember.get(_this, 'currentUser.model').setProperties(Ember.getProperties(user, 'name', 'sharefileRoles', 'canManageUsers', 'canManageBranding', 'canManageTemplates', 'canViewAllDocuments', 'canDelegateUsers', 'canViewBills'));
          }

          Ember.setProperties(_this, {
            'accountUser.oldPassword': null,
            'accountUser.newPassword': null
          });

          _this.closeModal();
        }, function (response) {
          var error = (0, _safeAccess.default)(response, {}, 'errors', 0);

          if (error.status === '410' && /user no longer exists/i.test(error.detail)) {
            _this.flashMessages.warning(_this.i18n.t('components.account-users.edit.save-user-warning-message', {
              errorMessage: error.detail
            }));

            _this.accountUser.unloadRecord();

            _this.closeModal();
          } else {
            Ember.set(_this, 'loading', false);
          }
        });
      },
      resendWelcome: function resendWelcome() {
        var _arguments = arguments,
            _this2 = this;

        Ember.set(this, 'loading', true);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.getJSON("".concat(_environment.default.api.endpoint, "/account/users/").concat(Ember.get(_this2, 'accountUser.id'), "/resend_welcome")).then(function (response) {
            _this2.flashMessages.success(_this2.i18n.t("components.account-users.edit.resent-welcome-email-success-message", {
              email: _this2.get('accountUser.email')
            }));

            _this2.closeModal();

            resolve(response);
          }, function () {
            Ember.set(_this2, 'loading', false);
            reject.apply(void 0, _toConsumableArray(_arguments));
          });
        }, 'resend welcome to a sharefile user');
      }
    }
  });

  _exports.default = _default;
});