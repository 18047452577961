define("ember-rs/templates/components/zero-clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "otgtpf9p",
    "block": "{\"symbols\":[],\"statements\":[[3,\" because you don't want a button \"],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"confirmed-copy\"],[12],[2,\"Copied to clipboard\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"a\"],[15,0,[34,0]],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"innerClass\",\"label\",\"copied\",\"if\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/zero-clipboard.hbs"
    }
  });

  _exports.default = _default;
});