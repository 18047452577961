define("ember-rs/locales/it/templates/components/document-download-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDownloadOptions = void 0;
  var documentDownloadOptions = {
    "originalPDF": "PDF originale",
    "completedPDF": "PDF completato",
    "overlay": "PDF con sovrapposizione stampabile",
    "failed": "PDF completato (operazione non riuscita)",
    "support": "Si è verificato un problema durante l'elaborazione del documento. Contattare l'assistenza.",
    "cert": "Certificato di firma",
    "mergedPDF": "PDF combinato"
  };
  _exports.documentDownloadOptions = documentDownloadOptions;
});