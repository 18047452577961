define("ember-rs/locales/nl/templates/components/document/attachment-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.attachmentRow = void 0;
  var attachmentRow = {
    "filePending": "Bestand in behandeling"
  };
  _exports.attachmentRow = attachmentRow;
});