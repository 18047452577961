define("ember-rs/locales/nl/templates/signer/passcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcode = void 0;
  var passcode = {
    "enterPin": "Voer PIN in.",
    "access": "Voer de verstrekte PIN in om toegang te krijgen tot het document.",
    "unlock": "Ontgrendelen",
    "docProtected": "Dit document is beveiligd met een wachtwoordcode.",
    "answer": "antwoord...",
    "show": "wachtwoordcode weergeven",
    "continue": "Doorgaan naar document",
    "answerQuestion": "Beantwoord de beveiligingsvraag hieronder om toegang te krijgen tot het document"
  };
  _exports.passcode = passcode;
});