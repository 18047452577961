define("ember-rs/locales/nl/templates/components/template/template-signer-link-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.templateSignerLinkItem = void 0;
  var templateSignerLinkItem = {
    "view": "Weergeven",
    "details": "Details",
    "reenable": "Opnieuw inschakelen",
    "disable": "Uitschakelen",
    "signed": "ondertekend",
    "identityMethod": "Identiteitsmethode: ",
    "expires": "Verloopt: ",
    "passcode": "Beveiligd met wachtwoordcode: ",
    "maxSigners": "Max. aantal ondertekenaars: ",
    "doesNotExpire": "Verloopt niet"
  };
  _exports.templateSignerLinkItem = templateSignerLinkItem;
});