define("ember-rs/mixins/overlay-component", ["exports", "ember-rs/mixins/component", "ember-rs/mixins/component-dragging"], function (_exports, _component, _componentDragging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_component.default, _componentDragging.default, {
    ////////////////////////  DEPENDENCIES  ////////////////////////
    overlayerData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    skipComponentSelection: false,
    ////////////////////////  HOOKS  ////////////////////////
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var previouslySigned = this.get('component.isValidSignatureAnnotation');

      if (this.componentType === 'signature-annotation-component' && !previouslySigned) {
        this.set('clicked', true);
      }

      this.$().on('mouseenter', function () {
        return _this.set('overlayerData.isHovering', true);
      });
      this.$().on('mouseleave', function () {
        return _this.set('overlayerData.isHovering', false);
      });
      this.$().on('click', function () {
        if (_this.skipComponentSelection) {
          _this.set('skipComponentSelection', false);
        } else if (_this.componentType === 'signature-annotation-component') {
          _this.set('clicked', true);
        } else {
          _this.sendAction('componentClicked', _this.component);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$().off('mouseenter mouseleave');
      this.$().off('click');
    },
    // Overwriting the default because we need to update the components position when the dragging stops
    onDragStop: function onDragStop(evt, ui) {
      this.set('component.x', parseFloat(ui.position.left) / this.get('documentViewerData.pageWidth'));
      this.set('component.y', parseFloat(ui.position.top) / this.get('documentViewerData.pageHeight'));
      this.set('overlayerData.isDragging', false);
      this.set('skipComponentSelection', true);
    },
    ////////////////////////  PROPERTIES  ////////////////////////
    // Used in the component dragging mixin
    dimensions: Ember.computed('component.width', 'component.height', 'component.x', 'component.y', function () {
      return this.getComponentDimensions(this.component);
    })
  });

  _exports.default = _default;
});