define("ember-rs/locales/fr/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pendingIdentityVerification = void 0;
  var pendingIdentityVerification = {
    "pendingIdentityVerification": "Vérification d'identité en attente",
    "success": "Vous avez signé ce document avec succès. Pour compléter ce document et le renvoyer à l'expéditeur, vous devrez vérifier votre identité. Pour ce faire, il vous suffit de consulter votre e-mail et de cliquer sur le lien « Vérifier e-mail » qui vous a été envoyé par RightSignature.",
    "resend": "Renvoyer vérification d'identité"
  };
  _exports.pendingIdentityVerification = pendingIdentityVerification;
});