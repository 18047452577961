define("ember-rs/locales/es/mixins/workflow-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.workflowType = void 0;
  var workflowType = {
    "signatureRequest": "Enviado por usted",
    "selfSign": "Autofirmado",
    "templateSignerLink": "A partir de la plantilla",
    "inPerson": "Firma en persona",
    "apiEmbedded": "API de firma incrustada",
    "bulkSend": "Enviar en bloque"
  };
  _exports.workflowType = workflowType;
});