define("ember-rs/locales/de/templates/components/signer/signer-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerInfo = void 0;
  var signerInfo = {
    "toSign": "Um dieses Dokument zu signieren, geben Sie bitte Ihren Namen und Ihre E-Mail-Adresse ein:",
    "name": "Name...",
    "proceed": "Weiter zum Dokument"
  };
  _exports.signerInfo = signerInfo;
});