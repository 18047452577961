define("ember-rs/components/persistent-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'persistent-modal',
    saveText: Ember.computed(function () {
      return this.i18n.t('shared.actions.save');
    }),
    cancelText: Ember.computed(function () {
      return this.i18n.t('shared.actions.cancel');
    }),
    saveDisabled: false,
    loading: false,
    actions: {
      cancelUpdates: function cancelUpdates() {
        this.sendAction('cancel');
      },
      saveUpdates: function saveUpdates() {
        this.sendAction('save');
      }
    }
  });

  _exports.default = _default;
});