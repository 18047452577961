define("ember-rs/locales/de/templates/components/document-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploader = void 0;
  var documentUploader = {
    "multiFile": "Das Hochladen mehrerer Dateien wird nicht unterstützt. Es wird nur die erste ausgewählte Datei hochgeladen.",
    "alreadyUploaded": "Sie haben diese Datei bereits hochgeladen.",
    "fileSize": "Die Datei '{{{fileName}}}' ist größer als das Limit von 20 MB.",
    "uploadFailed": "Fehler beim Upload"
  };
  _exports.documentUploader = documentUploader;
});