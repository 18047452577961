define("ember-rs/routes/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    router: Ember.inject.service(),
    titleToken: 'Reports',
    ////////////////////////  HOOKS  ////////////////////////
    model: function model() {
      return this.get('currentUser.account');
    },
    setupController: function setupController(controller, model) {
      controller.set('router', this.router);
      return this._super(controller, model);
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      reportPageChange: function reportPageChange(page) {
        this.transitionTo("reports.".concat(page));
      }
    }
  });

  _exports.default = _default;
});