define("ember-rs/routes/upgrade-worldpay", ["exports", "jquery", "ember-rs/config/environment", "ember-rs/utils/hybris-plan-details", "ember-rs/mixins/upgrade-worldpay"], function (_exports, _jquery, _environment, _hybrisPlanDetails, _upgradeWorldpay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_upgradeWorldpay.default, {
    currentUser: Ember.inject.service(),
    controller: {},
    fetchDiscount: function fetchDiscount() {
      var _this = this;

      this.controller.set('discountApiStatus', 'loading');
      new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax("".concat(_environment.default.api.endpoint, "/account/credit_amount"), {
          method: 'GET',
          global: false
        }).done(function (response) {
          _this.controller.setProperties({
            'creditAmount': response.CreditAmount,
            'discountApiStatus': 'fetched'
          });

          resolve(response);
        }).fail(function (response) {
          _this.controller.set('discountApiStatus', 'failed');

          reject(response);
        });
      }, 'Fetching RS3 credit amount');
    },
    fetchPrices: function fetchPrices(seats) {
      var _this2 = this;

      this.controller.set('basePriceApiStatus', 'loading');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var promises = [];
        var data = [];
        var controller = _this2.controller;

        _hybrisPlanDetails.billingOptions.forEach(function (billingOption, index) {
          promises[index] = _jquery.default.ajax({
            type: 'POST',
            url: "".concat(_environment.default.api.endpoint, "/account/base_price"),
            global: false,
            data: {
              plan: _this2.controller.get('selectedPlanId'),
              users: seats ? seats : _this2.controller.get('selectedSeats'),
              cycle: billingOption.name
            }
          }).then(function (response) {
            data.push(response);
          }).catch(function (error) {
            reject(error);
          });
        }, 'Fetching plan base values');

        Ember.RSVP.all(promises).then(function () {
          var newBillingOptions = Ember.copy(_hybrisPlanDetails.billingOptions, true);

          if (data.length < 3) {
            controller.set('basePriceApiStatus', 'failed');
            return;
          }

          data.forEach(function (datum) {
            return newBillingOptions.findBy('name', datum.cycle).value = datum.BasePrice;
          });
          controller.setProperties({
            'billingOptions': newBillingOptions,
            'basePriceApiStatus': 'fetched'
          });
          resolve(data);
        });
      });
    },
    setControllerProperties: function setControllerProperties() {
      if (this.controller.upgradeApiError) {
        this.prefillBillingDetails();
      } else {
        this.setDefaultSeats();
      }
    },
    prefillBillingDetails: function prefillBillingDetails() {
      var billingDetails = this.fetchBillingDetails();
      this.controller.setProperties({
        'billingFrequency': billingDetails.cycle,
        'selectedPlanId': billingDetails.plan,
        'selectedSeats': billingDetails.users,
        'billing': billingDetails
      });
    },
    setDefaultSeats: function setDefaultSeats() {
      var defaultTeamMemberCount = this.controller.selectedSeats;

      if (defaultTeamMemberCount === '') {
        defaultTeamMemberCount = _hybrisPlanDetails.plans[1].defaultTeamMemberCount.toString();
      }

      var selectedSeats = Math.max(this.controller.get('minimumSeats'), defaultTeamMemberCount);
      this.controller.set('selectedSeats', selectedSeats);
    },
    ////////////////////////  HOOKS  ////////////////////////
    beforeModel: function beforeModel(transition) {
      var orderId = transition.to.queryParams.orderId;
      var errorMessage = transition.to.queryParams.errorMessage;
      var driftkey = _environment.default.drift.driftKey;
      this.controllerFor('upgrade-worldpay').set('upgradeApiError', errorMessage);

      if (orderId) {
        this.controllerFor('upgrade-worldpay').set('orderId', orderId);
      } // Loading drift script


      window.drift.load(driftkey);
    },
    setupController: function setupController(controller, model) {
      var _this3 = this;

      this._super(controller, model);

      this.set('controller', controller);

      if (Ember.get(this, 'currentUser.account.isMigrated')) {
        Ember.get(this, 'currentUser.account.accountStatSet').then(function (response) {
          _this3.controller.set('minimumSeats', response.get('seatsUsed'));

          _this3.setControllerProperties();
        });
        this.fetchDiscount();
      } else {
        this.setControllerProperties();
      }
    },
    redirect: function redirect() {
      if (Ember.get(this, 'currentUser.account.plan') === "RightSignature for SFPrm" && Ember.get(this, 'currentUser.account.isHybrisTrial')) {
        this.transitionTo('documents');
      }

      if (!Ember.get(this, 'currentUser.account.isHybrisTrial')) {
        this.transitionTo('documents');
      }
    },
    deactivate: function deactivate() {
      // if (window.drift.apiReady) {
      //   window.drift.api.widget.hide();
      // }
      window.drift.unload();
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      updatePrices: function updatePrices() {
        this.fetchPrices();
      },
      updateDefaultPrices: function updateDefaultPrices(seats) {
        this.fetchPrices(seats);
      },
      upgradeAccount: function upgradeAccount(billingDetails) {
        var _this4 = this;

        this.upgradeToHybris(billingDetails).catch(function (errorMessage) {
          _this4.controller.set('upgradeApiError', errorMessage);

          _this4.controller.set('isLoading', false);
        });
      },
      redirectToWorldpay: function redirectToWorldpay(billingDetails) {
        var _this5 = this;

        _jquery.default.ajax({
          type: 'POST',
          url: "".concat(_environment.default.api.endpoint, "/account/cc_gateway_url"),
          data: billingDetails,
          global: false,
          success: function success(response) {
            _this5.setBillingDetails(billingDetails);

            window.location = response.redirect_url;
          },
          error: function error(_error) {
            if (_error.status.toString().match(/4[0-9][0-9]/)) {
              _this5.controller.set('upgradeApiError', _error.responseText);
            } else {
              _this5.controller.set('upgradeApiError', 'Data is not available at this moment. Please come back to this page later.');
            }

            _this5.controller.set('isLoading', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});