define("ember-rs/locales/nl/templates/signer/sign/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identify = void 0;
  var identify = {
    "advisory": "De afzender van dit document heeft de op kennis gebaseerde verificatiefunctie van RightSignature ingeschakeld om uw identiteit te verifiëren. RightSignature maakt gebruik van de externe services van BlockScore om de verstrekte persoonlijke informatie te valideren en te verifiëren. Het gebruik van de verstrekte persoonlijke informatie is beperkt tot het uitvoeren van de identiteitsvalidatie en -verificatie met de eigen gegevensrecords van BlockScore. De identiteitsvalidatie en -verificatie is niet van invloed op uw kredietrapport, kredietwaardigheid of rapportage aan kredietverstrekkers. Noch de IRS (Amerikaanse belastingdienst), Blockscore noch RightSignature hebben de mogelijkheid om inzicht in of toegang tot uw kredietrapport of uw belastinginformatie te krijgen in het kader van dit proces.",
    "step1": "Identiteitverificatie - Stap 1 van 2",
    "beforeSigning": "Voer uw persoonlijke gegevens in voordat u het document ondertekent om ons te helpen uw identiteit te verifiëren.",
    "notValid": "We konden helaas geen geldige identiteit vinden die met de verstrekte informatie overeenkomt. Controleer of de onderstaande informatie correct is en klik op Volgende om het opnieuw te proberen.",
    "youHave": "U hebt nog ",
    "remaining": " pogingen om toegang te krijgen tot het document.",
    "name": "Naam:",
    "dob": "Geboortedatum:",
    "ssn": "Laatste 4 cijfers van BSN:",
    "passport": "Paspoortnummer:",
    "address": "Adres:",
    "address2": "Adres 2:",
    "city": "Plaats:",
    "state": "Status:",
    "zip": "Postcode:",
    "country": "Land:",
    "return": "Terug naar het formulier",
    "advisory2": "Toelichting bij identiteitverificatie",
    "sampleAddress1": "120 S. West St.",
    "sampleAddress2": "Suite 100",
    "sampleAddress3": "Raleigh",
    "submit": "Indienen"
  };
  _exports.identify = identify;
});