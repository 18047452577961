define("ember-rs/transforms/array-of-objects", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(array) {
      if (Ember.isArray(array)) {
        var output = Ember.A();
        array.forEach(function (item) {
          output.pushObject(Ember.Object.create(item));
        });
        return output;
      } else {
        return Ember.A();
      }
    },
    serialize: function serialize(array) {
      if (Ember.isArray(array)) {
        var output = Ember.A();
        array.forEach(function (item) {
          output.pushObject(Ember.Object.create(item));
        });
        return output;
      } else {
        return Ember.A();
      }
    }
  });

  _exports.default = _default;
});