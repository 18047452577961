define("ember-rs/controllers/user/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    user: Ember.computed.alias('currentUser.model'),
    integrations: Ember.computed.alias('user.integrations'),
    contactServiceEnabled: Ember.computed('integrations.[]', function () {
      return this.get('user.integrations').isAny('contacts', true);
    })
  });

  _exports.default = _default;
});