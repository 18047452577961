define("ember-rs/locales/en/templates/document-transaction/review-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_mobile = void 0;
  var review_mobile = {
    "review_details": "review details for:",
    "reusable_template": "Reusable Template",
    "distribution_method": "Distribution Method:",
    "identity_method": "Identity Method:",
    "save_doc": "Save Document",
    "send_doc": "Send Document"
  };
  _exports.review_mobile = review_mobile;
});