define("ember-rs/components/main-footer", ["exports", "ember-rs/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'main-footer',
    tagName: 'footer',
    csgHomePage: _environment.default.csgHomePage,
    currentYear: new Date().getFullYear()
  });

  _exports.default = _default;
});