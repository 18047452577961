define("ember-rs/locales/it/templates/onboarding/-agree-to-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.agreeToTerms = void 0;
  var agreeToTerms = {
    "title": "Termini di servizio",
    "body": "Utilizzando RightSignature&trade; si certifica di aver letto i nostri {{partial 'terms-of-use-link'}} e l'<a href='{{csgHomePage}}/privacy-policy' target='_blank'>Informativa sulla privacy</a> e di aver fornito il consenso.",
    "noThanks": "No grazie",
    "iAgree": "Accetto"
  };
  _exports.agreeToTerms = agreeToTerms;
});