define("ember-rs/routes/reports/export/month/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ////////////////////////  HOOKS  ////////////////////////
    model: function model(params) {
      return this.store.findRecord('reusableTemplate', params.template_id, {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        month: this.paramsFor('reports.export.month').month,
        columnsSelected: []
      });
      return this._super(controller, model);
    }
  });

  _exports.default = _default;
});