define("ember-rs/locales/it/mixins/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.settings = void 0;
  var settings = {
    "updateSuccessful": "Impostazione aggiornata correttamente",
    "updateError": "Errore durante l'aggiornamento dell'impostazione"
  };
  _exports.settings = settings;
});