define("ember-rs/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uTorZlqh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"content\"],[15,0,[31,[[30,[36,2],[[35,3,[\"showModal\"]],\"show-modal\"],null],\" \",[30,[36,2],[[35,1,[\"model\",\"account\",\"isHybrisTrial\"]],\"user-in-trial\"],null],\" \",[30,[36,2],[[35,1,[\"model\",\"account\",\"isTrial\"]],\"user-in-trial\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[35,3,[\"useMobileLayout\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"mobile-header\",[],[[\"@title\"],[[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"info-banner\",[],[[],[]],null],[2,\"  \\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,1,\"settings\"],[15,0,[31,[[30,[36,4],[[35,0]],null],\" settings-container\"]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sticky-footer-container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"settings-content\"],[12],[2,\"\\n\\n        \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"settingsPage\",\"currentUser\",\"if\",\"layoutSizeData\",\"lowercase\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/account.hbs"
    }
  });

  _exports.default = _default;
});