define("ember-rs/locales/it/controllers/account/branding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.branding = void 0;
  var branding = {
    "emailUpdateSuccess": "Impostazioni e-mail dell'utente aggiornate correttamente.",
    "emailUpdateFail": "Errore durante l'aggiornamento delle impostazioni e-mail dell'utente.",
    "updateSuccess": "Impostazioni di personalizzazione dell'account utente aggiornate correttamente.",
    "updateFail": "Errore durante l'aggiornamento delle impostazioni di personalizzazione dell'account utente."
  };
  _exports.branding = branding;
});