define("ember-rs/locales/es/templates/components/account-user/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.edit = void 0;
  var edit = {
    "basic": "Información básica",
    "firstName": "Nombre:",
    "lastName": "Apellido:",
    "required": "* obligatorio",
    "email": "Correo electrónico:",
    "emailTip": "* Debe introducir una dirección de correo electrónico válida.",
    "password": "Contraseña:",
    "hiddenForSecurity": "oculto por seguridad",
    "reset": "Restablecer",
    "emailInfo": "Enviar por correo la información de inicio de sesión",
    "oldPass": "Contraseña anterior:",
    "newPass": "Nueva contraseña:",
    "passReq": "* Las contraseñas deben contener al menos 8 caracteres, y contener al menos 1 número, 1 letra mayúscula y 1 letra minúscula.",
    "permissions": "Permisos",
    "manageUsers": "Administrar usuarios",
    "manageDelegateUsers": "Puede delegar usuarios",
    "manageViewBills": "Puede ver facturas",
    "customizeBranding": "Personalizar la marca de la cuenta",
    "manageTemplates": "Administrar plantillas",
    "viewDocuments": "Ver todos los documentos",
    "removeUser": "Quitar usuario",
    "reset2fa": "Restablecer verificación de dos pasos"
  };
  _exports.edit = edit;
});