define("ember-rs/templates/components/flash-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "azYrJqPc",
    "block": "{\"symbols\":[\"modalMessage\"],\"statements\":[[10,\"ul\"],[14,0,\"messages\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[15,0,[31,[\"flash-message \",[34,0,[\"type\"]]]]],[12],[1,[32,1]],[2,\" \"],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[11,\"div\"],[24,0,\"dismiss\"],[4,[38,5],[[32,0],\"dismiss\",[35,0]],null],[12],[1,[30,[36,6],[\"components.flashModal.close\"],null]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"flash\",\"modalClasses\",\"modalMessages\",\"-track-array\",\"each\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/flash-modal.hbs"
    }
  });

  _exports.default = _default;
});