define("ember-rs/locales/de/templates/onboarding/signer-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.signerOnboarding1 = void 0;
  var signerOnboarding1 = {
    "redFlag": "Die <span>rote Markierung</span> weist auf Bereiche hin, die ausgefüllt werden müssen.",
    "zoom": "Auf dem Telefon können Sie durch Zusammenführen und Auseinanderdrücken der Finger das Dokument vergrößern."
  };
  _exports.signerOnboarding1 = signerOnboarding1;
});