define("ember-rs/locales/es/templates/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "send": "Enviar para firma",
    "notAllowed": " Su plan actual no le permite enviar documentos. Actualice el plan para acceder a esta función.",
    "embed": "Incrustar en el sitio Web",
    "share": "Generar enlace para compartir",
    "manageLinks": "Administrar enlaces e incrustación",
    "viewResponses": "Ver respuestas",
    "edit": "Modificar",
    "delete": "Eliminar",
    "roles": "Roles",
    "security": "Opciones de seguridad:",
    "expires": "Caduca dentro de:",
    "days": "días",
    "passcodeQuestion": "Pregunta del código de acceso:",
    "passcode": "Código de acceso:",
    "tags": "Etiquetas:",
    "linkHistory": "Historial de códigos de incrustación y enlaces",
    "linkHistoryTip": "Administre y vea la información de uso de todos los enlaces e incrustaciones de esta plantilla.",
    "generate": "Generar enlace para compartir",
    "nameLink": "Nombre este enlace de recurso compartido.",
    "nameLinkTip": "Esto puede facilitar la gestión de los enlaces.",
    "whichSigner": "¿Qué rol de firmante?",
    "whichSignerTip": "Las plantillas y enlaces incrustados solo pueden tener un único firmante.",
    "limitUses": "¿Limitar la cantidad de usos?",
    "limitUsesTip": "Establezca un límite para la cantidad de veces que se puede firmar este enlace.",
    "expireDays": "¿Caducar al cabo de varios días?",
    "expireDaysTip": "Una vez caducado, este enlace ya no funcionará.",
    "identityMethod": "Método de identidad:",
    "identityMethodTip": "¿Cómo debemos verificar la identidad de los firmantes?",
    "generateLink": "Generar enlace",
    "generateEmbed": "Generar código incrustado",
    "nameCode": "Nombre este código.",
    "nameCodeTip": "Esto puede facilitar la gestión de los códigos generados.",
    "displayType": "Tipo de visualización",
    "displayTypeTip": "Esto especifica cómo debe aparecer la incrustación",
    "pixel": "px",
    "codeGenerated": "Código generado",
    "linkGenerated": "Enlace generado",
    "clickingManage": "haciendo clic en \"Administrar enlaces e incrustaciones\" en la página de la plantilla.",
    "youCanAccessCode": "Puede acceder, inhabilitar y modificar este código haciendo clic en “Administrar enlaces e incrustación” en la página de la plantillas.",
    "youCanAccessLink": "Puede acceder, inhabilitar y modificar este enlace haciendo clic en “Administrar enlaces e incrustación” en la página de la plantillas.",
    "newPin": "Nuevo PIN",
    "done": "Listo",
    "email": "Correo electrónico",
    "sms": "SMS",
    "none": "Ninguno",
    "height": "Altura",
    "Width": "Ancho",
    "button": "button",
    "image": "imagen",
    "inline": "integrado",
    "createdBy": "Creada por",
    "actions": "Acciones",
    "visibility-toggle-text": "Establecer plantilla como privada",
    "visibility-toogle-info": "Al establecer la opción privada, solo los creadores de cada plantilla podrán verlas",
    "template-signer-link": {
      "passcode-message": "La función del código de acceso se habilitó para esta cuenta, pero no se aplicará a enlaces de uso compartido o documentos insertados. Este código de enlace o inserción permitirá el acceso a este documento sin el código de acceso."
    },
    "embed-in-website": {
      "disabled-merge-fields": "Los códigos de incrustación no están disponibles para esta plantilla actualmente. Para generar un código de incrustación para esta plantilla, primero elimine todos los campos de combinación requeridos.",
      "disabled-no-online-forms": "Su plan actual no le permite incrustar plantillas. Actualice el plan para acceder a esta función."
    },
    "generate-share-link": {
      "disabled-merge-fields": "Los códigos de incrustación no están disponibles para esta plantilla actualmente. Para generar un código de incrustación para esta plantilla, primero elimine todos los campos de combinación requeridos.",
      "disabled-no-manual-distribution": "Su plan actual no le permite generar enlaces de uso compartido. Actualice el plan para acceder a esta función."
    }
  };
  _exports.details = details;
});