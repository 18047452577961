define("ember-rs/locales/it/controllers/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "upgradeMsg": "L'account non ha accesso a questa funzione. Eseguire l'aggiornamento a un piano Pro per iniziare a utilizzare i modelli incorporati.",
    "templateDeleteMsg": "Eliminazione del modello completata.",
    "tagUpdateMsg": "Aggiornamento dei contrassegni completato",
    "tagUpdateFailedMsg": "Impossibile aggiornare i contrassegni: {{{formattedResponse}}}",
    "templateDeleteConfirm": "Eliminare questo modello?",
    "loadSignerLinksFailedMsg": "Impossibile caricare i collegamenti del firmatario del modello"
  };
  _exports.details = details;
});