define("ember-rs/locales/zh-cn/templates/onboarding/migration-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.migrationOnboarding = void 0;
  var migrationOnboarding = {
    "migrationHeading": "探索最新的 RightSignature!",
    "slide1Q": "新增功能",
    "slide1a": "高级安全功能:",
    "slide1b": "基于知识的身份验证、可强制执行的通行码访问等。",
    "slide1c": "增强的可用性:",
    "slide1d": "全新的简化布局、增强的可定制性、生产力通知和改进的移动功能。",
    "slide2": "来自旧版 RightSigntature 的文档在哪里?",
    "slide3": "来自旧版 RightSigntature 的模板在哪里?",
    "slide4": "我如何在最新的 RightSignature 上转换为付费计划?",
    "continue": "继续",
    "dismiss": "消除"
  };
  _exports.migrationOnboarding = migrationOnboarding;
});