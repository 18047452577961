define("ember-rs/controllers/archived-document/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var stateIconsForState = {
    voided: 'fa-ban',
    pending: 'fa-circle-o',
    executed: 'fa-check-circle',
    expired: 'fa-clock-o',
    declined: 'fa-times-circle'
  };

  var _default = Ember.Controller.extend({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    layoutSizeData: Ember.inject.service(),
    documentViewerData: Ember.inject.service(),
    paymentComponentData: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    //////////////////////////  DEFAULTS  //////////////////////////
    queryParams: ['signerPaymentId', 'activePanel'],
    ////////////////////////  PROPERTIES  ////////////////////////
    overviewPanelIsActive: Ember.computed('activePanel', function () {
      return this.activePanel === 'overview' || !this.activePanel;
    }),
    historyPanelIsActive: Ember.computed.equal('activePanel', 'history'),
    pageCount: Ember.computed.readOnly('model.upload.baseFile.pageCount'),
    hasShared: Ember.computed.notEmpty('model.sharedWith'),
    currentUserIsSender: Ember.computed('model.userId', 'currentUser.model.id', function () {
      return this.get('model.userId') === this.get('currentUser.model.id');
    }),
    canManageDocument: Ember.computed('model.userId', 'currentUser.id', function () {
      return Ember.isPresent(this.get('currentUser.id')) && this.get('model.userId') === this.get('currentUser.id');
    }),
    showMobileSidebar: false,
    showDesktopSidebar: true,
    isShowingEditSigner: false,
    showDeleteModal: false,
    initializeSignerPaymentModal: Ember.observer('signerPaymentId', function () {
      if (Ember.isPresent(this.signerPaymentId)) {
        var signerPayment = this.store.peekRecord('signerPayment', this.signerPaymentId);

        if (signerPayment) {
          this.set('paymentComponentData.selectedSignerPayment', signerPayment);
          this.set('paymentComponentData.showSignerPaymentModal', true);
          this.set('signerPaymentId', null);
        }
      }
    }),
    stateIcon: Ember.computed('model.documentState', function () {
      return (stateIconsForState[this.get('model.documentState')] || 'fa-circle-o').htmlSafe();
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      closeDocumentAccessSettings: function closeDocumentAccessSettings() {
        this.get('model.signers').filterBy('hasDirtyAttributes', true).forEach(function (signer) {
          return signer.rollbackAttributes();
        });
        this.set('showAccessSettings', false);
        this.set('isShowingEditSigner', false);
      },
      toggleSidebar: function toggleSidebar() {
        var sideBarMode = window.innerWidth <= 1024 ? 'showMobileSidebar' : 'showDesktopSidebar';
        this.toggleProperty(sideBarMode);
      },
      documents: function documents() {
        this.transitionToRoute('documents', {
          queryParams: {
            startingPage: this.get('model.dashboardPage')
          }
        });
      },
      changeActivePanel: function changeActivePanel(panelName) {
        this.set('activePanel', panelName);
      },
      toggleEditor: function toggleEditor(editor) {
        if (!this.currentUserIsSender) {
          this.flashMessages.warning("You are not allowed to do that.");
          return;
        } // pop up the sharing modal


        this.toggleProperty(editor);
      },
      addViewer: function addViewer(email) {
        this.get('model.sharedWith').push(email);
        this.send('saveViewers');
      },
      removeViewer: function removeViewer(email) {
        var updatedViewers = this.get('model.sharedWith').filter(function (viewer) {
          return viewer !== email;
        });
        this.set('model.sharedWith', updatedViewers);
        this.send('saveViewers');
      },
      saveViewers: function saveViewers() {
        var _this = this;

        var archived_doc = this.model;
        archived_doc.updateSharedWith({
          shared_with: this.get('model.sharedWith')
        }).then(function () {
          _this.flashMessages.success(_this.i18n.t('controllers.document.details.viewerListUpdated'));

          _this.model.reload();
        }).catch(function (error) {
          _this.flashMessages.warning(_this.i18n.t('controllers.document.details.viewerUpdateFailed', {
            viewers: JSON.stringify(error)
          }));

          console.error(error);
        });
      },
      toggleShowDeleteModal: function toggleShowDeleteModal() {
        this.toggleProperty('showDeleteModal');
      }
    }
  });

  _exports.default = _default;
});