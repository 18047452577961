define("ember-rs/helpers/status-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statusIcon = statusIcon;
  _exports.default = void 0;
  var ICONS_MAPPING = {
    pending: 'fa-circle-o',
    approved: 'fa-check-circle',
    rejected: 'fa-ban',
    banned: 'fa-ban'
  };

  function statusIcon(status) {
    return ICONS_MAPPING[status];
  }

  var _default = Ember.Helper.helper(statusIcon);

  _exports.default = _default;
});