define("ember-rs/locales/de/templates/components/document-upload-thumbnail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentUploadThumbnail = void 0;
  var documentUploadThumbnail = {
    "noThumb": "Keine Miniaturansicht...",
    "upload_done": "Upload abgeschlossen",
    "downloading": "Herunterladen",
    "storing": "Speichern",
    "converting_to_images": "Konvertierung in Bilder",
    "converting_to_pdf": "Konvertierung in PDF",
    "processing": "Verarbeiten",
    "created": "Erstellt"
  };
  _exports.documentUploadThumbnail = documentUploadThumbnail;
});