define("ember-rs/locales/zh-cn/templates/document/prepare-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepare_document = void 0;
  var prepare_document = {
    "prepare": "准备 ",
    "prepare_package": "准备文档包",
    "merge_field_message": "本文档包含需要您在发送之前填写的合并字段。",
    "merge_field_title": "填写合并字段",
    "missing_merge_fields": "必须填写所有必填合并字段。"
  };
  _exports.prepare_document = prepare_document;
});