define("ember-rs/routes/reusable-templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    sharefilePostMessage: Ember.inject.service(),
    wcsWorkflow: Ember.inject.service(),
    titleToken: 'Create Reusable Template',
    beforeModel: function beforeModel() {
      if (!Ember.get(this, 'currentUser.canSendDocuments') && !Ember.get(this, 'currentUser.canManageTemplates')) {
        this.transitionTo('templates');
        return;
      }

      this.controllerFor('reusable-template').set('currentStep', 'documents');
    },
    actions: {
      create: function create() {
        var self = this;
        this.currentModel.save().then(function (rt) {
          self.transitionTo('reusable-template.add_roles', rt);
        });
      },
      close: function close() {
        if (this.wcsWorkflow.getValue()) {
          //iframed for WCS Workflow
          this.sharefilePostMessage.post({
            event_type: "template_creation_cancelled"
          });
        } else {
          this.transitionTo('templates');
        }
      },
      skipToPrepareDocument: function skipToPrepareDocument() {
        var self = this;
        this.currentModel.save().then(function (rt) {
          if (self.wcsWorkflow.getValue()) {
            //iframed for WCS Workflow
            var role;
            var dataSources = self.get("session.data.sharefileRedirect.dataSources");

            if (Array.isArray(dataSources) && dataSources[0] === 'client') {
              role = self.i18n.t('routes.reusableTemplate.addRoles.client').string;
            } else if (Array.isArray(dataSources) && dataSources[0] === 'prospect') {
              role = self.i18n.t('routes.reusableTemplate.addRoles.prospect').string;
            }

            rt.newRole({
              name: role
            });
            rt.save().then(function (rt) {
              self.transitionTo('reusable-template.prepare', rt);
            });
          } else {
            self.transitionTo('reusable-template.add_roles', rt);
          }
        });
      }
    },
    model: function model() {
      return this.store.createRecord('reusable-template', {
        'account': this.get('currentUser.account'),
        'user': this.get('currentUser.model'),
        roles: [],
        components: [],
        mergefieldValues: []
      });
    }
  });

  _exports.default = _default;
});