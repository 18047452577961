define("ember-rs/locales/ja/templates/unauthorized/pending-identity-verification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pendingIdentityVerification = void 0;
  var pendingIdentityVerification = {
    "pendingIdentityVerification": "保留中の ID 確認",
    "success": "このドキュメントの署名が完了しました。このドキュメントを完了して送信者に戻すには、自身の ID を確認する必要があります。メールを確認して、RightSignature から送信された [メールを確認] リンクをクリックしてください。",
    "resend": "ID 確認を再送信する"
  };
  _exports.pendingIdentityVerification = pendingIdentityVerification;
});