define("ember-rs/locales/de/templates/components/verified-email-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verifiedEmailRow = void 0;
  var verifiedEmailRow = {
    "verified": "verifiziert",
    "remove": "Diese E-Mail entfernen"
  };
  _exports.verifiedEmailRow = verifiedEmailRow;
});