define("ember-rs/components/delete-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flashMessages: Ember.inject.service(),
    ////////////////////////  DEFAULTS  ////////////////////////
    loading: false,
    ////////////////////////  METHODS  ////////////////////////
    closeModal: function closeModal() {
      this.sendAction('closeAction');
    },
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      cancelEdit: function cancelEdit() {
        this.closeModal();
      },
      deleteSignature: function deleteSignature() {
        var _this = this;

        this.set('loading', true);
        this.signature.destroyRecord().then(function () {
          _this.flashMessages.success(_this.i18n.t('components.deleteSignature.deleteSuccess'));

          _this.set('loading', false);

          _this.closeModal();
        }, function () {
          _this.flashMessages.warning(_this.i18n.t('components.deleteSignature.deleteFailed'));

          _this.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});