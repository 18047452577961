define("ember-rs/locales/ja/templates/reusable-template/add-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addRoles = void 0;
  var addRoles = {
    "addRoles": "役割を追加",
    "whatRoles": "このテンプレートに必要な役割",
    "signOrder": "署名者の順番を設定",
    "uniqueName": "固有の役割名を割り当てる必要があります。",
    "addRole": "役割を追加",
    "sendRole": "送信者の役割を追加",
    "next": "次へ: フィールドの配置"
  };
  _exports.addRoles = addRoles;
});