define("ember-rs/controllers/unauthorized/confirm-identity-expired", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    ////////////////////////  PARAMS  ////////////////////////
    queryParams: ['signer_id', 'identity_token'],
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      resendIdentityConfirmation: function resendIdentityConfirmation() {
        this._resendIdentityConfirmation();
      }
    },
    _resendIdentityConfirmation: function _resendIdentityConfirmation() {
      var _this = this;

      return new Ember.RSVP.Promise(function () {
        _jquery.default.ajax({
          url: "".concat(_environment.default.api.endpoint, "/signers/").concat(_this.signer_id, "/resend_identity_verification"),
          data: {
            regenerate_token: true
          },
          type: 'POST',
          global: false
        }).then(function () {
          _this.flashMessages.success(_this.i18n.t('components.document.signerRow.resendIdentityVerification'));
        }).fail(function (response) {
          _this.flashMessages.warning(_this.i18n.t('components.document.signerRow.resendingFailed', {
            error: response.responseText
          }));
        });
      }, 'resend identity verification');
    }
  });

  _exports.default = _default;
});