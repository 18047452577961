define("ember-rs/locales/ja/templates/onboarding/migration-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.migrationOnboarding = void 0;
  var migrationOnboarding = {
    "migrationHeading": "最新バージョンの RightSignature をご紹介します。",
    "slide1Q": "新機能",
    "slide1a": "高度なセキュリティ機能:",
    "slide1b": "ナレッジベース認証、パスコードによるアクセスの強制可能など。",
    "slide1c": "ユーザビリティの向上:",
    "slide1d": "新しい合理化されたレイアウト、カスタマイズ性の向上、生産性に関する通知、および改善されたモバイル機能。",
    "slide2": "古いバージョンの RightSigntature のドキュメントの場所",
    "slide3": "古いバージョンの RightSigntature のテンプレートの場所",
    "slide4": "最新バージョンの RightSignature で有料プランに変更する方法",
    "continue": "続行",
    "dismiss": "閉じる"
  };
  _exports.migrationOnboarding = migrationOnboarding;
});