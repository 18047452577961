define("ember-rs/locales/es/templates/template", ["exports", "ember-rs/locales/es/templates/template/details", "ember-rs/locales/es/templates/template/search-header-mobile"], function (_exports, _details, _searchHeaderMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.template = void 0;
  var template = {
    details: _details.details,
    searchHeaderMobile: _searchHeaderMobile.searchHeaderMobile,
    "review": {
      "header-content": "Revisar",
      "create-button": "Crear plantilla",
      "update-button": "Actualizar plantilla"
    },
    "index": {
      "subheader-create-button": "Crear plantilla",
      "template-limit-modal": {
        "cancellation-grace-period": "Esta cuenta está cancelada, por lo que no puede crear plantillas",
        "limit-reached": "Su plan actual solo le permite crear {{templateLimit}} plantillas. Si quiere crear más, contacte con la Asistencia técnica y actualice su cuenta.",
        "not-enabled": "Su plan actual no le permite crear plantillas. Si desea crear plantillas, contáctese con soporte y actualice su cuenta."
      }
    }
  };
  _exports.template = template;
});