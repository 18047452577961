define("ember-rs/templates/components/persistent-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7HOUzYEp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"persistent-modal__main\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"persistent-modal__main__content\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"persistent-modal__main__actions\"],[12],[2,\"\\n\"],[6,[37,4],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"loading-spinner\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"persistent-modal_cancel\"],[4,[38,1],[[32,0],\"cancelUpdates\"],null],[12],[1,[34,3]],[13],[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[11,\"button\"],[24,0,\"persistent-modal_save\"],[16,\"disabled\",[34,0]],[4,[38,1],[[32,0],\"saveUpdates\"],null],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"saveDisabled\",\"action\",\"saveText\",\"cancelText\",\"if\",\"loading\",\"actionsHidden\",\"unless\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/components/persistent-modal.hbs"
    }
  });

  _exports.default = _default;
});