define("ember-rs/locales/fr/templates/onboarding/new-user-onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboarding = void 0;
  var newUserOnboarding = {
    "title": "Bienvenue à RightSignature™",
    "letsPersonalize": "Avant d’explorer les fonctionnalités, nous allons personnaliser votre compte.",
    "photoText": "Votre photo est utilisée sur la page de signature du document. Elle ajoute un niveau de personnalisation et d'authenticité.",
    "photoSubtext": "Vous pouvez la mettre à jour à tout moment en accédant aux paramètres de votre compte.",
    "sendSign": "Envoyer &amp; signer vos documents",
    "sendDoc": "Envoyez vos documents à signer ou signez les documents qui requièrent votre signature.",
    "knowMore": "Vous souhaitez en savoir plus ?",
    "viewSupport": "Consulter articles de support &amp; vidéos",
    "createTemplates": "Créez des modèles pour des documents fréquemment signés.",
    "saveTime": "Gagnez du temps et créez des modèles réutilisables pour vos documents fréquemment utilisés, y compris les exonérations, accords de confidentialité, formulaires de déclaration d'impôts, formulaires de demandes d'emploi, etc.",
    "learnMore": "Vous souhaitez en apprendre davantage ?",
    "accessSupport": "Accédez à notre <a href='#''>site de support</a>, qui contient des didacticiels vidéo et des conseils utiles pour vous permettre de tirer le maximum de RightSignature™."
  };
  _exports.newUserOnboarding = newUserOnboarding;
});