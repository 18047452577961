define("ember-rs/models/merge-transaction", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: (0, _attr.default)('string'),
    mergedUploadId: (0, _attr.default)('string'),
    uploadSequence: (0, _attr.default)('object'),
    totalUploadSize: (0, _attr.default)('number'),
    documentTransactionId: (0, _attr.default)('string'),
    documentTransaction: (0, _relationships.belongsTo)('document-transaction', {
      async: false
    })
  });

  _exports.default = _default;
});