define("ember-rs/locales/it/models/models", ["exports", "ember-rs/locales/it/models/document-template"], function (_exports, _documentTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.models = void 0;
  var models = {
    documentTemplate: _documentTemplate.documentTemplate
  };
  _exports.models = models;
});