define("ember-rs/locales/fr/templates/reports/export/month", ["exports", "ember-rs/locales/fr/templates/reports/export/month/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.month = void 0;
  var month = {
    template: _template.template,
    "header": "Filtrer par modèle",
    "description": "Sélectionnez le modèle à utiliser pour filtrer les documents",
    "template-select-text": "Choisir un modèle"
  };
  _exports.month = month;
});