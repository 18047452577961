define("ember-rs/models/dashboard-document", ["exports", "@ember-data/model", "ember-rs/mixins/workflow-type", "ember-rs/mixins/taggable"], function (_exports, _model, _workflowType, _taggable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // workflowType, isSelfSign .. are included from WorkflowType
  // hasTags is included from Taggable
  var _default = _model.default.extend(_workflowType.default, _taggable.default, {
    executedAt: (0, _model.attr)('moment-date'),
    sentAt: (0, _model.attr)('moment-date'),
    expiresAt: (0, _model.attr)('moment-date'),
    filename: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    model: (0, _model.attr)('type'),
    // from ElasticSearch result
    sender: (0, _model.attr)('object'),
    signers: (0, _model.attr)('array-of-objects'),
    state: (0, _model.attr)('string'),
    thumbnailUrl: (0, _model.attr)('string'),
    type: (0, _model.attr)('string'),
    tags: (0, _model.attr)('object'),
    workflow: (0, _model.attr)('string'),
    accountId: (0, _model.attr)('string'),
    userId: (0, _model.attr)('string'),
    nextSignerId: (0, _model.attr)('string'),
    kba: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    senderName: Ember.computed.readOnly('sender.name')
  });

  _exports.default = _default;
});