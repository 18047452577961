define("ember-rs/locales/zh-cn/controllers/signer/sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sign = void 0;
  var sign = {
    "validPhone": "请输入有效的电话号码",
    "passcode": "通行码无效。请重试。"
  };
  _exports.sign = sign;
});