define("ember-rs/locales/en/templates/logged-out/marketing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.marketing = void 0;
  var marketing = {
    "note": "Welcome to the RightSignature marketing page!"
  };
  _exports.marketing = marketing;
});