define("ember-rs/components/rs-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'rs-alert',
    classNameBindings: ['typeModifier', 'isDismissed:is-dismissed'],
    hasType: Ember.computed.notEmpty('type'),
    typeIconPath: Ember.computed('type', function () {
      return "/assets/v3/icons/".concat(this.type, ".svg");
    }),
    typeModifier: Ember.computed('type', function () {
      return this.type ? "is-".concat(this.type) : '';
    }),
    shouldShowDismissal: Ember.computed.not('dismissAfter'),
    dismissalDelay: Ember.computed('dismissAfter', function () {
      return this.dismissAfter * 1000 || 4000;
    }),
    activateTimedDismissal: Ember.on('didRender', function () {
      var _this = this;

      if (this.dismissAfter) {
        window.setTimeout(function () {
          _this.send('dismissAlert');
        }, this.dismissalDelay);
      }
    }),
    actions: {
      dismissAlert: function dismissAlert() {
        this.set('isDismissed', true);
      }
    }
  });

  _exports.default = _default;
});