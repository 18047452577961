define("ember-rs/locales/nl/templates/reusable-templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newTrans = void 0;
  var newTrans = {
    "upload": "Uploaden",
    "dragDrop": "Bestanden hier slepen en neerzetten of:",
    "uploading": "Bezig met uploaden",
    "uploadFile": "Een bestand uploaden",
    "chooseCloud": "kiezen uit de cloud",
    "prepare": "document voorbereiden"
  };
  _exports.newTrans = newTrans;
});