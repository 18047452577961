define("ember-rs/locales/es/templates/upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgrade = void 0;
  var upgrade = {
    "noUpgrade": "No se puede actualizar la cuenta",
    "noPermission": "Parece que no tiene permiso para actualizar la cuenta. Consulte con el administrador de la cuenta para saber cómo puede actualizar a un plan nuevo de RightSignature.",
    "upgrade": "Actualice su cuenta",
    "upgradeSubtitle": "Durante la prueba, ha estado utilizando el plan Business. Puede permanecer en este plan, o elegir otro que funcione mejor para usted.",
    "choosePlan": "1. Seleccione un plan",
    "allInOne": "La solución de firma electrónica \"todo en uno\"",
    "standard": "Funciones estándar ",
    "usersNeeded": "2. ¿Cuántos usuarios necesita?",
    "checkout": "Pagar",
    "showLess": "Mostrar menos",
    "showMore": "Mostrar más",
    "users": "Usuarios",
    "confirm": "Presione Entrar para confirmar",
    "billing": "Facturación",
    "billingDetails": "Detalles de facturación",
    "noOfUser": "Cantidad de usuarios",
    "billAmount": "Importe facturable",
    "creditAmount": "Crédito del antiguo RightSignature",
    "total": "Total",
    "taxCharges": "Se pueden aplicar cargos adicionales de impuestos",
    "oops": "Se ha producido un error",
    "dataUnavailable": "Los datos no están disponibles en este momento. Vuelva a esta página más tarde."
  };
  _exports.upgrade = upgrade;
});