define("ember-rs/locales/pt-br/templates/onboarding/prepare-onboarding-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding2 = void 0;
  var prepareOnboarding2 = {
    "useTools": "Use essas ferramentas para anotar o documento com <span>sua própria assinatura, texto ou marcas de verificação</span>"
  };
  _exports.prepareOnboarding2 = prepareOnboarding2;
});