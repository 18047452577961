define("ember-rs/locales/de/templates/components/archived-migrated-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.archivedMigratedModal = void 0;
  var archivedMigratedModal = {
    "expiredBilling": "Abrechnungsinformationen abgelaufen!",
    "updateBillingDetails": "Ihre Zahlungsdetails sind nicht aktuell. Klicken Sie unten, um die Abrechnungsdetails zu aktualisieren und unseren Service weiterhin zu nutzen.",
    "clickUpdate": "Aktualisieren",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>Support kontaktieren</a>",
    "logout": "Abmelden"
  };
  _exports.archivedMigratedModal = archivedMigratedModal;
});