define("ember-rs/locales/de/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbarMenuitem = void 0;
  var componentToolbarMenuitem = {
    "signatureComponent": "Unterschriftenfeld",
    "textComponent": "Textfeld",
    "dateComponent": "Datumsfeld",
    "checkComponent": "Kontrollkästchen",
    "checkGroupComponent": "Kontrollkästchengruppe",
    "initialsComponent": "Initialenfeld",
    "selectComponent": "Auswahlfeld",
    "signatureAnnotationComponent": "Signatur hinzufügen",
    "signatureAnnotationName": "Signaturanmerkung",
    "textAnnotationComponent": "Text hinzufügen",
    "textAnnotationName": "Textanmerkung",
    "checkmarkAnnotationComponent": "Häkchen hinzufügen",
    "checkmarkAnnotationName": "Häkchenanmerkung",
    "attachmentComponent": "Anlage",
    "attachmentComponentName": "Angeforderte Anlage",
    "paymentComponent": "Zahlung einziehen",
    "signatureComponenttip": "Platzieren Sie dieses Feld dort, wo ein Unterzeichner seine Unterschrift leisten muss.",
    "textComponenttip": "Platzieren Sie dieses Feld dort, wo ein Unterzeichner Textinformationen eingeben muss (z. B. Name oder Adresse).",
    "dateComponenttip": "Verwenden Sie dieses Feld, wo Datumsangaben hinzugefügt werden müssen. Beim Unterzeichnen des Dokuments wird dem Unterzeichner eine Datumsauswahl angezeigt. Das Datum wird als 'MM-TT-JJJJ' angezeigt.",
    "checkComponenttip": "Verwenden Sie dieses Feld, wenn der Unterzeichner eine Option aktivieren muss (z. B. eine Klausel \"Ich akzeptiere...\"). Wenn ein Dokument eine Reihe von Optionen enthält und der Unterzeichner nur eine oder mindestens eine Option auswählen soll, verwenden Sie die Kontrollkästchengruppe.",
    "checkGroupComponenttip": "Verwenden Sie eine Kontrollkästchengruppe, wenn ein Dokument eine Reihe von Optionen enthält und der Unterzeichner nur eine oder mindestens eine Option auswählen soll.",
    "initialsComponenttip": "Platzieren Sie dieses Feld dort, wo ein Unterzeichner seine Initialen angeben muss.",
    "selectComponenttip": "Platzieren Sie ein Auswahlfeld, wenn Unterzeichner aus einer Liste von Optionen auswählen.",
    "signatureAnnotationComponenttip": "Fügen Sie dem Dokument Ihre eigene Signatur hinzu.",
    "textAnnotationComponenttip": "Fügen Sie dem Dokument Ihren eigenen Text hinzu, um vorab auszufüllen oder Anmerkungen einzufügen.",
    "checkmarkAnnotationComponenttip": "Aktivieren Sie ein Kontrollkästchen im Dokument.",
    "attachmentComponenttip": "Platzieren Sie dieses Feld dort, wo ein Unterzeichner ein Bild oder eine Datei an Ihr Dokument anfügen soll.",
    "paymentComponenttip": "Verwenden Sie diese Option, um Ihrem Dokument eine Schaltfläche hinzuzufügen, sodass ein Unterzeichner eine Kreditkartennummer für die Zahlung eingeben kann. Hinweis: Damit Sie diese Option verwenden können, müssen Sie eine Zahlungsintegration für Ihr Konto aktivieren. (Gehen Sie zu 'Konto' und wählen Sie 'Integrationen'.).",
    "unknownField": "Unbekanntes Feld"
  };
  _exports.componentToolbarMenuitem = componentToolbarMenuitem;
});