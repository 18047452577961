define("ember-rs/locales/pt-br/templates/onboarding/new-user-onboarding-from-sharefile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newUserOnboardingFromSharefile = void 0;
  var newUserOnboardingFromSharefile = {
    "title": "Envie e assine seus documentos",
    "startText": "Envie seus documentos para outras pessoas assinem ou assine documentos que precisem da sua assinatura.",
    "clickLearn": "Clique em Avançar para saber mais.",
    "addSigner": "Adicionar signatários",
    "addAnyone": "Adicione qualquer pessoa que precise preencher ou assinar este documento.",
    "prepare": "Preparar o documento",
    "placeFields": "Coloque campos onde você precisa que os signatários preencham informações ou assinem o documento. Você pode atribuir campos a cada signatário.",
    "sendTrack": "Enviar e acompanhar o progresso",
    "onceSent": "Depois de ter enviado o documento, você poderá acompanhar seu progresso na página de detalhes do documento. Para navegar até a página de detalhes, basta clicar no documento do painel do documento."
  };
  _exports.newUserOnboardingFromSharefile = newUserOnboardingFromSharefile;
});