define("ember-rs/locales/ja/templates/mobile-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mobileNavigation = void 0;
  var mobileNavigation = {
    "documents": "ドキュメント",
    "templates": "テンプレート",
    "settings": "ユーザー設定",
    "integrations": "統合",
    "users": "ユーザー",
    "reports": "レポート",
    "help": "ヘルプ",
    "logout": "ログアウト"
  };
  _exports.mobileNavigation = mobileNavigation;
});