define("ember-rs/locales/es/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteDocumentModal = void 0;
  var deleteDocumentModal = {
    "permanentlyDeleted": "Esto eliminará permanentemente el documento de RightSignature. Una vez hecho esto, no se puede deshacer.",
    "email": "Los firmantes y otros destinatarios CC recibirán un correo electrónico con el documento completo y el certificado de firma para sus registros.",
    "briefMoment": "El documento puede tardar unos momentos en desaparecer del panel.",
    "delete": "Eliminar",
    "cancel": "Cancelar",
    "deleteDocument": "Eliminar documento",
    "deleteComplete": "Documento eliminado",
    "deleteFailed": "Error al destruir el documento"
  };
  _exports.deleteDocumentModal = deleteDocumentModal;
});