define("ember-rs/locales/pt-br/templates/components/force-complete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.forceCompleteDocumentModal = void 0;
  var forceCompleteDocumentModal = {
    "header": "Forçar execução do documento",
    "forceCompleted": "Isso removerá todos os campos atribuídos aos signatários que ainda não assinaram e fará com que o documento seja executado (estado final). Depois que isso for feito, não é possível desfazer.",
    "briefMoment": "Pode demorar alguns momentos para que o painel mostre o novo status.",
    "forceComplete": "Forçar execução",
    "cancel": "Cancelar",
    "success": "O documento foi concluído à força com sucesso.",
    "failed": "Falha ao forçar a conclusão do documento"
  };
  _exports.forceCompleteDocumentModal = forceCompleteDocumentModal;
});