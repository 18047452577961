define("ember-rs/templates/onboarding/account-onboarding-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Y1lMyaq",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"onboarding-target-image\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"title\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"settings\"],null]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"title-text\"],[12],[1,[30,[36,1],[\"components.rsLeftNav.Account\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,0],[\"caret_left\"],[[\"class\"],[\"caret_left\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"direction\"],[12],[2,\"\\n  \"],[10,\"object\"],[14,\"data\",\"/assets/images/onboarding-new/overlay-arrow.svg\"],[14,0,\"onboarding-arrow\"],[14,4,\"image/svg+xml\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/onboarding-new/overlay-arrow.png\"],[15,\"alt\",[30,[36,1],[\"shared.no-svg-support\"],null]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"onboarding.accountOnboarding1.body\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"t\",\"navItems\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/onboarding/account-onboarding-1.hbs"
    }
  });

  _exports.default = _default;
});