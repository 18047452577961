define("ember-rs/locales/nl/templates/components/component-toolbar-menuitem", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbarMenuitem = void 0;
  var componentToolbarMenuitem = {
    "signatureComponent": "Handtekeningveld",
    "textComponent": "Tekstveld",
    "dateComponent": "Datumveld",
    "checkComponent": "Selectievakje",
    "checkGroupComponent": "Groep selectievakjes",
    "initialsComponent": "Initialenveld",
    "selectComponent": "Keuzelijst",
    "signatureAnnotationComponent": "Handtekening toevoegen",
    "signatureAnnotationName": "Aantekening bij handtekening",
    "textAnnotationComponent": "Tekst toevoegen",
    "textAnnotationName": "Tekstaantekening",
    "checkmarkAnnotationComponent": "Vinkje toevoegen",
    "checkmarkAnnotationName": "Aantekening bij vinkje",
    "attachmentComponent": "Bijlage",
    "attachmentComponentName": "Gevraagde bijlage",
    "paymentComponent": "Innen van betaling",
    "signatureComponenttip": "Plaats dit veld waar een ondertekenaar zijn handtekening moet zetten.",
    "textComponenttip": "Plaats dit veld waar een ondertekenaar tekstinformatie moet invoeren (bijvoorbeeld, het typen van een naam of adres).",
    "dateComponenttip": "Gebruik dit veld waar datums moeten worden toegevoegd. Bij ondertekening van het document krijgt de ondertekenaar een datumkiezer te zien, waarmee gemakkelijk een datum kan worden geselecteerd. De datum wordt weergegeven als 'MM-DD-JJJJ'.",
    "checkComponenttip": "Gebruik dit veld waar de ondertekenaar een optie moet inschakelen (bijv. een clausule 'Ik ga akkoord met...'). Wanneer een document een reeks opties bevat, waarbij een ondertekenaar slechts één of minstens een van de opties moet selecteren, gebruikt u de optie Groep selectievakjes.",
    "checkGroupComponenttip": "Gebruik een groep selectievakjes wanneer er een reeks opties zijn en de ondertekenaar slechts één of minstens een van de opties moet selecteren.",
    "initialsComponenttip": "Plaats dit veld waar een ondertekenaar zijn/haar initialen moet opgeven.",
    "selectComponenttip": "Plaats een keuzelijst waar ondertekenaars uit een lijst met opties moeten kiezen.",
    "signatureAnnotationComponenttip": "Voeg uw eigen handtekening toe aan het document.",
    "textAnnotationComponenttip": "Voeg uw eigen tekst toe aan het document om het vooraf in te vullen of aantekeningen te maken.",
    "checkmarkAnnotationComponenttip": "Vink een vakje aan in het document.",
    "attachmentComponenttip": "Plaats dit vak waar een ondertekenaar een afbeelding of bestand bij uw document moet bijvoegen.",
    "paymentComponenttip": "Gebruik deze optie om een knop aan uw document toe te voegen, zodat een ondertekenaar een creditcardnummer voor de betaling kan invoeren. Opmerking: voordat u deze optie gebruikt, moet u een betalingsintegratie in uw account inschakelen. (ga naar Account en selecteer Integraties).",
    "unknownField": "Onbekend veld"
  };
  _exports.componentToolbarMenuitem = componentToolbarMenuitem;
});