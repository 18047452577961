define("ember-rs/locales/en/templates/cc-gateway-redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ccGatewayRedirect = void 0;
  var ccGatewayRedirect = {
    "title": "Upgrading...",
    "desc": "This may take a few seconds. Please do not refresh your page or click the Close or Back button of your browser."
  };
  _exports.ccGatewayRedirect = ccGatewayRedirect;
});