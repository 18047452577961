define("ember-rs/locales/ja/controllers/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.help = void 0;
  var help = {
    "success": "リクエストが送信されました。回答には 24 時間かかることがあります。",
    "error": "送信に失敗しました: {{{error}}}"
  };
  _exports.help = help;
});