define("ember-rs/locales/ja/templates/components/delete-document-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteDocumentModal = void 0;
  var deleteDocumentModal = {
    "permanentlyDeleted": "RightSignature からドキュメントが完全に削除されます。元に戻すことはできません。",
    "email": "署名者と CC の宛先は、参照用に完了したドキュメントと署名付き証明書をメールで受信します。",
    "briefMoment": "ダッシュボードからドキュメントが表示されなくなるまで時間がかかることがあります。",
    "delete": "削除",
    "cancel": "キャンセル",
    "deleteDocument": "ドキュメントの削除",
    "deleteComplete": "削除されたドキュメント",
    "deleteFailed": "ドキュメントの廃棄に失敗しました。"
  };
  _exports.deleteDocumentModal = deleteDocumentModal;
});