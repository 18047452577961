define("ember-rs/routes/document-builder-flow-grant", ["exports", "jquery", "ember-rs/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    partialSession: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!Ember.isPresent(transition.to.queryParams.document_builder_flow_grant_token)) {
        this.transitionTo('unauthorized.unauthorized');
      }
    },
    model: function model(queryParams, transition) {
      var _this = this;

      return _jquery.default.ajax({
        url: "".concat(_environment.default.api.endpoint, "/redirect_tokens/exchange"),
        type: 'POST',
        data: {
          token: transition.to.queryParams.document_builder_flow_grant_token
        }
      }).then(function (response) {
        if (response.redirect_token) {
          var document_transaction_id = response.redirect_token.document_transaction_id;
          var document_id = response.redirect_token.document_id;

          if (!_this.get('session.isAuthenticated')) {
            var partialSession = _this.partialSession;
            partialSession.updateStore(response);

            _this.currentUser.set('model', _this.store.peekRecord('user', response.redirect_token.user.id));

            partialSession.setValue(response.redirect_token.token);
            partialSession.setSession();
          }

          _this.replaceWith("/document-transactions/".concat(document_transaction_id, "/documents/").concat(document_id, "/prepare_document"));
        } else {
          _this.transitionTo('unauthorized.unauthorized');
        }
      }, function () {
        _this.transitionTo('unauthorized.unauthorized');
      });
    }
  });

  _exports.default = _default;
});