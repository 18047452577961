define("ember-rs/locales/ja/templates/components/hybris-trial-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialModal = void 0;
  var hybrisTrialModal = {
    "ohNo": "申し訳ございません。",
    "reminder": "ご一読ください。",
    "previewHasEnded": "最新バージョンの RightSignature のトライアル期間が終了しました。",
    "freeTrialEnds": "無料トライアル期間は {{trialDaysRemaining}} 日後に終了します 。",
    "needToGetThingsSigned": "引き続き使用されますか? RightSignature の新しいプランと価格設定は、お客様のビジネスにより多くの価値を提供します。",
    "exploreAdminFeatures": "高度な管理およびセキュリティ機能を確認する",
    "retainDocuments": "オリジナルのドキュメントおよびアカウント設定が保持されます",
    "easySetup": "簡単にセットアップでき、契約を必要とせず、いつでもキャンセルできます",
    "saveTimeAndPaper": "書類の処理にかかる時間を毎週 1 時間以上削減できます",
    "choosePlan": "継続するプランを選択してください",
    "needAssitance": "評価についてご不明な点がある場合:",
    "contactSupport": "<a href='https://support.citrix.com/cscase#/' class='rs-button is-link is-secondary'>サポートに連絡</a>",
    "notSureYet": "ご検討中ですか? RightSignature がお客様のビジネスにどのように役立つかをご確認ください。",
    "logout": "ログアウト"
  };
  _exports.hybrisTrialModal = hybrisTrialModal;
});