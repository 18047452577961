define("ember-rs/locales/zh-cn/templates/start-document/-send-for-signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sendForSignature = void 0;
  var sendForSignature = {
    "support": "请<a href='https://support.citrix.com/cscase'>联系技术支持</a>以获取帮助。"
  };
  _exports.sendForSignature = sendForSignature;
});