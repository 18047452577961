define("ember-rs/locales/de/templates/components/document-download-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.documentDownloadOptions = void 0;
  var documentDownloadOptions = {
    "originalPDF": "Original-PDF",
    "completedPDF": "Fertige PDF",
    "overlay": "Druckbare Überlagerungs-PDF",
    "failed": "Fertige PDF (fehlgeschlagen)",
    "support": "Beim Verarbeiten des Dokuments ist ein Problem aufgetreten. Kontaktieren Sie den Support.",
    "cert": "Signaturzertifikat",
    "mergedPDF": "Zusammengefügte PDF"
  };
  _exports.documentDownloadOptions = documentDownloadOptions;
});