define("ember-rs/locales/de/templates/onboarding/prepare-onboarding-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.prepareOnboarding3 = void 0;
  var prepareOnboarding3 = {
    "seeSigners": "<span>Zeigen Sie die Unterzeichner</span> des Dokuments an. Jeder Unterzeichner hat seine eigene Farbe, die den Feldern entspricht, die ausgefüllt werden müssen"
  };
  _exports.prepareOnboarding3 = prepareOnboarding3;
});