define("ember-rs/mixins/tags-collection", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ////////////////////////  DEPENDENCIES  ////////////////////////
    flashMessages: Ember.inject.service(),
    ////////////////////////  PROPERTIES  ////////////////////////
    tagsCollection: Ember.computed('tags', function () {
      return _lodash.default.map(this.tags, function (value, key) {
        return {
          name: key,
          value: value
        };
      });
    }),
    ////////////////////////  ACTIONS  ////////////////////////
    actions: {
      addNewTag: function addNewTag(tagName, tagValue) {
        tagName = (tagName || '').replace(/\s/g, '');

        if (Ember.isBlank(tagName)) {
          this.flashMessages.warning(this.i18n.t("mixins.tagsCollection.supplyTagName"));
          return;
        }

        var previousTag = this.tagsCollection.findBy('name', tagName);

        if (previousTag) {
          this.tagsCollection.removeObject(previousTag);
        }

        this.tagsCollection.pushObject(Ember.Object.create({
          name: tagName,
          value: tagValue || ''
        }));
        this.setProperties({
          newTagName: undefined,
          newTagValue: undefined,
          showTagsModal: false
        });
        this.updateDocumentTags();
      },
      removeTag: function removeTag(position) {
        var tags = this.tagsCollection || [];

        if (tags.objectAt(position)) {
          tags.removeAt(position);
          this.updateDocumentTags();
        }
      }
    },
    ////////////////////////  HELPERS  ////////////////////////
    updateDocumentTags: function updateDocumentTags() {
      var tagsObject = {};
      this.tagsCollection.forEach(function (tag) {
        tagsObject[tag.name] = tag.value;
      });
      this.sendAction('updateTags', tagsObject);
      return tagsObject;
    }
  });

  _exports.default = _default;
});