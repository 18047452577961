define("ember-rs/helpers/audit-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.auditIcon = auditIcon;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var auditIconMap = {
    'signed': 'fa-check',
    'self_signed': 'fa-check',
    'executed': 'fa-gavel',
    'created': 'fa-file-text',
    'distribution': 'fa-envelope',
    'viewed': 'fa-eye',
    'voided': 'fa-ban',
    'identity_authenticated': 'fa-lock',
    'reminder': 'fa-envelope',
    'attachment': 'fa-paperclip',
    'expired': 'fa-clock-o',
    'declined': 'fa-times-circle',
    'identity_verification': 'fa-key',
    'modified_email': 'fa-pencil-square-o'
  };

  function auditIcon(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        keyword = _ref2[0];

    return auditIconMap[keyword] || '';
  }

  var _default = Ember.Helper.helper(auditIcon);

  _exports.default = _default;
});