define("ember-rs/locales/zh-cn/templates/components/reusable-template-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reusableTemplateRow = void 0;
  var reusableTemplateRow = {
    "send": "发送",
    "details": "详细信息",
    "trialmessageComp": "您的当前试用计划仅允许您发送 {{{documentLimit}}} 个文档。 ",
    "planmessageComp": "您的当前计划仅允许您发送 {{{documentLimit}}} 个文档。 ",
    "upgrade": "请联系我们升级您的帐户。"
  };
  _exports.reusableTemplateRow = reusableTemplateRow;
});