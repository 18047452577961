define("ember-rs/components/form-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    type: Ember.computed('label', function () {
      if (this.label.match(/^password/i)) {
        return 'password';
      } else {
        return 'text';
      }
    })
  });

  _exports.default = _default;
});