define("ember-rs/locales/it/templates/document-transaction/review-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.review_mobile = void 0;
  var review_mobile = {
    "review_details": "rivedere i dettagli di:",
    "reusable_template": "Modello riutilizzabile",
    "distribution_method": "Metodo di distribuzione:",
    "identity_method": "Metodo di identità:",
    "save_doc": "Salva documento",
    "send_doc": "Invia documento"
  };
  _exports.review_mobile = review_mobile;
});