define("ember-rs/locales/pt-br/templates/unauthorized/no-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.noUser = void 0;
  var noUser = {
    "unableLogin": "Não é possível fazer login",
    "unableLoginTip": "Você não consegue entrar nesta conta do RightSignature.<br> Entre em contato com um administrador de conta para obter acesso."
  };
  _exports.noUser = noUser;
});