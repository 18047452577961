define("ember-rs/locales/pt-br/controllers/template/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.details = void 0;
  var details = {
    "upgradeMsg": "Sua conta não tem acesso a esse recurso. Atualize para um plano Pro para poder usar modelos incorporados.",
    "templateDeleteMsg": "O modelo foi excluído com sucesso.",
    "tagUpdateMsg": "As marcas foram atualizadas com sucesso",
    "tagUpdateFailedMsg": "Falha ao atualizar as marcas: {{{formattedResponse}}}",
    "templateDeleteConfirm": "Tem certeza de que deseja excluir este modelo?",
    "loadSignerLinksFailedMsg": "Falha ao carregar links de assinante de modelo"
  };
  _exports.details = details;
});