define("ember-rs/locales/it/utils/hybris-plan-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisPlanDetails = void 0;
  var hybrisPlanDetails = {
    "document-sending": "Invio documento",
    "document-sending-info": "Caricare e inviare documenti in formati comuni, tra cui PDF, .doc e altro ancora.",
    "signature": "Firme disegnate a mano",
    "signature-info": "Firmare con il mouse o il touchscreen: proprio come si firma su carta.",
    "customer-support": "Assistenza clienti 24 ore su 24, 7 giorni su 7",
    "customer-support-info": "Ricevere assistenza ogni volta che se ne ha necessità dal nostro pluripremiato team di supporto tecnico.",
    "email-alert": "Avvisi e-mail",
    "email-alert-info": "Promemoria per i firmatari e aggiornamenti istantanei dello stato per i documenti.",
    "integration": "Componenti aggiuntivi di integrazione",
    "integration-info": "Connessione di RightSignature a oltre una dozzina delle app avanzate più diffuse.",
    "type-sign": "Firma dalla tastiera",
    "type-sign-info": "Consente ai firmatari di firmare i documenti con una tastiera.",
    "mobile": "Firma sui dispositivi mobili",
    "mobile-info": "Firma di documenti da qualsiasi luogo usando lo smartphone o il tablet.",
    "guided-signing": "Firma guidata",
    "guided-signing-info": "Riduzione di errori ed omissioni con semplici istruzioni passo passo.",
    "archive": "Archivio documenti protetto",
    "archive-info": "I documenti importanti a portata di mano con il nostro archivio sicuro e ricercabile.",
    "encrypt": "Crittografia dei dati a 256 bit",
    "encrypt-info": "La sicurezza a livello bancario protegge i documenti sensibili da indiscrezioni e furti.",
    "biometric": "Autenticazione biometrica",
    "biometric-info": "Aiuta a verificare l'autenticità del firmatario con i dati biometrici chiave.",
    "fingerprint": "Impronte digitali",
    "fingerprint-info": "I checksum digitali SHA-1 impediscono a qualsiasi parte di modificare il documento.",
    "audit": "Registro di controllo e certificato",
    "audit-info": "Per la conformità legale, ogni azione compiuta su un documento viene registrata e protetta.",
    "template": "Modelli riutilizzabili",
    "template-info": "Condivisione dei documenti di uso comune con tutto il team.",
    "branding": "Branding personalizzato",
    "branding-info": "Aggiungere il logo, i colori e una foto ai documenti RightSignature.",
    "attachments": "Richiedere allegati",
    "attachments-info": "Consentire ai firmatari di allegare un file durante la firma del documento.",
    "payments": "Richiedi pagamenti",
    "payments-info": "Elaborazione dei pagamenti o raccolta i dati della carta di credito sui documenti.",
    "api": "API per sviluppatori",
    "api-info": "Integrazione delle firme elettroniche RightSignature nelle proprie app o nel proprio sito Web.",
    "kba": "Autenticazione basata sulla conoscenza",
    "kba-info": "In alcuni settori, migliorare il rispetto delle normative pertinenti.",
    "standard": "Standard",
    "advanced": "<span>Avanzato<span>",
    "advancedKba": "<span>KBA avanzato<span>",
    "sharefilePremium": "<span><p>ShareFile Premium</p> <p> con firma elettronica</p></span>",
    "annually": "Annuale",
    "quarterly": "Trimestrale",
    "monthly": "Mensile",
    "month": "mese",
    "year": "anno",
    "quarter": "trimestre",
    "document-sends-per-user": "100 invii di documenti per utente",
    "hand-written-text-to-sign": "Firma manoscritta da testo, fare clic per accettare",
    "bulk-send-for-signature": "Invio in blocco per la firma",
    "sequenced-signing": "Firma sequenziata",
    "knowledge-based-authentication": "Autenticazione basata sulla conoscenza",
    "unlimited-document-sends": "Invio di un numero illimitato di documenti per la firma",
    "store-sync-share-files": "Archiviare, sincronizzare e condividere file senza problemi",
    "hipaa-compliant": "Configurazione della conformità HIPAA",
    "advanced-caption": "Include funzionalità diffuse come:",
    "advanced-kba-caption": "Include funzionalità diffuse come:",
    "sharefile-caption": "Include TUTTE LE FUNZIONALITÀ DI FIRMA ELETTRONICA E ALTRO ANCORA CON CITRIX SHAREFILE:",
    "minimum-of": "Minimo di {{{noUsers}}} utenti",
    "users": "utenti",
    "user": "utente",
    "user-per-month": "per utente/mese",
    "compare-plans-in-detail": "<a href='https://citrix.sharefile.com/share/view/s1493194af0a44866ae95a6a667e7ffcd' style='color:#2071C5' target='_blank'>Confronto dei piani in dettaglio</a>",
    "selected-plan-requires-3-users": "<span style='color:#536E7B'>Il piano selezionato richiede almeno 3 utenti</span>",
    "more-than-1000-users-contact-sales": "<span style='color:#2071C5'>Se sono richiesti più di 1000 utenti, <a href={{driftLinkId}} style='color: #2071C5; text-decoration: underline; text-transform: lowercase'>chattare con l'ufficio vendite. </a></span>",
    "trail-upgrade-msg": "Rimangono {{{numberOfDaysLeft}}} giorni per la prova. Per godere di un utilizzo senza preoccupazioni né interruzioni, valutare la possibilità di aggiornare il piano ora.",
    "savings": "Risparmi",
    "contact-sales": "Chatta con l'ufficio vendite",
    "select-plan": "Seleziona piano",
    "selected-plan": "Piano selezionato",
    "best-for-team-collaboration": "Miglior rapporto qualità/prezzo"
  };
  _exports.hybrisPlanDetails = hybrisPlanDetails;
});