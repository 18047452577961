define("ember-rs/locales/nl/controllers/document/unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unlock = void 0;
  var unlock = {
    "success": "Alle ondertekenaars ontgrendeld",
    "error": "Kan ondertekenaars niet ontgrendelen. Probeer het later opnieuw."
  };
  _exports.unlock = unlock;
});