define("ember-rs/locales/en/templates/signer/passcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passcode = void 0;
  var passcode = {
    "enterPin": "Enter PIN.",
    "access": "Enter the PIN provided to access the document.",
    "unlock": "Unlock",
    "docProtected": "This document is passcode protected.",
    "answer": "answer...",
    "show": "show passcode",
    "continue": "Continue to Document",
    "answerQuestion": "Answer the security question below to access the document"
  };
  _exports.passcode = passcode;
});