define("ember-rs/locales/ja/templates/components/hybris-trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hybrisTrialBanner = void 0;
  var hybrisTrialBanner = {
    "billingInfo": "請求情報と価格プランは最新ではありません。",
    "update": "更新するにはここをクリック",
    "hybrisTrialMsg": "トライアル期間は、あと {{{numberOfDaysLeft}}} 日で終了します。有料プランに<a href='/upgrade-worldpay'>アップグレード</a>して、引き続きサービスをご利用ください。",
    "premiumTrialMsg": "You are on your Premium Trial. Please contact 1-800-441-3453 or Email us at <a href='mailto:{{{email}}}'>{{{email}}}</a> to upgrade and go completely paperless."
  };
  _exports.hybrisTrialBanner = hybrisTrialBanner;
});