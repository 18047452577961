define("ember-rs/locales/zh-cn/templates/components/main-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mainFooter = void 0;
  var mainFooter = {
    "Support": "支持",
    "privacy": "隐私政策",
    "copyright": "Citrix Systems, Inc. 保留所有权利。"
  };
  _exports.mainFooter = mainFooter;
});