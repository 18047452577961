define("ember-rs/templates/unauthorized/no-integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2KSWDhKT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[10,\"br\"],[12],[13],[1,[30,[36,0],[\"unauthorized.noIntegrations.noIntegrations\"],null]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"footer\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"unauthorized.noIntegrations.support\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/unauthorized/no-integrations.hbs"
    }
  });

  _exports.default = _default;
});