define("ember-rs/locales/en/templates/upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upgrade = void 0;
  var upgrade = {
    "noUpgrade": "Can't upgrade account",
    "noPermission": "It looks like you don't have permission to upgrade your account. Talk to your account administrator about upgrading to a new RightSignature plan.",
    "upgrade": "Upgrade your account",
    "upgradeSubtitle": "During your trial you have been on the Business plan. You can stay on this plan or choose another plan that works better for you.",
    "choosePlan": "1. Choose a plan",
    "allInOne": "The All-in-One Electronic Signature Solution",
    "standard": "Standard Features ",
    "usersNeeded": "2. How many users do you need?",
    "checkout": "Checkout",
    "showLess": "Show Less",
    "showMore": "Show More",
    "users": "Users",
    "confirm": "Press enter to confirm",
    "billing": "Billing",
    "billingDetails": "Billing Details",
    "noOfUser": "No of users",
    "billAmount": "Billable amount",
    "creditAmount": "Credit amount from old RightSignature",
    "total": "Total",
    "taxCharges": "additional tax charges may be applicable",
    "oops": "Oopsie! Something went wrong",
    "dataUnavailable": "Data is not available at this moment. Please come back to this page later."
  };
  _exports.upgrade = upgrade;
});