define("ember-rs/locales/ja/templates/signer/sign/identify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.identify = void 0;
  var identify = {
    "advisory": "このドキュメントの送信者は、ID を確認するために RightSignature のナレッジベース認証機能を有効にしました。RightSignature は、BlockScore のサードパーティ サービスを使用して、提供された個人情報を検証し、確認します。提供された個人情報の使用は、BlockScore 独自のデータ レコードによる ID の検証と確認に限定されます。ID の検証および確認のパフォーマンスが、ユーザーの信用報告書、信用度、または金融機関への報告書に影響を与えることはありません。IRS、Blockscore、RightSignature のいずれも、このプロセスによって対象の信用報告書または税務情報を閲覧したり、こうした情報にアクセスしたりすることはできません。",
    "step1": "ID 確認 - 手順 1/2",
    "beforeSigning": "ドキュメントの署名前に、ID 確認に必要な個人情報を入力してください。",
    "notValid": "申し訳ありません。提供された情報に一致する有効な ID が見つかりませんでした。以下の情報が正しいことを確認し、[次へ] をクリックして再試行してください。",
    "youHave": "後 ",
    "remaining": " 回、ドキュメントへのアクセスを試行できます。",
    "name": "名前：",
    "dob": "生年月日:",
    "ssn": "SSN の最後の 4 文字:",
    "passport": "パスポート番号:",
    "address": "住所:",
    "address2": "住所 2:",
    "city": "市区町村：",
    "state": "状態:",
    "zip": "郵便番号:",
    "country": "国：",
    "return": "フォームに戻る",
    "advisory2": "ID 確認に関するアドバイザリ",
    "sampleAddress1": "120 S. West St.",
    "sampleAddress2": "Suite 100",
    "sampleAddress3": "Raleigh",
    "submit": "送信"
  };
  _exports.identify = identify;
});