define("ember-rs/locales/fr/templates/components/component-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.componentToolbar = void 0;
  var componentToolbar = {
    "useTools": "Utilisez ces outils pour les champs que les signataires doivent remplir.",
    "showLess": "Afficher moins",
    "showMore": "Afficher plus",
    "annotate": "annoter :",
    "annotateTip": "Utilisez ces outils pour annoter le document avec votre propre signature, texte ou coche.",
    "request": "Demander :",
    "dataFields": "Champs de données",
    "sourceFetchFailed": "Impossible de récupérer les informations sur la source.",
    "schemaFetchFailed": "Failed to retrieve source entity schema."
  };
  _exports.componentToolbar = componentToolbar;
});