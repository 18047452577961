define("ember-rs/templates/reports/export/month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eu5cbloi",
    "block": "{\"symbols\":[\"xs\",\"template\"],\"statements\":[[10,\"div\"],[14,1,\"personal_info\"],[14,0,\"report-section\"],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"\\n    2. \"],[1,[30,[36,0],[\"reports.export.month.header\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"description\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"reports.export.month.description\"],null]],[2,\":\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"x-select\",[],[[\"@value\",\"@on-change\"],[[34,1],[30,[36,2],[\"templateWasSelected\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"option\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"reports.export.month.template-select-text\"],null]],[2,\"...\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"option\"]],[],[[\"@value\"],[[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,2,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"templateIdSelected\",\"route-action\",\"model\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ember-rs/templates/reports/export/month.hbs"
    }
  });

  _exports.default = _default;
});